import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { toggleOmitFromMkt } from './InventoryUserQueries';

class InventoryUserOmitFromMkt extends React.Component {
  state = {
    open     : false,
    omitUser : true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleOmit = () => {
    const { omitUser } = this.state;
    this.setState({ omitUser: !omitUser });
  };

  render () {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, omitUser } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Are you sure you want to include ${selected.length} users in marketplace?`;
    let bodyText = 'These users will show up in searches.';
    let assignAsSellerText = 'un-assignAsSeller';

    if (open) {
      const selections = selected.map(s => find(data, [ 'id', s ]));
      validSelections = filter(selections, [ 'has_user', true ]);
      invalidSelections = filter(selections, [ 'has_user', false ]);

      if (omitUser) {
        message = `Are you sure you want to omit ${selected.length} users from marketplace?`;
        bodyText = 'These users will not show up in searches.';
        assignAsSellerText = 'assignAsSeller';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Omit / Include user from Marketplace">
          <IconButton aria-label="Omit / Include user from Marketplace">
            <i class="material-icons" onClick={this.handleClickOpen}>
              location_off
            </i>
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={omitUser} onClick={this.toggleOmit} /> Omit user from marketplace
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot be ${assignAsSellerText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables : {
                    usersToOmit : validSelections.map(iu => iu.user.id),
                    omitted     : omitUser,
                  },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              disabled={validSelections.length === 0}
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserOmitFromMkt.propTypes = {
  selected          : PropTypes.array.isRequired,
  changePage        : PropTypes.func.isRequired,
  data              : PropTypes.object.isRequired,
  mutate            : PropTypes.func.isRequired,
  loadBulkItemsList : PropTypes.func.isRequired,
};

export default graphql(toggleOmitFromMkt)(InventoryUserOmitFromMkt);
