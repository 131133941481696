import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Mutation } from 'react-apollo';
import moment from 'moment';
import { get, isEmpty } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CustomDollarFormat from '../CustomDollarFormat/CustomDollarFormat';
import WarningText from '../WarningText/WarningText';
import { createPipelineCardMutation } from '../Pipelines/PipelineQueries';

class AddCaseDialog extends React.Component {
  originalState = {
    name          : '',
    value         : null,
    description   : null,
    visibility    : 'public_visibility',
    priority      : 'medium',
    owner         : null,
    close_date    : moment().add(1, 'M'),
    primary_agent : null,
    open          : false,
    pipeline_id   : null,
  };

  state = { ...this.originalState };

  componentWillReceiveProps = nextProps => {
    const { selectedPipeline } = this.props;
    if (selectedPipeline !== !nextProps.selectedPipeline) {
      this.setState({
        pipeline_id : nextProps.selectedPipeline,
        name        : nextProps.suggestedUser.name,
        description : nextProps.suggestedUser.description,
      });
    }
  };

  handleClose = () => {
    const { closeAddCaseDialog, updateSelectedSuggestedUser } = this.props;
    if (updateSelectedSuggestedUser) {
      updateSelectedSuggestedUser({});
    }
    if (closeAddCaseDialog) {
      closeAddCaseDialog();
    }
    this.setState({ ...this.originalState });
  };

  validateDate = date => {
    if (date === null) {
      return <div />;
    }
    return moment(date).isSameOrAfter(moment());
  };

  selectPipeline = pipeline_id => {
    const { selectPipelineFunc } = this.props;
    selectPipelineFunc(pipeline_id);
  };

  render () {
    const { selectedPipeline, suggestedUser, classes, pipelineList, owner, deleteSuggested } = this.props;
    const { name, value, description, visibility, priority, close_date, pipeline_id } = this.state;
    if (!suggestedUser) return <span />;
    return (
      <div>
        <Dialog
          open={!isEmpty(suggestedUser)}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialog}>
          <DialogTitle id="alert-dialog-title">{''}</DialogTitle>
          <DialogContent>
            Pipeline:
            <br />
            <Select
              value={selectedPipeline || pipeline_id}
              className={classes.inputField}
              onChange={e => {
                this.selectPipeline(e.target.value);
                this.setState({ pipeline_id: e.target.value });
              }}>
              {(pipelineList || []).map(p => (
                <MenuItem key={`pl_${p.id}`} value={p.id}>
                  {decodeURI(p.name)}
                </MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <TextField
              autoFocus
              id="name"
              className={classes.dialog}
              label="Name"
              type="text"
              value={suggestedUser.name || name}
              placeholder="Add Name"
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
            />
            <br />
            <br />
            <TextField
              id="value"
              label="Value"
              value={value}
              placeholder="Add Value"
              InputProps={{
                inputComponent : CustomDollarFormat,
              }}
              onChange={e => {
                this.setState({ value: e.target.value });
              }}
              fullWidth
            />
            <br />
            <br />
            <TextField
              id="description"
              label="Description"
              type="text"
              value={suggestedUser.description || description}
              placeholder="Add Description"
              onChange={e => {
                this.setState({ description: e.target.value });
              }}
              fullWidth
            />
            <br />
            <br />
            <div>Close Date: </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                value={close_date}
                onChange={date => this.setState({ close_date: date })}
              />
            </MuiPickersUtilsProvider>
            <WarningText visible={!this.validateDate(close_date)} message="Close Date Must Be In The Future" />
            <br />
            Owner: {get(owner, 'name')}
            <br />
            Primary Agent: {get(suggestedUser, 'inventory_user.name')}
            <br />
            <TextField
              id="standard-priority"
              select
              fullWidth
              label="Priority"
              className={classes.inputField}
              value={priority}
              onChange={e => {
                this.setState({ priority: e.target.value });
              }}
              InputLabelProps={{
                shrink : true,
              }}
              margin="normal">
              {[
                { value: 'no', label: 'None' },
                { value: 'low', label: 'Low' },
                { value: 'medium', label: 'Medium' },
                { value: 'high', label: 'High' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <TextField
              id="standard-visibility"
              select
              fullWidth
              label="Visibility"
              className={classes.inputField}
              value={visibility}
              onChange={e => {
                this.setState({ visibility: e.target.value });
              }}
              InputLabelProps={{
                shrink : true,
              }}
              margin="normal">
              {[
                { value: 'public_visibility', label: 'Public' },
                { value: 'private_visibility', label: 'Private' },
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <Divider />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Mutation mutation={createPipelineCardMutation}>
              {createPipeline => (
                <Button
                  onClick={() => {
                    createPipeline({
                      variables : {
                        name             : encodeURI(name),
                        pipeline_id,
                        value            : parseFloat(value),
                        description      : encodeURI(description),
                        visibility,
                        priority,
                        close_date,
                        owner_id         : owner.id,
                        primary_agent_id : get(suggestedUser, 'inventory_user.id'),
                      },
                    }).then(response => {
                      deleteSuggested({ variables: { id: suggestedUser.id } }).then(() => {
                        window.location = `/admin/pipelines/${pipeline_id}`;
                      });
                    });
                    this.handleClose();
                  }}
                  color="primary"
                  disabled={!(suggestedUser.selected_pipeline && name && description && priority && value)}
                  autoFocus>
                  Save
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddCaseDialog.propTypes = {
  suggestedUser               : PropTypes.object.isRequired,
  classes                     : PropTypes.object.isRequired,
  selectedPipeline            : PropTypes.string.isRequired,
  closeAddCaseDialog          : PropTypes.func.isRequired,
  updateSelectedSuggestedUser : PropTypes.func.isRequired,
  selectPipelineFunc          : PropTypes.func.isRequired,
  pipelineList                : PropTypes.object.isRequired,
  owner                       : PropTypes.object.isRequired,
  deleteSuggested             : PropTypes.func.isRequired,
};

const style = {
  dialog : {
    minWidth : 400,
  },
};

export default withStyles(style)(AddCaseDialog);
