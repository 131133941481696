import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import numeral from 'numeral';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import SurveyDialog from './SurveyDialog';
import ForwardToATSButton from './ForwardToATSButton';
import { S3Image } from '../../../lib/s3';

const styles = {
  drawerPaper : {
    width        : 450,
    position     : 'absolute',
    top          : 68,
    borderRadius : 4,
  },
  container   : {
    height      : '100%',
    borderRight : '1px solid #eee',
    padding     : 10,
  },
  header      : {
    height       : 64,
    borderBottom : '1px solid #eee',
  },
  title       : {
    padding : 4,
    float   : 'left',
  },
  close       : {
    float : 'right',
  },
  dataLeft    : { float: 'left', padding: 6 },
  dataRight   : { float: 'right', padding: 6 },
  data        : { padding: 4 },
  clear       : { clear: 'both' },
  buttonList  : { margin: '200px 0 0 0' },
};

const UserApplicationQuery = gql`
  query OpportunityQuery($userName: String) {
    getUser(userName: $userName) {
      id
      name
      phone
      birth_date
      assets_under_management
      number_of_clients
      years_practicing
      email
      annual_revenue
      new_in_industry
      is_linkedin
      candidate_profile {
        description
        family_background
        educational_background
        occupational_background
        professional_clubs
        areas_lived
      }
      oppapplications {
        opportunity {
          id
          title
        }
      }
    }
  }
`;

const EditOpportunityMutation = gql`
  mutation editCandidateApplication($id: ID!, $status: Int) {
    editCandidateApplication(id: $id, status: $status)
  }
`;

const RejectButton = ({ mutate, application }) => (
  <Button
    variant="contained"
    onClick={() => {
      mutate({
        variables : {
          id     : application.id,
          status : 3,
        },
      })
        .then(() => {
          window.location.reload();
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log(error);
          // eslint-disable-next-line
          alert('there was an error sending the query', error);
        });
    }}>
    Reject
  </Button>
);

RejectButton.propTypes = { mutate: PropTypes.func.isRequired, application: PropTypes.object.isRequired };

const GRejectButton = graphql(EditOpportunityMutation)(RejectButton);

const UserApplication = ({ application, close, classes }) => {
  if (isEmpty(application)) return <span />;
  return (
    <Drawer
      open
      variant="persistent"
      anchor="right"
      classes={{
        paper : classes.drawerPaper,
      }}>
      <Query query={UserApplicationQuery} variables={{ userName: application.user.user_name }}>
        {({ loading, error, data }) => {
          if (loading) return <span />;
          if (error) return `Error!: ${error}`;
          return (
            <div>
              <div className={classes.header}>
                <h3 className={classes.title}>
                  {application.user.name} &nbsp; &nbsp;{data.getUser.is_linkedin && (
                    <img alt="LinkedIn" src={S3Image('linked_in_icon.png')} />
                  )}
                </h3>
                <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={close}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.container}>
                <div className={classes.dataLeft}>
                  <div className={classes.dataLeft}>
                    <b>Phone </b>
                    {data.getUser.phone}
                  </div>
                  <div className={classes.dataLeft}>
                    <b>Email </b> {data.getUser.email}
                  </div>
                  <div className={classes.dataLeft}>
                    <b>Date of Birth </b>
                    {data.getUser.birth_date}
                  </div>
                  <div className={classes.dataLeft}>
                    <b>Years in Business </b>
                    {data.getUser.years_practicing}
                  </div>
                  {!data.getUser.new_in_industry && (
                    <div>
                      <div className={classes.dataLeft}>
                        <b>Revenue </b>
                        {numeral(data.getUser.annual_revenue).format('$0,0.00')}
                      </div>
                      <div className={classes.dataLeft}>
                        <b>Premium </b>
                        {numeral(data.getUser.premium).format('$0,0.00')}
                      </div>
                      <div className={classes.dataLeft}>
                        <b>Clients </b>
                        {numeral(data.getUser.number_of_clients).format('0,0')}
                      </div>
                      <div className={classes.dataLeft}>
                        <b>AUM/AUA</b> {numeral(data.getUser.assets_under_management).format('$0,0.00')}
                      </div>
                    </div>
                  )}
                  <br />
                  <div className={classes.clear}>
                    {data.getUser.new_in_industry && <SurveyDialog user={data.getUser} userName={data.getUser.name} />}
                  </div>
                  <hr />
                  <div>
                    <h4>Applied for the following requsitions</h4>
                    {data.getUser.oppapplications.map(a => <div>{a.opportunity.title}</div>)}
                  </div>
                  <hr />
                  <div>
                    <h3>Status: </h3>
                    <GRejectButton application={application} />
                    &nbsp;&nbsp;
                    <ForwardToATSButton application={application} user={data.getUser} />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    </Drawer>
  );
};

UserApplication.propTypes = {
  classes     : PropTypes.object.isRequired,
  application : PropTypes.object.isRequired,
  close       : PropTypes.func.isRequired,
};

export default withStyles(styles)(UserApplication);
