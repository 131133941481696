import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import { get, find } from 'lodash';
import moment from 'moment';

const handleMultiselectChange = (column, value, onFilterChange) => {
  onFilterChange(column, value);
};

const handleRangeChange = (column, value, index, currentValue, onFilterChange) => {
  let curVal;
  if (currentValue) {
    curVal = Array.from(currentValue);
  } else {
    curVal = [null, null];
  }
  curVal[index] = value;
  onFilterChange(column, curVal);
};

const renderFilters = (column, columns, filterList, classes, onClose, open, onFilterChange) => {
  switch (column.filterType) {
    case 'date':
      return (
        <div>
          <br />
          min:{' '}
          <input
            type={column.dataType}
            value={get(filterList, `['${column.id}'][0]`)}
            onChange={e => {
              handleRangeChange(column, e.target.value, 0, filterList[column.id], onFilterChange);
            }}
          />
          max:{' '}
          <input
            type={column.dataType}
            value={get(filterList, `['${column.id}'][1]`)}
            onChange={e => {
              handleRangeChange(column, e.target.value, 1, filterList[column.id], onFilterChange);
            }}
          />
        </div>
      );
    case 'range':
      return (
        <div>
          <br />
          min:{' '}
          <input
            type={column.dataType}
            value={get(filterList, `['${column.id}'][0]`)}
            onChange={e => {
              handleRangeChange(column, e.target.value, 0, filterList[column.id], onFilterChange);
            }}
          />
          max:{' '}
          <input
            type={column.dataType}
            value={get(filterList, `['${column.id}'][1]`)}
            onChange={e => {
              handleRangeChange(column, e.target.value, 1, filterList[column.id], onFilterChange);
            }}
          />
        </div>
      );
    case 'manager':
      return (
        <ManagerSearchField
          isMulti
          value={filterList ? filterList[column.id] : []}
          onChange={v => {
            handleMultiselectChange(column, v, onFilterChange);
          }}
        />
      );
    case 'text':
      return (
        <input
          value={String(get(filterList, `['${column.id}']`)).replaceAll('%', '')}
          onChange={e => {
            onFilterChange(column, `%${e.target.value.toLowerCase()}%`);
          }}
        />
      )
    default:
      return (
        <Select
          fullWidth
          multiple
          value={filterList ? filterList[column.id] : []}
          renderValue={selected => selected.map(s => find(column.filterOptions, { value: s }).name).join(', ')}
          name={column.name}
          onChange={event => handleMultiselectChange(column, event.target.value, onFilterChange)}
          input={<Input name={column.name} id={column.name} />}>
          {(column.filterOptions || []).map(({ name, value }, i) => (
            <MenuItem value={value} key={i + 1} fullWidth>
              <Checkbox
                checked={filterList && filterList[column.id].includes(value)}
                value={value}
                className={classes.checkboxIcon}
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      );
  }
};

const FBFilterDialog = ({ columns, filterList, classes, onClose, open, onFilterChange }) => {
  if (!open) return <span />;
  return (
    <Dialog open={open} maxWidth="xl" onBackdropClick={onClose}>
      <DialogTitle>Filter</DialogTitle>
      <DialogContent>
        <Grid className={classes.root}>
          {columns.map((column, index) => (
            <div className={classes.checkboxList}>
              {column.filter && (
                <span>
                  <div className={classes.title}>{column.label}</div>
                  <FormControl className={classes.selectFormControl} key={index}>
                    <InputLabel htmlFor={column.name}>{column.name}</InputLabel>
                    {renderFilters(column, columns, filterList, classes, onClose, open, onFilterChange)}
                  </FormControl>
                </span>
              )}
            </div>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export const styles = {
  root: {
    padding: '16px 24px 16px 24px',
    overflow: 'scroll',
    height: 'calc(80vh)',
  },
  header: {
    flex: '0 0 auto',
    marginBottom: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    display: 'block',
    color: '#424242',
    fontWeight: 'bold',
  },
  noMargin: {
    marginLeft: '0px',
  },
  /* checkbox */
  checkboxList: {
    flex: '1 1 100%',
    display: 'flex',
    marginRight: '24px',
  },
  checkboxListTitle: {
    marginLeft: '7px',
    marginBottom: '8px',
    color: '#424242',
    textAlign: 'left',
    fontWeight: 500,
  },
  checkboxFormGroup: {
    marginTop: '8px',
  },
  checkboxFormControl: {
    margin: '0px',
  },
  checkboxFormControlLabel: {
    marginLeft: '8px',
    color: '#4a4a4a',
  },
  checkboxIcon: {
    //color: "#027cb5",
    width: '32px',
    height: '32px',
  },
  checkbox: {
    '&$checked': {
      color: '#027cB5',
    },
  },
  checked: {},
  /* selects */
  selectRoot: {
    display: 'flex',
    marginTop: '16px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: '80%',
    justifyContent: 'space-between',
  },
  selectFormControl: {
    marginRight: '24px',
    marginBottom: '24px',
    minWidth: 340,
  },
};

export default withStyles(styles)(FBFilterDialog);
