import React from 'react';
import PropTypes from 'prop-types';
import Pipelines from '../components/Pipelines/Pipelines';
import { Query, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actions from '../actions/pipelineActionCreators';
import {
  PipelinesBoardQuery,
  updatePipelineCardMutation,
  updatePipelineOrderMutation,
} from '../components/Pipelines/PipelineQueries';
import { get, flowRight } from 'lodash';

class PipelineColumnViewContainer extends React.Component {
  state = {
    selectedCard              : {},
    status                    : 'open',
    agentName                 : '',
    column                    : 'name',
    columnDirection           : 'asc',
    ownerFilter               : '',
    filterByAgreementCheckbox : false,
  };

  toggleTable = id => {
    window.open(`/admin/pipelines/${id}/list`, '_self');
  };

  render () {
    const { match, pipeline, updatePipeline, page } = this.props;
    const { status, agentName, column, columnDirection, ownerFilter, filterByAgreementCheckbox } = this.state;
    return (
      <React.Fragment>
        <Query
          query={PipelinesBoardQuery}
          variables={{
            id                        : get(match, 'params.id'),
            status,
            agentName,
            column,
            columnDirection,
            ownerName                 : ownerFilter,
            filterByAgreementCheckbox,
          }}>
          {({ loading, error, data, refetch }) => {
            if (error) return `Error!: ${error}`;
            const matchID = get(this.props, 'match.params.id');
            if (!matchID || matchID === 'undefined') {
              return <Redirect to={`/admin/pipelines/${get(data, 'pipelines[0].id')}`} />;
            }
            if (!pipeline.id || match.params.id !== pipeline.id) {
              const pLine = get(data, 'pipeline');
              if (pLine) {
                const stages = get(pLine, 'pipeline_stages', []);
                let pipeline_cards = [];
                stages.map(s => {
                  pipeline_cards = pipeline_cards.concat(get(s, 'pipeline_cards', []));
                });
                this.setState(
                  {
                    pipelines : get(data, 'pipelines'),
                  },
                  () => updatePipeline({ ...pLine, page, pipeline_cards })
                );
              }
            }
            let inv_user = get(data, 'getCurrentUser.inventory_user', {});
            inv_user = {
              ...inv_user,
              label : inv_user.name,
              value : inv_user.id,
            };
            return (
              <Pipelines
                {...this.props}
                pipelineList={get(data, 'pipelines')}
                refetch={refetch}
                toggleTable={() => this.toggleTable(get(data, 'pipeline.id'), '')}
                currentInventoryUser={inv_user}
              />
            );
          }}
        </Query>
      </React.Fragment>
    );
  }
}

PipelineColumnViewContainer.propTypes = {
  classes            : PropTypes.object.isRequired,
  match              : PropTypes.object.isRequired,
  updatePipeline     : PropTypes.func.isRequired,
  updatePipelineCard : PropTypes.func.isRequired,
  pipeline           : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  pipeline       : state.pipeline,
  dragging       : get(state, 'pipeline.pipeline_state.dragging', false),
  pipeline_cards : get(state, 'pipeline.pipeline_cards'),
});

export default flowRight(
  graphql(updatePipelineCardMutation),
  graphql(updatePipelineOrderMutation, { name: 'mutateOrder' })
)(connect(mapStateToProps, actions)(PipelineColumnViewContainer));
