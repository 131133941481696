import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const ContinuingPayments = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');
  const continuingPaymentsFINRAFootnote = get(company, 'features', []).includes('continuing_payments_FINRA_footnote');
  const generateContinuingPaymentComponents = () => {
    let components = [];
    for (let i = query.buyerContinuingPaymentStartYear; i < query.buyerContinuingPaymentYears + query.buyerContinuingPaymentStartYear; i++) {
      components.push(
        <React.Fragment key={`cpYear${i}`}>
          <Grid item>
            &nbsp;&nbsp;
            <b className={classes.sub_heading}>Year {i} </b >
            <FBPercentInput
              upper={i > query.buyerContinuingPaymentStartYear ? query.buyerContinuingPayments[i - 1] * 100 : 100}
              value={query.buyerContinuingPayments[i]}
              onChange={val => {
                let payments = [...query.buyerContinuingPayments];
                payments[i] = val;
                updateCfaQuery({ ...query, buyerContinuingPayments: payments });
              }}
            />
          </Grid>
        </React.Fragment>
      );
    }
    return components;
  }


  return (
    <React.Fragment>
      <div>
        <>
          {query.buyerHasContinuingPayments && <React.Fragment>
            <div className={classes.header}>{showOrdinaryTaxes ? "Continuing Payments" : "Earn Out"}</div>
            <Grid container style={{ display: 'flex' }}>
              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.no_of_years" /></b >
                &nbsp;
                <select
                  value={query.buyerContinuingPaymentYears} onChange={e => {
                    // resize buyerContinuingPaymentAmount array
                    let buyerContinuingPayments = query.buyerContinuingPayments;
                    if (query.buyerContinuingPaymentYears > e.target.value) {
                      buyerContinuingPayments = buyerContinuingPayments.slice(0, e.target.value);
                    } else if (query.buyerContinuingPaymentYears < e.target.value) {
                      buyerContinuingPayments = [...buyerContinuingPayments, ...Array(e.target.value - query.buyerContinuingPaymentYears).fill(0)];
                    }
                    updateCfaQuery({ ...query, buyerContinuingPaymentYears: parseInt(e.target.value), buyerContinuingPayments, })
                  }
                  }>
                  <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                  {range(1, 6, 1).map(year =>
                    <option value={year} checked={query.buyerContinuingPaymentYears == year}>{year}</option>
                  )}
                </select>
              </Grid>

              <Grid item>&nbsp;
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.start_year" /></b >
                &nbsp;
                <select
                  value={query.buyerContinuingPaymentStartYear} onChange={e => {
                    updateCfaQuery({ ...query, buyerContinuingPaymentStartYear: parseInt(e.target.value) })
                  }
                  }>
                  <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                  {range(1, 6, 1).map(year =>
                    <option key={`year_${year}`} value={year} checked={query.buyerContinuingPaymentStartYear == year}>
                      {year}
                    </option>
                  )}
                </select>

              </Grid>

              {(Boolean(query.buyerContinuingPaymentStartYear) && query.buyerContinuingPaymentYears) && generateContinuingPaymentComponents()}
            </Grid>
          </React.Fragment>

          }

        </>
        <br />
        {continuingPaymentsFINRAFootnote && query.buyerHasContinuingPayments &&
          <FormattedMessage id="calculators.cfa.finraFootnote" />
        }
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(ContinuingPayments));