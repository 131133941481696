import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { ActivitiesQuery } from '../components/InventoryUsers/InventoryUserQueries';
import ActivityTab from '../components/InventoryUserDrawer/ActivityTab';
import ActivityInfoBoxSection from '../components/InventoryUserDrawer/ActivityInfoBoxSection';
import CircularProgress from '@material-ui/core/CircularProgress';

class ActivityTabContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      activityFilter : [ 
        'all', 
        'Notes',
        'Clicks',
        'Searches',
        'Message Sent',
        'Invites',
        'Closed',
      ], 
      invUserWithActivities: {},
      all            : true,
    };
  }

  selectActivity = ({ activity, refetch }) => {
    const { inventoryUser } = this.props;
    const { all } = this.state;

    if (activity.includes('All')) {
      if (all) {
        this.setState({ activityFilter: [], all: false });
      } else {
        this.setState({
          activityFilter : activity.includes('All') ? [ 'all' ] : activity,
          all            : true,
        });
      }
    } else {
      this.setState({ activityFilter: activity, all: false });
    }
    
    const index = activity.indexOf('all');
    if (index > -1) {
      activity.splice(index, 1);
    }

    refetch({activityFilter: activity, id: inventoryUser.id});
  };

  render () {
    const { inventoryUser, updateAction, currentUserIsAdmin, refetchList, hideInfoBox } = this.props;
    const { activityFilter, all, invUserWithActivities } = this.state;
    return (
      <div>
        <Query
          query={ActivitiesQuery}
          variables={{ id: inventoryUser.id, activityFilter }}
          fetchPolicy="network-only"
          onCompleted={data => {
              this.setState({ invUserWithActivities: data.inventoryUser })
            }
          }
        >
          {({ loading, error, data, refetch }) => {
            if (!loading && data.getInventoryUser) {
              return (
                <div>
                  {!hideInfoBox && <ActivityInfoBoxSection
                    inventoryUser={{...inventoryUser, ...invUserWithActivities}}
                    selectActivity={a => this.selectActivity({ activity: a, refetch: refetch })}
                    refetch={refetch}
                    activityFilter={activityFilter}
                    all={all}
                  />}
                  <ActivityTab
                    inventoryUser={data.getInventoryUser}
                    notes={data.adminNotesForUser.map(n => ({
                      ...n,
                      time : n.created_at,
                      name : 'note',
                    }))}
                    refetch={refetch}
                    currentUserId={data.currentUserId}
                    updateAction={updateAction}
                    currentUserIsAdmin={currentUserIsAdmin}
                    activityFilter={activityFilter}
                  />
                </div>
              );
            }
            return <div>
                     <ActivityInfoBoxSection
                       inventoryUser={inventoryUser}
                       selectActivity={a => this.selectActivity({ activity: a, refetch: refetch })}
                       refetch={refetch}
                       activityFilter={activityFilter}
                       all={all}
                     />
                    <div> 
                      <CircularProgress />
                    </div> 
                  </div>;
          }}
        </Query>
      </div>
    );
  }
}

ActivityTabContainer.propTypes = {
  inventoryUser : PropTypes.object,
  refetchList   : PropTypes.function,
  hideInfoBox   : PropTypes.bool,
};

ActivityTabContainer.defaultProps = {
  inventoryUser : {},
  refetchList   : () => {
    console.log('Missing refetchList');
  },
  hideInfoBox   : false,
};

export default ActivityTabContainer;
