import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  paper : {
    maxWidth  : 825,
    margin    : '40px auto 600px auto',
    padding   : 10,
    minHeight : 'calc(100vh - 300px);',
  },
};

const ThankYou = ({ classes }) => (
  <Paper className={classes.paper}>
    <h1>Thank you for your interest.</h1>
    Someone will review your application and get back to you. Feel free to apply to other oppportunities.
  </Paper>
);

ThankYou.propTypes = {
  classes : PropTypes.object.isRequired,
};

export default withStyles(styles)(ThankYou);
