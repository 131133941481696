import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import Rating from '../Rating/Rating';

const styles = {
  smallIcon : {
    width  : 28,
    height : 28,
  },
  small     : {
    width      : 30,
    height     : 30,
    padding    : '4px 4px 4px 4px',
    marginLeft : '-8px',
    color      : '#ffd700',
  },
  toolTip   : {
    padding : 12,
    margin  : 12,
  },
  rating    : {
    color : '#ff0000',
  },
  message   : {
    padding  : '4px',
    top      : '-4px',
    position : 'relative',
  },
  container : {
    margin     : '4px 0px 4px 0px',
    fontSize   : '14px',
    fontWeight : 'bold',
    color      : '#555',
  },
};

const rateUser = gql`
  mutation rateUser($userId: Int!, $score: Int!) {
    rateUser(userId: $userId, score: $score) {
      score
    }
  }
`;

class UserRating extends Component {
  constructor (props) {
    super(props);
    this.state = {
      score : props.props.user.my_rating,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange = value => {
    const { props, mutate } = this.props;
    const { user, updateBobCards, loadBobCardList, userId } = props;
    mutate({
      variables : { userId: parseInt(userId, 10), score: value },
    })
      .then(({ data }) => {
        updateBobCards({ ...user, my_rating: data.rateUser.score });
        loadBobCardList({ ...user, my_rating: data.rateUser.score });
        this.setState({ score: data.rateUser.score });
      })
      .catch(error => {
        console.log('there was an error sending the query', error);
      });

    return false;
  };

  componentWillReceiveProps = nextProps => {
    const { props } = this.props;
    const { user } = props;
    if (user.my_rating !== nextProps.props.user.my_rating) {
      this.setState({ score: nextProps.props.user.my_rating });
    }
  };

  render = () => {
    const { props } = this.props;
    const { marketplaceOnly } = props;
    const { score } = this.state;
    return (
      <div style={styles.container}>
        <div style={{ textAlign: `${marketplaceOnly ? 'center' : ''}` }}>
          <FormattedMessage id="bobcard.user_rating.rating_title" />
        </div>
        <div style={{ textAlign: `${marketplaceOnly ? 'center' : ''}` }}>
          <Rating
            value={score}
            max={5}
            onChange={this.onChange}
            itemStyle={styles.small}
            itemIconStyle={styles.smallIcon}
            rateMessage="Rate this user"
            messageStyle={styles.message}
          />
        </div>
      </div>
    );
  };
}

UserRating.propTypes = {
  props           : PropTypes.object.isRequired,
  user            : PropTypes.object.isRequired,
  userId          : PropTypes.number.isRequired,
  marketplaceOnly : PropTypes.bool.isRequired,
  mutate          : PropTypes.func.isRequired,
};

export default withStyles(styles)(graphql(rateUser)(UserRating));
