import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

export const EditEducationalContentMutation = gql`
  mutation editEducationalContent(
    $id: ID!
    $title: String
    $description: String
    $visible: Boolean
    $content_type: String
    $thumbnail_url: String
    $asset_url: String
    $embed_code: String
    $locale: String
  ) {
    editEducationalContent(
      id: $id
      title: $title
      description: $description
      visible: $visible
      content_type: $content_type
      thumbnail_url: $thumbnail_url
      asset_url: $asset_url
      embed_code: $embed_code
      locale: $locale
    )
  }
`;

class EditEducationalContent extends React.Component {
  constructor(props) {
    super(props);
    const { educationalContent } = this.props;
    this.state = {
      id: educationalContent.id,
      title: educationalContent.title,
      description: educationalContent.description,
      visible: educationalContent.visible,
      contentType: educationalContent.contentType,
      thumbnailUrl: educationalContent.thumbnailUrl,
      assetUrl: educationalContent.assetUrl,
      embedCode: educationalContent.embedCode,
      locale: educationalContent.locale,
    };
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const { educationalContent } = this.props;
    if (nextProps.educationalContent.id !== educationalContent.id) {
      this.setState({
        id: nextProps.educationalContent.id,
        title: nextProps.educationalContent.title,
        description: nextProps.educationalContent.description,
        visible: nextProps.educationalContent.visible,
        contentType: nextProps.educationalContent.contentType,
        thumbnailUrl: nextProps.educationalContent.thumbnailUrl,
        assetUrl: nextProps.educationalContent.assetUrl,
        embedCode: nextProps.educationalContent.embedCode,
        locale: nextProps.educationalContent.locale,
      });
    }
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const {
      mutate,
      refetch,
      openEdit,
      educationalContent,
      educationalContents,
      handleClickEdit,
      updateEducationalContent,
    } = this.props;
    const { id, title, description, visible, contentType, thumbnailUrl,
       assetUrl, embedCode, locale } = this.state;
    return (
      <div>
        <Dialog
          open={openEdit}
          onClose={() => handleClickEdit({})}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Edit Educational Content</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={e => {
                this.handleChange('title', e.target.value);
              }}>
              {title}
            </TextField>
            <TextField
              label="Description"
              fullWidth
              value={description}
              onChange={e => {
                this.handleChange('description', e.target.value);
              }}>
              {description}
            </TextField>
            <InputLabel id="demo-simple-select-label">Visible?</InputLabel>
            <Switch
              checked={visible}
              onChange={e => {
                this.handleChange('visible', e.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <InputLabel id="demo-simple-select-label">Content Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contentType}
              onChange={e => {
                this.handleChange('contentType', e.target.value);
              }}>
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="document">Document</MenuItem>
              <MenuItem value="iframe">iFrame</MenuItem>
            </Select>
            <TextField
              label="Thumbnail URL"
              fullWidth
              value={thumbnailUrl}
              onChange={e => {
                this.handleChange('thumbnailUrl', e.target.value);
              }}>
              {thumbnailUrl}
            </TextField>
            {contentType !== 'iframe' && (
              <TextField
                label="Asset URL"
                fullWidth
                value={assetUrl}
                onChange={e => {
                  this.handleChange('assetUrl', e.target.value);
                }}>
                {assetUrl}
              </TextField>
            )}
            {contentType !== 'document' &&
              contentType !== 'video' && (
                <TextField
                  label="Embed Code"
                  fullWidth
                  value={embedCode}
                  onChange={e => {
                    this.handleChange('embedCode', e.target.value);
                  }}>
                  {embedCode}
                </TextField>
              )}

            <InputLabel id="demo-simple-select-label">Locale</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={locale}
              onChange={e => {
                this.handleChange('locale', e.target.value);
              }}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fr">French</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClickEdit()} color="primary">
              Cancel
            </Button>
            <IconButton
              color="secondary"
              onClick={() => {
                mutate({
                  variables: {
                    id,
                    title,
                    description,
                    visible,
                    content_type: contentType,
                    thumbnail_url: thumbnailUrl,
                    asset_url: assetUrl,
                    embed_code: embedCode,
                  },
                }).then(response => {
                  if (response.data.editEducationalContent) {
                    updateEducationalContent({
                      ...educationalContents.find(e => e.id === educationalContent.id),
                      ...this.state,
                    });
                    refetch();
                  }
                  handleClickEdit();
                });
              }}>
              Save
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

EditEducationalContent.propTypes = {
  mutate: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  openEdit: PropTypes.bool,
  educationalContent: PropTypes.object,
  educationalContents: PropTypes.array,
  handleClickEdit: PropTypes.bool,
  updateEducationalContent: PropTypes.func.isRequired,
};

EditEducationalContent.defaultProps = {
  openEdit: false,
  handleClickEdit: false,
  educationalContent: {},
  educationalContents: [],
};

export default graphql(EditEducationalContentMutation)(EditEducationalContent);
