import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FreeToMessageButton from './FreeToMessageButton';
import SubscribeButton from './SubscribeButton';
import SaveButton from '../ProfileDrawerSideBarComponents/SaveButton';
import StatusSection from '../ProfileDrawerSideBarComponents/StatusSection';
import AskMeAbout from '../ProfileDrawerSideBarComponents/AskMeAbout';
import DesiredLocation from '../ProfileDrawerSideBarComponents/DesiredLocation';
import CollegeGraduationYear from '../ProfileDrawerSideBarComponents/CollegeGraduationYear';
import StudentAge from '../ProfileDrawerSideBarComponents/StudentAge';
import FamilyMemberFas from '../ProfileDrawerSideBarComponents/FamilyMemberFas';
import PlannedCertifications from '../ProfileDrawerSideBarComponents/PlannedCertifications';
import CollegeName from '../ProfileDrawerSideBarComponents/CollegeName';
import CollegeProgram from '../ProfileDrawerSideBarComponents/CollegeProgram';
import CollegeDegree from '../ProfileDrawerSideBarComponents/CollegeDegree';
import StartDealRoomButton from '../ProfileDrawerSideBarComponents/StartDealRoomButton';
import { get, isEmpty } from 'lodash';
import { canMessageUser } from '../../../lib/userUtils';

const FreeToMessage = ({
  scrollTo,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  displaySocialButtons,
  classes,
  updateNotes,
  notes,
  agentWordForCompany,
  obfuscateView,
  companyBusinessType,
  hideSideBarElements,
  company,
}) => {
  const showCreateDealButton =
    get(getCurrentUser, 'company.business_type') === 'wealth_rj' &&
    get(getCurrentUser, 'motivation') === 'succession' &&
    get(getCurrentUser, 'approval_state') === 'approved' &&
    get(getCurrentUser, 'can_match');
  const showRolodex =
    get(getCurrentUser, 'motivation') === 'succession' || get(getCurrentUser, 'motivation') === 'growth';
  const stylesheetName = get(getCurrentUser, 'company.stylesheet_name');
  const enabledRolodex = get(getCurrentUser, 'company.enabled_rolodex');
  const unsubscribed = !get(getCurrentUser, 'is_fb_subscriber');
  const hasDeal = get(getCurrentUser, 'has_deal');
  const dealRoomName = get(getCurrentUser, 'company.deal_room_branded_name');
  const bothUsersAreGrowth = canMessageUser(getCurrentUser, user, company);
  return (
    <div>
      <div className={classes.rightColumn}>
        {!hideSideBarElements && (
          <div>
            {showCreateDealButton &&
            !hasDeal && <StartDealRoomButton stylesheetName={stylesheetName} dealRoomName={dealRoomName} />}
            {showCreateDealButton &&
            hasDeal &&
            enabledRolodex && (
              <SaveButton
                user={user}
                userId={user.id}
                userName={user.fb_user_name}
                display={displaySocialButtons}
                noteCount={user.note_count}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                fromBobCard
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
                notes={notes}
                agentWordForCompany={agentWordForCompany}
              />
            )}
            {((companyHasNewMessages && companyHasNetworking && !unsubscribed) || bothUsersAreGrowth) && (
                <FreeToMessageButton
                  scrollTo={scrollTo}
                  disableMessageBtn={disableMessageBtn}
                  marketplaceOnly={marketplaceOnly}
                  stylesheetName={stylesheetName}
                />
              )}
            {unsubscribed && <SubscribeButton />}
            <br />
            <StatusSection
              showCreateDealButton={showCreateDealButton}
              companyHasNewMessages={companyHasNewMessages}
              companyHasNetworking={companyHasNetworking}
              user={user}
              obfuscateView={obfuscateView}
              currentUser={getCurrentUser}
              currentCompanySellerWord={currentCompanySellerWord}
              freeToMessage
              unsubscribed={unsubscribed}
              rolodexlMessage={showCreateDealButton && hasDeal && enabledRolodex}
              dealRoomName={dealRoomName}
            />
            <br />
            {enabledRolodex && (
              <SaveButton
                user={user}
                userId={user.id}
                userName={user.fb_user_name}
                display={showRolodex}
                noteCount={user.note_count}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                fromBobCard
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
                notes={notes}
                agentWordForCompany={agentWordForCompany}
              />
            )}
            <hr className={classes.bar} />
          </div>
        )}
        <StudentAge user={user} obfuscateView={obfuscateView} />
        <DesiredLocation user={user} obfuscateView={obfuscateView} />
        <CollegeGraduationYear user={user} obfuscateView={obfuscateView} />
        <CollegeDegree user={user} obfuscateView={obfuscateView} />
        <CollegeProgram user={user} obfuscateView={obfuscateView} />
        <CollegeName user={user} obfuscateView={obfuscateView} />
        <PlannedCertifications user={user} obfuscateView={obfuscateView} />
        <FamilyMemberFas user={user} obfuscateView={obfuscateView} />
        <AskMeAbout user={user} obfuscateView={obfuscateView} />         
      </div>
    </div>
  );
};

FreeToMessage.propTypes = {
  user                     : PropTypes.object.isRequired,
  obfuscateView            : PropTypes.bool.isRequired,
  companyHasNewMessages    : PropTypes.bool.isRequired,
  companyHasNetworking     : PropTypes.bool.isRequired,
  scrollTo                 : PropTypes.string.isRequired,
  updateBobCards           : PropTypes.func.isRequired,
  loadBobCardList          : PropTypes.func.isRequired,
  currentCompanySellerWord : PropTypes.bool.isRequired,
  getCurrentUser           : PropTypes.object.isRequired,
  disableMessageBtn        : PropTypes.bool.isRequired,
  marketplaceOnly          : PropTypes.bool.isRequired,
  displaySocialButtons     : PropTypes.bool.isRequired,
  classes                  : PropTypes.object.isRequired,
  updateNotes              : PropTypes.func.isRequired,
  notes                    : PropTypes.array.isRequired,
  agentWordForCompany      : PropTypes.string.isRequired,
  companyBusinessType      : PropTypes.string.isRequired,
  company                  : PropTypes.object.isRequired,
};

const styles = {
  rightColumn : {
    marginLeft  : 25,
    marginRight : '30%',
  },
  bar         : {
    width : 230,
  },
};

export default withStyles(styles)(FreeToMessage);
