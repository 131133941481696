import React from 'react';
import { Query, ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import PracticeInfo from '../components/PracticeInfo/PracticeInfo';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      number_of_clients
      avg_client_age
      annual_revenue
      assets_under_management
      recurring_revenue
      transition_period_months
      year_1_gross
      year_2_gross
      year_3_gross
      year_4_gross
      company {
        id
        business_type
      }
    }
  }
`;

const RevenueContainer = () => (
  <ApolloProvider client={client}>
    <Query query={getCurrentUser}>
      {({ loading, data }) => {
        if (loading) return <div />;
        if (data.getCurrentUser) {
          return <PracticeInfo currentUser={data.getCurrentUser} />;
        }
      }}
    </Query>
  </ApolloProvider>
);

export default RevenueContainer;
