import React from "react";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { Query } from "react-apollo";

import PipelineProjection from "../../components/Reports/PipelineProjection";

const PipelineReportQuery = gql`
  query pipeline($id: ID, $status: String, $agentName: String, $limit: String) {
    pipelines {
      id
      name
      pipeline_stages {
        id
      }
      pipeline_cards {
        id
      }
      card_count
    }
    pipeline(id: $id) {
      id
      name
      card_count
      pipeline_cards {
        id
        value
        close_date
        status
        created_at
      }
      pipeline_stages {
        id
        name
        card_count
        total_value
        pipeline_cards(status: $status, agentName: $agentName, limit: $limit) {
          id
          value
          close_date
          status
          created_at
        }
      }
    }
  }
`;

export const PipelineProjectionReportContainer = (props) => {
  return (
    <div>
      <div>
        <Query
          query={PipelineReportQuery}
          variables={{ agentName: "", status: "all", limit: "none" }}
        >
          {({ loading, error, data, refetch }) => {
            if (error) return `Error!: ${error}`;

            return (
              <PipelineProjection data={data} refetch={refetch} {...props} />
            );
          }}
        </Query>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.dateRangeFilter.sendStart.format("YYYY-MM-DD"),
  endDate: state.dateRangeFilter.sendEnd.format("YYYY-MM-DD"),
});

export default connect(mapStateToProps)(PipelineProjectionReportContainer);
