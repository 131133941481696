import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import RecentActiveUsersBox from '../InventoryUserDrawer/RecentActiveUsersBox';

const createInitials = name => {
  const splitName = name.split(' ').filter(Boolean);
  let initials = '';
  for (let i = 0; i < splitName.length; i++) {
    initials += splitName[i][0].toUpperCase();
  }
  return initials;
};

export const RecentActiveUsers = ({ recentActivities, classes }) => (
  <div>
    {recentActivities.map(u => (
      <RecentActiveUsersBox
        inventoryUser={u.inventory_user}
        firstName={u.name}
        initials={createInitials(u.name)}
        event={u.last_event}
        date={u.last_event.time}
        dashboard
      />
    ))}
  </div>
);

RecentActiveUsers.propTypes = {
  RecentActiveUsers : PropTypes.array,
  classes           : PropTypes.object.isRequired,
  refetch           : PropTypes.func.isRequired,
};

RecentActiveUsers.defaultProps = {
  RecentActiveUsers : [],
};

const styles = theme => ({
  box : {
    height : 10,
  },
});

export default withStyles(styles)(RecentActiveUsers);
