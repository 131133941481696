import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import InventoryUserSearchField from '../components/InventoryUserSearchField/InventoryUserSearchField';

const InventoryUserQuery = gql`
  query InventoryUserQueries($offset: Int, $limit: Int, $nameSearch: String) {
    getInventoryUsers(offset: $offset, limit: $limit, nameSearch: $nameSearch, allCustomers: true) {
      id
      name
      profile_picture
      has_user
    }
  }
`;

export const ManagerSearchFieldContainer = ({ isMulti, label, onChange, value }) => (
  <Query query={InventoryUserQuery} variables={{ offset: 0, limit: 10 }} fetchPolicy="network-only">
    {({ refetch }) => (
      <InventoryUserSearchField
        isMulti={isMulti}
        refetchList={refetch}
        label={label}
        onChange={onChange}
        value={value}
      />
    )}
  </Query>
);

ManagerSearchFieldContainer.propTypes = {
  isMulti  : PropTypes.bool,
  label    : PropTypes.string,
  onChange : PropTypes.func.isRequired,
  value    : PropTypes.string,
};

ManagerSearchFieldContainer.defaultProps = {
  isMulti : false,
  label   : '',
  value   : '',
};

export default ManagerSearchFieldContainer;
