import React from 'react';
import { Card, CardActionArea, CardContent, BottomNavigation, BottomNavigationAction, Avatar, Typography, IconButton, InputBase, Paper } from '@material-ui/core';
import { QuestionAnswer, Message, Home, Search, Help, ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';
import HelpSection from './HelpSection';
import ConversationSection from './ConversationSection';
// import ChatWidget from '../ChatWidget/ChatWidget';
import ChatWidget from '../../containers/ChatWidgetContainer';
import { injectIntl } from "react-intl";

const GET_CONVERSATIONS = gql`
  {
    company {
      id
      name
      help_url
      partial_sale_name
      __typename
    }
    getCurrentUser {
      id
      user_name
      name
      profile_picture
    }
    getMySupportRequests(page: 0) {
      id
      friendlyName
      __typename
      owner {
        id
        user_name
        name
        profile_picture
        __typename
      }
      status
      conversation {
        id
        conversationType
        __typename
        firstMessages(limit: 1) {
          id
          body
          createdAt
        }
        lastMessages(limit: 1) {
          id
          body
          createdAt
        }
      }
    }
    getPersonalConversations {
      id
      conversationType
      __typename
      lastMessages(limit: 1) {
        id
        body
        createdAt
        messageType
        read
        __typename
      }
      participant1 {
        id
        allowed_name
        user_name
        deleted
        hidden
        minimum_profile_completed
        __typename
      }
      participant2 {
        id
        allowed_name
        user_name
        deleted
        hidden
        minimum_profile_completed
        __typename
      }
      blockedByParticipant1
      blockedByParticipant2
    }
  }
`;

const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    position: 'fixed',
    bottom: 80,
    right: 20,
    zIndex: 1000,
  },
  container: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
    borderRadius: 10,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  message: {
    marginLeft: theme.spacing(2),
  },
  bottomNavigation: {
    backgroundColor: '#fff',
    borderRadius: '0 0 10px 10px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  cardContent: {
    minHeight: 600,
    padding: theme.spacing(2),
  },
  messageBox: {
    width: '365px',
    marginLeft: '-34px',
  },

}));

const titles = {
  'help': "Support Center",
  'messages': "Message Center",
  'chat_widget': "Chat Drawer"
};
const messages = {
  'help': "We're here to help. Start a support request or check up on an existing one",
  'messages': 'All of your messages in one place',
  'chat_widget': ''
};

const SupportDrawer = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('help');
  const [currentConvo, setCurrentConvo] = React.useState(null);
  const { data, loading, error, refetch } = useQuery(GET_CONVERSATIONS, { client: props.client });
  if(loading) return <span></span>;
  if(error) return <span>Error: {error.message}</span>;
  const profilePicture = get(data, 'getCurrentUser.profile_picture', '/static/images/avatar/1.jpg');
  const altIcon = get(data, 'getCurrentUser.name', 'User Avatar');
  const conversations = get(data, 'getPersonalConversations', []);
  const currentUser = get(data, 'getCurrentUser', {});
  const company = get(data, 'company', {});

  const changeConversation = (convoId) => {
    if (convoId == null) {
      setValue('messages');
    } else {
      setCurrentConvo(convoId);
      setValue('chat_widget');
    }
  };

  let chatWidgetTitle = '';
  if (currentConvo) {
    const convo = conversations.find(c => c.id === currentConvo);
    if (convo) {
      if (convo.conversationType === 'deal') {
        chatWidgetTitle = `Deal: ${get(convo, 'deal.deal_name', '')}`;
      } else if (convo.conversationType === 'deal_interest') {
        chatWidgetTitle = 'Deal Interest';
      } else if (convo.conversationType === 'partial_sale_listing') {
        chatWidgetTitle = `${get(currentUser, 'company.partial_sale_name', '')} Listing`;
      } else if (convo.conversationType === 'support_request') {
        chatWidgetTitle = `Support Request with ${get(convo, 'owner.name', '(Not assigned yet)')}`;
      } else {
        chatWidgetTitle = get(convo, 'participant1.allowed_name', '') || get(convo, 'participant1.user_name', '');
      }
    }
  }

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Avatar
            alt={altIcon}
            src={profilePicture}
            className={classes.avatar}
          />
          <Typography variant="body2" className={classes.message}>
            {titles[value]}<br />
            <span style={{ fontSize: '0.8em', color: '#ccc' }}>{messages[value]}</span>
          </Typography>
        </CardContent>
        <CardContent>
          <Paper className={classes.cardContent}>
            {value === "help" && <>
              <HelpSection
                company={company}
                client={props.client}
                supportRequests={get(data, 'getMySupportRequests', [])}
                changeConversation={changeConversation}
                refetch={refetch} />
            </>}
            {value === "messages" && <>
              <ConversationSection
                conversations={conversations}
                currentUser={currentUser}
                changeConversation={changeConversation} />
            </>}
            {value === "chat_widget" && currentConvo && <>
              <div className={classes.messageBox}>
                <ChevronLeft onClick={() => changeConversation(null)} style={{ position: 'relative', left: 38 }} />
                <ChatWidget
                  room_id={`${currentConvo}`}
                  currentUser={currentUser}
                  onNewMessage={() => {}}
                  title={chatWidgetTitle} />
              </div>
            </>}
          </Paper>
        </CardContent>
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={classes.bottomNavigation}
        >
          <BottomNavigationAction label={props.intl.formatMessage({id: "support_request.help"})} value="help" icon={<Help />} />
          <BottomNavigationAction label={props.intl.formatMessage({id: 'support_request.messages'})} value="messages" icon={<Message />} />
        </BottomNavigation>
      </Card>
    </div>
  );
};

export default injectIntl(SupportDrawer);
