import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import { withStyles } from '@material-ui/core/styles';
import { takeRight, get, sample } from 'lodash';
import numeral from 'numeral';

const financial_metric = opp => {
  const itemArray = [
    get(opp, 'user.user.annual_revenue', 0) > 0
      ? `${numeral(get(opp, 'user.user.annual_revenue', 0)).format('$ 0,0[.]00')} Revenue`
      : null,
    get(opp, 'user.life_ifp', 0) > 0
      ? `${numeral(get(opp, 'user.life_ifp', 0)).format('$ 0,0[.]00')} Life Premium`
      : null,
    get(opp, 'user.home_ifp', 0) > 0
      ? `${numeral(get(opp, 'user.home_ifp', 0)).format('$ 0,0[.]00')} Home Premium`
      : null,
    get(opp, 'user.auto_ifp', 0) > 0
      ? `${numeral(get(opp, 'user.auto_ifp', 0)).format('$ 0,0[.]00')} Auto Premium`
      : null,
    get(opp, 'user.mutual_funds_aum', 0) > 0 || get(opp, 'user.securities_aum', 0) > 0
      ? `${numeral(get(opp, 'user.mutual_funds_aum', 0) + get(opp, 'user.securities_aum', 0)).format('$ 0,0[.]00')} AUM`
      : null,
  ];
  return sample(itemArray.filter(obj => obj));
};

const clickOpportunity = opp => {
  window.open(`/opportunities/posting?id=${opp.id}`, '_self');
};

const OpportunityCarousel = ({ opportunities, classes }) => (
  <Carousel showThumbs={false} infiniteLoop interval={7000} autoPlay stopOnHover>
    {takeRight(opportunities, 3).map(o => (
      <div key={o.id} style={{ height: 700 }}>
        <div style={{ opacity: 0.3 }}>
          <img
            alt="Opportunity bgr img"
            src="https://media.gettyimages.com/photos/his-presentations-are-always-informative-picture-id887882750?s=2048x2048"
          />
        </div>
        <div className={classes.info}>
          <div className="tag price">{financial_metric(o)}</div>
          <h3>{o.title}</h3>
          <figure>{o.province}</figure>
          <hr />
          <button
            type="button"
            style={{ background: 'none', border: 'none' }}
            className="link-arrow"
            onClick={() => clickOpportunity(o)}>
            Read More
          </button>
        </div>
      </div>
    ))}
  </Carousel>
);

const styles = {
  info : {
    background : 'rgba(213, 216, 220, 0.8)',
    position   : 'absolute',
    left       : '30%',
    bottom     : 15,
    width      : 300,
    padding    : 10,
  },
};

OpportunityCarousel.propTypes = {
  classes       : PropTypes.object.isRequired,
  opportunities : PropTypes.array,
};

OpportunityCarousel.defaultProps = {
  opportunities : [],
};

export default withStyles(styles)(OpportunityCarousel);
