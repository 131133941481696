import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Query, Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { withSnackbar } from 'notistack';
import { get } from 'lodash';
import { Redirect } from 'react-router';
import MatchQuestion from './MatchQuestion';
import BackToContinuityButton from '../DealWizard/BackToContinuityButton';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import { Grid } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ConversationQuery } from '../../containers/ConversationContainer';

const updateMatchPreference = gql`
  mutation updateMatchPreference(
    $affiliation: Int
    $practice_similarity: Int
    $practice_structure: Int
    $servicing: Int
    $length_of_service: Int
    $length_of_service_min: Int
    $length_of_service_max: Int
    $aum: Int
    $aum_min: Float
    $aum_max: Float
    $avg_age_of_client: Int
    $avg_age_of_client_min: Float
    $avg_age_of_client_max: Float
    $proximity_max: Int
    $user_id: ID
    $affiliations: Int
  ) {
    updateMatchPreference(
      affiliation: $affiliation
      practice_similarity: $practice_similarity
      practice_structure: $practice_structure
      servicing: $servicing
      length_of_service: $length_of_service
      length_of_service_min: $length_of_service_min
      length_of_service_max: $length_of_service_max
      aum: $aum
      aum_min: $aum_min
      aum_max: $aum_max
      avg_age_of_client: $avg_age_of_client
      avg_age_of_client_min: $avg_age_of_client_min
      avg_age_of_client_max: $avg_age_of_client_max
      proximity_max: $proximity_max
      user_id: $user_id
      affiliations: $affiliations
    )
  }
`;

export const getUserNearbyCountQuery = gql`
  query getUserNearbyCount($proximityMax: Int) {
    getUserNearbyCount(proximityMax: $proximityMax)
  }
`;

const mapValueToSurvey = val => {
  if (val > 75) {
    return '100';
  }
  if (val < 25) {
    return '0';
  }
  return '50';
};

class MatchSurvey extends React.Component {
  constructor (props) {
    super(props);

    const matchPref = get(props, 'match_preference');

    this.state = {
      affiliation           : matchPref ? mapValueToSurvey(matchPref.affiliation_weight) : null,
      practice_similarity   : matchPref ? mapValueToSurvey(matchPref.product_mix_weight) : null,
      practice_structure    : matchPref ? mapValueToSurvey(matchPref.part_of_team_weight) : null,
      servicing             : matchPref ? mapValueToSurvey(matchPref.distance_weight) : null,
      length_of_service     : matchPref ? mapValueToSurvey(matchPref.length_of_service_weight) : null,
      aum                   : matchPref ? mapValueToSurvey(matchPref.aum_weight) : null,
      avg_age_of_client     : matchPref ? mapValueToSurvey(matchPref.avg_age_of_client_weight) : null,
      length_of_service_min : matchPref ? matchPref.length_of_service_min : null,
      length_of_service_max : matchPref ? matchPref.length_of_service_max : null,
      aum_min               : matchPref ? matchPref.aum_min : null,
      aum_max               : matchPref ? matchPref.aum_max : null,
      avg_age_of_client_min : matchPref ? matchPref.avg_age_of_client_min : null,
      avg_age_of_client_max : matchPref ? matchPref.avg_age_of_client_max : null,
      proximity_max         : matchPref ? matchPref.proximity_max : null,
      redirect              : null,
      loaded                : false,
      proximity_message     : '',
      fromProfile           : false,
      isUserIdNotEmpty      : props.isUserIdNotEmpty,
      affiliations          : matchPref ? mapValueToSurvey(matchPref.affiliate_network_weight) : null,
    };
  }

  handleCurrencyChange = (val, s) => {
    this.setState({ [val]: parseInt(s.replace('$', '').replaceAll(',', ''), 10) });
  };

  clickedMinAumArrow = direction => {
    const { aum_min } = this.state;
    if (direction === 'up') {
      this.setState({ aum_min: aum_min + 10000000 });
    } else if (direction === 'down') {
      this.setState({ aum_min: aum_min - 10000000 });
    }
  };

  clickedMaxAumArrow = direction => {
    const { aum_max } = this.state;
    if (direction === 'up') {
      this.setState({ aum_max: aum_max + 10000000 });
    } else if (direction === 'down') {
      this.setState({ aum_max: aum_max - 10000000 });
    }
  };

  updateUser = (updateMatchPref, updateAndNext = false) => {
    const { user, enqueueSnackbar, fromProfile, deal, intl, afterSaveRedirect } = this.props;
    const {
      affiliation,
      practice_similarity,
      practice_structure,
      servicing,
      redirect,
      loaded,
      length_of_service,
      length_of_service_min,
      length_of_service_max,
      aum,
      aum_max,
      aum_min,
      avg_age_of_client,
      avg_age_of_client_max,
      avg_age_of_client_min,
      proximity_max,
      proximity_message,
      affiliations,
    } = this.state;
    let variables = {
      affiliation           : Number(affiliation),
      practice_structure    : Number(practice_structure),
      practice_similarity   : Number(practice_similarity),
      servicing             : Number(servicing),
      length_of_service     : Number(length_of_service),
      length_of_service_min : Number(length_of_service_min),
      length_of_service_max : Number(length_of_service_max),
      aum                   : Number(aum),
      aum_min               : Number(aum_min),
      aum_max               : Number(aum_max),
      avg_age_of_client     : Number(avg_age_of_client),
      avg_age_of_client_min : Number(avg_age_of_client_min),
      avg_age_of_client_max : Number(avg_age_of_client_max),
      proximity_max         : Number(proximity_max),
      affiliations     : Number(affiliations),
    };
    if (user.id) {
      variables['user_id'] = user.id;
    }
    updateMatchPref({
      variables,
    }).then(() => {
      enqueueSnackbar(intl.formatMessage({id: 'dr_wizard.successfully_saved'}), {
        variant      : 'success',
        anchorOrigin : {
          vertical   : 'top',
          horizontal : 'center',
        },
      });
      //this.setState({ redirect: `/deal/${deal.deal.deal_name}/review` });
      if (fromProfile) {
        if (updateAndNext) {
          const currentPath = window.location.pathname.split('/').pop();
          const menuList = document.getElementById('edit_profile_path');
          const items = menuList.getElementsByTagName('li');
          let paths = [];
          for (var i = 0; i < items.length; ++i) {
            paths.push(items[i].querySelector('div a').href.split('/').pop());
          }
          window.location.href = `/profile/${paths[paths.indexOf(currentPath) + 1]}`;
        } else {
          window.location.href = `/users/${user.user_name}`;
        }
      } else {
        if (afterSaveRedirect) {
          window.location.href =  afterSaveRedirect;
        } else {
          window.location.href =  `/deal/${deal.deal.deal_name}/review`;
        }
      }
    });
  };

  render () {
    const { classes, deal, user, stylesheetName, hasAffiliation, fromProfile, intl, showContinuityButtons, } = this.props;
    const {
      affiliation,
      practice_similarity,
      practice_structure,
      servicing,
      redirect,
      loaded,
      length_of_service,
      length_of_service_min,
      length_of_service_max,
      aum,
      aum_max,
      aum_min,
      avg_age_of_client,
      avg_age_of_client_max,
      avg_age_of_client_min,
      proximity_max,
      proximity_message,
      affiliations,
    } = this.state;

    if (redirect) {
      return <Redirect push to={redirect} />;
    }
    if (window && !loaded) {
      this.setState({ loaded: true }, window.scrollTo(0, 0));
    }
    const nextButtonWord = fromProfile ? 'Update Profile' : 'Next';
    const allow_user_affiliations = get(user, 'company.allow_user_affiliations');

    return (
      <div className={classes.root}>
        <h2>
          <FormattedMessage id="dr_wizard.match_preferences" />
        </h2>
        <p className={classes.formControl}>
          <FormattedMessage id="dr_wizard.by_answering_the_following" />
        </p>
        <div className={classes.formControl}>
          <Query
            query={getUserNearbyCountQuery}
            variables={{ proximityMax: parseInt(proximity_max) }}
            onCompleted={data => {
              this.setState({ proximity_message: get(data, 'getUserNearbyCount') });
            }}>
            {({ data }) => (
              <div className={classes.questionText}>
                <FormLabel component="legend">
                  <FormattedMessage id="dr_wizard.what_is_the_maximum_distance" />
                </FormLabel>
                <input
                  value={proximity_max}
                  style={{ width: 'auto' }}
                  type="number"
                  onChange={e => {
                    let val = e.target.value.replace(/\D/g, '');
                    this.setState({ proximity_max: val });
                  }}
                />
                <FormattedMessage id="dr_wizard.miles" />
                <br />
                <div style={{ fontSize: 14, marginLeft: 4, color: parseInt(proximity_message, 10) < 1 && 'red' }}>
                  (<FormattedMessage id="dr_wizard.by_making_changes_to_this_criteria" />{' '}
                  <b>{proximity_message || '0'}</b> <FormattedMessage id="dr_wizard.people" />.)
                </div>
              </div>
            )}
          </Query>
        </div>
        {get(user, 'company.has_affiliation', false) ||
          (hasAffiliation && (
            <MatchQuestion
              selection={affiliation}
              handleChange={e => {
                this.setState({ affiliation: e.target.value });
              }}
              options={[
                { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
                { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
                { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
              ]}
              question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_the_practices_affiliation' })}`}
            />
          ))}
          {get(user, 'company.allow_user_affiliations', false) ||
            <MatchQuestion
              selection={affiliations}
              handleChange={e => {
                this.setState({ affiliations: e.target.value });
              }}
              options={[
                { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
                { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
                { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
              ]}
              question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_belonging' })}`}
            />
          }
        <MatchQuestion
          selection={practice_similarity}
          handleChange={e => {
            this.setState({ practice_similarity: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_similar_should_be' })}`}
        />
        <MatchQuestion
          selection={practice_structure}
          handleChange={e => {
            this.setState({ practice_structure: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_being_matched' })}`}
        />
        <MatchQuestion
          selection={servicing}
          handleChange={e => {
            this.setState({ servicing: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_servicing_similar' })}`}
        />
        <MatchQuestion
          selection={length_of_service}
          handleChange={e => {
            this.setState({ length_of_service: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_important_length_of_service' })}`}
        />
        {length_of_service !== '0' && (
          <div className={classes.rangeSection}>
            <FormattedMessage id="dr_wizard.min_number_of_years" />
            <input
              value={length_of_service_min}
              type="number"
              style={{ width: 'auto' }}
              onChange={e => {
                let val = e.target.value.replace(/\D/g, '');
                this.setState({ length_of_service_min: val });
              }}
            />
            &nbsp;<FormattedMessage id="dr_wizard.max_number_of_years" />
            <input
              value={length_of_service_max}
              style={{ width: 'auto' }}
              type="number"
              onChange={e => {
                let val = e.target.value.replace(/\D/g, '');
                this.setState({ length_of_service_max: val });
              }}
            />{' '}
            <br />
          </div>
        )}
        <MatchQuestion
          selection={aum}
          handleChange={e => {
            this.setState({ aum: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_similar_aum' })}`}
        />
        {aum !== '0' && (
          <div className={classes.rangeSection}>
            <Grid container>
              <div style={{ marginTop: 10 }}>
                <FormattedMessage id="dr_wizard.min_aum" />
              </div>
              <NumberFormat
                style={{ maxWidth: 250 }}
                value={aum_min}
                thousandSeparator={intl.locale == 'fr' ? ' ' : ','}
                decimalSeparator={intl.locale == 'fr' ? ',' : '.'}
                prefix={`${intl.formatMessage({ id: 'dr_wizard.prefix' })}`}
                suffix={`${intl.formatMessage({ id: 'dr_wizard.suffix' })}`}
                onChange={e => {
                  this.handleCurrencyChange('aum_min', e.target.value);
                }}
              />
              <div>
                <div className={classes.arrows} onClick={() => this.clickedMinAumArrow('up')}>
                  ▲
                </div>
                <div
                  className={`${classes.arrows} ${aum_min < 10000000 && classes.hide}`}
                  onClick={() => this.clickedMinAumArrow('down')}>
                  ▼
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                &nbsp;&nbsp;<FormattedMessage id="dr_wizard.max_aum" />
              </div>
              <NumberFormat
                style={{ maxWidth: 250 }}
                value={aum_max}
                thousandSeparator={intl.locale == 'fr' ? ' ' : ','}
                decimalSeparator={intl.locale == 'fr' ? ',' : '.'}
                prefix={`${intl.formatMessage({ id: 'dr_wizard.prefix' })}`}
                suffix={`${intl.formatMessage({ id: 'dr_wizard.suffix' })}`}
                onChange={e => {
                  this.handleCurrencyChange('aum_max', e.target.value);
                }}
              />
              <div>
                <div className={classes.arrows} onClick={() => this.clickedMaxAumArrow('up')}>
                  ▲
                </div>
                <div
                  className={`${classes.arrows} ${aum_max < 10000000 && classes.hide}`}
                  onClick={() => this.clickedMaxAumArrow('down')}>
                  ▼
                </div>
              </div>
            </Grid>
            <br />
          </div>
        )}
        <MatchQuestion
          selection={avg_age_of_client}
          handleChange={e => {
            this.setState({ avg_age_of_client: e.target.value });
          }}
          options={[
            { value: '0', label: intl.formatMessage({ id: 'dr_wizard.not_important' }) },
            { value: '50', label: intl.formatMessage({ id: 'dr_wizard.somewhat_important' }) },
            { value: '100', label: intl.formatMessage({ id: 'dr_wizard.very_important' }) },
          ]}
          question={`${intl.formatMessage({ id: 'dr_wizard.how_important_is_average_client_age' })}`}
        />
        {avg_age_of_client !== '0' && (
          <div className={classes.rangeSection}>
            <FormattedMessage id="dr_wizard.min_average_age" />
            <input
              value={avg_age_of_client_min}
              type="number"
              style={{ width: 'auto' }}
              onChange={e => {
                let val = e.target.value.replace(/\D/g, '');
                this.setState({ avg_age_of_client_min: val });
              }}
            />
            &nbsp;<FormattedMessage id="dr_wizard.max_average_age" />
            <input
              value={avg_age_of_client_max}
              style={{ width: 'auto' }}
              type="number"
              onChange={e => {
                let val = e.target.value.replace(/\D/g, '');
                this.setState({ avg_age_of_client_max: val });
              }}
            />{' '}
            <br />
          </div>
        )}
        <center style={{ margin: '20px auto' }}>
          <BackToContinuityButton deal={deal.deal} stylesheetName={stylesheetName} />
          <span>&nbsp;&nbsp;</span>
          <Mutation mutation={updateMatchPreference}>
            {updateMatchPref => (
              <div>
                {!fromProfile && (
                  <div>
                  {showContinuityButtons && (
                    <Button
                      variant="contained"
                      className={`${classes.button} ${stylesheetName === 'rjpe'
                        ? classes[`${stylesheetName}BackgroundColorAlt`]
                        : ''}`}
                      onClick={() => {
                        this.setState({ redirect: '/deal/questions' });
                      }}>
                      <FormattedMessage id="dr_wizard.back" />
                    </Button>
                  )}
                    <Button
                      className={`${classes.button} ${stylesheetName === 'rjpe'
                        ? classes[`${stylesheetName}BackgroundColor`]
                        : ''}`}
                        disabled={
                          affiliation === null ||
                          practice_structure === null ||
                          practice_similarity === null ||
                          servicing === null ||
                          affiliations === null ||
                          (parseInt(proximity_message) < 1 && window.location.href.indexOf('profile') < 0)
                        }
                      color="primary"
                      variant="contained"
                      onClick={() => this.updateUser(updateMatchPref)}>
                      <FormattedMessage id={`dr_wizard.${showContinuityButtons ? 'next' : 'save'}`} />
                    </Button>
                  </div>
                )}
                {fromProfile && (              
                  <div>               
                        <button
                          className={`btn ${stylesheetName === 'rjpe' ? 'btn-default' : 'btn-success'} center text-center`}
                          style={{ marginLeft: 20 }}
                          disabled={
                            affiliation === null ||
                            practice_structure === null ||
                            practice_similarity === null ||
                            servicing === null ||
                            affiliations === null ||
                            (parseInt(proximity_message) < 1 && window.location.href.indexOf('profile') < 0)
                          }
                          color="primary"
                          variant="contained"
                          onClick={() => this.updateUser(updateMatchPref, true)}>
                          <FormattedMessage id={`dr_wizard.${showContinuityButtons ? 'next' : 'save'}`} />
                        </button>
                        {showContinuityButtons && (
                          <div>
                        <button
                          onClick={() => (window.location.href = `/users/${user.user_name}`)}
                          className={`btn btn-secondary`}
                          style={{ padding: '12px 24px', marginLeft: 20 }}>
                          <FormattedMessage id="profile_page.cancel" />
                        </button>
                        </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
          </Mutation>
        </center>
      </div>
    );
  }
}

MatchSurvey.propTypes = {
  classes         : PropTypes.object.isRequired,
  enqueueSnackbar : PropTypes.object.isRequired,
  deal            : PropTypes.object,
  user            : PropTypes.object.isRequired,
  stylesheetName  : PropTypes.string.isRequired,
  hasAffiliation  : PropTypes.bool,
  fromProfile     : PropTypes.bool,
  showContinuityButtons : PropTypes.bool,
  afterSaveRedirect: PropTypes.string,
};

MatchSurvey.defaultProps = {
  fromProfile    : false,
  hasAffiliation : false,
  deal           : {},
  showContinuityButtons : true,
  afterSaveRedirect: null,
};

const styles = theme => ({
  root                   : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : '24px 12px',
  },
  arrows                 : {
    cursor : 'pointer',
  },
  hide                   : {
    display : 'none',
  },
  formControl            : {
    margin   : theme.spacing.unit * 3,
    fontSize : 18,
  },
  group                  : {
    margin        : `${theme.spacing.unit}px 0`,
    flexDirection : 'row',
  },
  rangeSection           : {
    margin : theme.spacing.unit * 3,
  },
  button                 : {
    margin  : '18px 8px',
    padding : 8,
  },
  questionText           : {
    color         : 'rgba(0, 0, 0, 0.54)',
    padding       : 0,
    fontSize      : '1rem',
    fontWeight    : '400',
    lineHeight    : '1',
    letterSpacing : '0.00938em',
  },
});

export default injectIntl(withStyles(styles)(withSnackbar(MatchSurvey)));
