import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import GmvPage from '../components/GmvPage/GmvPage';

const sacGrossMarketValueData = gql`
  query {
    sacGrossMarketValue
    sacNetMarketValue
    sacSusMarketValue
    sacNilMarketValue
  }
`;

const GmvPageValue = graphql(sacGrossMarketValueData)(GmvPage);

const GmvPageContainer = props => (
  <ApolloProvider client={client}>
    <GmvPageValue props={props} />
  </ApolloProvider>
);

export default GmvPageContainer;
