import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { trackAnalyticsEvent } from '../../lib/analytics';
import ActivityInfoBoxMini from './ActivityInfoBoxMini';
import AdminNotebookDrawerContainer from './AdminNotebookDrawerContainer';

class ActivityInfoBoxSection extends React.Component {
  activities = [
    'Notes',
    'Clicks',
    'Searches',
    'Message Sent',
    'Invites',
    'Closed',
  ];

  constructor(props) {
    super(props);
    this.state = {
      activityFilter: [
        'Notes',
        'Clicks',
        'Searches',
        'Message Sent',
        'Invites',
        'Closed',
      ],
      all: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { inventoryUser } = this.props;
    if (get(inventoryUser, 'id') !== get(nextProps, 'inventoryUser.id')) {
      this.setState({ ...nextProps.inventoryUser });
    }
  }

  onSave = (mutation, id, text, clear) => {
    const { refetch, inventoryUser } = this.props;
    mutation({
      variables: { id, text },
    })
      .then(() => {
        clear();
        refetch();

        trackAnalyticsEvent('notebook_note_made', {
          subjectUser: inventoryUser.id,
        });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  handleChange = event => {
    const { all } = this.state;
    console.log("Handle change");
    if (event.target.value.includes('All')) {
      if (all) {
        this.setState({ activityFilter: [], all: false });
      } else {
        this.setState({
          activityFilter: this.activities,
          all: true,
        });
      }
    } else {
      this.setState({ activityFilter: event.target.value, all: false });
    }
  };

  render() {
    const { classes, inventoryUser, selectActivity, activityFilter, all } = this.props;
    const user = get(inventoryUser, 'user');


    return (
      <div className={classes.container}>
        {user && (
          <div>
            Joined Marketplace {moment(user.created_at).format('M/DD/YYYY')}
          </div>
        )}
        {!user && <div> User has not joined the marketplace yet</div>}
        <div className={classes.cardgroup}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Sessions">
                {user ? user.number_of_sessions : '-'}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Last Session">
                {user && moment(user.last_session).isValid()
                  ? moment(user.last_session).format('M/DD/YYYY')
                  : 'N/A'}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Views">
                {user ? user.number_of_views : '-'}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Viewed">
                {user ? user.number_of_profiles_viewed : '-'}
              </ActivityInfoBoxMini>
            </Grid>
          </Grid>
        </div>
        <div className={classes.cardgroup}>
          <Grid container spacing={0}>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Searches">
                {get(user, 'number_of_searches', '-')}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Sent Msgs">
                {get(user, 'messages_sent', '-')}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Rec'd Msgs">
                {get(user, 'messages_received', '-')}
              </ActivityInfoBoxMini>
            </Grid>
            <Grid item xs={3}>
              <ActivityInfoBoxMini title="Following">
                {user ? user.following : '-'}
              </ActivityInfoBoxMini>
            </Grid>
          </Grid>
        </div>
        <br />
        <br />
        <AdminNotebookDrawerContainer
          id={get(inventoryUser, 'id')}
          onSave={this.onSave}
        />
        <FormControl className={classes.filterForm}>
          <Select
            multiple
            value={activityFilter}
            onChange={e => {
              selectActivity(e.target.value || []);
            }}
            input={<Input id="select-multiple-placeholder" />}
            renderValue={selected => {
              if (selected.length === 4) {
                return <em>All</em>;
              }
              if (selected.length === 0) {
                return <em>None Selected</em>;
              }
              return selected.join(', ');
            }}>
            <MenuItem key="All" value="All">
              <Checkbox checked={all} />
              <ListItemText primary="All" />
            </MenuItem>
            {this.activities.map(activity => (
              <MenuItem key={activity} value={activity}>
                <Checkbox
                  checked={all || activityFilter.indexOf(activity) > -1}
                />
                <ListItemText primary={activity} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
      </div>
    );
  }
}

ActivityInfoBoxSection.propTypes = {
  classes: PropTypes.object.isRequired,
  inventoryUser: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  selectActivity: PropTypes.func.isRequired,
};

const styles = {
  button: {
    color: '#0052CC',
    background: 'none',
    border: 'none',
  },
  card: {
    height: 90,
    borderRadius: 0,
  },
  container: {
    height: '100%',
    backgroundColor: '#eeeeee',
    padding: 10,
  },
  cardgroup: {
    height: 100,
    marginTop: 30,
    marginBottom: 30,
  },
  title: {
    padding: 4,
    float: 'left',
  },
  close: {
    float: 'right',
  },
  date: {
    fontSize: 15,
    margin: 8,
  },
  noUser: {
    height: '150px',
    margin: 10,
  },
  noUserText: {
    margin: 10,
    fontSize: 20,
  },
  filter: {
    minWidth: 80,
    width: 'auto',
  },
  filterForm: {
    float: 'right',
  },
};

export default withStyles(styles)(injectIntl(ActivityInfoBoxSection));
