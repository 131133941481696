/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Backend from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import { get, findIndex, orderBy, reduce, isEmpty, startCase } from 'lodash';
import numeral from 'numeral';
import Fuse from 'fuse.js';
import qs from 'query-string';
import moment from 'moment';
import NewPipelineCardDialog from './NewPipelineCardDialog';
import PipelineStage from './PipelineStage';
import PipelineCard from './PipelineCard';
import PipelineCardDrawer from './PipelineCardDrawer';

export const PipelineCardQuery = gql`
  query pipelineCard($id: ID!) {
    getActivityTypes {
      id
      name
      iconUrl
      __typename
    }
    pipelineCard(id: $id) {
      id
      created_at
      id
      name
      ordinal
      description
      value
      close_date
      last_moved_date
      status
      priority
      visibility
      candidate {
        id
        name
        annual_revenue
        year_career_began
        years_practicing
        number_of_clients
        avg_client_age
        recurring_revenue
        percent_commission
        percent_fee_based
        candidate_goals
        email
        birth_date
        postal_code
        phone
        locale
        new_in_industry
        tag_list
        candidate_designations
        description
        candidate_profile {
          family_background
          educational_background
          occupational_background
          professional_clubs
          areas_lived
        }
      }
      partial_sale_listing {
        id
        number_of_clients
        assets_under_management
        fee_based_revenue
        trails_revenue
        direct_revenue
        commission_revenue
        conversations {
          id
          participant1 {
            id
            name
            __typename
          }
          participant2 {
            id
            name
            __typename
          }
          __typename
        }
        states
        __typename
      }
      continuity_builder {
        id
        __typename
        canIssuedSeal
        dealStructureForCatastrophicPlan
        businessContinuityPlan {
          id
          planSentAt
        }
        continuityBuilderSuccessors {
          inventoryUser {
            id
            name
            email
            __typename
          }
          ordinal
        }
      }
      successionReadiness {
        id
        score
        completionPercentage
        yearsFromRetirement
        completed
        readinessActivities {
          id
          key
          title
          text
          score
          completed
          category
          dueDate
          ctaTarget
          ctaText
          ctaType
        }
      }
      primary_agent {
        id
        __typename
        name
        email
        has_user
        profile_picture
        cat_plan_filename
        discounted_cash_flows {
          id
          name
          friendlyName
          createdAt
          author {
            id
            name
            __typename
          }
          __typename
        }
        successor1_name
        agreement_type_for_for_catastrophic_plan
        catastrophic_plan_contract_date
        company {
          id
          name
          business_type
          disable_deal_room_from_bcb
          file_import_disclaimer
          partial_sale_name
          feature_types
        }
        continuity_builder {
          id
          __typename
          completedFindPartnerStep
          completedFileAgreementStep
          searchedForPartner
          filedAgreementTimestamp
          continuityBuilderSuccessors {
            inventoryUser {
              id
              name
              email
              __typename
            }
            ordinal
          }
          getCeContinuity {
            id
            graded
            passed
            watched
          }
          getCeAgreementType {
            id
            graded
            passed
            watched
          }
          userGoal {
            id
            completed
            remaining_step_count
            business_continuity_plan {
              createdAt
              updatedAt
              numberOfTimesAccessed
            }
          }
          deal {
            id
            deal_name
            number_of_deal_interests
          }
          documents {
            id
            name
            download_url
            category
          }
          existingPartnerName
          existingPartnerEmail
          lastUpdated
        }
      }
      owner {
        id
        name
        has_user
        profile_picture
      }
      inventory_users {
        id
        name
        has_user
        profile_picture
        divisional_director_name
        asst_divisional_director1_name
        asst_divisional_director2_name
        documents {
          id
          name
          download_url
          uploaded_by {
            id
            name
            __typename
          }
          created_at
          category
          mime_type
          __typename
        }
        __typename
        user {
          id
          name
          branch_net_payout
          advisor_licenses
          __typename
          company {
            id
            feature_types
          }
          cash_flows {
            id
            name
            createdAt
            friendlyName
          }
        }
      }
      pipeline {
        id
        name
        category_for_task {
          id
          __typename
        }
        __typename
      }
      pipeline_stage {
        id
        name
        __typename
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
        __typename
      }
      deal_interest {
        id
        invitation_date
        proposal_letter
        disclosing_party_nda_sent_timestamp
        receiving_party_nda_sent_timestamp
        file_url
        conversation_id
        documents {
          id
          name
          download_url
          category
          uploaded_by {
            name
          }
          created_at
          mime_type
          __typename
        }
        deal_scheduled_meetings {
          id
          format
          start_time
          duration
        }
        deal {
          id
          deal_name
        }
      }
      pipeline_activities {
        id
        interaction
        activityType {
          id
          name
          iconUrl
        }
        note
        activityDate
        inventoryUser {
          id
          name
          __typename
        }
      }
    }
    getCurrentUser {
      id
      inventory_user {
        id
        name
        id
        __typename
      }
    }
  }
`;

class Board extends React.Component {
  fuseOptions = {
    keys : [ 'name', 'owner.name', 'inventory_users.name' ],
  };

  colorForStatus = {
    open      : '#006BDB',
    won       : '#88B001',
    lost      : '#CA2D21',
    abandoned : '#D8D901',
    null      : 'white',
  };

  colorForCircle = {
    true  : '#1cb046',
    false : '#dadada',
    null  : 'white',
  };

  colorForBCBCircle = card => {
    if (get(card, 'continuity_builder.userGoal', false) && !card.continuity_builder.completedBusinessPlanStep) {
      return '#ffcc00';
    }
    if (card.continuity_builder.completedBusinessPlanStep) {
      return '#1cb046';
    }
    return 'white';
  };

  constructor (props) {
    super(props);
    this.state = { selectedCard: {} };
  }

  componentWillReceiveProps = nextProps => {
    const { pipeline, client, orderByAttribute, order } = this.props;
    const params = qs.parse(location.search);
    const { card_id } = params;
    if (isEmpty(pipeline.pipeline_cards) && !isEmpty(nextProps.pipeline.pipeline_cards)) {
      if (card_id) {
        this.selectCard({ id: card_id }, client);
        const currentUrl = window.location.href;
        window.history.pushState({}, document.title, `${currentUrl.substring(0, currentUrl.indexOf('?'))}`);
      }
    }
    if (orderByAttribute !== nextProps.orderByAttribute) {
      this.setState({ orderByAtt: nextProps.orderByAttribute });
    }
    if (order !== nextProps.order) {
      this.setState({ order: nextProps.order });
    }
  };

  update = (id, stage, columnIndex, oldStage) => {
    const { pipeline, updateCard, cardMutation, mutateOrder, updatePipeline } = this.props;
    const { pipeline_cards } = pipeline;

    const card = pipeline_cards.find(c => c.id === id);
    const oldCard = { ...card };
    if (isEmpty(oldCard)) {
      return;
    }

    card.pipeline_stage = stage;
    let cards = pipeline_cards.filter(c => get(c, 'pipeline_stage.id') === stage.id);
    const fromIndex = findIndex(cards, c => c.id === card.id);
    const f = cards.splice(fromIndex, 1)[0];
    cards.splice(columnIndex, 0, f);
    cards = cards.map((crd, i) => ({ ...crd, ordinal: i }));
    const cardVariables = {
      id                : card.id,
      pipeline_stage_id : stage.id,
    };

    if (get(oldCard, 'pipeline_stage.id') !== stage.id) {
      cardVariables.last_moved_date = moment().format('YYYY-MM-DD HH:mm:ss');
    }
    cardMutation({
      variables : cardVariables,
    })
      .then(() => {
        mutateOrder({
          variables : {
            id             : pipeline.id,
            pipeline_cards : cards.map(c => ({
              id      : c.id,
              ordinal : c.ordinal,
            })),
          },
        }).catch(e => {
          // eslint-disable-next-line
          console.log(e);
        });
      })
      .catch(e => {
        updateCard(oldCard);
        // eslint-disable-next-line
        console.log(e);
      });

    let newPipeline = pipeline;
    newPipeline.pipeline_stages.map(p => {
      if (p.id === oldStage.id) {
        p.card_count = p.card_count - 1;
      }
      if (p.id === stage.id) {
        p.card_count = p.card_count + 1;
      }
    });
    updatePipeline(newPipeline);

    updateCard(card);
    cards.map(c => updateCard(c));
  };

  selectCard = async (card, client) => {
    let result = {};
    if (card.id) {
      result = await client.query({
        query       : PipelineCardQuery,
        variables   : { id: card.id },
        fetchPolicy : 'network-only',
      });
    }
    this.setState({ selectedCard: get(result, 'data.pipelineCard', {}) });
  };

  deSelectCard = () => {
    this.setState({ selectedCard: {} });
  };

  render () {
    const { selectedCard } = this.state;
    const {
      classes,
      pipeline,
      changePipelineState,
      dragging,
      addPipelineCard,
      refetchPipelines,
      pipelineList,
      nameFilter,
      currentInventoryUser,
      client,
      toggleTable,
      disablePipelineFunctions,
      updateCard,
    } = this.props;
    let cards = get(pipeline, 'pipeline_cards', []);
    if (nameFilter) {
      const fuse = new Fuse(cards, this.fuseOptions);
      cards = fuse.search(nameFilter);
    }
    return (
      <DndProvider backend={Backend}>
        <main>
          <section className={classes.board}>
            <PipelineCardDrawer
              pipelineCard={selectedCard}
              onClose={this.deSelectCard}
              pipelineList={pipelineList}
              refetch={refetchPipelines}
              updateCard={(id, status, columnIndex) => {
                this.update(id, status, columnIndex);
              }}
              updateCardSingleCard={updateCard}
              pipeline={pipeline}
            />
            {orderBy(get(pipeline, 'pipeline_stages', []), [ 'ordinal' ], [ 'asc' ]).map((stage, stageNumber) => {
              const stageCards = cards.filter(card => get(card, 'pipeline_stage.id') === stage.id);
              const stageName = stage.name === 'Filed Agreement' && get(currentInventoryUser, 'company.business_type', '') === 'wealth_rj' ? 'Submitted Agreement' : decodeURI(stage.name);
              return (
                <PipelineStage key={`stage_${stage.id}`} status={stage.id}>
                  <div className={classes.column}>
                    <div className={classes.columnHead}>
                      <span className={classes.columnTitle}>
                        {stageName} ({stage.card_count}) <br />
                        <span className={classes.valueHeader}>{numeral(stage.stage_totals).format('$0,')}</span>
                      </span>
                      {stageNumber === 0 &&
                      !disablePipelineFunctions && (
                        <span className={classes.buttonContainer}>
                          <NewPipelineCardDialog
                            pipeline={pipeline}
                            addPipelineCard={addPipelineCard}
                            refetch={refetchPipelines}
                            currentInventoryUser={currentInventoryUser}
                          />
                        </span>
                      )}
                    </div>
                    <React.Fragment>
                      {stageCards.map((card, i) => (
                        <PipelineCard
                          id={card.id}
                          stage={stage}
                          columnIndex={i}
                          key={`card_${card.id}`}
                          changePipelineState={changePipelineState}
                          onDrop={(id, status, columnIndex) => {
                            this.update(id, status, columnIndex, stage);
                          }}
                          disablePipelineFunctions={disablePipelineFunctions}>
                          <div
                            id={`card_${card.id}`}
                            className={classes.item}
                            onClick={() => this.selectCard(card, client)}
                            role="button"
                            tabIndex={0}>
                            <div>
                              <h6 className={classes.cardHeader}>
                                <b>{decodeURI(card.name)}</b>
                              </h6>
                              <div
                                className={classes.status}
                                style={{
                                  color : this.colorForStatus[card.status],
                                }}>
                                {card.continuity_builder && (
                                  <div className={classes.right}>
                                    <span
                                      style={{
                                        color : this.colorForBCBCircle(card),
                                      }}>
                                      &#9679;
                                    </span>
                                    <span
                                      style={{
                                        color : this.colorForCircle[card.continuity_builder.completedFindPartnerStep],
                                      }}>
                                      &#9679;
                                    </span>
                                    <span
                                      style={{
                                        color : this.colorForCircle[card.continuity_builder.completedFileAgreementStep],
                                      }}>
                                      &#9679;
                                    </span>
                                  </div>
                                )}
                                <span className={classes.right}>{startCase(card.status)}</span>
                                {get(card, 'continuity_builder.filedAgreementTimestamp', false) && (
                                  <span className={classes.right} style={{ fontSize: 22 }}>
                                    <i class="fas fa-file-upload" />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div>
                              {[ get(card, 'primary_agent.name'), get(card, 'owner.name') ].filter(f => f).join(' | ')}
                            </div>
                            <div>Value: {numeral(card.value).format('$0,0')}</div>
                            <div>Close Date: {card.close_date}</div>
                          </div>
                        </PipelineCard>
                      ))}
                      {dragging && (
                        <div style={{ height: 'inherit' }}>
                          <PipelineCard stage={stage} columnIndex={stageCards.length} spacer>
                            <div className={classes.backSpacer} />
                          </PipelineCard>
                        </div>
                      )}
                    </React.Fragment>
                    {stageCards.length >= 10 && (
                      <div>
                        <div className={classes.cardCount}>
                          <span>
                            Showing {stageCards.length} out of {stage.card_count}
                          </span>
                        </div>
                        <div className={classes.cardCount}>
                          <button className={classes.button} type="button" onClick={() => toggleTable()}>
                            View All
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </PipelineStage>
              );
            })}
          </section>
        </main>
      </DndProvider>
    );
  }
}

Board.propTypes = {
  classes              : PropTypes.object.isRequired,
  currentInventoryUser : PropTypes.object.isRequired,
  pipeline             : PropTypes.object,
  changePipelineState  : PropTypes.func.isRequired,
  dragging             : PropTypes.oneOfType([ PropTypes.object, PropTypes.bool ]).isRequired,
  updateCard           : PropTypes.func.isRequired,
  addPipelineCard      : PropTypes.func.isRequired,
  refetchPipelines     : PropTypes.func.isRequired,
  cardMutation         : PropTypes.func.isRequired,
  mutateOrder          : PropTypes.func.isRequired,
  pipelineList         : PropTypes.array,
  nameFilter           : PropTypes.string,
  statusFilter         : PropTypes.string,
  userFilter           : PropTypes.string.isRequired,
  client               : PropTypes.object.isRequired,
  updatePipeline       : PropTypes.func.isRequired,
};

Board.defaultProps = {
  pipeline     : {},
  pipelineList : [],
  nameFilter   : '',
  statusFilter : '',
};

const styles = {
  board           : {
    display    : 'flex',
    margin     : '0 auto',
    width      : '100%',
    fontFamily : 'Arial, "Helvetica Neue", sans-serif',
    overflow   : 'scroll',
    height     : 'calc(100vh - 150px)',
  },
  column          : {
    minWidth     : 200,
    width        : '23vw',
    height       : 'inherit',
    minHeight    : '100%',
    margin       : '8px',
    borderRadius : '6px 6px 0 0',
    background   : '#fafafa',
  },
  columnHead      : {
    textAlign : 'left',
    padding   : '14px 14px 8px 18px',
    fontSize  : '1.4em',
    marginTop : 12,
    display   : 'flex',
    minHeight : 68,
  },
  columnTitle     : {
    width        : 252,
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    display      : 'inline-block',
    flex         : 1,
  },
  buttonContainer : {
    flex : 1,
  },
  item            : {
    padding  : 10,
    margin   : 10,
    fontSize : '0.8em',
    cursor   : 'pointer',
  },
  spacer          : {
    height          : 200,
    width           : '100%',
    backgroundImage : 'repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px)',
  },
  backSpacer      : {
    height          : 'calc(2 * 100vh)',
    width           : '100%',
    backgroundImage : 'repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px)',
  },
  cardHeader      : {
    marginTop    : 0,
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    textOverflow : 'ellipsis',
    maxWidth     : '80%',
  },
  valueHeader     : {
    fontSize : 14,
  },
  status          : {
    float    : 'right',
    position : 'relative',
    top      : -28,
  },
  cardCount       : {
    textAlign : 'center',
  },
  button          : {
    textAlign  : 'center',
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
    color      : 'rgb(0, 107, 219)',
  },
  right           : { clear: 'both', float: 'right' },
};

export default withStyles(styles)(Board);
