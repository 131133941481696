import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveButton from './ProfileDrawerSideBarComponents/SaveButton';
import ProfileDrawerStatusButtons from './ProfileDrawerSideBarComponents/ProfileDrawerStatusButtons';

const MobileProfileBottomBar = ({
  scrollTo,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  displaySocialButtons,
  updateNotes,
  notes,
  agentWordForCompany,
  classes,
}) => {
  return (
    <div className={classes.mobile}>
      <Grid container>
        <hr />
        <Grid xs={6} style={{ margin: 'auto' }}>
          <div>
            <SaveButton
              user={user}
              userId={user.id}
              userName={user.user_name}
              display={displaySocialButtons}
              noteCount={user.note_count}
              updateBobCards={updateBobCards}
              loadBobCardList={loadBobCardList}
              fromBobCard
              marketplaceOnly={marketplaceOnly}
              updateNotes={updateNotes}
              notes={notes}
              agentWordForCompany={agentWordForCompany}
            />
          </div>
        </Grid>
        <Grid xs={6}>
          {companyHasNewMessages &&
          companyHasNetworking && (
            <div style={{ marginBottom: 10, marginTop: 10 }}>
              <ProfileDrawerStatusButtons
                scrollTo={scrollTo}
                companyHasNewMessages={companyHasNewMessages}
                companyHasNetworking={companyHasNetworking}
                user={user}
                hasInterestedUsers={hasInterestedUsers}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                currentCompanySellerWord={currentCompanySellerWord}
                currentUserHasLimitedAccess={currentUserHasLimitedAccess}
                currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
                getCurrentUser={getCurrentUser}
                disableMessageBtn={disableMessageBtn}
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

MobileProfileBottomBar.propTypes = {
  user                                 : PropTypes.object.isRequired,
  classes                              : PropTypes.object.isRequired,
  companyHasNewMessages                : PropTypes.bool.isRequired,
  companyHasNetworking                 : PropTypes.bool.isRequired,
  scrollTo                             : PropTypes.string.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.bool.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.object.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  displaySocialButtons                 : PropTypes.bool.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  notes                                : PropTypes.array.isRequired,
  agentWordForCompany                  : PropTypes.string.isRequired,
};

const styles = {
  margin : {
    margin : 5,
  },
  mobile : {
    position   : 'fixed',
    left       : 0,
    bottom     : 0,
    width      : '100%',
    textAlign  : 'center',
    background : 'white',
    borderTop  : '1px #D5D8DC solid',
  },
};

export default withStyles(styles)(MobileProfileBottomBar);
