import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { debounce, get } from 'lodash';
import TextField from '@material-ui/core/TextField';
import CurrencyInput from 'react-currency-input-field';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import WarningText from '../WarningText/WarningText';
import numeral from 'numeral';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import {intlCurrencyConfig} from '../../lib/findBobUtils';
import { LiveTvOutlined } from '@material-ui/icons';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $fee_based_revenue: Float
    $trailer_revenue: Float
    $direct_revenue: Float
    $transactional_revenue: Float
    $recurring_revenue: Float
  ) {
    updateCurrentUser(
      fee_based_revenue: $fee_based_revenue
      trailer_revenue: $trailer_revenue
      direct_revenue: $direct_revenue
      transactional_revenue: $transactional_revenue
      recurring_revenue: $recurring_revenue
    )
  }
`;

class PeProductSummaryReport extends React.Component {
  updateUser = debounce(() => {
    const { mutate } = this.props;
    const { fee_based_revenue, trailer_revenue, direct_revenue, transactional_revenue, recurring_revenue } = this.state;
    if (this.numbersAreValid()) {
      mutate({
        variables : {
          fee_based_revenue,
          trailer_revenue,
          direct_revenue,
          transactional_revenue,
          recurring_revenue     : fee_based_revenue + trailer_revenue,
        },
      }).catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
    }
  }, 1000);

  numbersAreValid = () => {
    const { fee_based_revenue, trailer_revenue, direct_revenue, transactional_revenue } = this.state;
    if (
      fee_based_revenue <= 12000000 &&
      trailer_revenue <= 12000000 &&
      direct_revenue <= 12000000 &&
      transactional_revenue <= 12000000
    ) {
      return true;
    }
    return false;
  };

  constructor (props) {
    super(props);
    this.state = {
      fee_based_revenue     : props.currentUser.fee_based_revenue || 0,
      trailer_revenue       : props.currentUser.trailer_revenue || 0,
      direct_revenue        : props.currentUser.direct_revenue || 0,
      transactional_revenue : props.currentUser.transactional_revenue || 0,
    };
  }

  debouncedUpdate = debounce(() => {
    const { updateAction, saveToUser, updatePeProductSummary } = this.props;
    const { fee_based_revenue, trailer_revenue, direct_revenue, transactional_revenue } = this.state;
    const recRev = fee_based_revenue + trailer_revenue;
    const nonRecRev = transactional_revenue + direct_revenue;
    const total = recRev + nonRecRev;
    const feeTransTotal = fee_based_revenue + transactional_revenue;

    if (saveToUser) {
      this.updateUser();
    }
    updatePeProductSummary(fee_based_revenue, trailer_revenue, direct_revenue, transactional_revenue);
    updateAction({
      fee_based_revenue,
      trailer_revenue,
      direct_revenue,
      transactional_revenue,
      percent_revenue_recurring     : recRev / total,
      percent_non_recurring_revenue : nonRecRev / total,
      gross_production              : total,
      recurring_revenue             : recRev,
      percent_fee_based             : fee_based_revenue / feeTransTotal,
      precent_commission_based      : transactional_revenue / feeTransTotal,
      blah                          : total,
    });
  }, 1000);

  handleChange = (val, s) => {
    this.setState({ [val]: s }, () => this.debouncedUpdate());
  };

  handleCurrencyChange = (val, s) => {
    this.setState({ [val]: parseFloat(s.replace('$', '').replace(' ', '').replace(',', '', 10) || 0) }, () => this.debouncedUpdate());
  };

  render () {
    const { classes, alterItems, stylesheetName, profileView, disableRevenueSliders, intl, theme, currentUser} = this.props;
    const fee_based_revenue = parseFloat(this.state.fee_based_revenue || 0);
    const trailer_revenue = parseFloat(this.state.trailer_revenue || 0);
    const direct_revenue = parseFloat(this.state.direct_revenue || 0);
    const transactional_revenue = parseFloat(this.state.transactional_revenue || 0);
    const toolTipColor = stylesheetName === 'rjpe' ? '#002949' : '#1396e2';
    const hideDirectRevenue = get(currentUser.company, 'hide_direct_revenue');
    let trailsWords = get(currentUser, 'company.trails_word');
    if(intl.locale == 'fr') {
      trailsWords = 'Renouvellements';
    }
    const titleSize = () => {
      if (profileView) {
        return 9;
      }
      return disableRevenueSliders ? 7 : 12;
    };
    const sliderSize = profileView ? 9 : 7;
    const amountSize = profileView ? 3 : 5;
    if(intl.locale == 'fr') {
      numeral.localeData().delimiters.thousands = ' ';
    }
    return (
      <div style={{ marginTop: 50 }}>
        <Grid container spacing={1}>
          {!alterItems && (
            <Grid item xs={12}>
              <span className={classes.headline}>
                <FormattedMessage id="valuation_drawer.product_summary_report" />
              </span>
            </Grid>
          )}
          <Grid
            item
            xs={titleSize()}
            style={!alterItems || disableRevenueSliders ? { marginTop: 25 } : { marginTop: 0 }}>
            <span>
              <FormattedMessage id="valuation_page.fee_based_revenue" />{' '}
            </span>
          </Grid>
          <input type="hidden" name="user[updated_at]" value={0} />
          {!disableRevenueSliders && (
            <Grid item xs={sliderSize}>
              <RangeSliderContainer theme={theme}>
                <InputSlider
                  maxValue={12000000}
                  minValue={0}
                  value={fee_based_revenue || 0}
                  formatLabel={value => ``}
                  step={50000}
                  onChange={e => {
                    this.handleChange('fee_based_revenue', e);
                  }}
                />
              </RangeSliderContainer>
            </Grid>
          )}
          <Grid item xs={amountSize}>
            <CurrencyInput
              name="user[fee_based_revenue]"
              disabled={disableRevenueSliders}
              id="outlined-name"
              value={fee_based_revenue || 0}
              intlConfig={intlCurrencyConfig(intl)}
              allowDecimals={false}
              onValueChange={(value, name) => this.handleChange('fee_based_revenue', parseInt(value))}
            />
          </Grid>
          <WarningText
            visible={fee_based_revenue > 12000000}
            message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
          />
          <Grid item xs={titleSize()} style={disableRevenueSliders ? { marginTop: 25 } : { marginTop: 0 }}>
            <span>
              {trailsWords}{' '}           
            </span>
          </Grid>
          {!disableRevenueSliders && (
            <Grid item xs={sliderSize}>
              <RangeSliderContainer theme={theme}>
                <InputSlider
                  maxValue={12000000}
                  minValue={0}
                  value={trailer_revenue || 0}
                  formatLabel={value => ``}
                  step={50000}
                  onChange={e => {
                    this.handleChange('trailer_revenue', e);
                  }}
                />
              </RangeSliderContainer>
            </Grid>
          )}
          <Grid item xs={amountSize}>
            <CurrencyInput
              disabled={disableRevenueSliders}
              id="outlined-name"
              name="user[trailer_revenue]"
              value={trailer_revenue || 0}
              intlConfig={intlCurrencyConfig(intl)}
              allowDecimals={false}
              onValueChange={(value, name) => this.handleChange('trailer_revenue', parseInt(value))}
            />
          </Grid>
          <WarningText
            visible={trailer_revenue > 12000000}
            message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
          />
          {!hideDirectRevenue && (
            <React.Fragment>
              <Grid item xs={titleSize()} style={disableRevenueSliders ? { marginTop: 25 } : { marginTop: 0 }}>
                <span>
                  <FormattedMessage id="valuation_page.direct_revenue" />{' '}
                </span>
              </Grid>
              {!disableRevenueSliders&& (
                <Grid item xs={sliderSize}>
                  <RangeSliderContainer theme={theme}>
                    <InputSlider
                      maxValue={12000000}
                      minValue={0}
                      value={direct_revenue || 0}
                      formatLabel={value => ``}
                      step={50000}
                      onChange={e => {
                        this.handleChange('direct_revenue', e);
                      }}
                    />
                  </RangeSliderContainer>
                </Grid>
              )}
              <Grid item xs={amountSize}>
                <CurrencyInput
                  disabled={disableRevenueSliders}
                  id="outlined-name"
                  name="user[direct_revenue]"
                  value={direct_revenue || 0}
                  intlConfig={intlCurrencyConfig(intl)}
                  allowDecimals={false}
                  onValueChange={(value, name) => this.handleChange('direct_revenue', parseInt(value))}
                />
              </Grid>
              <WarningText
                visible={direct_revenue > 12000000}
                message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
              />
            </React.Fragment>
            )}
          <Grid item xs={titleSize()} style={disableRevenueSliders ? { marginTop: 25 } : { marginTop: 0 }}>
            <span>
              {alterItems ? (
                <FormattedMessage id="valuation_page.commission_revenue" />
              ) : (
                <FormattedMessage id="valuation_drawer.transactional_revenue" />
              )}{' '}
            </span>
            {(get(currentUser, 'company.insurance_commissions_information_tooltip') !== '' && intl.locale == 'en') && (
              <Tooltip
                classes={{ tooltip: classes.tooltiptext }}
                title={get(currentUser, 'company.insurance_commissions_information_tooltip')}
                placement="top">
                  <Button style={{ margin: -15 }}>
                    <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
                </Button>
             </Tooltip>)
          }
          </Grid>
          {!disableRevenueSliders && (
            <Grid item xs={sliderSize}>
              <RangeSliderContainer theme={theme}>
                <InputSlider
                  maxValue={12000000}
                  minValue={0}
                  value={transactional_revenue || 0}
                  formatLabel={value => ``}
                  step={50000}
                  onChange={e => {
                    this.handleChange('transactional_revenue', e);
                  }}
                />
              </RangeSliderContainer>
            </Grid>
          )} 
          <Grid item xs={amountSize}>
            <CurrencyInput
              disabled={disableRevenueSliders}
              id="outlined-name"
              name="user[transactional_revenue]"
              inputProps={{ tabIndex: '1' }}
              value={transactional_revenue || 0}
              intlConfig={intlCurrencyConfig(intl)}
              allowDecimals={false}
              onValueChange={(value, name) => this.handleChange('transactional_revenue', parseInt(value))}
            />
          </Grid>
          <WarningText
            visible={transactional_revenue > 12000000}
            message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
          />
          <Grid item xs={12}>
            <hr style={{ border: '0.5px solid black', margin: '10px 0 0 0' }} />
          </Grid>
          <Grid item xs={profileView ? 9 : 7}>
            <strong>
              <FormattedMessage id="valuation_drawer.total_production" />
            </strong>
          </Grid>
          <Grid item xs={profileView ? 3 : 5}>
            <strong style={{ marginLeft: 5, fontSize: 16 }}>
              {numeral(fee_based_revenue + trailer_revenue + direct_revenue + transactional_revenue).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}
            </strong>
          </Grid>
          <Grid item xs={12}>
            <hr style={{ border: '1.5px solid #002949', margin: '0 0 40px 0' }} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

PeProductSummaryReport.propTypes = {
  mutate                 : PropTypes.func.isRequired,
  currentUser            : PropTypes.object.isRequired,
  saveToUser             : PropTypes.bool,
  updatePeProductSummary : PropTypes.func,
};

PeProductSummaryReport.defaultProps = {
  updateAction           : () => {},
  disableRevenueSliders  : false,
  saveToUser             : false,
  updatePeProductSummary : () => {},
};

const styles = theme => ({
  toolTip     : {
    color : '#1396e2',
  },
  tooltiptext : {
    fontSize : 16,
  },
  headline    : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${props.theme.palette.accent.main} !important;
        border: 1px solid ${props.theme.palette.accent.main} !important;
      }

      .input-range__track--active {
        background: ${props.theme.palette.accent.main};
      }
    `};
`;

export default withTheme(withStyles(styles)(injectIntl(graphql(UpdateCurrentUser)(PeProductSummaryReport))));
