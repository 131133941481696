import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {get} from 'lodash';
import {Paper} from "@material-ui/core";

const CfaDisclaimer = ({classes, cfaType, updateCfaType, updateStep, step, intl, company}) => {

  return (<Paper className={classes.content}>
    <div className="wrapper">
      <div>
          <h2><FormattedMessage id="calculators.cfa.tool_disclaimer"/></h2>
          <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'calculators.cfa.disclaimer_copy'}, {company_name: company.name})}} />
      </div>
      </div>
      <center>
        <Button
          variant="contained"
          onClick={() => {
            updateStep(step + 1);
          }}
          ><FormattedMessage id="calculators.cfa_wizard.generate" /></Button>
    </center>
 </Paper>);
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
  },
  buttonB: {
    margin: '18px 8px',
    padding: '48px 12px',
    width: 180,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    backgroundColor: '#fff',
    border: 'none',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : '#1396e2',
    width: 400,
  },
  icon                   : {
    fontSize : 20,
  },
  content: {
    margin: 4,
    padding: 8,
},
};

const mapStateToProps = (state, props) => {
  return {
      company: {...get(state, 'company', {})},
  };
};

export default connect(mapStateToProps)(injectIntl(withStyles(styles)(CfaDisclaimer)));