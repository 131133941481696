import React from "react";
import { get, map } from "lodash";
import moment from "moment";
import CalibrationSelect from "./CalibrationSelect";
import CalibrationCheck from "./CalibrationCheck";
import CalibrationUserToggle from "./CalibrationUserToggle";
import InventoryUserAvatar from "../InventoryUserAvatar/InventoryUserAvatar";
import {
  ifp_template,
  render_dollar_values,
} from "../InventoryUsers/InventoryUserColumns";
import { interestsChip } from "../InventoryUserDrawer/OverviewUserData";
import { regionForCountry } from "../../lib/findBobUtils";

const render_bool = (n, att) => {
  const val = get(n, att, null);
  if (val !== null) {
    return val === true ? "Yes" : "No";
  }
  return "-";
};

const renderSelect = (n, att, options, updateState, company) => (
  <Select value={n[att]}>
    {map(options, (o) => (
      <MenuItem value={o.value}>{o.name}</MenuItem>
    ))}
  </Select>
);

const checkDisabled = (n) => {
  const hasPvP = get(n, "calibration_session_inventory_user.manual_pvp_value");
  const hasRiskAndImpact =
    get(n, "calibration_session_inventory_user.risk_of_loss_num") !== "risk_none" &&
    get(n, "calibration_session_inventory_user.impact_of_loss_num") !== "impact_none";
  if (hasRiskAndImpact || hasPvP) {
    return false;
  }
  return true;
};

const sessionColumns = ({ updateState, refetch, disabled, session, company }) => {
  const cols = [
    {
      id: "calibration_toggle",
      dataType: "disableSort",
      disablePadding: true,
      label: <span>&nbsp;&nbsp;Completed</span>,
      filter: false,
      customStyle: {
        position: "sticky",
        marginLeft: 4,
        left: 0,
        zIndex: 10,
        background: "white",
      },
      renderMethod: (n) => {
        const dis = disabled || checkDisabled(n);
        return (
          <span className="userToggle">
            <CalibrationUserToggle
              disabled={dis}
              inventoryUser={n}
              session={session}
              refetch={refetch}
            />
          </span>
        );
      },
    },
    {
      id: "name",
      dataType: "string",
      disablePadding: false,
      label: "Name",
      filter: false,
      customStyle: {
        position: "sticky",
        left: 82,
        zIndex: 10,
        background: "white",
      },
      customWidth: 150,
      renderMethod: (inventoryUser) => (
        <span style={{ display: "inline-flex", alignItems: "center" }}>
          <span>
            <InventoryUserAvatar inventoryUser={inventoryUser} />
          </span>
          <span style={{ verticalAlign: "middle" }}>{inventoryUser.name}</span>
        </span>
      ),
    },
    {
      id: "key_asset",
      dataType: "string",
      disablePadding: false,
      label: "Key Asset?",
      filter: true,
      ransack_filter: "key_asset",
      filterOptions: [
        { name: "True", value: "true" },
        { name: "False", value: "false" },
      ],
      renderMethod: (n) => (
        <CalibrationCheck
          disabled={disabled}
          inventoryUser={n}
          fieldName="key_asset"
          refetch={refetch}
        />
      ),
    },
    {
      id: "calculated_risk_of_loss",
      dataType: "string",
      disablePadding: false,
      label: "Calculated Risk Of Loss",
      filter: false,
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={get(n, "calibration_session_inventory_user", {})}
          options={[
            { name: "None", value: "cal_risk_none" },
            { name: "Low", value: "cal_risk_low" },
            { name: "Medium", value: "cal_risk_medium" },
            { name: "High", value: "cal_risk_high" },
          ]}
          fieldName="calculatedRiskOfLoss"
          refetch={refetch}
          disabled
        />
      ),
    },
    {
      id: "manual_risk_of_loss",
      dataType: "string",
      disablePadding: false,
      label: "Manual Risk Of Loss",
      filter: false,
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={get(n, "calibration_session_inventory_user", {})}
          options={[
            { name: "None", value: "man_risk_none" },
            { name: "Low", value: "man_risk_low" },
            { name: "Medium", value: "man_risk_medium" },
            { name: "High", value: "man_risk_high" },
          ]}
          fieldName="manualRiskOfLoss"
          refetch={refetch}
        />
      ),
    },
    {
      id: "calculated_impact_of_loss",
      dataType: "string",
      disablePadding: false,
      label: "Calculated Impact Of Loss",
      filter: false,
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={get(n, "calibration_session_inventory_user", {})}
          options={[
            { name: "None", value: "cal_impact_none" },
            { name: "Low", value: "cal_impact_low" },
            { name: "Medium", value: "cal_impact_medium" },
            { name: "High", value: "cal_impact_high" },
          ]}
          fieldName="calculatedImpactOfLoss"
          refetch={refetch}
          disabled
        />
      ),
    },
    {
      id: "manual_impact_of_loss_num",
      dataType: "string",
      disablePadding: false,
      label: "Manual Impact Of Loss",
      filter: false,
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={get(n, "calibration_session_inventory_user", {})}
          options={[
            { name: "None", value: "man_impact_none" },
            { name: "Low", value: "man_impact_low" },
            { name: "Medium", value: "man_impact_medium" },
            { name: "High", value: "man_impact_high" },
          ]}
          fieldName="manualImpactOfLoss"
          refetch={refetch}
        />
      ),
    },
    {
      id: "manual_pvp_value",
      dataType: "string",
      disablePadding: false,
      label: "PvP Value",
      filter: true,
      ransack_filter: "pvp_value",
      filterOptions: [
        { name: "Risk", value: "0" },
        { name: "Inconsistent Player", value: "1" },
        { name: "Average Performer", value: "2" },
        { name: "Potential Gem", value: "3" },
        { name: "Solid Performer", value: "4" },
        { name: "Core Player", value: "5" },
        { name: "Future Star", value: "6" },
        { name: "Current Star", value: "7" },
        { name: "Rainmaker", value: "8" },
      ],
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={get(n, "calibration_session_inventory_user", {})}
          options={[
            { name: "Risk", value: "pvp_risk" },
            { name: "Inconsistent Player", value: "man_pvp_inconsistent_player" },
            { name: "Average Performer", value: "man_pvp_average_performer" },
            { name: "Potential Gem", value: "man_pvp_potential_gem" },
            { name: "Solid Performer", value: "man_pvp_solid_performer" },
            { name: "Core Player", value: "man_pvp_core_player" },
            { name: "Future Star", value: "man_pvp_future_star" },
            { name: "Current Star", value: "man_pvp_current_star" },
            { name: "Rainmaker", value: "man_pvp_rainmaker" },
          ]}
          fieldName="manualPvp"
          refetch={refetch}
          disabled={disabled || get(n, "candidate_session_completed")}
        />
      ),
    },
    {
      id: "reason_for_loss",
      dataType: "string",
      disablePadding: false,
      label: "Reason For Loss",
      filter: true,
      ransack_filter: "reason_for_loss",
      filterOptions: [
        { name: "None", value: "" },
        { name: "Retirement", value: "retirement" },
        { name: "Loss of License", value: "loss of license" },
        { name: "Disability", value: "disability" },
        { name: "Death", value: "death" },
      ],
      renderMethod: (n) => (
        <CalibrationSelect
          inventoryUser={n}
          options={[
            { name: "None", value: "" },
            { name: "Retirement", value: "retirement" },
            { name: "Loss of License", value: "loss of license" },
            { name: "Disability", value: "disability" },
            { name: "Death", value: "death" },
          ]}
          fieldName="reason_for_loss"
          refetch={refetch}
          disabled={disabled || get(n, "candidate_session_completed")}
        />
      ),
    },
    {
      id: "managed_by",
      dataType: "user",
      filterType: "manager",
      disablePadding: false,
      label: "Managed By",
      filter: true,
      ransack_filter: "managed_by_users_id",
      renderMethod: (n) => get(n, "manager.name", ""),
    },
    {
      id: "province",
      dataType: "string",
      disablePadding: false,
      label: "Region",
      filter: true,
      ransack_filter: "province",
      filterOptions: regionForCountry("all", true).map((c) => ({
        name: c,
        value: c,
      })),
    },
    {
      ...ifp_template,
      id: "avg_client_age",
      label: "Avg. Client Age",
      ransack_filter: "avg_client_age",
      renderMethod: (n) => n.avg_client_age || "-",
    },
    {
      ...ifp_template,
      id: "rev_yield",
      label: "Revenue",
      ransack_filter: "rev_yield",
      renderMethod: (n) => render_dollar_values(n, "rev_yield"),
    },
    {
      ...ifp_template,
      id: "recurring_revenue",
      label: "Recurring Revenue",
      ransack_filter: "recurring_revenue",
      renderMethod: (n) => render_dollar_values(n, "recurring_revenue"),
    },
    {
      ...ifp_template,
      id             : 'total_assets',
      label          : 'Total Assets',
      ransack_filter : 'total_assets',
      renderMethod   : n => render_dollar_values(n, 'total_assets'),
    },
    {
      id: "birth_date",
      dataType: "number",
      disablePadding: false,
      label: "Age",
      filter: true,
      ransack_filter: "birth_date",
      filterType: "date",
      reverseOrderBy: true,
      renderMethod: (n) => moment().diff(n.birth_date, "years") || "-",
    },
    {
      id: "start_date",
      dataType: "number",
      disablePadding: false,
      label: "Years with Company",
      filter: true,
      ransack_filter: "start_date",
      filterType: "date",
      reverseOrderBy: true,
      renderMethod: (n) => moment().diff(n.start_date, "years") || "-",
    },
    {
      id: "transition_goals",
      dataType: "number",
      disablePadding: false,
      label: "Transition Goals",
      filter: true,
      ransack_filter: "transition_goals_name",
      filterOptions: [
        { name: "Buying", value: "Buying" },
        { name: "Merging", value: "Merging" },
        { name: "Partnering", value: "Partnering" },
        { name: "Business Continuity", value: "Business Continuity" },
        { name: "Find a Successor", value: "Find a Successor" },
        { name: "Become a Successor", value: "Become a Successor" },
        { name: "Selling my Practice", value: "Selling my practice" },
        { name: "Becoming a Junior Partner", value: "Becoming a Junior Partner" },
        {
          name: "Selling part of my practice",
          value: "Selling part of my practice",
        },
        { name: "Mentoring ", value: "Mentoring" },
        { name: "Being Mentored", value: "Being Mentored" },
      ],
      renderMethod: (n) =>
        (n.transition_goals || []).map((t) => interestsChip(t, {})),
    },
    {
      ...ifp_template,
      id: "health_ifp",
      label: "Health ifp",
      ransack_filter: "health_ifp",
      renderMethod: (n) => render_dollar_values(n, "health_ifp"),
    },
    {
      ...ifp_template,
      id: "life_ifp",
      label: "Life ifp",
      ransack_filter: "life_ifp",
      renderMethod: (n) => render_dollar_values(n, "life_ifp"),
    },
    {
      ...ifp_template,
      id: "group_ifp",
      label: "Group ifp",
      ransack_filter: "group_ifp",
      renderMethod: (n) => render_dollar_values(n, "group_ifp"),
    },
    {
      ...ifp_template,
      id: "mutual_funds_aum",
      label: "Mutual Funds (aum)",
      ransack_filter: "mutual_funds_aum",
      renderMethod: (n) => render_dollar_values(n, "mutual_funds_aum"),
    },
    {
      ...ifp_template,
      id: "securities_aum",
      label: "Securities (aum)",
      ransack_filter: "securities_aum",
      renderMethod: (n) => render_dollar_values(n, "securities_aum"),
    },
    {
      ...ifp_template,
      id: "annuities_aua",
      label: "Annuities (aua)",
      ransack_filter: "annuities_aua",
      renderMethod: (n) => render_dollar_values(n, "annuities_aua"),
    },
    {
      ...ifp_template,
      id: "segregated_funds_aua",
      label: "Segregated Funds (aua)",
      ransack_filter: "segregated_funds_aua",
      renderMethod: (n) => render_dollar_values(n, "segregated_funds_aua"),
    },
    {
      ...ifp_template,
      id: "mcpi_ifp",
      label: "MCPI (ifp)",
      ransack_filter: "mcpi_ifp",
      renderMethod: (n) => render_dollar_values(n, "mcpi_ifp"),
    },
    {
      ...ifp_template,
      id: "private_products_ifp",
      label: "Private Products (ifp)",
      ransack_filter: "private_products_ifp",
      renderMethod: (n) => render_dollar_values(n, "private_products_ifp"),
    },
    {
      ...ifp_template,
      id: "crop_hail_ifp",
      label: "Crop Hail (ifp)",
      ransack_filter: "crop_hail_ifp",
      renderMethod: (n) => render_dollar_values(n, "crop_hail_ifp"),
    },
    {
      ...ifp_template,
      id: "crop_hail_with_wind_ifp",
      label: "Crop Hail with Wind (ifp)",
      ransack_filter: "crop_hail_with_wind_ifp",
      renderMethod: (n) => render_dollar_values(n, "crop_hail_with_wind_ifp"),
    },
    {
      ...ifp_template,
      id: "price_products_ifp",
      label: "Price Products (ifp)",
      ransack_filter: "price_products_ifp",
      renderMethod: (n) => render_dollar_values(n, "price_products_ifp"),
    },
    {
      ...ifp_template,
      id: "replant_supplement_ifp",
      label: "Replant Supplement (ifp)",
      ransack_filter: "replant_supplement_ifp",
      renderMethod: (n) => render_dollar_values(n, "replant_supplement_ifp"),
    },
    {
      ...ifp_template,
      id: "farm_insurance_ifp",
      label: "Farm Insurance (ifp)",
      ransack_filter: "farm_insurance_ifp",
      renderMethod: (n) => render_dollar_values(n, "farm_insurance_ifp"),
    },
    {
      id             : 'region',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Region',
      filter         : false,
      ransack_filter : 'region',
      renderMethod   : n => n.region || '-',
    },
    {
      id             : 'division',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Division',
      filter         : false,
      ransack_filter : 'division',
      renderMethod   : n => n.division || '-',
    },
    {
      id             : 'branch',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch',
      filter         : false,
      ransack_filter : 'branch',
      renderMethod   : n => n.branch || '-',
    },
    {
      id             : 'industry_length_of_service',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Industry Length of Service',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'industry_length_of_service',
      renderMethod   : n => (n.industry_length_of_service ? `${n.industry_length_of_service} years` : '-'),
    },
  ];

  return cols;
};

export default sessionColumns;
