import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, filter, toLower, intersection, capitalize, slice } from 'lodash';
import TablePagination from '@material-ui/core/TablePagination';
import TalentThumbnail from '../TalentThumbnail/TalentThumbnail';
import InventoryUserDrawer from '../../InventoryUserDrawer/InventoryUserDrawer';

class TalentSelection extends Component {
  state = {
    page         : 0,
    itemsPerPage : 25,
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ itemsPerPage: event.target.value });
  };

  filterInventoryUsers = (inventoryUsers, userFilter) => {
    let filteredList = inventoryUsers;
    if (!isEmpty(userFilter.name)) {
      const name = toLower(userFilter.name);
      filteredList = filter(filteredList, u => toLower(u.name).indexOf(name) > -1);
    }
    if (!isEmpty(userFilter.province)) {
      const province = toLower(userFilter.province);
      filteredList = filter(filteredList, u => toLower(u.province).indexOf(province) > -1);
    }
    if (!isEmpty(userFilter.transition_goals)) {
      filteredList = filter(
        filteredList,
        u => intersection(u.transition_goals, userFilter.transition_goals).length > 0
      );
    }
    if (!isEmpty(userFilter.risk_of_loss)) {
      filteredList = filter(filteredList, u => userFilter.risk_of_loss.indexOf(capitalize(u.risk_of_loss)) > -1);
    }
    if (!isEmpty(userFilter.impact_of_loss)) {
      filteredList = filter(filteredList, u => userFilter.impact_of_loss.indexOf(capitalize(u.impact_of_loss)) > -1);
    }

    return filteredList;
  };

  render () {
    const { inventoryUsers, opportunity, userFilter, updateAction } = this.props;
    const { page, itemsPerPage } = this.state;
    const filteredList = this.filterInventoryUsers(inventoryUsers, userFilter);

    const rows = filteredList.length / itemsPerPage;

    return (
      <div>
        {slice(filteredList, page * itemsPerPage, page * itemsPerPage + itemsPerPage).map(u => (
          <TalentThumbnail user={u} updateAction={updateAction} />
        ))}
        <div style={{ clear: 'both' }}>
          <TablePagination
            rowsPerPageOptions={[ 25, 50 ]}
            rowsPerPage={itemsPerPage}
            colSpan={3}
            labelRowsPerPage="Items per page"
            count={rows}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelDisplayedRows={() => ''}
          />
        </div>
        <InventoryUserDrawer
          inventoryUser={opportunity.user}
          updateAction={updateAction}
          showSelectButton
          selectLink="details"
        />
      </div>
    );
  }
}

TalentSelection.propTypes = {
  inventoryUsers : PropTypes.array,
  opportunity    : PropTypes.object,
  userFilter     : PropTypes.func.isRequired,
  updateAction   : PropTypes.func.isRequired,
};

TalentSelection.defaultProps = {
  inventoryUsers : [],
  opportunity    : {},
};

export default TalentSelection;
