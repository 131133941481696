import { createMuiTheme } from '@material-ui/core/styles';

export const manulifeTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#00A758',
        },
        secondary: {
            main: '#0000C1',
        },
        accent: {
            main: '#00A758',
        },
        highlight: {
            main: '#282B3E',
        },
        graph: ['#00A758', '#0000C1', '#EC6453', '#282B3E', '#ec008c', '#68217a', '#00bcf2', '#00b294', '#00188f', '#ff8c00'],
    },
    shape: {
        borderRadius: 0,
    },
    props: {
        MuiButton: {
            size: 'small',
            color: 'primary',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
        MuiSlider: {
            color: 'primary',
        },
        MuiInputSlider: {
            color: 'primary',
        },
        InputSlider: {
            color: 'primary',
        },
    },
};

export default manulifeTheme;