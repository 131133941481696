import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { S3Image } from '../../lib/s3';

const ViewAProfile = ({ classes }) => {
  return (
    <div>
      <center>
        <Avatar name="default" src={S3Image('advisor.png')} className={classes.avatar} />
      </center>
      <br />
      <br />
      <span className="sub-title">View Your First Profile.</span>
      <p>You can view a suggested profile or discover one based on criteria you provide.</p>
      <br />
      <Button
        style={{ borderRadius: 0 }}
        className={classes.button}
        onClick={() => window.open(`/dashboard/tip_view_profile`, '_self')}
        variant="contained"
        color="primary">
        View a Profile &gt;&gt;
      </Button>
    </div>
  );
};

ViewAProfile.propTypes = {
  classes : PropTypes.object.isRequired,
};

const styles = () => ({
  avatar : {
    height : 125,
    width  : 125,
  },
  button : {
    float           : 'right',
    textTransform   : 'none',
    backgroundColor : '#1396e2',
  },
});

export default withStyles(styles)(ViewAProfile);
