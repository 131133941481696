import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {get} from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import {FormattedMessage, injectIntl} from "react-intl";

export const validate = () => {
    return true;
};

const Disclaimer = ({list, query, classes, updateCfaQuery, company, intl}) => {

    return <div>
        <h2><FormattedMessage id="calculators.cfa.tool_disclaimer"/></h2>
        <p dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'calculators.cfa.disclaimer_copy'}, {company_name: company.name})}} />
    </div>
};

Disclaimer.propTypes = {
    query: PropTypes.object,
    classes: PropTypes.object.isRequired,
};

Disclaimer.defaultProps = {
    query: {},
}

const styles = () => ({

});

const mapStateToProps = (state, props) => {
    return {
        result: {...get(state, 'cfa.result', {})},
        query: {...get(state, 'cfa.query', {})},
        company: {...get(state, 'company', {})},
    };
};

export default injectIntl(connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(Disclaimer)));