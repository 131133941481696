import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RecentActiveUsers from '../components/Analytics/RecentActiveUsers';

export const RecentActivityQuery = gql`
  query recentActivityQuery {
    recentActivityQuery {
      id
      name
      last_event {
        user_id
        name
        properties {
          tag
          href
          page
          viewed_user_id
          text
        }
        time
      }
      inventory_user {
        id
        profile_picture
        has_user
        name
      }
    }
  }
`;

const RecentActiveUsersContainer = () => (
  <Query query={RecentActivityQuery}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <CircularProgress />;
      if (error) return <div>{error.message}</div>;
      if (data.recentActivityQuery) {
        return <RecentActiveUsers recentActivities={data.recentActivityQuery} />;
      }
      return <div />;
    }}
  </Query>
);

export default RecentActiveUsersContainer;
