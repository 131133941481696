import React from 'react';
import PropTypes from 'prop-types';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import { FormattedMessage } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { IntlProvider, createIntlCache} from 'react-intl';
import translations from '../lib/i18n/translations.json';
import defaultLocale from '../lib/i18n/default.json';
import JssProvider from 'react-jss/lib/JssProvider';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NotificationDropDown from '../components/Notifications/NotificationDropDown';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS : true,
  productionPrefix        : 'fbNot',
});

export const getCurrentUser = gql`
  query getCurrentUser($after: String) {
    getCurrentUser {
      user_name
      open_notifications_count
      notifications(first: 10, after: $after) {
        edges {
          node {
            id
            action
            notifiableType
            createdAt
            readAt
            data
            notifiable {
              __typename
              ... on BusinessContinuityPlan {
                __typename
                id
              }
              ... on PersonalMessage {
                id
                __typename
                conversation {
                  id
                  conversationType
                }
              }
              ... on EducationalContent {
                __typename
                id
                title
              }
            }
            actor {
              allowed_name
              user_name
              profile_picture
            }
          }
          cursor
        }
        pageInfo {
          hasPreviousPage
          hasNextPage
          endCursor
          startCursor
        }
      }
    }
  }
`;
class NotificationsMenuContainer extends React.Component {
  state = { notifications: [], openCount: 0, after: null, loaded: false };

  render () {
    const jss = create(jssPreset());
    const { notifications, openCount, after, hasNextPage } = this.state;
    const { inMenu, color, theme_name } = this.props;
    let theme = themes[theme_name];
    setIntl();
    return (
      <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
        <ApolloProvider client={client}>
          <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbNot-">
            <MuiThemeProvider theme={createMuiTheme(theme)}>
              <CssBaseline />
              <Query
                query={getCurrentUser}
                variables={{ after }}
                fetchPolicy="no-cache"
                onCompleted={data => {
                  if (after !== null && !data.loadMore) return;
                  this.setState({
                    notifications : [
                      ...notifications,
                      ...get(data, 'getCurrentUser.notifications.edges', []).map(n => n.node),
                    ],
                    openCount     : get(data, 'getCurrentUser.open_notifications_count'),
                    after         : get(data, 'getCurrentUser.notifications.pageInfo.endCursor', ''),
                    hasNextPage   : get(data, 'getCurrentUser.notifications.pageInfo.hasNextPage', false),
                  });
                }}>
                {({ fetchMore }) => {
                  return (
                    <NotificationDropDown
                      inMenu={inMenu}
                      color={color}
                      notifications={notifications}
                      openCount={openCount}
                      updateCount={number => {
                        this.setState({ openCount: openCount - number });
                      }}
                      after={after}
                      fetchMore={fetchMore}
                      resetLoaded={s => {
                        this.setState({ ...s });
                      }}
                      hasNextPage={hasNextPage}
                    />
                  );
                }}
              </Query>
            </MuiThemeProvider>
          </JssProvider>
        </ApolloProvider>
      </IntlProvider>
    );
  }
}

NotificationsMenuContainer.propTypes = {
  inMenu : PropTypes.bool,
  color  : PropTypes.string,
};

NotificationsMenuContainer.defaultProps = {
  inMenu : false,
  color  : 'primary',
};

const NotificationsMenuContainerWithContext = (props, railsContext) =><NotificationsMenuContainer {...props} theme_name={railsContext.theme_name}/>

export default NotificationsMenuContainerWithContext;
