import PropTypes from 'prop-types';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField    : {},
  toggleButton : {
    margin : '12px',
  },
});

const ToggleButton = ({ id, checked, label, onChange, disabled, classes }) => (
  <FormControlLabel
    control={<Switch disabled={disabled} checked={checked} onChange={onChange} />}
    label={label}
    classes={{ root: classes.toggleButton, label: classes.textField }}
  />
);

export default withStyles(styles)(ToggleButton);
