import React from 'react';
import PropTypes from 'prop-types';
import CheckButton from '../../CheckButton/CheckButton';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($affiliation_ids: [ID!]) {
    updateCurrentUser(affiliation_ids: $affiliation_ids)
  }
`;

const toggleAffiliation = (user, affiliation, updateAction, mutate) => {
    const index = user.affiliations.findIndex(a => a.id === affiliation.id);
    if (index > -1) {
        user.affiliations.splice(index, 1);
    } else {
        user.affiliations.push(affiliation);
    }
    updateAction({...user.affiliations});

    mutate({
        variables : {
            affiliation_ids : user.affiliations.map(a => a.id),
        },
    })
        .then(response => {
            updateAction({ loading: false });
        })
        .catch(error => {
            console.log('there was an error sending the query', error);
        });

}


const AffiliationsSelectorComponent = ({availableAffiliations, userAffiliations, updateAction, user}) => (
    <div className="content">
    <b>
        Affiliations
    </b>
    <br />
    <Mutation mutation={UpdateCurrentUser}>
        {(mutate, { data }) => (
            <div>
                {availableAffiliations.map((affiliation) => (
                    <CheckButton
                        key={affiliation.id}
                        label={affiliation.name}
                        checked={userAffiliations.find(userAffiliation => userAffiliation.id === affiliation.id)}
                        value={affiliation}
                        onClick={() => toggleAffiliation(user, affiliation, updateAction, mutate)}
                    />
                ))}
            </div>
        )}
    </Mutation>
  </div>
);

AffiliationsSelectorComponent.propTypes = {
    availableAffiliations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    userAffiliations: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    updateAction: PropTypes.func.isRequired,
    user: PropTypes.object,
};

AffiliationsSelectorComponent.defaultProps = {
    user: {},
};

export default AffiliationsSelectorComponent;


