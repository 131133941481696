import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isEmpty, get, debounce } from 'lodash';
import Fab from '@material-ui/core/Fab';
import CurrentPipelineActivityCard from '../Staff/CurrentPipelineActivityCard';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import numeral from 'numeral';
import qs from 'query-string';
import CurrencyInput from 'react-currency-input-field';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import defaultLocale from '../../lib/i18n/default.json';
import translations from '../../lib/i18n/translations.json';
import Select from '@material-ui/core/Select';
import { createIntlCache, injectIntl, IntlProvider, FormattedMessage} from 'react-intl';
import { updateIntl } from 'react-intl-redux';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

const StyledTableCell = withStyles({
  root : {
    borderBottom : 'none',
    padding      : '10px 16px 0px 16px',
  },
})(TableCell);

class LoansRepaymentCalculator extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      lender_id                  : props.calculator_props.lender.id,
      lender_logo_url            : props.calculator_props.lender.small_logo_url,
      lenders                    : props.calculator_props.lenders,
      loan_roi                   : props.calculator_props.loan_roi,
      loan_tenor                 : props.calculator_props.loan_tenor,
      loan_amortization          : props.calculator_props.loan_amortization,
      loan_amortizations_periods : props.calculator_props.loan_amortizations_periods,
      loan_amount                : props.calculator_props.loan_amount,
      loan_years                 : props.calculator_props.loan_years,
      emi                        : props.calculator_props.emi,
      minimum                    : props.calculator_props.minimum,
      maximum                    : props.calculator_props.maximum,
    };
    this.loanRepaymentCalcRef = React.createRef();
    this.recalculate = debounce(this.recalculate, 500);
  }

  componentDidMount = () => {
    const params = qs.parse(location.search);
    const { showCalc } = params;
    if (showCalc) {
      this.loanRepaymentCalcRef.current.scrollIntoView({ block: 'center' });
    }
  };

  handleCurrencyChange = (val, s) => {
    if(this.valid()) {
      this.setState({ [val]: s }, this.recalculate);
    } else {
      this.setState({ [val]: s });
    }
  };

  recalculate = () => {
    const { lender_id, lenders, loan_roi, loan_tenor, loan_amortization, loan_amount, loan_years, emi } = this.state;
    const { refetch } = this.props;
    refetch({id: lender_id, loanAmount: loan_amount, loanAmortization: loan_amortization})
  };

  changeValue = (name, v) => {
    this.setState({ [name]: v.target.value },
    this.recalculate);
  };

  valid = () => {
    const { data, intl } = this.props;
    const { loan_amount } = this.state;

    const minimum = get(data, 'lender.minimum_loan_amount', 0);
    const maximum = get(data, 'lender.maximum_loan_amount', 0);
    
    return loan_amount >= minimum && loan_amount <= maximum;
  };

  headerTable = intl => ({
    total_interest: intl.formatMessage({ id: 'bank_finacing_tool.interest' }),
    total_repayments: intl.formatMessage({ id: 'bank_finacing_tool.principal_repayment' }),
    loan_outstanding: intl.formatMessage({ id: 'bank_finacing_tool.loan_outstanding' }),
    loan_outstanding_percentage: intl.formatMessage({ id: 'bank_finacing_tool.total_loan_amount' }),
  });

  render () {
    const { classes, theme, data, intl } = this.props;
    const {
      lender_id,
      lender_logo_url,
      lenders,
      loan_roi,
      loan_tenor,
      loan_amortization,
      loan_amount,
      loan_years,
    } = this.state;
    const monthlySchedules = get(data, 'lender.calculate_loan.monthly_schedules', []);
    const emi = get(data, 'lender.calculate_loan.emi', 0);
    const roi = get(data, 'lender.calculate_loan.roi', 0);
    const minimum = get(data, 'lender.minimum_loan_amount', 0);
    const maximum = get(data, 'lender.maximum_loan_amount', 0);
    const loan_amortizations_periods = get(data, 'lender.amortization_periods');
    
    if(!loan_amortizations_periods){
      return <div/>
    }

    setIntl();
    let currencyConfig = { locale: 'en-US', currency: 'USD' };

    if(intl.locale == 'fr') {
      numeral.localeData().delimiters.thousands = ' ';
      currencyConfig = { locale: 'fr-CA', currency: 'CAD' };
    }

    return (
        <React.Fragment>
          <div ref={this.loanRepaymentCalcRef} className={(classes.card, 'content-card')}>
            <Grid container>
              <Grid xs={4}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label"><FormattedMessage id="bank_finacing_tool.lender_programs" /></InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lender_id}
                    onChange={v => this.changeValue('lender_id', v)}>
                    {(lenders || []).map(l => <MenuItem style={{ fontSize: 20 }} value={l.id}>{l.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid xs={6} />
              <Grid xs={2}>
                <img src={`${lender_logo_url}`} style={{ height: 50 }} />
              </Grid>
            </Grid>

            <hr />
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableBody>
                  <TableRow>
                    <StyledTableCell component="th" scope="row" style={{ borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                      <h3><FormattedMessage id="bank_finacing_tool.your_loan_amount" /></h3>
                    </StyledTableCell>
                    <StyledTableCell align="right" style={{ borderBottom: '1px solid rgba(0, 0, 0, .1)' }}>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <CurrencyInput
                            id="input-example"
                            style={{ width: 110 }}
                            value={loan_amount || 0}
                            intlConfig={currencyConfig}
                            allowDecimals={false}
                            onValueChange={(value, name) => this.handleCurrencyChange('loan_amount', parseInt(value))}
                          />
                          {!this.valid() && (
                            <div style={{ color: 'red', fontSize: 9 }}>
                              <FormattedMessage id="bank_finacing_tool.range_must_be_between" />{numeral(minimum).format(intl.formatMessage({ id: 'bank_finacing_tool.currency' }))} and{' '}
                              {numeral(maximum).format(intl.formatMessage({ id: 'bank_finacing_tool.currency' }))}
                            </div>
                          )}
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <b><FormattedMessage id="bank_finacing_tool.interest_rate" /></b>:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          {(roi * 100).toFixed(2)} %
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <b><FormattedMessage id="bank_finacing_tool.amortization_period" /></b>:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={loan_amortization}
                              onChange={v => this.changeValue('loan_amortization', v)}>
                              {(loan_amortizations_periods || []).map(p => (
                                <MenuItem style={{ fontSize: 12 }} value={p}>
                                  {p}<FormattedMessage id="bank_finacing_tool.months" />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      <b><FormattedMessage id="bank_finacing_tool.monthly_payment_amount" /></b>:
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          {numeral(emi).format(intl.formatMessage({ id: 'bank_finacing_tool.currency' }))}
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                
                </TableBody>
              </Table>
            </TableContainer>
          </div>
            <div className={`${classes.card} content-card`}>
              <Grid container className="my-properties">
                <TableContainer className={classes.scrollable}>
                  <Table className={`${classes.table} table`} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell component="th" scope="row">
                          <b><FormattedMessage id="bank_finacing_tool.year" /></b>
                        </StyledTableCell>
                        {monthlySchedules.map(schedule => (
                          <StyledTableCell component="th" scope="row">
                            <b>{new Date().getFullYear() + schedule.month / 12}</b>
                          </StyledTableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {['total_interest','total_repayments','loan_outstanding',].map(field => <TableRow>
                        <StyledTableCell scope="row">
                          {this.headerTable(intl)[field]}:
                        </StyledTableCell>
                        {monthlySchedules.map(schedule => (
                          <StyledTableCell scope="row">
                            {schedule[field] > 0.0 ? `${numeral(Math.round(parseFloat(schedule[field]))).format(intl.formatMessage({ id: 'bank_finacing_tool.currency_1' }))}` : intl.formatMessage({ id: 'bank_finacing_tool.currency_no' })}
                          </StyledTableCell>
                          ))}
                      </TableRow>)}
                      <TableRow>
                        <StyledTableCell scope="row">
                          <FormattedMessage id="bank_finacing_tool.of_total_loan_amount" />
                        </StyledTableCell>
                        {monthlySchedules.map(schedule => (
                          <StyledTableCell scope="row">
                            {schedule['loan_outstanding_percentage'] > 0.0 ? `${Math.round(parseFloat(schedule['loan_outstanding_percentage']) * 100) } %` : '0 %'}
                          </StyledTableCell>
                          ))}
                      </TableRow>
                      
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </div>
        </React.Fragment>
    );
  }
}

const styles = theme => ({
  root                   : {
    width     : '100%',
    marginTop : 5,
  },
  card                   : {
    margin     : '5px 10px 5px 20px',
    background : 'white',
  },
  contentButton          : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  viewAll                : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  rjpeBackgroundColor    : {
    backgroundColor: theme.palette.primary.main,
    border: `6px solid ${theme.palette.primary.main}`,
  },
  rjpeBackgroundColorAlt : {
    backgroundColor : '#5B5E5A',
  },
  rjpe                   : {
    border: `6px solid ${theme.palette.primary.main}`,
    margin    : '0px',
  },
  formControl            : {
    margin   : 3,
    minWidth : 120,
  },
  selectEmpty            : {
    marginTop : 3,
  },
  scrollable: {
    overflowX: 'scroll',
    width: 700,

  }
});

LoansRepaymentCalculator.propTypes = {
  owned_cards : PropTypes.array,
  classes     : PropTypes.object.isRequired,
};

LoansRepaymentCalculator.defaultProps = {
  owned_cards : [],
};

export default injectIntl(withStyles(styles)(LoansRepaymentCalculator));
