import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import AddCaseDialog from '../components/SuggestedUsersPanel/AddCaseDialog';

class SuggestionsModalContainer extends React.Component {
  selectReduxPipeline = pipeline_id => {
    const { suggestedUser, updateSuggestedUser, updateSelectedSuggestedUser } = this.props;
    updateSuggestedUser({ ...suggestedUser, selected_pipeline: pipeline_id });
    updateSelectedSuggestedUser({ ...suggestedUser, selected_pipeline: pipeline_id });
  };

  render () {
    const {
      suggestedUser,
      pipelineList,
      owner,
      deleteSuggested,
      closeAddCaseDialog,
      updateSuggestedUsersList,
      updateSuggestedUser,
      updateSelectedSuggestedUser,
    } = this.props;
    return (
      <AddCaseDialog
        suggestedUser={suggestedUser}
        pipelineList={pipelineList}
        owner={owner}
        deleteSuggested={deleteSuggested}
        closeAddCaseDialog={closeAddCaseDialog}
        updateSuggestedUsersList={updateSuggestedUsersList}
        updateSuggestedUser={updateSuggestedUser}
        updateSelectedSuggestedUser={updateSelectedSuggestedUser}
        selectedPipeline={get(suggestedUser, 'selected_pipeline', null)}
        selectPipelineFunc={e => {
          this.selectReduxPipeline(e);
        }}
      />
    );
  }
}

SuggestionsModalContainer.propTypes = {
  suggestedUser               : PropTypes.object.isRequired,
  closeAddCaseDialog          : PropTypes.func.isRequired,
  updateSelectedSuggestedUser : PropTypes.func.isRequired,
  pipelineList                : PropTypes.object.isRequired,
  owner                       : PropTypes.object.isRequired,
  deleteSuggested             : PropTypes.func.isRequired,
  updateSuggestedUser         : PropTypes.func.isRequired,
  updateSuggestedUsersList    : PropTypes.func.isRequired,
};

export default SuggestionsModalContainer;
