import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';
import { VictoryPie, VictoryTheme, VictoryLegend } from 'victory';
import { sum, keys, merge, values, get } from 'lodash';

const ProductMix = props => {
  const { itemsPerRow, width, intl, colorScale } = props;
  let total = 0;
  let availableProducts = [];
  const availableProductsObject = JSON.parse(props.availableProducts || '{}');

  availableProducts = merge(values(availableProductsObject)).flatten();
  if (availableProducts) {
    total = sum(availableProducts.map(p => props[p]));
    total;
  }
  return (
    <div className="revPie">
      <VictoryPie
        data={(availableProducts || []).map(product => ({ y: parseFloat(props[product]) / total * 100 }))}
        colorScale={colorScale}
        height={320}
        theme={VictoryTheme.material}
        style={{
          labels : { fontSize: 17 },
        }}
        innerRadius={80}
        labels={l => (get(l, `data[${l.index}].y`, 0) > 0.0 ? `${numeral(get(l, `data[${l.index}].y`, 0)).format('0')} %` : '')}
      />
      <VictoryLegend
        orientation="horizontal"
        itemsPerRow={2}
        height={300}
        width={width}
        colorScale={colorScale}
        data={(availableProducts || []).map(s => ({
          name   : intl.formatMessage({ id: `bobcard.user_details.${s.replace(/_ifp|_aua|_aum/, '')}` }),
          labels : { fontSize: 18 },
        }))}
      />
    </div>
  );
};

ProductMix.propTypes = {
  itemsPerRow       : PropTypes.number.isRequired,
  width             : PropTypes.number.isRequired,
  intl              : PropTypes.objectOf(PropTypes.string).isRequired,
  availableProducts : PropTypes.arrayOf(PropTypes.string).isRequired,
  colorScale        : PropTypes.array,
};

ProductMix.defaultProps = {
  colorScale : [
    '#337ab7',
    'rgb(0, 121, 107)',
    'silver',
    'rgb(25, 245, 157)',
    '#FF8A65',
    '#FFF176',
    '#AFB42B',
    '#BA68C8',
    '#BCAAA4',
    '#004D40',
  ],
};

export default injectIntl(ProductMix);
