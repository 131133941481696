import React from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import RangeSlider from './RangeSlider';

const FinancialDetails = ({ formData, handleChange, user, company }) => {

  const partialSalesPropertyCasualty = company.feature_types.includes('partial_sales_property_casualty');

  return (
    <div>
      <Box>
        <Typography variant="h6" gutterBottom>
          Financial Details
        </Typography>
        <RangeSlider
          label="Number Of Clients"
          name="numberOfClients"
          object={formData}
          fieldName="numberOfClients"
          onChange={v => handleChange(v)}
          max={user.number_of_clients}
          step={1}
          prefix=''
        />
        <RangeSlider
          label={partialSalesPropertyCasualty ? "Total Premium" : "Assets Under Management"}
          name="assetsUnderManagement"
          object={formData}
          fieldName="assetsUnderManagement"
          onChange={v => handleChange(v)}
          max={user.assets_under_management}
        />
        <RangeSlider
          label={partialSalesPropertyCasualty ? "Recurring Commission Revenue" : "Fee Based Revenue"}
          name="feeBasedRevenue"
          object={formData}
          fieldName="feeBasedRevenue"
          onChange={v => handleChange(v)}
          max={partialSalesPropertyCasualty ? user.recurring_revenue : user.fee_based_revenue}
        />
        {!partialSalesPropertyCasualty && (
          <RangeSlider
            label="Trails Revenue"
            name="trailsRevenue"
            object={formData}
            fieldName="trailerRevenue"
            onChange={v => handleChange(v)}
            max={user.trailer_revenue}
          />
        )}
        { !partialSalesPropertyCasualty && (company && !company.feature_types.includes('hide_direct_revenue')) && (
          <RangeSlider
            label="Direct Revenue"
            name="directRevenue"
            object={formData}
            fieldName="directRevenue"
            onChange={v => handleChange(v)}
            max={user.direct_revenue}
          />
        )}
        
        <RangeSlider
          label={partialSalesPropertyCasualty ? "New Commission Revenue" : "Commission Revenue"}
          name="commissionRevenue"
          object={formData}
          fieldName="commissionRevenue"
          onChange={v => handleChange(v)}
          max={partialSalesPropertyCasualty ? (user.annual_revenue - user.recurring_revenue) : user.transactional_revenue}
        />
      </Box>
    </div>
  );
};

export default FinancialDetails;
