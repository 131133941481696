import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const redirect = () => {
  window.location.href = `/subscriptions`;
};

const SubscribeButton = ({ disableMessageBtn, marketplaceOnly, scrollTo, classes }) => (
  <div>
    <Button variant="contained" onClick={() => redirect()} className={classes.button}>
      <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.subscribe_now" />
    </Button>
  </div>
);

SubscribeButton.propTypes = {
  classes           : PropTypes.object.isRequired,
  scrollTo          : PropTypes.func.isRequired,
  disableMessageBtn : PropTypes.bool.isRequired,
  marketplaceOnly   : PropTypes.bool.isRequired,
};

const styles = {
  button  : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default withStyles(styles)(SubscribeButton);
