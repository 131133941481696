import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/VerifiedUser';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { toggleCustomerForUsers } from './InventoryUserQueries';

class InventoryUserMakeManagerDialog extends React.Component {
  state = {
    open        : false,
    makeManager : true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleMakeManager = () => {
    const { makeManager } = this.state;
    this.setState({ makeManager: !makeManager });
  };

  render () {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, makeManager } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Are you sure you want to revoke manager privileges for these ${selected.length} users?`;
    let bodyText = 'These users will not be able to access admin pages like this one';
    let hiddenText = 'have manager privileges revoked';

    if (open) {
      const selections = selected.map(s => find(data, [ 'id', s ]));
      validSelections = filter(selections, [ 'has_user', true ]);
      invalidSelections = filter(selections, [ 'has_user', false ]);

      if (makeManager) {
        message = `Are you sure you want to grant manager privileges for these ${selected.length} users?`;
        bodyText = 'These users will be granted access admin pages like this one';
        hiddenText = 'be granted manager privileges';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Add/Remove Manager Privileges">
          <IconButton aria-label="Add/Remove Manager Privileges">
            <EditIcon onClick={this.handleClickOpen} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={makeManager} onClick={this.toggleMakeManager} /> Grant Manager Privileges
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot ${hiddenText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables : { usersToUpdate: validSelections.map(iu => iu.user.id), customer: makeManager },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserMakeManagerDialog.propTypes = {
  selected          : PropTypes.array.isRequired,
  changePage        : PropTypes.func.isRequired,
  data              : PropTypes.object.isRequired,
  mutate            : PropTypes.func.isRequired,
  loadBulkItemsList : PropTypes.func.isRequired,
};

export default graphql(toggleCustomerForUsers)(InventoryUserMakeManagerDialog);
