import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { capitalize } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

const Strengths = ({ strengths, availableStrengths, intl, obfuscated, companyBusinessType }) => {
  const labels = {};
  availableStrengths.map(s => {
    labels[s] = intl.formatMessage({ id: `sign_up_pages.${s}` });
  });
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <h3>{companyBusinessType === 'wealth_rj' ? <FormattedMessage id="profile_page.practice_areas" /> : <FormattedMessage id="profile_page.skills" />}</h3>
        <Grid container>
          {strengths.map(tag => (
            <Grid item key={tag}>
              <span
                className={`label label-primary ${obfuscated ? 'blurry-text' : ''}`}
                style={{ margin: 5, backgroundColor: `${companyBusinessType === 'wealth_rj' ? '#002949' : ''}` }}>
                {obfuscated ? 'XXXXXXX' : capitalize(labels[tag] || tag.split('_').join(' '))}
              </span>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default injectIntl(Strengths);
