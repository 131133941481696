import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  inline       : {
    display : 'inline',
  },
  readColumn   : {
    width     : 80,
    marginTop : 30,
    fontSize  : 8,
  },
  callToAction : { float: 'right' },
  time         : {
    position : 'absolute',
    bottom   : 20,
  },
  avatar: {
    backgroundColor: "#1396e2",
  }
}));

const DayMessage = day_number => {
  switch (day_number) {
    case 1:
      return <span><FormattedMessage id="notifications.keep_up_with_your_business" /></span>;
    case 3:
      return <span><FormattedMessage id="notifications.stay_on_track_with_completing" /></span>;
    case 7:
      return <span><FormattedMessage id="notifications.dont_forget_about_your" /></span>;
    case 14:
      return <span><FormattedMessage id="notifications.make_some_time_to_protect" /></span>;
    default:
      return '';
  }
};

export const DayReminderNotification = props => {
  const { notification } = props;
  const classes = useStyles();
  const dataString = get(notification, 'data', '{}');
  const data = JSON.parse(dataString);
  const readCol = !get(notification, 'readAt') ? <div className={classes.readColumn}>&#128313;</div> : <div className={classes.readColumn}>&nbsp;</div>;

  return (
    <ListItem alignItems="flex-start">
      <ListItemText>
        {readCol}
      </ListItemText>
      <ListItemAvatar>
        <Avatar className={classes.avatar} name={get(notification, 'notifiableType')}>1</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<>{DayMessage(get(data, 'number_of_days'))}</>}
        secondary={
          <>
            <span className={classes.time}>{get(notification, 'readAt') && moment(notification.readAt).fromNow()}</span>
            <Button
              href="/continuity/builder"
              className={classes.callToAction}>
              <FormattedMessage id="notifications.continue_plan" />
            </Button>
          </>
        }
      />
    </ListItem>
  );
};

DayReminderNotification.propTypes = {
  notification: PropTypes.object.isRequired,
}

export default DayReminderNotification;
