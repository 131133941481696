import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toUpper, get } from 'lodash';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import  { Redirect } from 'react-router-dom'
import moment from 'moment';
import { MenuItem } from '@material-ui/core';
import { TextField as MTextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import WarningText from '../WarningText/WarningText';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import PoliciesDialog from './PoliciesDialog';

class ContactInformationForm extends React.Component {
  state = {
    error                         : null,
    password                      : '',
    postal                        : '',
    phone                         : '',
    birth_date                    : '',
    already_in_business           : false,
    loading                       : false,
    explicit_opt_in_tos_timestamp : null,
    openAgreement                 : false,
    agreedToPp                    : false,
    agreedToTos                   : false,
    openPp                        : false,
    openTos                       : false,
  };

  createUser = () => {
    const {
      mutate,
      onCreate,
      updateAction,
      full_name,
      birth_date,
      email,
      referrer,
      utm_source,
      utm_medium,
      utm_name,
      utm_campaign,
      paramsLocale,
      locale,
      intl,
      optInScrollPrivacyPolicy,
    } = this.props;
    const {
      password,
      postal,
      phone,
      already_in_business,
      explicit_opt_in_tos_timestamp,
      agreedToPp,
      agreedToTos,
    } = this.state;
    this.setState({ loading: true });
    mutate({
      variables : {
        fullName                      : full_name,
        email,
        birthDate                     : birth_date,
        password,
        postal,
        phone,
        ref                           : referrer,
        utm_source,
        utm_medium,
        utm_name,
        utm_campaign,
        locale                        : paramsLocale || locale,
        new_in_industry               : !already_in_business,
        explicit_opt_in_tos_timestamp : optInScrollPrivacyPolicy ? moment().format() : explicit_opt_in_tos_timestamp,
        accepted_policies             : agreedToPp && agreedToTos,
      },
    })
      .then(response => {
        const u = { password, postal, phone, new_in_industry: !already_in_business };
        const id = get(response, 'data.createCandidate') || get(response, 'data.creatNewUser');
        if (id) {
          u.id = id;
        }
        this.clickDisagreeTos();
        this.clickDisagreePp();
        updateAction(u);
        onCreate();
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        this.setState({ loading: false });
      });
  };

  componentDidMount = () => {
    const { company } = this.props;
    if (get(company, 'disable_recruitment_new_to_business_question', false)) {
      this.setState({ already_in_business: true });
    }
  };

  validatePassword = () => {
    const { password } = this.state;

    return password.length > 5 || password.length == 0;
  };

  validatePostalCode = () => {
    const { postal } = this.state;
    const matcher = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
    return isEmpty(postal) || matcher.test(toUpper(postal));
  };

  validateAge = () => {
    const { birth_date } = this.state;
    if (birth_date === 'Invalid Date') {
      return <div />;
    }
    const age = moment().diff(birth_date, 'years');
    return age > 18 || birth_date === '';
  };

  validateBirthDate = () => {
    const { birth_date } = this.state;
    if (birth_date === 'Invalid Date') {
      return false;
    }
    return true;
  };

  validateEmail = () => {
    const { email } = this.props;
    const matcher = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return isEmpty(email) || matcher.test(email);
  };

  validatePhone = () => {
    const { phone } = this.state;
    const matcher = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return isEmpty(phone) || matcher.test(phone);
  };

  validate = () => {
    const { full_name, email, explicitOptIn, optInScrollPrivacyPolicy } = this.props;
    const { password, postal, birth_date, explicit_opt_in_tos_timestamp } = this.state;

    if (
      isEmpty(full_name) ||
      (explicitOptIn && !explicit_opt_in_tos_timestamp && !optInScrollPrivacyPolicy) ||
      (isEmpty(email) || !this.validateEmail()) ||
      (isEmpty(password) || !this.validatePassword()) ||
      (isEmpty(postal) || !this.validatePostalCode()) ||
      (isEmpty(birth_date) || !this.validateAge() || !this.validateBirthDate())
    ) {
      return false;
    }
    return true;
  };

  toggleTimeStamp = () => {
    const { explicit_opt_in_tos_timestamp } = this.state;
    if (explicit_opt_in_tos_timestamp) {
      this.setState({ explicit_opt_in_tos_timestamp: null });
    } else {
      const d = new Date();
      this.setState({ explicit_opt_in_tos_timestamp: moment().format() });
    }
  };

  clickOpenAgreement = () => {
    this.setState({ openAgreement: true });
  };

  handleClose = () => {
    this.setState({ openAgreement: false, openPp: false, openTos: false });
  };

  stringToHTML = str => {
    return <div dangerouslySetInnerHTML={{ __html: str }} />;
  };

  clickAgreePp = () => {
    this.setState({ agreedToPp: true, openPp: false });
  };

  clickAgreeTos = () => {
    this.setState({ agreedToTos: true, openTos: false });
  };

  clickDisagreeTos = () => {
    this.setState({ agreedToTos: false, openTos: false });
  };

  clickDisagreePp = () => {
    this.setState({ agreedToPp: false, openPp: false });
  };

  render () {
    const {
      intl,
      updateAction,
      full_name,
      countryForCompany,
      displayBusinessQuestion,
      agentWord,
      classes,
      email,
      phone,
      postal,
      explicitOptIn,
      company,
      privacyPolicy,
      termsOfService,
      optInScrollPrivacyPolicy,
      stylesheetName,
    } = this.props;
    const {
      birth_date,
      error,
      already_in_business,
      loading,
      explicit_opt_in_tos_timestamp,
      openAgreement,
      agreedToPp,
      agreedToTos,
    } = this.state;
    const disable_recruitment_new_to_business_question = get(
      company,
      'disable_recruitment_new_to_business_question',
      false
    );
    const use_birth_year_instead_of_dob = get(company, 'use_birth_year_instead_of_dob', false);
    if (error && get(this.state, 'error.message', '') == 'GraphQL error: --UNAUTHORIZED--') { 
      window.location.replace("/unauthorized/index");
    }
    return (
      <div>
        {loading && <CircularProgress size={65} className={classes.loading} />}
        <h2>
          <FormattedMessage id="sign_up_pages.here_we_go" />
        </h2>
        <p>
          <FormattedMessage id="sign_up_pages.please_take_twenty_seconds" />
        </p>
        <p>
          <small>
            <FormattedMessage id="sign_up_pages.privacy_waiver" />
          </small>
        </p>
        <WarningText visible={error} message={get(this.state, 'error.message', '').replace('GraphQL error:', '')} />
        <b>
          <FormattedMessage id="sign_up_pages.full_name" />
        </b>
        <input
          className="form-control"
          value={full_name}
          shouldFitContainer
          compact
          isLabelHidden
          autoFocus
          fullWidth
          key="name"
          type="text"
          onChange={e => updateAction({ full_name: e.target.value })}
        />
        <br />
        <b>
          <FormattedMessage id="shared.email" />{intl.locale == 'fr' && ' :'}
        </b>
        <input
          className="form-control"
          value={email}
          onChange={e => updateAction({ email: e.target.value })}
          fullWidth
          shouldFitContainer
          isLabelHidden
          key="email"
          type="text"
          isInvalid={!this.validateEmail()}
          compact
        />
        <br />
        <b>{use_birth_year_instead_of_dob ? <FormattedMessage id="shared.year_of_birth" /> : <FormattedMessage id="shared.birth_date" />}{intl.locale == 'fr' && ' :'}</b>
        <FindbobDatePicker
          getDate={date => {
            updateAction({ birth_date: date });
            this.setState({ birth_date: date });
          }}
          maxYear={moment().year()}
          minYear={moment().year() - 100}
          yearOnly={use_birth_year_instead_of_dob}
        />
        <br />
        <WarningText
          visible={!this.validateAge()}
          message={intl.formatMessage({ id: 'sign_up_pages.agent_age_warning' }, { agent_word: agentWord })}
        />
        <WarningText
          visible={!this.validateBirthDate()}
          message="The date entered is invalid, please re-enter your birth date."
        />
        <br />
        <b>
          <FormattedMessage id="sign_up_pages.password_six_plus" />
        </b>
        <input
          type="password"
          shouldFitContainer
          compact
          isLabelHidden
          fullWidth
          ref="password"
          autocomplete="new-password"
          key="new-password"
          isInvalid={!this.validatePassword()}
          onChange={e => this.setState({ password: e.target.value })}
          className="form-control"
        />
        <br />
        <b>{countryForCompany !== 'ca' ? 'Zip Code' : <FormattedMessage id="sign_up_pages.postal_code" />}</b>
        <input
          placeholder={countryForCompany !== 'ca' ? '' : 'A1A 1A1'}
          onChange={e => {
            this.setState({ postal: e.target.value });
            updateAction({ postal: e.target.value });
          }}
          fullWidth
          value={postal}
          shouldFitContainer
          isLabelHidden
          key="postal"
          type="text"
          isInvalid={!this.validatePostalCode()}
          compact
          className="form-control"
        />
        <br />
        <b>
          <FormattedMessage id="sign_up_pages.phone_number" />
        </b>
        <p>
          <small>
            <FormattedMessage id="sign_up_pages.your_phone_numer_is_not_kept" />
          </small>
        </p>
        <input
          className="form-control"
          onChange={e => {
            this.setState({ phone: e.target.value });
            updateAction({ phone: e.target.value });
          }}
          shouldFitContainer
          fullWidth
          isLabelHidden
          value={phone}
          key="phone"
          type="text"
          compact
        />

        {displayBusinessQuestion &&
        !disable_recruitment_new_to_business_question && (
          <div>
            <p>
              <br />
              <b className="form-control" style={{ border: 'none', padding: 0, color: 'black' }}>
                Are You Already in Business?
              </b>
            </p>
            <MTextField
              select
              variant="standard"
              fullWidth
              classes={{
                root : classes.menuItem,
              }}
              InputProps={{
                className : classes.menuItem,
              }}
              value={already_in_business}
              onChange={e => {
                this.setState({ already_in_business: e.target.value });
              }}>
              <MenuItem key="yes" value>
                Yes
              </MenuItem>
              <MenuItem key="no" value={false}>
                No
              </MenuItem>
            </MTextField>
          </div>
        )}
        <br />
        {!optInScrollPrivacyPolicy && (
          <div>
            {explicitOptIn ? (
              <div>
                <input type="checkbox" value={explicit_opt_in_tos_timestamp} onClick={this.toggleTimeStamp} />
                <b>
                  <FormattedMessage id="sign_up_pages.explicit_opt_in" />
                  <a href="/terms_of_service" target="_blank">
                    <FormattedMessage id="sign_up_pages.explicit_opt_in" />
                    <FormattedMessage id="sign_up_pages.terms_of_service" />
                  </a>{' '}
                  {'& '}
                  <a href="/privacy_policy" target="_blank">
                    <FormattedMessage id="sign_up_pages.terms_of_service" />
                  </a>
                </b>
              </div>
            ) : (
              <b>
                <FormattedMessage id="sign_up_pages.implicit_opt_in" />
                <a href="/terms_of_service" target="_blank">
                  <FormattedMessage id="sign_up_pages.terms_of_service" />
                </a>{' '}
                {'& '}
                <a href="/privacy_policy" target="_blank">
                  <FormattedMessage id="sign_up_pages.privacy_policy" />
                </a>
              </b>
            )}
          </div>
        )}
        <br />
        <div className="text-center">
          <Button
            onClick={optInScrollPrivacyPolicy ? this.clickOpenAgreement : this.createUser}
            className="btn btn-default"
            style={{
              padding : '12px 24px',
            }}
            variant="contained"
            color="primary"
            disabled={!this.validate()}>
            <FormattedMessage id="sign_up_pages.next" />
          </Button>
        </div>
        <PoliciesDialog
          openAgreementProp={openAgreement}
          updateUser={this.createUser}
          privacyPolicy={privacyPolicy}
          termsOfService={termsOfService}
          validate={this.validate}
          optInScrollPrivacyPolicy={optInScrollPrivacyPolicy}
          handleClose={this.handleClose}
          stylesheetName={stylesheetName}
          agreedToPp={agreedToPp}
          agreedToTos={agreedToTos}
          clickAgreePp={this.clickAgreePp}
          clickAgreeTos={this.clickAgreeTos}
          clickDisagreePp={this.clickDisagreePp}
          clickDisagreeTos={this.clickDisagreeTos}
        />
      </div>
    );
  }
}

const styles = {
  menuItem : {
    '&:before'      : { borderBottom: 'none' },
    alignItems      : 'center',
    backgroundColor : '#FAFBFC',
    borderColor     : '#F4F5F7',
    borderRadius    : '5px',
    borderStyle     : 'solid',
    boxSizing       : 'border-box',
    color           : '#091E42',
    fontSize        : '14px',
    justifyContent  : 'space-between',
    lineHeight      : '1.1428571428571428',
    maxWidth        : '100%',
    overflow        : 'hidden',
    transition      : 'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out',
    wordWrap        : 'break-word',
    borderWidth     : '1px',
    padding         : '3px 7px',
    height          : 36,
  },
  loading  : {
    position : 'fixed',
    top      : '50%',
    left     : '40%',
  },
};

ContactInformationForm.propTypes = {
  company                 : PropTypes.bool,
  displayBusinessQuestion : PropTypes.object,
  intl                    : PropTypes.object.isRequired,
  updateAction            : PropTypes.func.isRequired,
  full_name               : PropTypes.string,
  countryForCompany       : PropTypes.string,
  agentWord               : PropTypes.string,
  classes                 : PropTypes.object.isRequired,
};

ContactInformationForm.defaultProps = {
  displayBusinessQuestion : false,
  full_name               : '',
  countryForCompany       : 'ca',
  agentWord               : '',
  company                 : {},
};

export default withStyles(styles)(injectIntl(ContactInformationForm));
