import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';
import LoansOtpCalculator from '../components/LoansOtpCalculator/LoansOtpCalculator';

const LoansOtpCalculatorContainer = (props, railsContext) => {
    const theme = themes[railsContext.theme_name];
    window.locale = railsContext.i18nLocale || 'en';
    return (<MuiThemeProvider theme={createMuiTheme(theme)}>
                <CssBaseline />
                <LoansOtpCalculator {...props} />
            </MuiThemeProvider>);
};

export default LoansOtpCalculatorContainer;