import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
    display  : 'inline-flex',
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin   : theme.spacing.unit,
    fontSize : '24px',
  },
});

const validateEmailString = (email, allowEmpty = false) => {
  if (allowEmpty) {
    return !email || EmailValidator.validate(email);
  }
  return !EmailValidator.validate(email);
};

const updateField = (data, action, event) => {
  data[event.target.id] = event.target.value;
  action(data);
};

export const currencies = [
  {
    value : 'USD',
    label : '$ USD',
  },
  {
    value : 'CAD',
    label : '$ CAD',
  },
  {
    value : 'EUR',
    label : '€ EUR',
  },
];

export const intervals = [
  {
    value : 'Yearly',
    label : <FormattedMessage id="admin_plans.yearly" />,
  },
  {
    value : 'Month',
    label : <FormattedMessage id="admin_plans.monthly" />,
  },
];

const Plan = ({ object, action, deleteAction, updateAction, classes, intl }) => (
  <TableRow>
    <TableCell className={classes.root}>
      <SimpleInputField
        id="name"
        required
        label={intl.formatMessage({ id: 'shared.name' })}
        data={object}
        action={action}
        required
      />
      <SimpleInputField
        id="stripe_id"
        autoFocus
        required
        style={{ width: '250px' }}
        label={intl.formatMessage({ id: 'admin_plans.stripe_id' })}
        data={object}
        action={action}
        required
      />
      <br />
      <TextField
        id="description"
        label={intl.formatMessage({ id: 'shared.description' })}
        onChange={e => {
          updateField(object, action, e);
        }}
        multiline
        value={object.description || ''}
        rows="2"
        className={classes.textField}
        margin="dense"
        style={{ width: '30%' }}
      />
      <SimpleInputField
        id="amount"
        required
        label={intl.formatMessage({ id: 'admin_plans.amount' })}
        data={object}
        action={action}
        required
      />
      <TextField
        id="currency"
        select
        label={intl.formatMessage({ id: 'admin_plans.currency' })}
        value={object.currency}
        onChange={e => {
          updateField(object, action, e);
        }}
        SelectProps={{
          native : true,
        }}
        style={{ margin: '12px' }}>
        {currencies.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <TextField
        id="interval"
        select
        label={intl.formatMessage({ id: 'admin_plans.interval' })}
        value={object.interval}
        onChange={e => {
          updateField(object, action, e);
        }}
        SelectProps={{
          native : true,
        }}
        style={{ margin: '12px' }}>
        {intervals.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
      <ToggleButton
        id="published"
        label={intl.formatMessage({ id: 'admin_plans.published' })}
        checked={object.published}
        onChange={() => {
          object.published = !object.published;
          action(object);
          object.modified = true;
        }}
      />
      {
        // <SimpleInputField id="published" required={true} label="published" data={object} action={action} required/>
      }
    </TableCell>
    <TableCell>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Save"
        disabled={!object.name || !object.modified}
        onClick={() => {
          updateAction(object);
        }}>
        <Icon>save</Icon>
      </IconButton>
      <IconButton className={classes.button} aria-label="Delete">
        <DeleteIcon
          onClick={() => {
            deleteAction(object);
          }}
        />
      </IconButton>
    </TableCell>
  </TableRow>
);

const PlanWithStyles = withStyles(styles)(Plan);
export default injectIntl(PlanWithStyles);
