import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { flowRight } from 'lodash';
import { FormattedMessage } from 'react-intl';

const AddNote = gql`
  mutation addNote($userName: String!) {
    addNote(userName: $userName, text: "Added to Rolodex") {
      id
      comment
      created_at
    }
  }
`;

const expressInterest = gql`
  mutation expressInterest($id: ID!, $type: String) {
    expressInterest(id: $id, type: $type)
  }
`;

class ExpressInterestButton extends Component {
  state = { showButton: true, emailDialogOpen: false };

  callExpressInterest = () => {
    const {
      user,
      expressInterestMutation,
      updateProfile,
      expressInterest,
      becomeAPartner,
      expressThroughManager,
    } = this.props;
    const type = () => {
      if (expressInterest && expressThroughManager) {
        return 'customer_success_inquiry';
      }
      if (becomeAPartner) {
        return 'seller_only';
      }
      return 'managed';
    };
    expressInterestMutation({
      variables : {
        id   : parseInt(user.id, 10),
        type : type(),
      },
    })
      .then(() => {
        this.setState({ showButton: false });
        updateProfile({ ...user, expressed_interest: true });
        if (user.note_count === 0) {
          this.callAddNote();
        }
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  callAddNote = () => {
    const { user, AddNewNote, refetch, updateBobCards, loadBobCardList, updateNotes } = this.props;
    AddNewNote({
      variables : { userName: user.user_name },
    })
      .then(r => {
        updateNotes({ notes: r.data.addNote, skip: true });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: 1 });
          loadBobCardList({ ...user, note_count: 1 });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  clickExpressInterest = () => {
    this.setState({ emailDialogOpen: true });
  };

  closeExpressInterestDialog = () => {
    this.setState({ emailDialogOpen: false });
  };

  render = () => {
    const { user, classes, sellerWord, expressInterest, expressThroughManager, becomeAPartner } = this.props;
    const { showButton, emailDialogOpen } = this.state;
    if (showButton) {
      return (
        <div>
          <Button
            variant="contained"
            // style={this.buttonColor()}
            className={classes.button}
            onClick={() => this.clickExpressInterest()}>
            {expressInterest && <span><FormattedMessage id="profile_page.express_intrest_button.express_interest"/></span>}
            {becomeAPartner && <span><FormattedMessage id="profile_page.express_intrest_button.become_a_sellerword" values={{sellerWord}}/></span>}
          </Button>

          <Dialog
            open={emailDialogOpen}
            onClose={this.closeExpressInterestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            {expressInterest &&
            expressThroughManager && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3><FormattedMessage id="profile_page.express_intrest_button.confirmation"/></h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      <FormattedMessage id="profile_page.express_intrest_button.by_clicking_the_comfirm_button" values={{user_name: user.user_name}}/>
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            {expressInterest &&
            !expressThroughManager && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3><FormattedMessage id="profile_page.express_intrest_button.by_clicking_the_comfirm_button" values={{user_name: user.user_name}}/></h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      <FormattedMessage id="profile_page.express_intrest_button.in_order_to_learn_more_about_this_profile"/>
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            {becomeAPartner && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3><FormattedMessage id="profile_page.express_intrest_button.inquire_about_becoming" values={{sellerWord}}/></h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      <FormattedMessage id="profile_page.express_intrest_button.in_order_to_connect_with_user" values={{user_name: user.user_name, sellerWord}}/> 
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            <DialogActions>
              <Button onClick={this.closeExpressInterestDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.callExpressInterest();
                  this.closeExpressInterestDialog();
                }}
                variant="contained"
                color="primary"
                style={{ float: 'right', marginRight: 15, backgroundColor: 'rgb(19, 150, 226)' }}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return (
      <div>
        {expressInterest && (
          <Button variant="contained" className={classes.pressed}>
            <span style={{ marginRight: 12 }} /> <FormattedMessage id="profile_page.express_intrest_button.express_interest"/>
          </Button>
        )}
        {becomeAPartner && (
          <Button variant="contained" className={classes.pressed}>
            <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.express_intrest_button.become_a_sellerword" values={{sellerWord}}/>
          </Button>
        )}
      </div>
    );
  };
}

ExpressInterestButton.propTypes = {
  user                    : PropTypes.object.isRequired,
  expressInterestMutation : PropTypes.func.isRequired,
  expressInterest         : PropTypes.bool.isRequired,
  becomeAPartner          : PropTypes.bool.isRequired,
  expressThroughManager   : PropTypes.bool.isRequired,
  updateProfile           : PropTypes.func.isRequired,
  refetch                 : PropTypes.func,
  updateBobCards          : PropTypes.func.isRequired,
  loadBobCardList         : PropTypes.func.isRequired,
  updateNotes             : PropTypes.func.isRequired,
  AddNewNote              : PropTypes.func.isRequired,
  classes                 : PropTypes.object.isRequired,
  pressed                 : PropTypes.object.isRequired,
  sellerWord              : PropTypes.string.isRequired,
};

ExpressInterestButton.defaultProps = {
  refetch : () => {},
};

const styles = {
  dialogText : {
    fontSize : 16,
    color    : 'black',
  },
  button     : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed    : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default flowRight(
  graphql(expressInterest, { name: 'expressInterestMutation' }),
  graphql(AddNote, { name: 'AddNewNote' })
)(withStyles(styles)(ExpressInterestButton));
