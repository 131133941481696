import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import PlusIcon from '@material-ui/icons/Add';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { graphql } from 'react-apollo';
import { isEmpty, get } from 'lodash';
import { createPipelineMutation, PipelinesBoardQuery } from './PipelineQueries';

class NewPipelineDialog extends React.Component {
  state = { name: '', pipeline_stages: [],  showDropdown: false, category_for_task_id: get(this.props, 'data.pipeline.category_for_task.id') };

  onClose () {
    const { handleClose } = this.props;

    handleClose();
  }

  onSave () {
    const { mutate } = this.props;
    const { name, pipeline_stages, category_for_task_id } = this.state;
  
    mutate({
      variables : {
        name            : encodeURI(name),
        category_for_task_id : encodeURI(category_for_task_id),
        pipeline_stages : pipeline_stages.map((p, i) => ({
          ...p,
          name           : encodeURI(p.name),
          ordinal        : i,
          win_percentage : parseInt(p.win_percentage, 10),
        })),
      },
    })
      .then(response => {
        window.location.href = `/admin/pipelines/${response.data.createPipeline.id}`;
        this.onClose();
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('there was an error sending the query', error);
      });
  }

  validatePipelineStages = () => {
    const { pipeline_stages } = this.state;
    const winPercentages = pipeline_stages.filter(p => p.win_percentage > 100);
    return isEmpty(winPercentages);
  };

  moveItem = (array, from, to) => {
    const f = array.splice(from, 1)[0];
    array.splice(to, 0, f);
    this.setState({ pipeline_stages: array });
  };

  moveUp = (array, index) => {
    if (index > 0) {
      this.moveItem(array, index, index - 1);
    }
  };

  moveDown = (array, index) => {
    if (index + 1 < array.length) {
      this.moveItem(array, index, index + 1);
    }
  };

  handleAddTaskClick = () => {
    this.setState({
      showDropdown: true,
    });
  };

  renderCategoryForTasks () {
    const { category_for_task_id } = this.state;
    const { getCategoryForTasks } = this.props.data;
    let selectedValue = category_for_task_id;
    if(category_for_task_id === undefined) {
      selectedValue = getCategoryForTasks[0].id;
      this.setState({ category_for_task_id: selectedValue });
    }

    return (
      <div>
        <select
          style={{ width: '150px', height: '30px', fontSize: '10px' }}
          value={selectedValue}
          onChange={e => {
            const selectedCategory = getCategoryForTasks.find(cat => cat.id === e.target.value);
            this.setState({ category_for_task_id: selectedCategory ? selectedCategory.id : null });
          }}
        >
          {getCategoryForTasks && getCategoryForTasks.map((cat) => (
            <option key={cat.id} value={cat.id}>
              {cat.name}
            </option>
          ))}
        </select>
      </div>
    );
  }

  renderStage (stage = {}, index) {
    const { pipeline_stages } = this.state;
    return (
      <div>
        <TextField
          label="Name"
          type="text"
          value={stage.name}
          onChange={e => {
            const stages = pipeline_stages;
            stages[index] = { ...stages[index], name: e.target.value };

            this.setState({ pipeline_stages: stages });
          }}
        />
        <TextField
          label="Win %"
          style={{ marginLeft: 20, width: 100 }}
          value={stage.win_percentage}
          placeholder="0"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 100 } }}
          onChange={e => {
            if (e.target.value.length <= 3) {
              const stages = pipeline_stages;
              stages[index] = { ...stages[index], win_percentage: e.target.value };
              this.setState({ pipeline_stages: stages });
            }
          }}
          error={stage.win_percentage > 100}
        />
        <div style={{ display: 'inline-flex', flexDirection: 'column', padding: 2 }}>
          <ArrowUpIcon onClick={() => this.moveUp(pipeline_stages, index)} style={{ height: '0.8em' }} />
          <ArrowDownIcon onClick={() => this.moveDown(pipeline_stages, index)} style={{ height: '0.8em' }} />
        </div>
      </div>
    );
  }

  render () {
    const { open } = this.props;
    const { name, pipeline_stages, category_for_tasks, showDropdown } = this.state;

    return (
      <div>
        {open && (
          <Dialog open={open} onClose={() => this.onClose()} aria-labelledby="form-dialog-new-pipeline">
            <DialogTitle id="form-dialog-title">Create A New Pipeline</DialogTitle>
            <DialogContent>
              <DialogContentText>Name your new Pipeline and add stages.</DialogContentText>
              <h5>Pipeline</h5>
              <TextField
                autoFocus
                id="name"
                label="Name"
                type="text"
                value={name}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                fullWidth
              />
              <Divider />
              <h5>Stages</h5>
              {pipeline_stages.map((stage, i) => this.renderStage(stage, i))}
              <Button
                onClick={() => {
                  pipeline_stages.push({ name: '', ordinal: pipeline_stages.length });
                  this.setState({
                    pipeline_stages,
                  });
                }}>
                <PlusIcon />Add Stage
              </Button>
              <Divider />
                <h5>Categorized Tasks</h5>
                {showDropdown && this.renderCategoryForTasks()}
                {!showDropdown && (
                  <Button onClick={this.handleAddTaskClick}>
                    <PlusIcon />Add Category
                  </Button>
                )}
              <Divider />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.onClose()} color="primary">
                Cancel
              </Button>
              <Button onClick={() => this.onSave()} color="primary" disabled={!name || !this.validatePipelineStages()}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

NewPipelineDialog.propTypes = {
  open        : PropTypes.bool,
  mutate      : PropTypes.func.isRequired,
  handleClose : PropTypes.func.isRequired,
  data        : PropTypes.object.isRequired,
};

NewPipelineDialog.defaultProps = {
  open : false,
};

export default graphql(PipelinesBoardQuery)(
  graphql(createPipelineMutation)(NewPipelineDialog)
);
