import React, {Fragment} from 'react';
import numeral from 'numeral';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash'; 
import BuyerSellerNote from './BuyerSellerNote';

const BuyerOrdinaryTaxBreakdown = ({buyerCashFlow, intl, classes, company}) => {
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }
  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');

  return <Fragment>
    <tr>
      <td colSpan={2}><b>Tax Adjustments</b></td>
    </tr>
    <tr>
      <td >
        Down Payment Loan Interest
      </td>
      {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>
        {numeral(v.downpaymentLoanInterestTax).format(dollarFormat)}
        </td>))}
    </tr>
    <tr>
      <td>
        Down Payment Amortization
      </td>
      {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>
        {numeral(v.downpaymentAmortizationTax).format(dollarFormat)}
        </td>))}
    </tr>
    <tr>
      <td>
        Earn Out
      </td>
      {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.contingentPayment).divide(-1).format(dollarFormat)}</td>))}
    </tr>
    { showSellerNote && <BuyerSellerNote buyerCashFlow={buyerCashFlow} /> }
    <tr>
        <td style={{ borderTop: '3px solid black' }}>
          Taxable Income
        </td>
        {buyerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>
          {numeral(v.taxableIncome).divide(-1).format(dollarFormat)}
          </td>))}
      </tr> 
    <tr>
        <td style={{ borderBottom: '3px solid black' }} ><FormattedMessage id="calculators.cfa.taxes"/> ({numeral(get(buyerCashFlow, '[0].taxRate', 0.0)).format(percentFormat)})</td>
        {buyerCashFlow.map((v) => (<td style={{ borderBottom: '3px solid black', color: 'red', textAlign: 'right'}}>{numeral(v.taxes).format(dollarFormat)}</td>))}
    </tr> 
  </Fragment>;
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
    company: {...get(state, 'company', {})},
  };
};

export default connect(mapStateToProps)(injectIntl(BuyerOrdinaryTaxBreakdown));