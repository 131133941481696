import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { get, find, startCase } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import {
  ifpOptions,
  aumOptions,
  aumList,
  insuranceList,
  canadianRetailWealthAumList,
  pAndCProductAumList,
} from '../SignUpWizard/ProductMix';
import { FormattedMessage } from 'react-intl';

const hasAum = user => {
  for (let i = 0; i < aumList.length; i += 1) {
    if (get(user, `product_mix[${aumList[i]}]`)) {
      return true;
    }
    return false;
  }
};

const hasInsurance = user => {
  for (let i = 0; i < insuranceList.length; i += 1) {
    if (get(user, `product_mix[${insuranceList[i]}]`)) {
      return true;
    }
    return false;
  }
};

const AumInfo = ({ classes, user, authorized, obfuscateView, intl, useCanadianRetailWealthProductMix, usePAndCProductMix, hideProductMix }) => {
  if (hideProductMix) {
    return null;
  }
  if (user.is_student && user.company.enabled_student_profiles) {
    return null;
  }
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        {(hasAum(user) || useCanadianRetailWealthProductMix) && (
          <div>
            <h3>{useCanadianRetailWealthProductMix ? <FormattedMessage id="profile_page.product_mix_breakdown" /> : <FormattedMessage id="profile_page.product_mix_breakdown" />}</h3>
            <Grid container style={{ marginLeft: 20 }}>
              {!useCanadianRetailWealthProductMix &&
                aumList.map(
                  a =>
                    user.product_mix[a] !== null && (
                      <Grid xs={3}>
                        {obfuscateView && <div className={(classes.header, 'blurry-text')}>$$$$</div>}
                        {!obfuscateView && (
                          <div className={classes.header}>
                            {get(find(aumOptions(intl), { value: get(user, `product_mix.${a}`) }), 'label', '-')}
                          </div>
                        )}
                        <div className={classes.body}>
                          {startCase(intl.formatMessage({id: `profile_page.${a}`}))}
                        </div>
                      </Grid>
                    )
                )}
              {useCanadianRetailWealthProductMix &&
                canadianRetailWealthAumList.map(
                  a =>
                    user.product_mix[a] !== null && (
                      <Grid xs={3}>
                        {obfuscateView && <div className={(classes.header, 'blurry-text')}>$$$$</div>}
                        {!obfuscateView && <div className={classes.header}>{user.product_mix[a]} %</div>}
                        <div className={classes.body}>
                          {a === 'gic_fixed_income' ? (
                            intl.formatMessage({id: `profile_page.gic_fixed_income`})
                          ) : (
                            startCase(intl.formatMessage({id: `profile_page.${a}`}))               
                          )}
                        </div>
                      </Grid>
                    )
                )}
                {usePAndCProductMix &&
                  pAndCProductAumList.map(
                    a =>
                      user.product_mix[a] !== null && (
                        <Grid xs={3}>
                          {obfuscateView && <div className={(classes.header, 'blurry-text')}>$$$$</div>}
                          {!obfuscateView && <div className={classes.header}>{user.product_mix[a]} %</div>}
                          <div className={classes.body}>
                            {a === 'health_ifp' ? (
                              intl.formatMessage({id: `profile_page.health_ifp`})
                            ) : (
                              startCase(intl.formatMessage({id: `profile_page.${a}`}))     
                            )}
                          </div>
                        </Grid>
                      )
                  )}
            </Grid>
          </div>
        )}
        <br />
        <br />
        {hasInsurance(user) && (
          <div>
            <h3><FormattedMessage id="profile_page.insurance_premium" /></h3>
            <Grid container style={{ marginLeft: 20 }}>
              {insuranceList.map(
                i =>
                  user.product_mix[i] !== 0.0 &&
                  user.product_mix[i] !== null && (
                    <Grid xs={3} style={{ height: 80 }}>
                      {obfuscateView && <div className={(classes.header, 'blurry-text')}>$$$$</div>}
                      {!obfuscateView && (
                        <div className={classes.header}>
                          {get(find(ifpOptions(intl), { value: get(user, `product_mix.${i}`) }), 'label', '-')}
                        </div>
                      )}
                      <div className={classes.body}>
                        {startCase(intl.formatMessage({id: `profile_page.${i}`}))}
                      </div>
                    </Grid>
                  )
              )}
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  header : {
    fontFamily : 'Trebuchet MS, Arial, Helvetica, sans-serif',
    fontWeight : 'bold',
    fontSize   : 18,
  },
  body   : {
    fontFamily : 'Trebuchet MS, Arial, Helvetica, sans-serif',
    color      : '#5a5a5a',
  },
};

export default injectIntl(withStyles(styles)(AumInfo));
