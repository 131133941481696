import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const ValueColumnSelector = ({ value, onChange, fullWidth }) => (
  <span>
    <Select value={value} onChange={onChange} placeholder="Calibrate value on" fullWidth={fullWidth}>
      {[
        { label: 'Total Assets', value: 'total_assets' },
        { label: 'Total Production', value: 'rev_yield' },
        { label: 'Total Premium', value: 'total_premium' },
      ].map(v => <MenuItem value={v.value}>{v.label}</MenuItem>)}
    </Select>
  </span>
);

ValueColumnSelector.propTypes = {
  value     : PropTypes.string,
  onChange  : PropTypes.func,
  fullWidth : PropTypes.bool,
};

ValueColumnSelector.defaultProps = {
  value     : '',
  onChange  : () => {},
  fullWidth : false,
};

export default ValueColumnSelector;
