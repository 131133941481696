import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import routes from '../routes/adminRoutes';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import configureStore from '../store/findBobStore';
import findBobMuiTheme from '../lib/findBobMuiTheme';
import { ApolloProvider } from 'react-apollo';
import { SnackbarProvider } from 'notistack';
import client from '../lib/apolloClient';
import { addLocaleData } from 'react-intl';
import translations from '../lib/i18n/translations.json';
import defaultLocale from '../lib/i18n/default.json';
import { IntlProvider } from 'react-intl';
import { Provider, updateIntl } from 'react-intl-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import icons from '../lib/fontAwesomeIcons';

library.add(icons);

const generateClassName = createGenerateClassName({ productionPrefix: 'fbAdminJss' });
const jss = create(jssPreset());

const CompanyAdminApp = (props, railsContext) => {
  const store = configureStore({ ...props, company: { 
    host: railsContext.company_host,
    features: railsContext.features,
    file_import_disclaimer: railsContext.file_import_disclaimer,
    name: railsContext.company_name,
    branded_name: railsContext.company_branded_name,
   } });

  // set locale and messages for IntlProvider.
  // TODO: Add a method that checks for current locale
  const locale = railsContext.i18nLocale || defaultLocale;
  store.dispatch(
    updateIntl({
      locale,
      messages : translations[window.locale || 'en'],
    })
  );
  return (<div data-turbo="false">
    <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbAdmin-">
      <MuiThemeProvider theme={createMuiTheme(findBobMuiTheme)}>
        <CssBaseline />
        <Provider store={store} props {...props}>
          <SnackbarProvider>
            <ApolloProvider client={client}>
              <BrowserRouter>{routes}</BrowserRouter>
            </ApolloProvider>
          </SnackbarProvider>
        </Provider>
      </MuiThemeProvider>
    </JssProvider>
  </div>);
};

export default CompanyAdminApp;
