import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import gql from 'graphql-tag';
import { graphql, Mutation } from 'react-apollo';
import IconSelector from './IconSelector';

const UpdateActivityTypeMutation = gql`
  mutation updateActivityType($id: ID!, $name: String, $iconUrl: String, $interaction: Boolean) {
    updateActivityType(id: $id, name: $name, icon_url: $iconUrl, interaction: $interaction) {
      id
    }
  }
`;

const DeleteActivityTypeMutation = gql`
  mutation deleteActivityType($id: ID!) {
    deleteActivityType(id: $id)
  }
`;

class ActivityRow extends React.Component {
  constructor (props) {
    super(props);

    this.state = { ...props.activityType, edited: false };
  }

  handleChange = (field, value) => {
    this.setState({ [field]: value, edited: true });
  };

  render () {
    const { activityType, mutate, refetch } = this.props;
    const { name, iconUrl, edited, interaction } = this.state;
    return (
      <TableRow>
        <TableCell>
          <IconSelector iconName={iconUrl} onIconChange={icon => this.handleChange('iconUrl', icon)} />
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            value={name}
            onChange={e => {
              this.handleChange('name', e.target.value);
            }}>
            {activityType.name}
          </TextField>
        </TableCell>
        <TableCell>
          <Switch
            checked={interaction}
            onChange={() => this.handleChange('interaction', !interaction)}
            color="primary"
          />
        </TableCell>
        <TableCell>
          <IconButton
            disabled={!edited}
            color="secondary"
            onClick={() => {
              mutate({ variables: this.state }).then(() => {
                refetch();
                this.setState({ edited: false });
              });
            }}>
            <SaveIcon />
          </IconButton>
          {activityType.relationCount === 0 && (
            <Mutation mutation={DeleteActivityTypeMutation}>
              {deleteActivityType => (
                <IconButton
                  onClick={() => {
                    deleteActivityType({ variables: { id: activityType.id } }).then(() => {
                      refetch();
                    });
                  }}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Mutation>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

ActivityRow.propTypes = {
  activityType : PropTypes.object,
  mutate       : PropTypes.func.isRequired,
  refetch      : PropTypes.func.isRequired,
};

ActivityRow.defaultProps = {
  activityType : {},
};

export default graphql(UpdateActivityTypeMutation)(ActivityRow);
