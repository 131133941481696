import {
  SUGGESTED_DEAL_LIST_UPDATE,
  SUGGESTED_DEAL_UPDATE,
  UPDATE_SELECTED_SUGGESTED_DEAL,
} from '../constants/suggestedDealConstants';

export const updateSuggestedDealsList = suggestedDeals => ({
  type           : SUGGESTED_DEAL_LIST_UPDATE,
  suggestedDeals,
});

export const updateSuggestedDeal = suggestedDeal => ({
  type          : SUGGESTED_DEAL_UPDATE,
  suggestedDeal,
});

export const updateSelectedSuggestedDeal = selectedSuggestedDeal => ({
  type                  : UPDATE_SELECTED_SUGGESTED_DEAL,
  selectedSuggestedDeal,
});
