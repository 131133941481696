import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin   : theme.spacing.unit,
    fontSize : '24px',
  },
});

const validateEmailString = (email, allowEmpty = false) => {
  if (allowEmpty) {
    return !email || EmailValidator.validate(email);
  }
  return !EmailValidator.validate(email);
};

const PendingAuthorizedUser = ({
  pending_authorized_user,
  authorizeAction,
  action,
  deleteAction,
  updateAction,
  userClick,
  classes,
  intl,
}) => (
  <TableRow>
    <TableCell>
      <SimpleInputField
        id="name"
        required
        label={intl.formatMessage({ id: 'admin_inventory_user.full_name' })}
        data={pending_authorized_user}
        action={action}
        required
      />
    </TableCell>
    <TableCell>
      <SimpleInputField
        id="email"
        validator={validateEmailString(pending_authorized_user.email)}
        required
        type="email"
        label={intl.formatMessage({ id: 'shared.email' })}
        data={pending_authorized_user}
        action={action}
        helperText={
          !validateEmailString(pending_authorized_user.email) ? (
            ''
          ) : (
            intl.formatMessage({ id: 'admin_inventory_user.enter_valid_email' })
          )
        }
        required
      />
    </TableCell>
    <TableCell>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Authorize"
        onClick={() => {
          userClick(pending_authorized_user);
        }}>
        <PersonIcon />
      </IconButton>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Authorize"
        onClick={() => {
          authorizeAction(pending_authorized_user);
        }}>
        <Icon>done</Icon>
      </IconButton>
      <IconButton className={classes.button} aria-label="Delete">
        <DeleteIcon
          onClick={() => {
            deleteAction(pending_authorized_user);
          }}
        />
      </IconButton>
    </TableCell>
  </TableRow>
);

const PendingAuthorizedUserWithStyles = withStyles(styles)(PendingAuthorizedUser);
export default injectIntl(PendingAuthorizedUserWithStyles);
