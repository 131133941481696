import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import numeral from 'numeral';
import { VictoryBar, VictoryLine, VictoryLabel, VictoryChart, VictoryTheme, VictoryStack } from 'victory';
import { Typography, Link } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { capturePdf } from '../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  report   : {},
  title    : {
    fontSize : '24px',
    color    : '#616161',
  },
  progress : {
    margin : 'auto',
  },
  card     : {
    margin       : 'auto',
    maxWidth     : '600px',
    border       : 0,
    borderRadius : 0,
  },
  bigCard  : {
    margin   : '25px auto',
    maxWidth : 'calc(90vh - 200px)',
  },
  media    : {
    height     : 0,
    paddingTop : '56.25%', // 16:9
  },
  listItem : {
    display       : 'inline',
    listStyleType : 'circle',
  },
});

const formatChartData = sacReport => [
  { step: 'Start', users: sacReport.signup_started },
  { step: 'Signup', users: sacReport.signup_ended },
  { step: 'P.Info', users: sacReport.practiceInfo_ended },
  { step: 'ProdMix', users: sacReport.productMix_ended },
  { step: 'Str', users: sacReport.strengths_ended },
  { step: 'Bio', users: sacReport.bio_ended },
];

const formatUniqData = sacReport => [
  { step: 'Start', users: sacReport.signup_started_uniq },
  { step: 'Signup', users: sacReport.signup_ended_uniq },
  { step: 'P.Info', users: sacReport.practiceInfo_ended_uniq },
  { step: 'ProdMix', users: sacReport.productMix_ended_uniq },
  { step: 'Str', users: sacReport.strengths_ended_uniq },
  { step: 'Bio', users: sacReport.bio_ended_uniq },
];

const clickedCapture = () => {
  capturePdf('l', 0, 0, '', '', 0.7);
};

class SignupReport extends React.Component {
  render () {
    const { classes, data, intl, startDate, endDate, bigCard = false } = this.props;
    const today = new Date();

    if (data.loading) return <CircularProgress size={68} className={classes.progress} />;
    return (
      <div id="capture">
        <div className={bigCard ? classes.bigCard : classes.card}>
          <Tooltip title="Download PDF" style={{ float: 'right' }}>
            <IconButton onClick={() => clickedCapture()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <a href="/admin/reports/signup_report">
            <CardHeader
              classes={{
                title : classes.title,
              }}
              title="Signup report"
              subheader={`${startDate} - ${endDate}`}
            />
          </a>
          <CardContent>
            <ul>
              <li className={classes.listItem}>- Bars: Total Events</li>
              <li className={classes.listItem}>{'       '}- Line: Unique Users</li>
            </ul>
            <VictoryChart theme={VictoryTheme.material} domainPadding={10}>
              <VictoryBar
                style={{
                  data   : {
                    fill        : d => (d.x === 3 ? '#000000' : '#c43a31'),
                    stroke      : d => (d.x === 3 ? '#000000' : '#c43a31'),
                    fillOpacity : 0.7,
                    strokeWidth : 3,
                  },
                  labels : {
                    fontSize  : 15,
                    fill      : d => (d.x === 3 ? '#000000' : '#c43a31'),
                    transform : 'rotate(45deg)',
                  },
                }}
                data={formatChartData(data.sacSignupStepCount)}
                x="step"
                y="users"
                labelComponent={<VictoryLabel angle={90} />}
              />

              <VictoryLine
                data={formatUniqData(data.sacSignupStepCount)}
                x="step"
                y="users"
                fixLabelOverlap
                style={{
                  data : {
                    fillOpacity : 0.7,
                    strokeWidth : 3,
                  },
                }}
                labelComponent={<VictoryLabel angle={90} />}
              />
            </VictoryChart>
          </CardContent>
        </div>
      </div>
    );
  }
}

SignupReport.propTypes = {
  data : PropTypes.shape({
    sacGrossMarketValue : PropTypes.number,
  }),
};

const SignupReportWithStyles = withStyles(styles)(SignupReport);
export default injectIntl(SignupReportWithStyles);
