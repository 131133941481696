import { connect } from 'react-redux';
import Affiliation from '../components/Affiliation/Affiliation';
import NewFormDialog from '../components/Affiliation/AffiliationNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/affiliationsActionCreators';

const mapStateToProps = state => ({
  ...state,
  listData: state.affiliations,
  title: state.intl.messages['admin_affiliations.affiliations'],
  NewFormDialog,
  CrudRow: Affiliation,
});

export default connect(mapStateToProps, actions)(CrudTable);
