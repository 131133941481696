import React from 'react';
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import gql from 'graphql-tag';
import { withSnackbar } from 'notistack';
import {Mutation} from "react-apollo";
import { get } from 'lodash';
import * as cfaActionCreators from '../../actions/cfaActionCreators';

const SaveCfa = gql`
  mutation updateCfa($id: ID,
      $name: String,
      $cashFlowLength: Int,
      $cashFlowProduction: Int,
      $cashFlowProductionShift: Float,
      $sellerT12Revenue: Float,
      $sellerFiveYearGrowthRate: Float,
      $numberOfBuyers: Int,
      $buyer1T12Revenue: Float,
      $buyer1FiveYearGrowthRate: Float,
      $buyer1PercentagePurchasing: Float,
      $buyer2T12Revenue: Float,
      $buyer2FiveYearGrowthRate: Float,
      $buyer2PercentagePurchasing: Float,
      $buyerHasLumpSumPayment: Boolean,
      $buyerLumpSumAmount: Float,
      $buyerLumpSumYear: Int,
      $buyersFinancingAmount: Float,
      $buyerFinancingYears: Float,
      $buyerHasFixedPayments: Boolean,
      $buyerFixedPaymentYears: Int,
      $buyerFixedPaymentAmount: Float,
      $buyerFixedPaymentStartYear: Int,
      $buyerHasContinuingPayments: Boolean,
      $buyerContinuingPaymentYears: Int,
      $buyerContinuingPaymentStartYear: Int,
      $buyerContinuingPaymentAmount: Float,
      $buyerContinuingPayments: [Float!],
      $sellerManualTaxRate: Boolean,
      $sellerTaxRate: Float,
      $buyer1ManualTaxRate: Boolean,
      $buyer1TaxRate: Float,
      $buyer2ManualTaxRate: Boolean,
      $buyer2TaxRate: Float,
      $cashFlowFinancingInterestRate: Float,
      $hasCheckSwap: Boolean,
      $checkSwapYear: Int,
      $checkSwapAnnualBonus: Float,
      $checkSwapLoanEndYear: Int,
      $checkSwapLoanRepaymentAmount: Float,
      $buyer1AnnualExpenses: Float,
      $buyer1ExpenseGrowth: Float,
      $buyer2AnnualExpenses: Float,
      $buyer2ExpenseGrowth: Float,
      $cashflowSellerLifetimeCapitalGainsTax: Float,
      $consultingTotalCompensation: Float,
      $consultingLength: Int,
      $sellerNoteAmount: Float,
      $sellerNoteTerm: Int,
      $sellerNoteInterestRate: Float,
			$bdRetentionRate: Float,
      ){
    updateCfa(
      id: $id,
      name: $name,
      cash_flow_length: $cashFlowLength,
      cash_flow_production: $cashFlowProduction,
      cash_flow_production_shift: $cashFlowProductionShift,
      seller_t12_revenue: $sellerT12Revenue,
      seller_five_year_growth_rate: $sellerFiveYearGrowthRate,
      number_of_buyers: $numberOfBuyers,
      buyer_1_t12_revenue: $buyer1T12Revenue,
      buyer_1_five_year_growth_rate: $buyer1FiveYearGrowthRate,
      buyer_1_percentage_purchasing: $buyer1PercentagePurchasing,
      buyer_2_t12_revenue: $buyer2T12Revenue,
      buyer_2_five_year_growth_rate: $buyer2FiveYearGrowthRate,
      buyer_2_percentage_purchasing: $buyer2PercentagePurchasing,
      buyer_has_lump_sum_payment: $buyerHasLumpSumPayment,
      buyer_lump_sum_amount: $buyerLumpSumAmount,
      buyer_lump_sum_year: $buyerLumpSumYear,
      buyers_financing_amount: $buyersFinancingAmount,
      buyer_financing_years: $buyerFinancingYears,
      buyer_has_fixed_payments: $buyerHasFixedPayments,
      buyer_fixed_payment_amount: $buyerFixedPaymentAmount,
      buyer_fixed_payment_years: $buyerFixedPaymentYears,
      buyer_fixed_payment_start_year: $buyerFixedPaymentStartYear,
      buyer_has_continuing_payments: $buyerHasContinuingPayments,
      buyer_continuing_payment_years: $buyerContinuingPaymentYears,
      buyer_continuing_payment_start_year: $buyerContinuingPaymentStartYear,
      buyer_continuing_payment_amount: $buyerContinuingPaymentAmount,
      buyer_continuing_payments: $buyerContinuingPayments,
      seller_manual_tax_rate: $sellerManualTaxRate,
      seller_tax_rate: $sellerTaxRate,
      buyer_1_manual_tax_rate: $buyer1ManualTaxRate,
      buyer_1_tax_rate: $buyer1TaxRate,
      buyer_2_manual_tax_rate: $buyer2ManualTaxRate,
      buyer_2_tax_rate: $buyer2TaxRate,
      cash_flow_financing_interest_rate: $cashFlowFinancingInterestRate
      has_check_swap: $hasCheckSwap,
      check_swap_year: $checkSwapYear,
      check_swap_annual_bonus: $checkSwapAnnualBonus,
      check_swap_loan_end_year: $checkSwapLoanEndYear,
      check_swap_loan_repayment_amount: $checkSwapLoanRepaymentAmount,
      buyer_1_annual_expenses: $buyer1AnnualExpenses,
      buyer_1_expense_growth: $buyer1ExpenseGrowth,
      buyer_2_annual_expenses: $buyer2AnnualExpenses,
      buyer_2_expense_growth: $buyer2ExpenseGrowth,
      cashflow_seller_lifetime_capital_gains_tax: $cashflowSellerLifetimeCapitalGainsTax,
      consulting_length: $consultingLength,
      consulting_total_compensation: $consultingTotalCompensation,
      seller_note_amount: $sellerNoteAmount,
      seller_note_term: $sellerNoteTerm,
      seller_note_interest_rate: $sellerNoteInterestRate,
			bd_retention_rate: $bdRetentionRate,
    ) {
      id
			name
			friendlyName
			cashFlowLength
			cashFlowProduction
			cashFlowProductionShift  # Add This to edit Production Shifts In Year cant be greater than length
			sellerT12Revenue
			sellerFiveYearGrowthRate
			numberOfBuyers
			buyer1T12Revenue
			buyer1FiveYearGrowthRate
			buyer1PercentagePurchasing
			buyer2T12Revenue
			buyer2FiveYearGrowthRate
			buyer2PercentagePurchasing
			buyerHasLumpSumPayment
			buyerLumpSumAmount
			buyerLumpSumYear
			buyersFinancingAmount
			buyerFinancingYears
			buyerHasFixedPayments
			buyerFixedPaymentYears
			buyerFixedPaymentAmount
			buyerFixedPaymentStartYear
			buyerHasContinuingPayments
			buyerContinuingPaymentYears
			buyerContinuingPaymentStartYear
			buyerContinuingPaymentAmount
			buyerContinuingPayments
			sellerAverageCashFlow
			buyer1AverageCashFlow
			buyer2AverageCashFlow
			sellerManualTaxRate
			sellerTaxRate
			buyer1ManualTaxRate
			buyer1TaxRate
			buyer2ManualTaxRate
			buyer2TaxRate
			cashFlowFinancingInterestRate
			buyer1BreakEven
			buyer2BreakEven
			hasCheckSwap
			checkSwapYear
			checkSwapAnnualBonus
			checkSwapLoanEndYear
			checkSwapLoanRepaymentAmount
			buyer1AnnualExpenses
			buyer1ExpenseGrowth
			buyer2AnnualExpenses
			buyer2ExpenseGrowth
			purchasePrice
			lifetimeCapitalGainsException
			cashflowSellerLifetimeCapitalGainsTax
			consultingTotalCompensation
			consultingLength
			sellerNoteAmount
			sellerNoteTerm
			sellerNoteInterestRate
			sellerNotePaymentFrequency
			sellerNoteDefaultAmmortization
			bdRetentionRate
			illustration {
				sellerCashFlow {
					revenueGrowth
					personalProduction
					acquiredProduction
					cashFlowProductionShift
					payoutRate
					payout
					lumpSumPaymentReceived
					fixedPaymentReceived
					contingentPaymentReceived
					bookSalePaymentReceived
					pretaxNetIncome
					taxes
					totalTaxes
					downPayment
					afterTaxNetIncome
					freeCashFlow
					buyerHasFixedPayments
					taxRate
					transitionAssistanceLoanRepayment
					checkSwapPayment
					consultingAgreement
					consultingAgreementTaxes
					sellerNotePayment
					sellerCarriedNoteInterest
					sellerCarriedNotePrincipal
					earnOutTaxes
					downpaymentTaxes
					branchRetentionTaxes
					sellerNoteInterestTaxes
					sellerNotePrincipalTaxes
          transitionAssistanceLoanBonus
					cumulativeProceeds
				}
				buyer1CashFlow {
					revenueGrowth
					grossRevenue
					personalProduction
					aquiredProduction
					totalProduction
					payoutRate
					payout
					bookBuyPaymentTotal
					fixedPayment
					contingentPayment
					pretaxNetIncome
					taxes
					afterTaxNetIncome
					lumpSumPaymentOutOfPocket
					successionLoanRepayment
					debtServiceCoverageRatio
					freeCashFlow
					taxRate
					transitionAssistanceBonus
					branchExpenses
					consultingAgreement
					sellerNoteInterestPayment
					sellerNotePayment
					sellerNoteAmortization
					downpaymentLoanInterestTax
					downpaymentAmortizationTax
          taxableIncome
          downPaymentNote
					attritionRate
				}
				buyer2CashFlow {
					revenueGrowth
					grossRevenue
					personalProduction
					aquiredProduction
					totalProduction
					payoutRate
					payout
					bookBuyPaymentTotal
					fixedPayment
					contingentPayment
					pretaxNetIncome
					taxes
					afterTaxNetIncome
					lumpSumPaymentOutOfPocket
					successionLoanRepayment
					debtServiceCoverageRatio
					freeCashFlow
					taxRate
					transitionAssistanceBonus
					branchExpenses
					consultingAgreement
					sellerNoteInterestPayment
					sellerNotePayment
					sellerNoteAmortization
					downpaymentLoanInterestTax
					downpaymentAmortizationTax
          taxableIncome
          downPaymentNote
					attritionRate
				}
      }
    }
  }
`;

const SaveCfaButton = ({ query, enqueueSnackbar, updateCfaQuery, updateCfaResults, result, callOnSave, buttonTextCode }) => (<Mutation mutation={SaveCfa} onCompleted={data => {
  enqueueSnackbar(`Save successful`, {
            variant      : 'success',
            anchorOrigin : {
              vertical   : 'top',
              horizontal : 'center',
            },
          });

  updateCfaQuery({...data.updateCfa, updating: true});
  updateCfaResults({...result, cashFlow: data.updateCfa});
  callOnSave(data.updateCfa.friendlyName);
}}>
  { saveCfa => (
        <Button
          variant="contained"
          style={{margin: 10, width: '100%'}}
          className="btn btn-default"
          onClick={() => {
          saveCfa({variables: {...query}});
        }}>
         <FormattedMessage id={buttonTextCode} />
        </Button>)
  }
  </Mutation>);

SaveCfaButton.propTypes = {
  query: PropTypes.object,
  callOnSave: PropTypes.func,
  buttonTextCode: PropTypes.string,
};

SaveCfaButton.defaultProps = {
  query: {},
  callOnSave: () => {},
  buttonTextCode: 'calculators.cfa.save',
}

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query')},
  };
};

export default connect(mapStateToProps, { ...cfaActionCreators, })(withSnackbar(SaveCfaButton));
