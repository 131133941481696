import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Paper, Typography, Toolbar, AppBar } from '@material-ui/core';

const styles = theme => ({
  paper : {
    padding   : 12,
    minHeight : 'calc(100vh - 220px)',
    flexGrow  : 1,
    marginTop : 60,
  },
  title : {
    fontSize : 24,
    color    : '#fff',
  },
  root  : {
    flexGrow : 1,
    zIndex   : 1,
    overflow : 'hidden',
    position : 'relative',
    display  : 'flex',
  },
});

export const AdminWrapper = ({ title, children, classes }) => {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>{children}</Paper>
    </div>
  );
};

AdminWrapper.propTypes = {
  children : PropTypes.object,
  title    : PropTypes.string,
  classes  : PropTypes.object,
};

export default withStyles(styles)(AdminWrapper);
