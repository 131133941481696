import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { isEmpty, get, replace, keys, debounce } from 'lodash';
import { withSnackbar } from 'notistack';
import ToggleButton from '../ToggleButton/ToggleButton';
import FeatureForm from '../FeatureForm/FeatureForm';
import SaveButton from '../SaveButton/SaveButton';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import numeral from 'numeral';

const styles = theme => ({
  root         : {
    width    : '99%',
    padding  : '12px',
    margin   : '12',
    fontSize : '16px',
  },
  paper        : {
    padding : 12,
  },
  control      : {
    padding : theme.spacing.unit * 2,
    display: 'block',
    width: '90%'
  },
  title        : {
    fontSize : '18px',
    color    : '#fff',
  },
  textField    : {
    fontSize : '24px',
    margin   : '12px',
    width : '300px',
  },
  toggleButton : {
    fontSize : '24px',
    margin   : '12px',
  },

  dividerForm  : {
    display       : 'block',
    flexDirection : 'column',
    margin        : '10px 10px 10px 0px',
  },
  listItem     : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : 0,
    margin        : '0 20px',
  },
  typo         : {
    margin         : '10px',
    textDecoration : 'underline',
    display        : 'block',

  },
  subtext      : {
    margin     : '15px',
    fontWeight : '400',
    fontSize   : 14,
  },
});

class CompanyForm extends React.Component {
  submitComp = debounce((event, enqueueSnackbar) => {
    const { company, submitCompany, generateCompanyPayload } = this.props;
    submitCompany(generateCompanyPayload(company.data), company, event, enqueueSnackbar);
    // refresh the browser
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  }, 2000);

  state = {
    tabValue: 0,
  };

  constructor (props) {
    super(props);
    props.submitCompany.bind(this);
  }

  componentWillMount = () => {
    const { company, match, loadCompany } = this.props;
    if (get(company, 'data[id]', null) !== match.params.id) {
      loadCompany(match.params.id);
    }
  };

  componentWillReceiveProps = nextProps => {
    const { match, loadCompany } = this.props;
    if (match.params.id !== nextProps.match.params.id) {
      loadCompany(match.params.id);
    }
  };

  updateCompanyField = event => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;

    company[event.target.id] = event.target.value;
    updateCompany(company);
  };

  updateCompanyBool = id => {
    const { company, updateCompany } = this.props;

    company.data[id] = !company.data[id];
    updateCompany(company);
  };

  renderLoading = classes => (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );

  handleToggle = lang => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { supported_locales } = company;
    if (supported_locales.indexOf(lang) === -1) {
      supported_locales += `,${lang}`;
    } else {
      supported_locales = replace(supported_locales, `,${lang}`, '');
    }
    company.supported_locales = supported_locales;
    updateCompany(company);
  };

  handleToggleViewedProfileEmailDates = day => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { viewed_profile_mailer_send_dates } = company;
    let dateArr = isEmpty(viewed_profile_mailer_send_dates) ? [] : viewed_profile_mailer_send_dates.split(',');
    if (dateArr.indexOf(day) === -1) {
      dateArr.push(day);
    } else {
      const index = dateArr.indexOf(day);
      if (index > -1) {
        dateArr.splice(index, 1);
      }
    }
    company.viewed_profile_mailer_send_dates = dateArr.toString();
    updateCompany(company);
  };

  handleToggleViewedProfileEmailDateRange = range => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { viewed_profile_mailer_date_range } = company;
    viewed_profile_mailer_date_range = range;
    company.viewed_profile_mailer_date_range = viewed_profile_mailer_date_range;
    updateCompany(company);
  };

  handleToggleNewUsersInProximityEmailDates = day => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { new_users_in_proximity_mailer_send_dates } = company;
    let dateArr = isEmpty(new_users_in_proximity_mailer_send_dates)
      ? []
      : new_users_in_proximity_mailer_send_dates.split(',');
    if (dateArr.indexOf(day) === -1) {
      dateArr.push(day);
    } else {
      const index = dateArr.indexOf(day);
      if (index > -1) {
        dateArr.splice(index, 1);
      }
    }
    company.new_users_in_proximity_mailer_send_dates = dateArr.toString();
    updateCompany(company);
  };

  handleToggleNewUsersInProximityEmailDateRange = range => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { new_users_in_proximity_mailer_date_range } = company;
    new_users_in_proximity_mailer_date_range = range;
    company.new_users_in_proximity_mailer_date_range = new_users_in_proximity_mailer_date_range;
    updateCompany(company);
  };

  handleTogglePartialSalesEmailDates = day => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { partial_sales_email_mailer_send_dates } = company;
    let dateArr = isEmpty(partial_sales_email_mailer_send_dates) ? [] : partial_sales_email_mailer_send_dates.split(',');
    if (dateArr.indexOf(day) === -1) {
      dateArr.push(day);
    } else {
      const index = dateArr.indexOf(day);
      if (index > -1) {
        dateArr.splice(index, 1);
      }
    }
    company.partial_sales_email_mailer_send_dates = dateArr.toString();
    updateCompany(company);
  }

  handleTogglePartialSalesEmailDateRange = range => () => {
    let { company } = this.props;
    const { updateCompany } = this.props;
    company = company.data;
    let { partial_sales_email_mailer_date_range } = company;
    partial_sales_email_mailer_date_range = range;
    company.partial_sales_email_mailer_date_range = partial_sales_email_mailer_date_range;
    updateCompany(company);
  }

  render () {
    const { company, updateCompany, enqueueSnackbar, classes } = this.props;
    const { success, loading, error } = company;
    if (isEmpty(company) || isEmpty(company.data)) return this.renderLoading(classes);
    const companyLocales = company.data.supported_locales.split(',');
    const companyViewedProfileMailerSendDates = get(company, 'data.viewed_profile_mailer_send_dates') || ''.split(',');
    const companyViewedProfileMailerDateRange = get(company, 'data.viewed_profile_mailer_date_range') || ''.split(',');
    const companyNewUsersInProximityEmailSendDates =
      get(company, 'data.new_users_in_proximity_mailer_send_dates') || ''.split(',');
    const companyNewUsersInProximityEmailDateRange =
      get(company, 'data.new_users_in_proximity_mailer_date_range') || ''.split(',');
    const companyPartialSalesMailerSendDates = get(company, 'data.partial_sales_email_mailer_send_dates') || ''.split(',');
    const companyPartialSalesMailerDateRange = get(company, 'data.partial_sales_email_mailer_date_range') || ''.split(',');
    const locales = [ 'en', 'fr' ];
    const weekdays = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday' ];
    const emailDateRange = [ '1', '7' ];
    const features = get(company.data, 'features', []);
    const partialSaleEmail = features.some(feature => feature.feature_type === 'partial_sale_email');
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Tabs 
            value={this.state.tabValue}
            onChange={(event, value) => this.setState({tabValue: value})}
          >
            <Tab label="Company" /> 
            <Tab label="Features" />
          </Tabs>
          {this.state.tabValue === 0 && <Grid item>
              <form className={classes.form}>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Company Names and Layouts</h1>
                  </AppBar>
                  <SimpleInputField
                    id="name"
                    label="Company Name i.e. findbob"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="host"
                    label="Host Name i.e. hub.finbob.io"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField id="branded_name" label="Branded Name" data={company.data} action={updateCompany} />
                  <SimpleInputField id="display_name" label="Display Name" data={company.data} action={updateCompany} />
                  <SimpleInputField id="display_name_fr" label="Display Name (French)" data={company.data} action={updateCompany} />
                  <SimpleInputField
                    id="affiliate_name"
                    label="Affiliate Name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="affiliate_display_name"
                    label="Affiliate Display Name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="layout"
                    label="Layout: Should correspond with valid layout file"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="root_controller"
                    label="Root controller: 'enterprise' or 'markerting'"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="template"
                    label="Template: should correspond with valid template file"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="generic_mailer"
                    label="Generic email template"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="generic_mailer_non_cta"
                    label="Generic email template without CTA"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="grey_mailer"
                    label="Alternate email template (grey mailer)"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;URL's and Links</h1>
                  </AppBar>
                  <SimpleInputField id="contact_url" label="Contact Url" data={company.data} action={updateCompany} />
                  <SimpleInputField id="help_url" label="Help Url" data={company.data} action={updateCompany} />
                  <SimpleInputField
                    id="enterprise_redirect"
                    label="Enterprise Redirect Url"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="logout_destination"
                    label="Logout Destination"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="concierge_link"
                    label="Concierge Link"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField id="sftp_path" label="SFTP Path" data={company.data} action={updateCompany} />
                  <SimpleInputField id="sftp_csv_url" label="SFTP Inventory User and CSV Path" data={company.data} action={updateCompany} />
                  <SimpleInputField id="sftp_plan_file_url" label="SFTP Plan File Url" data={company.data} action={updateCompany} />
                  <SimpleInputField
                    id="nda_template_preview_pdf_url"
                    label="NDA Template Preview PDF URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="partial_sales_banner_url"
                    label="Partial Sales Banner URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="partial_sales_banner_url_link"
                    label="Partial Sales Banner URL Link"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="acquisition_strategy_banner_url"
                    label="Acquisition Strategy Banner URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="acquisition_strategy_banner_url_link"
                    label="Acquisition Strategy Banner URL Link"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;API Keys</h1>
                  </AppBar>
                  <SimpleInputField
                    id="mailchimp_list_id"
                    label="Mailchimp list id"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="intercom_app_id"
                    label="Intercom App Id"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="intercom_api_key"
                    label="Intercom API Key"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="intercom_token"
                    label="Intercom Token"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Support Emails</h1>
                  </AppBar>
                  <SimpleInputField id="support_email" label="Support Email" data={company.data} action={updateCompany} />
                  <SimpleInputField
                    id="administrator_email"
                    label="Administrator Email"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="default_sender_email_address"
                    label="Default Sender Email"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="match_pool_exception_email_list"
                    label="Match Pool Exception Email List"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="buyer_report_email_list"
                    label="Buyer Report Email List"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="seller_report_email_list"
                    label="Seller Report Email List"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="default_continuity_builder_email_address"
                    label="Default Continuity Builder Email"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="default_deal_email_address"
                    label="Default Deal Email"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="recruitment_email"
                    label="Recruitment Email"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField id="ats_email" label="ATS Email" data={company.data} action={updateCompany} />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Transactional Emails</h1>
                  </AppBar>
                  <Typography variant="title" className={classes.typo}>
                    Viewed Profile Emails
                  </Typography>
                  <Typography variant="title" className={classes.subtext}>
                    Send weekly on the following days:
                  </Typography>
                  <List className={classes.listItem}>
                    {weekdays.map(day => (
                      <ListItem
                        key={day}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleToggleViewedProfileEmailDates(day)}>
                        <Checkbox
                          checked={companyViewedProfileMailerSendDates.includes(day)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={day} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="title" className={classes.subtext}>
                    Viewed Profile Emails - Date Range
                  </Typography>
                  <List className={classes.listItem}>
                    {emailDateRange.map(range => (
                      <ListItem
                        key={range}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleToggleViewedProfileEmailDateRange(range)}>
                        <Checkbox
                          checked={companyViewedProfileMailerDateRange.includes(range)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={range > 1 ? `${range} days` : `${range} day`} />
                      </ListItem>
                    ))}
                  </List>
                  <hr />
                  <Typography variant="title" className={classes.typo}>
                    New Users In Proximity Email
                  </Typography>
                  <Typography variant="title" className={classes.subtext}>
                    Send weekly on the following days:
                  </Typography>
                  <List className={classes.listItem}>
                    {weekdays.map(day => (
                      <ListItem
                        key={day}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleToggleNewUsersInProximityEmailDates(day)}>
                        <Checkbox
                          checked={companyNewUsersInProximityEmailSendDates.includes(day)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={day} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="title" className={classes.subtext}>
                    New Users In Proximity Mailer - Date Range
                  </Typography>
                  <List className={classes.listItem}>
                    {emailDateRange.map(range => (
                      <ListItem
                        key={range}
                        role={undefined}
                        dense
                        button
                        onClick={this.handleToggleNewUsersInProximityEmailDateRange(range)}>
                        <Checkbox
                          checked={companyNewUsersInProximityEmailDateRange.includes(range)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={range > 1 ? `${range} days` : `${range} day`} />
                      </ListItem>
                    ))}
                  </List>
                  {partialSaleEmail && (
                    <>
                      <hr />
                      <Typography variant="title" className={classes.typo}>
                        Partial Sales Email
                      </Typography>
                      <Typography variant="title" className={classes.subtext}>
                        Send weekly on the following days:
                      </Typography>
                      <List className={classes.listItem}>
                        {weekdays.map(day => (
                          <ListItem
                            key={day}
                            role={undefined}
                            dense
                            button
                            onClick={this.handleTogglePartialSalesEmailDates(day)}>
                            <Checkbox
                              checked={companyPartialSalesMailerSendDates.includes(day)}
                              tabIndex={-1}
                              disableRipple
                            />
                            <ListItemText primary={day} />
                          </ListItem>
                        ))}
                      </List>
                      <Typography variant="title" className={classes.subtext}>
                        Partial Sales Mailer - Date Range
                      </Typography>
                      <List className={classes.listItem}>
                        {emailDateRange.map(range => (
                          <ListItem
                            key={range}
                            role={undefined}
                            dense
                            button
                            onClick={this.handleTogglePartialSalesEmailDateRange(range)}>
                            <Checkbox
                              checked={companyPartialSalesMailerDateRange.includes(range)}
                              tabIndex={-1}
                              disableRipple
                            />
                            <ListItemText primary={range > 1 ? `${range} days` : `${range} day`} />
                          </ListItem>
                        ))}
                      </List>
                    </>
                  )}
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Documentation Integration</h1>
                  </AppBar>
                  <SimpleInputField
                    id="succession_guide_documentation"
                    label="Succession Guide Documentation"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="succession_guide_thumbnail_image_url"
                    label="Succession Guide Thumbnail Image URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="succession_guide_leaderboard_image_url"
                    label="Succession Guide Leaderboard Image URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="buyer_guide_documentation"
                    label="Buyer Guide Documentation"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="buyer_guide_thumbnail_image_url"
                    label="Buyer Guide Thumbnail Image URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="buyer_guide_leaderboard_image_url"
                    label="Buyer Guide Leaderboard Image URL"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Company Specific "Words"</h1>
                  </AppBar>
                  <SimpleInputField
                    id="agent_word"
                    label="Agent word (enter the word you would like to replace 'agent' with)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="recruitment_agent_word"
                    label="Recruitment Agent Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="recruitment_team_word"
                    label="Recruitment Team Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="recruitment_organization_word"
                    label="Recruitment Organization Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="seller_only_label"
                    label="Seller Only Label"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="stylesheet_name"
                    label="Stylesheet Name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="deal_room_branded_name"
                    label="Deal Room Branded Name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="deal_room_branded_name_fr"
                    label="Deal Room Branded Name (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="deal_room_single_word"
                    label="Deal Room Single Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="deal_room_single_word_fr"
                    label="Deal Room Single Word (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="user_name_prefix"
                    label="Prefix for user_name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="catastrophic_planning_word"
                    label="Catastrophic Planning Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                      id="catastrophic_planning_word_fr"
                      label="Catastrophic Planning Word (French)"
                      data={company.data}
                      action={updateCompany}
                  />
                  <SimpleInputField
                    id="file_import_disclaimer"
                    label="File Import Disclaimer"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                      id="valuation_disclaimer_en"
                      label="Valuation Disclaimer English"
                      data={company.data}
                      action={updateCompany}
                  />
                  <SimpleInputField
                      id="valuation_disclaimer_fr"
                      label="Valuation Disclaimer French"
                      data={company.data}
                      action={updateCompany}
                  />
                  <SimpleInputField
                    id="insurance_commissions_information_tooltip"
                    label="Insurance commissions information tooltip"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="trails_word"
                    label="Trails Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="cash_flow_analysis_word"
                    label="Cash Flow Analysis Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="cash_flow_analysis_word_fr"
                    label="Cash Flow Analysis Word (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_team_name"
                    label="Featured Support Summary Team Name"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_team_name_fr"
                    label="Featured Support Summary Team Name (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_short_copy"
                    label="Featured Support Summary Short Copy"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_short_copy_fr"
                    label="Featured Support Summary Short Copy (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_long_copy"
                    label="Featured Support Summary Long Copy"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_support_summary_long_copy_fr"
                    label="Featured Support Summary Long Copy (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_1_url"
                    label="Featured Education Content 1 URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_1_thumbnail_url"
                    label="Featured Education Content 1 Thumbnail URL"
                    data={company.data}
                    action={updateCompany}
                  />  
                  <SimpleInputField
                    id="featured_education_content_1_title"
                    label="Featured Education Content 1 Title"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_2_url"
                    label="Featured Education Content 2 URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_2_thumbnail_url"
                    label="Featured Education Content 2 Thumbnail URL"
                    data={company.data}
                    action={updateCompany}
                  />  
                  <SimpleInputField
                    id="featured_education_content_2_title"
                    label="Featured Education Content 2 Title"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_3_url"
                    label="Featured Education Content 3 URL"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="featured_education_content_3_thumbnail_url"
                    label="Featured Education Content 3 Thumbnail URL"
                    data={company.data}
                    action={updateCompany}
                  />  
                  <SimpleInputField
                    id="featured_education_content_3_title"
                    label="Featured Education Content 3 Title"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="cashflow_payout_word"
                    label="Cashflow Payout Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="student_word"
                    label="Student Word"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="student_program_title"
                    label="Student Program Title"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="partial_sale_name"
                    label="Partial Sale Name"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Footer</h1>
                  </AppBar>
                  <SimpleInputField
                    id="email_footer_banner"
                    label="Email - Footer Banner"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="about"
                    label="Footer - About Us (Blank = Default)"
                    placeholder="Leave blank for default Findbob copy"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="about_fr"
                    label="Footer - About Us (French)"
                    placeholder=""
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="contact_copy"
                    label="Footer - Contact Copy (Blank = Default)"
                    placeholder="Leave blank for default Findbob copy"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="contact_copy_fr"
                    label="Footer - Contact Copy French (Blank = Default)"
                    placeholder=""
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="contact_email"
                    label="Footer - Contact Email"
                    data={company.data}
                    action={updateCompany}
                  />
                </Paper>
                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp;Marketplace Settings</h1>
                  </AppBar>
                  <SimpleInputField
                      id="theme_name"
                      label="Theme Name"
                      data={company.data}
                      action={updateCompany}
                  />
                  <SimpleInputField
                    id="background_color"
                    label="Background Color"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField id="primary_color" label="Primary Color" data={company.data} action={updateCompany} />
                  <SimpleInputField
                    id="secondary_color"
                    label="Secondary Color"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField id="neutral_color" label="Neutral Color" data={company.data} action={updateCompany} />
                  <SimpleInputField id="accent_color" label="Accent Color" data={company.data} action={updateCompany} />
                  <SimpleInputField id="country" label="Country" data={company.data} action={updateCompany} />

                  <SimpleInputField
                    id="go_live"
                    labelProps={{ shrink: true }}
                    label="Go Live Date"
                    type="datetime-local"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="copyright_notice"
                    label="Copyright Notice"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="copyright_notice_fr"
                    label="Copyright Notice (French)"
                    data={company.data}
                    action={updateCompany}
                  />
                  <hr />
                  <SimpleInputField
                    id="total_licensed_users"
                    label="Total Licensed Users"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="cash_flow_financing_interest_rate"
                    label="Cash Flow Financing Interest Rate"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="chat_prompt_seconds_delay"
                    label="Chat Prompt Seconds Delay"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="admin_home_office_scheduling_link"
                    label="Admin Home Office Scheduling Link"
                    data={company.data}
                    action={updateCompany}
                  />
                  <Typography variant="title" className={classes.typo}>
                    Locales
                  </Typography>
                  <List className={classes.listItem}>
                    {locales.map(lang => (
                      <ListItem
                        key={lang}
                        role={undefined}
                        dense
                        button
                        disabled={lang === 'en'}
                        onClick={this.handleToggle(lang)}>
                        <Checkbox checked={companyLocales.includes(lang)} tabIndex={-1} disableRipple />
                        <ListItemText primary={lang} />
                      </ListItem>
                    ))}
                  </List>
                  {companyLocales.includes('fr') && (
                    <SimpleInputField
                      id="agent_word_fr"
                      label="FRENCH - Agent word (enter the word you would like to replace 'agent' with)"
                      data={company.data}
                      action={updateCompany}
                    />
                  )}
                  {companyLocales.includes('fr') && (
                    <SimpleInputField
                      id="branded_name_fr"
                      label="FRENCH - Branded Name"
                      data={company.data}
                      action={updateCompany}
                    />
                  )}
                  <SimpleInputField
                      id="sign_up_page_compliance_copy"
                      label="Sign Up Page Compliance Copy"
                      data={company.data}
                      action={updateCompany}
                      type="textarea"
                    />
                    <br/>
                  <ToggleButton
                    checked={company.data.has_billing || false}
                    fullWidth
                    label="Has Billing"
                    onChange={e => {
                      this.updateCompanyBool('has_billing', e);
                    }}
                  />
                    <br/>
                  <ToggleButton
                    checked={company.data.uses_enterprise_authorized_users || false}
                    fullWidth
                    label="Uses Enterprise Authorized Users"
                    onChange={e => {
                      this.updateCompanyBool('uses_enterprise_authorized_users', e);
                    }}
                  />
                  <br/>
                  <Typography variant="title" className={classes.typo}>
                    Business Type
                  </Typography>
                  <Select
                    className={classes.control}
                    id="business_type"
                    value={company.data.business_type}
                    onChange={e => {
                      let { company, updateCompany } = this.props;
                      company = company.data;

                      company.business_type = e.target.value;
                      updateCompany(company);
                    }}
                    inputProps={{
                      name : 'business_type',
                      id   : 'business_type',
                    }}>
                    {keys(company.data.business_types).map(bt => (
                      <MenuItem id="business_type" value={bt}>
                        {bt}
                      </MenuItem>
                    ))}
                  </Select>
                  <br/> 
                  <Typography variant="title" className={classes.typo}>
                    Marketplace Type
                  </Typography>
                  <Select
                    className={classes.control}
                    id="marketplace_type"
                    value={company.data.marketplace_type}
                    onChange={e => {
                      company.data.marketplace_type = e.target.value;
                      updateCompany(company.data);
                    }}
                    inputProps={{
                      name : 'marketplace_type',
                      id   : 'marketplace_type',
                    }}>
                    {keys(company.data.marketplace_types).map(bt => (
                      <MenuItem id="marketplace_type" value={bt}>
                        {bt}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="title" className={classes.typo}>
                    eSignature Partner
                  </Typography>
                  <Select
                    className={classes.control}
                    id="esignature_type"
                    value={company.data.esignature_type}
                    onChange={e => {
                      company.data.esignature_type = e.target.value;
                      updateCompany(company.data);
                    }}
                    inputProps={{
                      name : 'esignature_type',
                      id   : 'esignature_type',
                    }}>
                    {keys(company.data.esignature_types).map(bt => (
                      <MenuItem id="esignature_type" value={bt}>
                        {bt}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="title" className={classes.typo}>
                    Automatically Calibrate Impact On
                  </Typography>
                  <Select
                    className={classes.control}
                    id="automatically_calibrate_impact_on"
                    value={company.data.automatically_calibrate_impact_on}
                    onChange={e => {
                      company.data.automatically_calibrate_impact_on = e.target.value;
                      updateCompany(company.data);
                    }}
                    inputProps={{
                      name : 'automatically_calibrate_impact_on',
                      id   : 'automatically_calibrate_impact_on',
                    }}>
                      <MenuItem id="automatically_calibrate_impact_on" value="total_assets">
                        total_assets
                      </MenuItem>
                      <MenuItem id="automatically_calibrate_impact_on" value="data->'gross_production'">
                        gross_production
                      </MenuItem>
                      <MenuItem id="automatically_calibrate_impact_on" value="total_premium">
                        total_premium
                      </MenuItem>
                  </Select>
                  <SimpleInputField
                    id="docusign_rsa_key"
                    label="Docusign Key"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="new_recruit_value"
                    label="New Recruit Value"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="branch_exceptions"
                    label="Branch Exceptions"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="meeting_room_suggestion_threashold"
                    label="Meeting Room Suggestion Threashold"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />

                  <SimpleInputField
                    id="total_licensed_users"
                    label="Total Licensed Users"
                    data={company.data}
                    action={updateCompany}
                  />
                  <SimpleInputField
                    id="cash_flow_financing_interest_rate"
                    label="Cash Flow Financing Interest Rate"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />
                  <Typography variant="title" className={classes.typo}>
                    Pipeline Card Default Value Type
                  </Typography>
                  <Select
                    className={classes.control}
                    id="pipeline_card_default_value_type"
                    value={company.data.pipeline_card_default_value_type}
                    onChange={e => {
                      let { company, updateCompany } = this.props;
                      company = company.data;

                      company.pipeline_card_default_value_type = e.target.value;
                      updateCompany(company);
                    }}
                    inputProps={{
                      name : 'pipeline_card_default_value_type',
                      id   : 'pipeline_card_default_value_type',
                    }}>
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="assets">Assets</MenuItem>
                    <MenuItem value="revenue">Revenue</MenuItem>
                  </Select>
                  <Typography variant="title" className={classes.typo}>
                    Pipeline Card Default Value Source
                  </Typography>
                  <Select
                    className={classes.control}
                    id="pipeline_card_default_value_source"
                    value={company.data.pipeline_card_default_value_source}
                    onChange={e => {
                      let { company, updateCompany } = this.props;
                      company = company.data;

                      company.pipeline_card_default_value_source = e.target.value;
                      updateCompany(company);
                    }}
                    inputProps={{
                      name : 'pipeline_card_default_value_source',
                      id   : 'pipeline_card_default_value_source',
                    }}>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="inventory_user">Inventory User</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                </Paper>
                <Paper className={classes.dividerForm}>
                <Typography variant="title" className={classes.typo}>
                  Pipeline Card Default Value Type
                </Typography>
                <Select
                  className={classes.control}
                  id="pipeline_card_default_value_type"
                  value={company.data.pipeline_card_default_value_type}
                  onChange={e => {
                    let { company, updateCompany } = this.props;
                    company = company.data;

                    company.pipeline_card_default_value_type = e.target.value;
                    updateCompany(company);
                  }}
                  inputProps={{
                    name : 'pipeline_card_default_value_type',
                    id   : 'pipeline_card_default_value_type',
                  }}>
                  <MenuItem value="none">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="assets">Assets</MenuItem>
                  <MenuItem value="revenue">Revenue</MenuItem>
                </Select>
                <Typography variant="title" className={classes.typo}>
                  Pipeline Card Default Value Source
                </Typography>
                <Select
                  className={classes.control}
                  id="pipeline_card_default_value_source"
                  value={company.data.pipeline_card_default_value_source}
                  onChange={e => {
                    let { company, updateCompany } = this.props;
                    company = company.data;

                    company.pipeline_card_default_value_source = e.target.value;
                    updateCompany(company);
                  }}
                  inputProps={{
                    name : 'pipeline_card_default_value_source',
                    id   : 'pipeline_card_default_value_source',
                  }}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="inventory_user">Inventory User</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                </Select>
                <SimpleInputField
                    id="rjas_benchmark_expense_percentage"
                    label="Rjas Benchmark Expense Percentage"
                    type="number"
                    data={company.data}
                    action={updateCompany}
                  />
              </Paper>
              <Paper className={classes.dividerForm}>
                <AppBar position="static" color="primary">
                  <h1 className={classes.title}>&nbsp;Proof Point</h1>
                </AppBar>
                <ToggleButton
                  checked={company.data.process_proof_point_messages || false}
                  label="Provess Proof Point Messages"
                  onChange={e => {
                    this.updateCompanyBool('process_proof_point_messages', e);
                  }}
                />
                <ToggleButton
                  checked={company.data.process_proof_point_intercom || false}
                  label="Process Proof Point Intercom"
                  onChange={e => {
                    this.updateCompanyBool('process_proof_point_intercom', e);
                  }}
                />
                <SimpleInputField id="proof_point_intercom_company_id" label="Intercom Company Name" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_media_type" label="Proof Point Media Type" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_envelope_to" label="Proof Point Envelope To" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_match_regex" label="Proof Point Match Regex" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_findbob_bcc_email" label="Proof Point FindBob BCC Email" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_increment" label="Proof Point Increment" data={company.data} action={updateCompany} />
                <SimpleInputField id="proof_point_increment_unit" label="Proof Point Invcrement Unit" data={company.data} action={updateCompany} />
                </Paper>

                <Paper className={classes.dividerForm}>
                  <AppBar position="static" color="primary">
                    <h1 className={classes.title}>&nbsp; Rates (Current Prime Rate: {numeral(company.data.prime_rate).format('0.0%')})</h1>
                  </AppBar>
                  <br/>
                  <Typography variant="title" className={classes.typo}>
                    Loan Rate (Current Rate: {numeral(company.data.prime_rate_for_loan).format('0.0%')})
                  </Typography>
                  <br/>
                  <ToggleButton
                    checked={company.data.loan_rate_use_override || false}
                    fullWidth
                    label="Use Override"
                    onChange={e => {
                      this.updateCompanyBool('loan_rate_use_override', e);
                    }}
                  />
                  {!company.data.loan_rate_use_override ?
                  <SimpleInputField
                    id="loan_prime_rate_plus_minus"
                    label="Plus Minus"
                    data={company.data}
                    action={updateCompany}
                  />
                  :
                  <SimpleInputField
                    id="loan_rate_override"
                    label="Rate Override"
                    data={company.data}
                    action={updateCompany}
                  />
                  }
                  <br/>
                  <Typography variant="title" className={classes.typo}>
                    DCF Cost of Capital Rate (Current Rate: {numeral(company.data.prime_rate_for_dcf).format('0.0%')})
                  </Typography>
                  <br/>
                  <ToggleButton
                    checked={company.data.dcf_rate_use_override || false}
                    fullWidth
                    label="Use Override"
                    onChange={e => {
                      this.updateCompanyBool('dcf_rate_use_override', e);
                    }}
                  />
                  {!company.data.dcf_rate_use_override ?
                    <SimpleInputField
                      id="dcf_prime_rate_plus_minus"
                      label="Plus Minus"
                      data={company.data}
                      action={updateCompany}
                    />
                    :
                    <SimpleInputField
                      id="dcf_rate_override"
                      label="Rate Override"
                      data={company.data}
                      action={updateCompany}
                    />
                  }
                  <br/>
                  <Typography variant="title" className={classes.typo}>
                    Cash Flow Financing Rate (Current Rate: {numeral(company.data.prime_rate_for_cash_flow).format('0.0%')})
                  </Typography>
                  <br/>
                  <ToggleButton
                    checked={company.data.cash_flow_financing_rate_use_override || false}
                    fullWidth
                    label="Use Override"
                    onChange={e => {
                      this.updateCompanyBool('cash_flow_financing_rate_use_override', e);
                    }}
                  />
                  {!company.data.cash_flow_financing_rate_use_override ?
                    <SimpleInputField
                      id="cash_flow_financing_prime_rate_plus_minus"
                      label="Plus Minus"
                      data={company.data}
                      action={updateCompany}
                    />
                    :
                    <SimpleInputField
                      id="cash_flow_financing_rate_override"
                      label="Rate Override"
                      data={company.data}
                      action={updateCompany}
                    />}
                </Paper>
                <SaveButton
                  onSubmit={e => this.submitComp(e, enqueueSnackbar)}
                  success={success}
                  success={loading}
                  label="Save"
                />
                {error && <FormHelperText>{error}</FormHelperText>}
              </form>
              <br />
            </Grid>
          }
          { this.state.tabValue === 1 &&
          <Grid container>
            <FeatureForm company={company} updateCompany={updateCompany} />
          </Grid>
          }
        </Grid>
      </div>
    );
  }
}

CompanyForm.propTypes = {
  company                : PropTypes.object.isRequired,
  updateCompany          : PropTypes.func.isRequired,
  submitCompany          : PropTypes.func.isRequired,
  classes                : PropTypes.object.isRequired,
  generateCompanyPayload : PropTypes.func.isRequired,
  loadCompany            : PropTypes.func.isRequired,
  params                 : PropTypes.object.isRequired,
  match                  : PropTypes.object.isRequired,
  enqueueSnackbar        : PropTypes.func.isRequired,
};

export default withStyles(styles)(withSnackbar(CompanyForm));
