import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import BuyersAndSellersPage from '../components/BuyersAndSellersPage/BuyersAndSellersPage'

const sacBuyersAndSellersData = gql`
  query {
    sacAllUserCount
  	sacBuyers
    sacSellers
    sacBuyersAndSellers
    sacPureBuyers
    sacPureSellers
  }
`;

const BuyersAndSellersPageValue = graphql(sacBuyersAndSellersData)(BuyersAndSellersPage);

const BuyersAndSellersPageContainer = (props) => (
  <ApolloProvider client={client}>
    <BuyersAndSellersPageValue props={props} />
  </ApolloProvider>
);

export default BuyersAndSellersPageContainer;
