import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { get } from 'lodash';

const styles = {
  paper : {
    padding      : 14,
    marginBottom : 14,
    marginTop    : 14,
  },
  label : {
    verticalAlign : 'bottom',
  },
};

const TitleAndDescription = ({ opportunity, classes, opportunityTypes, updateAction }) => (
  <Paper className={classes.paper}>
    <h4>Opportunity Type</h4>
    <Select
      inputProps={{ name: 'opportunity_type' }}
      onChange={e => updateAction({ opportunity_type: opportunityTypes.find(t => t.id === e.target.value) })}
      value={get(opportunity, 'opportunity_type.id')}>
      {opportunityTypes.map(ot => <MenuItem value={ot.id}>{ot.name}</MenuItem>)}
    </Select>
    <h4>External Title</h4>
    <div>
      <p>Use words people would search for when looking</p>
      <TextField
        type="text"
        variant="filled"
        fullWidth
        value={opportunity.title}
        onChange={e => {
          updateAction({ title: e.target.value });
        }}
      />
    </div>
    <br />
    <h4>Details</h4>
    <div>
      <p>Select specific details about your opportunity to help users find it quickly.</p>
      <b className={classes.label}>Expiration: </b>
      <TextField
        type="date"
        variant="filled"
        inputProps={{ min: moment().format('YYYY-MM-DD') }}
        value={opportunity.expiry_date}
        min={Date.now()}
        onChange={e => {
          updateAction({ expiry_date: e.target.value });
        }}
      />
    </div>
    <br />
    <h4>External Description</h4>
    <div>
      <p>Provide a more specific description to help external candidates understand the opportunity</p>
      <TextField
        type="text"
        variant="filled"
        multiline
        rows="5"
        fullWidth
        value={opportunity.description}
        onChange={e => {
          updateAction({ description: e.target.value });
        }}
      />
    </div>
  </Paper>
);

TitleAndDescription.propTypes = {
  opportunity      : PropTypes.object,
  classes          : PropTypes.object.isRequired,
  updateAction     : PropTypes.func.isRequired,
  opportunityTypes : PropTypes.array,
};

TitleAndDescription.defaultProps = {
  opportunity : {},
};

export default withStyles(styles)(TitleAndDescription);
