import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const NextStep = ({ classes }) => {
  return (
    <div className={classes.status}>
      <strong><FormattedMessage id="profile_page.match_profile.next_step"/></strong>
      <p><FormattedMessage id="profile_page.match_profile.add_this_profile_to_your_shortlist"/></p>
    </div>
  );
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(NextStep);
