import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { get, upperCase } from 'lodash';
import IdentityLogin from './IdentityLogin';
import ContactInformation from './ContactInformation';
import PostalAndPhone from '../SignUpWizard/PostalAndPhone';
import PracticeInformation from './PracticeInformation';
import StrengthsAndAvatar from './StrengthsAndAvatar';
import ProductMix from './ProductMix';
import PlanningInfo from './PlanningInfo';
import CandidateDescription from './CandidateDescription';
import NewInBusinessForm from './NewInBusinessForm';
import AdvisorLicenseComponent from '../SignUpWizard/AdvisorLicenseComponent';

class LoginOptionDialog extends PureComponent {
  STEPS = {
    NEW_IN_BUSINESS: 'NEW_IN_BUSINESS',
    CANDIDATE_DESCRIPTION: 'CANDIDATE_DESCRIPTION',
    PLANNING_INFO: 'PLANNING_INFO',
    STRENGTHS_AND_AVATAR: 'STRENGTHS_AND_AVATAR',
    PRACTICE_INFORMATION: 'PRACTICE_INFORMATION',
    PRODUCT_MIX: 'PRODUCT_MIX',
    POSTAL_AND_PHONE: 'POSTAL_AND_PHONE',
    LOGIN: 'LOGIN',
  };

  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      loginOption: null,
    };
  }

  renderDialog = () => {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <p className={classes.text}>
          By creating an account and telling us a little bit about you, we&apos;ll keep you abreast of new opportunities
          and one of our {get(this.props, 'user.company.recruitment_team_word')} teammates will review your profile.
        </p>
        <div className={classes.signup}>
          <Button variant="contained" color="primary" onClick={() => this.setState({ loginOption: 'create' })}>
            Continue
          </Button>
        </div>
        <DialogActions>
          <Button color="primary" onClick={() => this.setState({ loginOption: 'currentUser' })}>
            I already have an account - Sign In
          </Button>
        </DialogActions>
      </div>
    );
  };

  renderStep = step => {
    const {
      updateUser,
      updateProductMix,
      availableDesignations,
      availableStrengths,
      availableProducts,
      availableAdvisorLicenses,

      agentWord,
    } = this.props;
    const { loginOption } = this.state;
    const { STEPS } = this;
    const user = get(this.props, 'user', {});

    switch (step) {
      case STEPS.NEW_IN_BUSINESS:
        return (
          <NewInBusinessForm
            onNext={() => {
              window.location.reload();
            }}
          />
        );
      case STEPS.CANDIDATE_DESCRIPTION:
        return (
          <CandidateDescription
            opportunity={null}
            company={get(user, 'company')}
            onNext={() => {
              window.location.href = '/opportunities/thanks';
            }}
          />
        );
      case STEPS.STRENGTHS_AND_AVATAR:
        return (
          <StrengthsAndAvatar
            userId={user.tag_list}
            professionalStrengths={user.tag_list}
            updateAction={updateUser}
            availableDesignations={availableDesignations}
            designations={user.designations}
            assets_under_management={user.assets_under_management}
            availableStrengths={availableStrengths}
            modalTitle="Almost Done!"
            modalSubtitle="Tell us where your practice strengths lie and if you hold any professional designations."
            disableAvatar
            onNext={() => {
              window.location.reload();
            }}
            onBack={null}
          />
        );
      case STEPS.PRODUCT_MIX:
        const productMix = get(user, 'product_mix', {});

        return (
          <ProductMix
            userId={user.tag_list}
            user={user}
            updateAction={updateProductMix}
            life_ifp={productMix.life_ifp}
            home_ifp={productMix.home_ifp}
            auto_ifp={productMix.auto_ifp}
            commercial_ifp={productMix.commercial_ifp}
            health_ifp={productMix.health_ifp}
            group_ifp={productMix.group_ifp}
            mutual_funds_aum={productMix.mutual_funds_aum}
            securities_aum={productMix.securities_aum}
            annuities_aua={productMix.annuities_aua}
            segregated_funds_aua={productMix.segregated_funds_aua}
            mcpi_ifp={productMix.mcpi_ifp}
            private_products_ifp={productMix.private_products_ifp}
            crop_hail_ifp={productMix.crop_hail_ifp}
            crop_hail_with_wind_ifp={productMix.crop_hail_with_wind_ifp}
            farm_insurance_ifp={productMix.farm_insurance_ifp}
            price_products_ifp={productMix.price_products_ifp}
            replant_supplement_ifp={productMix.replant_supplement_ifp}
            availableProducts={availableProducts}
            fullName={user.name}
            modalTitle="Almost Done!"
            modalSubtitle="Tell us where your practice strengths lie and if you hold any professional designations."
            disableAvatar
            onNext={() => {
              window.location.reload();
            }}
            onBack={null}
            requiredFields={[
              'product_mix.life_ifp',
              'product_mix.home_ifp',
              'product_mix.auto_ifp',
              'product_mix.commercial_ifp',
              'product_mix.group_ifp',
              'product_mix.health_ifp',
              'product_mix.mutual_funds_aum',
              'product_mix.securities_aum',
              'product_mix.annuities_aua',
              'product_mix.segregated_funds_aua',
              'product_mix.mcpi_ifp',
              'product_mix.private_products_ifp',
              'product_mix.crop_hail_ifp',
              'product_mix.crop_hail_with_wind_ifp',
              'product_mix.farm_insurance_ifp',
              'product_mix.price_products_ifp',
              'product_mix.replant_supplement_ifp',
            ]}
            companyIsWealthType={(get(user, 'company.business_type') === 'wealth' ||
              get(user, 'company.business_type') === 'wealth_rj')}>

            {(get(user, 'company.business_type') === 'wealth' || get(user, 'company.business_type') === 'wealth_rj') && (
              <AdvisorLicenseComponent
                availableAdvisorLicenses={availableAdvisorLicenses}
                updateAction={updateUser}
                userAdvisorLicenses={user.advisor_licenses}
              />
            )}
          </ProductMix>
        );
      case STEPS.PLANNING_INFO:
        return (
          <PlanningInfo
            user={user}
            updateAction={updateUser}
            company={get(user, 'company')}
            onBack={null}
            onNext={() => {
              window.location.reload();
            }}
            agentWord={agentWord}
          />
        );
      case STEPS.PRACTICE_INFORMATION:
        return (
          <PracticeInformation
            year_career_began={user.year_career_began}
            years_practicing={user.years_practicing}
            number_of_clients={user.number_of_clients}
            avg_client_age={user.avg_client_age}
            annual_revenue={user.annual_revenue}
            percent_commission={user.percent_commission || 100}
            percent_fee_based={user.percent_fee_based || 0}
            recurring_revenue={user.recurring_revenue}
            percent_revenue_recurring={user.percent_revenue_recurring}
            percent_non_recurring_revenue={user.percent_non_recurring_revenue}
            transition_goals={user.transition_goals || []}
            locale={user.locale}
            updateAction={updateUser}
            modalTitle="Tell Us More!"
            modalSubtitle="Tell us a bit about your practice to help us understand where you're at in your career and allow us to curate opportunities internally."
            onNext={() => {
              window.location.reload();
            }}
          />
        );
      case STEPS.POSTAL_AND_PHONE:
        return (
          <PostalAndPhone
            postal_code={user.postal_code}
            phone_number={user.phone_number}
            allowEmail={false}
            updateAction={updateUser}
            modalTitle="Tell Us More!"
            modalSubtitle="Tell us a bit about your practice to help us understand where you're at in your career and allow us to curate opportunities internally."
            displayBusinessQuestion
            onNext={() => {
              this.forceUpdate();
            }}
          />
        );
      default:
        return this.loginOptions()[loginOption || 'null'];
    }
  };

  titleForStep = step => {
    const { STEPS } = this;
    const { showIdentity } = this.state;
    const { user } = this.props;
    const company = get(user, 'company');

    switch (step) {
      case STEPS.CANDIDATE_DESCRIPTION:
        return 'Tell Us Why';
      case STEPS.NEW_IN_BUSINESS:
      case STEPS.STRENGTHS_AND_AVATAR:
      case STEPS.PRACTICE_INFORMATION:
      case STEPS.POSTAL_AND_PHONE:
      default:
        return showIdentity
          ? 'Sign in to your account'
          : `Interested in becoming ${this.returnA(get(company, 'display_name'))} ${get(company, 'display_name')} ${get(
            company,
            'recruitment_agent_word'
          )}?`;
    }
  };

  getCurrentStep = () => {
    const { STEPS } = this;
    const user = get(this.props, 'user');

    if (get(user, 'id')) {
      if (user.has_postal_code) {
        if (user.new_in_industry) {
          if (!user.has_candidate_profile) {
            return STEPS.NEW_IN_BUSINESS;
          }
          return STEPS.CANDIDATE_DESCRIPTION;
        }
        if (user.planning_info_complete) {
          return STEPS.CANDIDATE_DESCRIPTION;
        }
        if (user.product_mix_complete) {
          return STEPS.PLANNING_INFO;
        }
        if (user.strengths_complete) {
          return STEPS.PRODUCT_MIX;
        }
        if (user.practice_complete) {
          return STEPS.STRENGTHS_AND_AVATAR;
        }

        return STEPS.PRACTICE_INFORMATION;
      }
      return STEPS.POSTAL_AND_PHONE;
    }
    return STEPS.LOGIN;
  };

  loginOptions = () => ({
    null: this.renderDialog(),
    currentUser: <IdentityLogin />,
    create: (
      <ContactInformation
        onCreate={() => {
          window.location.reload();
        }}
      />
    ),
    linkedIn: <div>LinkedIn placeholder</div>,
  });

  returnA = name => {
    const char = upperCase(name.charAt(0));
    if (char === 'A' || char === 'E' || char === 'I' || char === 'O' || char === 'U') {
      return 'an';
    }
    return 'a';
  };

  render() {
    const { classes, fullScreen, user } = this.props;
    const company = get(user, 'company');
    const { open, showIdentity } = this.state;
    const step = this.getCurrentStep();

    return (
      <Dialog open={open} aria-labelledby="form-dialog-title" fullScreen={fullScreen}>
        <DialogTitle id="form-dialog-title" className={classes.title}>
          {showIdentity ? (
            'Sign in to your account'
          ) : (
              `Interested in becoming ${this.returnA(get(company, 'display_name'))} ${get(company, 'display_name')} ${get(
                company,
                'recruitment_agent_word'
              )}?`
            )}
        </DialogTitle>
        <DialogContent>{this.renderStep(step)}</DialogContent>
      </Dialog>
    );
  }
}

LoginOptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  updateUser: PropTypes.func.isRequired,
  availableDesignations: PropTypes.array,
  availableStrengths: PropTypes.array,
  history: PropTypes.object,
};

LoginOptionDialog.defaultProps = {
  open: false,
  availableDesignations: [],
  availableStrengths: [],
  history: {},
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#333',
    padding: '0 20px',
  },
  signup: {
    padding: '20px 0',
    borderTop: '1px solid #F1F2F7',
  },
  title: {
    textAlign: 'center',
  },
  text: {
    color: '#333',
  },
};

export default withStyles(styles)(withMobileDialog()(LoginOptionDialog));
