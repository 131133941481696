import React from 'react';
import PropTypes from 'prop-types';
import {ApolloConsumer, Mutation} from 'react-apollo';
import { get, debounce } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import numeral from 'numeral';
import MUIDataTable from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ColumnIcons from '@material-ui/icons/TableChartOutlined';
import PrintIcon from '@material-ui/icons/CloudDownload';
import { FormGroup, FormLabel, ListItemText, TextField, Checkbox, Grid, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import Input from '@material-ui/core/Input';
import { PipelineCardQuery } from './Board';
import PipelineCardDrawer from './PipelineCardDrawer';
import { printPipelineMutation } from './PipelineQueries';

const myTheme = () =>
  createMuiTheme({
    overrides : {
      MUIDataTableBodyCell : {
        root : {
          fontSize : 10,
        },
      },
      MUIDataTable         : {
        responsiveScroll : {
          height    : 'calc(100vh - 250px)',
          maxHeight : 'calc(100vh - 250px) !important',
        },
      },
    },
  });

class PipelineListComponent extends React.Component {
  changePage = debounce(
    ({ currentPage, searchText, refetch, changedColumn, direction, valueFilter, pipelineFilterArr }) => {
      const { match, updatePipeline, pipeline } = this.props;
      const { dealId, filterParams } = this.state;
      let page = currentPage || this.state.page;
      const search = searchText || this.state.search;
      const column = changedColumn || this.state.column;
      const newPipelineFilterAtt = pipelineFilterArr || pipeline.stageFilter;
      let columnDirection;
      if (direction) {
        columnDirection = direction === 'descending' ? 'desc' : 'asc';
      } else {
        columnDirection = this.state.columnDirection;
      }
      if (currentPage === 0) {
        page = currentPage;
      }
      const mergedFilters = { ...filterParams, ...valueFilter, pipeline_names: newPipelineFilterAtt };
      if (
        currentPage !== this.state.page ||
        searchText !== this.state.search ||
        changedColumn !== this.state.column ||
        direction !== this.state.columnDirection
      ) {
        if (searchText && searchText !== this.state.search) {
          page = 0;
        }
        this.setState({ page, search, column, columnDirection, dealId, filterParams: mergedFilters }, () => {
          const variables = {
            id              : get(match, 'params.id'),
            cardsPage       : page + 1,
            column,
            columnDirection,
            dealId,
            filterParams    : mergedFilters,
          };
          if (search) {
            variables.searchText = search;
          }

          refetch(variables).then(response => {
            const pl = get(response, 'data.pipeline');
            updatePipeline({ ...pl, page, searchText, changedColumn, direction, stageFilter: newPipelineFilterAtt });
          });
        });
      }
    },
    300
  );

  constructor (props) {
    super(props);
    this.state = {
      page            : 0,
      selectedCard    : {},
      search          : null,
      column          : null,
      columnDirection : null,
      dealId          : null,
      filterParams    : {
        value      : [ null, null ],
        close_date : [ moment('01/01/2000'), moment().add(20, 'year') ],
      },
      statusFilter    : 'open',
    };
  }

  handleRangeChange = (currentValue, index, column, refetch) => {
    let curVal;
    if (currentValue) {
      curVal = Array.from(currentValue);
    } else {
      curVal = [ null, null ];
    }
    const valueFilter = {};
    valueFilter[column.name] = curVal;
    if (!curVal.includes(undefined) && !curVal.includes(null) && curVal[0] < curVal[1]) {
      this.changePage({ valueFilter, refetch });
    }
  };

  handlePipelineFilterChange = (event, refetch) => {
    const pipelineFilterArr = event.target.value;
    this.changePage({ refetch, pipelineFilterArr });
  };

  clearSearch = refetch => {
    const searchText = null;
    this.setState({ search: null });
    this.changePage({ refetch, searchText });
  };

  tableOptions = ({ refetch, client }) => {
    const { pipeline } = this.props;
    const { page, search } = this.state;
    return {
      serverSide           : true,
      onChangePage         : currentPage => this.changePage({ currentPage, refetch }),
      onSearchChange       : searchText => this.changePage({ searchText, refetch }),
      onColumnSortChange   : (changedColumn, direction) => this.changePage({ changedColumn, direction, refetch }),
      onSearchClose        : () => this.clearSearch(refetch),
      page,
      rowsPerPage          : 15,
      rowsPerPageOptions   : [ 15 ],
      count                : get(pipeline, 'card_count', 0),
      selectableRows       : false,
      isRowSelectable      : () => false,
      disableToolbarSelect : true,
      print                : false,
      download             : false,
      filter               : true,
      searchText           : search,
      responsive           : 'scroll',
      fixedHeaderOptions   : { yAxis: true },
      onRowClick           : rowData => {
        this.selectCard({ id: rowData[0] }, client);
      },
    };
  };

  selectCard = async (card, client) => {
    let result = {};
    if (card.id) {
      result = await client.query({
        query       : PipelineCardQuery,
        variables   : { id: card.id },
        fetchPolicy : 'network-only',
      });
    }
    this.setState({ selectedCard: get(result, 'data.pipelineCard', {}) });
  };

  deSelectCard = () => {
    this.setState({ selectedCard: {} });
  };

  sortDirection = col => {
    const { column, columnDirection } = this.state;

    if (column === col) {
      return columnDirection;
    }

    return 'none';
  };

  toggleTable = id => {
    window.open(`/admin/pipelines/${id}`, '_self');
  };

  changeFilter = (newStatus, newAgent, orderBy, order) => {
    const { refetch, updatePipeline } = this.props;
    const { column, columnDirection } = this.state;
    refetch({
      status          : newStatus,
      column,
      columnDirection,
    }).then(response => {
      const pl = get(response, 'data.pipeline');
      updatePipeline({ ...pl });
    });
  };

  render () {
    const { pipeline, pipelines, updatePipelineCard, refetch, loading, enqueueSnackbar } = this.props;
    const { selectedCard, search, filterParams, statusFilter } = this.state;

    const pipelineStages = get(pipeline, 'pipeline_stages', []).map(p => p.name);
    const columns = () => [
      {
        name    : 'id',
        label   : 'ID',
        options : { filter: false, sortDirection: this.sortDirection('id') },
      },
      {
        name    : 'name',
        label   : 'Name',
        options : { filter: false, sortDirection: this.sortDirection('name') },
      },
      {
        name    : 'pipeline_stages.name',
        label   : 'Pipeline Stage',
        options : {
          filter        : true,
          sortDirection : this.sortDirection('pipeline_stages.name'),
          filterType    : 'custom',
          filterOptions : {
            names   : [],
            logic (value, filters) {
              if (filters[0] && filters[1]) {
                return value < filters[0] || value > filters[1];
              }
              if (filters[0]) {
                return value < filters[0];
              }
              if (filters[1]) {
                return value > filters[1];
              }
              return false;
            },
            display : () => (
              <div>
                <Grid container>
                  <Grid xs={12}>
                    <FormLabel>Pipeline Stage</FormLabel>
                    <FormGroup row>
                      <Select
                        style={{ marginTop: 18 }}
                        multiple
                        value={get(pipeline, 'stageFilter', []).map(name => decodeURI(name))}
                        onChange={e => this.handlePipelineFilterChange(e, refetch)}
                        input={<Input id="select-multiple-placeholder" />}
                        renderValue={selected => {
                          return selected.join(', ');
                        }}>
                        {pipelineStages.map(name => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={get(pipeline, 'stageFilter', []).indexOf(name) > -1} />
                            <ListItemText primary={decodeURI(name)} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormGroup>
                  </Grid>
                </Grid>
              </div>
            ),
          },
        },
      },
      {
        name    : 'value',
        label   : 'Value',
        options : {
          filter        : true,
          sortDirection : this.sortDirection('value'),
          filterType    : 'custom',
          filterOptions : {
            names   : [],
            logic (value, filters) {
              if (filters[0] && filters[1]) {
                return value < filters[0] || value > filters[1];
              }
              if (filters[0]) {
                return value < filters[0];
              }
              if (filters[1]) {
                return value > filters[1];
              }
              return false;
            },
            display : (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Value $</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterParams.value[0] || ''}
                    error={(filterParams.value[0] || 0) > filterParams.value[1] || 0}
                    onChange={event => {
                      const filter = filterList;
                      filter[index][0] = parseInt(event.target.value, 10);
                      onChange(filter[index], index, column);
                      this.setState({
                        filterParams : {
                          ...filterParams,
                          value : [ parseInt(event.target.value, 10), filterParams.value[1] ],
                        },
                      });
                      this.handleRangeChange(
                        [ parseInt(event.target.value, 10), filterParams.value[1] ],
                        index,
                        column,
                        refetch
                      );
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterParams.value[1] || ''}
                    error={(filterParams.value[0] || 0) > filterParams.value[1] || 0}
                    onChange={event => {
                      const filter = filterList;
                      filter[index][1] = parseInt(event.target.value, 10);
                      onChange(filter[index], index, column);
                      this.setState({
                        filterParams : {
                          ...filterParams,
                          value : [ filterParams.value[0], parseInt(event.target.value, 10) ],
                        },
                      });
                      this.handleRangeChange(
                        [ filterParams.value[0], parseInt(event.target.value, 10) ],
                        index,
                        column,
                        refetch
                      );
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
        },
      },
      {
        name    : 'close_date',
        label   : 'Close Date',
        options : {
          filter        : true,
          sortDirection : this.sortDirection('close_date'),
          filterType    : 'custom',
          filterOptions : {
            names   : [],
            logic (value, filters) {
              if (filters[0] && filters[1]) {
                return value < filters[0] || value > filters[1];
              }
              if (filters[0]) {
                return value < filters[0];
              }
              if (filters[1]) {
                return value > filters[1];
              }
              return false;
            },
            display : (filterList, onChange, index, column) => (
              <div>
                <Grid container>
                  <Grid xs={12}>
                    <div>
                      <FormLabel>Closing Date</FormLabel>
                      <br />
                      <br />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          style={{ width: '100%' }}
                          inputVariant="outlined"
                          label="Start Date"
                          format="MM/dd/yyyy"
                          value={filterParams.close_date[0]}
                          InputAdornmentProps={{ position: 'start' }}
                          onChange={date => {
                            const filter = filterList;
                            filter[index][0] = date;
                            onChange(filter[index], index, column);
                            this.setState({
                              filterParams : {
                                ...filterParams,
                                close_date : [ date, filterParams.close_date[1] ],
                              },
                            });
                            this.handleRangeChange([ date, filterParams.close_date[1] ], index, column, refetch);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <br />
                    <div>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          autoOk
                          variant="inline"
                          style={{ width: '100%' }}
                          inputVariant="outlined"
                          label="End Date"
                          format="MM/dd/yyyy"
                          value={filterParams.close_date[1]}
                          InputAdornmentProps={{ position: 'start' }}
                          onChange={date => {
                            const filter = filterList;
                            filter[index][1] = date;
                            onChange(filter[index], index, column);
                            this.setState({
                              filterParams : {
                                ...filterParams,
                                close_date : [ filterParams.close_date[0], date ],
                              },
                            });
                            this.handleRangeChange([ filterParams.close_date[0], date ], index, column, refetch);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Grid>
                </Grid>
              </div>
            ),
          },
        },
      },
    ];

    return (
      <React.Fragment>
        {search && (
          <Chip
            label={search}
            onDelete={() => this.setState({ search: null })}
            color="primary"
            style={{ margin: 12 }}
          />
        )}
        <ApolloConsumer>
          {client => (
            <div>
              <PipelineCardDrawer
                pipelineCard={selectedCard}
                onClose={this.deSelectCard}
                updateCard={updatePipelineCard}
                pipelineList={pipelines}
                refetch={refetch}
              />
              <div style={{ position: 'absolute', top: 90, right: 260, zIndex: 1000 }}>
                <Select
                  value={statusFilter}
                  onClick={e => {
                    if (e.target.value) {
                      this.setState({ statusFilter: e.target.value }, this.changeFilter(e.target.value));
                    }
                  }}>
                  {[
                    { label: 'Status: All', value: 'all' },
                    { label: 'Status: Open', value: 'open' },
                    { label: 'Status: Won', value: 'won' },
                    { label: 'Status: Lost', value: 'lost' },
                    { label: 'Status: Abandoned', value: 'abandoned' },
                  ].map(status => (
                    <MenuItem key={status.value} value={status.value}>
                      {status.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ position: 'absolute', top: 86, right: 195, zIndex: 1000 }}>
                <IconButton onClick={() => this.toggleTable(get(pipeline, 'id', ''))}>
                  <ColumnIcons />
                </IconButton>
              </div>
              <div style={{ position: 'absolute', top: 86, right: 165, zIndex: 1000 }}>
                <Mutation mutation={printPipelineMutation}>
                  {printPipeline => (
                    <IconButton
                      onClick={() => {
                        printPipeline({ variables: { id: pipeline.id } })
                          .then(() => {
                            // window.location.href = '/admin/pipelines';
                            enqueueSnackbar("A copy of this pipeline will be sent to your email shortly.", {
                              variant      : 'success',
                              anchorOrigin : {
                                vertical   : 'top',
                                horizontal : 'center',
                              },
                            });
                          })
                          .catch(e => {
                            // eslint-disable-next-line
                            console.log(e);
                          });
                      }}
                      color="primary"
                      autoFocus>
                      <PrintIcon />
                    </IconButton>
                  )}
                </Mutation>
              </div>
              {loading && <CircularProgress style={{ position: 'absolute', top: '40%', left: '50%' }} />}
              <MuiThemeProvider theme={myTheme()}>
                <MUIDataTable
                  title={decodeURI(get(pipeline, 'name', ''))}
                  data={get(pipeline, 'pipeline_cards', []).map(c => [
                    c.id,
                    decodeURI(c.name),
                    c.pipeline_stage.name === 'Filed Agreement' && document.URL.match(/rja|icd/)
                      ? 'Submitted Agreement'
                      : decodeURI(c.pipeline_stage.name),
                    numeral(c.value).format('$0,0'),
                    c.close_date,
                  ])}
                  columns={columns(refetch)}
                  options={this.tableOptions({ refetch, client })}
                />
              </MuiThemeProvider>
            </div>
          )}
        </ApolloConsumer>
      </React.Fragment>
    );
  }
}

PipelineListComponent.propTypes = {
  match              : PropTypes.object.isRequired,
  updatePipeline     : PropTypes.func.isRequired,
  updatePipelineCard : PropTypes.func.isRequired,
  pipeline           : PropTypes.object.isRequired,
  pipelines          : PropTypes.object.isRequired,
};

export default withSnackbar(PipelineListComponent);
