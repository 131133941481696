import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import Fab from '@material-ui/core/Fab';
import CurrentPipelineActivityCard from '../Staff/CurrentPipelineActivityCard';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import numeral from 'numeral';
import qs from 'query-string';

const StyledTableCell = withStyles(theme => ({
  head : {
    backgroundColor : '#455560',
    color           : theme.palette.common.white,
    fontSize        : 14,
    fontWeight      : 'bold',
  },
  body : {
    fontSize : 14,
  },
}))(TableCell);

class LoansOtpCalculator extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      rt_three_year_avg_t12            : props.retiring_assumptions_hash.three_year_avg_t12,
      recurring_revenue                : (props.retiring_assumptions_hash.recurring_revenue * 100).toFixed(1) || 0,
      rja_length_of_service            : props.retiring_assumptions_hash.rja_length_of_service,
      asset_attrition_at_retirement    :
        (props.retiring_assumptions_hash.asset_attrition_at_retirement * 100).toFixed(1) || 0,
      rt_rfa_annual_asset_appreciation :
        (props.retiring_assumptions_hash.rfa_annual_asset_appreciation * 100).toFixed(1) || 0,
      sc_three_year_avg_t12            : props.successor_assumptions_hash.three_year_avg_t12,
      discount_rate                    : props.successor_assumptions_hash.discount_rate,
      sc_rfa_annual_asset_appreciation :
        (props.successor_assumptions_hash.rfa_annual_asset_appreciation * 100).toFixed(1) || 0,
    };
    this.loanCalcRef = React.createRef();
  }

  componentDidMount = () => {
    const params = qs.parse(location.search);
    const { showCalc } = params;
    if (showCalc) {
      this.loanCalcRef.current.scrollIntoView({ block: 'center' });
    }
  };

  handleCurrencyChange = (val, s) => {
    this.setState({ [val]: s.replace('$', '').replace(/,/g, '') });
  };

  handleChange = (val, s) => {
    this.setState({ [val]: s });
  };

  recalculate = () => {
    const {
      rt_three_year_avg_t12,
      recurring_revenue,
      rja_length_of_service,
      asset_attrition_at_retirement,
      rt_rfa_annual_asset_appreciation,
      sc_three_year_avg_t12,
      sc_rfa_annual_asset_appreciation,
    } = this.state;
    const params = qs.parse(location.search);
    const { inv_id, user_name } = params;
    const inv_param = `&inv_id=${inv_id}`;
    const user_param = `&user_name=${user_name}`;
    const recurring_revenue_percent = recurring_revenue / 100;
    const asset_attrition_at_retirement_percent = asset_attrition_at_retirement / 100;
    const rt_rfa_annual_asset_appreciation_percent = rt_rfa_annual_asset_appreciation / 100;
    const sc_rfa_annual_asset_appreciation_percent = sc_rfa_annual_asset_appreciation / 100;

    window.open(
      `/loans/otp?successor_fa_t12=${sc_three_year_avg_t12}&successor_fa_annual_depreciation=${sc_rfa_annual_asset_appreciation_percent}&retiring_fa_t12=${rt_three_year_avg_t12}&retiring_fa_recurring_revenue=${recurring_revenue_percent}&retiring_fa_length_of_service=${rja_length_of_service}&retiring_fa_asset_attrition=${asset_attrition_at_retirement_percent}&retiring_fa_annual_depreciation=${rt_rfa_annual_asset_appreciation_percent}&showCalc=true${inv_id
        ? inv_param
        : ''}${user_name ? user_param : ''}`,
      '_self'
    );
  };

  reset = () => {
    const params = qs.parse(location.search);
    const { inv_id, user_name } = params;
    const inv_param = `&inv_id=${inv_id}`;
    const user_param = `&user_name=${user_name}`;
    window.open(`/loans/otp?showCalc=true${inv_id ? inv_param : ''}${user_name ? user_param : ''}`, '_self');
  };

  validatePercentage = (name, val) => {
    var v = parseFloat(val);
    let value;
    value = numeral(v).format('0[.]0a', Math.floor);
    this.setState({ [name]: parseFloat(value) });
  };

  render () {
    const { classes } = this.props;
    const {
      rt_three_year_avg_t12,
      recurring_revenue,
      rja_length_of_service,
      asset_attrition_at_retirement,
      rt_rfa_annual_asset_appreciation,
      sc_three_year_avg_t12,
      discount_rate,
      sc_rfa_annual_asset_appreciation,
    } = this.state;
    return (
      <div ref={this.loanCalcRef}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2}>
                      Retiring FA Assumptions*
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      3-Year Average T12
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={numeral(rt_three_year_avg_t12).format('$0,0[.]00') || 0}
                            variant="outlined"
                            number
                            onChange={e => {
                              this.handleCurrencyChange('rt_three_year_avg_t12', e.target.value);
                            }}
                            error={rt_three_year_avg_t12 < 0}
                          />
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      Recurring Revenue
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={recurring_revenue || 0}
                            variant="outlined"
                            type="number"
                            onChange={e => {
                              this.validatePercentage('recurring_revenue', e.target.value);
                            }}
                            error={recurring_revenue > 100}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div style={{ marginTop: 15, marginLeft: 5 }}>%</div>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      RJA Length of Service
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={rja_length_of_service || 0}
                            variant="outlined"
                            onChange={e => {
                              this.handleChange('rja_length_of_service', parseInt(e.target.value, 10));
                            }}
                          />
                          <Grid item xs={8} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      Asset Attrition at Retirement*<sup>1</sup>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={asset_attrition_at_retirement || 0}
                            variant="outlined"
                            type="number"
                            onChange={e => {
                              this.validatePercentage('asset_attrition_at_retirement', e.target.value);
                            }}
                            error={asset_attrition_at_retirement > 100 || asset_attrition_at_retirement < -100}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div style={{ marginTop: 15, marginLeft: 5 }}>%</div>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      RFA Annual Asset Appreciation (Depreciation)**<sup>2</sup>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={rt_rfa_annual_asset_appreciation || 0}
                            variant="outlined"
                            type="number"
                            onChange={e => {
                              this.validatePercentage('rt_rfa_annual_asset_appreciation', e.target.value);
                            }}
                            error={rt_rfa_annual_asset_appreciation > 100 || rt_rfa_annual_asset_appreciation < -100}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div style={{ marginTop: 15, marginLeft: 5 }}>%</div>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={6}>
            <TableContainer>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2}>
                      Successor FA Assumptions*
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      3-Year Average T12
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            number
                            value={numeral(sc_three_year_avg_t12).format('$0,0[.]00')}
                            variant="outlined"
                            onChange={e => {
                              this.handleCurrencyChange('sc_three_year_avg_t12', e.target.value);
                            }}
                            error={sc_three_year_avg_t12 > 10000000 || sc_three_year_avg_t12 < 0}
                          />
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      Discount Rate
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          {numeral(discount_rate).format('0[.]00%')}
                          <Grid item xs={2} />
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell component="th" scope="row">
                      RFA Annual Asset Appreciation (Depreciation)**<sup>2</sup>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Grid container>
                        <Grid item xs={5} />
                        <Grid item xs={5}>
                          <TextField
                            id="outlined-name"
                            inputProps={{ tabIndex: '1' }}
                            outlined-name
                            value={sc_rfa_annual_asset_appreciation || 0}
                            variant="outlined"
                            type="number"
                            onChange={e => {
                              this.validatePercentage('sc_rfa_annual_asset_appreciation', e.target.value);
                            }}
                            error={sc_rfa_annual_asset_appreciation > 100 || sc_rfa_annual_asset_appreciation < -100}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div style={{ marginTop: 15, marginLeft: 5 }}>%</div>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="center" colSpan={2}>
                      <button
                        type="button"
                        className={classes.rjpeBackgroundColor}
                        style={{ padding: '12px 24px', color: '#fff', margin: 20 }}
                        onClick={this.recalculate}>
                        Recalculate
                      </button>
                      <button
                        type="button"
                        className={classes.rjpeBackgroundColorAlt}
                        style={{ padding: '12px 24px', color: '#fff', margin: 20 }}
                        onClick={this.reset}>
                        Reset
                      </button>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = {
  root                   : {
    width     : '100%',
    marginTop : 5,
  },
  contentButton          : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  viewAll                : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  rjpeBackgroundColor    : {
    backgroundColor : '#002949',
  },
  rjpeBackgroundColorAlt : {
    backgroundColor : '#5B5E5A',
  },
  rjpe                   : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #002949',
    margin    : '0px',
  },
};

LoansOtpCalculator.propTypes = {
  owned_cards : PropTypes.array,
  classes     : PropTypes.object.isRequired,
};

LoansOtpCalculator.defaultProps = {
  owned_cards : [],
};

export default withStyles(styles)(LoansOtpCalculator);
