import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CircularProgress from '@material-ui/core/CircularProgress';
import FBFilterDialog from './FBFilterDialog';
import FBTableSearch from './FBTableSearch';
import FBTableDistanceSearch from './FBTableDistanceSearch';
import FBDeleteDialog from './FBDeleteDialog';
import FBColumnDialog from './FBColumnDialog';
import ExportEngagementMetrics from '../InventoryUsers/ExportEngagementMetrics';

const toolbarStyles = theme => ({
  root      : {
    paddingRight : theme.spacing.unit,
  },
  highlight :
    theme.palette.type === 'light'
      ? {
          color           : theme.palette.secondary.main,
          backgroundColor : lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color           : theme.palette.text.primary,
          backgroundColor : theme.palette.secondary.dark,
        },
  spacer    : {
    flex : '1 1 20%',
  },
  actions   : {
    color : theme.palette.text.secondary,
  },
  title     : {
    flex : '0 0 auto',
  },
});

class FBTableToolbar extends React.Component {
  state = { deleteDialogOpen: false };

  renderDeleteDialog = () => {
    return <div>This is the dialog</div>;
  };

  render () {
    const {
      numSelected,
      classes,
      columns,
      allColumns,
      showFilterDialog,
      toggleDialog,
      onFilterChange,
      filterList,
      selected,
      onDelete,
      clearSelected,
      onSearch,
      onDistanceSearch,
      updateColumns,
      bulkEditDialog,
      allowDelete,
      heading,
      exportToCSVButton,
      currentUserIsAdmin,
      loading,
      hideColumnSelect,
      addedIcons,
      hideFilterDialog,
      disableDistanceSearch,
    } = this.props;
    const { deleteDialogOpen } = this.state;
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="h4" id="tableTitle">
              {heading()}
            </Typography>
          )}
        </div>
        {loading && <CircularProgress style={{ position: 'absolute', left: '50%' }} />}
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected > 0 ? (
            <div>
              {bulkEditDialog({ selected })}
              {allowDelete && (
                <FBDeleteDialog
                  selected={selected}
                  onConfirmDelete={() => {
                    selected.map(id => onDelete({ id }));
                    clearSelected();
                  }}
                />
              )}
            </div>
          ) : (
            <div>
              <FBTableSearch onSearch={onSearch} />
              { !disableDistanceSearch && <FBTableDistanceSearch onDistanceSearch={onDistanceSearch} />}
              {!hideColumnSelect && (
                <FBColumnDialog
                  columns={allColumns}
                  selectedColumns={columns}
                  updateColumns={updateColumns}
                  onFilterChange={onFilterChange}
                  filterList={filterList}
                />
              )}
              {!hideFilterDialog && (
                <Tooltip title="Filter list">
                  <IconButton aria-label="Filter list">
                    <FilterListIcon onClick={toggleDialog} />
                    <FBFilterDialog
                      open={showFilterDialog}
                      columns={columns}
                      onClose={toggleDialog}
                      onFilterChange={onFilterChange}
                      filterList={filterList}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {exportToCSVButton()}
              {<ExportEngagementMetrics />}
              {addedIcons()}
            </div>
          )}
        </div>
      </Toolbar>
    );
  }
}

FBTableToolbar.propTypes = {
  classes            : PropTypes.object.isRequired,
  numSelected        : PropTypes.number.isRequired,
  heading            : PropTypes.func,
  loading            : PropTypes.bool.isRequired,
  hideColumnSelect   : PropTypes.bool,
  hideFilterDialog   : PropTypes.bool,
  columns            : PropTypes.array,
  allColumns         : PropTypes.array,
  currentUserIsAdmin : PropTypes.bool,
  addedIcons         : PropTypes.func,
  disableDistanceSearch: PropTypes.bool,
};

FBTableToolbar.defaultProps = {
  heading          : () => '',
  hideColumnSelect : false,
  columns          : [],
  allColumns       : [],
  addedIcons       : () => '',
  hideFilterDialog : false,
  disableDistanceSearch: false,
};

const StyledFBTableToolbar = withStyles(toolbarStyles)(FBTableToolbar);

export default StyledFBTableToolbar;
