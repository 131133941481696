import React, { useState } from "react";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { isEmpty } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

import PipelineSummary from "../../components/Reports/PipelineSummary";

const PipelineReportQuery = gql`
  query pipeline($id: ID, $status: String, $agentName: String, $limit: String) {
    getCurrentUser {
      company {
        business_type
      }
    }
    pipelines {
      id
      name
      pipeline_stages {
        id
      }
      pipeline_cards {
        id
      }
      card_count
    }
    pipeline(id: $id) {
      id
      name
      card_count
      pipeline_cards {
        id
      }
      pipeline_stages {
        id
        name
        card_count(status: $status, agentName: $agentName)
        total_value
        average_duration
        pipeline_cards(status: $status, agentName: $agentName, limit: $limit) {
          id
          value
          close_date
          last_moved_date
          status
          created_at
        }
      }
    }
  }
`;

export const PipelineSummaryReportContainer = (props) => {
  const [data, setData] = useState({});
  return (
    <div>
      <div>
        <Query
          query={PipelineReportQuery}
          onCompleted={(d) => {
            if (isEmpty(data)) {
              setData({ ...d });
            }
          }}
          fetchPolicy="network-only"
          variables={{ agentName: "", status: "open", limit: "none" }}
        >
          {({ loading, error, refetch }) => {
            if (error) return `Error!: ${error}`;
            if (!loading && data.pipeline) {
              return (
                <PipelineSummary
                  setData={setData}
                  data={data}
                  refetch={refetch}
                  {...props}
                />
              );
            }
            return <CircularProgress />;
          }}
        </Query>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.dateRangeFilter.sendStart.format("YYYY-MM-DD"),
  endDate: state.dateRangeFilter.sendEnd.format("YYYY-MM-DD"),
});

export default connect(mapStateToProps)(PipelineSummaryReportContainer);
