import React from 'react';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { VictoryPie, VictoryTheme, VictoryLegend } from 'victory';

const CompensationMix = ({ feeBased, commissionBased, intl, colorScale }) => (
  <div className="revPie">
    <VictoryPie
      data={[ { y: feeBased }, { y: commissionBased } ]}
      colorScale={colorScale}
      height={320}
      theme={VictoryTheme.material}
      style={{
        labels : { fontSize: 17 },
      }}
      innerRadius={80}
      labels={l => {
        const label = get(l, `data[${l.index}].y`, 0);

        return label > 0 ? `${numeral(label.toFixed(2) * 100).format('0')} %` : '';
      }}
    />
    <VictoryLegend
      orientation="horizontal"
      height={40}
      colorScale={colorScale}
      data={[
        { name: intl.formatMessage({ id: 'bobcard.user_details.fee_based' }), labels: { fontSize: 19 } },
        { name: intl.formatMessage({ id: 'bobcard.user_details.commission_based' }), labels: { fontSize: 19 } },
      ]}
    />
  </div>
);

export default injectIntl(CompensationMix);
