import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import { S3Image } from '../../../lib/s3';
import numeral from 'numeral';

const styles = {
  paper : {
    padding      : 14,
    marginBottom : 14,
    marginTop    : 14,
  },
  input : {
    background : '#E8E8E8',
    padding    : 6,
  },
  label : {
    verticalAlign : 'bottom',
  },
  media : {
    width : '100%',
  },
  name  : {
    textAlign : 'center',
    marginTop : 0,
  },
};

const PublicUserDetails = ({ user, classes }) => (
  <div>
    <h4>{user.headline}</h4>
    <p>{user.bio}</p>
    <div className={classes.data}>
      <b>Annual Revenue: </b>
      {numeral(user.annual_revenue).format('$0,0.00')}
    </div>
    <div className={classes.data}>
      <b>Recurring Revenue: </b>
      {numeral(user.recurring_revenue).format('$0,0.00')}
    </div>
    <div className={classes.data}>
      <b>Assets Under Management: </b>
      {numeral(user.assets_under_management).format('$0,0.00')}
    </div>
    <div className={classes.data}>
      <b>Number of Clients: </b>
      {user.number_of_clients}
    </div>
  </div>
);

const UserDetailCard = ({ opportunity, classes }) => {
  const user = opportunity.user || {};
  return (
    <Paper style={{ marginTop: 14 }}>
      <CardMedia
        component="img"
        alt={user.name}
        className={classes.media}
        image={S3Image(user.profile_picture)}
        title={user.name}
      />
      <div className={classes.paper}>
        <h3 className={classes.name}>{user.name}</h3>
        {user.has_user && <PublicUserDetails user={user.user} classes={classes} />}
        <hr />
        <div className={classes.data}>
          <b>Likelihood of loss: </b>
          {user.risk_of_loss}
        </div>
        <div className={classes.data}>
          <b>Damage of loss: </b>
          {user.impact_of_loss}
        </div>
        <div className={classes.data}>
          <b>Key Asset: </b>
          {user.key_asset}
        </div>
        <br />
        <div className={classes.data}>
          <b>Likely Reason for Loss: </b>
          {user.reason_for_loss}
        </div>
      </div>
    </Paper>
  );
};

UserDetailCard.propTypes = {
  opportunity : PropTypes.object,
  classes     : PropTypes.object.isRequired,
};

export default withStyles(styles)(UserDetailCard);
