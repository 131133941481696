import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import numeral from 'numeral';

import Grid from '@material-ui/core/Grid';

const motivationName = motivation => {
  if (motivation === 'succession') return 'Sellers';
  else if (motivation === 'growth') return 'Buyers';
  else if (motivation === 'no_motivation') return 'Researchers';
  return 'Users';
};

const MotivationHeader = ({ inventory_users, inventory_user_count, classes, motivationFilters, print_view }) => {
  return (
    <div>
      <p>
        The following user report does not include administrators, customers, managers or users who have been explicitly
        hidden. Start by selecting a user’s motivation and apply additional filters as necessary.
      </p>
      <br />
      <h4 className={print_view ? classes.subHeaderPrint : classes.subHeader}>Summary of your Users by Motivation</h4>
      <Grid container>
        <div style={{ display: 'inline-flex' }}>
          <h2 className={classes.bigNumber}>
            &nbsp;{(inventory_users || []).length}
            <span className={classes.numberLabel}>
              &nbsp;Total {motivationName(get(motivationFilters, 'user_motivation', 'succession'))}
            </span>
          </h2>
          <h2 className={classes.bigNumber}>
            &nbsp;{numeral((inventory_users || []).length / inventory_user_count).format('0%')}
            <span className={classes.numberLabel}> of Total Users</span>
          </h2>
        </div>
      </Grid>
    </div>
  );
};

const styles = {
  root           : {
    width     : '100%',
    overflowX : 'auto',
  },
  table          : {
    minWidth : 650,
  },
  container      : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu           : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker     : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber      : { fontSize: 50 },
  subHeader      : { fontWeight: 'normal', marginBottom: 0 },
  subHeaderPrint : { fontWeight: 'bold', marginBottom: 0, fontSize: 35 },
  numberLabel    : { fontSize: 16 },
  selectatron    : { marginRight: 10 },
  formControl    : {
    margin   : 2,
    minWidth : 120,
  },
};

const mapStateToProps = state => ({
  motivationFilters    : state.motivationFilters,
  inventory_users      : state.inventory_users.inventory_users,
  inventory_user_count : state.inventory_users.inventory_user_count,
  transition_goals     : state.inventory_users.transition_goals,
  print_view           : state.inventory_users.print_view,
});

const MotivationHeaderWithStyles = withStyles(styles)(MotivationHeader);
export default connect(mapStateToProps)(MotivationHeaderWithStyles);
