import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';

const ActivityInfoBoxMini = props => {
  const { classes, title, children } = props;
  return (
    <Card className={classes.card}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.title}>{children}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.content} type="headline">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

ActivityInfoBoxMini.propTypes = {
  classes  : PropTypes.object.isRequired,
  title    : PropTypes.string.isRequired,
  children : PropTypes.object.isRequired,
};

const styles = {
  card    : {
    borderRadius : 0,
    height       : 85,
  },
  title   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    marginTop  : 15,
    fontSize   : 18,
  },
  content : {
    textAlign : 'center',
    margin    : 5,
    fontSize  : 16,
    color     : '#808080',
  },
};

export default withStyles(styles)(ActivityInfoBoxMini);
