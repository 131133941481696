import { combineReducers } from 'redux';
import { MARKETPLACE_STATUS_UPDATE } from '../constants/marketplaceConstants';

const marketplaceStatusReducer = (
  state = {
    inviteeStatus : '',
    inviteeId     : '',
    inviteeName   : '',
  },
  action
) => {
  switch (action.type) {
    case MARKETPLACE_STATUS_UPDATE:
      return { ...state, ...action.invite };
    default:
      return state;
  }
};

export default marketplaceStatusReducer;
