import React from 'react';
import PropTypes from 'prop-types';
import { get, filter, startCase, mean } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import numeral from 'numeral';
import moment from 'moment';
import qs from 'query-string';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryAxis, VictoryLabel } from 'victory';
import DateRangeFilterContainer from '../../containers/DateRangeFilterContainer';
import { capturePdf } from '../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

class PipelineSummary extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      pipelineId : get(this.props, 'data.pipeline.id', null),
      yAxis      : 'value',
      status     : 'open',
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.data.pipeline.id) {
      this.setState({ pipelineId: nextProps.data.pipeline.id });
    }
  };

  formatChartData = data => {
    const company = get(this.props, 'data.getCurrentUser.company', {});
    return data.map(s => ({
      name  :
        company.business_type === 'wealth_rj' && s.name === 'Filed Agreement'
          ? 'Submitted Agreement'
          : decodeURI(s.name),
      value : get(s, 'pipeline_cards', []).reduce((s, c) => s + parseInt(c.value), 0),
      count : get(s, 'pipeline_cards', []).length,
    }));
  };

  renderAxis = y => {
    const { yAxis } = this.state;
    if (yAxis === 'value') {
      return numeral(y).format('$0,') === '$NaN' ? '$0' : numeral(y).format('$0,a');
    }
    return numeral(y).format('0') === 'NaN' ? '0' : numeral(y).format('0');
  };

  clickedCapture = () => {
    capturePdf('l', 10, 10, 250, 150, 2);
  };

  clickChartLabel = pipelineStage => {
    const { startDate, endDate } = this.props;
    const { pipelineId } = this.state;
    let stageName = pipelineStage;
    if (get(this.props, 'data.getCurrentUser.company.business_type', '') === 'wealth_rj') {
      stageName = pipelineStage === 'Submitted Agreement' ? 'Filed Agreement' : pipelineStage;
    }
    window.open(
      `/admin/pipelines/${pipelineId}/list?start_date=${startDate}&end_date=${endDate}&pipeline_stage=${decodeURI(
        stageName
      )}`,
      '_blank'
    );
  };

  render () {
    const { classes, startDate, endDate, refetch, setData } = this.props;
    const { yAxis, status, pipelineId } = this.state;
    const pipelines = get(this.props, 'data.pipelines', []);
    const pipeline = get(this.props, 'data.pipeline', {});
    const all_stages = get(pipeline, 'pipeline_stages', []);
    const company = get(this.props, 'data.getCurrentUser.company', {});
    const stages = all_stages.map(s => {
      if (startDate && endDate) {
        return {
          ...s,
          pipeline_cards : filter(
            s.pipeline_cards,
            c =>
              moment(c.close_date).isSameOrAfter(startDate, 'YYYY-MM-DD') &&
              moment(c.close_date).isSameOrBefore(endDate, 'YYYY-MM-DD') &&
              (status === 'all' || c.status === status)
          ),
        };
      }
      return s;
    });

    const total_card_count = () => {
      let sum = 0;
      for (let i = 0; i < stages.length; i += 1) {
        sum += stages[i].card_count || 0;
      }
      return sum;
    };

    const params = qs.parse(location.search);
    if (params.pipeline_id) {
      refetch({ id: params.pipeline_id });
      window.history.pushState({}, document.title, '/admin/reports/pipeline_summary');
    }

    const totalValue = stages.reduce(
      (sum, s) => sum + get(s, 'pipeline_cards', []).reduce((s, c) => s + parseInt(c.value), 0),
      0
    );
    const totalCases = stages.reduce((sum, s) => sum + get(s, 'pipeline_cards.length', 0), 0);

    const durationText = (duration) => {
      let durationText = '-'
      if(!isNaN(duration)) {
        const durationDays = (duration / 24).toFixed(2);
        if(durationDays < 1){
          durationText = `${duration.toFixed(2)} hrs`;
        } else {
          durationText = `${durationDays} d`;
        }
      }
      return durationText;
    }

    return (
      <div className={`col-md-12 col-sm-12 ${classes.container}`}>
        <div id="capture">
          <h4 className={classes.subHeader}>Summary of your pipeline by stage, based on Case closed date.</h4>
          <Grid container>
            <Grid>
              <h2 className={classes.bigNumber}>
                {numeral(totalValue).format('$0a')} <span className={classes.numberLabel}>Total Value</span>
              </h2>
            </Grid>
            <Grid>
              <h2 className={classes.bigNumber}>
                &nbsp;{totalCases} <span className={classes.numberLabel}>Cases</span>
              </h2>
            </Grid>
          </Grid>

          <div className={classes.menu}>
            <Select
              classes={{ select: classes.selectatron }}
              value={pipelineId}
              onClick={e => {
                if (e.target.value) {
                  refetch({ id: e.target.value }).then(r => {
                    setData({ ...r.data });
                  });
                  this.setState({ pipelineId: e.target.value });
                }
              }}>
              {pipelines.map(p => (
                <MenuItem key={p.id} value={p.id}>
                  {decodeURI(p.name)}
                </MenuItem>
              ))}
            </Select>
            &nbsp;
            <RadioGroup
              aria-label="Y-Axis"
              name=""
              value={yAxis}
              onChange={e => {
                this.setState({ yAxis: e.target.value });
              }}
              row>
              <FormControlLabel value="value" control={<Radio />} label="Total Value" labelPlacement="end" />
              <FormControlLabel value="count" control={<Radio />} label="Count" labelPlacement="end" />
            </RadioGroup>
            <div className={classes.datepicker}>
              &nbsp;Close Date: <DateRangeFilterContainer />
            </div>
            <Select
              value={status}
              onClick={e => {
                if (e.target.value) {
                  this.setState({ status: e.target.value });
                }
              }}>
              {[ 'all', 'open', 'won', 'lost', 'abandoned' ].map(s => (
                <MenuItem key={s} value={s}>
                  {startCase(s)}
                </MenuItem>
              ))}
            </Select>
            <Tooltip title="Download PDF" style={{ marginLeft: 30 }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </div>

          <VictoryChart
            width={800}
            height={260}
            domainPadding={[ 30, 30 ]}
            padding={{ bottom: 120, left: 80, top: 20, right: 80 }}
            theme={VictoryTheme.material}>
            <VictoryAxis dependentAxis tickFormat={y => this.renderAxis(y)} orientation="left" />
            <VictoryAxis
              dependentAxis={false}
              tickFormat={x => decodeURI(x)}
              orientation="bottom"
              tickLabelComponent={
                <VictoryLabel
                  angle={22}
                  textAnchor="start"
                  style={{ cursor: 'pointer' }}
                  events={{ onClick: e => this.clickChartLabel(e.target.innerHTML) }}
                />
              }
            />
            <VictoryBar
              width={800}
              height={280}
              data={this.formatChartData(stages)}
              x="name"
              y={yAxis}
              alignment="start"
              style={{
                data : { fill: '#2793FF', stroke: '#006BDB', strokeWidth: 2, cursor: 'pointer' },
              }}
              labels={d => (yAxis === 'value' ? numeral(d.datum[yAxis]).format('$0,a') : d.datum[yAxis])}
              events={[
                {
                  target        : 'data',
                  eventHandlers : {
                    onClick : () => {
                      return [
                        {
                          target   : 'labels',
                          mutation : props => {
                            this.clickChartLabel(props.datum.xName);
                          },
                        },
                      ];
                    },
                  },
                },
              ]}
            />
          </VictoryChart>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Stage</TableCell>
                <TableCell align="right">% of total</TableCell>
                <TableCell align="right">Cases</TableCell>
                <TableCell align="right">Avg Active Stage Duration</TableCell>
                <TableCell align="right">Total Value</TableCell>
                <TableCell align="right">Avg Time to Complete Stage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stages.map(stage => {
                const card_count = (stage.pipeline_cards || []).length;
                const totalVal = (stage.pipeline_cards || []).reduce((s, c) => s + c.value, 0);
                const avgDuration = mean((stage.pipeline_cards || []).map(c => moment().diff(moment.parseZone((c.last_moved_date || '').replace(' UTC',''), 'YYYY-MM-DD HH:mm:ss'), 'hours') || 0));
                const stageName =
                  company.business_type === 'wealth_rj' && stage.name === 'Filed Agreement'
                    ? 'Submitted Agreement'
                    : decodeURI(stage.name);

                const stageDuration = stage.average_duration;

                
                return (
                  <TableRow
                    key={stage.name}
                    onClick={() => this.clickChartLabel(stageName)}
                    style={{ cursor: 'pointer' }}>
                    <TableCell component="th" scope="row">
                      {stageName}
                    </TableCell>
                    <TableCell align="right">{numeral(card_count / total_card_count()).format('0%')}</TableCell>
                    <TableCell align="right">{card_count}</TableCell>
                    <TableCell align="right">{durationText(avgDuration)}</TableCell>
                    <TableCell align="right">{numeral(totalVal).format('$0,a')}</TableCell>
                    <TableCell align="right">{durationText(stageDuration)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

PipelineSummary.propTypes = {
  startDate : PropTypes.string.isRequired,
  endDate   : PropTypes.string.isRequired,
  classes   : PropTypes.object.isRequired,
  refetch   : PropTypes.func.isRequired,
  setData   : PropTypes.func.isRequired,
};

const styles = {
  download    : {
    float : 'right',
  },
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
};
export default withStyles(styles)(PipelineSummary);
