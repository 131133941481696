import React, { useState } from 'react';
import TextField from "@material-ui/core/TextField";
import { injectIntl } from "react-intl";
import { clamp, get } from 'lodash';
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { el } from 'date-fns/locale';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          }
        });
      }}
      thousandSeparator>
    </NumberFormat>
  )
}


const fixNumber = num => Number(num.toPrecision(15));
const FBPercentInput = props => {
  const [val, setVal] = useState(+(props.value * 100).toFixed(2));
  const format = get(props, 'intl.locale', 'en') == 'fr' ? ' %' : '%'
  
  return <TextField
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">{format}</InputAdornment>,
    }}
    value={val}
    type="number"
    onChange={e => {
      if (Object.is(NaN, parseFloat(e.target.value))) {
        setVal(e.target.value);
      }
      else {
        let v = clamp(+(parseFloat(e.target.value)), props.lower, props.upper);

        setVal(v);
        props.onChange(parseFloat(parseFloat(v / 100).toFixed(4)));
      }
    }}
  /> 
};

FBPercentInput.propTypes = {
  upper: PropTypes.number,
  lower: PropTypes.number,
};

FBPercentInput.defaultProps = {
  upper: 100,
  lower: 0,
};

export default injectIntl(FBPercentInput);