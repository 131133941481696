/* eslint-disable import/prefer-default-export */

import axios from 'axios';

import { DATE_RANGE_UPDATE } from '../constants/filterConstants';

export const updateDateFilter = filterOptions => ({
  type          : DATE_RANGE_UPDATE,
  filterOptions,
});
