import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import * as actions from '../actions/dealActionCreators';
import DealStepper from '../components/DealWizard/DealStepper';
import DealQuestions from '../components/DealWizard/DealQuestions';
import { get } from 'lodash';

const CompanyQuery = gql`
  query company {
    company {
      id
      stylesheet_name
      business_type
      name
    }
  }
`;

export const DealQuestionsContainer = ({ deal, match_preference, updateDeal }) => (
  <Query query={CompanyQuery}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <div />;
      if (data.company) {
        const stylesheetName = get(data, 'company.stylesheet_name');
        const businessType = get(data, 'company.business_type');
        const companyName = get(data, 'company.name');
        return (
          <Fragment>
            <br />
            <DealStepper activeStep={2} dealID={deal.deal_name} deal={deal} stylesheetName={stylesheetName} />
            <DealQuestions
              deal={deal}
              match_preference={match_preference}
              updateDealInRedux={updateDeal}
              stylesheetName={stylesheetName}
              businessType={businessType}
              companyName={companyName}
              
            />
          </Fragment>
        );
      }
      return <div />;
    }}
  </Query>
);

const mapStateToProps = state => ({
  deal             : state.deal,
  match_preference : state.match_preference,
});

export default connect(mapStateToProps, actions)(DealQuestionsContainer);
