import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { VictoryChart } from 'victory';
import { VictoryBar } from 'victory';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { capturePdf } from '../../lib/findBobUtils';

const styles = theme => ({
  report   : {
    margin : '25px',
  },
  title    : {
    fontSize : '24px',
  },
  progress : {
    margin : 'auto',
  },
  card     : {
    margin   : 'auto',
    maxWidth : '600px',
  },
  media    : {
    height     : 0,
    paddingTop : '56.25%', // 16:9
  },
});

class BuyersAndSellersPage extends React.Component {
  clickedCapture = () => {
    capturePdf('l', 0, 0, '', '', 0.7);
  };

  render () {
    const { classes, data, intl } = this.props;
    if (data.loading) return <CircularProgress size={68} className={classes.progress} />;
    return (
      <div id="capture">
        <section className={classes.report}>
          <Card className={classes.card}>
            <Tooltip title="Download PDF" style={{ float: 'right' }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            <CardHeader
              classes={{
                title : classes.title,
              }}
              title={intl.formatMessage({ id: 'admin_company_reports.buyers_and_sellers' })}
            />
            <VictoryChart domainPadding={100} height={400}>
              <VictoryBar
                categories={{
                  x : [
                    intl.formatMessage({ id: 'admin_company_reports.total' }),
                    intl.formatMessage({ id: 'admin_company_reports.buyers' }),
                    intl.formatMessage({ id: 'admin_company_reports.sellers' }),
                  ],
                }}
                labels={d => `${d.y}`}
                data={[
                  {
                    x    : intl.formatMessage({ id: 'admin_company_reports.total' }),
                    y    : data.sacAllUserCount,
                    fill : 'black',
                  },
                  {
                    x    : intl.formatMessage({ id: 'admin_company_reports.buyers' }),
                    y    : data.sacBuyers,
                    fill : 'grey',
                  },
                  {
                    x    : intl.formatMessage({ id: 'admin_company_reports.sellers' }),
                    y    : data.sacSellers,
                    fill : 'slategrey',
                  },
                ]}
              />
            </VictoryChart>
            <CardContent>
              <Typography gutterBottom variant="headline" component="h2">
                <FormattedMessage id="admin_company_reports.buyers_and_sellers" />
              </Typography>
              <Typography component="p">
                <strong>
                  <FormattedMessage id="admin_company_reports.total_number_of_users" />:{' '}
                </strong>
                {data.sacAllUserCount} <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.number_of_buyers" />:{' '}
                </strong>
                {data.sacBuyers} <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.number_of_sellers" />:{' '}
                </strong>
                {data.sacSellers} <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.buyer_seller_overlap" />:{' '}
                </strong>%{Math.round(data.sacBuyersAndSellers / data.sacAllUserCount * 100)} <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.buyer_seller_ratio" />:{' '}
                </strong>
                <FormattedMessage id="admin_company_reports.approx" />
                {Math.round(data.sacBuyers / data.sacSellers)}
                <FormattedMessage id="admin_company_reports.to_one" /> <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.ratio_of_pure_buyers_to_sellers" />:{' '}
                </strong>
                <FormattedMessage id="admin_company_reports.approx" />{' '}
                {Math.round(data.sacPureBuyers / data.sacPureSellers)}{' '}
                <FormattedMessage id="admin_company_reports.to_one" />
              </Typography>
            </CardContent>
          </Card>
        </section>
      </div>
    );
  }
}

BuyersAndSellersPage.propTypes = {
  data : PropTypes.shape({
    sacAllUserCount     : PropTypes.number,
    sacBuyers           : PropTypes.number,
    sacSellers          : PropTypes.number,
    sacBuyersAndSellers : PropTypes.number,
    sacPureBuyers       : PropTypes.number,
    sacPureSellers      : PropTypes.number,
  }),
};

const BuyersAndSellersPageWithSyles = withStyles(styles)(BuyersAndSellersPage);
export default injectIntl(BuyersAndSellersPageWithSyles);
