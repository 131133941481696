import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Badge from '@material-ui/core/Badge';
import ColumnIcons from '@material-ui/icons/ViewList';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import { get } from 'lodash';
import numeral from 'numeral';
import { ApolloConsumer } from 'react-apollo';
import qs from 'query-string';
import Board from './Board';
import PipelineSelector from './PipelineSelector';
import EditPipelineDialog from './EditPipelineDialog';
import DeletePipelineDialog from './DeletePipelineDialog';
import PipelineUserSelect from './PipelineUserSelect';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Checkbox from '@material-ui/core/Checkbox';
import { debounce } from 'lodash';

class Pipelines extends React.Component {
  constructor (props) {
    super(props);
    let userFilter = '';
    const params = qs.parse(location.search);
    const { inventoryUser } = params;

    if (Boolean(inventoryUser)) {
      userFilter = inventoryUser;
    }

    this.state = {
      statusFilter              : 'open',
      nameFilter                : '',
      ownerFilter               : '',
      userFilter,
      orderByAttribute          : 'name',
      order                     : 'asc',
      filterByAgreementCheckbox : false,
    };

    this.changeFilter = debounce(this.changeFilter, 250);
  }

  getTotalValue = () => {
    const { pipeline } = this.props;
    const total_val = pipeline.pipeline_stages.map(s => s.stage_totals).reduce((a, b) => a + b, 0);
    return total_val;
  };

  renderIcon = (statusFilter, userFilter, orderByAttribute) => {
    const { order } = this.state;
    if (order === 'asc') {
      return (
        <ArrowUpwardIcon
          style={{ cursor: 'pointer', fontSize: 15 }}
          onClick={() =>
            this.setState({ order: 'desc' }, this.changeFilter(statusFilter, userFilter, orderByAttribute, 'desc'))}
        />
      );
    }
    return (
      <ArrowDownwardIcon
        style={{ cursor: 'pointer', fontSize: 15 }}
        onClick={() =>
          this.setState({ order: 'asc' }, this.changeFilter(statusFilter, userFilter, orderByAttribute, 'asc'))}
      />
    );
  };

  handleAgreementFilterCheckBox = () => {
    const { filterByAgreementCheckbox } = this.state;
    this.setState({ filterByAgreementCheckbox: !filterByAgreementCheckbox });
  };

  changeFilter = (newStatus, newAgent, orderBy, order, ownerFilter) => {
    const { refetch, updatePipeline } = this.props;
    const { filterByAgreementCheckbox } = this.state;
    refetch({
      status                    : newStatus,
      agentName                 : newAgent,
      column                    : orderBy,
      columnDirection           : order,
      ownerName                 : ownerFilter,
      filterByAgreementCheckbox,
    }).then(r => {
      const pLine = get(r, 'data.pipeline');
      if (pLine) {
        const stages = get(pLine, 'pipeline_stages', []);
        let pipeline_cards = [];

        stages.map(s => {
          pipeline_cards = pipeline_cards.concat(get(s, 'pipeline_cards', []));
        });

        updatePipeline({ ...pLine, pipeline_cards });
      }
    });
  };

  render () {
    const {
      classes,
      match,
      pipeline,
      pipelineList,
      updatePipelineCard,
      changePipelineState,
      addPipelineCard,
      mutateOrder,
      mutate,
      dragging,
      refetch,
      updatePipeline,
      toggleTable,
      currentInventoryUser,
      changeFilters,
    } = this.props;
    const {
      statusFilter,
      nameFilter,
      userFilter,
      ownerFilter,
      orderByAttribute,
      order,
      filterByAgreementCheckbox,
    } = this.state;
    return (
      <Grid>
        <ApolloConsumer>
          {client => (
            <Paper className={classes.root}>
              <div className={classes.tableWrapper}>
                <Toolbar className={classes.toolbar}>
                  <div className={classes.selector}>
                    <PipelineSelector
                      match={match}
                      pipelines={pipelineList}
                      className={classes.selector}
                      pipeline={pipeline}
                      disablePipelineFunctions={get(pipeline, 'disable_pipeline_features', false)}
                    />
                    <span className={classes.value}>
                      <b>Total Value: </b>
                      {numeral(this.getTotalValue()).format('$0,0')}
                    </span>
                    <span>
                      <div style={{ marginLeft: 30 }}>
                        <span>Order: </span>
                        <Select
                          style={{ marginLeft: 10 }}
                          IconComponent={() => this.renderIcon(statusFilter, userFilter, orderByAttribute)}
                          value={orderByAttribute}
                          onChange={e => {
                            if (e.target.value) {
                              this.setState(
                                { orderByAttribute: e.target.value },
                                this.changeFilter(statusFilter, userFilter, e.target.value, order, ownerFilter)
                              );
                            }
                          }}>
                          {[
                            { label: 'Name', value: 'name' },
                            { label: 'Value', value: 'value' },
                            {
                              label : 'Inactive Days',
                              value : 'last_moved_date',
                            },
                            { label: 'Creation Date', value: 'created_at' },
                            { label: 'Close Date', value: 'close_date' },
                            {
                              label : 'Primary Agent',
                              value : 'primary_agent_name',
                            },
                          ].map(orderAtt => (
                            <MenuItem key={orderAtt.value} value={orderAtt.value}>
                              {orderAtt.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </span>
                  </div>
                  <div style={{ marginLeft: 30, width: 200 }}>
                    {get(pipeline, 'name', '') === 'Business Continuity Builder' && (
                      <div>
                        Submitted an agreement?{' '}
                        <Checkbox
                          checked={filterByAgreementCheckbox}
                          onClick={() => {
                            this.handleAgreementFilterCheckBox();
                            this.changeFilter(statusFilter, userFilter, orderByAttribute, order, ownerFilter);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div style={{ marginLeft: 30, width: 200 }}>
                    <div>Filter by user:</div>
                    <PipelineUserSelect
                      value={userFilter}
                      onChange={v => {
                        this.setState(
                          { userFilter: v.target.value },
                          this.changeFilter(statusFilter, v.target.value, orderByAttribute, order, ownerFilter)
                        );
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 30, width: 200 }}>
                    <div>Filter by owner:</div>
                    <TextField
                      value={ownerFilter}
                      onChange={v => {
                        this.setState(
                          { ownerFilter: v.target.value },
                          this.changeFilter(statusFilter, userFilter, orderByAttribute, order, v.target.value)
                        );
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: 30 }}>
                    <Select
                      value={statusFilter}
                      onClick={e => {
                        if (e.target.value) {
                          this.setState(
                            { statusFilter: e.target.value },
                            this.changeFilter(e.target.value, userFilter, orderByAttribute, order, ownerFilter)
                          );
                        }
                      }}>
                      {[
                        { label: 'Status: All', value: 'all' },
                        { label: 'Status: Open', value: 'open' },
                        { label: 'Status: Won', value: 'won' },
                        { label: 'Status: Lost', value: 'lost' },
                        { label: 'Status: Abandoned', value: 'abandoned' },
                      ].map(status => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className={classes.toolbarIcons} style={{ marginTop: 14 }}>
                    <div>
                      {pipeline.card_count > 100 ? (
                        <div>
                          <Badge color="primary" badgeContent={pipeline.card_count} className={classes.margin}>
                            <IconButton onClickCapture={toggleTable}>
                              <ColumnIcons />
                            </IconButton>
                          </Badge>
                        </div>
                      ) : (
                        <IconButton onClickCapture={toggleTable}>
                          <ColumnIcons />
                        </IconButton>
                      )}
                      &nbsp;
                    </div>
                    <EditPipelineDialog
                      pipeline={pipeline}
                      refetch={refetch}
                      disablePipelineFunctions={get(pipeline, 'disable_pipeline_features', false)}
                    />
                    <DeletePipelineDialog pipeline={pipeline} />
                  </div>
                </Toolbar>

                <Board
                  pipeline={pipeline}
                  updateCard={updatePipelineCard}
                  changePipelineState={changePipelineState}
                  cardMutation={mutate}
                  dragging={dragging}
                  mutateOrder={mutateOrder}
                  refetchPipelines={refetch}
                  updatePipeline={updatePipeline}
                  addPipelineCard={addPipelineCard}
                  pipelineList={pipelineList}
                  statusFilter={statusFilter}
                  nameFilter={nameFilter}
                  ownerFilter={ownerFilter}
                  draggable
                  canAddLanes
                  editable
                  currentInventoryUser={currentInventoryUser}
                  style={{ background: 'white' }}
                  userFilter={userFilter}
                  client={client}
                  toggleTable={toggleTable}
                  changeFilters={changeFilters}
                  disablePipelineFunctions={get(pipeline, 'disable_pipeline_features', false)}
                />
              </div>
            </Paper>
          )}
        </ApolloConsumer>
      </Grid>
    );
  }
}
const styles = () => ({
  toolbarIcons : {
    right         : 0,
    flex          : 0,
    display       : 'flex',
    flexDirection : 'row',
  },
  toolbar      : {
    display       : 'flex',
    flexDirection : 'row',
  },
  selector     : { flex: 1, display: 'flex' },
  nameFilter   : { marginRight: 8 },
  value        : { alignSelf: 'center', paddingLeft: 4 },
  margin       : { marginRight: 18, position: 'relative', top: 2 },
});

Pipelines.propTypes = {
  classes              : PropTypes.object.isRequired,
  match                : PropTypes.object.isRequired,
  pipeline             : PropTypes.object,
  pipelineList         : PropTypes.array,
  updatePipelineCard   : PropTypes.func.isRequired,
  changePipelineState  : PropTypes.func.isRequired,
  addPipelineCard      : PropTypes.func.isRequired,
  mutateOrder          : PropTypes.func.isRequired,
  mutate               : PropTypes.func.isRequired,
  dragging             : PropTypes.bool.isRequired,
  refetch              : PropTypes.func.isRequired,
  updatePipeline       : PropTypes.func.isRequired,
  toggleTable          : PropTypes.func.isRequired,
  currentInventoryUser : PropTypes.object.isRequired,
};

Pipelines.defaultProps = {
  pipeline     : {},
  pipelineList : [],
};

export default withStyles(styles)(Pipelines);
