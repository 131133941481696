import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { Link, Redirect } from 'react-router-dom';
import { get } from 'lodash';

import DownArrow from '@material-ui/icons/ArrowDropDownSharp';

import ErrorHandler from '../../components/Reports/ErrorHandler';
/* REPORTS */
import GmvPageFull from '../../components/GmvPageFull/GmvPageFull';
import BuyersAndSellersPageFull from '../../components/BuyersAndSellersPageFull/BuyersAndSellersPageFull';
import GmvMomPageFull from '../../components/GmvMomPageFull/GmvMomPageFull';
import GmvYoyPageFull from '../../components/GmvYoyPageFull/GmvYoyPageFull';
import EngagementsComponentPageFull from '../../components/EngagementsComponentPageFull/EngagementsComponentPageFull';
import SignupReportPageContainer from './SignupReportPageContainer';
import PipelineSummaryReportContainer from './PipelineSummaryReportContainer';
import PipelineByOwnerTotalReportContainer from './PipelineByOwnerTotalReportContainer';
import PipelineProjectionReportContainer from './PipelineProjectionReportContainer';
import ActivityReportContainer from './ActivityReportContainer';
import MailerReportContainer from './MailerReportContainer';
import MotivationReportContainer from './MotivationReportContainer';
/* END REPORTS */

const reportHash = {
  activity_report            : { component: ActivityReportContainer, title: 'Activity Report ' },
  pipeline_summary           : { component: PipelineSummaryReportContainer, title: 'Pipeline Summary ' },
  signup_report              : { component: SignupReportPageContainer, title: 'Sign up Report ' },
  buyers_and_sellers         : { component: BuyersAndSellersPageFull, title: 'Buyers and Sellers Report ' },
  gmv                        : { component: GmvPageFull, title: 'GMV Report ' },
  gmv_mom                    : { component: GmvMomPageFull, title: 'GMV MoM Report ' },
  gmv_yoy                    : { component: GmvYoyPageFull, title: 'GMV YoY Report ' },
  pipeline_by_owner          : {
    component : PipelineByOwnerTotalReportContainer,
    title     : 'Pipeline by Owner Total Report',
  },
  pipeline_projection_report : { component: PipelineProjectionReportContainer, title: 'Pipeline Projection Total' },
  user_interests             : { component: null, title: 'User Interests' },
  penetration                : { component: null, title: 'Penetration' },
  continuity_readiness       : { component: null, title: 'Continuity Readiness' },
  users_by_province          : { component: null, title: 'Users by Province/State' },
  mailer_report              : { component: MailerReportContainer, title: 'Mailer Report ' },
  motivation_report          : { component: MotivationReportContainer, title: 'Motivation Report ' },
};

class ReportsContainer extends React.Component {
  state = { anchorEl: null };

  handleClick = e => {
    const { anchorEl } = this.state;

    this.setState({ anchorEl: anchorEl ? null : e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render () {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const { reportName } = this.props.match.params;
    const Report = get(reportHash, `${reportName}.component`);
    const title = get(reportHash, `${reportName}.title`, 'Select A Report');
    return (
      <Paper className={`${Report ? classes.paper : classes.noBottom}`}>
        {!reportName && <Redirect to="/admin/reports/pipeline_summary" />}
        <Toolbar>
          <h3 onClick={this.handleClick}>
            {title} {!anchorEl && <DownArrow />}
          </h3>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical   : 'bottom',
              horizontal : 'left',
            }}
            transformOrigin={{
              vertical   : 'top',
              horizontal : 'left',
            }}>
            <h3 className={classes.title}>Reports</h3>
            <div className={classes.dialog}>
              <div className={classes.section}>
                <h4>Pipelines</h4>
                <ul className={classes.list}>
                  <li>
                    <a href="/admin/reports/pipeline_summary">Pipeline Summary</a>
                  </li>
                  <li>
                    <a href="/admin/reports/pipeline_by_owner">Pipeline by Owner Total</a>
                  </li>
                  <li>
                    <a href="/admin/reports/pipeline_projection_report">Pipeline Projection Report</a>
                  </li>
                  <li>
                    <a href="/admin/reports/activity_report">Activity Report</a>
                  </li>
                </ul>
              </div>
              <div className={classes.section}>
                <h4>Marketplace Metrics</h4>
                <ul className={classes.list}>
                  <li>
                    <a href="/admin/reports/gmv">GMV</a>
                  </li>
                  <li>
                    <Link to="/admin/reports/gmv_mom">GMV: MoM</Link>
                  </li>
                  <li>
                    <a href="/admin/reports/gmv_yoy">GMV: YoY</a>
                  </li>
                  <li>
                    <a href="/admin/reports/buyers_and_sellers">Buyers & Sellers</a>
                  </li>
                  <li>
                    <a href="/admin/reports/signup_report">Signup Report</a>
                  </li>
                  <li>
                    <a href="/admin/reports/penetration">Penetration</a>
                  </li>
                  <li>
                    <a href="/admin/reports/user_interests">User Interests</a>
                  </li>
                  <li>
                    <a href="/admin/reports/continuity_readiness">Continuity Readiness</a>
                  </li>
                  <li>
                    <a href="/admin/reports/users_by_province">Users By Province/State</a>
                  </li>
                </ul>
              </div>
              <div className={classes.section}>
                <h4>Effectivity Reports</h4>
                <ul className={classes.list}>
                  <li>
                    <a href="/admin/reports/mailer_report">Mailer Report</a>
                  </li>
                </ul>
                <ul className={classes.list}>
                  <li>
                    <a href="/admin/reports/motivation_report">Motivation Report</a>
                  </li>
                </ul>
              </div>
            </div>
          </Popover>
        </Toolbar>
        <ErrorHandler>{Report ? <Report /> : <div />}</ErrorHandler>
      </Paper>
    );
  }
}

const styles = {
  paper    : {
    height   : '93vh',
    overflow : 'scroll',
  },
  noBottom : {
    borderBottom            : 'none',
    boxShadow               : 'none',
    borderBottomRightRadius : 0,
    borderBottomLeftRadius  : 0,
  },
  dialog   : {
    width         : '80vh',
    display       : 'flex',
    flexDirection : 'row',
    padding       : 4,
  },
  section  : {
    padding : 4,
    margin  : 6,
  },
  title    : {
    padding      : '12px',
    marginTop    : 0,
    marginBottom : 0,
    background   : 'grey',
    color        : '#fff',
  },
  list     : { fontSize: 16, listStyle: 'none', paddingLeft: 0 },
};

export default withStyles(styles)(ReportsContainer);
