import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import MessageButton from '../../components/MessageButton/MessageButton';
import ExpressInterestButton from '../../components/ExpressInterestButton/ExpressInterestButton';

const renderMessageButton = (
  user,
  authorized,
  rolodexEnabled,
  newUser,
  currentUserHasLimitedAccess,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly
) => {
  if (marketplaceOnly) {
    return <div />;
  }
  if (disableMessageBtn) {
    return (
      <button type="button" disabled className="btn btn-success">
        <i className="fa fa-envelope" />{' '}
        <FormattedMessage id="bobcard.card_buttons.message" defaultMessage="Message" />
      </button>
    );
  }
  if (
    newUser ||
    (user.obfuscated && user.expressed_interest) ||
    (currentUserHasLimitedAccess && user.expressed_interest)
  ) {
    return (
      <button id="button" disabled className="btn btn-primary" type="button" style={{ color: 'white' }}>
        Confirmation Pending...
      </button>
    );
  }
  if (!newUser && user.obfuscated && !user.expressed_interest) {
    return <ExpressInterestButton user={user} fromBobCard={false} />;
  }
  if (currentUserHasLimitedAccess && currentUserHasSellingTransitionGoals && user.is_pure_buyer) {
    return (
      <ExpressInterestButton
        user={user}
        fromBobCard
        currentUserHasLimitedAccess
        currentUserHasSellingTransitionGoals
        sellerWord={currentCompanySellerWord}
        getCurrentUser={getCurrentUser}
      />
    );
  }
  if (currentUserHasLimitedAccess) {
    return (
      <ExpressInterestButton
        user={user}
        fromBobCard={false}
        currentUserHasLimitedAccess
        sellerWord={currentCompanySellerWord}
      />
    );
  }
  if (!newUser && !user.obfuscated) {
    return (
      <span style={{ float: 'right' }}>
        <MessageButton userId={user.id} userName={user.user_name} noteCount={user.note_count} />
      </span>
    );
  }
};

const StandardBanner = ({
  user,
  authorized,
  rolodexEnabled,
  newUser = false,
  currentUserHasLimitedAccess,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
}) => (
  <span style={{ float: 'right' }}>
    <span style={{ fontSize: '18px', color: '#1396e2' }}>
      <FormattedMessage id="interested_in_ad.connect_with_me_now" /> &nbsp;
    </span>
    <br />
    <div>
      {!newUser &&
        renderMessageButton(
          user,
          authorized,
          rolodexEnabled,
          newUser,
          currentUserHasLimitedAccess,
          currentCompanySellerWord,
          currentUserHasSellingTransitionGoals,
          getCurrentUser,
          disableMessageBtn,
          marketplaceOnly
        )}
    </div>
  </span>
);

export default StandardBanner;
