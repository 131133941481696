import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { findIndex, snakeCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import PrimaryMarkets from './PrimaryMarkets';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($primary_markets: [String!]) {
    updateCurrentUser(primary_markets: $primary_markets)
  }
`;

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

let newStrengthsToAdd = [];
class PrimaryMarketsComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      primary_markets : '',
    };
  }
  updateUser = () => {
    const { mutate, onNext, userPrimaryMarkets, updateAction } = this.props;

    updateAction({ loading: true });

    mutate({
      variables : {
        primary_markets : userPrimaryMarkets,
      },
    })
      .then(response => {
        onNext();
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  togglePrimaryMarkets = e => {
    let { userPrimaryMarkets } = this.props;
    const { updateAction } = this.props;

    const index = findIndex(userPrimaryMarkets, o => snakeCase(o) === snakeCase(e.target.value));
    userPrimaryMarkets = [ ...userPrimaryMarkets ];
    if (index > -1) {
      userPrimaryMarkets.splice(index, 1);
    } else {
      userPrimaryMarkets.push(snakeCase(e.target.value));
    }
    updateAction({ primary_markets: userPrimaryMarkets });
  };

  addPrimaryMarket = () => {
    const { primary_markets } = this.state;
    this.setState({ primary_markets: '' });
    this.togglePrimaryMarkets({ target: { value: primary_markets } });
  };

  render () {
    const {
      userPrimaryMarkets,
      availablePrimaryMarkets,
      intl,
      businessType,
      saveUser,
      stylesheetName,
      alterItems,
      disableUpdateButton,
      userName,
      saveUserAndNext,
    } = this.props;
    return (
      <div className="content">
        <b><FormattedMessage id="profile_page.strengths_component.primary_markets" /></b>
        <p><FormattedMessage id="profile_page.strengths_component.my_primary_markets_are" /></p>
        {userPrimaryMarkets.length > 5 && <div style={{ color: 'red' }}><FormattedMessage id="profile_page.strengths_component.please_choose_or_less" /></div>}
        <div>
          <div className="col-md-9">
            <TextField
              id="primary_market"
              label={intl.formatMessage({ id: `profile_page.strengths_component.primary_markets` })}
              type="text"
              value={this.state.primary_markets}
              onChange={e => {
                this.setState({ primary_markets: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="col-md-3">
            <button onClick={this.addPrimaryMarket} className="btn btn-default" style={{ padding: '12px' }}>
              <AddIcon />
            </button>
          </div>
        </div>
        <br />
        <br />
        <PrimaryMarkets
          availablePrimaryMarkets={availablePrimaryMarkets}
          userPrimaryMarkets={userPrimaryMarkets}
          togglePrimaryMarkets={this.togglePrimaryMarkets}
          stylesheetName={stylesheetName}
        />
        <br />
        <br />
        <br />
        <div className="text-center">
          {this.props.onBack && (
            <button
              className="btn btn-default"
              style={{ padding: '12px 24px', float: 'left' }}
              onClick={this.props.onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
          {!alterItems && (
            <LoadingButtonWrapper stateChild="user">
              <button
                onClick={() => saveUserAndNext()}
                disabled={disableUpdateButton}
                className={`btn ${stylesheetName === 'rjpe' ? 'btn-default' : 'btn-success'} center text-center`}
                style={{ padding: '12px 24px' }}>
                <FormattedMessage id="profile_page.save_and_next" />
              </button>
              <button
                onClick={() => (window.location.href = `/users/${userName}`)}
                disabled={disableUpdateButton}
                className={`btn btn-secondary`}
                style={{ padding: '12px 24px', marginLeft: 20 }}>
                <FormattedMessage id="profile_page.cancel" />
              </button>
            </LoadingButtonWrapper>
          )}
        </div>
      </div>
    );
  }
}

PrimaryMarketsComponent.propTypes = {
  updateAction      : PropTypes.func,
  availbleStrengths : PropTypes.array,
};

PrimaryMarketsComponent.defaultProps = {
  number_of_clients : 500,
};

const PrimaryMarketsComponentWithStyles = injectIntl(withStyles(styles)(PrimaryMarketsComponent));
export default graphql(UpdateCurrentUser)(PrimaryMarketsComponentWithStyles);
