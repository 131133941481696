import React from 'react';
import { Provider } from 'react-intl-redux';
import ChatWidget from "../components/ChatWidget/ChatWidget";
import configureStore from '../store/findBobPublicStore';
import { createIntlCache, IntlProvider } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import defaultLocale from '../lib/i18n/default.json';
import translations from '../lib/i18n/translations.json';

const ChatWidgetContainer = props => {

	const cache = createIntlCache();

	const setIntl = () => {
	updateIntl(
	{
		locale   : defaultLocale,
		messages : translations[window.locale || 'en'],
	},
	cache
	);}

	let store = document.store;
	if (!store) {
		store = configureStore(props);
		document.store = store;
	}

	setIntl();
	return (
		<Provider store={document.store} {...props}>
			<IntlProvider messages={translations[window.locale || 'en']} locale={window.locale || 'en'}>
				<ChatWidget {...props} />
			</IntlProvider>
		</Provider>
	);
}

export default ChatWidgetContainer;