import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TipViewFirstValuation from '../components/TipViewFirstValuation/TipViewFirstValuation';
import { get } from 'lodash';
import ProfileDrawerWithListener from './ProfileDrawerWithListener';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { Query, ApolloProvider } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      annual_revenue
      gross_expenses
      owners_compensation
      branch_net_payout
      expense_growth_rate
      marginal_tax_rate
      rj_terminal_growth_rate
      fee_based_revenue
      trailer_revenue
      direct_revenue
      transactional_revenue
      year_1_gross
      year_2_gross
      year_3_gross
      year_4_gross
      year_5_gross
      year_6_gross
      year_1_aum
      year_2_aum
      year_3_aum
      year_4_aum
      year_5_aum
      year_6_aum
      revenue_growth_rate
      use_benchmark_expense_percentage
      use_revenue_growth_rate
      three_yr_avg_t12
      benchmark_expenses
      motivation
      company {
        id
        name
        business_type
        branch_net_payout_read_only
        employee_based_advisors
        imported_production_data_read_only
        allow_user_to_specify_revenue_growth_rate
        show_pull_latest_revenue_button
        hide_direct_revenue
        hide_branch_net_payout
        trails_word
        insurance_commissions_information_tooltip
        use_enterprise_provided_growth_rate
      }
    }
  }
`;

const TipViewFirstValuationContainer = ({
  updateSelectedProfileDrawer,
}) => {
  const [ currentUserAllowed, setCurrentUserAllowed ] = useState(false);
  const [ data, setData ] = useState({});
  return (
    <ApolloProvider client={client}>
    <Query query={getCurrentUser}>
      {({ loading, data, refetch }) => {
        if (loading) return <CircularProgress />;
          return (
            <div>
              <ProfileDrawerWithListener />
              <TipViewFirstValuation
                marketplaceOnly
                currentUserAllowed={currentUserAllowed}
                data={data}
                updateSelectedProfileDrawer={updateSelectedProfileDrawer}
                agentWordForCompany={get(data, 'agentWordForCompany')}
                refetch={refetch}
                companyBusinessType={get(data, 'companyBusinessType')}
              />
            </div>
          );     
        }}
      </Query>
    </ApolloProvider>
  );
};

const mapStateToProps = state => ({
  profile  : state.profileDrawer,
});

TipViewFirstValuationContainer.propTypes = {
  updateUser                  : PropTypes.func.isRequired,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
};

TipViewFirstValuationContainer.defaultProps = {
  profileView            : false,
  showRevSliders         : false,
  saveToUser             : false,
  updatePePracticeInfo   : () => {},
  updatePeProductSummary : () => {},
  updateGrossProduction  : () => {},
};

const styles = theme => ({
  toolTip  : {
    color : '#1396e2',
  },
  headline : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

export default connect(mapStateToProps, {
})(withStyles(styles)(injectIntl(TipViewFirstValuationContainer)));
