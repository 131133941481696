import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

const flipCoin = () => {
  return Math.floor(Math.random() * 2) == 0;
};

export const AuctionAd = () => (
  <section id="advertising">
    <a href="/auctions">
      <div className="banner">
        <div className="wrapper">
          <span className="title">
            {flipCoin() ? 'Looking for the Advisor Showcase?' : 'Learn more about our spotlighted advisors.'}
          </span>
          <span className="submit">
            Click here! <i className="fas fa-plus-square" />
          </span>
        </div>
      </div>
    </a>
  </section>
);

export const PPIAd = () => (
  <section id="advertising">
    <div className="banner">
      <div className="wrapper">
        <span className="title">
          <FormattedMessage id="ppi.dont_have_a_business_continuity_plan" />
        </span>
      </div>
    </div>
  </section>
);

export const InterestedInAd = () => (
  <section id="advertising">
    <a href="/dashboard/interested_in?interest=continuity">
      <div className="banner">
        <div className="wrapper">
          <span className="title">
            <FormattedMessage id="interested_in_ad.copy" />
          </span>
          <span className="submit">
            <FormattedMessage id="interested_in_ad.click_to_learn_more" /> <i className="fas fa-plus-square" />
          </span>
        </div>
      </div>
    </a>
  </section>
);

export const SubscriptionsAd = () => (
  <section id="advertising">
    <a href="/subscriptions">
      <div className="banner">
        <div className="wrapper">
          <span className="title">
            Subscribe to see complete profile details, private message and participate in showcases.
          </span>
          <span className="submit">
            Enroll Now! <i className="fas fa-plus-square" />
          </span>
        </div>
      </div>
    </a>
  </section>
);
