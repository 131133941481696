import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { isEmpty, toUpper, get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { STEP_NUMBERS } from '../SignUpWizard/SignUpWizard';
import { TextField as MTextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import WarningText from '../WarningText/WarningText';
import LoadingButtonWrapper from '../LoadingButtonWrapper/LoadingButtonWrapper';
import PoliciesDialog from './PoliciesDialog';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $postal_code: String
    $phone: String
    $locale: String
    $email: String
    $name: String
    $new_in_industry: Boolean
    $completed_step_1: Boolean
    $accepted_policies: Boolean
  ) {
    updateCurrentUser(
      postal_code: $postal_code
      phone: $phone
      locale: $locale
      email: $email
      name: $name
      new_in_industry: $new_in_industry
      completed_step_1: $completed_step_1
      accepted_policies: $accepted_policies
    )
  }
`;

const styles = () => ({
  formGroup           : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle          : {
    paddingLeft : '6px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  policyModal         : {
    maxWidth : 900,
  },
});

class PostalAndPhone extends React.Component {
  state = {
    error         : null,
    email         : this.props.email,
    openAgreement : false,
    agreedToPp    : false,
    agreedToTos   : false,
    openPp        : false,
    openTos       : false,
  };

  updateUser = () => {
    const {
      mutate,
      onNext,
      allowEmail,
      name,
      disableName,
      postal_code,
      phone,
      locale,
      updateAction,
      optInScrollPrivacyPolicy,
    } = this.props;
    const { email, new_in_industry, agreedToPp, agreedToTos } = this.state;
    const variables = {
      postal_code,
      phone,
      locale,
      new_in_industry  : !new_in_industry,
      completed_step_1 : true,
    };
    if (allowEmail) {
      variables.email = email;
    }
    if (!disableName) {
      variables.name = name;
    }

    if (optInScrollPrivacyPolicy) {
      variables.accepted_policies = agreedToPp && agreedToTos;
    }

    updateAction({ loading: true });
    mutate({
      variables,
    })
      .then(response => {
        updateAction({ has_postal_code: true, postal_code, phone, locale });
        onNext();
        updateAction({ loading: false });
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  validate = () => {
    const { postal_code, allowEmail, optInScrollPrivacyPolicy } = this.props;
    const { email, agreedToPp, agreedToTos } = this.state;
    // if toggle is on for pp
    if (optInScrollPrivacyPolicy && (!agreedToPp || !agreedToTos)) {
      return false;
    }

    if ((allowEmail && isEmpty(email)) || !this.validateEmail()) {
      return false;
    }
    if (isEmpty(postal_code) || !this.validatePostalCode()) return false;
    return true;
  };

  validatePostalCode = () => {
    const { postal_code } = this.props;

    const matcher = /^(\d{5}(-\d{4})?|[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d)$/;
    return isEmpty(postal_code) || matcher.test(toUpper(postal_code));
  };

  validatePhone = () => {
    const { phone } = this.props;
    const matcher = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return isEmpty(phone) || matcher.test(phone);
  };

  validateEmail = () => {
    const { allowEmail } = this.props;
    if (!allowEmail) return true;
    const { email } = this.state;
    const matcher = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return isEmpty(email) || matcher.test(email);
  };

  clickOpenAgreement = () => {
    this.setState({ openAgreement: true });
  };

  handleClose = () => {
    this.setState({ openAgreement: false, openPp: false, openTos: false });
  };

  stringToHTML = str => {
    return <div dangerouslySetInnerHTML={{ __html: str }} />;
  };

  clickAgreePp = () => {
    this.setState({ agreedToPp: true, openPp: false });
  };

  clickAgreeTos = () => {
    this.setState({ agreedToTos: true, openTos: false });
  };

  clickDisagreeTos = () => {
    this.setState({ agreedToTos: false, openTos: false });
  };

  clickDisagreePp = () => {
    this.setState({ agreedToPp: false, openPp: false });
  };

  render () {
    const {
      updateAction,
      postal_code,
      classes,
      phone,
      countryForCompany,
      allowEmail,
      displayBusinessQuestion,
      modalTitle,
      modalSubtitle,
      disableEmail,
      stylesheetName,
      name,
      brandedName,
      disableName,
      privacyPolicy,
      termsOfService,
      optInScrollPrivacyPolicy,
    } = this.props;
    const { email, error, new_in_industry, openAgreement, agreedToPp, agreedToTos } = this.state;

    if(email == null) {
      this.setState({email: this.props.email});
    }
    
    return (
      <div className="content">
        <h2>{modalTitle}</h2>
        <p>{modalSubtitle}</p>
        <WarningText visible={error} message={get(this.state, 'error.message', '').replace('GraphQL error:', '')} />
        {disableEmail && (
          <div>
            <b>Name:</b>
            <TextField
              placeholder="Name"
              value={name}
              onChange={e => updateAction({ name: e.target.value })}
              shouldFitContainer
              isLabelHidden
              fullWidth
              compact
              isInvalid={!name}
              disabled={disableName}
            />
            <br />
            <br />
          </div>
        )}
        {allowEmail && (
          <div>
            <b>
              <FormattedMessage id="shared.email" />:
            </b>
            {!disableEmail && (
              <p>
                <small>
                  <FormattedMessage id="sign_up_pages.email_message" />:
                </small>
              </p>
            )}
            {disableEmail && (
              <p>
                <small>{`This is the email which ${brandedName} will send communications to`}:</small>
              </p>
            )}
            <TextField
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              shouldFitContainer
              isLabelHidden
              fullWidth
              isInvalid={!this.validateEmail()}
              compact
              disabled={disableEmail}
            />
            <br />
            <br />
          </div>
        )}
        <b>{countryForCompany !== 'ca' ? 'Zip Code:' : <FormattedMessage id="sign_up_pages.postal_code" />}</b>
        <TextField
          placeholder={countryForCompany !== 'ca' ? '' : 'A1A 1A1'}
          value={postal_code}
          onChange={e => updateAction({ postal_code: e.target.value })}
          shouldFitContainer
          isLabelHidden
          fullWidth
          isInvalid={!this.validatePostalCode()}
          compact
        />
        <br />
        <br />
        <b>
          <FormattedMessage id="sign_up_pages.phone_number" />
        </b>
        <p>
          <small>
            <FormattedMessage id="sign_up_pages.your_phone_numer_is_not_kept" />
          </small>
        </p>
        <TextField
          value={phone}
          onChange={e => updateAction({ phone: e.target.value })}
          shouldFitContainer
          fullWidth
          isLabelHidden
          isInvalid={!this.validatePhone()}
          compact
        />
        <br />
        {displayBusinessQuestion && (
          <div>
            <p>
              <b>Are you already in business</b>
            </p>
            <MTextField
              select
              variant="standard"
              fullWidth
              classes={{
                root : classes.menuItem,
              }}
              InputProps={{
                className : classes.menuItem,
              }}
              value={new_in_industry ? 'yes' : 'no'}
              onChange={e => {
                this.setState({ new_in_industry: e.target.value === 'yes' });
              }}>
              <MenuItem key="yes" value="yes">
                Yes
              </MenuItem>
              <MenuItem key="no" value="no">
                No
              </MenuItem>
            </MTextField>
          </div>
        )}
        <br />
        <div className="text-center">
          <LoadingButtonWrapper stateChild="user">
            <button
              onClick={optInScrollPrivacyPolicy ? this.clickOpenAgreement : this.updateUser}
              className={stylesheetName === 'rjpe' ? classes[`${stylesheetName}BackgroundColor`] : 'btn btn-default'}
              style={{ padding: '12px 24px', color: '#fff' }}
              variant="contained"
              color="primary"
              disabled={!optInScrollPrivacyPolicy && !this.validate()}>
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          </LoadingButtonWrapper>
        </div>
        <PoliciesDialog
          openAgreementProp={openAgreement}
          updateUser={this.updateUser}
          privacyPolicy={privacyPolicy}
          termsOfService={termsOfService}
          validate={this.validate}
          optInScrollPrivacyPolicy={optInScrollPrivacyPolicy}
          handleClose={this.handleClose}
          stylesheetName={stylesheetName}
          agreedToPp={agreedToPp}
          agreedToTos={agreedToTos}
          clickAgreePp={this.clickAgreePp}
          clickAgreeTos={this.clickAgreeTos}
          clickDisagreePp={this.clickDisagreePp}
          clickDisagreeTos={this.clickDisagreeTos}
        />
      </div>
    );
  }
}

PostalAndPhone.propTypes = {
  classes                 : PropTypes.object.isRequired,
  postal_code             : PropTypes.string,
  phone                   : PropTypes.string,
  locale                  : PropTypes.string.isRequired,
  updateAction            : PropTypes.func.isRequired,
  allowEmail              : PropTypes.bool,
  displayBusinessQuestion : PropTypes.bool,
  mutate                  : PropTypes.func.isRequired,
  onNext                  : PropTypes.func.isRequired,
  countryForCompany       : PropTypes.string,
  modalTitle              : PropTypes.string,
  modalSubtitle           : PropTypes.string,
  disableName             : PropTypes.bool,
  disableEmail            : PropTypes.bool,
};

PostalAndPhone.defaultProps = {
  postal_code             : '',
  phone                   : '',
  allowEmail              : true,
  displayBusinessQuestion : false,
  countryForCompany       : 'ca',
  modalTitle              : <FormattedMessage id="sign_up_pages.here_we_go" />,
  modalSubtitle           : <FormattedMessage id="sign_up_pages.please_take_twenty_seconds" />,
  disableEmail            : false,
  disableName             : false,
};

const PostalAndPhoneWithStyles = withStyles(styles)(PostalAndPhone);
export default graphql(UpdateCurrentUser)(PostalAndPhoneWithStyles);
