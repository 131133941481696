import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CalibrationStaticGrid from '../CalibrationSessions/CalibrationStaticGrid';
import { get } from 'lodash';
import classNames from 'classnames';

const updatePvp = (number, updateUser, updateUserState) => {
  updateUserState({ pvp_value: number }, () => updateUser({ pvp_value: number }));
};

export const NineBoxInput = props => {
  const { classes, updateUser, iUser, updateUserState } = props;
  return (
    <table>
      <tr>
        <td>
          <button
            className={classNames({
              [classes.button]: true,
              [classes.yellow]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_potential_gem',
            })}
            onClick={() => updatePvp('pvp_potential_gem', updateUser, updateUserState)}>
            Potential Gem
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_future_star', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.green]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_future_star',
            })}>
            Future Star
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_rainmaker', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.green]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_rainmaker',
            })}>
            Rainmaker
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button
            onClick={() => updatePvp('pvp_inconsistent_player', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.red]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_inconsistent_player',
            })}>
            Inconsistent Player
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_core_player', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.yellow]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_core_player',
            })}>
            Core Player
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_current_star', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.green]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_current_star',
            })}>
            Current Star
          </button>
        </td>
      </tr>
      <tr>
        <td>
          <button
            onClick={() => updatePvp('pvp_risk', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.red]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_risk',
            })}>
            Risk
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_average_performer', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.red]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_average_performer',
            })}>
            Average Performer
          </button>
        </td>
        <td>
          <button
            onClick={() => updatePvp('pvp_solid_performer', updateUser, updateUserState)}
            className={classNames({
              [classes.button]: true,
              [classes.yellow]: true,
              [classes.selected]: get(iUser, 'pvp_value') === 'pvp_solid_performer',
            })}>
            Solid Performer
          </button>
        </td>
      </tr>
    </table>
  );
};

NineBoxInput.propTypes = {
  iUser      : PropTypes.object,
  updateUser : PropTypes.func.isRequired,
  classes    : PropTypes.object.isRequired,
};

NineBoxInput.defaultProps = {
  iUser : {},
};

const styles = {
  button   : {
    width    : 125,
    height   : 125,
    border   : '#fff 1px solid',
    color    : '#fff',
    fontSize : 14,
  },
  red      : {
    background : 'rgb(204, 0, 0)',
  },
  yellow   : {
    background : 'rgb(241, 194, 50)',
  },
  green    : {
    background : 'rgb(106, 168, 79)',
  },
  selected : {
    background : 'rgb(77, 166, 255) !important',
  },
};

export default withStyles(styles)(NineBoxInput);
