import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const AskMeAbout = ({ classes, dealInterest }) => {
  if (get(dealInterest, 'inventory_user.user.ask_me_about') !== null) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.ask_me_about"/></strong>
        <p>{get(dealInterest, 'inventory_user.user.ask_me_about')}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(AskMeAbout);
