import React from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';

class FBTableDistanceSearch extends React.Component {
  state = {distanceSearch: null, distanceWithin: null, showTextBoxes: false};

  handleTextChange = debounce(({distanceSearch, distanceWithin}) => {
    const { onDistanceSearch } = this.props;
    onDistanceSearch({distanceSearch, distanceWithin});
  }, 500);

  handleNotListedLocationIconClick = () => {
    this.setState(prevState => ({ showTextBoxes: !prevState.showTextBoxes }));
  }
  render () {
    const { classes, onHide } = this.props;
    const { showTextBoxes } = this.state;

    return (
      <Grow appear in={true} timeout={300}>
      <div className={classes.main} ref={el => (this.rootRef = el)}>
          <Tooltip title="Filter by Distance">
              <IconButton onClick={this.handleNotListedLocationIconClick}>
                  <NotListedLocationIcon className={classes.NotListedLocationIcon} />
              </IconButton>
          </Tooltip>
          {showTextBoxes && (
          <React.Fragment>
          <div>
              City, Province/State Code:
              <TextField
              className={classes.distanceSearch}
              autoFocus={true}
              placeholder="Toronto, On"
              onChange={e => { 
                  const {distanceWithin} = this.state; 
                  const val = e.target.value;
                if(!val){
                    this.setState({distanceSearch: ""});
                    this.handleTextChange({distanceSearch: "####NONE####", distanceWithin});
                }else{
                    this.setState({distanceSearch: val});
                    this.handleTextChange({distanceSearch: val, distanceWithin});
                }
              }}
              fullWidth={true}
              inputRef={el => {this.searchField = el}}
              />
              Miles/KMs
              <TextField
              className={classes.distanceWithin}
              placeholder="200"
              onChange={e => { 
                  const {distanceSearch} = this.state; 
                  const val = e.target.value;
                  if(!val){
                      this.setState({distanceWithin: ""});
                      this.handleTextChange({distanceWithin: "####NONE####", distanceSearch});
                  }else{
                    this.setState({distanceWithin: parseInt(val)});
                    this.handleTextChange({distanceWithin: parseInt(val), distanceSearch});  
                  } 
              }}
              fullWidth={true}
              inputRef={el => {this.searchField = el}}
              />
          </div>
          </React.Fragment>)}
      </div>
    </Grow>
    );
  }
}

const styles = theme => ({
  main       : {
    display : 'flex',
    flex    : '1 0 auto',
    float   : 'left',
  },
  NotListedLocationIcon : {
    color       : theme.palette.text.secondary,
  },
  searchText : {
    flex : '0.8 0',
  },
  clearIcon  : {
    '&:hover' : {
      color : theme.palette.error.main,
    },
  },
  iconButton: {
    margin: 0
  }
});

export default withStyles(styles)(FBTableDistanceSearch);
