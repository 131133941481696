import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const SellerOrdinaryBreakdown = ({result, classes, intl, lenderUrl, company, query}) => {
  const sellerCashFlow = get(result, 'cashFlow.illustration.sellerCashFlow', []);

  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  
  let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(0);
  if(isNaN(consultingPercentOfDeal)) {
      consultingPercentOfDeal = 0;
  }

  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note'); 
  
  return (
    <React.Fragment>
      <tr>
        <td><strong><FormattedMessage id="calculators.cfa.year"/></strong></td>
            {sellerCashFlow.map((v, i) => (<td style={{textAlign: 'right'}}><strong>{i + 1}</strong></td>))}
        </tr>
        <tr>
            <td><i><FormattedMessage id="calculators.cfa.revenue_growth"/></i></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.revenueGrowth).format(percentFormat)}</td>))}
        </tr>
        <tr>
            <td><FormattedMessage id="calculators.cfa.personal_production"/></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.personalProduction).format(dollarFormat)}</td>))}
        </tr>
        <tr>
            <td>{get(result, 'company.cashflow_payout_word')} Rate</td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.payoutRate).format(intl.locale === 'en' ? '0.0%' : '0.0 %')}</td>))}
        </tr>
        <tr>
            <td>{get(result, 'company.cashflow_payout_word')}</td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.payout).format(dollarFormat)}</td>))}
        </tr>
        { document.location.href.match('rja') && <tr>
            <td><strong><FormattedMessage id="calculators.cfa.retirement_choice"/></strong></td>
        </tr>}
        <tr>
            <td><strong><FormattedMessage id="calculators.cfa.book_sell_payment_received"/></strong></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.bookSalePaymentReceived).format(dollarFormat)}</b></td>))}
        </tr>
        <tr>
            <td><FormattedMessage id="calculators.cfa.lump_sum_payment_received"/></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.lumpSumPaymentReceived).format(dollarFormat)}</td>))}
        </tr>
        <tr>
            <td><FormattedMessage id="calculators.cfa.fixed_payment_received"/></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.fixedPaymentReceived).format(dollarFormat)}</td>))}
        </tr>
        <tr>
        {!document.location.href.match('icd') ? (
          <td>
            <FormattedMessage id="calculators.cfa.contingent_continuite_payment_received" />
          </td>
        ) : (
          <td>
            <FormattedMessage id="calculators.cfa.contingent_participation_payment_received" />
          </td>
        )}
          {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.contingentPaymentReceived).format(dollarFormat)}</td>))}
        </tr>
        {showCheckSwap && <React.Fragment>
          <tr>
          <td><strong>Check Swap</strong></td>
            <td></td>
          </tr>
          <tr>
            <td><FormattedMessage id="calculators.cfa.transition_assistance_bonus"/></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.transitionAssistanceLoanBonus).format(dollarFormat)}</td>))}
          </tr>
          <tr>
            <td><FormattedMessage id="calculators.cfa.check_swap_payment_from_buyer"/></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.checkSwapPayment).format(dollarFormat)}</td>))}
          </tr>
        </React.Fragment>}
        {showConsultingAgreements && <React.Fragment>
          <tr>
            <td>Consulting Agreement</td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.consultingAgreement).format(dollarFormat)}</b></td>))}
        </tr> 
          </React.Fragment>}
        { showSellerNote && <tr>
          <td>Seller Note</td>
          {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.sellerNotePayment).format(dollarFormat)}</td>))}
        </tr> }
        <tr>
            <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.pretax_net_income"/></strong></td>
            {sellerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>{numeral(v.pretaxNetIncome).format(dollarFormat)}</td>))}
        </tr>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    lenderUrl: get(state, 'notes.lender_url'),
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};

export default injectIntl(connect(mapStateToProps)(SellerOrdinaryBreakdown));