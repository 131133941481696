import { createMuiTheme } from '@material-ui/core/styles';

export const rjTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#002949',
        },
        secondary: {
            main: '#5489B6',
        },
        accent: {
            main: '#002949',
        },
        highlight: {
            main: '#5B5E5A',
        },
        graph: ['#5489B6', '#002949', '#5B5E5A', '#282B3E', '#ec008c', '#68217a', '#00bcf2', '#00b294', '#00188f', '#ff8c00'],
    },
    props: {
        MuiButton: {
            size: 'small',
            color: 'primary',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
        MuiSlider: {
            color: 'primary',
        },
    },
};

export default rjTheme;