import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import ContactInformationForm from '../SignUpWizard/ContactInformationForm';
import * as actions from '../../actions/userActionCreators';

const CreateNewUser = gql`
  mutation createCandidate(
    $fullName: String!
    $email: String!
    $birthDate: String!
    $password: String!
    $postal: String!
    $phone: String
    $locale: String!
    $new_in_industry: Boolean!
  ) {
    createCandidate(
      fullName: $fullName
      email: $email
      birthDate: $birthDate
      password: $password
      postal: $postal
      phone: $phone
      locale: $locale
      new_in_industry: $new_in_industry
    )
  }
`;
const ContactInformation = ({ user, intl, updateUser, onCreate, mutate, email, paramsLocale }) => {
  return (
    <ContactInformationForm
      intl={intl}
      mutate={mutate}
      {...user}
      displayBusinessQuestion
      agentWord="agent"
      locale="en"
      updateAction={updateUser}
      onCreate={onCreate}
      onNext={() => {}}
      paramsLocale={paramsLocale}
    />
  );
};

const mapStateToProps = state => ({
  user : state.user,
  intl : state.intl,
});

ContactInformation.defaultProps = {
  onCreate : () => {},
};

export default connect(mapStateToProps, actions)(graphql(CreateNewUser)(ContactInformation));
