import { connect } from 'react-redux';
import Plan from '../components/Plan/Plan';
import NewFormDialog from '../components/Plan/PlanNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/plansActionCreators';

const mapStateToProps = state => ({
  ...state,
  listData: state.plans,
  title: state.intl.messages['admin_plans.plans'],
  NewFormDialog,
  CrudRow: Plan,
});

export default connect(mapStateToProps, actions)(CrudTable);
