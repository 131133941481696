import { get } from 'lodash';
import {
  NOTE_LIST_UPDATE,
  NOTE_UPDATE,
  FILTER_UPDATE,
  NOTE_STATUS_UPDATE,
  SELECTED_NOTE_UPDATE,
} from '../constants/noteConstants';

const noteReducer = (
  state = {
    notes  : [],
    loaded : false,
  },
  action
) => {
  switch (action.type) {
    case NOTE_LIST_UPDATE:
      return { ...state, ...action.notes, loaded: Boolean(get(action, 'notes.notes')) };
    case NOTE_UPDATE:
      return { ...state, notes: { ...state.note, ...action.note } };
    case SELECTED_NOTE_UPDATE:
      return {
        ...state,
        data : action.selectedProfileDrawer,
      };
    case FILTER_UPDATE:
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case NOTE_STATUS_UPDATE:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default noteReducer;
