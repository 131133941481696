import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { get, isEmpty } from 'lodash';
import CfaWizard from '../components/calculators/CfaWizard';
import * as cfaActionCreators from '../actions/cfaActionCreators';
import { updateCompany } from '../actions/companyActionCreators';
import { CircularProgress } from '@material-ui/core';
import qs from 'query-string';

const GetCashFlow = gql`
	query cashFlow($friendlyId: String) {
		cashFlows{
			id
			name
			createdAt
			sellerAverageCashFlow
			buyer1AverageCashFlow
			buyer2AverageCashFlow
			friendlyName
		}
		company {
			id
			host
			cash_flow_analysis_word
			cash_flow_analysis_word_fr
		}
		cashFlow(friendlyId: $friendlyId) {
			id
			name
			cashFlowLength
			cashFlowProduction
			cashFlowProductionShift  # Add This to edit Production Shifts In Year cant be greater than length
			sellerT12Revenue
			sellerFiveYearGrowthRate
			numberOfBuyers
			buyer1T12Revenue
			buyer1FiveYearGrowthRate
			buyer1PercentagePurchasing
			buyer2T12Revenue
			buyer2FiveYearGrowthRate
			buyer2PercentagePurchasing
			buyerHasLumpSumPayment
			buyerLumpSumAmount
			buyerLumpSumYear
			buyersFinancingAmount
			buyerHasFixedPayments
			buyerFixedPaymentYears
			buyerFixedPaymentAmount
			buyerFixedPaymentStartYear
			buyerHasContinuingPayments
			buyerContinuingPaymentYears
			buyerContinuingPaymentStartYear
			buyerContinuingPaymentAmount
			buyerContinuingPayments
		}
	}
`;

const CfaWizardContainer = props => {
	const params = qs.parse(location.search);
	const { id } = params;
	let query = props.query;
	if(isEmpty(query)){
		query = {  ...query  };
	}


	return (<Query  query={GetCashFlow} 
									variables={query}
									fetchPolicy='cache-and-network'
									onCompleted={data => {
										props.updateCfaResults(data);
										props.updateCfaQuery({...query, updating: false});
										props.updateCompany({cash_flow_analysis_word: data.company.cash_flow_analysis_word, cash_flow_analysis_word_fr: data.company.cash_flow_analysis_word_fr, host: data.company.host});
									}}>{ ({loading}) =>	((loading && !props.result) ? <CircularProgress /> : <CfaWizard />)
									}
			
		</Query>);
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
		query: {...get(state, 'cfa.query', {})},
  };
};

export default connect(mapStateToProps, { ...cfaActionCreators, updateCompany})(
  CfaWizardContainer
);