import React from 'react';
import { Button, ButtonGroup, withStyles } from '@material-ui/core';
import { get } from 'lodash';

const Pagination = ({ page, setPage, totalPages, classes }) => {
  return (
    <div className={classes.pagination}>
      <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={() => setPage(0)} disabled={page === 0}>
          First
        </Button>
        <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
          Previous
        </Button>
        <Button onClick={() => setPage(page + 1)} disabled={page >= totalPages - 1}>
          Next
        </Button>
        <Button onClick={() => setPage(totalPages - 1)} disabled={page >= totalPages - 1}>
          Last
        </Button>
      </ButtonGroup>
    </div>
  );
};

const styles = theme => ({
  pagination: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
});

export default withStyles(styles)(Pagination);
