import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { get, capitalize, isEmpty } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import { createDealMutation } from '../Deals/DealQueries';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { FormattedMessage, injectIntl } from 'react-intl';

class AddDealDialog extends React.Component {
  originalState = {
    owner               : this.props.owner,
    number_of_invites   : null,
    invitation_deadline : moment(moment('12:00', 'HH:mm')).add(1, 'month'),
    open                : false,
    deal_type           : null,
    suggested_deal_id   : null,
  };

  state = { ...this.originalState };

  componentWillReceiveProps = nextProps => {
    const { owner, suggestedDeal } = this.props;
    if (owner) {
      this.setState({
        owner,
      });
    }
    if (suggestedDeal !== nextProps.suggestedDeal) {
      this.setState({
        deal_type         : nextProps.suggestedDeal.deal_type,
        suggested_deal_id : nextProps.suggestedDeal.deal_id,
      });
    }
  };

  handleClickOpen = () => {
    const { currentInventoryUser } = this.props;
    this.setState({ owner: currentInventoryUser });
  };

  handleClose = () => {
    const { close, updateSelectedSuggestedDeal } = this.props;
    if (updateSelectedSuggestedDeal) {
      updateSelectedSuggestedDeal({});
    }
    if (close) {
      close();
    }
    this.setState({ ...this.originalState });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render () {
    const { classes, inventoryUser, agentWordForCompany, deleteSuggestedDeal, suggestedDeal } = this.props;
    const { number_of_invites, owner, invitation_deadline, deal_type, suggested_deal_id } = this.state;
    const agentWord = agentWordForCompany || get(suggestedDeal, 'agentWordForCompany', '');

    return (
      <div style={{ display: 'contents' }}>
        <Dialog
          open={!isEmpty(suggestedDeal)}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Add a new deal</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <div className={classes.dataLeft}>
              {capitalize(agentWord)}: {get(suggestedDeal, 'name', '')}
            </div>
            <br />
            <ManagerSearchField
              label="Owner"
              value={owner}
              onChange={v => {
                this.setState({ owner: v });
              }}
            />
            <TextField
              id="number_of_invites"
              label="Number of Invites Allowed"
              fullWidth
              type="number"
              className={classes.textField}
              value={number_of_invites}
              onChange={this.handleChange('number_of_invites')}
              margin="normal"
            />
            <InputLabel style={{ marginTop: 10 }} shrink>
              Deal Type
            </InputLabel>
            <Select
              style={{ minWidth: 200 }}
              value={deal_type}
              className={classes.inputField}
              onChange={this.handleChange('deal_type')}>
              <MenuItem key={0} value="continuity">
                <FormattedMessage id="dr_wizard.continuity" />
              </MenuItem>
              <MenuItem key={1} value="succession">
                <FormattedMessage id="dr_wizard.succession" />
              </MenuItem>
              <MenuItem key={2} value="sale">
                <FormattedMessage id="dr_wizard.sale" />
              </MenuItem>
            </Select>
            <div>
              <br />
              <b>Invitation Deadline</b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={invitation_deadline}
                  onChange={date => this.setState({ invitation_deadline: date })}
                />
              </MuiPickersUtilsProvider>
            </div>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Mutation mutation={createDealMutation}>
              {createDeal => (
                <Button
                  onClick={() => {
                    createDeal({
                      variables : {
                        inventory_user_id   : inventoryUser.inv_id,
                        owner_id            : owner.id,
                        number_of_invites   : parseInt(number_of_invites, 10),
                        invitation_deadline,
                        deal_type,
                      },
                    }).then(r => {
                      deleteSuggestedDeal({ variables: { id: suggested_deal_id } });
                      window.open(`/admin/deals/${get(r, 'data.createDeal.id', '')}`, '_self');
                    });
                    this.handleClose();
                  }}
                  color="primary"
                  disabled={!number_of_invites || !inventoryUser || !owner || !invitation_deadline || !deal_type}>
                  Save
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const styles = {
  taskButton : {
    marginLeft : 'auto',
  },
  dataLeft   : {
    color      : 'rgba(0, 0, 0, 0.54)',
    fontSize   : 13,
    fontWeight : 'bold',
  },
};

AddDealDialog.propTypes = {
  classes              : PropTypes.object.isRequired,
  currentInventoryUser : PropTypes.object.isRequired,
  inventoryUser        : PropTypes.object.isRequired,
  close                : PropTypes.func.isRequired,
  deleteSuggestedDeal  : PropTypes.func.isRequired,
  agentWordForCompany  : PropTypes.string.isRequired,
  open                 : PropTypes.bool,
};

AddDealDialog.defaultProps = {
  open : null,
};

export default withStyles(styles)(AddDealDialog);
