import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import InventoryUserSearchField from '../components/InventoryUserSearchField/InventoryUserSearchField';

export const InventoryUserQuery = gql`
  query InventoryUserQueries($offset: Int, $limit: Int, $nameSearch: String) {
    getInventoryUsers(offset: $offset, limit: $limit, nameSearch: $nameSearch) {
      id
      name
      province
      profile_picture
      has_user
      user {
        id
        annual_revenue
        __typename
      }
    }
  }
`;

export const InventoryUserSearchFieldContainer = ({
  isMulti,
  label,
  onChange,
  value,
}) => (
  <Query query={InventoryUserQuery} variables={{ offset: 0, limit: 10 }}>
    {({ refetch }) => (
      <InventoryUserSearchField
        isMulti={isMulti}
        refetchList={refetch}
        label={label}
        onChange={onChange}
        value={value}
      />
    )}
  </Query>
);

InventoryUserSearchFieldContainer.propTypes = {
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

InventoryUserSearchFieldContainer.defaultProps = {
  isMulti: false,
  label: '',
  value: '',
};

export default InventoryUserSearchFieldContainer;
