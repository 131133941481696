import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const DeleteUserDialog = ({ classes, open, handleClose, deleteUser, closeDeleteCard }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <div className={classes.card}>
      <DialogTitle className={classes.nameSpace}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block', fontSize: '22px' }}>
            <b>Delete User?</b>
          </div>
          <br />
        </div>
      </DialogTitle>
      <DialogContent>
        <b>
          You are about to delete this user. Once you delete this user,{' '}
          <span className="text-danger">you cannot undo this action.</span> Are you sure you want to delete this user?
        </b>
      </DialogContent>
      <Button
        onClick={() => closeDeleteCard()}
        color="primary"
        style={{ float: 'left', marginLeft: 15, marginTop: 25 }}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          deleteUser();
        }}
        variant="contained"
        color="primary"
        style={{ float: 'right', marginRight: 15, marginTop: 25 }}>
        Yes, DELETE this user
      </Button>
    </div>
  </Dialog>
);

DeleteUserDialog.propTypes = {
  handleClose     : PropTypes.func.isRequired,
  deleteUser      : PropTypes.func.isRequired,
  closeDeleteCard : PropTypes.func.isRequired,
  classes         : PropTypes.object.isRequired,
  open            : PropTypes.bool.isRequired,
};

const styles = {
  card : {
    width  : 520,
    height : 200,
    margin : 20,
  },
};

export default withStyles(styles)(DeleteUserDialog);
