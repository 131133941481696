import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}>
    {children}
  </Typography>
);

Placeholder.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

Placeholder.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: {},
};

export default Placeholder;
