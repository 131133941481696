import PropTypes from 'prop-types';
import React from 'react';
import { Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '../ToggleButton/ToggleButton';
import SimpleInputField from '../SimpleTextField/SimpleTextField';

const styles = theme => ({
  title     : {
    fontSize : 18,
    color    : '#fff',
    margin   : 12,
  },
  features  : {
    display       : 'flex',
    flexDirection : 'column',
  },
  typo      : {
    margin         : '10px',
    textDecoration : 'underline',
    fontSize       : 15,
  },
  subtypo   : {
    margin : '10px',
  },
  textField : {
    width : '95%',
  },
});

const HomeOfficeCtaMenu = ({ company, updateCompany, classes, updateCompanyBool }) => (
  <div>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography type="h1" className={classes.title}>
          Home Office CTA Menu
        </Typography>
      </Toolbar>
    </AppBar>
    <Paper className={classes.features}>
      <Typography variant="title" className={classes.typo}>
        Home Office CTA
      </Typography>
      <SimpleInputField
        id="home_office_cta_title"
        label="Home office cta title"
        data={company.data}
        action={updateCompany}
      />
      <SimpleInputField
        id="home_office_cta_paragraph"
        label="Home office cta paragraph"
        data={company.data}
        action={updateCompany}
      />
      <SimpleInputField
        id="home_office_cta_button_copy"
        label="Home office cta Button copy"
        data={company.data}
        action={updateCompany}
      />
      <SimpleInputField id="home_office_email" label="Home office email" data={company.data} action={updateCompany} />
    </Paper>
  </div>
);

HomeOfficeCtaMenu.propTypes = {
  company           : PropTypes.object.isRequired,
  updateCompany     : PropTypes.func.isRequired,
  classes           : PropTypes.object.isRequired,
  updateCompanyBool : PropTypes.func.isRequired,
};

export default withStyles(styles)(HomeOfficeCtaMenu);
