import { CFA_RESULT_UPDATE, CFA_QUERY_UPDATE } from '../constants/cfaConstants';

const cfaReducer = (
  state = {
    query       : {},
    result      : {},
  },
  action
) => {
  switch (action.type) {
    case CFA_RESULT_UPDATE:
      return { ...state, result: action.cfaResults };
    case CFA_QUERY_UPDATE:
      return { ...state, query: {...state.query, ...action.cfaQuery} };
    default:
      return state;
  }
};

export default cfaReducer;
