import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';

export const ViewCard = ({ activity, classes }) => (
  <CardContent classes={{ root: classes.content }}>
    <div style={{ whiteSpace: 'pre-wrap' }}>
      <div className={classes.date}>{moment(activity.activityDate).format('MMMM DD, YYYY [@] h:mma')}</div>
      <p>{decodeURI(activity.note)}</p>
    </div>
  </CardContent>
);

const styles = {
  root    : {
    margin : '8px 0 0 0',
  },
  date    : {
    color        : '#777',
    marginBottom : 8,
  },
  content : {
    marginTop   : 2,
    paddingTop  : 0,
    paddingLeft : 68,
  },
};

ViewCard.propTypes = {
  activity : PropTypes.object.isRequired,
  classes  : PropTypes.object.isRequired,
};

export default withStyles(styles)(ViewCard);
