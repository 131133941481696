import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PipelineCardQuery } from '../components/Pipelines/PipelineQueries';
import PipelineCardTab from '../components/InventoryUserDrawer/PipelineCardTab';

export const PipelineCardTabContainer = ({ inventoryUserId, documents }) => {
  if (!inventoryUserId) return <div />;
  return (
    <Query query={PipelineCardQuery} variables={{ id: inventoryUserId }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <CircularProgress />;
        if (error) return <div>{error.message}</div>;
        if (data.getInventoryUser) {
          return (
            <PipelineCardTab
              cards={data.getInventoryUser.pipeline_cards}
              inventoryUserId={inventoryUserId}
              documents={documents}
            />
          );
        }
        return <div />;
      }}
    </Query>
  );
};

PipelineCardTabContainer.propTypes = {
  pipelineCardId : PropTypes.number.isRequired,
};

export default PipelineCardTabContainer;
