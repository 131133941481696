import React, { ReactFragment } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';

const SellerSellerNote = ({sellerCashFlow, company, intl, result}) => {
  
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  return (
    <React.Fragment>
      <tr>
        <td>Seller Note Carried Note Interest ({numeral(get(sellerCashFlow,'[1].taxRate',0)).format(percentFormat)})</td>
        {sellerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red'}}>{numeral(v.sellerNoteInterestTaxes).divide(-1).format(dollarFormat)}</td>))}
      </tr>
      <tr>
        <td>Seller Note Carried Note Principal ({numeral(get(result,'cashFlow.cashflowSellerLifetimeCapitalGainsTax',0)).format(percentFormat)}) </td>
        {sellerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red',}}>{numeral(v.sellerNotePrincipalTaxes).divide(-1).format(dollarFormat)}</td>))}
      </tr>
    </React.Fragment>
);}

const mapStateToProps = (state) => ({
  result: {...get(state, 'cfa.result', {})},
  sellerCashFlow: get(state, 'cfa.result.cashFlow.illustration.sellerCashFlow', []),
  company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
});

export default connect(mapStateToProps)(injectIntl(SellerSellerNote));