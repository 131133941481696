import React from 'react';
import PropTypes from 'prop-types';
import { Query, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { get, flowRight } from 'lodash';
import { createMuiTheme } from '@material-ui/core/styles';
import qs from 'query-string';
import moment from 'moment';
import * as actions from '../actions/pipelineActionCreators';
import {
  PipelinesQuery,
  updatePipelineCardMutation,
  updatePipelineOrderMutation,
} from '../components/Pipelines/PipelineQueries';
import PipelineListComponent from '../components/Pipelines/PipelineListComponent';
import { PipelineCardQuery } from '../components/Pipelines/Board';

const myTheme = () =>
  createMuiTheme({
    overrides : {
      MUIDataTableBodyCell : {
        root : {
          fontSize : 10,
        },
      },
      MUIDataTable         : {
        responsiveScroll : {
          height    : 'calc(100vh - 200px)',
          maxHeight : 'calc(100vh - 200px) !important',
        },
      },
    },
  });

class PipelineListContainer extends React.Component {
  state = {
    page            : 0,
    selectedCard    : {},
    pipelines       : [],
    search          : null,
    column          : null,
    columnDirection : null,
    dealId          : null,
    filterParams    : {
      value      : [ null, null ],
      close_date : [ moment('01/01/2000'), moment().add(20, 'year') ],
    },
    status          : 'open',
  };

  componentDidMount = () => {
    const params = qs.parse(location.search);
    const { deal_id, start_date, end_date, pipeline_stage } = params;
    if (deal_id) {
      this.setState({ dealId: parseInt(deal_id, 10) });
    }
    if (start_date && end_date && pipeline_stage) {
      this.setState({
        filterParams : {
          close_date     : [ moment(start_date), moment(end_date) ],
          pipeline_names : [ decodeURI(pipeline_stage) ],
        },
      });
    }
  };

  selectCard = async (card, client) => {
    let result = {};
    if (card.id) {
      result = await client.query({
        query       : PipelineCardQuery,
        variables   : { id: card.id },
        fetchPolicy : 'network-only',
      });
    }
    this.setState({ selectedCard: get(result, 'data.pipelineCard', {}) });
  };

  deSelectCard = () => {
    this.setState({ selectedCard: {} });
  };

  toggleTable = id => {
    window.open(`/admin/pipelines/${id}`, '_self');
  };

  render () {
    const { match, pipeline, updatePipeline, updatePipelineCard } = this.props;
    const { page, search, column, columnDirection, dealId, filterParams, pipelines, status } = this.state;

    return (
      <Query
        query={PipelinesQuery}
        variables={{
          id              : get(match, 'params.id'),
          cardsPage       : page + 1,
          searchText      : search,
          column,
          columnDirection,
          dealId,
          filterParams,
          status,
        }}>
        {({ loading, error, data, refetch }) => {
          if (error) return `Error!: ${error}`;
          const matchID = get(this.props, 'match.params.id');
          if (!matchID || matchID === 'undefined') {
            return <Redirect to={`/admin/pipelines/${get(data, 'pipelines[0].id')}`} />;
          }
          if (!pipeline.id || match.params.id !== pipeline.id) {
            const pLine = get(data, 'pipeline');
            if (pLine) {
              const pipelineStages = get(pLine, 'pipeline_stages', []).map(p => p.name);
              this.setState(
                {
                  pipelines : get(data, 'pipelines'),
                  showTable : pLine.card_count > 200,
                  page,
                },
                () => updatePipeline({ ...pLine, page, stageFilter: pipelineStages })
              );
            }
          }
          let inv_user = get(data, 'getCurrentUser.inventory_user', {});
          inv_user = { ...inv_user, label: inv_user.name, value: inv_user.id };
          return (
            <PipelineListComponent
              pipeline={pipeline}
              refetch={refetch}
              updatePipeline={updatePipeline}
              updatePipelineCard={updatePipelineCard}
              loading={loading}
              match={match}
              pipelines={pipelines}
              paramCloseDate={filterParams.close_date}
              paramPipelineName={filterParams.pipeline_names}
            />
          );
        }}
      </Query>
    );
  }
}

PipelineListContainer.propTypes = {
  classes            : PropTypes.object.isRequired,
  match              : PropTypes.object.isRequired,
  updatePipeline     : PropTypes.func.isRequired,
  updatePipelineCard : PropTypes.func.isRequired,
  pipeline           : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  pipeline       : state.pipeline,
  dragging       : get(state, 'pipeline.pipeline_state.dragging', false),
  pipeline_cards : get(state, 'pipeline.pipeline_cards'),
});

export default flowRight(
  graphql(updatePipelineCardMutation),
  graphql(updatePipelineOrderMutation, { name: 'mutateOrder' })
)(connect(mapStateToProps, actions)(PipelineListContainer));
