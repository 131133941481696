import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get, upperCase } from 'lodash';
import gql from 'graphql-tag';
import { graphql, Mutation } from 'react-apollo';
import DeclineFaModal from '../DeclineFaModal';

const InviteMutation = gql`
  mutation inviteUser($deal_interest: String!) {
    inviteUser(deal_interest: $deal_interest)
  }
`;

const clickButton = (dealInterest, route) => {
  window.open(`/deal/${get(dealInterest, 'deal.deal_name')}/${route}/${get(dealInterest, 'id')}`, '_self');
};

const ndaSignDiscloser = dealInterest => {
  window.open(
    `/deal/nda_sign_discloser?deal_id=${get(dealInterest, 'deal.id')}&match_id=${get(dealInterest, 'id')}`,
    '_self'
  );
};

const downloadNda = dealInterest => {
  window.open(`/deal/${get(dealInterest, 'deal.deal_name')}/open_file/${get(dealInterest, 'id')}`, '_blank');
};

const StatusButtons = ({
  classes,
  dealInterest,
  scrollTo,
  deadLinePassed,
  stylesheetName,
  dealInterestName,
  allowShortlistMessaging,
  dealRoomSingleName,
}) => {
  const [ openModal, setModalState ] = useState(false);
  const [ inviteStatus, setInviteStatus ] = useState(get(dealInterest, 'invited'));
  if (get(dealInterest, 'deal.closed', false)) {
    return <span></span>;
  }

  switch (dealInterest.workflow_state) {
    case 'matching':
    case 'invited':
      if (!inviteStatus) {
        return (
          <div>
            {get(dealInterest, 'reason_for_suggestion', '') && (
              <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
                <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
              </Button>
            )}
            {get(dealInterest, 'proposal_letter', '') && (
              <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
                <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
              </Button>
            )}
            {deadLinePassed && (
              <Button variant="contained" size="large" disabled style={{ width: 200 }}>
                <FormattedMessage id="profile_page.match_profile.deadline_passed"/>
              </Button>
            )}
            {!deadLinePassed && (
              <Mutation mutation={InviteMutation}>
                {inviteUser => (
                  <div>
                    <Button
                      className={`wiggle-btn`}
                      variant="contained"
                      size="large"
                      style={{ color: '#fff', width: 200, marginLeft: 25, marginBottom: 15 }}
                      // href={`/deal/${get(dealInterest, 'deal.deal_name')}/invite/${get(dealInterest, 'id')}`}>
                      Invite
                      onClick={() => {
                        inviteUser({ variables: { deal_interest: get(dealInterest, 'id') } }).then(r => {
                          if (r.data.inviteUser) {
                            setInviteStatus(true);
                            const button = document.getElementById(`invite${dealInterestName}`);
                            button.className = 'btn btn-completed disabled invite_button';
                            button.innerHTML = `<span class="fas fa-check"></span>&nbsp;&nbsp;&nbsp;Invited&nbsp;&nbsp;&nbsp;`;
                          }
                        });
                      }}>
                      {<FormattedMessage id="profile_page.invite_to"/>}
                    </Button>
                    <p className={classes.margin} style={{ width: 200 }}>
                    <FormattedMessage id="profile_page.match_profile.interested_in_this_financial"/>
                    </p>
                  </div>
                )}
              </Mutation>
            )}
          </div>
        );
      }
      return (
        <div>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          <Button className={`${classes.margin} ${classes.whiteButton}`} variant="contained" size="large" disabled>
            <FormattedMessage id="profile_page.match_profile.invite_sent"/>
          </Button>
          <p className={classes.margin} style={{ width: 200 }}>
            <FormattedMessage id="profile_page.match_profile.youve_already_invited_this"/>
          </p>
        </div>
      );
    case 'reviewing_proposal':
      return (
        <div>
          <Button
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            onClick={() => this.clickButton(dealInterest, 'send_NDA_to_receiving_party')}>
              <FormattedMessage id="profile_page.match_profile.shortlist"/>
          </Button>
          <br />
          <Button
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            onClick={() => clickButton(dealInterest, 'archive_deal_interest')}>
             <FormattedMessage id="profile_page.match_profile.archive"/>
          </Button>
        </div>
      );
    case 'shortlisted':
      return (
        <div className={`${classes.margin}`}>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          {allowShortlistMessaging && (
            <div>
              <Button onClick={() => scrollTo('chat')} className={`${classes.ref}}`}>
                <span className="fa fa-envelope" style={{ marginRight: 12 }} /> <FormattedMessage id="profile_page.match_profile.message"/>
              </Button>
            </div>
          )}
          <br />
          <Button
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            onClick={() => clickButton(dealInterest, 'send_NDA_to_receiving_party')}>
             <FormattedMessage id="profile_page.match_profile.send_nda"/>
          </Button>
          <br />
          <Button
            variant="contained"
            size="large"
            style={{ width: 200, margin: '10px 0' }}
            onClick={() => clickButton(dealInterest, 'shortlist_deal_interest')}>
              <FormattedMessage id="profile_page.match_profile.unshortlist"/>
          </Button>
          <br />
          {stylesheetName === 'rjpe' && (
            <Button
              variant="contained"
              size="large"
              style={{ color: '#fff', width: 200 }}
              onClick={() => setModalState(true)}>
               <FormattedMessage id="profile_page.match_profile.decline"/>
            </Button>
          )}
          {stylesheetName !== 'rjpe' && (
            <Button
              variant="contained"
              size="large"
              style={{ color: '#fff', width: 200 }}
              onClick={() => clickButton(dealInterest, 'archive_deal_interest')}>
                <FormattedMessage id="profile_page.match_profile.archive"/>
            </Button>
          )}
          <DeclineFaModal dealInterest={dealInterest} openModal={openModal} setModalState={setModalState} />
        </div>
      );
    case 'archived':
      return (
        <div className={`${classes.margin}`}>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          {stylesheetName !== 'rjpe' && (
            <Button
              className={`${classes.ref}}`}
              variant="contained"
              size="large"
              style={{ width: 200 }}
              onClick={() => clickButton(dealInterest, 'archive_deal_interest')}>
                <FormattedMessage id="profile_page.match_profile.unarchive"/>
            </Button>
          )}
        </div>
      );
    case 'out_for_receiving_signature':
      return (
        <div className={`${classes.margin}`}>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          <Button disabled variant="contained" size="large" style={{ width: 200 }}>
            <FormattedMessage id="profile_page.match_profile.nda_sent"/>
          </Button>
          <p className={classes.margin} style={{ width: 200 }}>
            <FormattedMessage id="profile_page.match_profile.youve_sent_this_user_an_nda"/>
          </p>
        </div>
      );
    case 'awaiting_disclosing_signature':
      return (
        <div className={`${classes.margin}`}>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button className={`${classes.ref}}`} onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          <Button
            className={`wiggle-btn`}
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            onClick={() => ndaSignDiscloser(dealInterest)}>
              <FormattedMessage id="profile_page.match_profile.sign_nda"/>
          </Button>
        </div>
      );
    case 'negotiating':
      return (
        <div className={`${classes.margin}`}>
          <Button
            className={`wiggle-btn`}
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            onClick={() => downloadNda(dealInterest)}>
              <FormattedMessage id="profile_page.match_profile.download_nda"/>
          </Button>
        </div>
      );
    default:
      return <div><FormattedMessage id="profile_page.match_profile.invalid_status"/></div>;
  }
};

const styles = theme => ({
  margin      : {
    margin : '0px 0px 15px 25px',
  },
  button      : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
  },
  ref         : {
    textTransform : 'capitalize',
    margin        : '0px 0px 5px 20px',
    color         : '#1396e2',
    display       : 'block',
  },
  whiteButton : {
    background : '#fff',
    color      : theme.palette.primary.main,
    width      : 200,
  },
  FontColor   : {
    color : '#1396e2',
  },
});

export default withStyles(styles)(StatusButtons);
