import { createStore, combineReducers, applyMiddleware } from 'redux';
import userReducer from '../reducers/userReducer';
import thunk from 'redux-thunk';
import { intlReducer } from 'react-intl-redux';
import opportunityReducer from '../reducers/opportunityReducer';
import bobCardReducer from '../reducers/bobCardReducer';
import dealReducer from '../reducers/dealReducer';
import profileDrawerReducer from '../reducers/profileDrawerReducer';
import noteReducer from '../reducers/noteReducer';
import companyReducer from '../reducers/companyReducer';
import conversationReducer from '../reducers/conversationReducer';
import cfaReducer from '../reducers/cfaReducer';
import successionReadinessReducer from '../reducers/successionReadinessReducer';

const configureStore = railsProps =>
    createStore(
        combineReducers({
            company: companyReducer,
            user: userReducer,
            intl: intlReducer,
            opportunities: opportunityReducer,
            bobCards: bobCardReducer,
            deal: dealReducer,
            match_preference: state => ({...state }),
            profileDrawer: profileDrawerReducer,
            notes: noteReducer,
            conversation: conversationReducer,
            cfa: cfaReducer,
            successionReadiness: successionReadinessReducer,
        }),
        railsProps,
        applyMiddleware(thunk)
    );

export default configureStore;