import React from 'react';
import { times } from 'lodash';
import ErrorHandler from '../ErrorHandler/ErrorHandler';

const renderStars = ({ number }) => {
  const stars = times(number, j => (
    <i key={`${j}_stars`} className="fas fa-dollar-sign" style={{ color: 'goldenrod' }} />
  ));
  const grey = times(5 - number, j => <i key={`${j}_grey`} className="marker-hover fas fa-dollar-sign" />);
  return (
    <ErrorHandler>
      <div>
        {stars}
        {grey}
      </div>
    </ErrorHandler>
  );
};

export default renderStars;
