import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import './PlanningInformation.css';

const monthOptions = [{ name: '0 months', value: 0 }, { name: '6 months', value: 6 }, { name: '12 months', value: 12 }, { name: '18 months', value: 18 }, { name: '24 months', value: 24 }]

const UserTransitionPeriod = ({ user, updateAction }) => {
  return (<>
    <div><FormattedMessage id="profile_page.product_mix.number_months_you_agree_stay_transition" /></div>
    <Select
      fullWidth
      className="PlanningInformation__radio"
      value={`${get(user, 'transition_period_months')}`}
      onChange={e => {
        updateAction({ transition_period_months: parseInt(e.target.value, 10) })
      }}
    >
      {monthOptions.map(sps =>
        <MenuItem
          value={sps.value}
          className="PlanningInformation__radio"
          label={sps.name}>
          {sps.name}
        </MenuItem>)}
    </Select>
  </>);
};

UserTransitionPeriod.propTypes = {
  user: PropTypes.object,
  updateAction: PropTypes.func.isRequired,
};

UserTransitionPeriod.defaultProps = {
  user: {},
};

export default UserTransitionPeriod;