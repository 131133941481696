import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Notifications from '@material-ui/icons/Notifications';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { get } from 'lodash';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NotificationsList from './NotificationsList';

const ReadNotifications = gql`
  mutation readNotifications($ids: [ID!]!) {
    readNotifications(ids: $ids)
  }
`;

const useStyles = makeStyles(theme => ({
  typography         : {
    padding : theme.spacing(2),
  },
  notificationBadge  : {
    height     : 24,
    padding    : 0,
    marginLeft : 8,
  },
  notificationButton : {
    height        : 20,
    color         : '#2a2a2a',
    textTransform : 'none',
    paddingBottom : 8,
    paddingLeft   : 0,
  },
  badgeSpacer        : {
    paddingLeft : 14,
  },
  buttonText         : {
    opacity : 0.7,
  },
}));

const NotificationDropDown = props => {
  const classes = useStyles();
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const handleClick = (event, readNotifications) => {
    setAnchorEl(event.currentTarget);

    const unread = props.notifications.filter(n => n.readAt === null);
    const unreadCount = unread.length;
    readNotifications({ variables: { ids: unread.map(u => u.id) } }).then(() => {
      props.updateCount(unreadCount);
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Mutation mutation={ReadNotifications}>
      {readNotifications => (
        <div>
          {!matches ? (
            <>
              <Button
                aria-describedby={id}
                color="default"
                onClick={event => {
                  handleClick(event, readNotifications);
                }}
                className={classes.notificationButton}>
                <span className={classes.buttonText}>Notifications</span>
                <span className={classes.badgeSpacer} />
                <Badge badgeContent={props.openCount} color="primary" classes={{ badge: classes.badge }} />
              </Button>
            </>
          ) : (
            <IconButton
              aria-describedby={id}
              variant="contained"
              color={props.color}
              onClick={event => {
                handleClick(event, readNotifications);
              }}
              className={classes.notificationBadge}>
              <Badge badgeContent={props.openCount} color="error" classes={{ badge: classes.badge }}>
                <Notifications />
              </Badge>
            </IconButton>
          )}

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical   : 'bottom',
              horizontal : 'center',
            }}
            transformOrigin={{
              vertical   : 'top',
              horizontal : 'center',
            }}>
            <NotificationsList notifications={props.notifications} />
            <center>
              <Button
              disabled={!props.hasNextPage}
                onClick={() => {
                  props.fetchMore({
                    variables   : {
                      after : props.after,
                    },
                    updateQuery : (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      // props.resetLoaded();
                      const result = { ...fetchMoreResult, loadMore: true };
                      props.resetLoaded({
                        notifications : [
                          ...props.notifications,
                          ...get(fetchMoreResult, 'getCurrentUser.notifications.edges', []).map(n => n.node),
                        ],
                        after         : get(fetchMoreResult, 'getCurrentUser.notifications.pageInfo.endCursor', ''),
                      });
                      return result;
                    },
                  });
                }}>
                <FormattedMessage id="notifications.load_more" />
              </Button>
            </center>
          </Popover>
        </div>
      )}
    </Mutation>
  );
};

NotificationDropDown.propTypes = {
  color         : PropTypes.string,
  notifications : PropTypes.array,
  openCount     : PropTypes.number,
  updateCount   : PropTypes.func.isRequired,
  fetchMore     : PropTypes.func.isRequired,
  resetLoaded   : PropTypes.func.isRequired,
  after         : PropTypes.string,
  hasNextPage: PropTypes.bool,
};

NotificationDropDown.defaultProps = {
  color         : 'primary',
  notifications : [],
  openCount     : 0,
  after         : null,
  hasNextPage: false,
};

export default NotificationDropDown;
