import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, get, filter } from 'lodash';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { updateDateFilter } from '../../actions/dateRangeFilterActionCreators';
import { loadInventoryUserList } from '../../actions/inventoryUsersActionCreators';
import { updateMotivationFilters } from '../../actions/motivationReportActionCreators';

import MotivationReport from '../../components/Reports/MotivationReport';
import { isBoolean } from 'util';

export const MotivationQuery = gql`
  query MotivationQuery($nameSearch: String, $filterList: InventoryUserFilterInput) {
    getMotivationUsers(nameSearch: $nameSearch, onlyUsers: true, filterList: $filterList) {
      id
      name
      email
      branch
      division
      region
      dcf_valuation
      approval_state
      created_at
      deal_interests_workflow_state_suggested_match_count
      deal_interests_workflow_state_following_count
      deal_interests_count
      manager {
        id
        name
      }
      age
      industry_length_of_service
      years_with_company
      rev_yield
      total_assets
      user {
        id
        gross_production
        planning_preference
        disapproved_reason_comment
        created_at
      }
      deals {
        id
        creation_date
        pipeline_card {
          id
          status
        }
        evaluate {
          inventory_user {
            id
            name
            region
          }
        }
        invited {
          inventory_user {
            id
            name
            region
          }
        }
        review_proposals {
          inventory_user {
            id
            name
            region
          }
        }
        nda_out_for_review {
          inventory_user {
            id
            name
            region
          }
        }
        negotiating {
          inventory_user {
            id
            name
            region
          }
        }
      }
      deal_interests {
        id
        workflow_state       
        deal {
          id
          inventory_user {
            id
            name
            region
          }       
        }
      }
      transition_goals
      birth_date
      industry_length_of_service
      mutual_funds_aum
      securities_aum
      city
      province
      continuity_builder {
        id
        continuityBuilderSuccessors {
          inventoryUser {
            id
            name
          }
        }
        existingPartnerName
        pipelineCard {
          pipeline_stage {
            name
          }
        }
      }
    }
    getMotivationUsersCount(nameSearch: "", onlyUsers: true, filterList: { not_admins_not_customers_not_hidden: true, is_invisible: ["false"] })
    getTransitionGoals
    getDealCount
    getCurrentUser {
      company {
        country
        catastrophic_planning_word
      }
    }
  }
`;

export const setData = invUsers => {
  const inv = invUsers.map(inv => {
    let cat_partners = get(inv, 'continuity_builder.continuityBuilderSuccessors', []).map(c =>
      get(c, 'inventoryUser.name')
    );
    cat_partners = cat_partners.concat([ get(inv, 'continuity_builder.existingPartnerName') ]).join(', ');
    return {
      ...inv,
      creation_date         : get(inv, 'deals[0].creation_date'),
      status                : get(inv, 'deals[0].pipeline_card.status'),
      manager_name          : get(inv, 'manager.name', '-'),
      user_gross_production : get(inv, 'user.gross_production', '-'),
      matching              : get(inv, 'deals[0].evaluate', []).map(i => `${i.inventory_user.name}${i.inventory_user.region ? ` (${i.inventory_user.region})` : ''}`).join(', '),
      invited               : get(inv, 'deals[0].invited', []).map(i => `${i.inventory_user.name}${i.inventory_user.region ? ` (${i.inventory_user.region})` : ''}`).join(', '),
      reviewing_proposal    : get(inv, 'deals[0].review_proposals', []).map(i => `${i.inventory_user.name}${i.inventory_user.region ? ` (${i.inventory_user.region})` : ''}`),
      nda_out_for_review    : get(inv, 'deals[0].nda_out_for_review', []).map(i => `${i.inventory_user.name}${i.inventory_user.region ? ` (${i.inventory_user.region})` : ''}`).join(', '),
      negotiating           : get(inv, 'deals[0].negotiating', []).map(i => `${i.inventory_user.name}${i.inventory_user.region ? ` (${i.inventory_user.region})` : ''}`).join(', '),
      di_matching           : get(inv, 'deal_interests', [])
        .filter(di => di.workflow_state === 'matching')
        .map(di => `${get(di, 'deal.inventory_user.name', '')}${get(di, 'deal.inventory_user.region', '') ? ` (${get(di, 'deal.inventory_user.region', '')})` : ''}`)
        .filter(n => n)
        .join(', '),
      reviewing             : get(inv, 'deal_interests', [])
        .filter(di => di.workflow_state === 'invited')
        .map(di => `${get(di, 'deal.inventory_user.name', '')}${get(di, 'deal.inventory_user.region', '') ? ` (${get(di, 'deal.inventory_user.region', '')})` : ''}`)
        .join(', '),
      proposal              : get(inv, 'deal_interests', [])
        .filter(di => di.workflow_state === 'reviewing_proposal')
        .map(di => `${get(di, 'deal.inventory_user.name', '')}${get(di, 'deal.inventory_user.region', '') ? ` (${get(di, 'deal.inventory_user.region', '')})` : ''}`)
        .join(', '),
      nda                   : get(inv, 'deal_interests', [])
        .filter(di => di.workflow_state === 'awaiting_disclosing_signature')
        .map(di => `${get(di, 'deal.inventory_user.name', '')}${get(di, 'deal.inventory_user.region', '') ? ` (${get(di, 'deal.inventory_user.region', '')})` : ''}`)
        .join(', '),
      di_negotiating        : get(inv, 'deal_interests', [])
        .filter(di => di.workflow_state === 'negotiating')
        .map(di => `${get(di, 'deal.inventory_user.name', '')}${get(di, 'deal.inventory_user.region', '') ? ` (${get(di, 'deal.inventory_user.region', '')})` : ''}`)
        .join(', '),
      transition_goals      : isEmpty(get(inv, 'transition_goals', []))
        ? [ 'No Motivation' ]
        : get(inv, 'transition_goals', []).join(', '),
      cat_plan_preference   : get(inv, 'user.planning_preference', '-'),
      cat_plan_stage        : get(inv, 'continuity_builder.pipelineCard.pipeline_stage.name', '-'),
      cat_partners,
    };
  });

  return inv.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
};

export const MotivationReportContainer = props => {
  let queryVars = {
    filterList : { ...props.motivationFilters },
  };
  delete queryVars.filterList.manager_objects;
  let obj = queryVars.filterList;
  Object.keys(obj).forEach(k => isEmpty(obj[k]) && !isBoolean(obj[k]) && delete obj[k]);
  return (
    <div>
      <Query
        query={MotivationQuery}
        variables={queryVars}
        onCompleted={data => {
          const inventory_users = setData(data.getMotivationUsers);

          props.loadInventoryUserList({
            inventory_users,
            inventory_user_count       : data.getMotivationUsersCount,
            transition_goals           : data.getTransitionGoals,
            currentCountry             : get(data, 'getCurrentUser.company.country'),
            catastrophic_planning_word : get(data, 'getCurrentUser.company.catastrophic_planning_word'),
            deals_count                : data.getDealCount,
            print_view                 : false,
          });
        }}>
        {({ loading, error, data, refetch }) => {
          if (error) return `Error!: ${error}`;
          return <MotivationReport refetch={refetch} loading={loading} {...props} />;
        }}
      </Query>
    </div>
  );
};

const mapStateToProps = state => ({
  startDate            : state.dateRangeFilter.sendStart.format('YYYY-MM-DD'),
  endDate              : state.dateRangeFilter.sendEnd.format('YYYY-MM-DD'),
  motivationFilters    : state.motivationFilters,
  inventory_users      : state.inventory_users,
  inventory_user_count : state.inventory_users.inventory_user_count,
  transition_goals     : state.inventory_users.transition_goals,
});

const mapDispatchToProps = {
  updateDateFilter,
  updateMotivationFilters,
  loadInventoryUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(MotivationReportContainer);
