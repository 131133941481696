import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Fab from '@material-ui/core/Fab';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { get } from 'lodash';
import AddCaseDialog from './AddCaseDialog';
import client from '../../lib/apolloClient';

const deleteSuggestedCaseMutation = gql`
  mutation deleteSuggestedCase($id: ID!) {
    deleteSuggestedCase(id: $id)
  }
`;

const clickViewAll = () => {
  window.open(`/admin/suggestions`, '_self');
};

class SuggestedUsersPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = { suggested_user: {}, suggested_cases: props.suggested_users, selectedPipeline: null };
  }

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).format('MMM D')} at ${moment(date).format('LT')}`;
  };

  closeAddCaseDialog = () => {
    this.setState({ suggested_user: {}, selectedPipeline: null });
  };

  setlectPipeline = pipeline_id => {
    const { suggested_user } = this.state;
    this.setState({
      suggested_user   : { ...suggested_user, selected_pipeline: pipeline_id },
      selectedPipeline : pipeline_id,
    });
  };

  render () {
    const { classes, pipelineList, currentUser } = this.props;
    const { suggested_user, suggested_cases, selectedPipeline } = this.state;
    return (
      <ApolloProvider client={client}>
        <Mutation mutation={deleteSuggestedCaseMutation}>
          {deleteSuggested => (
            <div className={classes.box}>
              <AddCaseDialog
                updateState={s => this.setState(s)}
                suggestedUser={suggested_user}
                pipelineList={pipelineList}
                owner={currentUser}
                deleteSuggested={deleteSuggested}
                closeAddCaseDialog={() => this.closeAddCaseDialog()}
                selectPipelineFunc={e => this.setlectPipeline(e)}
                selectedPipeline={selectedPipeline}
              />

              {suggested_cases.map(user => (
                <div className={classes.row}>
                  <Grid container spacing={0}>
                    <Grid item xs={2}>
                      <Avatar className={classes.avatar}>
                        <i className="material-icons">attach_money</i>
                      </Avatar>
                    </Grid>
                    <Grid item xs={6}>
                      <div className={classes.text}>
                        <span className={classes.nameField}>{user.name}</span>
                        <br />
                        {this.formatDate(get(user, 'updated_at'))} {user.manager && <span>| {user.manager}</span>}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton color="primary">
                        <CloseIcon
                          onClick={() => {
                            deleteSuggested({ variables: { id: user.id } });
                            window.location.reload();
                          }}
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <Fab
                        style={{ marginTop: 4 }}
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="Add"
                        onClick={() => this.setState({ suggested_user: user })}>
                        Add
                      </Fab>
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                </div>
              ))}
              <div className={classes.viewAll}>
                <button type="button" className={classes.contentButton} onClick={() => clickViewAll()}>
                  <span className={classes.content}>See All Suggestions</span>
                </button>
              </div>
            </div>
          )}
        </Mutation>
      </ApolloProvider>
    );
  }
}

SuggestedUsersPanel.propTypes = {
  suggested_users : PropTypes.array,
  classes         : PropTypes.object.isRequired,
  pipelineList    : PropTypes.array,
  currentUser     : PropTypes.object.isRequired,
};

SuggestedUsersPanel.defaultProps = {
  suggested_users : [],
  pipelineList    : [],
};

const styles = {
  box           : {
    width     : '100%',
    marginTop : 15,
  },
  name          : {
    width : '85%',
  },
  nameField     : {
    fontWeight : 500,
  },
  avatar        : {
    margin : '0px 10px 0px 15px',
  },
  text          : {
    textAlign : 'left',
  },
  row           : {
    marginTop    : 5,
    marginBottom : 5,
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
};

export default withStyles(styles)(SuggestedUsersPanel);
