import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ChatEmptyIcon from '@material-ui/icons/ChatBubble';
import ChatIcon from '@material-ui/icons/Chat';

const MessageIconButton = ({ classes, hasMessages, onClick }) => (
  <Fragment>
    <IconButton onClick={onClick} className={classes.messageButton}>
      {hasMessages ? (
        <ChatIcon className={`${classes.chat} ${classes.chatEnabled}`} />
      ) : (
        <ChatEmptyIcon className={classes.chat} />
      )}
    </IconButton>
  </Fragment>
);

MessageIconButton.propTypes = {
  classes     : PropTypes.array,
  hasMessages : PropTypes.bool.isRequired,
};

const styles = {
  chat          : { fontSize: 18, color: '#1396e2' },
  chatEnabled   : { color: '#1396e2' },
  messageButton : { padding: 4 },
};

export default withStyles(styles)(MessageIconButton);
