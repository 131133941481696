import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get, isEmpty, capitalize } from 'lodash';

const NicheMarkets = ({ classes, dealInterest }) => {
  if (!isEmpty(get(dealInterest, 'inventory_user.user.primary_markets'))) {
    const markets = get(dealInterest, 'inventory_user.user.primary_markets', []).map(p =>
      capitalize(p.replaceAll('_', ' '))
    );
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.primary_markets"/></strong>
        <p>{markets.join(', ')}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(NicheMarkets);
