/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { debounce } from 'lodash';
import AsyncSelect from 'react-select/async';
import Control from './Control';
import Menu from './Menu';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Placeholder from './Placeholder';
import SingleValue from './SingleValue';
import ValueContainer from './ValueContainer';
import Option from './Option';

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class IntegrationReactSelect extends React.Component {
  reloadOptions = debounce((inputValue, callback) => {
    const { refetchList } = this.props;
    const name = inputValue || '';
    refetchList({ nameSearch: name, offset: 0, limit: 10 }).then(response => {
      if (response.data)
        return callback(
          response.data.getInventoryUsers.map(user => ({
            ...user,
            label : user.name,
            value : user.id,
          }))
        );
      return null;
    });
  }, 300);

  handleChange = item => {
    const { onChange } = this.props;
    onChange(item);
  };

  render () {
    const { classes, theme, isMulti, label, value, onChange } = this.props;

    const selectStyles = {
      input : base => ({
        ...base,
        color     : theme.palette.text.primary,
        '& input' : {
          font : 'inherit',
        },
      }),
    };

    return (
      <div className={classes.root}>
        <AsyncSelect
          classes={classes}
          styles={selectStyles}
          value={value}
          textFieldProps={{
            label,
            InputLabelProps : {
              shrink : true,
            },
          }}
          loadOptions={this.reloadOptions}
          defaultOptions
          components={components}
          placeholder="Search by name"
          isMulti={isMulti}
          onChange={onChange}
        />
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes  : PropTypes.object.isRequired,
  theme    : PropTypes.object.isRequired,
  isMulti  : PropTypes.bool,
  label    : PropTypes.string,
  onChange : PropTypes.func.isRequired,
};

IntegrationReactSelect.defaultProps = {
  suggestions : [],
  isMulti     : false,
  label       : 'Inventory User',
};

const styles = theme => ({
  root             : {
    flexGrow : 1,
  },
  input            : {
    display     : 'flex',
    paddingLeft : 9,
    color       : '#777',
    height      : 'auto',
  },
  valueContainer   : {
    display    : 'flex',
    flexWrap   : 'wrap',
    flex       : 1,
    alignItems : 'center',
    overflow   : 'hidden',
  },
  chip             : {
    margin : `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused      : {
    backgroundColor : emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage : {
    padding : `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue      : {
    fontSize   : 16,
    display    : 'flex',
    alignItems : 'center',
  },
  placeholder      : {
    position : 'absolute',
    left     : 2,
    fontSize : 16,
  },
  paper            : {
    position  : 'absolute',
    zIndex    : 1,
    marginTop : theme.spacing.unit,
    left      : 0,
    right     : 0,
    background: 'white',
  },
  divider          : {
    height : theme.spacing.unit * 2,
  },
});

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
