import React from 'react';
import PropTypes from 'prop-types';
import Pipelines from '../components/Pipelines/Pipelines';
import { Query, graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { get, flowRight } from 'lodash';

class LoanCalculatorContainer extends React.Component {
  render () {
    return <div>Loans go here</div>;
  }
}

LoanCalculatorContainer.propTypes = {};

const mapStateToProps = state => ({});

export default LoanCalculatorContainer;
