import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineEdit from '../InlineEdit/InlineEdit';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const nickNameUser = gql`
  mutation nickNameUser($userId: ID!, $nickName: String!) {
    nickNameUser(userId: $userId, nickName: $nickName)
  }
`;

class NickNameInput extends Component {
  constructor (props) {
    super(props);
    this.state = {
      editValue     : props.nickName || props.userName,
      readValue     : props.nickName || props.userName,
      onEventResult : 'Click on a field above to show edit view',
      error         : null,
    };

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onConfirm = editValue => {
    const { mutate, userId, callback, userName } = this.props;

    mutate({
      variables : { userId: userId, nickName: editValue || '' },
    })
      .then(response => {
        this.setState({
          readValue : response.data.nickNameUser,
          editValue : response.data.nickNameUser,
          error     : null,
        });
        callback();
        const el = document.querySelector(`#${userName}-button`);
        if (el) el.className += ' pulse-button';
      })
      .catch(error => {
        // eslint-disable-next-line
        error: error, console.log('there was an error sending the query', error);
      });
  };

  onCancel = () => {
    this.setState({ editValue: this.state.readValue });
  };

  renderInput = options => (
    <SingleLineTextInput
      id={options.id}
      isEditing={options.isEditing}
      isInitiallySelected
      value={this.state.editValue}
      onChange={this.onChange}
      className="input"
    />
  );

  readView = () => (
    <h3 className={`nickName ${this.props.styleTag}`}>
      {this.state.readValue}{' '}
      <span className="fa-solid fa-pen-to-square" style={{ fontSize: '14px', paddingLeft: '6px' }} />
    </h3>
  );

  onChange = event => {
    this.setState({
      editValue     : event.target.value,
      onEventResult : `onChange called with value: ${event.target.value}`,
    });
  };

  isInvalid = () => {
    return this.state.error || this.state.editValue === '';
  };

  render () {
    const id = 'nick_name_input';
    return (
      <InlineEdit
        value={this.state.editValue}
        onConfirm={this.onConfirm}
      />
    );
  }
}

NickNameInput.propTypes = {
  mutate   : PropTypes.func,
  onClick  : PropTypes.func,
  userId   : PropTypes.string,
  nickName : PropTypes.string,
  userName : PropTypes.string,
  callback : PropTypes.func,
};

NickNameInput.defaultProps = {
  callback : () => {},
};

export default graphql(nickNameUser)(NickNameInput);
