import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  inline: {
    display: 'inline',
  },
  readColumn: {
    width: 80,
    marginTop: 30,
    fontSize: 8,
  },
  callToAction: { float: 'right' },
  time: {
    position: 'absolute',
    bottom: 20,
  },
  avatar: {
    backgroundColor: '#1396e2',
  },
}));

const WeekMessage = week_number => {
  switch (week_number) {
    case 0:
      return (
        <span>
          Your business is unprotected! Go to the Business Continuity Builder
          and start planning now.
        </span>
      );
    case 1:
      return (
        <span>
          Make business continuity planning a priority! Visit the business
          continuity builder now.
        </span>
      );
    case 2:
      return (
        <span>
          Business continuity planning is not exit planning! Find out more.
        </span>
      );
    default:
      return '';
  }
};

export const AutoMailerUserNotification = props => {
  const { notification } = props;
  const classes = useStyles();
  const dataString = get(notification, 'data', '{}');
  const data = JSON.parse(dataString);
  const readCol = !get(notification, 'readAt') ? (
    <div className={classes.readColumn}>&#128313;</div>
  ) : (
    <div className={classes.readColumn}>&nbsp;</div>
  );

  return (
    <ListItem alignItems="flex-start">
      <ListItemText>{readCol}</ListItemText>
      <ListItemAvatar>
        <Avatar
          className={classes.avatar}
          name={get(notification, 'notifiableType')}>
          BC
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={<>{WeekMessage(get(data, 'week'))}</>}
        secondary={
          <>
            <span className={classes.time}>
              {get(notification, 'readAt') &&
                moment(notification.readAt).fromNow()}
            </span>
            <Button href="/continuity/builder" className={classes.callToAction}>
              Visit Builder
            </Button>
          </>
        }
      />
    </ListItem>
  );
};

AutoMailerUserNotification.propTypes = {
  notification: PropTypes.object.isRequired,
};

export default AutoMailerUserNotification;
