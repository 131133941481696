import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  VictoryLegend,
  VictoryLabel,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryGroup,
  VictoryStack,
  VictoryBar,
} from 'victory';
import currencyFormatter from 'currency-formatter';
import { slice, uniqBy, replace, groupBy, size, includes, values } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const EngagementsByRange = gql`
  query engagementsByRange($firstDate: String!, $lastDate: String!) {
    engagementsByRange(firstDate: $firstDate, lastDate: $lastDate) {
      id
      user_id
      visit_id
      name
      properties {
        tag
        href
        page
        viewed_user_id
        text
      }
      time
    }
  }
`;

const styles = theme => ({
  report: {
    width: '100%',
    height: '100%',
    marginBottom: '100px',
  },
  title: {
    fontSize: '24px',
  },
  value: {
    backgroundColor: 'Gainsboro',
    marginTop: '25px',
    marginBottom: '25px',
    padding: '15px',
    fontSize: '15px',
  },
});

class EngagementsPage extends React.Component {
  render() {
    const {
      classes, firstDate, lastDate, interval, data, intl,
    } = this.props;
    if (data.loading) return <CircularProgress size={68} />;

    const dateOne = moment(firstDate);
    const dateTwo = moment(lastDate);
    const numOfDays = dateTwo.diff(dateOne, 'days');
    const totalsGraphData = [];
    const uniquesGraphData = [];
    const engagements = this.props.data.engagementsByRange.map(event => ({
      ...event,
      time: new Date(event.time),
      year: new Date(event.time).getFullYear(),
      month: new Date(event.time).getMonth(),
      day: new Date(event.time).getDate(),
    }));

    const isEngaged = (event) => {
      if (
        includes(values(event.properties), 'Map Search') ||
        includes(values(event.properties), 'List Search') ||
        includes(values(event.properties), 'Update Profile') ||
        includes(values(event.properties), 'Send') ||
        includes(values(event.properties), '/conversations')
      ) {
        return true;
      }
      return false;
    };

    const countEngagements = (year, month, day) => {
      const yearResult = engagements.filter(y => y.year === year);
      const monthlyResult = yearResult.filter(m => m.month === month);
      const dailyResult = monthlyResult.filter(d => d.day === day);
      const groupResult = dailyResult.filter(isEngaged);
      const uniqueDailyResults = uniqBy(dailyResult, 'user_id');
      const visitCounter = {
        totalVisits: uniqueDailyResults.length,
        uniqueVisits: size(groupBy(groupResult, 'user_id')),
      };
      return visitCounter;
    };

    const countWeeklyEngagements = (year, month, day) => {
      const yearResult = engagements.filter(y => y.year === year);
      const monthlyResult = yearResult.filter(m => m.month === month);
      const dailyResult = monthlyResult.filter(d => d.day === day);
      return dailyResult;
    };

    const countMonthlyEngagements = (year, month) => {
      const yearResult = engagements.filter(y => y.year === year);
      const monthlyResult = yearResult.filter(m => m.month === month);
      const monthlyEngaged = monthlyResult.filter(isEngaged);
      const uniqueMonthlyResults = uniqBy(monthlyResult, 'user_id');
      const visitCounter = {
        totalVisits: uniqueMonthlyResults.length,
        uniqueVisits: size(groupBy(monthlyEngaged, 'user_id')),
      };
      return visitCounter;
    };

    if (interval === 'daily') {
      for (let i = numOfDays; i >= 0; i--) {
        let endDate = moment(lastDate);
        endDate = moment(endDate).subtract(i, 'days');
        const weekDate = endDate
          .locale(intl.formatMessage({ id: 'admin_company_reports.lang_abv' }))
          .format('ddd MMM D YYYY');
        const count = countEngagements(
          parseInt(endDate.format('YYYY')),
          endDate.format('M') - 1,
          parseInt(endDate.format('D')),
        );
        totalsGraphData.push({ x: weekDate, y: count.totalVisits - count.uniqueVisits });
        uniquesGraphData.push({ x: weekDate, y: count.uniqueVisits });
      }
    } else if (interval === 'weekly') {
      let dayCount = 0;
      let weeklyCount = 0;
      let weeklyEvents = [];

      for (let i = numOfDays; i >= 0; i--) {
        let endDate = moment(lastDate);
        let weekRange = moment(lastDate);
        endDate = moment(endDate).subtract(i, 'days');
        weekRange = moment(weekRange).subtract(i + 7, 'days');
        const dailyCount = countWeeklyEngagements(
          parseInt(endDate.format('YYYY')),
          endDate.format('M') - 1,
          parseInt(endDate.format('D')),
        );
        if (dailyCount.length > 0) {
          for (let j = 0; j < dailyCount.length; j++) {
            weeklyEvents.push(dailyCount[j]);
          }
        }
        if (dayCount % 7 === 0) {
          weeklyCount = uniqBy(weeklyEvents, 'user_id').length;
          const weeklyEngagedCount = size(groupBy(weeklyEvents.filter(isEngaged), 'user_id'));
          const weekDate = `${weekRange
            .locale(intl.formatMessage({ id: 'admin_company_reports.lang_abv' }))
            .format('ddd MMM D')} - ${endDate
            .locale(intl.formatMessage({ id: 'admin_company_reports.lang_abv' }))
            .format('ddd MMM D')}`;
          totalsGraphData.push({ x: weekDate, y: weeklyCount - weeklyEngagedCount });
          uniquesGraphData.push({ x: weekDate, y: weeklyEngagedCount });
          weeklyCount = 0;
          weeklyEvents = [];
        }
        dayCount++;
      }
      totalsGraphData.shift();
      uniquesGraphData.shift();
    } else if (interval === 'monthly') {
      let currentDay = moment().startOf('month');
      const numOfMonths = parseInt(currentDay.format('M') - 1);
      for (let i = 0; i <= numOfMonths; i++) {
        currentDay = moment(currentDay).month(i);
        const count = countMonthlyEngagements(
          parseInt(currentDay.format('YYYY')),
          currentDay.format('M') - 1,
        );
        const monthName = `${currentDay
          .locale(intl.formatMessage({ id: 'admin_company_reports.lang_abv' }))
          .format('MMM')} ${currentDay
          .locale(intl.formatMessage({ id: 'admin_company_reports.lang_abv' }))
          .format('YYYY')}`;
        totalsGraphData.push({ x: monthName, y: count.totalVisits - count.uniqueVisits });
        uniquesGraphData.push({ x: monthName, y: count.uniqueVisits });
      }
    }

    let numOfUniqueEvents = 0;
    for (let i = 0; i < totalsGraphData.length; i++) {
      numOfUniqueEvents += totalsGraphData[i].y;
      numOfUniqueEvents += uniquesGraphData[i].y;
    }

    if (numOfUniqueEvents != 0) {
      return (
        <section className={classes.report}>
          <VictoryChart domainPadding={{ x: 30, y: 30 }} height={350} width={800}>
            <VictoryLegend
              x={125}
              y={10}
              orientation="horizontal"
              gutter={20}
              style={{ border: { stroke: 'black' } }}
              colorScale={['#89bdd3', '9ad3de']}
              data={[
                { name: intl.formatMessage({ id: 'admin_company_reports.sign_in_only' }) },
                { name: intl.formatMessage({ id: 'admin_company_reports.engaged' }) },
              ]}
            />
            <VictoryLabel
              x={575}
              y={24}
              style={styles.title}
              text={intl.formatMessage(
                { id: 'admin_company_reports.total_for_current_view' },
                { total_for_view: numOfUniqueEvents },
              )}
            />
            <VictoryAxis
              tickFormat={totalsGraphData.map(x => x.x)}
              style={{ tickLabels: { angle: 310, fontSize: 7, padding: 20 } }}
            />
            <VictoryAxis dependentAxis tickFormat={t => `${Math.round(t)}`} />
            <VictoryStack
              offset={20}
              style={{
                data: { width: 17 },
                labels: { fontSize: 15, fill: 'DimGrey', padding: 15 },
              }}
              colorScale={['#89bdd3', '#9ad3de']}
            >
              <VictoryBar
                data={totalsGraphData}
                labelComponent={<VictoryLabel dy={35} />}
                labels={d => (d.y > 0 ? `${d.y}` : '')}
              />
              <VictoryBar
                data={uniquesGraphData}
                labelComponent={<VictoryLabel dy={35} />}
                // labels={(d) => `${d.y}`}
                labels={d => (d.y > 0 ? `${d.y}` : '')}
              />
            </VictoryStack>
          </VictoryChart>
        </section>
      );
    }
    return (
      <section>
        <VictoryChart domainPadding={{ x: 30, y: 5 }} width={800}>
          <VictoryLabel
            x={400}
            y={24}
            style={styles.title}
            text={intl.formatMessage({ id: 'admin_company_reports.no_events_in_timeframe' })}
          />
          <VictoryAxis
            tickFormat={totalsGraphData.map(x => x.x)}
            style={{ tickLabels: { angle: 310, fontSize: 7, padding: 20 } }}
          />
          <VictoryAxis dependentAxis tickValues={[1, 2, 3, 4, 5]} />
          <VictoryGroup offset={20} style={{ data: { width: 15 } }}>
            <VictoryBar data={totalsGraphData} labels={d => `${d.y}`} />
          </VictoryGroup>
        </VictoryChart>
      </section>
    );
  }
}

EngagementsPage.propTypes = {
  data: PropTypes.shape({
    firstDate: PropTypes.String,
    lastDate: PropTypes.String,
    interval: PropTypes.String,
  }),
};

const EngagementPageStyled = withStyles(styles)(EngagementsPage);
const EngagementPageStyledGraph = graphql(EngagementsByRange)(EngagementPageStyled);
export default injectIntl(EngagementPageStyledGraph);
