import React, {Fragment} from 'react';
import numeral from 'numeral';
import SellerSellerNote from './SellerSellerNote';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';


const SellerOrdinaryTaxBreakdown = ({result, query, classes, intl, company }) => {
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  const sellerCashFlow = get(result, 'cashFlow.illustration.sellerCashFlow', []);
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note'); 
  
  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }
  
  let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(0);
  if(isNaN(consultingPercentOfDeal)) {
      consultingPercentOfDeal = 0;
  }
  return <Fragment>
          <tr>
            <td><strong>Tax Treatments</strong></td>
            <td></td>
          </tr> 
          <tr>
            <td><i>Income Tax Rate</i></td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.taxRate).format(percentFormat)}</td>))}
          </tr>
          <tr>
              <td>Income Tax</td>
              {sellerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.taxes).divide(-1).format(dollarFormat)}</td>))}
          </tr>
          {showConsultingAgreements && <React.Fragment>
            <tr>
              <td>Consulting Agreement ({consultingPercentOfDeal}%)</td>
              {sellerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.consultingAgreementTaxes).format(dollarFormat)}</td>))}
          </tr> 
            </React.Fragment>}
          { showSellerNote && <SellerSellerNote /> }
          <tr>
              <td style={{ borderTop: '3px solid black' }}>Total Taxes</td>
              {sellerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', color: 'red', textAlign: 'right'}}>{numeral(v.totalTaxes).divide(-1).format(dollarFormat)}</td>))}
          </tr>
    </Fragment>;
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
    company: {...get(state, 'company', {})},
  };
};

export default connect(mapStateToProps)(injectIntl(SellerOrdinaryTaxBreakdown));