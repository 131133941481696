import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { findIndex, snakeCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import WhyValues from './WhyValues';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($why_values: [String!]) {
    updateCurrentUser(why_values: $why_values)
  }
`;

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

let newStrengthsToAdd = [];
class WhyValuesComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      why_values : '',
    };
  }
  updateUser = () => {
    const { mutate, onNext, userWhyValues, updateAction } = this.props;

    updateAction({ loading: true });

    mutate({
      variables : {
        why_values : userWhyValues,
      },
    })
      .then(response => {
        onNext();
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  toggleWhyValues = e => {
    let { userWhyValues } = this.props;
    const { updateAction } = this.props;

    const index = findIndex(userWhyValues, o => snakeCase(o) === snakeCase(e.target.value));
    userWhyValues = [ ...userWhyValues ];
    if (index > -1) {
      userWhyValues.splice(index, 1);
    } else {
      userWhyValues.push(snakeCase(e.target.value));
    }
    updateAction({ why_values: userWhyValues });
  };

  addPrimaryMarket = () => {
    const { why_values } = this.state;
    this.setState({ why_values: '' });
    this.toggleWhyValues({ target: { value: why_values } });
  };

  render () {
    const {
      userWhyValues,
      availableWhyValues,
      intl,
      businessType,
      saveUser,
      saveUserAndNext,
      stylesheetName,
      disableUpdateButton,
      userName,
    } = this.props;
    return (
      <div className="content">
        <b><FormattedMessage id="profile_page.strengths_component.whats_your_why" /></b>
        <p><FormattedMessage id="profile_page.strengths_component.which_values_represent" /></p>
        {userWhyValues.length > 5 && <div style={{ color: 'red' }}><FormattedMessage id="profile_page.strengths_component.please_choose_or_less" /></div>}
        <WhyValues
          availableWhyValues={availableWhyValues}
          userWhyValues={userWhyValues}
          toggleWhyValues={this.toggleWhyValues}
          stylesheetName={stylesheetName}
        />
        <br />
        <br />
        <br />
        <div className="text-center">
          {this.props.onBack && (
            <button
              className="btn btn-default"
              style={{ padding: '12px 24px', float: 'left' }}
              onClick={this.props.onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
          <LoadingButtonWrapper stateChild="user">
            <button
              onClick={() => saveUserAndNext()}
              disabled={disableUpdateButton}
              className={`btn ${stylesheetName === 'rjpe' ? 'btn-default' : 'btn-success'} center text-center`}
              style={{ padding: '12px 24px', marginLeft: 20 }}>
              <FormattedMessage id="profile_page.save_and_next" />
            </button>
            <button
              onClick={() => (window.location.href = `/users/${userName}`)}
              disabled={disableUpdateButton}
              className={`btn btn-secondary`}
              style={{ padding: '12px 24px', marginLeft: 20 }}>
              <FormattedMessage id="profile_page.cancel" />
            </button>
          </LoadingButtonWrapper>
        </div>
      </div>
    );
  }
}

WhyValuesComponent.propTypes = {
  updateAction      : PropTypes.func,
  availbleStrengths : PropTypes.array,
};

WhyValuesComponent.defaultProps = {
  number_of_clients : 500,
};

const WhyValuesComponentWithStyles = injectIntl(withStyles(styles)(WhyValuesComponent));
export default graphql(UpdateCurrentUser)(WhyValuesComponentWithStyles);
