import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { withSnackbar } from 'notistack';
import qs from 'query-string';
import DealStepper from './DealStepper';
import { UpdateDealMutation } from '../Deals/DealQueries';
import BackToContinuityButton from './BackToContinuityButton';
import { S3Image } from '../../lib/s3';
import { get, capitalize } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, injectIntl } from 'react-intl';

const DeleteDeal = gql`
  mutation deleteDeal($id: ID!) {
    deleteDeal(id: $id)
  }
`;

class DealCreate extends Component {
  constructor (props) {
    super(props);

    this.state = {
      redirect  : false,
      loaded    : false,
      showModal :
        this.props.currentUser.valuation_last_accessed_date === null &&
        this.props.companyName.deal_room_reminder_seller_about_valuation,
    };
  }

  clickedValuationButton = () => {
    const { companyName } = this.props;
    window.open(`${companyName.valuation_url}`, '_self');
  };

  updateDeal = (mutate, deal_type) => {
    const { enqueueSnackbar, deal, updateDeal, intl } = this.props;
    updateDeal({ deal_type });

    mutate({
      variables : {
        ...deal,
        deal_type,
      },
    }).then(response => {
      enqueueSnackbar(intl.formatMessage({ id: 'dr_wizard.deal_successfully_saved' }), {
        variant      : 'success',
        anchorOrigin : {
          vertical   : 'top',
          horizontal : 'center',
        },
      });
      this.setState({ redirect: true });
    });
  };

  clickModalClose = () => {
    this.setState({ showModal: false });
  };

  render () {
    const { classes, updateDeal, deal, companyName, dealRoomName, currentUser } = this.props;
    const { redirect, loaded, showModal } = this.state;
    const stylesheetName = get(companyName, 'stylesheet_name', '');
    const continuityWord = get(companyName, 'business_type') === 'wealth_rj' ? 'catastrophic' : 'continuity';
    const dealRoomNameFr = (<FormattedMessage id="deal_page.deal_room" />);
    if (redirect) {
      return <Redirect push to="/deal/wizard" />;
    }
    if (window && !loaded) {
      this.setState({ loaded: true }, window.scrollTo(0, 0));
    }
    const params = qs.parse(location.search);
    const fromCB = Boolean(params.continuity_builder_id || deal.continuity_builder_id);
    return (
      <center style={{ margin: '20px auto' }}>
        <Mutation mutation={UpdateDealMutation}>
          {updateDealMutation => (
            <div className={classes.root}>
              <DealStepper activeStep={0} dealID={deal.deal_name} deal={deal} stylesheetName={stylesheetName} />
              <h2 className={classes.title}><FormattedMessage id="dr_wizard.getting_started" /></h2>
              <Dialog onClose={() => this.clickModalClose()} fullWidth maxWidth="md" open={showModal}>
                <Grid container spacing={0} style={{ padding: 30 }}>
                  <Grid xs={9} style={{ padding: 20 }}>
                    <center>
                      <div className="continuity-sub-title">
                        <b>
                          <h3>
                            <FormattedMessage id="deal_create.obtain_indication_of_value" />
                          </h3>
                        </b>
                        <p>
                          <FormattedMessage id="deal_create.understanding_your_practice" />
                        </p>
                      </div>
                      <br />
                      <br />
                      <br />
                      <Button onClick={() => this.clickModalClose()} color="primary">
                        <FormattedMessage id="valuation_drawer.close" />
                      </Button>
                      <Button
                        style={{ marginLeft: 30 }}
                        color="primary"
                        variant="contained"
                        onClick={() => this.clickedValuationButton()}>
                        <FormattedMessage id="deal_create.valuation_page" />
                      </Button>
                    </center>
                  </Grid>
                  <Grid xs={3}>
                    <span className="continuity-submit">
                      <img
                        src={S3Image(`${companyName.name.toLowerCase()}_obtain_valuation.png`)}
                        alt="obtain_valuation"
                      />
                    </span>
                  </Grid>
                </Grid>
              </Dialog>
              <div className="banner">
                <div className="wrapper">
                  <center>
                    <div className="continuity-sub-title">              
                      <b>
                        <h3>{currentUser.locale === 'fr' ? (<FormattedMessage id="dr_wizard.welcome_to" values={{deal_room_branded_name}}/>) : (<FormattedMessage id="dr_wizard.welcome_to" values={{deal_room_branded_name: dealRoomNameFr}}/>)}</h3>
                      </b>
                      <p>
                        {currentUser.locale === 'fr' ? (<FormattedMessage id="dr_wizard.complete_your_private_deal" values={{deal_room_branded_name}}/>) : (<FormattedMessage id="dr_wizard.complete_your_private_deal" values={{deal_room_branded_name: dealRoomNameFr}}/>)}
                      </p>
                      <p>
                        {currentUser.locale === 'fr' ? (<FormattedMessage id="dr_wizard.sellers_have_complete_control" values={{deal_room_branded_name}}/>) : (<FormattedMessage id="dr_wizard.sellers_have_complete_control" values={{deal_room_branded_name: dealRoomNameFr}}/>)}
                      </p>         
                    </div>
                  </center>
                  <span className="continuity-submit">
                    <img
                      className="img-fluid continuity_button_image"
                      src={S3Image(`${companyName.name.toLowerCase()}_deal_tracker.gif`)}
                      alt="deal_tracker_gif"
                    />
                  </span>
                </div>
              </div>

              <header className={classes.subTitle}>
                {currentUser.locale === 'fr' ? (<FormattedMessage id="dr_wizard.what_type_of_deal" values={{deal_room_branded_name}}/>) : (<FormattedMessage id="dr_wizard.what_type_of_deal" values={{deal_room_branded_name: dealRoomNameFr}}/>)}
              </header>
              <div className={classes.box}>
                {!get(companyName, 'hide_continuity_builder_create_in_deal_wizard') && (
                  <button
                    type="button"
                    className={`${classes.button} ${deal.deal_type === 'continuity' &&
                      classes['selected']}`}
                    onClick={() => {
                      updateDeal({ deal_type: 'continuity' });
                    }}>
                    <i className={`fas fa-umbrella ${classes.icon}`} />
                    <h3><FormattedMessage id={`dr_wizard.${continuityWord}`} /></h3>
                    <p>
                      {<FormattedMessage id="dr_wizard.find_a_partner" values={{continuityWord}}/>}<br />
                      <FormattedMessage id="dr_wizard.to_prepare_your_business" />
                    </p>
                  </button>
                )}
                <button
                  type="button"
                  disabled={fromCB}
                  className={`${classes.button} ${deal.deal_type === 'succession' &&
                    classes['selected']} ${fromCB && classes.disabled}`}
                  onClick={() => {
                    updateDeal({ deal_type: 'succession' });
                  }}>
                  <i className={`fas fa-handshake ${classes.icon}`} />
                  <h3><FormattedMessage id="dr_wizard.succession" /></h3>
                  <p><FormattedMessage id="dr_wizard.find_a_succession_partner" /></p>
                </button>
                <button
                  type="button"
                  disabled={fromCB}
                  className={`${classes.button} ${deal.deal_type === 'sale' &&
                    classes['selected']} ${fromCB && classes.disabled}`}
                  onClick={() => {
                    updateDeal({ deal_type: 'sale' });
                  }}>
                  <i className={`fas fa-money-bill-wave-alt ${classes.icon}`} />
                  <h3><FormattedMessage id="dr_wizard.sale" /></h3>
                  <p>
                    <FormattedMessage id="dr_wizard.find_a_prospective_buyer" />
                    <br />
                    <FormattedMessage id="dr_wizard.to_sell_all_or_a_segment" />
                  </p>
                </button>
              </div>
              <div className={classes.box}>
                {deal.headline && <BackToContinuityButton deal={deal} stylesheetName={stylesheetName} />}
                <div>&nbsp;&nbsp;</div>
                {!deal.headline && (
                  <Mutation mutation={DeleteDeal}>
                    {deleteDeal => (
                      <Button
                        variant="contained"
                        color="secondary"
                        className={stylesheetName === 'rjpe' ? classes[`${stylesheetName}BackgroundColorAlt`] : ''}
                        onClick={() => {
                          deleteDeal({ variables: { id: deal.id } }).then(response => {
                            window.location.href = '/continuity/builder';
                          });
                        }}>
                        <FormattedMessage id="dr_wizard.quit" />
                      </Button>
                    )}
                  </Mutation>
                )}
                <div>&nbsp;&nbsp;</div>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!deal.deal_type}
                  className={
                    stylesheetName === 'rjpe' ? classes[`${stylesheetName}BackgroundColor`] : 'btn btn-default'
                  }
                  onClick={() => this.updateDeal(updateDealMutation, deal.deal_type)}>
                  <FormattedMessage id="modals.findbob_modals.continue" />
                </Button>
              </div>
            </div>
          )}
        </Mutation>
      </center>
    );
  }
}

DealCreate.propTypes = {
  enqueueSnackbar : PropTypes.func.isRequired,
  deal            : PropTypes.object.isRequired,
  updateDeal      : PropTypes.func.isRequired,
  classes         : PropTypes.object.isRequired,
  companyName     : PropTypes.string.isRequired,
  currentUser     : PropTypes.object.isRequired,
};

const styles = theme => ({
  root                   : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : '24px 12px',
  },
  button                 : {
    margin          : '18px 8px',
    padding         : '48px 12px',
    width           : 180,
    height          : 260,
    display         : 'flex',
    flexDirection   : 'column',
    alignItems      : 'center',
    background      : 'none',
    backgroundColor : '#fff',
    border          : 'none',
    fontWeight : 'bold',
  },
  title                  : {
    textAlign : 'left',
  },
  subTitle               : {
    textAlign  : 'left',
    padding    : '20px',
    margin     : 'auto',
    minWidth   : 600,
    fontWeight : 'bold',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : theme.palette.primary.main,
    fontWeight: 'bold',
  },
  selected               : {
    border     : '2px solid #002949',
    borderColor: theme.palette.primary.main,
    color      : theme.palette.primary.main,
    background : '#fff',
    padding    : '46px 10px',
  },
  rjpeselected           : {
    border     : '2px solid #002949',
    borderColor: theme.palette.primary.main,
    color      : '#002949',
    background : '#fff',
    padding    : '46px 10px',
  },
  icon                   : {
    fontSize : 20,
  },
  disabled               : { opacity: 70, color: 'grey' },
  rjpeBackgroundColor    : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  rjpeBackgroundColorAlt : {
    backgroundColor : '#5B5E5A',
    '&:hover'       : {
      background : '#424949',
    },
  },
});

export default withStyles(styles)(withSnackbar(injectIntl(DealCreate)));
