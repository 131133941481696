import React, { Fragment, Component } from 'react';
import { ApolloConsumer, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import DealForm from '../DealPage/DealForm';
import DealStepper from '../DealWizard/DealStepper';
import { withSnackbar } from 'notistack';
import { UpdateDealMutation } from '../Deals/DealQueries';
import BackToContinuityButton from './BackToContinuityButton';
import { FormattedMessage, injectIntl } from 'react-intl';

class DealWizard extends Component {
  constructor (props) {
    super(props);

    this.state = {
      deal         : props.deal,
      value        : 0,
      publishState : props.deal.deal_state,
      redirect     : false,
      loaded       : false,
    };
  }

  updateDeal = (mutate, togglePublished, dealChanges = {}) => {
    const { enqueueSnackbar, deal, updateDeal } = this.props;
    if (togglePublished) {
      deal.deal_state = deal.deal_state === 'published' ? 'new_deal' : 'published';
      updateDeal({ publishState: deal.deal_state });
    }

    mutate({
      variables : {
        ...deal,
        headline                       : encodeURI(deal.headline),
        sub_headline                   : encodeURI(deal.sub_headline),
        summary_of_business            : encodeURI(deal.summary_of_business),
        ideal_candidate                : encodeURI(deal.ideal_candidate),
        growth_opportunities           : encodeURI(deal.growth_opportunities),
        assets_included                : encodeURI(deal.assets_included),
        intangible_assets_included     : encodeURI(deal.intangible_assets_included),
        customer_demographics_comments : encodeURI(deal.customer_demographics_comments),
        products_and_services_comments : encodeURI(deal.products_and_services_comments),
        invitation_deadline            : '2050-01-08T19:27:50.576Z',
        ...dealChanges,
      },
    }).then(response => {
      enqueueSnackbar(<FormattedMessage id="dr_wizard.your_teaser_has_been" />, {
        variant      : 'success',
        anchorOrigin : {
          vertical   : 'top',
          horizontal : 'center',
        },
      });
    });
  };

  handleNext = () => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(<FormattedMessage id="dr_wizard.deal_successfully_saved" />, {
      variant      : 'success',
      anchorOrigin : {
        vertical   : 'top',
        horizontal : 'center',
      },
    });
    this.setState({ redirect: '/deal/questions' });
  };

  validate = () => {
    const {
      headline,
      sub_headline,
      summary_of_business,
      ideal_candidate,
      growth_opportunities,
      assets_included,
      intangible_assets_included,
      customer_demographics_comments,
      products_and_services_comments,
    } = get(this, 'props.deal');
    const { user } = this.props;
    const hideBusinessOverviewQuestion = get(user, 'company.teaser_hide_business_overview_question');
    const hideAssetsQuestion = get(user, 'company.teaser_hide_assets_question');
    return (
      headline &&
      ((!hideBusinessOverviewQuestion && sub_headline) || hideBusinessOverviewQuestion) &&
      ((!hideAssetsQuestion && intangible_assets_included && assets_included) || hideAssetsQuestion) &&
      summary_of_business &&
      ideal_candidate &&
      growth_opportunities &&
      customer_demographics_comments &&
      products_and_services_comments
    );
  };

  render () {
    const { classes, updateDeal, deal, user } = this.props;
    const { redirect, loaded } = this.state;
    const stylesheetName = get(user, 'company.stylesheet_name');
    const continuityWord = get(user, 'company.business_type') === 'wealth_rj' ? 'catastrophic' : 'continuity';
    const dealRoomName = get(user, 'company.deal_room_branded_name');
    const dealRoomNameFr = (<FormattedMessage id="deal_page.deal_room" />);
    if (redirect) {
      return <Redirect push to={redirect} />;
    }
    if (window && !loaded) {
      this.setState({ loaded: true }, window.scrollTo(0, 0));
    }
    return (
      <center style={{ margin: '20px auto' }}>
        <Mutation mutation={UpdateDealMutation}>
          {updateDealMutation => (
            <div className={classes.root}>
              <DealStepper activeStep={1} dealID={deal.deal_name} deal={deal} stylesheetName={stylesheetName} />
              <h2 className={classes.title}>
                <FormattedMessage id="dr_wizard.teaser_information" />
              </h2>
              <header className={classes.subTitle}>
                <p>
                  {user.locale === 'fr' ? (<FormattedMessage id="dr_wizard.the_first_step" values={{dealRoomName: dealRoomName, continuityWord: continuityWord}}/>) : (<FormattedMessage id="dr_wizard.the_first_step" values={{dealRoomName: dealRoomNameFr, continuityWord: continuityWord}}/>)}
                </p>
                <p>
                  <FormattedMessage id="dr_wizard.some_data" />
                  <a href="/profile/revenue_information" target="_blank">
                    &nbsp;<FormattedMessage id="dr_wizard.here" />
                  </a>
                </p>
              </header>
              <DealForm
                deal={deal}
                updateState={updateDeal}
                hideDeadline
                user={user}
                saveDeal={(dealChanges = {}) => this.updateDeal(updateDealMutation, false, dealChanges)}
                stylesheetName={stylesheetName}
              />
              <div clasName={classes.buttonBox}>
                <BackToContinuityButton deal={deal} stylesheetName={stylesheetName} />
                <Button
                  color="default"
                  className={classes.button}
                  variant="contained"
                  onClick={() => {
                    this.setState({ redirect: '/deal/create' });
                  }}>
                  <FormattedMessage id="dr_wizard.back" />
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  disabled={!this.validate()}
                  onClick={() => {
                    this.handleNext();
                  }}>
                  <FormattedMessage id="dr_wizard.next" />
                </Button>
              </div>
            </div>
          )}
        </Mutation>
      </center>
    );
  }
}

const styles = theme => ({
  root                   : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : '24px 12px',
  },
  button                 : {
    margin  : '18px 8px',
    padding : 8,
    width   : 100,
  },
  buttonBox              : {
    display       : 'flex',
    flexDirection : 'column',
  },
  title                  : {
    textAlign : 'left',
  },
  subTitle               : {
    textAlign    : 'left',
    paddingLeft  : 20,
    paddingRight : 20,
    fontSize     : 18,
  },
});

export default injectIntl(withStyles(styles)(withSnackbar(DealWizard)));
