/* eslint-disable import/prefer-default-export */

import axios from 'axios';

import { UPDATE_FILTER } from '../constants/filterConstants';

export const updateMotivationFilters = filterOptions => ({
  type          : UPDATE_FILTER,
  filterOptions,
});
