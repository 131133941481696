import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const ReadProposalButton = ({ classes, dealInterest, scrollTo, deadLinePassed, stylesheetName }) => {
  return (
    <div>
      {get(dealInterest, 'reason_for_suggestion', '') && (
        <Button
          className={classes.ref}
          onClick={() => scrollTo('recommendation')}>
          <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
        </Button>
      )}
      {get(dealInterest, 'proposal_letter', '') && (
        <div>
          <Button
            className={`${classes.margin} wiggle-btn`}
            variant="contained"
            size="large"
            style={{ color: '#fff', width: 200 }}
            Invite
            onClick={() => scrollTo('proposal')}>
            <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
          </Button>
          <p className={classes.margin} style={{ width: 200 }}>
            <FormattedMessage id="profile_page.match_profile.click_read_proposal_above"/>
          </p>
        </div>
      )}
    </div>
  );
};

const styles = {
  margin                 : {
    margin : '0px 0px 15px 25px',
  },
  button                 : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
  },
  ref                    : {
    textTransform : 'capitalize',
    margin        : '0px 0px 5px 20px',
    color         : '#1396e2',
    display       : 'block',
  },
};

export default withStyles(styles)(ReadProposalButton);
