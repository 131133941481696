import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

class InfoBox extends React.Component {
  render () {
    const { classes, title } = this.props;
    return (
      <div className={classes.border}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} type="headline">
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.content}>{this.props.children}</div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

InfoBox.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  border  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 80,
    width        : 80,
  },
  title   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
    fontSize   : 10,
  },
  content : {
    textAlign : 'center',
    margin    : 7,
    fontSize  : 16,
  },
};

export default withStyles(styles)(InfoBox);
