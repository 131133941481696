import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';

const styles = {
  paper : {
    padding      : 14,
    marginBottom : 14,
    marginTop    : 14,
  },
  input : {
    background : '#E8E8E8',
    padding    : 6,
  },
  label : {
    verticalAlign : 'bottom',
  },
};

const Requirements = ({ opportunity, classes, updateAction }) => (
  <Paper className={classes.paper}>
    <h4>Requirements</h4>
    <div>
      <p>What are your requirements for this opportunity</p>
      <TextField
        type="text"
        variant="filled"
        multiline
        rows="5"
        fullWidth
        value={opportunity.requirements}
        onChange={e => {
          updateAction({ requirements: e.target.value });
        }}
      />
    </div>
  </Paper>
);

Requirements.propTypes = {
  opportunity  : PropTypes.object,
  classes      : PropTypes.object.isRequired,
  updateAction : PropTypes.func.isRequired,
};

export default withStyles(styles)(Requirements);
