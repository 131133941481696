import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MessageForm from './MessageForm';
import Message from './Message';
import { get, uniqBy } from 'lodash';
import { ApolloProvider, Query } from 'react-apollo';
import client from '../../lib/apolloClient';
import * as actions from '../../actions/conversationActionCreators';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { injectIntl } from 'react-intl';
import { createConsumer } from '@rails/actioncable';
import { UserProfileQueries } from '../../containers/UserProfileContainer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../../lib/themes';
import BlockButton from './BlockButton';
import MessageMultipleChoice from './MessageMultipleChoice';
import ConfettiComponent from '../ConfettiComponent/ConfettiComponent';

export const ConversationQuery = gql`
  query getConversation($convoId: ID) {
    getConversation(convoId: $convoId) {
      id
      __typename
      blockedByMe
      blockedByThem
    }
  }
`;

class ChatWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { messages: [], isConfettiActive: false };
    this.bottom = React.createRef();

    console.log("create consumer");
    this.cable = createConsumer();
    this.createSubscription = this.createSubscription.bind(this);
  }

  scrollToBottom = () => {
    const objDiv = this.bottom.current;
    if (objDiv) {
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  };

  componentDidUpdate(prevProps) {
    const { room_id, updateConversation } = this.props;
    if (room_id !== prevProps.room_id) {
      if (this.cable.subscriptions.subscriptions[0]) {
        this.cable.disconnect();
        this.cable = createConsumer();
      }
      this.createSubscription(room_id);
    }
  }

  clickedHeadline = async (client) => {
    const { updateSelectedProfileDrawer, userName } = this.props;
    const { data } = await client.query({
      query: UserProfileQueries,
      variables: { userName },
      fetchPolicy: 'network-only',
    });

    updateSelectedProfileDrawer({ ...get(data, 'data'), anchor: '' });
  };

  createSubscription(room_id) {
    const { messages } = this.state;

    this.cable.subscriptions.create(
      { channel: 'ChatChannel', room_id },
      {
        received: data => {
          switch (data.type) {
            case 'message':
              if (this.state.messages.find(m => m.id === data.message.id)) {
                break;
              }
              this.setState(
                {
                  messages: [...this.state.messages, data.message],
                },
                () => {
                  this.scrollToBottom();
                  this.cable.subscriptions.subscriptions[0].read({
                    message_id: data.message.id,
                    room_id,
                  });
                  if (data.message.confetti) {
                    this.setState({ isConfettiActive: true });
                    setTimeout(() => {
                      this.setState({ isConfettiActive: false });
                    }, 5000);
                  }
                }
              );
              break;
            case 'messages':
              this.setState({ messages: data.messages }, this.scrollToBottom);
              break;
            case 'receipt':
              this.setState({
                messages: this.state.messages.map(m => {
                  if (m.id === data.message_read) {
                    m.read = true;
                  }
                  return m;
                }),
              }, this.props.onNewMessage());
              break;
            default:
              break;
          }
        },
        speak: function (message) {
          return this.perform('speak', {
            room_id,
            message: message.message,
          });
        },
        load: function (e) {
          return this.perform('load', { room_id });
        },
        read: function (data) {
          return this.perform('read', {
            ...data,
          });
        },
        close: function () { },
        error: function () {
          return this.reconnect();
        },
        connected: () => {
          this.cable.subscriptions.subscriptions[0].load();
          return true;
        },
      }
    );
  }

  displayError = () => {
    const { disableChatMessage, conversation, intl } = this.props;

    if (get(conversation, 'blockedByMe', false)) {
      return intl.formatMessage({ id: 'messages_section.you_blocked' });
    }

    if (get(conversation, 'blockedByThem', false)) {
      return intl.formatMessage({ id: 'messages_section.chat_disabled' });
    }
    return disableChatMessage;
  };

  render() {
    const { messages, isConfettiActive } = this.state;
    const {
      classes,
      standalone,
      title,
      room_id,
      fromMessagesPage,
      disableChat,
      currentUser,
      dealInterestStage,
      canViewRealName,
      theme_name,
      disableChatMessage,
      conversation,
      updateConversation,
    } = this.props;

    // Step 1: Sort messages by their 'sent' timestamp
    const sortedMessages = messages.sort((a, b) => new Date(a.sent) - new Date(b.sent));

    // Step 2 & 3: Filter out messages with the same content sent within 1 second
    const filteredMessages = sortedMessages.filter((msg, index, arr) => {
      if (index === 0) return true; // Always include the first message
      const prevMsg = arr[index - 1];
      const timeDiff = Math.abs(new Date(msg.sent) - new Date(prevMsg.sent)) / 1000; // Time difference in seconds
      // Check if the current message has the same content as the previous one and was sent within 1 second
      return !(msg.content === prevMsg.content && timeDiff <= 1);
    });

    const messageList = filteredMessages.map((message, index) => {
      if (message.message_type === 'multiple_choice') {
        return (
          <div className={classes.listItem} key={message.id}>
            <MessageMultipleChoice
              message={message}
              currentUser={currentUser}
              dealInterestStage={dealInterestStage}
              canViewRealName={canViewRealName}
              cable={this.cable}
              roomId={room_id}
              lastMessage={index === (filteredMessages.length - 1)}
            />
          </div>
        );
      }
      return (
        <div className={classes.listItem} key={message.id}>
          <Message
            message={message}
            currentUser={currentUser}
            dealInterestStage={dealInterestStage}
            canViewRealName={canViewRealName}
          />
        </div>
      );
    });

    let theme = themes[theme_name];
    const blocked = get(conversation, 'blockedByThem', false) || get(conversation, 'blockedByMe', false);
    const disabledOrBlocked = disableChat || blocked;
    const blockedByThem = get(conversation, 'blockedByThem', false);

    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <CssBaseline />
          <Paper className={classes.chatroomContainer} className="content-card">
            <Query
              query={ConversationQuery}
              variables={{ convoId: room_id }}
              onCompleted={data => {
                if (data.getConversation) {
                  this.createSubscription(room_id);
                  updateConversation(data.getConversation)
                  
                  
                }
              }}
            >
              {({ loading, error }) => (
                <div style={{ paddingLeft: '8px' }}>
                  {fromMessagesPage && (
                    <h3>
                      {!disabledOrBlocked && (
                        <a
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.clickedHeadline(client)}>
                          {title}
                        </a>
                      )}
                      {disabledOrBlocked && <span>{title} - {this.displayError()}</span>}
                      {(room_id && !blockedByThem) && <span className={classes.blockButton}>
                        <BlockButton user={currentUser} room_id={room_id} />
                      </span>}
                    </h3>
                  )}
                  {!fromMessagesPage && <h3>{title} -
                    {disabledOrBlocked ? this.displayError() : ''}
                    {(room_id && !blockedByThem) && <span className={classes.blockButton}>
                      <BlockButton
                        user={currentUser}
                        conversation={conversation}
                        room_id={room_id}
                        client={client} />
                    </span>}
                  </h3>}
                </div>
              )}
            </Query>
            <div id="messageList" className={standalone ? classes.standaloneList : classes.messageList} ref={this.bottom}>
              {messageList}
            </div>
            <MessageForm cable={this.cable} room_id={room_id} disableChat={disabledOrBlocked} />
            {isConfettiActive && <ConfettiComponent numberOfPieces={200} />}
          </Paper>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }
}

ChatWidget.propTypes = {
  title: PropTypes.string,
  standalone: PropTypes.bool,
  deleted: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  fromMessagesPage: PropTypes.bool,
  disableChat: PropTypes.bool,
  disableChatMessage: PropTypes.string,
  onNewMessage: PropTypes.func,
};

ChatWidget.defaultProps = {
  title: 'Messages',
  standalone: false,
  deleted: false,
  fromMessagesPage: false,
  disableChat: false,
  disableChatMessage: 'This chat has been disabled',
  onNewMessage: () => { },
};

const styles = theme => ({
  chatroomContainer: {
    padding: 12,
  },
  messageList: {
    overflowY: 'scroll',
    height: '400px',
  },
  standaloneList: {
    overflowY: 'scroll',
    height: 'calc(100vh - 416px)',
    width: '100%',
  },
  loadButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  listItem: {
    padding: 8,
  },
  blockButton: {
    float: 'right',
    top: '-12px',
    position: 'relative',
  },
});

const ChatWidgetWithContext = (props, railsContext) => <ChatWidget {...props} theme_name={railsContext.theme_name} />;

const mapStateToProps = state => ({
  conversation: { ...state.conversation },
});

export default connect(mapStateToProps, { ...actions })(withStyles(styles)(injectIntl(ChatWidgetWithContext)));
