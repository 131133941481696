import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ExpressInterestButton from './ExpressInterestButton';
import { FormattedMessage } from 'react-intl';

const renderMessageButton = (
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  sellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  scrollTo,
  classes,
  updateNotes,
  updateProfile
) => {
  if (marketplaceOnly) {
    return <div />;
  }
  if (user.expressed_interest) {
    return (
      <div>
        <Button variant="contained" className={classes.pressed}>
          <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.express_intrest_button.become_a_sellerword" values={{sellerWord}}/>
        </Button>
      </div>
    );
  }
  return (
    <div>
      <ExpressInterestButton
        scrollTo={scrollTo}
        user={user}
        currentUserHasLimitedAccess={currentUserHasLimitedAccess}
        hasInterestedUsers={hasInterestedUsers}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        sellerWord={sellerWord}
        currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
        getCurrentUser={getCurrentUser}
        disableMessageBtn={disableMessageBtn}
        marketplaceOnly={marketplaceOnly}
        updateNotes={updateNotes}
        updateProfile={updateProfile}
        becomeAPartner
      />
    </div>
  );
};

const BecomeAPartnerContainer = ({
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  scrollTo,
  classes,
  updateNotes,
  updateProfile,
}) => (
  <div>
    {renderMessageButton(
      user,
      currentUserHasLimitedAccess,
      hasInterestedUsers,
      updateBobCards,
      loadBobCardList,
      currentCompanySellerWord,
      currentUserHasSellingTransitionGoals,
      getCurrentUser,
      disableMessageBtn,
      marketplaceOnly,
      scrollTo,
      classes,
      updateNotes,
      updateProfile
    )}
  </div>
);

BecomeAPartnerContainer.propTypes = {
  classes                              : PropTypes.object.isRequired,
  user                                 : PropTypes.object.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.func.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  scrollTo                             : PropTypes.func.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  sellerWord                           : PropTypes.string.isRequired,
};

const styles = {
  button  : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default withStyles(styles)(BecomeAPartnerContainer);
