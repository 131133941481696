import * as React from 'react';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
  ticker: {
    display: 'flex',
    backgroundColor: '#fff',
    width: '100%',
    overflow: 'hidden',
    flex: '0 0 100%',
    '--gap': '1rem',
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    userSelect: 'none',
    gap: 'var(--gap)',
  },
  tickerList: {
    display: 'flex',
    flex: '0 0 100%',
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    // justifyContent: 'space-around',
    minWidth: '100%',
    animation: '$scroll 60s linear infinite',
  },
  '@keyframes scroll': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(calc(-100% + var(--gap)))',
    },
  },
  tickerHover: {
    '& .tickerList': {
      animationPlayState: 'paused',
    },
  },
  '@keyframes ticker': {
    '0%': {
      transform: 'translateX(0%)',
    },
    '100%': {
      transform: 'translateX(-100%)',
    },
  },
  tickerItem: {
    borderLeft: '1px solid transparent',
    display: 'table-cell',
    position: 'relative',
    verticalAlign: 'top',
    color: '#fff',
    width: '100%',
    '&:hover': {
      backgroundColor: '#000',
    },
    '&:after': {
      backgroundColor: '#2a2a39',
      content: '""',
      position: 'absolute',
      top: 10,
      bottom: 10,
      left: -1,
      width: 1,
    },
  },
});

const TickerList = (props) => {
  const animation = props.slideSpeed ? `scroll ${props.slideSpeed}s linear infinite` : 'scroll 15s linear infinite';
  return (
    <div className={props.classes.ticker}>
      <div style={{ animation: animation, display: "flex" }}>
        <div className={props.classes.tickerList}>
          {props.children}
        </div>

        {
          !props.isNewsTicker ? <div className={props.classes.tickerList} aria-hidden={true}>
            {props.children}
          </div> : ''
        }
      </div>
    </div>
  )
}

export default withStyles(styles)(TickerList);
