import { combineReducers } from 'redux';
import { findIndex, pullAt } from 'lodash';

// Crud Reducer
const update = (list, object, modified = true) => {
  const index = findIndex(list, e => e['id'] === object['id']);
  if (index != -1) {
    if (modified) object.modified = true;
    else object.modified = false;
    list[index] = object;
  }
  return list;
};

const remove = (list, object_id) => {
  const index = findIndex(list, e => e.id === object_id);
  if (index != -1) pullAt(list, index);
  return list;
};

class CrudReducer {
  constructor (objectName) {
    this.objectName = objectName;
    this.LIST = `${this.objectName}_LIST`;
    this.UPDATE = `${this.objectName}_UPDATE`;
    this.ADD = `${this.objectName}_ADD`;
    this.DELETE = `${this.objectName}_DELETE`;
    this.PUT = `${this.objectName}_PUT`;
  }

  Reducer = (state = {}, action) => {
    let list = [];
    switch (action.type) {
      case this.LIST:
        return { ...state, data: action.data };
      case this.UPDATE:
        return {
          ...state,
          data : update(state.data, action.data),
        };
      case this.ADD:
        list = state.data || [];
        if (action.data) {
          list.push(action.data);
        }

        return {
          ...state,
          data    : list,
          error   : action.error,
          close   : action.close,
          loading : action.loading,
        };
      case this.DELETE:
        list = state.data;
        list = action.error || action.loading ? list : remove(list, action.object_id);
        return {
          ...state,
          data    : list,
          error   : action.error,
          close   : action.close,
          loading : action.loading,
        };
      case this.PUT:
        list = state.data;
        list = action.error || action.loading ? list : update(state.data, action.data, false);

        return {
          ...state,
          data    : list,
          error   : action.error,
          close   : action.close,
          loading : action.loading,
        };
      default:
        return state;
    }
  };
}

export default CrudReducer;
