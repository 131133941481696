import PropTypes from 'prop-types';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, get, debounce } from 'lodash';
import HomeOfficeCtaMenu from '../HomeOfficeCtaMenu/HomeOfficeCtaMenu';
import SaveButton from '../SaveButton/SaveButton';

const styles = theme => ({
  root         : {
    flexGrow : 1,
    width    : '100%',
    padding  : '12px',
    margin   : '12',
    fontSize : '16px',
  },
  paper        : {
    padding : 12,
  },
  control      : {
    padding : theme.spacing.unit * 2,
  },
  title        : {
    fontSize : '18px',
    color    : '#fff',
  },
  textField    : {
    fontSize : '24px',
    margin   : '12px',
  },
  toggleButton : {
    fontSize : '24px',
    margin   : '12px',
  },
  form         : {
    display       : 'flex',
    flexDirection : 'column',
  },
  listItem     : {
    display       : 'flex',
    flexDirection : 'row',
    padding       : 0,
  },
  typo         : {
    margin         : '10px',
    textDecoration : 'underline',
  },
});

class HomeOfficeCtaForm extends React.Component {
  submitComp = debounce(event => {
    const { company, submitCompany, generateCompanyPayload } = this.props;
    submitCompany(generateCompanyPayload(company.data), company, event);
  }, 2000);

  constructor (props) {
    super(props);
    props.submitCompany.bind(this);
  }

  componentWillMount = () => {
    const { company, match, loadCompany } = this.props;
    if (get(company, 'data[id]', null) !== match.params.id) {
      loadCompany(match.params.id);
    }
  };

  componentWillReceiveProps = nextProps => {
    const { match, loadCompany } = this.props;
    if (match.params.id !== nextProps.match.params.id) {
      loadCompany(match.params.id);
    }
  };

  updateCompanyBool = id => {
    const { company, updateCompany } = this.props;

    company.data[id] = !company.data[id];
    updateCompany(company);
  };

  renderLoading = classes => (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );

  render () {
    const { company, updateCompany, classes } = this.props;
    const { success, loading, error } = company;
    if (isEmpty(company) || isEmpty(company.data)) return this.renderLoading(classes);
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <HomeOfficeCtaMenu
              company={company}
              updateCompany={updateCompany}
              updateCompanyBool={this.updateCompanyBool}
            />
            <SaveButton onSubmit={e => this.submitComp(e)} success={success} success={loading} label="Save" />
            {error && <FormHelperText>{error}</FormHelperText>}
          </Grid>
        </Grid>
      </div>
    );
  }
}

HomeOfficeCtaForm.propTypes = {
  company                : PropTypes.object.isRequired,
  updateCompany          : PropTypes.func.isRequired,
  submitCompany          : PropTypes.func.isRequired,
  classes                : PropTypes.object.isRequired,
  generateCompanyPayload : PropTypes.func.isRequired,
  loadCompany            : PropTypes.func.isRequired,
  params                 : PropTypes.object.isRequired,
  match                  : PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeOfficeCtaForm);
