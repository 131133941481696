import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import ContactInformationForm from './ContactInformationForm';

const CreateNewUser = gql`
  mutation createNewUser(
    $agencyName: String
    $fullName: String!
    $email: String!
    $birthDate: String!
    $password: String!
    $postal: String!
    $phone: String
    $locale: String!
    $ref: String
    $explicit_opt_in_tos_timestamp: String
    $accepted_policies: Boolean
  ) {
    createNewUser(
      agencyName: $agencyName
      fullName: $fullName
      email: $email
      birthDate: $birthDate
      password: $password
      postal: $postal
      phone: $phone
      locale: $locale
      ref: $ref
      explicit_opt_in_tos_timestamp: $explicit_opt_in_tos_timestamp
      accepted_policies: $accepted_policies
    )
  }
`;

export default graphql(CreateNewUser)(ContactInformationForm);
