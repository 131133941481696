import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

class ClickActivities extends React.Component {
  filterEvent = (firstName, event) => {
    if (event.properties.tag !== null && event.properties.text === 'View Profile' && event.properties.href !== null) {
      return `${firstName} viewed user ${event.properties.href.replace('/users/', '')}'s profile`;
    }
    if (event.properties.text === 'Message' || event.properties.text === 'Send') {
      if (event.properties.page === '/dashboard') {
        return `${firstName} sent a message to a user from the dashboard.`;
      }
      return `${firstName} sent a message to user ${event.properties.page.replace('/users/', '')}`;
    }
    if (event.properties.text === 'Education') {
      return `${firstName} clicked on the Education Link in the header.`;
    }
    if (event.properties.text === 'Search Now') {
      return `${firstName} performed a search`;
    }
    if (event.properties.text === 'Strengths') {
      return `${firstName} viewed their own Strengths`;
    }
    if (event.properties.text === 'Update Profile') {
      return `${firstName} updated their profile`;
    }
    if (event.properties.text === 'Nothing Selected') {
      return `${firstName} clicked on the drop-down menu`;
    }
    if (event.properties.text.includes('$')) {
      return `${firstName} clicked on ${event.properties.text} from a drop-down menu`;
    }
    if (event.properties.text.includes('#FB')) {
      return `${firstName} selected user '${event.properties.text}' from the ${event.properties.page.replace(
        '/',
        ''
      )} page.`;
    }
    if (event.properties.tag !== null && event.properties.text !== 'Message') {
      return `${firstName} clicked on the '${event.properties.text}' button on the ${event.properties.page.replace(
        '/',
        ''
      )} page.`;
    }
  };

  render () {
    const { firstName, event } = this.props;
    return <span>{this.filterEvent(firstName, event)}</span>;
  }
}

ClickActivities.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  border  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 80,
    width        : 80,
  },
  title   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
    fontSize   : 10,
  },
  content : {
    textAlign : 'center',
    margin    : 7,
    fontSize  : 16,
  },
};

export default withStyles(styles)(ClickActivities);
