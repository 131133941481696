import React from 'react';
import { get, filter, startCase, keyBy, groupBy, map } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import numeral from 'numeral';
import moment from 'moment';
import DateRangeFilterContainer from '../../containers/DateRangeFilterContainer';
import {
  VictoryBar,
  VictoryLegend,
  VictoryChart,
  VictoryTheme,
  VictoryStack,
  VictoryContainer,
  VictoryAxis,
} from 'victory';
import color from '@material-ui/core/colors/deepOrange';
import { capturePdf } from '../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

const colorScale = [
  '#337ab7',
  'rgb(0, 121, 107)',
  'silver',
  'rgb(25, 245, 157)',
  '#FF8A65',
  '#FFF176',
  '#AFB42B',
  '#BA68C8',
  '#BCAAA4',
  '#004D40',
];

class PipelineByOwnerTotalReport extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      pipelineId : get(this.props, 'data.pipeline.id', null),
      yAxis      : 'value',
      status     : 'open',
      resized    : false,
    };
  }

  componentWillReceiveProps = nextProps => {
    if (nextProps.data.pipeline.id) {
      this.setState({ pipelineId: nextProps.data.pipeline.id });
    }
  };

  formatChartData = data => {
    return data.map((cards, key) => ({
      name  : key,
      value : get(cards, 'pipeline_cards', []).reduce((s, c) => s + parseInt(c.value), 0),
      count : get(cards, 'pipeline_cards', []).length,
    }));
  };

  getGraphData = (stages, owners) => {
    let newCardArray = [];
    const ownerNames = Object.keys(owners);
    for (let i = 0; i < stages.length; i++) {
      const lineData = [];
      for (let j = 0; j < ownerNames.length; j++) {
        const stageCards = stages[i].pipeline_cards;
        stageCards = stageCards.filter(sc => get(sc, 'owner.name', '') === ownerNames[j]);
        var value = stageCards.reduce((prev, cur) => {
          return prev + cur.value;
        }, 0);
        lineData.push({ owner: `${ownerNames[j]}`, value: value, count: stageCards.length });
      }
      let count = 0;
      count = lineData.reduce((prev, cur) => {
        return prev + cur.count;
      }, 0);
      if (count > 0) {
        newCardArray.push(lineData);
      }
    }
    return newCardArray;
  };

  clickedCapture = () => {
    capturePdf('l', 10, 10, 250, 150, 2);
  };

  resizeSVG = () => {
    const svg = document.querySelector('.brutalLegend svg');
    const bbox = svg.getBBox();
    svg.setAttribute('width', bbox.x + bbox.width + bbox.x);
    svg.setAttribute('height', bbox.y + bbox.height + bbox.y);
    this.setState({ resized: true });
  };

  render () {
    const { classes, startDate, endDate, refetch, setData } = this.props;
    const { yAxis, status, resized, pipelineId } = this.state;
    const pipelines = get(this.props, 'data.pipelines', []);
    const pipeline = get(this.props, 'data.pipeline', {});
    const all_stages = get(pipeline, 'pipeline_stages', []); // delete this after
    const all_cards = get(pipeline, 'pipeline_cards', []);
    const owners = groupBy(all_cards, 'owner.name');

    const stages = all_stages.map(s => {
      if (startDate && endDate) {
        return {
          ...s,
          pipeline_cards : filter(
            s.pipeline_cards,
            c =>
              moment(c.close_date).isAfter(startDate, 'YYYY-MM-DD') &&
              moment(c.close_date).isBefore(endDate, 'YYYY-MM-DD') &&
              (status === 'all' || c.status === status)
          ),
        };
      }
      return s;
    });
    const graphData = this.getGraphData(stages, owners);

    const totalValue = stages.reduce(
      (sum, s) => sum + get(s, 'pipeline_cards', []).reduce((s, c) => s + parseInt(c.value), 0),
      0
    );
    const totalCases = stages.reduce((sum, s) => sum + get(s, 'pipeline_cards.length', 0), 0);
    return (
      <div className={`col-md-12 col-sm-12 ${classes.container}`}>
        <div id="capture">
          <h4 className={classes.subHeader}>The distribution of Cases across your team, based on Case close date</h4>
          <Grid container>
            <Grid>
              <h2 className={classes.bigNumber}>
                {numeral(totalValue).format('$0a')} <span className={classes.numberLabel}>Total Value</span>
              </h2>
            </Grid>
            <Grid>
              <h2 className={classes.bigNumber}>
                &nbsp;{totalCases} <span className={classes.numberLabel}>Cases</span>
              </h2>
            </Grid>
          </Grid>
          <div className={classes.menu}>
            <Select
              classes={{ select: classes.selectatron }}
              value={pipelineId}
              onClick={e => {
                if (e.target.value) {
                  refetch({ id: e.target.value }).then(r => {
                    setData({ ...r.data });
                  });
                  this.setState({ pipelineId: e.target.value });
                }
              }}>
              {pipelines.map(p => <MenuItem value={p.id}>{decodeURI(p.name)}</MenuItem>)}
            </Select>
            &nbsp;
            <RadioGroup
              aria-label="Y-Axis"
              name=""
              value={yAxis}
              onChange={e => {
                this.setState({ yAxis: e.target.value });
              }}
              row>
              <FormControlLabel value="value" control={<Radio />} label="Total Value" labelPlacement="end" />
              <FormControlLabel value="count" control={<Radio />} label="Count" labelPlacement="end" />
            </RadioGroup>
            <div className={classes.datepicker}>
              &nbsp;Close Date: <DateRangeFilterContainer />
            </div>
            <Select
              value={status}
              onClick={e => {
                if (e.target.value) {
                  this.setState({ status: e.target.value });
                }
              }}>
              {[ 'all', 'open', 'won', 'lost', 'abandoned' ].map(s => <MenuItem value={s}>{startCase(s)}</MenuItem>)}
            </Select>
            <Tooltip title="Download PDF" style={{ marginLeft: 30 }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <VictoryChart
            width={800}
            height={260}
            domainPadding={[ 30, 30 ]}
            padding={{ bottom: 50, left: 80, top: 40, right: 80 }}
            theme={VictoryTheme.material}
            style={{ display: 'flex', flexDirection: 'column' }}>
            <VictoryAxis dependentAxis={false} orientation="left" style={{ tickLabels: { fontSize: 8, padding: 3 } }} />
            <VictoryAxis
              dependentAxis
              tickFormat={x => (yAxis === 'value' ? numeral(x).format('$0,a') : x)}
              orientation="bottom"
            />
            <VictoryStack colorScale={colorScale} horizontal y={0} x={0} height={260} width={800}>
              {graphData.map(d => <VictoryBar width={800} height={260} data={d || []} x="owner" y={yAxis} align="" />)}
            </VictoryStack>
          </VictoryChart>
          <center>
            <VictoryLegend
              containerComponent={
                <VictoryContainer
                  className="brutalLegend"
                  style={{
                    width     : 500,
                    display   : 'flex',
                    alignSelf : 'center',
                    height    : 170,
                  }}
                  height={160}
                />
              }
              y={70}
              padding={{ top: 20, bottom: 20 }}
              className="brutalLegend"
              title="Legend"
              centerTitle
              orientation="horizontal"
              position="center"
              gutter={20}
              width={'100%'}
              id="coolLegend"
              style={{
                border    : { stroke: 'black' },
                title     : { fontSize: 16 },
                bottom    : 0,
                alignSelf : 'center',
                overflow  : 'inherit',
              }}
              colorScale={colorScale}
              data={stages
                .filter(s => (s.pipeline_cards || []).length > 0)
                .map(st => ({
                  name:
                    document.URL.match(/rja|icd/) && st.name === 'Filed Agreement'
                      ? 'Submitted Agreement'
                      : decodeURI(st.name),
                }))}
            />
          </center>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Owner</TableCell>
                {stages.map(s => (
                  <TableCell align="right">
                    {document.URL.match(/rja|icd/) && s.name === 'Filed Agreement' ? (
                      'Submitted Agreement'
                    ) : (
                      decodeURI(s.name)
                    )}
                  </TableCell>
                ))}
                <TableCell align="right">Total Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(owners, (cards, owner) => {
                const crds = filter(
                  cards,
                  c =>
                    moment(c.close_date).isAfter(startDate, 'YYYY-MM-DD') &&
                    moment(c.close_date).isBefore(endDate, 'YYYY-MM-DD') &&
                    (status === 'all' || c.status === status)
                );
                const rowTotal = crds.map(c => c.value).reduce((a, b) => a + b, 0);
                return (
                  <TableRow key={owner}>
                    <TableCell component="th" scope="row">
                      {decodeURI(owner === 'undefined' ? 'No Owner' : owner)}
                    </TableCell>
                    {stages.map(s => (
                      <TableCell align="right">
                        {numeral(
                          crds.filter(c => c.pipeline_stage.id === s.id).reduce((r, u) => r + u.value, 0)
                        ).format('$0.0a')}
                      </TableCell>
                    ))}
                    <TableCell align="right">{numeral(rowTotal).format('$0.0a')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <div>
            {!resized && setTimeout(() => this.resizeSVG(), 2000)}
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
};
export default withStyles(styles)(PipelineByOwnerTotalReport);
