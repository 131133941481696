import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

class FBTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render () {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, columns, classes } = this.props;
    return (
      <TableHead className={classes.stickyHeader}>
        <TableRow>
          {onSelectAllClick && (
            <TableCell padding="checkbox" className={classes.checkboxHeader}>
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columns.map(
            column => (
              <TableCell
                className={classes.stickyHeader}
                style={{ ...column.customStyle, zIndex: column.customStyle ? 20 : 8 }}
                key={column.id}
                align={column.numeric ? 'right' : 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.id ? order : false}>
                <Tooltip
                  title={column.dataType === 'disableSort' ? '' : 'Sort'}
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    disabled={column.dataType === 'disableSort'}
                    onClick={this.createSortHandler(column.id)}
                    style={{ width: column.customWidth }}>
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

FBTableHead.propTypes = {
  numSelected      : PropTypes.number.isRequired,
  onRequestSort    : PropTypes.func.isRequired,
  onSelectAllClick : PropTypes.func.isRequired,
  order            : PropTypes.string.isRequired,
  orderBy          : PropTypes.string.isRequired,
  rowCount         : PropTypes.number.isRequired,
  columns          : PropTypes.array.isRequired,
  classes          : PropTypes.object.isRequired,
};

const styles = {
  stickyHeader   : {
    position   : 'sticky',
    top        : 0,
    zIndex     : 8,
    background : 'white',
  },
  checkboxHeader : {
    position   : 'sticky',
    top        : 0,
    left       : 0,
    zIndex     : 10,
    background : 'white',
  },
};

export default withStyles(styles)(FBTableHead);
