import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export const AgencyNameSelectorContainer = ({ isMulti, agencies, label, onChange, value }) => (
  <Select isMulti={isMulti} options={agencies} label={label} onChange={onChange} value={value} />
);

AgencyNameSelectorContainer.propTypes = {
  isMulti  : PropTypes.bool,
  label    : PropTypes.string,
  onChange : PropTypes.func.isRequired,
  value    : PropTypes.string,
  agencies : PropTypes.object,
};

AgencyNameSelectorContainer.defaultProps = {
  isMulti  : false,
  label    : '',
  value    : '',
  agencies : [],
};

export default AgencyNameSelectorContainer;
