import { DEAL_LIST_UPDATE, DEAL_UPDATE, FILTER_UPDATE, UPDATE_STATUS } from '../constants/dealConstants';

const dealReducer = (
  state = {
    deals       : [],
    deal        : {},
    filter      : {},
    user        : {},
    status      : null,
    order       : 'desc',
    orderBy     : 'id',
    selected    : [],
    data        : [],
    loaded      : false,
    page        : 0,
    rowsPerPage : 10,
    offset      : 0,
    limit       : 10,
  },
  action
) => {
  switch (action.type) {
    case DEAL_LIST_UPDATE:
      return { ...state, ...action.deals, loaded: true };
    case DEAL_UPDATE:
      return { ...state, ...action.deal };
    case FILTER_UPDATE:
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case UPDATE_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default dealReducer;
