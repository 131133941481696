import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import LifetimeCapitalGains from './LifetimeCapitalGains';
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const TaxRateAssumptions = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showBranchExpenses = get(company, 'features', []).includes('cashflow_branch_expenses');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return (
    <React.Fragment>
      <div className={classes.header}>Tax Rate Assumptions </div>
      <Grid item xs={12}></Grid>
      <Grid item>
        <RadioGroup row aria-label="overrideTaxRate" name="overrideTaxRate" value={`${query.sellerManualTaxRate}`} onChange={e => {
          const sellerManualTaxRate = Boolean("true" == e.target.value);

          updateCfaQuery({ ...query, sellerManualTaxRate, })
        }}>

          <b className={classes.sub_heading}>Seller - Income</b>

          <FormControlLabel
            value="false"
            control={<Radio />}
            label={
              <span>{intl.formatMessage({ id: "calculators.cfa.dynamic_short" })}
                <Tooltip title={intl.formatMessage({ id: "calculators.cfa.dynamic_tt" })}>
                  <span>
                    {' '}<i className="fas fa-info-circle custom-tool-tip" />
                  </span>
                </Tooltip>
              </span>
            } />
          <FormControlLabel
            value="true"
            control={<Radio />}
            label={
              <span>
                {intl.formatMessage({ id: "calculators.cfa.manual_short" })}
                <Tooltip title={intl.formatMessage({ id: "calculators.cfa.manual_tt" })}>
                  <span>
                    {' '}<i className="fas fa-info-circle custom-tool-tip" />
                  </span>
                </Tooltip>
              </span>
            } />
          {query.sellerManualTaxRate && <React.Fragment>
            <dd>
              <FBPercentInput
                className={classes.percentage}
                value={query.sellerTaxRate}
                onChange={val => {
                  updateCfaQuery({ ...query, sellerTaxRate: val })
                }}
              />
            </dd>
          </React.Fragment>}

        </RadioGroup>
      </Grid>
      <LifetimeCapitalGains classes={classes} updateCfaQuery={updateCfaQuery} />
      {range(0, query.numberOfBuyers, 1).map(i => <React.Fragment>
        <Grid item xs={12}></Grid>
        <Grid item>
          <div>
            <>
              <Grid container spacing={2}>

                {showBranchExpenses && <React.Fragment>
                  <Grid item>
                    <RadioGroup row value={`${query[`buyer${i + 1}ManualTaxRate`]}`} onChange={e => {
                      const buyerManualTaxRate = Boolean("true" == e.target.value);

                      updateCfaQuery({ ...query, [`buyer${i + 1}ManualTaxRate`]: buyerManualTaxRate, })
                    }}>
                      <b className={classes.sub_heading}>Buyer {i + 1} - Income</b>
                      <FormControlLabel value="false"
                        control={<Radio />}
                        label={
                          <span>{intl.formatMessage({ id: "calculators.cfa.dynamic_short" })}
                            <Tooltip title={intl.formatMessage({ id: "calculators.cfa.dynamic_tt" })}>
                              <span>
                                {' '}<i className="fas fa-info-circle custom-tool-tip" />
                              </span>
                            </Tooltip>
                          </span>
                        }
                      />
                      <FormControlLabel value="true"
                        control={<Radio />}
                        label={
                          <span>
                            {intl.formatMessage({ id: "calculators.cfa.manual_short" })}
                            <Tooltip title={intl.formatMessage({ id: "calculators.cfa.manual_tt" })}>
                              <span>
                                {' '}<i className="fas fa-info-circle custom-tool-tip" />
                              </span>
                            </Tooltip>
                          </span>
                        } />
                      {query[`buyer${i + 1}ManualTaxRate`] && <React.Fragment>

                        <FBPercentInput
                          className={classes.percentage}
                          value={query[`buyer${i + 1}TaxRate`]}
                          onChange={val => {
                            updateCfaQuery({ ...query, [`buyer${i + 1}TaxRate`]: val })
                          }}
                        />
                      </React.Fragment>}
                    </RadioGroup>
                  </Grid>
                </React.Fragment>}
              </Grid>
            </>
          </div>
        </Grid>

      </React.Fragment>
      )}

    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(TaxRateAssumptions));