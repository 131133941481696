import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import FileTab from '../components/FileTab/FileTab';
import AgreementDatePopup from '../components/AgreementDatePopup/AgreementDatePopup';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import configureStore from '../store/findBobPublicStore';
import { IntlProvider } from 'react-intl';
import translations from '../lib/i18n/translations.json';

class FileTabContainer extends Component {
  state = {
    showPopup: false,
  } 
  
  render = () => {
    const { documents, dropMessage, continuity_builder_id, disablePopup, disableUpload, disableFileSelection } = this.props;
    const { showPopup } = this.state;

    return <ApolloProvider client={client}>
            <div>
              <FileTab 
                {...this.props}
                documents={documents}
                dropMessage={dropMessage}
                disableFileSelection={disableFileSelection}
                continuity_builder_id={continuity_builder_id}
                  disableUpload={disableUpload}
                  callBack={() => {
                    if (!disablePopup) {
                      this.setState({ showPopup: true })
                    }
                  }
                }
              />
              <AgreementDatePopup 
                displayPopup={showPopup}
                closePopup={() => {this.setState({showPopup: false})}}
                continuity_builder_id={continuity_builder_id}
              />
            </div>
          </ApolloProvider>

  }
}
  
FileTabContainer.propTypes = {
  documents: PropTypes.array,
  dropMessage: PropTypes.string.isRequired,
  continuity_builder_id: PropTypes.string.isRequired,
  disableFileSelection: PropTypes.bool,
}

FileTabContainer.defaultTypes = {
  disablePopup: false,
  disableFileSelection: false,
}

const FileTabWithContext = (props, railsContext) => {
  const store = configureStore({ ...props, company: { 
    host: railsContext.company_host,
    features: railsContext.features,
    file_import_disclaimer: railsContext.file_import_disclaimer,
    file_import_disclaimer_fr: railsContext.file_import_disclaimer_fr } });
  
  window.locale = railsContext.i18nLocale || 'en';
  return (<Provider store={store} props>
      <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
        <FileTabContainer {...props} />
      </IntlProvider>
    </Provider>);
} 

export default FileTabWithContext;
