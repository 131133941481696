import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { get } from 'lodash';
import { VictoryPie, VictoryTheme, VictoryLegend, VictoryLabel } from 'victory';

const RevenueMix = ({ recurring, nonRecurring, intl, colorScale }) => (
  <div className="revPie">
    <VictoryPie
      data={[ { x: 'Recurring Revenue', y: recurring }, { x: 'Non-Recurring Revenue', y: nonRecurring } ]}
      colorScale={colorScale}
      height={320}
      theme={VictoryTheme.material}
      style={{
        labels : { fontSize: 17 },
      }}
      innerRadius={80}
      labels={l => {
        const label = get(l, `data[${l.index}].y`);

        return label > 0 ? `${numeral(label * 100).format('0')} %` : '';
      }}
      labelComponent={<VictoryLabel />}
    />
    <VictoryLegend
      orientation="horizontal"
      colorScale={colorScale}
      height={40}
      data={[
        { name: intl.formatMessage({ id: 'bobcard.user_details.recurring_revenue' }), labels: { fontSize: 19 } },
        { name: intl.formatMessage({ id: 'bobcard.user_details.non_recurring_revenue' }), labels: { fontSize: 19 } },
      ]}
    />
  </div>
);

RevenueMix.propTypes = {
  recurring    : PropTypes.number,
  nonRecurring : PropTypes.number,
  intl         : PropTypes.object,
  colorScale   : PropTypes.array,
};

RevenueMix.defaultProps = {
  recurring    : 0,
  nonRecurring : 0,
  intl         : {},
  colorScale   : [ '#337ab7', 'rgb(0, 121, 107)', '#C83803', 'gold' ],
};

export default injectIntl(RevenueMix);
