import { connect } from 'react-redux';
import Insurer from '../components/Insurer/Insurer';
import NewFormDialog from '../components/Insurer/InsurerNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/insurersActionCreators';

const mapStateToProps = state => ({
  ...state,
  listData: state.insurers,
  title: state.intl.messages['admin_insurers.insurers'],
  NewFormDialog,
  CrudRow: Insurer,
});

export default connect(mapStateToProps, actions)(CrudTable);
