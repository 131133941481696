import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InputSlider from '../InputSlider/InputSlider';
import Select from '@material-ui/core/Select';
import ListItem from '@material-ui/core/ListItem';
import { connect } from 'react-redux';
import { get, clamp } from 'lodash';

class MatchPreference extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.match_preference,
      license_filter_array: (get(props, 'match_preference.license_filter', '') || '').split(','),
    };
  }

  render() {
    const { classes } = this.props;
    const {
      percent_fee_based,
      designation_weight,
      language_weight,
      distance_weight,
      product_mix_weight,
      proximity_max,
      years_practicing_min,
      years_practicing_max,
      annual_revenue_min,
      annual_revenue_max,
      years_with_company_min,
      years_with_company_max,
      license_filter_array,
      licenses,
      team_size_weight,
      number_of_client_wieght,
      professional_strengths_weight,
      length_of_service_weight,
      target_timeframe_weight,
      businiess_continuity_state_weight,
      part_of_team_weight,
      niche_market_weight,
      affiliation_weight,
      branch_weight,
      division_weight,
      aum_weight,
      aum_min,
      aum_max,
      avg_age_of_client_weight,
      avg_age_of_client_min,
      avg_age_of_client_max,
      number_of_suggested_matches,
      affiliate_network_weight,
    } = this.state;


    return (
      <Grid container>
        <Grid xs={5}>
          <h3>Display Preferences</h3>
          <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
            <b>Number of Suggested Matches</b> :
            <input
              className={classes.fullWidth}
              name="match_preference[number_of_suggested_matches]"
              value={number_of_suggested_matches}
              onChange={v => {
                let num_of_matches = v.target.value.replace(new RegExp(/[^?0-9.]/g), '');
                num_of_matches = clamp(parseInt(num_of_matches), 5, 25);

                if (isNaN(num_of_matches)) num_of_matches = 0;

                this.setState({ number_of_suggested_matches: num_of_matches })
              }

              }
            />
          </div>
          <br />
          <h3>Pre-Qualification Criteria</h3>
          <br />
          <b>Proximity</b> <br />
          <div className={classes.preQualification}>
            <div className={classes.pqItemTitle}>Max:</div>
            <div className={classes.pqItem}>
              <input
                className={classes.fullWidth}
                name="match_preference[proximity_max]"
                value={proximity_max}
                onChange={v => this.setState({ proximity_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
          </div>
          <br />
          <div>
            <input
              name="match_preference[license_filter]"
              hidden
              value={(license_filter_array || []).filter(e => `${e}`.length > 0).join(',')}
            />

            <b>Advisor Licenses </b>
            <div className={classes.preQualification}>
              <br />
              <Select
                fullWidth
                multiple
                value={license_filter_array}
                onClick={e => {
                  if (e.target.value) {
                    this.setState({ license_filter_array: e.target.value });
                  }
                }}>
                {(licenses || []).map(l => <ListItem value={l.name}>{l.name}</ListItem>)}
              </Select>
            </div>
          </div>
          <br />
          <b>Years Practicing</b> <br />
          <div className={classes.preQualification}>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Min:{' '}
              <input
                className={classes.fullWidth}
                name="match_preference[years_practicing_min]"
                value={years_practicing_min}
                onChange={v =>
                  this.setState({ years_practicing_min: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Max:
              <input
                className={classes.fullWidth}
                name="match_preference[years_practicing_max]"
                value={years_practicing_max}
                onChange={v =>
                  this.setState({ years_practicing_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
          </div>
          <br />
          <b>Annual Revenue</b> <br />
          <div className={classes.preQualification}>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Min:
              <input
                className={classes.fullWidth}
                name="match_preference[annual_revenue_min]"
                value={annual_revenue_min}
                onChange={v =>
                  this.setState({ annual_revenue_min: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Max:
              <input
                className={classes.fullWidth}
                name="match_preference[annual_revenue_max]"
                value={annual_revenue_max}
                onChange={v =>
                  this.setState({ annual_revenue_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
          </div>
          <br />
          <b>Years With Company</b> <br />
          <div className={classes.preQualification}>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Min:{' '}
              <input
                className={classes.fullWidth}
                name="match_preference[years_with_company_min]"
                value={years_with_company_min}
                onChange={v =>
                  this.setState({ years_with_company_min: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>

            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Max:
              <input
                className={classes.fullWidth}
                name="match_preference[years_with_company_max]"
                value={years_with_company_max}
                onChange={v => {
                  this.setState({ years_with_company_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') });
                }}
              />
            </div>
          </div>
          <b>Average Client Age</b> <br />
          <div className={classes.preQualification}>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Min:
              <input
                className={classes.fullWidth}
                name="match_preference[avg_age_of_client_min]"
                value={avg_age_of_client_min}
                onChange={v =>
                  this.setState({ avg_age_of_client_min: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Max:
              <input
                className={classes.fullWidth}
                name="match_preference[avg_age_of_client_max]"
                value={avg_age_of_client_max}
                onChange={v =>
                  this.setState({ avg_age_of_client_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
          </div>
          <br />
          <b>AUM</b> <br />
          <div className={classes.preQualification}>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Min:
              <input
                className={classes.fullWidth}
                name="match_preference[aum_min]"
                value={aum_min}
                onChange={v =>
                  this.setState({ aum_min: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
            <div className={[classes.preQualification, classes.fullWidth].join(', ')}>
              Max:
              <input
                className={classes.fullWidth}
                name="match_preference[aum_max]"
                value={aum_max}
                onChange={v =>
                  this.setState({ aum_max: v.target.value.replace(new RegExp(/[^?0-9.]/g), '') })}
              />
            </div>
          </div>
          <br />
        </Grid>
        <Grid xs={1} />
        <Grid xs={5}>
          <h3>Data Point Weighting</h3>
          <br />
          <InputSlider
            title="Percent Fee Based Weight"
            name="match_preference[percent_fee_based]"
            selector=".match_preference_percent_fee_based"
            maxValue={100}
            minValue={0}
            step={1}
            value={percent_fee_based || 0}
            onChange={v => this.setState({ percent_fee_based: v })}
          />
          <br />
          <InputSlider
            title="Designation Weight"
            name="match_preference[designation_weight]"
            selector=".match_preference_designation_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={designation_weight || 0}
            onChange={v => this.setState({ designation_weight: v })}
          />
          <br />
          <InputSlider
            title="Language Weight"
            name="match_preference[language_weight]"
            selector=".match_preference_language_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={language_weight || 0}
            onChange={v => this.setState({ language_weight: v })}
          />
          <br />
          <InputSlider
            title="Distance Weight"
            name="match_preference[distance_weight]"
            selector=".match_preference_distance_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={distance_weight || 0}
            onChange={v => this.setState({ distance_weight: v })}
          />
          <br />
          <InputSlider
            title="Product Mix Weight"
            name="match_preference[product_mix_weight]"
            selector=".match_preference_product_mix_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={product_mix_weight || 0}
            onChange={v => this.setState({ product_mix_weight: v })}
          />
          <br />
          <InputSlider
            title="Team Size Weight"
            name="match_preference[team_size_weight]"
            selector=".match_preference_team_size_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={team_size_weight || 0}
            onChange={v => this.setState({ team_size_weight: v })}
          />
          <br />
          <InputSlider
            title="Professional Strengths Weight"
            name="match_preference[professional_strengths_weight]"
            selector=".match_preference_professional_strengths_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={professional_strengths_weight || 0}
            onChange={v => this.setState({ professional_strengths_weight: v })}
          />
          <br />
          <InputSlider
            title="Length Of Service Weight"
            name="match_preference[length_of_service_weight]"
            selector=".match_preference_length_of_service_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={length_of_service_weight || 0}
            onChange={v => this.setState({ length_of_service_weight: v })}
          />
          <br />
          <InputSlider
            title="Number of Clients Weight"
            name="match_preference[number_of_client_wieght]"
            selector=".match_preference_number_of_client_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={number_of_client_wieght || 0}
            onChange={v => this.setState({ number_of_client_wieght: v })}
          />
          <br />
          <InputSlider
            title="Target Timeframe Weight"
            name="match_preference[target_timeframe_weight]"
            selector=".match_preference_target_timeframe_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={target_timeframe_weight || 0}
            onChange={v => this.setState({ target_timeframe_weight: v })}
          />
          <br />
          <InputSlider
            title="Business Contiuity Weight"
            name="match_preference[businiess_continuity_state_weight]"
            selector=".match_preference_businiess_continuity_state_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={businiess_continuity_state_weight || 0}
            onChange={v => this.setState({ businiess_continuity_state_weight: v })}
          />
          <br />
          <InputSlider
            title="Affiliate Network Weight"
            name="match_preference[affiliate_network_weight]"
            selector=".match_preference_affiliate_network_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={affiliate_network_weight || 0}
            onChange={v => this.setState({ affiliate_network_weight: v })}
          />
          <br />
          <InputSlider
            title="Part of Team Weight"
            name="match_preference[part_of_team_weight]"
            selector=".match_preference_part_of_team_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={part_of_team_weight || 0}
            onChange={v => this.setState({ part_of_team_weight: v })}
          />
          <br />
          <InputSlider
            title="Nice Market Weight"
            name="match_preference[niche_market_weight]"
            selector=".match_preference_niche_market_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={niche_market_weight || 0}
            onChange={v => this.setState({ niche_market_weight: v })}
          />
          <br />
          <InputSlider
            title="Affiliation Weight"
            name="match_preference[affiliation_weight]"
            selector=".match_preference_affiliation_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={affiliation_weight || 0}
            onChange={v => this.setState({ affiliation_weight: v })}
          />
          <br />
          <InputSlider
            title="Branch Weight"
            name="match_preference[branch_weight]"
            selector=".match_preference_branch_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={branch_weight || 0}
            onChange={v => this.setState({ branch_weight: v })}
          />
          <br />
          <InputSlider
            title="Division Weight"
            name="match_preference[division_weight]"
            selector=".match_preference_division_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={division_weight || 0}
            onChange={v => this.setState({ division_weight: v })}
          />
          <br />
          <InputSlider
            title="AUM Weight"
            name="match_preference[aum_weight]"
            selector=".match_preference_aum_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={aum_weight || 0}
            onChange={v => this.setState({ aum_weight: v })}
          />
          <br />
          <InputSlider
            title="AVG Age of Client Weight"
            name="match_preference[avg_age_of_client_weight]"
            selector=".match_preference_avg_age_of_client_weight_weight"
            maxValue={100}
            minValue={0}
            step={1}
            value={avg_age_of_client_weight || 0}
            onChange={v => this.setState({ avg_age_of_client_weight: v })}
          />
          <br />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state, p) => ({
  ...state,
  ...p,
});

const styles = {
  preQualification: {
    display: 'flex',
    padding: 4,
  },
  pqItemTitle: {
    flexGrow: 1,
  },
  pqItem: {
    flexGrow: 4,
  },
  fullWidth: {
    width: '100%',
    paddingRight: 8,
  },
};

export default connect(mapStateToProps)(withStyles(styles)(MatchPreference));
