import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PayoutGrid from '../components/PayoutGrid/PayoutGrid';

const PayoutGridQuery= gql`
  query cashFlowCompensationLookups{
    cashFlowCompensationLookups{
      id
      comp_max
      result
    }
  }
`;

const PayoutGridContainer = () => {
  const [rows, setRows] = React.useState([]);

  return (
    <Query
      query={PayoutGridQuery}
      onCompleted={data => {
        setRows(data.cashFlowCompensationLookups);
      }}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <CircularProgress />;
        if (error) return <div>{error.message}</div>;
        if (data.cashFlowCompensationLookups) {
          return (
            <Grid>
              <Paper>
                <PayoutGrid refetch={refetch} payoutGrids={rows} setRows={setRows} />
              </Paper>
            </Grid>
          );
        }
        return <div />;
      }}
    </Query>
  );
}

export default PayoutGridContainer;
