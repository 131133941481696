import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import SocialButtons from '../components/SocialButtons/SocialButtons';

const SocialButtonsContainer = props => <SocialButtons {...props} />;

export default SocialButtonsContainer;
