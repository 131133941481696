import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';


const FamilyMemberFas = ({ classes, user, obfuscateView, intl }) => {
  if (user) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.family_member_fas"/></strong>
        {!obfuscateView && <p>{get(user, 'family_member_fas') ? intl.formatMessage({ id: 'profile_page.yes_discretionary_investment_models' }) : intl.formatMessage({ id: 'profile_page.no_discretionary_investment_models' })}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
}

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default injectIntl(withStyles(styles)(FamilyMemberFas));
