import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpressInterestButtonContainer from './ExpressInterestButtonContainer';
import SaveButton from '../ProfileDrawerSideBarComponents/SaveButton';
import SubscribeButton from './SubscribeButton';
import StatusSection from '../ProfileDrawerSideBarComponents/StatusSection';
import ContinuityPlanInPlace from '../ProfileDrawerSideBarComponents/ContinuityPlanInPlace';
import Languages from '../ProfileDrawerSideBarComponents/Languages';
import RecognitionLevel from '../ProfileDrawerSideBarComponents/RecognitionLevel';
import DefinedInvestmentStyle from '../ProfileDrawerSideBarComponents/DefinedInvestmentStyle';
import DefinedServiceModel from '../ProfileDrawerSideBarComponents/DefinedServiceModel';
import TeamSize from '../ProfileDrawerSideBarComponents/TeamSize';
import NicheMarkets from '../ProfileDrawerSideBarComponents/NicheMarkets';
import SideSpecializedBusinesses from '../ProfileDrawerSideBarComponents/SideSpecializedBusinesses';
import SideDiscretionaryInvestmentModels from '../ProfileDrawerSideBarComponents/SideDiscretionaryInvestmentModels';
import SideWhatsYourWhy from '../ProfileDrawerSideBarComponents/SideWhatsYourWhy';
import AskMeAbout from '../ProfileDrawerSideBarComponents/AskMeAbout';
import StartDealRoomButton from '../ProfileDrawerSideBarComponents/StartDealRoomButton';
import { get, isEmpty } from 'lodash';
import Affiliations from '../ProfileDrawerSideBarComponents/Affiliations';

const ExpressInterest = ({
  scrollTo,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  displaySocialButtons,
  classes,
  updateNotes,
  notes,
  agentWordForCompany,
  obfuscateView,
  updateProfile,
  expressThroughManager,
  companyBusinessType,
  hideSideBarElements,
}) => {
  const showCreateDealButton =
    get(getCurrentUser, 'company.business_type') === 'wealth_rj' &&
    get(getCurrentUser, 'motivation') === 'succession' &&
    get(getCurrentUser, 'approval_state') === 'approved' &&
    get(getCurrentUser, 'can_match');
  const showRolodex =
    get(getCurrentUser, 'motivation') === 'succession' || get(getCurrentUser, 'motivation') === 'growth';
  const stylesheetName = get(getCurrentUser, 'company.stylesheet_name');
  const enabledRolodex = get(getCurrentUser, 'company.enabled_rolodex');
  const unsubscribed = !get(getCurrentUser, 'is_fb_subscriber');
  const hasDeal = get(getCurrentUser, 'has_deal');
  const dealRoomName = get(getCurrentUser, 'company.deal_room_branded_name');
  const allowUserAffiliations = get(getCurrentUser, 'company.allow_user_affiliations');
  const affiliationsNotEmpty = !isEmpty(get(user, 'affiliations'));
  return (
    <div>
      <div className={classes.rightColumn}>
        {!hideSideBarElements && (
          <div>
            {showCreateDealButton &&
            !hasDeal && <StartDealRoomButton stylesheetName={stylesheetName} dealRoomName={dealRoomName} />}
            {showCreateDealButton &&
            hasDeal &&
            enabledRolodex && (
              <SaveButton
                user={user}
                userId={user.id}
                userName={user.fb_user_name}
                display={displaySocialButtons}
                noteCount={user.note_count}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                fromBobCard
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
                notes={notes}
                agentWordForCompany={agentWordForCompany}
              />
            )}
            {companyHasNewMessages &&
            companyHasNetworking &&
            !unsubscribed && (
              <ExpressInterestButtonContainer
                scrollTo={scrollTo}
                user={user}
                currentUserHasLimitedAccess={currentUserHasLimitedAccess}
                hasInterestedUsers={hasInterestedUsers}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                currentCompanySellerWord={currentCompanySellerWord}
                currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
                getCurrentUser={getCurrentUser}
                disableMessageBtn={disableMessageBtn}
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
                updateProfile={updateProfile}
                expressInterest
                expressThroughManager={expressThroughManager}
              />
            )}
            {unsubscribed && <SubscribeButton />}
            <br />
            <StatusSection
              showCreateDealButton={showCreateDealButton}
              companyHasNewMessages={companyHasNewMessages}
              companyHasNetworking={companyHasNetworking}
              user={user}
              obfuscateView={obfuscateView}
              currentUser={getCurrentUser}
              currentCompanySellerWord={currentCompanySellerWord}
              expressInterest
              expressThroughManager={expressThroughManager}
              unsubscribed={unsubscribed}
              rolodexlMessage={showCreateDealButton && hasDeal && enabledRolodex}
              dealRoomName={dealRoomName}
            />
            <br />
            {enabledRolodex && (
              <SaveButton
                user={user}
                userId={user.id}
                userName={user.fb_user_name}
                display={showRolodex}
                noteCount={user.note_count}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                fromBobCard
                marketplaceOnly={marketplaceOnly}
                updateNotes={updateNotes}
                notes={notes}
                agentWordForCompany={agentWordForCompany}
              />
            )}
            <hr className={classes.bar} />
          </div>
        )}
        <ContinuityPlanInPlace user={user} obfuscateView={obfuscateView} />
        <Languages user={user} obfuscateView={obfuscateView} />
        <RecognitionLevel user={user} obfuscateView={obfuscateView} />
        {companyBusinessType !== 'p_and_c' && <DefinedInvestmentStyle user={user} obfuscateView={obfuscateView} />}
        <DefinedServiceModel user={user} obfuscateView={obfuscateView} />
        {allowUserAffiliations && affiliationsNotEmpty && (
          <Affiliations user={user} obfuscateView={obfuscateView} />
        )}
        <TeamSize user={user} obfuscateView={obfuscateView} />
        {!isEmpty(user.primary_markets) && <NicheMarkets user={user} obfuscateView={obfuscateView} />}
        {companyBusinessType === 'wealth_rj' && (
          <div>
            <AskMeAbout user={user} obfuscateView={obfuscateView} />
            {!isEmpty(user.specialized_businesses) && (
              <SideSpecializedBusinesses user={user} obfuscateView={obfuscateView} />
            )}
            <SideDiscretionaryInvestmentModels user={user} obfuscateView={obfuscateView} />
            {!isEmpty(user.why_values) && <SideWhatsYourWhy user={user} obfuscateView={obfuscateView} />}
          </div>
        )}
      </div>
    </div>
  );
};

ExpressInterest.propTypes = {
  agentWordForCompany                  : PropTypes.string.isRequired,
  classes                              : PropTypes.object.isRequired,
  companyBusinessType                  : PropTypes.string.isRequired,
  companyHasNetworking                 : PropTypes.bool.isRequired,
  companyHasNewMessages                : PropTypes.bool.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool,
  currentUserHasSellingTransitionGoals : PropTypes.bool,
  disableMessageBtn                    : PropTypes.bool,
  displaySocialButtons                 : PropTypes.bool,
  expressThroughManager                : PropTypes.bool,
  getCurrentUser                       : PropTypes.object.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  marketplaceOnly                      : PropTypes.bool,
  notes                                : PropTypes.array.isRequired,
  obfuscateView                        : PropTypes.bool.isRequired,
  scrollTo                             : PropTypes.func.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  user                                 : PropTypes.object.isRequired,
};

ExpressInterest.defaultProps = {
  expressThroughManager                : false,
  displaySocialButtons                 : false,
  marketplaceOnly                      : false,
  disableMessageBtn                    : false,
  currentUserHasSellingTransitionGoals : false,
  currentUserHasLimitedAccess          : false,
};

const styles = {
  rightColumn : {
    marginLeft  : 25,
    marginRight : '30%',
  },
  bar         : {
    width : 230,
  },
};

export default withStyles(styles)(ExpressInterest);
