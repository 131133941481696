import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, get, lowerCase, snakeCase } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

const UpdateCalibrationSessionInventoryUser = gql`
  mutation updateCalibrationSessionInventoryUser(
    $id: ID!,
    $manual_risk_of_loss: String,
    $manual_impact_of_loss: String,
    $manual_pvp: String,
    $calculated_risk_of_loss: String,
    $calculated_impact_of_loss: String,
    $calculated_pvp: String,
    ) {
    updateCalibrationSessionInventoryUser(
      id: $id,
      manual_risk_of_loss: $manual_risk_of_loss,
      manual_impact_of_loss: $manual_impact_of_loss,
      manual_pvp: $manual_pvp,
      calculated_risk_of_loss: $calculated_risk_of_loss,
      calculated_impact_of_loss: $calculated_impact_of_loss,
      calculated_pvp: $calculated_pvp,
      )
  }
`;


export default class CalibrationSelect extends PureComponent {
  constructor (props) {
    super(props);

    this.state = { value: props.inventoryUser[props.fieldName] };
  }

  componentWillReceiveProps (nextProps) {
    const { fieldName } = this.props;
    const { inventoryUser } = this.props;

    if (inventoryUser[fieldName] !== nextProps.inventoryUser[fieldName]) {
      this.setState({ value: nextProps.inventoryUser[fieldName] });
    }
  }

  render () {
    const { inventoryUser, options, fieldName, refetch, disabled } = this.props;
    const { value } = this.state;

    return (
      <Mutation mutation={UpdateCalibrationSessionInventoryUser}>
        {updateInventoryUser => (
          <Select
            disabled={disabled}
            value={value}
            onChange={e => {
              this.setState({ value: e.target.value });

              updateInventoryUser({ variables: { id: inventoryUser.id, [snakeCase(fieldName)]: e.target.value } });
              refetch();
            }}>
            {map(options, o => <MenuItem value={o.value}>{o.name}</MenuItem>)}
          </Select>
        )}
      </Mutation>
    );
  }
}

CalibrationSelect.propTypes = {
  inventoryUser : PropTypes.object.isRequired,
  options       : PropTypes.array.isRequired,
  fieldName     : PropTypes.string.isRequired,
  refetch       : PropTypes.func.isRequired,
  disabled      : PropTypes.bool,
};

CalibrationSelect.defaultProps = {
  disabled : false,
};
