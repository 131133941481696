import PropTypes from 'prop-types';
import React from 'react';
import GmvMomPageContainer from '../../containers/GmvMomPageContainer';

const GmvMomPageFull = ({ classes, data }) => {
  return (
    <div>
      <GmvMomPageContainer />
    </div>
  );
};

GmvMomPageFull.propTypes = {
  classes : PropTypes.object,
};

export default GmvMomPageFull;
