import React from 'react';
import { Grid } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';
import RangeSliderContainer from './RangeSliderContainer';
import InputSlider from '../../InputSlider/InputSlider';
import { max } from 'lodash';
import { sanitizeNumber, getMotivation, intlCurrencyConfig } from '../../../lib/findBobUtils';


const RangeSlider = ({ object, fieldName, label, onChange, max, prefix='$', step=10000}) => (
    <Grid container spacing={1}>
      <Grid
        item
        xs={9}
        style={{marginTop: 0}}>
        <span style={{ fontWeight: 'bold' }}>
          {label}
        </span>
      </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={max}
              minValue={0}
              value={object[fieldName] || 0}
              formatLabel={value => ``}
              step={step}
              onChange={e => {
                onChange({target: { value: parseFloat(e), name: fieldName}});
              }}
            />
          </RangeSliderContainer>
        </Grid>
      <Grid item xs={5}>
        <CurrencyInput
          name={fieldName}
          id="outlined-name"
          value={object[fieldName] || 0}
          allowDecimals={false}
          prefix={prefix}
          max={max}
          onValueChange={(value, name) => {onChange({target:{value: Math.min(value, max), name}})}}
        />
      </Grid>
    </Grid>
);

export default RangeSlider;