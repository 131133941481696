import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const NoOptionsMessage = ({ selectProps, innerProps }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}>
    {selectProps.inputValue ? 'No agents found.' : 'Enter text to begin search'}
  </Typography>
);

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
};

NoOptionsMessage.defaultProps = {
  selectProps: {},
  innerProps: {},
};

export default NoOptionsMessage;
