import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { upperCase } from 'lodash';
import Card from '@material-ui/core/Card';
import { FormattedMessage } from 'react-intl';
import FileIcon, { defaultStyles } from 'react-file-icon';
import DeleteFileDialog from './DeleteFileDialog';

export const FileCard = ({ document, deleteDoc, classes }) => {
  const ext = document.name.split('.').pop();
  return (
    <Card className={classes.card} key={`doc_${document.id}`}>
      <div className={classes.icon}>
        <FileIcon size={32} extension={ext} {...defaultStyles[ext]} />
      </div>
      <div className={classes.cardContent}>
        <a href={document.download_url} target="_blank" className={classes.docLink}>
          {document.name}
        </a>
        <div className={classes.details}>
          <FormattedMessage id="file_tab.added_by" />{document.uploaded_by.name} on {document.created_at}<br />
          <FormattedMessage id="file_tab.category" /> <b>{upperCase(document.category || '')}</b>
        </div>
      </div>
      <DeleteFileDialog
        className={classes.deleteButton}
        deleteAction={() => {
          deleteDoc({ variables: { id: document.id } });
        }}
      />
    </Card>
  );
};

FileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteDoc: PropTypes.func.isRequired,
  document: PropTypes.object,
};

FileCard.defaultProps = {
  document: {},
};

const styles = {
  card: {
    padding: 20,
    fontSize: 16,
    margin: '4px 0',
    color: '#333',
    display: 'flex',
  },
  deleteButton: {
    float: 'right',
    marginRight: 4,
    flex: 1,
  },
  docLink: {
    paddingTop: 4,
    color: '#333',
    verticalAlign: 'text-top',
  },
  cardContent: {
    flex: 10,
    alignSelf: 'center',
  },
  details: {
    fontSize: 14,
    color: '#777',
  },
  icon: {
    alignSelf: 'center',
    border: '#777 1px solid',
    borderRadius: '50%',
    padding: 8,
    margin: 8,
  },
};

export default withStyles(styles)(FileCard);
