import React from 'react';
import ProfilePicture from '../ProfilePicture/ProfilePicture.jsx';
import { UserProfileQueries } from '../../containers/UserProfileContainer';
import { graphql, ApolloConsumer, Query } from 'react-apollo';
import { get } from 'lodash';

const displayLocation = (city, province, county, countryForCompany, companyHasCounty, show_city) => {
  if (countryForCompany === 'us' && companyHasCounty && !show_city) {
    if (county) {
      return ` ${county} County, ${province}`;
    } else if (city) {
      return `${province}`;
    }
  } else if (city) {
    return `${city}, ${province}`;
  }
};

const clickedHeadline = async (client, user, updateSelectedProfileDrawer) => {
  const result = await client.query({
    query       : UserProfileQueries,
    variables   : { userName: user.user_name },
    fetchPolicy : 'network-only',
  });
  updateSelectedProfileDrawer(get(result, 'data'));
};

const Minicard = ({ user, countryForCompany, companyHasCounty, updateSelectedProfileDrawer, client }) => (
  <aside id="featured-properties">
    <div className="property small">
      <div className="property-image">
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            clickedHeadline(client, user, updateSelectedProfileDrawer);
          }}>
          <ProfilePicture pictureUrl={user.profile_picture} userName={user.user_name} userIsStudent={user.is_student} userCompanyEnabledStudentProfiles={user.company.enabled_student_profiles} disableClick userCompanyStudentWord={user.company.student_word} />
        </a>
      </div>
      <div className="info">
        <div>
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              clickedHeadline(client, user, updateSelectedProfileDrawer);
            }}>
            <h4 className="alignleft" style={{ width: '100%' }}>
              #{user.user_name}
            </h4>
          </a>
        </div>
        <figure>
          <span
            style={{ display: 'inline', width: '100%' }}
            className="fa fa-map-marker "
          />
          &nbsp;{displayLocation(
            user.city,
            user.province,
            user.county,
            countryForCompany,
            companyHasCounty,
            user.show_city
          )}
        </figure>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => {
            clickedHeadline(client, user, updateSelectedProfileDrawer);
          }}>
          <div className="tag price">View Profile</div>
        </a>
      </div>
    </div>
  </aside>
);

export default Minicard;
