import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';
import { injectIntl } from 'react-intl';
import CheckButton from '../CheckButton/CheckButton';
import { uniq, toLower, capitalize, snakeCase } from 'lodash';

const togglelicenses = (e, availableAdvisorLicenses, updateAction, userAdvisorLicenses, iirocLicenseOptions) => {
  let advisor_licenses = userAdvisorLicenses;
  const license = e.target.value;
  const index = findIndex(advisor_licenses, o => o === license);
  advisor_licenses = [ ...advisor_licenses ];
  if (index > -1) {
    advisor_licenses.splice(index, 1);
    if (iirocLicenseOptions && license === 'IIROC') {
      advisor_licenses = removeAmpOptions(advisor_licenses);
    }
  } else {
    advisor_licenses.push(license);
  }
  updateAction({ advisor_licenses });
};

const removeAmpOptions = advisor_licenses => {
  const ampIndex = findIndex(advisor_licenses, o => o === 'AMP');
  if (ampIndex > -1) {
    advisor_licenses.splice(ampIndex, 1);
  }
  const optiondIndex = findIndex(advisor_licenses, o => o === 'Options');
  if (optiondIndex > -1) {
    advisor_licenses.splice(optiondIndex, 1);
  }
  return advisor_licenses;
};

const alphaSortLicenses = licenses => {
  const sorted = licenses.sort((a, b) => {
    return a.localeCompare(b, undefined, {
      numeric     : true,
      sensitivity : 'base',
    });
  });

  return sorted;
};

const checkIfIiroc = (iirocLicenseOptions, userAdvisorLicenses, license) => {
  if (iirocLicenseOptions) {
    if (!userAdvisorLicenses.includes('IIROC')) {
      return license === 'AMP' || license === 'Options';
    }
  }
  return false;
};

const renderAdvisorLicenses = (
  availableAdvisorLicenses,
  updateAction,
  userAdvisorLicenses,
  stylesheetName,
  iirocLicenseOptions,
  intl
) => {
  return alphaSortLicenses(availableAdvisorLicenses).map(license => (
    <CheckButton
      stylesheetName={stylesheetName}
      label={intl.formatMessage({id: `sign_up_pages.licenses.${snakeCase(license)}`})}
      checked={findIndex(userAdvisorLicenses, o => o === license) > -1}
      value={license}
      disable={checkIfIiroc(iirocLicenseOptions, userAdvisorLicenses, license)}
      onClick={e => togglelicenses(e, availableAdvisorLicenses, updateAction, userAdvisorLicenses, iirocLicenseOptions)}
    />
  ));
};

const AdvisorLicenseComponent = ({
  availableAdvisorLicenses,
  updateAction,
  userAdvisorLicenses,
  stylesheetName,
  iirocLicenseOptions,
  intl,
}) => {
  return (
    <div>
      {renderAdvisorLicenses(
        availableAdvisorLicenses,
        updateAction,
        userAdvisorLicenses,
        stylesheetName,
        iirocLicenseOptions,
        intl
      )}
    </div>
  );
};

export default injectIntl(AdvisorLicenseComponent);
