import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import TextField from '@material-ui/core/TextField';
import { get, compact } from 'lodash';

const drawerWidth = 240;

const styles = {
  container   : {
    height      : '100%',
    borderRight : '1px solid #eee',
    padding     : 10,
  },
  drawerPaper : {
    position  : 'relative',
    width     : drawerWidth,
    float     : 'left',
    minHeight : 'calc(100vh - 220px)',
  },
  header      : {
    height       : 64,
    borderBottom : '1px solid #eee',
  },
  title       : {
    padding : 4,
  },
  input       : {
    background : '#E8E8E8',
    margin     : 4,
    padding    : 6,
  },
  label       : {
    verticalAlign : 'bottom',
  },
  checkbox    : {
    color       : '#E8E8E8',
    '&$checked' : {
      color : '#E8E8E8',
    },
  },
};

export class TalentFilter extends PureComponent {
  constructor (props) {
    super(props);

    props.updateAction({
      name             : null,
      province         : null,
      transition_goals : compact([ get(props, 'opportunityType.filter_criteria', null) ]),
      risk_of_loss     : [],
      impact_of_loss   : [],
    });

    this.updateName = debounce(this.updateName, 250);
    this.updateProvince = debounce(this.updateProvince, 250);
  }

  updateName = e => {
    this.props.updateAction({ name: e.target.value });
  };

  updateProvince = e => {
    this.props.updateAction({ province: e.target.value });
  };

  toggleTransitionGoal = e => {
    const goal = e.target.value;
    const { filter } = this.props;

    const i = filter.transition_goals.indexOf(goal);

    if (i > -1) {
      filter.transition_goals.splice(i, 1);
    } else {
      filter.transition_goals.push(goal);
    }
    this.props.updateAction({ transition_goals: filter.transition_goals });
  };

  toggleRisks = e => {
    const risk = e.target.value;
    const { filter } = this.props;

    const i = filter.risk_of_loss.indexOf(risk);

    if (i > -1) {
      filter.risk_of_loss.splice(i, 1);
    } else {
      filter.risk_of_loss.push(risk);
    }
    this.props.updateAction({ risk_of_loss: filter.risk_of_loss });
  };
  toggleImpactOfLoss = e => {
    const impact = e.target.value;
    const { filter } = this.props;

    const i = filter.impact_of_loss.indexOf(impact);

    if (i > -1) {
      filter.impact_of_loss.splice(i, 1);
    } else {
      filter.impact_of_loss.push(impact);
    }
    this.props.updateAction({ impact_of_loss: filter.impact_of_loss });
  };

  render () {
    const { classes, filter } = this.props;
    if (!filter.transition_goals) return <div />;
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper : classes.drawerPaper,
        }}
        anchor="left">
        <div className={classes.header} />
        <h4 className={classes.title}>Filter Results</h4>
        <div className={classes.container}>
          <div>
            <b className={classes.label}>Name</b>
            <TextField
              type="text"
              variant="filled"
              fullWidth
              onChange={e => {
                e.persist();
                this.updateName(e);
              }}
            />
          </div>
          <div>
            <b>Province</b>
            <TextField
              type="text"
              variant="filled"
              fullWidth
              onChange={e => {
                e.persist();
                this.updateProvince(e);
              }}
            />
          </div>
          <br />
          <div>
            <b>Transition Goals</b>
            <br />
            {[
              'Buying',
              'Merging',
              'Partnering',
              'Business Continuity',
              'Find a Successor',
              'Become a Successor',
              'Becoming a Junior Partner',
              'Selling my practice',
              'Selling part of my practice',
            ].map(c => (
              <div>
                <Checkbox
                  checked={filter.transition_goals.indexOf(c) > -1}
                  classes={{
                    root    : classes.checkbox,
                    checked : classes.checked,
                  }}
                  value={c}
                  onClick={this.toggleTransitionGoal}
                  key={c}
                />{' '}
                {c}
              </div>
            ))}
          </div>
          <div>
            <b>Risk of Loss</b>
            <br />
            {[ 'High', 'Medium', 'Low' ].map(c => (
              <div>
                <Checkbox
                  checked={filter.risk_of_loss.indexOf(c) > -1}
                  classes={{
                    root    : classes.checkbox,
                    checked : classes.checked,
                  }}
                  value={c}
                  onClick={this.toggleRisks}
                  key={c}
                />{' '}
                {c}
              </div>
            ))}
          </div>
          <div>
            <b>Impact of Loss</b>
            <br />
            {[ 'High', 'Medium', 'Low' ].map(c => (
              <div>
                <Checkbox
                  checked={filter.impact_of_loss.indexOf(c) > -1}
                  classes={{
                    root    : classes.checkbox,
                    checked : classes.checked,
                  }}
                  value={c}
                  onClick={this.toggleImpactOfLoss}
                  key={c}
                />{' '}
                {c}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    );
  }
}

TalentFilter.propTypes = {
  updateAction : PropTypes.func.isRequired,
  classes      : PropTypes.object.isRequired,
};

export default withStyles(styles)(TalentFilter);
