import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Mutation } from 'react-apollo';
import { get } from 'lodash';
import { deletePipelineMutation } from './PipelineQueries';

class DeletePipelineDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { pipeline } = this.props;
    const { open } = this.state;
    const has_cards = get(pipeline, 'pipeline_cards', []).length > 0;

    if (has_cards) return <span />;
    return (
      <div>
        <IconButton variant="outlined" color="primary" onClick={this.handleClickOpen}>
          <DeleteIcon style={{ color: 'rgba(255, 0, 0, 0.3)' }} />
        </IconButton>
        {open && (
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Would you like to delete this pipeline?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">This pipeline will be deleted.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Mutation mutation={deletePipelineMutation}>
                {deletePipeline => (
                  <Button
                    onClick={() => {
                      deletePipeline({ variables: { id: pipeline.id } })
                        .then(() => {
                          window.location.href = '/admin/pipelines';
                        })
                        .catch(e => {
                          // eslint-disable-next-line
                          console.log(e);
                        });
                    }}
                    color="primary"
                    autoFocus>
                    Yes Delete
                  </Button>
                )}
              </Mutation>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

DeletePipelineDialog.propTypes = {
  pipeline : PropTypes.object,
};

DeletePipelineDialog.defaultProps = {
  pipeline : {},
};

export default DeletePipelineDialog;
