import React, { Component } from 'react'
import Ticker from '../tickers/Ticker';
import themes from '../../lib/themes';
import numeral from 'numeral';

const textColors = {'-1': '#ff6961',
  '0': '#fff',
  '1': '#77dd77',};

const textColor = (value, arrow) => {
  if(!arrow)
    return '#fff';

  return textColors[Math.sign(value.toString())]
};

const renderArrow = (value) => {
  if(value == 0) {
    return <span />;
  }
  
  return (
    <span style={{fontSize: 14, verticalAlign: 'middle', lineHeight: '32px'}}>
      <i className={`fas fa-arrow-${ Math.sign(value) > 0 ? 'up' : 'down'}`} />&nbsp;
    </span>);
};

const chooseTickerBackground = (ticker, i, defaultColors) => {
  if(ticker.color) {
    return ticker.color;
  }

  return defaultColors[ i % defaultColors.length];
}

const DashboardMarketplaceTicker = (props, railsContext) => {
  const colors = themes[railsContext.theme_name].palette.graph;
  const locale = railsContext.i18nLocale;

  return (
    <div style={{marginLeft: 10,}}>
      <div className="newsticker" style={{background: '#fff !important'}}>
        <Ticker className="cool" isNewsTicker slideSpeed={30}>
          <div style={{display: 'flex', width: props.tickers.length * 200, background: "white"}}>
            {props.tickers.map((ticker, i) => {
              return (<div style={{height: 50, color: textColor(ticker.value, ticker.arrow), background: chooseTickerBackground(ticker,i, colors), width: 200, padding: '5px 10px',}}>
                <div style={{fontSize: 12, fontWeight: 'bold', textTransform: 'uppercase',}}>{locale === 'fr' ? ticker.french_label : ticker.label}</div>
                
                <div
                  style={{fontSize: 24, bottom: 0, right: 0, position: 'relative', float: 'right', textShadow: '#999 -1px 1px', fontWeight: 'bolder'}}>
                  {ticker.arrow && renderArrow(ticker.value) }
                  {numeral(ticker.value).format(locale === 'fr' ? ticker.french_format : ticker.format )}
                </div>
              </div>)
            })}
          </div>
        </Ticker>
      </div>
    </div>
  );
}

export default DashboardMarketplaceTicker;