import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { toggleHiddenForUsers } from './InventoryUserQueries';

class InventoryUserHideUserDialog extends React.Component {
  state = {
    open      : false,
    hideUsers : true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleHideUsers = () => {
    const { hideUsers } = this.state;
    this.setState({ hideUsers: !hideUsers });
  };

  render () {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, hideUsers } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Are you sure you want to un-hide ${selected.length} users?`;
    let bodyText = 'These users will be able to log in and other users will be able to see their profiles.';
    let hiddenText = 'un-hidden';

    if (open) {
      const selections = selected.map(s => find(data, [ 'id', s ]));
      validSelections = filter(selections, [ 'has_user', true ]);
      invalidSelections = filter(selections, [ 'has_user', false ]);

      if (hideUsers) {
        message = `Are you sure you want to hide ${selected.length} users?`;
        bodyText = 'These users will NOT be able to log in and these users will not be visible in search.';
        hiddenText = 'hidden';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Hide/Unhide User">
          <IconButton aria-label="Hide/Unhide User">
            <EditIcon onClick={this.handleClickOpen} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={hideUsers} onClick={this.toggleHideUsers} /> Hide Users
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot be ${hiddenText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables : { usersToHide: validSelections.map(iu => iu.user.id), hidden: hideUsers },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              disabled={validSelections.length === 0}
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserHideUserDialog.propTypes = {
  selected          : PropTypes.array.isRequired,
  changePage        : PropTypes.func.isRequired,
  data              : PropTypes.object.isRequired,
  mutate            : PropTypes.func.isRequired,
  loadBulkItemsList : PropTypes.func.isRequired,
};

export default graphql(toggleHiddenForUsers)(InventoryUserHideUserDialog);
