import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { get, isEmpty, flowRight } from 'lodash';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import TagSearchFieldContainer from '../../containers/TagSearchFieldContainer';
import NineBoxInput from '../NineBoxInput/NineBoxInput';
import { connect } from 'react-redux';
import TransitionGoalModularSelectorContainer from '../../containers/TransitionGoalModularSelectorContainer';

export const UpdateManagersAssessment = gql`
  mutation editInventoryUser(
    $id: ID!
    $name: String
    $email: String
    $birth_date: String
    $enterprise_id: String
    $organization: String
    $company_id: Int
    $start_date: String
    $rev_yield: Int
    $clients: Int
    $total_premium: Int
    $total_assets: Int
    $key_asset: Boolean
    $risk_of_loss_num: String
    $reason_for_loss: String
    $planning_preference: String
    $impact_of_loss_num: String
    $province: String
    $manager_email: String
    $pvp_value: String
  ) {
    editInventoryUser(
      id: $id
      email: $email
      name: $name
      birth_date: $birth_date
      enterprise_id: $enterprise_id
      organization: $organization
      company_id: $company_id
      start_date: $start_date
      rev_yield: $rev_yield
      clients: $clients
      total_premium: $total_premium
      total_assets: $total_assets
      key_asset: $key_asset
      risk_of_loss_num: $risk_of_loss_num
      reason_for_loss: $reason_for_loss
      planning_preference: $planning_preference
      impact_of_loss_num: $impact_of_loss_num
      province: $province
      manager_email: $manager_email
      pvp_value: $pvp_value
    )
  }
`;

export const addIuTag = gql`
  mutation addIuTag($id: ID!, $tag: String) {
    addIuTag(id: $id, tag: $tag)
  }
`;

export const removeIuTag = gql`
  mutation removeIuTag($id: ID!, $tag: String) {
    removeIuTag(id: $id, tag: $tag)
  }
`;

export const editTransitionGoals = gql`
  mutation editTransitionGoals($id: ID!, $transition_goals: [String!], $initiate_approval_flow: Boolean) {
    editTransitionGoals(id: $id, transition_goals: $transition_goals, initiate_approval_flow: $initiate_approval_flow)
  }
`;

class ManagersAssessment extends React.Component {
  constructor (props) {
    super(props);
    const { inventoryUser } = this.props;
    this.state = {
      id                 : inventoryUser.id,
      email              : inventoryUser.email,
      name               : inventoryUser.name,
      birth_date         : inventoryUser.birth_date,
      enterprise_id      : inventoryUser.enterprise_id,
      organization       : inventoryUser.organization,
      company_id         : inventoryUser.company_id,
      start_date         : inventoryUser.start_date,
      rev_yield          : inventoryUser.rev_yield,
      clients            : inventoryUser.clients,
      total_premium      : inventoryUser.total_premium,
      total_assets       : inventoryUser.total_assets,
      key_asset          : inventoryUser.key_asset,
      risk_of_loss       : inventoryUser.risk_of_loss,
      impact_of_loss     : inventoryUser.impact_of_loss,
      risk_of_loss_num   : inventoryUser.risk_of_loss_num,
      impact_of_loss_num : inventoryUser.impact_of_loss_num,
      reason_for_loss    : inventoryUser.reason_for_loss,
      planning_preference: inventoryUser.planning_preference,
      pvp_value          : inventoryUser.pvp_value,
      manager_email      : inventoryUser.manager_email,
      province           : inventoryUser.province,
      city               : inventoryUser.city,
      phone              : inventoryUser.phone,
      transition_goals   : inventoryUser.transition_goals,
      tag_list           : inventoryUser.tag_list,
      headline           : inventoryUser.headline,
      bio                : inventoryUser.bio,
      languages          : inventoryUser.languages,
      tags               : inventoryUser.tags,
      marketplace_status : inventoryUser.marketplace_status,
      iu_tags            : inventoryUser.iu_tags,
      limited_access     : inventoryUser.limited_access,
      hidden             : inventoryUser.hidden,
      is_obfuscated      : inventoryUser.is_obfuscated,
      is_customer        : inventoryUser.is_customer,
      tag_id             : null,
      openSnackBar       : false,
      tagname            : '',
      manager            : {
        ...get(inventoryUser, 'manager', {}),
        label : get(inventoryUser, 'name'),
        value : get(inventoryUser, 'id'),
      },
      manager_iu_id      : get(inventoryUser, 'manager.id', ''),
      addTagLine         : false,
    };
  }

  componentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.id !== nextProps.inventoryUser.id) {
      this.setState({ ...nextProps.inventoryUser });
    }
  }

  editInventoryUser = () => {
    const { mutate, inventoryUser, updateInventoryUser, updateAction, openSnack, refetch } = this.props;
    const {
      id,
      email,
      name,
      birth_date,
      enterprise_id,
      organization,
      company_id,
      start_date,
      rev_yield,
      clients,
      total_premium,
      total_assets,
      key_asset,
      risk_of_loss,
      risk_of_loss_num,
      impact_of_loss,
      impact_of_loss_num,
      reason_for_loss,
      planning_preference,
      province,
      manager_email,
      marketplace_status,
      manager,
      manager_iu_id,
      pvp_value,
    } = this.state;
    mutate({
      variables : {
        id,
        email,
        name,
        birth_date,
        enterprise_id,
        organization,
        company_id,
        start_date,
        rev_yield,
        clients,
        total_premium,
        total_assets,
        key_asset,
        risk_of_loss,
        risk_of_loss_num,
        impact_of_loss,
        impact_of_loss_num,
        reason_for_loss,
        planning_preference,
        province,
        manager_email,
        marketplace_status,
        manager,
        manager_iu_id,
        pvp_value,
      },
    })
      .then(() => {
        updateInventoryUser({ ...inventoryUser, ...this.state });
        updateAction({ user: { ...inventoryUser, ...this.state } });
        refetch();
        openSnack();
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  clickCheckbox = () => {
    const { key_asset } = this.state;
    this.setState(
      {
        key_asset : !key_asset,
      },
      () => {
        this.editInventoryUser();
      }
    );
  };

  editTransitionGoals = reInitiateApproval => {
    const {
      inventoryUser,
      editTransitionGoalsMutation,
      openSnack,
      updateInventoryUser,
      updateAction,
      user,
    } = this.props;
    const { id } = this.state;
    this.setState(
      {
        transition_goals : user.transition_goals,
        user             : { ...inventoryUser.user, transition_goals: user.transition_goals },
      },
      () => {
        editTransitionGoalsMutation({
          variables : {
            id,
            transition_goals       : user.transition_goals,
            initiate_approval_flow : reInitiateApproval === 'true',
          },
        }).then(r => {
          if (r.data.editTransitionGoals) {
            updateInventoryUser({ ...inventoryUser, ...this.state });
            updateAction({ user: { ...inventoryUser, ...this.state } });
            openSnack();
          }
        });
      }
    );
  };

  editTags = (currentTags, newTags) => {
    if (newTags.length > currentTags.length) {
      if (newTags[newTags.length - 1].__isNew__) {
        const newTag = newTags[newTags.length - 1];
        this.addTag('', { ...newTag, name: newTag.label });
      } else {
        const currentTagsNames = currentTags.map(n => n.name);
        const newTagsNames = newTags.map(n => n.name);
        const tagToAdd = newTagsNames.filter(x => !currentTagsNames.includes(x));
        this.addTag('', newTags.find(x => x.name === tagToAdd[0]));
      }
    }
    if (newTags.length < currentTags.length) {
      const currentTagsNames = currentTags.map(n => n.name);
      const newTagsNames = newTags.map(n => n.name);
      const tagToRemove = currentTagsNames.filter(x => !newTagsNames.includes(x));
      this.removeTag('', currentTags.find(x => x.name === tagToRemove[0]));
    }
  };

  addTag = (tagText, tagObject) => {
    const { addIuTagMutation, openSnack, inventoryUser, updateInventoryUser, refetch } = this.props;
    const { id, iu_tags } = this.state;
    let tag = tagText;
    if (!isEmpty(tagObject)) {
      tag = tagObject.name;
    }
    if (tag) {
      addIuTagMutation({
        variables : {
          id,
          tag,
        },
      })
        .then(() => {
          if (isEmpty(tagObject)) {
            iu_tags.push({ name: tag, value: tag });
          } else {
            iu_tags.push(tagObject);
          }
          this.setState({ tagname: '', iu_tags });
          updateInventoryUser({ ...inventoryUser, ...this.state });
          refetch();
          openSnack();
        })
        .catch(error => {
          this.setState({ error });
          // eslint-disable-next-line
          console.log('there was an error sending the query', error);
        });
    }
  };

  removeTag = (tagNameToDelete, tagObject) => {
    const { removeIuTagMutation, openSnack, inventoryUser, updateInventoryUser, refetch } = this.props;
    const { id, iu_tags } = this.state;
    let tag = tagNameToDelete;
    if (!isEmpty(tagObject)) {
      tag = tagObject.name;
    }
    removeIuTagMutation({
      variables : {
        id,
        tag,
      },
    })
      .then(() => {
        const index = iu_tags.map(e => e.name).indexOf(tag);
        if (index > -1) {
          iu_tags.splice(index, 1);
        }
        this.setState({ tagname: '', iu_tags });
        updateInventoryUser({ ...inventoryUser, ...this.state });
        refetch();
        openSnack();
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  onKeyDown = (e, value) => {
    const { tagname } = this.state;
    if (e === 'Enter' && tagname) {
      this.addTag(value, {});
    }
  };
  
  render () {
    const { classes, inventoryUser, user, assignManager, tags, company } = this.props;
    const {
      impact_of_loss_num,
      key_asset,
      reason_for_loss,
      planning_preference,
      risk_of_loss_num,
      iu_tags,
      tagname,
      manager,
      addTagLine,
    } = this.state;
    const searchTags = (tags || []).map(t => (t = { ...t, label: t.name, value: t.name }));

    return (
      <div>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Manager:</InputLabel>
            <ManagerSearchField
              value={{
                ...manager,
                label : get(manager, 'name'),
                value : get(manager, 'id'),
              }}
              onChange={v => {
                this.setState({
                  manager       : {
                    ...v,
                    label : get(v, 'name'),
                    value : get(v, 'id'),
                  },
                  manager_iu_id : v.id,
                });
                assignManager(inventoryUser.email, v.id);
                this.editInventoryUser();
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Risk of Loss:</InputLabel>
            <Select
              label="Risk of Loss:"
              name="Risk of Loss"
              value={risk_of_loss_num}
              disabled={ get(company, 'feature_types', []).includes('automatically_calibrate_inventory_users') }
              onChange={e => {
                this.setState({ risk_of_loss_num: e.target.value }, () => {
                  this.editInventoryUser();
                });
              }}
              InputProps={{
                classes : { root: classes.root, underline: classes.underline },
              }}
              fullWidth>
              <MenuItem value={null}>
                <em>None Selected</em>
              </MenuItem>
              <MenuItem value="risk_low">Low</MenuItem>
              <MenuItem value="risk_medium">Medium</MenuItem>
              <MenuItem value="risk_high">High</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Impact of Loss:</InputLabel>
            <Select
              label="Impact of Loss:"
              name="Impact of Loss"
              disabled={ get(company, 'feature_types', []).includes('automatically_calibrate_inventory_users') }
              value={impact_of_loss_num}
              onChange={e => {
                this.setState({ impact_of_loss_num: e.target.value }, () => {
                  this.editInventoryUser();
                });
              }}
              InputProps={{
                classes : { root: classes.root, underline: classes.underline },
              }}
              fullWidth>
              <MenuItem value={null}>
                <em>None Selected</em>
              </MenuItem>
              <MenuItem value="impact_low">Low</MenuItem>
              <MenuItem value="impact_medium">Medium</MenuItem>
              <MenuItem value="impact_high">High</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Reason for Leaving:</InputLabel>
            <Select
              label="Reason for Leaving:"
              name="Reason for Leaving"
              value={reason_for_loss}
              
              onChange={e => {
                this.setState({ reason_for_loss: e.target.value }, () => {
                  this.editInventoryUser();
                });
              }}
              InputProps={{
                classes : { root: classes.root, underline: classes.underline },
              }}
              fullWidth>
              <MenuItem value={null}>
                <em>None Selected</em>
              </MenuItem>
              <MenuItem value="retirement">Retirement</MenuItem>
              <MenuItem value="loss of license">Loss of License</MenuItem>
              <MenuItem value="disability">Disability</MenuItem>
              <MenuItem value="death">Death</MenuItem>
            </Select>
          </Grid>
          {inventoryUser.has_user && (
            <Grid item xs={12}>
              <Grid container spacing={0} className={classes.dataLeft}>
                <Grid item xs={8}>
                  <div>
                    <b>Transition Goals: </b>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <center>
                    <TransitionGoalModularSelectorContainer
                      adminView
                      editTransitionGoals={e => this.editTransitionGoals(e)}
                    />
                  </center>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item xs={12}>
            <InputLabel className={classes.label}>Planning Preference:</InputLabel>
            <Select
              label="Planning Preference:"
              name="Planning Preference"
              value={planning_preference}
              onChange={e => {
                this.setState({ planning_preference: e.target.value }, () => {
                  this.editInventoryUser();
                });
              }}
              InputProps={{
                classes : { root: classes.root, underline: classes.underline },
              }}
              fullWidth>
              <MenuItem value={null}>
                <em>None Selected</em>
              </MenuItem>
              <MenuItem value="find_partner">Find Partner</MenuItem>
              <MenuItem value="become_partner">Become Partner</MenuItem>
              <MenuItem value="no_plan_preference">No Plan Preference</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} className={classes.dataLeft}>
              <Grid item xs={8}>
                <div>
                  <b>9-Box: </b>
                </div>
              </Grid>
              <Grid item xs={12}>
                <center>
                  <NineBoxInput
                    iUser={inventoryUser}
                    updateUser={this.editInventoryUser}
                    updateUserState={(user, callback) => this.setState({ ...user }, callback)}
                  />
                </center>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} className={classes.dataLeft}>
              <Grid item xs={4}>
                <div>
                  <b>Key Asset: </b>
                </div>
              </Grid>
              <Grid item xs={8}>
                <Checkbox style={{ margin: '-15px' }} checked={key_asset} onChange={this.clickCheckbox} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.dataLeft}>
              <b>Tags: </b>
            </div>
          </Grid>
          <Grid item xs={12}>
            {addTagLine && (
              <TextField
                id="Add Tag"
                label="Add Tag"
                value={tagname}
                InputProps={{
                  classes : { underline: classes.underline },
                }}
                fullWidth
                margin="dense"
                onChange={e => {
                  this.setState({ tagname: e.target.value });
                }}
                onBlur={e => this.addTag(e.target.value, {})}
                onKeyDown={e => this.onKeyDown(e.key, e.target.value)}
              />
            )}
            <InputLabel className={classes.label}>Search or add New:</InputLabel>
            <TagSearchFieldContainer
              isMulti
              tags={searchTags}
              value={(iu_tags || []).map(t => (t = { ...t, label: t.name, value: t.name }))}
              onChange={v => {
                this.setState({ iu_tags: v });
                this.editTags(iu_tags, v);
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ManagersAssessment.propTypes = {
  inventoryUser       : PropTypes.object.isRequired,
  classes             : PropTypes.object.isRequired,
  tags                : PropTypes.object.isRequired,
  refetch             : PropTypes.func.isRequired,
  mutate              : PropTypes.func.isRequired,
  updateInventoryUser : PropTypes.func.isRequired,
  updateAction        : PropTypes.func.isRequired,
  addIuTagMutation    : PropTypes.func.isRequired,
  removeIuTagMutation : PropTypes.func.isRequired,
  assignManager       : PropTypes.func.isRequired,
  openSnack           : PropTypes.bool,
};

ManagersAssessment.defaultProps = {
  openSnack : false,
};

const styles = {
  prodmixborder  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 310,
    width        : 230,
  },
  prodmixtitle   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
  },
  prodmixcontent : {
    textAlign : 'center',
    margin    : 7,
  },
  title          : {
    color : 'rgba(0, 0, 0, 0.54)',
  },
  dataLeft       : {
    color     : 'rgba(0, 0, 0, 0.54)',
    fontSize  : 12,
    marginTop : 12,
  },
  label          : {
    fontSize : 10,
  },
};

const mapStateToProps = state => ({ user: state.user });

export default flowRight(
  graphql(UpdateManagersAssessment),
  graphql(addIuTag, { name: 'addIuTagMutation' }),
  graphql(removeIuTag, { name: 'removeIuTagMutation' }),
  graphql(editTransitionGoals, { name: 'editTransitionGoalsMutation' })
)(withStyles(styles)(connect(mapStateToProps)(ManagersAssessment)));
