import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get } from 'lodash';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import BenchCard from './BenchCard';

class NomAttSelect extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      nominate_as : null,
      ready_in    : null,
      confirmOpen : false,
    };
  }

  validateAtt = att => {
    const { handleClose, clickNominate, user } = this.props;
    const attributes = att;
    attributes.profile_picture = get(user, 'profile_picture', '');
    attributes.has_user = get(user, 'has_user', false);
    if (att.nominate_as !== null && att.ready_in !== null) {
      clickNominate(attributes);
      handleClose();
      this.confirmClose();
      this.setState({
        nominate_as : null,
        ready_in    : null,
      });
    }
  };

  validateNom = att => {
    if (att.nominate_as === null || att.ready_in === null) {
      return true;
    }
    return false;
  };

  handleClose = () => {
    this.setState({ confirmOpen: false });
  };

  confirmClose = () => {
    const { confirmOpen } = this.state;
    if (confirmOpen) this.setState({ confirmOpen: false });
  };

  render () {
    const { user, classes, open, handleClose, inventoryUser } = this.props;
    const { nominate_as, ready_in, confirmOpen } = this.state;
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={classes.card}>
          <DialogTitle className={classes.nameSpace}>
            <b>You are about to nominate</b>
            <br />
          </DialogTitle>
          <DialogContent>
            <Grid container justify="center">
              <div className={classes.smallbench}>
                <BenchCard user={user} bench={false} showSelect={false} />
              </div>
            </Grid>
            <hr style={{ height: '2px', border: 'none', color: '#E3E3E3', backgroundColor: '#E3E3E3' }} />
            <Grid container spacing={8}>
              <Grid item xs={6}>
                <div className={classes.dataLeft}>
                  <p className={classes.dropdown}>
                    <b>As a: </b>
                  </p>
                  <br />
                  <FormControl>
                    <Select
                      value={nominate_as}
                      onChange={e => {
                        this.setState({ nominate_as: e.target.value });
                      }}
                      displayEmpty
                      name="nominate_as">
                      <MenuItem value={null}>
                        <em>None Selected</em>
                      </MenuItem>
                      <MenuItem value="successor">Successor</MenuItem>
                      <MenuItem value="continuity_partner">Continuity Partner</MenuItem>
                      <MenuItem value="buyer">Buyer</MenuItem>
                      <MenuItem value="partner_for">Partner For</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.dataLeft}>
                  <p className={classes.dropdown}>
                    <b>Ready In: </b>
                  </p>

                  <br />
                  <FormControl>
                    <Select
                      value={ready_in}
                      onChange={e => {
                        this.setState({ ready_in: e.target.value });
                      }}
                      displayEmpty
                      name="ready_in">
                      <MenuItem value={null}>
                        <em>None Selected</em>
                      </MenuItem>
                      <MenuItem value="ready">Ready</MenuItem>
                      <MenuItem value="1-2 years">1-2 years</MenuItem>
                      <MenuItem value="3+ years">3+ years</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
            <hr style={{ height: '2px', border: 'none', color: '#E3E3E3', backgroundColor: '#E3E3E3' }} />
            <DialogTitle>
              <b>For user</b>
            </DialogTitle>
            <Grid container justify="center">
              <div className={classes.smallbench}>
                <BenchCard user={inventoryUser} bench={false} showSelect={false} />
              </div>
            </Grid>
          </DialogContent>
          <hr />
          <Button onClick={handleClose} color="primary" style={{ float: 'left', marginLeft: 15 }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              this.setState({ confirmOpen: true });
            }}
            variant="contained"
            color="primary"
            disabled={this.validateNom(this.state)}
            style={{ float: 'right', marginRight: 15 }}>
            Nominate User
          </Button>
        </div>
        <Dialog open={confirmOpen} onClose={this.confirmClose} aria-labelledby="form-dialog-title">
          <DialogTitle>
            <b>Confirmation</b>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Nominate <b>{user.name}</b> for <b>{inventoryUser.name}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.confirmClose} color="primary" style={{ float: 'left', marginLeft: 15 }}>
              Close
            </Button>
            <Button
              onClick={() => {
                this.validateAtt(this.state);
                this.confirmClose();
              }}
              variant="contained"
              color="primary"
              style={{ float: 'right', marginRight: 15 }}>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
}

NomAttSelect.propTypes = {
  handleClose   : PropTypes.func.isRequired,
  clickNominate : PropTypes.func.isRequired,
  user          : PropTypes.string.isRequired,
  classes       : PropTypes.object.isRequired,
  open          : PropTypes.bool.isRequired,
  inventoryUser : PropTypes.object.isRequired,
};

const styles = {
  card       : {
    width  : 520,
    height : 570,
    margin : 20,
  },
  searchBar  : {
    margin : 20,
  },
  suggested  : {
    width : '100%',
  },
  paper      : {
    backgroundColor : 'red',
    boxShadow       : 'none',
    overflow        : 'hidden',
  },
  smallbench : {
    width  : '70%',
    border : '6px solid #F0F0F0',
  },
  dataLeft   : {
    float   : 'left',
    padding : 6,
    display : 'flex',
  },
  dropdown   : {
    marginRight : '20px',
  },
};

export default withStyles(styles)(NomAttSelect);
