import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import AdminWrapper from '../../components/AdminWrapper/AdminWrapper';
import NewOpportunityDetails from '../../components/OpportunityAdmin/NewOpportunityDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from '../../actions/opportunityActionCreators';
import qs from 'query-string';

const OpportunityTypesQuery = gql`
  query OpportunityTypesQuery($inventoryUserID: ID!) {
    getOpportunityTypes {
      id
      name
      icon_url
      background_url
    }
    getInventoryUser(id: $inventoryUserID) {
      id
      name
      birth_date
      province
      rev_yield
      clients
      total_premium
      total_assets
      key_asset
      risk_of_loss
      impact_of_loss
      profile_picture
      transition_goals
      has_user
      user {
        headline
        bio
        annual_revenue
        recurring_revenue
        assets_under_management
        number_of_clients
      }
    }
  }
`;

const CreateOpportunity = gql`
  mutation createOpportunity(
    $title: String!
    $description: String!
    $expiry_date: String
    $requirements: String
    $user_id: ID!
    $opportunity_type_id: ID!
  ) {
    createOpportunity(
      title: $title
      description: $description
      expiry_date: $expiry_date
      requirements: $requirements
      user_id: $user_id
      opportunity_type_id: $opportunity_type_id
    )
  }
`;

const NewOpportunityDetailsContainer = props => {
  const params = qs.parse(props.location.search);
  const { inventoryUserID } = params;

  return (
    <AdminWrapper title="Create Opportunity - Details">
      <Query query={OpportunityTypesQuery} variables={{ inventoryUserID: inventoryUserID }}>
        {({ loading, error, data }) => {
          if (loading) return <CircularProgress />;
          if (error) return `Error!: ${error}`;

          if (!props.opportunity.user) {
            props.updateOpportunity({ user: data.getInventoryUser });
          }
          return <NewOpportunityDetails {...props} opportunityTypes={data.getOpportunityTypes} />;
        }}
      </Query>
    </AdminWrapper>
  );
};

const mapStateToProps = state => ({
  state,
  opportunity : get(state.opportunities, 'opportunity', {}),
  status      : get(state.opportunities, 'status', null),
  intl        : state.intl,
});

const withRedux = connect(mapStateToProps, actions)(NewOpportunityDetailsContainer);
export default graphql(CreateOpportunity)(withRedux);
