import React from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-redux';
import configureStore from '../store/findBobPublicStore';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import qs from 'query-string';
import MatchProfile from '../components/MatchProfile/MatchProfile';
import { MatchProfileQuery } from '../components/MatchProfile/MatchProfileQuery';
import client from '../lib/apolloClient';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

class MatchProfileContainer extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedUser        : {},
      getDealInterest     : {},
      availableStrengths  : [],
      getCurrentUser      : {},
      currentUserInfoAuth : false,
      countryForCompany   : 'ca',
      companyHasCounty    : false,
      availableProducts   : [],
      companyBusinessType : '',
    };
  }

  setUser = async deal_interest_id => {
    const result = await client.query({
      query       : MatchProfileQuery,
      variables   : { dealInterestId: deal_interest_id },
      fetchPolicy : 'network-only',
    });
    this.setState({ selectedUser: true, ...get(result, 'data', {}) });
  };

  componentDidMount = () => {
    const { deal_interest_id } = this.props;
    const params = qs.parse(location.search);
    const { deal_int_id } = params;
    if (deal_int_id && `${deal_int_id}` === `${deal_interest_id}`) {
      this.setUser(deal_int_id);
    }
  };

  render () {
    const { deal_interest_id, headline, deadline_passed, deal_interest_name, theme_name } = this.props;
    const {
      selectedUser,
      getDealInterest,
      availableStrengths,
      getCurrentUser,
      currentUserInfoAuth,
      countryForCompany,
      companyHasCounty,
      availableProducts,
      agentWordForCompany,
      companyBusinessType,
    } = this.state;
    let theme = themes[theme_name];
    return (
      <ApolloProvider client={client}>
        <MuiThemeProvider theme={createMuiTheme(theme)}>
          <CssBaseline />
          <div>
            <Button
              style={{ color: '#1396e2', fontSize: '24px', textTransform: 'none' }}
              onClick={() => this.setUser(deal_interest_id)}>
              {headline}
            </Button>
            {selectedUser && (
              <MatchProfile
                user={get(getDealInterest, 'inventory_user.user')}
                dealInterest={getDealInterest}
                availableStrengths={availableStrengths}
                currentUser={getCurrentUser}
                currentUserInfoAuth={currentUserInfoAuth}
                countryForCompany={countryForCompany}
                companyHasCounty={companyHasCounty}
                availableProducts={availableProducts}
                agentWordForCompany={agentWordForCompany}
                deadLinePassed={deadline_passed}
                companyBusinessType={get(getCurrentUser, 'company.business_type', '')}
                stylesheetName={get(getCurrentUser, 'company.stylesheet_name', '')}
                dealInterestName={deal_interest_name}
              />
            )}
          </div>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }
}

MatchProfileContainer.propTypes = {
  deal_interest_id : PropTypes.number.isRequired,
  headline         : PropTypes.string.isRequired,
  deadline_passed  : PropTypes.bool.isRequired,
};

const MatchProfileContainerWithContext = (props, railsContext) => {
  const store = configureStore({ ...props, company: { 
    host: railsContext.company_host,
    features: railsContext.features,
    file_import_disclaimer: railsContext.file_import_disclaimer } });

  window.locale = railsContext.i18nLocale || 'en';
  
  return (<Provider store={store} props>
      <MatchProfileContainer {...props} theme_name={railsContext.theme_name} />
    </Provider>);
};
export default MatchProfileContainerWithContext;
