import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { map, get, lowerCase } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { Mutation } from 'react-apollo';
import { toggleCalibrationSessionUserMutation } from './CalibrationQuery';

const GreenCheckbox = withStyles({
  root    : {
    color       : green[400],
    '&$checked' : {
      color : green[600],
    },
  },
  checked : {},
})(props => <Checkbox color="default" {...props} />);

export default class CalibrationUserToggle extends PureComponent {
  constructor (props) {
    super(props);
    this.state = { value: get(props, 'inventoryUser.calibration_session_inventory_user.completed', false) };
  }

  onComponentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.candidate_session_completed === nextProps.inventoryUser.candidate_session_completed) {
      this.setState({ value: Boolean(nextProps.inventoryUser.candidate_session_completed) });
    }
  }

  render () {
    const { inventoryUser, session, refetch, disabled } = this.props;
    const { value } = this.state;

    return (
      <Mutation mutation={toggleCalibrationSessionUserMutation}>
        {toggleSessionUser => (
          <GreenCheckbox
            color="default"
            disabled={disabled}
            type="checkbox"
            id={`candidate_session_completed_${inventoryUser.id}`}
            checked={Boolean(value)}
            onChange={e => {
              toggleSessionUser({
                variables : {
                  id                : session.id,
                  inventory_user_id : inventoryUser.id,
                  calibration_session_id: session.id,
                },
              }).then(() => {
                this.setState({ value: !value });
                refetch();
              });
            }}
            style={{
              fontSize     : 14,
              borderRadius : '50%',
              background   : 'rgba(0, 0, 0, 0.2)',
            }}
          />
        )}
      </Mutation>
    );
  }
}

CalibrationUserToggle.propTypes = {
  disabled      : PropTypes.bool,
  inventoryUser : PropTypes.object.isRequired,
  session       : PropTypes.object.isRequired,
  refetch       : PropTypes.func.isRequired,
};

CalibrationUserToggle.defaultProps = {
  disabled : false,
};
