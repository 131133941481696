import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash'
import SaveButton from '../ProfileDrawerSideBarComponents/SaveButton';
import StatusSection from '../ProfileDrawerSideBarComponents/StatusSection';
import ContinuityPlanInPlace from '../ProfileDrawerSideBarComponents/ContinuityPlanInPlace';
import Languages from '../ProfileDrawerSideBarComponents/Languages';
import RecognitionLevel from '../ProfileDrawerSideBarComponents/RecognitionLevel';
import DefinedInvestmentStyle from '../ProfileDrawerSideBarComponents/DefinedInvestmentStyle';
import DefinedServiceModel from '../ProfileDrawerSideBarComponents/DefinedServiceModel';
import TeamSize from '../ProfileDrawerSideBarComponents/TeamSize';
import NicheMarkets from '../ProfileDrawerSideBarComponents/NicheMarkets';

const Disabled = ({
  companyHasNewMessages,
  companyHasNetworking,
  user,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  getCurrentUser,
  marketplaceOnly,
  displaySocialButtons,
  classes,
  updateNotes,
  notes,
  agentWordForCompany,
  obfuscateView,
  companyBusinessType,
}) => {
  const dealRoomName = get(getCurrentUser, 'company.deal_room_branded_name');
  return (
    <div>
      <div className={classes.rightColumn}>
        <br />
        <StatusSection
          companyHasNewMessages={companyHasNewMessages}
          companyHasNetworking={companyHasNetworking}
          user={user}
          obfuscateView={obfuscateView}
          currentUser={getCurrentUser}
          currentCompanySellerWord={currentCompanySellerWord}
          dealRoomName={dealRoomName}
          disabled
        />
        <br />
        <SaveButton
          user={user}
          userId={user.id}
          userName={user.user_name}
          display={displaySocialButtons}
          noteCount={user.note_count}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          fromBobCard
          marketplaceOnly={marketplaceOnly}
          updateNotes={updateNotes}
          notes={notes}
          agentWordForCompany={agentWordForCompany}
        />
        <hr className={classes.bar} />
        <ContinuityPlanInPlace user={user} obfuscateView={obfuscateView} />
        <Languages user={user} obfuscateView={obfuscateView} />
        <RecognitionLevel user={user} obfuscateView={obfuscateView} />
        {companyBusinessType !== 'p_and_c' && <DefinedInvestmentStyle user={user} obfuscateView={obfuscateView} />}
        <DefinedServiceModel user={user} obfuscateView={obfuscateView} />
        <TeamSize user={user} obfuscateView={obfuscateView} />
        <NicheMarkets user={user} obfuscateView={obfuscateView} />
      </div>
    </div>
  );
};

Disabled.propTypes = {
  user: PropTypes.object.isRequired,
  obfuscateView: PropTypes.bool.isRequired,
  companyHasNewMessages: PropTypes.bool.isRequired,
  companyHasNetworking: PropTypes.bool.isRequired,
  updateBobCards: PropTypes.func.isRequired,
  loadBobCardList: PropTypes.func.isRequired,
  currentCompanySellerWord: PropTypes.bool.isRequired,
  getCurrentUser: PropTypes.object.isRequired,
  marketplaceOnly: PropTypes.bool.isRequired,
  displaySocialButtons: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  updateNotes: PropTypes.func.isRequired,
  notes: PropTypes.array.isRequired,
  agentWordForCompany: PropTypes.string.isRequired,
  companyBusinessType: PropTypes.string.isRequired,
};

const styles = {
  rightColumn: {
    marginLeft: 25,
    marginRight: '30%',
  },
  bar: {
    width: 230,
  },
};

export default withStyles(styles)(Disabled);
