/* eslint-disable import/prefer-default-export */

import { UPDATE_EDUCATIONAL_CONTENTS, UPDATE_EDUCATIONAL_CONTENT } from '../constants/educationalContentConstants';

export const updateEducationalContents = contents => ({
  type     : UPDATE_EDUCATIONAL_CONTENTS,
  contents,
});
export const updateEducationalContent = content => ({
  type    : UPDATE_EDUCATIONAL_CONTENT,
  content,
});
