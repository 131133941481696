import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { BatchHttpLink } from 'apollo-link-batch-http';
import { createUploadLink } from 'apollo-upload-client';
import ReactOnRails from 'react-on-rails';
import fetch from 'unfetch';

const options = {
  uri         : '/graphql',
  credentials : 'same-origin',
  headers     : {
    'X-CSRF-Token' : ReactOnRails.authenticityToken(),
  },
  fetch       : fetch,
};

const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(options),
  new HttpLink(options)
);

const client = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  link  : httpLink,
  cache : new InMemoryCache(),
});

export default client;
