import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { updateDateFilter } from '../../actions/dateRangeFilterActionCreators';

import ActivityReport from '../../components/Reports/ActivityReport';

const ActivityReportQuery = gql`
  query activityReportQuery {
    getManagers {
      id
      name
      is_admin
      inventory_user {
        pipeline_activities {
          id
          note
          activityType {
            id
            name
          }
          activityDate
        }
        completed_tasks {
          name
          status
          statusChangedDate
          activityType {
            name
          }
        }
      }
    }
    getActivityTypes {
      name
    }
  }
`;

export const ActivityReportContainer = props => {
  return (
    <div>
      <div>
        <Query query={ActivityReportQuery}>
          {({ loading, error, data, refetch }) => {
            if (error) return `Error!: ${error}`;

            return <ActivityReport data={data} refetch={refetch} {...props} />;
          }}
        </Query>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  startDate : state.dateRangeFilter.sendStart.format('YYYY-MM-DD'),
  endDate   : state.dateRangeFilter.sendEnd.format('YYYY-MM-DD'),
});

const mapDispatchToProps = {
  updateDateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityReportContainer);
