import React from 'react';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { toUpper } from 'lodash';

const StartDealRoomButton = ({ classes, stylesheetName, dealRoomName }) => {
  return (
    <div>
      <Button
        variant="contained"
        size="large"
        style={{ color: '#fff', width: 200, fontSize: '0.8rem' }}
        href={`/deal/create`}>
        {<FormattedMessage id="profile_page.start_a" values={{dealRoomName}}/>}
      </Button>
    </div>
  );
};

const styles = {
  button              : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed             : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default withStyles(styles)(StartDealRoomButton);
