import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TasksQuery } from '../components/Pipelines/PipelineQueries';
import PipelineTaskTab from '../components/Pipelines/PipelineTaskTab';

export const PipelineTaskTabContainer = ({ pipelineCardId }) => {
  if (!pipelineCardId) return <div />;
  return (
    <Query query={TasksQuery} variables={{ id: pipelineCardId }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <CircularProgress />;
        if (error) return <div>{error.message}</div>;
        if (data.pipelineCard) {
          let inv_user = get(data, 'getCurrentUser.inventory_user', {});
          inv_user = { ...inv_user, label: inv_user.name, value: inv_user.id };
          return (
            <PipelineTaskTab
              refetch={refetch}
              pipelineCard={data.pipelineCard}
              activityTypes={data.getActivityTypes}
              currentInventoryUser={inv_user}
            />
          );
        }
        return <div />;
      }}
    </Query>
  );
};

PipelineTaskTabContainer.propTypes = {
  pipelineCardId : PropTypes.number,
};

export default PipelineTaskTabContainer;
