import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { sum, merge, values, get } from 'lodash';
import { spotlightElement } from '../../../lib/findBobUtils';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { STEP_NUMBERS } from '../../SignUpWizard/SignUpWizard';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';
import RetailWealthProductMixSliders from './RetailWealthProductMixSliders';
import PAndCProductMixSliders from './PAndCProductMixSliders';
import CurrencyInput from 'react-currency-input-field';

const styles = () => ({
  formGroup           : {
    border       : '1px solid #dadada',
    padding      : '12px',
    borderRadius : '2px',
  },
  groupTitle          : {
    paddingLeft : '6px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  ppiBackgroundColor  : {
    backgroundColor : '#7399c6',
    '&:hover'       : {
      background : '#949ca',
    },
  },
});

export const canadianRetailWealthAumList = [ 'mutual_funds_aum', 'segregated_funds_aua', 'equity', 'gic_fixed_income', 'insurance' ];

export const pAndCProductAumList = [ 
  'home_ifp', 
  'commercial_ifp', 
  'private_products_ifp', 
  'crop_hail_with_wind_ifp', 
  'private_products_ifp', 
  'life_ifp',
  'auto_ifp',
  'mcpi_ifp',
  'crop_hail_ifp',
  'farm_insurance_ifp',
  'replant_supplement_ifp',
  'health_ifp',
];

export const aumList = [
  'mutual_funds_aum',
  'securities_aum',
  'annuities_aua',
  'segregated_funds_aua',
  'equity',
  'gic_fixed_income',
  'insurance',
];
export const insuranceList = [
  'life_ifp',
  'home_ifp',
  'auto_ifp',
  'commercial_ifp',
  'health_ifp',
  'group_ifp',
  'mcpi_ifp',
  'private_products_ifp',
  'crop_hail_ifp',
  'crop_hail_with_wind_ifp',
  'farm_insurance_ifp',
  'price_products_ifp',
  'replant_supplement_ifp',
];

export const ifpOptions = intl => [
  { value: 0, label: 'N/A' },
  { value: 0.5, label: intl.formatMessage({ id: 'sign_up_pages.500_k' }) },
  { value: 1, label: intl.formatMessage({ id: 'sign_up_pages.500_1m' }) },
  { value: 5, label: intl.formatMessage({ id: 'sign_up_pages.1m_5m' }) },
  { value: 25, label: intl.formatMessage({ id: 'sign_up_pages.5m_25m' }) },
  { value: 50, label: intl.formatMessage({ id: 'sign_up_pages.25m_50m' }) },
  { value: 51, label: intl.formatMessage({ id: 'sign_up_pages.50m' }) },
];

export const aumOptions = intl => [
  { value: 0, label: 'N/A' },
  { value: 0.5, label: intl.formatMessage({ id: 'sign_up_pages.500_k' }) },
  { value: 1, label: intl.formatMessage({ id: 'sign_up_pages.500_1m' }) },
  { value: 5, label: intl.formatMessage({ id: 'sign_up_pages.1m_5m' }) },
  { value: 25, label: intl.formatMessage({ id: 'sign_up_pages.5m_25m' }) },
  { value: 50, label: intl.formatMessage({ id: 'sign_up_pages.25m_50m' }) },
  { value: 100, label: intl.formatMessage({ id: 'sign_up_pages.50m_100m' }) },
  { value: 250, label: intl.formatMessage({ id: 'sign_up_pages.100m_250m' }) },
  { value: 500, label: intl.formatMessage({ id: 'sign_up_pages.250m_500m' }) },
  { value: 501, label: intl.formatMessage({ id: 'sign_up_pages.500m' }) },
];

const updateOption = (e, action) => {
  const params = {};
  params[e.target.id] = parseFloat(e.target.value);
  action(params);
};

const ProuductInput = ({ value, label, id, options, action }) => (
  <div style={{ margin: '20px' }}>
    <b>{label}</b>
    <select className="form-control" id={id} onChange={e => updateOption(e, action)}>
      {options.map(o => (
        <option selected={o.value === value} key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  </div>
);

class ProductMix extends React.Component {
  state = { loading: false };
  updateUser = updateAndNext => {
    const {
      mutate,
      onNext,
      life_ifp,
      home_ifp,
      auto_ifp,
      commercial_ifp,
      group_ifp,
      health_ifp,
      mutual_funds_aum,
      securities_aum,
      annuities_aua,
      segregated_funds_aua,
      mcpi_ifp,
      private_products_ifp,
      crop_hail_ifp,
      crop_hail_with_wind_ifp,
      farm_insurance_ifp,
      price_products_ifp,
      replant_supplement_ifp,
      gic_fixed_income,
      insurance,
      equity,
      user,
      updateAction,
      aua,
    } = this.props;
    this.setState({ loading: true });
    mutate({
      variables : {
        assets_under_management : aua || 0,
        advisor_licenses        : get(user, 'advisor_licenses', []),
        product_mix             : {
          life_ifp,
          home_ifp,
          auto_ifp,
          commercial_ifp,
          group_ifp,
          health_ifp,
          mutual_funds_aum,
          securities_aum,
          annuities_aua,
          segregated_funds_aua,
          mcpi_ifp,
          private_products_ifp,
          crop_hail_ifp,
          crop_hail_with_wind_ifp,
          farm_insurance_ifp,
          price_products_ifp,
          replant_supplement_ifp,
          gic_fixed_income,
          insurance,
          equity,
        },
      },
    })
      .then(response => {
        if (updateAndNext) {
          const currentPath = window.location.pathname.split('/').pop();
          const menuList = document.getElementById('edit_profile_path');
          const items = menuList.getElementsByTagName('li');
          let paths = [];
          for (var i = 0; i < items.length; ++i) {
            paths.push(items[i].querySelector('div a').href.split('/').pop());
          }
          window.location.href = `/profile/${paths[paths.indexOf(currentPath) + 1]}`;
        } else {
          onNext(STEP_NUMBERS.strengths);
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        this.setState({ loading: false });
      });
  };

  validate = () => {
    const {
      requiredFields,
      user,
      companyIsWealthType,
      disableProductMixStep,
      useCanadianRetailWealth,
      usePAndCProductMix,
      useWealthTypeCompanies,
      gic_fixed_income,
      insurance,
      equity,
      mutual_funds_aum,
      segregated_funds_aua,
      home_ifp,
      commercial_ifp,
      private_products_ifp,
      crop_hail_with_wind_ifp,
      life_ifp,
      auto_ifp,
      mcpi_ifp,
      crop_hail_ifp,
      farm_insurance_ifp,
      replant_supplement_ifp,
      health_ifp,
    } = this.props;
    if (disableProductMixStep) {
      return true;
    }
    if (!disableProductMixStep && !useCanadianRetailWealth && !usePAndCProductMix) {
      return true;
    }
    if (companyIsWealthType || useWealthTypeCompanies) {
      return sum(requiredFields.map(f => get(this.props, f, 0))) > 99 && user.advisor_licenses.length > 0;
    }
    return sum(requiredFields.map(f => get(this.props, f, 0))) > 99;
  };

  generateMixes = intl => {
    const availableProductsObject = JSON.parse(this.props.availableProducts);
    const availableProducts = merge(values(availableProductsObject)).flatten();

    const selectedInsuranceList = ['health_ifp', 'life_ifp', 'home_ifp', 'group_ifp'];

    const insuranceMixes = selectedInsuranceList.map(insuranceLabel => ({
      label   : <FormattedMessage id={`sign_up_pages.${insuranceLabel}`} />,
      value   : this.props[insuranceLabel],
      id      : insuranceLabel,
      options : ifpOptions(intl),
    }));
    const financialMixes = availableProducts.map(productLabel => ({
      label   : <FormattedMessage id={`sign_up_pages.${productLabel}`} />,
      value   : this.props[productLabel],
      id      : productLabel,
      options : productLabel.indexOf('_ifp') > -1 ? ifpOptions(intl) : aumOptions(intl),
    }));
  
    return [...insuranceMixes, ...financialMixes];
  };

  render () {
    const {
      companyIsWealthType,
      updateAction,
      availableProducts,
      headerText,
      nextButtonText,
      fullName,
      intl,
      classes,
      fromEditMyProfile,
      stylesheetName,
      disableProductMixStep,
      useCanadianRetailWealth,
      usePAndCProductMix,
      userName,
      gic_fixed_income,
      insurance,
      equity,
      mutual_funds_aum,
      segregated_funds_aua,
      home_ifp,
      commercial_ifp,
      private_products_ifp,
      crop_hail_with_wind_ifp,
      life_ifp,
      auto_ifp,
      mcpi_ifp,
      crop_hail_ifp,
      farm_insurance_ifp,
      replant_supplement_ifp,
      health_ifp,
      aua,
      updateUser,
    } = this.props;
    const { loading } = this.state;
    const mixes = availableProducts ? this.generateMixes(intl) : [];
    spotlightElement('.product-mix');
    const intlConfig = () => {
      if (intl.locale == 'fr') {
        return { locale: 'fr-CA', currency: 'CAD' };
      }
      return { locale: 'en-US', currency: 'USD' };
    };
    return (
      <div className="content">
        {(!disableProductMixStep || useCanadianRetailWealth || usePAndCProductMix) && (
          <div>
            <h2>{headerText}</h2>
            <b>
              <FormattedMessage id="sign_up_pages.select_relevant" />{' '}
            </b>
            <br />
            <br />
            {!useCanadianRetailWealth && !usePAndCProductMix && (
              <div>
                {mixes.map(mix => (
                  <ProuductInput
                    key={mix.id}
                    label={mix.label}
                    value={mix.value}
                    id={mix.id}
                    options={mix.options}
                    action={updateAction}
                  />
                ))}
              </div>
            )}
            {useCanadianRetailWealth && (
              <div>
                {!fromEditMyProfile && (
                  <div>
                    <span><FormattedMessage id="sign_up_pages.assets_under_admin" /></span>
                    <CurrencyInput
                      id="input-example"
                      value={aua || 0}
                      intlConfig={intlConfig()}
                      allowDecimals={false}
                      onValueChange={(value, name) => updateUser({ assets_under_management: parseInt(value) })}
                    />
                  </div>
                )}
                <br />
                <br />
                <RetailWealthProductMixSliders
                  gic_fixed_income={gic_fixed_income}
                  insurance={insurance}
                  equity={equity}
                  mutual_funds_aum={mutual_funds_aum}
                  segregated_funds_aua={segregated_funds_aua}
                  updateAction={updateAction}
                  updateUser={updateUser}
                />
              </div>
            )}
            {usePAndCProductMix && (
              <div>
                {!fromEditMyProfile && (
                  <div>
                    <span><FormattedMessage id="sign_up_pages.assets_under_admin" /></span>
                    <CurrencyInput
                      id="input-example"
                      value={aua || 0}
                      intlConfig={intlConfig()}
                      allowDecimals={false}
                      onValueChange={(value, name) => updateUser({ assets_under_management: parseInt(value) })}
                    />
                  </div>
                )}
                <br />
                <br />
                <PAndCProductMixSliders
                  home_ifp={home_ifp}
                  commercial_ifp={commercial_ifp}
                  private_products_ifp={private_products_ifp}
                  crop_hail_with_wind_ifp={crop_hail_with_wind_ifp}
                  life_ifp={life_ifp}
                  auto_ifp={auto_ifp}
                  mcpi_ifp={mcpi_ifp}
                  crop_hail_ifp={crop_hail_ifp}
                  farm_insurance_ifp={farm_insurance_ifp}
                  replant_supplement_ifp={replant_supplement_ifp}
                  health_ifp={health_ifp}
                  updateAction={updateAction}
                  updateUser={updateUser}
                />
              </div>
            )}
          </div>
        )}
        {(companyIsWealthType  || useCanadianRetailWealth) && (
          <div style={{ padding: '15px' }}>
            <div className={classes.formGroup}>
              <b><FormattedMessage id="sign_up_pages.active_licenses" />{intl.locale == 'fr' &&  ' '}:</b>
              <div style={{ marginTop: '20px' }}>{this.props.children}</div>
            </div>
          </div>
        )}
        <div className="text-center">
          {this.props.onBack && (
            <button
              className={'btn btn-default'}
              style={{ padding: '12px 24px', float: 'left', color: '#fff' }}
              onClick={this.props.onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
          {!fromEditMyProfile && (
            <LoadingButtonWrapper stateChild="user" loading={loading}>
              <button
                onClick={() => this.updateUser(false)}
                disabled={fromEditMyProfile ? false : !this.validate()}
                className={'btn btn-default'}
                style={{ padding: '12px 24px', float: 'right', color: '#fff' }}>
                {nextButtonText}
              </button>
            </LoadingButtonWrapper>
          )}
        </div>
        {fromEditMyProfile && (
          <LoadingButtonWrapper stateChild="user" loading={loading}>
            <div style={{ textAlign: 'end' }}>
              <button
                onClick={() => this.updateUser(true)}
                disabled={fromEditMyProfile && !useCanadianRetailWealth  && !usePAndCProductMix ? false : !this.validate()}
                className={`btn ${stylesheetName === 'rjpe' ? 'btn-default' : 'btn-success'}`}
                style={{ padding: '12px 24px', color: '#fff' }}>
                <FormattedMessage id={`profile_page.save_and_next`} />
              </button>
              <button
                onClick={() => (window.location.href = `/users/${userName}`)}
                className={`btn btn-secondary`}
                style={{ padding: '12px 24px', marginLeft: 20 }}>
                <FormattedMessage id="profile_page.cancel" />
              </button>
            </div>
          </LoadingButtonWrapper>
        )}
      </div>
    );
  }
}

ProductMix.propTypes = {
  life_ifp                : PropTypes.number,
  home_ifp                : PropTypes.number,
  auto_ifp                : PropTypes.number,
  commercial_ifp          : PropTypes.number,
  health_ifp              : PropTypes.number,
  group_ifp               : PropTypes.number,
  mutual_funds_aum        : PropTypes.number,
  securities_aum          : PropTypes.number,
  annuities_aua           : PropTypes.number,
  segregated_funds_aua    : PropTypes.number,
  mcpi_ifp                : PropTypes.number,
  private_products_ifp    : PropTypes.number,
  crop_hail_ifp           : PropTypes.number,
  crop_hail_with_wind_ifp : PropTypes.number,
  farm_insurance_ifp      : PropTypes.number,
  price_products_ifp      : PropTypes.number,
  replant_supplement_ifp  : PropTypes.number,
  fullName                : PropTypes.string,
  headerText              : PropTypes.string,
  nextButtonText          : PropTypes.string,
  updateAction            : PropTypes.func.isRequired,
  intl                    : PropTypes.object.isRequired,
  user                    : PropTypes.object.isRequired,
};

ProductMix.defaultProps = {
  life_ifp                : 0,
  home_ifp                : 0,
  auto_ifp                : 0,
  commercial_ifp          : 0,
  health_ifp              : 0,
  group_ifp               : 0,
  mutual_funds_aum        : 0,
  securities_aum          : 0,
  annuities_aua           : 0,
  segregated_funds_aua    : 0,
  mcpi_ifp                : 0,
  private_products_ifp    : 0,
  crop_hail_ifp           : 0,
  crop_hail_with_wind_ifp : 0,
  farm_insurance_ifp      : 0,
  price_products_ifp      : 0,
  replant_supplement_ifp  : 0,
  fullName                : '',
  headerText              : <FormattedMessage id="sign_up_pages.looking_good" />,
  nextButtonText          : <FormattedMessage id="sign_up_pages.next" />,
};

const ProductMixWithStyles = withStyles(styles)(ProductMix);
export default injectIntl(ProductMixWithStyles);
