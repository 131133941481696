import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { get } from 'lodash';
import * as actions from '../actions/suggestedDealActionCreators';
import SuggestedDealsPage from '../components/SuggestedDealsPanel/SuggestedDealsPage';

const SuggestedDealsQuery = gql`
  query getSuggestedDeals {
    getSuggestedDeals {
      id
      created_at
      user {
        id
        name
        annual_revenue
        city
        province
        created_at
        inventory_user {
          id
        }
      }
    }
    agentWordForCompany
    getOwner {
      id
      name
      email
      has_user
      profile_picture
    }
  }
`;

const SuggestedDealsContainer = ({
  suggestedDeals,
  updateSuggestedDealsList,
  updateSuggestedDeal,
  updateSelectedSuggestedDeal,
}) => {
  return (
    <Query query={SuggestedDealsQuery}>
      {({ loading, error, data, refetch }) => {
        if (!loading && suggestedDeals.loaded === false) {
          updateSuggestedDealsList({
            suggestedDeals : data.getSuggestedDeals,
          });
        }
        return (
          <SuggestedDealsPage
            suggestedDeals={get(suggestedDeals, 'suggestedDeals', [])}
            suggestedDeal={get(suggestedDeals, 'selected', {})}
            updateSuggestedDealsList={updateSuggestedDealsList}
            updateSuggestedDeal={updateSuggestedDeal}
            updateSelectedSuggestedDeal={updateSelectedSuggestedDeal}
            agentWordForCompany={get(data, 'agentWordForCompany', '')}
            owner={get(data, 'getOwner', {})}
          />
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  suggestedDeals : state.suggestedDeals,
  suggestedDeal  : get(state.suggestedDeals, 'suggestedDeal', {}),
});

export default connect(mapStateToProps, actions)(SuggestedDealsContainer);
