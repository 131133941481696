import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const inputComponent = ({ inputRef, ...props }) => (
  <div ref={inputRef} {...props} />
);

const Control = ({ selectProps, innerRef, children, innerProps }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...selectProps.textFieldProps}
  />
);

Control.propTypes = {
  selectProps: PropTypes.object,
  innerRef: PropTypes.string,
  children: PropTypes.object,
  innerProps: PropTypes.object,
};

Control.defaultProps = {
  selectProps: {},
  innerRef: '',
  children: {},
  innerProps: {},
};

export default Control;
