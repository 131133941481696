import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, truncate } from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { ChevronRight } from '@material-ui/icons';
import Chip from '@material-ui/core/Chip';
import { S3Image } from '../../lib/s3';
import { injectIntl, FormattedMessage } from 'react-intl';
import { LocalOffer, Loyalty, Help, QuestionAnswer, Message } from '@material-ui/icons';

export const iconsByConversationType = {
  deal: <LocalOffer />,
  deal_interest: <LocalOffer />,
  partial_sale_listing: <Loyalty />,
  support_request: <Help />,
  paddy: <QuestionAnswer />,
  message: <Message />,
};

export const colorsByConversationType = {
  deal: 'secondary',
  deal_interest: 'secondary',
  partial_sale_listing: 'default',
  support_request: 'primary',
  paddy: 'primary',
  message: 'highlight',
};

const styles = theme => ({
  root           : {
    width    : '100%',
    maxWidth : 360,
    height   : 'calc(100vh - 200px)',
    overflow : 'scroll',
  },
  inline         : {
    display : 'inline',
  },
  selected       : {
    background : '#dadada',
  },
  standaloneList : {
    overflowY : 'scroll',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  convoItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    position: 'relative',
    cursor: 'pointer',
  },
  convoContent: {
    flexGrow: 1,
  },
  chevronIcon: {
    marginLeft: 'auto',
    paddingLeft: '10px',
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
  },
  circle: {
    marginRight: '5px',
    borderRadius: '50%',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.0em',
    flexGrow: 1,
  },
  name: {
    marginLeft: '10px',
    flex: 'auto',
  },
  lastMessageBody: {
    color: '#666',
  },
});


function ConverastionList (props) {
  const { classes, conversations, currentUser, room_id, updateParent, intl } = props;
  return (
    <List className={classes.root}>
      <h2>&nbsp;<FormattedMessage id="messages_section.conversations"/></h2>
      {conversations.map((convo, index) => {
        let otherUser = null;
        if (currentUser.id === get(convo, 'participant1.id')) {
          otherUser = get(convo, 'participant2');
        } else {
          otherUser = get(convo, 'participant1');
        }
        const convo_type = get(convo, 'conversationType');
        let subtitle = get(convo, 'conversationType');
        if (subtitle === 'deal') {
          subtitle = `Deal`;
        } else if (subtitle === 'deal_interest') {
          subtitle = 'Deal';
        } else if (subtitle === 'partial_sale_listing') {
          subtitle = `${get(currentUser, 'company.partial_sale_name')}`;
        }
        else if (subtitle === 'support_request') {
          subtitle = 'Help';
        } else {
          subtitle = 'Chat';
        }
        const convoTitle = () => {
          if (get(otherUser, 'hidden')) {
            if (convo_type === 'deal_interest') {
              return `Deal Room closed`;
            } else {
              return intl.formatMessage({ id: 'messages_section.user_no_longer_active', subtitle: ''});
            }
          }
          return get(otherUser, 'allowed_name', 'Paddy');
        };
        let lastMessageBody = get(convo, 'lastMessages[0].body', '');
        if (lastMessageBody.length > 50) {
          lastMessageBody = lastMessageBody.substring(0, 50) + '...';
        }
        let read = true;
        if (get(convo, 'lastMessages[0].sender.id') === get(otherUser, 'id') && get(convo, 'lastMessages[0].read') === false) {
          read = false;
        }
        
        let allowedName = get(otherUser, 'allowed_name', 'Paddy');
        if(allowedName === 'Paddy' && convo.conversationType === 'Support Request') {
          allowedName = 'Support';
        }
        return (
          <div 
            key={index}
            className={classes.convoItem}
            onClick={() => updateParent({
              room_id       : convo.id,
              title         : `${convo_type === 'deal_interest'
                ? get(otherUser, 'allowed_name', '')
                : get(otherUser, 'user_name', '')}`,
              open          : false,
              can_view_name : get(otherUser, 'can_view_real_name', false),
              otherUser,
            })}
            >
            <div className={classes.convoContent}>
              <div className={classes.title}> <Avatar alt={allowedName} src={S3Image(get(otherUser, 'profile_picture',''))} /> 
                <span className={classes.name}>{convoTitle()}</span>
              <Chip 
                style={{float: 'right', fontSize: '0.5em'}} 
                icon={iconsByConversationType[convo.conversationType]} 
                color={colorsByConversationType[convo.conversationType]} 
                label={subtitle}/>
              </div>
              <br style={{clear: 'both'}} />
              <p>
                {!read && <i className={`fas fa-circle icon_shimmer ${classes.circle}`} style={{ color: '#1396e2'  }}></i>}
                <span className={classes.lastMessageBody}>{lastMessageBody}</span>
              </p>
            </div>
            <ChevronRight className={classes.chevronIcon} />
            <hr className={classes.divider} />
          </div>
        );
      })}
    </List>
  );
}

ConverastionList.propTypes = {
  classes       : PropTypes.object.isRequired,
  conversations : PropTypes.object.isRequired,
  currentUser   : PropTypes.object.isRequired,
  room_id       : PropTypes.number.isRequired,
  updateParent  : PropTypes.func.isRequired,
};

export default injectIntl(withStyles(styles)(ConverastionList));
