import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { get, capitalize, isEmpty, groupBy } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MotivationFilters from './MotivationFilters';
import MotivationTable from './MotivationTable';
import MotivationHeader from './MotivationHeader';
import MotivationGraph from './MotivationGraph';
import ReactToPrint from 'react-to-print';
import { loadInventoryUserList } from '../../../actions/inventoryUsersActionCreators';

const MotivationReport = ({
  classes,
  data,
  motivationFilters,
  inventory_users,
  inventory_user_count,
  refetch,
  loading,
  loadInventoryUserList,
}) => {
  const componentRef = useRef();

  return (
    <div id="capture" ref={componentRef}>
      <style type="text/css" media="print">
        {`\
@media all { .page-break { display: none; } }\
@media print { html, body { line-height: 50px; height: initial !important; overflow: initial !important; -webkit-print-color-adjust: exact; } }\
@media print { .page-break { display: block; break-after: always !important; page-break-after: always !important; } }\
@media print { @page { size: 24in 16in; } }\
@media print { p { padding: 5px; } }\
@page { margin: 40px; }\
`}
      </style>
      <MotivationHeader />
      <MotivationFilters componentRef={componentRef} />
      <MotivationGraph loading={loading} />
      <div className="page-break" />
      <MotivationTable loading={loading} />
    </div>
  );
};
MotivationReport.propTypes = {
  classes : PropTypes.object.isRequired,
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : { marginRight: 10 },
  formControl : {
    margin   : 2,
    minWidth : 120,
  },
};

const mapStateToProps = state => ({
  motivationFilters    : state.motivationFilters,
  inventory_users      : state.inventory_users.inventory_users,
  inventory_user_count : state.inventory_users.inventory_user_count,
  transition_goals     : state.inventory_users.transition_goals,
});

const mapDispatchToProps = {
  loadInventoryUserList,
};

const MotivationReportWithStyles = withStyles(styles)(MotivationReport);
export default connect(mapStateToProps, mapDispatchToProps)(MotivationReportWithStyles);
