import React, {useState} from 'react';
import {Box} from "@material-ui/core";
import {ImportContacts} from "@material-ui/icons";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage} from "react-intl";
import CfaChoice from "./CfaChoice";
import CfaDisclaimer from "./CfaDisclaimer";
import CfaPrice from "./CfaPrice";
import CfaViewExample from "./CfaViewExamples";
import {connect} from "react-redux";
import {get} from 'lodash';

const CfaStart = ({classes, companyName, company}) => {
  const [cfaType, updateCfaType] = useState();
  const [step, updateStep] = useState(0);
  const [cashFlows, updateCashFlows] = useState([]);
  const features = get(company, 'features', []);
  const prepopulatedCashFlowOnly = features.includes('prepopulated_cash_flow_only');

  if (prepopulatedCashFlowOnly && !cfaType) {
    updateCfaType('pre-populated');
    updateStep(1);
  }

  const renderStep = () => {
    switch(step) {
      case 1:
        return <CfaPrice updateStep={updateStep} step={step} cashFlows={cashFlows} updateCashFlows={updateCashFlows} companyName={companyName}/>;
      case 2:
        return <CfaDisclaimer updateStep={updateStep} step={step} companyName={companyName} />;
      case 3:
        return <CfaViewExample cashFlows={cashFlows} companyName={companyName}/>;
      default:
        return <CfaChoice cfaType={cfaType} updateCfaType={updateCfaType} step={step} updateStep={updateStep} companyName={companyName}/>;
    }

  }
console.log(companyName)
  return (<div className={classes.root}>
    {renderStep() }
  </div>);
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
  },
  buttonB: {
    margin: '18px 8px',
    padding: '48px 12px',
    width: 180,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    backgroundColor: '#fff',
    border: 'none',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : '#1396e2',
    width: 400,
  },
  icon                   : {
    fontSize : 20,
  },
};

const mapStateToProps = (state, props) => {
  const companyName = get(state, 'company.host', '').split('.')[0];
  return {
    companyName,
    company : state.company,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(CfaStart));