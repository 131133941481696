import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { relative } from 'path';

const AddNote = gql`
  mutation addNote($userName: String!) {
    addNote(userName: $userName, text: "Added to Rolodex") {
      id
      comment
      created_at
    }
  }
`;

const styles = theme => ({
  close           : {
    width  : theme.spacing.unit * 4,
    height : theme.spacing.unit * 4,
  },
  message         : {
    fontSize   : '18px',
    fontColor  : '#337ab7',
    lineHeight : '24px',
  },
  rolodexButton   : {
    fontSize   : '14px',
    background : 'transparent',
    border     : 'none',
    color      : '#337ab7',
    padding    : '2px',
  },
  noteCount       : {
    color        : '#337ab7',
    background   : '#fff',
    borderRadius : '50%',
    fontSize     : '14px',
    padding      : '4px',
  },
  snackBar        : {
    backgroundColor : '#1396e2',
  },
  red             : {
    color       : 'rgb(255, 80, 135)',
    marginRight : 4,
  },
  buttonContainer : {
    position : 'relative',
    top      : 1,
  },
});

class AddToRolodexButton extends Component {
  state = { showButton: true };

  click = () => {
    const {
      mutate,
      user,
      userName,
      refetch,
      intl,
      enqueueSnackbar,
      updateBobCards,
      loadBobCardList,
      updateNotes,
    } = this.props;

    mutate({
      variables : { userName },
    })
      .then(r => {
        this.setState({ showButton: false });

        enqueueSnackbar(
          `${intl.formatMessage({ id: 'mailers.rolodex.youve_added_a_new_contact' })}
           ${intl.formatMessage({ id: 'mailers.rolodex.you_can_keep_all_your_notes' })}`,
          {
            variant      : 'success',
            anchorOrigin : {
              vertical   : 'top',
              horizontal : 'center',
            },
          }
        );
        updateNotes({ notes: r.data.addNote, skip: true });
        updateBobCards({ ...user, note_count: 1 });
        loadBobCardList({ ...user, note_count: 1 });
        refetch();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  renderButton = () => {
    const { classes, display, noteCount, userName, intl, clickAction } = this.props;
    const { showButton } = this.state;
    if (showButton && display && noteCount < 1) {
      return (
        <button type="button" className={classes.rolodexButton} onClick={this.click} id={`${userName}-button`}>
          <i className="fas far fa-heart" />
        </button>
      );
    }
    return (
      <span
        onClick={() => clickAction()}
        className={`${classes.noteCount} ${classes.buttonContainer}`}
        title={intl.formatMessage({ id: 'mailers.rolodex.notes_about_this_user' })}>
        <a>
          <i className={`fas fa fa-heart ${classes.red}`} />&nbsp;<i className="fas fa-book" />{' '}
          {parseInt(noteCount > 0 ? noteCount - 1 : 0, 10)}
        </a>
      </span>
    );
  };

  render = () => {
    const { display, classes } = this.props;
    if (!display) {
      return <span />;
    }
    return <span>{this.renderButton()}</span>;
  };
}

AddToRolodexButton.propTypes = {
  mutate          : PropTypes.func.isRequired,
  enqueueSnackbar : PropTypes.func.isRequired,
  updateBobCards  : PropTypes.func.isRequired,
  loadBobCardList : PropTypes.func.isRequired,
  refetch         : PropTypes.func,
  classes         : PropTypes.object.isRequired,
  noteCount       : PropTypes.number,
  display         : PropTypes.bool,
  intl            : PropTypes.object.isRequired,
  user            : PropTypes.object.isRequired,
  userName        : PropTypes.string,
};

AddToRolodexButton.defaultProps = {
  userName  : 'Advisor',
  noteCount : 0,
  display   : true,
  refetch   : () => {},
};

export default graphql(AddNote)(injectIntl(withStyles(styles)(withSnackbar(AddToRolodexButton))));
