import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Company from '../Company/Company';
import { Grid, Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { colors } from '@material-ui/core';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 18,
    color    : '#fff',
  },
});

class CompaniesContainer extends React.Component {
  render () {
    const { companies, classes } = this.props;

    return (
      <section>
        <Paper className={classes.paper}>
          <Grid container className={classes.root}>
            <Grid container className={classes.demo}>
              {(companies || {}).map(c => <Company key={c.id} company={c} />)}
            </Grid>
          </Grid>
        </Paper>
      </section>
    );
  }
}

CompaniesContainer.propTypes = {
  companies : PropTypes.arrayOf(PropTypes.object),
};

CompaniesContainer.defaultProps = {
  companies : [],
};

export default withStyles(styles)(CompaniesContainer);
