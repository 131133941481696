import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';

import { withStyles } from '@material-ui/core/styles';
import { isEmpty, get, flowRight } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import ShowInventoryUserDrawer from './ShowInventoryUserDrawer';

const GetManagers = gql`
  query GetManagers {
    getManagers {
      id
      name
      email
    }
  }
`;

const AssignManager = gql`
  mutation assignManager($user_email: String, $manager_iu_id: Int) {
    assignManager(user_email: $user_email, manager_iu_id: $manager_iu_id)
  }
`;

const styles = {
  drawerPaper  : {
    width        : 530,
    height       : 'calc(100% - 100px)',
    position     : 'absolute',
    top          : 68,
    borderRadius : 4,
    overflowY    : 'auto',
    boxShadow    : '-2px 2px 2px -1px #aaa',
    borderTop    : '1px solid #aaa',
  },
  container    : {
    height      : '100%',
    borderRight : '1px solid #eee',
    padding     : 10,
  },
  header       : {
    height       : 'auto',
    borderBottom : '1px solid #eee',
  },
  headerEdit   : {
    height       : 64,
    borderBottom : '1px solid #eee',
    background   : '#90caf9',
  },
  title        : {
    padding    : 4,
    float      : 'left',
    marginLeft : 4,
    color      : '#000',
  },
  close        : {
    float : 'right',
  },
  dataLeft     : { float: 'left', padding: 6 },
  data         : { padding: 4 },
  clear        : { clear: 'both' },
  buttonList   : { margin: '200px 0 0 0' },
  designations : {
    fontSize : 15,
  },
};
class InventoryUserDrawer extends React.Component {
  assignManager = (user_email, strId) => {
    const { mutate } = this.props;
    const manager_iu_id = parseInt(strId, 10);
    mutate({ variables: { user_email, manager_iu_id } }).then(() => {}).catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
    });
  };

  render () {
    const {
      inventoryUser,
      classes,
      updateAction,
      updateInventoryUser,
      showSelectButton,
      showActivities,
      selectLink,
      showBench,
      showEditButton,
      loadBenchUsers,
      inventory_users,
      onlyUsers,
      country,
      openSnack,
      data,
      agentWordForCompany,
      currentUserIsAdmin,
      currentUserId,
      refetch,
      tags,
      agencies,
      allFeatures,
      company,
    } = this.props;
    const { getManagers } = data;
    const user = get(inventoryUser, 'user', []);
    if (isEmpty(inventoryUser)) return <span />;
    return (
      <Drawer
        open
        variant="persistent"
        anchor="right"
        classes={{
          paper : classes.drawerPaper,
        }}>
        <ShowInventoryUserDrawer
          agentWordForCompany={agentWordForCompany}
          assignManager={this.assignManager}
          classes={classes}
          country={country}
          inventoryUser={inventoryUser}
          inventoryUsers={inventory_users}
          inventory_users={inventory_users}
          loadBenchUsers={loadBenchUsers}
          managers={getManagers}
          openSnack={openSnack}
          selectLink={selectLink}
          showBench={showBench}
          showEditButton={showEditButton}
          showSelectButton={showSelectButton}
          updateAction={updateAction}
          showActivities={showActivities}
          updateInventoryUser={updateInventoryUser}
          user={user}
          onlyUsers={onlyUsers}
          currentUserIsAdmin={currentUserIsAdmin}
          currentUserId={currentUserId}
          refetch={refetch}
          tags={tags}
          agencies={agencies}
          allFeatures={allFeatures}
          company={company}
        />
      </Drawer>
    );
  }
}

InventoryUserDrawer.propTypes = {
  inventoryUser       : PropTypes.shape({
    name           : PropTypes.string,
    birth_date     : PropTypes.string,
    province       : PropTypes.string,
    rev_yield      : PropTypes.number,
    clients        : PropTypes.number,
    total_premium  : PropTypes.number,
    total_assets   : PropTypes.number,
    key_asset      : PropTypes.bool,
    risk_of_loss   : PropTypes.string,
    impact_of_loss : PropTypes.string,
  }),
  classes             : PropTypes.object.isRequired,
  showSelectButton    : PropTypes.bool,
  showBench           : PropTypes.bool,
  showEditButton      : PropTypes.bool,
  updateAction        : PropTypes.func.isRequired,
  updateInventoryUser : PropTypes.func.isRequired,
  selectLink          : PropTypes.string,
  loadBenchUsers      : PropTypes.func.isRequired,
  refetch             : PropTypes.func.isRequired,
  currentUserId       : PropTypes.number.isRequired,
};

InventoryUserDrawer.defaultProps = {
  inventoryUser    : {},
  showSelectButton : false,
  showBench        : false,
  showEditButton   : false,
  selectLink       : '',
};

export default flowRight(graphql(GetManagers), graphql(AssignManager))(withStyles(styles)(InventoryUserDrawer));
