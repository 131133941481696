import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {get, range, reduce, mean, sum} from 'lodash';
import numeral from 'numeral';
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage, injectIntl } from 'react-intl';

export function calculatePurchasePrice(cf) {
  const sellerCfs = get(cf, 'illustration.sellerCashFlow', []);
  let purchasePrice = 0.0;

  purchasePrice += reduce(sellerCfs, (sum, cf) => sum + get(cf, 'lumpSumPaymentReceived', 0), 0);
  purchasePrice += reduce(sellerCfs, (sum, cf) => sum + get(cf, 'contingentPaymentReceived', 0), 0);
  purchasePrice += reduce(sellerCfs, (sum, cf) => sum + get(cf, 'sellerNotePayment', 0), 0);
  purchasePrice += reduce(sellerCfs, (sum, cf) => sum + get(cf, 'consultingAgreement', 0), 0);

  return purchasePrice;
}

export function calculatePurchasePriceOrdinaryTaxes(cf) {
  const sellerCfs = get(cf, 'illustration.sellerCashFlow', []);
  let purchasePrice = 0.0;

  purchasePrice += reduce(sellerCfs, (sum, cf) => sum + get(cf, 'bookSalePaymentReceived', 0), 0);

  return purchasePrice;
}

const Grades = ({result, classes, handleClick, value, intl, company}) => {
  const cf = get(result, 'cashFlow', {});
  const sellerCfs = get(cf, 'illustration.sellerCashFlow', []);
  const buyer1Cfs = get(cf, 'illustration.buyer1CashFlow', []);
  const buyer2Cfs = get(cf, 'illustration.buyer2CashFlow', []) || [];
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  const averageFreeCashFlow = cf.sellerAverageCashFlow
  const buyer1OperatingVsNetSales = sum(buyer1Cfs.map(s => s.afterTaxNetIncome)) / sum(buyer1Cfs.map(s => s.payout));
  const buyer2OperatingVsNetSales = sum(buyer2Cfs.map(s => s.afterTaxNetIncome)) / sum(buyer2Cfs.map(s => s.payout));

  const compFreeCashFlowBuyer1 = sum(buyer1Cfs.map(s => s.freeCashFlow)) / sum(buyer1Cfs.map(s => s.afterTaxNetIncome));
  const compFreeCashFlowBuyer2 = sum(buyer2Cfs.map(s => s.freeCashFlow)) / sum(buyer2Cfs.map(s => s.afterTaxNetIncome));

  const avgFreeCashFlowBuyer1 = cf.buyer1AverageCashFlow;
  const avgFreeCashFlowBuyer2 = cf.buyer2AverageCashFlow;

  let editedPurchasePrice = 0.0;

  if (showOrdinaryTaxes) {
    editedPurchasePrice = calculatePurchasePriceOrdinaryTaxes(cf);
  }else {
    editedPurchasePrice = calculatePurchasePrice(cf);
  }

  let editedMultiple = (editedPurchasePrice / cf.sellerT12Revenue).toFixed(2);

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  return <Grid container spacing={1} className={classes.grid}>
    <Grid sm={cf.numberOfBuyers < 2 ? 6: 4}>
      <section id="agent-testimonials" className={`${classes.card}  ${ value == 0 && classes.selectedCard} content-card`} >
        <center><h2><FormattedMessage id="calculators.cfa.seller_summary" /></h2></center>
        <Tooltip title="Year in which production revenue shifts to the buyer(s)">
          <b><FormattedMessage id="calculators.cfa.target_retirement" /> </b>
        </Tooltip> {cf.cashFlowProductionShift}<br/>
        <Tooltip title="Amount and year in which a lump sum / downpayment is provided to the seller">
          <b><FormattedMessage id="calculators.cfa.lump_sum" /> </b>
        </Tooltip> {numeral(cf.buyerLumpSumAmount).format(intl.locale === 'en' ? '$0,0' : '0,0 $')} <FormattedMessage id="calculators.cfa.in_year" /> {cf.buyerLumpSumYear}<br/>
        <Tooltip title="Is the average of net operating cash flow minus expenditures over the duration of the cash flow period.  Demonstrates how efficient the business is at generating cash.">
          <b><FormattedMessage id="calculators.cfa.avg_free_cash_flow" /> </b>
        </Tooltip> <span className={averageFreeCashFlow < 0 ? classes.red : ''}>{numeral(averageFreeCashFlow).format(intl.locale === 'en' ? '$0,0' : '0,0 $')} </span> <FormattedMessage id="calculators.cfa.over" /> {cf.cashFlowLength} <FormattedMessage id="calculators.cfa.years" /><br/>
        <Tooltip title="Is the purchase amount and the price / the sellers T12 Revenue">
          <b><FormattedMessage id="calculators.cfa_wizard.purchase_price" /></b>
          </Tooltip>{`${numeral(parseFloat(editedPurchasePrice).toFixed(2)).format(intl.locale === 'en' ? '$0,0.00' : '0,0.00 $')} (${editedMultiple}x multiple)`}<br/>
         <Button
          variant="contained"
          style={{margin: 10, width: '80%', position: 'absolute', bottom: 18}}
          className="btn btn-default"
          disabled={value == 0}
          onClick={e => {
            handleClick(e, 0);
        }}>
          <FormattedMessage id="calculators.cfa.see_seller_details" />
        </Button>
      </section>
    </Grid>
    
    {range(1, (cf.numberOfBuyers || 0) + 1, 1).map(buyer =>
        <React.Fragment>
          <Grid sm={cf.numberOfBuyers < 2 ? 6: 4}>
            <section id="agent-testimonials"  className={`${classes.card} ${ value == buyer && classes.selectedCard} content-card`} >
              <center><h2><FormattedMessage id="calculators.cfa.buyer_summary" values={{buyer}}/></h2></center>
              <Tooltip title="This ratio expresses the business' net operating cash flow to its revenue, or net sales.  It will describe how many dollars of cash are generated for every dollar of sales.  The higher the percentage, the better.">
                <b><FormattedMessage id="calculators.cfa.operating_cash_flow" /></b>
              </Tooltip> {numeral(eval(`buyer${buyer}OperatingVsNetSales`)).format(intl.locale === 'en' ? '0.00%' : '0.00 %')}<br/>
              <Tooltip title="This ratio is calculated by dividing the free cash flow by net operating cash flow.  The higher the percentage, the better.">
                <b><FormattedMessage id="calculators.cfa.comprehensive_cash_flow" /></b>
              </Tooltip> {numeral(eval(`compFreeCashFlowBuyer${buyer}`)).format(intl.locale === 'en' ? '0.00%' : '0.00 %')}<br/>
              <Tooltip title="Is the average of net operating cash flow minus expenditures over the duration of the cash flow period.  Demonstrates how efficient the business is at generating cash.">
                <b><FormattedMessage id="calculators.cfa.avg_free_cash_flow" /> </b>
              </Tooltip>
              <span className={eval(`avgFreeCashFlowBuyer${buyer}`) < 0 ? classes.red : '' }>{numeral(eval(`avgFreeCashFlowBuyer${buyer}`)).format(intl.locale === 'en' ? '$0,0' : '0,0 $')} </span> <FormattedMessage id="calculators.cfa.over" /> {cf.cashFlowLength} <FormattedMessage id="calculators.cfa.years" /><br/>
              {<React.Fragment>
                <Tooltip title="">
                <b><FormattedMessage id="calculators.cfa.break_even_year" /> </b>
              </Tooltip>{cf[`buyer${buyer}BreakEven`] > 0 ? cf[`buyer${buyer}BreakEven`] : 'N/A in current time frame.'}</React.Fragment>}
              
              <Button
                variant="contained"
                style={{margin: 10, width: '100%'}}
                className="btn btn-default"
                disabled={value == buyer}
                onClick={e => {
                  handleClick(e,buyer);
                }}>
                  <FormattedMessage id="calculators.cfa.see_buyer_details" values={{buyer}}/>
              </Button>
            </section>
          </Grid>
        </React.Fragment>
      )}
    <div style={{clear: 'both'}}/>
  </Grid>
};

const styles = theme => ({
  red: {
    color: 'red',
  },
  grid: {
    margin: 20,
    padding: '10px 0',
    marginLeft: '-10px'
  },
  selectedCard: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  card: {
    margin: 0,
  },
});

Grades.propTypes = {
  result: {},
  handleClick: PropTypes.func,
  value: PropTypes.number,
}

Grades.defaultProps = {
  handleClick: () => {},
  value: 0,
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(Grades))
);