import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '../ToggleButton/ToggleButton';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import Switch from '@material-ui/core/Switch';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const styles = theme => ({
  title     : {
    fontSize : 18,
    color    : '#fff',
    margin   : 12,
  },
  features  : {
    display       : 'flex',
    flexDirection : 'column',
  },
  typo      : {
    margin         : '10px',
    textDecoration : 'underline',
    fontSize       : 15,
  },
  subtypo   : {
    margin : '10px',
  },
  textField : {
    width : '95%',
  },
});

const ColorSwitch = withStyles({
  switchBase : {
    color                : '#002949',
    '&$checked'          : {
      color : '#002949',
    },
    '&$checked + $track' : {
      backgroundColor : '#002949',
    },
  },
  checked    : {},
  track      : {},
})(Switch);

const UpdateCompany = gql`
  mutation updateCompany($rj_use_owners_comp_direct_expenses: Boolean, $rj_use_bottom_two_tiers_recurring_revenue: Boolean) {
    updateCompany(rj_use_owners_comp_direct_expenses: $rj_use_owners_comp_direct_expenses, rj_use_bottom_two_tiers_recurring_revenue: $rj_use_bottom_two_tiers_recurring_revenue)
  }
`;

const PeCompanyValuationMenu = ({ company, updateCompany, classes, mutate }) => {
  const [ rjUseOwnersCompDirectExpenses, setRjUseOwnersCompDirectExpenses ] = useState(
    company.data.rj_use_owners_comp_direct_expenses || false
  );
  const [ rjUseBottomTwoTierRevenueFactor, setRjUseBottomTwoTierRevenueFactor ] = useState(
    company.data.rj_use_bottom_two_tiers_recurring_revenue || false
  );
  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography type="h1" className={classes.title}>
            Valuation Menu
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.features}>
        <div style={{ marginTop: 15 }}>
          <Typography variant="title" className={classes.typo}>
            Multiple of Revenue
          </Typography>
        </div>
        <div style={{ marginLeft: 10 }}>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_recurring_high"
              label="MOR Recurring High"
              data={company.data}
              action={updateCompany}
            />
          </div>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_recurring_avg"
              label="MOR Recurring Average"
              data={company.data}
              action={updateCompany}
            />
          </div>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_recurring_low"
              label="MOR Recurring Low"
              data={company.data}
              action={updateCompany}
            />
          </div>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_non_recurring_high"
              label="MOR Non-Recurring High"
              data={company.data}
              action={updateCompany}
            />
          </div>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_non_recurring_avg"
              label="MOR Non-Recurring Average"
              data={company.data}
              action={updateCompany}
            />
          </div>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_mor_non_recurring_low"
              label="MOR Non-Recurring Low"
              data={company.data}
              action={updateCompany}
            />
          </div>
        </div>
        <br />
        <Typography variant="title" className={classes.typo}>
          EBOC
        </Typography>
        <div style={{ marginLeft: 10 }}>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_recurring_high"
              label="EBOC Recurring High"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_recurring_low"
              label="EBOC Recurring Low"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_non_recurring_high"
              label="EBOC Non Recurring High"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_non_recurring_low"
              label="EBOC Non Recurring Low"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_recurring_avg_multiplier"
              label="EBOC Recurring Average Multiplier"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_eboc_non_recurring_avg_multiplier"
              label="EBOC Non Recurring Average Multiplier"
              data={company.data}
              action={updateCompany}
            />
          </div>
        </div>
        <br />
        <Typography variant="title" className={classes.typo}>
          Discounted Rate
        </Typography>
        <div style={{ marginLeft: 10 }}>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="rj_company_specific_risk"
              label="Company Specific Risk"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
            fullWidth
            type="number"
            id="rj_company_specific_risk_secondary"
            label="Company Specific Risk Secondary"
            data={company.data}
            action={updateCompany}
          />
          </div>
        </div>
        <br />
        <Typography variant="title" className={classes.typo}>
          Constants
        </Typography>
        <div style={{ marginLeft: 10 }}>
          <div className={classes.textField}>
            <SimpleInputField
              fullWidth
              type="number"
              id="terminal_growth_rate_constant"
              label="Terminal Growth Rate"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
              fullWidth
              type="number"
              id="revenue_growth_rate_constant"
              label="Revenue Growth Rate"
              data={company.data}
              action={updateCompany}
            />
            <SimpleInputField
                fullWidth
                type="number"
                id="valuation_growth_rate_cap"
                label="Growth Rate Cap"
                data={company.data}
                action={updateCompany}
            />
            <div>
              <p style={{ fontSize: 12, marginLeft: 10, marginTop: 20 }}>Use Owners Compensation / Direct Expenses</p>
              <ColorSwitch
                checked={rjUseOwnersCompDirectExpenses}
                onChange={() => {
                  mutate({
                    variables : {
                      rj_use_owners_comp_direct_expenses : !rjUseOwnersCompDirectExpenses,
                    },
                  });
                  setRjUseOwnersCompDirectExpenses(!rjUseOwnersCompDirectExpenses);
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div>
              <p style={{ fontSize: 12, marginLeft: 10, marginTop: 20 }}>Use Bottom Two Tier Revenue Discount Factor</p>
              <ColorSwitch
                checked={rjUseBottomTwoTierRevenueFactor}
                onChange={() => {
                  mutate({
                    variables : {
                      rj_use_bottom_two_tiers_recurring_revenue : !rjUseBottomTwoTierRevenueFactor,
                    },
                  });
                  setRjUseBottomTwoTierRevenueFactor(!rjUseBottomTwoTierRevenueFactor);
                }}
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <SimpleInputField
              fullWidth
              type="number"
              id="rjas_branch_net_payout"
              label="RJAS - Branch Net Payout"
              data={company.data}
              action={updateCompany}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

PeCompanyValuationMenu.propTypes = {
  company           : PropTypes.object.isRequired,
  updateCompany     : PropTypes.func.isRequired,
  classes           : PropTypes.object.isRequired,
  updateCompanyBool : PropTypes.func.isRequired,
};

export default withStyles(styles)(graphql(UpdateCompany)(PeCompanyValuationMenu));
