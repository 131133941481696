import { createMuiTheme } from '@material-ui/core/styles';

export const ppiTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#7399C6',
        },
        secondary: {
            main: '#455560',
        },
        accent: {
            main: '#7399C6',
        },
        highlight: {
            main: '#f3f3f3',
        },
        graph: ['#455560', '#7399C6', '#f3f3f3', '#282B3E', '#ec008c', '#68217a', '#00bcf2', '#00b294', '#00188f', '#ff8c00'],
    },
    props: {
        MuiButton: {
            size: 'small',
            color: 'primary',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
        MuiInputSlider: {
            color: 'secondary'
        },
        InputSlider: {
            color: 'secondary'
        },
    },
};

export default ppiTheme;