import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import ToggleButton from '../ToggleButton/ToggleButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  addButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  text      : {
    fontSize : '18px',
  },
  title     : {
    fontSize : 24,
    margin   : 12,
  },
});

class TodoListNewFormDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  addRow = action => {
    const { title, description, image_name, thumbnail_name } = this.state;

    action({ title, description, image_name, thumbnail_name });
  };

  render () {
    const { classes, error, close } = this.props;
    if (close) this.handleRequestClose();

    return (
      <div>
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>Add Todo List</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              required={true}
              id="title"
              label="Title"
              className={classes.textField}
              onChange={e => {
                this.setState({ title: e.target.value });
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              required={true}
              id="description"
              label="Description"
              className={classes.textField}
              onChange={e => {
                this.setState({ description: e.target.value });
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              required={true}
              id="image_name"
              label="Image Name"
              className={classes.textField}
              onChange={e => {
                this.setState({ image_name: e.target.value });
              }}
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              required={true}
              id="thumbnail_name"
              label="Thumbnail Name"
              className={classes.textField}
              onChange={e => {
                this.setState({ thumbnail_name: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              Cancel
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}
              disabled={!this.state.title}>
              Add
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TodoListNewFormDialog);
