/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { ApolloConsumer } from 'react-apollo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ConversationAdmin from '../components/Conversation/ConversationAdmin';

class ConversationsAdminContainer extends React.Component {
  render () {
    const { conversations, conversation_count } = this.props; 
    return <ApolloConsumer> 
      {client => (
        <ConversationAdmin conversation_count={conversation_count} conversations={conversations} client={client} />
      )}
    </ApolloConsumer>;
  };
};

ConversationsAdminContainer.propTypes = {
  conversations    : PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return {
    conversations: get(state, 'conversations.list', []),
    conversation_count:  get(state, 'conversations.count', 0),
  };
};

export default connect(mapStateToProps)(ConversationsAdminContainer);