import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BecomeAPartnerContainer from './BecomeAPartnerContainer';
import SaveButton from '../ProfileDrawerSideBarComponents/SaveButton';
import StatusSection from '../ProfileDrawerSideBarComponents/StatusSection';
import ContinuityPlanInPlace from '../ProfileDrawerSideBarComponents/ContinuityPlanInPlace';
import Languages from '../ProfileDrawerSideBarComponents/Languages';
import RecognitionLevel from '../ProfileDrawerSideBarComponents/RecognitionLevel';
import DefinedInvestmentStyle from '../ProfileDrawerSideBarComponents/DefinedInvestmentStyle';
import DefinedServiceModel from '../ProfileDrawerSideBarComponents/DefinedServiceModel';
import DesiredLocation from '../ProfileDrawerSideBarComponents/DesiredLocation';
import TeamSize from '../ProfileDrawerSideBarComponents/TeamSize';
import NicheMarkets from '../ProfileDrawerSideBarComponents/NicheMarkets';
import AskMeAbout from '../ProfileDrawerSideBarComponents/AskMeAbout';

const ExpressInterest = ({
  scrollTo,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  displaySocialButtons,
  classes,
  updateNotes,
  notes,
  agentWordForCompany,
  obfuscateView,
  updateProfile,
  companyBusinessType,
}) => {
  const dealRoomName = get(getCurrentUser, 'company.deal_room_branded_name');
  return (
    <div>
      <div className={classes.rightColumn}>
        {companyHasNewMessages &&
        companyHasNetworking && (
          <BecomeAPartnerContainer
            scrollTo={scrollTo}
            user={user}
            currentUserHasLimitedAccess={currentUserHasLimitedAccess}
            hasInterestedUsers={hasInterestedUsers}
            updateBobCards={updateBobCards}
            loadBobCardList={loadBobCardList}
            currentCompanySellerWord={currentCompanySellerWord}
            currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
            getCurrentUser={getCurrentUser}
            disableMessageBtn={disableMessageBtn}
            marketplaceOnly={marketplaceOnly}
            updateNotes={updateNotes}
            updateProfile={updateProfile}
            becomeAPartner
          />
        )}
        <br />
        <StatusSection
          companyHasNewMessages={companyHasNewMessages}
          companyHasNetworking={companyHasNetworking}
          user={user}
          obfuscateView={obfuscateView}
          currentUser={getCurrentUser}
          currentCompanySellerWord={currentCompanySellerWord}
          dealRoomName={dealRoomName}
          becomeAPartner
        />
        <br />
        <SaveButton
          user={user}
          userId={user.id}
          userName={user.user_name}
          display={displaySocialButtons}
          noteCount={user.note_count}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          fromBobCard
          marketplaceOnly={marketplaceOnly}
          updateNotes={updateNotes}
          notes={notes}
          agentWordForCompany={agentWordForCompany}
        />
        <hr className={classes.bar} />
        <ContinuityPlanInPlace user={user} obfuscateView={obfuscateView} />
        <Languages user={user} obfuscateView={obfuscateView} />
        <RecognitionLevel user={user} obfuscateView={obfuscateView} />
        {companyBusinessType !== 'p_and_c' && <DefinedInvestmentStyle user={user} obfuscateView={obfuscateView} />}
        <DefinedServiceModel user={user} obfuscateView={obfuscateView} />
        <DesiredLocation user={user} obfuscateView={obfuscateView} />
        <TeamSize user={user} obfuscateView={obfuscateView} />
        <NicheMarkets user={user} obfuscateView={obfuscateView} />
        {companyBusinessType === 'wealth_rj' && <AskMeAbout user={user} obfuscateView={obfuscateView} />}
      </div>
    </div>
  );
};

ExpressInterest.propTypes = {
  user                                 : PropTypes.object.isRequired,
  obfuscateView                        : PropTypes.bool.isRequired,
  companyHasNewMessages                : PropTypes.bool.isRequired,
  companyHasNetworking                 : PropTypes.bool.isRequired,
  scrollTo                             : PropTypes.string.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.bool.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.object.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  displaySocialButtons                 : PropTypes.bool.isRequired,
  classes                              : PropTypes.object.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  notes                                : PropTypes.array.isRequired,
  agentWordForCompany                  : PropTypes.string.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  companyBusinessType                  : PropTypes.string.isRequired,
};

const styles = {
  rightColumn : {
    marginLeft  : 25,
    marginRight : '30%',
  },
  bar         : {
    width : 230,
  },
};

export default withStyles(styles)(ExpressInterest);
