import { connect } from 'react-redux';
import { forEach } from 'lodash';
import HomeOfficeCtaForm from '../components/HomeOfficeCtaForm/HomeOfficeCtaForm';
import * as actions from '../actions/companyActionCreators';

const generateCompanyPayload = company => {
  const payload = { company: {} };
  [
    'home_office_cta_title',
    'home_office_cta_paragraph',
    'home_office_cta_button_copy',
    'home_office_email',
    'id',
  ].map(name => (payload.company[name] = company[name]));

  return payload;
};

const mapStateToProps = state => ({
  company                : state.company,
  generateCompanyPayload,
});

export default connect(mapStateToProps, actions)(HomeOfficeCtaForm);
