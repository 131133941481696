import React from 'react';
import CurrencyInput from "react-currency-input-field";
import {injectIntl} from "react-intl";
import {get} from 'lodash';


const FBCurrencyInput = props => {
  const intlConfig = () => {
    if (get(props, 'intl.locale', 'en') == 'fr') {
      return {locale: 'fr-CA', currency: 'CAD'};
    }
    return {locale: 'en-US', currency: 'USD'};
  };

  return <CurrencyInput {...props} intlConfig={intlConfig()}/>
};

export default injectIntl(FBCurrencyInput);