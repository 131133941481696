import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Fab from '@material-ui/core/Fab';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { get } from 'lodash';
import numeral from 'numeral';
import client from '../../lib/apolloClient';
import AddDealDialog from './AddDealDialog';

const deleteSuggestedDealMutation = gql`
  mutation deleteSuggestedDeal($id: ID!) {
    deleteSuggestedDeal(id: $id)
  }
`;

const clickViewAll = () => {
  window.open(`/admin/suggested_deals`, '_self');
};

class SuggestedDealsPanel extends React.Component {
  constructor (props) {
    super(props);
    this.state = { suggested_deal: null };
  }

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).fromNow()}`;
  };

  handleClose = () => {
    this.setState({ suggested_deal: null });
  };

  render () {
    const { suggested_deals, classes, current_user, agent_word, owner } = this.props;
    const { suggested_deal } = this.state;
    return (
      <div>
        <ApolloProvider client={client}>
          <Mutation mutation={deleteSuggestedDealMutation}>
            {deleteSuggestedDeal => (
              <div className={classes.box}>
                <AddDealDialog
                  suggestedDeal={suggested_deal}
                  close={() => this.handleClose()}
                  inventoryUser={suggested_deal}
                  currentUser={current_user}
                  agentWordForCompany={agent_word}
                  owner={owner}
                  deleteSuggestedDeal={deleteSuggestedDeal}
                />

                {suggested_deals.map(user => (
                  <div className={classes.row}>
                    <Grid container spacing={0}>
                      <Grid item xs={2}>
                        <Avatar className={classes.avatar}>
                          <i className="fas fa-briefcase" />
                        </Avatar>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.text}>
                          <span className={classes.nameField}>
                            {user.name} | {numeral(user.annual_revenue).format('$0a')} | {user.province}
                          </span>
                          <br />
                          {this.formatDate(get(user, 'created_at'))}
                        </div>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton color="primary">
                          <CloseIcon
                            onClick={() => {
                              deleteSuggestedDeal({ variables: { id: user.id } });
                              window.location.reload();
                            }}
                          />
                        </IconButton>
                      </Grid>
                      <Grid item xs={1}>
                        <Fab
                          style={{ marginTop: 4 }}
                          variant="extended"
                          size="medium"
                          color="primary"
                          aria-label="Add"
                          onClick={() =>
                            this.setState({ suggested_deal: { ...user, deal_id: user.id, deal_type: null } })}>
                          Add
                        </Fab>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </div>
                ))}
                <div className={classes.viewAll}>
                  <button type="button" className={classes.contentButton} onClick={() => clickViewAll()}>
                    <span className={classes.content}>See All Deals</span>
                  </button>
                </div>
              </div>
            )}
          </Mutation>
        </ApolloProvider>
      </div>
    );
  }
}

SuggestedDealsPanel.propTypes = {
  suggested_deals : PropTypes.array,
  classes         : PropTypes.object.isRequired,
  current_user    : PropTypes.object.isRequired,
  agent_word      : PropTypes.string.isRequired,
  owner           : PropTypes.object.isRequired,
};

SuggestedDealsPanel.defaultProps = {
  suggested_deals : [],
};

const styles = {
  box           : {
    width     : '100%',
    marginTop : 15,
  },
  name          : {
    width : '85%',
  },
  nameField     : {
    fontWeight : 500,
  },
  avatar        : {
    margin : '0px 10px 0px 15px',
  },
  text          : {
    textAlign : 'left',
  },
  row           : {
    marginTop    : 5,
    marginBottom : 5,
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
};

export default withStyles(styles)(SuggestedDealsPanel);
