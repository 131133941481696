import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import ConversationContainer from '../../containers/ConversationContainer';
import { Link } from 'react-router-dom';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Input, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';

function SearchTable({ onSearch }) {
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        // Get the search value from the URL parameter
        const searchParams = new URLSearchParams(window.location.search);
        const query = searchParams.get("search");
        if (query) {
            setSearchValue(query);
        }
    }, []);
  
    const handleSearch = () => {
        // Update the URL parameter with the new search value
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("search", searchValue);
        window.location.search = searchParams.toString();
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
          handleSearch();
        }
      };
    
    return (
        <div style={{ display: 'flex', 
            display: 'flex', 
            alignItems: 'center', 
            marginBottom: '1rem', 
            float: 'right', 
            textAlign: 'right', 
            marginTop: '10px',
            position: 'static'
        }}>
            <Input
                value={searchValue}
                onChange={(event) => setSearchValue(event.target.value)}
                placeholder="Search Conversations"
                startAdornment={
                    <IconButton onClick={handleSearch}>
                        <SearchIcon />
                    </IconButton>
                }
                onKeyDown={handleKeyDown}
            />
        </div>
    );
}

function ConversationAdmin({ conversations, client, conversation_count }) {
  const [searchText, setSearchText] = useState("");

  const columns = [
    { 
        name: 'participant_1_name',
        label: "Sender",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => {
                if (!value) {
                    return "Deleted";
                  }
                const nameToFind = value;
                const conversation = conversations.length > 0 && conversations.find(
                    (conversation) =>
                        conversation.participant_1_name === nameToFind
                    );
                if (conversation) {
                  const participant1InventoryUserId = conversation.participant_1_inventory_user_id;
                  return conversation.participant_1_hidden 
                    ? <div><a href={`/admin/user_admin?inv_user=${participant1InventoryUserId}&drawerOpen=true`}>{value}</a> <VisibilityOffIcon style={{ color: '#4051b5' }} /></div> 
                    : <a href={`/admin/user_admin?inv_user=${participant1InventoryUserId}&drawerOpen=true`}>{value}</a>;
                }
            }
        },
    },
    { 
        name: 'participant_2_name',
        label: "Recipient",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => {
                if (!value) {
                    return "Deleted";
                  }
                const nameToFind = value;
                const conversation = conversations.length > 0 && conversations.find(
                    (conversation) =>
                        conversation.participant_2_name === nameToFind
                    );
                if (conversation) {
                  const participant2InventoryUserId = conversation.participant_2_inventory_user_id;
                  return conversation.participant_2_hidden 
                    ? <div><a href={`/admin/user_admin?inv_user=${participant2InventoryUserId}&drawerOpen=true`}>{value}</a> <VisibilityOffIcon style={{ color: '#4051b5' }} /></div> 
                    : <a href={`/admin/user_admin?inv_user=${participant2InventoryUserId}&drawerOpen=true`}>{value}</a>;
                }
            }
        },
    },
    { 
        name: 'deal_room_link',
        label: "Deal Room Link",
        options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
                const conversation = conversations[tableMeta.rowIndex];
                if (conversation.deal_room_name) {
                  const conversationDealRoomName = conversation.deal_room_name;
                  const conversationDealRoomPipelineId = conversation.deal_room_pipeline_id;
                  let conversationDealRoomWorkflowState = conversation.deal_room_workflow_state;
                  conversationDealRoomWorkflowState = conversationDealRoomWorkflowState.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                  return <a href={`/admin/pipelines/2?open_nav=true&card_id=${conversationDealRoomPipelineId}&open_tab=3`} rel="noreferrer">{conversationDealRoomName} ({conversationDealRoomWorkflowState})</a>;
                }
                return "";
              },
        },
    },
    { 
        name: 'created_at',
        label: "Created At Date",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (d) => {
                const formattedDate = moment.parseZone(d).format("YYYY-MM-DD");
                return formattedDate;
            },
        },
    },
    { 
        name: 'updated_at',
        label: "Last Message Sent Date",
        options: {
            filter: false,
            sortDirection: 'desc',
            sort: true,
            customBodyRender: (d, meta) => {
                const rowData = meta.rowData;
                const createdAt = rowData[2];
                const formattedDate = d ? moment.parseZone(d).format("YYYY-MM-DD") : moment.parseZone(createdAt).format("YYYY-MM-DD");
                return formattedDate;
            },
        },
    },
    {
      name: "message_count",
      label: "Message Count",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
        const conversationId = value;
        const conversation = conversations.find(conversation => conversation.id === conversationId);
        if (!conversation) {
          return null;
        }
        return (
            <ConversationContainer client={client} convoId={conversationId} />
        );
        },
      },
    },
  ];
  // get the page number from the URL parameter
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const rowsPerPage = searchParams.get("rows") || 10;
  const rowsPerPageOptions = [10, 15, 20, 100];
  let page = searchParams.get("page") || 1;
  page -= 1;
  const order = searchParams.get("order") || "desc";
  const orderBy = searchParams.get("order_by") || "updated_at";
  const search = searchParams.get("search") || "";

  const options = {
    selectableRows: false,
    filterType: "checkbox",
    search: false,
    serverSide: true,
    count: conversation_count,
    page: page,
    searchText: searchText,
    responsive: "vertical",
    filter: false,
    print: false,
    download: false,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    sortOrder: {
      name: orderBy,
      direction: order,
    },
    onSearchChange: (text) => {
      setSearchText(text);
    },
    onTableChange: (action, tableState) => {
      if (action == "filterChange") {
        setSearchText(tableState.searchText);
      }
      if (action == "sort") {
        window.location.href = `/admin/conversations?page=${tableState.page + 1}&search=${search}&order_by=${tableState.sortOrder.name}&order=${tableState.sortOrder.direction}&rows=${tableState.rowsPerPage}`;
      }
      if (action == "changeRowsPerPage") {
        window.location.href = `/admin/conversations?page=${tableState.page + 1}&search=${search}&order_by=${orderBy}&order=${order}&rows=${tableState.rowsPerPage}`;
      }
      if (action == "changePage") {
        let location =`/admin/conversations?page=${tableState.page + 1}&search=${tableState.searchText}&rows=${tableState.rowsPerPage}`;
        if (tableState.sortOrder.name) {
          location = `${location}&order_by=${tableState.sortOrder.name}&order=${tableState.sortOrder.direction}&rows=${tableState.rowsPerPage}`;
        }
        window.location.href = location;
      }
    }
  };

  const data = conversations.map((conversation) => [
      conversation.participant_1_name,
      conversation.participant_2_name,
      conversation.deal_room_name,
      conversation.created_at,
      conversation.updated_at,
      conversation.message_count,
      conversation.id,
      conversation.participant_1_inventory_user_id,
      conversation.participant_2_inventory_user_id,
      conversation.deal_room_pipeline_id,
      conversation.deal_room_workflow_state,
    ]);

  return (
    <div className="conversation-admin" style={{ maxHeight: 'calc(100vh - 115px)', overflow: 'scroll'}}>
      <SearchTable />
      <MUIDataTable 
      data={data} 
      columns={columns} 
      options={options} 
      />
    </div>
  );
}

export default ConversationAdmin;