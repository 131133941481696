import React from 'react';
import { Query } from 'react-apollo';
import { get, uniqBy, find, isEmpty } from 'lodash';
import gql from 'graphql-tag';
import qs from 'query-string';
import Button from '@material-ui/core/Button';
import client from '../../lib/apolloClient';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';
import CheckboxList from '../CheckboxList/CheckboxList';
import { update } from 'immutable';

class MatchMaking extends React.Component {
  addDealInterestUsers = () => {
    const { deal_interests, matches, checkedMatches, checkedDealInterests, updateState } = this.props;
    const newMatches = matches;
    checkedMatches.map(id => {
      const inventoryUserIndex = matches.findIndex(u => u.id === id);
      if (inventoryUserIndex > -1) {
        const inventoryUser = newMatches[inventoryUserIndex];
        deal_interests.push(inventoryUser);
        newMatches.splice(inventoryUserIndex, 1);
        updateState({ checkedMatches: [], deal_interests, matches: newMatches });
      }
    });
  };

  removeDealInterestUsers = () => {
    const { deal_interests, matches, checkedDealInterests, updateState } = this.props;
    const newDealInterests = deal_interests;
    checkedDealInterests.map(id => {
      const inventoryUserIndex = deal_interests.findIndex(u => u.id === id);
      if (inventoryUserIndex > -1) {
        const inventoryUser = newDealInterests[inventoryUserIndex];
        matches.unshift(inventoryUser);
        newDealInterests.splice(inventoryUserIndex, 1);
        updateState({ checkedMatches: [], deal_interests: newDealInterests, matches });
      }
    });
  };

  setDealInterests = dealInterests => {
    const { updateState } = this.props;
    updateState({ deal_interests: dealInterests });
  };

  render () {
    const {
      classes,
      checkedMatches,
      deal,
      rolodex,
      deal_interests,
      matches,
      includeRolodex,
      checkedDealInterests,
      value,
      publishState,
      updateState,
      userClick,
    } = this.props;

    if (!matches) return 'No matches found for this user';

    let sanMatches = [];

    if (isEmpty(deal_interests)) {
      sanMatches = matches;
    } else {
      sanMatches = deal_interests;
    }

    return (
      <div className={classes.container}>
        <div>
          <h4>&nbsp;&nbsp;Deal Interests:</h4>
          <div className={classes.invSearch}>
            <InventoryUserSearchField
              label="Add Another Inventory User"
              onChange={v => {
                let newMatches = sanMatches;
                if (!find(sanMatches, s => `${parseInt(s.id, 10)}` === `${parseInt(v.id, 10)}`)) {
                  sanMatches.unshift(v);
                }
                newMatches = newMatches.map(d => ({ ...d, id: parseInt(d.id, 10) }));
                updateState({ deal_interests: newMatches });
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.list}>
            <CheckboxList
              items={sanMatches}
              onToggle={checkedDealInterests => {
                updateState({ checkedDealInterests });
              }}
              showInfo
              showState
              infoClick={invUser => {
                userClick(invUser, client);
              }}
              checked={checkedDealInterests}
              movable
              onMove={array => updateState({ deal_interests: array })}
              showReasons
              onReasonChange={(inventoryUser, reason) => {
                const newDealInterests = deal_interests;

                const inventoryUserIndex = newDealInterests.findIndex(u => u.id === inventoryUser.id);
                if (inventoryUserIndex >= 0) {
                  newDealInterests[inventoryUserIndex].reason_for_suggestion = reason;
                }
                updateState({ deal_interests: newDealInterests });
              }}
              includeRolodex={includeRolodex}
            />
          </div>
          <div>
            {!isEmpty(checkedDealInterests) && (
              <Button
                className={classes.button}
                variant="outlined"
                disabled={deal.deal.deal_state === 'published'}
                onClick={this.removeDealInterestUsers}>
                {'Remove Users'}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MatchMaking;
