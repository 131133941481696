/* eslint-disable import/prefer-default-export */

import * as api from '../lib/api';

export default class crudActionCreators {
  constructor (objectName, baseUrl) {
    this.objectName = objectName;
    this.LIST = `${this.objectName}_LIST`;
    this.UPDATE = `${this.objectName}_UPDATE`;
    this.ADD = `${this.objectName}_ADD`;
    this.DELETE = `${this.objectName}_DELETE`;
    this.PUT = `${this.objectName}_PUT`;
    this.baseUrl = baseUrl;
  }

  updateAction = data => ({
    type : this.UPDATE,
    data,
  });

  loadAction = id => {
    return dispatch => {
      dispatch({ type: LIST, company: {}, loading: true });
      api.get(`${this.baseUrl}.json`).then(response => {
        dispatch({
          type    : this.LIST,
          data    : response.data,
          loading : false,
        });
      });
    };
  };

  addAction = object => {
    return dispatch => {
      dispatch({ type: this.ADD, error: null, data: null, close: false, loading: true });
      const payload = {};
      payload[this.objectName] = object;
      api.post(`${this.baseUrl}`, payload).then(response => {
        dispatch({
          type    : this.ADD,
          data    : JSON.parse(response.data),
          error   : response.error,
          close   : response.status === 200,
          loading : false,
        });
        setInterval(() => {
          dispatch({
            type    : this.ADD,
            error   : null,
            close   : false,
            loading : false,
          });
        }, 8000);
      });
    };
  };

  deleteAction = object => {
    const object_id = object.id;

    return dispatch => {
      dispatch({ type: this.DELETE, data: null, error: null, close: false, loading: true });
      api.del(`${this.baseUrl}/${object_id}.json`).then(response => {
        dispatch({
          type      : this.DELETE,
          object_id,
          error     : response.error,
          loading   : false,
        });
        setInterval(() => {
          dispatch({
            type    : this.DELETE,
            error   : null,
            loading : false,
          });
        }, 8000);
      });
    };
  };

  putAction = object => {
    return dispatch => {
      dispatch({ type: this.PUT, data: null, error: null, close: false, loading: true });
      delete object.modified;
      const payload = {};
      payload[this.objectName] = object;
      api
        .put(`${this.baseUrl}/${object.id}.json`, payload)
        .then(response => {
          dispatch({
            type    : this.PUT,
            data    : response.data,
            error   : response.error,
            close   : response.status === 200,
            loading : false,
          });
          setInterval(() => {
            dispatch({
              type    : this.PUT,
              error   : null,
              close   : false,
              loading : false,
            });
          }, 8000);
        })
        .catch(function (error) {
          dispatch({
            type    : this.PUT,
            data    : null,
            error   : error.response,
            close   : false,
            loading : false,
          });
        });
    };
  };
}
