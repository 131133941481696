import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import Fab from '@material-ui/core/Fab';
import CurrentPipelineActivityCard from '../Staff/CurrentPipelineActivityCard';

const clickViewAll = () => {
  window.open(`/admin/pipelines`, '_self');
};

const CurrentPipelineActivities = ({ owned_cards, classes, railsContext }) => {
  return (
    <div className={classes.root}>
      {!isEmpty(owned_cards) && (
        <div>
          {owned_cards.map(c => <CurrentPipelineActivityCard pipeline={c} />)}
          <div className={classes.viewAll}>
            <button type="button" className={classes.contentButton} onClick={() => clickViewAll()}>
              <span className={classes.content}>View All Cases</span>
            </button>
          </div>
        </div>
      )}
      {isEmpty(owned_cards) && (
        <div>
          <Fab
            style={{ width: '70%', marginTop: '120px' }}
            variant="extended"
            size="medium"
            color="primary"
            aria-label="Add"
            onClick={() => clickViewAll()}>
            View Pipelines
          </Fab>
        </div>
      )}
    </div>
  );
};

const styles = {
  root          : {
    width     : '100%',
    marginTop : 5,
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
};

CurrentPipelineActivities.propTypes = {
  owned_cards : PropTypes.array,
  classes     : PropTypes.object.isRequired,
};

CurrentPipelineActivities.defaultProps = {
  owned_cards : [],
};

export default withStyles(styles)(CurrentPipelineActivities);
