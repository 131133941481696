import { CFA_RESULT_UPDATE, CFA_QUERY_UPDATE } from '../constants/cfaConstants';

export const updateCfaResults = cfaResults => ({
  type  : CFA_RESULT_UPDATE,
  cfaResults,
});

export const updateCfaQuery = cfaQuery => ({
  type  : CFA_QUERY_UPDATE,
  cfaQuery,
});