import gql from 'graphql-tag';

export const CalibrationQuery = gql`
  query getCalibrationSessions(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $status_eq_any: [String]
    $name_cont: String
    $nameSearch: String
    $page: Int
    $rowsPerPage: Int
  ) {
    getManagers {
      id
      name
    }
    getTaskCount
    getCalibrationSessions(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      status_eq_any: $status_eq_any
      name_cont: $name_cont
      nameSearch: $nameSearch
      page: $page
      rowsPerPage: $rowsPerPage
    ) {
      id
      name
      startDate
      endDate
      createdAt
      status
      numberOfAdvisors
      numberOfParticipants
      facilitators {
        id
        name
      }
      completion
      completedInvUserCount
      completedInvUserValues
      completedPopulating
    }
    agentWordForCompany
    getCalibrationSessionsCount(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      status_eq_any: $status_eq_any
      name_cont: $name_cont
      nameSearch: $nameSearch
      page: $page
      rowsPerPage: $rowsPerPage
    )
  }
`;

export const checkInventoryUserCountQuery = gql`
  query checkCalibrationPotentialInventoryUserCount(
    $state: [String]
    $start_date_min: Int
    $start_date_max: Int
    $age_min: Int
    $age_max: Int
    $managed_by_user_id: [ID!]
    $branch: String
    $region: String
    $division: String
    $catastrophic_plan_on_file: Boolean
    $industry_length_of_service_min: Int
    $industry_length_of_service_max: Int
  ) {
    checkCalibrationPotentialInventoryUserCount(
      state: $state
      startDateMin: $start_date_min
      startDateMax: $start_date_max
      ageMin: $age_min
      ageMax: $age_max
      managedByUserId: $managed_by_user_id
      branch: $branch
      region: $region
      division: $division
      catastrophicPlanOnFile: $catastrophic_plan_on_file
      industryLengthOfServiceMin: $industry_length_of_service_min
      industryLengthOfServiceMax: $industry_length_of_service_max
    )
  }`
 

export const createCalibrationSessionMutation = gql`
  mutation createCalibrationSession(
    $name: String
    $start_date: String
    $end_date: String
    $value_column_name: String
    $calibration_participants: [ID!]
    $calibration_facilitators: [ID!]
    $state: [String!]
    $start_date_min: Int
    $start_date_max: Int
    $age_min: Int
    $age_max: Int
    $managed_by_user_id: [ID!]
    $branch: String
    $region: String
    $division: String
    $catastrophic_plan_on_file: Boolean
    $industry_length_of_service_min: Int
    $industry_length_of_service_max: Int
  ) {
    createCalibrationSession(
      name: $name
      start_date: $start_date
      end_date: $end_date
      value_column_name: $value_column_name
      calibration_participants: $calibration_participants
      calibration_facilitators: $calibration_facilitators
      state: $state
      start_date_min: $start_date_min
      start_date_max: $start_date_max
      age_min: $age_min
      age_max: $age_max
      managed_by_user_id: $managed_by_user_id
      branch: $branch
      region: $region
      division: $division
      catastrophic_plan_on_file: $catastrophic_plan_on_file
      industry_length_of_service_min: $industry_length_of_service_min
      industry_length_of_service_max: $industry_length_of_service_max
    ) {
      id
    }
  }
`;

export const updateCalibrationSessionMutation = gql`
  mutation updateCalibrationSession(
    $id: ID!
    $name: String
    $start_date: String
    $end_date: String
    $status: String
    $value_column_name: String
    $calibration_participants: [ID!]
    $calibration_facilitators: [ID!]
    $inv_users: [ID!]
    $advisor_filter: InventoryUserFilterInput
    $calibration_value: String
    $calibrated_on: String
  ) {
    updateCalibrationSession(
      id: $id
      name: $name
      start_date: $start_date
      end_date: $end_date
      status: $status
      value_column_name: $value_column_name
      calibration_participants: $calibration_participants
      calibration_facilitators: $calibration_facilitators
      inv_users: $inv_users
      advisor_filter: $advisor_filter
      calibration_value: $calibration_value
      calibrated_on: $calibrated_on
    ) {
      id
    }
  }
`;

export const getCalibrationSessionQuery = gql`
  query getCalibrationSession($id: ID!, $offset: Int, $limit: Int, $order: String, $orderBy: String, $name_cont: String) {
    company {
      id
      name
      feature_types
      business_type
    }
    getManagers {
      id
      name
      profile_picture
      inventory_user {
        id
        name
        profile_picture
      }
    }
    getCalibrationSession(id: $id, offset: $offset, limit: $limit, order: $order, orderBy: $orderBy) {
      id
      name
      startDate
      endDate
      createdAt
      status
      valueColumnName
      filterJson
      impactVsRisk
      pvp
      pvpValues
      impactVsRiskValues
      calImpactVsRisk
      calPvp
      calPvpValues
      calImpactVsRiskValues
      completedInvUserCount
      completedInvUserValues
      completedPopulating
      createdAt
      branch
      region
      division
      ageMin
      ageMax
      industryLengthOfServiceMin
      industryLengthOfServiceMax
      catastrophicPlanOnFile
      state
      managedByUser {
        id
        name
      }
      participants {
        id
        name
      }
      facilitators {
        id
        name
      }
      inventoryUserCount
      inventoryUsers(offset: $offset, limit: $limit, order: $order, orderBy: $orderBy, nameContains: $name_cont) {
        id
        name
        email
        birth_date
        city
        province
        rev_yield
        clients
        total_premium
        total_assets
        key_asset
        recurring_revenue
        risk_of_loss
        impact_of_loss
        risk_of_loss_num
        impact_of_loss_num
        pvp_value
        marketplace_status
        start_date
        has_user
        reason_for_loss
        manager_email
        transition_goals
        is_customer
        is_obfuscated
        profile_picture
        life_ifp
        avg_client_age
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        segregated_funds_aua
        total_assets
        mcpi_ifp
        private_products_ifp
        crop_hail_ifp
        crop_hail_with_wind_ifp
        farm_insurance_ifp
        price_products_ifp
        replant_supplement_ifp
        industry_length_of_service
        iu_tags {
          id
          name
          __typename
        }
        calibration_session_inventory_user(calibrationSessionId: $id) {
          id
          manualRiskOfLoss
          manualImpactOfLoss
          manualPvp
          calculatedRiskOfLoss
          calculatedImpactOfLoss
          calculatedPvp
          completed
        }
        calibration_sessions {
          id
          name
        }
        candidate_session_completed(calibration_session_id: $id)
        manager {
          name
          email
        }
        user {
          id
          annual_revenue
          recurring_revenue
          hidden
          profile_percentage_complete
          created_at
          product_mix {
            id
            aum_stars
            p_c_stars
            group_stars
            health_and_life_stars
            life_ifp
            home_ifp
            auto_ifp
            commercial_ifp
            health_ifp
            group_ifp
            mutual_funds_aum
            securities_aum
            annuities_aua
            segregated_funds_aua
            total_assets
            mcpi_ifp
            private_products_ifp
            crop_hail_ifp
            crop_hail_with_wind_ifp
            farm_insurance_ifp
            price_products_ifp
            replant_supplement_ifp
            gic_fixed_income
            insurance
            equity
          }
        }
      }
    }
    getInventoryUserTags {
      id
      name
      __typename
    }
  }
`;

export const deleteCalibrationSessionMutation = gql`
  mutation deleteCalibrationSession($id: ID!) {
    deleteCalibrationSession(id: $id)
  }
`;

export const exportCalibrationSessionMutation = gql`
  mutation exportCalibrationSession($id: ID!) {
    exportCalibrationSession(id: $id)
  }
`;

export const toggleCalibrationSessionUserMutation = gql`
  mutation toggleCalibrationSessionUser($id: ID!, $inventory_user_id: ID!, $calibration_session_id: ID!) {
    toggleCalibrationSessionUser(id: $id,
                                  inventory_user_id: $inventory_user_id,
                                  calibration_session_id: $calibration_session_id,
                                  )
  }
`;

export default CalibrationQuery;
