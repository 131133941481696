import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListSubheader from '@material-ui/core/ListSubheader';
import Notification from './Notification';

const useStyles = makeStyles(theme => ({
  root   : {
    minWidth        : '42ch',
    maxWidth        : '52ch',
    backgroundColor : theme.palette.background.paper,
  },
  inline : {
    display : 'inline',
  },
}));

const NotificationList = props => {
  const classes = useStyles();
  const { notifications } = props;
  if (notifications.length < 1) {
    return (
      <List className={classes.root}>
        <ListItem alignItems="flex-start"><FormattedMessage id="notifications.you_currently_dont_have" /></ListItem>
      </List>
    );
  }
  return (
    <List className={classes.root} subheader={<ListSubheader><FormattedMessage id="notifications.notifications" /></ListSubheader>}>
      {notifications.map(notification => (
        <Fragment key={notification.id}>
          <Notification notification={notification} />
          <Divider variant="inset" component="li" />
        </Fragment>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  notifications : PropTypes.array,
};

NotificationList.defaultProps = {
  notifications : [],
};

export default NotificationList;
