import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import IconButton from '@material-ui/core/IconButton';
import { findIndex } from 'lodash';

class FBColumnDialog extends React.Component {
  state = {
    open   : false,
    values : [],
  };

  componentWillReceiveProps (nextProps) {
    const { selectedColumns } = this.props;
    if (selectedColumns !== nextProps.selectedColumns) {
      this.setState({ selectedColumns });
    }
  }

  handleClose = () => {
    const { updateColumns } = this.props;
    const { newCols, values, selectedColumns } = this.state;
    const cols = selectedColumns.map(c => c.id);
    this.setState({ open: false, values: [] });
    updateColumns(newCols || cols, values);
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleCheckClick = (checked, value, column) => {
    const { selectedColumns, values } = this.state;
    values.push(value);
    const newCols = selectedColumns.map(c => c.id);
    if (checked) {
      newCols.push(value);
      selectedColumns.push(column);
    } else {
      const i = findIndex(selectedColumns || [], c => c.id === value);
      newCols.splice(i, 1);
      selectedColumns.splice(i, 1);
    }
    this.setState({ selectedColumns, newCols, values });
  };

  render () {
    const { columns } = this.props;
    const { open, selectedColumns } = this.state;
    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Select Columns">
          <IconButton aria-label="Select Columns">
            <ViewColumnIcon onClick={this.handleClickOpen} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Columns</DialogTitle>
          <DialogContent>
            {columns.map(column => (
              <div key={column.id}>
                <Checkbox
                  checked={findIndex(selectedColumns || [], c => c.id === column.id) > -1}
                  value={column.id}
                  onClick={e => this.handleCheckClick(e.target.checked, e.target.value, column)}
                />
                {column.label}
              </div>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

FBColumnDialog.propTypes = {
  selectedColumns : PropTypes.object.isRequired,
  columns         : PropTypes.object.isRequired,
  updateColumns   : PropTypes.func.isRequired,
};

export default FBColumnDialog;
