import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get, capitalize, isEmpty, groupBy, mean, compact } from 'lodash';
import numeral from 'numeral';

const formatData = (data, isCurrency) => {
  if (isCurrency) {
    return numeral(data).format('($0,0a)');
  }
  return data;
};

const getIcon = {
  age        : 'fas fa-birthday-cake fa-inverse',
  years      : 'fas fa-suitcase fa-inverse',
  dollar     : 'fas fa-dollar-sign fa-inverse',
  gears      : 'fas fa-cogs fa-inverse',
  calculator : 'fas fa-calculator fa-inverse',
  shake      : 'fas fa-handshake fa-inverse',
};

const Infographic = props => {
  const { data, icon, isCurrency, classes, print_view } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={print_view ? 2 : 3}>
          <div className={print_view ? classes.iconBackgroundPrint : classes.iconBackground}>
            <i className={`${getIcon[icon]} ${print_view ? classes.iconPrint : classes.icon}`} />
          </div>
        </Grid>
        <Grid item xs={print_view ? 10 : 9}>
          <div style={{ padding: 10 }}>
            <div className={print_view ? classes.bigNumPrint : classes.bigNum}>{formatData(data, isCurrency)}</div>
            <div className={print_view ? classes.bigTextPrint : classes.bigText}>{props.children}</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  root                : {
    width  : '100%',
    margin : '20px 0',
  },
  rootPrint           : {
    width  : '100%',
    margin : '50px 0',
  },
  iconBackground      : {
    background    : '#72B1D7',
    width         : '80px',
    height        : '80px',
    borderRadius  : '50%',
    textAlign     : 'center',
    verticalAlign : 'middle',
  },
  iconBackgroundPrint : {
    background    : '#72B1D7',
    width         : '100px',
    height        : '100px',
    borderRadius  : '50%',
    textAlign     : 'center',
    verticalAlign : 'middle',
  },
  icon                : {
    fontSize : 40,
    position : 'relative',
    top      : 20,
  },
  iconPrint           : {
    fontSize : 80,
    position : 'relative',
    top      : 20,
  },
  table               : {
    minWidth : 650,
  },
  container           : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu                : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker          : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber           : { fontSize: 50 },
  subHeader           : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel         : { fontSize: 16 },
  selectatron         : { marginRight: 10 },
  formControl         : {
    margin   : 2,
    minWidth : 120,
  },
  bigNum              : {
    fontWeight : 200,
    fontSize   : 25,
    color      : '#008cff',
  },
  bigText             : {
    fontWeight : 400,
    fontSize   : 15,
    color      : '#6e6e6e',
    marginTop  : 4,
  },
  bigNumPrint         : {
    fontWeight : 200,
    fontSize   : 45,
    color      : '#008cff',
  },
  bigTextPrint        : {
    fontWeight : 400,
    fontSize   : 27,
    color      : '#6e6e6e',
    marginTop  : 4,
  },
};

const InfographicWithStyles = withStyles(styles)(Infographic);
export default InfographicWithStyles;
