import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import { Select, MenuItem } from '@material-ui/core';

const AdvancedInfo = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return (
    <React.Fragment>
        <p>
          <span className={classes.sub_heading}>&nbsp;<FormattedMessage id="calculators.cfa.length_of_cash_flow" /></span>

          <Select
            value={query.cashFlowLength}
            onChange={e => {
              updateCfaQuery({ ...query, cashFlowLength: parseInt(e.target.value) })
            }}
            className={classes.percentage}
          >
            {[...Array(10).keys()].map((_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {index + 1}
              </MenuItem>
            ))}
          </Select>

          &nbsp;
          {showOrdinaryTaxes && <React.Fragment>
            <span className={classes.sub_heading}>&nbsp;<FormattedMessage id="calculators.cfa.cash_flow_production_shift" /></span>
            <TextField
              type="number"
              className={classes.percentage}
              value={query.cashFlowProductionShift}
              onChange={e => {
                updateCfaQuery({ ...query, cashFlowProductionShift: parseInt(e.target.value) })
              }}
            />
          </React.Fragment>}
        </p>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(AdvancedInfo));