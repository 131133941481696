import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Query } from "react-apollo";
import { get } from "lodash";
import * as actions from "../actions/opportunityActionCreators";
import * as userActions from "../actions/userActionCreators";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoginOptionDialog from "../components/CandidateSignIn/LoginOptionDialog";
import { trackAnalyticsEvent } from "../lib/analytics";

const CandidateQuery = gql`
  query CandidateQuery {
    getCurrentCandidate {
      id
      has_postal_code
      practice_complete
      strengths_complete
      product_mix_complete
      planning_info_complete
      has_candidate_profile
      new_in_industry
      candidate_goals
      company {
        id
        is_managed
        display_name
        agent_word
        recruitment_team_word
        recruitment_agent_word
        recruitment_organization_word
        business_type
        disable_recruitment_new_to_business_question
        business_continuity_states {
          id
          name
        }
        succession_planning_states {
          id
          name
        }
      }
    }
    availableDesignations {
      id
      name
      fr_name
    }
    availableStrengths
    availableProducts
    availableAdvisorLicenses
    agentWordForCompany
  }
`;

export const JoinContainer = ({
  match,
  classes,
  user,
  updateUser,
  updateProductMix,
  history,
}) => (
  <Query
    query={CandidateQuery}
    onCompleted={(data) => {
      const can = get(data, "getCurrentCandidate", {});
      can.transition_goals = get(can, "candidate_goals", []);
      updateUser(can);
    }}
  >
    {({ loading, error, data, refetch }) => {
      if (loading) return <CircularProgress />;
      if (error) return `Error!: ${error}`;
      return (
        <Paper className={classes.paper}>
          <LoginOptionDialog
            open={!match.params.embed}
            user={user}
            updateUser={updateUser}
            updateProductMix={updateProductMix}
            availableDesignations={data.availableDesignations}
            availableStrengths={data.availableStrengths}
            availableProducts={data.availableProducts}
            availableAdvisorLicenses={data.availableAdvisorLicenses}
            agentWord={data.agentWordForCompany}
            refetch={refetch}
            history={history}
          />
        </Paper>
      );
    }}
  </Query>
);

const styles = {
  paper: {
    maxWidth: 825,
    margin: "40px auto 600px auto",
    padding: 10,
    minHeight: "calc(100vh - 300px);",
  },
};

const mapStateToProps = (state) => ({
  state,
  user: state.user,
  intl: state.intl,
});

JoinContainer.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

JoinContainer.defaultProps = { user: {} };

export default connect(mapStateToProps, { ...actions, ...userActions })(
  withStyles(styles)(JoinContainer)
);
