import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { toggleAsLimitedAccess } from './InventoryUserQueries';

class InventoryUserAssignAsLimitedAccess extends React.Component {
  state = {
    open                       : false,
    assignUsersAsLimitedAccess : true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleAssignAsLimitedAccess = () => {
    const { assignUsersAsLimitedAccess } = this.state;
    this.setState({ assignUsersAsLimitedAccess: !assignUsersAsLimitedAccess });
  };

  render () {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, assignUsersAsLimitedAccess } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Are you sure you want to remove limited access to ${selected.length} users?`;
    let bodyText = "These user's profiles will be fully visible.";
    let assignAsSellerText = 'un-assignAsSeller';

    if (open) {
      const selections = selected.map(s => find(data, [ 'id', s ]));
      validSelections = filter(selections, [ 'has_user', true ]);
      invalidSelections = filter(selections, [ 'has_user', false ]);

      if (assignUsersAsLimitedAccess) {
        message = `Are you sure you want to limit access to ${selected.length} users?`;
        bodyText = "These user's profiles will have limited information displayed.";
        assignAsSellerText = 'assignAsSeller';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Assign as Limited User / Unassign as Limited User">
          <IconButton aria-label="Assign as Limited User / Unassign as Limited User">
            <i class="material-icons" onClick={this.handleClickOpen}>
              attach_money
            </i>
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={assignUsersAsLimitedAccess} onClick={this.toggleAssignAsLimitedAccess} /> Assign as
            Limited User
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot be ${assignAsSellerText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables : {
                    usersToAssignAsLimitedAccess : validSelections.map(iu => iu.user.id),
                    assignedAsLimitedAccess      : assignUsersAsLimitedAccess,
                  },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              disabled={validSelections.length === 0}
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserAssignAsLimitedAccess.propTypes = {
  selected          : PropTypes.array.isRequired,
  changePage        : PropTypes.func.isRequired,
  data              : PropTypes.object.isRequired,
  mutate            : PropTypes.func.isRequired,
  loadBulkItemsList : PropTypes.func.isRequired,
};

export default graphql(toggleAsLimitedAccess)(InventoryUserAssignAsLimitedAccess);
