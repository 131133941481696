import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { findIndex, snakeCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import Strengths from './Strengths';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($tag_list: [String!]) {
    updateCurrentUser(tag_list: $tag_list)
  }
`;

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

let newStrengthsToAdd = [];
class StrengthsComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      strengths : '',
    };
  }
  updateUser = () => {
    const { mutate, onNext, professionalStrengths } = this.props;

    mutate({
      variables : {
        tag_list : professionalStrengths,
      },
    })
      .then(response => {
        onNext();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  toggleStrengths = e => {
    let { professionalStrengths } = this.props;
    const { updateAction } = this.props;

    const index = findIndex(professionalStrengths, o => snakeCase(o) === snakeCase(e.target.value));
    professionalStrengths = [ ...professionalStrengths ];
    if (index > -1) {
      professionalStrengths.splice(index, 1);
    } else {
      professionalStrengths.push(snakeCase(e.target.value));
    }
    updateAction({ tag_list: professionalStrengths });
  };

  addStrength = () => {
    const { strengths } = this.state;
    this.setState({ strengths: '' });
    this.toggleStrengths({ target: { value: strengths } });
  };

  render () {
    const {
      professionalStrengths,
      availableStrengths,
      intl,
      businessType,
      saveUser,
      stylesheetName,
      fromProfile,
    } = this.props;
    return (
      <div className="content">
        <b>
          {businessType === 'wealth_rj' && (<FormattedMessage id="profile_page.strengths_component.practice_areas" />)}
          {!businessType === 'wealth_rj' && (
            <FormattedMessage id="profile_page.headline_section.professional_strengths" />
          )}
        </b>
        <p>
          {businessType === 'wealth_rj' && (<FormattedMessage id="profile_page.strengths_component.please_choose_the_practice_areas" />)}
          {!businessType === 'wealth_rj' && (
            <FormattedMessage id="profile_page.headline_section.professional_strengths_choose" />
          )}
        </p>
        {professionalStrengths.length > 5 && <div style={{ color: 'red' }}><FormattedMessage id="profile_page.strengths_component.please_choose_or_less" /></div>}
        <div>
          <div className="col-md-9">
            <TextField
              id="strengths"
              label={intl.formatMessage({ id: `shared.strengths` })}
              type="text"
              value={this.state.strengths}
              onChange={e => {
                this.setState({ strengths: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="col-md-3">
            <button onClick={this.addStrength} className="btn btn-default" style={{ padding: '12px' }}>
              <AddIcon />
            </button>
          </div>
        </div>
        <br />
        <br />
        <Strengths
          availableStrengths={availableStrengths}
          professionalStrengths={professionalStrengths}
          toggleStrengths={this.toggleStrengths}
          stylesheetName={stylesheetName}
          fromProfile={fromProfile}
        />
        <br />
        <br />
        <br />
        <div className="text-center">
          {this.props.onBack && (
            <button
              className="btn btn-default"
              style={{ padding: '12px 24px', float: 'left' }}
              onClick={this.props.onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

StrengthsComponent.propTypes = {
  updateAction      : PropTypes.func,
  availbleStrengths : PropTypes.array,
  fromProfile       : PropTypes.bool,
};

StrengthsComponent.defaultProps = {
  number_of_clients : 500,
  fromProfile       : false,
};

const StrengthsComponentWithStyles = injectIntl(withStyles(styles)(StrengthsComponent));
export default graphql(UpdateCurrentUser)(StrengthsComponentWithStyles);
