import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import numeral from 'numeral';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

class ListPriceSelector extends Component {
  constructor (props) {
    super(props);
    const { deal } = this.props;
    let selectedInput = 'TBD';

    if (parseFloat(deal.list_price) === Math.round(parseFloat(get(deal, 'revenue_multiple')))) {
      selectedInput = 'RevenueMultiple';
    } else if (parseFloat(deal.list_price) > 0) {
      selectedInput = 'Custom';
    } else {
      selectedInput = 'TBD';
    }

    this.state = { selectedInput };
  }

  handleChange = event => {
    const { deal, numericFieldChange, fieldChange } = this.props;
    this.setState({ selectedInput: event.target.value });
    if (event.target.value === 'TBD') {
      fieldChange('list_price')({ target: { value: 0 } });
    } else if (event.target.value === 'DCF') {
      fieldChange('list_price')({ target: { value: parseInt(deal.dcf) } });
    } else if (event.target.value === 'RevenueMultiple') {
      fieldChange('list_price')({ target: { value: parseInt(deal.revenue_multiple) } });
    }
  };

  render () {
    const { classes, deal, numericFieldChange, fieldChange, user, saveDeal, intl } = this.props;
    const { selectedInput } = this.state;
    return (
      <span>
        <FormControl component="fieldset" className={classes.formControl} fullWidth>
          <FormLabel component="legend" className={classes.label}>
            <FormattedMessage id="dr_wizard.list_price" />
          </FormLabel>
          <RadioGroup
            aria-label={intl.formatMessage({ id: 'deal_page.list_price' })}
            name="listprice1"
            className={classes.group}
            value={selectedInput}
            onChange={this.handleChange}>
            <FormControlLabel
              value="TBD"
              control={<Radio />}
              label={`${intl.formatMessage({ id: 'dr_wizard.tbd' })}`}
            />
            {get(user, 'valuation_unlocked') && (
              <FormControlLabel
                value="RevenueMultiple"
                control={<Radio />}
                label={`${intl.formatMessage({ id: 'dr_wizard.revenue_multiple' })} (${numeral(
                  get(deal, 'revenue_multiple')
                ).format('$0,0')})`}
              />
            )}
            {get(user, 'dcf_valuation_unlocked') && (
              <FormControlLabel
                value="DCF"
                control={<Radio />}
                label={`${intl.formatMessage({ id: 'dr_wizard.dcf' })} (${numeral(deal.dcf).format('$0,0')})`}
              />
            )}
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label={`${intl.formatMessage({ id: 'dr_wizard.custom' })}`}
            />
          </RadioGroup>

          {selectedInput === 'Custom' && (
            <TextField
              id="list_price"
              label={`${intl.formatMessage({ id: 'dr_wizard.list_price' })}`}
              helperText={<span />}
              multiline
              size="medium"
              rows={1}
              variant="filled"
              classes={{ root: classes.label, label: classes.label }}
              InputProps={{
                startAdornment : (
                  <InputAdornment className={classes.adorn} position="start">
                    $
                  </InputAdornment>
                ),
                className      : classes.whiteFilled,
              }}
              value={numeral(deal.list_price).format('0,0[.]00') || 0}
              onChange={numericFieldChange('list_price')}
              onBlur={() => saveDeal()}
            />
          )}
        </FormControl>
        <p>
          <FormattedMessage id="dr_wizard.please_note" />
        </p>
      </span>
    );
  }
}

const styles = {
  adorn       : { paddingBottom: 14 },
  label       : {
    fontSize      : 12,
    paddingBottom : 8,
  },
  group       : {
    display       : 'flex',
    flexDirection : 'row',
  },
  formControl : {
    textAlign : 'left',
    padding   : '0 14px',
  },
};

const ListPriceSelectorWithStyles = withStyles(styles)(ListPriceSelector);
export default injectIntl(ListPriceSelectorWithStyles);
