import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { get, range, isNull } from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import { FormattedMessage, injectIntl } from "react-intl";

export const validate = () => {
  return true;
};

const DownPayment = ({ list, query, classes, updateCfaQuery, intl, company }) => {
  const [fixedPaymentTooHigh, setFixedPaymentTooHigh] = React.useState(false);
  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  
  const validate = () => {
    let valid = true;

    if (query.buyerHasLumpSumPayment == undefined) {
      valid = false;
    } else if (query.lumpSumPayment) {
      if (!query.buyerLumpSumAmount || parseFloat(query.buyerLumpSumAmount) < 1) {
        valid = false;
      }
    }

    if (query.buyerIsFinancing == undefined) {
      valid = false;
    } else if (query.buyerIsFinancing) {
      if (!query.buyersFinancingAmount || parseFloat(query.buyersFinancingAmount) < 1) {
        valid = false;
      }

      if (!query.buyerFinancingYears || parseFloat(query.buyerFinancingYears) < 1) {
        valid = false;
      }
    }

    if (query.valid != valid) {
      updateCfaQuery({ ...query, valid });
    }
  }

  validate();
  return <div>
    <h2><FormattedMessage id="calculators.cfa.terms_of_deal" /></h2>
    <p>
      <FormattedMessage id="calculators.cfa.terms_copy" />
    </p>
    <h3><FormattedMessage id="calculators.cfa.down_payment" /></h3>
    <div>
      <dl>
        <dt><FormattedMessage id="calculators.cfa.down_payment_q" /></dt>
        <dd>
          <RadioGroup className="right" row aria-label="numberOfBuyers" name="numberOfBuyers" value={`${query.buyerHasLumpSumPayment}`} onChange={e => {
            const lumpSumPayment = Boolean("true" == e.target.value);
            let clear = {};
            if (!lumpSumPayment) {
              clear = {
                buyerLumpSumAmount: 0,
                buyersFinancingAmount: 0,
                buyerIsFinancing: false,
                buyerFinancingYears: 0,
              };
            }

            updateCfaQuery({ ...query, ...clear, buyerHasLumpSumPayment: lumpSumPayment, })
          }}>
            <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
            <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
          </RadioGroup>
        </dd>
      </dl>
      {query.buyerHasLumpSumPayment &&
        <div className={classes.question}>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.how_much_down" /></dt>
            <dd><CurrencyInput
              style={{ width: 'inherit' }}
              value={query.buyerLumpSumAmount}
              onValueChange={value => {
                let val = query.buyersFinancingAmount;
                if (val > value) {
                  val = value;
                }
                updateCfaQuery({ ...query, buyerLumpSumAmount: parseFloat(value), buyersFinancingAmount: val })
              }}
            /></dd>
          </dl>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.year_given" /></dt>
            <dd>
              <select onChange={e => {
                updateCfaQuery({ ...query, buyerLumpSumYear: parseInt(e.target.value) })
              }
              }>
                <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                {range(1, query.cashFlowLength, 1).map(year =>
                  <option value={year} checked={query.buyerLumpSumYear == year}>{year}</option>
                )}
              </select>
            </dd>
          </dl>
        </div>

      }
      <br /><br />
      <dl>
        <dt><FormattedMessage id="calculators.cfa.utilize_financing" /></dt>
        <dd>
          <RadioGroup className="right"
            row
            aria-label="numberOfBuyers"
            name="numberOfBuyers"
            disabled={!query.buyerHasLumpSumPayment}
            value={`${query.buyerIsFinancing}`}
            onChange={e => {
              const buyerIsFinancing = Boolean("true" == e.target.value);
              let clear = {};
              if (!buyerIsFinancing) {
                clear = { buyersFinancingAmount: 0 };
              }

              updateCfaQuery({ ...query, ...clear, buyerIsFinancing, })
            }}>
            <FormControlLabel value="true" disabled={!query.buyerHasLumpSumPayment} control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
            <FormControlLabel value="false" disabled={!query.buyerHasLumpSumPayment} control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
          </RadioGroup>
        </dd>
      </dl>
      {(query.buyerIsFinancing || query.buyerFinancingAmount > 0) &&
        <div className={classes.question}>
          {fixedPaymentTooHigh && <div className={classes.error}>
            Finance Amount Cannot Be Greater Than Down Payment
          </div>}
          <dl>
            <dt><FormattedMessage id="calculators.cfa.how_much_to_finance" /></dt>
            <dd><CurrencyInput
              style={{ width: 'inherit' }}
              value={query.buyersFinancingAmount}
              onValueChange={value => {
                let val = value;
                if (val > query.buyerLumpSumAmount) {
                  val = query.buyerLumpSumAmount;
                  setFixedPaymentTooHigh(true);
                } else {
                  setFixedPaymentTooHigh(false);
                }
                updateCfaQuery({ ...query, buyersFinancingAmount: parseFloat(val) })
              }}
            /></dd>
          </dl>

          <dl>
            <dt><FormattedMessage id="calculators.cfa.how_many_years_to_finance" /></dt>
            <dd>
              <select onChange={e => {
                updateCfaQuery({ ...query, buyerFinancingYears: parseInt(e.target.value) })
              }
              }>
                <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                {range(1, 11, 1).map(year =>
                  <option value={year} checked={query.buyerFinancingYears == year}>{year}</option>
                )}
              </select>
            </dd>
          </dl>
        </div>
      }
      <br />
      <br />
      { showCheckSwap && <React.Fragment>
        <div>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.will_the_parties_utilize_a_check_swap" /></dt>
            <dd>
              <RadioGroup className="right"
                row
                aria-label="hasCheckSwap"
                name="hasCheckSwap"
                value={`${query.hasCheckSwap}`}
                onChange={e => {
                  const hasCheckSwap = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!hasCheckSwap) {
                    // clear = {buyersFinancingAmount: 0};
                  }

                  updateCfaQuery({ ...query, ...clear, hasCheckSwap, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
            </dd>
          </dl>
        </div>
        {query.hasCheckSwap && (<div className={classes.question}>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.what_year_to_check_swap_to" /></dt>
            <dd>
              <select onChange={e => {
                updateCfaQuery({ ...query, checkSwapYear: parseInt(e.target.value) })
              }
              }>
                <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                {range(1, query.cashFlowLength + 1, 1).map(year =>
                  <option value={year} checked={query.checkSwapYear == year}>{year}</option>
                )}
              </select>
            </dd>
          </dl>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.what_is_the_check_swap_amount" /></dt>
            <dd><CurrencyInput
              style={{ width: 'inherit' }}
              value={query.checkSwapAnnualBonus}
              onValueChange={value => {
                updateCfaQuery({ ...query, checkSwapAnnualBonus: parseFloat(value) })
              }}
            /></dd>
          </dl>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.what_year_will_loan_end" /></dt>
            <dd>
              <select onChange={e => {
                updateCfaQuery({ ...query, checkSwapLoanEndYear: parseInt(e.target.value) })
              }
              }>
                <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                {range(1, query.cashFlowLength + 1, 1).map(year =>
                  <option value={year} checked={query.checkSwapLoanEndYear == year}>{year}</option>
                )}
              </select>
            </dd>
          </dl>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.what_is_the_loan_repayment_amount" /></dt>
            <dd><CurrencyInput
              style={{ width: 'inherit' }}
              value={query.checkSwapLoanRepaymentAmount}
              onValueChange={value => {
                updateCfaQuery({ ...query, checkSwapLoanRepaymentAmount: parseFloat(value) })
              }}
            /></dd>
          </dl>
        </div>
        )}
      </React.Fragment> }
      <br />

    </div>
  </div>
};

DownPayment.propTypes = {
  query: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

DownPayment.defaultProps = {
  list: [],
}

const styles = () => ({
  question: {
    clear: 'both',
  },
  error: {
    color: 'red',
  }
});

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: { ...get(state, 'company', {}) },
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(DownPayment)));