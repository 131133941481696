import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { flowRight } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const AddNote = gql`
  mutation addNote($userName: String!) {
    addNote(userName: $userName, text: "Added to Rolodex") {
      id
      comment
      created_at
    }
  }
`;

const expressInterest = gql`
  mutation expressInterest($id: ID!, $type: String) {
    expressInterest(id: $id, type: $type)
  }
`;

class ExpressInterestButton extends Component {
  state = { showButton: true, emailDialogOpen: false };

  callExpressInterest = () => {
    const {
      user,
      expressInterestMutation,
      currentUserHasLimitedAccess,
      currentUserHasSellingTransitionGoals,
      updateProfile,
    } = this.props;
    const type = () => {
      if (currentUserHasLimitedAccess && currentUserHasSellingTransitionGoals && user.is_pure_buyer) {
        return 'customer_success_inquiry';
      }
      if (currentUserHasLimitedAccess && !currentUserHasSellingTransitionGoals) {
        return 'seller_only';
      }
      return 'managed';
    };
    expressInterestMutation({
      variables : {
        id   : parseInt(user.id, 10),
        type : type(),
      },
    })
      .then(() => {
        this.setState({ showButton: false });
        updateProfile({ ...user, expressed_interest: true });
        if (user.note_count === 0) {
          this.callAddNote();
        }
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  buttonColor = () => {
    const { user, currentUserHasLimitedAccess, currentUserHasSellingTransitionGoals } = this.props;
    if (currentUserHasLimitedAccess && currentUserHasSellingTransitionGoals && user.is_pure_buyer) {
      return { backgroundColor: '#006BDB' };
    }
    if (currentUserHasLimitedAccess && !currentUserHasSellingTransitionGoals) {
      return { backgroundColor: '#FFD700' };
    }
    return { backgroundColor: 'rgb(19, 150, 226)' };
  };

  callAddNote = () => {
    const { user, AddNewNote, refetch, updateBobCards, loadBobCardList, updateNotes } = this.props;
    AddNewNote({
      variables : { userName: user.user_name },
    })
      .then(r => {
        updateNotes({ notes: [ r.data.addNote ], skip: true });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: 1 });
          loadBobCardList({ ...user, note_count: 1 });
        }
        refetch();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  clickExpressInterest = () => {
    this.setState({ emailDialogOpen: true });
  };

  closeExpressInterestDialog = () => {
    this.setState({ emailDialogOpen: false });
  };

  render = () => {
    const {
      user,
      classes,
      currentUserHasLimitedAccess,
      sellerWord,
      currentUserHasSellingTransitionGoals,
      getCurrentUser,
    } = this.props;
    const { showButton, emailDialogOpen } = this.state;
    if (showButton) {
      return (
        <div>
          <Button
            variant="contained"
            // style={this.buttonColor()}
            className={classes.button}
            onClick={() => this.clickExpressInterest()}>
            {currentUserHasLimitedAccess &&
            currentUserHasSellingTransitionGoals &&
            user.is_pure_buyer && <span>Express Interest</span>}
            {currentUserHasLimitedAccess && !currentUserHasSellingTransitionGoals && <span>Become a {sellerWord}</span>}
            {!currentUserHasLimitedAccess && <span>Express Interest</span>}
          </Button>

          <Dialog
            open={emailDialogOpen}
            onClose={this.closeExpressInterestDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            {!currentUserHasLimitedAccess && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3>Confirmation:</h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      By clicking the CONFIRM button, we'll forward your interest in {user.user_name} to a team member
                      who'll reach out to you about next steps.
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            {currentUserHasLimitedAccess &&
            currentUserHasSellingTransitionGoals &&
            user.is_pure_buyer && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3>Thank you for your interest in {user.user_name}!</h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      Due to your access limitations on {getCurrentUser.company.branded_name}, we would be happy to
                      facilitate a connection with {user.user_name} through one of our Customer Success Managers.
                      <br />
                      <br />
                      By clicking to confirm, you will be opting-in to a facilitated conversation between yourself and{' '}
                      {user.user_name} based on your mutual availability and interest. A Customer Success Manager will
                      be following up with you by email promptly to provide more details and arrange a connection.
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            {currentUserHasLimitedAccess &&
            !currentUserHasSellingTransitionGoals && (
              <div>
                <DialogTitle id="alert-dialog-title">
                  <h3>Inquire About Becoming a {sellerWord}:</h3>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description" className={classes.dialogText}>
                    <p>
                      In order to connect with {user.user_name} you must be a {sellerWord}. By clicking confirm we will
                      contact you on next steps. In the meantime, if you haven't already added {user.user_name} to your
                      Rolodex, they will automatically added, you can view them under the Rolodex tab.
                    </p>
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            <DialogActions>
              <Button onClick={this.closeExpressInterestDialog} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.callExpressInterest();
                  this.closeExpressInterestDialog();
                }}
                variant="contained"
                color="primary"
                style={{ float: 'right', marginRight: 15, backgroundColor: 'rgb(19, 150, 226)' }}>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return (
      <Button variant="contained" className={classes.pressed}>
        <span style={{ marginRight: 12 }} /> Express Interest
      </Button>
    );
  };
}

ExpressInterestButton.propTypes = {
  user                                 : PropTypes.object.isRequired,
  expressInterestMutation              : PropTypes.func.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  refetch                              : PropTypes.func,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  AddNewNote                           : PropTypes.func.isRequired,
  classes                              : PropTypes.object.isRequired,
  pressed                              : PropTypes.object.isRequired,
  getCurrentUser                       : PropTypes.object.isRequired,
  sellerWord                           : PropTypes.string.isRequired,
};

ExpressInterestButton.defaultProps = {
  refetch : () => {},
};

const styles = {
  dialogText : {
    fontSize : 16,
    color    : 'black',
  },
  button     : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed    : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default flowRight(
  graphql(expressInterest, { name: 'expressInterestMutation' }),
  graphql(AddNote, { name: 'AddNewNote' })
)(withStyles(styles)(ExpressInterestButton));
