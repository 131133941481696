import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import Paper from '@material-ui/core/Paper';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";
import { CardHeader, Grid } from '@material-ui/core';
import AdvancedInfo from './AdvancedInfo';


const NameAndPrice = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <div className={classes.header}>Cash Flow Setup</div>
          <Grid container xs={12} spacing={1}>
            <Grid item>
              <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.name" /></b> &nbsp;
              <TextField
                style={{ width: 'inherit' }}
                value={query.name}
                onChange={e => {
                  updateCfaQuery({ ...query, name: e.target.value })
                }}
              />
            </Grid>
            <AdvancedInfo classes={classes} updateCfaQuery={updateCfaQuery} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(NameAndPrice));