import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

const NicheMarkets = ({ classes, user, obfuscateView }) => {
  if (!isEmpty(get(user, 'primary_markets'))) {
    const markets = get(user, 'primary_markets', []).map(p => capitalize(p.replaceAll('_', ' ')));
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.primary_markets"/></strong>
        {!obfuscateView && (
          <p>
            {markets.map(s => (
              <span key={s}>
                {s}
                <br />
              </span>
            ))}
          </p>
        )}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default withStyles(styles)(NicheMarkets);
