import React, {Fragment} from 'react';
import numeral from 'numeral';
import { FormattedMessage } from 'react-intl';
import SellerSellerNote from './SellerSellerNote';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

const SellerFullTaxBreakdown = ({result, query, classes, intl, company }) => {
  
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  const sellerCashFlow = get(result, 'cashFlow.illustration.sellerCashFlow', []);
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note'); 
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');

  // let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(0);
  // if(isNaN(consultingPercentOfDeal)) {
  //     consultingPercentOfDeal = 0;
  // }
  let consultingTaxRate = get(sellerCashFlow,'[1].taxRate',0) * 100;
  return <Fragment>
    <tr>
      <td><strong>Tax Treatments</strong></td>
      <td></td>
    </tr> 
    <tr>
      <td><i>Income ({numeral(get(sellerCashFlow,'[1].taxRate',0)).format(percentFormat)})</i></td>
      {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(0).format(percentFormat)}</td>))}
    </tr>
    <tr>
        <td>Down Payment ({numeral(get(result,'cashFlow.cashflowSellerLifetimeCapitalGainsTax','')).format(percentFormat)})</td>
        {sellerCashFlow.map((v) => (<td  style={{color: 'red', textAlign: 'right'}}>{numeral(v.downpaymentTaxes).divide(-1).format(dollarFormat)}</td>))}
    </tr>
    <tr>
        <td>Earn Out ({numeral(get(sellerCashFlow,'[1].taxRate',0)).format(percentFormat)})</td>
        {sellerCashFlow.map((v) => (<td  style={{color: 'red', textAlign: 'right'}}>
            {numeral(v.earnOutTaxes).divide(-1).format(dollarFormat)}
          </td>))}
    </tr>
    { showSellerNote && <SellerSellerNote /> }
    {showConsultingAgreements && <React.Fragment>
      <tr>
        <td>Consulting Agreement ({consultingTaxRate}%)</td>
        {sellerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.consultingAgreementTaxes).divide(-1).format(dollarFormat)}</td>))}
    </tr> 
    </React.Fragment>}
    <tr>
        <td style={{ borderTop: '3px solid black' }} >Total Taxes</td>
        {sellerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', color: 'red', textAlign: 'right'}}>{numeral(v.totalTaxes).divide(-1).format(dollarFormat)}</td>))}
    </tr>
  </Fragment>;
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
    company: {...get(state, 'company', {})},
  };
};

const styles = {
  highlightedRow: {
    borderTop: '3px solid black !important',
    borderBottom: '3px solid black !important',
  },
  lineTop: {
    borderTop: '3px solid black !important',
  },
  gap: {
    height: 1,
    display: 'table-row',
  },
  lineBottom: {
    borderBottom: '3px solid black !important',
  },
}

export default connect(mapStateToProps)(injectIntl(withStyles(styles)(SellerFullTaxBreakdown)));