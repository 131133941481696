import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import {withStyles} from "@material-ui/core/styles";
import {isEmpty} from "lodash";

const CalibrationSessionFilterDialog = ({session, classes}) => {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleFilterSelection = (filter) => {
    if (selectedFilters.includes(filter)) {
      setSelectedFilters(selectedFilters.filter((item) => item !== filter));
    } else {
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const handleApplyFilters = () => {
    // Perform actions with selectedFilters
    // ...

    // Close the dialog
    onClose();
  };

  return (
    <React.Fragment>
      <IconButton onClick={onOpen} style={{display: 'inline' }}><FilterIcon  style={{display: 'inline' }}/></IconButton>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle className={classes.dialogTitle}>Calibration Session Filters</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {session.branch && <div>
            <p>
              <b>Branch</b>: {session.branch} 
            </p>
          </div>}
          {session.division && <div>
            <p>
              <b>Division</b>: {session.division}
            </p>
          </div>}
          {session.region && <div>
            <p>
              <b>Region</b>: {session.region}
            </p>
          </div>}
          {(session.ageMin !== null || session.ageMax !== null) && <div>
            <p>
              <b>Age:</b>
            </p>
            <p> Min: {session.ageMin || 0} </p>
            <p> Max: {session.ageMax || 0} </p>
          </div>}
          {(session.industryLengthOfServiceMin !== null || session.industryLengthOfServiceMax !== null) && <div>
            <p>
              <b>Industry Length Of Service</b>
              <p> Min: {session.industryLengthOfServiceMin || 0} </p>
              <p> Max: {session.industryLengthOfServiceMax || 0} </p>
            </p>
          </div>}
          {session.managedByUser.length > 0 && <div>
            <p>
              <b>Managed By</b>: {(session.managedByUser).map(user => user.name).join(', ')}
            </p>
          </div>}
          {session.catastrophicPlanOnFile && <div>
            <p>
              <b>Catastrophic Plan On File</b>: {session.catastrophicPlanOnFile}
            </p>
          </div>}
          {session.start_date_min !== null || session.start_date_max !== null && <div>
            <p>
              <b>Years with Company</b>
              <label> Min: {session.startDateMin || 0} </label>
              <label> Max: {session.startDateMax || 0} </label>
            </p>
          </div>}
          {(!isEmpty(session.state) || session.state == [])  && <div>
            <p>
              <b>State/Province</b>: {session.state.join(', ')}
            </p>
          </div>}


          
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
    padding: 14,
  },
  dialogContent: {
    padding: 14,
    fontSize: 14,
    textDecoration: 'none',
    fontWeight: "normal",
  },

});

export default withStyles(styles)(CalibrationSessionFilterDialog);
