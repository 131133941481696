import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { updateDateFilter } from '../../actions/dateRangeFilterActionCreators';

import MailerReport from '../../components/Reports/MailerReport';

const MailerQuery = gql`
  query MailerQuery {
    getMailers {
      id
      mailer
      sentAt
      openedAt
      clickedAt
    }
  }
`;

export const MailerReportContainer = props => {
  return (
    <div>
      <Query query={MailerQuery}>
        {({ loading, error, data, refetch }) => {
          if (error) return `Error!: ${error}`;

          return <MailerReport data={data} refetch={refetch} {...props} />;
        }}
      </Query>
    </div>
  );
};

const mapStateToProps = state => ({
  startDate : state.dateRangeFilter.sendStart.format('YYYY-MM-DD'),
  endDate   : state.dateRangeFilter.sendEnd.format('YYYY-MM-DD'),
});

const mapDispatchToProps = {
  updateDateFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailerReportContainer);
