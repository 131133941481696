import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const ToggleMatchException = gql`
  mutation toggleMatchException($inventoryUserId: ID!, $is_exception: Boolean, $note: String) {
    toggleMatchException(inventoryUserId: $inventoryUserId, isException: $is_exception, note: $note)
  }
`;

const ExceptionToggle = ({ isException, inventoryUserId, updateUserState, updateUser, refetch }) => {
  const [ exceptionNote, setExceptionNote ] = useState(exceptionNote);
  const [ open, setOpen ] = React.useState(false);
  const [ exceptionReason, setExceptionReason ] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkInputs = () => {
    if (isException) {
      return !exceptionNote;
    }
    return !exceptionReason;
  };

  const exceptionDetails = () => {
    if (isException) {
      return `[Match Pool Exception Removed] <br /><b>Reason:</b> ${exceptionNote}`;
    }
    return `[Match Pool Exception] <br /><b>Reason:</b> ${exceptionReason} <br />${exceptionNote
      ? '<b>Details:</b> ' + exceptionNote
      : ''}`;
  };
  return (
    <div>
      <br />
      <input type="checkbox" checked={isException} onChange={handleClickOpen} />
      &nbsp; Match Pool Exception
      <Mutation mutation={ToggleMatchException}>
        {toggleMatchException => (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{isException ? 'Remove Exception' : 'Mark as Exception?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {isException ? (
                  <span>
                    This person will no longer be marked as an exception. Please provide a note explaining why you are
                    removing exception.
                  </span>
                ) : (
                  <span>
                    This person will be marked as a match exception. Please select a reason why the exception is granted
                    and, optionally, provide detail for the exception.
                  </span>
                )}
              </DialogContentText>
              {!isException && (
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={exceptionReason}
                    style={{ minWidth: 100, marginBottom: 30 }}
                    onChange={e => setExceptionReason(e.target.value)}>
                    <MenuItem value={'Management Exception'}>Management Exception</MenuItem>
                    <MenuItem value={'In Current Succession Plan'}>In Current Succession Plan</MenuItem>
                    <MenuItem value={'Licensing Exception'}>Licensing Exception</MenuItem>
                  </Select>
                </FormControl>
              )}
              <br />
              <TextField
                fullWidth
                label="Details (Optional)"
                rows={3}
                style={{ whiteSpace: 'pre-wrap' }}
                onChange={e => setExceptionNote(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={checkInputs()}
                onClick={() => {
                  const ex = !isException;
                  toggleMatchException({
                    variables : {
                      inventoryUserId,
                      is_exception    : ex,
                      note            : exceptionDetails(),
                    },
                  }).then(r => {
                    setExceptionNote('');
                    updateUserState({ is_exception: ex, approval_state: ex ? 'approved' : 'denied' }, updateUser());
                    refetch();
                    handleClose();
                  });
                }}
                color="primary"
                autoFocus>
                {isException ? 'Remove exception' : 'Mark as exception'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Mutation>
    </div>
  );
};

ExceptionToggle.propTypes = {
  isException     : PropTypes.boolean,
  inventoryUserId : PropTypes.number,
  updateUserState : PropTypes.func,
};

ExceptionToggle.defaultProps = {
  isException     : false,
  inventoryUserId : -1,
  updateUserState : () => {},
};

export default ExceptionToggle;
