import React from 'react';
import PropTypes from 'prop-types';
import ReactOnRails from 'react-on-rails';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import { capitalize, keys } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import { listOfStates, listOfProvinces } from '../../lib/findBobUtils';
import WarningText from '../WarningText/WarningText';
import gql from 'graphql-tag';
import { graphql, Query, ApolloConsumer } from 'react-apollo';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import { debounce } from 'lodash';

const emailExistsQuery = gql`
  query emailExistsQuery($email: String) {
    emailExistsQuery(email: $email)
  }
`;
class InventoryUserNewFormDialog extends React.Component {
  defaultState = {
    annual_revenue          : '',
    annuities_aua           : '',
    auto_ifp                : '',
    birth_date              : '',
    clients                 : '',
    commercial_ifp          : '',
    crop_hail_ifp           : '',
    crop_hail_with_wind_ifp : '',
    email                   : null,
    emailDuplicate          : true,
    farm_insurance_ifp      : '',
    group_ifp               : '',
    health_ifp              : '',
    home_ifp                : '',
    impact_of_loss          : '',
    impact_of_loss_num      : '',
    key_asset               : '',
    life_ifp                : '',
    mcpi_ifp                : '',
    mutual_funds_aum        : '',
    name                    : '',
    open                    : false,
    openCsv                 : false,
    openEmailExists         : false,
    openManual              : false,
    price_products_ifp      : '',
    private_products_ifp    : '',
    province                : '',
    replant_supplement_ifp  : '',
    rev_yield               : '',
    risk_of_loss            : '',
    risk_of_loss_num        : '',
    securities_aum          : '',
    segregated_funds_aua    : '',
    start_date              : '',
    total_assets            : '',
    total_premium           : '',
  };

  state = this.defaultState;

  resetForm = () => {
    this.setState(this.defaultState);
  };

  handleRequestClose = () => {
    const { open } = this.state;
    if (open) {
      this.setState({ open: false });
    }
  };

  handleOpenManual = () => {
    this.setState({ openManual: true });
  };

  handleCloseManual = () => {
    const { openManual } = this.state;
    if (openManual) {
      this.setState({ openManual: false, email: '', openEmailExists: false });
    }
  };

  handleOpenCsv = () => {
    this.setState({ openCsv: true });
  };

  handleCloseCsv = () => {
    const { openCsv } = this.state;
    if (openCsv) {
      this.setState({ openCsv: false });
    }
  };

  handleChange = (id, e) => {
    let state = this.state;
    state[id] = e.target.value;
    this.setState(state);
  };

  validateBirthday = () => {
    const { birth_date } = this.state;
    const age = moment().diff(birth_date, 'years');
    return age > 18 || birth_date === '';
  };

  handleBdayChange = date => {
    this.setState({ birth_date: date });
  };

  handleStartDateChange = date => {
    this.setState({ start_date: date });
  };

  stateType = () => {
    const { country, classes } = this.props;
    if (country === 'ca') {
      return (
        <span className={classes.textFieldKey}>
          <FormattedMessage id="admin_inventory_user.province" />:
        </span>
      );
    }
    if (country === 'us') {
      return (
        <span className={classes.textFieldKey}>
          <FormattedMessage id="admin_inventory_user.state" />:
        </span>
      );
    }
    return <div />;
  };

  addRow = action => {
    const {
      name,
      email,
      province,
      birth_date,
      start_date,
      clients,
      rev_yield,
      annual_revenue,
      total_premium,
      total_assets,
      key_asset,
      risk_of_loss,
      impact_of_loss,
      risk_of_loss_num,
      impact_of_loss_num,
      life_ifp,
      home_ifp,
      auto_ifp,
      commercial_ifp,
      group_ifp,
      health_ifp,
      mutual_funds_aum,
      securities_aum,
      annuities_aua,
      segregated_funds_aua,
      mcpi_ifp,
      private_products_ifp,
      crop_hail_ifp,
      crop_hail_with_wind_ifp,
      farm_insurance_ifp,
      price_products_ifp,
      replant_supplement_ifp,
    } = this.state;

    action({
      name,
      email,
      province,
      birth_date,
      start_date,
      clients,
      rev_yield,
      annual_revenue,
      total_premium,
      total_assets,
      key_asset,
      risk_of_loss,
      impact_of_loss,
      risk_of_loss_num,
      impact_of_loss_num,
      life_ifp,
      home_ifp,
      auto_ifp,
      commercial_ifp,
      group_ifp,
      health_ifp,
      mutual_funds_aum,
      securities_aum,
      annuities_aua,
      segregated_funds_aua,
      mcpi_ifp,
      private_products_ifp,
      crop_hail_ifp,
      crop_hail_with_wind_ifp,
      farm_insurance_ifp,
      price_products_ifp,
      replant_supplement_ifp,
    });
  };

  validateFile = () => {
    const file = document.getElementById('file');
    file.onchange = function (e) {
      var ext = this.value.match(/\.([^\.]+)$/)[1];
      switch (ext) {
        case 'csv':
          alert('allowed');
          break;
        default:
          alert('not allowed');
          this.value = '';
      }
    };
  };

  openEmailExists = () => {
    this.setState({ openEmailExists: true, emailDuplicate: true });
  };

  closeEmailExists = () => {
    this.setState({ openEmailExists: false });
  };

  noEmailDupe = () => {
    this.setState({ emailDuplicate: false });
  };

  checkDupe = debounce(async (e, client) => {
    this.setState({ email: e });
    const { data } = await client.query({
      query     : emailExistsQuery,
      variables : { email: e },
    });
    if (data.emailExistsQuery === true) {
      this.openEmailExists();
    } else {
      this.noEmailDupe();
    }
  }, 2000);

  handleClickOpen = () => {
    this.setState({ open: true });
  };
  showAddButton = () => {
    const { onlyUsers, classes } = this.props;
    if (onlyUsers) {
      return <div />;
    }
    return (
      <Icon
        className={classes.addButton}
        style={{ fontSize: 40, cursor: 'pointer' }}
        color="primary"
        onClick={this.handleClickOpen}>
        add_circle
      </Icon>
    );
  };

  render () {
    const { classes, error, intl, country, action, agentWordForCompany, availableProducts, onlyUsers } = this.props;
    const {
      province,
      open,
      openCsv,
      openManual,
      email,
      key_asset,
      risk_of_loss,
      impact_of_loss,
      risk_of_loss_num,
      impact_of_loss_num,
      openEmailExists,
      emailDuplicate,
    } = this.state;
    let regionList = [];
    if (country === 'ca') {
      regionList = listOfProvinces;
    } else if (country === 'us') {
      regionList = listOfStates;
    }
    const availableProductsObject = JSON.parse(availableProducts);
    return (
      <div>
        {this.showAddButton()}
        <Dialog open={open} onRequestClose={this.handleRequestClose}>
          <div className={classes.titleText}>Add {capitalize(agentWordForCompany)}s</div>
          <DialogContent>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <button type="button" className={classes.iconBox} onClick={this.handleOpenCsv}>
                  <i style={{ fontSize: '100px', color: 'dodgerblue' }} className="material-icons md-36">
                    description
                  </i>
                  <br />
                  <div className={classes.iconBoxText}>Import from CSV</div>
                  <br />
                  <div>Add {agentWordForCompany}s via bulk upload.</div>
                </button>
              </Grid>
              <Grid item xs={6}>
                <button type="button" className={classes.iconBox} onClick={this.handleOpenManual}>
                  <i style={{ fontSize: '100px', color: 'dodgerblue' }} className="material-icons md-36">
                    assignment_ind
                  </i>
                  <br />
                  <div className={classes.iconBoxText}>Create manually</div>
                  <br />
                  <div>Add {agentWordForCompany}s manually one-by-one.</div>
                </button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
        <Dialog open={openCsv} onRequestClose={this.handleCloseCsv}>
          <DialogContent>
            <div className={classes.csvBox}>
              <div className={classes.titleText}>
                <i
                  style={{ fontSize: '30px', color: 'dodgerblue', marginRight: '5px' }}
                  className="material-icons md-36">
                  description
                </i>Upload using a CSV file
              </div>
              <div className={classes.csvText}>Please choose a file to upload.</div>
            </div>
          </DialogContent>
          <DialogContent>
            <form
              encType="multipart/form-data"
              action="/admin/inventory_users/upload"
              acceptCharset="UTF-8"
              method="post">
              <input name="utf8" type="hidden" value="✓" />
              <input type="hidden" name="authenticity_token" value={ReactOnRails.authenticityToken()} />
              <input type="file" name="file" id="file" accept=".csv" />
              <input type="submit" name="commit" value="Upload CSV" />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCsv} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
        <Dialog open={openManual} onRequestClose={this.handleCloseManual}>
          <Grid container spacing={0}>
            <Grid className={classes.dialog} item xs={12}>
              <h3 className={classes.text}>Add {capitalize(agentWordForCompany)}</h3>
              <TextField
                autoFocus
                margin="dense"
                required
                id="name"
                label={intl.formatMessage({ id: 'admin_inventory_user.full_name' })}
                className={classes.textField}
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                fullWidth
              />

              <ApolloConsumer>
                {client => (
                  <div>
                    <TextField
                      required
                      margin="dense"
                      id="email"
                      label={intl.formatMessage({ id: 'enterprise.email_address' })}
                      type="email"
                      helperText={email === null || EmailValidator.validate(email) ? '' : 'Enter a valid email address'}
                      error={email !== null && !EmailValidator.validate(email)}
                      className={classes.textField}
                      onChange={e => this.checkDupe(e.target.value, client)}
                      fullWidth
                    />
                    {emailDuplicate &&
                    email !== null && <p className={classes.emailWarning}>Email already exists in the system</p>}
                  </div>
                )}
              </ApolloConsumer>

              <InputLabel className={classes.inputs}>Province (Please Choose One):</InputLabel>
              <Select
                label={this.stateType() + ':'}
                name="Province"
                value={province}
                onChange={e => {
                  this.handleChange('province', e);
                }}
                fullWidth>
                {regionList.map(prov => (
                  <MenuItem key={prov} value={prov}>
                    {prov}
                  </MenuItem>
                ))}
              </Select>
              <Typography className={classes.textField}>
                <FormattedMessage id="shared.birth_date" />:
              </Typography>
              <FindbobDatePicker
                getDate={date => {
                  this.handleBdayChange(date);
                }}
                maxYear={moment().year()}
                minYear={moment().year() - 80}
              />
              <WarningText
                visible={!this.validateBirthday()}
                message={intl.formatMessage({ id: 'admin_inventory_user.invalid_birthday' })}
              />
              <Typography className={classes.textField}>
                <FormattedMessage id="admin_inventory_user.company_start_date" />:
              </Typography>
              <FindbobDatePicker
                getDate={date => {
                  this.handleStartDateChange(date);
                }}
                maxYear={moment().year()}
                minYear={moment().year() - 80}
              />
              <TextField
                margin="dense"
                required={false}
                id="clients"
                label={intl.formatMessage({ id: 'profile_page.practice_info_section.number_of_clients' })}
                className={classes.textField}
                onChange={e => {
                  this.setState({ clients: e.target.value });
                }}
                fullWidth
              />
              <TextField
                margin="dense"
                required={false}
                id="rev_yield"
                label={intl.formatMessage({ id: 'user_details.annual_revenue' })}
                className={classes.textField}
                onChange={e => {
                  this.setState({ rev_yield: e.target.value });
                }}
                InputProps={{
                  startAdornment : <InputAdornment position="start">$</InputAdornment>,
                }}
                fullWidth
              />
              <Grid item xs={12}>
                <InputLabel className={classes.inputs}>
                  <FormattedMessage id="admin_inventory_user.agent_key" />:
                </InputLabel>
                <Select
                  label="Key Asset:"
                  name="Key Asset"
                  value={key_asset}
                  onChange={e => {
                    this.handleChange('key_asset', e);
                  }}
                  InputProps={{
                    classes : { root: classes.root, underline: classes.underline },
                  }}
                  fullWidth>
                  <MenuItem value={null}>
                    <em>None Selected</em>
                  </MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputs}>
                  <FormattedMessage id="admin_inventory_user.agent_risk_of_loss" />:
                </InputLabel>
                <Select
                  label="Risk of Loss:"
                  name="Risk of Loss"
                  value={risk_of_loss_num}
                  onChange={e => {
                    this.handleChange('risk_of_loss_num', e);
                  }}
                  InputProps={{
                    classes : { root: classes.root, underline: classes.underline },
                  }}
                  fullWidth>
                  <MenuItem value={null}>
                    <em>None Selected</em>
                  </MenuItem>
                  <MenuItem value="risk_low">Low</MenuItem>
                  <MenuItem value="risk_medium">Medium</MenuItem>
                  <MenuItem value="risk_high">High</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <InputLabel className={classes.inputs}>
                  <FormattedMessage id="admin_inventory_user.agent_impact_of_loss" />:
                </InputLabel>
                <Select
                  label="Impact of Loss:"
                  name="Impact of Loss"
                  value={impact_of_loss_num}
                  onChange={e => {
                    this.handleChange('impact_of_loss_num', e);
                  }}
                  InputProps={{
                    classes : { root: classes.root, underline: classes.underline },
                  }}
                  fullWidth>
                  <MenuItem value={null}>
                    <em>None Selected</em>
                  </MenuItem>
                  <MenuItem value="impact_low">Low</MenuItem>
                  <MenuItem value="impact_medium">Medium</MenuItem>
                  <MenuItem value="impact_high">High</MenuItem>
                </Select>
              </Grid>
              {keys(availableProductsObject).map(k => {
                return (
                  <div>
                    <br />
                    <b className={classes.headers}>
                      <FormattedMessage id={`shared.${k}`} />:
                    </b>
                    {availableProductsObject[k].map(s => (
                      <div>
                        <TextField
                          margin="dense"
                          required={false}
                          id={s}
                          value={this.state[s] || ''}
                          label={intl.formatMessage({ id: `bobcard.user_details.${s.replace(/_ifp|_aum|_aua/, '')}` })}
                          className={classes.textFieldHeader}
                          onChange={e => {
                            this.setState({ [s]: parseFloat(e.target.value) });
                          }}
                          InputProps={{
                            startAdornment : <InputAdornment position="start">$</InputAdornment>,
                          }}
                          fullWidth
                        />
                      </div>
                    ))}
                  </div>
                );
              })}
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={this.handleCloseManual} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              className={classes.text}
              onClick={() => {
                this.addRow(action);
                this.resetForm();
              }}
              disabled={emailDuplicate || !EmailValidator.validate(email)}>
              <FormattedMessage id="shared.add" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
        <Dialog
          open={openEmailExists}
          onClose={() => this.closeEmailExists()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Email entered already exists in the system'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please enter a different email address, or use the search function if you would like to view / edit this
              user.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeEmailExists()} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserNewFormDialog.propTypes = {
  classes : PropTypes.object.isRequired,
  intl    : PropTypes.object.isRequired,
  country : PropTypes.string.isRequired,
  error   : PropTypes.bool,
  action  : PropTypes.func.isRequired,
};

InventoryUserNewFormDialog.defaultProps = {
  error : false,
};

const styles = () => ({
  addButton       : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  textField       : {
    fontSize        : 14,
    marginTop       : 12,
    backgroundColor : '#fff',
  },
  textFieldHeader : {
    fontSize        : 14,
    marginTop       : 8,
    backgroundColor : '#fff',
  },
  textFieldKey    : {
    fontWeight      : 'bold',
    fontSize        : '18px',
    margin          : '12px',
    backgroundColor : '#fff',
  },
  text            : {
    fontSize   : 22,
    textAlign  : 'center',
    fontWeight : 700,
  },
  iconBoxText     : {
    fontSize   : '18px',
    textAlign  : 'center',
    fontWeight : 'bold',
    color      : 'dodgerblue',
  },
  titleText       : {
    fontSize   : '22px',
    textAlign  : 'center',
    fontWeight : 'bold',
    margin     : '30px 0px 10px 0px',
  },
  csvBox          : {
    height : '100px',
    width  : '400px',
  },
  csvText         : { textAlign: 'center' },
  iconBox         : {
    border    : '1px solid #EAECEE',
    textAlign : 'center',
    height    : '250px',
    width     : '235px',
    margin    : '20px',
  },
  dialog          : {
    padding : 20,
  },
  inputs          : {
    marginTop : 20,
  },
  headers         : {
    textDecoration : 'underline',
  },
  emailWarning    : {
    textDecoration : 'underline',
    color          : 'red',
  },
});

export default injectIntl(withStyles(styles)(InventoryUserNewFormDialog));
