import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from 'react-apollo';
import { isEmpty, get, take } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Bling as GPT } from 'react-gpt';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Button from '@material-ui/core/Button';
import LimitedAccessMessage from './LimitedAccessMessage';
import { AuctionAd, PPIAd, InterestedInAd, SubscriptionsAd } from '../AdCard/AdCard';
import BobCard from '../BobCard/BobCard';
import qs from 'query-string';
import client from '../../lib/apolloClient';
import { UserProfileQueries } from '../../containers/UserProfileContainer';

GPT.enableSingleRequest().then(value => {
  // console.log('value', value);
});

class Dashboard extends Component {
  MAX_PAGES = 2;
  CARDS_PER_PAGE = 12;

  partnerAd = (
    <div className="leaderboard">
      <GPT
        adUnitPath="/21717728079/acct_homepage_banner"
        sizeMapping={[ { viewport: [ 300, 0 ], slot: [ 320, 50 ] }, { viewport: [ 728, 90 ], slot: [ 728, 90 ] } ]}
        targeting={{ platform: window.location.hostname }}
      />
    </div>
  );

  constructor (props) {
    super(props);
    this.state = {
      page                : 1,
      limitedAccessNotice : true,
    };
  }

  setUser = async user_name => {
    const { updateSelectedProfileDrawer } = this.props;
    const result = await client.query({
      query       : UserProfileQueries,
      variables   : { userName: user_name },
      fetchPolicy : 'network-only',
    });
    updateSelectedProfileDrawer({ ...get(result, 'data') });
    window.history.pushState({}, document.title, '/dashboard/managed');
  };

  componentDidUpdate = async () => {
    const params = qs.parse(location.search);
    if (params.user_name) {
      const { user_name } = params;
      this.setUser(user_name);
    }
  };

  addAdsToCards = cards => {
    const { data } = this.props;
    const { currentAuctionCount, currentUserASubscriber, companyHasBilling, companyHasAds } = data;
    let cardComponent = null;

    if (currentUserASubscriber || !companyHasBilling) {
      if (currentAuctionCount > 0) {
        cardComponent = <AuctionAd />;
      } else {
        if (get(window, 'location.host', '').indexOf('matchbook') > -1) cardComponent = <PPIAd />;
        else cardComponent = <InterestedInAd />;
      }
    } else {
      cardComponent = <SubscriptionsAd />;
    }

    cards.splice(2, 0, companyHasAds ? this.partnerAd : cardComponent);

    [ 4, 6, 8 ].map(i => {
      cards.splice(i, 0, cardComponent);
    });

    return cards;
  };

  isAdmin = () => {
    const { data } = this.props;
    const { getCurrentUser } = data;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles.includes('admin')) {
      return true;
    }
    return false;
  };

  disableMessageBtn = () => {
    const { data } = this.props;
    const { getCurrentUser } = data;
    const checkRoles = [ 'customer', 'staff' ];

    return (
      !get(getCurrentUser, 'company.networking_allowed') ||
      checkRoles.some(role => get(getCurrentUser, 'roles', []).includes(role))
    );
  };

  renderUserCards = () => {
    const { data } = this.props;
    const { currentUserInfoAuth, companyHasFeature, companyHasCounty, countryForCompany, getCurrentUser } = data;
    const {
      users,
      updateBobCards,
      loadBobCardList,
      marketplaceOnly,
      updateSelectedProfileDrawer,
      updateNotes,
      refetch,
      companyBusinessType,
    } = this.props;
    const { page } = this.state;

    if (isEmpty(users)) return <div />;
    const seg_users = take(users, this.CARDS_PER_PAGE * page);

    const cards = seg_users.map(user => (
      <BobCard
        user={user}
        isUserAuthorized={currentUserInfoAuth}
        displaySocialButtons={companyHasFeature}
        refetch={refetch}
        companyHasCounty={companyHasCounty}
        countryForCompany={countryForCompany}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        userIsAdmin={this.isAdmin()}
        currentUserHasLimitedAccess={getCurrentUser.limited_access}
        currentCompanySellerWord={getCurrentUser.company.seller_only_label}
        currentUserHasSellingTransitionGoals={getCurrentUser.has_selling_transition_goals}
        getCurrentUser={getCurrentUser}
        disableMessageBtn={this.disableMessageBtn()}
        marketplaceOnly={marketplaceOnly}
        updateSelectedProfileDrawer={updateSelectedProfileDrawer}
        updateNotes={updateNotes}
        companyBusinessType={companyBusinessType}
      />
    ));

    if (marketplaceOnly) {
      return cards;
    }
    return this.addAdsToCards(cards);
  };

  action = () => (
    <Button color="secondary" size="small" onClick={() => this.handleClose()}>
      Close
    </Button>
  );

  handleClose = () => {
    this.setState({
      limitedAccessNotice : false,
    });
  };

  message = data => {
    if (!data.loading) {
      return (
        <LimitedAccessMessage
          companyBrandedName={get(data, 'getCurrentUser.company.branded_name', '')}
          currentCompanySellerWord={get(data, 'getCurrentUser.company.seller_only_label', '')}
        />
      );
    }
  };

  render = () => {
    const { data, marketplaceOnly, users, currentUserAllowed } = this.props;
    const { page, limitedAccessNotice } = this.state;
    return (
      <ApolloProvider client={client}>
        <div>
          <section className="results">
            {!marketplaceOnly && (
              <header>
                <h1> {<FormattedMessage id="dashboard.menu.your_suggested_matches" />} </h1>
              </header>
            )}
          </section>
          <br />
          <section id="properties" className="display-lines">
            {!data.loading && currentUserAllowed && this.renderUserCards()}
          </section>
          <div>
            {page < this.MAX_PAGES &&
            users.length > this.CARDS_PER_PAGE &&
            currentUserAllowed && (
              <button
                type="button"
                onClick={() => {
                  this.setState({ page: page + 1 });
                }}
                className="btn btn-default center-block">
                <FormattedMessage id="dashboard.buttons.load_additional_results" />
              </button>
            )}
          </div>
          {!data.loading && (
            <Snackbar
              open={get(data, 'getCurrentUser.is_seller_only', false) && limitedAccessNotice}
              anchorOrigin={{
                vertical   : 'top',
                horizontal : 'center',
              }}
              onClose={this.handleClose}>
              <SnackbarContent message={this.message(data)} action={this.action()} />
            </Snackbar>
          )}
        </div>
      </ApolloProvider>
    );
  };
}

Dashboard.propTypes = {
  users                       : PropTypes.object.isRequired,
  data                        : PropTypes.object,
  currentUserInfoAuth         : PropTypes.bool.isRequired,
  currentUserAllowed          : PropTypes.bool.isRequired,
  companyHasFeature           : PropTypes.bool.isRequired,
  loading                     : PropTypes.bool.isRequired,
  updateBobCards              : PropTypes.func.isRequired,
  loadBobCardList             : PropTypes.func.isRequired,
  refetch                     : PropTypes.func.isRequired,
  companyHasCounty            : PropTypes.bool.isRequired,
  countryForCompany           : PropTypes.string.isRequired,
  getCurrentUser              : PropTypes.object.isRequired,
  marketplaceOnly             : PropTypes.bool.isRequired,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
  companyBusinessType         : PropTypes.string.isRequired,
  updateNotes                 : PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  data : {},
};

export default Dashboard;
