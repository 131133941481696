import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import { currencies, intervals } from './Plan';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  addButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  text      : {
    fontSize : '18px',
  },
  title     : {
    fontSize : 24,
    margin   : 12,
  },
});

class PlanNewFormDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  addRow = action => {
    const { name, stripe_id, description, amount, currency, interval, published } = this.state;

    action({
      name,
      stripe_id,
      description,
      amount,
      currency,
      interval,
      published,
    });
  };

  render () {
    const { classes, error, close, intl } = this.props;
    if (close) this.handleRequestClose();

    return (
      <div>
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>
            <FormattedMessage id="admin_plans.add_plan" />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              required
              id="name"
              label={intl.formatMessage({ id: 'shared.name' })}
              className={classes.textField}
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="stripe_id"
              label={intl.formatMessage({ id: 'admin_plans.stripe_id' })}
              className={classes.textField}
              onChange={e => {
                this.setState({ stripe_id: e.target.value });
              }}
              fullWidth
            />
            <TextField
              id="description"
              label={intl.formatMessage({ id: 'shared.description' })}
              onChange={e => {
                this.setState({ description: e.target.value });
              }}
              multiline
              rows="2"
              className={classes.textField}
              margin="dense"
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="amount"
              label={intl.formatMessage({ id: 'admin_plans.amount' })}
              className={classes.textField}
              onChange={e => {
                this.setState({ amount: e.target.value });
              }}
              fullWidth
            />
            <TextField
              id="currency"
              select
              label={intl.formatMessage({ id: 'admin_plans.currency' })}
              onChange={e => {
                this.setState({ currency: e.target.value });
              }}
              SelectProps={{
                native : true,
              }}
              style={{ margin: '12px' }}>
              {currencies.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              id="interval"
              select
              label={intl.formatMessage({ id: 'admin_plans.interval' })}
              onChange={e => {
                this.setState({ interval: e.target.value });
              }}
              SelectProps={{
                native : true,
              }}
              style={{ margin: '12px' }}>
              {intervals.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <ToggleButton
              label={intl.formatMessage({ id: 'admin_plans.published' })}
              onChange={e => {
                this.setState({ published: !this.state.published });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}
              disabled={!this.state.name}>
              <FormattedMessage id="admin_plans.add" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

const PlanNewFormDialogWithStyles = withStyles(styles)(PlanNewFormDialog);
export default injectIntl(PlanNewFormDialogWithStyles);
