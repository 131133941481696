import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepLabel';
import { Redirect } from 'react-router';
import { capitalize, get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

const styles = theme => ({
  root                : {
    marginBottom : 20,
    paddingTop   : 10,
  },
  stepper             : {
    background : 'transparent',
  },
  button              : {
    marginRight : 20,
    cursor      : 'pointer',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  rjpeColor           : {
    color : '#002949 !important',
  },
});

class DealStepper extends React.Component {
  state = { redirect: null };
  render () {
    const { classes, activeStep, dealID, deal, stylesheetName, updateWord, intl } = this.props;
    const { redirect } = this.state;

    const isPublished = get(deal, 'deal_state', '') === 'published';

    if (redirect) {
      return <Redirect push to={redirect} />;
    }
    return (
      <div className={classes.root}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          <Step key="step_0">
            <StepButton
              disabled={!isPublished}
              StepIconProps={{
                classes : {
                  completed : classes[`${stylesheetName}Color`],
                  active    : classes[`${stylesheetName}Color`],
                },
              }}
              className={classes.button}
              onClick={() => {
                if(!isPublished) return;
                if (activeStep !== 0) {
                  this.setState({ redirect: '/deal/create' });
                }
              }}>
              <FormattedMessage id="dr_wizard.getting_started" />
            </StepButton>
          </Step>
          <Step key="step_1">
            <StepButton
              disabled={!isPublished}
              StepIconProps={{
                classes : {
                  completed : classes[`${stylesheetName}Color`],
                  active    : classes[`${stylesheetName}Color`],
                },
              }}
              className={classes.button}
              onClick={() => {
                if(!isPublished) return;
                if (activeStep !== 1) {
                  this.setState({ redirect: '/deal/wizard' });
                }
              }}>
              <FormattedMessage id="dr_wizard.create_your_teaser" />
            </StepButton>
          </Step>
          <Step key="step_2">
            <StepButton
              disabled={!isPublished}
              StepIconProps={{
                classes : {
                  completed : classes[`${stylesheetName}Color`],
                  active    : classes[`${stylesheetName}Color`],
                },
              }}
              className={classes.button}
              onClick={() => {
                if(!isPublished) return;
                if (activeStep > 0 && activeStep !== 2) {
                  this.setState({ redirect: '/deal/questions' });
                }
              }}>
              {intl.locale === 'en' && `Add ${stylesheetName === 'rjpe' ? 'Discovery' : 'Knockout'} Question`}
              {intl.locale === 'fr' && <FormattedMessage id="dr_wizard.add_knockout_question" />}
            </StepButton>
          </Step>
          <Step key="step_3">
            <StepButton
              StepIconProps={{
                classes : {
                  completed : classes[`${stylesheetName}Color`],
                  active    : classes[`${stylesheetName}Color`],
                },
              }}
              className={classes.button}
              disabled={!isPublished}
              onClick={() => {
                if(!isPublished) return;
                if (activeStep > 1 && activeStep !== 3) {
                  this.setState({ redirect: '/deal/survey' });
                }
              }}>
              <FormattedMessage id="dr_wizard.tell_us_what_your_looking_for" />
            </StepButton>
          </Step>
          <Step key="step_4">
            <StepButton
              disabled={!isPublished}
              StepIconProps={{
                classes : {
                  completed : classes[`${stylesheetName}Color`],
                  active    : classes[`${stylesheetName}Color`],
                },
              }}
              className={classes.button}>
              {intl.locale === 'en' && `Review & ${updateWord ? capitalize(updateWord) : 'Create'}`}
              {intl.locale === 'fr' && <FormattedMessage id="dr_wizard.publish" />}
            </StepButton>
          </Step>
        </Stepper>
      </div>
    );
  }
}

DealStepper.propTypes = {
  classes    : PropTypes.object.isRequired,
  activeStep : PropTypes.number,
};

DealStepper.defaultProps = {
  activeStep : 0,
};

export default injectIntl(withStyles(styles)(DealStepper));
