import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize } from 'lodash';

const SaveButton = ({ classes, dealInterest, agentWordForCompany }) => {
  return (
    <div className={classes.margin}>
      <a href={`/deal/${get(dealInterest, 'deal.deal_name')}/save/${get(dealInterest, 'id')}`}>
        {!get(dealInterest, 'saved') && <i className="far fa-heart" style={{ marginRight: 5 }} />}{' '}
        {get(dealInterest, 'saved') && <i className="fas fa-heart" style={{ marginRight: 5, color: '#FF5087' }} />}<FormattedMessage id="profile_page.save_button"/> 
      </a>
    </div>
  );
};

const styles = {
  margin : {
    margin : '0px 0px 15px 25px',
  },
};

export default withStyles(styles)(SaveButton);
