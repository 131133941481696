import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

Menu.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  children: PropTypes.object,
};

Menu.defaultProps = {
  selectProps: {},
  innerProps: {},
  children: {},
};

export default Menu;
