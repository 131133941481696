import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import { ApolloConsumer, Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import * as actions from '../../actions/dealActionCreators';
import gql from 'graphql-tag';
import numeral from 'numeral';
import { get, uniqBy, find, capitalize } from 'lodash';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import Checkbox from '@material-ui/core/Checkbox';
import CheckboxList from '../CheckboxList/CheckboxList';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';
import MatchMakingContainer from '../../containers/MatchMakingContainer';
import InventoryUserDrawer from '../InventoryUserDrawer/InventoryUserDrawer';
import MatchSurvey from '../../components/MatchSurvey/MatchSurvey';
import DealQuestions from '../DealWizard/DealQuestions';
import { InventoryUserQuery } from '../InventoryUsers/InventoryUserQueries';
import DealForm from './DealForm';
import { UpdateDealMutation } from '../Deals/DealQueries';
import { FormattedMessage, injectIntl } from 'react-intl';

class DealPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deal: props.deal.deal,
      matches: get(props.deal, 'deal.matches', []),
      deal_interests: get(props.deal, 'deal_interest_users'),
      checkedMatches: [],
      checkedDealInterests: [],
      value: 0,
      publishState: props.deal.deal.deal_state,
      includeRolodex: true,
      setInState: false,
    };

    props.updateDeal(props.deal.deal);
  }

  updateDeal = (mutate, togglePublished) => {
    const { enqueueSnackbar, deal, updateDeal, intl } = this.props;
    const { deal_interests } = this.state;
    if (togglePublished) {
      deal.deal_state = deal.deal_state === 'published' ? 'new_deal' : 'published';
      updateDeal({ publishState: deal.deal_state });
    }

    mutate({
      variables: {
        ...deal,
        headline: encodeURI(deal.headline),
        sub_headline: encodeURI(deal.sub_headline),
        summary_of_business: encodeURI(deal.summary_of_business),
        growth_opportunities: encodeURI(deal.growth_opportunities),
        assets_included: encodeURI(deal.assets_included),
        intangible_assets_included: encodeURI(deal.intangible_assets_included),
        customer_demographics_comments: encodeURI(deal.customer_demographics_comments),
        products_and_services_comments: encodeURI(deal.products_and_services_comments),
        owner_id: get(deal, 'owner.id'),
        deal_interest_users: deal_interests.map(u => ({
          inventory_user_id: u.id,
          reason_for_suggestion: u.reason_for_suggestion,
        })),
      },
    }).then(response => {
      enqueueSnackbar(intl.formatMessage({ id: 'dr_wizard.deal_successfully_saved' }), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    });
  };

  addDealInterestUsers = () => {
    const { deal_interests, matches, checkedMatches } = this.state;
    const newMatches = matches;
    checkedMatches.map(id => {
      const inventoryUserIndex = matches.findIndex(u => u.id === id);
      if (inventoryUserIndex > -1) {
        const inventoryUser = newMatches[inventoryUserIndex];
        deal_interests.push(inventoryUser);
        newMatches.splice(inventoryUserIndex, 1);
        this.setState({ checkedMatches: [], deal_interests, matches: newMatches });
      }
    });
  };

  removeDealInterestUsers = () => {
    const { deal_interests, matches, checkedDealInterests } = this.state;
    const newDealInterests = deal_interests;
    checkedDealInterests.map(id => {
      const inventoryUserIndex = deal_interests.findIndex(u => u.id === id);
      if (inventoryUserIndex > -1) {
        const inventoryUser = newDealInterests[inventoryUserIndex];
        matches.unshift(inventoryUser);
        newDealInterests.splice(inventoryUserIndex, 1);
        this.setState({ checkedMatches: [], deal_interests: newDealInterests, matches });
      }
    });
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  detailsComplete = () => {
    const { deal } = this.props;

    return (
      Boolean(deal.headline) &&
      Boolean(deal.sub_headline) &&
      Boolean(deal.owner) &&
      Boolean(deal.summary_of_business) &&
      Boolean(deal.summary_of_business) &&
      Boolean(deal.growth_opportunities) &&
      Boolean(deal.assets_included) &&
      Boolean(deal.intangible_assets_included) &&
      Boolean(deal.customer_demographics_comments) &&
      Boolean(deal.products_and_services_comments)
    );
  };

  interestsComplete = () => {
    const { deal_interests } = this.state;

    return Boolean((deal_interests || []).length);
  };

  userClick = async (invUser, client) => {
    const { updateSelectedInventoryUser } = this.props;

    updateSelectedInventoryUser({
      user: invUser,
    });

    const result = await client.query({
      query: InventoryUserQuery,
      variables: { id: invUser.id },
      fetchPolicy: 'network-only',
    });
    updateSelectedInventoryUser({
      user: get(result, 'data.getInventoryUser'),
    });
  };

  clickCheckBox = () => {
    const { includeRolodex, deal_interests } = this.state;
    this.setState({
      includeRolodex: !includeRolodex,
      setInState: false,
      deal_interests: deal_interests.filter(d => !d.isRolodex),
    });
  };

  render() {
    const { classes, updateSelectedInventoryUser, inventory_users, deal, match_preference, updateDeal} = this.props;
    const company = get(inventory_users, 'inventoryUser.user.user.company');
    const stylesheetName = get(company, 'stylesheet_name');
    const businessType = get(company, 'business_type');
    const companyName = get(company, 'name');
    const hasAffiliation = get(company, 'affiliation_type');
    const {
      matches,
      deal_interests,
      checkedMatches,
      checkedDealInterests,
      value,
      publishState,
      includeRolodex,
      setInState,
    } = this.state;
    if (!matches) return 'No matches found for this user';
    let sanMatches = matches.filter(item1 => !deal_interests.some(item2 => `${item2.id}` === `${item1.id}`));
    return (
      <ApolloConsumer>
        {client => (
          <div>
            <h2 className={classes.title}>
              {`${get(deal, 'inventory_user.name')} | ${numeral(get(deal, 'list_price')).format('$0.0a')}`}
              <IconButton
                onClick={() => {
                  this.userClick(get(deal, 'inventory_user'), client);
                }}>
                <InfoIcon />
              </IconButton>
              {publishState === 'published' && (deal.approval_status ? 'Approved' : 'Under Review')}
              <h3>
              <FormattedMessage id="deal_page.type_created" values={{deal_type: capitalize(deal.deal_type) , deal_creation_date: deal.deal_creation_date}}/> {deal.closed && " | Closed"} 
              </h3>
              <div className={classes.buttonContainer}>
                <div style={{ fontSize: 15, display: 'inline' }}>
                  <FormattedMessage id="deal_page.include_rolodex"/>
                  <Checkbox
                    type="checkbox"
                    id={deal.id}
                    checked={includeRolodex}
                    onChange={() => this.clickCheckBox()}
                    style={{ fontSize: 14 }}
                  />
                </div>
                <Button variant="outlined" href="/admin/deals">
                  <FormattedMessage id="deal_page.back_to_deals"/>
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  disabled={publishState !== 'published'}
                  href={`/admin/pipelines/${deal.deal_pipeline_id}?inventoryUser=${deal.inventory_user_id}`}>
                   <FormattedMessage id="deal_page.pipeline"/>
                </Button>
                &nbsp;
                <Button
                  variant="outlined"
                  href={`/deal/${deal.deal_name}/teaser?id=${get(deal, 'inventory_user.user.id')}`}
                  target="__blank">
                  {publishState === 'published' ? 'View Teaser' : 'Preview Teaser'}
                </Button>
                &nbsp;
                <Mutation mutation={UpdateDealMutation}>
                  {updateDealMutation => (
                    <span>
                      <Button
                        variant="contained"
                        onClick={() => {
                          this.updateDeal(updateDealMutation);
                        }}>
                        <FormattedMessage id="deal_page.save"/>
                      </Button>
                      &nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes[deal.deal_state]}
                        onClick={() => {
                          this.updateDeal(updateDealMutation, true);
                        }}>
                        {deal.deal_state === 'published' ? 'Unpublish' : 'Publish'}
                      </Button>
                      &nbsp;
                    </span>
                  )}
                </Mutation>
              </div>
            </h2>
            <AppBar position="static">
              <Tabs value={value} onChange={this.handleChange}>
                <Tab
                  label={
                    <span>
                      <FormattedMessage id="deal_page.details"/>
                      {this.detailsComplete() && (
                        <CheckIcon
                          style={{
                            verticalAlign: 'bottom',
                          }}
                          fontSize="small"
                        />
                      )}
                    </span>
                  }
                />
                <Tab
                  label={
                    <span>
                      Discovery Questions
                      {this.detailsComplete() && (
                        <CheckIcon
                          style={{
                            verticalAlign: 'bottom',
                          }}
                          fontSize="small"
                        />
                      )}
                    </span>
                  }
                />
                <Tab
                  label={
                    <span>
                      Match Preferences
                      {this.detailsComplete() && (
                        <CheckIcon
                          style={{
                            verticalAlign: 'bottom',
                          }}
                          fontSize="small"
                        />
                      )}
                    </span>
                  }
                />
                <Tab
                  label={
                    <span>
                      <FormattedMessage id="deal_page.MatchMaking"/>
                      {this.interestsComplete() && (
                        <CheckIcon
                          style={{
                            verticalAlign: 'bottom',
                          }}
                          fontSize="small"
                        />
                      )}
                    </span>
                  }
                />
              </Tabs>
            </AppBar>
            <br />

            {value === 0 && (
              <DealForm
                fieldChange={this.fieldChange}
                numericFieldChange={this.numericFieldChange}
                updateState={updateDeal}
                deal={deal}
                fromAdmin
              />
            )}
            {value === 1 && (    
                <DealQuestions
                  deal={deal.deal}
                  match_preference={match_preference}
                  updateDealInRedux={d => {updateDeal({...deal, deal : {...deal.deal, ...d}})}}
                  stylesheetName={stylesheetName}
                  businessType={businessType}
                  companyName={companyName}
                  showContinuityButtons={false}
                  afterSaveRedirect={`/admin/deals/${deal.id}`}
                />
            )}
            {value === 2 && (
              <MatchSurvey
                deal={deal}
                match_preference={get(deal, 'user.match_preference')}
                user={get(deal, 'user')}
                stylesheetName={stylesheetName}
                hasAffiliation={hasAffiliation}
                showContinuityButtons={false}
                afterSaveRedirect={`/admin/deals/${deal.id}`}
              />
            )}
            {value === 3 && (
              <div>
                <MatchMakingContainer
                  inventoryUserId={get(deal, 'inventory_user.id')}
                  deal={deal}
                  classes={classes}
                  includeRolodex={includeRolodex}
                  updateState={state => this.setState({ ...state })}
                  deal_interests={deal_interests}
                  matches={matches}
                  checkedMatches={checkedMatches}
                  checkedDealInterests={checkedDealInterests}
                  value={value}
                  publishState={publishState}
                  userClick={this.userClick}
                  setInState={setInState}
                />
              </div>
            )}

            <InventoryUserDrawer
              inventoryUser={get(inventory_users, 'inventoryUser.user')}
              inventory_users={[]}
              updateAction={updateSelectedInventoryUser}
              updateInventoryUser={() => { }}
              showBench={false}
              showEditButton={false}
              loadBenchUsers={() => { }}
              inviteButton={() => ''}
              country="us"
              openSnack={() => {
                this.handleSnackOpen('User Updated');
              }}
              agentWordForCompany="agent"
              currentUserIsAdmin={false}
              refetch={() => ''}
              showActivities={false}
              onlyUsers
              company={company}
            />
          </div>
        )}
      </ApolloConsumer>
    );
  }
}

DealPage.propTypes = {
  classes: PropTypes.object.isRequired,
  inventory_users: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  updateSelectedInventoryUser: PropTypes.func.isRequired,
};

const styles = theme => ({
  title: {
    marginLeft: 16,
  },
  container: {
    alignItems: 'center',
    marginLeft: 12,
    overflow: 'scroll',
  },
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 12,
    maxWidth: 700,
  },
  list: {
    overflow: 'scroll',
    height: 'calc(100vh - 340px)',
    width: 700,
    border: '1px solid #dadada',
    borderRadius: '4px',
    margin: 4,
  },
  matchList: {
    overflow: 'scroll',
    height: 'calc(100vh - 340px)',
    width: 380,
    border: '1px solid #dadada',
    borderRadius: '4px',
    margin: 4,
  },
  button: {
    margin: 12,
  },
  buttonContainer: {
    float: 'right',
  },
  gridItem: {
    padding: 12,
  },
  invSearch: {
    margin: 4,
    border: '1px solid #dadada',
    borderRadius: '4px',
    padding: 4,
    width: 700,
  },
  published: {
    background: 'red',
  },
  new_deal: {
    background: 'green',
  },
  back: {
    float: 'right',
    fontSize: 18,
    paddingRight: 8,
    position: 'relative',
    paddingTop: 12,
  },
});

const mapStateToProps = state => ({
   ...state,
    deal: state.deal,
});

export default connect(mapStateToProps, { ...actions })(withStyles(styles)(withSnackbar(injectIntl(DealPage))));
