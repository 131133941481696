import { replace, findIndex } from 'lodash';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

export const classNames = classArray => {
  return classArray.join(' ');
};

export const clearSpotlights = () => {
  const spotlighted = document.querySelectorAll('.joyride-rel-hole');

  spotlighted.forEach(el => {
    el.className = replace(el.className, ' joyride-rel-hole hole', '');
  });
};

export const spotlightElement = selector => {
  clearSpotlights();
  const el = document.querySelector(selector);

  if (el && el.className.indexOf('joyride-rel-hole') < 0) el.className += ' joyride-rel-hole hole';
  if (el) {
    el.scrollIntoView();
  }
};

export const sanitizeNumber = stringNumber => {
  return stringNumber.replace(/\D/g, '').replace(' ', '').trim();
};

export const listOfStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const listOfProvinces = [
  'Alberta',
  'British Columbia',
  'Manitoba',
  'New Brunswick',
  'Newfoundland and Labrador',
  'Northwest Territories',
  'Nova Scotia',
  'Nunavut',
  'Ontario',
  'Prince Edward Island',
  'Quebec',
  'Saskatchewan',
  'Yukon',
];

export const regionForCountry = (country = 'ca', getAll = false) => {
  if (getAll) return listOfStates.concat(listOfProvinces);
  if (country === 'ca') return listOfProvinces;
  return listOfStates;
};

const defaultColors = [
  '#2ecc71', // emerald
  '#3498db', // peter river
  '#8e44ad', // wisteria
  '#e67e22', // carrot
  '#e74c3c', // alizarin
  '#1abc9c', // turquoise
  '#2c3e50', // midnight blue
];

export const bgColorForName = (userName = '') => {
  let sum = 0;
  const name = userName || '';
  if (!name) return defaultColors[6];
  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i);
  }
  const idx = sum % defaultColors.length;
  return defaultColors[idx];
};

export const changeAppBarTitle = title => {
  const el = document.querySelector('.appBarTitle');
  if (el) {
    el.innerHTML = title;
  }
};

// export const capturePdf = (orientation, x, y, w, h) => {
//   const url = window.location.pathname;
//   const filename = `${url.substring(url.lastIndexOf('/') + 1)}.pdf`;
//   html2canvas(document.getElementById('capture'), { scale: 2 }).then(canvas => {
//     const pdf = new jsPDF(orientation, 'mm', 'a4');
//     const width = pdf.internal.pageSize.getWidth() * 2;
//     const height = pdf.internal.pageSize.getHeight() * 0.75;
//     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', x, y, w, h);
//     pdf.save(filename);
//   });
// };

export const capturePdf = (orientation, x, y, w, h, scale) => {
  const url = window.location.pathname;
  const filename = `${url.substring(url.lastIndexOf('/') + 1)}.pdf`;
  html2canvas(document.getElementById('capture'), { scale }).then(canvas => {
    let pdf;
    if (w && h) {
      pdf = new jsPDF(orientation, 'mm', 'a4');
      pdf.addImage(canvas.toDataURL('image/png'), 'PNG', x, y, w, h);
    } else {
      pdf = new jsPDF(orientation, 'pt', 'a4');
      pdf.addImage(canvas, 'PNG', x, y, canvas.width, canvas.height);
    }
    pdf.save(filename);
  });
};

export const initX = () => {
  document.x = {
    aInternal        : null,
    aListener        : function (val) {},
    set a (val) {
      this.aInternal = val;
      this.aListener(val);
      document.x.aInternal = val;
    },
    get a () {
      return this.aInternal;
    },
    registerListener : function (listener) {
      this.aListener = listener;
    },
  };
};

export const getMotivation = (transitionGoals = []) => {
  if (transitionGoals.length === 0) return 'EMPTY';
  let index = findIndex(transitionGoals, g => g.match(/.*(not interested).*/));
  if (index > -1) return 'NONE';

  index = findIndex(transitionGoals, g => g.match(/.*(Buying|Become|Partner).*/));
  if (index > -1) return 'GROWTH';

  return 'SUCCESSION';
};

export const intlCurrencyConfig = intl => {
  if (intl.locale == 'fr') {
    return { locale: 'fr-CA', currency: 'CAD' };
  }
  return { locale: 'en-US', currency: 'USD' };
};
