import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { get, isEmpty, capitalize } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';
import { createDealMutation } from './DealQueries';

class NewDealDialog extends React.Component {
  originalState = {
    inventoryUser       : null,
    owner               : get(this.props, 'currentInventoryUser.id', null),
    number_of_invites   : null,
    invitation_deadline : moment(moment('12:00', 'HH:mm')).add(1, 'month'),
    open                : false,
  };

  state = { ...this.originalState };

  handleClickOpen = () => {
    const { currentInventoryUser } = this.props;
    this.setState({ open: true, owner: currentInventoryUser });
  };

  handleClose = () => {
    window.history.pushState({}, document.title, '/admin/deals');
    this.setState({ ...this.originalState });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  render () {
    const {
      classes,
      activityTypes,
      pipelineCard,
      refetch,
      buttonType,
      updateDeals,
      deals,
      agentWordForCompany,
      currentInventoryUser,
      defaultDealOwnerCustomer,
    } = this.props;
    const { open, number_of_invites, inventoryUser, owner, invitation_deadline } = this.state;
    return (
      <div style={{ display: 'contents' }}>
        <Icon style={{ fontSize: 40, cursor: 'pointer' }} color="primary" onClick={this.handleClickOpen}>
          add_circle
        </Icon>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Add a new deal</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <InventoryUserSearchField
              label={`${capitalize(agentWordForCompany)}`}
              value={inventoryUser}
              onChange={v => {
                this.setState({ inventoryUser: v });
              }}
            />
            {!defaultDealOwnerCustomer && (
              <ManagerSearchField
                label="Owner"
                value={owner}
                onChange={v => {
                  this.setState({ owner: v });
                }}
              />
            )}
            <TextField
              id="number_of_invites"
              label="Number of Invites Allowed"
              fullWidth
              type="number"
              className={classes.textField}
              value={number_of_invites}
              onChange={this.handleChange('number_of_invites')}
              margin="normal"
            />
            <div>
              <br />
              <b>Invitation Deadline</b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={invitation_deadline}
                  onChange={date => this.setState({ invitation_deadline: date })}
                />
              </MuiPickersUtilsProvider>
            </div>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Mutation mutation={createDealMutation}>
              {createDeal => (
                <Button
                  onClick={() => {
                    createDeal({
                      variables : {
                        inventory_user_id   : inventoryUser.id,
                        owner_id            : owner.id,
                        number_of_invites   : parseInt(number_of_invites, 10),
                        invitation_deadline,
                      },
                    }).then(r => {
                      this.handleClose();
                      window.open(`/admin/deals/${get(r, 'data.createDeal.id', '')}`, '_self');
                    });
                  }}
                  color="primary"
                  disabled={!number_of_invites || !inventoryUser || !owner || !invitation_deadline}>
                  Save
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const styles = {
  taskButton : {
    marginLeft : 'auto',
  },
};

NewDealDialog.propTypes = {
  classes              : PropTypes.object.isRequired,
  activityTypes        : PropTypes.array.isRequired,
  pipelineCard         : PropTypes.object,
  currentInventoryUser : PropTypes.object,
  refetch              : PropTypes.func.isRequired,
  buttonType           : PropTypes.string,
};

NewDealDialog.defaultProps = {
  pipelineCard         : {},
  buttonType           : 'text',
  currentInventoryUser : {},
};

export default withStyles(styles)(NewDealDialog);
