import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { groupBy, get, isEmpty, capitalize, flowRight } from 'lodash';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import PandLData from './PandLData';
import PandLDataWealthRj from './PandLDataWealthRj';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

const UpdateCurrentInventoryUser = gql`
  mutation editInventoryUser($id: ID!, $minimum_production_threshold: Boolean) {
    editInventoryUser(id: $id, minimum_production_threshold: $minimum_production_threshold)
  }
`;

class PandLTab extends React.Component {
  constructor (props) {
    super(props);
    const { inventoryUser } = this.props;
    const user = get(inventoryUser, 'user', []);
    this.state = {
      open1                        : isEmpty(user),
      open2                        : !isEmpty(user),
      id                           : inventoryUser.id,
      minimum_production_threshold : inventoryUser.minimum_production_threshold,
    };
  }

  componentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.id !== nextProps.inventoryUser.id) {
      const user = get(nextProps.inventoryUser, 'user', []);
      this.setState({
        ...nextProps.inventoryUser,
        open1 : isEmpty(user),
        open2 : !isEmpty(user),
      });
    }
  }

  handleOpen1 = () => {
    const { open1 } = this.state;
    this.setState({ open1: !open1 });
  };

  handleOpen2 = () => {
    const { open2 } = this.state;
    this.setState({ open2: !open2 });
  };

  clickCheckbox = () => {
    const { minimum_production_threshold } = this.state;
    this.setState(
      {
        minimum_production_threshold : !minimum_production_threshold,
      },
      () => {
        this.editInventoryUser();
      }
    );
  };

  editInventoryUser = () => {
    const { mutate, inventoryUser, updateInventoryUser, updateAction, openSnack, refetch } = this.props;
    const { id, minimum_production_threshold } = this.state;
    mutate({
      variables : {
        id,
        minimum_production_threshold,
      },
    })
      .then(() => {
        // updateInventoryUser({ ...inventoryUser, ...this.state });
        // updateAction({ user: { ...inventoryUser, ...this.state } });
        refetch();
        openSnack();
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  render () {
    const { inventoryUser, classes, agentWordForCompany, onlyUsers, company } = this.props;
    const { open1, open2, minimum_production_threshold } = this.state;
    const user = get(inventoryUser, 'user', []);
    const displayPandC= get(company, 'feature_types', []).includes( 'display_p_and_c_values' );

    return (
      <div>
        {!onlyUsers && (
          <div>
            <ExpansionPanel expanded={open1}>
              <ExpansionPanelSummary
                onClick={() => this.handleOpen1()}
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root : classes.root,
                }}>
                <ListItemIcon>
                  <i class="material-icons">assessment</i>
                </ListItemIcon>
                <Typography className={classes.heading}>Enterprise Supplied</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                classes={{
                  root : classes.details,
                }}>
                <Grid item xs={12}>
                  <Grid container spacing={0} className={classes.dataLeft}>
                    <Grid item xs={12}>
                      {displayPandC ?
                        <PandLData
                          user={{
                            ...inventoryUser,
                            number_of_clients : inventoryUser.clients,
                            annual_revenue    : inventoryUser.rev_yield,
                          }}
                        />
                       :
                        <PandLDataWealthRj
                          user={{
                            ...inventoryUser,
                            number_of_clients : inventoryUser.clients,
                            annual_revenue    : inventoryUser.rev_yield,
                          }}
                        />
                      }
                    </Grid>
                    <br />
                    <Grid item xs={7}>
                      <div style={{ fontWeight: 'bold', margin: '15px 0px 0px 10px', color: 'rgba(0, 0, 0, 0.54)' }}>
                        Minimum Production Threshold met:{' '}
                      </div>
                    </Grid>
                    <Grid item xs={5}>
                      <Checkbox checked={minimum_production_threshold} onChange={this.clickCheckbox} />
                    </Grid>
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
        <div>
          <ExpansionPanel disabled={isEmpty(user)} expanded={open2}>
            <ExpansionPanelSummary
              onClick={() => this.handleOpen2()}
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root : classes.root,
              }}>
              <ListItemIcon>
                <i class="material-icons">assessment</i>
              </ListItemIcon>
              <Typography className={classes.heading}>
                {user ? (
                  'User Supplied'
                ) : (
                  'P&L information will be displayed when an [agent/advisor] creates their marketplace profile'
                )}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              classes={{
                root : classes.details,
              }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  {user && (
                    <p className={classes.percentage}>
                      Percentage of Profile Complete: {user.profile_percentage_complete}%
                    </p>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {displayPandC ? <PandLData user={user} /> : <PandLDataWealthRj user={user} showUserSide />}
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }
}

PandLTab.propTypes = {
  classes       : PropTypes.object.isRequired,
  inventoryUser : PropTypes.object.isRequired,
  onlyUsers     : PropTypes.bool,
};

PandLTab.defaultProps = {
  onlyUsers : false,
};

const styles = {
  card       : {
    height       : 90,
    borderRadius : 0,
  },
  root       : {
    color      : 'black',
    background : '#F6F6F6',
    '&:hover'  : { background: '#E2E2E2' },
  },
  container  : {
    height          : '100%',
    backgroundColor : '#eeeeee',
    padding         : 10,
  },
  cardgroup  : {
    height       : 100,
    marginTop    : 30,
    marginBottom : 30,
  },
  title      : {
    padding : 4,
    float   : 'left',
  },
  close      : {
    float : 'right',
  },
  date       : {
    fontSize : 15,
    margin   : 8,
  },
  noUser     : {
    height : '150px',
    margin : 10,
  },
  noUserText : {
    margin   : 10,
    fontSize : 20,
  },
  details    : {
    padding : 0,
  },
  percentage : {
    margin : 10,
  },
};

export default withStyles(styles)(injectIntl(graphql(UpdateCurrentInventoryUser)(PandLTab)));
