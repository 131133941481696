import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import JssProvider from 'react-jss/lib/JssProvider';
import { SnackbarProvider, withSnackbar } from 'notistack';
import { keys } from 'lodash';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../../lib/themes';

const messageTypeMap = {
  info    : 'default',
  danger  : 'error',
  success : 'success',
  warning : 'info',
  ''      : 'default',
};

const Snackbars = ({ flashes, enqueueSnackbar, closeSnackbar }) => {
  keys(flashes).map(k => {
    const value = flashes[k];
    const isString = typeof value === 'string';

    enqueueSnackbar(isString ? value : value.message, {
      variant      : messageTypeMap[k],
      anchorOrigin : {
        vertical   : 'top',
        horizontal : 'center',
      },
      persist      : !isString,
      action       : key => (
        <React.Fragment>
          {!isString && (
            <Button
              variant="outlined"
              onClick={() => {
                window.open(value.buttonHref);
                closeSnackbar(key);
              }}
              style={{ color: 'white', borderColor: 'white' }}>
              {value.buttonText}
            </Button>
          )}
          <IconButton
            onClick={() => {
              closeSnackbar(key);
            }}>
            <CloseIcon style={{ color: 'white' }} />
          </IconButton>
        </React.Fragment>
      ),
    });
  });

  return <React.Fragment />;
};

Snackbars.propTypes = {
  enqueueSnackbar : PropTypes.func.isRequired,
  flashes         : PropTypes.object,
};

Snackbars.defaultProps = {
  flashes : [],
};

const SnackbarsWithSnackbar = withSnackbar(Snackbars);
const SnackBarsWithProvider = (props, railsContext) => {
  const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS : true,
    productionPrefix        : 'fbSnack',
  });
  const jss = create(jssPreset());
  let theme = themes[railsContext.theme_name];
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbSnack-">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <SnackbarProvider maxSnack={5}>
          <SnackbarsWithSnackbar {...props} />
        </SnackbarProvider>
      </MuiThemeProvider>
    </JssProvider>
  );
};

export default SnackBarsWithProvider;
