import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/BlurOn';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { toggleObfuscateForUsers } from './InventoryUserQueries';

class InventoryUserObfuscateUserDialog extends React.Component {
  state = {
    open           : false,
    obfuscateUsers : true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleObfuscateUsers = () => {
    const { obfuscateUsers } = this.state;
    this.setState({ obfuscateUsers: !obfuscateUsers });
  };

  render () {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, obfuscateUsers } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Are you sure you want to un-obfuscate ${selected.length} users?`;
    let bodyText = "These user's profiles will be fully visible.";
    let obfuscatedText = 'un-obfuscated';

    if (open) {
      const selections = selected.map(s => find(data, [ 'id', s ]));
      validSelections = filter(selections, [ 'has_user', true ]);
      invalidSelections = filter(selections, [ 'has_user', false ]);

      if (obfuscateUsers) {
        message = `Are you sure you want to obfuscate ${selected.length} users?`;
        bodyText = "These user's profiles will have limited information displayed.";
        obfuscatedText = 'obfuscated';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Obfuscate / Unobfuscate User">
          <IconButton aria-label="Obfuscate/Unobfuscate User">
            <EditIcon onClick={this.handleClickOpen} />
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={obfuscateUsers} onClick={this.toggleObfuscateUsers} /> Obfuscate Users
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot be ${obfuscatedText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables : { usersToObfuscate: validSelections.map(iu => iu.user.id), obfuscated: obfuscateUsers },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              disabled={validSelections.length === 0}
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserObfuscateUserDialog.propTypes = {
  selected          : PropTypes.array.isRequired,
  changePage        : PropTypes.func.isRequired,
  data              : PropTypes.object.isRequired,
  mutate            : PropTypes.func.isRequired,
  loadBulkItemsList : PropTypes.func.isRequired,
};

export default graphql(toggleObfuscateForUsers)(InventoryUserObfuscateUserDialog);
