import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { findIndex, remove, toLower } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import CheckButton from '../../CheckButton/CheckButton';
import { S3Image } from '../../../lib/s3';
import { spotlightElement } from '../../../lib/findBobUtils';
import { STEP_NUMBERS } from '../SignUpWizard';
import { get } from 'lodash';
import Strengths from './Strengths';
import Designations from './Designations';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';
import { capitalize } from 'lodash';
import AffiliationsSelectorComponent from './AffiliationsSelectorComponent';

const styles = () => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
  rjpe              : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #002949',
    margin    : '0px',
  },
});

const stylesheet = name => {
  if (name) {
    return name;
  }
  return 'selected';
};
class StrengthsAndAvatar extends React.Component {
  updateUser = () => {
    const {
      mutate,
      onNext,
      designations,
      professionalStrengths,
      currentAvatar,
      disableAvatar,
      disableTransitionReadiness,
      updateAction,
    } = this.props;
    const des = designations;
    remove(des, o => findIndex(o, 'Your designations here') >= 0);
    const variables = {
      designations : des,
      tag_list     : professionalStrengths,
    };

    if (!disableAvatar) {
      variables.profile_picture = currentAvatar;
    }
    updateAction({ loading: true });
    mutate({
      variables,
    })
      .then(() => {
        // TODO: Add feature check here
        onNext(disableTransitionReadiness ? STEP_NUMBERS.bio : STEP_NUMBERS.planningInfo);
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  validate = () => {
    const { professionalStrengths, currentAvatar, disableAvatar } = this.props;
    if (disableAvatar) return professionalStrengths.length > 0;
    return professionalStrengths.length > 0 && currentAvatar !== 'advisor.png';
  };

  formatCompensationLabel = (v, t) => {
    if (v == 0) return t === 'value' ? '0%/100%' : 'Fee';
    if (v == 100) return t === 'value' ? '100%/0%' : 'Compensation';
    return `${v}%/${100 - v}%`;
  };

  toggleStrengths = e => {
    let { professionalStrengths } = this.props;
    const { updateAction } = this.props;

    const index = findIndex(professionalStrengths, o => o === e.target.value);
    professionalStrengths = [ ...professionalStrengths ];
    if (index > -1) {
      professionalStrengths.splice(index, 1);
    } else if (professionalStrengths.length < 5) {
      professionalStrengths.push(e.target.value);
    }
    updateAction({ tag_list: professionalStrengths });
  };

  toggleDesignations = e => {
    let { designations } = this.props;
    const { updateAction, availableDesignations } = this.props;

    const index = findIndex(designations, o => o === e);

    designations = [ ...designations ];
    if (index > -1) {
      designations.splice(index, 1);
    } else {
      designations.push(e);
    }
    updateAction({ designations });
  };

  render () {
    const {
      updateAction,
      avatars,
      designations,
      professionalStrengths,
      availableStrengths,
      currentAvatar,
      classes,
      disableAvatar,
      onBack,
      intl,
      modalTitle,
      modalSubtitle,
      locale,
      stylesheetName,
      availableDesignations,
      alterItems,
      availableAffiliations,
      affiliations,
      data,
      allowUserAffiliations,
    } = this.props;
    return (
      <div className="content">
        <h2>{modalTitle}</h2>
        <p>{modalSubtitle}</p>
        <div className={classes.formGroup}>
          {alterItems && <b><FormattedMessage id="sign_up_pages.i_excel_at" /></b>}
          {!alterItems && (
            <b>
              <FormattedMessage id="sign_up_pages.what_are_your_top_five_strengths" />
            </b>
          )}
          <Strengths
            newStrengthsToAdd={[]}
            professionalStrengths={professionalStrengths}
            updateAction={updateAction}
            toggleStrengths={this.toggleStrengths}
            availableStrengths={availableStrengths}
            locale={locale}
            stylesheetName={stylesheetName}
          />
        </div>
        {allowUserAffiliations &&
          <div className={classes.formGroup}>
            <AffiliationsSelectorComponent
              availableAffiliations={availableAffiliations}
              userAffiliations={affiliations}
              updateAction={updateAction}
              user={data.getCurrentUser}
            />
          </div>
        }
        <div
          className={classes.formGroup}
          onFocus={() => spotlightElement('.designations')}
          onMouseOver={() => spotlightElement('.designations')}>
          <div className={classes.groupTitle}>
            <b>
              <FormattedMessage id="sign_up_pages.which_designations_have_you_earned" />
            </b>
            <br />
            <br />
          </div>
          <Designations
            toggleDesignations={this.toggleDesignations}
            availableDesignations={availableDesignations}
            locale={locale}
            designations={designations}
            stylesheetName={stylesheetName}
          />
        </div>
        {!disableAvatar && (
          <div
            className={classes.formGroup}
            onFocus={() => spotlightElement('.avatar')}
            onMouseOver={() => spotlightElement('.avatar')}>
            <div className={classes.groupTitle}>
              <b>
                <FormattedMessage id="profile_page.avatar_section.choose_an_avatar" />
              </b>
              <br />
              <br />
            </div>
            {(avatars || []).map(a => {
              const imageName = `${a}-rectangle.png`;
              return (
                <button
                  type="button"
                  className={[ classes.avatarButton, currentAvatar === imageName && 'selected' ].join(' ')}
                  onClick={() => updateAction({ profile_picture: imageName })}>
                  <img alt={imageName} className={classes.avatarButtonImage} src={S3Image(imageName)} />
                </button>
              );
            })}
          </div>
        )}
        <br />
        <br />
        <br />
        <div className="text-center">
          {onBack && (
            <button
              type="button"
              className={'btn btn-default'}
              style={{ padding: '12px 24px', float: 'left', color: '#fff' }}
              onClick={onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
          <LoadingButtonWrapper stateChild="user">
            <button
              type="button"
              onClick={this.updateUser}
              className={'btn btn-default'}
              style={{ padding: '12px 24px', float: 'right', color: '#fff' }}
              disabled={!this.validate()}>
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          </LoadingButtonWrapper>
        </div>
      </div>
    );
  }
}

StrengthsAndAvatar.propTypes = {
  professionalStrengths : PropTypes.array,
  updateAction          : PropTypes.func.isRequired,
  disableAvatar         : PropTypes.bool,
  avatars               : PropTypes.array,
  currentAvatar         : PropTypes.string,
  onNext                : PropTypes.func,
  onBack                : PropTypes.func,
  availableStrengths    : PropTypes.array,
  availableDesignations : PropTypes.array,
  designations          : PropTypes.array,
  mutate                : PropTypes.func.isRequired,
  classes               : PropTypes.object.isRequired,
  intl                  : PropTypes.object.isRequired,
  modalTitle            : PropTypes.string,
  modalSubtitle         : PropTypes.string,
  locale                : PropTypes.string,
  availableAffiliations : PropTypes.array,
  affiliations          : PropTypes.array,
  data                  : PropTypes.object,
};

StrengthsAndAvatar.defaultProps = {
  disableAvatar         : false,
  avatars               : [],
  currentAvatar         : '',
  professionalStrengths : [],
  availableStrengths    : [],
  availableDesignations : [],
  designations          : [],
  onNext                : () => {},
  onBack                : () => {},
  modalTitle            : <FormattedMessage id="sign_up_pages.customize_your_profile" />,
  modalSubtitle         : <FormattedMessage id="sign_up_pages.complete_your_profile_to_appear" />,
  locale                : 'en',
  availableAffiliations : [],
  affiliations          : [],
  data                  : {},
};

export default injectIntl(withStyles(styles)(StrengthsAndAvatar));
