import {
  SUGGESTED_USER_LIST_UPDATE,
  SUGGESTED_USER_UPDATE,
  UPDATE_SELECTED_SUGGESTED_USER,
} from '../constants/suggestedUserConstants';

export const updateSuggestedUsersList = suggestedUsers => ({
  type           : SUGGESTED_USER_LIST_UPDATE,
  suggestedUsers,
});

export const updateSuggestedUser = suggestedUser => ({
  type          : SUGGESTED_USER_UPDATE,
  suggestedUser,
});

export const updateSelectedSuggestedUser = selectedSuggestedUser => ({
  type                  : UPDATE_SELECTED_SUGGESTED_USER,
  selectedSuggestedUser,
});
