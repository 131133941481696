import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { spotlightElement } from '../../lib/findBobUtils';
import { generatedHeadlineForUser, generatedBioForUser, generatedRjHeadlineForUser } from '../../lib/userUtils';
import { STEP_NUMBERS } from './SignUpWizard';
import LoadingButtonWrapper from '../LoadingButtonWrapper/LoadingButtonWrapper';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($headline: String, $bio: String, $ask_me_about: String) {
    updateCurrentUser(headline: $headline, bio: $bio, ask_me_about: $ask_me_about)
  }
`;

const styles = () => ({
  formGroup           : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle          : {
    paddingLeft : '6px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  ppiBackgroundColor  : {
    backgroundColor : '#7399c6',
    '&:hover'       : {
      background : '#949ca',
    },
  },
});

class UserBio extends React.Component {
  state = {};

  componentWillMount () {
    setTimeout(this.generateHeadline, 200);
  }
  generateHeadline = () => {
    const {
      headline,
      user,
      updateAction,
      intl,
      companyHasCounty,
      countryForCompany,
      agentWord,
      locale,
      companyBusinessType,
    } = this.props;
    let generateHeadline = '';
    if(locale !== 'fr') {
      if (companyBusinessType === 'wealth_rj') {
        generateHeadline = generatedRjHeadlineForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
      } else {
        generateHeadline = generatedHeadlineForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
      }
    }
    console.log('generateHeadline', generateHeadline);
    if (headline === null) {
      this.setState({
        headline : generateHeadline,
      }, () => {
        updateAction({
          headline : user.headline || generateHeadline,
          bio      : user.bio || generatedBioForUser(user),
        });
      });
    } else {
      this.setState({
        headline : headline || generateHeadline,
      });
    }
  }

  updateUser = () => {
    const { mutate, onNext, updateAction } = this.props;
    const { headline, ask_me_about } = this.state;
    updateAction({ loading: true });
    mutate({
      variables : {
        headline,
        ask_me_about,
      },
    })
      .then(() => {
        onNext(STEP_NUMBERS.complete);
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  validate = () => {
    const { alterItems, askMeAboutMandatory } = this.props;
    const { headline, ask_me_about } = this.state;
    if (alterItems && askMeAboutMandatory) {
      return headline && ask_me_about;
    }
    return headline;
  };

  render () {
    const {
      updateAction,
      onBack,
      classes,
      stylesheetName,
      alterItems,
      show_ask_about_me,
      askMeAboutMandatory,
      user,
    } = this.props;
    const { headline } = this.state;
    return (
      <div className="content">
        <h2>
          <FormattedMessage id="sign_up_pages.last_but_not_least" />
        </h2>
        <b>
          <FormattedMessage id="sign_up_pages.headline" />
        </b>
        <p>
          {user.is_student 
            ? <FormattedMessage id="sign_up_pages.student_headline_subtitle" />
            : <FormattedMessage id="sign_up_pages.headline_subtitle" />
          }
        </p>
        <div onMouseOver={e => spotlightElement('.headline')}>
          <TextareaAutosize
            placeholder={headline}
            value={headline}
            shouldFitContainer
            autoFocus
            minRows={4}
            isSpellCheckEnabled
            isLabelHidden
            multiline
            key="headline"
            style={{ width: '100%' }}
            onChange={e => {
              this.setState({ headline: e.target.value });
              updateAction({ headline: e.target.value });
            }}
          />
        </div>
        {alterItems &&
        show_ask_about_me && (
          <div>
            <br />
            <b><FormattedMessage id="sign_up_pages.ask_me_about" /></b>
            <p>
              <FormattedMessage id="sign_up_pages.ask_me_about_subtitle" />
            </p>
            <div>
              <TextareaAutosize
                shouldFitContainer
                autoFocus
                minRows={4}
                isSpellCheckEnabled
                isLabelHidden
                multiline
                key="headline"
                style={{ width: '100%' }}
                onChange={e => {
                  this.setState({ ask_me_about: e.target.value });
                  updateAction({ ask_me_about: e.target.value });
                }}
              />
              <p>
                <FormattedMessage id="sign_up_pages.click_below_to_complete" />
              </p>
            </div>
          </div>
        )}
        <br />
        <br />
        <div className="text-center">
          {onBack && (
            <button
              type="button"
              className={'btn btn-default'}
              style={{ padding: '12px 24px', float: 'left', color: '#fff' }}
              onClick={onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
          <LoadingButtonWrapper stateChild="user">
            <button
              type="button"
              onClick={this.updateUser}
              className={'btn btn-default'}
              style={{ padding: '12px 24px', float: 'right', color: '#fff' }}
              disabled={!this.validate()}>
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          </LoadingButtonWrapper>
        </div>
      </div>
    );
  }
}

UserBio.propTypes = {
  headline          : PropTypes.string,
  countryForCompany : PropTypes.string,
  agentWord         : PropTypes.string,
  locale            : PropTypes.string,
  user              : PropTypes.object,
  updateAction      : PropTypes.func.isRequired,
  mutate            : PropTypes.func.isRequired,
  onNext            : PropTypes.func.isRequired,
  intl              : PropTypes.object.isRequired,
  companyHasCounty  : PropTypes.bool,
  onBack            : PropTypes.func,
};

UserBio.defaultProps = {
  headline          : null,
  user              : {},
  companyHasCounty  : false,
  countryForCompany : 'ca',
  agentWord         : '',
  locale            : 'en',
  onBack            : null,
};

const StyledTextField = styled(TextField)`
  && .MuiInput-underline:after {
    border-color: #002949 !important;
  }
`;

const UserBioWithStyles = injectIntl(withStyles(styles)(UserBio));
export default graphql(UpdateCurrentUser)(UserBioWithStyles);
