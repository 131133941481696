import PropTypes from 'prop-types';
import React from 'react';
import BuyersAndSellersPageContainer from '../../containers/BuyersAndSellersPageContainer';

const BuyersAndSellersPageFull = ({ classes, data }) => {
  return (
    <div>
      <BuyersAndSellersPageContainer />
    </div>
  );
};

BuyersAndSellersPageFull.propTypes = {
  classes : PropTypes.object,
};

export default BuyersAndSellersPageFull;
