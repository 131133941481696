import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get, findIndex } from 'lodash';
import moment from 'moment';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

const getSteps = pipelineCard => [
  {
    key     : 'Matching',
    label   : 'Reviewing Matches',
    content : card => `${get(card, 'name', '').split('<>')[1]} was presented as a suggested match.`,
  },
  {
    key     : 'Invited',
    label   : 'Invited',
    content : card => {
      if(!get(card, 'deal_interest.invitation_date'))
        return '';

      return `${get(card, 'name', '').split('<>')[1]} was invited to view teaser on ${moment(
        get(card, 'deal_interest.invitation_date', '')
      ).format('MMMM D, YYYY')}`
    },
  },
  {
    key     : 'Reviewing Proposal',
    label   : 'Reviewing Proposal',
    content : card => {
      if (get(card, 'deal_interest.proposal_letter')) {
        return (
          <div>
            {get(card, 'name', '').split('<>')[1]} wrote the following proposal letter:
            <br />
            <i>"{get(card, 'deal_interest.proposal_letter', '')}"</i>
          </div>
        );
      }
      return `Awaiting proposal`;
    },
  },
  {
    key     : 'Shortlisted',
    label   : 'Shortlisted',
    content : card => `${get(card, 'name', '').split('<>')[1]} has been shortlisted.`,
  },
  {
    key     : 'NDA - Out for Signature',
    label   : `NDA - Awaiting ${get(pipelineCard, 'name', '').split('<>')[1]}'s Signature`,
    content : card => {
      if (get(card, 'deal_interest.receiving_party_nda_sent_timestamp')) {
        return `Signed on ${moment(get(card, 'deal_interest.receiving_party_nda_sent_timestamp', '')).format(
          'MMMM D, YYYY'
        )}.`;
      }
      return `Awaiting Signature`;
    },
  },
  {
    key     : 'NDA - Awaiting Seller Signature',
    label   : `NDA - Awaiting ${get(pipelineCard, 'name', '').split('<>')[0]}'s Signature`,
    content : card => {
      return (
        <div>
          <a href="#" onClick={() => downloadNda(card.deal_interest)}>
            Download fully executed NDA
          </a>
        </div>
      );
    },
  },
  {
    key     : 'Negotiating',
    label   : 'Negotiating',
    content : card => {
      const meetings = get(card, 'deal_interest.deal_scheduled_meetings', []);
      return (
        <div>
          {meetings.length > 0 && (
            <div>
              Meetings Scheduled:
              <ul>
                {meetings.map(m => (
                  <li>
                    <b>{m.format}</b>: {moment(m.start_time).format('MMM DD YYYY')}&nbsp;
                    {moment(m.start_time).format('h:mm a')} -&nbsp;
                    {moment(m.start_time).add(m.duration, 'm').format('h:mm a')}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {meetings.length === 0 && <p>No Meetings Scheduled</p>}
        </div>
      );
    },
  },
];

const downloadNda = dealInterest => {
  window.open(`/deal/${get(dealInterest, 'deal.deal_name')}/open_file/${get(dealInterest, 'id')}`, '_blank');
};

const getStepContent = step => {
  const stepFunction = stepContent[step];
  if (stepFunction) return stepFunction;
  return () => '';
};

export const DealStepTab = ({ card, classes }) => {
  const steps = getSteps(card);
  const currentStep = get(card, 'pipeline_stage.name');
  const stepIndex = findIndex(steps, { key: currentStep });
  return (
    <div>
      {currentStep !== 'Declined' && (
        <Stepper activeStep={stepIndex} orientation="vertical">
          {steps.map(({ key, label, content }, index) => {
            return (
              <Step
                active={index <= stepIndex}
                key={key}
                classes={{
                  root      : classes.step,
                  completed : classes.completed,
                }}>
                <StepLabel
                  classes={{
                    alternativeLabel : classes.alternativeLabel,
                    labelContainer   : classes.labelContainer,
                  }}
                  StepIconProps={{
                    classes : {
                      root      : classes.step,
                      completed : classes.completed,
                    },
                  }}>
                  {label}
                </StepLabel>
                <StepContent>
                  <span>{content(card)}</span>
                  <div className={classes.actionsContainer} />
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      )}
      {currentStep === 'Declined' && <span style={{ margin: 10 }}>This deal was declined</span>}
    </div>
  );
};

const styles = {
  step             : {
    '& $completed' : {
      color : 'green',
    },
    '& $active'    : {
      color : 'blue',
    },
    '& $disabled'  : {
      color : 'red',
    },
  },
  alternativeLabel : {},
  active           : {},
  disabled         : {},
  completed        : {
    color : '#000',
  },
  stepIcon         : {
    color : 'green',
  },
  labelContainer   : {
    '& $alternativeLabel' : {
      marginTop : 0,
    },
  },
};

export default withStyles(styles)(DealStepTab);
