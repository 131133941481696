import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import numeral from 'numeral';
import { VictoryPie } from 'victory';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { capturePdf } from '../../lib/findBobUtils';

const styles = theme => ({
  report   : {
    margin : '25px',
  },
  title    : {
    fontSize : '24px',
    color    : '#616161',
  },
  progress : {
    margin : 'auto',
  },
  card     : {
    margin   : 'auto',
    maxWidth : '600px',
  },
  media    : {
    height     : 0,
    paddingTop : '56.25%', // 16:9
  },
});

class GmvPage extends React.Component {
  clickedCapture = () => {
    capturePdf('p', 0, 0, '', '', 0.8);
  };

  render () {
    const { classes, data, intl } = this.props;
    const today = new Date();
    if (data.loading) return <CircularProgress size={68} className={classes.progress} />;
    return (
      <div id="capture">
        <section className={classes.report}>
          <Card className={classes.card}>
            <Tooltip title="Download PDF" style={{ float: 'right' }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            <CardHeader
              classes={{
                title : classes.title,
              }}
              title={intl.formatMessage({ id: 'admin_company_reports.gmv' })}
              subheader={today.toDateString()}
            />
            <VictoryPie
              colorScale={[ 'RoyalBlue', 'Grey' ]}
              data={[
                {
                  x : `${numeral(data.sacNetMarketValue).format('$0,0.00')}${intl.formatMessage({
                    id : 'admin_company_reports.net_total_market_value',
                  })}`,
                  y : parseFloat(data.sacNetMarketValue),
                },
                {
                  x : `${numeral(data.sacSusMarketValue).format('$0,0.00')}${intl.formatMessage({
                    id : 'admin_company_reports.unconfirmed',
                  })}`,
                  y : parseFloat(data.sacSusMarketValue),
                },
              ]}
            />
            <CardContent>
              <Typography gutterBottom variant="headline" component="h2">
                <FormattedMessage id="admin_company_reports.gmv" />
              </Typography>
              <Typography component="p">
                <strong>
                  <FormattedMessage id="admin_company_reports.gross_market_value" />:
                </strong>{' '}
                {numeral(data.sacGrossMarketValue).format('$0,0.00')} <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.net_market_value" />:
                </strong>{' '}
                <FormattedMessage id="admin_company_reports.sum_of_users_market_value" /> <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.unconfirmed" />:
                </strong>{' '}
                <FormattedMessage id="admin_company_reports.users_who_reported_less_than" /> <br />
                <strong>
                  <FormattedMessage id="admin_company_reports.grandfathered_users" />:{' '}
                </strong>{' '}
                {data.sacNilMarketValue} <br />
              </Typography>
            </CardContent>
          </Card>
        </section>
      </div>
    );
  }
}

GmvPage.propTypes = {
  data : PropTypes.shape({
    sacGrossMarketValue : PropTypes.number,
  }),
};

const GmvPageWithStyles = withStyles(styles)(GmvPage);
export default injectIntl(GmvPageWithStyles);
