/* eslint-disable import/prefer-default-export */

import {
  OPPORTUNITY_LIST_UPDATE,
  OPPORTUNITY_UPDATE,
  FILTER_UPDATE,
  UPDATE_STATUS,
} from '../constants/opportunityConstants';

export const updateOpportunities = opportunities => ({
  type          : OPPORTUNITY_LIST_UPDATE,
  opportunities,
});

export const updateOpportunity = opportunity => ({
  type        : OPPORTUNITY_UPDATE,
  opportunity,
});

export const updateFilter = filter => ({
  type   : FILTER_UPDATE,
  filter,
});

export const updateUser = user => ({
  type : OPPORTUNITY_INVENTORY_USER_UPDATE,
  user,
});

export const updateStatus = status => ({
  type   : UPDATE_STATUS,
  status,
});
