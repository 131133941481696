import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const styles = {
  saveButton    : {
    margin : '6px',
  },
  editorWrapper : {
    padding : '6px',
    display : 'block',
  },
  toolbar       : {
    background : '#dadada',
    padding    : '6px',
    margin     : 0,
  },
  editor        : {
    padding      : 0,
    marginBottom : '12px',
  },
  textArea      : {
    minHeight : '80px',
  },
};

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display              : [ 'INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS' ],
  INLINE_STYLE_BUTTONS : [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
  ],
  BLOCK_TYPE_BUTTONS   : [ { label: 'UL', style: 'unordered-list-item' }, { label: 'OL', style: 'ordered-list-item' } ],
};

class NotebookEditor extends Component {
  static propTypes = {
    onChange : PropTypes.func,
  };

  state = {
    value : RichTextEditor.createEmptyValue(),
  };

  onChange = value => {
    this.setState({ value });
    if (this.props.onChange) {
      this.props.onChange(value.toString('html'));
    }
  };

  clear = () => {
    this.setState({ value: RichTextEditor.createEmptyValue() });
  };

  onSave = () => {
    const { userName, mutate, onSave } = this.props;
    const { value } = this.state;
    onSave(mutate, userName, value.toString('html'), this.clear.bind(this));
  };

  render () {
    const { classes, intl } = this.props;
    return (
      <div className={classes.editorWrapper}>
        <RichTextEditor
          className={classes.editor}
          toolbarConfig={toolbarConfig}
          toolbarClassName={classes.toolbar}
          editorClassName={classes.textArea}
          value={this.state.value}
          onChange={this.onChange}
          placeholder={intl.formatMessage({ id: `notebook.create_a_new_note` })}
          autoFocus={true}
        />
        <div className={classes.saveButton}>
          <button className="btn btn-default" onClick={this.onSave}>
            <FormattedMessage id="shared.save" />
          </button>
        </div>
      </div>
    );
  }
}

const EditorWithStyle = withStyles(styles)(NotebookEditor);

export default injectIntl(EditorWithStyle);
