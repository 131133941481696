import { findIndex } from 'lodash';
import { UPDATE_EDUCATIONAL_CONTENTS, UPDATE_EDUCATIONAL_CONTENT } from '../constants/educationalContentConstants';

const updateEducationalContent = (educationalContents, educationalContent, modified = true) => {
  const index = findIndex(educationalContents, e => e.id === educationalContent.id);
  const educationalContentsNew = educationalContents;
  const educationalContentNew = educationalContent;
  if (index !== -1) {
    if (modified) educationalContentNew.modified = true;
    else educationalContentNew.modified = false;
    educationalContentsNew[index] = educationalContentNew;
  }
  return educationalContentsNew;
};

const educationalContentReducer = (
  state = {
    educationalContents : [],
    order               : 'desc',
    orderBy             : 'id',
    page                : 0,
  },
  action
) => {
  switch (action.type) {
    case UPDATE_EDUCATIONAL_CONTENTS:
      return { ...state, ...action.contents };
    case UPDATE_EDUCATIONAL_CONTENT:
      return { ...state, educationalContents: updateEducationalContent(state.educationalContents, action.content) };
    default:
      return state;
  }
};

export default educationalContentReducer;
