/* eslint-disable import/prefer-default-export */

import { TASK_UPDATE, LOAD_TASKS, TASK_LIST_UPDATE, ADD_TASK } from '../constants/taskConstants';

export const updateTaskCard = task => ({
  type : TASK_UPDATE,
  task,
});

export const loadTasks = taskList => ({
  type     : LOAD_TASKS,
  taskList,
});

export const updateTaskList = tasksList => ({
  type      : TASK_LIST_UPDATE,
  tasksList,
});

export const addTask = newTask => ({
  type    : ADD_TASK,
  newTask,
});
