import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import {get, range, reduce, mean, sum} from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import {Grid} from "@material-ui/core";
import { FormattedMessage } from 'react-intl';

const PrintableGrades = ({result, classes, company}) => {
  const cf = get(result, 'cashFlow', {});
  const sellerCfs = get(cf, 'illustration.sellerCashFlow', []);
  const buyer1Cfs = get(cf, 'illustration.buyer1CashFlow', []);
  const buyer2Cfs = get(cf, 'illustration.buyer2CashFlow', []) || [];

  const averageFreeCashFlow = mean(sellerCfs.map(s => s.freeCashFlow))
  const buyer1OperatingVsNetSales = sum(buyer1Cfs.map(s => s.afterTaxNetIncome)) / sum(buyer1Cfs.map(s => s.payout));
  const buyer2OperatingVsNetSales = sum(buyer2Cfs.map(s => s.afterTaxNetIncome)) / sum(buyer2Cfs.map(s => s.payout));

  const compFreeCashFlowBuyer1 = sum(buyer1Cfs.map(s => s.freeCashFlow)) / sum(buyer1Cfs.map(s => s.afterTaxNetIncome));
  const compFreeCashFlowBuyer2 = sum(buyer2Cfs.map(s => s.freeCashFlow)) / sum(buyer2Cfs.map(s => s.afterTaxNetIncome));

  const avgFreeCashFlowBuyer1 =  mean(buyer1Cfs.map(s => s.freeCashFlow));
  const avgFreeCashFlowBuyer2 = mean(buyer2Cfs.map(s => s.freeCashFlow));

  return <span className='title'>
      <h1>{cf.name}</h1>
      <p>Updated at: {moment(cf.createdAt).format('YYYY-MM-DD')}</p>
    </span>
};
const styles = theme => ({
  red: {
    color: 'red',
  }
});


const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    company : state.company,
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(PrintableGrades)
);
