import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import StarSigns from '../UserDetails/StarSigns';
import InfoBox from './InfoBox';
import { isEmpty, capitalize, replace } from 'lodash';
import { ProductMix, OldProductMix } from '../UserCharts';
import numeral from 'numeral';
import { get } from 'lodash';

const PandLDataWealthRj = ({ classes, user, showUserSide }) => (
  <Grid container spacing={0}>
    <Grid item xs={6}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <InfoBox title="Commissions Revenue">
            {capitalize(numeral(get(user, 'transactional_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Fee Revenue">
            {capitalize(numeral(get(user, 'fee_based_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Trail Revenue">
            {capitalize(numeral(get(user, 'trailer_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Direct Revenue">
            {capitalize(numeral(get(user, 'direct_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          {!showUserSide && (
            <InfoBox title="AUM">{capitalize(numeral(get(user, 'total_assets', 0)).format('$ 0 a'))}</InfoBox>
          )}
          {showUserSide && (
            <InfoBox title="AUM">
              {capitalize(numeral(get(user, 'assets_under_management', 0)).format('$ 0 a'))}
            </InfoBox>
          )}
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Clients">{get(user, 'number_of_clients', 'N/A')}</InfoBox>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

InfoBox.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  prodmixborder  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 310,
    width        : 230,
  },
  prodmixtitle   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
  },
  prodmixcontent : {
    textAlign : 'center',
    margin    : 7,
  },
};

export default withStyles(styles)(PandLDataWealthRj);
