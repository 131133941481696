import { findIndex } from 'lodash';
import {
  SUGGESTED_DEAL_LIST_UPDATE,
  SUGGESTED_DEAL_UPDATE,
  UPDATE_SELECTED_SUGGESTED_DEAL,
} from '../constants/suggestedDealConstants';

const updateSuggestedDealFunc = (suggestedDealsList, suggestedDeal, modified = true) => {
  const index = findIndex(suggestedDealsList, e => e.id === suggestedDeal.id);
  const suggestedDealsListNew = suggestedDealsList;
  const suggestedDealNew = suggestedDeal;
  if (index !== -1) {
    if (modified) suggestedDealNew.modified = true;
    else suggestedDealNew.modified = false;
    suggestedDealsListNew[index] = suggestedDealNew;
  }
  return suggestedDealsListNew;
};

const suggestedDealReducer = (
  state = {
    suggestedDeals : [],
    suggestedDeal  : {},
    loaded         : false,
  },
  action
) => {
  switch (action.type) {
    case SUGGESTED_DEAL_LIST_UPDATE:
      return { ...state, ...action.suggestedDeals, loaded: true };
    case SUGGESTED_DEAL_UPDATE:
      return {
        ...state,
        ...updateSuggestedDealFunc(state.suggestedDeals, action.suggestedDeal),
      };
    case UPDATE_SELECTED_SUGGESTED_DEAL:
      return { ...state, selected: action.selectedSuggestedDeal };
    default:
      return state;
  }
};

export default suggestedDealReducer;
