import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { map, get, lowerCase } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import { Mutation } from 'react-apollo';
import { UpdateCurrentInventoryUser } from '../InventoryUsers/InventoryUserQueries';

export default class CalibrationCheck extends PureComponent {
  constructor (props) {
    super(props);

    this.state = { value: Boolean(props.inventoryUser[props.fieldName]) };
  }

  onComponentWillReceiveProps (nextProps) {
    const { fieldName } = this.props;
    const { inventoryUser } = this.props;
    if (inventoryUser[fieldName] === nextProps.inventoryUser[fieldName]) {
      this.setState({ value: Boolean(nextProps.inventoryUser[fieldName]) });
    }
  }

  render () {
    const { inventoryUser, fieldName, refetch, disabled } = this.props;
    const { value } = this.state;

    return (
      <Mutation mutation={UpdateCurrentInventoryUser}>
        {updateInventoryUser => (
          <Checkbox
            disabled={disabled}
            type="checkbox"
            id={`${fieldName}${inventoryUser.id}`}
            checked={Boolean(value)}
            onChange={e => {
              updateInventoryUser({
                variables : { id: inventoryUser.id, [fieldName]: !value },
              }).then(() => {
                this.setState({ value: !value });
                refetch();
              });
            }}
            style={{ fontSize: 14 }}
          />
        )}
      </Mutation>
    );
  }
}

CalibrationCheck.propTypes = {
  disabled      : PropTypes.bool,
  inventoryUser : PropTypes.object.isRequired,
  fieldName     : PropTypes.string.isRequired,
  refetch       : PropTypes.func.isRequired,
};

CalibrationCheck.defaultProps = {
  disabled : false,
};
