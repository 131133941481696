import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { get, isEmpty } from 'lodash';
import Cfa from '../components/calculators/Cfa';
import * as cfaActionCreators from '../actions/cfaActionCreators';
import { updateCompany } from '../actions/companyActionCreators';
import { CircularProgress } from '@material-ui/core';

const GetCashFlow = gql`
	query cashFlow($friendlyName: String) {
	cashFlows{
		id
		name
		numberOfBuyers
		cashFlowLength
		sellerAverageCashFlow
		buyer1AverageCashFlow
		buyer2AverageCashFlow
		createdAt
		friendlyName
	}
	company {
		id
		cash_flow_analysis_word
		cash_flow_analysis_word_fr
		cashflow_payout_word
		show_branch_expenses
	}
		getCurrentUser {
			id
			motivation
		}
    cashFlow(friendlyName: $friendlyName) {
			id
			name
			friendlyName
			createdAt
			cashFlowLength
			cashFlowProduction
			cashFlowProductionShift  # Add This to edit Production Shifts In Year cant be greater than length
			sellerT12Revenue
			sellerFiveYearGrowthRate
			numberOfBuyers
			buyer1T12Revenue
			buyer1FiveYearGrowthRate
			buyer1PercentagePurchasing
			buyer2T12Revenue
			buyer2FiveYearGrowthRate
			buyer2PercentagePurchasing
			buyerHasLumpSumPayment
			buyerLumpSumAmount
			buyerLumpSumYear
			buyersFinancingAmount
			buyerFinancingYears
			buyerHasFixedPayments
			buyerFixedPaymentYears
			buyerFixedPaymentAmount
			buyerFixedPaymentStartYear
			buyerHasContinuingPayments
			buyerContinuingPaymentYears
			buyerContinuingPaymentStartYear
			buyerContinuingPaymentAmount
			buyerContinuingPayments
			sellerAverageCashFlow
			buyer1AverageCashFlow
			buyer2AverageCashFlow
			sellerManualTaxRate
			sellerTaxRate
			buyer1ManualTaxRate
			buyer1TaxRate
			buyer2ManualTaxRate
			buyer2TaxRate
			cashFlowFinancingInterestRate
			buyer1BreakEven
			buyer2BreakEven
			hasCheckSwap
			checkSwapYear
			checkSwapAnnualBonus
			checkSwapLoanEndYear
			checkSwapLoanRepaymentAmount
			buyer1AnnualExpenses
			buyer1ExpenseGrowth
			buyer2AnnualExpenses
			buyer2ExpenseGrowth
			purchasePrice
			lifetimeCapitalGainsException
			cashflowSellerLifetimeCapitalGainsTax
			consultingTotalCompensation
			consultingLength
			sellerNoteAmount
			sellerNoteTerm
			sellerNoteInterestRate
			sellerNotePaymentFrequency
			sellerNoteDefaultAmmortization
			bdRetentionRate
			illustration {
				sellerCashFlow {
					revenueGrowth
					personalProduction
					acquiredProduction
					cashFlowProductionShift
					payoutRate
					payout
					lumpSumPaymentReceived
					fixedPaymentReceived
					contingentPaymentReceived
					bookSalePaymentReceived
					pretaxNetIncome
					taxes
					totalTaxes
					downPayment
					afterTaxNetIncome
					freeCashFlow
					buyerHasFixedPayments
					taxRate
					transitionAssistanceLoanRepayment
					checkSwapPayment
					consultingAgreement
					consultingAgreementTaxes
					sellerNotePayment
					sellerCarriedNoteInterest
					sellerCarriedNotePrincipal
					earnOutTaxes
					downpaymentTaxes
					branchRetentionTaxes
					sellerNoteInterestTaxes
					sellerNotePrincipalTaxes
					transitionAssistanceLoanBonus
					cumulativeProceeds
				}
				buyer1CashFlow {
					revenueGrowth
					personalProduction
					aquiredProduction
					totalProduction
					payoutRate
					grossRevenue
					payout
					bookBuyPaymentTotal
					fixedPayment
					contingentPayment
					pretaxNetIncome
					taxes
					afterTaxNetIncome
					lumpSumPaymentOutOfPocket
					successionLoanRepayment
					debtServiceCoverageRatio
					freeCashFlow
					taxRate
					transitionAssistanceBonus
					branchExpenses
					consultingAgreement
					sellerNoteInterestPayment
					sellerNotePayment
					sellerNoteAmortization
					netIncome
					downpaymentLoanInterestTax
					downpaymentAmortizationTax
					taxableIncome
					downPaymentNote
					attritionRate
				}
				buyer2CashFlow {
					revenueGrowth
					grossRevenue
					personalProduction
					aquiredProduction
					totalProduction
					payoutRate
					payout
					bookBuyPaymentTotal
					fixedPayment
					contingentPayment
					pretaxNetIncome
					taxes
					afterTaxNetIncome
					lumpSumPaymentOutOfPocket
					successionLoanRepayment
					debtServiceCoverageRatio
					freeCashFlow
					taxRate
					transitionAssistanceBonus
					branchExpenses
					consultingAgreement
					sellerNoteInterestPayment
					sellerNotePayment
					sellerNoteAmortization
					netIncome
					downpaymentLoanInterestTax
					downpaymentAmortizationTax
					taxableIncome
					downPaymentNote
					attritionRate
				}
			}
		}
	}
`;

const CfaContainer = props => {
	const { params } = props.match;
	const { id } = params;
	let query = props.query;
	if(isEmpty(query)){
		query = {  ...query, updating: true, };
	}
	if(id) {
		if(query.id != id || isEmpty(query)){
			query = {  ...query, updating: true, };
		}
		query = {  ...query, friendlyName: id, };
	}

	return (<Query  query={GetCashFlow} 
									variables={query}
									onCompleted={data => {
										props.updateCompany({ ...data.company });
										if(!query.updating){
											return;
										}
										props.updateCfaResults(data);
										let { illustration, ...q } = get(data, 'cashFlow', {})

										props.updateCfaQuery({...q, motivation: get(data, 'getCurrentUser.motivation', ''), updating: false});

									}}>{ ({loading, data}) =>	(loading ? <CircularProgress /> : <Cfa motivation={get(data, 'getCurrentUser.motivation', 'succession')} />)
									}
			
		</Query>);
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
		query: {...get(state, 'cfa.query', {})},
  };
};

export default connect(mapStateToProps, { ...cfaActionCreators, updateCompany})(
  CfaContainer
);