import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { get, groupBy, keys, filter, orderBy } from 'lodash';
import moment from 'moment';
import NewPipelineActivity from './NewPipelineActivity';
import PipelineActivityCard from './PipelineActivityCard';
import ActivityInfoBoxMini from '../InventoryUserDrawer/ActivityInfoBoxMini';

const formatDate = date => {
  if (date.fromNow().indexOf('hours') > -1) {
    return 'Today';
  }
  return date.fromNow();
};

export const PipelineActivityTab = ({ pipelineCard, classes, activityTypes, refetch, inventoryUserId }) => {
  const allActivities = get(pipelineCard, 'pipeline_activities', []);
  const activities = allActivities.filter(a => a.activityType.name !== 'Invite');
  const filteredActivityTypes = activityTypes.filter(a => a.name !== 'Invite');
  const acts = orderBy(activities, a => a.activityDate);
  const days = groupBy(acts, a => formatDate(moment(a.activityDate)));
  const orderedDays = keys(days).reverse();
  const activityLength = activities.length;
  return (
    <div>
      <Card>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <ActivityInfoBoxMini title="Interactions">
              {filter(activities || [], 'interaction').length}
            </ActivityInfoBoxMini>
          </Grid>
          <Grid item xs={4}>
            <ActivityInfoBoxMini title="Inactive Days">
              {activityLength ? (
                moment().diff(moment(activities.map(a => a.activityDate).sort((a, b) => b - a)[0]), 'days')
              ) : (
                '-'
              )}
            </ActivityInfoBoxMini>
          </Grid>
          <Grid item xs={4}>
            <ActivityInfoBoxMini title="Days in Stage">
              {moment().diff(pipelineCard.last_moved_date || pipelineCard.created_at, 'days')}
            </ActivityInfoBoxMini>
          </Grid>
        </Grid>
      </Card>
      <br />
      <Divider />
      <NewPipelineActivity pipelineCard={pipelineCard} activityTypes={filteredActivityTypes} refetch={refetch} />
      <br />
      <Divider />
      <br />
      <div>
        {orderedDays.map(day => (
          <div>
            <h5 className={classes.day}>{day}</h5>
            {orderBy(days[day], ac => ac.activityDate)
              .reverse()
              .map(a => (
                <PipelineActivityCard
                  activity={a}
                  pipelineCard={pipelineCard}
                  inventoryUserId={inventoryUserId}
                  refetch={refetch}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  root : {
    margin : '8px 0',
  },
  day  : {
    fontWeight : 'bold',
  },
};

PipelineActivityTab.propTypes = {
  pipelineCard    : PropTypes.object,
  classes         : PropTypes.object.isRequired,
  activityTypes   : PropTypes.array.isRequired,
  refetch         : PropTypes.func.isRequired,
  inventoryUserId : PropTypes.string,
};

PipelineActivityTab.defaultProps = {
  pipelineCard    : {},
  inventoryUserId : null,
};

export default withStyles(styles)(PipelineActivityTab);
