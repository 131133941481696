import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import PlanningInformation from '../SignUpWizard/PlanningInfo/PlanningInformation';

export const UpdateCurrentUser = gql`
  mutation updateCandidate(
    $business_continuity_state_id: ID
    $succession_planning_state_id: ID
    $transition_period_months: Int
    $target_timeframe: Int
    $workweek_trajectory_now: Int
    $workweek_trajectory_year_3: Int
    $workweek_trajectory_year_5: Int
    $workweek_trajectory_year_10: Int
    $workweek_trajectory_year_15: Int
    $workweek_trajectory_year_20: Int
    $workweek_trajectory_year_25: Int
  ) {
    updateCandidate(
      business_continuity_state_id: $business_continuity_state_id
      succession_planning_state_id: $succession_planning_state_id
      transition_period_months: $transition_period_months
      target_timeframe: $target_timeframe
      workweek_trajectory_now: $workweek_trajectory_now
      workweek_trajectory_year_3: $workweek_trajectory_year_3
      workweek_trajectory_year_5: $workweek_trajectory_year_5
      workweek_trajectory_year_10: $workweek_trajectory_year_10
      workweek_trajectory_year_15: $workweek_trajectory_year_15
      workweek_trajectory_year_20: $workweek_trajectory_year_20
      workweek_trajectory_year_25: $workweek_trajectory_year_25
    )
  }
`;

export default graphql(UpdateCurrentUser)(PlanningInformation);
