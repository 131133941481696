import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import LoadingButtonWrapper from '../LoadingButtonWrapper/LoadingButtonWrapper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';

const PoliciesDialog = ({
  classes,
  openAgreementProp,
  updateUser,
  optInScrollPrivacyPolicy,
  privacyPolicy,
  termsOfService,
  handleClose,
  stylesheetName,
  agreedToPp,
  agreedToTos,
  clickAgreePp,
  clickAgreeTos,
  clickDisagreePp,
  clickDisagreeTos,
}) => {
  const [ openPp, setOpenPp ] = useState(false);
  const [ openTos, setOpenTos ] = useState(false);
  const [ disableNext, setDisableNext ] = useState(false);

  const clickComponentDisagreePp = () => {
    clickDisagreePp();
    setOpenPp(false);
  };

  const clickComponentAgreePp = () => {
    clickAgreePp();
    setOpenPp(false);
  };

  const clickComponentDisagreeTos = () => {
    clickDisagreeTos();
    setOpenTos(false);
  };

  const clickComponentAgreeTos = () => {
    clickAgreeTos();
    setOpenTos(false);
  };

  const handleComponentClose = () => {
    handleClose();
    setOpenAgreement(false);
    setOpenPp(false);
    setOpenTos(false);
    setDisableNext(false);
  };

  const setOpenAgreement = () => {};

  const stringToHTML = str => {
    return <div dangerouslySetInnerHTML={{ __html: str }} />;
  };

  const validate = () => {
    if (optInScrollPrivacyPolicy && (!agreedToPp || !agreedToTos)) {
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (disableNext) {
      return;
    }
    setDisableNext(true);
    updateUser();
  };

  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={openAgreementProp}
      onClose={() => handleClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title"><FormattedMessage id="sign_up_pages.privacy_policy_and_terms" /></DialogTitle>
      <DialogContent>
        <Accordion expanded={openPp}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => setOpenPp(!openPp)} />}
            aria-controls="panel1a-content"
            id="panel1a-header">
            <Typography className={classes.heading}>
              <FormattedMessage id="shared.privacy_policy" />{' '}
              {agreedToPp ? (
                <span style={{ color: 'green' }}> <FormattedMessage id="sign_up_pages.agreed" /></span>
              ) : (
                <span style={{ color: 'grey' }}> <FormattedMessage id="sign_up_pages.not_yet_agreed" /></span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0}>
              <Grid xs={12}>{stringToHTML(privacyPolicy)}</Grid>
              <Grid xs={12} style={{ marginTop: 20 }}>
                <Button onClick={() => clickComponentDisagreePp()} color="default">
                  <FormattedMessage id="sign_up_pages.disagree" />
                </Button>
                <Button onClick={() => clickComponentAgreePp()} color="primary">
                  <FormattedMessage id="sign_up_pages.agree" />
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={openTos}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={() => setOpenTos(!openTos)} />}
            aria-controls="panel2a-content"
            id="panel2a-header">
            <Typography className={classes.heading}>
              <FormattedMessage id="shared.terms_of_use" />{' '}
              {agreedToTos ? (
                <span style={{ color: 'green' }}> <FormattedMessage id="sign_up_pages.agreed" /></span>
              ) : (
                <span style={{ color: 'grey' }}> <FormattedMessage id="sign_up_pages.not_yet_agreed" /></span>
              )}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={0}>
              <Grid xs={12}>{stringToHTML(termsOfService)}</Grid>
              <Grid xs={12} style={{ marginTop: 20 }}>
                <Button onClick={() => clickComponentDisagreeTos()} color="default">
                <FormattedMessage id="sign_up_pages.disagree" />
                </Button>
                <Button onClick={() => clickComponentAgreeTos()} color="primary">
                <FormattedMessage id="sign_up_pages.agree" />
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <div className="text-center" style={{ padding: 20 }}>
        <LoadingButtonWrapper loading={disableNext} stateChild="user">
          <Button onClick={() => handleComponentClose()} color="primary">
            <FormattedMessage id="shared.close" />
          </Button>
          {validate() && (
            <button
              disabled={disableNext}
              onClick={() => handleNext()}
              className={stylesheetName === 'rjpe' ? classes[`${stylesheetName}BackgroundColor`] : 'btn btn-default'}
              style={{ padding: '12px 24px', color: '#fff' }}
              variant="contained"
              color="primary">
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          )}
          {!validate() && (
            <button style={{ padding: '12px 24px', color: '#fff' }} variant="contained" color="primary" disabled>
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          )}
        </LoadingButtonWrapper>
      </div>
    </Dialog>
  );
};

const styles = () => ({
  formGroup           : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle          : {
    paddingLeft : '6px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  policyModal         : {
    maxWidth : 900,
  },
  loading             : {
    margin : 'auto',
  },
});

PoliciesDialog.propTypes = {
  classes                  : PropTypes.object.isRequired,
  openAgreementProp        : PropTypes.bool.isRequired,
  agreedToPp               : PropTypes.bool.isRequired,
  agreedToTos              : PropTypes.bool.isRequired,
  optInScrollPrivacyPolicy : PropTypes.bool.isRequired,
  updateUser               : PropTypes.func.isRequired,
  privacyPolicy            : PropTypes.string,
  termsOfService           : PropTypes.string,
  stylesheetName           : PropTypes.string,
  clickAgreePp             : PropTypes.func.isRequired,
  clickAgreeTos            : PropTypes.func.isRequired,
  clickDisagreePp          : PropTypes.func.isRequired,
  clickDisagree            : PropTypes.func.isRequired,
};

PoliciesDialog.defaultProps = {
  privacyPolicy  : '',
  termsOfService : '',
  stylesheetName : '',
};

export default withStyles(styles)(injectIntl(PoliciesDialog));
