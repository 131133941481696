import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';

const SideDiscretionaryInvestmentModels = ({ classes, user, obfuscateView }) => {
  return (
    <div className={classes.status}>
      <strong><FormattedMessage id="profile_page.discretionary_investment_models"/></strong>
      {!obfuscateView && <p>{user.discretionary_investment_models ? <FormattedMessage id="profile_page.yes_discretionary_investment_models"/> : <FormattedMessage id="profile_page.no_discretionary_investment_models"/>}</p>}
      {obfuscateView && <p className="blurry-text">???</p>}
    </div>
  );
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(SideDiscretionaryInvestmentModels);
