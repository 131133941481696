import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis } from 'victory';
import currencyFormatter from 'currency-formatter';
import Grid from '@material-ui/core/Grid';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { capturePdf } from '../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  report    : {
    margin : '25px',
  },
  title     : {
    fontSize : '24px',
    color    : '#616161',
  },
  list      : {
    listStyleType : 'none',
    fontSize      : '15px',
  },
  tableData : {
    paddingLeft : '10px',
    textAlign   : 'right',
  },
  tableOne  : {
    display     : 'inline-block',
    paddingLeft : '5px',
    textAlign   : 'right',
  },
  tableTwo  : {
    display     : 'inline-block',
    marginLeft  : '5px',
    paddingLeft : '5px',
    textAlign   : 'right',
  },
  progress  : {
    margin : 'auto',
  },
  card      : {
    margin   : 'auto',
    maxWidth : '600px',
  },
  media     : {
    height     : 0,
    paddingTop : '56.25%', // 16:9
  },
  table     : {
    width    : '50%',
    fontSize : '12px',
  },
});

const GmvMomPage = ({ classes, data, intl }) => {
  if (data.loading) return <CircularProgress size={68} className={classes.progress} />;

  const month = [
    '',
    intl.formatMessage({ id: 'admin_company_reports.jan' }),
    intl.formatMessage({ id: 'admin_company_reports.feb' }),
    intl.formatMessage({ id: 'admin_company_reports.mar' }),
    intl.formatMessage({ id: 'admin_company_reports.apr' }),
    intl.formatMessage({ id: 'admin_company_reports.may' }),
    intl.formatMessage({ id: 'admin_company_reports.jun' }),
    intl.formatMessage({ id: 'admin_company_reports.jul' }),
    intl.formatMessage({ id: 'admin_company_reports.aug' }),
    intl.formatMessage({ id: 'admin_company_reports.sep' }),
    intl.formatMessage({ id: 'admin_company_reports.oct' }),
    intl.formatMessage({ id: 'admin_company_reports.nov' }),
    intl.formatMessage({ id: 'admin_company_reports.dec' }),
  ];
  const graphData = [];
  const momPercentage = {};
  const momAmount = {};

  // Sets up data for line graph as well as data for table
  for (let i = 1; i < data.currentYearMv.length; i += 1) {
    const currentMonthName = month[parseInt(data.currentYearMv[i].month, 10)];
    const currentMonthGmv = data.currentYearMv[i].value;
    const lastMonthGmv = data.currentYearMv[i - 1].value;
    momPercentage[currentMonthName] = `${parseFloat((currentMonthGmv - lastMonthGmv) / lastMonthGmv * 100).toFixed(
      2
    )}%`;
    momAmount[currentMonthName] = currencyFormatter.format(currentMonthGmv - lastMonthGmv, {
      code : 'USD',
    });
    graphData.push({
      x     : currentMonthName,
      y     : currentMonthGmv / 1000000,
      label : momPercentage[currentMonthName],
    });
  }

  // Dynamically sets lowest and highest points of axis for better graph view
  const graphRangeLow = Math.min(...graphData.map(o => o.y)) * 0.99;
  const graphRangeHigh = Math.max(...graphData.map(o => o.y)) * 1.01;

  // Slices display into two separate arrays for better viewing when more than 6 entries
  const months = Object.keys(momPercentage);
  const julToDec = months.slice(0, 6);
  const janToJun = months.slice(6);
  const clickedCapture = () => {
    capturePdf('l', 0, 0, '', '', 0.7);
  };

  return (
    <div id="capture">
      <section className={classes.report}>
        <Card className={classes.card}>
          <Tooltip title="Download PDF" style={{ float: 'right' }}>
            <IconButton onClick={() => clickedCapture()}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <CardHeader
            classes={{
              title : classes.title,
            }}
            title={intl.formatMessage({ id: 'admin_company_reports.gmv_mom' })}
          />
          <VictoryChart
            className={classes.chart}
            theme={VictoryTheme.material}
            width={600}
            height={600}
            domain={{ x: [ 1, data.currentYearMv.length + 1 ], y: [ graphRangeLow, graphRangeHigh ] }}
            padding={{
              top    : 40,
              bottom : 80,
              left   : 80,
              right  : 40,
            }}>
            <VictoryLine
              style={{
                data   : { stroke: '#c43a31', strokeWidth: 5 },
                parent : { border: '1px solid #ccc' },
              }}
              data={graphData}
            />
            <VictoryAxis
              label={intl.formatMessage({ id: 'admin_company_reports.month' })}
              style={{
                axisLabel : { padding: 60 },
              }}
            />
            <VictoryAxis
              dependentAxis
              label={intl.formatMessage({ id: 'admin_company_reports.gmv_x' })}
              style={{
                axisLabel : { padding: 60 },
              }}
            />
          </VictoryChart>
          <CardContent>
            <Typography gutterBottom variant="headline" component="h2">
              <FormattedMessage id="admin_company_reports.gmv_month_over_month" /> {' '}
              {data.currentYearMv[data.currentYearMv.length - 1].year}
            </Typography>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Table className={classes.table}>
                  <TableBody>
                    {janToJun.map(k => (
                      <TableRow key={k}>
                        <TableCell style={{ padding: 0 }}>{`${k}:  ${momAmount[k]}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={6}>
                <Table className={classes.table}>
                  <TableBody>
                    {julToDec.map(j => (
                      <TableRow key={j}>
                        <TableCell style={{ padding: 0 }}>{`${j}:  ${momAmount[j]}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </section>
    </div>
  );
};

GmvMomPage.propTypes = {};

export default injectIntl(withStyles(styles)(GmvMomPage));
