import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import DealWizard from '../components/DealWizard/DealWizard';
import * as actions from '../actions/dealActionCreators';

export const DealWizardComponent = props => (
  <Fragment>
    <DealWizard {...props} />
  </Fragment>
);

const mapStateToProps = state => ({
  deal  : state.deal,
  user  : state.user,
  state,
});

export default connect(mapStateToProps, actions)(DealWizardComponent);
