import React, { Component } from "react";
import { FormattedMessage } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { IntlProvider, createIntlCache} from 'react-intl';
import translations from '../../lib/i18n/translations.json';
import defaultLocale from '../../lib/i18n/default.json';
import { Button, Modal, Box, Typography, Radio, RadioGroup, FormControlLabel, TextField } from "@material-ui/core";

const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

class CloseDealButton extends Component {
    state = { open: false, reason: "", otherReason: "" }
    handleOpen = () => this.setState({ open: true });
    handleClose = () => this.setState({ open: false });
    handleReasonChange = e => {
        const value = e.target.value;
        this.setState({ reason: value, otherReason: "" });
    }
    handleOtherReasonChange = e => this.setState({ otherReason: e.target.value });

    
    render() {
        const { dealId, dealRoomBrandedName } = this.props;
        const { open, reason, otherReason } = this.state;
        setIntl();
        const isRadioGroupValid = !!reason;
        const isTextFieldValid = (reason === "0" || reason === "1" || reason === "4") ? !!otherReason : true;
        const isFormValid = isRadioGroupValid && isTextFieldValid;
        
        return (
            <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
                <span><Button onClick={this.handleOpen}><FormattedMessage id="deal_page.close" values={{dealRoomBrandedName: dealRoomBrandedName}}/></Button>
                    <Modal
                        open={open}
                        onClose={this.handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <FormattedMessage id="deal_page.please_provide_a_reason" values={{dealRoomBrandedName: dealRoomBrandedName}}/>
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <RadioGroup
                                    aria-labelledby="demo-error-radios"
                                    name="reasoon_for_closing"
                                    value={reason}
                                    onChange={this.handleReasonChange}
                                >
                                    <FormControlLabel value="0" control={<Radio />} label={<FormattedMessage id="deal_page.found_a_buyer" values={{dealRoomBrandedName: dealRoomBrandedName}}/>} />
                                    <FormControlLabel value="1" control={<Radio />} label={<FormattedMessage id="deal_page.found_a_buyer_outside" values={{dealRoomBrandedName: dealRoomBrandedName}}/>} />
                                    <FormControlLabel value="2" control={<Radio />} label={<FormattedMessage id="deal_page.no_longer_interested"/>} />
                                    <FormControlLabel value="3" control={<Radio />} label={<FormattedMessage id="deal_page.pausing_process"/>} />
                                    <FormControlLabel value="4" control={<Radio />} label={<FormattedMessage id="deal_page.other"/>} />
                                </RadioGroup>
                                {reason === "0" || reason === "1" ? (
                                    <TextField
                                        id="outlined-multiline-static"
                                        label={<FormattedMessage id="deal_page.name_of_the_other_party" />}                                  
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth
                                        value={otherReason}
                                        onChange={this.handleOtherReasonChange}
                                    />
                                ) : null}
                                {reason === "4" ? (
                                    <TextField
                                        id="outlined-multiline-static"
                                        label={<FormattedMessage id="deal_page.other_reason" />}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        fullWidth
                                        value={otherReason}
                                        onChange={this.handleOtherReasonChange}
                                    />
                                ) : null}
                            </Typography>
                            <br />
                            <Button variant="contained" onClick={this.handleClose}><FormattedMessage id="deal_page.cancel"/></Button>
                            &nbsp;
                            <a href={`/deal/${dealId}/close?reason_for_closing=${reason}&other_reason=${otherReason}`}>
                                <Button variant="contained" color="primary" disabled={!isFormValid}><FormattedMessage id="deal_page.close_deal"/></Button>
                            </a>
                        </Box>
                    </Modal>
                </span>
            </IntlProvider>
        );
    }
}

export default CloseDealButton;