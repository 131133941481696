import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import RevenueInformationContainer from '../../containers/RevenueInformationContainer';
import {FormattedMessage, injectIntl} from "react-intl";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get, isEmpty } from 'lodash';

class TipViewFirstValuation extends React.Component {
  hanldeFollowAll = threeUsers => {
    for (let i = 0; i < threeUsers.length; i += 1) {
      if (threeUsers[i].note_count < 1) {
        this.hanldeFollow(threeUsers[i].user_name);
      }
    }
    window.open(`/dashboard/managed`, '_self');
  };

  isAdmin = () => {
    const { data } = this.props;
    const { getCurrentUser } = data;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles.includes('admin')) {
      return true;
    }
    return false;
  };

  render () {
    const { data } = this.props;
    const { getCurrentUser, user } = data;
    const {
      classes,
    } = this.props;
    const defaultValuationOtpDcf = get(getCurrentUser.company, 'default_valuation_otp_dcf');
    const defaultValuationDcf = get(getCurrentUser.company, 'default_valuation_dcf');
    const threeUsers = get(getCurrentUser, 'suggested_users', []).slice(0, 3);
    return (
        <div>
          <header className="center">
            <div className={classes.title}><FormattedMessage id="motivation_onboarding.pages.first_valuation_text.title"/></div>
                <div
                className="cite no-bottom-margin center"
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <FormattedMessage id="motivation_onboarding.pages.first_valuation_text.text"/>
                </div>
                <div
                className="cite no-bottom-margin center"
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '60px'}}>
                    <FormattedMessage id="motivation_onboarding.pages.first_valuation_text.text_part_2"/>
                <br/>
                </div>
            </header>
            <div>
            <div className={classes.sliderContent}>
                <Grid container spacing={1} style={{ width: '80%', 
                margin: '0 auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',}}>
                <div className={classes.title}><FormattedMessage id="valuation_drawer.valuation_inputs" /></div>
                <br/>
                <br/>
                <RevenueInformationContainer saveToUser/>
                </Grid>
            </div>
          </div>
        <div className={classes.buttonSection}>
        <div className={classes.buttonDiv}>
          <Button variant="contained" onClick={() => this.hanldeFollowAll(threeUsers)} className={classes.button}>
            <FormattedMessage id="motivation_onboarding.pages.back_to_dashboard_button"/>
          </Button>
          { !defaultValuationOtpDcf && !defaultValuationDcf ? (
            <Button variant="contained" onClick={() => {window.location.href = `/valuations/dcf`}} className={classes.button} style={{ marginLeft: '10px' }}>
                <FormattedMessage id="motivation_onboarding.pages.first_valuation_text.review_valuation"/>
            </Button>
            ) : (
            <Button variant="contained" onClick={() => {window.location.href = `/valuations/pe_valuation`}} className={classes.button} style={{ marginLeft: '10px' }}>
                <FormattedMessage id="motivation_onboarding.pages.first_valuation_text.review_valuation"/>
            </Button>
            )}
          <Button variant="contained" onClick={() => {window.location.href = `/profile/compensation`}} className={classes.button} style={{ marginLeft: '10px' }}>
            <FormattedMessage id="motivation_onboarding.pages.first_valuation_text.save_and_continue_editing_profile"/>
          </Button>
        </div>
      </div>
      <br/>
      <br/>
     </div>
    );
  }
}

TipViewFirstValuation.propTypes = {
  data                        : PropTypes.object,
  mutate                      : PropTypes.func.isRequired,
  classes                     : PropTypes.object.isRequired,
  refetch                     : PropTypes.func.isRequired,
  companyBusinessType         : PropTypes.string,
};

TipViewFirstValuation.defaultProps = {
  currentUser         : {},
  data                : {},
  marketplaceOnly     : true,
  companyBusinessType : '',
};

const styles = () => ({
  title         : {
    fontSize   : '24px',
    fontWeight : 100,
  },
  subTitle      : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  buttonSection : {
    width : '100%',
  },
  buttonDiv     : {
    marginLeft  : 'auto',
    marginRight : 'auto',
    width       : 'fit-content',
  },
  button        : {
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 25,
  },
});

export default withStyles(styles)(TipViewFirstValuation);
