import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { get, capitalize } from 'lodash';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

class SuggestedUserCard extends React.Component {
  componentWillReceiveProps (nextProps) {
    const { user, updateSuggestedUser } = this.props;
    if (user.id !== nextProps.user.id) {
      updateSuggestedUser(user);
    }
  }

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).format('MMM D')} at ${moment(date).format('LT')}`;
  };

  selectPipeline = pipeline_id => {
    const { user, updateSuggestedUser } = this.props;
    updateSuggestedUser({ ...user, selected_pipeline: pipeline_id });
  };

  addUser = () => {
    const { user, updateSelectedSuggestedUser } = this.props;
    updateSelectedSuggestedUser({ ...user });
  };

  render () {
    const { classes, user, deleteSuggested, pipelineList } = this.props;
    return (
      <Card style={{ height: '100%' }}>
        <div className={classes.row}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Avatar className={classes.avatar}>
                <i className="material-icons">attach_money</i>
              </Avatar>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.text}>
                <span className={classes.nameField}>{get(user, 'inventory_user.name')}</span>
                <br />
                <span className={classes.caseField}>{capitalize(get(user, 'case_type')).replace(/_/g, ' ')}</span>
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={1}>
              <IconButton color="primary">
                <CloseIcon
                  onClick={() => {
                    deleteSuggested({ variables: { id: user.id } });
                    window.location.reload();
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={10}>
              <div style={{ marginTop: 15, marginLeft: 12 }}>{get(user, 'name', '')}</div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={10}>
              <div style={{ marginTop: 10, marginLeft: 12 }}>{get(user, 'description', '')}</div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} style={{ height: 25 }} />
            <Grid item xs={10}>
              <span className={classes.timeField}>
                {this.formatDate(get(user, 'updated_at'))} {user.manager && <span>| {user.manager}</span>}
              </span>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} style={{ height: 30 }} />
            <Grid item xs={10}>
              <Select
                style={{ minWidth: 150 }}
                value={get(user, 'selected_pipeline')}
                className={classes.inputField}
                onChange={e => {
                  this.selectPipeline(e.target.value);
                }}>
                {(pipelineList || []).map(p => (
                  <MenuItem key={`pl_${p.id}`} value={p.id}>
                    {decodeURI(p.name)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Fab variant="extended" size="medium" color="primary" aria-label="Add" onClick={() => this.addUser()}>
                Add
              </Fab>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </div>
      </Card>
    );
  }
}

const styles = {
  avatar    : {
    margin : '0px 10px 0px 10px',
  },
  row       : {
    margin : '30px 10px 5px 30px',
  },
  caseField : {
    color    : '#797D7F',
    fontSize : 14,
  },
  timeField : {
    color      : '#797D7F',
    marginLeft : 12,
  },
  nameField : {
    fontWeight : 500,
    fontSize   : 20,
  },
};

SuggestedUserCard.propTypes = {
  classes                     : PropTypes.object.isRequired,
  user                        : PropTypes.object.isRequired,
  updateSuggestedUser         : PropTypes.func.isRequired,
  deleteSuggested             : PropTypes.func.isRequired,
  updateSelectedSuggestedUser : PropTypes.func.isRequired,
  pipelineList                : PropTypes.array.isRequired,
};

export default withStyles(styles)(SuggestedUserCard);
