import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoBox from './InfoBox';
import { capitalize, replace, get, toUpper } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import InviteButton from '../InviteButton/InviteButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';

export const interestsChip = (interest, classes) => {
  switch (interest) {
    case 'Buying':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#0097a7' }} />;
    case 'Merging':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#4caf50' }} />;
    case 'Partnering':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#ff5722' }} />;
    case 'Business Continuity':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#2196f3' }} />;
    case 'Find a Successor':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#9e9e9e' }} />;
    case 'Become a Successor':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#e91e63' }} />;
    case 'Becoming a Junior Partner':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#673ab7' }} />;
    case 'Selling my practice':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#f9a825' }} />;
    case 'Selling part of my practice':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#9c27b0' }} />;
    case 'Mentoring':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#FFC107' }} />;
    case 'Being Mentored':
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#00BFA5' }} />;
    default:
      return <Chip label={interest} className={classes.chip} style={{ color: 'white', background: '#00BFA5' }} />;
  }
};

const interestsChips = (interest, classes) => interestsChip(interest, classes);

class OverviewUserData extends React.Component {
  constructor (props) {
    super(props);
    const { inventoryUser } = this.props;
    const user = get(inventoryUser, 'user', []);
    this.state = {
      manager_email : inventoryUser.manager_email,
    };
  }

  render () {
    const {
      inventoryUser,
      managers,
      classes,
      user,
      assignManager,
      openSnack,
      updateInventoryUser,
      updateAction,
      company,
    } = this.props;
    const { manager_email } = this.state;
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Button
              style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
              size="small"
              classes={{
                root : classes.root,
              }}
              onClick={() => {
                window.open(`/users/${user.fb_user_name}`, '_blank');
              }}>
              View Marketplace Profile
            </Button>
          </Grid>
          {get(company, 'business_type', '') === 'wealth_rj' && (
            <div>
              <Grid item xs={12}>
                <Button
                  style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                  size="small"
                  classes={{
                    root : classes.root,
                  }}
                  onClick={() => {
                    window.open(`/valuations/dcf?user_name=${user.fb_user_name}`, '_blank');
                  }}>
                  View DCF Valuation
                </Button>
              </Grid>
              {toUpper(get(company, 'affiliate_name', '')) === 'ICD' && (
                <Grid item xs={12}>
                  <Button
                    style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                    size="small"
                    classes={{
                      root : classes.root,
                    }}
                    onClick={() => {
                      window.open(`/valuations/pevaluation?user_name=${user.fb_user_name}`, '_blank');
                    }}>
                    View Multiple of Revenue Valuation
                  </Button>
                </Grid>
              )}
              {toUpper(get(company, 'affiliate_name', '')) === 'RJA' && (
                <Grid item xs={12}>
                  <Button
                    style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                    size="small"
                    classes={{
                      root : classes.root,
                    }}
                    onClick={() => {
                      window.open(`/loans/otp?user_name=${user.fb_user_name}`, '_blank');
                    }}>
                    View Retirement Choice Calculator
                  </Button>
                </Grid>
              )}
            </div>
          )}
          {get(company, 'business_type', '') !== 'wealth_rj' && (
            <Grid item xs={12}>
              <TextField
                id="date-of-birth"
                InputLabel={{ disabled: true }}
                label="Date of Birth:"
                value={user.birth_date}
                InputProps={{
                  classes  : { root: classes.root, underline: classes.underline },
                  readOnly : true,
                }}
                fullWidth
                margin="normal"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="years_practicing"
              label="Years Practicing:"
              value={user.years_practicing ? user.years_practicing + ' years' : '-'}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="agency_name"
              label="Agency Name:"
              value={user.agency_name}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email:"
              value={user.email}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              label="Phone:"
              value={user.phone ? user.phone : '-'}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="city"
              label="City:"
              value={user.city ? user.city : '-'}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="province"
              label="Province:"
              value={user.province ? user.province : '-'}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.titles}>
              <b>Interests: </b>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.titles}>
              {user.transition_goals ? user.transition_goals.map(t => interestsChips(t, classes)) : ''}
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <TextField
              id="headline"
              label="Headline:"
              value={user.headline ? user.headline : '-'}
              InputProps={{
                classes  : { root: classes.root, underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              multiline
              margin="normal"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="summary_bio"
              label="Summary / Bio:"
              value={user.bio ? user.bio : '-'}
              InputProps={{
                classes  : { underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              multiline
              margin="normal"
            />
          </Grid>
          <Grid item xs={4}>
            <div className={classes.titles}>
              <b>Languages: </b>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className={classes.titles}>
              {user.languages ? user.languages.map(l => capitalize(l)).join(', ') : '-'}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.titles}>
              <b>Professional Strengths: </b>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div>{user.tag_list ? user.tag_list.map(t => replace(capitalize(t), '_', ' ')).join(', ') : '-'}</div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

InfoBox.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  root              : {
    padding : 0,
  },
  marketplacebutton : {
    textAlign : 'center',
  },
  chip              : {
    margin : 2,
    height : 23,
  },
  underline         : {
    '&:after' : {
      borderBottom  : `2px solid #F7F7F7`,
      left          : 0,
      bottom        : 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content       : '""',
      position      : 'absolute',
      right         : 0,
      transform     : 'scaleX(1)',
      pointerEvents : 'none', // Transparent to the hover style.
    },
  },
  titles            : {
    fontSize : 10,
    color    : 'rgba(0, 0, 0, 0.54)',
  },
};

export default withStyles(styles)(OverviewUserData);
