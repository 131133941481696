import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import classNames from 'classnames';
import InventoryUserAvatar from '../InventoryUserAvatar/InventoryUserAvatar';
import CancelIcon from '@material-ui/icons/Cancel';

const MultiValue = ({ children, selectProps, isFocused, removeProps, data }) => (
  <Chip
    avatar={<InventoryUserAvatar inventoryUser={data} />}
    tabIndex={-1}
    label={children}
    className={classNames(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused,
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<CancelIcon {...removeProps} />}
  />
);

MultiValue.propTypes = {
  children    : PropTypes.object,
  selectProps : PropTypes.object,
  isFocused   : PropTypes.bool,
  removeProps : PropTypes.bool,
  data        : PropTypes.object,
};

MultiValue.defaultProps = {
  children    : {},
  selectProps : {},
  isFocused   : false,
  removeProps : false,
};

export default MultiValue;
