import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import StrengthsAndAvatar from '../SignUpWizard/StrengthsAndAvatar/StrengthsAndAvatar';

const UpdateCandidate = gql`
  mutation updateCandidate($designations: [String!], $primary_markets: [String!], $tag_list: [String!], $profile_picture: String) {
    updateCandidate(designations: $designations, primary_markets: $primary_markets, tag_list: $tag_list, profile_picture: $profile_picture)
  }
`;

export default graphql(UpdateCandidate)(StrengthsAndAvatar);
