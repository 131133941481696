import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import ProductMix from '../SignUpWizard/ProductMix/ProductMix';

const UpdateCurrentUser = gql`
mutation updateCandidate($product_mix: ProductMixInput, $advisor_licenses: [String!]) {
    updateCandidate(product_mix: $product_mix, advisor_licenses: $advisor_licenses)
  }
`;


export default graphql(UpdateCurrentUser)(ProductMix);
