import React from 'react';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';
import { withStyles } from '@material-ui/core/styles';
import { spotlightElement } from '../../lib/findBobUtils';
import classNames from 'classnames';

const InputSlider = ({ title, selector, value, onChange, onMouseOut, minValue, maxValue, step, formatLabel, name, classes }) => (
  <div onMouseOver={e => spotlightElement(selector)}>
    <b>{title}</b>
    <InputRange
      className={classes.InputRange}
      maxValue={maxValue}
      minValue={minValue}
      step={step}
      value={value}
      formatLabel={formatLabel}
      onChange={onChange}
      name={name || selector}
    />
  </div>
);

InputSlider.propTypes = {
  title       : PropTypes.string,
  selector    : PropTypes.string,
  value       : PropTypes.string,
  onChange    : PropTypes.func,
  onMouseOut  : PropTypes.func,
  formatLabel : PropTypes.func,
  minValue    : PropTypes.number,
  maxValue    : PropTypes.number,
  step        : PropTypes.number,
};

InputSlider.defaultProps = {
  title    : '',
  selector : '.no_class',
  minValue : 1,
  maxValue : 100,
  step     : 1,
};

const styles = theme => ({
  InputRange: {
    background: theme.palette.primary.main,
  }
})

export default withStyles(styles)(InputSlider);
