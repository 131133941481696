import { combineReducers } from 'redux';
import { COMPANY_UPDATE, LOAD_COMPANY, SUBMIT_COMPANY } from '../constants/companyConstants';

const companyReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_UPDATE:
      return { ...state, company: { data: action.company } };
    case LOAD_COMPANY:
      return { data: action.company, loading: action.loading };
    case SUBMIT_COMPANY:
      return { ...state, loading: action.loading, success: action.success, error: action.error };
    default:
      return state;
  }
};

export default companyReducer;
