import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Chip } from '@material-ui/core';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import SupportRequestInputForm from './SupportRequestInputForm';
import moment from 'moment';
import { ChevronRight } from '@material-ui/icons';
import { injectIntl } from "react-intl";

const CREATE_SUPPORT_REQUEST = gql`
  mutation createSupportRequestLead($message: String!, $email: String!, $name: String!) {
    createSupportRequestLead(message: $message, name: $name, email: $email) {
      id
      conversation {
        id
      }
    }
  }
`;

const colorsForStatus = {
  open    : 'primary',
  pending : 'secondary',
  closed  : 'default',
};

const HelpSection = ({ company, supportRequests, client, classes, changeConversation, refetch, intl }) => {
  const [createSupportRequest] = useMutation(CREATE_SUPPORT_REQUEST, { client });
  const [newSupportRequest, setNewSupportRequest] = useState(false);
  const helpUrl = get(company, 'help_url', '');
  if (newSupportRequest) {
    return <SupportRequestInputForm createSupportRequest={createSupportRequest} setNewSupportRequest={setNewSupportRequest} changeConversation={changeConversation} refetch={refetch} />;
  }
  return (
    <div>
      <h2>{intl.formatMessage({id: 'support_request.need_help'})}</h2>
      <p>
        {intl.formatMessage({id: 'support_request.welcome'})}

      </p>
      <p>
        {intl.formatMessage({id: 'support_request.if_you_need_help'})}

      </p>
      <br />
      <Button variant="contained" color="primary" href={helpUrl} target="_blank">
        {intl.formatMessage({id: 'support_request.help_center'})}
      </Button>
      <hr />
      <p>{intl.formatMessage({id: 'support_request.still_need_help'})}</p>
      <Button variant="contained" color="primary" onClick={() => {
        setNewSupportRequest(true);
      }
      }>{intl.formatMessage({id: 'support_request.support_conversation'})}</Button>
      <hr />
    </div>
  );
}

HelpSection.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
}

const styles = theme => ({
  button: {
    margin: '10px',
  },
  container: {
    padding: '10px',
  },
  request: {
    padding: '10px',
    borderBottom: '1px solid #dadada',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  requestContent: {
    flexGrow: 1,
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1), // Adjust spacing as needed
  },
  timeAndStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end', // Aligns the chip to the right
    top: 20,
    position: 'relative',
  },
  lastMessage: {
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  chevronIcon: {
    marginLeft: '10px',
  },
  chippy: {
    marginTop: theme.spacing(1), // Adds spacing between the date and the chip
  },
  requests: {
    overflow: 'scroll',
    maxHeight: '300px',

  },
});

export default withStyles(styles)(injectIntl(HelpSection));