import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withSnackbar } from 'notistack';

const ToggleInventoryUserFeature = gql`
  mutation toggleInventoryUserFeature($id: ID!, $feature_name: String!, $value: Boolean!) {
    toggleInventoryUserFeature(id: $id, feature_name: $feature_name, value: $value)
  }
`;

export const FeatureTab = ({
  user,
  allFeatures,
  featureTypes,
  enqueueSnackbar,
  inventoryUser,
  updateInventoryUser,
  currentUserIsAdmin,
}) => {
  const [ count, setCount ] = useState(0);
  return (
    <FormGroup>
      <Mutation mutation={ToggleInventoryUserFeature}>
        {toggleUserFeatureMutation => {
          return allFeatures.map(feature => {
            const toggled = featureTypes.includes(feature);
            return (
              <FormControlLabel
                control={
                  <Switch
                    checked={toggled}
                    disabled={!currentUserIsAdmin}
                    onChange={() => {
                      toggleUserFeatureMutation({
                        variables : {
                          id           : inventoryUser.id,
                          feature_name : feature,
                          value        : !toggled,
                        },
                      }).then(response => {
                        if (!toggled === true) {
                          inventoryUser.feature_types.push(feature);
                        } else {
                          var index = inventoryUser.feature_types.indexOf(feature);
                          if (index > -1) {
                            inventoryUser.feature_types.splice(index, 1);
                          }
                        }
                        updateInventoryUser({ inventoryUser: { ...inventoryUser, user } });
                        enqueueSnackbar('Feature Toggled', {
                          variant      : 'success',
                          anchorOrigin : {
                            vertical   : 'top',
                            horizontal : 'center',
                          },
                        });
                      });
                    }}
                    value={toggled}
                  />
                }
                label={feature}
              />
            );
          });
        }}
      </Mutation>
    </FormGroup>
  );
};

FeatureTab.propTypes = {
  allFeatures        : PropTypes.array,
  featureTypes       : PropTypes.array,
  currentUserIsAdmin : PropTypes.bool,
};

FeatureTab.defaultProps = {
  allFeatures        : [],
  featureTypes       : [],
  currentUserIsAdmin : false,
};

export default withSnackbar(FeatureTab);
