import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import InventoryUserAvatar from '../InventoryUserAvatar/InventoryUserAvatar';

const Option = ({ innerRef, isFocused, isSelected, innerProps, data }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}>
    <InventoryUserAvatar inventoryUser={data} />
    {data.label}
  </MenuItem>
);

Option.propTypes = {
  innerRef: PropTypes.string,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.object,
  data: PropTypes.object,
};

Option.defaultProps = {
  innerRef: '',
  isFocused: false,
  isSelected: false,
  innerProps: {},
  data: {},
};

export default Option;
