import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const MessageButton = ({ userId, userName, noteCount = 1 }) => (
  <button
    id="messageButton"
    className="btn btn-success"
    data-id={userId}
    data-target="#messageModal"
    data-title={userName}
    data-notecount={noteCount}
    data-toggle="modal"
    type="button">
    <i className="fa fa-envelope" />{' '}
    <FormattedMessage id="bobcard.card_buttons.message" defaultMessage="Message" />
  </button>
);

MessageButton.propTypes = {
  userId    : PropTypes.string,
  userName  : PropTypes.string,
  noteCount : PropTypes.number,
};

export default MessageButton;
