import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from '../../lib/s3';
import initials from 'initials';
import { bgColorForName } from '../../lib/findBobUtils';
import moment from 'moment';
import {injectIntl} from 'react-intl'
import {get} from 'lodash';
import Button from '@material-ui/core/Button';
import { getProfileImage } from './Message';

export const MessageMultipleChoice = ({ message, classes, currentUser, dealInterestStage, canViewRealName, intl, cable, roomId, lastMessage }) => {
  const handleSubmit = (answer) => {
    cable.subscriptions.subscriptions[0].speak({
      message : answer,
      room_id : roomId,
    });
  }

  const showRealName = canViewRealName || dealInterestStage === 'negotiating';
  let name = showRealName ? get(message, 'sender.name', '') : get(message, 'sender.user_name', '');
  if (parseInt(currentUser.id, 10) === get(message, 'sender.id', -1)) {
    name = intl.formatMessage({id: 'messages_section.me_word'});
  }
  const profile_picture = getProfileImage(message);
  const sent = message.sent;

  return (
    <div className={classes.messageContainer}>
      <Avatar
        size="18"
        name={name}
        src={profile_picture ? S3Image(profile_picture) : null}
        style={{ backgroundColor: bgColorForName(name) }}>
        <span>{initials(name)}</span>
      </Avatar>
      <div className={classes.message}>
        <span className={classes.name}>{name}</span> &middot;{' '}
        <span className="#dadada">{intl.locale == 'fr' ? moment(sent).format('Do MMM YYYY, H:mm') : moment(sent).format('MMM Do YYYY, h:mm a')}</span>
        {Boolean(message.read) && <span className={classes.check}>&nbsp;&#10004;</span>}
        <br />
        <span className={classes.messageFormat} dangerouslySetInnerHTML={{__html: message.content}}></span>
        <div>
          {lastMessage && message.answer_options && message.answer_options.map((option, index) => {
            return (
              <Button
                className={classes.button}
                color='primary'
                variant="contained"
                key={index}
                onClick={() => handleSubmit(option)}>{option}</Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

MessageMultipleChoice.propTypes = {
  dealInterestStage : PropTypes.string,
  messages: PropTypes.array,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  cable: PropTypes.object.isRequired,
  roomId: PropTypes.number.isRequired,
  lastMessage: PropTypes.bool.isRequired,
};

MessageMultipleChoice.defaultProps = {
  dealInterestStage : '',
};

const styles = {
  messageContainer : {
    display       : 'flex',
    flexDirection : 'row',
  },
  header           : {
    display : 'flex',
  },
  name             : {
    fontWeight : 'bold',
  },
  date             : {
    color : '#dadada',
  },
  message          : {
    padding    : '0 8px',
    whiteSpace : 'pre-wrap',
  },
  check            : {
    color : '#1396e2',
  },
  messageFormat    : {
    wordWrap : 'anywhere',
  },
  button: {
    margin: '5px',
  },
};

export default injectIntl(withStyles(styles)(MessageMultipleChoice));
