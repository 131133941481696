/* eslint-disable import/prefer-default-export */

export const INVENTORY_USERS = 'INVENTORY_USERS';
export const INVENTORY_USERS_UPDATE = 'INVENTORY_USERS_UPDATE';
export const INVENTORY_USERS_ADD = 'INVENTORY_USERS_ADD';
export const INVENTORY_USERS_DELETE = 'INVENTORY_USERS_DELETE';
export const INVENTORY_USERS_PUT = 'INVENTORY_USERS_PUT';
export const LOAD_INVENTORY_USERS = 'LOAD_INVENTORY_USERS';
export const SELECT_INVENTORY_USER_UPDATE = 'SELECT_INVENTORY_USER_UPDATE';
export const LOAD_BENCH_USERS = 'LOAD_BENCH_USERS';
