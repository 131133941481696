import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { get, debounce } from 'lodash';
import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { createPipelineActivityMutation } from './PipelineQueries';

const times = Array.from(
  {
    length : 48,
  },
  (_, hour) =>
    moment({
      hour    : Math.floor(hour / 2),
      minutes : hour % 2 === 0 ? 0 : 30,
    }).format('HH:mm')
);
class NewPipelineActivity extends React.Component {
  clickSave = debounce(() => {
    const { pipelineCard, mutate, refetch } = this.props;
    const { note, activityType, activityDate } = this.state;
    mutate({
      variables : {
        note             : encodeURI(note),
        activity_type_id : activityType,
        pipeline_card_id : pipelineCard.id,
        activity_date    : activityDate,
      },
    }).then(() => {
      refetch();
      this.setState({ ...this.defaultState });
    });
  }, 500);

  constructor (props) {
    super(props);
    this.defaultState = {
      activityType : get(props, 'activityTypes[0].id'),
      note         : '',
      activityDate : new Date(),
    };
    this.state = { ...this.defaultState };
  }

  onChange = e => {
    const { onChange } = this.props;
    this.setState({ note: e.target.value });
    if (onChange) {
      onChange(e.target.value);
    }
  };

  render = () => {
    const { classes, intl, activityTypes } = this.props;
    const { note, activityType, activityDate } = this.state;
    return (
      <Card>
        <CardContent>
          <div>
            <b>Log Activity</b> &nbsp;
            <Select value={activityType} onClick={e => this.setState({ activityType: e.target.value })}>
              {(activityTypes || []).map(at => <MenuItem value={at.id}>{at.name}</MenuItem>)}
            </Select>
          </div>
          <br />
          <TextField
            id="standard-multiline-flexible"
            label="Note"
            multiline
            fullWidth
            rowsMax="4"
            value={note}
            onChange={this.onChange}
            className={classes.textField}
            placeholder={intl.formatMessage({ id: `notebook.create_a_new_note` })}
            margin="normal"
          />
          {note && (
            <div>
              <br />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  variant="inline"
                  margin="normal"
                  value={activityDate}
                  onChange={date => this.setState({ activityDate: date })}
                />
              </MuiPickersUtilsProvider>
            </div>
          )}
          <Button
            disabled={!note}
            className={classes.saveButton}
            onClick={() => {
              this.clickSave();
            }}>
            {' '}
            Save{' '}
          </Button>
        </CardContent>
      </Card>
    );
  };
}

const styles = {
  saveButton    : {
    float  : 'right',
    margin : 8,
  },
  editorWrapper : {
    padding : '6px',
    display : 'block',
  },
  toolbar       : {
    padding : '6px',
    margin  : 0,
  },
  editor        : {
    padding      : 0,
    marginBottom : '12px',
  },
  textArea      : {
    minHeight : '80px',
  },
};

NewPipelineActivity.propTypes = {
  pipelineCard  : PropTypes.object,
  classes       : PropTypes.object.isRequired,
  intl          : PropTypes.object.isRequired,
  activityTypes : PropTypes.array,
  mutate        : PropTypes.func.isRequired,
  onChange      : PropTypes.func.isRequired,
  refetch       : PropTypes.func.isRequired,
};

NewPipelineActivity.defaultProps = {
  pipelineCard  : {},
  activityTypes : [],
};

export default graphql(createPipelineActivityMutation)(injectIntl(withStyles(styles)(NewPipelineActivity)));
