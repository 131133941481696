import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import numeral from "numeral";
import {Divider} from "@material-ui/core";
import CalibrationStaticGrid from "./CalibrationStaticGrid";

const ManualCharts = ({ session }) => {
  return (
    <div>
      <br />
      <Divider />
      <CalibrationStaticGrid
        data={session.calImpactVsRisk.map((v) =>
          numeral(v).format("0a")
        )}
        title="Risk vs Impact (#)"
        yLabel="Risk of Loss"
        xLabel="Impact of Loss"
        tileColor={(num) => {
          if (num >= 5) {
            return "#0b5394";
          }
          if (num > 0 && num < 5) {
            return "#3d85c6";
          }
          return "#4DA6FF";
        }}
        ySubTitles={["Low", "Medium", "High"]}
        xSubTitles={["Low", "Medium", "High"]}
      />
      <CalibrationStaticGrid
        data={session.calPvp}
        title="Performance vs. Potential (#)"
        xLabel="Performance"
        ySubTitles={["Low", "Medium", "High"]}
        xSubTitles={["Low", "Medium", "High"]}
        yLabel="Potential"
        dataTitles={[
          "Potential Gem",
          "Future Star",
          "Rainmaker",
          "Inconsistent Player",
          "Core Player",
          "Current Star",
          "Risk",
          "Average Performer",
          "Solid Performer",
        ]}
        tileColors={[
          "#f1c232",
          "#6aa84f",
          "#6aa84f",
          "#cc0000",
          "#f1c232",
          "#6aa84f",
          "#cc0000",
          "#cc0000",
          "#f1c232",
        ]}
      />
      <div style={{ clear: "both" }} />
      <CalibrationStaticGrid
        data={get(session, "calImpactVsRiskValues", []).map((v) =>
          numeral(v).format("$0a")
        )}
        title="Risk vs Impact ($)"
        yLabel="Risk of Loss"
        xLabel="Impact of Loss"
        tileColor={(num) => {
          if (num.length >= 4) {
            return "#0b5394";
          }
          if (num !== "$0") {
            return "#3d85c6";
          }
          return "#4DA6FF";
        }}
        ySubTitles={["Low", "Medium", "High"]}
        xSubTitles={["Low", "Medium", "High"]}
      />
      <CalibrationStaticGrid
        data={get(session, "calPvpValues", []).map((v) =>
          numeral(v).format("$0a")
        )}
        title="Performance vs. Potential ($)"
        xLabel="Performance"
        ySubTitles={["Low", "Medium", "High"]}
        xSubTitles={["Low", "Medium", "High"]}
        yLabel="Potential"
        dataTitles={[
          "Potential Gem",
          "Future Star",
          "Rainmaker",
          "Inconsistent Player",
          "Core Player",
          "Current Star",
          "Risk",
          "Average Performer",
          "Solid Performer",
        ]}
        tileColors={[
          "#f1c232",
          "#6aa84f",
          "#6aa84f",
          "#cc0000",
          "#f1c232",
          "#6aa84f",
          "#cc0000",
          "#cc0000",
          "#f1c232",
        ]}
      />
      <div />
    </div>
  );
}

ManualCharts.propTypes = {
  session: PropTypes.object.isRequired,
}

export default ManualCharts;