import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import TipViewDealStructure from '../components/TipViewDealStructure/TipViewDealStructure';
import * as userActions from '../actions/userActionCreators';
import { get, isEmpty } from 'lodash';
import ProfileDrawerWithListener from './ProfileDrawerWithListener';
import gql from 'graphql-tag';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
    }
  }
`;

const TipViewDealStructureContainer = ({
  updateUser,
}) => {
  const [ currentUserAllowed, setCurrentUserAllowed ] = useState(false);
  const [ data, setData ] = useState({});
  return (
    <Query
      query={getCurrentUser}
      onCompleted={returnData => {
        setCurrentUserAllowed(get(returnData, 'currentUserAllowed'));
        setData(returnData);
        updateUser(get(returnData, 'getCurrentUser'));
      }}>
      {({ refetch }) => {
        return (
          <div>
            <ProfileDrawerWithListener />
            <TipViewDealStructure
              marketplaceOnly
              currentUserAllowed={currentUserAllowed}
              data={data}
              agentWordForCompany={get(data, 'agentWordForCompany')}
              refetch={refetch}
              companyBusinessType={get(data, 'companyBusinessType')}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  profile  : state.profileDrawer,
});

TipViewDealStructureContainer.propTypes = {
  updateUser                  : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...userActions,
})(TipViewDealStructureContainer);
