import PropTypes from 'prop-types';
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { colors } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const styles = theme => ({
  card      : {
    width  : 275,
    margin : 6,
  },
  title     : {
    fontSize : '18px',
  },
  subHeader : {
    fontSize : '16px',
  },
  header    : {
    marginBottom : '16px',
    fontSize     : '16px',
    color        : theme.palette.text.secondary,
  },
  pos       : {
    marginBottom : '14px',
    color        : theme.palette.text.secondary,
    fontSize     : '16px',
  },
  avatar    : {
    backgroundColor : colors.blue[500],
  },
});

const Company = ({ company, classes }) => (
  <Card className={classes.card}>
    <CardContent>
      <CardHeader
        classes={{ root: classes.header, title: classes.title }}
        avatar={
          <Avatar aria-label="Recipe" className={classes.avatar}>
            {company.name.substr(0, 3)}
          </Avatar>
        }
        title={company.name}
        subheader={company.display_name}
      />
      <Typography component="p" className={classes.pos}>
        <b>Host:</b> {company.host}
        <br />
        <b>Display Name:</b> {company.display_name}
        <br />
        <b>Layout:</b> {company.layout}
        <br />
        <b>Root Controller:</b> {company.root_controller}
        <br />
        <b>Template:</b> {company.template}
        <br />
        {company.last_import_start && <div>
          <hr/>
          <b>Last Import Started:</b> { company.last_import_start && moment.tz(company.last_import_start, "America/Toronto").format('MMMM Do YYYY @h:mm a')}
          <br />
          <b>Last Import Finished:</b> { company.last_import_finished && moment.tz(company.last_import_finished, "America/Toronto").format('MMMM Do YYYY @h:mm a')}
          <br />
          <b>Last Import Total:</b> {company.last_import_total_number}
          <br />
          <b>Last Import Failures:</b> {company.last_import_failure_number}
        </div>}
        <br />
      </Typography>
    </CardContent>
    <CardActions extra>
      <Link to={`/admin/companies/${company.id}`}>Edit</Link>
    </CardActions>
  </Card>
);

Company.propTypes = {
  company : PropTypes.object,
  classes : PropTypes.object.isRequired,
};

Company.defaultProps = {
  company : {},
};

export default withStyles(styles)(Company);
