import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import OpportunityCard from '../../OpportunityCard/OpportunityCard';
import StatusControls from '../StatusControls/StatusControls';

const styles = {
  paper       : {
    padding       : 12,
    display       : 'flex',
    flexDirection : 'column',
    fontSize      : 16,
  },
  twoCol      : {
    padding       : 12,
    display       : 'flex',
    flexDirection : 'row',
  },
  performance : {
    flex         : 1,
    marginLeft   : 12,
    marginBottom : 12,
    padding      : 12,
  },
  card        : {
    flex : 2,
  },
  listingInfo : {
    flex      : 2,
    padding   : '0 12px',
    marginTop : -12,
  },
};

const ListingInfo = ({ classes, opportunity }) => (
  <Paper className={classes.paper}>
    <div className={classes.twoCol}>
      <div className={classes.card}>
        <a href={`/admin/opportunities/${opportunity.id}`}>Revise your opportunity</a>
      </div>
      <div className={classes.listingInfo}>
        <h4>Listing Info</h4>
        <b>Duration </b>
        {moment(opportunity.created_at).diff(moment(opportunity.expiry_date), 'days')} days
        <br />
        <b>Start Date: </b>
        {opportunity.created_at}
        <br />
        <b>End Date: </b>
        {opportunity.expiry_date}
        <br />
      </div>
      <div>
        <StatusControls opportunity={opportunity} />
      </div>
    </div>
    <div className={classes.twoCol}>
      <div className={classes.card}>
        <OpportunityCard opportunity={opportunity} />
      </div>
      <Paper className={classes.performance}>
        <h4>Opportunity Perfomance</h4>
        <b>Views: </b>
        {opportunity.ahoy_views}
        <br />
        <b>Applications: </b>
        {opportunity.applications.length}
        <br />
        <b>Views To Submissions: </b>
        {opportunity.ahoy_views / opportunity.applications.length}
        <br />
      </Paper>
    </div>
  </Paper>
);

ListingInfo.propTypes = {
  classes     : PropTypes.object.isRequired,
  opportunity : PropTypes.object.isRequired,
};

export default withStyles(styles)(ListingInfo);
