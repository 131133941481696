import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import InputSlider from '../../InputSlider/InputSlider';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import { debounce } from 'lodash';

class RetailWealthProductMixSliders extends React.Component {
  constructor (props) {
    super(props);
    const { gic_fixed_income, insurance, equity, mutual_funds_aum, segregated_funds_aua } = this.props.user.product_mix;
    this.state = {
      gic_fixed_income         : gic_fixed_income,
      insurance                : insurance,
      equity                   : equity,
      mutual_funds_aum         : mutual_funds_aum,
      segregated_funds_aua     : segregated_funds_aua,
      gic_fixed_income_max     : gic_fixed_income,
      insurance_max            : insurance,
      equity_max               : equity,
      mutual_funds_aum_max     : mutual_funds_aum,
      segregated_funds_aua_max : segregated_funds_aua,
    };
  }

  percentValuesAreGood = () => {
    const { gic_fixed_income, insurance, equity, mutual_funds_aum, segregated_funds_aua } = this.state;
    if (gic_fixed_income + insurance + equity + mutual_funds_aum + segregated_funds_aua <= 100) {
      return true;
    }
  };

  calcValues = () => {
    const { user } = this.props;
    const { gic_fixed_income, insurance, equity, mutual_funds_aum, segregated_funds_aua } = this.state;
    if (gic_fixed_income + insurance + equity + mutual_funds_aum + segregated_funds_aua <= 100) {
      this.setState({ gic_fixed_income_max: 100 - (insurance + equity + mutual_funds_aum + segregated_funds_aua) });
      this.setState({ insurance_max: 100 - (gic_fixed_income + equity + mutual_funds_aum + segregated_funds_aua) });
      this.setState({ equity_max: 100 - (gic_fixed_income + insurance + mutual_funds_aum + segregated_funds_aua) });
      this.setState({ mutual_funds_aum_max: 100 - (gic_fixed_income + insurance + equity + segregated_funds_aua) });
      this.setState({ segregated_funds_aua_max: 100 - (gic_fixed_income + insurance + equity + mutual_funds_aum) });
    }
  };

  debouncedUpdateAction = debounce(e => {
    const { updateAction } = this.props;
    updateAction(e);
  }, 1000);

  handleSliderChange = e => {
    this.setState(e);
    this.debouncedUpdateAction(e);
  };

  render () {
    const { classes, intl, user, theme } = this.props;
    const {
      gic_fixed_income_max,
      insurance_max,
      equity_max,
      mutual_funds_aum_max,
      segregated_funds_aua_max,
      gic_fixed_income,
      insurance,
      equity,
      mutual_funds_aum,
      segregated_funds_aua,
    } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <span><FormattedMessage id="profile_page.gic_fixed_income" /></span>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer theme={theme}>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={gic_fixed_income <= gic_fixed_income_max ? gic_fixed_income : gic_fixed_income_max}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.handleSliderChange({ gic_fixed_income: v <= gic_fixed_income_max ? v : gic_fixed_income_max });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{gic_fixed_income}{intl.locale == 'fr' && ' '} %</div>
        </Grid>
        <Grid item xs={12}>
          <span><FormattedMessage id="profile_page.segregated_funds_aua" /></span>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer theme={theme}>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={segregated_funds_aua <= segregated_funds_aua_max ? segregated_funds_aua : segregated_funds_aua_max}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.handleSliderChange({
                  segregated_funds_aua : v <= segregated_funds_aua_max ? v : segregated_funds_aua_max,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{segregated_funds_aua}{intl.locale == 'fr' && ' '}  %</div>
        </Grid>
        <Grid item xs={12}>
          <span><FormattedMessage id="profile_page.equity" /></span>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer theme={theme}>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={equity <= equity_max ? equity : equity_max}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.handleSliderChange({
                  equity : v <= equity_max ? v : equity_max,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{equity}{intl.locale == 'fr' && ' '}  %</div>
        </Grid>
        <Grid item xs={12}>
          <span><FormattedMessage id="profile_page.mutual_funds_aum" /></span>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer theme={theme}>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={mutual_funds_aum <= mutual_funds_aum_max ? mutual_funds_aum : mutual_funds_aum_max}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.handleSliderChange({
                  mutual_funds_aum : v <= mutual_funds_aum_max ? v : mutual_funds_aum_max,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{mutual_funds_aum}{intl.locale == 'fr' && ' '}  %</div>
        </Grid>
        <Grid item xs={12}>
          <span><FormattedMessage id="profile_page.insurance" /></span>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer theme={theme}>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={insurance <= insurance_max ? insurance : insurance_max}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.handleSliderChange({
                  insurance : v <= insurance_max ? v : insurance_max,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{insurance}{intl.locale == 'fr' && ' '}  %</div>
        </Grid>
        <Grid item xs={7}>
          <div style={{ marginLeft: 10, marginTop: 25 }}><FormattedMessage id="shared.total" /></div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>
            {gic_fixed_income + insurance + equity + mutual_funds_aum + segregated_funds_aua}{intl.locale == 'fr' && ' '}  %
          </div>
        </Grid>
        <div style={{ marginBottom: 100 }} />
      </Grid>
    );
  }
}

RetailWealthProductMixSliders.propTypes = {
  mutate      : PropTypes.func.isRequired,
  currentUser : PropTypes.object.isRequired,
};

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${props.theme.palette.accent.main} !important;
        border: 1px solid ${props.theme.palette.accent.main} !important;
      }

      .input-range__track--active {
        background: ${props.theme.palette.accent.main};
      }
    `};
`;

const mapStateToProps = state => ({
  user : {
    ...state.user,
    product_mix : {
      gic_fixed_income     : state.user.product_mix.gic_fixed_income || 0,
      insurance            : state.user.product_mix.insurance || 0,
      equity               : state.user.product_mix.equity || 0,
      mutual_funds_aum     : state.user.product_mix.mutual_funds_aum || 0,
      segregated_funds_aua : state.user.product_mix.segregated_funds_aua || 0,
    },
  },
});

export default withTheme(connect(mapStateToProps, {})(injectIntl(RetailWealthProductMixSliders)));
