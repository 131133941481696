import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { get } from 'lodash';
import './PlanningInformation.css';

const hoursOptions = [
  { name: '0 hours', value: 0 },
  { name: '5 hours', value: 5 },
  { name: '10 hours', value: 10 },
  { name: '15 hours', value: 15 },
  { name: '20 hours', value: 20 },
  { name: '25 hours', value: 25 },
  { name: '30 hours', value: 30 },
  { name: '35 hours', value: 35 },
  { name: '40 hours', value: 40 }
];

const workweeks = [
  { id: 'now', name: 'Now' },
  { id: 'year_3', name: '3 Years From Now' },
  { id: 'year_5', name: '5 Years From Now' },
  { id: 'year_10', name: '10 Years From Now' },
  { id: 'year_15', name: '15 Years From Now' },
  { id: 'year_20', name: '20 Years From Now' },
  { id: 'year_25', name: '25 Years From Now' },
];

const UserWorkweekTrajectory = ({ user, updateAction }) => {
  return (<>
    <br />

    <div className="planningInformation__headingSection">
      <b>Your Workweek Trajectory</b>
    </div>
    <div>Indicate how many hours you plan on physically spending in the business.</div>

    {workweeks.map(ww =>
      <div className="form-group required col-md-6">
        <span className="help-block">
          {ww.name}
        </span>
        <Select
          fullWidth
          className="PlanningInformation__radio"
          value={`${get(user, `workweek_trajectory_${ww.id}`)}`}
          onChange={e => {
            updateAction({ [`workweek_trajectory_${ww.id}`]: parseInt(e.target.value) })
          }}
        >
          {hoursOptions.map(sps =>
            <MenuItem
              value={sps.value}
              className="PlanningInformation__radio"
              label={sps.name}
            >
              {sps.name}
            </MenuItem>)}
        </Select>
      </div>
    )}
  </>);
};

UserWorkweekTrajectory.propTypes = {
  user: PropTypes.object,
  updateAction: PropTypes.func.isRequired,
};

UserWorkweekTrajectory.defaultProps = {
  user: {},
};

export default UserWorkweekTrajectory;