import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const renderMessageButton = (disableMessageBtn, marketplaceOnly, scrollTo, classes, stylesheetName) => {
  if (marketplaceOnly) {
    return <div />;
  }
  if (disableMessageBtn) {
    return (
      <div>
        <Button variant="contained" disabled className={classes.button}>
          <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.message"/>
        </Button>
      </div>
    );
  }
  return (
    <div>
      <Button
        className={classes.margin}
        variant="contained"
        size="large"
        style={{ color: '#fff', width: '100%' }}
        onClick={() => scrollTo('chatRef')}>
        <FormattedMessage id="profile_page.message"/>
      </Button>
    </div>
  );
};

const ProfileCardMessageButton = ({ disableMessageBtn, marketplaceOnly, scrollTo, classes, stylesheetName }) => (
  <div>{renderMessageButton(disableMessageBtn, marketplaceOnly, scrollTo, classes, stylesheetName)}</div>
);

ProfileCardMessageButton.propTypes = {
  classes           : PropTypes.object.isRequired,
  scrollTo          : PropTypes.func.isRequired,
  disableMessageBtn : PropTypes.bool.isRequired,
  marketplaceOnly   : PropTypes.bool.isRequired,
};

const styles = {
  button                 : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed                : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
  rjpeFontColor          : {
    color : '#002949',
  },
  ref                    : {
    textTransform : 'capitalize',
    margin        : '0px 0px 5px 10px',
    color         : '#1396e2',
    display       : 'block',
  },
  margin                 : {
    margin : '0px 0px 15px 15px',
  },
};

export default withStyles(styles)(ProfileCardMessageButton);
