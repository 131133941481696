import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import Star from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';

export default class Rating extends Component {
  constructor (props) {
    super(props);
    this.state = {
      hoverValue : props.value,
    };
  }

  renderIcon = i => {
    const { value, itemIconStyle, iconHovered, iconFilled, iconNormal } = this.props;
    const { hoverValue } = this.state;
    const filled = i < (value || -1);
    const hovered = i < (hoverValue || -1);
    const icons = { iconHovered, iconFilled, iconNormal };

    if ((hovered && !filled) || (!hovered && filled)) {
      return <icons.iconHovered style={itemIconStyle} />;
    }
    if (filled) {
      return <icons.iconFilled style={itemIconStyle} />;
    }
    return <icons.iconNormal style={itemIconStyle} />;
  };

  renderRatings = () => {
    const { max, className, disabled, itemStyle, readOnly, value, onChange } = this.props;
    const ratings = new Array(max).fill(1);

    return ratings.map((m, i) => (
      <IconButton
        key={`ib_${i + 1}`}
        className={className}
        disabled={disabled}
        style={itemStyle}
        onMouseEnter={() => this.setState({ hoverValue: i + 1 })}
        onMouseLeave={() => this.setState({ hoverValue: value })}
        onClick={() => {
          if (!readOnly && onChange) {
            onChange(i + 1);
          }
        }}>
        {this.renderIcon(i)}
      </IconButton>
    ));
  };

  render = () => {
    const { disabled, style, readOnly } = this.props;

    return <div style={disabled || readOnly ? { ...style } : style}>{this.renderRatings()}</div>;
  };
}

Rating.defaultProps = {
  disabled      : false,
  iconFilled    : Star,
  iconHovered   : Star,
  iconNormal    : StarBorder,
  max           : 5,
  readOnly      : false,
  value         : 0,
  className     : '',
  itemIconStyle : {},
  itemStyle     : {},
  style         : {},
};

Rating.propTypes = {
  className     : PropTypes.string,
  disabled      : PropTypes.bool,
  iconFilled    : PropTypes.func,
  iconHovered   : PropTypes.func,
  iconNormal    : PropTypes.func,
  max           : PropTypes.number,
  onChange      : PropTypes.func.isRequired,
  readOnly      : PropTypes.bool,
  value         : PropTypes.number,
  itemIconStyle : PropTypes.object,
  itemStyle     : PropTypes.object,
  style         : PropTypes.object,
};
