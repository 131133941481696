import { DataGrid } from '@material-ui/data-grid';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

const theme = createMuiTheme({
  typography: {
    fontSize: 14, // replace with your desired font size
  },
});

const CashFlowAnalysisWidgit = ({ inventoryUser }) => {
  const cashFlows = get(inventoryUser, 'user.cash_flows', []);
  const rows = cashFlows.reverse().map((cf, index) => ({
    id: index,
    name: get(cf, 'name'),
    author: inventoryUser.user.name,
    createdAt: moment(cf.createdAt).format("D MMM, YYYY"),
    link: `/calculators/cfa/${cf.friendlyName}`
  }));

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'author', headerName: 'Author', width: 100 },
    { field: 'createdAt', headerName: 'Created At', width: 100 },
    {
      field: 'link',
      headerName: 'Link',
      width: 100,
      renderCell: (params) => (
        <a href={params.value} target="_blank">View</a>
      ),
    },
  ];

  return (
    <div>
      <h5>Cash Flow Analysis</h5>
      {
        <div>
          <table style={{width: '100%'}}>
            <tr>
              <th>Name</th>
              <th>Author</th>
              <th>Created At</th>
              <th>Link</th>
            </tr>
            {cashFlows
              .slice()
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
              .map(cf => (
              <tr>
                <td>{get(cf, 'name')}</td>
                <td>{inventoryUser.user.name}</td>
                <td>{moment(cf.createdAt).format("D MMM, YYYY")}</td>
                <td><a href={`/calculators/cfa/${cf.friendlyName}`} target="_blank">View</a></td>
              </tr>
            ))}
          </table>
        </div>
      }
      <br />
      <br />
    </div>
  );
};

export default CashFlowAnalysisWidgit;