import React from 'react';
import { get } from 'lodash';


const CFABanner = ({lenderUrl, company}) => {
  const hasCfaEducationBanner = get(company, 'features', []).includes('cfa_education_banner');
  // get first 3 letters of the host name after the http:// or https:// and assign to a variable
  const tenant = company.host.match(/(.{3})/)[1];
  if(hasCfaEducationBanner) {
    let urlLink = "https://findbob-production.s3-us-west-2.amazonaws.com/rj/Buy+-+Sell+Education/Understanding+Deal+Structure+(RJA).pdf"
    if(tenant === 'icd') {
      urlLink = "https://help-practiceexchange.rjf.com/en/articles/9298697-using-the-deal-structure-tool"
    }
    return (
      (<span className="cfa_referral" style={{ paddingTop: 70, display: 'block' }}>
        <a href={urlLink} target="_blank">
          <center>
            <img style={{width: '90%'}} src={`https://findbob-production.s3.us-west-2.amazonaws.com/images/${tenant}_deal_structure_education.gif`} />
          </center>
        </a>
      </span>)
    );
  }

  if(lenderUrl) {
    return (
      (<span className="cfa_referral" style={{ paddingTop: 70, display: 'block' }}>
        <a href={"#"} onClick={() => {sendReferral();}}>
          <center>
            <img style={{width: '90%'}} src={lenderUrl} />
          </center>
        </a>
      </span>)
    );
  }
  return <span/>; 
}

export default CFABanner;