/* eslint-disable import/prefer-default-export */

import * as api from '../lib/api';

import {
  LOAD_INVENTORY_USERS,
  INVENTORY_USERS,
  INVENTORY_USERS_UPDATE,
  INVENTORY_USERS_ADD,
  INVENTORY_USERS_DELETE,
  INVENTORY_USERS_PUT,
  SELECT_INVENTORY_USER_UPDATE,
  LOAD_BENCH_USERS,
} from '../constants/inventoryUsersConstants';

export const loadInventoryUserList = inventoryUserSettings => ({
  type                  : LOAD_INVENTORY_USERS,
  inventoryUserSettings,
});

export const updateInventoryUser = inventoryUser => ({
  type          : INVENTORY_USERS_UPDATE,
  inventoryUser,
});

export const updateSelectedInventoryUser = selectedInventoryUser => ({
  type                  : SELECT_INVENTORY_USER_UPDATE,
  selectedInventoryUser,
});

export const loadBenchUsers = (bench, benchLoad) => ({
  type      : LOAD_BENCH_USERS,
  bench,
  benchLoad,
});

export const loadInventoryUser = () => dispatch => {
  dispatch({ type: INVENTORY_USERS, company: {}, loading: true });
  api.get(`/admin/inventory_users.json`).then(response => {
    dispatch({
      type            : INVENTORY_USERS,
      inventory_users : response.data,
      loading         : false,
    });
  });
};

export const addInventoryUser = newInventoryUser => dispatch => {
  dispatch({ type: INVENTORY_USERS_ADD, newInventoryUser: null, error: null, close: false, loading: true });
  api
    .post(`/admin/inventory_users`, { inventory_user: newInventoryUser })
    .then(response => {
      dispatch({
        type             : INVENTORY_USERS_ADD,
        newInventoryUser : { ...response.data, id: String(response.data.id) },
        error            : response.error,
        close            : response.status === 200,
        loading          : false,
      });
      setTimeout(() => {
        dispatch({
          type    : INVENTORY_USERS_ADD,
          error   : null,
          close   : false,
          loading : false,
        });
      }, 8000);
    })
    .catch(error => {
      dispatch({
        type    : INVENTORY_USERS_ADD,
        error,
        loading : false,
      });
    });
};

export const deleteInventoryUser = inventoryUser => {
  const deletedInventoryUser = inventoryUser;
  const inventoryUser_id = inventoryUser.id;
  deletedInventoryUser.__destroy = true;
  return dispatch => {
    dispatch({ type: INVENTORY_USERS_DELETE, inventoryUser: null, error: null, close: false, loading: true });
    api
      .del(`/admin/inventory_users/${inventoryUser_id}.json`)
      .then(response => {
        dispatch({
          type             : INVENTORY_USERS_DELETE,
          inventoryUser_id,
          error            : response.error,
          loading          : false,
        });
      })
      .catch(e => {
        dispatch({
          type             : INVENTORY_USERS_DELETE,
          inventoryUser_id,
          error            : `Could not delete inventory user. It's probably being used in other tables. ${e.message}`,
          loading          : false,
        });
        setTimeout(() => {
          dispatch({
            type    : INVENTORY_USERS_DELETE,
            error   : null,
            loading : false,
          });
        }, 8000);
      });
  };
};

export const putInventoryUser = inventoryUser => dispatch => {
  dispatch({ type: INVENTORY_USERS_PUT, newInventoryUser: null, error: null, close: false, loading: true });
  const inventoryUserNew = inventoryUser;
  delete inventoryUserNew.modified;
  api
    .put(`/admin/inventory_users/${inventoryUser.id}.json`, { inventory_user: inventoryUserNew })
    .then(response => {
      dispatch({
        type          : INVENTORY_USERS_PUT,
        inventoryUser : response.data,
        error         : response.error,
        close         : response.status === 200,
        loading       : false,
      });
      setTimeout(() => {
        dispatch({
          type    : INVENTORY_USERS_PUT,
          error   : null,
          close   : false,
          loading : false,
        });
      }, 8000);
    })
    .catch(function (error) {
      dispatch({
        type          : INVENTORY_USERS_PUT,
        inventoryUser : null,
        error         : error.response,
        close         : false,
        loading       : false,
      });
    });
};
