import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import numeral from 'numeral';

const oppositeStatus = (status = 'in_progress') => {
  if (status === 'in_progress') {
    return 'approved';
  }
  return 'in_progress';
};

const calibratedOnValue = {
  rev_yield               : 'Revenue',
  total_premium           : 'Total Premium',
  total_assets            : 'Total Assets',
  life_ifp                : 'Life Ifp',
  home_ifp                : 'Home Ifp',
  auto_ifp                : 'Auto Ifp',
  commercial_ifp          : 'Commercial Ifp',
  health_ifp              : 'Health Ifp',
  group_ifp               : 'Group Ifp',
  mutual_funds_aum        : 'Mutual Funds Aum',
  securities_aum          : 'Securities Aum',
  annuities_aua           : 'Annuities Aua',
  mcpi_ifp                : 'MCPI Ifp',
  private_products_ifp    : 'Private Products Ifp',
  crop_hail_ifp           : 'Crop Hail Ifp',
  crop_hail_with_wind_ifp : 'Crop Hail with Wind Ifp',
  farm_insurance_ifp      : 'Farm Insurance Ifp',
  price_products_ifp      : 'Price Products Ifp',
  replant_supplement_ifp  : 'Replant Supplement Ifp',
  segregated_funds_aua    : 'Segregated Funds Aua',
};

const ApproveSessionButton = ({
  updateCalibration,
  session,
  refetch,
  enqueueSnackbar,
  inv_users,
  calibrationValue,
  calibratedOn,
}) => {
  return (
    <span>
      <Button
        onClick={() => {
          updateCalibration({
            variables : {
              id                : session.id,
              status            : oppositeStatus(session.status),
              inv_users         : inv_users.filter(u => u.candidate_session_completed).map(i => i.id),
              calibration_value : numeral(calibrationValue).format('$0,0[.]00'),
              calibrated_on     : calibratedOnValue[calibratedOn],
            },
          });
          refetch();
          if (session.status === 'in_progress') {
            enqueueSnackbar(
              'Congratulations on calibrating your session. In order to make any further changes, you must click ‘Re-Open‘',
              {
                variant      : 'success',
                anchorOrigin : {
                  vertical   : 'top',
                  horizontal : 'center',
                },
              }
            );
          }
        }}>
        {get(session, 'status', 'in_progress') === 'in_progress' ? 'Approve' : 'Re-Open'}
      </Button>
    </span>
  );
};

ApproveSessionButton.propTypes = {
  updateCalibration : PropTypes.func,
  session           : PropTypes.object.isRequired,
  refetch           : PropTypes.func.isRequired,
  enqueueSnackbar   : PropTypes.func.isRequired,
  inv_users         : PropTypes.array.isRequired,
  calibrationValue  : PropTypes.string.isRequired,
  calibratedOn      : PropTypes.string.isRequired,
};

ApproveSessionButton.defaultProps = {
  updateCalibration : () => {},
};

export default withSnackbar(ApproveSessionButton);
