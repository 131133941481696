import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';

const SideWhatsYourWhy = ({ classes, user, obfuscateView }) => {
  if (!isEmpty(get(user, 'why_values'))) {
    const whyValues = get(user, 'why_values', []).map(p => capitalize(p.replaceAll('_', ' ')));
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.whats_your_why"/></strong>
        {!obfuscateView && (
          <p>
            {whyValues.map(s => (
              <span>
                {s}
                <br />
              </span>
            ))}
          </p>
        )}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(SideWhatsYourWhy);
