import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import BenchCard from './BenchCard';
import { isEmpty, get } from 'lodash';
import NomAttSelect from './NomAttSelect';
import TalentThumbnail from '../OpportunityAdmin/TalentThumbnail/TalentThumbnail';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';

const CreateBench = gql`
  mutation createBench($inventory_user_id: Int!, $nominee_iu_id: Int!, $nominate_as: String, $ready_in: String) {
    createBench(
      inventory_user_id: $inventory_user_id
      nominee_iu_id: $nominee_iu_id
      nominate_as: $nominate_as
      ready_in: $ready_in
    )
  }
`;
class BenchNomCard extends React.Component {
  constructor (props) {
    super(props);
    const { inventoryUsers } = this.props;
    let cleanInventoryUsers = [];

    if (!isEmpty(inventoryUsers)) {
      cleanInventoryUsers = inventoryUsers.data.filter(user => user.name != null);
    }

    this.state = {
      initialItems     : cleanInventoryUsers,
      benchUsers       : [],
      nomAttSelectOpen : false,
      nominee          : '',
      error            : '',
      duplicateNominee : false,
      self             : false,
    };
    this.clickNominate = this.clickNominate.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ nomAttSelectOpen: true });
  };

  handleClose = () => {
    this.setState({ nomAttSelectOpen: false });
  };

  createBench = (nominee, nominate_as, ready_in, profile_picture, has_user) => {
    const { mutate, inventoryUser, loadBenchUsers, updateInventoryUser } = this.props;
    mutate({
      variables : {
        inventory_user_id : parseInt(inventoryUser.id, 10),
        nominee_iu_id     : parseInt(nominee.id, 10),
        nominate_as,
        ready_in,
      },
    })
      .then(() => {
        const newBench = [
          { nominee_iu: { id: nominee.id, name: nominee.name, profile_picture, has_user }, nominate_as, ready_in },
        ];
        if (inventoryUser.bench) {
          loadBenchUsers(newBench.concat(inventoryUser.bench));
          updateInventoryUser({ ...inventoryUser, bench_count: inventoryUser.bench.length + 1 });
        } else {
          loadBenchUsers(newBench);
          updateInventoryUser({ ...inventoryUser, bench_count: 1 });
        }
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  clickNominate = att => {
    const { nominee } = this.state;
    this.createBench(nominee, att.nominate_as, att.ready_in, att.profile_picture, att.has_user);
  };

  closeDuplicateNominee = () => {
    this.setState({ duplicateNominee: false, self: false });
  };

  checkDupe = v => {
    const { inventoryUser, inventoryUserBenchList } = this.props;
    if (v.id === inventoryUser.id) {
      this.setState({ duplicateNominee: true, self: true });
      return;
    }
    if (
      isEmpty(inventoryUserBenchList) ||
      (!isEmpty(inventoryUserBenchList) &&
        isEmpty(inventoryUserBenchList.filter(benchUser => benchUser.nominee_iu.id === v.id)))
    ) {
      this.setState({ nomAttSelectOpen: true, nominee: v });
    } else {
      this.setState({ duplicateNominee: true });
    }
  };

  componentWillMount = () => {
    const { initialItems } = this.state;
    this.setState({ benchUsers: initialItems });
  };

  filterList = event => {
    const { initialItems } = this.state;
    let updatedList = initialItems;
    updatedList = updatedList.filter(item => item.name.toLowerCase().search(event.target.value.toLowerCase()) !== -1);
    this.setState({ benchUsers: updatedList });
  };

  filterBenchUsers = (inventoryUser, inventoryUserBenchList, benchUsers) => {
    let removeFromList = [];
    let filteredBenchUsers = benchUsers;
    if (inventoryUserBenchList) {
      removeFromList = inventoryUserBenchList.map(b => parseInt(b.nominee_iu.id, 10));
      for (let i = 0; i < removeFromList.length; i += 1) {
        filteredBenchUsers = filteredBenchUsers.filter(b => b.id !== removeFromList[i]);
      }
    }
    filteredBenchUsers = filteredBenchUsers.filter(b => b.id !== inventoryUser.id);
    return filteredBenchUsers;
  };

  render () {
    const { classes, bench, open, handleClose, inventoryUser, inventoryUserBenchList } = this.props;
    const { benchUsers, nomAttSelectOpen, nominee, error, duplicateNominee, self } = this.state;

    const filteredBenchUsers = this.filterBenchUsers(inventoryUser, inventoryUserBenchList, benchUsers);
    return (
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <div className={classes.card}>
          <DialogTitle className={classes.nameSpace}>
            <b>Find Bench Talent For</b>
          </DialogTitle>
          <DialogContent>
            <div className={classes.nameSpace}>
              <BenchCard user={inventoryUser} inventoryUser={inventoryUser} bench={bench} />
            </div>
            <hr />
            <DialogContentText>
              <b>Search for bench talent within your company</b>
              <br />
              Enter the name of someone in your distribution who would be a good candidate with {inventoryUser.name}
              .
            </DialogContentText>
            <br />
            <InventoryUserSearchField
              onChange={v => {
                this.checkDupe(v);
              }}
            />
            <NomAttSelect
              open={nomAttSelectOpen}
              handleClose={this.handleClose}
              inventoryUser={inventoryUser}
              user={nominee}
              clickNominate={this.clickNominate}
            />
            <br />
            <p className={classes.suggestedSection}>Suggested Talent</p>
            <div className={classes.suggested}>
              <div className={classes.suggestedList}>
                {filteredBenchUsers.slice(0, 2).map(user => (
                  <div className={classes.suggestedCards}>
                    <TalentThumbnail user={user} bench showSelect createBench={() => this.checkDupe(user)} />
                  </div>
                ))}
              </div>
            </div>
          </DialogContent>
          <Dialog open={duplicateNominee} onClose={this.closeDuplicateNominee} aria-labelledby="form-dialog-title">
            {!self && (
              <div>
                <DialogTitle>
                  <b>Already in Bench!</b>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This user is already in {inventoryUser.name}'s Bench.
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            {self && (
              <div>
                <DialogTitle>
                  <b>Invalid nomination!</b>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You cannot nominate {inventoryUser.name} to their own Bench. Please choose someone else.
                  </DialogContentText>
                </DialogContent>
              </div>
            )}
            <DialogActions>
              <Button onClick={this.closeDuplicateNominee} color="primary" style={{ float: 'left', marginLeft: 15 }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

BenchNomCard.propTypes = {
  classes                : PropTypes.object.isRequired,
  bench                  : PropTypes.bool,
  open                   : PropTypes.bool.isRequired,
  handleClose            : PropTypes.func.isRequired,
  mutate                 : PropTypes.func.isRequired,
  updateInventoryUser    : PropTypes.func.isRequired,
  inventoryUser          : PropTypes.object.isRequired,
  inventoryUsers         : PropTypes.object.isRequired,
  inventoryUserBenchList : PropTypes.array.isRequired,
  loadBenchUsers         : PropTypes.func.isRequired,
};

BenchNomCard.defaultProps = {
  bench : false,
};

const styles = {
  card             : {
    width  : 520,
    height : 670,
  },
  nameSpace        : {
    marginLeft : 4,
  },
  searchBar        : {
    margin : 20,
  },
  suggested        : {
    width : '100%',
  },
  list             : {
    height    : '378px',
    overflowX : 'hidden',
    overflowY : 'auto',
  },
  cards            : {
    margin : '15px',
  },
  suggestedList    : {
    display  : 'flex',
    overflow : 'hidden',
  },
  suggestedCards   : {
    transform  : 'scale(0.8)',
    marginLeft : '-35px',
  },
  suggestedSection : {
    marginTop : 30,
  },
};

export default withStyles(styles)(graphql(CreateBench)(BenchNomCard));
