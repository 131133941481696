import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {get} from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {FormattedMessage} from "react-intl";

const NumberOfBuyers = ({list, query, classes, updateCfaQuery}) => {
    const validate = () => {
        let valid = true;

        if (!query.numberOfBuyers) {
            valid = false;
        }

        if (query.valid != valid) {
            updateCfaQuery({...query, valid});
        }
    }

    validate();
    return <div>
        <FormControl component="fieldset">
            <FormLabel component="legend"><FormattedMessage id="calculators.cfa.how_many_buyers_q"/></FormLabel>
            <RadioGroup aria-label="numberOfBuyers" name="numberOfBuyers" value={`${query.numberOfBuyers}`} onChange={e => {
                let buyerClear = {};
                if(parseInt(e.target.value) == 1){
                   buyerClear = {buyer1PercentagePurchasing: 1.0, buyer2T12Revenue: null, buyer2FiveYearGrowthRate: null, buyer2PercentagePurchasing: null};
                } else {
                    if(query.buyer2PercentagePurchasing == null) {
                        buyerClear = {buyer1PercentagePurchasing: 0.5, buyer2PercentagePurchasing: 0.5,}
                    }
                }
                            updateCfaQuery({...query,
                                ...buyerClear,
                                numberOfBuyers: parseInt(e.target.value), })
                        }}>
                <FormControlLabel value="1" control={<Radio />} label={<FormattedMessage id="calculators.cfa.one"/>} />
                <FormControlLabel value="2" control={<Radio />} label={<FormattedMessage id="calculators.cfa.two"/>} />
            </RadioGroup>
        </FormControl>
    </div>
};

NumberOfBuyers.propTypes = {
    query: PropTypes.array,
    classes: PropTypes.object.isRequired,
};

NumberOfBuyers.defaultProps = {
    list: [],
}

const styles = () => ({

});

const mapStateToProps = (state, props) => {
    return {
        result: {...get(state, 'cfa.result', {})},
        query: {...get(state, 'cfa.query', {})},
    };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(NumberOfBuyers));