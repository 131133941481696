import React from 'react';
import { Query, graphql } from 'react-apollo';
import client from '../lib/apolloClient';
import { get, uniqBy, isEmpty } from 'lodash';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import { MatchProfileQuery } from '../components/MatchProfile/MatchProfileQuery';
import qs from 'query-string';
import MatchMaking from '../components/MatchMaking/MatchMaking';

export const getRolodex = gql`
  query getRolodex($id: ID!) {
    getRolodex(id: $id) {
      id
      name
      inventory_user {
        id
        name
        province
        user {
          annual_revenue
        }
      }
    }
  }
`;

const removeDupes = (list, rolodex) => {
  return rolodex.filter(value => !list.map(l => l.id).includes(value.id));
};

const MatchMakingContainer = ({
  inventoryUserId,
  sanMatches,
  deal,
  classes,
  includeRolodex,
  updateState,
  deal_interests,
  matches,
  checkedMatches,
  checkedDealInterests,
  value,
  publishState,
  userClick,
  setInState,
}) => {
  return (
    <Query query={getRolodex} variables={{ id: inventoryUserId }}>
      {({ loading, error, data, refetch }) => {
        if (loading) return <div />;
        if (error) return `Error!: ${error}`;
        const rolodex = (get(data, 'getRolodex', []) || [])
          .map(r => r.inventory_user)
          .map(d => ({ ...d, id: parseInt(d.id, 10) }));
        const dealInterestRolodex = removeDupes(deal_interests, rolodex).map(r => ({ ...r, isRolodex: true }));
        const matchesRolodex = removeDupes(matches, rolodex).map(r => ({ ...r, isRolodex: true }));

        let newDealInterest = deal_interests;
        let newMatches = matches;
        if (includeRolodex) {
          newDealInterest = uniqBy(deal_interests.concat(dealInterestRolodex), 'id');
          newMatches = uniqBy(matches.concat(matchesRolodex), 'id');
        }
        if (!setInState) {
          updateState({ setInState: true, deal_interests: isEmpty(deal_interests) ? newMatches : newDealInterest });
        }

        return (
          <MatchMaking
            sanMatches={sanMatches}
            deal={deal}
            classes={classes}
            rolodex={rolodex}
            includeRolodex={includeRolodex}
            updateState={updateState}
            deal_interests={isEmpty(deal_interests) ? newMatches : newDealInterest}
            matches={newMatches}
            checkedMatches={checkedMatches}
            checkedDealInterests={checkedDealInterests}
            value={value}
            publishState={publishState}
            userClick={userClick}
            dealInterestRolodex={dealInterestRolodex}
            matchesRolodex={matchesRolodex}
          />
        );
      }}
    </Query>
  );
};

export default MatchMakingContainer;
