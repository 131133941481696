import React from 'react';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';

import { VictoryPie, VictoryTheme, VictoryLegend } from 'victory';

const OldProductMix = ({ insurance, segregatedFunds, mutualFunds, other, intl, colorScale }) => (
  <div className="revPie">
    <VictoryPie
      data={[{ y: insurance }, { y: segregatedFunds }, { y: mutualFunds }, { y: other }]}
      colorScale={colorScale}
      height={320}
      theme={VictoryTheme.material}
      style={{
        labels: { fontSize: 17 },
      }}
      innerRadius={80}
      labels={l => (l.y > 0 ? `${numeral(l.y).format('0')} %` : '')}
    />
    <VictoryLegend
      orientation="horizontal"
      itemsPerRow={2}
      height={80}
      colorScale={colorScale}
      data={[
        { name: intl.formatMessage({ id: 'bobcard.user_details.insurance' }), labels: { fontSize: 19 } },
        { name: intl.formatMessage({ id: 'bobcard.user_details.segregated_funds' }), labels: { fontSize: 19 } },
        { name: intl.formatMessage({ id: 'bobcard.user_details.mutual_funds' }), labels: { fontSize: 19 } },
        { name: intl.formatMessage({ id: 'bobcard.user_details.other' }), labels: { fontSize: 19 } },
      ]}
    />
  </div>
);

export default injectIntl(OldProductMix);
