import React from 'react';
import PropTypes, { objectOf } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import { injectIntl, FormattedMessage } from 'react-intl'
import { get } from 'lodash';
import ChatWidget from '../ChatWidget/ChatWidget';
import ConversationList from './ConversationList';
import { includes } from 'lodash';
import withWidth from '@material-ui/core/withWidth';
import PartialSaleBoxInMessagesPage from './PartialSaleBoxInMessagesPage';
import { support } from 'jquery';

const getOtherUser = (conversations, currentUser) => {
  const convo = conversations[0];
  if (currentUser.id === get(convo, 'participant1.id')) {
    return get(convo, 'participant2', {});
  }
  return get(convo, 'participant1', {});
};

class MessagesPage extends React.Component {
  constructor(props) {
    super(props);
    const { conversations, currentUser } = props;
    const filteredConversations = conversations.filter(c => c.conversationType !== 'paddy');
    // check if rid param is in url, if it is and it's in the conversations list, set it as the room_id
    const urlParams = new URLSearchParams(window.location.search);
    const rid = urlParams.get('rid');
    let room_id = get(filteredConversations[0], 'id', null);
    if (rid) {
      const c = filteredConversations.find(c => c.id === rid)
      if (c) {
        room_id = c.id;
      }
    }

    this.state = {
      room_id,
      title: get(getOtherUser(filteredConversations, currentUser), 'allowed_name') || 'Messages',
      open: true,
      otherUser: getOtherUser(filteredConversations, currentUser),
      can_view_name: get(getOtherUser(filteredConversations, currentUser), 'can_view_real_name'),
    };
  }

  userIsDeleted = () => {
    const { title } = this.state;
    return title.includes('Deleted') || title === '';
  };

  disableChat = () => {
    const { conversations, currentUser } = this.props;
    const { otherUser, room_id } = this.state;
    const currentConvo = conversations.find(c => c.id === room_id);
    const isDealClosed = Boolean(get(currentConvo, 'deal.closed', false) + get(currentConvo, 'dealInterest.deal.closed', false));
    if (['paddy', 'support_request'].includes(currentConvo.conversationType)) {
      return false;
    }

    let isDisabled = false;
    if (currentUser.company.segmented_views_enforced) {
      const otherUser = getOtherUser([currentConvo], currentUser);
      isDisabled = otherUser.inventory_user.segmented_view !== currentUser.inventory_user.segmented_view;
    }

    return isDealClosed || this.userIsDeleted() || get(otherUser, 'hidden') || get(otherUser, 'minimum_profile_completed') === false || isDisabled;
  };

  render() {
    const {
      classes,
      conversations,
      currentUser,
      companyHasNetworking,
      companyHasNewMessages,
      companyHasFeature,
      width,
      updateSelectedProfileDrawer,
      profile,
      company,
    } = this.props;
    const { room_id, title, open, can_view_name } = this.state;
    const hasDealTracking = companyHasFeature;
    const matches = width === 'xs';
    // filter out paddy conversations
    const filteredConversations = conversations.filter(c => c.conversationType !== 'paddy');
    const currentConvo = filteredConversations.find(c => c.id === room_id);
    const isDealClosed = Boolean(get(currentConvo, 'deal.closed', false) + get(currentConvo, 'dealInterest.deal.closed', false));
    const currentPartialSale = get(currentConvo, 'partialSaleListingUser.partial_sale_listing', {});
    
    const isPartialSale = currentConvo.conversationType === 'partial_sale_listing';
    let windowWidth = 9;
    if (!matches && isPartialSale) {
      windowWidth = 6;
    } else if (!matches || !isPartialSale) {
      windowWidth = 9;
    }

    return (
      <Grid container className={classes.container}>
        {matches ? (
          <Drawer
            variant={matches ? 'temporary' : 'permanent'}
            open={open}
            onClose={() => this.setState({ open: false })}
            classes={{
              paper: classes.drawerPaper,
            }}>
            <ConversationList
              conversations={filteredConversations}
              currentUser={currentUser}
              updateParent={this.setState.bind(this)}
              company={company}
            />
          </Drawer>
        ) : (
          <Grid xs={3}>
            <ConversationList
              conversations={filteredConversations}
              currentUser={currentUser}
              updateParent={this.setState.bind(this)}
            />

          </Grid>
        )}
        <Grid xs={windowWidth}>

          <Grid>
            {room_id && (
              <ChatWidget
                room_id={room_id}
                standalone
                updateSelectedProfileDrawer={updateSelectedProfileDrawer}
                profile={profile}
                userName={title}
                fromMessagesPage
                currentUser={currentUser}
                canViewRealName={can_view_name}
                title={
                  <span>
                    {matches && (
                      <Button onClick={() => this.setState({ open: true })}>
                        <BackIcon />
                      </Button>
                    )}
                    {title}
                  </span>
                }
                disableChat={this.disableChat()}
                disableChatMessage={isDealClosed ? <FormattedMessage id="messages_section.chat_disabled_room_closed" /> : <FormattedMessage id="messages_section.user_no_longer_active" />}
              />
            )}
            {!room_id &&
              companyHasNewMessages && (
                <div>
                  {companyHasNetworking &&
                    !hasDealTracking && (
                      <div style={{ marginTop: 30 }}>
                        <section id="about-us">
                          <section id="ceo-section" className="center">
                            <header className="center">
                              <div className="cite-title"><FormattedMessage id="messages_section.no_messages_yet" /></div>
                            </header>
                            <div className="cite no-bottom-margin">
                              <FormattedMessage id="messages_section.in_order_to_initiate" />
                            </div>
                            <hr className="divider" />
                            <a className="image">
                              <i className="fas fa-comments" style={{ fontSize: '60px' }} />
                            </a>
                            <h3><FormattedMessage id="messages_section.messaging" /></h3>
                            <figure className="subtitle"><FormattedMessage id="messages_section.connect_with_likeminded" /></figure>
                          </section>
                        </section>
                      </div>
                    )}
                  {!companyHasNetworking &&
                    hasDealTracking && (
                      <div style={{ marginTop: 30 }}>
                        <section id="about-us">
                          <section id="ceo-section" className="center">
                            <header className="center">
                              <div className="cite-title"><FormattedMessage id="messages_section.no_messages_yet" /></div>
                            </header>
                            <div className="cite no-bottom-margin">
                              <FormattedMessage id="messages_section.once_you_have_entered" />
                            </div>
                            <hr className="divider" />
                            <a className="image">
                              <i className="fas fa-comments" style={{ fontSize: '60px' }} />
                            </a>
                            <h3><FormattedMessage id="messages_section.messaging" /></h3>
                            <figure className="subtitle"><FormattedMessage id="messages_section.connect_with_likeminded" /></figure>
                          </section>
                        </section>
                      </div>
                    )}
                  {companyHasNetworking &&
                    hasDealTracking && (
                      <div style={{ marginTop: 30 }}>
                        <section id="about-us">
                          <section id="ceo-section" className="center">
                            <header className="center">
                              <div className="cite-title"><FormattedMessage id="messages_section.no_messages_yet" /></div>
                            </header>
                            <div className="cite no-bottom-margin">
                              <FormattedMessage id="messages_section.in_order_to_initiate_with_negotiation" />
                            </div>
                            <hr className="divider" />
                            <a className="image">
                              <i className="fas fa-comments" style={{ fontSize: '60px' }} />
                            </a>
                            <h3><FormattedMessage id="messages_section.messaging" /></h3>
                            <figure className="subtitle"><FormattedMessage id="messages_section.connect_with_likeminded" /></figure>
                          </section>
                        </section>
                      </div>
                    )}
                </div>
              )}

          </Grid>

        </Grid>
        {isPartialSale && (
          <Grid xs={3}>
            <PartialSaleBoxInMessagesPage
              partialSale={currentPartialSale}
              convo={currentConvo}
              currentUser={currentUser}
              classes={classes}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}

MessagesPage.propTypes = {
  conversations: PropTypes.array,
  currentUser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

MessagesPage.defaultProps = {
  conversations: [],
};

const styles = {
  container: {
    maxWidth: 1140,
    margin: '20px auto',
    height: 'calc(100vh - 160px)',
    display: 'flex',
  },
  drawerPaper: {
    width: '100vw',
    top: 114,
  },
  profilePicture: {
    width: 70,
    height: 70,
    borderRadius: 50,
    objectFit: 'cover',

  },
  saleBox: {
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    border: '1px solid #ccc',
    borderRadius: 4,
    margin: '6px 0 0 40px',
    width: '100%',
  },
  segmentDetails: {
    fontSize: 18,
    marginBottom: 10,
    flex: 3,
  },
  askingPrice: {
    fontSize: 18,
    color: '#00a99d',
    flex: 1,
  },
  pictureBox: {
    flex: 1,
    margin: 'auto',
  },
  saleCopy: {
    flex: 5,
  },
  expansionPanel: {
    padding: 0,
    marginTop: 10,
  },
  listItem: {
    padding: 0,
  },
  expansionPanelDetails: {
    marginTop: 0,
    padding: 4,
  },
  list: {
    padding: '6px',
  },
  thinLine: {
    marginBottom: 5,
    marginTop: 5,
  },
  title: {
    marginTop: 2,
  },
  supportButton: {
    width: '100%',
    padding: 12,
    border: '1px solid #ccc',
    borderRadius: 0,
    fontSize: 12,
  },
};

export default injectIntl(withStyles(styles)(withWidth()(MessagesPage)));
