/* eslint-disable import/prefer-default-export */

import {
  PIPELINE_UPDATE,
  PIPELINE_CARD_UPDATE,
  PIPELINE_CARD_ADD,
  CHANGE_PIPELINE_STATE,
} from '../constants/pipelineConstants';

export const updatePipeline = pipeline => ({
  type     : PIPELINE_UPDATE,
  pipeline,
});

export const updatePipelineCard = pipelineCard => ({
  type         : PIPELINE_CARD_UPDATE,
  pipelineCard,
});

export const addPipelineCard = pipelineCard => ({
  type         : PIPELINE_CARD_ADD,
  pipelineCard,
});

export const changePipelineState = pipelineState => ({
  type          : CHANGE_PIPELINE_STATE,
  pipelineState,
});
