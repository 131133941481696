import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import DeclineFaModal from './DeclineFaModal';

const clickShortlist = dealInterest => {
  window.open(
    `/deal/${get(dealInterest, 'deal.deal_name')}/shortlist_deal_interest/${get(dealInterest, 'id')}`,
    '_self'
  );
};

const clickArchive = (dealInterest, declined) => {
  window.open(
    `/deal/${get(dealInterest, 'deal.deal_name')}/archive_deal_interest/${get(
      dealInterest,
      'id'
    )}?declined=${declined}`,
    '_self'
  );
};

const ProposalLetter = ({
  proposalLetter,
  dealInterest,
  classes,
  highlight,
  allowShortlistMessaging,
  businessType,
  intl,
}) => {
  const [ openModal, setModalState ] = useState(false);
  const questionWord = businessType === 'wealth_rj' ? 'discovery' : 'knockout';
  return (
    <div>
      <div className={`content-card ${highlight ? classes.highlight : ''}`} style={{ height: 'inherit' }}>
        <div id="property-detail">
          <h3><FormattedMessage id="profile_page.match_profile.proposal_letter"/></h3>
          <div className="property-title">{proposalLetter}</div>
        </div>
        {dealInterest.workflow_state === 'reviewing_proposal' && (
          <div>
            <div className={classes.updateButtonMobile}>
              <Grid container>
                <hr />
                <Grid xs={6} style={{ margin: 'auto' }}>
                  <div>
                    <Button
                      onClick={() => clickShortlist(dealInterest)}
                      class="btn btn-default"
                      type="button"
                      style={{ marginTop: 10, width: '90%' }}>
                      <FormattedMessage id="profile_page.match_profile.shortlist"/>
                    </Button>
                  </div>
                </Grid>
                <Grid xs={6}>
                  {businessType !== 'wealth_rj' && (
                    <div>
                      <Button
                        onClick={() => clickArchive(dealInterest, false)}
                        color="primary"
                        class="btn btn-default"
                        type="button"
                        style={{ marginTop: 10, width: '90%' }}>
                        <FormattedMessage id="profile_page.match_profile.archive"/>
                      </Button>
                    </div>
                  )}
                  {businessType === 'wealth_rj' && (
                    <div>
                      <Button
                        onClick={() => setModalState(true)}
                        color="primary"
                        class="btn btn-default"
                        type="button"
                        style={{ marginTop: 10, width: '90%' }}>
                        <FormattedMessage id="profile_page.match_profile.decline"/>
                      </Button>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={classes.status}>
              <strong><FormattedMessage id="profile_page.match_profile.next_step"/></strong>
              {allowShortlistMessaging && (
                <p>
                  {intl.formatMessage({ id: 'profile_page.match_profile.after_reading_the_proposal_letter' }, {questionWord} )}
                </p>
              )}
              {!allowShortlistMessaging && (
                <p><FormattedMessage id="profile_page.match_profile.add_this_profile_to_your_shortlist"/></p>
              )}
            </div>
          </div>
        )}
      </div>
      <DeclineFaModal dealInterest={dealInterest} openModal={openModal} setModalState={setModalState} />
    </div>
  );
};

const styles = {
  highlight                : {
    animation  : '$changeColor 3s linear 2s 1 normal forwards',
    background : '#ffff99',
  },
  '@keyframes changeColor' : {
    from          : { background: '#ffff99' },
    to            : { background: '#fff' },
    bottomButtons : {
      position        : 'fixed',
      left            : 0,
      bottom          : 0,
      textAlign       : 'center',
      paddingBottom   : 10,
      borderTop       : '1px #D5D8DC solid',
      backgroundColor : '#fff',
      width           : '100%',
    },
    status        : {
      marginTop : 20,
    },
  },
};

export default withStyles(styles)(injectIntl(ProposalLetter));
