import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import InsertChart from '@material-ui/icons/InsertChart';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import {get} from 'lodash';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import { ExportMetrics } from './InventoryUserQueries';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
        feature_types
      }
    }
  }
`

const handleClick = mutate => {
  mutate().then(alert('CSV with engagement four_year_revenue will be emailed to you shortly')).catch(error => console.log(error));
};

const ExportEngagementMetrics = ({ mutate, company, user }) => {
  
  return (
    <Query query={getCurrentUser}>
      {({ loading, data, error }) => {
        const allow_export = company.features.includes('export_company_metrics') ||
          (get(data, 'getCurrentUser.inventory_user.feature_types', []).includes('export_company_metrics'));
        if(!allow_export) {
          return <span/>;
        }
        return <Tooltip title="Export Engagement Metrics">
                <IconButton onClick={() => handleClick(mutate)}>
                  <InsertChart />
                </IconButton>
              </Tooltip>
      }}
    </Query>
)};

ExportEngagementMetrics.propTypes = {
  mutate : PropTypes.func.isRequired,
};

const mapStateToProps = state => ({ company: state.company, user: state });

export default connect(mapStateToProps)(graphql(ExportMetrics)(ExportEngagementMetrics));
