import React from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

const SuccessionReadinessTab = ({ classes, card }) => {
  const successionReadiness = get(card, 'successionReadiness', {});
  const readinessActivities = get(successionReadiness, 'readinessActivities', []);

  return (
    <Box className={classes.box}>
      <Typography variant="h4">Succession Readiness</Typography>
      <Box className={classes.bannerBox}>
        <Typography variant="h5" className={classes.yearsFromRetirement}>&nbsp;{successionReadiness.yearsFromRetirement}</Typography>
        <Typography className={classes.leftMargin}> Years from Retirement</Typography>
      </Box>
      <Typography variant="h4">Activity Cards</Typography>
      <Box>
        {readinessActivities.map((activity, index) => (
          <Box key={index}>
            <Box>
              <Checkbox checked={activity.completed} />
              <span>{activity.title}</span>
            </Box>
            <Typography className={classes.activityText}>{activity.description}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

SuccessionReadinessTab.propTypes = {
  classes: PropTypes.object.isRequired,
  card: PropTypes.object.isRequired,
};

const styles = {
 box: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  bannerBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
  },
};

export default withStyles(styles)(SuccessionReadinessTab);