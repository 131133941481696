import React from 'react';
import PropTypes from 'prop-types';
import InterestBanner from '../components/InterestBanner/InterestBanner';
import GettingStartedBanner from '../components/GettingStartedBanner/GettingStartedBanner';

const GetStartedContainer = ({ data, updateUser, currentUser }) => {
  if (currentUser.welcome_current_interest) {
    return <GettingStartedBanner data={data} currentUser={currentUser} />;
  }
  return <InterestBanner currentUser={currentUser} updateUser={updateUser} />;
};

GetStartedContainer.propTypes = {
  currentUser : PropTypes.object,
  updateUser  : PropTypes.object.isRequired,
  data        : PropTypes.object,
};

GetStartedContainer.defaultProps = {
  currentUser : {},
  data        : {},
};

export default GetStartedContainer;
