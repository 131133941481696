import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

const ScheduledMeetings = ({ meetings }) => {
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <h3><FormattedMessage id="profile_page.match_profile.scheduled_meetings"/></h3>
        {meetings.map(m => (
          <div>
            <strong>{m.format}</strong>
            <div className="property-title">
              {moment(m.start_time).format('MMMM Do YYYY')} - {moment.utc(m.start_time).format('h:mm a')} -{' '}
              {moment.utc(m.start_time).add(m.duration, 'minutes').format('h:mm a')}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScheduledMeetings;
