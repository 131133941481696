import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

const RecognitionLevel = ({ classes, user, obfuscateView }) => {
  if (get(user, 'recognition_level')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.recognition_level"/></strong>
        {!obfuscateView && <p>{get(user, 'recognition_level')}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status: {
    marginBottom: 15,
    width: 170,
  },
};

export default withStyles(styles)(RecognitionLevel);
