import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import { graphql, Mutation } from 'react-apollo';

export const UpdateContinuityBuilder = gql`
  mutation updateContinuityBuilder(
    $id: ID!
    $filed_agreement_date: String
  ) {
    updateContinuityBuilder(
      id: $id
      filed_agreement_date: $filed_agreement_date
    )
  }
`;

export const AgreementDatePopup = ({ displayPopup, closePopup, continuity_builder_id, }) => {
    const [filed_agreement_date, setFiledAgreeementDate] = useState() 

    return <Fragment>
      <Dialog open={displayPopup} onClose={closePopup}>
        <DialogTitle><FormattedMessage id="file_tab.enter_document_execution" /></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage id="file_tab.please_indicate" />
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="contract_date"
            label="Contract Date"
            InputProps={{ inputProps: { max: `${moment().format("YYYY-MM-DD")}`}}}
            type="date"
            fullWidth
            variant="standard"
            value={filed_agreement_date}
            onChange={e => setFiledAgreeementDate(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closePopup}><FormattedMessage id="shared.cancel" /></Button>
          <Mutation
            mutation={UpdateContinuityBuilder}
            onCompleted={response => {
              let el = document.getElementById('execution_date');
              if(el) {
                el.innerText = moment(filed_agreement_date).format("MMMM DD, YYYY");
              }
            }}>
              {updateBuilder => 
              <Button
                disabled={!Boolean(filed_agreement_date)}
                onClick={() => {
                  const variables = {
                      id: continuity_builder_id,
                      filed_agreement_date, 
                    };

                  updateBuilder({
                    variables, 
                  });
                  closePopup();
                  }}>
                    OK
                  </Button>
              }
          </Mutation>
        </DialogActions>
      </Dialog>     
    </Fragment>;
};

AgreementDatePopup.propTypes = {
  displayPopup: PropTypes.bool.isRequired,
  closePopup: PropTypes.func.isRequired,
};


export default AgreementDatePopup;
