import React, { useState, Fragment } from 'react';
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import { FormattedMessage, injectIntl } from "react-intl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";

import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import Grid from '@material-ui/core/Grid';


const CheckSwap = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showCheckSwap = get(company, 'features', []).includes('check_swap');

  return (
    <React.Fragment>
      {showCheckSwap && <React.Fragment>
        <div>
          <>

            <Grid container>


              {query.hasCheckSwap && (<React.Fragment>
                <div className={classes.header}><FormattedMessage id="calculators.cfa.check_swap" /></div>
                <Grid item xs={12} style={{padding: 0, margin:0}} />
                <Grid item style={{ paddingTop: 0,}}>
                  <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_swap_year" /></b>

                  <select value={query.checkSwapYear} onChange={e => {
                    updateCfaQuery({ ...query, checkSwapYear: parseInt(e.target.value) })
                  }
                  }>
                    <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                    {range(1, query.cashFlowLength, 1).map(year =>
                      <option value={year} checked={query.checkSwapYear == year}>{year}</option>
                    )}
                  </select>

                </Grid>
                <Grid item>
                  <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_annual_bonus" /></b>
                  <CurrencyInput
                    className={classes.currency}
                    value={query.checkSwapAnnualBonus}
                    onValueChange={value => {
                      updateCfaQuery({ ...query, checkSwapAnnualBonus: parseFloat(value) })
                    }}
                  />
                </Grid>
                <Grid item>
                  <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_loan_end_year" /></b>

                  <select value={query.checkSwapLoanEndYear} onChange={e => {
                    updateCfaQuery({ ...query, checkSwapLoanEndYear: parseInt(e.target.value) })
                  }
                  }>
                    <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                    {range(1, query.cashFlowLength, 1).map(year =>
                      <option value={year} checked={query.checkSwapLoanEndYear == year}>{year}</option>
                    )}
                  </select>

                </Grid>
                <Grid item>
                  <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_repayment_amount" /></b>
                  <CurrencyInput
                    className={classes.currency}
                    value={query.checkSwapLoanRepaymentAmount}
                    onValueChange={value => {
                      updateCfaQuery({ ...query, checkSwapLoanRepaymentAmount: parseFloat(value) })
                    }}
                  />
                </Grid>
              </React.Fragment>
              )}
            </Grid>
          </>
        </div>
      </React.Fragment>}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(CheckSwap));