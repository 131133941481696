import gql from 'graphql-tag';

export const createDealMutation = gql`
  mutation createDeal(
    $inventory_user_id: ID
    $owner_id: ID
    $number_of_invites: Int
    $invitation_deadline: String
    $headline: String
    $deal_type: String
  ) {
    createDeal(
      inventory_user_id: $inventory_user_id
      owner_id: $owner_id
      number_of_invites: $number_of_invites
      invitation_deadline: $invitation_deadline
      headline: $headline
      deal_type: $deal_type
    ) {
      id
    }
  }
`;

export const OpenDealsQuery = gql`
  query getOpenDeals($userID: ID) {
    getOpenDeals(userID: $userID) {
      id
      name
      status
      description
      dueDate
      closed
      owner {
        id
        name
        __typename
      }
      pipelineCard {
        id
        name
        pipeline {
          id
          __typename
        }
        __typename
      }
    }
  }
`;

export const UpdateDealMutation = gql`
  mutation updateDeal(
    $id: ID!
    $headline: String
    $sub_headline: String
    $summary_of_business: String
    $ideal_candidate: String
    $growth_opportunities: String
    $assets_included: String
    $owner_id: ID
    $intangible_assets_included: String
    $customer_demographics_comments: String
    $products_and_services_comments: String
    $number_of_invites: Int
    $list_price: Int
    $invitation_deadline: String
    $deal_state: String
    $deal_interest_users: [DealInterestInput!]
    $approval_status: Boolean
    $question_1: String
    $question_2: String
    $question_3: String
    $question_4: String
    $question_5: String
    $deal_type: String
  ) {
    updateDeal(
      id: $id
      headline: $headline
      sub_headline: $sub_headline
      summary_of_business: $summary_of_business
      ideal_candidate: $ideal_candidate
      growth_opportunities: $growth_opportunities
      assets_included: $assets_included
      intangible_assets_included: $intangible_assets_included
      customer_demographics_comments: $customer_demographics_comments
      products_and_services_comments: $products_and_services_comments
      invitation_deadline: $invitation_deadline
      number_of_invites: $number_of_invites
      deal_state: $deal_state
      list_price: $list_price
      deal_interest_users: $deal_interest_users
      approval_status: $approval_status
      question_1: $question_1
      question_2: $question_2
      question_3: $question_3
      question_4: $question_4
      question_5: $question_5
      deal_type: $deal_type
      owner_id: $owner_id
    )
  }
`;
