import gql from 'graphql-tag';

export const createDocumentMutation = gql`
  mutation createDocument(
    $name: String
    $category: String
    $file: File!
    $pipeline_card_id: ID
    $task_id: ID
    $inventory_user_id: ID
    $deal_interest_id: ID
    $continuity_builder_id: ID
    $document_type: Int
  ) {
    createDocument(
      name: $name
      category: $category
      file: $file
      pipeline_card_id: $pipeline_card_id
      task_id: $task_id
      inventory_user_id: $inventory_user_id
      deal_interest_id: $deal_interest_id
      continuity_builder_id: $continuity_builder_id
      document_type: $document_type
    ) {
      id
      name
      category
      download_url
      uploaded_by {
        name
      }
      created_at
      document_type
    }
  }
`;

export const deleteDocumentMutation = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
`;
