import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import { ApolloConsumer } from 'react-apollo';
import MessageInfoBox from './MessageInfoBox';
import ViewedProfileBox from './ViewedProfileBox';
import ActivityInfoBox from './ActivityInfoBox';
import ClosedConversation from './ClosedConversation';
import ConversationContainer from '../../containers/ConversationContainer';
import { InventoryUserQuery } from '../InventoryUsers/InventoryUserQueries';
import { log } from 'util';
import gql from 'graphql-tag';

// export const ConversationQuery = gql`
//   query getConversation($senderId: ID, $receiverId: ID) {
//     getConversation(senderId: $senderId, receiverId: $receiverId) {
//       personalMessages {
//         sender {
//           id
//           name
//         }
//         receiver {
//           id
//           name
//         }
//         body
//       }
//     }
//   }
// `;

const createInitials = name => {
  if (name) {
    const splitName = name.split(' ').filter(Boolean);
    let initials = '';
    for (let i = 0; i < splitName.length; i += 1) {
      initials += splitName[i][0].toUpperCase();
    }
    return initials;
  }
};

const checkEmpty = properties => {
  const values = Object.values(properties || []);
  return values.every(x => x === null || x === '' || isEmpty(x) || x === 'AhoyEventProperty');
};

const formatDate = date => {
  const today = moment().format('YYYYMMDD');
  if (today === moment(date).format('YYYYMMDD')) {
    return moment(date).format('LT');
  }
  if (today - moment(date).format('YYYYMMDD') >= 1) {
    return `${moment(date).format('MMM D')} at ${moment(date).format('LT')}`;
  }
};

const nameClick = async (id, client, updateAction) => {
  const result = await client.query({
    query       : InventoryUserQuery,
    variables   : { id },
    fetchPolicy : 'network-only',
  });
  updateAction({
    user : get(result, 'data.getInventoryUser'),
  });
};

const clickInviteeName = invitee_iu_id => {
  window.open(`/admin/inventory_users?inv_user=${invitee_iu_id}`, '_self');
};

const returnActivity = (event, inventoryUser, currentUserId, classes, currentUserIsAdmin, updateAction) => {
  const initials = createInitials(inventoryUser.name);
  const firstName = inventoryUser.name.split(' ');
  if (event.name === 'note') {
    const notesInitials = createInitials(get(event, 'user.name', ''));
    let name = '';
    if (get(event, 'user.id') === currentUserId) {
      name = 'You';
    } else {
      name = get(event, 'user.name', '');
    }
    return (
      <ActivityInfoBox
        initials={notesInitials}
        date={formatDate(event.time)}
        activity={`${name} added a note`}
        note={<div dangerouslySetInnerHTML={{ __html: event.comment }} />}
      />
    );
  }
  if (event.name === 'Message Sent') {
    const notesInitials = createInitials(get(event, 'sender'));
    const senderId = get(event, 'properties.sender.id', '');
    const receiverId = get(event, 'properties.receiver.id');
    const convoId = get(event, 'properties.convo_id', '');
    let senderName = get(event, 'properties.sender.name', '');
    let receiverName = get(event, 'properties.receiver.name');
    senderName = senderId === currentUserId ? 'You' : senderName;
    receiverName = receiverId === currentUserId ? 'You' : receiverName;
    return (
      <ApolloConsumer>
        {client => (
          <div>
            <MessageInfoBox
              initials={notesInitials}
              date={formatDate(event.time)}
              activity={[
                <ConversationContainer senderId={senderId} receiverId={receiverId} client={client} convoId={convoId} />,
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => nameClick(event.properties.sender.inventory_user.id, client, updateAction)}>
                  {senderName}
                </button>,
                <span style={{ fontWeight: '300' }}> to </span>,
                <button
                  type="button"
                  className={classes.button}
                  onClick={() => nameClick(event.properties.receiver.inventory_user.id, client, updateAction)}>
                  {receiverName}
                </button>,
              ]}
              note={'Message'}
              event={event}
              currentUserIsAdmin={currentUserIsAdmin}
            />
          </div>
        )}
      </ApolloConsumer>
    );
  }
  if (event.name === 'closed_conversation') {
    const receiverName = get(event, 'properties.participant');
    return (
      <ApolloConsumer>
        {client => (
          <ClosedConversation
            firstName={firstName[0]}
            initials={initials}
            date={formatDate(event.time)}
            activity={[
              <i
                class="material-icons"
                style={{
                  fontSize  : '18px',
                  color     : '#808080',
                  marginTop : '5px',
                  float     : 'left',
                }}>
                cancel
              </i>,
              <button type="button" className={classes.button} onClick={() => nameClick(event.sender_iu_id, client)}>
                {firstName[0]}
              </button>,
              <span style={{ fontWeight: '300' }}> closed a conversation with </span>,
              <button type="button" className={classes.button} onClick={() => nameClick(event.receiver_iu_id, client)}>
                {receiverName}
              </button>,
            ]}
            event={event}
            currentUserIsAdmin={currentUserIsAdmin}
          />
        )}
      </ApolloConsumer>
    );
  }
  if (event.name === 'invite') {
    const notesInitials = createInitials(get(event, 'properties.from_name'));
    let name = '';
    if (get(event, 'user_id') === currentUserId) {
      name = 'You';
    } else {
      name = get(event, 'properties.from_name');
    }
    return (
      <div>
        {get(event, 'properties.invitee_iu_id') && (
          <ApolloConsumer>
            {client => (
              <ActivityInfoBox
                initials={notesInitials}
                date={formatDate(event.time)}
                activity={[
                  <i
                    class="material-icons"
                    style={{
                      fontSize  : '18px',
                      color     : '#008080',
                      marginTop : '5px',
                      float     : 'left',
                    }}>
                    contact_mail
                  </i>,
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => nameClick(get(event, 'properties.from_iu_id'), client, updateAction)}>
                    {get(event, 'properties.from_name')}
                  </button>,
                  <span style={{ fontWeight: '300' }}>invited</span>,
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => clickInviteeName(get(event, 'properties.invitee_iu_id'))}>
                    {get(event, 'properties.invitee_name')}
                  </button>,
                  <span style={{ fontWeight: '300' }}>to the marketplace</span>,
                  <br />,
                ]}
              />
            )}
          </ApolloConsumer>
        )}

        {!get(event, 'properties.invitee_iu_id') && (
          <ApolloConsumer>
            {client => (
              <ActivityInfoBox
                initials={notesInitials}
                date={formatDate(event.time)}
                activity={[
                  <i
                    class="material-icons"
                    style={{
                      fontSize  : '18px',
                      color     : '#008080',
                      marginTop : '5px',
                      float     : 'left',
                    }}>
                    contact_mail
                  </i>,
                  <button
                    className={classes.button}
                    type="button"
                    onClick={() => nameClick(get(event, 'properties.from_iu_id'), client)}>
                    {get(event, 'properties.from_name')}
                  </button>,
                  <span style={{ fontWeight: '300' }}>invited</span>,
                  <span style={{ marginLeft: 10 }}>{get(event, 'properties.invitee_name')}</span>,
                  <br />,
                  <span style={{ fontWeight: '300' }}> with email </span>,
                  <span style={{ marginLeft: '5' }}>{get(event, 'properties.invitee_email')}</span>,
                  <span style={{ fontWeight: '300' }}>to the marketplace from the Dashboard</span>,
                  <br />,
                ]}
              />
            )}
          </ApolloConsumer>
        )}
      </div>
    );
  }

  if (event.name === 'Profile View') {
    const viewedUserName = get(event, 'properties.viewed_user.name', '');
    const viewedUserId = get(event, 'properties.viewed_user.inventory_user.id', '');
    return (
      <ApolloConsumer>
        {client => (
          <ViewedProfileBox
            initials={initials}
            date={formatDate(event.time)}
            activity={[
              <i class="fa fa-user" aria-hidden="true" />,
              <span> {firstName[0]}</span>,
              <span style={{ fontWeight: '300' }}> viewed</span>,
              <button
                type="button"
                className={classes.button}
                onClick={() => nameClick(viewedUserId, client, updateAction)}>
                {viewedUserName}
              </button>,
              <span>'s profile</span>,
            ]}
            event={event}
          />
        )}
      </ApolloConsumer>
    );
  }

  if (get(event, 'properties.text') === 'Search Now' || checkEmpty(event.properties)) {
    return <div />;
  }
  if ((event.properties.text && event.properties.text.split(' ').length) > 5 && !event.properties.viewed_user) {
    return <div />;
  }
  return <ActivityInfoBox firstName={firstName[0]} initials={initials} date={formatDate(event.time)} event={event} />;
};

const ActivityTypes = ({ event, inventoryUser, currentUserId, classes, currentUserIsAdmin, updateAction }) => {
  return returnActivity(event, inventoryUser, currentUserId, classes, currentUserIsAdmin, updateAction);
};

ActivityTypes.propTypes = {
  classes            : PropTypes.object.isRequired,
  event              : PropTypes.object.isRequired,
  firstName          : PropTypes.string.isRequired,
  initials           : PropTypes.string.isRequired,
  date               : PropTypes.string.isRequired,
  note               : PropTypes.string.isRequired,
  activity           : PropTypes.string.isRequired,
  currentUserIsAdmin : PropTypes.bool.isRequired,
};

const styles = {
  button : {
    color      : '#0052CC',
    background : 'none',
    border     : 'none',
  },
};

export default withStyles(styles)(ActivityTypes);
