import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';
import InputSlider from '../../InputSlider/InputSlider';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import { debounce } from 'lodash';

class PAndCProductMixSliders extends React.Component {
    constructor(props) {
        super(props);
        const { home_ifp, commercial_ifp, private_products_ifp, crop_hail_with_wind_ifp, life_ifp, auto_ifp, mcpi_ifp, crop_hail_ifp, farm_insurance_ifp, replant_supplement_ifp, health_ifp } = this.props.user.product_mix;
        this.state = {
            home_ifp                  : home_ifp,
            commercial_ifp            : commercial_ifp,
            private_products_ifp      : private_products_ifp,
            crop_hail_with_wind_ifp   : crop_hail_with_wind_ifp,
            life_ifp                  : life_ifp,
            auto_ifp                  : auto_ifp,
            mcpi_ifp                  : mcpi_ifp,
            crop_hail_ifp             : crop_hail_ifp,
            farm_insurance_ifp        : farm_insurance_ifp,
            replant_supplement_ifp    : replant_supplement_ifp,
            health_ifp                : health_ifp,
            home_ifp_max              : home_ifp,
            commercial_ifp_max        : commercial_ifp,
            private_products_ifp_max  : private_products_ifp,
            crop_hail_with_wind_ifp_max : crop_hail_with_wind_ifp,
            life_ifp_max              : life_ifp,
            auto_ifp_max              : auto_ifp,
            mcpi_ifp_max              : mcpi_ifp,
            crop_hail_ifp_max         : crop_hail_ifp,
            farm_insurance_ifp_max    : farm_insurance_ifp,
            replant_supplement_ifp_max: replant_supplement_ifp,
            health_ifp_max            : health_ifp,
        };
    }

    percentValuesAreGood = () => {
        const { home_ifp, commercial_ifp, private_products_ifp, crop_hail_with_wind_ifp, life_ifp, auto_ifp, mcpi_ifp, crop_hail_ifp, farm_insurance_ifp, replant_supplement_ifp, health_ifp } = this.state;
        if (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp <= 100) {
            return true;
        }
    };

    calcValues = () => {
        const { user } = this.props;
        const { home_ifp, commercial_ifp, private_products_ifp, crop_hail_with_wind_ifp, life_ifp, auto_ifp, mcpi_ifp, crop_hail_ifp, farm_insurance_ifp, replant_supplement_ifp, health_ifp } = this.state;
        if (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp <= 100) {
            this.setState({ home_ifp_max: 100 - (commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ commercial_ifp_max: 100 - (home_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ private_products_ifp_max: 100 - (home_ifp + commercial_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ crop_hail_with_wind_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ life_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ auto_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ mcpi_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ crop_hail_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ farm_insurance_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + replant_supplement_ifp + health_ifp) });
            this.setState({ replant_supplement_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + health_ifp) });
            this.setState({ health_ifp_max: 100 - (home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp) });
        }
    };

    debouncedUpdateAction = debounce(e => {
        const { updateAction } = this.props;
        updateAction(e);
    }, 1000);

    handleSliderChange = e => {
        this.setState(e);
        this.debouncedUpdateAction(e);
    };

    render() {
        const { classes, intl, user, theme } = this.props;
        const { 
            home_ifp, 
            commercial_ifp, 
            private_products_ifp, 
            crop_hail_with_wind_ifp, 
            life_ifp, 
            auto_ifp, 
            mcpi_ifp, 
            crop_hail_ifp, 
            farm_insurance_ifp, 
            replant_supplement_ifp, 
            health_ifp,
            home_ifp_max,
            commercial_ifp_max,
            private_products_ifp_max,
            crop_hail_with_wind_ifp_max,
            life_ifp_max,
            auto_ifp_max,
            mcpi_ifp_max,
            crop_hail_ifp_max,
            farm_insurance_ifp_max,
            replant_supplement_ifp_max,
            health_ifp_max,
        } = this.state;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.home_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                <RangeSliderContainer theme={theme}>
                  <InputSlider
                    maxValue={100}
                    minValue={0}
                    value={home_ifp <= home_ifp_max ? home_ifp : home_ifp_max}
                    formatLabel={value => ``}
                    step={1}
                    onChange={v => {
                      this.handleSliderChange({ home_ifp: v <= home_ifp_max ? v : home_ifp_max });
                      this.calcValues();
                    }}
                  />
                </RangeSliderContainer>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <div style={{ marginLeft: 10, marginTop: 25 }}>{home_ifp}{intl.locale == 'fr' && ' '} %</div>
              </Grid>
              <Grid item xs={12}>
                <span><FormattedMessage id="profile_page.commercial_ifp" /></span>
              </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={commercial_ifp <= commercial_ifp_max ? commercial_ifp : commercial_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ commercial_ifp: v <= commercial_ifp_max ? v : commercial_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{commercial_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid> 
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.private_products_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={private_products_ifp <= private_products_ifp_max ? private_products_ifp : private_products_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ private_products_ifp: v <= private_products_ifp_max ? v : private_products_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{private_products_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.crop_hail_with_wind_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={crop_hail_with_wind_ifp <= crop_hail_with_wind_ifp_max ? crop_hail_with_wind_ifp : crop_hail_with_wind_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ crop_hail_with_wind_ifp: v <= crop_hail_with_wind_ifp_max ? v : crop_hail_with_wind_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{crop_hail_with_wind_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.life_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={life_ifp <= life_ifp_max ? life_ifp : life_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ life_ifp: v <= life_ifp_max ? v : life_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{life_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.auto_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={auto_ifp <= auto_ifp_max ? auto_ifp : auto_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ auto_ifp: v <= auto_ifp_max ? v : auto_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{auto_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.mcpi_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={mcpi_ifp <= mcpi_ifp_max ? mcpi_ifp : mcpi_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ mcpi_ifp: v <= mcpi_ifp_max ? v : mcpi_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{mcpi_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.crop_hail_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={crop_hail_ifp <= crop_hail_ifp_max ? crop_hail_ifp : crop_hail_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ crop_hail_ifp: v <= crop_hail_ifp_max ? v : crop_hail_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{crop_hail_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.farm_insurance_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                            maxValue={100}
                            minValue={0}
                            value={farm_insurance_ifp <= farm_insurance_ifp_max ? farm_insurance_ifp : farm_insurance_ifp_max}
                            formatLabel={value => ``}
                            step={1}
                            onChange={v => {
                            this.handleSliderChange({ farm_insurance_ifp: v <= farm_insurance_ifp_max ? v : farm_insurance_ifp_max });
                            this.calcValues();
                            }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{farm_insurance_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.replant_supplement_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                        maxValue={100}
                        minValue={0}
                        value={replant_supplement_ifp <= replant_supplement_ifp_max ? replant_supplement_ifp : replant_supplement_ifp_max}
                        formatLabel={value => ``}
                        step={1}
                        onChange={v => {
                            this.handleSliderChange({ replant_supplement_ifp: v <= replant_supplement_ifp_max ? v : replant_supplement_ifp_max });
                            this.calcValues();
                        }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{replant_supplement_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={12}>
                    <span><FormattedMessage id="profile_page.health_ifp" /></span>
                </Grid>
                <Grid item xs={7}>
                    <RangeSliderContainer theme={theme}>
                        <InputSlider
                        maxValue={100}
                        minValue={0}
                        value={health_ifp <= health_ifp_max ? health_ifp : health_ifp_max}
                        formatLabel={value => ``}
                        step={1}
                        onChange={v => {
                            this.handleSliderChange({ health_ifp: v <= health_ifp_max ? v : health_ifp_max });
                            this.calcValues();
                        }}
                        />
                    </RangeSliderContainer>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <div style={{ marginLeft: 10, marginTop: 25 }}>{health_ifp}{intl.locale == 'fr' && ' '} %</div>
                </Grid>
                <Grid item xs={7}>
                <div style={{ marginLeft: 10, marginTop: 25 }}><FormattedMessage id="shared.total" /></div>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <div style={{ marginLeft: 10, marginTop: 25 }}>
                    {home_ifp + commercial_ifp + private_products_ifp + crop_hail_with_wind_ifp + life_ifp + auto_ifp + mcpi_ifp + crop_hail_ifp + farm_insurance_ifp + replant_supplement_ifp + health_ifp}{intl.locale == 'fr' && ' '} %
                </div>
            </Grid>
            <div style={{ marginBottom: 100 }} />
        </Grid>
    );
    }
}

PAndCProductMixSliders.propTypes = {
    mutate      : PropTypes.func.isRequired,
    currentUser : PropTypes.object.isRequired,
};

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${props.theme.palette.accent.main} !important;
        border: 1px solid ${props.theme.palette.accent.main} !important;
      }

      .input-range__track--active {
        background: ${props.theme.palette.accent.main};
      }
    `};
`;

const mapStateToProps = state => ({
    user : {
        ...state.user,
        product_mix : {
            home_ifp         : state.user.product_mix.home_ifp || 0,
            commercial_ifp   : state.user.product_mix.commercial_ifp || 0,
            private_products_ifp : state.user.product_mix.private_products_ifp || 0,
            crop_hail_with_wind_ifp : state.user.product_mix.crop_hail_with_wind_ifp || 0,
            life_ifp         : state.user.product_mix.life_ifp || 0,
            auto_ifp         : state.user.product_mix.auto_ifp || 0,
            mcpi_ifp         : state.user.product_mix.mcpi_ifp || 0,
            crop_hail_ifp    : state.user.product_mix.crop_hail_ifp || 0,
            farm_insurance_ifp : state.user.product_mix.farm_insurance_ifp || 0,
            replant_supplement_ifp : state.user.product_mix.replant_supplement_ifp || 0,
            health_ifp       : state.user.product_mix.health_ifp || 0,
        },
    },
});

export default withTheme(connect(mapStateToProps, {})(injectIntl(PAndCProductMixSliders)));