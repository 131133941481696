import React from 'react';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { startCase, get, toUpper } from 'lodash';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { bgColorForName } from '../../lib/findBobUtils';
import Avatar from '@material-ui/core/Avatar';
import initials from 'initials';
import { S3Image } from '../../lib/s3';

const DeleteBench = gql`
  mutation deleteBench($inventory_user_id: Int!, $nominee_iu_id: Int!) {
    deleteBench(inventory_user_id: $inventory_user_id, nominee_iu_id: $nominee_iu_id)
  }
`;

class TalentThumbnail extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      openWarning : false,
    };
  }

  handleClickOpen = () => {
    this.setState({ openWarning: true });
  };

  handleClose = () => {
    this.setState({ openWarning: false });
  };

  deleteBench = nominee => {
    const { mutate, inventoryUser, loadBenchUsers, updateInventoryUser } = this.props;
    mutate({
      variables : {
        inventory_user_id : parseInt(inventoryUser.id, 10),
        nominee_iu_id     : parseInt(nominee.id, 10),
      },
    })
      .then(() => {
        if (inventoryUser.bench) {
          const newBench = inventoryUser.bench.filter(t => t.nominee_iu.id !== nominee.id);
          loadBenchUsers(newBench);
          updateInventoryUser({ ...inventoryUser, bench_count: inventoryUser.bench.length - 1 });
        }
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log(this.state.error);
      });
  };

  colourReady = () => {
    const { ready_in } = this.props;
    if (ready_in) {
      if (ready_in === 'ready') {
        return <div style={{ color: 'limeGreen' }}>Ready</div>;
      }
      if (ready_in === '1-2 years') {
        return <div style={{ color: 'orange' }}>1-2 Years</div>;
      }
      if (ready_in === '3+ years') {
        return <div style={{ color: 'blue' }}>3+ Years</div>;
      }
    }
    return <div />;
  };

  render () {
    const { inventoryUser, user, classes, createBench, bench = false, showSelect = false, nominate_as } = this.props;
    const { openWarning } = this.state;
    const nomination = startCase(nominate_as) || '';
    let benchType;
    if (bench) {
      benchType = (
        <Card className={classes.card}>
          <Grid container alignItems="center" justify="center" spacing={0}>
            <Grid item xs={4}>
              {get(user, 'has_user') && (
                <CardMedia
                  component="img"
                  alt={get(user, 'name')}
                  image={get(user, 'has_user') ? S3Image(user.profile_picture) : null}
                  title={get(user, 'name')}
                  style={{
                    height          : 'auto',
                    objectFit       : 'cover',
                    objectPosition  : '0 0',
                    maxHeight       : '107px',
                    maxWidth        : '100%',
                    margin          : 'auto',
                    transformOrigin : 'top',
                  }}
                />
              )}
              {!get(user, 'has_user') && (
                <Avatar
                  style={{
                    backgroundColor : bgColorForName(user.name),
                    height          : '107px',
                    width           : 'auto',
                    marginTop       : 'auto',
                    textAlign       : 'center',
                    borderRadius    : '0',
                  }}>
                  <span className={classes.avatarText}>{toUpper(initials(user.name))}</span>
                </Avatar>
              )}
            </Grid>
            <Grid item xs={4}>
              <div className={classes.nameSection}>{user.name}</div>
            </Grid>
            <Grid item xs={4}>
              {showSelect && (
                <Button variant="contained" color="primary" className={classes.button} onClick={createBench}>
                  Select
                </Button>
              )}
              {!showSelect && (
                <div>
                  <div className={classes.close}>
                    <DeleteForeverIcon
                      fontSize="small"
                      onClick={() => {
                        this.handleClickOpen();
                      }}
                    />
                  </div>
                  <div className={classes.nameSection}>
                    <b>{nomination}</b>
                    <br />
                    {this.colourReady()}
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
          <Dialog
            open={openWarning}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Warning:</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove <b>{user.name}</b> from <b>{inventoryUser.name}</b>&apos;s bench?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() => {
                  this.deleteBench(user);
                  this.handleClose();
                }}
                variant="contained"
                color="primary"
                style={{ float: 'right', marginRight: 15 }}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      );
    } else {
      benchType = (
        <div>
          <Grid container alignItems="center" justify="center" spacing={0}>
            <Grid item xs={4}>
              {get(user, 'has_user') && (
                <CardMedia
                  component="img"
                  alt={get(user, 'name')}
                  image={get(user, 'has_user') ? S3Image(user.profile_picture) : null}
                  title={get(user, 'name')}
                  style={{
                    height          : 'auto',
                    objectFit       : 'cover',
                    objectPosition  : '0 0',
                    maxHeight       : '107px',
                    maxWidth        : '100%',
                    margin          : 'auto',
                    transformOrigin : 'top',
                  }}
                />
              )}
              {!get(user, 'has_user') && (
                <Avatar
                  style={{
                    backgroundColor : bgColorForName(user.name),
                    height          : '107px',
                    width           : 'auto',
                    marginTop       : 'auto',
                    textAlign       : 'center',
                    borderRadius    : '0',
                  }}>
                  <span className={classes.avatarText}>{toUpper(initials(user.name))}</span>
                </Avatar>
              )}
            </Grid>
            <Grid item xs={8}>
              <div className={classes.nameSection}>
                <b>{user.name}</b>
              </div>
            </Grid>
          </Grid>
        </div>
      );
    }

    return benchType;
  }
}

TalentThumbnail.propTypes = {
  mutate              : PropTypes.func.isRequired,
  loadBenchUsers      : PropTypes.func.isRequired,
  createBench         : PropTypes.func.isRequired,
  updateInventoryUser : PropTypes.func.isRequired,
  ready_in            : PropTypes.string.isRequired,
  nominate_as         : PropTypes.string.isRequired,
  classes             : PropTypes.object.isRequired,
  user                : PropTypes.object.isRequired,
  inventoryUser       : PropTypes.object.isRequired,
  bench               : PropTypes.bool,
  showSelect          : PropTypes.bool,
};

TalentThumbnail.defaultProps = {
  bench      : false,
  showSelect : false,
};

const styles = {
  card        : {
    width     : '100%',
    height    : 107,
    boxShadow : '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  content     : {
    width : '100%',
    float : 'right',
  },
  profile     : {
    width : 151,
  },
  button      : {
    width : '75%',
  },
  text        : {
    marginTop : '50%',
  },
  nameSection : {
    width     : '100%',
    textAlign : 'center',
  },
  img         : {
    margin    : 'auto',
    display   : 'block',
    maxWidth  : '100%',
    maxHeight : '100%',
  },
  close       : {
    textAlign   : 'right',
    color       : 'black',
    marginRight : '5px',
    marginTop   : '-20px',
  },
  avatar      : {
    borderRadius : 0,
  },
  avatarText  : {
    fontSize : 30,
    color    : 'white',
  },
};

export default withStyles(styles)(graphql(DeleteBench)(TalentThumbnail));
