import React from 'react';
import PropTypes from 'prop-types';
import './CandidateTab.css';
import { get, startCase } from 'lodash';

const contactInfoFields = [
  'email',
  'birth_date',
  'postal_code',
  'phone',
  'new_in_industry',
]

const practiceInfoFields = [
  'annual_revenue',
  'year_career_began',
  'years_practicing',
  'number_of_clients',
  'avg_client_age',
  'recurring_revenue',
  'percent_commission',
  'percent_fee_based',
];

export const CandidateTab = ({ card }) => {
  const { candidate } = card;
  return (
    <div className="CandidateTab__container">
      <h4>{get(candidate, 'name')}</h4>
      <h5>Contact Information</h5>
      <div className="CandidateTab__container">
        <dl>
        {contactInfoFields.map(f => <>
          <dt>{startCase(f)}</dt>
          <dd>{get(candidate, f )}&nbsp;</dd>
          </>)}
        </dl>
      </div>

      {!get(candidate, 'new_in_industry') &&
      <div>
        <h5>Practice Information</h5>
        <div className="CandidateTab__container">
          <dl>
          {practiceInfoFields.map(f => <>
              <dt>{startCase(f)}</dt>
              <dd>{get(candidate, f )}</dd>
            </>)}
            <dt>Transition Goals</dt>
            <dd>{get(candidate, 'candidate_goals', []).join(', ')}</dd>
          </dl>
        </div>
      </div>
      }

      {!get(candidate, 'new_in_industry') &&
      <div>
      <h5>Strengths</h5>
      <div className="CandidateTab__container">
        <dl>
          <dt>Designations</dt>
          <dd>{get(candidate, 'candidate_designations', []).join(', ')}</dd>
          <dt>Strengths</dt>
          <dd>{get(candidate, 'tag_list', []).map(t => startCase(t)).join(', ')}</dd>
        </dl>
      </div>
      </div>}
      <h5>Description</h5>
      <div className="CandidateTab__container">
        <p>
          {get(candidate, 'description', '')}
        </p>
      </div>

      {get(candidate, 'candidate_profile.family_background') &&
      <div>
      <h5>Candidate Profile</h5>
      <div className="CandidateTab__container">
        <dl>
          <dt>Family Background</dt>
          <p><br/>{get(candidate, 'candidate_profile.family_background', '')}&nbsp;</p>
        </dl>
        <dl>
          <dt>Educational Background</dt>
          <p><br/>{get(candidate, 'candidate_profile.educational_background', '')}&nbsp;</p>
        </dl>
        <dl>
          <dt>Occupational Background</dt>
          <p><br/>{get(candidate, 'candidate_profile.occupational_background', '')}&nbsp;</p>
        </dl>
        <dl>
          <dt>Professional Clubs</dt>

          <p><br/>{get(candidate, 'candidate_profile.professional_clubs', '')}&nbsp;</p>
        </dl>
        <dl>
          <dt>Areas Lived</dt>
          <p><br/>{get(candidate, 'candidate_profile.areas_lived', '')}&nbsp;</p>
        </dl>
      </div>
      </div>
      }
    </div>
  );
};

CandidateTab.propTypes = {
  card : PropTypes.object,
};

CandidateTab.defaultProps = {
  card : {},
};

export default CandidateTab;
