import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, replace } from 'lodash';
import CheckButton from '../../CheckButton/CheckButton';
import InputSlider from '../../InputSlider/InputSlider';
import { S3Image } from '../../../lib/s3';
import { spotlightElement } from '../../../lib/findBobUtils';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { uniq, toLower, capitalize, snakeCase } from 'lodash';

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

const Strengths = ({
  professionalStrengths,
  toggleStrengths,
  intl,
  availableStrengths,
  locale,
  stylesheetName,
  fromProfile,
}) => {
  let managementCategories = [ ...availableStrengths ];

  professionalStrengths.forEach(strength => {
    managementCategories.push(strength);
  });

  const labels = {};
  availableStrengths.map(s => {
    labels[s] = capitalize(intl.formatMessage({ id: `sign_up_pages.${s}` }).replace('_', ' '));
    if (labels.pc_insurance) {
      labels.pc_insurance = 'P&C insrurance';
    }
    if (labels.partnerships_with_cois) {
      labels.partnerships_with_cois = <FormattedMessage id="sign_up_pages.partnerships_with_cois"/>;
    }
  });

  managementCategories = uniq(managementCategories.map(c => snakeCase(c)));
  if (locale === 'fr') {
    return (
      <div onMouseOver={e => spotlightElement('.the-tag-list')}>
        <div>
          <br />
          <br />
          {managementCategories.map(category => (
            <CheckButton
              key={category}
              stylesheetName={stylesheetName}
              label={labels[category]}
              checked={findIndex(professionalStrengths, o => snakeCase(o) === category) > -1}
              value={snakeCase(intl.formatMessage({ id: `sign_up_pages.${category}` }))}
              onClick={toggleStrengths}
            />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div onMouseOver={e => spotlightElement('.the-tag-list')}>
      <div>
        <br />
        <br />
        {managementCategories.map(category => {
          const checked = findIndex(professionalStrengths, o => snakeCase(o) === category) > -1;
          return (
            <CheckButton
              key={category}
              stylesheetName={stylesheetName}
              label={labels[category] || capitalize(category).replace('_',' ')}
              checked={checked}
              value={snakeCase(category)}
              onClick={toggleStrengths}
              notAllowed={!fromProfile && (!checked && professionalStrengths.length > 4)}
              disabled={!fromProfile && (!checked && professionalStrengths.length > 4)}
            />
          );
        })}
      </div>
    </div>
  );
};

Strengths.propTypes = {
  updateAction : PropTypes.func,
};

Strengths.defaultProps = {
  number_of_clients : 500,
};

export default injectIntl(withStyles(styles)(Strengths));
