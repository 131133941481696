import { SUCCESSION_READINESS_UPDATE, SUCCESSION_READINESS_REFETCH } from '../constants/successionReadinessConstants';

const successionReadinessReducer = (
  state = {
    successionReadiness: {},
    refetch: () => {},
  },
  action
) => {
  switch (action.type) {
    case SUCCESSION_READINESS_UPDATE:
      return { ...state, successionReadiness: action.succesionReadiness, };
    case SUCCESSION_READINESS_REFETCH:
      return { ...state, refetch: action.refetchFunc, };
    default:
      return state;
  }
};

export default successionReadinessReducer;
