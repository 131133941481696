import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withSnackbar } from 'notistack';
import { get } from 'lodash';

const SendSeal = gql`
  mutation builderIssueSeal($id: ID!) {
    builderIssueSeal(id: $id)
  }
`;

export const ContinuitySealButton = ({ continuityBuilderId, enqueueSnackbar, updateCard, card, pipeline }) => {
  const [ open, setOpen ] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSend = ({ mutation }) => {
    mutation({ variables: { id: continuityBuilderId } })
      .then(response => {
        const stage = get(pipeline, 'pipeline_stages', {}).filter(p => p.name === 'Approved')[0];
        updateCard(card.id, stage, 1);

        // Do something like populate a spotlight
        enqueueSnackbar('Seal has been issued.', {
          variant      : 'success',
          anchorOrigin : {
            vertical   : 'top',
            horizontal : 'center',
          },
        });
        handleClose();
      })
      .error(e => {
        enqueueSnackbar('Failed to issue seal. Please contact administrator.', {
          variant      : 'error',
          anchorOrigin : {
            vertical   : 'top',
            horizontal : 'center',
          },
        });
      });
  };

  return (
    <Mutation mutation={SendSeal}>
      {issueSeal => (
        <div>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Issue Verification Seal
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Issue Verification Seal?'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By clicking issue the user will receive an email indicating that their continuity plan has been verified
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button onClick={() => handleSend({ mutation: issueSeal })} color="primary" autoFocus>
                Issue Seal Now
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </Mutation>
  );
};

export default withSnackbar(ContinuitySealButton);
