import { connect } from 'react-redux';
import TodoItem from '../components/TodoItem/TodoItem';
import NewFormDialog from '../components/TodoItem/TodoItemNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import crudActionCreators from '../actions/crudActionCreators';
import { bindActionCreators } from 'redux';

let actions = {};



const mapStateToProps = (state, props) => {


  return ({ ...state,
    listData: state.todo_items,
    title: 'Todo Items',
    NewFormDialog,
    CrudRow: TodoItem,
  }
 )};

const mapDispatchToProps = (dispatch, props) => {
  let cac = {};
  cac = new crudActionCreators('todo_item', props.location.pathname.replace(/\/$/, ''));
  return bindActionCreators({
    loadAction: cac.loadAction,
    addAction: cac.addAction,
    deleteAction: cac.deleteAction,
    putAction: cac.putAction,
    updateAction: cac.updateAction,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CrudTable);
