import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useMutation } from "react-apollo";
import { get } from 'lodash';
import { gql } from "graphql-tag";
import numeral from 'numeral';
import { Flip, FlipToBack, FlipToFront } from '@material-ui/icons';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export const TOGGLE_PARTIAL_SALE_INTEREST = gql`
  mutation expressInterestForPartialSale($partialSaleId: ID!, $requestMessage: String) {
    expressInterestForPartialSale(partialSaleId: $partialSaleId, requestMessage: $requestMessage) {
      id
    }
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '48%',
    maxWidth: '48%',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    borderRadius: '5px',
    float: 'left',
    margin: '1%',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  pos: {
    marginBottom: 12,
    fontSize: 13,
    fontWeight: 'normal',
  },
  actions: {
    alignContent: 'space-around',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: '40%',
    top: '25%',
  },
  flipIcon: {
    float: 'right',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const PartialSaleBanner = ({ user, company }) => {
  const [expressInterestForPartialSale, { data, loading, error }] = useMutation(TOGGLE_PARTIAL_SALE_INTEREST);
  const [open, setOpen] = React.useState(false);
  const [partialSales, setPartialSales] = React.useState(user.open_partial_sale_listings);
  const [requestMessage, setRequestMessage] = React.useState('');
  const [showFront, setShowFront] = React.useState(partialSales.map(() => true));

  const partialSalesPropertyCasualty = company?.feature_types?.includes('partial_sales_property_casualty') || false;

  const toggleShowFront = (index) => {
    const newShowFront = showFront.map((sf, i) => {
      if (i === index) {
        return !sf;
      }
      return sf;
    });
    setShowFront(newShowFront);
  }

  const classes = useStyles();
  if (!user.open_partial_sale_listings || user.open_partial_sale_listings.length === 0) {
    return <span />;
  }

  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <h3>
        {company.partial_sale_name} Listings
      </h3>
      <div className={classes.container}>
        {partialSales.map((partialSale) => {
          const partialSaleIndex = partialSales.indexOf(partialSale);
          return (

            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  {partialSale.segment_details}

                  {showFront ?
                    <FlipToBack
                      className={classes.flipIcon}
                      onClick={() => { toggleShowFront(partialSaleIndex) }} />
                    :
                    <FlipToFront
                      className={classes.flipIcon}
                      onClick={() => { toggleShowFront(partialSaleIndex) }} />
                  }
                </Typography>
                <Typography variant="h5" component="h2">
                </Typography>
                <TransitionGroup>
                  <CSSTransition
                    key={showFront[partialSaleIndex] ? 'front' : 'back'}
                    timeout={500}
                    classNames="flip"
                  >
                    {showFront[partialSaleIndex] ?
                      <div>
                        <Typography className={classes.pos} color="textSecondary">
                          <br />
                          <dl>
                            <dt>Clients:</dt>
                            <dd>{partialSale.number_of_clients}</dd>
                            <dt>{partialSalesPropertyCasualty ? "Recurring Commission Revenue" : "Fee Based Revenue"}:</dt>
                            <dd>{numeral(partialSale.fee_based_revenue).format("$0,0")}</dd>
                            {!partialSalesPropertyCasualty && (
                              <>
                                <dt>Trails Revenue:</dt>
                                <dd>{numeral(partialSale.trails_revenue).format("$0,0")}</dd>
                                <dt>Direct Revenue:</dt>
                                <dd>{numeral(partialSale.direct_revenue).format("$0,0")}</dd>
                              </>
                            )}
                            <dt>{partialSalesPropertyCasualty ? "New Commission Revenue" : "Commission Revenue"}:</dt>
                            <dd>{numeral(partialSale.commission_revenue).format("$0,0")}</dd>
                            <dt>{partialSalesPropertyCasualty ? "Total Premium" : "AUM"}:</dt>
                            <dd>{numeral(partialSale.assets_under_management).format("$0,0")}</dd>
                            <dt>Asking Price:</dt>
                            <dd>{partialSale.asking_price === '0.0' ? 'Negotiable' : numeral(partialSale.asking_price).format('$0,0.00')}</dd>
                          </dl>
                        </Typography>
                        <Typography variant="body2" component="p">
                          {partialSale.description}
                          <br />
                        </Typography>
                      </div>
                      :
                      <div>
                        <Typography className={classes.pos} color="textSecondary">
                          <br />
                          <b>More Details:</b>
                          <div>
                            {(partialSale.questions || []).map((question, index) => (
                              <p>
                                <b>{question}</b><br />
                                {get(partialSale, `answers[${index}]`, false) ? 'Yes' : 'No'}
                              </p>
                            ))}
                            <p>
                              <b>States and Provinces</b><br />
                              {(partialSale.states || []).join(', ')}
                            </p>
                          </div>
                        </Typography>
                      </div>}
                  </CSSTransition>
                </TransitionGroup>
              </CardContent>
              <CardActions className={classes.actions}>
                <center>
                  {partialSale.current_user_is_an_interest
                    ?
                    <Button disabled variant="contained">Already Expressed Interest</Button>
                    :
                    <div>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >I'm Interested
                      </Button>
                      <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                      >
                        <div className={classes.paper}>
                          Please indicate to the seller why you are interested in this opportunity below.
                          <br />
                          <TextField
                            id="sale-closed-reason"
                            label="Send a Request Message to the Seller"
                            multiline
                            rows={4}
                            variant="outlined"
                            onChange={e => setRequestMessage(e.target.value)}
                            value={requestMessage}
                            fullWidth
                          />
                          <br />
                          <Button
                            disabled={!Boolean(requestMessage)}
                            onClick={() => {
                              expressInterestForPartialSale({ variables: { partialSaleId: partialSale.friendly_name, requestMessage: requestMessage } }).then(() => {
                                // setPartialSales({...partialSale, sale_status, reason_for_close});
                                const ps = partialSales.map((ps) => {
                                  if (ps.friendly_name === partialSale.friendly_name) {
                                    return { ...ps, current_user_is_an_interest: true };
                                  }
                                  return ps;
                                });
                                setPartialSales([ ...ps ])
                                setRequestMessage('');
                                setOpen(false);
                              });
                            }}>
                            Send
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  }
                </center>
              </CardActions>

            </Card>

          )
        })}
      </div>
    </div>
  );
}

export default PartialSaleBanner;