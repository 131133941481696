import React from 'react';

class ErrorHandler extends React.Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true });
  }

  render () {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <h4>
          <br />
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There currently is no data to show for this report.</p>
        </h4>
      );
    }
    return this.props.children;
  }
}

export default ErrorHandler;
