import React from 'react';
import PropTypes from 'prop-types';
import { get, capitalize, isEmpty, groupBy } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import numeral from 'numeral';
import moment from 'moment';
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryGroup,
} from 'victory';

const colorScale = [
  '#337ab7',
  'rgb(0, 121, 107)',
  'silver',
  'rgb(25, 245, 157)',
  '#FF8A65',
  '#FFF176',
  '#AFB42B',
  '#BA68C8',
  '#BCAAA4',
  '#004D40',
];

class MailerReport extends React.Component {
  state = {
    yAxis     : 'count',
    showRange : 'year',
    dateRange : 'all_time',
    mailer    : 'all',
  };

  months = [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ];

  quarters = [ 'Q1', 'Q2', 'Q3', 'Q4' ];

  year = moment().year;

  today = moment().format('MMM-DD');

  names = [ 'Sent', 'Opened', 'Clicked' ];

  weeks = () => {
    const num = [];
    for (let i = 1; i <= 52; i += 1) {
      const startDate = moment().week(i).startOf('week');
      const endDate = moment().week(i).endOf('week');
      num.push(`${startDate.format('MMM-DD')} - ${endDate.format('MMM-DD')}`);
    }
    return num;
  };

  singleMonth = () => {
    const { dateRange } = this.state;
    let month = moment().month();
    if (dateRange === 'this_month') {
      month = moment().month();
    }
    if (dateRange === 'last_month') {
      month = moment().subtract(1, 'month').month();
    }
    return [ this.months[month] ];
  };

  singleWeek = () => {
    const { dateRange } = this.state;
    let week = moment().week();
    if (dateRange === 'this_week') {
      week = moment().week();
    }
    if (dateRange === 'last_week') {
      week = moment().subtract(1, 'week').week();
    }
    return [ this.weeks()[week - 1] ];
  };

  singleQuarter = () => {
    const { dateRange } = this.state;
    let quarter = moment().quarter();
    if (dateRange === 'this_quarter') {
      quarter = moment().quarter();
    }
    if (dateRange === 'last_quarter') {
      quarter = moment().subtract(1, 'quarter').quarter();
    }
    return [ this.quarters[quarter - 1] ];
  };

  getShowType = () => {
    const { dateRange } = this.state;
    if (dateRange === 'this_month' || dateRange === 'last_month') {
      return 'singleMonth';
    }
    if (dateRange === 'this_week' || dateRange === 'last_week') {
      return 'singleWeek';
    }
    if (dateRange === 'this_quarter' || dateRange === 'last_quarter') {
      return 'singleQuarter';
    }
    if (dateRange === 'all_quarters') {
      return 'allQuarters';
    }
    if (dateRange === 'this_year' || dateRange === 'last_year') {
      return 'allMonths';
    }
    if (dateRange === 'all_time') {
      return 'allTime';
    }
    if (dateRange === 'today') {
      return 'today';
    }
    return 'allTime';
  };

  setChartType = (
    finalChartData,
    finalMonthlyChartData,
    singleMonthData,
    singleWeekData,
    singleQuarterData,
    allTimeData,
    todayData
  ) => {
    if (this.getShowType() === 'singleMonth') {
      return singleMonthData;
    }
    if (this.getShowType() === 'singleWeek') {
      return singleWeekData;
    }
    if (this.getShowType() === 'singleQuarter') {
      return singleQuarterData;
    }
    if (this.getShowType() === 'allMonths') {
      return finalMonthlyChartData;
    }
    if (this.getShowType() === 'allTime') {
      return allTimeData;
    }
    if (this.getShowType() === 'today') {
      return todayData;
    }
    return finalChartData;
  };

  getChartSizes = () => {
    if (this.getShowType() === 'singleMonth') {
      return { tickType: this.singleMonth(), barWidth: 40, offSet: 50, labelSize: 18 };
    }
    if (this.getShowType() === 'singleWeek') {
      return { tickType: this.singleWeek(), barWidth: 40, offSet: 50, labelSize: 18 };
    }
    if (this.getShowType() === 'singleQuarter') {
      return { tickType: this.singleQuarter(), barWidth: 40, offSet: 50, labelSize: 18 };
    }
    if (this.getShowType() === 'allMonths') {
      return { tickType: this.months, barWidth: 14, offSet: 18, labelSize: 10 };
    }
    if (this.getShowType() === 'allQuarters') {
      return { tickType: this.quarters, barWidth: 40, offSet: 50, labelSize: 18 };
    }
    if (this.getShowType() === 'allTime') {
      return { tickType: [ 'All Time' ], barWidth: 40, offSet: 50, labelSize: 18 };
    }
    if (this.getShowType() === 'today') {
      return { tickType: this.today, barWidth: 40, offSet: 50, labelSize: 18 };
    }
    return { tickType: [ 'All Time' ], barWidth: 40, offSet: 50, labelSize: 18 };
  };

  formatMailersIntoQuarters = data => {
    const stageNames = {};
    const stageData = [];
    for (let i = 0; i < this.names.length; i += 1) {
      stageNames[this.names[i]] = {};
      stageNames[this.names[i]].q1 = [ data.filter(d => d.name === this.names[i]).filter(p => p.quarter === 1).length ];
      stageNames[this.names[i]].q2 = [ data.filter(d => d.name === this.names[i]).filter(p => p.quarter === 2).length ];
      stageNames[this.names[i]].q3 = [ data.filter(d => d.name === this.names[i]).filter(p => p.quarter === 3).length ];
      stageNames[this.names[i]].q4 = [ data.filter(d => d.name === this.names[i]).filter(p => p.quarter === 4).length ];
      stageData.push(stageNames[this.names[i]]);
    }
    return stageData;
  };

  formatChartData = data => {
    const charArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const chartData = [];
      for (let a = 1; a < 5; a += 1) {
        chartData.push({
          quarter : a,
          count   : data[i][`q${a}`][0],
        });
      }
      charArr.push(chartData);
    }
    return charArr;
  };

  formatMailersIntoMonths = data => {
    const returnArr = [];
    const months_short = this.months;
    for (let i = 0; i < this.names.length; i += 1) {
      const monthlyObj = {};
      const currentData = data.filter(d => d.name === this.names[i]);
      for (let a = 0; a < months_short.length; a += 1) {
        if (this.names[i] === 'Sent') {
          monthlyObj[months_short[a]] = currentData.filter(p => moment(p.sentAt).month() === a).length;
        }
        if (this.names[i] === 'Opened') {
          monthlyObj[months_short[a]] = currentData.filter(p => moment(p.openedAt).month() === a).length;
        }
        if (this.names[i] === 'Clicked') {
          monthlyObj[months_short[a]] = currentData.filter(p => moment(p.clickedAt).month() === a).length;
        }
      }
      returnArr.push(monthlyObj);
    }
    return returnArr;
  };

  formatMonthlyChartData = data => {
    const charArr = [];
    const months_short = this.months;
    for (let i = 0; i < data.length; i += 1) {
      const chartData = [];
      for (let a = 0; a < months_short.length; a += 1) {
        chartData.push({
          month : months_short[a],
          count : data[i][months_short[a]],
        });
      }
      charArr.push(chartData);
    }
    return charArr;
  };

  formatSingleMonthData = data => {
    const monthNum = this.months.indexOf(this.singleMonth()[0]);
    const charArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const chartData = data[i][monthNum];
      charArr.push([ chartData ]);
    }
    return charArr;
  };

  formatSingleQuarterData = data => {
    const quarterNum = this.quarters.indexOf(this.singleQuarter()[0]) + 1;
    const charArr = [];
    for (let i = 0; i < data.length; i += 1) {
      charArr.push([ { quarter: this.quarters[quarterNum - 1], count: data[i][`q${quarterNum}`][0] } ]);
    }
    return charArr;
  };

  formatSingleWeekData = data => {
    const weekNum = this.weeks().indexOf(this.singleWeek()[0]);
    const charArr = [];
    for (let i = 0; i < this.names.length; i += 1) {
      if (this.names[i] === 'Sent') {
        charArr.push([ { week: this.weeks()[weekNum], count: data.filter(d => d.name === 'Sent').length } ]);
      }
      if (this.names[i] === 'Opened') {
        charArr.push([ { week: this.weeks()[weekNum], count: data.filter(d => d.name === 'Opened').length } ]);
      }
      if (this.names[i] === 'Clicked') {
        charArr.push([ { week: this.weeks()[weekNum], count: data.filter(d => d.name === 'Clicked').length } ]);
      }
    }
    return charArr;
  };

  formatTodayData = data => {
    const charArr = [];
    for (let i = 0; i < this.names.length; i += 1) {
      if (this.names[i] === 'Sent') {
        charArr.push([ { today: this.today, count: data.filter(d => d.name === 'Sent').length } ]);
      }
      if (this.names[i] === 'Opened') {
        charArr.push([ { today: this.today, count: data.filter(d => d.name === 'Opened').length } ]);
      }
      if (this.names[i] === 'Clicked') {
        charArr.push([ { today: this.today, count: data.filter(d => d.name === 'Clicked').length } ]);
      }
    }
    return charArr;
  };

  formatAllTimeData = data => {
    const charArr = [];
    for (let i = 0; i < this.names.length; i += 1) {
      const singleArr = [];
      if (this.names[i] === 'Sent') {
        singleArr.push({ year: 'All Time', count: data.filter(d => d.name === 'Sent').length });
      }
      if (this.names[i] === 'Opened') {
        singleArr.push({ year: 'All Time', count: data.filter(d => d.name === 'Opened').length });
      }
      if (this.names[i] === 'Clicked') {
        singleArr.push({ year: 'All Time', count: data.filter(d => d.name === 'Clicked').length });
      }
      charArr.push(singleArr);
    }
    return charArr;
  };

  renderAxis = y => (numeral(y).format('0') === 'NaN' ? '0' : numeral(y).format('0'));

  sanitizeMailers = mailers => {
    const new_mailers = [];
    for (let i = 0; i < mailers.length; i += 1) {
      if (mailers[i].clickedAt) {
        new_mailers.push({ ...mailers[i], quarter: moment(mailers[i].sentAt).quarter(), name: 'Clicked' });
      }
      if (mailers[i].openedAt) {
        new_mailers.push({ ...mailers[i], quarter: moment(mailers[i].sentAt).quarter(), name: 'Opened' });
      }
      new_mailers.push({ ...mailers[i], quarter: moment(mailers[i].sentAt).quarter(), name: 'Sent' });
    }
    return new_mailers;
  };

  reduceMailers = data => {
    let count = 0;
    for (let i = 0; i < data.length; i += 1) {
      for (let a = 0; a < data[i].length; a += 1) {
        count += data[i][a].count;
      }
    }
    return count;
  };

  getTableData = mailers => {
    const sentCount = mailers[0][0].count;
    const openedCount = mailers[1][0].count;
    const clickedCount = mailers[2][0].count;
    return {
      sentCount,
      unopenedCount      : sentCount - openedCount,
      unopenedPercentage : (sentCount - openedCount) / sentCount * 100 || 0,
      openedCount,
      openedPercentage   : openedCount / sentCount * 100 || 0,
      clickedCount,
      clickedPercentage  : clickedCount / sentCount * 100 || 0,
    };
  };

  render () {
    const { classes } = this.props;
    const { yAxis, dateRange, showRange, mailer } = this.state;
    const allMailers = get(this.props, 'data.getMailers', {});
    const groupedMailers = groupBy(allMailers, 'mailer');

    let startDate = '';
    let endDate = '';
    if (dateRange) {
      switch (dateRange) {
        case 'today':
          startDate = moment().startOf('day');
          endDate = moment().endOf('day');
          break;
        case 'last_week':
          startDate = moment().subtract(1, 'week').startOf('week');
          endDate = moment().subtract(1, 'week').endOf('week');
          break;
        case 'this_week':
          startDate = moment().startOf('week');
          endDate = moment().endOf('week');
          break;
        case 'this_month':
          startDate = moment().startOf('month');
          endDate = moment().endOf('month');
          break;
        case 'last_month':
          startDate = moment().subtract(1, 'month').startOf('month');
          endDate = moment().subtract(1, 'month').endOf('month');
          break;
        case 'this_quarter':
          startDate = moment().startOf('quarter');
          endDate = moment().endOf('quarter');
          break;
        case 'last_quarter':
          startDate = moment().subtract(1, 'quarter').startOf('quarter');
          endDate = moment().subtract(1, 'quarter').endOf('quarter');
          break;
        case 'this_year':
          startDate = moment().startOf('year');
          endDate = moment().endOf('year');
          break;
        case 'last_year':
          startDate = moment().subtract(1, 'year').startOf('year');
          endDate = moment().subtract(1, 'year').endOf('year');
          break;
        case 'all_time':
          startDate = moment().year(2010).startOf('year');
          endDate = moment().endOf('year');
          break;
        default:
          startDate = moment().year(2010).startOf('year');
          endDate = moment().endOf('year');
      }
    }
    let mailers = [];
    if (!isEmpty(allMailers)) {
      mailers = allMailers.filter(
        m => moment(m.sentAt).isAfter(startDate, 'YYYY-MM-DD') && moment(m.sentAt).isBefore(endDate, 'YYYY-MM-DD')
      );
    }

    if (!isEmpty(mailers) && mailer !== 'all') {
      mailers = mailers.filter(m => m.mailer.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g).join(' ').replace(/#/g, ' - ') === mailer);
    }

    const sanitizedMailers = this.sanitizeMailers(mailers);
    const quarteredMailers = this.formatMailersIntoQuarters(sanitizedMailers);
    const monthlyMailers = this.formatMailersIntoMonths(sanitizedMailers);
    const finalChartData = this.formatChartData(quarteredMailers);
    const finalMonthlyChartData = this.formatMonthlyChartData(monthlyMailers);
    const singleMonthData = this.formatSingleMonthData(finalMonthlyChartData);
    const singleWeekData = this.formatSingleWeekData(sanitizedMailers);
    const singleQuarterData = this.formatSingleQuarterData(quarteredMailers);
    const allTimeData = this.formatAllTimeData(sanitizedMailers);
    const todayData = this.formatTodayData(sanitizedMailers);

    const totalCases = mailers.length;

    const mailerSelectorNames = Object.keys(groupedMailers)
      .map(m => m.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g).join(' ').replace(/#/g, ' - '))
      .sort();

    const tableData = this.getTableData(
      this.setChartType(
        finalChartData,
        finalMonthlyChartData,
        singleMonthData,
        singleWeekData,
        singleQuarterData,
        allTimeData,
        todayData
      )
    );
    return (
      <div className={`col-md-12 col-sm-12 ${classes.container}`}>
        <h4 className={classes.subHeader}>Mailers that have been Sent, Opened, or Clicked</h4>
        <Grid container>
          <Grid>
            <h2 className={classes.bigNumber}>
              &nbsp;{totalCases} <span className={classes.numberLabel}>Sent Emails</span>
            </h2>
          </Grid>
        </Grid>
        <div className={classes.menu}>
          <Select
            classes={{ select: classes.selectatron }}
            value={mailer}
            onClick={e => {
              if (e.target.value) {
                this.setState({ mailer: e.target.value });
              }
            }}>
            <MenuItem value="all">All</MenuItem>
            {mailerSelectorNames.map(m => <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>)}
          </Select>
          &nbsp;Date Range: &nbsp;
          <Select
            style={{ width: 200, marginRight: 50 }}
            value={dateRange}
            onChange={e => {
              this.setState({ dateRange: e.target.value });
              if (e.target.value === 'today') {
                this.setState({ showRange: 'today' });
              }
              if (e.target.value === 'last_year') {
                this.setState({ showRange: 'month' });
              }
              if (e.target.value === 'this_year') {
                this.setState({ showRange: 'month' });
              }
              if (e.target.value === 'last_month') {
                this.setState({ showRange: 'month' });
              }
              if (e.target.value === 'this_month') {
                this.setState({ showRange: 'month' });
              }
              if (e.target.value === 'last_week') {
                this.setState({ showRange: 'week' });
              }
              if (e.target.value === 'this_week') {
                this.setState({ showRange: 'week' });
              }
              if (e.target.value === 'this_quarter') {
                this.setState({ showRange: 'quarter' });
              }
              if (e.target.value === 'last_quarter') {
                this.setState({ showRange: 'quarter' });
              }
              if (e.target.value === 'all_quarters') {
                this.setState({ showRange: 'quarter' });
              }
              if (e.target.value === 'all_time') {
                this.setState({ showRange: 'year' });
              }
            }}
            input={<OutlinedInput name="age" id="outlined-age-simple" />}>
            <MenuItem value="all_time">All Time</MenuItem>
            <MenuItem value="today">Today</MenuItem>
            <MenuItem value="this_week">This Week</MenuItem>
            <MenuItem value="last_week">Last Week</MenuItem>
            <MenuItem value="this_month">This Month</MenuItem>
            <MenuItem value="last_month">Last Month</MenuItem>
            <MenuItem value="this_quarter">This Quarter</MenuItem>
            <MenuItem value="last_quarter">Last Quarter</MenuItem>
            <MenuItem value="all_quarters">All Quarters</MenuItem>
            <MenuItem value="this_year">This Year</MenuItem>
            <MenuItem value="last_year">Last Year</MenuItem>
          </Select>
        </div>
        <VictoryChart
          width={1000}
          height={350}
          domainPadding={[ 80, 80 ]}
          padding={{ bottom: 100, left: 80, top: 20, right: 80 }}
          theme={VictoryTheme.material}>
          <VictoryAxis dependentAxis tickFormat={y => this.renderAxis(y)} orientation="left" />
          <VictoryAxis
            tickValues={this.getChartSizes().tickType}
            tickFormat={t => `${capitalize(t)}`}
            orientation="bottom"
            tickLabelComponent={<VictoryLabel angle={22} textAnchor="start" />}
          />
          <VictoryGroup offset={this.getChartSizes().offSet}>
            {this.setChartType(
              finalChartData,
              finalMonthlyChartData,
              singleMonthData,
              singleWeekData,
              singleQuarterData,
              allTimeData,
              todayData
            ).map(c => (
              <VictoryBar
                colorScale={colorScale}
                data={c}
                x={showRange}
                y={yAxis}
                barWidth={this.getChartSizes().barWidth}
                labels={({ datum }) => (datum.count !== 0 ? datum.count : '')}
                labelComponent={<VictoryLabel dy={0} />}
                domain={{ y: [ 0, totalCases * 0.7 ] }}
                style={{ labels: { fontSize: this.getChartSizes().labelSize } }}
              />
            ))}
          </VictoryGroup>
        </VictoryChart>
        <VictoryLegend
          height={30}
          x={120}
          y={0}
          orientation="horizontal"
          gutter={15}
          colorScale={colorScale}
          style={{ labels: { fontSize: 5 } }}
          data={[ 'Sent', 'Opened', 'Clicked' ].map(s => ({ name: s }))}
        />

        <Table className={classes.table}>
          <TableHead>
            <TableCell>Mailer Name</TableCell>
            <TableCell>Sent Count</TableCell>
            <TableCell>Unopened Count</TableCell>
            <TableCell>Unopened %</TableCell>
            <TableCell>Open Count</TableCell>
            <TableCell>Open %</TableCell>
            <TableCell>Clicked Count</TableCell>
            <TableCell>Clicked %</TableCell>
          </TableHead>
          <TableBody>
            <TableCell component="th" scope="row">
              {capitalize(mailer.replace(/_/g, ' '))}
            </TableCell>
            <TableCell>{tableData.sentCount}</TableCell>
            <TableCell>{tableData.unopenedCount}</TableCell>
            <TableCell>{`${tableData.unopenedPercentage.toFixed(2)}%`}</TableCell>
            <TableCell>{tableData.openedCount}</TableCell>
            <TableCell>{`${tableData.openedPercentage.toFixed(2)}%`}</TableCell>
            <TableCell>{tableData.clickedCount}</TableCell>
            <TableCell>{`${tableData.clickedPercentage.toFixed(2)}%`}</TableCell>
          </TableBody>
        </Table>
      </div>
    );
  }
}

MailerReport.propTypes = {
  classes : PropTypes.object.isRequired,
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
  formControl : {
    margin   : 2,
    minWidth : 120,
  },
};
export default withStyles(styles)(MailerReport);
