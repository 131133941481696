import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { debounce, get } from 'lodash';
import InventoryUserAvatar from '../InventoryUserAvatar/InventoryUserAvatar';

const SingleValue = ({ selectProps, innerProps, data }) => {
  if (get(data, 'value')) {
    return (
      <div>
        <Typography className={selectProps.classes.singleValue} {...innerProps}>
          <InventoryUserAvatar inventoryUser={data} />
          <a
            href={`/admin/${
              data.has_user ? 'user_admin' : 'inventory_users'
            }?inv_user=${data.id}`}>
            {data.label}{' '}
            <i
              className="fas fa-external-link-alt"
              style={{ fontSize: 12 }}
              aria-hidden="true"
            />
          </a>
        </Typography>
      </div>
    );
  }
  return <div />;
};

SingleValue.propTypes = {
  selectProps: PropTypes.object,
  innerProps: PropTypes.object,
  data: PropTypes.object,
};

SingleValue.defaultProps = {
  selectProps: {},
  innerProps: {},
  data: {},
};

export default SingleValue;
