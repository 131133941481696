import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { graphql, Mutation } from 'react-apollo';
import CompletedSteps from './CompletedSteps';
import Button from "@material-ui/core/Button";

export const DownloadCatPlan = gql`
  mutation downloadCatPlan(
    $id: ID!
  ) {
    downloadCatPlan(
      id: $id
    )
  }
`;

export const ContinuityStepTab = ({ card, classes, dealTrackingPipeLineId, enqueueSnackbar, updateCard, pipeline }) => {
  return (
    <div>
      { get(card, 'primary_agent.cat_plan_filename', false) &&
        <p className={classes.dealStucture}>
          <br/><b>&nbsp;&nbsp;Deal Structure for Catatasrophic Plan:</b> {get(card, 'continuity_builder.dealStructureForCatastrophicPlan')}
          {card.pipeline_stage.name == 'Approved' &&
            <div>
              <br/>
              <br/>
              &nbsp;&nbsp;
              <Mutation
                onCompleted={() => {
                  enqueueSnackbar("The Plan is being retrieved and will be emailed to you.", {
                  variant      : 'success',
                  anchorOrigin : {
                    vertical   : 'top',
                    horizontal : 'center',
                  }});
                }}
                mutation={DownloadCatPlan}>
                {downloadBuilder => <Button
                  variant="contained"
                  onClick={ () =>{
                  const variables = {
                      id: get(card, 'primary_agent.id', null),
                    };
                  downloadBuilder({
                    variables,
                  })}}
                >Click here to download plan</Button>
                }
                  </Mutation>

            </div>
          }
        </p>
      }
      <CompletedSteps
        card={card}
        classes={classes}
        dealTrackingPipeLineId={dealTrackingPipeLineId}
        enqueueSnackbar={enqueueSnackbar}
        updateCard={updateCard}
        pipeline={pipeline}
      />
    </div>
  );
};

ContinuityStepTab.propTypes = {
  card                   : PropTypes.object.isRequired,
  classes                : PropTypes.object.isRequired,
  dealTrackingPipeLineId : PropTypes.number,
  enqueueSnackbar        : PropTypes.func.isRequired,
  updateCard             : PropTypes.func.isRequired,
  pipeline               : PropTypes.object.isRequired,
};

ContinuityStepTab.defaultProps = {
  dealTrackingPipeLineId : '',
};

const styles = {
  step             : {
    '& $completed' : {
      color : 'green',
    },
    '& $active'    : {
      color : 'blue',
    },
    '& $disabled'  : {
      color : 'red',
    },
  },
  alternativeLabel : {},
  active           : {},
  disabled         : {},
  completed        : {
    color : '#000',
  },
  stepIcon         : {
    color : 'green',
  },
  labelContainer   : {
    '& $alternativeLabel' : {
      marginTop : 0,
    },
  },
  completionCount  : {
    marginTop  : 10,
    marginLeft : 25,
    fontSize   : 18,
  },
  dealStructure: {
    padding: 20,
  },
};

export default withStyles(styles)(withSnackbar(ContinuityStepTab));
