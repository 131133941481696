import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Mutation } from 'react-apollo';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { get, isEmpty } from 'lodash';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ManagerSearchField from '../../../containers/ManagerSearchFieldContainer';
import { createTaskMutation } from '../PipelineQueries';
import qs from 'query-string';

class NewTaskDialog extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      addTaskOpen    : this.props.addTaskOpen,
      name           : '',
      description    : '',
      inventoryUser  : this.props.currentInventoryUser,
      activityTypeId : null,
      dueDate        : Date(),
      paramsActive   : false,
    };
  }

  originalState = {
    addTaskOpen    : false,
    name           : '',
    description    : '',
    inventoryUser  : null,
    activityTypeId : null,
    dueDate        : Date(),
    paramsActive   : false,
  };

  handleClose = () => {
    const { openNewTaskCard } = this.props;
    // window.history.pushState({}, document.title, '/admin/tasks');
    this.setState({ ...this.originalState });
    openNewTaskCard();
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleParams = params => {
    if (params.new_task === 'true') {
      const { currentInventoryUser } = this.props;
      if (!isEmpty(currentInventoryUser)) {
        this.setState({ addTaskOpen: true, inventoryUser: currentInventoryUser, paramsActive: true });
      }
    }
  };

  render () {
    const { classes, activityTypes, pipelineCard, refetch, openTask } = this.props;
    const { addTaskOpen, name, description, dueDate, inventoryUser, activityTypeId, paramsActive } = this.state;
    const params = qs.parse(location.search);
    if (params && paramsActive === false) {
      this.handleParams(params);
    }
    return (
      <div style={{ display: 'contents' }}>
        <Dialog
          open={openTask || addTaskOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Add a new task</DialogTitle>
          <DialogContent>
            <TextField
              id="standard-name"
              label="Name"
              fullWidth
              className={classes.textField}
              value={name}
              onChange={this.handleChange('name')}
              margin="normal"
            />
            <TextField
              id="standard-description"
              label="Description"
              fullWidth
              className={classes.textField}
              value={description}
              onChange={this.handleChange('description')}
              margin="normal"
            />
            <br />
            <br />
            <ManagerSearchField
              label="Owner"
              value={inventoryUser}
              onChange={v => {
                this.setState({ inventoryUser: v });
              }}
            />
            <div>
              <br />
              <b>Activity Type</b> &nbsp;
              <Select value={activityTypeId} onClick={e => this.setState({ activityTypeId: e.target.value })}>
                {(activityTypes || []).map(at => <MenuItem value={at.id}>{at.name}</MenuItem>)}
              </Select>
            </div>
            <div>
              <br />
              <b>Due Date</b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={dueDate}
                  onChange={date => this.setState({ dueDate: date })}
                />
              </MuiPickersUtilsProvider>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Mutation mutation={createTaskMutation}>
              {createTask => (
                <Button
                  onClick={() => {
                    createTask({
                      variables : {
                        ...this.state,
                        pipeline_card_id  : get(pipelineCard, 'id'),
                        activity_type_id  : activityTypeId,
                        inventory_user_id : inventoryUser.id,
                        status            : 'open',
                        due_date          : dueDate,
                      },
                    }).then(() => {
                      refetch();
                      this.handleClose();
                    });
                  }}
                  color="primary"
                  disabled={!(name && activityTypeId && inventoryUser)}>
                  Save
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const styles = {
  taskButton : {
    marginLeft : 'auto',
  },
};

NewTaskDialog.propTypes = {
  classes              : PropTypes.object.isRequired,
  activityTypes        : PropTypes.array.isRequired,
  pipelineCard         : PropTypes.object,
  currentInventoryUser : PropTypes.object,
  refetch              : PropTypes.func.isRequired,
  buttonType           : PropTypes.string,
};

NewTaskDialog.defaultProps = {
  pipelineCard         : {},
  buttonType           : 'text',
  currentInventoryUser : {},
};

export default withStyles(styles)(NewTaskDialog);
