import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty, groupBy, toLower } from 'lodash';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { updateMotivationFilters } from '../../../actions/motivationReportActionCreators';
import { updateDateFilter } from '../../.././actions/dateRangeFilterActionCreators';
import { loadInventoryUserList } from '../../../actions/inventoryUsersActionCreators';
import { listOfStates, listOfProvinces, getMotivation } from '../../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import ReactToPrint from 'react-to-print';

const MotivationFilters = ({
  updateMotivationFilters,
  motivationFilters,
  transition_goals,
  classes,
  currentCountry,
  loadInventoryUserList,
  componentRef,
}) => {
  const motivations = [ 'growth', 'succession', 'no_motivation', 'here_for_research' ];
  const changeFilter = e => {
    if (e.manager_objects) {
      e.managed_by_users_id = e.manager_objects.map(m => m.id);
    }
    updateMotivationFilters(e);
  };
  const {
    user_motivation,
    transition_goals_name,
    branch,
    division,
    region,
    province,
    manager_objects,
    managed_by_users_id,
    deal_room_only,
  } = motivationFilters;
  const tempSelect = [ 'Test' ];
  const divisionSelect = [
    'ab division',
    'ams',
    'eastern division',
    'fixed income cap mkts div',
    'gws',
    'iad',
    'legal & compliance',
    'northern division',
    'operations',
    'pcg management',
    'pcg support',
    'raymond james and associates',
    'southern division',
    'western division',
    'fid',
    'financial institutions division',
    'icd',
    'independent contractor division',
  ];
  const regionSelect = [
    'ab admin',
    'ab atlantic region',
    'ab metro region',
    'ams',
    'est-no region',
    'florida region',
    'northeast region',
    'fixed inc sales, tdg & resrch',
    'gws equ retail svcs',
    'wealth, retirement & portfolio',
    'iad - unknown',
    'compliance',
    'great lakes region',
    'mid-american region',
    'midwest region',
    'nth-no region',
    'transitionstrfrs&svc',
    'asscfinsvcs-invcntrl',
    'pcg educ & practive mgmt',
    'pcg operations',
    'supervision',
    'rja - unknown',
    'mid-south region',
    'southeast region',
    'sth-no region',
    'west coast region',
    'western gulf region',
    'wst-no region',
  ];
  const goal_map = {
    Buying                                         : 'growth',
    Merging                                        : 'growth',
    Partnering                                     : 'growth',
    'Business Continuity'                          : 'no_motivation',
    'Find a Successor'                             : 'succession',
    'Selling my practice'                          : 'succession',
    'Selling part of my practice'                  : 'succession',
    Mentoring                                      : 'succession',
    'Being Mentored'                               : 'growth',
    'Forming a Partnership'                        : 'growth',
    'Finding a Junior Partner'                     : 'growth',
    'No Motivation Transition Goal'                : 'no_motivation',
    'Currently not interested in Buying / Selling' : 'no_motivation',
    'Becoming a Junior Partner'                    : 'growth',
  };

  const filtered_goals = () => {
    if (user_motivation == null) {
      return transition_goals;
    }
    return [ ...transition_goals ].filter(t => {
      return goal_map[t] === user_motivation;
    });
  };

  const handleBeforePrint = () => {
    loadInventoryUserList({ print_view: true });
    return Promise.resolve();
  };
  const handleAfterPrint = () => {
    loadInventoryUserList({ print_view: false });
  };
  const isRJ = document.URL.match(/rja|icd/);
  return (
    <div className={classes.menu}>
      &nbsp;Motivation: &nbsp;
      <Select
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        value={user_motivation == null ? 'all' : user_motivation}
        onChange={e => {
          const d = e.target.value === 'all' ? null : e.target.value;
          changeFilter({ user_motivation: d });
        }}>
        {motivations.map(m => <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>)}
      </Select>
      &nbsp;Transition Goals: &nbsp;
      <Select
        id="transition_goals_name"
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        value={isEmpty(transition_goals_name) ? [ 'all' ] : transition_goals_name}
        multiple
        onChange={e => {
          let d = null;
          if (e.target.value[e.target.value.length - 1] !== 'all') {
            d = e.target.value;
            d = d.filter(e => e !== 'all');
          }
          changeFilter({ transition_goals_name: d });
        }}>
        <MenuItem value={'all'}>All</MenuItem>
        {(filtered_goals() || []).map(m => <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>)}
      </Select>
        {isRJ && <React.Fragment>
      &nbsp;Branch: &nbsp;
      <input
        className={classes.selectatron}
        value={branch}
        onChange={e => {
          changeFilter({ branch: toLower(e.target.value) });
        }}
      />
      &nbsp;Division: &nbsp;
      <Select
        id="division"
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        value={isEmpty(division) ? [ 'all' ] : division}
        multiple
        onChange={e => {
          let d = null;
          if (e.target.value[e.target.value.length - 1] !== 'all') {
            d = e.target.value;
            d = d.filter(e => e !== 'all');
          }
          changeFilter({ division: d });
        }}>
        <MenuItem value={'all'}>All</MenuItem>
        {divisionSelect.map(m => <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>)}
      </Select>
      &nbsp;Region: &nbsp;
      <Select
        id="region"
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        multiple
        value={isEmpty(region) ? [ 'all' ] : region}
        onChange={e => {
          let d = null;
          if (e.target.value[e.target.value.length - 1] !== 'all') {
            d = e.target.value;
            d = d.filter(e => e !== 'all');
          }
          changeFilter({ region: d });
        }}>
        <MenuItem value={'all'}>All</MenuItem>
        {regionSelect.map(m => <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>)}
      </Select>
      </React.Fragment>}
      &nbsp;
      {currentCountry === 'ca' ? 'Province ' : 'State'}
      &nbsp;
      <Select
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        value={isEmpty(province) ? [ 'all' ] : province}
        multiple
        onChange={e => {
          let d = null;
          if (e.target.value[e.target.value.length - 1] !== 'all') {
            d = e.target.value;
            d = d.filter(e => e !== 'all');
          }
          changeFilter({ province: d });
        }}>
        <MenuItem value={'all'}>All</MenuItem>
        {(currentCountry == 'ca' ? listOfProvinces : listOfStates).map(m => (
          <MenuItem value={m}>{capitalize(m.replace(/_/g, ' '))}</MenuItem>
        ))}
      </Select>
      &nbsp;Deal Room Only: &nbsp;
      <Select
        className={classes.selectatron}
        classes={{ select: classes.selectatron }}
        value={deal_room_only == null ? 'all' : deal_room_only}
        onChange={e => {
          const d = e.target.value === 'all' ? null : e.target.value;
          changeFilter({ deal_room_only: d });
        }}>
        {[ { l: 'All', v: 'all' }, { l: 'No', v: false }, { l: 'Yes', v: true } ].map(m => (
          <MenuItem value={m.v}>{capitalize(m.l.replace(/_/g, ' '))}</MenuItem>
        ))}
      </Select>
      <Tooltip title="Export Report" style={{ marginLeft: 30 }}>
        <IconButton>
          <ReactToPrint
            onBeforeGetContent={handleBeforePrint}
            onAfterPrint={handleAfterPrint}
            trigger={() => {
              return <CloudDownloadIcon />;
            }}
            content={() => componentRef.current}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : { marginRight: 10 },
  formControl : {
    margin   : 2,
    minWidth : 120,
  },
};

const mapStateToProps = state => ({
  motivationFilters : state.motivationFilters,
  transition_goals  : get(state, 'inventory_users.transition_goals', []),
  currentCountry    : get(state, 'inventory_users.currentCountry', ''),
  inventory_users   : state.inventory_users.inventory_users,
  print_view        : state.inventory_users.print_view,
});

const mapDispatchToProps = {
  updateDateFilter,
  updateMotivationFilters,
  loadInventoryUserList,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MotivationFilters));
