import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 0,
    padding: 0,
    justifyContent: 'right',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: '40%',
    left: '45%',
    zIndex: 1000,
  },

}));

const LoadingButtonWrapper = props => {
  const classes = useStyles();
  const timer = React.useRef();
  const { loading, children } = props;

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <div className={classes.wrapper}>
      {loading ?
        <CircularProgress size={64} className={classes.fabProgress} />
        :
        children
      }
    </div>
  );
}

LoadingButtonWrapper.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.any.isRequired,
  stateChild: PropTypes.string,
};

LoadingButtonWrapper.defaultProps = {
  loading: false,
  stateChild: 'user',
};

const mapStateToProps = (state, ownProps) => {
  return ({
    loading: ownProps.loading || state[ownProps.stateChild].loading,
  });
}

export default connect(mapStateToProps)(LoadingButtonWrapper);