import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { truncate } from 'lodash';
import { S3Image } from '../../../lib/s3';

const styles = {
  card         : {
    width  : 'fit-content',
    margin : 10,
    height : 112,
    float  : 'left',
  },
  details      : {
    display       : 'flex',
    flexDirection : 'column',
  },
  content      : {
    flex    : '1 0 auto',
    float   : 'left',
    padding : 12,
    width   : 135,
  },
  profile      : {
    width : 151,
    float : 'left',
  },
  button       : {
    width : '-webkit-fill-available',
  },
  selectButton : {
    width  : '50%',
    height : 'auto',
  },
};

const TalentThumbnail = ({ user, classes, updateAction, showSelect, createBench }) => (
  <Card className={classes.card}>
    <CardMedia
      className={classes.profile}
      component="img"
      alt={user.name}
      image={user.profile_picture ? S3Image(user.profile_picture) : S3Image('advisor.png')}
      title={user.name}
    />
    <div className={classes.details}>
      <CardContent className={classes.content}>
        {showSelect && (
          <div>
            <span>{truncate(user.name, { length: 15 })}</span>
            <div>
              Risk: {user.risk_of_loss}
              <br />
              Impact: {user.impact_of_loss}
            </div>
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={classes.selectButton}
              onClick={createBench}>
              Select
            </Button>
          </div>
        )}
        {!showSelect && (
          <CardActionArea
            onClick={() => {
              updateAction({ user });
            }}>
            <div>
              <span>{truncate(user.name, { length: 15 })}</span>
              <div>
                <b>
                  <span className="fa fa-map-marker " />
                  &nbsp;{user.province}
                </b>
                <div>
                  <b>Risk:</b> {user.risk_of_loss}
                  <br />
                  <b>Impact:</b> {user.impact_of_loss}
                </div>
              </div>
            </div>
          </CardActionArea>
        )}
      </CardContent>
    </div>
  </Card>
);

TalentThumbnail.propTypes = {
  user         : PropTypes.object,
  classes      : PropTypes.object.isRequired,
  updateAction : PropTypes.func.isRequired,
  createBench  : PropTypes.func.isRequired,
  showSelect   : PropTypes.bool,
};

TalentThumbnail.defaultProps = {
  showSelect : false,
  user       : {},
};

export default withStyles(styles)(TalentThumbnail);
