import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StatusButtons from '../SideBarComponents/StatusButtons';
import SaveButton from '../SideBarComponents/SaveButton';
import StatusSection from '../SideBarComponents/StatusSection';
import Availability from '../SideBarComponents/Availability';
import Affiliation from '../SideBarComponents/Affiliation';
import Branch from '../SideBarComponents/Branch';
import Division from '../SideBarComponents/Division';
import YearsPracticing from '../SideBarComponents/YearsPracticing';
import ContinuityPlanInPlace from '../SideBarComponents/ContinuityPlanInPlace';
import Languages from '../SideBarComponents/Languages';
import RecognitionLevel from '../../ProfileDrawer/ProfileDrawerSideBarComponents/RecognitionLevel';
import DefinedInvestmentStyle from '../SideBarComponents/DefinedInvestmentStyle';
import DefinedServiceModel from '../SideBarComponents/DefinedServiceModel';
import TeamSize from '../SideBarComponents/TeamSize';
import NicheMarkets from '../SideBarComponents/NicheMarkets';
import AskMeAbout from '../SideBarComponents/AskMeAbout';
import SideSpecializedBusinesses from '../SideBarComponents/SideSpecializedBusinesses';
import SideDiscretionaryInvestmentModels from '../SideBarComponents/SideDiscretionaryInvestmentModels';
import SideWhatsYourWhy from '../SideBarComponents/SideWhatsYourWhy';
import { withStyles } from '@material-ui/core/styles';
import { get, isEmpty } from 'lodash';

const ShortListed = ({
  classes,
  dealInterest,
  agentWordForCompany,
  scrollTo,
  stylesheetName,
  dealInterestName,
  allowShortlistMessaging,
  dealRoomName,
  dealRoomSingleName,
  currentUser,
  companyBusinessType,
}) => {
  const user = get(dealInterest, 'inventory_user.user');
  return (
    <div>
      <StatusButtons
        dealInterest={dealInterest}
        scrollTo={scrollTo}
        stylesheetName={stylesheetName}
        dealInterestName={dealInterestName}
        allowShortlistMessaging={allowShortlistMessaging}
      />
      <SaveButton dealInterest={dealInterest} agentWordForCompany={agentWordForCompany} />
      <hr className={classes.bar} />
      <StatusSection dealInterest={dealInterest} dealRoomName={dealRoomName} dealRoomSingleName={dealRoomSingleName} />
      <hr className={classes.bar} />
      <Availability dealInterest={dealInterest} />
      {get(dealInterest, 'proposal_meeting_availability') && <hr className={classes.bar} />}
      <Affiliation dealInterest={dealInterest} />
      <Branch dealInterest={dealInterest} />
      <Division dealInterest={dealInterest} />
      <YearsPracticing dealInterest={dealInterest} />
      <ContinuityPlanInPlace dealInterest={dealInterest} />
      <Languages dealInterest={dealInterest} />
      <RecognitionLevel user={dealInterest} obfuscateView={false} />
      <DefinedInvestmentStyle dealInterest={dealInterest} />
      <DefinedServiceModel dealInterest={dealInterest} />
      <TeamSize dealInterest={dealInterest} />
      {!isEmpty(get(dealInterest, 'inventory_user.user.primary_markets')) && (
        <NicheMarkets dealInterest={dealInterest} />
      )}
      {companyBusinessType === 'wealth_rj' && (
        <div>
          <AskMeAbout user={user} obfuscateView={false} />
          {!isEmpty(user.specialized_businesses) && <SideSpecializedBusinesses user={user} obfuscateView={false} />}
          <SideDiscretionaryInvestmentModels user={user} obfuscateView={false} />
          {!isEmpty(user.why_values) && <SideWhatsYourWhy user={user} obfuscateView={false} />}
        </div>
      )}
    </div>
  );
};

const styles = {
  bar : {
    marginLeft : 25,
    width      : 200,
  },
};

export default withStyles(styles)(ShortListed);
