import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { get, capitalize } from "lodash";
import { S3Image } from "../../lib/s3";
import { Paper, Avatar } from "@material-ui/core";
import OwnershipSelect from "./OwnershipSelect";
import StatusSelect from "./StatusSelect";

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    height: "calc(100vh - 200px)",
    overflow: "scroll",
    fontSize: "12px",
    padding: '20px',
  },
  inline: {
    display: "inline"
  },
  selected: {
    background: "#dadada"
  },
  standaloneList: {
    overflowY: "scroll"
  },
  root: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center"
  },
  data: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    fontSize: "12px"
  },
  dataPoint: {
    padding: theme.spacing(1),
  },
  header: {
    marginLeft: 30,
  }
});

const SupportRequestHeader = ({ supportRequest, classes, managers, refetch }) => {
  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Avatar alt="Remy Sharp" src={`${S3Image(get(supportRequest, "user.profile_picture",''))}`} />
        <h4 className={classes.title}>
          {get(supportRequest, "user.name")}{supportRequest.leadName}
        </h4>
      </div>
      <div className={classes.header}>
        <h4 className={classes.title}>
          {get(supportRequest, "friendlyName")}
        </h4>
      </div>
      <div className={classes.data}>
        <div className={classes.dataPoint}>
          <dt>Min Profile Completed</dt>
          <dd>{get(supportRequest, "user.minimum_profile_completed") ? 'True' : 'False'}</dd>
          <br/>
          <dt>Profile % Complete</dt>
          <dd>{get(supportRequest, "user.profile_percentage_complete")}</dd>
        </div>
        <div className={classes.dataPoint}>
          <dt>Messages Sent</dt>
          <dd>{get(supportRequest, "user.messages_sent")}</dd>
          <br/>
          <dt>Messages Received</dt>
          <dd>{get(supportRequest, "user.messages_received")}</dd>
        </div>
        <div className={classes.dataPoint}>
          <dt>Motivation</dt>
          <dd>{capitalize(get(supportRequest, "user.motivation"))}</dd>
          <br/>
          <dt>Can Match</dt>
          <dd>{get(supportRequest, "user.can_match") ? 'True': 'False'}</dd>
        </div>
        <div className={classes.dataPoint}>
          <dt>Can Create Meeting Room</dt>
          <dd>{get(supportRequest, "user.can_create_meeting_room") ? 'True' : 'False'}</dd>
        </div>

        <div className={classes.dataPoint}>
          <b style={{ verticalAlign: 'super' }}>Request Assigned To </b><br/>
          <OwnershipSelect supportRequest={supportRequest} managers={managers} refetch={refetch} />
        </div>
        <div className={classes.dataPoint}>
          <b>Request Status </b><StatusSelect supportRequest={supportRequest} refetch={refetch} prefix={'srmodal'} />
        </div>
      </div>
    </Paper>
  );
}

export default withStyles(styles)(SupportRequestHeader);