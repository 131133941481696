import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles'; 
import { get, capitalize } from 'lodash';
import gql from 'graphql-tag';
import client from '../../lib/apolloClient';
import { Avatar, Box, Divider, Typography, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { S3Image } from '../../lib/s3';

const USER_SUPPORT_REQUESTS = gql`
  query UserSupportRequests($userId: ID) {
    supportRequests(page: 0, perPage: 1000, userId: $userId) {
      id
      friendlyName
      __typename
      user {
        id
        user_name
        name
        profile_picture
        inventory_user {
          id
          __typename
        }
        __typename
      }
      owner {
        id
        user_name
        name
        profile_picture
        __typename
      }
      status
      conversation {
        id
        conversationType
        __typename
        firstMessages(limit: 1) {
          id
          body
          createdAt
        }
      }
      comments {
        id
        __typename
        title
        comment
        created_at
      }
    }
  }
`;

const SupportRequestForUser = ({ userId, changeSupportRequest, classes }) => {
  const { loading, error, data } = useQuery(USER_SUPPORT_REQUESTS, {
    variables: { userId }, client,
  });

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><Typography color="error">Error :(</Typography></Box>;

  return (
    <Box className={classes.container}>
      {data.supportRequests.map((supportRequest) => (
        <Box 
          key={supportRequest.id}
          className={classes.card}
          onClick={() => changeSupportRequest(supportRequest)}
        >
          <Box display="flex" alignItems="flex-start">
            <Avatar 
              alt={get(supportRequest, 'user.name')}
              src={S3Image(get(supportRequest, 'user.profile_picture', ''))}
              className={classes.avatar}
            />
            <Box ml={2} flex={1}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="h6" className={classes.title}>
                  {get(supportRequest, 'friendlyName', '')}
                </Typography>
                <Typography variant="subtitle2" color="textSecondary">
                  {moment(get(supportRequest, 'conversation.firstMessages[0].createdAt')).fromNow()}
                </Typography>
              </Box>
              <Typography variant="body1" color="textSecondary">
                {get(supportRequest, 'user.name', '')}
              </Typography>
              <Box mt={1}>
                <Typography variant="body2" color="textSecondary">
                  Status: <b>{capitalize(get(supportRequest, 'status'))}</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Assigned To: <b>{get(supportRequest, 'owner.name')}</b>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Latest Note: {get(supportRequest, 'comments[0].comment', '')}
                </Typography>
                <br />
              </Box>
            </Box>
          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );
}

const styles = theme => ({
  container: {
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  card: {
    padding: 6,
    '&:hover': {
      boxShadow: theme.shadows[3],
      cursor: 'pointer',
    },
  },
  avatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  title: {
    fontWeight: 'bold',
  },
});

SupportRequestForUser.propTypes = {
  userId: PropTypes.string.isRequired,
  changeSupportRequest: PropTypes.func.isRequired,
};

export default withStyles(styles)(SupportRequestForUser);
