import React from 'react';
import { ApolloProvider } from 'react-apollo';
import JssProvider from 'react-jss/lib/JssProvider';
import { Provider } from 'react-intl-redux';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import { SnackbarProvider } from 'notistack';
import client from '../lib/apolloClient';
import ProfileDrawerWithListener from './ProfileDrawerWithListener';
import configureStore from '../store/findBobPublicStore';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

const ProfileDrawerContainer = (props, railsContext) => {
  let store = {};
  if (document.store) {
    store = document.store;
  } else {
    store = configureStore(props);
  }
  let theme = themes[railsContext.theme_name];
  const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS : true,
    productionPrefix        : 'fbProf',
  });
  const jss = create({
    ...jssPreset(),
  });
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbDrawer-">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <SnackbarProvider>
          <ApolloProvider client={client}>
            <div>
              <Provider store={store} props>
                <ProfileDrawerWithListener {...props} />
              </Provider>
            </div>
          </ApolloProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </JssProvider>
  );
};

export default ProfileDrawerContainer;
