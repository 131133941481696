import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import SaveIcon from '@material-ui/icons/Save';
import { filter, mapKeys, get } from 'lodash';

const styles = theme => ({
  root       : {
    flexGrow : 1,
    fontSize : '18px',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing.unit * 2,
  },
  title      : {
    fontSize : 18,
    color    : '#fff',
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
  },
});

class CrudTable extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      order       : 'asc',
      orderBy     : 'name',
      selected    : [],
      page        : 0,
      rowsPerPage : 10,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  modifiedList = listData => {
    return filter(listData.data, a => a.modified);
  };

  updateModified = (listData, putAction) => {
    this.modifiedList(listData).map(a => putAction(a));
  };

  printError = errors => {
    return <h2>{errors.request.responseText}</h2>;
  };

  render () {
    const {
      listData,
      classes,
      updateAction,
      addAction,
      deleteAction,
      putAction,
      CrudRow,
      NewFormDialog,
      title,
    } = this.props;
    const { data, order, orderBy, selected, rowsPerPage, page } = this.state;
    return (
      <section>
        <NewFormDialog action={addAction} close={get(listData, 'close', null)} error={get(listData, 'errors', null)} />
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          className={classes.saveButton}
          disabled={this.modifiedList(listData).length < 1}
          onClick={() => this.updateModified(listData, putAction)}>
          <SaveIcon />
        </Button>
        <Paper className={classes.paper}>
          <Grid container className={classes.root}>
            <Grid container className={classes.demo}>
              <Table className={classes.root}>
                {(listData['data'] || [])
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(a => (
                    <CrudRow
                      key={a.id}
                      object={a}
                      action={updateAction}
                      deleteAction={deleteAction}
                      updateAction={putAction}
                    />
                  ))}
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={(listData.data || {}).length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              {listData.error && <FormHelperText error={true}>{this.printError(listData.error)}</FormHelperText>}
            </Grid>
          </Grid>
        </Paper>
      </section>
    );
  }
}

CrudTable.propTypes = {
  listData      : PropTypes.object,
  classes       : PropTypes.object,
  updateAction  : PropTypes.func,
  addAction     : PropTypes.func,
  deleteAction  : PropTypes.func,
  putAction     : PropTypes.func,
  CrudRow       : PropTypes.func,
  NewFormDialog : PropTypes.func,
  title         : PropTypes.string,
};

export default withStyles(styles)(CrudTable);
