import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const CustomDollarFormat = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={values => {
      onChange({
        target : {
          value : values.value,
        },
      });
    }}
    thousandSeparator
    prefix="$"
  />
);

CustomDollarFormat.propTypes = {
  inputRef : PropTypes.func.isRequired,
  onChange : PropTypes.func.isRequired,
};

export default CustomDollarFormat;
