import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import UpIcon from '@material-ui/icons/ArrowDropUp';
import initials from 'initials';
import { bgColorForName } from '../../lib/findBobUtils';
import { S3Image } from '../../lib/s3';
import { get } from 'lodash';

class UserMenu extends React.Component {
  state = {
    anchorEl : null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  changeUrl = url => {
    window.location = url;
  };

  render () {
    const { user, classes, company } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const showHelp = get(company, 'toggled_help_url', false);

    return (
      <div className={classes.root}>
        <Avatar
          size="68"
          name={user.name}
          src={user.profile_picture ? S3Image(user.profile_picture) : null}
          className={classes.avatar}
          style={{ backgroundColor: bgColorForName(user.name) }}>
          <span className={classes.avatarText}>{initials(user.name)}</span>
        </Avatar>
        <div className={classes.name}>
          <div>{user.name}</div>
          <IconButton className={classes.closeButton} onClick={this.handleMenu}>
            {open ? <UpIcon /> : <DownIcon />}
          </IconButton>
        </div>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          classes={{ paper: classes.menu }}
          anchorOrigin={{
            vertical   : 'top',
            horizontal : 'right',
          }}
          transformOrigin={{
            vertical   : 'top',
            horizontal : 'right',
          }}
          open={open}
          onClose={this.handleClose}>
          <MenuItem
            onClick={() => {
              this.changeUrl('/dashboard/index');
              this.handleClose();
            }}>
            <i class=" fas fa-desktop" />&nbsp; Agent view
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.changeUrl(`/account`);
              this.handleClose();
            }}>
            <i class=" fas fa-cog" />&nbsp;Settings
          </MenuItem>
          {showHelp && (
            <MenuItem
              onClick={() => {
                window.open(`${get(company, 'help_url', '/admin/dashboard')}`, 'blank');
                this.handleClose();
              }}>
              <i class=" fas fa-question-circle" />&nbsp;Help
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              this.changeUrl('/logout');
              this.handleClose();
            }}>
            <i class=" fas fa-key" />&nbsp;Log out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const styles = {
  root        : {
    flex   : 0,
    margin : '0 auto 0 10px',
  },
  avatar      : {
    height    : 68,
    width     : 68,
    display   : 'inline-flex',
    margin    : '35px 0 20px 0',
    fontSize  : 20,
    cursor    : 'pointer',
    boxShadow : '1px 1px 1px 0px rgba(0,0,0,0.43)',
  },
  avatarText  : {
    textTransform : 'uppercase',
  },
  name        : {
    display : 'flex',
    margin  : '10px 0',
    width   : 270,
  },
  closeButton : {
    display    : 'inline-block',
    marginLeft : 'auto',
    color      : '#eee',
    marginTop  : -15,
  },
  menu        : {
    width : 270,
  },
};

export default withStyles(styles, { name: 'UserMenu', productionPrefix: 'fb101' })(UserMenu);
