import React from 'react';
import OpportunityCarousel from '../components/OpportunityCarousel/OpportunityCarousel';

const CarouselContainer = ({ opportunities }) => (
  <div>
    <OpportunityCarousel opportunities={opportunities} />
  </div>
);

export default CarouselContainer;
