import React, {forwardRef, useImperativeHandle} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import numeral from "numeral";
import {withStyles} from "@material-ui/core/styles";
import {get} from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import TextField from "@material-ui/core/TextField";
import {FormattedMessage} from "react-intl";

const DealStructure= ({list, query, classes, updateCfaQuery}) => {
  const validate = () => {
    let valid = true;

    if(!query.cashFlowLength || parseFloat(query.cashFlowLength) < 1) {
      valid = false;
    }

    if(!query.cashFlowProductionShift || parseFloat(query.cashFlowProductionShift) < 1) {
      valid = false;
    }

    if(!query.name) {
      valid = false;
    }

    if(query.valid != valid) {
      updateCfaQuery({...query, valid});
    }
  }

  validate();
  return <div>
    <h2><FormattedMessage id="calculators.cfa.terms_of_deal_q"/></h2>
    <p>
      <FormattedMessage id="calculators.cfa.deal_structure_copy"/>
    </p>
    <center>
      <br/>
      <dl>
        <dt><FormattedMessage id="calculators.cfa.please_deal_name"/></dt>
        <dd><TextField
          style={{width: 'inherit'}}
          value={query.name}
          onChange={e => {
            updateCfaQuery({...query, name: e.target.value})
          }}
        /></dd>
        <dt><FormattedMessage id="calculators.cfa.cash_flow_length_q"/></dt>
        <dd><TextField
          type="number"
          InputProps={{ inputProps: { min: 1, max: 20 } }}
          style={{width: 'inherit'}}
          value={query.cashFlowLength}
          onChange={e => {
            let val = parseInt(e.target.value)
            if (val > 20) {
              val = 20;
            }
            updateCfaQuery({...query, cashFlowLength: val})
          }}
        /></dd>
        <dt><FormattedMessage id="calculators.cfa.cash_flow_prod_shift"/> </dt>
        <dd><TextField
          type="number"
          style={{width: 'inherit'}}
          value={query.cashFlowProductionShift}
          onChange={e => {
            updateCfaQuery({...query, cashFlowProductionShift: parseInt(e.target.value)})
          }}
        /></dd>
      </dl>
    </center>
  </div>
};

DealStructure.propTypes = {
  query: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

DealStructure.defaultProps = {
  list: [],
}

const styles = () => ({

});

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(DealStructure));