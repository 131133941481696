import PropTypes from 'prop-types';
import React from 'react';
import { Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '../ToggleButton/ToggleButton';
import SimpleInputField from '../SimpleTextField/SimpleTextField';

const styles = theme => ({
  title     : {
    fontSize : 18,
    color    : '#fff',
    margin   : 12,
  },
  features  : {
    display       : 'flex',
    flexDirection : 'column',
  },
  typo      : {
    margin         : '10px',
    textDecoration : 'underline',
    fontSize       : 15,
  },
  subtypo   : {
    margin : '10px',
  },
  textField : {
    width : '95%',
  },
});

const CompanyValuationMenu = ({ company, updateCompany, classes, updateCompanyBool }) => (
  <div>
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography type="h1" className={classes.title}>
          Valuation Menu
        </Typography>
      </Toolbar>
    </AppBar>
    <Paper className={classes.features}>
      <Typography variant="title" className={classes.typo}>
        Rule of Thumb
      </Typography>
      <div style={{ marginLeft: 10 }}>
        <Typography variant="title" className={classes.subtypo}>
          Recurring Revenue Multiple
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_rot_recurring_multiple"
            label="The multiple applied to recurring revenue"
            data={company.data}
            action={updateCompany}
          />
        </div>
        <Typography variant="title" className={classes.subtypo}>
          Non-Recurring Revenue Multiple
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_rot_non_recurring_multiple"
            label="The multiple applied to non-recurring revenue"
            data={company.data}
            action={updateCompany}
          />
        </div>
        <ToggleButton
          checked={company.data.valuation_rot_use_range || false}
          label="Use Range?"
          onChange={e => {
            updateCompanyBool('valuation_rot_use_range', e);
          }}
        />
        <SimpleInputField
          fullWidth
          type="number"
          id="valuation_rot_range_low"
          label="Low Range"
          data={company.data}
          action={updateCompany}
        />
        <SimpleInputField
          fullWidth
          type="number"
          id="valuation_rot_range_high"
          label="High Range"
          data={company.data}
          action={updateCompany}
        />
      </div>
      <br />
      <Typography variant="title" className={classes.typo}>
        Cash Flow
      </Typography>
      <div style={{ marginLeft: 10 }}>
        <Typography variant="title" className={classes.subtypo}>
          The following are multiples for varying levels of EBITDA:
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_14"
            label=">= $10,000,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_13"
            label=">= $7,500,000 and < $10,000,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_12"
            label=">= $5,000,000 and < $7,500,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_11"
            label=">= $2,500,000 and < $5,000,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_10"
            label=">= $1,000,000 and < $2,500,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_9"
            label=">= $900,000 and < $1,000,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_8"
            label=">= $800,000 and < $900,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_7"
            label=">= $700,000 and < $800,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_6"
            label=">= $600,000 and < $700,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_5"
            label=">= $500,000 and < $600,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_4"
            label=">= $400,000 and < $500,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_3"
            label=">= $300,000 and < $400,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_2"
            label=">= $150,000 and < $300,000"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_multiple_level_1"
            label="< $150,000"
            data={company.data}
            action={updateCompany}
          />
          <ToggleButton
            checked={company.data.valuation_ebitda_use_range || false}
            label="Use Range?"
            onChange={e => {
              updateCompanyBool('valuation_ebitda_use_range', e);
            }}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_low"
            label="Low Range"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_ebitda_high"
            label="High Range"
            data={company.data}
            action={updateCompany}
          />
        </div>
      </div>
      <br />
      <Typography variant="title" className={classes.typo}>
        Discounted Cash Flow
      </Typography>
      <div style={{ marginLeft: 10 }}>
        <Typography variant="title" className={classes.subtypo}>
          Payout Rate
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_dcf_payout_rate"
            label="Percentage of gross production that represents advisor's gross revenue"
            data={company.data}
            action={updateCompany}
          />
        </div>
        <Typography variant="title" className={classes.subtypo}>
          Discount Rate
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_dcf_discount_rate"
            label="Interest rate used to determine the present value of future cash flows"
            data={company.data}
            action={updateCompany}
          />
        </div>
        <Typography variant="title" className={classes.subtypo}>
          Terminal Growth Rate
        </Typography>
        <div className={classes.textField}>
          <SimpleInputField
            fullWidth
            type="number"
            id="terminal_growth_rate_expansion_stage"
            label="Expansion Stage"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="terminal_growth_rate_decelerated_stage"
            label="Decelerated Stage"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="terminal_growth_rate_mature_stage"
            label="Mature Stage"
            data={company.data}
            action={updateCompany}
          />
          <ToggleButton
            checked={company.data.valuation_dcf_use_range || false}
            label="Use Range?"
            onChange={e => {
              updateCompanyBool('valuation_dcf_use_range', e);
            }}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_dcf_range_low"
            label="Low Range"
            data={company.data}
            action={updateCompany}
          />
          <SimpleInputField
            fullWidth
            type="number"
            id="valuation_dcf_range_high"
            label="High Range"
            data={company.data}
            action={updateCompany}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <Typography variant="title" className={classes.subtypo}>
            Discount applied to a book if has been with firm relatively short period of time
          </Typography>
          <ToggleButton
            checked={company.data.valuation_dcf_has_book_tenure_discount || false}
            label="Apply Book Tenure Discount"
            onChange={e => {
              updateCompanyBool('valuation_dcf_has_book_tenure_discount', e);
            }}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <Typography variant="title" className={classes.subtypo}>
            Discount applied to books with higher than normal turn rate
          </Typography>
          <br />
          <ToggleButton
            checked={company.data.valuation_dcf_has_turn_rate_discount || false}
            label="Apply Turn Rate Discount"
            onChange={e => {
              updateCompanyBool('valuation_dcf_has_turn_rate_discount', e);
            }}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <Typography variant="title" className={classes.subtypo}>
            Company Valuation Disclaimer
          </Typography>
          <SimpleInputField fullWidth id="company_valuation_disclaimer" data={company.data} action={updateCompany} />
        </div>
      </div>
    </Paper>
  </div>
);

CompanyValuationMenu.propTypes = {
  company           : PropTypes.object.isRequired,
  updateCompany     : PropTypes.func.isRequired,
  classes           : PropTypes.object.isRequired,
  updateCompanyBool : PropTypes.func.isRequired,
};

export default withStyles(styles)(CompanyValuationMenu);
