import React, { Component } from "react";
import PropTypes from "prop-types";
import Joyride from "react-joyride";
import gql from "graphql-tag";
import { graphql, Mutation } from "react-apollo";
import { isEmpty } from "lodash";
import { injectIntl } from "react-intl";

const SpotlightViewed = gql`
  mutation spotlightViewd($spotlight: String!) {
    spotlightViewed(spotlight: $spotlight)
  }
`;

const templateStep = {
  title: "",
  text: "",
  selector: "",
  position: "bottom-left",
  type: "hover",
  isFixed: true,
  style: {
    textAlign: "center",
    header: {
      borderBottom: "1px solid #45A6FF",
    },
    main: {
      padding: "20px",
    },
    footer: {
      display: "none",
    },
    skip: {
      color: "#f04",
    },
    hole: {
      backgroundColor: "rgba(69,166,255, 0.2)",
    },
  },
};

export const generateSteps = (stepArray, intl) =>
  stepArray.map((s) => SpotlightSteps[s](intl));

const SpotlightSteps = {
  nick_name: (intl) => ({
    ...templateStep,
    title: intl.formatMessage({ id: `spotlight.give_a_nickname` }),
    text: intl.formatMessage({ id: `spotlight.nickname_body` }),
    selector: ".nickName",
    id: "nick_name",
  }),
  notebook: (intl) => ({
    ...templateStep,
    title: intl.formatMessage({ id: `spotlight.keep_all_your_notes` }),
    text: intl.formatMessage({ id: `spotlight.stay_organized` }),
    selector: ".notebook-tip",
    position: "left",
    id: "notebook",
  }),
  managed_profile_strength: (intl) => ({
    ...templateStep,
    title: "This is your Profile Strength",
    text: "The stronger your profile, the more likely you get matched.",
    selector: ".managed_profile_strength",
    id: "managed_profile_strength",
  }),
  deal_room_eval_matches: (intl) => ({
    ...templateStep,
    title: "Evaluate the matches",
    text: "Evaluate matches invite the people you like",
    selector: ".evaluate_matches",
    id: "evaluate_matches",
  }),
};

class Spotlight extends Component {
  spotlightCallback = (actionData, mutate, callback) => {
    if (
      (actionData.action === "close" || actionData.lifecycle === "complete") &&
      actionData.type === "step:after"
    ) {
      mutate({
        variables: { spotlight: actionData.step.id },
      })
        .then(() => {
          // this.props.data.refetch();
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log("there was an error sending the query", error);
        });
      callback();
      // this.joyride.next();
    }
  };

  render = () => {
    const { steps, run, debug, autoStart, callback, userId, intl } = this.props;

    if (isEmpty(steps)) {
      return <span></span>;
    }

    return (
      <Mutation mutation={SpotlightViewed}>
        {(mutate) => (
          <Joyride
            steps={steps}
            ref={(c) => (this.joyride = c)}
            run // or some other boolean for when you want to start it
            autoStart={autoStart}
            scrollToSteps
            continuous
            disableBeacon
            type="continuous"
            styles={{
              options: {
                primaryColor: "#002949",
                textColor: "#333333",
              },
            }}
            callback={(actionData) =>
              this.spotlightCallback(actionData, mutate, callback)
            }
          />
        )}
      </Mutation>
    );
  };
}

Spotlight.propTypes = {
  steps: PropTypes.array.isRequired,
  run: PropTypes.bool,
  debug: PropTypes.bool,
  autoStart: PropTypes.bool,
  callback: PropTypes.func,
};

Spotlight.defaultProps = {
  callback: () => { },
  run: true,
  debug: false,
  autoStart: true,
};

export default injectIntl(Spotlight);
