import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { deletePipelineActivityMutation } from '../PipelineQueries';

class DeleteButton extends React.Component {
  state = { open: false };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { mutate, classes, refetch, activityId } = this.props;
    const { open } = this.state;
    return (
      <div>
        <IconButton>
          <DeleteIcon className={classes.deleteIcon} color="error" onClick={() => this.setState({ open: true })} />
        </IconButton>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Permanently Delete Activity?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to delete this activity. This cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                const variables = { id: activityId };
                mutate({ variables })
                  .then(() => {
                    refetch();
                    this.handleClose();
                  })
                  .catch(e => {
                    // eslint-disable-next-line
                    console.log(e);
                  });
              }}
              color="primary"
              autoFocus>
              Delete Activity
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = {
  deleteIcon : {
    opacity : '0.4',
  },
};

DeleteButton.propTypes = {
  mutate     : PropTypes.func.isRequired,
  classes    : PropTypes.object.isRequired,
  refetch    : PropTypes.func.isRequired,
  activityId : PropTypes.number.isRequired,
};

export default graphql(deletePipelineActivityMutation)(withStyles(styles)(DeleteButton));
