import React from 'react';
import client from '../lib/apolloClient';
import { ApolloProvider } from 'react-apollo';
import { get } from 'lodash';
import Button from '@material-ui/core/Button';
import qs from 'query-string';
import MinicardsComponent from '../components/MinicardsComponent/MinicardsComponent';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

const getTrendingBobs = gql`
  query getTrendingBobs {
    getTrendingBobs {
      id
      user_name
      city
      county
      show_city
      profile_picture
      province
    }
    countryForCompany
    companyHasCounty
  }
`;

const TrendingBobsContainer = ({ updateSelectedProfileDrawer, profile }, {theme_name}) => 
{
  const theme = themes[theme_name];
  return (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <CssBaseline />
      <ApolloProvider client={client}>
        <Query query={getTrendingBobs}>
          {({ loading, data }) => {
            if (loading) return <CircularProgress />;
            if (data.getTrendingBobs) {
              return (
                <section id="sidebar">
                  <aside id="featured-properties">
                    <header>
                      <h3>Trending Bobs</h3>
                    </header>
                    <MinicardsComponent
                      users={data.getTrendingBobs}
                      countryForCompany={data.countryForCompany}
                      companyHasCounty={data.companyHasCounty}
                      updateSelectedProfileDrawer={updateSelectedProfileDrawer}
                      profile={profile}
                      client={client}
                    />
                  </aside>
                </section>
              );
            }
          }}
        </Query>
      </ApolloProvider>
    </MuiThemeProvider>
)};

export default TrendingBobsContainer;
