import PropTypes from 'prop-types';
import React from 'react';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { compact, find, get, each, debounce, map, isEmpty, flowRight } from 'lodash';
import { injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { graphql, ApolloConsumer } from 'react-apollo';
import qs from 'query-string';
import InventoryUserDrawer from '../InventoryUserDrawer/InventoryUserDrawer';
import NewFormDialog from './InventoryUserNewFormDialog';
import InventoryUserMakeManagerDialog from './InventoryUserMakeManagerDialog';
import InventoryUserHideUserDialog from './InventoryUserHideUserDialog';
import InventoryUserObfuscateUserDialog from './InventoryUserObfuscateUserDialog';
import InventoryUserAssignAsLimitedAccess from './InventoryUserAssignAsLimitedAccess';
import InventoryUserResendActivationEmail from './InventoryUserResendActivationEmail';
import InventoryUserOmitFromMkt from './InventoryUserOmitFromMkt';
import LoadCard from './LoadCard';
import FBTable from '../FBTable/FBTable';
import { HomeOfficeQuery, InventoryUserQuery, UpdateCurrentUserColumns } from './InventoryUserQueries';
import ExportToCSVButton from './ExportToCSVButton';

const tableTheme = createMuiTheme({
  overrides : {
    MUIDataTable : {
      responsiveScroll : {
        minHeight : '100%',
        maxHeight : '100%',
      },
    },
  },
});

class InventoryUsers extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      inviteDialogOpen : false,
      inviteeId        : '',
      inviteeName      : '',
      resend           : false,
      page             : 0,
      count            : 0,
      openSnackBar     : false,
      filterParams     : {},
      compactedList    : {},
    };

    this.changePage = debounce(this.changePage, 300);
  }

  updateModifiedEmails = (inventory_users, putInventoryUser) => {
    this.modifiedList(inventory_users).map(a => putInventoryUser(a));
  };

  changePage = (page, refetch, rowsPerPage, orderHash, filter, searchText, compactedList, distanceSearchInput = null, distanceWithinInput = null) => {
    const { filterParams } = this.state;
    let compactFilter;
    if (isEmpty(compactedList)) {
      compactFilter = filterParams;
    } else {
      compactFilter = compactedList;
    }
    const { inventory_users, loadInventoryUserList } = this.props;
    const rows = rowsPerPage || inventory_users.rowsPerPage;
    const ord = orderHash || {
      orderBy : inventory_users.orderBy,
      order   : inventory_users.order,
    };
    const filterList = filter || inventory_users.filterList;

    const nameSearch = searchText || inventory_users.nameSearch;
    let name = nameSearch || null;
    if (name === '####NONE####') {
      name = null;
    }

    let distanceSearch = distanceSearchInput || inventory_users.distanceSearch;
    if (distanceSearch === '####NONE####') {
      distanceSearch = null;
    }

    let distanceWithin = distanceWithinInput || inventory_users.distanceWithin;
    if (distanceWithin === '####NONE####') {
      distanceWithin = null;
    }

    let tgFilter = 'Normal';
    if (!isEmpty(filterList.transition_goals) && filterList.transition_goals.includes('None')) {
      delete compactFilter.user_transition_goals_name;
      tgFilter = 'None';
    }
    let tagFilter = 'Normal';
    if (!isEmpty(filterList.iu_tags) && filterList.iu_tags.includes('None')) {
      delete compactFilter.iu_tags_name;
      tagFilter = 'None';
    }

    refetch({
      limit      : rows,
      offset     : page * rows,
      ...ord,
      filterList : compactFilter,
      nameSearch : name,
      distanceSearch,
      distanceWithin,
      tgFilter,
      tagFilter,
    }).then(response => {
      if (response.data) {
        loadInventoryUserList({
          data        : response.data.getInventoryUsers,
          page,
          rowsPerPage : rows,
          orderBy     : ord.orderBy,
          order       : ord.order,
          filterList,
          nameSearch  : name,
          distanceSearch,
          distanceWithin,
          count       : response.data.getInventoryUserCount,
        });
        this.setState({compactedList: compactFilter});
      }
    });
  };

  rowClick = async (event, user, client) => {
    const { updateSelectedInventoryUser, updateUser } = this.props;
    switch (event.target.innerText) {
      case 'Joined':
        break;
      case 'INVITE':
        break;
      case 'PENDING':
        break;
      case 'Activated':
        break;
      case 'CANCEL':
        break;
      default:
        if ([ 'TD', 'SPAN' ].includes(event.target.tagName)) {
          const result = await client.query({
            query       : InventoryUserQuery,
            variables   : { id: user.id },
            fetchPolicy : 'network-only',
          });
          updateSelectedInventoryUser({
            user : get(result, 'data.getInventoryUser'),
          });
          updateUser(get(result, 'data.getInventoryUser.user'));
        }
    }
  };

  changeRowsPerPage = numberOfRows => {
    const { inventory_users, refetch } = this.props;
    this.changePage(inventory_users.page, refetch, numberOfRows);
  };

  // onFilterChange = (changedColumn, value) => {
  //   const { inventory_users, loadInventoryUserList, refetch } = this.props;
  //   let { filterList } = inventory_users;

  //   filterList[changedColumn.id] = value;

  //   this.changePage(inventory_users.page, refetch, null, null, filterList);
  // };

  onFilterChange = (changedColumn, value) => {
    const { inventory_users, fb_columns, loadInventoryUserList, refetch } = this.props;
    const allColumns = fb_columns(this.props);
    const { filterList } = inventory_users;
    const list = filterList || [];
    const compactedList = {};
    list[changedColumn.id] = value;
    map(list, (v, k) => {
      const ransack_filter = get(find(fb_columns(this.props), [ 'id', k ]), 'ransack_filter');
      if (ransack_filter && (v || []).length > 0) {
        if (allColumns.find(f => f.id === k).filterType === 'range') {
          if (v[0]) {
            compactedList[ransack_filter] = {
              ...compactedList[ransack_filter],
              gte : v[0],
            };
          }
          if (v[1]) {
            compactedList[ransack_filter] = {
              ...compactedList[ransack_filter],
              lte : v[1],
            };
          }
          return v;
        } else if (allColumns.find(f => f.id === k).filterType === 'date') {
          if (v[0]) {
            compactedList[ransack_filter] = {
              ...compactedList[ransack_filter],
              lte : moment().subtract(v[0], 'year').format('YYYY-MM-DD'),
            };
          }
          if (v[1]) {
            compactedList[ransack_filter] = {
              ...compactedList[ransack_filter],
              gte : moment().subtract(v[1], 'year').format('YYYY-MM-DD'),
            };
          }
          return v;
        } else if (allColumns.find(f => f.id === k).filterType === 'manager') {
          compactedList[ransack_filter] = v.map(i => i.id);
          return v;
        } else if (allColumns.find(f => f.id === k).filterType === 'text') {
          compactedList[ransack_filter] = { like: v };
          return v;
        }

        return (compactedList[ransack_filter] = v);
      }
      return null;
    });
    this.changePage(inventory_users.page, refetch, null, null, list, null, compactedList);
    this.setState({ filterParams: compactedList });
  };

  onSort = (event, property) => {
    const { inventory_users, refetch } = this.props;
    let newOrder = 'desc';

    if (inventory_users.orderBy === property && inventory_users.order === 'desc') {
      newOrder = 'asc';
    }

    this.changePage(inventory_users.page, refetch, null, {
      orderBy : property,
      order   : newOrder,
    });
  };

  onSearchChange = value => {
    const { inventory_users, refetch } = this.props;
    let searchText = '####NONE####';
    if (value && value !== '') {
      searchText = value;
    }
    this.changePage(inventory_users.page, refetch, null, null, null, searchText);
  };
  
  onDistanceSearch = ({distanceSearch, distanceWithin}) => {
    const { inventory_users, refetch } = this.props;
    this.changePage(inventory_users.page, refetch, null, null, null, null, null, distanceSearch, distanceWithin);
  };


  handleSnackOpen = snackMessage => {
    const { enqueueSnackbar } = this.props;
    enqueueSnackbar(snackMessage, {
      variant      : 'default',
      anchorOrigin : {
        vertical   : 'top',
        horizontal : 'center',
      },
    });
  };

  render () {
    const {
      classes,
      deleteInventoryUser,
      inventory_users,
      addInventoryUser,
      updateInventoryUser,
      inventory_user,
      putInventoryUser,
      updateSelectedInventoryUser,
      loadBenchUsers,
      data,
      refetch,
      loadInventoryUserList,
      mutate,
      onlyUsers,
      filterColumns,
      fb_columns,
      currentUserIsAdmin,
      currentUserId,
      loading,
      tags,
      agencies,
      bulkEditItems,
      loadBulkItemsList,
      company,
      allFeatures,
    } = this.props;
    const { countryForCompany, agentWordForCompany, availableProducts } = data;
    const { openSnackBar, snackMessage } = this.state;

    if (!inventory_users.filterList && inventory_users.loaded) {
      const filterList = {};
      filterColumns(this.props).forEach(c => (filterList[c.id] = []));
      loadInventoryUserList({ filterList });
    }
    const params = qs.parse(this.props.location.search);
    const { inv_user } = params;
    if (inventory_users.error && !openSnackBar && inventory_users.error !== snackMessage) {
      this.handleSnackOpen(inventory_users.error);
    }
    return (
      <Grid>
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <ApolloConsumer>
              {client => (
                <div>
                  <LoadCard
                    client={client}
                    user={inv_user || ''}
                    updateSelectedInventoryUser={updateSelectedInventoryUser}
                    InventoryUserQuery={InventoryUserQuery}
                    changeRowsPerPage={this.changeRowsPerPage}
                    onFilterChange={this.onFilterChange}
                    filterList={inventory_users.filterList}
                    onDelete={deleteInventoryUser}
                    onSearch={this.onSearchChange}
                    onDistanceSearch={this.onDistanceSearch}
                    allColumns={fb_columns(this.props)}
                    allowDelete={!onlyUsers}
                    bulkEditDialog={props => (
                      <div style={{ float: 'left' }}>
                        {onlyUsers && (
                          <span>
                            <InventoryUserMakeManagerDialog
                              {...props}
                              data={inventory_users.data}
                              managers={inventory_users.managers}
                              loadBulkItemsList={loadBulkItemsList}
                              changePage={(e, page) => {
                                this.changePage(inventory_users.page, refetch);
                              }}
                            />
                            <InventoryUserHideUserDialog
                              {...props}
                              data={inventory_users.data}
                              managers={inventory_users.managers}
                              loadBulkItemsList={loadBulkItemsList}
                              changePage={(e, page) => {
                                this.changePage(inventory_users.page, refetch);
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                    updateColumns={(list, value) => {
                      var vars = {};
                      if(onlyUsers) {
                        vars = { default_user_columns : list }
                      } else {
                        vars = { default_inventory_user_columns: list }
                      }
                      mutate({
                        variables : vars,
                      }).then(() => {
                        const fl = inventory_users.filterList;
                        if (fl[value]) {
                          delete fl[value];
                        } else {
                          fl[value] = [];
                        }
                        loadInventoryUserList({
                          selectedColumns : list,
                          filterList      : fl,
                        });
                        this.changePage(inventory_users.page, refetch, null, null, fl);
                      });
                    }}
                  />
                  <MuiThemeProvider theme={tableTheme}>
                    <FBTable
                      data={inventory_users.data}
                      tags={tags}
                      order={inventory_users.order}
                      orderBy={inventory_users.orderBy}
                      count={inventory_users.count}
                      selected={inventory_users.selected}
                      page={inventory_users.page}
                      offset={inventory_users.offset}
                      rowsPerPage={inventory_users.rowsPerPage}
                      columns={filterColumns(this.props)}
                      onSort={this.onSort}
                      changePage={(e, page) => {
                        this.changePage(page, refetch);
                      }}
                      rowClick={this.rowClick}
                      client={client}
                      changeRowsPerPage={this.changeRowsPerPage}
                      onFilterChange={this.onFilterChange}
                      filterList={inventory_users.filterList}
                      onDelete={deleteInventoryUser}
                      onSearch={this.onSearchChange}
                      onDistanceSearch={this.onDistanceSearch}
                      allColumns={fb_columns(this.props)}
                      allowDelete={!onlyUsers}
                      loading={loading}
                      exportToCSVButton={props => (
                        <span>
                          <ExportToCSVButton
                            inventory_users={inventory_users}
                            {...props}
                            filterList={this.state.compactedList}
                          />
                        </span>
                      )}
                      currentUserIsAdmin={currentUserIsAdmin}
                      bulkEditItems={bulkEditItems}
                      loadBulkItemsList={loadBulkItemsList}
                      bulkEditDialog={props => (
                        <div style={{ float: 'left' }}>
                          {onlyUsers && (
                            <span>
                              <InventoryUserMakeManagerDialog
                                {...props}
                                data={inventory_users.data}
                                managers={inventory_users.managers}
                                loadBulkItemsList={loadBulkItemsList}
                                changePage={(e, page) => {
                                  this.changePage(inventory_users.page, refetch);
                                }}
                              />
                              <InventoryUserHideUserDialog
                                {...props}
                                data={inventory_users.data}
                                managers={inventory_users.managers}
                                loadBulkItemsList={loadBulkItemsList}
                                changePage={(e, page) => {
                                  this.changePage(inventory_users.page, refetch);
                                }}
                              />
                              <InventoryUserObfuscateUserDialog
                                {...props}
                                data={inventory_users.data}
                                managers={inventory_users.managers}
                                loadBulkItemsList={loadBulkItemsList}
                                changePage={(e, page) => {
                                  this.changePage(inventory_users.page, refetch);
                                }}
                              />
                              <InventoryUserAssignAsLimitedAccess
                                {...props}
                                data={inventory_users.data}
                                managers={inventory_users.managers}
                                loadBulkItemsList={loadBulkItemsList}
                                changePage={(e, page) => {
                                  this.changePage(inventory_users.page, refetch);
                                }}
                              />
                              {currentUserIsAdmin && (
                                <InventoryUserResendActivationEmail
                                  {...props}
                                  data={inventory_users.data}
                                  managers={inventory_users.managers}
                                  loadBulkItemsList={loadBulkItemsList}
                                  changePage={(e, page) => {
                                    this.changePage(inventory_users.page, refetch);
                                  }}
                                />
                              )}
                              <InventoryUserOmitFromMkt
                                {...props}
                                data={inventory_users.data}
                                managers={inventory_users.managers}
                                loadBulkItemsList={loadBulkItemsList}
                                changePage={(e, page) => {
                                  this.changePage(inventory_users.page, refetch);
                                }}
                              />
                            </span>
                          )}
                        </div>
                      )}
                      updateColumns={(list, values) => {
                        var vars = {};
                        if(onlyUsers) {
                          vars = { default_user_columns: list }
                        } else {
                          vars = { default_inventory_user_columns: list }
                        }
                        mutate({
                        variables : vars,
                        }).then(() => {
                          const fl = inventory_users.filterList;
                          for (let i = 0; i < values.length; i += 1) {
                            if (fl[values[i]]) {
                              delete fl[values[i]];
                            } else {
                              fl[values[i]] = [];
                            }
                          }
                          loadInventoryUserList({
                            selectedColumns : list,
                            filterList      : fl,
                          });
                          this.changePage(inventory_users.page, refetch, null, null, fl);
                        });
                      }}
                    />
                  </MuiThemeProvider>
                </div>
              )}
            </ApolloConsumer>
            <InventoryUserDrawer
              inventoryUser={inventory_user.user}
              inventory_users={inventory_users}
              updateAction={updateSelectedInventoryUser}
              updateInventoryUser={updateInventoryUser}
              company={company}
              showBench
              showEditButton
              loadBenchUsers={loadBenchUsers}
              inviteButton={this.inviteButton}
              country={get(company, 'country')}
              openSnack={() => {
                this.handleSnackOpen('User Updated');
              }}
              agentWordForCompany={agentWordForCompany}
              currentUserIsAdmin={currentUserIsAdmin}
              currentUserId={currentUserId}
              refetch={refetch}
              tags={tags}
              agencies={agencies}
              allFeatures={allFeatures}
            />
            <NewFormDialog
              onlyUsers={onlyUsers}
              agentWordForCompany={agentWordForCompany}
              action={addInventoryUser}
              close={inventory_users.close}
              error={inventory_users.error}
              country={get(company, 'country')}
              availableProducts={availableProducts || '[]'}
            />
            <Button
              mini
              color="primary"
              aria-label="add"
              disabled={inventory_users.data.length < 1}
              onClick={() => this.updateModifiedEmails(inventory_users, putInventoryUser)}
            />
          </div>
        </Paper>
      </Grid>
    );
  }
}

InventoryUsers.propTypes = {
  classes                     : PropTypes.object.isRequired,
  inventory_user              : PropTypes.object.isRequired,
  inventory_users             : PropTypes.object.isRequired,
  data                        : PropTypes.object.isRequired,
  intl                        : PropTypes.object.isRequired,
  tags                        : PropTypes.object.isRequired,
  loadInventoryUserList       : PropTypes.func.isRequired,
  putInventoryUser            : PropTypes.func.isRequired,
  deleteInventoryUser         : PropTypes.func.isRequired,
  addInventoryUser            : PropTypes.func.isRequired,
  updateInventoryUser         : PropTypes.func.isRequired,
  updateSelectedInventoryUser : PropTypes.func.isRequired,
  loadBenchUsers              : PropTypes.func.isRequired,
  mutate                      : PropTypes.func.isRequired,
  fb_columns                  : PropTypes.func.isRequired,
  filterColumns               : PropTypes.func.isRequired,
  currentUserIsAdmin          : PropTypes.bool.isRequired,
  loading                     : PropTypes.bool.isRequired,
  onlyUsers                   : PropTypes.bool.isRequired,
  enqueueSnackbar             : PropTypes.func.isRequired,
  refetch                     : PropTypes.func.isRequired,
  agencies                    : PropTypes.array.isRequired,
  bulkEditItems               : PropTypes.array.isRequired,
  loadBulkItemsList           : PropTypes.func.isRequired,
  currentUserId               : PropTypes.number.isRequired,
};

const styles = theme => ({
  title         : {
    fontSize : 18,
    color    : '#fff',
  },
  chip          : {
    margin : '2px',
  },
  invite        : {
    width       : '90px',
    display     : 'block',
    paddingLeft : 15,
  },
  avatar        : {
    height      : 30,
    width       : 30,
    display     : 'inline-flex',
    marginRight : 4,
    fontSize    : 10,
  },
  avatarText    : {
    textTransform : 'uppercase',
  },
  nameField     : {
    display    : 'inline-flex',
    alignItems : 'center',
  },
  iconContainer : {
    minWidth : 60,
  },
  error         : {
    backgroundColor : '#d32f2f',
  },
  default       : {
    backgroundColor : '#333',
  },
});

export default flowRight(graphql(HomeOfficeQuery), graphql(UpdateCurrentUserColumns))(
  injectIntl(withStyles(styles)(withSnackbar(InventoryUsers)))
);
