import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { useLazyQuery } from '@apollo/react-hooks';
import { get } from 'lodash';
import gql from 'graphql-tag';
import Conversation from '../components/Conversation/Conversation';

export const ConversationQuery = gql`
  query getConversation($senderId: ID, $receiverId: ID, $convoId: ID) {
    getConversation(senderId: $senderId, receiverId: $receiverId, convoId: $convoId) {
      personalMessages {
        sender {
          id
          name
        }
        receiver {
          id
          name
        }
        body
        createdAt
      }
    }
  }
`;

const ConversationContainer = ({ senderId, receiverId, client, convoId }) => {
  const [ openModal, setOpenModal ] = useState(false);
  const [ getConversation, { loading, data } ] = useLazyQuery(ConversationQuery, {
    variables : { senderId, receiverId, convoId: parseInt(convoId) },
    client,
  });

  return (
    <div>
      <i
        class="material-icons"
        onClick={() => {
          getConversation();
          setOpenModal(true);
        }}
        style={{
          fontSize  : '18px',
          color     : '#E91E63',
          marginTop : '5px',
          float     : 'left',
          cursor    : 'pointer',
        }}>
        mail_outline
      </i>
      <Conversation
        loading={loading}
        conversation={get(data, 'getConversation.personalMessages', [])}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

ConversationContainer.propTypes = {
  senderId   : PropTypes.string.isRequired,
  receiverId : PropTypes.string.isRequired,
};

export default ConversationContainer;
