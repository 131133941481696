import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Chip } from '@material-ui/core';
import { get } from 'lodash';
import { ChevronRight } from '@material-ui/icons';
import { iconsByConversationType, colorsByConversationType } from '../MessagesPage/ConversationList';
import { injectIntl} from "react-intl";

const ConversationSection = ({ conversations, currentUser, classes, changeConversation, intl }) => {
  // Sort conversations by last message date
  const orderedConversations = conversations.sort((a, b) => {
    const aDate = get(a, 'lastMessages[0].createdAt', new Date(0));
    const bDate = get(b, 'lastMessages[0].createdAt', new Date(0));
    return new Date(bDate) - new Date(aDate);
  });
  return (
    <div className={classes.root}>
      {orderedConversations.map((conversation, index) => {
        let otherUser = null;
        if (currentUser.id === get(conversation, 'participant1.id')) {
          otherUser = get(conversation, 'participant2');
        } else {
          otherUser = get(conversation, 'participant1');
        }
        const convo_type = get(conversation, 'conversationType');
        let subtitle = get(conversation, 'conversationType');
        if (subtitle === 'deal') {
          subtitle = `Deal`;
        } else if (subtitle === 'deal_interest') {
          subtitle = 'Deal';
        } else if (subtitle === 'partial_sale_listing') {
          subtitle = `${get(currentUser, 'company.partial_sale_name')}`;
        }
        else if (subtitle === 'support_request') {
          subtitle = 'Help';
        } else {
          subtitle = 'Chat';
        }
        let lastMessageBody = get(conversation, 'lastMessages[0].body', '');
        if (lastMessageBody.length > 50) {
          lastMessageBody = lastMessageBody.substring(0, 50) + '...';
        }
        let read = true;
        if (get(conversation, 'lastMessages[0].sender.id') === get(otherUser, 'id') && get(conversation, 'lastMessages[0].read') === false) {
          read = false;
        }

        let allowedName = get(otherUser, 'allowed_name', 'Paddy');
        if (allowedName === 'Paddy' && conversation.conversationType === 'Support Request') {
          allowedName = 'Support';
        }

        return (
          <div key={index} className={classes.convoItem} onClick={() => { changeConversation(conversation.id) }}>
            <div className={classes.convoContent}>
              <p className={classes.header}>

                {get(otherUser, 'allowed_name', 'Paddy')} 
                <Chip
                  style={{ float: 'right', fontSize: '0.5em' }}
                  icon={iconsByConversationType[conversation.conversationType]}
                  color={colorsByConversationType[conversation.conversationType]}
                  label={subtitle} />

              </p>
              <p>
                {!read && <i className={`fas fa-circle icon_shimmer ${classes.circle}`} style={{ color: '#1396e2' }}></i>}
                {lastMessageBody}
              </p>
            </div>
            <ChevronRight className={classes.chevronIcon} />
            <hr className={classes.divider} />
          </div>
        );
      })}
    </div>
  );
}

ConversationSection.propTypes = {
  classes: PropTypes.object.isRequired,
  conversations: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  changeConversation: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    height: 'calc(100vh - 400px)',
    overflow: 'scroll',
  },
  inline: {
    display: 'inline',
  },
  selected: {
    background: '#dadada',
  },
  standaloneList: {
    overflowY: 'scroll',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  convoItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    position: 'relative',
    cursor: 'pointer',
  },
  convoContent: {
    flexGrow: 1,
  },
  chevronIcon: {
    marginLeft: 'auto',
    paddingLeft: '10px',
  },
  divider: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    border: 'none',
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
  },
  circle: {
    marginRight: '5px',
    borderRadius: '50%',
  },
});


export default withStyles(styles)(injectIntl(ConversationSection));