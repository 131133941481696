import React from 'react';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import CheckBox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { find, groupBy, capitalize, countBy, orderBy } from 'lodash';
import moment from 'moment';

const styles = theme => ({
  paps: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '20%',
    left: '30%',
    fontSize: '1rem',
    overflow: 'scroll',
    maxHeight: '70%',
  },
  coolRule: {
    width: '80%',
  },
});

class ManagerConfirmation extends React.Component {
  constructor(props) {
    super(props);
    const userList = this.props.users;
    const pendingUsers = userList.filter(u => u.approval_state === 'pending');
    const completedUsers = userList.filter(u => u.approval_state !== 'pending');
    const originalPending = [...pendingUsers];
    this.state = { pendingUsers, originalPending, completedUsers, displayModal: false, submitted: false };

    window.addEventListener('beforeunload', this.tabClosing);
  }

  showModal = () => {
    this.setState({ displayModal: true });
  };

  updateUser = user => {
    const { pendingUsers } = this.state;

    const ul = pendingUsers.map(u => {
      if (u.id === user.id) {
        return user;
      }
      return u;
    });

    this.setState({ pendingUsers: ul });
  };

  validate = () => {
    const { pendingUsers } = this.state;

    return find(pendingUsers, u => {
      if (pendingUsers.length === pendingUsers.filter(u => u.approval_state === 'pending').length) {
        return true;
      }
      if (u.approval_state === 'denied') {
        if (!u.disapproved_reason) {
          return true;
        }
        if (u.disapproved_reason === 'Other:' && !u.disapproved_reason_comment) {
          return true;
        }
      }
      return false;
    });
  };

  approvalText = user => {
    switch (user.approval_state) {
      case 'approved':
        return (
          <span>
            {user.name}: <b>Approved</b>
            <br />
            <p>
              The advisor can access Practice Exchange and its features like the Valuation Tool and Resources, and will
              also be able to be part of the Match Pool and participate in deals.
            </p>
          </span>
        );
      case 'denied':
        return (
          <span>
            {user.name}: <b>Not Approved</b>
            <br />
            <p>
              The advisor can access Practice Exchange and its features like the Valuation Tool and Resources, but will
              not be able to be part of the Match Pool or participate in any deals.
            </p>
          </span>
        );
      default:
        return (
          <span>
            {user.name}: <b>Pending</b>
            <br />
            <p>No Action Taken</p>
          </span>
        );
    }
  };

  renderHeader = (key, divisionalDirector) => {
    if (!key) {
      return (
        <div>
          {divisionalDirector && (
            <div>
              <h2>Pending Seller(s) to Review</h2>
              <p>
                <b>NO ACTION NEEDED</b> if you acknowledge this Seller registering on the site.  They will receive access in 48 hours.
                <br />
                <br />
                If Seller should not have access at this time due to concerns such as compliance / supervision or performance issue, please disapprove user and select the reason below.
              </p>
            </div>
          )}
          {!divisionalDirector && (
            <div>
              <h2>Pending Seller(s) to Review</h2>
              <p>
                Please approve or disapprove access to the Match Pool for Sellers.  Sellers require your explicit approval in order to be granted access to the Match Pool and the ability to engage with buyers.  Unlike Buyers, your action is required for Sellers.
              </p>
            </div>
          )}
        </div>
      );
    }
    return (
      <div>
        {divisionalDirector && (
          <div>
            <h2>Pending Buyer(s) to Review</h2>
            <p>
              <b>NO ACTION NEEDED</b> if you acknowledge this Buyer registering on the site.  They will receive access in 48 hours.

              <br />
              <br />
              If Buyer should not have access at this time due to concerns such as compliance / supervision or performance issue, please disapprove user and select the reason below.
            </p>
          </div>
        )}
        {!divisionalDirector && (
          <div>
            <h2>Pending Buyer(s) to Review</h2>
            <p>
              <b>NO ACTION NEEDED</b> if you approve of this Buyer registering on the site.
              They will receive access in 48 hours.
              <br />
              <br />
              If Buyer should not have access at this time due to concerns such as compliance / supervision or performance issue, please disapprove user and select the reason below.
            </p>
          </div>
        )}
      </div>
    );
  };

  tabClosing = ev => {
    const { pendingUsers, originalPending, submitted } = this.state;
    const pendingCount = countBy(pendingUsers, p => p.approval_state === 'pending')[true];
    const ogPendingCount = countBy(originalPending, p => p.approval_state === 'pending')[true];

    if (ogPendingCount !== pendingCount && !submitted) {
      ev.preventDefault();
      ev.returnValue = 'You sure you want to close the tab? You have not submitted your changes.';
    }
  };

  render = () => {
    const { classes, company_name, divisional_director } = this.props;
    const { displayModal, pendingUsers, completedUsers, submitted } = this.state;
    const groupedUsers = groupBy(pendingUsers, x => x.growth);

    return (
      <div>
        {[false, true].map(key => (
          <div>
            {this.renderHeader(key, divisional_director)}
            <center>
              {`${(groupedUsers[key] || []).length} advisor(s) to review`}
              <br />
            </center>
            {!groupedUsers[key] && (
              <div className="content-card">{`No pending ${!key ? 'Sellers' : 'Buyers'} left to review.`}</div>
            )}
            {orderBy(groupedUsers[key] || [], ['profile_completion_date'], ['asc']).map(user => (
              <div className="content-card" key={user.id}>
                <input value={user.id} type="hidden" name={`users[user][${user.id}][id]`} />

                <h3>{user.name}</h3>
                <InputLabel id="demo-simple-select-label">
                  <label>Profile Completion: </label>{' '}
                  {moment(user.profile_completion_date).format('MMMM Do YYYY, h:mm a')}
                </InputLabel>
                <InputLabel id="demo-simple-select-label">
                  <label>Transition Goals: </label>
                  <span>&nbsp;{user.transition_goals}</span>
                </InputLabel>
                <input type="hidden"
                  name={`users[user][${user.id}][approval_state]`}
                  value={user.approval_state}></input>
                {user.allowApproval &&
                  <div>
                    <input
                      type="checkbox"
                      checked={user.approval_state === 'approved'}

                      value={user.approval_state}
                      label="Not Approved"
                      onChange={e => {
                        let a_s = user.approval_state;

                        if (a_s === 'pending' || a_s === 'denied') {
                          a_s = 'approved';
                        } else {
                          a_s = 'pending';
                        }
                        this.updateUser({ ...user, approval_state: a_s });
                      }} /> Approved
                  </div>}
                <div>
                  <input
                    type="checkbox"
                    checked={user.approval_state === 'denied'}

                    value={user.approval_state}
                    label="Not Approved"
                    onChange={e => {
                      let a_s = user.approval_state;

                      if (a_s === 'pending' || a_s === 'approved') {
                        a_s = 'denied';
                      } else {
                        a_s = 'pending';
                      }
                      this.updateUser({ ...user, approval_state: a_s });
                    }} /> Not Approved </div>

                {user.approval_state === 'denied' && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Please Select</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name={`users[user][${user.id}][disapproved_reason]`}
                      value={user.disapproved_reason}
                      fullWidth
                      onChange={e => {
                        this.updateUser({ ...user, disapproved_reason: e.target.value });
                      }}>
                      <MenuItem value="Compliance/Supervision Issue">Compliance/Supervision Issue</MenuItem>
                      <MenuItem value="Not in Good Standing">Not in Good Standing</MenuItem>
                      <MenuItem value="Performance Issue">Performance Issue</MenuItem>
                      <MenuItem value="Other:">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
                {user.approval_state === 'denied' &&
                  user.disapproved_reason === 'Other:' && (
                    <div>
                      <br />
                      <p>(Other) reason:</p>
                      <textarea
                        onKeyUp={e => {
                          this.updateUser({ ...user, disapproved_reason_comment: e.target.value });
                        }}
                        name={`users[user][${user.id}][disapproved_reason_comment]`}
                        cols="50"
                        rows="10"
                      />
                    </div>
                  )}
              </div>
            ))}
          </div>
        ))
        }

        <h2>Completed:</h2>
        <div className="content-card">
          {orderBy(completedUsers || [], ['confirmation_date'], ['desc']).map(u => (
            <div>
              {u.name} - <b>{capitalize(u.approval_state)} </b>
              {Boolean(u.disapproved_reason) && (
                <p style={{ marginLeft: 8 }}>
                  <b>Reviewer:</b> {u.confirming_manager}
                  <br />
                  <b>Date Reviewed:</b> {moment(u.confirmation_date).format('MMMM Do YYYY, h:mm a')}
                  <br />
                  {Boolean(u.disapproved_reason) && (
                    <span>
                      <b>Reason:</b> {u.disapproved_reason.replace(':', '')}
                    </span>
                  )}
                  <br />
                  {Boolean(u.disapproved_reason_comment) && (
                    <span>
                      <b>Comment:</b> {u.disapproved_reason_comment}
                    </span>
                  )}
                </p>
              )}
              <hr />
            </div>
          ))}
        </div>
        <br />
        <br />
        {
          Boolean(pendingUsers.length) && (
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: '#5489b6' }}
              disabled={this.validate()}
              onClick={this.showModal}>
              Submit
            </Button>
          )
        }
        <Modal
          open={displayModal}
          onClose={() => this.setState({ displayModal: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <div className={classes.paps}>
            <h2 id="simple-modal-title">Confirmation</h2>
            <div id="simple-modal-description">
              <h3 style={{ fontWeight: 'normal' }}>Pending Buyer(s) to Review</h3>
              {!groupedUsers[true] && <p>No pending Buyers left to review.</p>}
              {(groupedUsers[true] || []).map(user => (
                <p>
                  {this.approvalText(user)}
                  <hr className={classes.coolRule} />
                </p>
              ))}
              <hr />
              <h3 style={{ fontWeight: 'normal' }}>Sellers to Review</h3>
              {!groupedUsers[false] && <p>No pending Sellers left to review.</p>}
              {(groupedUsers[false] || []).map(user => (
                <p>
                  {this.approvalText(user)}
                  <hr className={classes.coolRule} />
                </p>
              ))}
              <p>If you have changed your mind, click "Cancel" below.</p>
              <Button variant="contained" color="default" onClick={() => this.setState({ displayModal: false })}>
                Cancel
              </Button>{' '}
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={submitted}
                style={{ backgroundColor: '#5489b6' }}
                onClick={() => {
                  this.setState({ submitted: true }, document.forms[0].submit());
                }}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </div >
    );
  };
}

export default withStyles(styles)(ManagerConfirmation);
