import React, {Component} from 'react';

const AuctionBanner = ({user, authorized}) => (
  <a
    href="#"
    data-title={user.nick_name || user.user_name}
    data-id={user.id}
    id="messageButton"
    data-toggle="modal"
    data-target={authorized && newUser ? '#messageModal' : ''}>
    <div className="banner">
      <div className="wrapper">
        <span className="title">Want to discuss growth?</span>
        <span className="submit">
          Express interest now! <i className="fas fa-envelope-o" />
        </span>
      </div>
    </div>
  </a>
);

export default AuctionBanner;
