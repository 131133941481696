import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get, take, isEmpty} from 'lodash';
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {Box, Grid, Modal, Paper} from "@material-ui/core";
import moment from 'moment';
import {Redirect} from "react-router";
import Button from "@material-ui/core/Button";
import MUIDataTable from "mui-datatables";
import numeral from 'numeral';
import Typography from "@material-ui/core/Typography";
import {FormattedMessage, injectIntl} from "react-intl";

const CfaIndex = ({result, classes, intl, company}) => {
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(-1);

  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const handleOpen = id => {
    setOpen(true);
    setSelected(id);
  };
  const handleClose = () => {
    setOpen(false);
    setSelected(-1);
  };
  const list = get(result, 'cashFlows', []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

   const columns = [
      {
        name: "name",
        label: intl.formatMessage({id: "calculators.cfa.name"}),
        options: {
          sort: false,
        }
      },
      {
        name: "createdAt",
        label: intl.formatMessage({id: "calculators.cfa.date_added"}),
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "actions",
        label: "Actions",
        options: {
          filter: true,
          sort: false,
        }
      },
    ];

    const data = list.map(cashFlow => ({
      ...cashFlow,
      name: <React.Fragment>
        <a href={`/calculators/cfa/${cashFlow.friendlyName}`}><h5 className={classes.name}>{cashFlow.name}</h5></a>
        <figure className={classes.details}>{cashFlow.numberOfBuyers} {intl.formatMessage({id: "calculators.cfa.buyer"})}{cashFlow.numberOfBuyers > 1 ?  's' : ''}; {cashFlow.cashFlowLength} {<FormattedMessage id="calculators.cfa.year_cash_flow" />} </figure>
        <div><span className="tag price">Seller Annual Earnings: {numeral(cashFlow.sellerAverageCashFlow).format(dollarFormat)}</span>   <span className={`${classes.green} tag price`}>Buyer Annual Earnings: {numeral(cashFlow.buyer1AverageCashFlow).format(dollarFormat)}</span>
          &nbsp;
          { cashFlow.numberOfBuyers > 1  && <span className={`${classes.green} tag price`}>Buyer Annual Earnings: {numeral(cashFlow.buyer2AverageCashFlow).format(dollarFormat)}</span>}
        </div>
      </React.Fragment>,
      createdAt: moment(cashFlow.createdAt).format(intl.locale == 'fr' ? 'DD MMMM YYYY' : 'MMMM DD, YYYY'),
      actions: <React.Fragment>
        <a href={`/calculators/cfa/${cashFlow.friendlyName}?print=true`} className={classes.link}><i className="fas fa-download"></i> <FormattedMessage id="calculators.cfa.download" /></a>
        <a href={`/calculators/cfa/${cashFlow.friendlyName}`} className={classes.link}><i className="fas fa-pencil-alt"></i> <FormattedMessage id="calculators.cfa.edit" /></a>
        <a onClick={() => {handleOpen(cashFlow.friendlyName)}} className={classes.link}><i className="fa fa-trash" style={{color: 'red'}}></i> <FormattedMessage id="calculators.cfa.delete"/></a>
      </React.Fragment>,
    }));

    const options = {
      filterType: 'none',
      filter: false,
      download: false,
      search: false,
      print: false,
      selectToolbarPlacement: 'none',
      viewColumns: false,
      selectableRows: 'none',
    };

   if(isEmpty(result) || list.length > 0) {
     return <React.Fragment>
       <Grid>
       <h2><FormattedMessage id="calculators.cfa.my_saved_cash_flow" values={{cash_flow_analysis_word: get(company, 'company.data.cash_flow_analysis_word')}}></FormattedMessage> <Button href='/calculators/cfa/start' variant="contained" className="right" ><FormattedMessage id="calculators.cfa.create_new_analysis" /></Button></h2>
       <MUIDataTable
         title={null}
         data={data}
         columns={columns}
         options={options}
       />

       </Grid>

       <Modal
         open={open}
         onClose={handleClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
       >
         <Box sx={style}>
           <Typography id="modal-modal-description" sx={{ mt: 2 }}>
             <FormattedMessage id="calculators.cfa.please_click_delete" />
           </Typography>
           <Button className="right" style={{cursor: 'pointer'}} variant="contained" href={`/calculators/cfa/${selected}/delete`}><FormattedMessage id="calculators.cfa.delete" /></Button>
           <Button className="right" style={{cursor: 'pointer', marginRight: 4}} variant="contained" color="secondary" onClick={handleClose}><FormattedMessage id="calculators.cfa.cancel" /></Button>
         </Box>
       </Modal>
     </React.Fragment>;
   } else {
      return <Redirect to='/calculators/cfa/wizard' />;
   }
};

CfaIndex.propTypes = {
    list: PropTypes.array,
};

CfaIndex.defaultProps = {
    list: [],
}

const styles = () => ({
    flow: {
        padding: 6,
        margin: 4,
    },
    date: {
        textAlign: 'right',
        color: '#455560',
    },
    green: {
      backgroundColor: 'green !important',
    },
    name: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 4,
    },
    details: {
      color: 'cadetblue',
      marginBottom: 4,
    },
    link: {
      marginRight: 8,
      cursor: 'pointer',
    }
});

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
    company : state.company,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CfaIndex)));


