import React, {forwardRef, useImperativeHandle} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {get, range} from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import {FormattedMessage, injectIntl} from "react-intl";

const FixedPayment = forwardRef(({list, query, classes, updateCfaQuery, intl}, ref) => {
    const validate = () => {
        let valid = true;

        if(query.buyerHasFixedPayments == undefined){
           valid = false;
        } else if(query.buyerHasFixedPayments) {
            if(!query.buyerFixedPaymentYears || parseFloat(query.buyerFixedPaymentYears) < 1) {
                valid = false;
            }

            if(!query.buyerFixedPaymentYears || parseFloat(query.buyerFixedPaymentYears) < 1) {
                valid = false;
            }
        }

        if(query.valid != valid) {
            updateCfaQuery({...query, valid});
        }
    }

    validate();
    return <div>
        <h2><FormattedMessage id="calculators.cfa.fixed_header"/></h2>
        <p>
            <FormattedMessage id="calculators.cfa.fixed_copy"/>
        </p>
        <p>
            <FormattedMessage id="calculators.cfa.fixed_copy_2"/>
        </p>
        <h3><FormattedMessage id="calculators.cfa.fixed_payments"/></h3>
            <FormLabel component="legend"><FormattedMessage id="calculators.cfa.will_there_be_fixed_payments"/></FormLabel>
            <RadioGroup row className="right" aria-label="numberOfBuyers" name="numberOfBuyers" value={`${query.buyerHasFixedPayments}`} onChange={e => {
                const buyerHasFixedPayments = Boolean("true" == e.target.value);
                let clear = {};
                if(!buyerHasFixedPayments) {
                    clear = {buyerHasFixedPayments: 0};
                }

                updateCfaQuery({...query, ...clear, buyerHasFixedPayments, })
            }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.yes_label"})} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.no_label"})} />
            </RadioGroup>
            {query.buyerHasFixedPayments &&
              <div className={classes.question}>
                  <dl>
                      <dt><FormattedMessage id="calculators.cfa.how_many_years_fixed"/></dt>
                      <dd>
                          <select onChange={e => {
                              updateCfaQuery({...query, buyerFixedPaymentYears: parseInt(e.target.value)})
                          }
                          }>
                              <option>{intl.formatMessage({id: "calculators.cfa.please_select_a_year"})}</option>
                              { range(1, 6, 1).map(year =>
                                  <option value={year} checked={query.buyerFixedPaymentYears == year}>{year}</option>
                              )}
                          </select>
                      </dd>
                      <dt><FormattedMessage id="calculators.cfa.fixed_payment_start_year"/></dt>
                      <dd>
                          <select onChange={e => {
                              updateCfaQuery({...query, buyerFixedPaymentStartYear: parseInt(e.target.value)})
                              }
                          }>
                              <option>{intl.formatMessage({id: "calculators.cfa.please_select_a_year"})}</option>
                          { range(1, 6, 1).map(year =>
                            <option value={year} checked={query.buyerFixedPaymentStartYear == year}>{year}</option>
                          )}
                      </select>
                      </dd>
                      <br/>
                      <dt><FormattedMessage id="calculators.cfa.fixed_payment_per_year"/></dt>
                      <dd><CurrencyInput
                        style={{width: 'inherit'}}
                        value={query.buyerFixedPaymentAmount}
                        onValueChange={value => {
                            updateCfaQuery({...query, buyerFixedPaymentAmount: parseFloat(value)})
                        }}
                      /></dd>
                      <br/>
                  </dl>
              </div>
            }
    </div>
});

FixedPayment.propTypes = {
    query: PropTypes.array,
    classes: PropTypes.object.isRequired,
};

FixedPayment.defaultProps = {
    list: [],
}

const styles = () => ({
    question: {
        clear: 'both',
    },
});

const mapStateToProps = (state, props) => {
    return {
        result: {...get(state, 'cfa.result', {})},
        query: {...get(state, 'cfa.query', {})},
    };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(FixedPayment) ));