import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from '../actions/opportunityActionCreators';
import AdminWrapper from '../components/AdminWrapper/AdminWrapper';
import Applications from '../components/OpportunityAdmin/Applications/Applications';
import ListingInfo from '../components/OpportunityAdmin/ListingInfo/ListingInfo';

const OpportunityStatQueries = gql`
  query OpportunityQuery($id: ID) {
    getOpportunity(id: $id) {
      id
      title
      description
      ahoy_views
      status
      city
      province
      inquiries
      requirements
      expiry_date
      created_at
      activity_status
      applied
      user {
        id
        name
        birth_date
        province
        rev_yield
        clients
        total_premium
        total_assets
        key_asset
        risk_of_loss
        impact_of_loss
        profile_picture
        transition_goals
        has_user
        user {
          headline
          bio
          annual_revenue
          recurring_revenue
          assets_under_management
          number_of_clients
        }
      }
      opportunity_type {
        id
        name
        icon_url
        background_url
      }
      applications {
        user {
          name
          user_name
          province
          birth_date
          years_practicing
          new_in_industry
        }
        description
        status
        id
        reason
      }
    }
    getCurrentCandidate {
      id
      has_postal_code
      practice_complete
      strengths_complete
      has_candidate_profile
    }
    availableDesignations {
      id
      name
      fr_name
    }
    availableStrengths
  }
`;

export const OpportunityStatContainer = ({ opportunity, match, updateOpportunity }) => (
  <AdminWrapper title="Opportunity - View">
    <Query query={OpportunityStatQueries} variables={{ id: match.params.id }}>
      {({ loading, error, data }) => {
        if (loading) return <CircularProgress />;
        if (error) return `Error!: ${error}`;
        if (!opportunity.id) {
          updateOpportunity(data.getOpportunity);
          return <div />;
        }
        return (
          <div>
            <ListingInfo opportunity={opportunity} />
            <Applications applications={opportunity.applications} />
          </div>
        );
      }}
    </Query>
  </AdminWrapper>
);

const mapStateToProps = state => ({
  opportunity : get(state.opportunities, 'opportunity', {}),
  intl        : state.intl,
});

OpportunityStatContainer.propTypes = {
  opportunity       : PropTypes.object,
  match             : PropTypes.object.isRequired,
  updateOpportunity : PropTypes.func.isRequired,
};

OpportunityStatContainer.defaultProps = {
  opportunity : {},
};

export default connect(mapStateToProps, actions)(graphql(OpportunityStatQueries)(OpportunityStatContainer));
