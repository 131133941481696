import React, { Component } from 'react';
import PropTypes from 'prop-types';

const Location = ({ newUser, city, province, county = '', intl, getUser, countryForCompany, companyHasCounty }) => {
  const user = getUser;
  if (countryForCompany === 'us' && companyHasCounty && !user.show_city) {
    if (county) {
      return (
        <span>
          <span className="fa fa-map-marker " />{' '}
          <strong>
            <small> {`${county} County, ${province}`} </small>
          </strong>
          <br />
        </span>
      );
    } else if (city) {
      return (
        <span>
          <span className="fa fa-map-marker " />{' '}
          <strong>
            <small> {`${province}`} </small>
          </strong>
          <br />
        </span>
      );
    } else if (newUser) {
      return (
        <span>
          <span className="fa fa-map-marker " />{' '}
          <strong>
            <small>{`County, ${province}`}</small>
          </strong>
          <br />
        </span>
      );
    }
  } else if (city) {
    return (
      <span>
        <span className="fa fa-map-marker " />{' '}
        <strong>
          <small> {`${city}, ${province}`} </small>
        </strong>
        <br />
      </span>
    );
  } else if (newUser) {
    return (
      <span>
        <span className="fa fa-map-marker " />{' '}
        <strong>
          <small>
            {`${intl.formatMessage({
              id : 'shared.city',
            })}, ${province}`}
          </small>
        </strong>
        <br />
      </span>
    );
  }
  return <span />;
};

export default Location;
