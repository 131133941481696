import React from 'react';
import PropTypes from 'prop-types';

const UserName = ({ user }) => <div>{user.nick_name || `#${user.user_name}`}</div>;

UserName.propTypes = {
  user : PropTypes.object.isRequired,
};

export default UserName;
