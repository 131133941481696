import React from 'react';
import PropTypes from 'prop-types';
import { get, filter, capitalize, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import numeral from 'numeral';
import moment from 'moment';
import qs from 'query-string';
import {
  VictoryStack,
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
} from 'victory';

const colorScale = [
  '#337ab7',
  'rgb(0, 121, 107)',
  'silver',
  'rgb(25, 245, 157)',
  '#FF8A65',
  '#FFF176',
  '#AFB42B',
  '#BA68C8',
  '#BCAAA4',
  '#004D40',
];

class PipelineProjection extends React.Component {
  state = {
    yAxis        : 'value',
    status       : 'open',
    showRange    : 'quarter',
    dateRange    : 'this_year',
    selectedYear : moment().year(),
  };

  months = [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec' ];

  quarters = [ 'Q1', 'Q2', 'Q3', 'Q4' ];

  singleMonth = () => {
    const { dateRange } = this.state;
    let month = moment().month();
    if (dateRange === 'this_month') {
      month = moment().month();
    }
    if (dateRange === 'next_month') {
      month = moment().add(1, 'month').month();
    }
    if (dateRange === 'last_month') {
      month = moment().subtract(1, 'month').month();
    }
    return [ this.months[month] ];
  };

  getShowType = () => {
    const { dateRange, showRange } = this.state;
    if (dateRange === 'this_month' || dateRange === 'last_month' || dateRange === 'next_month') {
      if (showRange === 'quarter') {
        return 'allQuarters';
      }
      return 'singleMonth';
    }
    if (showRange === 'month') {
      return 'allMonths';
    }
    return 'allQuarters';
  };

  getTickType = () => {
    if (this.getShowType() === 'singleMonth') {
      return this.singleMonth();
    }
    if (this.getShowType() === 'allMonths') {
      return this.months;
    }
    return this.quarters;
  };

  setChartType = (finalChartData, finalMonthlyChartData, singleMonthData) => {
    if (this.getShowType() === 'singleMonth') {
      return singleMonthData;
    }
    if (this.getShowType() === 'allMonths') {
      return finalMonthlyChartData;
    }
    return finalChartData;
  };

  setBarWidth = () => {
    if (this.getShowType() === 'singleMonth') {
      return 200;
    }
    if (this.getShowType() === 'allMonths') {
      return 30;
    }
    return 80;
  };

  quarterDates = () => {
    const { selectedYear } = this.state;
    const quarterRange = [];
    const quart = [ 0, 3, 6, 9 ];
    for (let i = 0; i < quart.length; i += 1) {
      quarterRange.push(
        `${moment(selectedYear, 'YYYY').month(quart[i]).startOf('quarter').format('M/DD/YYYY')} to ${moment(
          selectedYear,
          'YYYY'
        )
          .month(quart[i])
          .endOf('quarter')
          .format('M/DD/YYYY')}`
      );
    }
    return quarterRange;
  };

  monthlyDates = () => {
    const { selectedYear, dateRange } = this.state;
    const monthRange = [];
    const currentSelectedMonth = this.singleMonth()[0];
    if (dateRange === 'this_month' || dateRange === 'last_month' || dateRange === 'next_month') {
      monthRange.push(
        `${moment(selectedYear, 'YYYY').month(currentSelectedMonth).startOf('month').format('M/DD/YYYY')} to ${moment(
          selectedYear,
          'YYYY'
        )
          .month(currentSelectedMonth)
          .endOf('month')
          .format('M/DD/YYYY')}`
      );
    } else {
      for (let i = 0; i < 12; i += 1) {
        monthRange.push(
          `${moment(selectedYear, 'YYYY').month(i).startOf('month').format('M/DD/YYYY')} to ${moment(
            selectedYear,
            'YYYY'
          )
            .month(i)
            .endOf('month')
            .format('M/DD/YYYY')}`
        );
      }
    }
    return monthRange;
  };

  formatStagesIntoQuarters = data => {
    const stageNames = {};
    const stageData = [];
    for (let i = 0; i < data.length; i += 1) {
      stageNames[data[i].name] = {};
      stageNames[data[i].name].q1 = data[i].pipeline_cards.filter(p => p.quarter === 1).map(g => g.value);
      stageNames[data[i].name].q2 = data[i].pipeline_cards.filter(p => p.quarter === 2).map(g => g.value);
      stageNames[data[i].name].q3 = data[i].pipeline_cards.filter(p => p.quarter === 3).map(g => g.value);
      stageNames[data[i].name].q4 = data[i].pipeline_cards.filter(p => p.quarter === 4).map(g => g.value);
      stageData.push(stageNames[data[i].name]);
    }
    return stageData;
  };

  formatChartData = data => {
    const charArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const chartData = [];
      for (let a = 1; a < 5; a += 1) {
        chartData.push({
          quarter : a,
          value   : !isEmpty(data[i][`q${a}`]) ? data[i][`q${a}`].reduce((b, c) => b + c) : 0,
          count   : !isEmpty(data[i][`q${a}`]) ? data[i][`q${a}`].length : 0,
        });
      }
      charArr.push(chartData);
    }
    return charArr;
  };

  formatStagesIntoMonths = data => {
    const stageNames = {};
    const stageData = [];
    const months_short = this.months;
    for (let i = 0; i < data.length; i += 1) {
      stageNames[data[i].name] = {};
      for (let a = 0; a < months_short.length; a += 1) {
        stageNames[data[i].name][months_short[a]] = data[i].pipeline_cards
          .filter(p => moment(p.close_date).month() === a)
          .map(g => g.value);
      }
      stageData.push(stageNames[data[i].name]);
    }
    return stageData;
  };

  formatMonthlyChartData = data => {
    const charArr = [];
    const months_short = this.months;
    for (let i = 0; i < data.length; i += 1) {
      const chartData = [];
      for (let a = 0; a < months_short.length; a += 1) {
        chartData.push({
          month : months_short[a],
          value : !isEmpty(data[i][months_short[a]]) ? data[i][months_short[a]].reduce((c, d) => c + d) : 0,
          count : !isEmpty(data[i][months_short[a]]) ? data[i][months_short[a]].length : 0,
        });
      }
      charArr.push(chartData);
    }
    return charArr;
  };

  formatSingleMonthData = data => {
    const monthNum = this.months.indexOf(this.singleMonth()[0]);
    const charArr = [];
    for (let i = 0; i < data.length; i += 1) {
      const chartData = data[i][monthNum];
      charArr.push([ chartData ]);
    }
    return charArr;
  };

  renderAxis = y => {
    const { yAxis } = this.state;
    if (yAxis === 'value') {
      return numeral(y).format('$0,') === '$NaN' ? '$0' : numeral(y).format('$0,a');
    }
    return numeral(y).format('0') === 'NaN' ? '0' : numeral(y).format('0');
  };

  sanitizeStages = stages => {
    const new_stages = [];
    for (let i = 0; i < stages.length; i += 1) {
      new_stages.push({
        ...stages[i],
        pipeline_cards : stages[i].pipeline_cards.map(d => ({
          ...d,
          quarter : moment(d.close_date).quarter(),
        })),
      });
    }
    return new_stages;
  };

  render () {
    const { classes, refetch } = this.props;
    const { yAxis, status, dateRange, showRange } = this.state;
    const pipelines = get(this.props, 'data.pipelines', []);
    const pipeline = get(this.props, 'data.pipeline', {});
    const all_stages = get(pipeline, 'pipeline_stages', []);

    const stages = all_stages.map(s => {
      if (dateRange) {
        let startDate = '';
        let endDate = '';
        switch (dateRange) {
          case 'this_month':
            startDate = moment().startOf('month');
            endDate = moment().endOf('month');
            break;
          case 'last_month':
            startDate = moment().subtract(1, 'month').startOf('month');
            endDate = moment().subtract(1, 'month').endOf('month');
            break;
          case 'next_month':
            startDate = moment().add(1, 'month').startOf('month');
            endDate = moment().add(1, 'month').endOf('month');
            break;
          case 'this_year':
            startDate = moment().startOf('year');
            endDate = moment().endOf('year');
            break;
          case 'last_year':
            startDate = moment().subtract(1, 'year').startOf('year');
            endDate = moment().subtract(1, 'year').endOf('year');
            break;
          case 'next_year':
            startDate = moment().add(1, 'year').startOf('year');
            endDate = moment().add(1, 'year').endOf('year');
            break;
          default:
            startDate = moment().startOf('year');
            endDate = moment().endOf('year');
        }
        return {
          ...s,
          pipeline_cards : filter(
            s.pipeline_cards,
            c =>
              moment(c.close_date).isAfter(startDate, 'YYYY-MM-DD') &&
              moment(c.close_date).isBefore(endDate, 'YYYY-MM-DD') &&
              (status === 'all' || c.status === status)
          ),
        };
      }
      return s;
    });

    const sanitizedStages = this.sanitizeStages(stages);
    const quarteredStages = this.formatStagesIntoQuarters(sanitizedStages);
    const monthlyStages = this.formatStagesIntoMonths(sanitizedStages);
    const finalChartData = this.formatChartData(quarteredStages);
    const finalMonthlyChartData = this.formatMonthlyChartData(monthlyStages);
    const singleMonthData = this.formatSingleMonthData(finalMonthlyChartData);

    const params = qs.parse(location.search);
    if (params.pipeline_id) {
      refetch({ id: params.pipeline_id });
      window.history.pushState({}, document.title, '/admin/reports/pipeline_summary');
    }

    const totalValue = stages.reduce(
      (sum, s) => sum + get(s, 'pipeline_cards', []).reduce((d, c) => d + parseInt(c.value, 10), 0),
      0
    );
    const totalCases = stages.reduce((sum, s) => sum + get(s, 'pipeline_cards.length', 0), 0);
    return (
      <div className={`col-md-12 col-sm-12 ${classes.container}`}>
        <h4 className={classes.subHeader}>
          The projected value of your upcoming pipeline, based on Activity close date.
        </h4>
        <Grid container>
          <Grid>
            <h2 className={classes.bigNumber}>
              {numeral(totalValue).format('$0a')} <span className={classes.numberLabel}>Total Value</span>
            </h2>
          </Grid>
          <Grid>
            <h2 className={classes.bigNumber}>
              &nbsp;{totalCases} <span className={classes.numberLabel}>Cases</span>
            </h2>
          </Grid>
        </Grid>
        <div className={classes.menu}>
          <Select
            classes={{ select: classes.selectatron }}
            value={pipeline.id}
            onClick={e => {
              if (e.target.value) {
                refetch({ id: e.target.value });
              }
            }}>
            {pipelines.map(p => <MenuItem value={p.id}>{decodeURI(p.name)}</MenuItem>)}
          </Select>
          &nbsp;
          <RadioGroup
            aria-label="Y-Axis"
            name=""
            value={yAxis}
            onChange={e => {
              this.setState({ yAxis: e.target.value });
            }}
            row>
            <FormControlLabel
              style={{ marginTop: 5 }}
              value="value"
              control={<Radio />}
              label="Total Value"
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ marginTop: 5 }}
              value="count"
              control={<Radio />}
              label="Count"
              labelPlacement="end"
            />
          </RadioGroup>
          &nbsp;Close Date Range: &nbsp;
          <Select
            style={{ width: 200, marginRight: 50 }}
            value={dateRange}
            onChange={e => {
              this.setState({ dateRange: e.target.value });
              if (e.target.value === 'last_year') {
                this.setState({ selectedYear: moment().subtract(1, 'year').year() });
              }
              if (e.target.value === 'next_year') {
                this.setState({ selectedYear: moment().add(1, 'year').year() });
              }
              if (e.target.value === 'next_month') {
                this.setState({ selectedYear: moment().add(1, 'month').year() });
              }
              if (e.target.value === 'last_month') {
                this.setState({ selectedYear: moment().subtract(1, 'month').year() });
              }
              if (e.target.value === 'this_year') {
                this.setState({ selectedYear: moment().year() });
              }
              if (e.target.value === 'this_month') {
                this.setState({ selectedYear: moment().year() });
              }
            }}
            input={<OutlinedInput name="age" id="outlined-age-simple" />}>
            <MenuItem value="this_month">This Month</MenuItem>
            <MenuItem value="last_month">Last Month</MenuItem>
            <MenuItem value="next_month">Next Month</MenuItem>
            <MenuItem value="this_year">This Year</MenuItem>
            <MenuItem value="last_year">Last Year</MenuItem>
            <MenuItem value="next_year">Next Year</MenuItem>
          </Select>
          &nbsp; &nbsp;
          <RadioGroup
            aria-label="Y-Axis"
            name=""
            value={showRange}
            onChange={e => {
              this.setState({ showRange: e.target.value });
            }}
            row>
            <FormControlLabel
              style={{ marginTop: 5 }}
              value="quarter"
              control={<Radio />}
              label="Quarterly"
              labelPlacement="end"
            />
            <FormControlLabel
              style={{ marginTop: 5 }}
              value="month"
              control={<Radio />}
              label="Monthly"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <VictoryChart
          width={1000}
          height={260}
          domainPadding={[ 80, totalCases < 3 ? 230 : 80 ]}
          padding={{ bottom: 50, left: 80, top: 20, right: 80 }}
          theme={VictoryTheme.material}>
          <VictoryAxis dependentAxis tickFormat={y => this.renderAxis(y)} orientation="left" />
          <VictoryAxis
            tickValues={this.getTickType()}
            tickFormat={t => `${capitalize(t)}`}
            orientation="bottom"
            tickLabelComponent={<VictoryLabel angle={22} textAnchor="start" />}
          />
          <VictoryStack>
            {this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData).map(c => (
              <VictoryBar colorScale={colorScale} data={c} x={showRange} y={yAxis} barWidth={this.setBarWidth()} />
            ))}
          </VictoryStack>
        </VictoryChart>
        <VictoryLegend
          height={30}
          x={120}
          y={0}
          orientation="horizontal"
          gutter={15}
          colorScale={colorScale}
          style={{ labels: { fontSize: 5 } }}
          data={stages.map(st => ({ name: decodeURI(st.name) }))}
        />

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Period</TableCell>
              {stages.map(st => <TableCell align="center">{decodeURI(st.name)}</TableCell>)}
              <TableCell align="center">Total Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(showRange === 'quarter' ? this.quarterDates() : this.monthlyDates()).map((q, i) => {
              return (
                <TableRow key={q}>
                  <TableCell align="center">{q}</TableCell>
                  {this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData).map(c => (
                    <TableCell align="center">
                      {yAxis === 'value' ? numeral(c[i][yAxis]).format('$ 0,0[.]00') : c[i][yAxis]}
                    </TableCell>
                  ))}
                  <TableCell align="center">
                    {yAxis === 'value' ? (
                      numeral(
                        this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData)
                          .map(c => c[i][yAxis])
                          .reduce((a, b) => a + b, 0)
                      ).format('$ 0,0[.]00')
                    ) : (
                      this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData)
                        .map(c => c[i][yAxis])
                        .reduce((a, b) => a + b, 0)
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell align="center">Total</TableCell>
              {this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData).map(s => (
                <TableCell align="center">
                  {yAxis === 'value' ? (
                    numeral(s.map(q => q[yAxis]).reduce((a, b) => a + b, 0)).format('$ 0,0[.]00')
                  ) : (
                    s.map(q => q[yAxis]).reduce((a, b) => a + b, 0)
                  )}
                </TableCell>
              ))}
              <TableCell align="center">
                {yAxis === 'value' ? (
                  numeral(
                    this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData)
                      .map(s => s.map(q => q[yAxis]))
                      .map(q => q.reduce((a, b) => a + b, 0))
                      .reduce((a, b) => a + b, 0)
                  ).format('$ 0,0[.]00')
                ) : (
                  this.setChartType(finalChartData, finalMonthlyChartData, singleMonthData)
                    .map(s => s.map(q => q[yAxis]))
                    .map(q => q.reduce((a, b) => a + b, 0))
                    .reduce((a, b) => a + b, 0)
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

PipelineProjection.propTypes = {
  startDate : PropTypes.string.isRequired,
  endDate   : PropTypes.string.isRequired,
  classes   : PropTypes.object.isRequired,
  refetch   : PropTypes.func.isRequired,
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
  formControl : {
    margin   : 2,
    minWidth : 120,
  },
};
export default withStyles(styles)(PipelineProjection);
