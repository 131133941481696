import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TitleAndDescription from './TitleAndDescription';
import Requirements from './Requirements';
import PreviewCard from './PreviewCard';
import UserDetailCard from '../UserDetailCard/UserDetailCard';
import Button from '@material-ui/core/Button';

const NewOpportunityDetails = ({ opportunity, updateOpportunity, opportunityTypes, mutate, updateStatus }) => (
  <Grid container spacing={24}>
    <Grid item xs={20} sm={9}>
      <TitleAndDescription
        opportunity={opportunity}
        updateAction={updateOpportunity}
        opportunityTypes={opportunityTypes}
      />
      <Requirements opportunity={opportunity} updateAction={updateOpportunity} />
      <PreviewCard opportunity={opportunity} mutate={mutate} updateStatus={updateStatus} />
    </Grid>
    <Grid item xs={4} sm={3}>
      <UserDetailCard opportunity={opportunity} />
    </Grid>
  </Grid>
);

NewOpportunityDetails.propTypes = {
  opportunity       : PropTypes.object,
  updateOpportunity : PropTypes.func.isRequired,
  opportunityTypes  : PropTypes.array,
  updateStatus      : PropTypes.func.isRequired,
};

NewOpportunityDetails.defaultProps = {
  opportunity      : {},
  opportunityTypes : [],
};
export default NewOpportunityDetails;
