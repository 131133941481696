import PropTypes from 'prop-types';
import React from 'react';
import { get } from 'lodash';

class LoadCard extends React.Component {
  componentDidMount = async () => {
    const { user, updateSelectedInventoryUser, InventoryUserQuery, client } = this.props;
    const result = await client.query({
      query       : InventoryUserQuery,
      variables   : { id: user },
      fetchPolicy : 'network-only',
    });
    updateSelectedInventoryUser({
      user : get(result, 'data.getInventoryUser'),
    });
  };

  render () {
    return <div />;
  }
}

LoadCard.propTypes = {
  updateSelectedInventoryUser : PropTypes.func.isRequired,
  user                        : PropTypes.string.isRequired,
  InventoryUserQuery          : PropTypes.object.isRequired,
  client                      : PropTypes.object.isRequired,
};

export default LoadCard;
