import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';

export default function BackToContinuityButton({ deal, stylesheetName }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!deal.continuity_builder_id) {
    return <span />;
  }
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="secondary"
        style={stylesheetName === 'rjpe' ? { color: '#5489B6', borderColor: '#5489B6' } : {}}
        onClick={handleClickOpen}>
          <FormattedMessage id="sdp_plan.back_to_continuity_builder" />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You are leaving the deal wizard. Any unsaved information will be lost. <br />
            Are you sure you want to leave?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              window.location.href = '/continuity/builder';
              handleClose();
            }}
            color="primary"
            autoFocus>
            Yes, Take Me To The Continuity Builder
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
