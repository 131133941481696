import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

export const UpdateCurrentUser = gql`
  mutation updateCurrentUser($welcome_current_interest: String) {
    updateCurrentUser(welcome_current_interest: $welcome_current_interest)
  }
`;

class InterestBanner extends React.Component {
  constructor (props) {
    super(props);
    this.state = { value: null };
  }

  handleSelect = e => {
    this.setState({ value: e.target.value });
  };

  clickNext = () => {
    const { currentUser, updateUser, mutate } = this.props;
    const { value } = this.state;

    mutate({
      variables : {
        welcome_current_interest : value,
      },
    });

    updateUser({ ...currentUser, welcome_current_interest: value });
  };

  render () {
    const { classes } = this.props;
    const { value } = this.state;
    return (
      <div className="content-card" style={{ padding: 30 }}>
        <div className={classes.title}>
          What are you <i>most</i> interested in today?
        </div>
        <div className={classes.subTitle}>
          Choose one the options below and we&apos;ll suggest the best tips to get you started.
        </div>
        <hr />
        <FormControl component="fieldset">
          <RadioGroup aria-label="interest" name="interest" value={value} onChange={e => this.handleSelect(e)}>
            <FormControlLabel value="growing_my_business" control={<Radio />} label="Growing my Business" />
            <FormControlLabel
              value="establish_business_continuity"
              control={<Radio />}
              label="Establish Business Continuity"
            />
            <FormControlLabel value="finding_a_successor" control={<Radio />} label="Finding a Successor" />
            <FormControlLabel value="selling_my_business" control={<Radio />} label="Selling my Business" />
            <FormControlLabel value="buying_a_business" control={<Radio />} label="Buying a Business" />
          </RadioGroup>
        </FormControl>
        <div>
          <Button
            style={{ borderRadius: 0 }}
            className={classes.button}
            onClick={() => this.clickNext()}
            variant="contained"
            color="primary">
            Continue &gt;&gt;
          </Button>
        </div>
      </div>
    );
  }
}

InterestBanner.propTypes = {
  currentUser : PropTypes.object,
  updateUser  : PropTypes.func.isRequired,
  mutate      : PropTypes.func.isRequired,
  classes     : PropTypes.object.isRequired,
};

InterestBanner.defaultProps = {
  currentUser : {},
};

const styles = () => ({
  title    : {
    fontSize   : '24px',
    fontWeight : 100,
    width      : '70%',
  },
  subTitle : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  button   : {
    float           : 'right',
    textTransform   : 'none',
    backgroundColor : '#1396e2',
  },
});

export default withStyles(styles)(graphql(UpdateCurrentUser)(InterestBanner));
