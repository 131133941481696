import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/SendRounded';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';

const SupportRequestInputForm = ({ classes, intl, createSupportRequest, refetch, changeConversation }) => {
  const [body, setBody] = useState('');
  const disableChat = false;
  return (
    <div>
      <h1>{intl.formatMessage({id: 'support_request.support_request_input_form'})}</h1>
      <div id="messageList" className={classes.messageList}>
        <p>
          {intl.formatMessage({id: 'support_request.input_form_copy'})}
        </p>
      </div>
      <TextField
        disabled={disableChat}
        variant="outlined"
        type="text"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        multiline
        placeholder={disableChat ? intl.formatMessage({ id: 'messages_section.chat_has_been_disabled' }) : intl.formatMessage({ id: 'messages_section.type_message_here_placeholder' })}
        fullWidth
        inputProps={{
          style: {
            height: 100,
            overflow: 'auto',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!disableChat && (
                <IconButton onClick={() => {
                  createSupportRequest({variables: {message: body}}).then(response => {
                    refetch();
                    changeConversation(response.data.createSupportRequest.conversation.id);
                  })
                }}>
                  <SendIcon color="primary" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const styles = theme => ({
  chatroomContainer: {
    padding: 12,
  },
  messageList: {
    overflowY: 'scroll',
    height: '400px',
  },
  standaloneList: {
    overflowY: 'scroll',
    height: 'calc(100vh - 416px)',
    width: '100%',
  },
  loadButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  listItem: {
    padding: 8,
  },
  blockButton: {
    float: 'right',
    top: '-12px',
    position: 'relative',
  },
});

export default withStyles(styles)(injectIntl(SupportRequestInputForm));