import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

export const TagSearchFieldContainer = ({ isMulti, tags, label, onChange, value }) => (
  <CreatableSelect isMulti={isMulti} options={tags} label={label} onChange={onChange} value={value} />
);

TagSearchFieldContainer.propTypes = {
  isMulti  : PropTypes.bool,
  label    : PropTypes.string,
  onChange : PropTypes.func.isRequired,
  value    : PropTypes.string,
  tags     : PropTypes.object,
};

TagSearchFieldContainer.defaultProps = {
  isMulti : false,
  label   : '',
  value   : '',
  tags    : [],
};

export default TagSearchFieldContainer;
