import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import { currencies, intervals } from './Ticker';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  addButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  text      : {
    fontSize : '18px',
  },
  title     : {
    fontSize : 24,
    margin   : 12,
  },
});

class TickerNewFormDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  addRow = action => {
    const { name, label, model, resolver, color, french_label} = this.state;

    action({
      name,
      label,
      model,
      resolver,
      color,
      french_label,
    });
  };

  render () {
    const { classes, error, close, intl } = this.props;
    if (close) this.handleRequestClose();

    return (
      <div>
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>
            Add Ticker Entry
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              required
              id="name"
              label={intl.formatMessage({ id: 'shared.name' })}
              className={classes.textField}
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="label"
              label="Label"
              className={classes.textField}
              onChange={e => {
                this.setState({ label: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="model"
              label="Model"
              className={classes.textField}
              onChange={e => {
                this.setState({ model: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="resolver"
              label="Resolver"
              className={classes.textField}
              onChange={e => {
                this.setState({ resolver: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="french_label"
              label="French Label"
              className={classes.textField}
              onChange={e => {
                this.setState({ resolver: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="color"
              label="Color"
              className={classes.textField}
              onChange={e => {
                this.setState({ resolver: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}
              disabled={!this.state.name}>
              Add
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

const TickerNewFormDialogWithStyles = withStyles(styles)(TickerNewFormDialog);
export default injectIntl(TickerNewFormDialogWithStyles);
