import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

const Languages = ({ classes, user, obfuscateView }) => {
  if (get(user, 'languages').length) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.languages"/></strong>
        {!obfuscateView && <p>{get(user, 'languages').join(', ')}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default withStyles(styles)(Languages);
