import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { map, find, isEmpty, get } from 'lodash';
import moment from 'moment';

const defaultFilterListStyles = {
  root: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    margin: '0px 16px 0px 16px',
  },
  chip: {
    margin: '8px 8px 0px 0px',
  },
};

const FBFilterList = ({ classes, filterList, filterUpdate, columns }) => (
  <div className={classes.root}>
    {map(filterList || {}, (item, index) => {
      const column = find(columns, { id: index });
      if (get(column, 'filterType', '') === 'date') {
        if (item[0] && item[1])
          return (
            <Chip
              label={`${column.label}: ${item.join(' - ')}`}
              key={column.id}
              onDelete={() => {
                filterUpdate(column, []);
              }}
              className={classes.chip}
            />
          );
        else return;
      }
      if (get(column, 'filterType', '') === 'range') {
        if (item[0] && item[1])
          return (
            <Chip
              label={`${column.label}: ${item.join(' - ')}`}
              key={column.id}
              onDelete={() => {
                filterUpdate(column, []);
              }}
              className={classes.chip}
            />
          );
        else return;
      }
      if (get(column, 'filterType', '') === 'manager') {
        return (item || []).map((data, colIndex) => (
          <Chip
            label={`${column.label}: ${data.name}`}
            key={colIndex}
            onDelete={() => {
              const a = item;
              a.splice(a.indexOf(data), 1);
              filterUpdate(column, a);
            }}
            className={classes.chip}
          />
        ));
      }
      if (get(column, 'filterType', '') === 'text') {
        if (Array.isArray(item)) {
          return <span></span>;
        }
        return [item].filter(Boolean).map((data, colIndex) => (
          <Chip
            label={`${column.label}: ${item.replaceAll('%', '')}`}
            key={colIndex}
            onDelete={() => {
              const a = null;
              filterUpdate(column, a);
            }}
            className={classes.chip}
          />
        ));
      }
      return item.map((data, colIndex) => (
        <Chip
          label={`${column.label}: ${column.filterOptions ? find(column.filterOptions, { value: data }).name : data}`}
          key={colIndex}
          onDelete={() => {
            const a = item;
            a.splice(a.indexOf(data), 1);
            filterUpdate(column, a);
          }}
          className={classes.chip}
        />
      ));
    })}
  </div>
);

FBFilterList.propTypes = {
  filterList: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  filterUpdate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(defaultFilterListStyles)(FBFilterList);
