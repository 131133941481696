import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import StarSigns from '../UserDetails/StarSigns';
import InfoBox from './InfoBox';
import { isEmpty, capitalize, replace, get} from 'lodash';
import { ProductMix, OldProductMix } from '../UserCharts';
import numeral from 'numeral';

const PandLData = ({ classes, user }) => (
  <Grid container spacing={0}>
    <Grid item xs={6}>
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <InfoBox title="Annual Revenue">
            {capitalize(numeral(get(user, 'annual_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Recurring Revenue">
            {capitalize(numeral(get(user, 'recurring_revenue', 0)).format('$ 0 a'))}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="AUM / AUA">
            {user && user.product_mix ? (
              <StarSigns number={get(user, 'product_mix.aum_stars', 0)} />
            ) : (
              <div>
                {get(user, 'assets_under_management', 0) >= 1000000 ? (
                  numeral(get(user, 'assets_under_management', 0)).format('($0.00a)')
                ) : (
                  numeral(get(user, 'assets_under_management', 0)).format('($0a)')
                )}
              </div>
            )}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Life & Health">
            {user && user.product_mix ? (
              <StarSigns number={get(user, 'product_mix.health_and_life_stars', 0)} />
            ) : (
              <div>{capitalize(numeral(get(user, 'life_ifp', 0) + get(user, 'home_ifp', 0)).format('$ 0 a'))}</div>
            )}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="P & C">
            {user && user.product_mix ? (
              <StarSigns number={get(user, 'product_mix.p_c_stars', 0)} />
            ) : (
              <div>
                {capitalize(
                  numeral(get(user, 'auto_ifp', 0) + get(user, 'commercial_ifp', 0) + get(user, 'home_ifp', 0)).format(
                    '$ 0 a'
                  )
                )}
              </div>
            )}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Group">
            {user && user.product_mix ? (
              <StarSigns number={get(user, 'product_mix.group_stars', 0)} />
            ) : (
              <div>{capitalize(numeral(get(user, 'group_ifp', 0)).format('$ 0 a'))}</div>
            )}
          </InfoBox>
        </Grid>
        <Grid item xs={4}>
          <InfoBox title="Clients">{get(user, 'number_of_clients', 'N/A')}</InfoBox>
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <div className={classes.prodmixborder}>
            <Typography className={classes.prodmixtitle} type="headline">
              Product Mix
            </Typography>
            {user && user.product_mix ? (
              <ProductMix
                life_ifp={user.product_mix.life_ifp}
                home_ifp={user.product_mix.home_ifp}
                auto_ifp={user.product_mix.auto_ifp}
                commercial_ifp={user.product_mix.commercial_ifp}
                health_ifp={user.product_mix.health_ifp}
                group_ifp={user.product_mix.group_ifp}
                mutual_funds_aum={user.product_mix.mutual_funds_aum}
                securities_aum={user.product_mix.securities_aum}
                annuities_aua={user.product_mix.annuities_aua}
                segregated_funds_aua={user.product_mix.segregated_funds_aua}
                mcpi_ifp={user.product_mix.mcpi_ifp}
                private_products_ifp={user.product_mix.private_products_ifp}
                crop_hail_ifp={user.product_mix.crop_hail_ifp}
                crop_hail_with_wind_ifp={user.product_mix.crop_hail_with_wind_ifp}
                farm_insurance_ifp={user.product_mix.farm_insurance_ifp}
                price_products_ifp={user.product_mix.price_products_ifp}
                replant_supplement_ifp={user.product_mix.replant_supplement_ifp}
                availableProducts={
                  '{"life_and_health":["life_ifp","health_ifp"],"aum":["mutual_funds_aum","securities_aum","annuities_aua","segregated_funds_aua"],"p_and_c":["auto_ifp","commercial_ifp","home_ifp"],"group":["group_ifp"]}'
                }
                itemsPerRow={3}
                width={600}
              />
            ) : (
              <OldProductMix
                insurance={get(user, 'mix_insurance')}
                segregatedFunds={get(user, 'mix_annuities')}
                mutualFunds={get(user, 'mix_mutual_funds')}
                other={get(user,'mix_other')}
                colorScale={[
                  '#337ab7',
                  'rgb(0, 121, 107)',
                  '#C83803',
                  'gold',
                  '#ec008c',
                  '#68217a',
                  '#00bcf2',
                  '#00b294',
                  '#00188f',
                  '#ff8c00',
                ]}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

InfoBox.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  prodmixborder  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 330,
    width        : 230,
  },
  prodmixtitle   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
  },
  prodmixcontent : {
    textAlign : 'center',
    margin    : 7,
  },
};

export default withStyles(styles)(PandLData);
