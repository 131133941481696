import gql from 'graphql-tag';
import client from '../lib/apolloClient';

const sendAnalyticsEvent = gql`
  mutation sendAnalyticsEvent($eventName: String!, $customProps: Json) {
    sendAnalyticsEvent(eventName: $eventName, customProps: $customProps)
  }
`;

export const trackAnalyticsEvent = (name, customProps) => {
  client
    .mutate({
      variables: {
        eventName: name,
        customProps: JSON.stringify(customProps),
      },
      mutation: sendAnalyticsEvent,
    })
    .then(response => {
      // console.log(response);
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
    });
};
