import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { isEmpty, filter, toLower, intersection } from 'lodash';
import OpportunityCard from '../../components/OpportunityCard/OpportunityCard';

const styles = {
  paper     : {
    maxWidth : 825,
    margin   : '40px auto 600px auto',
    padding  : 10,
  },
  box       : {
    display : 'inline-block',
  },
  textField : {
    background : 'none',
    fontSize   : 16,
  },
};

class OpportunityCardList extends Component {
  state = { types: [], searchText: '' };

  filterOpportunities = opportunities => {
    let filteredList = opportunities;

    if (!isEmpty(this.state.types)) {
      filteredList = filter(filteredList, o => intersection([ o.opportunity_type.id ], this.state.types).length > 0);
    }
    const searchText = toLower(this.state.searchText);
    if (!isEmpty(this.state.searchText)) {
      filteredList = filter(filteredList, o => toLower(`${o.title} ${o.description})`).indexOf(searchText) > -1);
    }

    return filteredList;
  };

  toggleTransitionGoal = e => {
    const goal = e.target.value;
    let { types } = this.state;

    const i = types.indexOf(goal);

    if (i > -1) {
      types.splice(i, 1);
    } else {
      types.push(goal);
    }
    this.setState({ types: types });
  };

  render () {
    const { opportunities, opportunityTypes, intl, classes, embed = false } = this.props;

    return (
      <Paper className={classes.paper}>
        <TextField
          type="text"
          fullWidth
          className={classes.textField}
          placeholder="Enter keywords for they type of opportunity you’re searching for"
          onChange={e => {
            this.setState({ searchText: e.target.value });
          }}
        />
        <br />
        <br />
        {opportunityTypes.map(o => {
          return (
            <span className={classes.box}>
              <Checkbox
                checked={this.state.types.indexOf(o.id) > -1}
                classes={{
                  root    : classes.checkbox,
                  checked : classes.checked,
                }}
                value={o.id}
                onClick={this.toggleTransitionGoal}
                key={o.id}
              />{' '}
              {o.name}
            </span>
          );
        })}
        <br />
        <br />
        {this.filterOpportunities(opportunities).map(o => (
          <OpportunityCard opportunity={o} intl={intl} linkTo={`/opportunities/${o.id}${embed ? '' : '?embed=true'}`} />
        ))}
      </Paper>
    );
  }
}

OpportunityCardList.propTypes = {
  opportunities    : PropTypes.array,
  opportunityTypes : PropTypes.array,
  intl             : PropTypes.object,
  classes          : PropTypes.object,
  embed            : PropTypes.bool,
};

OpportunityCardList.defaultProps = {
  embed : false,
};

export default withStyles(styles)(OpportunityCardList);
