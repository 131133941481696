import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core';
import { Query, ApolloProvider } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import PeProductSummaryReport from '../components/PeProductSummaryReport/PeProductSummaryReport';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      fee_based_revenue
      trailer_revenue
      direct_revenue
      transactional_revenue
      company {
        insurance_commissions_information_tooltip
        trails_word      
      }
    }
  }
`;

const PeProductSummaryReportContainer = ({
  classes,
  alterItems = false,
  updateAction = () => {},
  stylesheetName,
  updateUserState,
  disableRevenueSliders,
}) => (
  <ApolloProvider client={client}>
    <Query query={getCurrentUser}>
      {({ loading, data }) => {
        if (loading) return <CircularProgress />;
        if (data.getCurrentUser) {
          return (
            <div>
              <PeProductSummaryReport
              currentUser={data.getCurrentUser}
              classes={classes}
              alterItems={alterItems}
              updateAction={updateAction}
              stylesheetName={stylesheetName}
              updateUserState={updateUserState}
              disableRevenueSliders={disableRevenueSliders}
              />
            </div>
            
          );
        }
      }}
    </Query>
  </ApolloProvider>
);

PeProductSummaryReportContainer.propTypes = {
  classes : PropTypes.object.isRequired,
};

const styles = theme => ({
  toolTip  : {
    color : '#1396e2',
  },
  headline : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

export default withStyles(styles)(injectIntl(PeProductSummaryReportContainer));
