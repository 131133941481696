import gql from 'graphql-tag';

export const MatchProfileQuery = gql`
  query MatchProfileQuery($dealInterestId: ID!) {
    currentUserInfoAuth
    companyHasCounty(featureName: "show_county")
    countryForCompany
    agentWordForCompany
    getDealInterest(id: $dealInterestId) {
      id
      workflow_state
      invited
      saved
      shortlisted
      proposal
      proposal_letter
      receiving_party_nda_sent_timestamp
      conversation_id
      reason_for_suggestion
      proposal_meeting_availability
      q1
      q2
      q3
      q4
      q5
      a1
      a2
      a3
      a4
      a5
      deal_scheduled_meetings {
        id
        start_time
        duration
        format
      }
      deal {
        id
        headline
        deal_name
        closed
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
      }
      inventory_user {
        id
        affiliation
        branch
        division
        user {
          id
          user_name
          fb_user_name
          allowed_name
          allowed_phone
          can_view_real_name
          annual_revenue
          assets_under_management
          avg_client_age
          bio
          city
          county
          deleted
          show_city
          number_of_clients
          profile_picture
          province
          recurring_revenue
          created_at
          headline
          my_rating
          nick_name
          obfuscated
          is_pure_buyer
          is_admin
          designations
          tag_list
          primary_markets
          transition_goals
          in_auction
          years_practicing
          number_of_employees
          percent_revenue_recurring
          percent_non_recurring_revenue
          percent_fee_based
          percent_commission
          mix_insurance
          mix_mutual_funds
          mix_annuities
          mix_other
          note_count
          affiliations {
            id 
            name
          }
          notes {
            id
            comment
            title
            created_at
          }
          business_continuity_state
          languages
          defined_investment_style
          defined_service_model
          part_of_team
          team_size
          niche_market
          expressed_interest
          ask_me_about
          specialized_businesses
          discretionary_investment_models
          why_values
          gross_production
          product_mix {
            life_ifp
            home_ifp
            auto_ifp
            commercial_ifp
            health_ifp
            group_ifp
            mutual_funds_aum
            securities_aum
            annuities_aua
            segregated_funds_aua
            total_assets
            mcpi_ifp
            private_products_ifp
            crop_hail_ifp
            crop_hail_with_wind_ifp
            farm_insurance_ifp
            price_products_ifp
            replant_supplement_ifp
            gic_fixed_income
            insurance
            equity
          }
          company {
            id
            skip_product_mix_on_signup
            use_canadian_retail_wealth_product_mix
            use_p_and_c_product_mix
            enabled_student_profiles
            student_word
            student_program_title
          }
        }
      }
    }
    getCurrentUser {
      id
      user_name
      name
      locale
      roles
      has_selling_transition_goals
      limited_access
      is_admin
      company {
        id
        name
        branded_name
        seller_only_label
        stylesheet_name
        business_type
        allow_scheduling_in_deal_room
        deal_room_allow_messaging_when_shortlisted
        deal_room_branded_name
        deal_room_single_word
        use_canadian_retail_wealth_product_mix
        use_p_and_c_product_mix
        allow_blocking_in_messages
      }
    }
    getSpotlights(spotlights: ["nick_name", "notebook"])
    availableProducts
    availableStrengths
  }
`;

export default MatchProfileQuery;
