import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { updatePipelineActivityMutation } from '../PipelineQueries';

class EditCard extends React.Component {
  constructor (props) {
    super(props);
    const { activity } = props;

    this.defaultState = {
      note         : decodeURI(activity.note),
      activityDate : activity.activityDate,
    };
    this.state = { ...this.defaultState };
  }

  componentWillReceiveProps (props) {
    const { activity } = props;
    this.setState({ note: decodeURI(activity.note), activityDate: activity.activityDate });
  }

  onChange = e => {
    this.setState({ note: e.target.value });
  };

  render = () => {
    const { classes, intl, activity, mutate, refetch, onCancel } = this.props;
    const { note, activityDate } = this.state;

    return (
      <Card>
        <CardContent>
          <br />
          <TextField
            id="standard-multiline-flexible"
            label="Note"
            multiline
            fullWidth
            rowsMax="4"
            value={decodeURI(note)}
            onChange={this.onChange}
            className={classes.textField}
            placeholder={intl.formatMessage({ id: `notebook.create_a_new_note` })}
            margin="normal"
          />
          <div>
            <br />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                margin="normal"
                format="MMMM/dd/yyyy h:mma"
                value={activityDate}
                onChange={date => this.setState({ activityDate: date })}
              />
            </MuiPickersUtilsProvider>
          </div>
          <Button
            color="primary"
            className={classes.saveButton}
            onClick={() => {
              mutate({
                variables : {
                  id            : activity.id,
                  note          : encodeURI(note),
                  activity_date : activityDate,
                },
              }).then(() => {
                refetch();
                this.setState({ ...this.defaultState });
                onCancel();
              });
            }}>
            Save
          </Button>
          <Button className={classes.cancelButton} onClick={onCancel} color="default">
            Cancel
          </Button>
        </CardContent>
      </Card>
    );
  };
}

const styles = {
  saveButton    : {
    float  : 'right',
    margin : 8,
  },
  cancelButton  : {
    float  : 'right',
    margin : 8,
  },
  editorWrapper : {
    padding : '6px',
    display : 'block',
  },
  toolbar       : {
    padding : '6px',
    margin  : 0,
  },
  editor        : {
    padding      : 0,
    marginBottom : '12px',
  },
  textArea      : {
    minHeight : '80px',
  },
};

EditCard.propTypes = {
  classes  : PropTypes.object.isRequired,
  intl     : PropTypes.object.isRequired,
  activity : PropTypes.object.isRequired,
  mutate   : PropTypes.func.isRequired,
  refetch  : PropTypes.func.isRequired,
  onCancel : PropTypes.func.isRequired,
};

export default graphql(updatePipelineActivityMutation)(injectIntl(withStyles(styles)(EditCard)));
