import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ClickActivities from './ClickActivities';
import SearchActivities from './SearchActivities';
import OtherActivities from './OtherActivities';

class ClosedConversation extends React.Component {
  state = { showBody: false };

  clickSubject = () => {
    const { showBody } = this.state;
    this.setState({ showBody: !showBody });
  };

  render () {
    const { classes, firstName, initials, event, date, note, activity, currentUserIsAdmin } = this.props;
    const { showBody } = this.state;
    return (
      <Card className={classes.card}>
        <Grid container spacing={6}>
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </Grid>
          <Grid item xs={10}>
            {activity && <Typography className={classes.title}>{activity}</Typography>}
            <Typography className={classes.content} type="headline">
              {date}
            </Typography>
            {currentUserIsAdmin && (
              <div>
                <Typography className={classes.note} type="headline">
                  Subject
                </Typography>
                <Typography className={classes.message} type="headline">
                  <b>{event.properties.subject}...</b>
                </Typography>
                <Typography className={classes.message} type="headline">
                  <b>With the reason(s) of:</b>
                  {event.properties.reasons ? event.properties.reasons.map(r => <li>{r}</li>) : 'None Given'}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

ClosedConversation.propTypes = {
  classes            : PropTypes.object.isRequired,
  event              : PropTypes.object.isRequired,
  firstName          : PropTypes.string.isRequired,
  initials           : PropTypes.string.isRequired,
  date               : PropTypes.string.isRequired,
  note               : PropTypes.string.isRequired,
  activity           : PropTypes.string.isRequired,
  currentUserIsAdmin : PropTypes.bool.isRequired,
};

const styles = {
  card        : {
    borderRadius : 0,
    height       : 'auto',
    marginBottom : 1,
  },
  title       : {
    fontWeight : 'bold',
    textAlign  : 'left',
    marginTop  : 15,
    fontSize   : 18,
  },
  activity    : {
    textAlign : 'left',
    marginTop : 15,
    fontSize  : 18,
  },
  message     : {
    fontSize     : 16,
    marginBottom : 10,
  },
  messageBody : {
    paddingRight : 60,
  },
  content     : {
    textAlign : 'left',
    fontSize  : 16,
    color     : '#808080',
  },
  note        : {
    textAlign : 'left',
    fontSize  : 13,
    color     : '#000',
    marginTop : 8,
  },
  avatar      : {
    margin   : 20,
    fontSize : 25,
  },
  button      : {
    color      : 'black',
    fontWeight : 'bold',
    background : 'none',
    border     : 'none',
    padding    : '0px',
  },
};

export default withStyles(styles)(ClosedConversation);
