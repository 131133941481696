import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { partial } from 'lodash';

export const TOGGLE_PARTIAL_SALE_STATUS = gql`
  mutation TogglePartialSaleStatus($id: ID!, $sale_status: String!, $reason_for_close: String) {
    toggleStatusForPartialSale(id: $id, sale_status: $sale_status, reason_for_close: $reason_for_close) {
      id
      sale_status
      reason_for_close
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    left: '40%',
    top: '25%',
  },
}));

const PartialSaleStatusToggle = ({partialSale, setPartialSale, enqueueSnackbar}) => {
  const classes = useStyles();
  const [togglePartialSaleStatus, { data, loading, error }] = useMutation(TOGGLE_PARTIAL_SALE_STATUS);
  const [open, setOpen] = React.useState(false);
  const [sale_status, setSaleStatus] = React.useState(partialSale.sale_status);
  const [reason_for_close, setReasonForClose] = React.useState(partialSale.reason_for_close);

  return (
    <div>
      <Select
        value={partialSale.sale_status}
        native
        onChange={e => {
          setSaleStatus(e.target.value);
          if(e.target.value !== 'closed') {
            togglePartialSaleStatus({ variables: { id: partialSale.id, sale_status: e.target.value } }).then(result => {
              setPartialSale({...partialSale, sale_status: result.data.toggleStatusForPartialSale.sale_status });
              enqueueSnackbar('Sale status updated', { variant: 'success' });
              setOpen(false);
            });
          } else {
            setOpen(true);
          }
        }}
        inputProps={{
          name: 'sale_status',
          id: 'filled-age-native-simple',
        }}
      >
        <option value={'new'}>New</option>
        <option value={'open'}>Open</option>
        <option value={'closed'}>Closed</option>
      </Select>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className={classes.paper}>
          Please provide a reason for closing the sale
          <br/>
          <TextField
            id="sale-closed-reason"
            label="Reason"
            multiline
            rows={4}
            variant="outlined"
            value={reason_for_close}
            onChange={e => setReasonForClose(e.target.value)}
            fullWidth
          />
          <br/>
          <Button
            disabled={!Boolean(reason_for_close)}
            onClick={() => {
              togglePartialSaleStatus({ variables: { id: partialSale.id, sale_status, reason_for_close: reason_for_close} }).then(() => {
                setPartialSale({...partialSale, sale_status, reason_for_close});
                enqueueSnackbar('Sale status updated', { variant: 'success' });
                setOpen(false);
              });
            }}
          > 
            Save 
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default withSnackbar(PartialSaleStatusToggle);