import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Box, Tabs, Tab, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TabPanel from '../TabPanel/TabPanel';
import Grades from './Grades';
import PrintableGrades from './PrintableGrades';
import BuyerPerspective from './BuyerPerspective';
import BuyerNonOrdinaryPerspective from './BuyerNonOrdinaryPerspective';
import SellerPerspective from './SellerPerspective';
import * as cfaActionCreators from '../../actions/cfaActionCreators';
import { get } from 'lodash';
import qs from 'query-string';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import SaveCfaButton from "./SaveCfaButton";
import EditCfa from "./EditCfa";
import CfaList from "./CfaList";
import { FormattedMessage } from "react-intl";
import Disclaimer from './CfaWizard/Disclaimer';

const CfaComponent = ({ updateCfaQuery, result, query, classes, motivation, company }) => {
  const [value, setValue] = React.useState(motivation === 'succession' ? 0 : 1);
  const [printing, setPrinting] = React.useState(false);
  const [printedOnLoad, setPrintedOnLoad] = React.useState(false);
  const exportRef = useRef();
  const cf = get(result, 'cashFlow', {});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const print = () => {
    var opt = {
      pagebreak: { mode: ['avoid-all'], before: '.page_break', after: '.page_break2' },
      jsPDF: { unit: 'in', format: [11, 18], orientation: 'landscape' },
      margin: [0.1, 0.1, 0.1, 0.1]
    };
  
    const graphElementSeller = document.querySelector('.cfa_graph');
    const graphElementBuyer = document.querySelector('.cfa_graph_buyer');
    const cfaReferral = document.querySelector('.cfa_referral');
    const cfaReferralBuyer = document.querySelector('.cfa_referral_buyer');
    if (graphElementSeller || graphElementBuyer) {
      graphElementSeller.style.display = 'none';
      graphElementSeller.style.height = '0';
      graphElementSeller.style.width = '0';
      graphElementBuyer.style.display = 'none';
      graphElementBuyer.style.height = '0';
      graphElementBuyer.style.width = '0';
    }

    if (cfaReferral || cfaReferralBuyer) {
      cfaReferral.style.display = 'none';
      cfaReferral.style.height = '0';
      cfaReferral.style.width = '0';
      cfaReferralBuyer.style.display = 'none';
      cfaReferralBuyer.style.height = '0';
      cfaReferralBuyer.style.width = '0';
    }

    var worker = html2pdf().set(opt).from(exportRef.current).save("Cash_Flow_Analysis.pdf");
  
    worker.then(() => {
      if (graphElementSeller || graphElementBuyer) {
        graphElementSeller.style.display = '';
        graphElementSeller.style.height = '';
        graphElementSeller.style.width = '';
        cfaReferral.style.display = '';
        cfaReferral.style.height = '';
        cfaReferral.style.width = '';
        graphElementBuyer.style.display = '';
        graphElementBuyer.style.height = '';
        graphElementBuyer.style.width = '';
        cfaReferralBuyer.style.display = '';
        cfaReferralBuyer.style.height = '';
        cfaReferralBuyer.style.width = '';
      }
      if (cfaReferral || cfaReferralBuyer) {
        cfaReferral.style.display = '';
        cfaReferral.style.height = '';
        cfaReferral.style.width = '';
        cfaReferralBuyer.style.display = '';
        cfaReferralBuyer.style.height = '';
        cfaReferralBuyer.style.width = '';
      }
    });
  
    setPrinting(false);
  }

  const params = qs.parse(location.search);

  if (!printedOnLoad && params.print) {
    setPrintedOnLoad(true);
    setPrinting(true);
    setTimeout(print, 400);
  }

  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return (<React.Fragment>
    <h1>
      <FormattedMessage id="calculators.cfa.title" values={{ cash_flow_analysis_word: get(company, 'company.data.cash_flow_analysis_word') }} />
      <div className={classes.buttonBox}>
        <Button variant="contained" color="primary" className={classes.downloadButton} style={{ margin: 10 }} onClick={() => {
          setPrinting(true);
          setTimeout(print, 400);
        }}><FormattedMessage id="calculators.cfa.download_pdf" /></Button>
        <EditCfa />
      </div>
    </h1>
    <Grid container spacing={2}>
      
      <Grid item xs={12}>
        <div style={{ display: 'flex' }} className="cfa_buyer_seller_summaries">
          <Grades handleClick={handleChange} value={value} />
        </div>
        <div style={{ clear: 'both' }}>
          <Box sx={{ width: '100%' }}>
            <div>
              {!printing ?
                <div>
                  <TabPanel value={value} index={0}>
                    <SellerPerspective />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {showOrdinaryTaxes ? <BuyerPerspective index={1} /> : <BuyerNonOrdinaryPerspective index={1} />}
                  </TabPanel>
                  {get(result, 'cashFlow.numberOfBuyers', 0) > 1 && <TabPanel value={value} index={2}>
                    {showOrdinaryTaxes ? <BuyerPerspective index={2} /> : <BuyerNonOrdinaryPerspective index={2} />}
                  </TabPanel>}
                  <br />
                  <br />
                  <Disclaimer />
                </div>
                : 
                <div>
                  <span ref={exportRef} style={{ fontSize: 10, margin: 50 }}>
                    <PrintableGrades/>
                    <span style={{ margin: 40 }} className="printable">           
                      <SellerPerspective />
                      <br/>
                      <br/>
                    </span>
                    <span className="printable" style={{ margin: 40 }}>
                      {showOrdinaryTaxes ? <BuyerPerspective index={1} /> : <BuyerNonOrdinaryPerspective index={1} />}
                    </span>
                    {get(result, 'cashFlow.numberOfBuyers', 0) > 1 && 
                    <span className="printable" style={{ margin: 40 }}>
                      {showOrdinaryTaxes ? <BuyerPerspective index={2} /> : <BuyerNonOrdinaryPerspective index={2} />}
                    </span>}
                    <Disclaimer />
                  </span>
                </div>}
            </div>
          </Box>
        </div>
      </Grid>
    </Grid>
  </React.Fragment>)
};

const styles = {
  tab: {
    '& .MuiBox-root': {
      padding: '0px',
    },
    padding: '0px',
  },
  flexContainer: {
    width: '100%',
    justifyContent: 'space-between',
  },
  contentButton: {
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
  },
  viewAll: {
    position: 'absolute',
    bottom: '25px',
    right: '25%',
    left: '50%',
    marginLeft: '-150px',
    color: '#0052CC',
  },
  buttonBox: { 
    display: 'flex',
    justifyContent: 'stretch',
    position: 'absolute',
    right: '0',
    top: '3px',
    height: '56px',
    alignItems: 'center',
    width: '240px',
  },
  downloadButton: {
    height: '30px',
    width: '138px',
  }
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...state.cfa.result },
    query: { ...state.cfa.query },
    company: state.company,
  };
};

export default withStyles(styles)(connect(mapStateToProps, { ...cfaActionCreators, })(
  CfaComponent
));