import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Play from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import Stop from '@material-ui/icons/Stop';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const EditOpportunity = gql`
  mutation editOpportunity($id: ID!, $activity_status: Int) {
    editOpportunity(id: $id, activity_status: $activity_status)
  }
`;

const styles = {
  iconButton : {
    border       : 'solid #ddd 3px',
    borderRadius : '50%',
    height       : 40,
    width        : 40,
    minWidth     : 40,
    padding      : 0,
  },
};

const OPEN = 0;
const PAUSED = 1;
const CLOSED = 2;

const updateStatus = (mutate, opportunityId, status) => {
  mutate({
    variables : {
      id              : opportunityId,
      activity_status : status,
    },
  })
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      // eslint-disable-next-line
      alert('there was an error sending the query', error);
    });
};

const StatusControls = ({ classes, opportunity, mutate }) => (
  <div>
    {opportunity.activity_status !== OPEN && (
      <Button
        variant="outlined"
        className={classes.iconButton}
        onClick={() => updateStatus(mutate, opportunity.id, OPEN)}>
        <Play />
      </Button>
    )}
    {opportunity.activity_status !== PAUSED &&
    opportunity.activity_status !== CLOSED && (
      <Button className={classes.iconButton} onClick={() => updateStatus(mutate, opportunity.id, PAUSED)}>
        <Pause />
      </Button>
    )}
    {opportunity.activity_status !== CLOSED && (
      <Button className={classes.iconButton} onClick={() => updateStatus(mutate, opportunity.id, CLOSED)}>
        <Stop />
      </Button>
    )}
  </div>
);

StatusControls.propTypes = {
  classes     : PropTypes.object.isRequired,
  opportunity : PropTypes.object,
  mutate      : PropTypes.func.isRequired,
};

StatusControls.defaultProps = {
  opportunity : {},
};

export default graphql(EditOpportunity)(withStyles(styles)(StatusControls));
