import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as actions from '../actions/opportunityActionCreators';

import OpportunityList from '../components/OpportunityAdmin/OpportunityList/OpportunityList';

const OpportunityQueries = gql`
  query OpportunityQueries($rowsPerPage: Int, $order: String, $orderBy: String, $nameSearch: String, $page: Int) {
    getOpportunities(
      rowsPerPage: $rowsPerPage
      order: $order
      orderBy: $orderBy
      nameSearch: $nameSearch
      page: $page
    ) {
      id
      title
      description
      views
      status
      city
      province
      inquiries
      expiry_date
      user {
        id
        name
      }
      author {
        id
        user_name
      }
      opportunity_type {
        id
        name
      }
      number_of_applications
    }
    getOpportunitiesCount(
      rowsPerPage: $rowsPerPage
      order: $order
      orderBy: $orderBy
      nameSearch: $nameSearch
      page: $page
    )
  }
`;

const OpportunityListContainer = props => {
  const { opportunities, updateOpportunities } = props;
  const queryVars = {
    rowsPerPage : opportunities.rowsPerPage || 10,
    order       : opportunities.order || 'desc',
    orderBy     : opportunities.orderBy || 'id',
    page        : opportunities.page || 0,
  };
  return (
    <Query query={OpportunityQueries} variables={queryVars}>
      {({ loading, error, data, refetch }) => {
        if (!loading && opportunities.loaded === false) {
          updateOpportunities({
            order       : queryVars.order,
            orderBy     : queryVars.orderBy,
            data        : data.getOpportunities,
            page        : opportunities.page || 0,
            rowsPerPage : queryVars.rowsPerPage,
            count       : data.getOpportunitiesCount,
          });
        }
        return (
          <div>
            <OpportunityList {...props} refetch={refetch} loading={loading} error={error} />
          </div>
        );
      }}
    </Query>
  );
};

OpportunityListContainer.propTypes = {
  opportunities       : PropTypes.object.isRequired,
  updateOpportunities : PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  state,
  opportunities : state.opportunities,
  intl          : state.intl,
});

export default connect(mapStateToProps, actions)(OpportunityListContainer);
