import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import IconSelector from './IconSelector';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const CreateActivityTypeMutation = gql`
  mutation createActivityType($name: String, $iconUrl: String) {
    createActivityType(name: $name, icon_url: $iconUrl) {
      id
    }
  }
`;

class AddActivityButton extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render () {
    const { mutate, refetch } = this.props;
    const { open, name, iconUrl } = this.state;

    return (
      <div>
        <Fab style={{ position: 'fixed', bottom: 20, right: 20 }} onClick={this.handleClickOpen} color="primary">
          <AddIcon />
        </Fab>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Add An activity </DialogTitle>
          <DialogContent>
            <div>
              Icon Type: &nbsp;
              <IconSelector iconName={iconUrl} onIconChange={icon => this.handleChange('iconUrl', icon)} />
            </div>
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={e => {
                this.handleChange('name', e.target.value);
              }}>
              {name}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <IconButton
              disabled={!(name && iconUrl)}
              color="secondary"
              onClick={() => {
                mutate({
                  variables : {
                    name,
                    iconUrl,
                  },
                })
                  .then(() => {
                    refetch();
                    this.handleClose();
                  })
                  .catch(e => {
                    // eslint-disable-next-line
                    console.log(e);
                  });
              }}>
              Save
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddActivityButton.propTypes = {
  mutate  : PropTypes.func.isRequired,
  refetch : PropTypes.func.isRequired,
};

export default graphql(CreateActivityTypeMutation)(AddActivityButton);
