import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { get } from 'lodash';
import FBTableHead from './FBTableHead';
import FBTableToolbar from './FBTableToolbar';
import FBFilterList from './FBFilterList';

function desc (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const styles = theme => ({
  root         : {
    width     : '100%',
    marginTop : theme.spacing.unit * 3,
  },
  table        : {
    overflow    : 'scroll',
    maxHeight   : 'calc(100vh - 280px)',
    height      : 'calc(100vh - 280px)',
    display     : 'flow-root',
    tableLayout : 'auto',
  },
  tableWrapper : {
    width : '100%',
  },
  tableCell    : {
    height : '85px',
  },
  stickyColumn : {
    position   : 'sticky',
    left       : 0,
    zIndex     : 10,
    background : 'white',
  },
});

class FBTable extends React.Component {
  state = {
    order            : 'asc',
    orderBy          : 'calories',
    showFilterDialog : false,
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    const { data, loadBulkItemsList } = this.props;
    if (event.target.checked) {
      loadBulkItemsList(data.map(n => n.id));
      return;
    }
    loadBulkItemsList([]);
  };

  clearSelected = () => {
    const { loadBulkItemsList } = this.props;
    loadBulkItemsList([]);
  };

  handleClick = (event, id) => {
    const { loadBulkItemsList, bulkEditItems } = this.props;
    if (event.target.type !== 'checkbox') return;
    const selected = get(bulkEditItems, 'data', []);
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    loadBulkItemsList(newSelected);
  };

  isSelected = id => get(this.props, 'bulkEditItems.data', []).indexOf(id) !== -1;

  toggleFilterDialog = () => {
    const { showFilterDialog } = this.state;
    this.setState({ showFilterDialog: !showFilterDialog });
  };

  render () {
    const {
      classes,
      data,
      order,
      orderBy,
      rowsPerPage,
      page,
      count,
      columns,
      onSort,
      changePage,
      rowClick,
      changeRowsPerPage,
      client,
      onFilterChange,
      filterList,
      onDelete,
      onSearch,
      onDistanceSearch,
      allColumns,
      updateColumns,
      bulkEditDialog,
      allowDelete,
      heading,
      exportToCSVButton,
      currentUserIsAdmin,
      loading,
      hideColumnSelect,
      bulkEditItems,
    } = this.props;
    const { showFilterDialog } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <FBTableToolbar
          numSelected={get(bulkEditItems, 'data', []).length}
          toggleDialog={this.toggleFilterDialog}
          columns={columns}
          allColumns={allColumns}
          showFilterDialog={showFilterDialog}
          onFilterChange={onFilterChange}
          filterList={filterList}
          selected={get(bulkEditItems, 'data', [])}
          onDelete={onDelete}
          clearSelected={this.clearSelected}
          onSearch={onSearch}
          onDistanceSearch={onDistanceSearch}
          updateColumns={updateColumns}
          bulkEditDialog={bulkEditDialog}
          allowDelete={allowDelete}
          heading={heading}
          exportToCSVButton={exportToCSVButton}
          currentUserIsAdmin={currentUserIsAdmin}
          loading={loading}
          hideColumnSelect={hideColumnSelect}
        />
        <FBFilterList filterList={filterList} columns={columns} filterUpdate={onFilterChange} />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" fixedHeader>
            <FBTableHead
              numSelected={get(bulkEditItems, 'data', []).length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={onSort}
              rowCount={data.length}
              columns={columns}
            />
            <TableBody>
              {data.map(n => {
                const isSelected = this.isSelected(n.id);
                return (
                  <TableRow
                    hover
                    onClick={event => {
                      rowClick(event, n, client);
                      this.handleClick(event, n.id);
                    }}
                    role="checkbox"
                    aria-checked={isSelected}
                    tabIndex={-1}
                    key={n.id}
                    selected={isSelected}>
                    <TableCell padding="checkbox" className={classes.stickyColumn} style={{ zIndex: 9 }}>
                      <Checkbox checked={isSelected} />
                    </TableCell>
                    {columns.map(c => (
                      <TableCell padding="checkbox" className={classes.tableCell} style={c.customStyle || {}}>
                        {c.renderMethod ? c.renderMethod(n) : get(n, c.id) || '-'}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label' : 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label' : 'Next Page',
          }}
          onChangePage={changePage}
          onChangeRowsPerPage={e => changeRowsPerPage(e.target.value)}
        />
      </Paper>
    );
  }
}

FBTable.propTypes = {
  classes            : PropTypes.object.isRequired,
  data               : PropTypes.array.isRequired,
  order              : PropTypes.string,
  orderBy            : PropTypes.string,
  rowsPerPage        : PropTypes.number.isRequired,
  page               : PropTypes.number.isRequired,
  count              : PropTypes.number.isRequired,
  columns            : PropTypes.array.isRequired,
  onSort             : PropTypes.func.isRequired,
  changePage         : PropTypes.func.isRequired,
  changeRowsPerPage  : PropTypes.func.isRequired,
  rowClick           : PropTypes.func.isRequired,
  onFilterChange     : PropTypes.func.isRequired,
  heading            : PropTypes.func,
  allowDelete        : PropTypes.bool,
  loading            : PropTypes.bool.isRequired,
  onDelete           : PropTypes.func,
  hideColumnSelect   : PropTypes.bool,
  currentUserIsAdmin : PropTypes.bool.isRequired,
  loadBulkItemsList  : PropTypes.func.isRequired,
  onSearch           : PropTypes.func.isRequired,
  onDistanceSearch   : PropTypes.func.isRequired,
  bulkEditItems      : PropTypes.array,
  filterList         : PropTypes.array.isRequired,
  client             : PropTypes.object.isRequired,
  allColumns         : PropTypes.array.isRequired,
  updateColumns      : PropTypes.func.isRequired,
  exportToCSVButton  : PropTypes.func.isRequired,
  bulkEditDialog     : PropTypes.object.isRequired,
};

FBTable.defaultProps = {
  order            : '',
  orderBy          : '',
  heading          : () => <span />,
  allowDelete      : false,
  onDelete         : () => {},
  hideColumnSelect : false,
  bulkEditItems    : [],
};

export default withStyles(styles)(FBTable);
