import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import { Query, ApolloProvider } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import PePracticeInfo from '../components/PracticeInfo/PePracticeInfo';
import PeProductSummaryReport from '../components/PeProductSummaryReport/PeProductSummaryReport';
import PeGrossProduction from '../components/PeGrossProduction/PeGrossProduction';
import RjPullRevenueInformationButton from '../components/RjPullRevenueInformationButton/RjPullRevenueInformationButton';
import { get, lowerCase } from 'lodash';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      annual_revenue
      gross_expenses
      owners_compensation
      branch_net_payout
      expense_growth_rate
      marginal_tax_rate
      rj_terminal_growth_rate
      fee_based_revenue
      trailer_revenue
      direct_revenue
      transactional_revenue
      year_1_gross
      year_2_gross
      year_3_gross
      year_4_gross
      year_5_gross
      year_6_gross
      year_1_aum
      year_2_aum
      year_3_aum
      year_4_aum
      year_5_aum
      year_6_aum
      revenue_growth_rate
      use_benchmark_expense_percentage
      use_revenue_growth_rate
      three_yr_avg_t12
      benchmark_expenses
      update_production_data_automatically
      inventory_user {
        id
        is_rjas
      }
      company {
        id
        name
        business_type
        branch_net_payout_read_only
        employee_based_advisors
        imported_production_data_read_only
        allow_user_to_specify_revenue_growth_rate
        show_pull_latest_revenue_button
        hide_direct_revenue
        hide_branch_net_payout
        trails_word
        insurance_commissions_information_tooltip
        use_enterprise_provided_growth_rate
        feature_types
      }
    }
  }
`;

const PeValuationUpdateContainer = ({
  classes,
  hidePracticeInfo,
  profileView,
  hideGrossProduction,
  hidePullLatestButton,
  showRevSliders,
  saveToUser,
  updatePePracticeInfo,
  updatePeProductSummary,
  updateGrossProduction,
  user,
}) => {
  return (
    <ApolloProvider client={client}>
      <Query query={getCurrentUser}>
        {({ loading, data }) => {
          if (loading) return <CircularProgress />;
          if (get(data, 'getCurrentUser', false)) {
            const alterItems = get(data, 'getCurrentUser.company.business_type', '') === 'wealth_rj';
            const show_pull_latest_revenue_button = get(data, 'getCurrentUser.company.show_pull_latest_revenue_button', false);
            const hide_direct_revenue = get(data, 'getCurrentUser.company.hide_direct_revenue', false);
            const employeeBasedAdvisors = get(data, 'getCurrentUser.company.employee_based_advisors', false);
            const disableRevenueSliders = () => {
              if (showRevSliders) {
                return false;
              }
              return get(data, 'getCurrentUser.company.imported_production_data_read_only', false);
            };
            const showRevGrowthRate = get(
              data,
              'getCurrentUser.company.allow_user_to_specify_revenue_growth_rate',
              false
            );
            
            const useEnterpriseProvidedGrowthRate = get(data, 'getCurrentUser.company.use_enterprise_provided_growth_rate', false);

            let userToUse = saveToUser ? data.getCurrentUser : {...data.getCurrentUser, ...user};
            return (
              <Grid container spacing={1}>
                {!hidePracticeInfo && (
                  <PePracticeInfo
                    currentUser={userToUse}
                    classes={classes}
                    profileView
                    employeeBasedAdvisors={employeeBasedAdvisors}
                    saveToUser={saveToUser}
                    updatePePracticeInfo={updatePePracticeInfo}
                  />
                )}
                <Grid item xs={12}>
                  <span className={classes.headline}>                    
                    <FormattedMessage id="profile_page.profile_sidebar.revenue_info"/>
                  </span>
                  <p>
                    <FormattedMessage
                      id="valuation_drawer.pevaluation_container_message"
                      values={{ branch_word: employeeBasedAdvisors ? 'practice' : 'branch' }}
                    />
                  </p>
                  {(!employeeBasedAdvisors && !hidePullLatestButton && show_pull_latest_revenue_button) && <RjPullRevenueInformationButton currentUser={userToUse} />}
                </Grid>
  
                <PeProductSummaryReport
                  currentUser={userToUse}
                  classes={classes}
                  profileView={profileView}
                  alterItems={alterItems}
                  disableRevenueSliders={disableRevenueSliders()}
                  saveToUser={saveToUser}
                  updatePeProductSummary={updatePeProductSummary}
                />
                {(!hideGrossProduction) && (
                  <PeGrossProduction
                    currentUser={userToUse}
                    classes={classes}
                    profileView={profileView}
                    disableRevenueSliders={disableRevenueSliders()}
                    saveToUser={saveToUser}
                    updateGrossProduction={updateGrossProduction}
                    showRevGrowthRate={showRevGrowthRate}
                  />
                )}
              </Grid>
            );
          }
        }}
      </Query>
    </ApolloProvider>
  );
};

PeValuationUpdateContainer.propTypes = {
  classes                : PropTypes.object.isRequired,
  hidePracticeInfo       : PropTypes.bool,
  profileView            : PropTypes.bool,
  saveToUser             : PropTypes.bool,
  updatePePracticeInfo   : PropTypes.func,
  updatePeProductSummary : PropTypes.func,
  updateGrossProduction  : PropTypes.func,
};

PeValuationUpdateContainer.defaultProps = {
  hidePracticeInfo       : false,
  profileView            : false,
  showRevSliders         : false,
  saveToUser             : false,
  updatePePracticeInfo   : () => {},
  updatePeProductSummary : () => {},
  updateGrossProduction  : () => {},
};

const styles = theme => ({
  toolTip  : {
    color : '#1396e2',
  },
  headline : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

export default withStyles(styles)(injectIntl(PeValuationUpdateContainer));
