import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const ForwardToATS = gql`
  mutation forwardCandidateApplication($id: ID!, $email: String, $reason: String) {
    forwardCandidateApplication(id: $id, email: $email, reason: $reason)
  }
`;

class ForwardToATSButton extends React.Component {
  state = {
    open        : false,
    emailChoice : false,
    email       : null,
    reason      : '',
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { mutate, application, userName } = this.props;
    const { open, emailChoice, email, reason } = this.state;
    return (
      <span>
        <Button variant="contained" color="primary" onClick={this.handleClickOpen}>
          Forward to ATS
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <h3>You are about to forward: {userName}</h3>
          </DialogTitle>
          <DialogContent>
            To:
            <TextField
              select
              variant="filled"
              value={emailChoice}
              defaultValue={false}
              fullWidth
              onClick={e => this.setState({ emailChoice: e.target.value })}>
              <option value={false}>Enterprise ATS</option>
              <option value>Recruiting Manager via Email</option>
            </TextField>
            {emailChoice && (
              <div>
                <br />
                Email:
                <TextField
                  type="text"
                  variant="filled"
                  fullWidth
                  onChange={e => {
                    this.setState({ email: e.target.value });
                  }}
                />
              </div>
            )}
            <h4>Reason for Recommendation</h4>
            <TextField
              type="text"
              variant="filled"
              fullWidth
              multiline
              rows="4"
              onChange={e => {
                this.setState({ reason: e.target.value });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              autoFocus
              onClick={() => {
                const variables = {
                  id     : application.id,
                  reason,
                };
                if (email) {
                  variables.email = email;
                }

                mutate({
                  variables,
                })
                  .then(() => {
                    window.location.reload();
                  })
                  .catch(error => {
                    // eslint-disable-next-line
                    console.log(error);
                    // eslint-disable-next-line
                    alert('there was an error sending the query', error);
                  });
              }}>
              {'> Forward Now'}
            </Button>
          </DialogActions>
        </Dialog>
      </span>
    );
  }
}

ForwardToATSButton.propTypes = {
  mutate      : PropTypes.func.isRequired,
  application : PropTypes.object,
  userName    : PropTypes.string,
};

ForwardToATSButton.defaultProps = {
  application : {},
  userName    : '',
};

export default graphql(ForwardToATS)(ForwardToATSButton);
