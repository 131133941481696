import {
  PROFILE_DRAWER_LIST_UPDATE,
  PROFILE_DRAWER_UPDATE,
  FILTER_UPDATE,
  PROFILE_DRAWER_STATUS_UPDATE,
  SELECTED_PROFILE_DRAWER_UPDATE,
} from '../constants/profileDrawerConstants';

const profileDrawerReducer = (
  state = {
    data : {},
  },
  action
) => {
  switch (action.type) {
    case PROFILE_DRAWER_LIST_UPDATE:
      return { ...state, ...action.profileDrawers, loaded: true };
    case PROFILE_DRAWER_UPDATE:
      return { ...state, data: { ...state.data, getUser: { ...state.data.getUser, ...action.profileDrawer } } };
    case SELECTED_PROFILE_DRAWER_UPDATE:
      return {
        ...state,
        data : action.selectedProfileDrawer,
      };
    case FILTER_UPDATE:
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case PROFILE_DRAWER_STATUS_UPDATE:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default profileDrawerReducer;
