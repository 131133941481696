import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import numeral from 'numeral';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import WarningText from '../WarningText/WarningText';
import RevenueContainer from '../../containers/RevenueContainer';
import HistoricalInfo from '../HistoricalInfo/HistoricalInfo';
import CurrencyInput from 'react-currency-input-field';
import { get } from 'lodash';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $avg_client_age: Int
    $annual_revenue: Float
    $assets_under_management: Float
    $recurring_revenue: Float
    $transition_period_months: Int
    $number_of_clients: Int
    $year_1_gross: Int
    $year_2_gross: Int
    $year_3_gross: Int
    $year_4_gross: Int
  ) {
    updateCurrentUser(
      avg_client_age: $avg_client_age
      annual_revenue: $annual_revenue
      assets_under_management: $assets_under_management
      recurring_revenue: $recurring_revenue
      transition_period_months: $transition_period_months
      number_of_clients: $number_of_clients
      year_1_gross: $year_1_gross
      year_2_gross: $year_2_gross
      year_3_gross: $year_3_gross
      year_4_gross: $year_4_gross
    )
  }
`;

class PracticeInfo extends React.Component {
  updateUser = debounce(() => {
    const { mutate } = this.props;
    const {
      avg_client_age,
      annual_revenue,
      assets_under_management,
      recurring_revenue,
      transition_period_months,
      number_of_clients,
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
    } = this.state;

    mutate({
      variables : {
        avg_client_age,
        annual_revenue,
        assets_under_management,
        recurring_revenue,
        transition_period_months,
        number_of_clients,
        year_1_gross,
        year_2_gross,
        year_3_gross,
        year_4_gross,
      },
    })
      .then(r => {
        if (r) {
          window.location.reload(true);
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  }, 2000);

  constructor (props) {
    super(props);
    this.state = {
      number_of_clients        : props.currentUser.number_of_clients || 0,
      avg_client_age           : props.currentUser.avg_client_age || 0,
      annual_revenue           : props.currentUser.annual_revenue || 0,
      assets_under_management  : props.currentUser.assets_under_management || 0,
      recurring_revenue        : props.currentUser.recurring_revenue || 0,
      transition_period_months : props.currentUser.transition_period_months || 0,
      year_1_gross             : props.currentUser.year_1_gross || 0,
      year_2_gross             : props.currentUser.year_2_gross || 0,
      year_3_gross             : props.currentUser.year_3_gross || 0,
      year_4_gross             : props.currentUser.year_4_gross || 0,
      badRevenues              : false,
      badValues                : false,
    };
  }

  revenuesAreGood = () => {
    const { annual_revenue, recurring_revenue } = this.state;
    return annual_revenue > recurring_revenue;
  };

  valuesAreGood = () => {
    const {
      number_of_clients,
      avg_client_age,
      annual_revenue,
      assets_under_management,
      recurring_revenue,
      transition_period_months,
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
    } = this.state;
    return (
      annual_revenue <= 10000000 &&
      recurring_revenue <= 10000000 &&
      assets_under_management <= 1000000000 &&
      number_of_clients <= 10000 &&
      avg_client_age <= 2000 &&
      transition_period_months <= 24 &&
      year_1_gross <= 10000000 &&
      year_2_gross <= 10000000 &&
      year_3_gross <= 10000000 &&
      year_4_gross <= 10000000
    );
  };

  calcValues = () => {
    if (this.revenuesAreGood() && this.valuesAreGood()) {
      this.setState({ badRevenues: false, badValues: false });
      this.updateUser();
    } else if (!this.revenuesAreGood()) {
      this.setState({ badRevenues: true, badValues: false });
    } else if (!this.valuesAreGood()) {
      this.setState({ badValues: true, badRevenues: false });
    }
  };

  handleChange = (val, s) => {
    this.setState({ [val]: s });
  };

  updateState = v => {
    this.handleChange(Object.keys(v)[0], v[Object.keys(v)[0]]);
  };

  render () {
    const { currentUser, classes, intl } = this.props;
    const {
      avg_client_age,
      annual_revenue,
      assets_under_management,
      recurring_revenue,
      transition_period_months,
      badRevenues,
      badValues,
      number_of_clients,
    } = this.state;
    const businessType = get(currentUser, 'company.business_type');

    const intlConfig = () => {
      if (intl.locale == 'fr') {
        return { locale: 'fr-CA', currency: 'CAD' };
      }
      return { locale: 'en-US', currency: 'USD' };
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <span className={classes.headline}>
            <FormattedMessage id="profile_page.profile_sidebar.practice_info" />
          </span>
        </Grid>
        {badRevenues && (
          <div style={{ color: 'red' }}>
            <FormattedMessage id="valuation_drawer.recurring_revenue_must_be_lower" />
          </div>
        )}
        {badValues && (
          <div style={{ color: 'red' }}>
            <FormattedMessage id="valuation_drawer.please_adjust_values" />
          </div>
        )}
        <Grid item xs={12}>
          <div style={{ marginBottom: 25 }} />
          <span>
            <FormattedMessage id="bobcard.user_details.annual_revenue" />{' '}
          </span>
          <Tooltip title={intl.formatMessage({ id: 'valuation_drawer.annual_revenue_tool_tip' })} placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={annual_revenue || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('annual_revenue', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText
            visible={annual_revenue > 10000000}
            message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_10_000_000' })}
          />
          <CurrencyInput
            id="input-example"
            value={annual_revenue}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('annual_revenue', parseInt(value))}
          />
        </Grid>
        <br />
        <span>
          <FormattedMessage id="bobcard.user_details.recurring_revenue" />{' '}
        </span>
        <Tooltip title={intl.formatMessage({ id: 'valuation_drawer.recurring_revenue_tool_tip' })} placement="right">
          <Button style={{ margin: -15 }}>
            <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
          </Button>
        </Tooltip>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={recurring_revenue || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('recurring_revenue', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText
            visible={recurring_revenue > 10000000}
            message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_10_000_000' })}
          />
          <CurrencyInput
            id="input-example"
            value={recurring_revenue}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('recurring_revenue', parseInt(value))}
          />
        </Grid>
        {businessType !== 'p_and_c' && (
          <Grid container>
            <br />
            <span>
              <FormattedMessage id="bobcard.user_details.assets_under_management" />{' '}
            </span>
            <Tooltip
              title={intl.formatMessage({ id: 'valuation_drawer.assets_under_management_tool_tip' })}
              placement="right">
              <Button style={{ margin: -15 }}>
                <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
              </Button>
            </Tooltip>
            <Grid item xs={7}>
              <RangeSliderContainer>
                <InputSlider
                  maxValue={1000000000}
                  minValue={0}
                  value={assets_under_management || 0}
                  formatLabel={value => ``}
                  step={50000}
                  onChange={e => {
                    this.handleChange('assets_under_management', e);
                  }}
                />
              </RangeSliderContainer>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <WarningText
                visible={assets_under_management > 1000000000}
                message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_1_000_000_000' })}
              />
              <CurrencyInput
                id="input-example"
                value={assets_under_management}
                intlConfig={intlConfig()}
                allowDecimals={false}
                onValueChange={(value, name) => this.handleChange('assets_under_management', parseInt(value))}
              />
            </Grid>
          </Grid>
        )}
        <br />
        <span>
          <FormattedMessage id="bobcard.user_details.number_of_clients" />{' '}
        </span>
        <Tooltip title={intl.formatMessage({ id: 'valuation_drawer.number_of_clients_tool_tip' })} placement="right">
          <Button style={{ margin: -15 }}>
            <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
          </Button>
        </Tooltip>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000}
              minValue={0}
              value={number_of_clients || 0}
              formatLabel={value => ``}
              step={1}
              onChange={e => {
                this.handleChange('number_of_clients', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText
            visible={number_of_clients > 10000}
            message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_10_000' })}
          />
          <TextField
            id="outlined-read-only-input"
            value={number_of_clients}
            margin="normal"
            variant="outlined"
            type="number"
            onChange={e => {
              this.handleChange('number_of_clients', parseInt(e.target.value, 10));
            }}
          />
        </Grid>
        <br />
        <span>
          <FormattedMessage id="bobcard.user_details.average_client_age" />{' '}
        </span>
        <Tooltip
          title={intl.formatMessage({ id: 'valuation_drawer.average_age_of_clients_tool_tip' })}
          placement="right">
          <Button style={{ margin: -15 }}>
            <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
          </Button>
        </Tooltip>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={avg_client_age || 0}
              formatLabel={value => ``}
              step={1}
              onChange={e => {
                this.handleChange('avg_client_age', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText
            visible={avg_client_age > 100}
            message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_100' })}
          />
          <TextField
            id="outlined-read-only-input"
            value={avg_client_age}
            margin="normal"
            variant="outlined"
            type="number"
            onChange={e => {
              this.handleChange('avg_client_age', parseInt(e.target.value, 10));
            }}
          />
        </Grid>
        <br />
        <span>
          <FormattedMessage id="valuation_page.transition_period" />
        </span>
        <Tooltip title={intl.formatMessage({ id: 'valuation_drawer.transition_period_tool_tip' })} placement="right">
          <Button style={{ margin: -15 }}>
            <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
          </Button>
        </Tooltip>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={24}
              minValue={0}
              value={transition_period_months || 0}
              formatLabel={value => ``}
              step={6}
              onChange={e => {
                this.handleChange('transition_period_months', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText
            visible={transition_period_months > 24}
            message={intl.formatMessage({ id: 'valuation_drawer.value_must_be_under_24' })}
          />
          <div style={{ marginLeft: 10, marginTop: 25 }}>{transition_period_months}</div>
        </Grid>
        <div style={{ marginBottom: 100 }} />
        <RevenueContainer classes={classes} />
        <br />
        <HistoricalInfo classes={classes} updateState={v => this.updateState(v)} currentUser={currentUser} />
        <Grid xs={4} />
        <br />
        <Grid xs={4}>
          <Button
            onClick={() => this.calcValues()}
            color="primary"
            class="btn btn-default"
            type="button"
            style={{ marginTop: 10 }}>
            <FormattedMessage id="valuation_drawer.recalculate" />
          </Button>
        </Grid>
        <Grid xs={4} />
      </Grid>
    );
  }
}

PracticeInfo.propTypes = {
  mutate      : PropTypes.func.isRequired,
  currentUser : PropTypes.object.isRequired,
};

const styles = theme => ({
  toolTip  : {
    color : '#1396e2',
  },
  headline : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
`;

export default withStyles(styles)(injectIntl(graphql(UpdateCurrentUser)(PracticeInfo)));
