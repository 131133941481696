import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {FormattedMessage, injectIntl} from "react-intl";
import TipViewUpdateProfileUserHeadlineBio from '../TipViewUpdateProfile/TipViewUpdateProfileUserHeadlineBio';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';

class TipViewUpdateProfile extends React.Component {

  render () {
    const {     
      updateAction,
      company,
      user,
     } = this.props;
    const {
      classes,
    } = this.props;
    const companyName = get(company, 'name');
    return (
        <div>
          <header className="center">
            <div className={classes.title}><FormattedMessage id="motivation_onboarding.pages.update_profile_text.title"/></div>
                <div
                className="cite no-bottom-margin center"
                style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <FormattedMessage id="motivation_onboarding.pages.update_profile_text.text" values={{company_name: companyName}} />
                </div>
                <br/>
            </header>
            <div>
              <TipViewUpdateProfileUserHeadlineBio
                headline={user.headline}
                bio={user.bio}
                user={user}
                updateAction={updateAction}
                countryForCompany={get(company, 'country')}
                companyHasCounty={get(company, 'has_county')}
                agentWord={get(company, 'agent_word')}
                locale={user.locale}
                show_ask_about_me={get(company, 'ask_me_about_question', false)}
                companyBusinessType={get(company, 'business_type')}
                askMeAboutMandatory={false}
                onNext={() => {window.location.href = `/profile/headline`}}
              />
          </div>
     </div>
    );
  }
}

TipViewUpdateProfile.propTypes = {
  classes           : PropTypes.object.isRequired,
  user              : PropTypes.object,
  updateAction      : PropTypes.func.isRequired,
  company           : PropTypes.object,
};

TipViewUpdateProfile.defaultProps = {
  user              : {},
  company           : {},
};

const styles = () => ({
  title         : {
    fontSize   : '24px',
    fontWeight : 100,
  },
  subTitle      : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  buttonSection : {
    width : '100%',
  },
  buttonDiv     : {
    marginLeft  : 'auto',
    marginRight : 'auto',
    width       : 'fit-content',
  },
  button        : {
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 25,
  },
});

export default withStyles(styles)(TipViewUpdateProfile);