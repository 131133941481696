import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { debounce, get, toUpper } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import InfoBox from './InfoBox';
import { listOfStates, listOfProvinces } from '../../lib/findBobUtils';
import AgencyNameSelectorContainer from '../../containers/AgencyNameSelectorContainer';
import ExceptionToggle from './ExceptionToggle';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

const UpdateCurrentInventoryUser = gql`
  mutation editInventoryUser(
    $id: ID!
    $name: String
    $email: String
    $birth_date: String
    $enterprise_id: String
    $organization: String
    $company_id: Int
    $start_date: String
    $rev_yield: Int
    $clients: Int
    $total_premium: Int
    $total_assets: Int
    $key_asset: Boolean
    $is_invisible: Boolean
    $is_exception: Boolean
    $risk_of_loss: String
    $reason_for_loss: String
    $impact_of_loss: String
    $province: String
    $manager_email: String
    $affiliation: String
    $branch: String
    $division: String
    $agency_name: String
    $website: String
    $team_name: String
    $team_enhanced_compensation: Boolean
  ) {
    editInventoryUser(
      id: $id
      email: $email
      name: $name
      birth_date: $birth_date
      enterprise_id: $enterprise_id
      organization: $organization
      company_id: $company_id
      start_date: $start_date
      rev_yield: $rev_yield
      clients: $clients
      total_premium: $total_premium
      total_assets: $total_assets
      key_asset: $key_asset
      is_invisible: $is_invisible
      is_exception: $is_exception
      risk_of_loss: $risk_of_loss
      reason_for_loss: $reason_for_loss
      impact_of_loss: $impact_of_loss
      province: $province
      manager_email: $manager_email
      affiliation: $affiliation
      branch: $branch
      division: $division
      agency_name: $agency_name
      website: $website
      team_name: $team_name
      team_enhanced_compensation: $team_enhanced_compensation
    )
  }
`;

export const addIuTag = gql`
  mutation addIuTag($id: ID!, $tagname: String) {
    addIuTag(id: $id, tagname: $tagname)
  }
`;

export const removeIuTag = gql`
  mutation removeIuTag($id: ID!, $deleteName: String) {
    removeIuTag(id: $id, deleteName: $deleteName)
  }
`;

class OverviewInventoryUserData extends React.Component {
  editInventoryUser = debounce(() => {
    const { mutate, inventoryUser, updateInventoryUser, updateAction, refetch } = this.props;
    const {
      id,
      birth_date,
      phone_number,
      city,
      clients,
      company_id,
      email,
      enterprise_id,
      impact_of_loss,
      key_asset,
      is_invisible,
      manager_email,
      marketplace_status,
      name,
      organization,
      province,
      reason_for_loss,
      rev_yield,
      risk_of_loss,
      start_date,
      total_assets,
      total_premium,
      affiliation,
      branch,
      division,
      agency_name,
      website,
      is_exception,
      team_name,
      team_enhanced_compensation,
    } = this.state;
    mutate({
      variables : {
        id,
        birth_date,
        phone_number,
        city,
        clients,
        company_id,
        email,
        enterprise_id,
        impact_of_loss,
        key_asset,
        is_invisible,
        manager_email,
        marketplace_status,
        name,
        organization,
        province,
        reason_for_loss,
        rev_yield,
        risk_of_loss,
        start_date,
        total_assets,
        total_premium,
        affiliation,
        branch,
        division,
        agency_name,
        is_exception,
        team_name,
        team_enhanced_compensation,
        website            : this.validateWebsite(website) ? website : '',
      },
    })
      .then(() => {
        updateInventoryUser({ ...inventoryUser, ...this.state });
        updateAction({ user: { ...inventoryUser, ...this.state } });
        this.openDebouncedSnack();
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  }, 0);

  constructor (props) {
    super(props);
    const { inventoryUser } = this.props;

    this.state = {
      id                  : inventoryUser.id,
      affiliation         : inventoryUser.affiliation,
      bio                 : inventoryUser.bio,
      birth_date          : inventoryUser.birth_date,
      branch              : inventoryUser.branch,
      is_branch_exception : inventoryUser.is_branch_exception,
      phone_number        : inventoryUser.phone_number,
      city                : inventoryUser.city,
      clients             : inventoryUser.clients,
      company_id          : inventoryUser.company_id,
      division            : inventoryUser.division,
      email               : inventoryUser.email,
      enterprise_id       : inventoryUser.enterprise_id,
      headline            : inventoryUser.headline,
      impact_of_loss      : inventoryUser.impact_of_loss,
      key_asset           : inventoryUser.key_asset,
      is_invisible        : inventoryUser.is_invisible,
      languages           : inventoryUser.languages,
      manager_email       : inventoryUser.manager_email,
      marketplace_status  : inventoryUser.marketplace_status,
      name                : inventoryUser.name,
      is_exception        : inventoryUser.is_exception,
      organization        : inventoryUser.organization,
      phone               : inventoryUser.phone,
      province            : inventoryUser.province,
      reason_for_loss     : inventoryUser.reason_for_loss,
      rev_yield           : inventoryUser.rev_yield,
      risk_of_loss        : inventoryUser.risk_of_loss,
      start_date          : inventoryUser.start_date,
      tag_list            : inventoryUser.tag_list,
      tags                : inventoryUser.tags,
      total_assets        : inventoryUser.total_assets,
      total_premium       : inventoryUser.total_premium,
      transition_goals    : inventoryUser.transition_goals,
      website             : inventoryUser.website,
      team_name           : inventoryUser.team_name,
      team_enhanced_compensation : inventoryUser.team_enhanced_compensation,
      openBdayDatePicker  : false,
      openSnackBar        : false,
      openStartDayPicker  : false,
      showHidden          : false,
      tabValue            : 0,
    };
  }

  componentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.id !== nextProps.inventoryUser.id) {
      this.setState({ ...nextProps.inventoryUser });
    }
  }

  openDebouncedSnack = debounce(() => {
    const { openSnack } = this.props;
    openSnack();
  }, 1000);

  validateBirthday = () => {
    const { inventoryUser } = this.props;
    const { birth_date } = inventoryUser;
    const age = moment().diff(birth_date, 'years');
    return age > 18 || birth_date === '';
  };

  validateWebsite = str => {
    const pattern = new RegExp(
      '^((ftp|http|https)://)?(www.)?(?!.*(ftp|http|https|www.))' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    if (str) {
      return !!pattern.test(str);
    }
    return true;
  };

  openBdayDatePicker = () => {
    const { openBdayDatePicker } = this.state;
    this.setState({ openBdayDatePicker: !openBdayDatePicker });
  };

  handleBdayChange = date => {
    this.setState({ birth_date: date }, () => {
      this.editInventoryUser();
      this.setState({ openBdayDatePicker: false });
    });
  };

  handleWebsiteChange = value => {
    this.setState({ website: value });
    if (this.validateWebsite(value) === true) {
      this.editInventoryUser();
    }
  };

  openStartDayPicker = () => {
    const { openStartDayPicker } = this.state;
    this.setState({ openStartDayPicker: !openStartDayPicker });
  };

  handleStartDayChange = date => {
    this.setState({ start_date: date }, () => {
      this.editInventoryUser();
      this.setState({ openStartDayPicker: false });
    });
  };

  handleShowHidden = () => {
    const { showHidden } = this.state;
    this.setState({ showHidden: !showHidden });
  };

  handleTabChange = (event, tabValue) => {
    this.setState({ tabValue });
  };

  stateType = () => {
    const { country, classes } = this.props;
    if (country === 'ca') {
      return (
        <span className={classes.textFieldKey}>
          <FormattedMessage id="admin_inventory_user.province" />:
        </span>
      );
    }
    if (country === 'us') {
      return (
        <span className={classes.textFieldKey}>
          <FormattedMessage id="admin_inventory_user.state" />:
        </span>
      );
    }
    return <div />;
  };

  handleChangeProvince = (id, e) => {
    let state = this.state;
    state[id] = e.target.value;
    this.setState(state);
    this.editInventoryUser();
  };

  handleAgencyChange = agencyName => {
    this.setState({ agency_name: agencyName }, this.editInventoryUser);
  };

  handleShowHidden = () => {
    const { showHidden } = this.state;
    this.setState({ showHidden: !showHidden });
  };

  handleDelete = () => {
    alert('You clicked the delete icon.'); // eslint-disable-line no-alert
  };

  onKeyDown = e => {
    const { tagname } = this.state;
    if (e === 'Enter' && tagname) {
      this.addTag();
    }
  };

  clickCheckbox = () => {
    const { is_invisible } = this.state;
    this.setState(
      {
        is_invisible : !is_invisible,
      },
      () => {
        this.editInventoryUser();
      }
    );
  };

  render () {
    const { classes, country, agencies, refetch, inventoryUser, company } = this.props;
    const {
      id,
      bio,
      birth_date,
      phone_number,
      city,
      email,
      name,
      openBdayDatePicker,
      openStartDayPicker,
      province,
      start_date,
      affiliation,
      branch,
      division,
      agency_name,
      is_exception,
      team_name,
      team_enhanced_compensation,
      is_branch_exception,
      website,
      is_invisible,
    } = this.state;
    let regionList = [];
    if (country === 'ca') {
      regionList = listOfProvinces;
    } else if (country === 'us') {
      regionList = listOfStates;
    }

    const agencyList = (agencies || []).map(t => (t = { ...t, label: t.name, value: t.name }));

    return (
      <div>
        <Grid container spacing={0}>
          {get(company, 'business_type', '') === 'wealth_rj' && (
            <div>
              <Grid item xs={12}>
                <Button
                  style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                  size="small"
                  classes={{
                    root : classes.root,
                  }}
                  onClick={() => {
                    window.open(`/valuations/dcf?inv_id=${id}`, '_blank');
                  }}>
                  View DCF Valuation
                </Button>
              </Grid>
              {toUpper(get(company, 'affiliate_name', '')) === 'ICD' && (
                <Grid item xs={12}>
                  <Button
                    style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                    size="small"
                    classes={{
                      root : classes.root,
                    }}
                    onClick={() => {
                      window.open(`/valuations/pevaluation?inv_id=${id}`, '_blank');
                    }}>
                    View Multiple of Revenue Valuation
                  </Button>
                </Grid>
              )}
              {toUpper(get(company, 'affiliate_name', '')) === 'RJA' && (
                <Grid item xs={12}>
                  <Button
                    style={{ textDecoration: 'underline', textTransform: 'capitalize' }}
                    size="small"
                    classes={{
                      root : classes.root,
                    }}
                    onClick={() => {
                      window.open(`/loans/otp?inv_id=${id}`, '_blank');
                    }}>
                    View Retirement Choice Calculator
                  </Button>
                </Grid>
              )}
            </div>
          )}
          <Grid item xs={12}>
            <TextField
              id="name"
              label="Name:"
              value={name}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          {get(company, 'business_type') !== 'wealth_rj' && (
            <Grid item xs={12}>
              <TextField
                id="date-of-birth"
                InputLabel={{ disabled: true }}
                label="Date of Birth:"
                value={moment(birth_date).format('MM-DD-YYYY')}
                InputProps={{
                  classes  : { underline: classes.underline },
                  readOnly : true,
                }}
                fullWidth
                margin="normal"
                onClick={() => {
                  this.openBdayDatePicker();
                }}
              />
            </Grid>
          )}

          {openBdayDatePicker && (
            <Grid container spacing={0}>
              <b>* Please enter birth date</b>
              <FindbobDatePicker
                getDate={date => {
                  this.handleBdayChange(date);
                }}
                maxYear={moment().year()}
                minYear={moment().year() - 80}
                date={moment(birth_date).format('YYYY-MM-DD')}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="start_date"
              label="Firm Length of Service:"
              value={moment(start_date).isValid() ? moment(start_date).fromNow(true) : '-'}
              InputProps={{
                classes  : { underline: classes.underline },
                readOnly : true,
              }}
              fullWidth
              margin="normal"
              onClick={() => {
                this.openStartDayPicker();
              }}
            />
          </Grid>
          {openStartDayPicker && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <b>* Please enter company start date</b>
                <FindbobDatePicker
                  getDate={date => {
                    this.handleStartDayChange(date);
                  }}
                  maxYear={moment().year()}
                  minYear={moment().year() - 80}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              id="email"
              label="Email:"
              value={email}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ email: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="website"
              label="Website:"
              value={website || ''}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.handleWebsiteChange(e.target.value);
              }}
              error={!this.validateWebsite(website)}
              helperText={!this.validateWebsite(website) ? 'Invalid URL format' : ' '}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="phone"
              label="Phone:"
              value={phone_number || '-'}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ phone_number: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="city"
              label="City:"
              value={city || '-'}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ city: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Province:</InputLabel>
            <Select
              label="Province:"
              name="Province"
              value={province || '-'}
              onChange={e => {
                this.handleChangeProvince('province', e);
              }}
              fullWidth>
              {regionList.map(prov => <MenuItem value={prov}>{prov}</MenuItem>)}
            </Select>
          </Grid>
          <Grid xs={12}>
            <InputLabel className={classes.label} style={{ marginTop: 25 }}>
              Agency:
            </InputLabel>
            <AgencyNameSelectorContainer
              agencies={agencyList}
              value={{ label: agency_name, value: agency_name }}
              onChange={v => {
                this.handleAgencyChange(v.name);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="affiliation"
              label="Affiliation:"
              value={affiliation}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ affiliation: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="branch"
              label="Branch:"
              value={branch}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ branch: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} className={classes.dataLeft}>
              <Checkbox style={{ marginLeft: '-15px', marginTop: '-10px' }} checked={is_branch_exception} disabled />
              <span>
                <b>Branch Exclusion?</b>
              </span>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="division"
              label="Division:"
              value={division}
              InputProps={{
                classes : { underline: classes.underline },
              }}
              fullWidth
              margin="normal"
              onChange={e => {
                this.setState({ division: e.target.value });
              }}
              onBlur={this.editInventoryUser}
            />
          </Grid>
          {get(company, 'business_type', '') === 'wealth_rj' && (
            <React.Fragment>
              <Grid item xs={12}>
                <TextField
                  id="region"
                  label="Region"
                  value={get(inventoryUser, 'region')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="alex_brown_flag"
                  label="Alex Brown Flag"
                  value={get(inventoryUser, 'alex_brown_flag', '')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="recognition_level"
                  label="Recognition Level"
                  value={get(inventoryUser, 'recognition_level')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="team_size"
                  label="Team Size"
                  value={get(inventoryUser, 'team_size')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="amp_trainee_status"
                  label="AMP trainee status"
                  value={get(inventoryUser, 'amp_trainee_status', '')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="CSSID"
                  label="CSSID"
                  value={get(inventoryUser, 'cssid')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  d="office_city"
                  label="office_city"
                  value={get(inventoryUser, 'office_city')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="office_state"
                  label="office_state"
                  value={get(inventoryUser, 'office_state')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="retirement_choice_calculator_code"
                  label="Retirement Choice Calculator Code"
                  value={get(inventoryUser, 'retirement_choice_calculator_code')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="insurance_business"
                  label="Insurance Business"
                  value={get(inventoryUser, 'insurance_business')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="discretion"
                  label="Discretion"
                  value={get(inventoryUser, 'discretion')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="licensing"
                  label="Licensing"
                  value={get(inventoryUser, 'licensing')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="retirement_choice_calculator_code"
                  label="Retirement Choice Calculator Code"
                  value={get(inventoryUser, 'retirement_choice_calculator_code')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="state_registrations"
                  label="State Registrations"
                  value={get(inventoryUser, 'state_registrations')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="team_name"
                  label="Team Name"
                  value={get(inventoryUser, 'team_name')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  onChange={e => {
                    this.setState({ team_name: e.target.value });
                  }}
                  onBlur={this.editInventoryUser}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  id="team_enhanced_compensation"
                  label="Team Enhanced Compensation"
                  value={get(inventoryUser, 'team_enhanced_compensation')}
                  InputProps={{
                    classes : { underline: classes.underline },
                  }}
                  fullWidth
                  margin="normal"
                  onChange={e => {
                    this.setState({ team_enhanced_compensation: e.target.value });
                  }}
                  onBlur={this.editInventoryUser}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0} className={classes.dataLeft}>
                  <Checkbox
                    style={{ marginLeft: '-15px', marginTop: '-10px' }}
                    checked={is_invisible}
                    onChange={this.clickCheckbox}
                  />
                  <span>
                    <b>Make Invisible</b>
                  </span>
                </Grid>
              </Grid>
              {get(company, 'match_pool_exception_enabled') && (
                <div>
                  <Grid item xs={12}>
                    <ExceptionToggle
                      inventoryUserId={id}
                      isException={is_exception}
                      updateUserState={this.setState.bind(this)}
                      updateUser={this.editInventoryUser}
                      refetch={refetch}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <input type="checkbox" checked={get(inventoryUser, 'in_good_standing')} disabled={true} />
                    <span style={{ color: '#333' }}>&nbsp; In Good Standing</span>
                  </Grid>
                </div>
              )}
            </React.Fragment>
          )}
        </Grid>
      </div>
    );
  }
}

OverviewInventoryUserData.propTypes = {
  mutate              : PropTypes.func.isRequired,
  updateInventoryUser : PropTypes.func.isRequired,
  updateAction        : PropTypes.func.isRequired,
  openSnack           : PropTypes.func.isRequired,
  classes             : PropTypes.object.isRequired,
  inventoryUser       : PropTypes.object.isRequired,
  country             : PropTypes.string,
};

OverviewInventoryUserData.defaultProps = {
  country : '',
};

const styles = {
  root      : {
    marginTop : 11,
  },
  titles    : {
    marginTop : 10,
  },
  chip      : {
    margin : 2,
  },
  label     : {
    fontSize : 10,
  },
  underline : {
    '&:after' : {
      borderBottom  : `2px solid #F7F7F7`,
      left          : 0,
      bottom        : 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content       : '""',
      position      : 'absolute',
      right         : 0,
      transform     : 'scaleX(0)',
      pointerEvents : 'none', // Transparent to the hover style.
    },
  },
  dataLeft  : {
    color     : 'rgba(0, 0, 0, 0.54)',
    fontSize  : 11,
    marginTop : 15,
  },
};

export default withStyles(styles)(injectIntl(graphql(UpdateCurrentInventoryUser)(OverviewInventoryUserData)));
