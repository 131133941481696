import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get, mean, compact, sum } from 'lodash';
import Infographic from './Infographic';
import numeral from 'numeral';
import {
  VictoryBar,
  VictoryPie,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryGroup,
} from 'victory';
import TransitionGoalPieChart from './TransitionGoalPieChart';

const colorScale = [
  '#337ab7',
  'rgb(0, 121, 107)',
  'silver',
  'rgb(25, 245, 157)',
  '#FF8A65',
  '#FFF176',
  '#AFB42B',
  '#BA68C8',
  '#BCAAA4',
  '#004D40',
];

export const average_age_of_users = inventory_users =>
  mean(compact((inventory_users || []).map(i => parseFloat(i.age))));

export const average_years_in_industry = inventory_users =>
  mean(compact((inventory_users || []).map(i => parseFloat(i.industry_length_of_service))));

export const average_gross_production = inventory_users =>
  mean(compact((inventory_users || []).map(i => parseFloat(i.user_gross_production))));

export const average_aum = inventory_users =>
  mean(compact((inventory_users || []).map(i => parseFloat(i.total_assets))));

export const average_dcf = inventory_users =>
  mean(compact((inventory_users || []).map(i => parseFloat(i.dcf_valuation))));

export const total_gross_production = inventory_users =>
  compact((inventory_users || []).map(i => parseFloat(i.user_gross_production))).reduce((a, b) => a + b, 0);

export const total_aum = inventory_users =>
  compact((inventory_users || []).map(i => parseFloat(i.total_assets))).reduce((a, b) => a + b, 0);

export const total_dcf = inventory_users =>
  compact((inventory_users || []).map(i => parseFloat(i.dcf_valuation))).reduce((a, b) => a + b, 0);

const formatNumber = (data, isCurrency) => {
  if (isCurrency) {
    return numeral(data).format('($0,0a)');
  }
  return data;
};

export const generatePieData = inventory_users => {
  const t_goals = (inventory_users || []).map(i => i.transition_goals);
  const data = {};
  for (let i = 0; i < t_goals.length; i++) {
    if (data[t_goals[i]]) {
      data[t_goals[i]]++;
    } else {
      data[t_goals[i]] = 1;
    }
  }
  const pieData = [];
  Object.keys(data).forEach(
    k =>
      Math.floor(data[k] / t_goals.length * 100) != 0 &&
      pieData.push({
        label : k.split(', ').join('\n') + `\n${Math.floor(data[k] / t_goals.length * 100)}%`,
        x     : k,
        y     : Math.floor(data[k] / t_goals.length * 100),
      })
  );

  return pieData;
};

export const generateBarData = inventory_users => {
  const province = (inventory_users || []).map(i => i.province);
  const data = {};
  for (let i = 0; i < province.length; i++) {
    if (data[province[i]]) {
      data[province[i]]++;
    } else {
      data[province[i]] = 1;
    }
  }
  const barData = [];
  Object.keys(data).forEach(k =>
    barData.push({
      state : k,
      users : data[k],
    })
  );
  const sorted = barData.sort((a, b) => (a.state > b.state ? 1 : b.state > a.state ? -1 : 0));
  return sorted;
};

const getUserWord = filter => {
  const motivation = filter.user_motivation;
  if (motivation === 'succession') return 'seller';
  else if (motivation === 'growth') return 'buyer';
  else if (motivation === 'no_motivation') return 'researcher';
  return 'user';
};

export const getDealCount = inv_users => {
  return sum((inv_users || []).map(i => i.deals.length));
};

const MotivationGraph = ({
  inventory_users,
  deals_count,
  classes,
  loading,
  motivationFilters,
  currentCountry,
  print_view,
}) => {
  if (loading) {
    return <div />;
  }
  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={print_view ? 12 : 6}>
          <Grid container spacing={0} style={{ padding: 40 }} className={print_view ? classes.scaleWrapper : ''}>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic data={average_age_of_users(inventory_users).toFixed(1)} icon="age" print_view={print_view}>
                <p>Average age of {getUserWord(motivationFilters)}s</p>
              </Infographic>
            </Grid>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic
                data={Math.floor(average_years_in_industry(inventory_users))}
                icon="years"
                print_view={print_view}>
                <p>
                  Average years a {getUserWord(motivationFilters)} has been in the industry.{' '}
                  {Math.floor(average_years_in_industry(inventory_users))} years with company.
                </p>
              </Infographic>
            </Grid>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic data={total_aum(inventory_users)} isCurrency icon="dollar" print_view={print_view}>
                <p>
                  Total AUM of {getUserWord(motivationFilters)}s. Average {getUserWord(motivationFilters)} has{' '}
                  {formatNumber(average_aum(inventory_users), true)} in AUM.
                </p>
              </Infographic>
            </Grid>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic
                data={total_gross_production(inventory_users)}
                isCurrency
                icon="gears"
                print_view={print_view}>
                <p>
                  Total gross production of {getUserWord(motivationFilters)}s. Average {getUserWord(motivationFilters)}{' '}
                  has {formatNumber(average_gross_production(inventory_users), true)} in gross.
                </p>
              </Infographic>
            </Grid>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic data={total_dcf(inventory_users)} isCurrency icon="calculator" print_view={print_view}>
                <p>
                  Total DCF valuation for {getUserWord(motivationFilters)}s. Average {getUserWord(motivationFilters)}{' '}
                  has a DCF valuation of {formatNumber(average_dcf(inventory_users), true)}
                </p>
              </Infographic>
            </Grid>
            <Grid item xs={6} className={print_view ? classes.infoGraphicContainerPrint : classes.infoGraphicContainer}>
              <Infographic data={getDealCount(inventory_users)} icon="shake" print_view={print_view}>
                <p>Number of meeting rooms started by sellers thus far.</p>
              </Infographic>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={print_view ? 12 : 6}>
          <div className="page-break" />
          <TransitionGoalPieChart
            data={generatePieData(inventory_users || [])}
            colorScale={colorScale}
            print_view={print_view}
          />
        </Grid>
      </Grid>
      <div className="page-break" />
      <Grid container spacing={2}>
        <Grid xs={12}>
          <h3 className={print_view ? classes.stateGraphTitlePrint : classes.stateGraphTitle}>
            Motivation by {currentCountry === 'ca' ? 'Province ' : 'State'}
          </h3>
          <svg width={`${print_view ? '2100' : '1800'}`} height={`${print_view ? '1000' : '500'}`}>
            <VictoryChart
              standalone={false}
              width={`${print_view ? 2100 : 1790}`}
              height={`${print_view ? 990 : 490}`}
              domainPadding={[ generateBarData(inventory_users || []).length * 10, 30 ]}
              padding={{ bottom: 80, left: 80, top: 20, right: 80 }}
              style={{
                labels : { fontSize: 8 },
              }}>
              <VictoryAxis dependentAxis orientation="left" />
              <VictoryAxis
                dependentAxis={false}
                orientation="bottom"
                tickLabelComponent={
                  <VictoryLabel
                    angle={26}
                    textAnchor="start"
                    style={[
                      {
                        fill     : 'black',
                        fontSize : generateBarData(inventory_users || []).length > 20 ? 12 : 15,
                      },
                    ]}
                  />
                }
              />
              <VictoryBar
                barRatio={0.5}
                x="state"
                y="users"
                data={generateBarData(inventory_users || [])}
                style={{ data: { fill: '#2C3E50' } }}
              />
            </VictoryChart>
          </svg>
        </Grid>
      </Grid>
    </div>
  );
};

const styles = {
  root                      : {
    maxWidth : '100%',
    margin   : '20px 0',
  },
  table                     : {
    minWidth : 650,
  },
  print                     : {
    width   : 'fit-content',
    display : 'block',
  },
  container                 : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu                      : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker                : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber                 : { fontSize: 50 },
  subHeader                 : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel               : { fontSize: 16 },
  selectatron               : { marginRight: 10 },
  formControl               : {
    margin   : 2,
    minWidth : 120,
  },
  infoGraphicContainer      : {
    margin : '34px 0px',
  },
  infoGraphicContainerPrint : {
    margin : '5px 0px',
    height : 25,
  },
  scaleWrapper              : {
    padding : '10px',
    height  : '1000px',
  },
  pieGraphTitle             : {
    marginLeft : '20%',
  },
  pieGraphTitlePrint        : {
    fontWeight   : 'bold',
    marginBottom : 0,
    fontSize     : 35,
  },
  stateGraphTitle           : {
    marginLeft : '20%',
  },
  stateGraphTitlePrint      : {
    fontWeight : 'bold',
    marginLeft : 5,
    fontSize   : 35,
  },
};

const mapStateToProps = state => ({
  motivationFilters    : state.motivationFilters,
  inventory_users      : state.inventory_users.inventory_users,
  inventory_user_count : state.inventory_users.inventory_user_count,
  inventory_user_count : state.inventory_users.inventory_user_count,
  transition_goals     : state.inventory_users.transition_goals,
  deals_count          : state.inventory_users.deals_count,
  currentCountry       : get(state, 'inventory_users.currentCountry', ''),
  print_view           : state.inventory_users.print_view,
});

const MotivationGraphWithStyles = withStyles(styles)(MotivationGraph);
export default connect(mapStateToProps)(MotivationGraphWithStyles);
