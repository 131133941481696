import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

const DeleteEducationalContentConfirmation = ({
  openDelete,
  educationalContent,
  handleClickDelete,
  clickDeleteEducationalContent,
}) => {
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={() => handleClickDelete()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete this Educational Content?</DialogTitle>
        <DialogContent>
          <div>Title: {educationalContent.title}</div>
          <div>Type: {educationalContent.contentType}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClickDelete({})} color="primary">
            Cancel
          </Button>
          <IconButton
            color="secondary"
            onClick={() => {
              clickDeleteEducationalContent();
            }}>
            Confirm
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteEducationalContentConfirmation.propTypes = {
  openDelete                    : PropTypes.bool,
  educationalContent            : PropTypes.object,
  handleClickDelete             : PropTypes.func.isRequired,
  clickDeleteEducationalContent : PropTypes.func.isRequired,
};

DeleteEducationalContentConfirmation.defaultProps = {
  openDelete         : false,
  educationalContent : {},
};

export default DeleteEducationalContentConfirmation;
