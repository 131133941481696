import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import DealCreate from '../components/DealWizard/DealCreate';
import * as actions from '../actions/dealActionCreators';

export const getCompanyName = gql`
  query getCompanyName {
    getCompanySearchItems {
      name
      stylesheet_name
      business_type
      deal_room_branded_name
      hide_continuity_builder_create_in_deal_wizard
      deal_room_reminder_seller_about_valuation
      valuation_url
    }
    getCurrentUser {
      id
      valuation_last_accessed_date
    }
  }
`;

export const DealCreateComponent = props => (
  <Query query={getCompanyName}>
    {({ loading, data }) => {
      if (loading) return <div />;
      return (
        <Fragment>
          <DealCreate
            {...props}
            companyName={data.getCompanySearchItems}
            dealRoomName={data.getCompanySearchItems.deal_room_branded_name}
            currentUser={data.getCurrentUser}
          />
        </Fragment>
      );
    }}
  </Query>
);

const mapStateToProps = state => ({
  deal  : state.deal,
  state,
});

export default connect(mapStateToProps, actions)(DealCreateComponent);
