import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import { get, isEmpty } from 'lodash';
import * as actions from '../actions/bobCardActionCreators';
import DashboardQueries from '../components/Dashboard/Dashboard.graphql';
import * as userActions from '../actions/userActionCreators';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import * as noteActions from '../actions/noteActionCreators';
import Dashboard from '../components/Dashboard/Dashboard';
import GetStartedContainer from './GetStartedContainer';
import WelcomeBanner from '../components/Dashboard/WelcomeBanner';

const ManagedDashboardContainer = ({
  bobCards,
  user,
  updateUser,
  loadBobCardList,
  updateBobCards,
  updateSelectedProfileDrawer,
  updateNotes,
}) => {
  const [ currentUserAllowed, setCurrentUserAllowed ] = useState(false);
  const [ data, setData ] = useState({});
  const [ currentUser, setCurrentUser ] = useState({});
  return (
    <Query
      query={DashboardQueries}
      onCompleted={returnData => {
        loadBobCardList({
          data : get(returnData, 'getCurrentUser.suggested_users'),
        });
        setCurrentUserAllowed(get(returnData, 'currentUserAllowed'));
        setData(returnData);
        setCurrentUser(get(returnData, 'getCurrentUser'));
        updateUser(get(returnData, 'getCurrentUser'));
      }}>
      {({ refetch }) => {
        if (isEmpty(bobCards)) {
          return <div />;
        }
        return (
          <div>
            {get(currentUser, 'company.show_welcome_banner', false) && (
              <div>
                <WelcomeBanner user={currentUser} />
                <GetStartedContainer currentUser={user} updateUser={updateUser} />
              </div>
            )}
            <Dashboard
              marketplaceOnly
              currentUserAllowed={currentUserAllowed}
              data={data}
              users={get(bobCards, 'data', [])}
              updateBobCards={updateBobCards}
              loadBobCardList={loadBobCardList}
              updateSelectedProfileDrawer={updateSelectedProfileDrawer}
              updateNotes={updateNotes}
              agentWordForCompany={get(data, 'agentWordForCompany')}
              refetch={refetch}
              companyBusinessType={get(data, 'companyBusinessType')}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  bobCards : state.bobCards,
  user     : state.user,
});

ManagedDashboardContainer.propTypes = {
  bobCards                    : PropTypes.object,
  user                        : PropTypes.object.isRequired,
  loadBobCardList             : PropTypes.func.isRequired,
  updateBobCards              : PropTypes.func.isRequired,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
  updateUser                  : PropTypes.func.isRequired,
};

ManagedDashboardContainer.defaultProps = {
  bobCards : [],
};

export default connect(mapStateToProps, {
  ...actions,
  ...profileDrawerActions,
  ...userActions,
  ...noteActions,
})(ManagedDashboardContainer);
