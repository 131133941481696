import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';


const Affiliations = ({ classes, user, obfuscateView, intl }) => {
  if (!isEmpty(get(user, 'affiliations'))) {
    const affiliations = get(user, 'affiliations', []);
    const affiliationNames = affiliations.map(p => p.name);
    return (
      <div className={classes.status}>
        <strong>Affiliations</strong>
        {!obfuscateView && (
          <p>
            {affiliationNames.map(s => (
              <span key={s}>
                {s}
                <br />
              </span>
            ))}
          </p>
        )}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default injectIntl(withStyles(styles)(Affiliations));
