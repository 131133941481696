import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import FBTextArea from '../FBTextArea/FBTextArea';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';

const UpdateCandidate = gql`
  mutation updateCandidateProfile(
    $family_background: String
    $educational_background: String
    $occupational_background: String
    $professional_clubs: String
    $areas_lived: String
    $current_career_goal: String
    $available_start_date: String
  ) {
    updateCandidateProfile(
      family_background: $family_background
      educational_background: $educational_background
      occupational_background: $occupational_background
      professional_clubs: $professional_clubs
      areas_lived: $areas_lived
      current_career_goal: $current_career_goal
      available_start_date: $available_start_date
    )
  }
`;

const styles = {
  container: {
    padding: 30,
  },
  nextBox: {
    margin: '30px auto 0px',
    display: 'table',
  },
};

class NewInBusinessForm extends PureComponent {
  state = {
    family_background: '',
    educational_background: '',
    occupational_background: '',
    professional_clubs: '',
    areas_lived: '',
    current_career_goal: '',
    available_start_date: null,
  };

  render() {
    const { classes, mutate, onNext } = this.props;
    const date = new Date();
    const { available_start_date } = this.state;

    return (
      <div className={classes.container}>
        <p>
          Tell us your story - anything you would like a prospective employer to know about you to further understand
          your background, personal and career goals.
          <br />
          <br />
        </p>
        <b>Family background (parent’s occupations, community activities, interests, etc.):</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ family_background: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Educational background (major, favorite subjects, extra-curricular):</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ educational_background: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Occupational background (previous jobs, internships, etc):</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ occupational_background: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Professional clubs to which I have belonged:</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ professional_clubs: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Cities/areas I have lived:</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ areas_lived: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Current Career Goal:</b>
        <FBTextArea
          placeholder=""
          onChange={e => {
            this.setState({ current_career_goal: e.target.value });
          }}
          shouldFitContainer
          rows={5}
          isLabelHidden
          compact
        />
        <b>Available Start Date:</b>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            minDate={date.setDate(date.getDate())}
            value={available_start_date || date.setDate(date.getDate())}
            format="MM/dd/yyyy"
            margin="normal"
            fullWidth
            onChange={dt => this.setState({ available_start_date: dt })}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.nextBox}>
          <Button
            variant="contained"
            onClick={() => {
              const {
                family_background,
                educational_background,
                occupational_background,
                professional_clubs,
                areas_lived,
                current_career_goal,
              } = this.state;
              mutate({
                variables: {
                  family_background,
                  educational_background,
                  occupational_background,
                  professional_clubs,
                  areas_lived,
                  current_career_goal,
                  available_start_date,
                },
              });
              onNext();
            }}
            color="primary">
            Next!
          </Button>
        </div>
      </div>
    );
  }
}

NewInBusinessForm.propTypes = {
  classes: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default graphql(UpdateCandidate)(withStyles(styles)(NewInBusinessForm));
