import { connect } from 'react-redux';
import TodoList from '../components/TodoList/TodoList';
import NewFormDialog from '../components/TodoList/TodoListNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/todoListsActionCreators';

const mapStateToProps = (state) => ({ ...state,
  listData: state.todo_lists,
  title: `Todo Lists`,
  NewFormDialog,
  CrudRow: TodoList,
 });

export default connect(mapStateToProps, actions)(CrudTable);
