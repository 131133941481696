import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import UserProfile from '../UserProfile/UserProfile';
import ContactInformation from './ContactInformation';
import PostalAndPhone from './PostalAndPhone';
import PracticeInformation from './PracticeInformation';
import StrengthsAndAvatar from './StrengthsAndAvatar';
import PlanningInfo from './PlanningInfo';
import UserBio from './UserBio';
import SwitchLocale from './SwitchLocale';
import ProductMix from './ProductMix';
import { isEmpty, get, invert, intersection, isNull } from 'lodash';
import { generatedHeadlineForUser, generatedBioForUser } from '../../lib/userUtils';
import qs from 'query-string';
import { trackAnalyticsEvent } from '../../lib/analytics';
import { clearSpotlights } from '../../lib/findBobUtils';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import AdvisorLicenseComponent from './AdvisorLicenseComponent';
import { S3Image } from '../../lib/s3';

const styles = theme => ({
  root                : {
    fontSize   : '16px',
    paddingTop : '25px',
  },
  backButton          : {
    marginRight : theme.spacing.unit,
  },
  instructions        : {
    marginTop    : theme.spacing.unit,
    marginBottom : theme.spacing.unit,
  },
  stepLabel           : {
    fontSize : '16px',
  },
  buttons             : {
    justifyContent : 'center',
  },
  modalRight          : {
    justifyContent : 'flex-end',
  },
  backdrop            : {
    background : 'red',
  },
  localeswitch        : {},
  localeSpacer        : {
    display       : 'flex',
    marginBottom  : '10px',
    flexDirection : 'row-reverse',
    position      : 'absolute',
    top           : '10px',
    right         : '20px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
  },
  rjpeColor           : {
    color : '#002949 !important',
  },
  companyLogo         : {
    marginTop    : 35,
    marginBottom : 20,
    textAlign    : 'center',
  },
});

function getSteps (hasBilling = false) {
  if (hasBilling)
    return [
      <FormattedMessage id="sign_up_pages.contact_information" />,
      <FormattedMessage id="sign_up_pages.build_your_profile" />,
      <FormattedMessage id="sign_up_pages.choose_plan" />,
    ];
  return [
    <FormattedMessage id="sign_up_pages.contact_information" />,
    <FormattedMessage id="sign_up_pages.build_your_profile" />,
    <FormattedMessage id="sign_up_pages.profile_complete" />,
  ];
}

export const STEP_NUMBERS = {
  signup         : 0,
  postalAndPhone : 0.5,
  practiceInfo   : 1,
  productMix     : 2,
  strengths      : 3,
  planningInfo   : 4,
  bio            : 5,
  complete       : 6,
};

class SignUpWizard extends React.Component {
  state = {
    activeStep     : 0,
    postalRequired : 0,
  };

  trueStepCount = 6;

  // componentDidMount = () => {
  //   const params = qs.parse(this.props.location.search);
  //   const { name, email, phone, zip } = params;
  //   this.props.updateUser({
  //     full_name : name,
  //     email,
  //     phone,
  //     postal    : zip,
  //   });
  // };

  componentWillReceiveProps = nextProps => {
    if (this.props.data.loading && !nextProps.data.loading) {
      const params = qs.parse(this.props.location.search);
      let { name, email, phone, zip } = params;
      const user = nextProps.data.getCurrentUser || {};
      name = isEmpty(name) ? user.name : name;
      email = isEmpty(email) ? user.email : email;
      phone = isEmpty(phone) ? user.phone : phone;
      zip = isEmpty(zip) ? user.postal_code : zip;

      nextProps.updateUser({
        ...nextProps.data.getCurrentUser,
        full_name : name,
        name,
        email,
        phone,
        postal    : zip,
      });
    }

    let activeStep = this.state.activeStep;
    if (!isEmpty(get(nextProps, 'data.getCurrentUser.user_name', null))) {
      const user = nextProps.data.getCurrentUser;
      if (this.state.activeStep < STEP_NUMBERS.practiceInfo) {
        if (user.planning_info_complete && (user.tag_list || []).length > 0) {
          activeStep = STEP_NUMBERS.bio;
          this.setState({ activeStep });
        } else if (
          get(this.props, 'data.company.ask_transition_readiness_on_signup') &&
          (user.tag_list || []).length > 0
        ) {
          activeStep = STEP_NUMBERS.planningInfo;
          this.setState({ activeStep });
        } else if (user.product_mix_complete) {
          activeStep = STEP_NUMBERS.strengths;
          this.setState({ activeStep });
        } else if (
          user.compensation_complete &&
          user.practice_complete &&
          user.revenue_complete &&
          user.completed_step_1 &&
          user.transition_goals_complete
         ) {
          activeStep = STEP_NUMBERS.productMix;
          this.setState({ activeStep });
        } else if (!user.completed_step_1) {
          activeStep = STEP_NUMBERS.postalAndPhone;
          this.setState({ activeStep });
        } else {
          activeStep = STEP_NUMBERS.practiceInfo;
          this.setState({ activeStep });
        }
      }
    }
    const stepNumber = activeStep;
    window.history.replaceState({}, window.history.title, `/signup?step=${invert(STEP_NUMBERS)[stepNumber]}`);
  };

  getStepContent = stepIndex => {
    const { updateUser, updateProductMix } = this.props;
    const { user, location, data, classes } = this.props;
    const { availableAdvisorLicenses, company } = data;
    const { locale } = get(data, 'getCurrentUser', {});
    const params = qs.parse(location.search);
    const referrer = params.ref;
    const productMix = user.product_mix || {};
    const alterItems = get(this.props, 'data.company.business_type') === 'wealth_rj';
    const disableTransitionReadiness = !get(this.props, 'data.company.ask_transition_readiness_on_signup');
    const disableProductMixStep = get(this.props, 'data.company.skip_product_mix_on_signup');
    const skipProductMix = get(this.props, 'data.company.hide_product_mix_on_profile');
    const useCanadianRetailWealth = get(this.props, 'data.company.use_canadian_retail_wealth_product_mix');
    const usePAndCProductMix = get(this.props, 'data.company.use_p_and_c_product_mix');
    const useWealthTypeCompanies = get(this.props, 'data.company.wealth_type_companies_product_mix');
    const askMeAboutMandatory = get(this.props, 'data.company.ask_me_about_mandatory');
    const stylesheetName = get(data, 'company.stylesheet_name', 'none');
    const disableRevenueSliders = get(this.props, 'data.company.imported_production_data_read_only');
    const iirocLicenseOptions = get(this.props, 'data.company.iiroc_licensing_options');
    const allowUserAffiliations = get(data, 'company.allow_user_affiliations');

    switch (stepIndex) {
      case STEP_NUMBERS.signup:
        if (get(data, 'company.identity_authentication_enabled', null) === false && !params.identity) {
          window.location.href = '/login?not_allowed';
        }
        return (
          <div>
            <div className={classes.localeSpacer}>
              <div className={classes.localeswitch}>
                <SwitchLocale
                  currentLocale={user.locale}
                  locales={get(data, 'company.supported_locales')}
                  updateAction={updateUser}
                />
              </div>
            </div>
            <ContactInformation
              full_name={user.full_name}
              email={user.email}
              birth_date={user.birth_date}
              password={user.password}
              postal={user.postal}
              phone={user.phone}
              referrer={referrer}
              utm_source={params.utm_source}
              utm_medium={params.utm_medium}
              utm_name={params.utm_name}
              utm_campaign={params.utm_campaign}
              onCreate={this.onUserCreate.bind(this)}
              countryForCompany={get(this.props, 'data.company.country')}
              locale={user.locale}
              agentWord={get(data, 'company.agent_word')}
              updateAction={updateUser}
              companyHasAgencyName={get(this.props, 'data.company.has_agency_name')}
              agency_name={user.agency_name}
              paramsLocale={params.locale}
              explicitOptIn={get(data, 'company.explicit_opt_in_tos_and_pp')}
              stylesheetName={stylesheetName}
              company={get(data, 'company')}
              privacyPolicy={get(this.props, 'data.getPrivacyPolicy')}
              termsOfService={get(this.props, 'data.getTermsOfService')}
              optInScrollPrivacyPolicy={get(this.props, 'data.company.opt_in_scroll_accept_tos_privacy_policy')}
            />
          </div>
        );
      case STEP_NUMBERS.postalAndPhone:
        return (
          <div>
            <div className={classes.localeSpacer}>
              <div className={classes.localeswitch}>
                <SwitchLocale currentLocale={user.locale} locales={data.localesForCompany} updateAction={updateUser} />
              </div>
            </div>
            <PostalAndPhone
              disableEmail={alterItems}
              disableName={!alterItems}
              postal_code={user.postal_code}
              phone={user.phone}
              email={user.email}
              name={user.name}
              countryForCompany={get(this.props, 'data.company.country')}
              locale={user.locale}
              updateAction={updateUser}
              onNext={() => {
                this.onUserCreate(false);
              }}
              stylesheetName={stylesheetName}
              modalTitle={
                alterItems ? (
                  <FormattedMessage id="sign_up_pages.welcome_message" values={{branded_name: get(this.props, 'data.company.branded_name', '') }} />
                ) : (
                  <FormattedMessage id="sign_up_pages.here_we_go" />
                )
              }
              modalSubtitle={
                alterItems ? (
                  <FormattedMessage id="sign_up_pages.please_review_contact_info" />
                ) : (
                  <FormattedMessage id="sign_up_pages.please_take_twenty_seconds" />
                )
              }
              brandedName={get(this.props, 'data.company.branded_name', '')}
              privacyPolicy={get(this.props, 'data.getPrivacyPolicy')}
              termsOfService={get(this.props, 'data.getTermsOfService')}
              optInScrollPrivacyPolicy={get(this.props, 'data.company.opt_in_scroll_accept_tos_privacy_policy')}
            />
          </div>
        );
      case STEP_NUMBERS.practiceInfo:
        return (
          <PracticeInformation
            alterItems={alterItems}
            year_career_began={user.year_career_began}
            years_practicing={user.years_practicing}
            number_of_clients={user.number_of_clients}
            avg_client_age={user.avg_client_age}
            annual_revenue={user.annual_revenue}
            assets_under_management={user.assets_under_management == null ? 10000000 : user.assets_under_management}
            percent_commission={user.percent_commission || 0}
            percent_fee_based={user.percent_fee_based || 0}
            recurring_revenue={user.recurring_revenue}
            percent_revenue_recurring={user.percent_revenue_recurring}
            percent_non_recurring_revenue={user.percent_non_recurring_revenue}
            transition_goals={user.transition_goals}
            planning_preference={user.planning_preference}
            fee_based_revenue={user.fee_based_revenue}
            trailer_revenue={user.trailer_revenue}
            direct_revenue={user.direct_revenue}
            transactional_revenue={user.transactional_revenue}
            showPlanningQuestion={get(data, 'company.show_planning_preference_question')}
            showRequestAumOnSutd={get(data, 'company.request_aum_on_sutd')}
            recognition_level={get(user, 'inventory_user.recognition_level', '')}
            locale={user.locale}
            updateAction={updateUser}
            disableProductMixStep={disableProductMixStep}
            onNext={this.handleNext}
            stylesheetName={stylesheetName}
            disableRevenueSliders={disableRevenueSliders}
            imported_production_data_read_only={get(data, 'company.imported_production_data_read_only')}
            modalSubtitle={
              alterItems ? (
                <FormattedMessage id="sign_up_pages.profiles_are" values={{ branded_name: get(this.props, 'data.company.branded_name') }} />
              ) : (
                <FormattedMessage id="sign_up_pages.complete_your_profile_to_appear" />
              )
            }
          />
        );
      case STEP_NUMBERS.planningInfo:
        return (
          <PlanningInfo
            user={user}
            updateAction={updateUser}
            company={company}
            onBack={() => this.handleBack()}
            onNext={this.handleNext}
            agentWord={get(data, 'company.agent_word')}
            stylesheetName={stylesheetName}
            askTransitionReadiness={get(this.props, 'data.company.ask_transition_readiness_on_signup')}
          />
        );
      case STEP_NUMBERS.productMix:
        const useCanadianRetailWealth= get(this.props, 'data.company.use_canadian_retail_wealth_product_mix', false);
        const usePAndCProductMix= get(this.props, 'data.company.use_p_and_c_product_mix', false);
    
        let reqF = [
          'life_ifp',
          'home_ifp',
          'auto_ifp',
          'commercial_ifp',
          'group_ifp',
          'health_ifp',
          'mutual_funds_aum',
          'securities_aum',
          'annuities_aua',
          'segregated_funds_aua',
          'mcpi_ifp',
          'private_products_ifp',
          'crop_hail_ifp',
          'crop_hail_with_wind_ifp',
          'farm_insurance_ifp',
          'price_products_ifp',
          'replant_supplement_ifp',
          'gic_fixed_income',
          'equity',
        ];
    
        if (useCanadianRetailWealth) {
          reqF = [
            'gic_fixed_income',
            'equity',
            'mutual_funds_aum',
            'segregated_funds_aua',
            'insurance',
          ];
        }
    
        if (usePAndCProductMix) {
          reqF = [
            'home_ifp',
            'commercial_ifp',
            'private_products_ifp',
            'crop_hail_with_wind_ifp',
            'life_ifp',
            'auto_ifp',
            'mcpi_ifp',
            'crop_hail_ifp',
            'farm_insurance_ifp',
            'replant_supplement_ifp',
            'health_ifp',
          ];
        }
        return (
          <div>
            <ProductMix
              user={user}
              life_ifp={productMix.life_ifp}
              home_ifp={productMix.home_ifp}
              auto_ifp={productMix.auto_ifp}
              commercial_ifp={productMix.commercial_ifp}
              health_ifp={productMix.health_ifp}
              group_ifp={productMix.group_ifp}
              mutual_funds_aum={productMix.mutual_funds_aum}
              securities_aum={productMix.securities_aum}
              annuities_aua={productMix.annuities_aua}
              segregated_funds_aua={productMix.segregated_funds_aua}
              mcpi_ifp={productMix.mcpi_ifp}
              private_products_ifp={productMix.private_products_ifp}
              crop_hail_ifp={productMix.crop_hail_ifp}
              crop_hail_with_wind_ifp={productMix.crop_hail_with_wind_ifp}
              farm_insurance_ifp={productMix.farm_insurance_ifp}
              price_products_ifp={productMix.price_products_ifp}
              replant_supplement_ifp={productMix.replant_supplement_ifp}
              gic_fixed_income={productMix.gic_fixed_income}
              insurance={productMix.insurance}
              equity={productMix.equity}
              availableProducts={data.availableProducts}
              fullName={user.name}
              aua={user.assets_under_management}
              updateAction={updateProductMix}
              updateUser={updateUser}
              disableProductMixStep={disableProductMixStep}
              useCanadianRetailWealth={useCanadianRetailWealth}
              usePAndCProductMix={usePAndCProductMix}
              useWealthTypeCompanies={useWealthTypeCompanies}
              onNext={this.handleNext}
              onBack={() => {
                this.handleBack();
              }}
              stylesheetName={stylesheetName}
              requiredFields={reqF}
              companyIsWealthType={
                get(company, 'business_type') === 'wealth' || get(company, 'business_type') === 'wealth_rj'
              }>
              {(get(company, 'business_type') === 'wealth' || get(company, 'business_type') === 'wealth_rj') && (
                <AdvisorLicenseComponent
                  availableAdvisorLicenses={availableAdvisorLicenses}
                  updateAction={updateUser}
                  userAdvisorLicenses={user.advisor_licenses}
                  stylesheetName={stylesheetName}
                  iirocLicenseOptions={iirocLicenseOptions}
                />
              )}
            </ProductMix>
          </div>
        );
      case STEP_NUMBERS.strengths:
        return (
          <StrengthsAndAvatar
            alterItems={alterItems}
            avatars={this.props.data.availableAvatars}
            currentAvatar={user.profile_picture}
            professionalStrengths={user.tag_list}
            updateAction={updateUser}
            onNext={() => {
              this.handleNext(disableTransitionReadiness ? STEP_NUMBERS.bio : STEP_NUMBERS.planningInfo);
            }}
            availableDesignations={this.props.data.availableDesignations}
            designations={user.designations}
            assets_under_management={user.assets_under_management}
            availableStrengths={this.props.data.availableStrengths}
            onBack={() => this.handleBack()}
            locale={locale}
            disableTransitionReadiness={disableTransitionReadiness}
            stylesheetName={stylesheetName}
            modalSubtitle={
              alterItems ? (
                <FormattedMessage id="sign_up_pages.complete_profile_to_improve" values={{branded_name: get(this.props, 'data.company.branded_name')}}/>
              ) : (
                <FormattedMessage id="sign_up_pages.complete_your_profile_to_appear" />
              )
            }
            affiliations={user.affiliations}
            availableAffiliations={this.props.data.availableAffiliations}
            data={data}
            allowUserAffiliations={allowUserAffiliations}
          />
        );
      case STEP_NUMBERS.bio:
        return (
          <UserBio
            headline={user.headline}
            user={user}
            updateAction={updateUser}
            onNext={this.handleNext}
            onBack={() => {
              this.handleBack(disableTransitionReadiness ? 2 : 1);
            }}
            countryForCompany={get(company, 'country')}
            companyHasCounty={get(company, 'has_county')}
            agentWord={get(company, 'agent_word')}
            locale={user.locale}
            stylesheetName={stylesheetName}
            alterItems={alterItems}
            show_ask_about_me={get(company, 'ask_me_about_question', false)}
            companyBusinessType={get(company, 'business_type')}
            askMeAboutMandatory={askMeAboutMandatory}
          />
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  handleNext = (step = STEP_NUMBERS.signup) => {
    clearSpotlights();
    trackAnalyticsEvent(`${invert(STEP_NUMBERS)[this.state.activeStep]}_completed`, {
      subjectUser : this.props.user.id,
    });
    this.setState({
      activeStep : step,
    });
    trackAnalyticsEvent(`${invert(STEP_NUMBERS)[this.state.activeStep]}_started`, {
      subjectUser : this.props.user.id,
    });
    document.querySelector('.dialogTitle').scrollIntoView(false);
  };

  handleBack = (stepsBack = 1) => {
    const { activeStep } = this.state;

    if (activeStep < 2) return;
    trackAnalyticsEvent(`${invert(STEP_NUMBERS)[activeStep]}_completed`, {
      subjectUser : this.props.user.id,
    });
    this.setState({
      activeStep : activeStep - stepsBack,
    });
    document.querySelector('.dialogTitle').scrollIntoView(false);
  };

  handleFinish = () => {
    this.setState({ activeStep: 0 });
  };

  renderTitle = () => {
    if (this.props.fullScreen) {
      return (
        <DialogTitle className="dialogTitle" id="responsive-dialog-title">
          Sign Up
        </DialogTitle>
      );
    }
    return <span className="dialogTitle" />;
  };

  onUserCreate = () => {
    window.location.reload();
  };

  stepToHighlight = step => {
    if (step < 1) return 0;
    else if (step > this.trueStepCount - 1) return 3;
    return 1;
  };

  redirectUrl = data => {
    if (get(data, 'company.has_billing')) return '/account_setup/step_2a';
    if (get(data, 'company.in_wait_list')) return '/wait_list/step_3';

    return '/dashboard/index';
  };

  render () {
    const { classes, data, width, updateUser, intl } = this.props;
    const { activeStep } = this.state;
    if (data.loading) return <div />;

    const steps = getSteps(get(data, 'company.has_billing'));
    let dialogMargin = '0';
    let dialogWidth = '550px';

    if (width === 'xl') {
      dialogMargin = '-72%';
    } else if (width !== 'sm' && width !== 'xs') {
      dialogMargin = '-72%';
      dialogWidth = '500px';
    }
    const stylesheetName = get(data, 'company.stylesheet_name', 'none');
    const businessType = get(this.props, 'data.company.business_type');
    const alterItems = get(this.props, 'data.company.business_type') === 'wealth_rj';
    if (
      get(this.props, 'data.company.business_type') === 'wealth_rj' &&
      get(this, 'props.user.id') &&
      !get(this, 'props.user.inventory_user.id')
    ) {
      window.location.href = '/not_authorized';
    }
    return (
      <div className="col-md-7 col-sm-7 right">
        <br />
        <UserProfile
          newUser
          data={{
            getUser                 : this.props.user,
            getCurrentUser          : this.props.user,
            currentUserInfoAuth     : true,
            companyHasFeature       : true,
            companyHasBilling       : false,
            currentUserAuctionCount : 0,
            currentUserASubscriber  : true,
            getSpotlights           : [],
            countryForCompany       : get(data, 'company.country'),
            availableProducts       : data.availableProducts,
            companyHasCounty        : get(data, 'company.has_county'),
            enabledStudentProfiles  : get(data, 'company.enabled_student_profiles'),
            availableStrengths      : data.availableStrengths,
            companyBusinessType     : businessType,
          }}
        />
        <div
          style={{
            marginLeft : dialogMargin,
            width: 500,
            position: 'absolute',
            top: 40,
            background: '#fff',
            border: '1px solid #eee',
            borderRadius: 4,
          }}>
          {alterItems && (
            <div className={classes.companyLogo}>
              <img src={S3Image(get(this.props, 'data.company.logo', ''))} />
            </div>
          )}
          <DialogContent
            style={{
              maxWidth : dialogWidth,
              maxHeight : 800,
            }}>
            {this.renderTitle()}
            <div className={classes.root}>
              <LinearProgress
                variant="determinate"
                classes={{ barColorPrimary: classes[`${stylesheetName}BackgroundColor`] }}
                value={activeStep / this.trueStepCount * 100}
              />
              <Stepper activeStep={this.stepToHighlight(activeStep)} alternativeLabel>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        classes : {
                          completed : classes[`${stylesheetName}Color`],
                          active    : classes[`${stylesheetName}Color`],
                        },
                      }}
                      classes={{
                        label : [ classes.stepLabel ],
                      }}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div>
                {this.state.activeStep === this.trueStepCount ? (
                  <span
                    style={{
                      opacity : 0,
                    }}>
                    {(window.location = this.redirectUrl(data))}
                  </span>
                ) : (
                  <div>
                    {this.getStepContent(activeStep)}
                    <div />
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </div>
      </div>
    );
  }
}

SignUpWizard.propTypes = {
  classes : PropTypes.object,
};

const SignUpWizardWithDialog = withMobileDialog()(withStyles(styles)(SignUpWizard));
export default injectIntl(SignUpWizardWithDialog);
