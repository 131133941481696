import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient'
import UserRating from '../components/UserRating/UserRating'

const UserRatingContainer = (props) => (
  <ApolloProvider client={client}>
    <UserRating props={props} />
  </ApolloProvider>
)

export default UserRatingContainer;
