import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import FBTableHead from '../FBTable/FBTableHead';

const styles = theme => ({
  root         : {
    width     : '100%',
    marginTop : theme.spacing.unit * 3,
  },
  table        : {
    overflow    : 'scroll',
    maxHeight   : 'calc(100vh - 315px)',
    height      : 'calc(100vh - 315px)',
    display     : 'flow-root',
    tableLayout : 'auto',
  },
  tableWrapper : {
    width : '100%',
  },
  tableCell    : {
    height : '85px',
  },
  stickyColumn : {
    position   : 'sticky',
    left       : 0,
    zIndex     : 10,
    background : 'white',
  },
});

class InventoryUserList extends React.Component {
  state = {
    selected         : [],
    showFilterDialog : false,
  };

  handleClick = (event, id) => {
    if (event.target.type !== 'checkbox' || event.target.id !== '') return;
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };

  isSelected = id => {
    const { selected } = this.state;
    return selected.indexOf(id) !== -1;
  };

  toggleFilterDialog = () => {
    const { showFilterDialog } = this.state;
    this.setState({ showFilterDialog: !showFilterDialog });
  };

  render () {
    const {
      classes,
      data,
      order,
      orderBy,
      rowsPerPage,
      page,
      count,
      columns,
      onSort,
      changePage,
      rowClick,
      changeRowsPerPage,
      client,
      refetch,
    } = this.props;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle" fixedHeader>
            <FBTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onRequestSort={onSort}
              rowCount={data.length}
              columns={columns}
              refetch={refetch}
            />
            <TableBody className="invUserRow">
              {data.map(n => (
                <TableRow
                  hover
                  onClick={event => {
                    rowClick(event, n, client);
                    this.handleClick(event, n.id);
                  }}
                  role="checkbox"
                  tabIndex={-1}
                  key={n.id}>
                  {columns.map(c => (
                    <TableCell padding="checkbox" className={classes.tableCell} style={c.customStyle || {}}>
                      {c.renderMethod ? c.renderMethod(n) : get(n, c.id) || '-'}
                    </TableCell>
                  ))}
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[ 10, 15, 25, 50, 100 ]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label' : 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label' : 'Next Page',
          }}
          onChangePage={changePage}
          onChangeRowsPerPage={e => changeRowsPerPage(e.target.value)}
        />
      </Paper>
    );
  }
}

InventoryUserList.propTypes = {
  classes           : PropTypes.object.isRequired,
  client            : PropTypes.object.isRequired,
  data              : PropTypes.array.isRequired,
  order             : PropTypes.string,
  orderBy           : PropTypes.string,
  rowsPerPage       : PropTypes.number.isRequired,
  page              : PropTypes.number.isRequired,
  count             : PropTypes.number.isRequired,
  columns           : PropTypes.array.isRequired,
  onSort            : PropTypes.func.isRequired,
  changePage        : PropTypes.func.isRequired,
  changeRowsPerPage : PropTypes.func.isRequired,
  rowClick          : PropTypes.func.isRequired,
  refetch           : PropTypes.func.isRequired,
};

InventoryUserList.defaultProps = {
  order   : '',
  orderBy : '',
};

export default withStyles(styles)(InventoryUserList);
