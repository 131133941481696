import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, replace } from 'lodash';
import CheckButton from '../../CheckButton/CheckButton';
import InputSlider from '../../InputSlider/InputSlider';
import { S3Image } from '../../../lib/s3';
import { spotlightElement } from '../../../lib/findBobUtils';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { uniq, toLower, capitalize, snakeCase } from 'lodash';

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

const ToggleDiscretionaryInvestmentModels = ({ updateAction, userDiscretionaryInvestmentModels, stylesheetName }) => {
  const [ dim, setToggle ] = useState(userDiscretionaryInvestmentModels);
  useEffect(
    () => {
      setToggle(userDiscretionaryInvestmentModels);
    },
    [ userDiscretionaryInvestmentModels ]
  );
  return (
    <div style={{ padding: 24 }}>
      <b><FormattedMessage id="profile_page.strengths_component.discretionary_investment_models" /></b>
      <br />
      <CheckButton
        stylesheetName={stylesheetName}
        label={<FormattedMessage id="profile_page.strengths_component.i_manage_discretionary" />}
        checked={dim}
        value={dim}
        onClick={() => {
          setToggle(!dim);
          updateAction({ discretionary_investment_models: !dim });
        }}
      />
    </div>
  );
};

ToggleDiscretionaryInvestmentModels.propTypes = {
  updateAction                      : PropTypes.func,
  userDiscretionaryInvestmentModels : PropTypes.bool,
};

ToggleDiscretionaryInvestmentModels.defaultProps = {
  userDiscretionaryInvestmentModels : false,
};

export default injectIntl(withStyles(styles)(ToggleDiscretionaryInvestmentModels));
