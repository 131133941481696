import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BobCard from '../BobCard/BobCard';
import Button from '@material-ui/core/Button';
import {FormattedMessage, injectIntl} from "react-intl";
import { get, isEmpty } from 'lodash';

const AddNote = gql`
  mutation addNote($userName: String!) {
    addNote(userName: $userName, text: "Added to Rolodex") {
      id
      comment
      created_at
    }
  }
`;

class TipViewDiscoverPartners extends React.Component {
  hanldeFollowAll = threeUsers => {
    for (let i = 0; i < threeUsers.length; i += 1) {
      if (threeUsers[i].note_count < 1) {
        this.hanldeFollow(threeUsers[i].user_name);
      }
    }
    window.open(`/dashboard/managed`, '_self');
  };

  hanldeFollow = userName => {
    const { mutate } = this.props;

    mutate({
      variables : { userName },
    }).catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
    });
  };

  isAdmin = () => {
    const { data } = this.props;
    const { getCurrentUser } = data;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles.includes('admin')) {
      return true;
    }
    return false;
  };

  disableMessageBtn = () => {
    const { data } = this.props;
    const { getCurrentUser } = data;
    const checkRoles = [ 'customer', 'staff' ];

    return (
      !get(getCurrentUser, 'company.networking_allowed') ||
      checkRoles.some(role => get(getCurrentUser, 'roles', []).includes(role))
    );
  };

  render () {
    const { data } = this.props;
    const { currentUserInfoAuth, companyHasFeature, companyHasCounty, countryForCompany, getCurrentUser, user } = data;
    const {
      updateBobCards,
      loadBobCardList,
      marketplaceOnly,
      agentWordForCompany,
      updateSelectedProfileDrawer,
      updateNotes,
      refetch,
      companyBusinessType,
      classes,
    } = this.props;
    const threeUsers = get(getCurrentUser, 'suggested_users', []).slice(0, 3);
    return (
      <div>
        <div>
          <header className="center">
            <div className={classes.title}><FormattedMessage id="motivation_onboarding.pages.discover_partners_text.title"/></div>
            <div
              className="cite no-bottom-margin center"
              style={{ width: '75%', marginLeft: 'auto', marginRight: 'auto' }}>
              <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.text" values={{agent_word: agentWordForCompany}} />
              <a href='/map'><FormattedMessage id="motivation_onboarding.pages.discover_partners_text.map"/></a>
              <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.or"/>
              <a href='/list'><FormattedMessage id="motivation_onboarding.pages.discover_partners_text.list"/></a>
              <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.based_search"/>
              <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.text_part_2"/>
            </div>
          </header>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Grid container justify="center" spacing={2} style={{ marginTop: 30 }}>
                {threeUsers &&
                  threeUsers.map(user => (
                    <Grid item xs={12} md={4}>
                      <BobCard
                        user={user}
                        isUserAuthorized={currentUserInfoAuth}
                        displaySocialButtons={companyHasFeature}
                        refetch={refetch}
                        companyHasCounty={companyHasCounty}
                        countryForCompany={countryForCompany}
                        updateBobCards={updateBobCards}
                        loadBobCardList={loadBobCardList}
                        userIsAdmin={this.isAdmin()}
                        currentUserHasLimitedAccess={getCurrentUser.limited_access}
                        currentCompanySellerWord={getCurrentUser.company.seller_only_label}
                        currentUserHasSellingTransitionGoals={getCurrentUser.has_selling_transition_goals}
                        getCurrentUser={getCurrentUser}
                        disableMessageBtn={this.disableMessageBtn()}
                        marketplaceOnly={marketplaceOnly}
                        updateSelectedProfileDrawer={updateSelectedProfileDrawer}
                        updateNotes={updateNotes}
                        companyBusinessType={companyBusinessType}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonSection}>
            <div className={classes.buttonDiv}>
              <Button variant="contained" onClick={() => this.hanldeFollowAll(threeUsers)} className={classes.button}>
                <FormattedMessage id="motivation_onboarding.pages.back_to_dashboard_button"/>
              </Button>
              <Button variant="contained" onClick={() => {window.location.href = "/map";}} className={classes.button} style={{ marginLeft: '10px' }}>
                <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.map_based_search_button"/>
              </Button>              
              <Button variant="contained" onClick={() => {window.location.href = "/list";}} className={classes.button} style={{ marginLeft: '10px' }}>
                <FormattedMessage id="motivation_onboarding.pages.discover_partners_text.list_based_search_button"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TipViewDiscoverPartners.propTypes = {
  data                        : PropTypes.object,
  mutate                      : PropTypes.func.isRequired,
  classes                     : PropTypes.object.isRequired,
  updateBobCards              : PropTypes.func.isRequired,
  loadBobCardList             : PropTypes.func.isRequired,
  marketplaceOnly             : PropTypes.bool,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
  updateNotes                 : PropTypes.func.isRequired,
  refetch                     : PropTypes.func.isRequired,
  companyBusinessType         : PropTypes.string,
};

TipViewDiscoverPartners.defaultProps = {
  user                : {},
  inventoryUser       : {},
  currentUser         : {},
  data                : {},
  marketplaceOnly     : true,
  companyBusinessType : '',
};

const styles = () => ({
  title         : {
    fontSize   : '24px',
    fontWeight : 100,
  },
  subTitle      : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  buttonSection : {
    width : '100%',
  },
  buttonDiv     : {
    marginLeft  : 'auto',
    marginRight : 'auto',
    width       : 'fit-content',
  },
  button        : {
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 25,
  },
});

export default withStyles(styles)(graphql(AddNote)(TipViewDiscoverPartners));
