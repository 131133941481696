import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  contentText : {
    fontSize : 'medium',
  },
};

class SurveyDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { userName, user, classes } = this.props;
    const { open } = this.state;

    const candidate_profile = get(user, 'candidate_profile', {}) || {};
    return (
      <div>
        <Button onClick={this.handleClickOpen}>See Markets Survey</Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" className={classes.contentText}>
            {userName} - Markets Survey
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" className={classes.contentText}>
              <div>
                <b>Family background (parents&apos; occupations, community activities, interests, etc.)</b>
                <br />
                {candidate_profile.family_background}
              </div>
              <div>
                <b>Educational background (major, favorite subjects, extra-curricular):</b>
                <br />
                {candidate_profile.educational_background}
              </div>
              <div>
                <b>Occupational background (previous jobs, internships, etc):</b>
                <br />
                {candidate_profile.occupational_background}
              </div>
              <div>
                <b>Professional clubs to which I have belonged:</b>
                <br />
                {candidate_profile.professional_clubs}
              </div>
              <div>
                <b>Cities/areas I have lived:</b>
                <br />
                {candidate_profile.areas_lived}
              </div>
              <div>
                <b>Current Career Goal:</b>
                <br />
                {candidate_profile.current_career_goal}
              </div>
              <div>
                <b>Available Start Date:</b>
                <br />
                {candidate_profile.available_start_date}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

SurveyDialog.propTypes = {
  userName : PropTypes.string.isRequired,
  user     : PropTypes.object,
  classes  : PropTypes.object.isRequired,
};

SurveyDialog.defaultProps = {
  user : {},
};

export default withStyles(styles)(SurveyDialog);
