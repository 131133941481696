import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import numeral from 'numeral';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

const BuyerSellerNote = ({buyerCashFlow, intl}) => {
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  return (
    <React.Fragment>
      <tr>
        <td>Seller Note Interest Payment</td>
        {buyerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red',}}>{numeral(v.sellerNoteInterestPayment).format(dollarFormat)}</td>))}
      </tr>
      <tr>
        <td>Seller Note Amortization</td>
        {buyerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red',}}>{numeral(v.sellerNoteAmortization).divide(-1).format(dollarFormat)}</td>))}
      </tr>
      
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};


export default connect(mapStateToProps)(injectIntl(BuyerSellerNote));