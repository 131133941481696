import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { get, isEmpty } from 'lodash';
import * as actions from '../actions/suggestedUserActionCreators';
import SuggestedUsersPage from '../components/SuggestedUsersPanel/SuggestedUsersPage';

export const SuggestionsQuery = gql`
  query getSuggestions {
    getSuggestions {
      id
      inventory_user_id
      name
      description
      case_type
      updated_at
      inventory_user {
        id
        name
      }
      value
      manager
    }
    pipelines {
      id
      name
      card_count
    }
    getCurrentUser {
      id
      name
      user_name
    }
  }
`;

const SuggestionsContainer = ({
  suggestedUsers,
  updateSuggestedUsersList,
  updateSuggestedUser,
  updateSelectedSuggestedUser,
}) => {
  return (
    <Query
      query={SuggestionsQuery}
      onCompleted={data => {
        updateSuggestedUsersList({
          suggestedUsers : data.getSuggestions,
        });
      }}>
      {({ data }) => {
        if (!isEmpty(get(suggestedUsers, 'suggestedUsers', []))) {
          return (
            <SuggestedUsersPage
              suggested_users={get(suggestedUsers, 'suggestedUsers', [])}
              suggestedUser={get(suggestedUsers, 'selected', {})}
              pipelineList={get(data, 'pipelines', {})}
              currentUser={get(data, 'getCurrentUser', {})}
              updateSuggestedUsersList={updateSuggestedUsersList}
              updateSuggestedUser={updateSuggestedUser}
              updateSelectedSuggestedUser={updateSelectedSuggestedUser}
            />
          );
        }
        return <div>Loading...</div>;
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  suggestedUsers : state.suggestedUsers,
  suggestedUser  : get(state.suggestedUsers, 'suggestedUser', {}),
});

SuggestionsContainer.propTypes = {
  suggestedUsers              : PropTypes.array,
  updateSelectedSuggestedUser : PropTypes.func.isRequired,
  updateSuggestedUsersList    : PropTypes.func.isRequired,
  updateSuggestedUser         : PropTypes.func.isRequired,
};

SuggestionsContainer.defaultProps = {
  suggestedUsers : [],
};

export default connect(mapStateToProps, actions)(SuggestionsContainer);
