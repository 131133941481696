import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { findIndex, snakeCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import SpecializedBusinesses from './SpecializedBusinesses';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($specialized_businesses: [String!]) {
    updateCurrentUser(specialized_businesses: $specialized_businesses)
  }
`;

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

let newStrengthsToAdd = [];
class SpecializedBusinessComponent extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      specialized_businesses : '',
    };
  }
  updateUser = () => {
    const { mutate, onNext, userSpecializedBusinesses, updateAction } = this.props;

    updateAction({ loading: true });

    mutate({
      variables : {
        specialized_businesses : userSpecializedBusinesses,
      },
    })
      .then(response => {
        onNext();
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  toggleSpecializedBusinesses = e => {
    let { userSpecializedBusinesses } = this.props;
    const { updateAction } = this.props;

    const index = findIndex(userSpecializedBusinesses, o => snakeCase(o) === snakeCase(e.target.value));
    userSpecializedBusinesses = [ ...userSpecializedBusinesses ];
    if (index > -1) {
      userSpecializedBusinesses.splice(index, 1);
    } else {
      userSpecializedBusinesses.push(snakeCase(e.target.value));
    }
    updateAction({ specialized_businesses: userSpecializedBusinesses });
  };

  addPrimaryMarket = () => {
    const { specialized_businesses } = this.state;
    this.setState({ specialized_businesses: '' });
    this.toggleSpecializedBusinesses({ target: { value: specialized_businesses } });
  };

  render () {
    const {
      userSpecializedBusinesses,
      availableSpecializedBusinesses,
      intl,
      businessType,
      saveUser,
      stylesheetName,
      disableUpdateButton,
    } = this.props;
    return (
      <div className="content">
        <b><FormattedMessage id="profile_page.strengths_component.specialized_business" /></b>
        <p><FormattedMessage id="profile_page.strengths_component.my_practice_is_unique" /></p>
        {userSpecializedBusinesses.length > 5 && <div style={{ color: 'red' }}><FormattedMessage id="profile_page.strengths_component.please_choose_or_less" /></div>}
        <div>
          <div className="col-md-9">
            <TextField
              id="specialized_business"
              label={intl.formatMessage({ id: `profile_page.strengths_component.specialized_business` })}
              type="text"
              value={this.state.specialized_businesses}
              onChange={e => {
                this.setState({ specialized_businesses: e.target.value });
              }}
              fullWidth
            />
          </div>
          <div className="col-md-3">
            <button onClick={this.addPrimaryMarket} className="btn btn-default" style={{ padding: '12px' }}>
              <AddIcon />
            </button>
          </div>
        </div>
        <br />
        <br />
        <SpecializedBusinesses
          availableSpecializedBusinesses={availableSpecializedBusinesses}
          userSpecializedBusinesses={userSpecializedBusinesses}
          toggleSpecializedBusinesses={this.toggleSpecializedBusinesses}
          stylesheetName={stylesheetName}
        />
        <br />
        <br />
        <br />
        <div className="text-center">
          {this.props.onBack && (
            <button
              className="btn btn-default"
              style={{ padding: '12px 24px', float: 'left' }}
              onClick={this.props.onBack}>
              <FormattedMessage id="sign_up_pages.back" />
            </button>
          )}
        </div>
      </div>
    );
  }
}

SpecializedBusinessComponent.propTypes = {
  updateAction      : PropTypes.func,
  availbleStrengths : PropTypes.array,
};

SpecializedBusinessComponent.defaultProps = {
  number_of_clients : 500,
};

const SpecializedBusinessComponentWithStyles = injectIntl(withStyles(styles)(SpecializedBusinessComponent));
export default graphql(UpdateCurrentUser)(SpecializedBusinessComponentWithStyles);
