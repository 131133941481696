import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'immutable';

const colorForByTileColor = (tileColor, number) => {
  if (typeof tileColor === 'string') {
    return tileColor;
  }
  return tileColor(number);
};

const CalibrationStaticGrid = ({
  data,
  classes,
  title,
  xLabel,
  yLabel,
  dataTitles,
  tileColors,
  tileColor,
  xSubTitles,
  ySubTitles,
}) => {
  return (
    <div className={classes.graph}>
      <div className={classes.yLabel}>{yLabel}</div>
      <div className={classes.xLabel}>{xLabel}</div>
      <div className={classes.outerGrid}>
        <div className={classes.title}>{title}</div>
        <div className={classes.container}>
          <div className={classes.ySubLabels}>
            <span style={{ flexGrow: 1 }}>{get(ySubTitles, 0)}</span>
            <span style={{ flexGrow: 1 }}>{get(ySubTitles, 1)}</span>
            <span style={{ flexGrow: 1 }}>{get(ySubTitles, 2)}</span>
          </div>
          <div className={classes.xSubLabels}>
            <span style={{ flexGrow: 1 }}>{get(xSubTitles, 0)}</span>
            <span style={{ flexGrow: 1 }}>{get(xSubTitles, 1)}</span>
            <span style={{ flexGrow: 1 }}>{get(xSubTitles, 2)}</span>
          </div>
          <div className={classes.outerSquare}>
            {data.map((d, i) => (
              <div className={classes.innerGrid}>
                <div
                  className={classes.innerSquare}
                  style={{ color: 'white', backgroundColor: get(tileColors, i) || colorForByTileColor(tileColor, d) }}>
                  <div className={classes.sectionTitle}>{dataTitles && dataTitles[i]}</div>
                  {d}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

CalibrationStaticGrid.propTypes = {
  data       : PropTypes.array,
  title      : PropTypes.string,
  xLabel     : PropTypes.string,
  yLabel     : PropTypes.string,
  dataTitles : PropTypes.array,
  tileColors : PropTypes.array,
  tileColor  : PropTypes.oneOfType([ PropTypes.string, PropTypes.func ]),
};

CalibrationStaticGrid.defaultProps = {
  data       : [],
  dataTitles : null,
  title      : '',
  xLabel     : '',
  yLabel     : '',
  tileColors : [],
  tileColor  : '#fff',
};

const styles = {
  container    : {},
  outerGrid    : {
    position      : 'relative',
    width         : 420,
    height        : 420,
    padding       : 20,
    paddingBottom : '33.3%',
    display       : 'inline-flex',
    margin        : 40,
    marginBottom  : 60,
    flexGrow      : 1,
  },
  outerSquare  : {
    position   : 'absolute',
    left       : 0,
    top        : 0,
    width      : 366,
    height     : 320,
    margin     : '10%',
    display    : 'flex',
    flexWrap   : 'wrap',
    paddingTop : 20,
  },
  innerGrid    : {
    width   : 120,
    height  : 120,
    display : 'table',
  },
  innerSquare  : {
    lineHeight    : '100%',
    fontSize      : '1.5rem',
    display       : 'table-cell',
    textAlign     : 'center',
    verticalAlign : 'middle',
    flexGrow      : 1,
    border        : '1px solid',
  },
  ySubLabels   : {
    position  : 'absolute',
    left      : -125,
    top       : 235,
    transform : 'rotate(270deg)',
    fontSize  : '0.9rem',
    display   : 'flex',
    width     : 300,
    textAlign : 'center',
  },
  yLabel       : {
    position  : 'relative',
    left      : -210,
    top       : 118,
    transform : 'rotate(270deg)',
    fontSize  : '1.2rem',
  },
  xLabel       : {
    position : 'relative',
    left     : 208,
    top      : 522,
    fontSize : '1.2rem',
  },
  xSubLabels   : {
    position  : 'absolute',
    bottom    : -35,
    left      : 50,
    fontSize  : '0.9rem',
    display   : 'flex',
    width     : 360,
    textAlign : 'center',
  },
  title        : {
    fontSize      : '1.5rem',
    marginBottom  : 24,
    marginLeft    : 24,
    textAlign     : 'center',
    width         : '100%',
    paddingBottom : 10,
  },
  sectionTitle : {
    position     : 'relative',
    top          : -30,
    right        : 0,
    textAlign    : 'center',
    fontSize     : '0.8rem',
    maxWidth     : '100%',
    overflow     : 'hidden',
    paddingRight : 3,
    fontWeight   : 500,
  },
  graph        : {
    display : 'inline-block',
    width   : 500,
    height  : 582,
  },
};

export default withStyles(styles)(CalibrationStaticGrid);
