import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Input from "@material-ui/core/Input";
import gql from "graphql-tag";
import {sortBy} from 'lodash';
import { withStyles } from '@material-ui/core';
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/Button";
import DeletePayoutButton from "./DeletePayoutButton";
import {Mutation} from "react-apollo";
import AddPayoutButton from "./AddPayoutButton";

const UpdatePayoutMutation = gql`
  mutation editCashFlowCompensationLookup(
    $id: ID!
    $compMax: Float
    $result: Float
  ) {
     editCashFlowCompensationLookup(id: $id, comp_max: $compMax, result: $result) {
       id
       comp_max
       result
     }
  }
`
export const PayoutGrid = ({ payoutGrids, classes, setRows }) => {
  const [changedRows, setSetChangedRows] = React.useState([]);
  const payoutRows = sortBy(payoutGrids, ['comp_max', 'id']);
  return (
    <Mutation mutation={UpdatePayoutMutation}>
      {(updatePayout) => (
        <div>
          <Table className={classes.root}>
            <TableHead>
              <TableRow>
                <TableCell>Comp Max</TableCell>
                <TableCell>Result</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {payoutRows.map(pg => {
              const {id, comp_max, result} = pg;
              const onChange = e => {
                const value = e.target.value;
                const name = e.target.name;
                const newRows = payoutRows.map(row => {
                  if (row.id === id) {
                    return { ...row, [name]: parseFloat(value) };
                  }
                  return row;
                });
                setRows(newRows);
                const index = changedRows.indexOf(pg.id);

                if(index < 0) {
                  changedRows.push(id);
                  setSetChangedRows(changedRows);
                }
              }
              return (<TableRow key={pg.id}>
                <TableCell><Input value={comp_max} type="number" name="comp_max" onChange={e => onChange(e)}/></TableCell>
                <TableCell><Input value={result} type="number" name="result" onChange={e => onChange(e)} /></TableCell>
                <TableCell>
                  <IconButton color="primary" disabled={!changedRows.includes(id)} onClick={() => {
                    updatePayout({variables: pg}).then(r => {
                      const index = changedRows.indexOf(pg.id);
                      let rs = [...changedRows]
                      rs.splice(index, 1);
                      setSetChangedRows(rs);
                    });
                  } } ><SaveIcon /></IconButton>
                  <DeletePayoutButton setRows={setRows} id={id} payoutGrids={payoutRows} />
                </TableCell>
              </TableRow>);
            })}
          </Table>
          <AddPayoutButton payoutRows={payoutRows} setRows={setRows}/>
        </div>
      )}
    </Mutation>
  );
}

PayoutGrid.propTypes = {
  payoutGrid    : PropTypes.array,
  classes       : PropTypes.object.isRequired,
  refetch       : PropTypes.func.isRequired,
};

PayoutGrid.defaultProps = {
  PayoutGrid : [],
};

const styles = theme => ({
  root       : {
    flexGrow : 1,
    fontSize : '18px',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing.unit * 2,
  },
  title      : {
    fontSize : 18,
    color    : '#fff',
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
  },
});

export default withStyles(styles)(PayoutGrid);
