import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from '../../lib/s3';
import moment from 'moment';
import { get } from 'lodash';

const useStyles = makeStyles(theme => ({
  inline       : {
    display : 'inline',
  },
  readColumn   : {
    width     : 40,
    marginTop : 30,
    fontSize  : 8,
  },
  callToAction : { float: 'right' },
  time         : {
    position : 'absolute',
    bottom   : 20,
  },
}));

export const PersonalMessageNotification = props => {
  const { notification } = props;
  const classes = useStyles();
  const profile_picture = get(notification, 'actor.profile_picture');
  return (
    <ListItem alignItems="flex-start">
      <ListItemText>
        {!get(notification, 'readAt') ? (
          <div className={classes.readColumn}>&#128313;</div>
        ) : (
          <div className={classes.readColumn}>&nbsp;</div>
        )}
      </ListItemText>
      <ListItemAvatar>
        <Avatar name={get(notification, 'notifiableType')} src={profile_picture ? S3Image(profile_picture) : null} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Fragment>
            <FormattedMessage id="notifications.youve_received" /><b><FormattedMessage id="notifications.new_messsage" /></b><FormattedMessage id="notifications.from" />{get(notification, 'actor.allowed_name', '')}.
          </Fragment>
        }
        secondary={
          <Fragment>
            <span className={classes.time}>{get(notification, 'readAt') && moment(notification.readAt).fromNow()}</span>
            <br></br>
            <Button
              href={`/messages?room_id=${get(notification, 'notifiable.conversation.id')}`}
              className={classes.callToAction}>
              <FormattedMessage id="notifications.view_message" />
            </Button>
          </Fragment>
        }
      />
    </ListItem>
  );
};

PersonalMessageNotification.propTypes = {
  notification : PropTypes.object,
};

PersonalMessageNotification.defaultProps = {
  notification : {},
};

export default PersonalMessageNotification;
