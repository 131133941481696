import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Fab from '@material-ui/core/Fab';
import { ApolloProvider, Query, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import client from '../../lib/apolloClient';
import MyOpenTasksCard from '../Staff/MyOpenTasksCard';
import { OpenTasksQuery, updateTaskMutation } from '../Tasks/TaskQuery';

class MyOpenTasks extends React.Component {
  clickViewAll = () => {
    window.open(`/admin/tasks`, '_self');
  };

  clickViewSuggestions = () => {
    window.open(`/admin/pipelines`, '_self');
  };

  clickCreateTask = () => {
    window.open(`/admin/tasks?new_task=true`, '_self');
  };

  render () {
    const { currentUser, classes } = this.props;
    return (
      <div>
        <ApolloProvider client={client}>
          <Query query={OpenTasksQuery} variables={{ userID: currentUser.id }}>
            {({ data, loading, refetch }) => {
              if (loading) return <div />;
              if (!loading && !isEmpty(data.getOpenTasks)) {
                return (
                  <div>
                    {data.getOpenTasks
                      .slice(0, 5)
                      .map(c => (
                        <Mutation mutation={updateTaskMutation}>
                          {updateTask => <MyOpenTasksCard mutation={updateTask} task={c} refetch={refetch} />}
                        </Mutation>
                      ))}
                    <div className={classes.viewAll}>
                      <button type="button" className={classes.contentButton} onClick={() => this.clickViewAll()}>
                        <span className={classes.content}>View All Tasks</span>
                      </button>
                    </div>
                  </div>
                );
              }
              return (
                <div>
                  <Fab
                    style={{ width: '70%', marginTop: '120px' }}
                    variant="extended"
                    size="medium"
                    color="primary"
                    aria-label="Add"
                    onClick={() => this.clickCreateTask()}>
                    Create a new task!
                  </Fab>
                </div>
              );
            }}
          </Query>
        </ApolloProvider>
      </div>
    );
  }
}

MyOpenTasks.propTypes = {
  currentUser : PropTypes.object.isRequired,
  classes     : PropTypes.object.isRequired,
};

const styles = {
  root          : {
    width     : '100%',
    marginTop : 5,
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  taskButton    : {
    height         : 'auto',
    padding        : 0,
    border         : 'none',
    background     : 'none',
    textDecoration : 'underline',
    marginTop      : 20,
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
};

export default withStyles(styles)(MyOpenTasks);
