import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FreeToMessage from './StatusComponents/FreeToMessage';
import ExpressInterest from './StatusComponents/ExpressInterest';
import BecomeAPartner from './StatusComponents/BecomeAPartner';
import Disabled from './StatusComponents/Disabled';
import StudentFreeToMessage from './StatusComponents/StudentFreeToMessage';

const sectionSelector = {
  regular_seller             : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
  regular_buyer              : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
  implicitly_obfuscated_user : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
  explicitly_obfuscated_user : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
  limited_access_seller      : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
  limited_access_buyer       : {
    regular_buyer              : props => <StudentFreeToMessage {...props} />,
  },
};

const getComponentType = props => sectionSelector[props.getCurrentUser.profile_type][props.user.profile_type](props);

const ProfileDrawerSideBar = props => {
  return <div>{getComponentType(props)}</div>;
};

ProfileDrawerSideBar.propTypes = {
  agentWordForCompany                  : PropTypes.string.isRequired,
  classes                              : PropTypes.object.isRequired,
  companyBusinessType                  : PropTypes.string.isRequired,
  companyHasNetworking                 : PropTypes.bool.isRequired,
  companyHasNewMessages                : PropTypes.bool.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool,
  currentUserHasSellingTransitionGoals : PropTypes.bool,
  disableMessageBtn                    : PropTypes.bool,
  displaySocialButtons                 : PropTypes.bool,
  expressThroughManager                : PropTypes.bool,
  getCurrentUser                       : PropTypes.object.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  marketplaceOnly                      : PropTypes.bool,
  notes                                : PropTypes.array.isRequired,
  obfuscateView                        : PropTypes.bool.isRequired,
  scrollTo                             : PropTypes.func.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  user                                 : PropTypes.object.isRequired,
};

ProfileDrawerSideBar.defaultProps = {
  expressThroughManager                : false,
  displaySocialButtons                 : false,
  marketplaceOnly                      : false,
  disableMessageBtn                    : false,
  currentUserHasSellingTransitionGoals : false,
  currentUserHasLimitedAccess          : false,
};

const styles = {
  margin : {
    margin : 5,
  },
};

export default withStyles(styles)(ProfileDrawerSideBar);
