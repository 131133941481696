import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import {
  VictoryBar,
  VictoryLegend,
  VictoryChart,
  VictoryTheme,
  VictoryStack,
  VictoryAxis,
  VictoryZoomContainer,
} from 'victory';
import DateRangeFilterContainer from '../../containers/DateRangeFilterContainer';
import { capturePdf } from '../../lib/findBobUtils';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';

const colorScale = [
  '#337ab7',
  'rgb(0, 121, 107)',
  'silver',
  'rgb(25, 245, 157)',
  '#FF8A65',
  '#FFF176',
  '#AFB42B',
  '#BA68C8',
  '#BCAAA4',
  '#004D40',
];
class ActivityReport extends React.Component {
  state = { filter: 'all' };

  getTableData = combinedActs => {
    const managers = Object.keys(combinedActs);
    return managers.map((m, i) => ({
      key   : m,
      total : Object.values(combinedActs[managers[i]]).reduce((a, b) => a + b, 0),
      ...combinedActs[managers[i]],
    }));
  };

  componentDidMount = () => {
    const { updateDateFilter } = this.props;
    updateDateFilter({
      sendStart : moment().add(-1, 'month'),
      sendEnd   : moment(),
    });
  };

  combineActivities = () => {
    const { data, startDate, endDate } = this.props;
    const { filter } = this.state;
    let tasks;
    let filteredTasks;
    let filteredActivities;
    let activities = [];
    const combinedObj = {};

    for (let i = 0; i < get(data, 'getManagers', []).length; i += 1) {
      filteredTasks = get(data, `getManagers[${i}].inventory_user.completed_tasks`).filter(
        t =>
          moment(t.statusChangedDate).isAfter(startDate, 'YYYY-MM-DD') ||
          (moment(t.statusChangedDate).isSame(startDate, 'YYYY-MM-DD') &&
            moment(t.statusChangedDate).isBefore(endDate, 'YYYY-MM-DD')) ||
          moment(t.statusChangedDate).isSame(endDate, 'YYYY-MM-DD')
      );
      tasks = filteredTasks.map(t => get(t, 'activityType.name'));
      filteredActivities = get(data, `getManagers[${i}].inventory_user.pipeline_activities`).filter(
        t =>
          moment(t.activityDate).isAfter(startDate, 'YYYY-MM-DD') ||
          (moment(t.activityDate).isSame(startDate, 'YYYY-MM-DD') &&
            moment(t.activityDate).isBefore(endDate, 'YYYY-MM-DD')) ||
          moment(t.activityDate).isSame(endDate, 'YYYY-MM-DD')
      );
      activities = filteredActivities.map(t => get(t, 'activityType.name'));
      let total = tasks.concat(activities);
      if (filter !== 'all') {
        total = total.filter(a => a === filter);
      }
      const counts = {};
      const managersName = get(data, `getManagers[${i}].name`);
      total.forEach(function (x) {
        counts[x] = (counts[x] || 0) + 1;
      });
      combinedObj[managersName] = counts;
    }
    return combinedObj;
  };

  countActivities = activities => {
    let count = 0;
    if (!isEmpty(activities)) {
      const objArray = Object.values(activities);
      for (let i = 0; i < objArray.length; i += 1) {
        if (!isEmpty(objArray[i])) {
          count += Object.values(objArray[i]).reduce((a, b) => a + b);
        }
      }
      return count;
    }
    return count;
  };

  dataForGraph = combinedActs => {
    const { data } = this.props;
    const activityTypes = get(data, 'getActivityTypes', []).map(a => a.name);
    const managerNames = Object.keys(combinedActs);
    const graphData = [];

    for (let j = 0; j < activityTypes.length; j += 1) {
      const lineData = [];
      for (let i = 0; i < managerNames.length; i += 1) {
        lineData.push({ x: `${managerNames[i]}`, y: combinedActs[managerNames[i]][activityTypes[j]] || 0 });
      }
      graphData.push(lineData);
    }
    return graphData;
  };

  clickedCapture = () => {
    capturePdf('l', 10, 10, 327, 210, 2);
  };

  render () {
    const { classes, data } = this.props;
    const { filter } = this.state;
    const combinedActivities = this.combineActivities();
    const activityTypes = get(data, 'getActivityTypes', []).map(a => a.name);
    const tableData = this.getTableData(combinedActivities);
    const graphData = this.dataForGraph(combinedActivities);

    return (
      <div className={`col-md-12 col-sm-12 ${classes.container}`}>
        <div id="capture">
          <h4 className={classes.subHeader}>Activities completed by team member.</h4>
          <Grid container>
            <Grid>
              <h2 className={classes.bigNumber}>
                {this.countActivities(combinedActivities)} <span className={classes.numberLabel}>Total Activities</span>
              </h2>
            </Grid>
          </Grid>
          <div className={classes.menu}>
            <Select
              classes={{ select: classes.selectatron }}
              defaultValue="All"
              value={filter}
              onClick={e => {
                if (e.target.value) {
                  this.setState({ filter: e.target.value });
                }
              }}>
              <MenuItem value="all">
                <em>All</em>
              </MenuItem>
              {activityTypes.map(p => <MenuItem value={p}>{decodeURI(p)}</MenuItem>)}
            </Select>
            <div className={classes.datepicker}>
              &nbsp;Completed Date:{' '}
              <DateRangeFilterContainer defaultStartDate={moment().add(-1, 'month')} defaultEndDate={moment()} />
            </div>
            <Tooltip title="Download PDF" style={{ marginLeft: 30 }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </div>
          <VictoryChart
            width={790}
            height={300}
            domainPadding={[ 30, 30 ]}
            padding={{ bottom: 80, left: 80, top: 20, right: 80 }}
            theme={VictoryTheme.material}
            containerComponent={<VictoryZoomContainer zoomDomain={{ x: [ 0, 20 ] }} />}>
            <VictoryAxis
              dependentAxis={false}
              orientation="left"
              style={{
                tickLabels : { fontSize: 8 },
              }}
            />
            <VictoryAxis dependentAxis orientation="bottom" />
            <VictoryLegend
              x={730}
              y={50}
              colorScale={colorScale}
              style={{ labels: { fontSize: 6 } }}
              data={get(data, 'getActivityTypes', []).map(a => ({ name: decodeURI(a.name) }))}
            />
            <VictoryStack style={{ data: { strokeWidth: 0 } }} colorScale={colorScale}>
              {graphData.map(d => <VictoryBar horizontal data={d} />)}
            </VictoryStack>
          </VictoryChart>

          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                {activityTypes.map(a => <TableCell align="right">{a}</TableCell>)}
                <TableCell align="right">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.reverse().map(manager => (
                <TableRow key={manager.key}>
                  <TableCell component="th" scope="row">
                    {decodeURI(manager.key)}
                  </TableCell>
                  {activityTypes.map(a => <TableCell align="right">{manager[a] || '-'}</TableCell>)}
                  <TableCell align="right">
                    <b>{manager.total || 0}</b>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

ActivityReport.propTypes = {
  startDate        : PropTypes.string.isRequired,
  endDate          : PropTypes.string.isRequired,
  classes          : PropTypes.object.isRequired,
  data             : PropTypes.object.isRequired,
  updateDateFilter : PropTypes.func.isRequired,
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  container   : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu        : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 10 },
  datepicker  : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber   : { fontSize: 50 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
};
export default withStyles(styles)(ActivityReport);
