import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import numeral from "numeral";
import { get } from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import TextField from "@material-ui/core/TextField";
import { FormattedMessage, injectIntl } from "react-intl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const SellerInfo = ({ list, query, classes, updateCfaQuery, intl, currentUser, company }) => {
  const validate = () => {
    let valid = true;

    if (!query.sellerT12Revenue || parseFloat(query.sellerT12Revenue) < 1) {
      valid = false;
    }

    if (query.sellerManualTaxRate == undefined || (query.sellerManualTaxRate && parseFloat(query.sellerTaxRate || 0) < 0.01)) {
      valid = false;
    }

    if (query.valid != valid) {
      updateCfaQuery({ ...query, valid });
    }
  }

  const intlConfig = () => {
    if (intl.locale == 'fr') {
      return { locale: 'fr-CA', currency: 'CAD' };
    }
    return { locale: 'en-US', currency: 'USD' };
  };

  validate();

  if (company.host.toString().includes("rjf.com")) {
    query.sellerManualTaxRate = false;
  }

  return <div>
    <h2><FormattedMessage id="calculators.cfa.tell_us_about" /></h2>
    <p>
      <FormattedMessage id="calculators.cfa.participant_copy" />
    </p>
    <center>
      <br />
      <dl>
        <dt><FormattedMessage id="calculators.cfa.seller_t12_rev_q" /> </dt>
        <dd><CurrencyInput
          decimalsLimit={2}
          intlConfig={intlConfig()}
          style={{ width: 'inherit' }}
          value={query.sellerT12Revenue}
          onValueChange={value => {
            let sellerFiveYearGrowthRate = query.sellerFiveYearGrowthRate;

            if (sellerFiveYearGrowthRate == undefined) {
              sellerFiveYearGrowthRate = 0;
            }

            updateCfaQuery({ ...query, sellerFiveYearGrowthRate, sellerT12Revenue: parseFloat(value), })
          }}
        /></dd>
        {!company.host.toString().includes("rjf.com") &&
          <React.Fragment>
            <dt><FormattedMessage id="calculators.cfa.seller_5_growth_q" /></dt>
            <dd><FBPercentInput
              style={{ width: 'inherit' }}
              value={query.sellerFiveYearGrowthRate}
              onChange={val => {
                updateCfaQuery({ ...query, sellerFiveYearGrowthRate: val })
              }}
              lower={-100}
            /></dd>
          </React.Fragment>
        }
        {!company.host.toString().includes("rjf.com") &&
          <React.Fragment>
            <dt><FormattedMessage id="calculators.cfa.manually_override_tax_rate" /></dt>
            <dd>
              <RadioGroup row className="right" aria-label="overrideTaxRate" name="overrideTaxRate" value={`${query.sellerManualTaxRate}`} onChange={e => {
                const sellerManualTaxRate = Boolean("true" == e.target.value);
                let sellerTaxRate = query.sellerTaxRate;
                if (sellerManualTaxRate && sellerTaxRate === undefined) {
                  sellerTaxRate = 0.35;
                }
        
                updateCfaQuery({ ...query, sellerManualTaxRate, sellerTaxRate, })
              }}>
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.dynamic" })} />
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.manual" })} />
              </RadioGroup>
            </dd>
          </React.Fragment>
        }
        <div className={classes.question}></div>
        {query.sellerManualTaxRate &&
          <React.Fragment>
            <dt><FormattedMessage id="calculators.cfa.tax_rate" /></dt>
            <dd>
              <FBPercentInput
                style={{ width: 'inherit' }}
                value={query.sellerTaxRate}
                upper={300}
                onChange={val => {
                  updateCfaQuery({ ...query, sellerTaxRate: val })
                }}
              />
            </dd>
          </React.Fragment>
         }
      </dl>
    </center>
  </div>
};

SellerInfo.propTypes = {
  query: PropTypes.array,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
};

SellerInfo.defaultProps = {
  list: [],
}

const styles = () => ({
  question: {
    clear: 'both',
  },
});

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    currentUser: { ...get(state, 'currentUser', {}) },
    company: { ...get(state, 'company', {}) },
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(SellerInfo)));