import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import TipViewDiscoverPartners from '../components/TipViewDiscoverPartners/TipViewDiscoverPartners';
import DashboardQueries from '../components/Dashboard/Dashboard.graphql';
import * as actions from '../actions/bobCardActionCreators';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import * as userActions from '../actions/userActionCreators';
import { get, isEmpty } from 'lodash';
import ProfileDrawerWithListener from './ProfileDrawerWithListener';

const TipViewDiscoverPartnersContainer = ({
  bobCards,
  updateUser,
  loadBobCardList,
  updateBobCards,
  updateSelectedProfileDrawer,
}) => {
  const [ currentUserAllowed, setCurrentUserAllowed ] = useState(false);
  const [ data, setData ] = useState({});
  return (
    <Query
      query={DashboardQueries}
      onCompleted={returnData => {
        loadBobCardList({
          data : get(returnData, 'getCurrentUser.suggested_users'),
        });
        setCurrentUserAllowed(get(returnData, 'currentUserAllowed'));
        setData(returnData);
        updateUser(get(returnData, 'getCurrentUser'));
      }}>
      {({ refetch }) => {
        if (isEmpty(bobCards)) {
          return <div>Loading...</div>;
        }
        return (
          <div>
            <ProfileDrawerWithListener />
            <TipViewDiscoverPartners
              marketplaceOnly
              currentUserAllowed={currentUserAllowed}
              data={data}
              users={get(bobCards, 'data', [])}
              updateBobCards={updateBobCards}
              loadBobCardList={loadBobCardList}
              updateSelectedProfileDrawer={updateSelectedProfileDrawer}
              agentWordForCompany={get(data, 'agentWordForCompany')}
              refetch={refetch}
              companyBusinessType={get(data, 'companyBusinessType')}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  bobCards : state.bobCards,
  profile  : state.profileDrawer,
  notes    : state.notes,
});

TipViewDiscoverPartnersContainer.propTypes = {
  bobCards                    : PropTypes.object,
  updateUser                  : PropTypes.func.isRequired,
  loadBobCardList             : PropTypes.func.isRequired,
  updateBobCards              : PropTypes.func.isRequired,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
};

TipViewDiscoverPartnersContainer.defaultProps = {
  bobCards : [],
};

export default connect(mapStateToProps, {
  ...actions,
  ...profileDrawerActions,
  ...userActions,
})(TipViewDiscoverPartnersContainer);
