import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NickNameInput from '../NickNameInput/NickNameInput';
import { nameForUser, areUsersTheSame } from '../../lib/userUtils.jsx';

const UserName = ({ getUser, getCurrentUser, showRolodex, userUpdate }) => {
  const user = getUser;
  const currentUser = getCurrentUser;
  if (areUsersTheSame(user, currentUser) || (!showRolodex && !getUser.can_view_real_name)) {
    return (
      <h3>
        <span
          data-container="body"
          data-toggle="popover"
          data-placement="right"
          data-content="This is your unique identifier on the Practice Exchange platform. All profiles have their own unique FB ID number to identify their profile and protect their identity.">
          {nameForUser(user)}
        </span>
      </h3>
    );
  } else if (getUser.can_view_real_name) {
    return (
      <h3>
        <span>{getUser.allowed_name}</span>
      </h3>
    );
  }
  return <NickNameInput userName={user.user_name} userId={user.id} nickName={user.nick_name} callback={userUpdate} />;
};

export default UserName;
