import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';
import { orderBy, get } from 'lodash';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';

const Conversation = ({ loading, conversation, openModal, setOpenModal, classes }) => {
  const [ currentConvo, setCurrentConvo ] = useState(conversation);
  useEffect(
    () => {
      setCurrentConvo(conversation);
    },
    [ conversation ]
  );

  return (
    <Dialog
      onClose={() => setOpenModal(false)}
      aria-labelledby="simple-dialog-title"
      open={openModal}
      fullWidth
      maxWidth="sm">
      <Typography variant="h5" align="center" style={{ padding: 10 }}>
        Conversation
      </Typography>
      <List>
        {loading && <div>Loading...</div>}
        {!loading &&
          orderBy(currentConvo, o => moment(o.createdAt).format('YYYYMMDDHHmmss'), ['desc']).map(c => (
            <div className={classes.messageContainer}>
              <div className={classes.message}>
                <span
                  className={classes.name}
                  style={{ color: `${get(currentConvo[0], 'sender.name', '') === get(c, 'sender.name', '-') ? '#2874A6' : ''}` }}>
                  {get(c, 'sender.name', '-')}
                </span>{' '}
                &middot;
                <span className="#dadada">{`${moment(c.createdAt).format('MMM Do YYYY, h:mm a')} EST`}</span>
                <br />
                <span className={classes.messageFormat} dangerouslySetInnerHTML={{__html: c.body}}></span>
              </div>
            </div>
          ))}
      </List>
    </Dialog>
  );
};

Conversation.propTypes = {
  classes            : PropTypes.object.isRequired,
  event              : PropTypes.object.isRequired,
  firstName          : PropTypes.string.isRequired,
  initials           : PropTypes.string.isRequired,
  date               : PropTypes.string.isRequired,
  note               : PropTypes.string.isRequired,
  activity           : PropTypes.string.isRequired,
  currentUserIsAdmin : PropTypes.bool.isRequired,
};

const styles = {
  messageContainer : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 10,
  },
  header           : {
    display : 'flex',
  },
  name             : {
    fontWeight : 'bold',
  },
  date             : {
    color : '#dadada',
  },
  message          : {
    padding    : '0 8px',
    whiteSpace : 'pre-wrap',
  },
  check            : {
    color : '#1396e2',
  },
  messageFormat    : {
    wordWrap : 'anywhere',
    color    : '#474747',
  },
};

export default withStyles(styles)(Conversation);
