import PropTypes from 'prop-types';
import React from 'react';
import GmvPageContainer from '../../containers/GmvPageContainer';

const GmvPageFull = ({ classes, data }) => {
  return (
    <div>
      <div>
        <GmvPageContainer />
      </div>
    </div>
  );
};

GmvPageFull.propTypes = {
  classes : PropTypes.object,
};

export default GmvPageFull;
