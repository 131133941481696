import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

const DefinedInvestmentStyle = ({ classes, user, obfuscateView }) => {
  if (user) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.defined_investment_style"/></strong>
        {!obfuscateView && <p>{get(user, 'defined_investment_style') ? <FormattedMessage id="profile_page.yes_discretionary_investment_models"/> : <FormattedMessage id="profile_page.no_discretionary_investment_models"/>}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default withStyles(styles)(DefinedInvestmentStyle);
