import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const styles = {
  container : {
    display       : 'flex',
    flexDirection : 'column',
    lignItems     : 'center',
    minWidth      : 320,
  },
  field     : {
    width : '100%',
  },
};

const CandidateLogin = gql`
  mutation loginCandidate($email: String!, $password: String!) {
    loginCandidate(email: $email, password: $password)
  }
`;

class IdentityLogin extends Component {
  state = { email: '', password: '', error: '' };

  click = () => {
    const { mutate } = this.props;
    const { email, password } = this.state;
    mutate({
      variables : { email, password },
    })
      .then(() => {
        this.setState({ error: '' });
        window.location.reload();
      })
      .catch(error => {
        this.setState({ error: error.message });
      });
  };

  render () {
    const { error } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {error && <well>{error}</well>}
        <div className={classes.field}>
          <label htmlFor="auth_key">Email</label>
          <br />
          <TextField
            type="text"
            name="auth_key"
            id="auth_key"
            shouldFitContainer
            isLabelHidden
            onChange={e => {
              this.setState({ email: e.target.value });
            }}
          />
        </div>
        <br />
        <div className="field">
          <label htmlFor="password">Password</label>
          <br />
          <TextField
            type="password"
            name="password"
            id="password"
            shouldFitContainer
            isLabelHidden
            onChange={e => {
              this.setState({ password: e.target.value });
            }}
          />
        </div>
        <br />
        <div className="actions clearfix">
          <Button variant="contained" color="primary" onClick={this.click}>
            Log in
          </Button>
        </div>
      </div>
    );
  }
}

IdentityLogin.propTypes = {
  mutate  : PropTypes.func.isRequired,
  classes : PropTypes.object.isRequired,
};

export default graphql(CandidateLogin)(withStyles(styles)(IdentityLogin));
