import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import ExpressInterestButton from '../../ExpressInterestButton/ExpressInterestButton';
import MessageButton from '../../MessageButton/MessageButton';

const renderMessageButton = (
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  sellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly
) => {
  if (marketplaceOnly) {
    return <div />;
  }
  if (disableMessageBtn) {
    return (
      <button type="button" disabled className="btn btn-success">
        <i className="fa fa-envelope" />{' '}
        <FormattedMessage id="bobcard.card_buttons.message" defaultMessage="Message" />
      </button>
    );
  }
  if ((user.obfuscated && user.expressed_interest) || (currentUserHasLimitedAccess && user.expressed_interest)) {
    return (
      <button id="button" disabled className="btn btn-primary" type="button" style={{ color: 'white' }}>
        Confirmation Pending...
      </button>
    );
  }
  if (user.obfuscated && !user.expressed_interest) {
    return (
      <ExpressInterestButton
        user={user}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        fromBobCard
      />
    );
  }
  if (currentUserHasLimitedAccess && currentUserHasSellingTransitionGoals && user.is_pure_buyer) {
    return (
      <ExpressInterestButton
        user={user}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        fromBobCard
        currentUserHasLimitedAccess
        currentUserHasSellingTransitionGoals
        sellerWord={sellerWord}
        getCurrentUser={getCurrentUser}
      />
    );
  }
  if (currentUserHasLimitedAccess) {
    return (
      <ExpressInterestButton
        user={user}
        updateBobCards={updateBobCards}
        loadBobCardList={loadBobCardList}
        fromBobCard
        currentUserHasLimitedAccess
        sellerWord={sellerWord}
      />
    );
  }
  return <MessageButton userId={user.id} userName={user.user_name} noteCount={user.note_count} />;
};

const BobCardMessageButton = ({
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
}) => (
  <div>
    {renderMessageButton(
      user,
      currentUserHasLimitedAccess,
      hasInterestedUsers,
      updateBobCards,
      loadBobCardList,
      currentCompanySellerWord,
      currentUserHasSellingTransitionGoals,
      getCurrentUser,
      disableMessageBtn,
      marketplaceOnly
    )}
  </div>
);

BobCardMessageButton.propTypes = {
  user                                 : PropTypes.object.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.func.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
};

export default BobCardMessageButton;
