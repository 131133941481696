import React from "react";
import PropTypes, { number } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import numeral from "numeral";


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const PartialSaleListing = (props) => {
  const { partialSale } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const numberOfInterests = partialSale.partial_sale_listing_users.length;
  
  return (
    <React.Fragment>   
      <dl>
        <dt>
          Description:
        </dt>
        <dd>
          {partialSale.description}
        </dd>
        <dt>
          Clients:
        </dt>
        <dd>
          {partialSale.number_of_clients}
        </dd>
        <dt>
          Revenue:
        </dt>
        <dd>
          {numeral(partialSale.fee_based_revenue).format('$0,0.00')}
        </dd>
        <dt>
          Asking Price:
        </dt>
        <dd>
          {partialSale.asking_price === '0.0' ? 'Negotiable' : numeral(partialSale.asking_price).format('$0,0.00')}
        </dd>
      </dl>
    </React.Fragment>
  );
}

PartialSaleListing.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default PartialSaleListing;