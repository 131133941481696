import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

class SearchActivities extends React.Component {
  filterEvent = (firstName, event) => {
    const props = JSON.parse(event.property_string);

    return (
      <div>
        <p>
          {firstName} performed a {event.name} with the following parameters:
        </p>
        {!isEmpty(props) && (Object.keys(props).map((key, index) => {
          if (key === '[[Prototype]]')
            return "";

          return <li>
            {key}: <span style={{ fontWeight: 'normal' }}>{JSON.stringify(props[key])}</span>
          </li>;
        }))
        }
      </div>
    );
  };

  render() {
    const { firstName, event } = this.props;
    return <span>{this.filterEvent(firstName, event)}</span>;
  }
}

SearchActivities.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

const styles = {
  border: {
    border: '1px solid lightgrey',
    borderRadius: 8,
    margin: 5,
    height: 80,
    width: 80,
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 7,
    fontSize: 10,
  },
  content: {
    textAlign: 'center',
    margin: 7,
    fontSize: 16,
  },
};

export default withStyles(styles)(SearchActivities);
