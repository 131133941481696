import React, { useState } from 'react';
import { IntlProvider, createIntl, createIntlCache, injectIntl } from 'react-intl';
import { Provider } from 'react-intl-redux';
import { connect } from 'react-redux';
import { findIndex, get } from 'lodash';
import translations from '../lib/i18n/translations.json';
import defaultLocale from '../lib/i18n/default.json';
import configureStore from '../store/findBobPublicStore';
import TransitionGoalSelector from '../components/SignUpWizard/PracticeInformation/TransitionGoalSelector';
import CatastrophicPlanningPreference from '../components/SignUpWizard/PracticeInformation/CatastrophicPlanningPreference';
import { getMotivation } from '../lib/findBobUtils';
import { withStyles } from '@material-ui/core/styles';
import { updateUser } from '../actions/userActionCreators';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

class TransitionGoalModularSelectorContainer extends React.Component {
  state = { openDialog: false, reInitiateApproval: 'true' };

  render () {
    const props = this.props;
    const { openDialog, reInitiateApproval } = this.state;
    const { user, updateUser, company, disableSelection, adminView, editTransitionGoals, classes } = props;

    let transition_goals = get(user, 'transition_goals', []);
    const stylesheetName = get(user, 'company.stylesheet_name');

    const handleToggleDialog = () => {
      this.setState({ openDialog: !openDialog });
    };

    const handleToggleApproval = event => {
      this.setState({ reInitiateApproval: event.target.value });
    };
    const noMotivation = user.transition_goals.includes('Currently not interested in Buying / Selling');
    return (
      <div>
        <TransitionGoalSelector
          disableSelection={disableSelection}
          stylesheetName={stylesheetName}
          adminView={adminView}
          toggleGoal={e => {
            const userMotivation = getMotivation(transition_goals);
            const goal = e.target.value;

            if (userMotivation === getMotivation([ goal ]) || !get(user, 'company.is_managed')) {
              const index = findIndex(transition_goals, o => o === e.target.value);
              transition_goals = [ ...transition_goals ];
              if (index > -1) {
                transition_goals.splice(index, 1);
              } else {
                transition_goals.push(e.target.value);
              }
              updateUser({ transition_goals });
            } else {
              updateUser({ transition_goals: [ e.target.value ] });
            }
          }}
        />
        {adminView && (
          <div>
            <Dialog
              open={openDialog}
              onClose={() => handleToggleDialog()}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">{`${noMotivation
                ? 'Confirm'
                : 'Re-initiate Approval Workflow?'}`}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <FormControl component="fieldset">
                    {!noMotivation && <FormLabel component="legend">Re-initiate Approval Workflow?</FormLabel>}
                    {noMotivation && (
                      <p>
                        Please confirm that you would like to change this user’s motivation to Here for Research. Doing
                        so will disable access to the Match Pool and Networking features.
                      </p>
                    )}
                    {!noMotivation && (
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={reInitiateApproval}
                        onChange={e => handleToggleApproval(e)}>
                        <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        <FormControlLabel value="false" control={<Radio />} label="No" />
                      </RadioGroup>
                    )}
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleToggleDialog()} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleToggleDialog();
                    editTransitionGoals(reInitiateApproval);
                  }}
                  color="primary"
                  autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <button onClick={() => handleToggleDialog()}>
              Save
            </button>
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  rjpe      : {
    background : '#002949',
    color      : '#fff',
  },
  grey_text : {
    color : '#aaa',
  },
});

const mapStateToProps = state => ({ user: state.user, intl: state.intl });

const mapDispatchToProps = {
  updateUser,
};

const TransitionGoalModularSelectorContainerWithStyles = withStyles(styles)(TransitionGoalModularSelectorContainer);
export default connect(mapStateToProps, mapDispatchToProps)(TransitionGoalModularSelectorContainerWithStyles);
