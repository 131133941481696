import { UPDATE_FILTER } from '../constants/filterConstants';

const motivationReportReducer = (
  state = {
    user_motivation                     : 'succession',
    not_admins_not_customers_not_hidden : true,
    is_invisible                        : ['false'],
  },
  action
) => {
  switch (action.type) {
    case UPDATE_FILTER:
      return { ...state, ...action.filterOptions };
    default:
      return state;
  }
};

export default motivationReportReducer;
