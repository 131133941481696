import React, { useState } from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get, range} from 'lodash';
import numeral from "numeral";
import {withStyles} from "@material-ui/core/styles";
import CurrencyInput from '../FBCurrencyInput/FBCurrencyInput';
import * as cfaActionCreators from '../../actions/cfaActionCreators';
import SaveCfaButton from "./SaveCfaButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {FormattedMessage, injectIntl} from "react-intl";

const CfaSummary = ({list, query, classes, updateCfaQuery, intl, company}) => {
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');
  
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note'); 

  return <React.Fragment>
    <div className="cfa_edit">
        <div>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.name" /></dt>
            <dd className="right">{ query.name }</dd>
          </dl><br/>
          <br/>
          {showPurchasePrice && query.purchasePrice && <React.Fragment>
            <dl>
              <dt className={classes.sub_heading}>Purchase Price</dt>
              <dd className="tag price right">{numeral(query.purchasePrice).format(dollarFormat)}</dd>
            </dl><br/>
            <br/> 
            <dl>
              <dt className={classes.sub_heading}>Multiple</dt>
              <dd>{numeral((parseFloat(query.purchasePrice) / parseFloat(query.sellerT12Revenue))).format(percentFormat)}</dd>
            </dl><br/>
          </React.Fragment>}

          <h4><b><u><FormattedMessage id="calculators.cfa.seller" /></u></b></h4>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.t_12" /></dt>
            <dd className="tag price right">{numeral(query.sellerT12Revenue).format(dollarFormat)}</dd>
          </dl><br/>
          <br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.revenue_growth" /></dt>
            <dd>{numeral(query.sellerFiveYearGrowthRate).format(percentFormat)}</dd>
          </dl>
          {range(0, query.numberOfBuyers, 1).map(i => <React.Fragment>
              <h4><b><u><FormattedMessage id="calculators.cfa.buyer" /> {i + 1}</u></b></h4>
                <dl>
                  <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.t_12" /></dt>
                  <dd className="tag price right">{numeral(query[`buyer${i + 1}T12Revenue`]).format(dollarFormat)}</dd>
                </dl><br/>
                <br/>
                <dl>
                  <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.revenue_growth" /></dt>
                  <dd>{numeral(query[`buyer${i + 1}FiveYearGrowthRate`]).format(percentFormat)}</dd>
                </dl><br/>
                <dl>
                  <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.percent_purchasing" /> </dt>
                  <dd>{numeral(query[`buyer${i + 1}PercentagePurchasing`]).format(percentFormat)}</dd>
                </dl><br/>
                { get(company, 'features').includes('cashflow_branch_expenses') && <>
                  <dl>
                    <dt className={classes.sub_heading}> Annual Expenses</dt>
                    <dd className="tag price right">{numeral(query[`buyer${i + 1}AnnualExpenses`]).format(dollarFormat)}</dd>
                  </dl><br/><br/>
                  <dl>
                    <dt className={classes.sub_heading}> Expense Growth </dt>
                    <dd>{numeral(query[`buyer${i + 1}ExpenseGrowth`]).format(percentFormat)}</dd>
                  </dl><br/>
                </>}
              </React.Fragment>
            )
          }
          <h4><b><u><FormattedMessage id="calculators.cfa.lump_sum" /></u></b></h4>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.lump_sum_year" /></dt>
            <dd>{query.buyerLumpSumYear}</dd>
          </dl><br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.lump_sum" /></dt>
            <dd className="tag price right">{numeral(query.buyerLumpSumAmount).format(dollarFormat)}</dd>
          </dl><br/>
          <br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.financing_term" /></dt>
            <dd>{query.buyerFixedPaymentYears}</dd>
          </dl>
          <br/>
          <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.financing_interest_rate" /> </dt>
              <dd>{numeral(query.cashFlowFinancingInterestRate).format(intl.locale === 'en' ? '0.0%' : '0.0 %')}</dd>
          </dl><br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.amount_financed" /></dt>
            <dd className="tag price right">{numeral(query.buyersFinancingAmount).format(dollarFormat)}</dd>
          </dl><br/>
          <br/>
          <br/>
          <h4><b><u><FormattedMessage id="calculators.cfa.fixed_payments" /></u></b></h4>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.no_of_years" /></dt>
            <dd className="right">{ query.buyerFixedPaymentYears }</dd>
          </dl><br/>
          <br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.start_year" /></dt>
            <dd className="right">{query.buyerFixedPaymentStartYear}</dd>
          </dl><br/>
          <br/>

          {showCheckSwap && <React.Fragment>
            <h4><b><u><FormattedMessage id="calculators.cfa.check_swap" /></u></b></h4>
            <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_year" /></dt>
              <dd className="right">{query.checkSwapYear}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_annual_bonus" /></dt>
              <dd className="tag price right">{ numeral(query.checkSwapAnnualBonus).format(dollarFormat) }</dd>
            </dl><br/><br/>
            <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_loan_end_year" /></dt>
              <dd className="right">{query.checkSwapLoanEndYear}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.check_swap_repayment_amount" /></dt>
              <dd className="tag price right">{ numeral(query.checkSwapLoanRepaymentAmount).format(dollarFormat) }</dd>
            </dl><br/><br/>
          </React.Fragment>}
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.payment_amount" /></dt>
            <dd className="tag price right">{ numeral(query.buyerFixedPaymentAmount).format(dollarFormat) }</dd>
          </dl><br/>
          <br/>
          <h4><b><u><FormattedMessage id="calculators.cfa.cont_payments" /></u></b></h4>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.no_of_years" /></dt>
            <dd>{query.buyerContinuingPaymentYears}</dd>
          </dl><br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.start_year" /></dt>
            <dd>{query.buyerContinuingPaymentStartYear}</dd>
          </dl><br/>
          {get(query, 'buyerContinuingPayments',[]).map((payment, i) => <React.Fragment>
            <dl>
              <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.amount" /> year: {i + query.buyerContinuingPaymentStartYear}</dt>
              <dd>{numeral(payment).format(percentFormat)}</dd>
            </dl><br/>
          </React.Fragment>)}
          {(query.purchasePrice && showConsultingAgreements) && <React.Fragment>
            <h4><b><u>Consulting Agreement</u></b></h4>
            <dl>
              <dt className={classes.sub_heading}>Total Compensation</dt>
              <dd className="tag price right">{numeral(query.consultingTotalCompensation).format(dollarFormat)}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}>% of Deal</dt>
              <dd>{numeral(query.consultingTotalCompensation / query.purchasePrice).format(percentFormat)}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}>Length (Years)</dt>
              <dd>{query.consultingLength}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}>Annual Compensation</dt>
              <dd className="tag price right">{numeral(query.consultingTotalCompensation / query.consultingLength).format(dollarFormat)}</dd>
            </dl><br/>
            <br/>
            <dl>
              <dt className={classes.sub_heading}>Consulting included in List Price</dt>
              <dd>{query.consultingIncludedInListPrice}</dd>
            </dl>
            </React.Fragment>}
          {showSellerNote && <React.Fragment>
            <h4><b><u>Seller Note</u></b></h4>
            <dl>
              <dt className={classes.sub_heading}>Seller Note Term</dt>
              <dd>{query.sellerNoteTerm}</dd>
            </dl><br/>
            <dl>
              <dt className={classes.sub_heading}>Seller Note Interest Rate</dt>
              <dd>{numeral(query.sellerNoteInterestRate).format(percentFormat)}</dd>
            </dl><br/>
            <dl>
              <dt className={classes.sub_heading}>Seller Note Amount</dt>
              <dd className="tag price right">{numeral(query.sellerNoteAmount).format(dollarFormat)}</dd>
            </dl><br/>
          </React.Fragment> }
          <br/>
          <h4><b><u><FormattedMessage id="calculators.cfa.advanced" /></u></b></h4>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.length_of_cash_flow" /></dt>
            <dd className="right">{ numeral(query.cashFlowLength).format() }</dd>
          </dl><br/>
          <br/>
          <dl>
            <dt className={classes.sub_heading}><FormattedMessage id="calculators.cfa.cash_flow_production_shift" /></dt>
            <dd className="right">{ numeral(query.cashFlowProductionShift).format() }</dd>
          </dl><br/>
          <br/>
          <br/>
        </div>
    </div>
  </React.Fragment>
}

CfaSummary.propTypes = {
  query: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

CfaSummary.defaultProps = {
  list: [],
}

const styles = () => ({
  sub_heading: {
    fontWeight: 'normal',
  },
});

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
    company: {...get(state, 'company', {})},
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(CfaSummary)));