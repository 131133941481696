import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const StartChatWidget = ({ userName, createChat, startConvo = false, stylesheetName, classes }) => {
  if (startConvo) {
    createChat();
  }
  return (
    <div id="agent-detail" className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <h3><FormattedMessage id="profile_page.messages" /> </h3>
        <div style={{ textAlign: 'center' }}>
          <Button
            className={classes.margin}
            variant="contained"
            color="primary"
            style={{ color: 'white' }}
            onClick={() => createChat()}>
            <FormattedMessage id="profile_page.message_now" values={{userName}} /> 
          </Button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  margin                 : {
    margin : '0px 0px 15px 25px',
  },
  button                 : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
  },
  ref                    : {
    textTransform : 'capitalize',
    margin        : '0px 0px 5px 20px',
    color         : '#1396e2',
    display       : 'block',
  },
  FontColor              : {
    color : '#1396e2',
  },
  rjpeFontColor          : {
    color : '#002949',
  },
  BackgroundColor        : {
    backgroundColor : '#1396e2',
  },
  rjpeBackgroundColor    : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  rjpeBackgroundColorAlt : {
    backgroundColor : '#5B5E5A',
    '&:hover'       : {
      background : '#424949',
    },
  },
};

export default withStyles(styles)(StartChatWidget);
