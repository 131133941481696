import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import SideProfileDashboardContainer from './SideProfileDashboardContainer';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import * as notesActions from '../actions/noteActionCreators';
import * as bobCardActions from '../actions/bobCardActionCreators';

const ProfileConnectContainer = props => {
  return <SideProfileDashboardContainer {...props} />;
};

ProfileConnectContainer.propTypes = {
  notes       : PropTypes.array,
  updateNotes : PropTypes.func.isRequired,
  profile     : PropTypes.object,
};

ProfileConnectContainer.defaultProps = {
  notes   : [],
  profile : {},
};

const mapStateToProps = (state, props) => {
  return {
    notes    : state.notes,
    userName : get(props.profile, 'data.getUser.user_name'),
    profile  : state.profileDrawer,
  };
};

export default connect(mapStateToProps, { ...profileDrawerActions, ...notesActions, ...bobCardActions })(
  ProfileConnectContainer
);
