import React from 'react';
import { get, startCase } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { S3Image } from '../../lib/s3';
import { FormattedMessage, injectIntl } from 'react-intl';

const roundLargeNum = (business_type, num) => {
  if (business_type !== 'wealth_rj') {
    return num;
  }
  if (num >= 100000) {
    return Math.round(num / 100000) * 100000;
  } else if (num < 100000 && num > 10000) {
    return Math.round(num / 10000) * 10000;
  }
  return Math.round(num / 1000) * 1000;
};

const roundLargeNumAum = num => {
  if (num >= 100000) {
    return Math.round(num / 1000000) * 1000000;
  }
  return 0;
};

export const DealTeaser = ({ company, deal, intl }) => {
  const product_mix = get(deal, 'inventory_user.user.product_mix', {});
  const business_type = get(deal, 'inventory_user.user.company.business_type');
  const hideBusinessOverviewQuestion = get(company, 'teaser_hide_business_overview_question');
  const hideAssetsQuestion = get(company, 'teaser_hide_assets_question');
  const hideDirectRevenue = get(company, 'hide_direct_revenue');
  return (
    <div>
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
        <ol className="carousel-indicators">
          <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active" />
          <li data-target="#carouselExampleIndicators" data-slide-to="1" />
          <li data-target="#carouselExampleIndicators" data-slide-to="2" />
          <li data-target="#carouselExampleIndicators" data-slide-to="3" />
          <li data-target="#carouselExampleIndicators" data-slide-to="4" />
          <li data-target="#carouselExampleIndicators" data-slide-to="5" />
          <li data-target="#carouselExampleIndicators" data-slide-to="6" />
          <li data-target="#carouselExampleIndicators" data-slide-to="7" />
        </ol>
        <div className="carousel-inner">
          <div className="item active">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_cover.png`)}`} />

            <div className="carousel-caption">
              <h3 style={{ textAlign: 'center' }}>#{deal.deal_name}</h3>
              <h1 style={{ fontSize: 40, textAlign: 'center', borderBottom: 0, marginBottom: 5 }}>{deal.headline}</h1>
              {!hideBusinessOverviewQuestion && (
                <h2 style={{ textAlign: 'center', borderBottom: 0 }}>{deal.sub_headline}</h2>
              )}
              <br />
              <br />
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_a.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.business_highlights" /></h1>
              <dl>
                <dt><FormattedMessage id="teaser_slideshow.list_price" /></dt>
                {deal.list_price && deal.list_price > 0 ? (
                  <dd>
                    <span className="tag price">{numeral(deal.list_price).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}</span>
                  </dd>
                ) : (
                  <dd>
                    <span className="tag price">TBD</span>
                  </dd>
                )}
                <dt><FormattedMessage id="teaser_slideshow.location_service_area" /></dt>
                <dd>
                  {get(deal, 'inventory_user.user.city')}, {get(deal, 'inventory_user.user.province')}
                </dd>
                {get(deal, 'inventory_user.user.assets_under_management') && (
                  <React.Fragment>
                    <dt><FormattedMessage id="teaser_slideshow.aum_aua" /></dt>
                    <dd>
                      ${numeral(roundLargeNumAum(get(deal, 'inventory_user.user.assets_under_management'))).format(
                        intl.locale == 'fr' ? '0,0[.] $' : '0,0[.]'
                      )}
                    </dd>
                  </React.Fragment>
                )}
                <dt><FormattedMessage id="teaser_slideshow.number_of_clients" /></dt>
                <dd>{get(deal, 'inventory_user.user.number_of_clients')}</dd>
                <dt><FormattedMessage id="teaser_slideshow.year_established" /></dt>
                <dd>{moment().subtract(get(deal, 'inventory_user.user.years_practicing'), 'y').year()}</dd>
                <dt><FormattedMessage id="teaser_slideshow.commission" /></dt>
                <dd>{numeral(get(deal, 'inventory_user.user.percent_commission', 0)).format('%0')}</dd>
                <dt><FormattedMessage id="teaser_slideshow.fee" /></dt>
                <dd>{numeral(get(deal, 'inventory_user.user.percent_fee_based', 0)).format('%0')}</dd>
                {get(deal, 'inventory_user.user.team_size', 0) > 0 && (
                  <React.Fragment>
                    <dt><FormattedMessage id="teaser_slideshow.team_size" /></dt>
                    <dd>{get(deal, 'inventory_user.user.team_size')}</dd>
                  </React.Fragment>
                )}
                {get(deal, 'inventory_user.user.number_of_employees', 0) > 0 && (
                  <React.Fragment>
                    <dt><FormattedMessage id="teaser_slideshow.no_of_employees" /></dt>
                    <dd>{get(deal, 'inventory_user.user.number_of_employees')}</dd>
                  </React.Fragment>
                )}
                <hr />
                <dt><FormattedMessage id="teaser_slideshow.business_continuity_plan" /></dt>
                <dd>
                  {get(deal, 'inventory_user.user.business_continuity_state_id', 0) < 2 ? <FormattedMessage id="teaser_slideshow.non" /> : <FormattedMessage id="teaser_slideshow.yes" /> }
                </dd>
                <br/>
                <dt><FormattedMessage id="teaser_slideshow.seller_transition_period_months" /></dt>
                <dd>{get(deal, 'inventory_user.user.transition_period_months')}</dd>
              </dl>
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_b.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.summary_of_business" /></h1>
              <p>
                {deal.summary_of_business}
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_d.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.ideal_candidate" /></h1>
              <p>
                {deal.ideal_candidate}
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_c.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.financial_highlights" /></h1>
              <h4><FormattedMessage id="teaser_slideshow.trailing_12_month_revenue" /></h4>
              <ul className="list-unstyled property-features-list">
                {business_type === 'wealth_rj' && (
                  <li>
                    <FormattedMessage id="teaser_slideshow.t12_revenue" />{' '}
                    {numeral(
                      roundLargeNum(business_type, get(deal, 'inventory_user.user.gross_rev_detail_total_revenue'))
                    ).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}{' '}
                  </li>
                )}
                {business_type !== 'wealth_rj' && (
                  <li><FormattedMessage id="teaser_slideshow.t12_gross_revenue" /> {numeral(get(deal, 'inventory_user.user.annual_revenue')).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')} </li>
                )}
                <li>
                  <FormattedMessage id="teaser_slideshow.t12_recurring_revenue" />{' '}
                  {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.recurring_revenue'))).format(
                    intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]'
                  )}{' '}
                </li>
                {get(company, 'business_type') === 'wealth_rj' && (
                  <React.Fragment>
                    <li>
                      <FormattedMessage id="teaser_slideshow.fee_based_revenue" />{' '}
                      {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.fee_based_revenue'))).format(
                        intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]'
                      )}
                    </li>
                    <li>
                      <FormattedMessage id="teaser_slideshow.trailer_revenue" />{' '}
                      {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.trailer_revenue'))).format(
                        intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]'
                      )}
                    </li>
                    {!hideDirectRevenue && (
                      <li>
                        <FormattedMessage id="teaser_slideshow.direct_revenue" />{' '}
                        {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.direct_revenue'))).format(
                          intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]'
                        )}
                      </li>
                    )}
                    <li>
                      <FormattedMessage id="teaser_slideshow.transactional_revenue" />{' '}
                      {numeral(
                        roundLargeNum(business_type, get(deal, 'inventory_user.user.transactional_revenue'))
                      ).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}
                    </li>
                  </React.Fragment>
                )}
                <h4><FormattedMessage id="teaser_slideshow.gross_production" /></h4>
                <li>
                  {`${moment().subtract(1, 'year').year()}${intl.locale == 'fr' ? ' ' : ''}: ${numeral(
                    roundLargeNum(business_type, get(deal, 'inventory_user.user.year_1_gross', 0))
                  ).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}`}
                </li>
                <li>
                  {' '}
                  {moment().subtract(2, 'year').year()}{intl.locale == 'fr' ? ' ' : ''}:{' '}
                  {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.year_2_gross'))).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}
                </li>
                <li>
                  {moment().subtract(3, 'year').year()}{intl.locale == 'fr' ? ' ' : ''}:{' '}
                  {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.year_3_gross'))).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}
                </li>
                <li>
                  {moment().subtract(4, 'year').year()}{intl.locale == 'fr' ? ' ' : ''}:{' '}
                  {numeral(roundLargeNum(business_type, get(deal, 'inventory_user.user.year_4_gross'))).format(intl.locale == 'fr' ? '0,0[.] $' : '$0,0[.]')}
                </li>

                {!hideAssetsQuestion && (
                  <div>
                    <li><FormattedMessage id="teaser_slideshow.assets_included_in_purchase" /> {deal.assets_included}</li>
                    <li><FormattedMessage id="teaser_slideshow.intangible_assets" /> {deal.intangible_assets_included}</li>
                  </div>
                )}
              </ul>
            </div>
          </div>

          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_d.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              {business_type === 'wealth_rj' && <h1><FormattedMessage id="teaser_slideshow.client_demographics" /></h1>}
              {business_type !== 'wealth_rj' && <h1><FormattedMessage id="teaser_slideshow.customer_demographics" /></h1>}
              <ul className="list-unstyled property-features-list">
                <li><FormattedMessage id="teaser_slideshow.average_age_of_clients" /> {get(deal, 'inventory_user.user.avg_client_age')}</li>
                <li><FormattedMessage id="teaser_slideshow.number_of_households" /> {get(deal, 'inventory_user.user.number_of_clients')}</li>
                <li><FormattedMessage id="teaser_slideshow.niche_market" /> {get(deal, 'inventory_user.user.niche_market') ? <FormattedMessage id="teaser_slideshow.yes" /> : <FormattedMessage id="teaser_slideshow.non" />}</li>
              </ul>
              <p>
                {deal.customer_demographics_comments}
                <br />
                <br />
              </p>
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_e.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.products_services" /></h1>
              <ul className="list-unstyled property-features-list" style={{ maxWidth: '80%' }}>
                {get(product_mix, 'life_ifp', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.life" /></li>}
                {get(product_mix, 'home_ifp', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.home_insurance" /></li>}
                {get(product_mix, 'auto_ifp') > 0 && <li><FormattedMessage id="teaser_slideshow.auto_insurance" /></li>}
                {get(product_mix, 'commercial_ifp', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.commercial_insurance" /></li>}
                {get(product_mix, 'mutual_funds_aum', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.mutual_funds" /></li>}
                {get(product_mix, 'securities_aum', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.securities" /></li>}
                {get(product_mix, 'mcpi_ifp', 0) > 0 && <li><FormattedMessage id="teaser_slideshow.farmers_insurance" /></li>}
                {get(product_mix, 'private_products_ifp') > 0 && <li><FormattedMessage id="teaser_slideshow.private_products" /></li>}
                {(get(product_mix, 'crop_hail_ifp', 0) > 0 || get(product_mix, 'crop_hail_with_wind_ifp') > 0) && (
                  <li><FormattedMessage id="teaser_slideshow.crop_and_hail_insurance" /></li>
                )}
                {(get(product_mix, 'farm_insurance_ifp', 0) > 0 ||
                  get(product_mix, 'price_products_ifp') > 0 ||
                  get(product_mix, 'replant_supplement_ifp') > 0) && <li><FormattedMessage id="teaser_slideshow.farm_insurance" /></li>}

                {company.business_type === 'wealth_rj' ? (
                  <React.Fragment>
                    {get(deal, 'inventory_user.user.tag_list', []).length > 0 && (
                      <li>
                        <FormattedMessage id="teaser_slideshow.practice_areas" />{' '}
                        <blockquote className="property-features-list">
                          {deal.inventory_user.user.tag_list.map(t => intl.formatMessage({id: `find_a_bob.map_search.${t}`, defaultMessage: startCase(t)})).join(', ')}
                        </blockquote>
                      </li>
                    )}
                    {get(deal, 'inventory_user.user.primary_markets', []).length > 0 && (
                      <li>
                        <FormattedMessage id="teaser_slideshow.primary_markets" />{' '}
                        <blockquote className="property-features-list">
                          {deal.inventory_user.user.primary_markets.map(t => intl.formatMessage({id: `find_a_bob.map_search.${t}`, defaultMessage: startCase(t)})).join(', ')}
                        </blockquote>
                      </li>
                    )}
                    {get(deal, 'inventory_user.user.specialized_businesses', []).length > 0 && (
                      <li>
                        <FormattedMessage id="teaser_slideshow.specialized_business" />{' '}
                        <blockquote className="property-features-list">
                          {deal.inventory_user.user.specialized_businesses.map(t => intl.formatMessage({id: `find_a_bob.map_search.${t}`, defaultMessage: startCase(t)})).join(', ')}
                        </blockquote>
                      </li>
                    )}
                    {get(deal, 'inventory_user.user.discretionary_investment_models') && (
                      <li><FormattedMessage id="teaser_slideshow.discretionary_investment_models_yes" /></li>
                    )}
                    {get(deal, 'inventory_user.user.why_values', []).length > 0 && (
                      <li>
                        <FormattedMessage id="teaser_slideshow.what_s_your_why" />{' '}
                        <blockquote className="property-features-list">
                          {deal.inventory_user.user.why_values.map(t => intl.formatMessage({id: `sign_up_pages.${t}`, defaultMessage: startCase(t)})).join(', ')}
                        </blockquote>
                      </li>
                    )}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li>
                    <FormattedMessage id="teaser_slideshow.defined_investment_style" />{' '}
                      {get(deal, 'inventory_user.user.defined_investment_style') ? 'Yes' : 'No'}
                    </li>
                    <li>
                    <FormattedMessage id="teaser_slideshow.defined_service_model" /> {get(deal, 'inventory_user.user.defined_service_model') ? 'Yes' : 'No'}
                    </li>
                  </React.Fragment>
                )}
              </ul>
              <p>{deal.products_and_services_comments}</p>
            </div>
          </div>
          <div className="item">
            <img src={`${S3Image(`${company.name.toLowerCase()}_slide_f.png`)}`} />
            <div className="carousel-caption">
              <h4>#{deal.deal_name}</h4>
              <h1><FormattedMessage id="teaser_slideshow.growth_opportunities" /></h1>
              <p>
                {deal.growth_opportunities}
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
        <a className="left carousel-control" href="#carouselExampleIndicators" role="button" data-slide="prev">
          <span className="fa fa-chevron-left" aria-hidden="true" />
          <span className="sr-only"><FormattedMessage id="teaser_slideshow.previous" /></span>
        </a>
        <a className="right carousel-control" href="#carouselExampleIndicators" role="button" data-slide="next">
          <span className="fa fa-chevron-right" aria-hidden="true" />
          <span className="sr-only"><FormattedMessage id="teaser_slideshow.next" /></span>
        </a>
      </div>

      <script type="text/javascript">$('.carouselExampleIndicators').carousel(false)</script>
    </div>
  );
};

export default injectIntl(DealTeaser);
