import { combineReducers } from 'redux';
import { USER_UPDATE, PRODUCT_MIX_UPDATE } from '../constants/userConstants';

const defaultState = {
  id                      : '',
  user_name               : '#FB',
  annual_revenue          : '',
  assets_under_management : '',
  bio                     : '',
  city                    : '',
  number_of_clients       : '',
  profile_picture         : 'advisor.png',
  province                : '',
  recurring_revenue       : '',
  created_at              : '',
  headline                : 'Your headline here',
  my_rating               : '',
  nick_name               : '',
  is_student              : false,
  designations            : [ 'Your designations here' ],
  tag_list                : [],
  transition_goals        : [ '[Your goals here]' ],
  in_auction              : '',
  product_mix             : {},
  advisor_licenses        : [],
  company                 : { is_managed: false , enabled_student_profiles: false },
};
const userReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case USER_UPDATE:
      return { ...state, ...action.user };
    case PRODUCT_MIX_UPDATE:
      return { ...state, product_mix: { ...state.product_mix, ...action.product_mix } };
    default:
      return state;
  }
};

export default userReducer;
