import React, { Component } from 'react';
import PropTypes from 'prop-types';

const KnockoutQuestions = ({ dealInterest, businessType }) => {
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <h3>{`${businessType === 'wealth_rj' ? 'Discovery' : 'Knockout'} Questions`}</h3>
        <div className="property-title">
          {[ 1, 2, 3, 4, 5 ].map(i => (
            <div>
              <div>
                <b>{dealInterest[`q${i}`]}</b>
              </div>
              <p>{dealInterest[`a${i}`]}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KnockoutQuestions;
