import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import { FormattedMessage } from 'react-intl';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  addButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  textField : {
    fontSize        : '18px',
    margin          : '12px',
    backgroundColor : '#fff',
  },
  text      : {
    fontSize : '18px',
  },
});

class InsurerNewFormDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  addRow = action => {
    const { name } = this.state;

    action({ name });
  };

  render () {
    const { classes, error, close } = this.props;
    if (close) this.handleRequestClose();

    return (
      <div>
        <Button fab color="primary" aria-label="add" className={classes.addButton} onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>
            <FormattedMessage id="admin_lines_of_businesses.add_line_of_business" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.text}>
              <FormattedMessage id="admin_lines_of_businesses.add_a_line_of_business" />
            </DialogContentText>
            <TextField
              margin="dense"
              required
              id="name"
              label="Name"
              className={classes.textField}
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}
              disabled={!this.state.name}>
              <FormattedMessage id="shared.add" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(InsurerNewFormDialog);
