import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { addTaskCommentMutation } from './TaskQuery';
import { get, find } from 'lodash';

class TaskNotes extends React.Component {
  state = { comment: '' };

  onChange = e => {
    this.setState({ comment: e.target.value });
  };

  render () {
    const { classes, notes, task, intl, refetch, updateTask, updateState } = this.props;
    const { comment } = this.state;
    return (
      <div>
        <h4>Add A Note</h4>
        <Card>
          <CardContent>
            <TextField
              id="standard-multiline-flexible"
              label="Note"
              multiline
              fullWidth
              rowsMax="4"
              value={decodeURI(comment)}
              onChange={this.onChange}
              className={classes.textField}
              placeholder={intl.formatMessage({ id: `notebook.create_a_new_note` })}
              margin="normal"
            />
            <Mutation mutation={addTaskCommentMutation}>
              {addComment => (
                <Button
                  className={classes.saveButton}
                  onClick={() => {
                    addComment({
                      variables : {
                        comment : encodeURI(comment),
                        task_id : task.id,
                      },
                    }).then(() => {
                      refetch().then(r => {
                        const tasks = get(r, 'data.getTasks');
                        const tsk = find(tasks, [ 'id', task.id ]);
                        if (tsk) {
                          updateTask(tsk);
                        }
                        updateState({ tasks });
                      });
                      this.setState({ comment: '' });
                    });
                  }}>
                  Save{' '}
                </Button>
              )}
            </Mutation>
          </CardContent>
        </Card>
        <Divider variant="middle" />
        <h4>Notes</h4>
        {notes.map(c => (
          <div>
            <Card>
              <CardContent>{decodeURI(c.comment)}</CardContent>
              <CardActions>
                <span className={classes.cardActions}>{get(c, 'user.name')}</span>
              </CardActions>
            </Card>
            <br />
          </div>
        ))}
      </div>
    );
  }
}

TaskNotes.defaultProps = {
  notes : [],
};

const styles = {
  saveButton    : {
    float  : 'right',
    margin : 8,
  },
  editorWrapper : {
    padding : '6px',
    display : 'block',
  },
  toolbar       : {
    padding : '6px',
    margin  : 0,
  },
  editor        : {
    padding      : 0,
    marginBottom : '12px',
  },
  textArea      : {
    minHeight : '80px',
  },
  cardActions   : {
    marginLeft : 'auto',
  },
};

TaskNotes.propTypes = {
  classes     : PropTypes.object.isRequired,
  notes       : PropTypes.array,
  task        : PropTypes.object,
  intl        : PropTypes.object.isRequired,
  refetch     : PropTypes.func.isRequired,
  updateTask  : PropTypes.func.isRequired,
  updateState : PropTypes.func.isRequired,
};

TaskNotes.defaultProps = {
  task  : {},
  notes : [],
};

export default injectIntl(withStyles(styles)(TaskNotes));
