import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis } from 'victory';
import currencyFormatter from 'currency-formatter';
import { get, filter } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { capturePdf } from '../../lib/findBobUtils';

const styles = theme => ({
  report    : {
    margin : '25px',
  },
  title     : {
    fontSize : '24px',
    color    : '#616161',
  },
  subtitle  : {
    fontSize       : '18px',
    textDecoration : 'underline',
    textAlign      : 'center',
    marginBottom   : '15px',
  },
  list      : {
    listStyleType : 'none',
    fontSize      : '15px',
  },
  tableData : {
    paddingLeft : '20px',
    textAlign   : 'right',
  },
  tableOne  : {
    display     : 'inline-block',
    paddingLeft : '5px',
    textAlign   : 'right',
    marginLeft  : 'auto',
  },
  tableTwo  : {
    display     : 'inline-block',
    marginLeft  : '5px',
    paddingLeft : '5px',
    textAlign   : 'right',
  },
  card      : {
    margin   : 'auto',
    maxWidth : '600px',
  },
  media     : {
    height     : 0,
    paddingTop : '56.25%', // 16:9
  },
  table     : {
    width    : '50%',
    fontSize : '12px',
  },
});

class GmvYoyPage extends React.Component {
  clickedCapture = () => {
    capturePdf('l', 0, 0, '', '', 0.7);
  };

  render () {
    const { classes, data, intl } = this.props;
    if (data.loading) return <CircularProgress size={68} className={classes.progress} />;

    const graphData = [];
    const yoyPercentage = {};
    const yoyAmount = {};
    let lastYearGmvArr = {};
    let currentYearGmvArr = {};

    // Sets up data for line graph as well as data for table
    for (let currentYear = 2014; currentYear <= new Date().getFullYear(); currentYear++) {
      lastYearGmvArr = filter(data.monthlyMarketValue, [ 'year', currentYear - 1 ]);
      currentYearGmvArr = filter(data.monthlyMarketValue, [ 'year', currentYear ]);
      const lastYearGmv = get(lastYearGmvArr, `[${lastYearGmvArr.length - 1}].value`, 0);
      const currentYearGmv = get(currentYearGmvArr, `[${currentYearGmvArr.length - 1}].value`, 0);

      // This prevents the gmv formula form producing a NaN or Infinity when dividing a number by 0
      if (lastYearGmv && currentYearGmv === 0) {
        yoyPercentage[currentYear] = '0%';
      } else if (lastYearGmv === 0 && currentYearGmv != 0) {
        yoyPercentage[currentYear] = '100%';
      } else {
        yoyPercentage[currentYear] = `${parseFloat(((currentYearGmv - lastYearGmv) / lastYearGmv || 0) * 100).toFixed(
          2
        )}%`;
      }
      yoyAmount[currentYear] = currencyFormatter.format(currentYearGmv - lastYearGmv, {
        code : 'USD',
      });
      graphData.push({
        x     : currentYear.toString(),
        y     : currentYearGmv / 1000000,
        label : yoyPercentage[currentYear],
      });
    }

    // Dynamically sets lowest and highest points of axis for better graph view
    const graphRangeLow = Math.min(...graphData.map(o => o.y)) * 0.9;
    const graphRangeHigh = Math.max(...graphData.map(o => o.y)) * 1.1;

    const years = Object.keys(yoyPercentage);

    return (
      <div id="capture">
        <section className={classes.report}>
          <Card className={classes.card}>
            <Tooltip title="Download PDF" style={{ float: 'right' }}>
              <IconButton onClick={() => this.clickedCapture()}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
            <CardHeader
              classes={{
                title : classes.title,
              }}
              title={intl.formatMessage({ id: 'admin_company_reports.gmv_yoy' })}
            />
            <VictoryChart
              className={classes.chart}
              theme={VictoryTheme.material}
              width={600}
              height={600}
              domain={{ x: [ 1, graphData.length + 1 ], y: [ graphRangeLow, graphRangeHigh ] }}
              padding={{
                top    : 40,
                bottom : 80,
                left   : 80,
                right  : 40,
              }}>
              <VictoryLine
                style={{
                  data   : { stroke: '#c43a31', strokeWidth: 5 },
                  parent : { border: '1px solid #ccc' },
                }}
                data={graphData}
              />
              <VictoryAxis
                label={intl.formatMessage({ id: 'admin_company_reports.year' })}
                style={{
                  axisLabel : { padding: 60 },
                }}
              />
              <VictoryAxis
                dependentAxis
                label={intl.formatMessage({ id: 'admin_company_reports.gmv_x' })}
                style={{
                  axisLabel : { padding: 60 },
                }}
              />
            </VictoryChart>
            <CardContent>
              <Typography gutterBottom variant="headline" component="h2">
                {intl.formatMessage({ id: 'admin_company_reports.gmv_year_over_year' })}{' '}
                {data.currentYearMv[data.currentYearMv.length - 1].year}
              </Typography>
              <Table className={classes.table}>
                <TableBody>
                  {years.map(k => (
                    <TableRow key={k}>
                      <TableCell style={{ padding: 0 }}>{`${k}:  ${yoyAmount[k]}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </section>
      </div>
    );
  }
}

GmvYoyPage.propTypes = {
  data : PropTypes.shape({
    company_id : PropTypes.number,
    year       : PropTypes.number,
    month      : PropTypes.number,
    value      : PropTypes.number,
  }),
};

const GmvYoyPageWithStyles = withStyles(styles)(GmvYoyPage);
export default injectIntl(GmvYoyPageWithStyles);
