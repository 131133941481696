import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Query, useQuery } from "react-apollo";
import { connect } from 'react-redux';
import { Box, Paper, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { find, get } from 'lodash';
import PaddyChatDrawer from '../PaddyChatDrawer/PaddyChatDrawer';
import SucccessionActivityBanner from './SuccessionActivityBanner';
import { SuccessionReadinessQuery } from "../../containers/SuccessionReadinessWidgetContainer";
import * as successionReadinessActionCreators from '../../actions/successionReadinessActionCreators';

const styles = {

  bannerBox: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '10px',
  },
  yearsFromRetirement: {
    fontSize: '2em',
  },
  paddyButton: {
    float: 'right',
    alignSelf: 'flex-end',
  },
  title: {
    textAlign: 'center',
    fontSize: '1.5em',
  },
  leftMargin: {
    marginLeft: '12px',
  },
};

export const yearsFromRetirementOptions = [
  { value: 10, 
    label: '1-3 years', 
    message: 'Winding down and divesting your business are a priority', 
    image: 'https://findbob-production.s3.us-west-2.amazonaws.com/images/rja_readiness_1-3_years.png' 
  },
  { value: 40, 
    label: '4-6 years', 
    message: "You're very active in the business, but retirement is near.", 
    image: 'https://findbob-production.s3.us-west-2.amazonaws.com/images/rja_readiness_4-6_years.png'
  },
  { value: 70, 
    label: '7+ years', 
    message: 'Growing your business is your priority at the moment', 
    image: 'https://findbob-production.s3.us-west-2.amazonaws.com/images/rja_readiness_7_years.png'
  },
]

const SuccesionReadinessBanner = (props) => {
  const { loading, error, data, refetch, networkStatus } = useQuery(SuccessionReadinessQuery, {
    fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      props.updateSuccessionReadinesses(get(data, 'successionReadiness', {}));
      props.refetchSuccessionReadiness(refetch);
    },
    onChange: (prevData, newData) => {
      props.updateSuccessionReadinesses(get(newData, 'successionReadiness', {}));
    }
  },);

  useEffect(() => {
    props.updateSuccessionReadinesses(get(data, 'successionReadiness', {}));
  }, [networkStatus]);

  const [open, setOpen] = React.useState(false);

  const { classes } = props;
  const yearsFromRetirement = get(props, 'successionReadiness.yearsFromRetirement', 1);

  const currentYearOption = find(yearsFromRetirementOptions, ['value', yearsFromRetirement * 10]) || {};

  const successionReadiness = get(props, 'successionReadiness', {});
  const refetchFunc = get(props, 'refetchFunc', null);
  const completed = get(successionReadiness, 'completed', false);
  const companySuccessionReadinessName = get(data, 'company.succession_readiness_name', '');

  return (
    <div>
      {completed ? (
        <SucccessionActivityBanner {...props} setOpen={setOpen} />
      ) : ( <div>
        {companySuccessionReadinessName === 'Succession Readiness' ? (
          <div>
          <h3 className={classes.title}>How many years away are you from retirement?</h3>
          <Paper className={classes.bannerBox}>
            <img src={currentYearOption.image} alt="placeholder" style={{width: 150, height: 150}}/>
            <div className={classes.leftMargin}>
              <h4 className={classes.yearsFromRetirement}>{currentYearOption.label}</h4>
              <p>{currentYearOption.message}</p>
            </div>
          </Paper>
          <Box sx={{ width: '100%' }} className={classes.box}>
            <Slider
              disabled={get(successionReadiness, 'id', false)}
              aria-label="Years from retirement"
              value={yearsFromRetirement * 10}
              marks={yearsFromRetirementOptions.map(option => ({ value: option.value, label: option.label }))}
              step={null}
              onChange={(event, value) => props.updateSuccessionReadinesses({ ...successionReadiness, yearsFromRetirement: value / 10 })}
            />
          </Box>
        </div>
      ) : (
        <div style={{ float: 'left' }}>
          <h2 className={classes.title} style={{ textAlign: 'left' }}>Continuity Readiness</h2>
            <div className={classes.leftMargin}>
              <p> 
                In today’s dynamic financial environment, 
                business continuity is essential for both operational stability and outstanding client service. 
                A solid plan ensures your firm can handle disruptions while consistently meeting client needs. 
                Take our quick assessment to gauge your preparedness, uncover vulnerabilities, 
                and strengthen your strategies. Protect your business and reassure clients of your 
                resilience—evaluate your readiness today.
              </p>
            </div>
        </div>
      ) }
      </div>
        
      )}
      <span className={classes.paddyButton}>
        <PaddyChatDrawer
          {...props}
          open={open}
          completed={completed}
          yearsFromRetirement={yearsFromRetirement}
          companySuccessionReadinessName={companySuccessionReadinessName}
          createCallback={(data) => {
            console.log('createCallback', data);
            props.updateSuccessionReadinesses(data);
          }}
        />
      </span>
      
    </div>
  )
}

SuccesionReadinessBanner.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    successionReadiness: get(state, 'successionReadiness.successionReadiness', {}),
    refetchFunc: get(state, 'successionReadiness.refetch', () => { }),
  }
};

export default withStyles(styles)(connect(mapStateToProps, { ...successionReadinessActionCreators })(SuccesionReadinessBanner));
