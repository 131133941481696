import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import PersonalMessageNotification from './PersonalMessageNotification';
import DayReminderNotification from './DayReminderNotification';
import AutoMailerUserNotification from './AutoMailerUserNotification';
import BcbAutoMailerGenericNotification from './BcbAutoMailerGenericNotification';
import EducationalContentNotification from './EducationalContentNotification';

const MessageByType = notification => {
  const { action } = notification;
  switch (action) {
    case 'findbob.message_sent':
      return <PersonalMessageNotification notification={notification} />;
    case 'findbob.bcp.send_day_reminder':
      return <DayReminderNotification notification={notification} />;
    case 'findbob.bcb.auto_mailer_user':
      return <AutoMailerUserNotification notification={notification} />;
    case 'findbob.educational_content_created':
      return <EducationalContentNotification notification={notification} />;
    case 'findbob.bcb.auto_mailer_generic':
      return <BcbAutoMailerGenericNotification notification={notification} />;
    default:
      return <div><FormattedMessage id="notifications.unknown_notification" /> {action}</div>;
  }
};

export const Notification = ({ notification, intl }) => {
  moment.locale(intl.locale)
  return MessageByType(notification);
};

export default injectIntl(Notification);
