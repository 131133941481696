import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import AddEducationalContentButton from './AddEducationalContentButton';
import EditEducationalContent from './EditEducationalContent';
import DeleteEducationalContentConfirmation from './DeleteEducationalContentConfirmation';
import MUIDataTable from 'mui-datatables';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const DeleteEducationalContentMutation = gql`
  mutation deleteEducationalContent($id: ID!) {
    deleteEducationalContent(id: $id)
  }
`;
class EducationalContent extends Component {
  changePage = ({ currentPage, changedColumn, direction }) => {
    const { updateEducationalContents, refetch, educationalContents } = this.props;
    let page = currentPage || educationalContents.page;
    const column = changedColumn || educationalContents.orderBy;
    let columnDirection;
    if (direction) {
      columnDirection = direction === 'descending' ? 'desc' : 'asc';
    } else {
      columnDirection = educationalContents.order;
    }
    if (currentPage === 0) {
      page = currentPage;
    }
    if (
      currentPage !== educationalContents.page ||
      changedColumn !== educationalContents.orderBy ||
      direction !== educationalContents.order
    ) {
      updateEducationalContents(
        {
          page,
          orderBy: column,
          order: columnDirection,
        },
        () => {
          const variables = {
            page,
            orderBy: column,
            order: columnDirection,
          };
          refetch(variables).then(response => {
            const ec = get(response, 'data.getEducationalContents');
            updateEducationalContents({
              page,
              orderBy: column,
              order: columnDirection,
              educationalContents: ec,
            });
          });
        }
      );
    }
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      openEdit: false,
      openDelete: false,
    };
  }

  options = () => {
    const { educationalContents } = this.props;
    const { count } = educationalContents;
    const { page } = this.state;
    return {
      serverSide: true,
      onChangePage: currentPage => this.changePage({ currentPage }),
      onColumnSortChange: (changedColumn, direction) => this.changePage({ changedColumn, direction }),
      page,
      rowsPerPage: 10,
      rowsPerPageOptions: [10],
      count,
      selectableRows: false,
      disableToolbarSelect: true,
      print: false,
      download: false,
      filter: false,
      search: false,
      responsive: 'scroll',
      fixedHeaderOptions: { yAxis: true },
    };
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            fontSize: 10,
          },
        },
        MUIDataTable: {
          responsiveScroll: {
            height: 'calc(100vh - 210px)',
            maxHeight: 'calc(100vh - 210px) !important',
          },
        },
      },
    });

  sortDirection = col => {
    const { educationalContents } = this.props;
    if (educationalContents.orderBy === col) {
      return educationalContents.order;
    }
    return 'none';
  };

  columns = () => {
    return [
      { name: 'Title', options: { filter: false, sortDirection: this.sortDirection('Title') } },
      { name: 'Type', options: { filter: false, sortDirection: this.sortDirection('Type') } },
      { name: 'Visibility', options: { filter: false, sortDirection: this.sortDirection('Visibility') } },
      { name: 'Notify On Publish', options: { filter: false, sortDirection: this.sortDirection('notifyOnPublish') } },
      { name: 'Locale', options: { filter: false, sortDirection: this.sortDirection('Locale') } },
      { name: 'Edit', options: { filter: false, sort: false } },
      { name: 'Remove', options: { filter: false, sort: false } },
    ];
  };

  formatRow = edu => [
    edu.title,
    edu.contentType,
    edu.visible ? 'True' : 'False',
    edu.notifyOnPublish ? 'True' : 'False',
    edu.locale,
    <IconButton onClick={() => this.handleClickEdit(edu)}>
      <EditIcon />
    </IconButton>,
    <IconButton
      onClick={() => {
        this.handleClickDelete(edu);
      }}>
      <DeleteIcon />
    </IconButton>,
  ];

  handleClickEdit = edu => {
    const { openEdit } = this.state;
    this.setState({ openEdit: !openEdit, selected: edu });
  };

  handleClickDelete = edu => {
    const { openDelete } = this.state;
    this.setState({ openDelete: !openDelete, selected: edu });
  };

  clickDeleteEducationalContent = () => {
    const { mutate, educationalContents, updateEducationalContents, refetch } = this.props;
    const { selected } = this.state;
    mutate({ variables: { id: selected.id } }).then(response => {
      if (response.data.deleteEducationalContent) {
        updateEducationalContents({
          educationalContents: educationalContents.educationalContents.filter(el => el.id !== selected.id),
        });
        refetch();
        this.handleClickDelete({});
      }
    });
  };

  render() {
    const { classes, educationalContents, refetch, updateEducationalContents, updateEducationalContent } = this.props;
    const { openEdit, openDelete, selected, order } = this.state;
    return (
      <div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            title=""
            data={get(educationalContents, 'educationalContents', []).map(ec => this.formatRow(ec))}
            columns={this.columns()}
            options={this.options()}
          />
        </MuiThemeProvider>
        <DeleteEducationalContentConfirmation
          openDelete={openDelete}
          educationalContent={selected}
          educationalContents={get(educationalContents, 'educationalContents', [])}
          updateEducationalContents={updateEducationalContents}
          clickDeleteEducationalContent={this.clickDeleteEducationalContent}
          handleClickDelete={this.handleClickDelete}
        />
        <EditEducationalContent
          openEdit={openEdit}
          refetch={refetch}
          educationalContent={selected}
          educationalContents={get(educationalContents, 'educationalContents', [])}
          updateEducationalContents={updateEducationalContents}
          updateEducationalContent={updateEducationalContent}
          handleClickEdit={this.handleClickEdit}
        />
        <AddEducationalContentButton
          refetch={refetch}
          educationalContents={get(educationalContents, 'educationalContents', [])}
          updateEducationalContents={updateEducationalContents}
          updateEducationalContent={updateEducationalContent}
        />
      </div>
    );
  }
}

EducationalContent.propTypes = {
  classes: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  educationalContents: PropTypes.array,
  updateEducationalContents: PropTypes.func.isRequired,
  updateEducationalContent: PropTypes.func.isRequired,
};

EducationalContent.defaultProps = {
  classes: {},
  educationalContents: [],
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    fontSize: '18px',
  },
  paper: {
    padding: 12,
  },
  control: {
    padding: theme.spacing.unit * 2,
  },
  title: {
    fontSize: 18,
    color: '#fff',
  },
  saveButton: {
    position: 'fixed',
    right: 30,
    bottom: 130,
  },
});

export default withStyles(styles)(graphql(DeleteEducationalContentMutation)(EducationalContent));
