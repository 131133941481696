import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { S3Image } from '../../lib/s3';

const OpportunityCard = ({ opportunity, classes, width, linkTo }) => {
  const { user, opportunity_type } = opportunity;

  return (
    <Paper className={classes.paper}>
      <CardActionArea className={classes.paper} component={props => <Link to={linkTo} {...props} />}>
        <CardMedia
          component="img"
          alt="Opportunity"
          className={classes.media}
          image={S3Image(opportunity_type.background_url)}
          title="Opportunity"
        />
        {width !== 'xs' && (
          <img
            src={S3Image(opportunity.user.profile_picture.replace('-rectangle', ''))}
            className={classes.profilePicture}
          />
        )}
        <div className={classes.ribbon}>{opportunity_type.name}</div>
        <div className={classes.cardContent}>
          <Typography variant="h4" className={classes.title} gutterBottom>
            {opportunity.title}
          </Typography>
          <span>
            <span className="fa fa-map-marker " />
            &nbsp;{user.province}
          </span>
          <div className={classes.body}>
            <p className={classes.description}>{opportunity.description}</p>
            {opportunity.user.user && (
              <div className={classes.info}>
                <dl>
                  <dt className={classes.dt}>Annual Revenue:</dt>
                  <dd className={classes.dd}>{numeral(user.user.annual_revenue).format('$0,0.00')}</dd>
                  <dt className={classes.dt}>Recurring Revenue:</dt>
                  <dd className={classes.dd}>{numeral(user.user.recurring_revenue).format('$0,0.00')}</dd>
                  <dt className={classes.dt}>Assets Under Management:</dt>
                  <dd className={classes.dd}>{numeral(user.user.assets_under_management).format('$0,0.00')}</dd>
                  <dt className={classes.dt}>Number of Clients:</dt>
                  <dd className={classes.dd}>{numeral(user.user.number_of_clients).format('0,0')}</dd>
                </dl>
              </div>
            )}
          </div>
        </div>
      </CardActionArea>
    </Paper>
  );
};

//Replace opportunity with shape
OpportunityCard.propTypes = {
  opportunity : PropTypes.object.isRequired,
  classes     : PropTypes.object.isRequired,
  width       : PropTypes.string.isRequired,
  linkTo      : PropTypes.string,
};

OpportunityCard.defaultProps = {
  linkTo : '#',
};

const styles = theme => ({
  paper          : {
    padding                                 : 0,
    marginBottom                            : 14,
    marginTop                               : 0,
    position                                : 'relative',
    height                                  : 'fit-content',
    width                                   : '100%',
    [theme.breakpoints.between('xs', 'sm')]: {
      display       : 'flex',
      flexDirection : 'row',
    },
  },
  media          : {
    width                                   : '100%',
    borderRadius                            : '4px 4px 0 0',
    [theme.breakpoints.between('xs', 'sm')]: {
      position : 'absolute',
      top      : '0px',
    },
  },
  profilePicture : {
    border       : '3px solid #4D7188',
    borderRadius : '50%',
    boxShadow    : '0px 2px 5px 0px rgba(0,0,0,0.75)',
    position     : 'relative',
    top          : -90,
    left         : 20,
    height       : 140,
  },
  cardContent    : {
    display                                 : 'inline-flex',
    marginLeft                              : 40,
    position                                : 'absolute',
    flexDirection                           : 'column',
    width                                   : '76%',
    [theme.breakpoints.between('xs', 'sm')]: {
      display    : 'flex',
      marginLeft : 0,
      position   : 'relative',
      width      : '90%',
      marginTop  : 60,
    },
  },
  title          : {
    marginBottom : 4,
    overflow     : 'hidden',
    whiteSpace   : 'nowrap',
    textOverflow : 'ellipsis',
    lineHeight   : '1.2',
    fontSize     : 18,
    paddingTop   : 4,
  },
  body           : {
    display                                 : 'flex',
    flexDirection                           : 'row',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection : 'column',
    },
  },
  description    : {
    flex         : 2,
    height       : '50%',
    overflow     : 'hidden',
    textOverflow : 'ellipsis',
    padding      : 4,
    maxHeight    : 56,
  },
  info           : {
    width                                   : '40%',
    fontSize                                : 12,
    [theme.breakpoints.between('xs', 'sm')]: {
      width : '100%',
    },
  },
  dt             : { float: 'left' },
  dd             : { textAlign: 'right' },
  ribbon         : {
    position                                : 'absolute',
    right                                   : 12,
    top                                     : 12,
    background                              : '#000',
    color                                   : '#fff',
    fontSize                                : 18,
    fontFamily                              : 'inherit',
    fontWeight                              : '500',
    lineHeight                              : '1.2',
    padding                                 : '6px 10px',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize : 11,
    },
  },
});

export default withWidth()(withStyles(styles)(OpportunityCard));
