import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { get, mean, compact } from 'lodash';
import Infographic from './Infographic';
import numeral from 'numeral';
import {
  VictoryBar,
  VictoryPie,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend,
  VictoryGroup,
} from 'victory';

const TransitionGoalPieChart = ({ inventory_users, classes, print_view, data, colorScale }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Grid container spacing={0} className={classes.scaleWrapper}>
          <Grid item xs={12}>
            <h3 className={print_view ? classes.pieGraphTitlePrint : classes.pieGraphTitle}>Transition Goals</h3>
            <svg width={`${print_view ? 900 : 500}`} height={`${print_view ? 700 : 400}`} viewBox={`45 35 300 300`}>
              <VictoryPie
                standalone={false}
                width={480}
                height={380}
                labels={() => null}
                style={{ labels: { display: 'none' } }}
                startAngle={640}
                endAngle={0}
                colorScale={colorScale}
                data={data}
              />
              <VictoryLabel textAnchor="middle" style={{ fontSize: 20 }} x={200} y={15} text="Pie!" />
            </svg>
          </Grid>
          <Grid item xs={12}>
            <svg viewBox={`0 0 ${print_view ? '700' : '500'} ${print_view ? '100' : '200'} `}>
              <VictoryLegend
                standalone={false}
                y={10}
                orientation="horizontal"
                colorScale={colorScale}
                gutter={5}
                itemsPerRow={3}
                centerTitle
                style={{ title: { fontSize: 15 }, labels: { fontSize: 8 } }}
                data={data.map(i => ({
                  name : i.label,
                }))}
              />
            </svg>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  root                 : {
    maxWidth : '100%',
    margin   : '20px 0',
  },
  table                : {
    minWidth : 650,
  },
  print                : {
    width   : 'fit-content',
    display : 'block',
  },
  container            : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu                 : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker           : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber            : { fontSize: 50 },
  subHeader            : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel          : { fontSize: 16 },
  selectatron          : { marginRight: 10 },
  formControl          : {
    margin   : 2,
    minWidth : 120,
  },
  infoGraphicContainer : {
    margin : '34px 0px',
  },
  scaleWrapper         : {
    padding : 40,
  },
  scaleWrapperPrint    : {
    padding    : '10px',
    background : 'white',
    boxSizing  : 'border-box',
    resize     : 'horizontal',
    overflow   : 'auto',
    maxWidth   : '100%',
    height     : 'calc(100vh - 16px)',
  },
  pieGraphTitle        : {
    marginLeft : '23%',
  },
  pieGraphTitlePrint   : {
    fontWeight   : 'bold',
    marginBottom : 0,
    fontSize     : 35,
  },
  stateGraphTitle      : {
    marginLeft : '20%',
  },
  stateGraphTitlePrint : {
    fontWeight   : 'bold',
    marginBottom : 0,
    fontSize     : 35,
  },
};

const mapStateToProps = state => ({
  motivationFilters    : state.motivationFilters,
  inventory_users      : state.inventory_users.inventory_users,
  inventory_user_count : state.inventory_users.inventory_user_count,
  inventory_user_count : state.inventory_users.inventory_user_count,
  transition_goals     : state.inventory_users.transition_goals,
  deals_count          : state.inventory_users.deals_count,
  currentCountry       : get(state, 'inventory_users.currentCountry', ''),
  print_view           : state.inventory_users.print_view,
});

const TransitionGoalPieChartWithStyles = withStyles(styles)(TransitionGoalPieChart);
export default connect(mapStateToProps)(TransitionGoalPieChartWithStyles);
