import React from 'react';
import { Card, CardActionArea, CardContent, BottomNavigation, BottomNavigationAction, Avatar, Typography, IconButton, InputBase, Paper } from '@material-ui/core';
import { QuestionAnswer, Message, Home, Search, Help, ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import gql from 'graphql-tag';
import HelpSection from './HelpSection';
// import ChatWidget from '../ChatWidget/ChatWidget';
import { injectIntl } from "react-intl";


const useStyles = makeStyles(theme => ({
  root: {
    width: 400,
    position: 'fixed',
    bottom: 80,
    right: 20,
    zIndex: 1000,
  },
  container: {
    backgroundColor: theme.palette.secondary.dark,
    color: '#fff',
    borderRadius: 10,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: theme.spacing(1),
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  message: {
    marginLeft: theme.spacing(2),
  },
  bottomNavigation: {
    backgroundColor: '#fff',
    borderRadius: '0 0 10px 10px',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  cardContent: {
    minHeight: 600,
    padding: theme.spacing(2),
  },
  messageBox: {
    width: '365px',
    marginLeft: '-34px',
  },

}));

const titles = {
  'help': "Support Center",
  'messages': "Message Center",
  'chat_widget': "Chat Drawer"
};
const messages = {
  'help': "We're here to help. Start a support request or check up on an existing one",
  'messages': 'All of your messages in one place',
  'chat_widget': ''
};

const LeadDrawer = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('help');
  // const company = get(data, 'company', {});
  const company = {};

  return (
    <div className={classes.root}>
      <Card className={classes.container}>
        <CardContent className={classes.content}>
          <Avatar
            alt={'fb'}
            className={classes.avatar}
          />
          <Typography variant="body2" className={classes.message}>
            {titles[value]}<br />
            <span style={{ fontSize: '0.8em', color: '#ccc' }}>{messages[value]}</span>
          </Typography>
        </CardContent>
        <CardContent>
          <Paper className={classes.cardContent}>
            <HelpSection company={company} client={props.client} />
          </Paper>
        </CardContent>
        <BottomNavigation
          showLabels
          className={classes.bottomNavigation}
        >
          <BottomNavigationAction label={props.intl.formatMessage({id: "support_request.help"})} value="help" icon={<Help />} />
        </BottomNavigation>
      </Card>
    </div>
  );
};

export default injectIntl(LeadDrawer);
