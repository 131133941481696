import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { STEP_NUMBERS } from '../SignUpWizard';
import UserBusinessContinuity from './UserBusinessContinuity';
import UserSuccessionPlanning from './UserSuccessionPlanning';
import UserTransitionPeriod from './UserTransitionPeriod';
import UserWorkweekTrajectory from './UserWorkweekTrajectory';
import './PlanningInformation.css';
import { intersection } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';

const timeOptions = [
  { name: '0 months', value: 0 },
  { name: '6 months', value: 6 },
  { name: '12 months', value: 12 },
  { name: '18 months', value: 18 },
  { name: '2 years', value: 24 },
  { name: '2.5 years', value: 30 },
  { name: '3 years', value: 36 },
  { name: '4 years', value: 48 },
  { name: '5 years', value: 60 },
  { name: '6 years', value: 72 },
  { name: '7 years', value: 84 },
  { name: '8 years', value: 96 },
  { name: '9 years', value: 108 },
  { name: '10 years', value: 120 },
];

const disableNext = (user, isSeller) => {
  let result = false;
  if (isSeller) {
    [
      'business_continuity_state_id',
      'succession_planning_state_id',
      'transition_period_months',
      'target_timeframe',
      'workweek_trajectory_now',
      'workweek_trajectory_year_3',
      'workweek_trajectory_year_5',
      'workweek_trajectory_year_10',
      'workweek_trajectory_year_15',
      'workweek_trajectory_year_20',
      'workweek_trajectory_year_25',
    ].map(field => {
      if ((get(user, field) !== null || -1) < 0) {
        result = true;
      }
    });
  } else {
    [ 'business_continuity_state_id', 'target_timeframe' ].map(field => {
      if ((get(user, field) !== null || -1) < 0) {
        result = true;
      }
    });
  }
  return result;
};

const PlanningInformation = ({ user, updateAction, company, mutate, onBack, onNext, agentWord }) => {
  const isSeller =
    intersection(user.transition_goals, [
      'Selling my practice',
      'Selling part of my practice',
      'Selling',
      'Finding a Successor',
      'Business Continuity',
    ]).length > 0;
  return (
    <div className="content">
      {isSeller && (
        <div>
          <h2><FormattedMessage id="profile_page.product_mix.how_ready_transition_your_business" /></h2>
          <p>
            <FormattedMessage id="profile_page.product_mix.you_indicated_you_were_either_interested_selling" />
          </p>
        </div>
      )}
      {!isSeller && (
        <div>
          <h2><FormattedMessage id="profile_page.product_mix.you_have_business_continuity_plan" /></h2>
          <p>
            {`Regardless of the stage in your career, every ${agentWord} requires a plan in the event of the unexpected.
            Whether that is a significant business disruption like an earthquake, flood, or pandemic. Or perhaps a
            ${get(
              user,
              'company.catastrophic_planning_word',
              'catastrophic'
            )} disruption like death, disability, or loss of license. Whatever the case may be, help us better
            understand your state of readiness.`}
          </p>
        </div>
      )}
      <div className="planningInformation__headingSection">
        <b><FormattedMessage id="profile_page.product_mix.business_continuity_readiness" /></b>
      </div>
      <UserBusinessContinuity user={user} updateAction={updateAction} company={company} />
      {isSeller && (
        <div>
          <br />
          <div className="planningInformation__headingSection">
            <b><FormattedMessage id="profile_page.product_mix.successsion_planning_readiness" /></b>
          </div>
          <UserSuccessionPlanning user={user} updateAction={updateAction} company={company} />

          <div className="planningInformation__headingSection">
            <b><FormattedMessage id="profile_page.product_mix.planning_time_horizon" /></b>
          </div>
          <UserTransitionPeriod user={user} updateAction={updateAction} company={company} />
        </div>
      )}
      <br />
      <div className="planningInformation__headingSection">
        <b><FormattedMessage id="profile_page.product_mix.target_timeframe" /></b>
      </div>
      <div><FormattedMessage id="profile_page.product_mix.your_ideal_timeframe_completing_transition_goals" /></div>
      <Select
        fullWidth
        className="PlanningInformation__radio"
        value={`${get(user, 'target_timeframe')}`}
        onChange={e => {
          updateAction({ target_timeframe: parseInt(e.target.value, 10) });
        }}>
        {timeOptions.map(sps => (
          <MenuItem value={sps.value} className="PlanningInformation__radio" label={sps.name}>
            {sps.name}
          </MenuItem>
        ))}
      </Select>
      <br />
      {isSeller && (
        <div>
          <UserWorkweekTrajectory user={user} updateAction={updateAction} company={company} />
          <div className="planningInformation__spacer clearfix">
            <br />
          </div>
        </div>
      )}
      <br />
      {onBack && (
        <button className="btn btn-default planningInformation__wizardButton" onClick={onBack}>
          <FormattedMessage id="profile_page.product_mix.back" />
        </button>
      )}
      <div className="right">
        <LoadingButtonWrapper stateChild="user">
          <button
            disabled={disableNext(user, isSeller)}
            className="btn btn-default right planningInformation__wizardButton"
            onClick={() => {
              updateAction({ loading: true });
              mutate({
                variables : {
                  business_continuity_state_id : user.business_continuity_state_id,
                  succession_planning_state_id : user.succession_planning_state_id,
                  transition_period_months     : user.transition_period_months,
                  target_timeframe             : user.target_timeframe,
                  workweek_trajectory_now      : user.workweek_trajectory_now,
                  workweek_trajectory_year_3   : user.workweek_trajectory_year_3,
                  workweek_trajectory_year_5   : user.workweek_trajectory_year_5,
                  workweek_trajectory_year_10  : user.workweek_trajectory_year_10,
                  workweek_trajectory_year_15  : user.workweek_trajectory_year_15,
                  workweek_trajectory_year_20  : user.workweek_trajectory_year_20,
                  workweek_trajectory_year_25  : user.workweek_trajectory_year_25,
                },
              }).then(response => {
                updateAction({ planning_info_complete: true, loading: false });
                onNext(STEP_NUMBERS.bio);
              });
            }}>
            <FormattedMessage id="profile_page.product_mix.next" />
          </button>
        </LoadingButtonWrapper>
      </div>
    </div>
  );
};

PlanningInformation.propTypes = {
  user         : PropTypes.object,
  updateAction : PropTypes.func.isRequired,
  company      : PropTypes.object,
  mutate       : PropTypes.func.isRequired,
  onBack       : PropTypes.func.isRequired,
  onNext       : PropTypes.func.isRequired,
};

PlanningInformation.defaultProps = {
  user    : {},
  company : {},
};

export default PlanningInformation;
