import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import CurrencyInput from 'react-currency-input-field';
import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';
import { get } from 'lodash';
import numeral from 'numeral';

const PE_VALUATION_QUERY = gql`
  query peValuation($numberOfClients: Float!, $assetsUnderManagement: Float!, $feeBasedRevenue: Float!, $trailerRevenue: Float!, $directRevenue: Float!, $commissionRevenue: Float!) {
    peValuation(
      numberOfClients: $numberOfClients
      assetsUnderManagement: $assetsUnderManagement
      feeBasedRevenue: $feeBasedRevenue
      trailerRevenue: $trailerRevenue
      directRevenue: $directRevenue
      commissionRevenue: $commissionRevenue
    )
  }
`;

const PriceDetails = ({ formData, setFormData, handleCurrencyChange, company }) => {
  const { loading, error, data } = useQuery(PE_VALUATION_QUERY, {
    variables: { ...formData },
  });

  const peValuation = data ? data.peValuation : null;
  const valuationLow = get(peValuation, '[0]', 0);
  const valuationHigh = get(peValuation, '[1]', 0);
  const [isNegotiable, setIsNegotiable] = useState(true);
  const hidePartialSalesSuggestedPriceRange = company.feature_types.includes('hide_partial_sales_suggested_price_range');
  const currencyInputRef = useRef(null);

  useEffect(() => {
    if (currencyInputRef.current) {
      currencyInputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (formData.askingPrice === 0) {
      setIsNegotiable(true);
    }
  }, [formData.askingPrice]);

  const handleNegotiableChange = (event) => {
    const checked = event.target.checked;
    setIsNegotiable(checked);
    if (checked) {
      setFormData({ ...formData, askingPrice: 0 });
      handleCurrencyChange({ target: { value: 'USD' } });
    }
  };

  const handleAskingPriceChange = (value, name) => {
    const numericValue = parseFloat(value) || 0.00;
    setFormData({ ...formData, [name]: numericValue });
    if (numericValue === 0.00) {
      setIsNegotiable(true);
    } else {
      setIsNegotiable(false);
    }
  };

  const displayPrice = isNegotiable || formData.askingPrice === 0 ? 'Negotiable' : numeral(formData.askingPrice).format('$0,0.00');

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        What is your asking price?
      </Typography>  
      <CurrencyInput
        ref={currencyInputRef}
        label="Asking Price"
        name="askingPrice"
        value={formData.askingPrice}
        onValueChange={(value) => handleAskingPriceChange(value, 'askingPrice')}
        decimalScale={2}
        prefix="$"
        fullWidth
        margin="normal"
      />
      <Typography variant="body1" gutterBottom>
        Asking Price: <strong>{displayPrice}</strong>
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={isNegotiable}
            onChange={handleNegotiableChange}
            name="isNegotiable"
            color="primary"
          />
        }
        label="Price is Negotiable"
      />    
      <hr/>
      {!hidePartialSalesSuggestedPriceRange && (
        <Typography variant="body1" gutterBottom>
          Based on the information you provided, we approximate the value of your segment to be between
          <strong> {numeral(valuationLow).format('$0,0.00')}</strong> and <strong>{numeral(valuationHigh).format('$0,0.00')}</strong>.
        </Typography>
      )}
    </Box>
  );
};

export default PriceDetails;