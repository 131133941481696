import React from 'react';

const returnTransitionGoals = user => {
  const tg = [];
  for (let i = 0; i < user.transition_goals.length; i += 1) {
    switch (user.transition_goals[i]) {
      case 'Selling my practice':
      case 'Selling part of my practice':
      case 'Business Continuity':
        tg.push('buyer');
        break;
      case 'Find a Successor':
        tg.push('successor');
        break;
      default:
        tg.push('transition partner');
        break;
    }
  }
  return tg;
};

const displayHeadline = (
  user,
  userIsAdmin,
  currentUserIsSellerOnly,
  currentCompanySellerWord,
  disableClick,
  handleClick,
  primaryColor = '#1396e2'
) => {
  if (disableClick) {
    return <div style={{ color: primaryColor }}>{user.headline}</div>;
  }
  if (userIsAdmin) {
    return (
      <a
        style={{ color: primaryColor }}
        onClick={() => {
          handleClick();
        }}>
        {user.headline}
      </a>
    );
  }
  if (currentUserIsSellerOnly && !user.is_pure_buyer) {
    const tg = returnTransitionGoals(user);
    const tgUnique = tg.filter((item, index) => tg.indexOf(item) === index);
    return (
      <a
        onClick={() => {
          handleClick();
        }}>
        {`${currentCompanySellerWord} interested in finding a ${tgUnique.join(' and ')}.`}
      </a>
    );
  }
  return (
    <a
      style={{ color: primaryColor }}
      onClick={() => {
        handleClick();
      }}>
      {user.headline}
    </a>
  );
};

const Headline = ({
  user,
  userIsAdmin,
  currentUserHasLimitedAccess,
  currentCompanySellerWord,
  disableClick,
  handleClick,
  primaryColor,
}) => (
    <span>
      {displayHeadline(
        user,
        userIsAdmin,
        currentUserHasLimitedAccess,
        currentCompanySellerWord,
        disableClick,
        handleClick,
        primaryColor
      )}
    </span>
  );

export default Headline;
