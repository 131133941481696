import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ClickActivities from './ClickActivities';
import SearchActivities from './SearchActivities';
import OtherActivities from './OtherActivities';
import { S3Image } from '../../lib/s3';
import initials from 'initials';
import moment from 'moment';
import { regionForCountry, bgColorForName } from '../../lib/findBobUtils';

class RecentActiveUsersBox extends React.Component {
  getAvatar = name => {
    const { inventoryUser, classes } = this.props;
    if(!inventoryUser)
      return <span></span>
    return (
      <Avatar src={inventoryUser.has_user ? S3Image(inventoryUser.profile_picture) : null} className={classes.avatar} />
    );
  };

  clickName = () => {
    const { inventoryUser } = this.props;
    window.open(`/admin/user_admin?inv_user=${inventoryUser.id}`, '_self');
  };

  // Leaving getActivity function for future
  getActivity = (firstName, event) => {
    if (event) {
      switch (event.name) {
        case '$click':
          return <ClickActivities firstName="" event={event} />;
        case 'Map Search':
        case 'List Search':
          return <SearchActivities firstName="" event={event} />;
        default:
          return <OtherActivities firstName="" event={event} />;
      }
    }
    return <div />;
  };

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).format('MMM D')} at ${moment(date).format('LT')}`;
  };

  render () {
    const { classes, firstName, initials, event, date, note, activity, dashboard } = this.props;
    return (
      <div className={classes.box}>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            {dashboard ? this.getAvatar() : initials}
          </Grid>
          <Grid item xs={10}>
            <div className={classes.contentBox}>
              <button type="button" className={classes.button} onClick={() => this.clickName()}>
                <Typography className={classes.title}>{firstName}</Typography>
                <Typography className={classes.event}>Last Active: {this.formatDate(date)}</Typography>
              </button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

RecentActiveUsersBox.propTypes = {
  classes   : PropTypes.object.isRequired,
  event     : PropTypes.object.isRequired,
  firstName : PropTypes.string.isRequired,
  initials  : PropTypes.string.isRequired,
  date      : PropTypes.string.isRequired,
  note      : PropTypes.string.isRequired,
  activity  : PropTypes.string.isRequired,
};

const styles = {
  box        : {
    marginTop : 5,
  },
  title      : {
    fontWeight : 'bold',
    textAlign  : 'left',
    fontSize   : 14,
  },
  event      : {
    textAlign : 'left',
    color     : '#808080',
    fontSize  : '1em',
  },
  content    : {
    textAlign : 'left',
    fontSize  : 16,
    color     : '#808080',
  },
  contentBox : {
    margin : '5px 0px 5px 5px',
  },
  note       : {
    textAlign    : 'left',
    fontSize     : 16,
    color        : '#000',
    marginTop    : 8,
    marginBottom : 10,
  },
  avatar     : {
    margin : '5px 10px 0px 15px',
  },
  avatarText : {
    textTransform : 'uppercase',
  },
  button     : {
    width      : '100%',
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
};

export default withStyles(styles)(RecentActiveUsersBox);
