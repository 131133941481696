import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { get, capitalize } from 'lodash';
import Fab from '@material-ui/core/Fab';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import numeral from 'numeral';
import { FormattedMessage, injectIntl } from 'react-intl';

class SuggestedDealCard extends React.Component {
  constructor (props) {
    super(props);
    this.state = { deal_type: 'continuity' };
  }

  componentWillReceiveProps (nextProps) {
    const { deal, updateSuggestedDeal } = this.props;
    if (deal.id !== nextProps.deal.id) {
      updateSuggestedDeal(deal);
    }
  }

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).format('MMM D')} at ${moment(date).format('LT')}`;
  };

  selectDealType = deal_type => {
    const { deal, updateSuggestedDeal } = this.props;
    this.setState({ deal_type });
    updateSuggestedDeal({ ...deal, selected_deal_type: deal_type });
  };

  addDeal = () => {
    const { deal, updateSelectedSuggestedDeal, agentWordForCompany } = this.props;
    const { deal_type } = this.state;

    updateSelectedSuggestedDeal({
      ...deal.user,
      inv_id              : get(deal, 'user.inventory_user.id', ''),
      user_id             : get(deal, 'user.id', ''),
      deal_type,
      agentWordForCompany,
      deal_id             : get(deal, 'id', ''),
    });
  };

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    return `${moment(date).fromNow()}`;
  };

  render () {
    const { classes, deal, deleteSuggestedDeal, pipelineList } = this.props;
    const { deal_type } = this.state;
    return (
      <Card style={{ height: '100%' }}>
        <div className={classes.row}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Avatar className={classes.avatar}>
                <i className="fas fa-briefcase" />
              </Avatar>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.text}>
                <span className={classes.nameField}>{get(deal, 'user.name')}</span>
                <br />
                <span className={classes.caseField}>
                  {capitalize(get(deal, 'user.city'))}, {capitalize(get(deal, 'user.province'))}
                </span>
                <br />
                <span className={classes.caseField}>
                  {numeral(get(deal, 'user.annual_revenue')).format('$ 0,0[.]00')}
                </span>
              </div>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={1}>
              <IconButton color="primary">
                <CloseIcon
                  onClick={() => {
                    deleteSuggestedDeal({ variables: { id: deal.id } });
                    window.location.reload();
                  }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={12} style={{ height: 10 }} />
            <Grid item xs={10}>
              <span className={classes.timeField}>Suggested {this.formatDate(get(deal, 'user.created_at'))}</span>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={12} style={{ height: 35 }} />
            <Grid item xs={10}>
              <Select
                style={{ minWidth: 150 }}
                value={deal_type}
                className={classes.inputField}
                onChange={e => {
                  this.selectDealType(e.target.value);
                }}>
                <MenuItem key={0} value="continuity">
                  <FormattedMessage id="dr_wizard.continuity" />
                </MenuItem>
                <MenuItem key={1} value="succession">
                  <FormattedMessage id="dr_wizard.succession" />
                </MenuItem>
                <MenuItem key={2} value="sale">
                  <FormattedMessage id="dr_wizard.sale" />
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={2}>
              <Fab variant="extended" size="medium" color="primary" aria-label="Add" onClick={() => this.addDeal()}>
                <FormattedMessage id="dr_wizard.add" />
              </Fab>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </div>
      </Card>
    );
  }
}

const styles = {
  avatar    : {
    margin : '0px 10px 0px 10px',
  },
  row       : {
    margin : '30px 10px 5px 30px',
  },
  caseField : {
    color    : '#797D7F',
    fontSize : 14,
  },
  timeField : {
    color : '#797D7F',
  },
  nameField : {
    fontWeight : 500,
    fontSize   : 20,
  },
};

SuggestedDealCard.propTypes = {
  classes                     : PropTypes.object.isRequired,
  deal                        : PropTypes.object.isRequired,
  updateSuggestedDeal         : PropTypes.func.isRequired,
  deleteSuggestedDeal         : PropTypes.func.isRequired,
  updateSelectedSuggestedDeal : PropTypes.func.isRequired,
  pipelineList                : PropTypes.array.isRequired,
};

export default withStyles(styles)(SuggestedDealCard);
