import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import { get } from 'lodash';

const TeamSize = ({ classes, dealInterest }) => {
  if (get(dealInterest, 'inventory_user.user.part_of_team')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.team_size"/></strong>
        <p>{get(dealInterest, 'inventory_user.user.team_size')}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(TeamSize);
