import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { get, isEmpty } from 'lodash';
import ProfileDrawer from '../components/ProfileDrawer/ProfileDrawer';

const GetNotebookForUser = gql`
  query NotebookForUser($userName: String!) {
    notesForUser(userName: $userName) {
      id
      comment
      created_at
    }
  }
`;

class SideProfileDashboardContainer extends React.Component {
  state = {
    updateQuery : false,
  };

  componentWillReceiveProps (nextProps) {
    const { profile } = this.props;
    const userName = get(profile, 'data.getUser.user_name');
    const otherUserName = get(nextProps.profile, 'data.getUser.user_name');

    if (userName !== otherUserName) {
      this.setState({ updateQuery: nextProps.userName });
      this.props.updateNotes({ notes: [], skip: false, loaded: false });
    }
  }

  render () {
    const { notes, updateNotes, profile, userName } = this.props;
    const { updateQuery } = this.state;
    if (!get(profile, 'data.getUser.id')) {
      return <span />;
    }
    const getCurrentUser = get(profile, 'data.getCurrentUser');
    return (
      <Query
        query={GetNotebookForUser}
        variables={{ userName: get(profile, 'data.getUser.fb_user_name', '') }}
        fetchPolicy="network-only">
        {({ loading, data, refetch, variables }) => {
          if (
            !loading &&
            Boolean(get(data, 'notesForUser')) &&
            get(notes, 'notes.length') !== get(data, 'notesForUser.length', -1) &&
            !notes.skip
          ) {
            if (isEmpty(data)) return <div />;
            updateNotes({ notes: get(data, 'notesForUser', {}), userName });
          }
          if (updateQuery) {
            this.setState({ updateQuery: false }, () => {
              refetch({ variables: { userName: updateQuery } }).then(response => {
                updateNotes({ notes: get(response, 'data.notesForUser', {}), userName });
              });
            });
          }
          // return <div />;
          return <ProfileDrawer {...this.props} refetch={refetch} getCurrentUser={getCurrentUser} />;
        }}
      </Query>
    );
  }
}

SideProfileDashboardContainer.propTypes = {
  notes       : PropTypes.array,
  updateNotes : PropTypes.func.isRequired,
  profile     : PropTypes.object,
};

SideProfileDashboardContainer.defaultProps = {
  notes   : [],
  profile : {},
};

export default SideProfileDashboardContainer;
