import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import * as actions from '../actions/dealActionCreators';
import { updateSelectedInventoryUser } from '../actions/inventoryUsersActionCreators';

import DealPage from '../components/DealPage';

const DealsContainer = DealPage;

const mapStateToProps = state => ({
  ...state,
  intl : state.intl,
});

export default connect(mapStateToProps, { ...actions, updateSelectedInventoryUser })(DealsContainer);
