import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get, take} from 'lodash';
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {Paper} from "@material-ui/core";
import moment from 'moment';
import numeral from "numeral";
import {FormattedMessage, injectIntl} from "react-intl";

const CfaList = ({result, classes, intl}) => {
  const list = get(result, 'cashFlows', []);
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  return <React.Fragment>
    <h2><FormattedMessage id="calculators.cfa.saved_analyses" /></h2>
    {take(list || [], 10).map(cashFlow => (
      <Link to={`/calculators/cfa/${cashFlow.friendlyName}`}>
        <Paper className={classes.flow}>
          <h5 className={classes.name}>{cashFlow.name}</h5>
          <figure
            className={classes.details}>{cashFlow.numberOfBuyers} {<FormattedMessage id="calculators.cfa.buyer"/>}{cashFlow.numberOfBuyers > 1 ? 's' : ''}; {cashFlow.cashFlowLength}
            {<FormattedMessage id="calculators.cfa.year_cash_flow"/>}
          </figure>
          <div><span className="tag price">{numeral(cashFlow.sellerAverageCashFlow).format(dollarFormat)}</span> <span
            className={`${classes.green} tag price`}>{numeral(cashFlow.buyer1AverageCashFlow).format(dollarFormat)}</span>
            &nbsp;
            {cashFlow.numberOfBuyers > 1 && <span
              className={`${classes.green} tag price`}>{numeral(cashFlow.buyer2AverageCashFlow).format(dollarFormat)}</span>}
          </div>
          <div className={classes.date}>{moment.utc(cashFlow.createdAt).fromNow()}</div>
        </Paper>
      </Link>
    ))}
  </React.Fragment>;
};

CfaList.propTypes = {
  list: PropTypes.array,
};

CfaList.defaultProps = {
  list: [],
}

const styles = () => ({
  flow: {
    padding: 6,
    margin: 4,
    paddingTop: 2,
    paddingBottom: 2,
  },
  date: {
    textAlign: 'right',
    color: '#455560',
  },
  green: {
    backgroundColor: 'green !important',
  },
  name: {
    fontSize: 16,
    marginBottom: 4,
  },
  details: {
    color: 'cadetblue',
    marginBottom: 4,
  },
  link: {
    marginRight: 8,
    cursor: 'pointer',
  },
});

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
    query: {...get(state, 'cfa.query', {})},
  };
};

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CfaList)));


