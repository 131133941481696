import gql from 'graphql-tag';

export const PipelinesQuery = gql `
  query pipeline(
    $id: ID
    $cardsPage: Int
    $searchText: String
    $column: String
    $columnDirection: String
    $dealId: Int
    $filterParams: PipelineListViewFilterType
    $status: String
  ) {
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
        __typename
      }
      __typename
    }
    getCategoryForTasks {
      id
      name
      __typename
    }
    pipelines {
      id
      name
      card_count
      category_for_task {
        id
        __typename
      }
      __typename
      pipeline_stages {
        id
        __typename
      }
    }
    pipeline(id: $id) {
      id
      name
      category_for_task {
        id
        __typename
      }
      card_count(searchText: $searchText, filterParams: $filterParams, status: $status)
      __typename
      pipeline_stages {
        id
        name
        ordinal
        win_percentage
      }
      pipeline_cards(
        page: $cardsPage
        searchText: $searchText
        column: $column
        columnDirection: $columnDirection
        dealId: $dealId
        filterParams: $filterParams
        status: $status
      ) {
        id
        name
        ordinal
        value
        close_date
        last_moved_date
        status
        priority
        visibility
        __typename
        primary_agent {
          id
          name
          __typename
        }
        owner {
          id
          name
          __typename
        }
        pipeline_stage {
          id
          name
          __typename
        }
        deal_interest {
          deal {
            id
            deal_name
            __typename
          }
        }
      }
    }
  }
`;

export const PipelinesBoardQuery = gql `
  query pipeline(
    $id: ID
    $column: String
    $columnDirection: String
    $status: String
    $agentName: String
    $ownerName: String
    $filterByAgreementCheckbox: Boolean
  ) {
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
        documents {
          id
          name
          download_url
          uploaded_by {
            id
            name
            __typename
          }
          created_at
          category
          mime_type
          __typename
        }
        company {
         business_type
         feature_types
        }
      }
    }
    getCategoryForTasks {
      id
      name
      __typename
    }
    pipelines {
      id
      name
      card_count
      category_for_task {
        id
        __typename
      }
      pipeline_stages {
        id
      }
      __typename
    }
    pipeline(id: $id) {
      id
      name
      disable_pipeline_features
      category_for_task {
        id
        __typename
      }
      __typename
      pipeline_stages {
        id
        name
        ordinal
        win_percentage
        card_count(
          status: $status
          agentName: $agentName
          ownerName: $ownerName
          filterByAgreementCheckbox: $filterByAgreementCheckbox
        )
        stage_totals(
          status: $status
          agentName: $agentName
          ownerName: $ownerName
          filterByAgreementCheckbox: $filterByAgreementCheckbox
        )
        total_value
        pipeline_cards(
          column: $column
          columnDirection: $columnDirection
          status: $status
          agentName: $agentName
          ownerName: $ownerName
          filterByAgreementCheckbox: $filterByAgreementCheckbox
        ) {
          id
          name
          ordinal
          value
          close_date
          last_moved_date
          status
          priority
          visibility
          created_at
          successionReadiness {
            id
            score
            completionPercentage
            yearsFromRetirement
            completed
            readinessActivities {
              id
              key
              title
              text
              score
              completed
              category
              dueDate
              ctaTarget
              ctaText
              ctaType
            }
          }
          __typename
          continuity_builder {
            id
            __typename
            completedFindPartnerStep
            completedFileAgreementStep
            completedBusinessPlanStep
            filedAgreementTimestamp
            userGoal {
              id
            }
          }
          primary_agent {
            id
            name
            __typename
          }
          owner {
            id
            name
            __typename
          }
          pipeline_stage {
            id
            name
            __typename
          }
          deal_interest {
            deal {
              id
              deal_name
              __typename
            }
          }
        }
      }
    }
  }
`;

export const ActivitiesQuery = gql `
  query pipelineCard($id: ID!) {
    getActivityTypes {
      id
      name
      iconUrl
      __typename
    }
    pipelineCard(id: $id) {
      id
      created_at
      last_moved_date
      __typename
      pipeline_activities {
        id
        interaction
        activityType {
          id
          name
          iconUrl
        }
        note
        activityDate
        inventoryUser {
          id
          name
          __typename
        }
      }
    }
    getCurrentUser {
      id
      inventory_user {
        id
        name
        id
        __typename
      }
    }
  }
`;

export const TasksQuery = gql `
  query pipelineCard($id: ID!) {
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
      }
      __typename
    }
    getActivityTypes {
      id
      name
      iconUrl
      __typename
    }
    pipelineCard(id: $id) {
      id
      tasks {
        id
        __typename
        name
        status
        description
        dueDate
        documents {
          id
          name
          download_url
          uploaded_by {
            id
            name
            __typename
          }
          created_at
          category
          mime_type
          __typename
        }
        pipelineCard {
          id
          name
          __typename
        }
        activityType {
          id
          name
          iconUrl
          __typename
        }
        inventoryUser {
          id
          name
          __typename
        }
      }
    }
  }
`;

export const CategorizedTaskQuery = gql `
  query getCategorizedTasks(
    $order: String
    $orderBy: String
    $activity_type_id_in: [ID]
    $category_for_task_id_in: [ID]
    $nameSearch: String
  ) {
    getCategorizedTasks(
      order: $order
      orderBy: $orderBy
      activity_type_id_in: $activity_type_id_in
      category_for_task_id_in: $category_for_task_id_in
      nameSearch: $nameSearch
    ) {
      id
      name
      description
      ordinal
      activityType {
        id
        name
        iconUrl
      }
      categoryForTask {
        id
        name
      }
    }
  }
`;

export const PipelineCardQuery = gql `
  query pipelineCards($id: ID!) {
    getInventoryUser(id: $id) {
      id
      pipeline_cards {
        id
        name
        pipeline {
          id
          name
          __typename
        }
        pipeline_stage {
          id
          name
          __typename
        }
        status
        value
      }
    }
  }
`;

export const updatePipelineCardMutation = gql `
  mutation updatePipelineCard(
    $id: ID!
    $name: String
    $pipeline_stage_id: ID
    $pipeline_id: ID
    $value: Float
    $description: String
    $close_date: String
    $owner_id: ID
    $primary_agent_id: ID
    $inventory_users: [ID!]
    $status: String
    $visibility: String
    $priority: String
    $last_moved_date: String
  ) {
    updatePipelineCard(
      id: $id
      name: $name
      pipeline_stage_id: $pipeline_stage_id
      pipeline_id: $pipeline_id
      value: $value
      description: $description
      close_date: $close_date
      owner_id: $owner_id
      primary_agent_id: $primary_agent_id
      inventory_users: $inventory_users
      status: $status
      visibility: $visibility
      priority: $priority
      last_moved_date: $last_moved_date
    ) {
      id
    }
  }
`;

export const createPipelineMutation = gql `
  mutation createPipeline($name: String!, $pipeline_stages: [PipelineStageInput!], $category_for_task_id: ID) {
    createPipeline(name: $name, pipeline_stages: $pipeline_stages, category_for_task_id: $category_for_task_id) {
      id
      name
    }
  }
`;

export const updatePipelineMutation = gql `
  mutation updatePipeline(
    $id: ID!
    $name: String!
    $disable_pipeline_functions: Boolean!
    $pipeline_stages: [PipelineStageInput!]
    $category_for_task_id: ID
  ) {
    updatePipeline(
      id: $id
      name: $name
      disable_pipeline_functions: $disable_pipeline_functions
      pipeline_stages: $pipeline_stages
      category_for_task_id: $category_for_task_id
    ) {
      id
      name
    }
  }
`;

export const createPipelineCardMutation = gql `
  mutation createPipelineCard(
    $name: String
    $pipeline_stage_id: ID
    $pipeline_id: ID
    $value: Float
    $description: String
    $status: String
    $visibility: String
    $priority: String
    $owner_id: ID
    $primary_agent_id: ID
    $close_date: String
  ) {
    createPipelineCard(
      name: $name
      pipeline_stage_id: $pipeline_stage_id
      pipeline_id: $pipeline_id
      value: $value
      description: $description
      status: $status
      visibility: $visibility
      priority: $priority
      owner_id: $owner_id
      primary_agent_id: $primary_agent_id
      close_date: $close_date
    ) {
      id
      name
      status
      visibility
      priority
      primary_agent {
        id
        name
        has_user
        profile_picture
      }
      owner {
        id
        name
        has_user
        profile_picture
      }
      pipeline_stage {
        id
        __typename
      }
      pipeline {
        id
        __typename
      }
      inventory_users {
        id
        name
        has_user
        profile_picture
        __typename
      }
      value
      description
      close_date
    }
  }
`;

export const updatePipelineOrderMutation = gql `
  mutation updatePipelineOrder($id: ID!, $pipeline_cards: [PipelineCardInput!], $category_for_task_id: ID) {
    updatePipelineOrder(id: $id, pipeline_cards: $pipeline_cards, category_for_task_id: $category_for_task_id) {
      id
    }
  }
`;

export const createPipelineActivityMutation = gql `
  mutation createPipelineActivity(
    $note: String
    $pipeline_card_id: ID!
    $activity_type_id: ID!
    $activity_date: String
  ) {
    createPipelineActivity(
      note: $note
      pipeline_card_id: $pipeline_card_id
      activity_type_id: $activity_type_id
      activity_date: $activity_date
    ) {
      id
      activityDate
      activityType {
        id
        name
        iconUrl
        __typename
      }
      inventoryUser {
        id
        name
      }
      note
    }
  }
`;

export const updatePipelineActivityMutation = gql `
  mutation updatePipelineActivity($id: ID!, $note: String, $activity_date: String) {
    updatePipelineActivity(id: $id, note: $note, activity_date: $activity_date) {
      id
      activityDate
      activityType {
        id
        name
        iconUrl
      }
      note
      inventoryUser {
        id
        name
      }
    }
  }
`;

export const deletePipelineActivityMutation = gql `
  mutation deletePipelineActivity($id: ID!) {
    deletePipelineActivity(id: $id)
  }
`;

export const deletePipelineMutation = gql `
  mutation deletePipeline($id: ID!) {
    deletePipeline(id: $id)
  }
`;

export const printPipelineMutation = gql `
  mutation printPipeline($id: ID!) {
    printPipeline(id: $id)
  }
`;

export const deletePipelineStageMutation = gql `
  mutation deletePipelineStage($id: ID!) {
    deletePipelineStage(id: $id)
  }
`;

export const createTaskMutation = gql `
  mutation createTask(
    $name: String
    $description: String
    $pipeline_card_id: ID
    $activity_type_id: ID
    $inventory_user_id: ID
    $status: String
    $due_date: String
  ) {
    createTask(
      name: $name
      description: $description
      pipeline_card_id: $pipeline_card_id
      activity_type_id: $activity_type_id
      inventory_user_id: $inventory_user_id
      status: $status
      due_date: $due_date
    ) {
      id
    }
  }
`;