import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import MessageButton from '../../components/MessageButton/MessageButton';
import AddToRolodexButton from '../../components/AddToRolodexButton/AddToRolodexButton';

const SocialButtons = ({ displaySocialButtons, displayMessageButton, user, refetch }) => <div />;

SocialButtons.propTypes = {
  displaySocialButtons : PropTypes.bool,
  displayMessageButton : PropTypes.bool,
  isUserAuthorized     : PropTypes.bool,
  user                 : PropTypes.shape({
    user_name : PropTypes.string,
  }),
  refetch              : PropTypes.func,
};

SocialButtons.defaultProps = {
  displaySocialButtons : false,
  displayMessageButton : true,
  isUserAuthorized     : false,
  isPending            : false,
  refetch              : () => {},
};

export default SocialButtons;
