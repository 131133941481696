import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { withSnackbar } from 'notistack';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const SendMarketplaceInvite = gql`
  mutation sendMarketplaceInvite($id: ID!) {
    sendMarketplaceInvite(id: $id)
  }
`;

class InviteDialog extends React.Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      openSuccess : false,
    };
  }

  showSuccess = () => {
    const { closeSendInvite, enqueueSnackbar, inviteeName } = this.props;
    closeSendInvite();
    enqueueSnackbar(`Email sent to ${inviteeName}`, {
      variant      : 'success',
      anchorOrigin : {
        vertical   : 'top',
        horizontal : 'center',
      },
    });
  };

  sendMarketplaceInvite = inviteeId => {
    const { mutate, updateInventoryUser, updateAction, inventory_users } = this.props;
    const invitee = inventory_users.data.find(inv_user => String(inv_user.id) === inviteeId);
    mutate({
      variables : {
        id : inviteeId,
      },
    })
      .then(() => {
        updateInventoryUser({ ...invitee, marketplace_status: 'pending' });
        if (inventory_users.inventoryUser.user) {
          updateAction({ user: { ...invitee, marketplace_status: 'pending' } });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log(error);
      });
  };

  render = () => {
    const { openSuccess } = this.state;
    const {
      classes,
      intl,
      inviteDialogOpen,
      inviteeId,
      inviteeName,
      resend,
      inventory_users,
      closeSendInvite,
    } = this.props;

    return (
      <div>
        <Dialog open={inviteDialogOpen} onRequestClose={this.closeSendInvite}>
          <DialogTitle className={classes.text} />
          <DialogContent>
            <Grid className={classes.center} container spacing={16}>
              <Grid item xs={12}>
                <div className={classes.center}>
                  <i
                    style={{ fontSize: '100px', color: 'dodgerblue', marginTop: '5%' }}
                    className="material-icons md-36">
                    mail_outline
                  </i>
                  <br />
                </div>
                <div className={classes.iconBoxText}>
                  <FormattedMessage id="admin_inventory_user.invite_user" />
                </div>
                <br />
                <DialogContentText className={classes.text}>
                  {resend && (
                    <FormattedMessage
                      id="admin_inventory_user.resend_invite_this_user"
                      values={{ invitee: inviteeName }}
                    />
                  )}
                  {!resend && (
                    <FormattedMessage id="admin_inventory_user.invite_this_user" values={{ invitee: inviteeName }} />
                  )}
                  <br />
                  <br />
                  <div>
                    <Button
                      onClick={() => {
                        this.sendMarketplaceInvite(inviteeId);
                        this.showSuccess();
                      }}
                      variant="contained"
                      color="primary">
                      Invite
                    </Button>
                  </div>
                </DialogContentText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeSendInvite} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };
}

const styles = theme => ({
  root         : {
    minWidth  : '100%',
    marginTop : theme.spacing.unit * 3,
  },
  tableWrapper : {
    overflowX : 'auto',
  },
  right        : {
    textAlign : 'right',
  },
  title        : {
    fontSize : 24,
  },
  table        : {
    minWidth : 1020,
  },
  saveButton   : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
  },
  activated    : {
    color       : 'limeGreen',
    borderColor : 'limeGreen',
    width       : '100%',
  },
  joined       : {
    color       : 'blue',
    borderColor : 'blue',
    width       : '100%',
  },
  pending      : {
    color       : 'orange',
    borderColor : 'orange',
    width       : '100%',
  },
  invite       : {
    color       : 'grey',
    borderColor : 'grey',
    width       : '100%',
  },
  text         : {
    fontSize : '18px',
  },
  iconBox      : {
    border    : '1px solid #EAECEE',
    textAlign : 'center',
    height    : '250px',
    width     : '200px',
    margin    : '20px',
  },
  iconBoxText  : {
    fontSize   : '18px',
    textAlign  : 'center',
    fontWeight : 'bold',
    color      : 'dodgerblue',
  },
  center       : {
    textAlign : 'center',
  },
});

export default graphql(SendMarketplaceInvite)(injectIntl(withStyles(styles)(withSnackbar(InviteDialog))));
