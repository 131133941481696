import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { range } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

const YearsInBusinessDropDown = ({ year_career_began, updateAction, intl, years_practicing, imported_production_data_read_only }) => {
  const years = moment().diff(moment(`1/1/${year_career_began}`), 'y');
  return (
    <div>
      <b>
        <FormattedMessage id="sign_up_pages.how_many_years" />
      </b>
      <select
        className="form-control"
        autoFocus
        value={years_practicing}
        onChange={e =>
          updateAction({
            year_career_began : moment().subtract(e.target.value, 'y').year(),
            years_practicing  : ~~e.target.value,
          })}
        disabled={imported_production_data_read_only}
          >
        <option value={year_career_began} selected={year_career_began === 0} disabled hidden>
          <FormattedMessage id="sign_up_pages.year" />
        </option>
        <option value={0} selected={year_career_began > 0 && years === 0}>
          {intl.formatMessage({ id: 'sign_up_pages.less_than_one' })}
        </option>
        {range(1, 49, 1).map(y => (
          <option key={y} value={y} selected={years == y}>
            {y}
          </option>
        ))}
        <option value={50} selected={years > 49}>
          50+
        </option>
      </select>
    </div>
  );
};

YearsInBusinessDropDown.propTypes = {
  year_career_began : PropTypes.number,
  updateAction      : PropTypes.func.isRequired,
  intl              : PropTypes.object.isRequired,
};

YearsInBusinessDropDown.defaultProps = {
  year_career_began : 0,
};
export default injectIntl(YearsInBusinessDropDown);
