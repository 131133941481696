import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import numeral from 'numeral';
import moment from 'moment';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import WarningText from '../WarningText/WarningText';
import CurrencyInput from 'react-currency-input-field';

class HistoricalInfo extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      year_1_gross : props.currentUser.year_1_gross || 0,
      year_2_gross : props.currentUser.year_2_gross || 0,
      year_3_gross : props.currentUser.year_3_gross || 0,
      year_4_gross : props.currentUser.year_4_gross || 0,
      badValues    : false,
    };
  }

  handleChange = (val, s) => {
    const { updateState } = this.props;
    updateState({ [val]: s });
    this.setState({ [val]: s });
  };

  render () {
    const { classes, intl } = this.props;
    const { year_1_gross, year_2_gross, year_3_gross, year_4_gross, badValues } = this.state;
    const intlConfig = () => {
      if (intl.locale == 'fr') {
        return { locale: 'fr-CA', currency: 'CAD' };
      }
      return { locale: 'en-US', currency: 'USD' };
    };
    return (
      <Grid container>
        <Grid item xs={12}>
          <span className={classes.headline}>
            <FormattedMessage id="valuation_drawer.historical_revenue" />
          </span>
        </Grid>
        {badValues && (
          <div style={{ color: 'red' }}>
            <FormattedMessage id="valuation_drawer.please_adjust_values" />
          </div>
        )}
        <Grid item xs={12}>
          <div style={{ marginBottom: 25 }} />
          <span>
            {moment().year() - 1} <FormattedMessage id="profile_page.revenue" />{' '}
          </span>
          <Tooltip
            title={intl.formatMessage(
              { id: 'valuation_drawer.historical_revenue_tool_tip' },
              { year: moment().year() - 1 }
            )}
            placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={year_1_gross || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('year_1_gross', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText visible={year_1_gross > 10000000} message="Value must be under $10,000,000" />
          <CurrencyInput
            id="input-example"
            value={year_1_gross}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('year_1_gross', parseInt(value))}
          />
        </Grid>
        <Grid item xs={12}>
          <span>
            {moment().year() - 2} <FormattedMessage id="profile_page.revenue" />{' '}
          </span>
          <Tooltip
            title={intl.formatMessage(
              { id: 'valuation_drawer.historical_revenue_tool_tip' },
              { year: moment().year() - 2 }
            )}
            placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={year_2_gross || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('year_2_gross', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText visible={year_2_gross > 10000000} message="Value must be under $10,000,000" />
          <CurrencyInput
            id="input-example"
            value={year_2_gross}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('year_2_gross', parseInt(value))}
          />
        </Grid>
        <Grid item xs={12}>
          <span>
            {moment().year() - 3} <FormattedMessage id="profile_page.revenue" />{' '}
          </span>
          <Tooltip
            title={intl.formatMessage(
              { id: 'valuation_drawer.historical_revenue_tool_tip' },
              { year: moment().year() - 3 }
            )}
            placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={year_3_gross || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('year_3_gross', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText visible={year_3_gross > 10000000} message="Value must be under $10,000,000" />
          <CurrencyInput
            id="input-example"
            value={year_3_gross}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('year_3_gross', parseInt(value))}
          />
        </Grid>
        <Grid item xs={12}>
          <span>
            {moment().year() - 4} <FormattedMessage id="profile_page.revenue" />{' '}
          </span>
          <Tooltip
            title={intl.formatMessage(
              { id: 'valuation_drawer.historical_revenue_tool_tip' },
              { year: moment().year() - 4 }
            )}
            placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={10000000}
              minValue={0}
              value={year_4_gross || 0}
              formatLabel={value => ``}
              step={5000}
              onChange={e => {
                this.handleChange('year_4_gross', e);
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <WarningText visible={year_4_gross > 10000000} message="Value must be under $10,000,000" />
          <CurrencyInput
            id="input-example"
            value={year_4_gross}
            intlConfig={intlConfig()}
            allowDecimals={false}
            onValueChange={(value, name) => this.handleChange('year_4_gross', parseInt(value))}
          />
        </Grid>
      </Grid>
    );
  }
}

HistoricalInfo.propTypes = {
  updateState : PropTypes.func.isRequired,
  currentUser : PropTypes.object.isRequired,
};

const styles = theme => ({
  sliders  : {
    color : 'red',
  },
  cssLabel : {
    color : 'green',
  },
});

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
`;

export default withStyles(styles)(injectIntl(HistoricalInfo));
