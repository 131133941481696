import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

const RecommendationSection = ({ recommendation }) => {
  return (
    <div className="content-card" style={{ height: 'inherit' }}>
      <div id="property-detail">
        <h3><FormattedMessage id="profile_page.match_profile.reason_for_recommendation"/> </h3>
        <div className="property-title">{recommendation}</div>
      </div>
    </div>
  );
};

export default RecommendationSection;
