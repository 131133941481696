/* eslint-disable import/prefer-default-export */

import {
  SELECTED_NOTE_UPDATE,
  NOTE_LIST_UPDATE,
  NOTE_UPDATE,
  FILTER_UPDATE,
  NOTE_STATUS_UPDATE,
} from '../constants/noteConstants';

export const updateNotes = notes => ({
  type  : NOTE_LIST_UPDATE,
  notes,
});

export const updateNote = note => ({
  type : NOTE_UPDATE,
  note,
});

export const updateSelectedNote = selectedNote => ({
  type         : SELECTED_NOTE_UPDATE,
  selectedNote,
});

export const updateFilter = filter => ({
  type   : FILTER_UPDATE,
  filter,
});

export const updateStatus = status => ({
  type   : NOTE_STATUS_UPDATE,
  status,
});
