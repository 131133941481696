import React from 'react';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Query, ApolloProvider } from 'react-apollo';
import moment from 'moment';
import client from '../../lib/apolloClient';
import { isEmpty } from 'lodash';
import AnalyticsPipelineSummary from '../../components/Reports/AnalyticsPipelineSummary';

const PipelineReportQuery = gql`
  query pipeline($id: ID, $status: String, $agentName: String) {
    pipelines {
      id
      name
      pipeline_stages {
        id
      }
      pipeline_cards {
        id
      }
      card_count
    }
    pipeline(id: $id) {
      id
      name
      card_count
      pipeline_cards {
        id
      }
      pipeline_stages {
        id
        name
        card_count
        total_value
        pipeline_cards(status: $status, agentName: $agentName) {
          id
          value
          close_date
          status
          created_at
        }
      }
    }
  }
`;

export const AnalyticsPipelineSummaryReportContainer = () => {
  const startDate = moment().startOf('month').subtract(1, 'year');
  const endDate = moment().endOf('month').add(1, 'year');
  return (
    <div>
      <ApolloProvider client={client}>
        <Query query={PipelineReportQuery} variables={{ agentName: '', status: 'all' }}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <CircularProgress />;
            if (error) return `Error!: ${error}`;
            if (!loading && !isEmpty(data.pipelines)) {
              return <AnalyticsPipelineSummary data={data} refetch={refetch} startDate={startDate} endDate={endDate} />;
            }
            return <div>No Pipelines to show</div>;
          }}
        </Query>
      </ApolloProvider>
    </div>
  );
};

export default AnalyticsPipelineSummaryReportContainer;
