import { connect } from 'react-redux';
import { forEach } from 'lodash';
import ESignatureForm from '../components/ESignatureForm/ESignatureForm';
import * as actions from '../actions/companyActionCreators';

const generateCompanyPayload = company => {
  const payload = { company: {} };
  [
    'name',
    'host',
    'branded_name',
    'branded_name_fr',
    'display_name',
    'layout',
    'root_controller',
    'template',
    'contact_url',
    'help_url',
    'enterprise_redirect',
    'has_billing',
    'uses_enterprise_authorized_users',
    'mailchimp_list_id',
    'logout_destination',
    'administrator_email',
    'background_color',
    'intercom_token',
    'intercom_app_id',
    'intercom_api_key',
    'support_email',
    'go_live',
    'supported_locales',
    'business_type',
    'country',
    'agent_word',
    'agent_word_fr',
    'ats_email',
    'concierge_link',
    'total_licensed_users',
    'seller_only_label',
    'valuation_rot_recurring_multiple',
    'valuation_rot_non_recurring_multiple',
    'valuation_dcf_payout_rate',
    'valuation_dcf_discount_rate',
    'valuation_dcf_has_book_tenure_discount',
    'valuation_dcf_has_turn_rate_discount',
    'valuation_ebitda_multiple_level_1',
    'valuation_ebitda_multiple_level_2',
    'valuation_ebitda_multiple_level_3',
    'valuation_ebitda_multiple_level_4',
    'valuation_ebitda_multiple_level_5',
    'valuation_ebitda_multiple_level_6',
    'valuation_ebitda_multiple_level_7',
    'valuation_ebitda_multiple_level_8',
    'valuation_ebitda_multiple_level_9',
    'valuation_ebitda_multiple_level_10',
    'valuation_ebitda_multiple_level_11',
    'valuation_ebitda_multiple_level_12',
    'valuation_ebitda_multiple_level_13',
    'valuation_ebitda_multiple_level_14',
    'terminal_growth_rate_expansion_stage',
    'terminal_growth_rate_decelerated_stage',
    'terminal_growth_rate_mature_stage',
    'esig_client_id',
    'esig_api_key',
    'esig_test_mode',
    'esig_allow_decline',
    'esig_nda_template_id',
    'esig_docusign_jwt_integration_key',
    'esig_docusign_authorization_server',
    'esig_docusign_aud',
    'esig_docusign_impersonated_user_guid',
    'esig_docusign_target_account_id',
    'esig_docusign_secret_key',
    'esig_docusign_nda_template_id',
    'esig_docusign_application_ping_url',
    'company_valuation_disclaimer',
    'valuation_dcf_use_range',
    'valuation_dcf_range_low',
    'valuation_dcf_range_high',
    'valuation_rot_use_range',
    'valuation_rot_range_low',
    'valuation_rot_range_high',
    'valuation_ebitda_use_range',
    'valuation_ebitda_low',
    'valuation_ebitda_high',
    'id',
  ].map(name => (payload.company[name] = company[name]));

  const form_features = {};
  company.all_feature_types.map(feature => (form_features[feature.feature_type] = feature));

  company.features.map(feature => {
    if (feature.feature_type) {
      form_features[feature.feature_type].id = feature.id;
      if (!form_features[feature.feature_type].enabled) {
        form_features[feature.feature_type]._destroy = true;
      }
    }
  });

  payload.company.features_attributes = [];

  forEach(form_features, feature => {
    const obj = {};
    if (feature.id) obj.id = feature.id;
    if (feature._destroy) {
      if (feature.id) {
        obj._destroy = true;
        payload.company.features_attributes.push(obj);
      }
    } else {
      obj.feature_type = feature.enum_value;
      if (feature.enabled) payload.company.features_attributes.push(obj);
    }
  });

  return payload;
};

const mapStateToProps = state => ({
  company                : state.company,
  generateCompanyPayload,
});

export default connect(mapStateToProps, actions)(ESignatureForm);
