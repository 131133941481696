import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ProfileCardMessageButton from '../../BobCard/BobCardComponents/ProfileCardMessageButton';

const ProfileDrawerStatusButtons = ({
  scrollTo,
  user,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  updateNotes,
  updateProfile,
}) => (
  <div>
    <ProfileCardMessageButton
      scrollTo={scrollTo}
      user={user}
      currentUserHasLimitedAccess={currentUserHasLimitedAccess}
      hasInterestedUsers={hasInterestedUsers}
      updateBobCards={updateBobCards}
      loadBobCardList={loadBobCardList}
      currentCompanySellerWord={currentCompanySellerWord}
      currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
      getCurrentUser={getCurrentUser}
      disableMessageBtn={disableMessageBtn}
      marketplaceOnly={marketplaceOnly}
      updateNotes={updateNotes}
      updateProfile={updateProfile}
    />
  </div>
);

ProfileDrawerStatusButtons.propTypes = {
  user                                 : PropTypes.object.isRequired,
  scrollTo                             : PropTypes.string.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.bool.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.object.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
};

const styles = {
  button : {
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginLeft : 10,
    marginTop  : 5,
  },
};

export default withStyles(styles)(ProfileDrawerStatusButtons);
