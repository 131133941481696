import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import { Paper } from '@material-ui/core';
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const PracticeMetrics = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');

  const showBranchExpenses = get(company, 'features', []).includes('cashflow_branch_expenses');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');
  const showBranchRetention = get(company, 'features', []).includes('cash_flow_branch_retention');

  return (
    <React.Fragment>
      <div>
        <>
          <Grid container>
            <Grid item>
              <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.t_12" /></b>
              <span>
                <CurrencyInput
                  decimalsLimit={2}
                  style={{ width: 'inherit' }}
                  className={classes.currency}
                  value={query.sellerT12Revenue}
                  onValueChange={value => {
                    updateCfaQuery({ ...query, sellerT12Revenue: parseFloat(value) })
                  }}
                />
              </span>
            </Grid>
            <Grid item>
              <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.revenue_growth" /></b>
              <span>
                <FBPercentInput
                  className={classes.percentage}
                  value={query.sellerFiveYearGrowthRate}
                  onChange={val => {
                    updateCfaQuery({ ...query, sellerFiveYearGrowthRate: parseFloat(val.toFixed(2)) })
                  }}
                  lower={-100}
                />
              </span>
            </Grid>
            {range(0, parseInt(query.numberOfBuyers, 10), 1).map(i => <React.Fragment>
              {showOrdinaryTaxes && <React.Fragment>
                <Grid item xs={12}></Grid>
                <Grid item>
                  <b className={classes.sub_heading}>Buyer {i + 1} T12</b>
                  <CurrencyInput
                    decimalsLimit={2}
                    className={classes.currency}
                    value={query[`buyer${i + 1}T12Revenue`]}
                    onValueChange={value => {
                      updateCfaQuery({ ...query, [`buyer${i + 1}T12Revenue`]: parseFloat(value) })
                    }}
                  />
                </Grid>
                <Grid item>
                  <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.revenue_growth" /></b>
                  <FBPercentInput
                    className={classes.percentage}
                    value={query[`buyer${i + 1}FiveYearGrowthRate`]}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${i + 1}FiveYearGrowthRate`]: parseFloat(val.toFixed(2)) })
                    }}
                    lower={-100}
                  />
                </Grid>
              </React.Fragment>}
              {showBranchExpenses && <>
                <Grid item>
                  <b className={classes.sub_heading}>Annual Expenses</b>

                  <CurrencyInput
                    decimalsLimit={2}
                    className={classes.currency}
                    value={query[`buyer${i + 1}AnnualExpenses`]}
                    onValueChange={value => {
                      updateCfaQuery({ ...query, [`buyer${i + 1}AnnualExpenses`]: parseFloat(value), })
                    }}
                  />

                </Grid>
                <Grid item>
                  <b className={classes.sub_heading}>Expense Growth</b>
                  <FBPercentInput
                    className={classes.percentage}
                    value={query[`buyer${i + 1}ExpenseGrowth`]}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${i + 1}ExpenseGrowth`]: val })
                    }}
                  />
                </Grid>
                {query.numberOfBuyers > 1 && <Grid item>
                  <b className={classes.sub_heading}>Buyer {i + 1} <FormattedMessage id="calculators.cfa.percent_purchasing" /></b>
                  <FBPercentInput
                    type="text"
                    className={classes.percentage}
                    value={query[`buyer${i + 1}PercentagePurchasing`].toFixed(2)}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${i + 1}PercentagePurchasing`]: val })
                    }}
                  />
                </Grid>
                }

              </>}
            </React.Fragment>)}
            {showBranchRetention && <Grid item>
              &nbsp;
              <b className={classes.sub_heading}>BD Retention</b>
              <FBPercentInput
                type="text"
                className={classes.percentage}
                value={query[`bdRetentionRate`].toFixed(2)}
                onChange={val => {
                  updateCfaQuery({ ...query, bdRetentionRate: val })
                }}
              />
            </Grid>}


          </Grid>

        </>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(PracticeMetrics));