import React from 'react';
import PropTypes from 'prop-types';
import { get, filter } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import numeral from 'numeral';
import moment from 'moment';
import { VictoryBar, VictoryChart, VictoryTheme, VictoryAxis, VictoryLabel } from 'victory';

class AnalyticsPipelineSummary extends React.Component {
  state = { yAxis: 'value', status: 'open', chartWidth: 0 };

  componentDidMount () {
    this.setState({
      chartWidth : window.innerWidth,
    });
    window.addEventListener('resize', this.updateDimensions.bind(this));
    // remove this on unmount
  }

  formatChartData = data => {
    return data.map(s => ({
      name  : s.name,
      value : get(s, 'pipeline_cards', []).reduce((d, c) => d + parseInt(c.value, 10), 0),
      count : get(s, 'pipeline_cards', []).length,
    }));
  };

  updateDimensions = event => {
    this.setState({
      chartWidth : event.target.innerWidth,
    });
  };

  shortenString = (string, limit, usage) => {
    if (string.length >= limit) {
      if (usage === 'x') {
        const newString = string.substring(0, 15);
        return `${newString}...`;
      }
      if (usage === 'dropDown') {
        const newString = string.substring(0, 23);
        return `${newString}...`;
      }
    }
    return string;
  };

  render () {
    const { classes, startDate, endDate, refetch } = this.props;
    const { yAxis, status, chartWidth } = this.state;
    const pipelines = get(this.props, 'data.pipelines', []);
    const pipeline = get(this.props, 'data.pipeline', {});
    const all_stages = get(pipeline, 'pipeline_stages', []);

    const stages = all_stages.map(s => {
      if (startDate && endDate) {
        return {
          ...s,
          pipeline_cards : filter(
            s.pipeline_cards,
            c =>
              moment(c.close_date).isAfter(startDate, 'YYYY-MM-DD') &&
              moment(c.close_date).isBefore(endDate, 'YYYY-MM-DD') &&
              c.status === status
          ),
        };
      }
      return s;
    });

    const totalValue = stages.reduce(
      (sum, s) => sum + get(s, 'pipeline_cards', []).reduce((d, c) => d + parseInt(c.value, 10), 0),
      0
    );
    const totalCases = stages.reduce((sum, s) => sum + get(s, 'pipeline_cards.length', 0), 0);
    return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <span className={classes.date}>
              {`${moment(startDate).format('MMM DD, YYYY')} - ${moment(endDate).format('MMM DD, YYYY')}`}
            </span>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={1}>
            <Grid item xs={12}>
              <span className={classes.numberLabel}>Total Value </span>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.bigNumber}>{numeral(totalValue).format('$0a')}</div>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid item xs={12}>
              <span className={classes.numberLabel}>Cases</span>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.bigNumber}>{totalCases}</div>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Select
              classes={{ select: classes.selectatron }}
              value={pipeline.id}
              onClick={e => {
                if (e.target.value) {
                  refetch({ id: e.target.value });
                }
              }}>
              {pipelines.map(p => (
                <MenuItem value={p.id}>{this.shortenString(decodeURI(p.name), 23, 'dropDown')}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={2} alignItems="right">
            <a href={`/admin/reports/pipeline_summary?pipeline_id=${pipeline.id}`}>
              <button type="button" className={classes.button}>
                <span className={classes.viewLink}>View Report</span>
              </button>
            </a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <VictoryChart
              width={chartWidth / 2.4}
              height={210}
              domainPadding={[ 30, 30 ]}
              padding={{ bottom: 80, left: 80, top: 12, right: 40 }}
              theme={VictoryTheme.material}>
              <VictoryAxis
                dependentAxis
                tickFormat={y =>
                  (yAxis === 'value' ? numeral(y).format('$0,') : y) === '$NaN' ? '$0' : numeral(y).format('$0,a')}
                orientation="left"
              />
              <VictoryAxis
                dependentAxis={false}
                tickFormat={x => this.shortenString(decodeURI(x), 15, 'x')}
                orientation="bottom"
                tickLabelComponent={<VictoryLabel angle={10} textAnchor="start" />}
                style={{
                  tickLabels : { fontSize: 10 },
                }}
              />
              <VictoryBar
                width={600}
                height={210}
                data={this.formatChartData(stages)}
                x="name"
                y={yAxis}
                align=""
                style={{
                  data : { fill: '#2793FF', stroke: '#006BDB', strokeWidth: 2 },
                }}
              />
            </VictoryChart>
          </Grid>
        </Grid>
      </div>
    );
  }
}

AnalyticsPipelineSummary.propTypes = {
  startDate : PropTypes.string.isRequired,
  endDate   : PropTypes.string.isRequired,
  classes   : PropTypes.object.isRequired,
  refetch   : PropTypes.func.isRequired,
};

const styles = {
  root        : {
    width     : '100%',
    overflowX : 'auto',
  },
  table       : {
    minWidth : 650,
  },
  button      : {
    textDecoration : 'underline',
    border         : 'none',
    background     : 'none',
    fontSize       : 18,
  },
  bigNumber   : { fontSize: 25 },
  subHeader   : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel : { fontSize: 16 },
  selectatron : {},
  viewLink    : {
    textAlign      : 'left',
    color          : '#0052CC',
    fontSize       : '0.7em',
    textDecoration : 'underline',
    fontWeight     : 'bold',
  },
  date        : {
    fontSize : '0.9em',
    color    : '#808080',
  },
};
export default withStyles(styles)(AnalyticsPipelineSummary);
