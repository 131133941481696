import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const LumpSumPayment = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');

  return (
    <React.Fragment>
      <div>
        <>
          <Grid container>
            {query.buyerHasLumpSumPayment && <React.Fragment>
              <div className={classes.header}>Down Payment</div>
              <Grid item xs={12}></Grid>
              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.lump_sum" /></b>

                <CurrencyInput
                  className={classes.currency}
                  value={(query.buyerLumpSumAmount || 0)}
                  decimalsLimit={2}
                  onValueChange={value => {
                    const val = parseFloat(value);
                    const buyersFinancingAmount = clamp(query.buyersFinancingAmount, 0, val);

                    updateCfaQuery({ ...query, buyersFinancingAmount, buyerLumpSumAmount: val })
                  }}
                />
              </Grid>
              <Grid item>
                <b className={classes.sub_heading}>Year</b>

                <select value={query.buyerLumpSumYear} onChange={e => {
                  updateCfaQuery({ ...query, buyerLumpSumYear: parseInt(e.target.value) })
                }
                }>
                  <option>Please Select a Year</option>
                  {range(1, 11, 1).map(year =>
                    <option value={year} checked={query.buyerLumpSumYear == year}>{year}</option>
                  )}
                </select>
              </Grid>
              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.amount_financed" /></b>

                <CurrencyInput
                  className={classes.currency}
                  value={query.buyersFinancingAmount}
                  decimalsLimit={2}
                  onValueChange={value => {
                    let val = parseFloat(value);
                    val = clamp(val, 0, query.buyerLumpSumAmount);

                    updateCfaQuery({ ...query, buyersFinancingAmount: val })
                  }} />

              </Grid>
              <Grid item>
                <b className={classes.sub_heading}>Financing <FormattedMessage id="calculators.cfa.term" /></b>

                <select value={query.buyerFinancingYears} onChange={e => {
                  updateCfaQuery({ ...query, buyerFinancingYears: parseInt(e.target.value) })
                }
                }>
                  <option>Please Select a Year</option>
                  {range(1, query.cashFlowLength + 1, 1).map(year =>
                    <option value={year} checked={query.buyerFinancingYears == year}>{year}</option>
                  )}
                </select>
              </Grid>
              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.financing_interest_rate" /></b>

                <FBPercentInput
                  className={classes.percentage}
                  value={query.cashFlowFinancingInterestRate}
                  onChange={val => {
                    updateCfaQuery({ ...query, cashFlowFinancingInterestRate: val })
                  }}
                />

              </Grid>



            </React.Fragment>}
          </Grid>
        </>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(LumpSumPayment));