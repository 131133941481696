import { connect } from 'react-redux';
import Designation from '../components/Designation/Designation';
import NewFormDialog from '../components/Designation/DesignationNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/designationsActionCreators';
import { capitalize, get } from 'lodash';

const mapStateToProps = state => {
  const orderedDesignations = {
    ...state.designations,
    data : get(state, 'designations.data', {}).sort(
      (a, b) => (capitalize(a.name) > capitalize(b.name) ? 1 : capitalize(b.name) > capitalize(a.name) ? -1 : 0)
    ),
  };
  return {
    ...state,
    listData      : orderedDesignations,
    title         : state.intl.messages['admin_designations.designations'],
    NewFormDialog,
    CrudRow       : Designation,
  };
};

export default connect(mapStateToProps, actions)(CrudTable);
