import axios from 'axios';
import ReactOnRails from 'react-on-rails';

const config = () => ({
  dataType: "json",
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    'Accept': 'application/json',
    'X-CSRF-Token': ReactOnRails.authenticityToken(),
  }
});

export const put = (location, data) => {
  return axios.put(location, data, config());
};

export const post = (location, data) => {
  return axios.post(location, data, config());
};

export const get = (location) => {
  return axios.get(location, config());
};

export const del = (location) => {
  return axios.delete(location, config());
};
