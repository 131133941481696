import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { FormattedMessage } from 'react-intl';

class DeleteFileDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { deleteAction, classes } = this.props;
    return (
      <div>
        <IconButton className={classes.deleteButton} onClick={this.handleClickOpen}>
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{<FormattedMessage id="file_tab.delete_file" />}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <FormattedMessage id="file_tab.are_you_sure" />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              onClick={() => {
                deleteAction();
                this.handleClose();
              }}
              color="primary"
              autoFocus>
                <FormattedMessage id="file_tab.delete" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = {
  deleteButton : {
    float       : 'right',
    marginRight : 4,
    flex        : 1,
  },
};

export default withStyles(styles)(DeleteFileDialog);
