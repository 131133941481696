import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { get, isEmpty } from 'lodash';
import CfaIndex from '../components/calculators/CfaIndex';
import * as cfaActionCreators from '../actions/cfaActionCreators';
import { updateCompany } from '../actions/companyActionCreators';
import { CircularProgress } from '@material-ui/core';
import qs from 'query-string';

const GetCashFlow = gql`
	query cashFlows {
		company {
			id
			host
			cash_flow_analysis_word
			cash_flow_analysis_word_fr
		}
		cashFlows{
			id
			name
			createdAt
			cashFlowLength
			cashFlowProduction
			cashFlowProductionShift  # Add This to edit Production Shifts In Year cant be greater than length
			sellerT12Revenue
			sellerFiveYearGrowthRate
			numberOfBuyers
			buyer1T12Revenue
			buyer1FiveYearGrowthRate
			buyer1PercentagePurchasing
			buyer2T12Revenue
			buyer2FiveYearGrowthRate
			buyer2PercentagePurchasing
			buyerHasLumpSumPayment
			buyerLumpSumAmount
			buyerLumpSumYear
			buyersFinancingAmount
			buyerHasFixedPayments
			buyerFixedPaymentYears
			buyerFixedPaymentAmount
			buyerFixedPaymentStartYear
			buyerHasContinuingPayments
			buyerContinuingPaymentYears
			buyerContinuingPaymentStartYear
			buyerContinuingPaymentAmount
			sellerAverageCashFlow
			buyer1AverageCashFlow
			buyer2AverageCashFlow
			friendlyName
		}
	}
`;

const CfaIndexContainer = ({query, updateCfaResults, updateCfaQuery, updateCompany})=> {
	return (<Query  query={GetCashFlow}
									variables={query}
									onCompleted={data => {
										updateCfaResults(data);
										updateCfaQuery({...query, updating: false});
										updateCompany({cash_flow_analysis_word: data.company.cash_flow_analysis_word, cash_flow_analysis_word_fr: data.company.cash_flow_analysis_word_fr});

									}}>{ ({loading}) =>	(loading ? <CircularProgress /> : <CfaIndex />)
									}
			
		</Query>);
};

const mapStateToProps = (state, props) => {
  return {
    result: {...get(state, 'cfa.result', {})},
	query: {...get(state, 'cfa.query', {})},
  };
};

export default connect(mapStateToProps, { ...cfaActionCreators, updateCompany})(
  CfaIndexContainer
);