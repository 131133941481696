import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';
import PeValuationUpdateContainer from '../../containers/PeValuationUpdateContainer';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import withWidth from '@material-ui/core/withWidth';
import { get, debounce } from 'lodash';
import { initX } from '../../lib/findBobUtils';

const DRAWER_WIDTH = 470;

const styles = theme => ({
  drawerPaper           : {
    width      : DRAWER_WIDTH,
    background : '#f3f3f3',
    boxShadow  : '-2px 3px 2px rgba(0, 0, 0, 0.2)',
  },
  drawerPaperMobile     : {
    width      : '100%',
    background : '#f3f3f3',
    boxShadow  : '-2px 3px 2px rgba(0, 0, 0, 0.2)',
  },
  content               : {
    flexGrow        : 1,
    backgroundColor : theme.palette.background.default,
    padding         : theme.spacing.unit * 3,
    minWidth        : 0, // So the Typography noWrap works
  },
  toolbar               : theme.mixins.toolbar,
  noteBookButtonWrapper : {
    position        : 'fixed',
    transform       : 'rotate(-90deg)',
    top             : 'calc(50% - 100px)',
    backgroundColor : theme.palette.primary.main,
    borderColor     : theme.palette.primary.main,
    zIndex          : '9999999',
    borderRadius    : '2px',
    boxShadow       : '-2px 2px 0px rgba(0, 0, 0, 0.33)',
  },
  noteBookButton        : {
    backgroundColor : theme.palette.primary.main,
    borderColor     : theme.palette.primary.main,
    color           : '#fff',
    padding         : '10px',
    fontSize        : '18px',
    borderRadius    : '2px',
    fontWeight      : 'lighter',
    width           : '160px',
  },
  userName              : {
    color        : '#e1e1e1',
    marginTop    : '-20px',
    marginBottom : 0,
    paddingLeft  : '6px',
  },
  bookTitle             : {
    padding : '6px',
    color   : '#fff',
  },
  cardList              : {
    overflowY     : 'scroll',
    background    : '#f3f3f3',
    paddingBottom : '50%',
  },
  header                : {
    boxShadow       : '0px 2px 1px 0px rgba(0,0,0,0.33)',
    backgroundColor : theme.palette.primary.main,
    padding         : '6px',
    fontSize        : '28px',
    fontWeight      : 'lighter',
    border          : 0,
    color           : '#fff',
    marginBottom    : 20,
    textAlign       : 'center',
  },
  sliderContent         : {
    padding      : 35,
    marginBottom : 60,
  },
  updateButton          : {
    position        : 'fixed',
    bottom          : 0,
    textAlign       : 'center',
    paddingBottom   : 10,
    borderTop       : '1px #D5D8DC solid',
    backgroundColor : '#fff',
    width           : DRAWER_WIDTH,
  },
  updateButtonMobile    : {
    position        : 'fixed',
    left            : 0,
    bottom          : 0,
    textAlign       : 'center',
    paddingBottom   : 10,
    borderTop       : '1px #D5D8DC solid',
    backgroundColor : '#fff',
    width           : '100%',
  },
});

class EditPeValuationDrawer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      opened                           : false,
      fee_based_revenue                : props.user.fee_based_revenue || 0,
      trailer_revenue                  : props.user.trailer_revenue || 0,
      direct_revenue                   : props.user.direct_revenue || 0,
      transactional_revenue            : props.user.transactional_revenue || 0,
      gross_expenses                   : props.user.gross_expenses || 0,
      branch_net_payout                : props.user.branch_net_payout * 100 || 0,
      expense_growth_rate              : props.user.expense_growth_rate * 100 || 0,
      use_benchmark_expense_percentage : props.user.use_benchmark_expense_percentage || 0,
      benchmark_expenses               : props.user.benchmark_expenses || 0,
      year_1_gross                     : props.user.year_1_gross || 0,
      year_2_gross                     : props.user.year_2_gross || 0,
      year_3_gross                     : props.user.year_3_gross || 0,
      year_4_gross                     : props.user.year_4_gross || 0,
      year_5_gross                     : props.user.year_5_gross || 0,
      revenue_growth_rate              : props.user.revenue_growth_rate * 100 || 0,
      use_revenue_growth_rate          : props.user.use_revenue_growth_rate || 0,
    };
  }

  toggleOpen = () => {
    const { opened } = this.state;
    this.setState({ opened: !opened });
  };

  handleParams = params => {
    if (params.anchor === 'notebook') {
      setTimeout(() => {
        this.setState({ opened: true });
        window.history.replaceState({}, window.history.title, `/dashboard/index`);
      }, 1000);
    }
  };

  reloadWindow = debounce(() => {
    window.location.reload(true);
  }, 2000);

  updatePePracticeInfo = (
    gross_expenses,
    branch_net_payout,
    expense_growth_rate,
    use_benchmark_expense_percentage,
    benchmark_expenses
  ) => {
    this.setState({
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses,
    });
  };

  updatePeProductSummary = (fee_based_revenue, trailer_revenue, direct_revenue, transactional_revenue) => {
    this.setState({
      fee_based_revenue,
      trailer_revenue,
      direct_revenue,
      transactional_revenue,
    });
  };

  updateGrossProduction = (
    year_1_gross,
    year_2_gross,
    year_3_gross,
    year_4_gross,
    year_5_gross,
    revenue_growth_rate,
    use_revenue_growth_rate
  ) => {
    this.setState({
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate,
    });
  };

  recalculate = () => {
    const {
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses,
      fee_based_revenue,
      trailer_revenue,
      direct_revenue,
      transactional_revenue,
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate,
    } = this.state;
    const params = qs.parse(location.search);
    const { inv_id, user_name } = params;
    const inv_param = `&inv_id=${inv_id}`;
    const user_param = `&user_name=${user_name}`;
    let url = `/valuations/dcf?branch_net_payout=${branch_net_payout}&fee_based_revenue=${fee_based_revenue}&trailer_revenue=${trailer_revenue}&transactional_revenue=${transactional_revenue}&direct_revenue=${direct_revenue}&year_1_gross=${year_1_gross}&year_2_gross=${year_2_gross}&year_3_gross=${year_3_gross}&&year_4_gross=${year_4_gross}&year_5_gross=${year_5_gross}&use_benchmark_expense_percentage=${use_benchmark_expense_percentage}&gross_expenses=${gross_expenses}&expense_growth_rate=${expense_growth_rate}&use_revenue_growth_rate=${use_revenue_growth_rate}&revenue_growth_rate=${revenue_growth_rate}${inv_id
        ? inv_param
        : ''}${user_name ? user_param : ''}`
    if(get(this, 'props.user.dcf_id')) {
      url = url += `&dcf_id=${this.props.user.dcf_id}`;
    }
    window.open(url, '_self');
  };

  reset = () => {
    const params = qs.parse(location.search);
    const { inv_id, user_name } = params;
    const inv_param = `?inv_id=${inv_id}`;
    const user_param = `?user_name=${user_name}`;
    window.open(`/valuations/dcf${inv_id ? inv_param : ''}${user_name ? user_param : ''}`, '_self');
  };

  render = () => {
    const { user, classes, hidePracticeInfo, profileView, width } = this.props;
    const { opened } = this.state;
    const params = qs.parse(location.search);
    if (params) {
      this.handleParams(params);
    }
    initX();

    document.x.registerListener(val => {
      if (val === 'openValDrawer') {
        this.setState({ opened: !opened });
      }
    });
    const mobile = width === 'sm' || width === 'xs';
    return (
      <div>
        {opened && (
          <Drawer
            variant="persistent"
            open={opened}
            anchor="right"
            onClose={this.toggleOpen}
            classes={
              width === 'sm' || width === 'xs' ? (
                {
                  paper : classes.drawerPaperMobile,
                }
              ) : (
                {
                  paper : classes.drawerPaper,
                }
              )
            }>
            <div>
              <header className={`${classes.header} pe-header`}>
                <p className={classes.bookTitle}>
                  <FormattedMessage id="valuation_drawer.valuation_inputs" />
                </p>
              </header>
              <div className={classes.sliderContent}>
                <Grid container spacing={1}>
                  <PeValuationUpdateContainer
                    showRevSliders
                    hidePullLatestButton
                    updatePePracticeInfo={this.updatePePracticeInfo}
                    updatePeProductSummary={this.updatePeProductSummary}
                    updateGrossProduction={this.updateGrossProduction}
                    user={user}
                  />
                </Grid>
              </div>
              {width !== 'sm' &&
                (width !== 'xs' && (
                  <div className={classes.updateButton}>
                    <Grid container style={{ textAlign: 'center' }}>
                      <Grid xs={6}>
                        <Button
                          onClick={() => this.reset()}
                          class="btn btn-default"
                          type="button"
                          style={{ marginTop: 10, width: '90%', background: '#E5E8E8', color: '#002949' }}>
                          <FormattedMessage id="valuation_drawer.reset" />
                        </Button>
                      </Grid>
                      <Grid xs={6}>
                        <Button
                          onClick={() => this.recalculate()}
                          color="primary"
                          class="btn btn-default"
                          type="button"
                          style={{ marginTop: 10, width: '90%' }}>
                          <FormattedMessage id="valuation_drawer.recalculate" />
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {mobile && (
                <div className={classes.updateButtonMobile}>
                  <Grid container>
                    <hr />
                    <Grid xs={6}>
                      <Button
                        onClick={() => this.reset()}
                        class="btn btn-default"
                        type="button"
                        style={{ marginTop: 10, width: '90%', background: '#E5E8E8', color: '#002949' }}>
                        <FormattedMessage id="valuation_drawer.reset" />
                      </Button>
                    </Grid>
                    <Grid xs={6}>
                      <div>
                        <Button
                          onClick={() => this.recalculate()}
                          color="primary"
                          class="btn btn-default"
                          type="button"
                          style={{ marginTop: 10, width: '90%' }}>
                          <FormattedMessage id="valuation_drawer.recalculate" />
                        </Button>
                      </div>
                    </Grid>
                    <Grid xs={12} style={{ margin: 'auto' }}>
                      <div>
                        <Button
                          onClick={() => this.toggleOpen()}
                          class="btn btn-default"
                          type="button"
                          style={{ marginTop: 10, width: '95%', background: '#E5E8E8', color: '#002949' }}>
                          <FormattedMessage id="valuation_drawer.reset" />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </Drawer>
        )}
        <div
          className={classes.noteBookButtonWrapper}
          style={{
            right   : this.state.opened ? '418px' : '-56px',
            display : mobile && this.state.opened ? 'none' : 'block',
          }}
          onClick={this.toggleOpen}>
          <button className={`${classes.noteBookButton} notebook-tip`}>
            <span>
              {this.state.opened ? (
                <FormattedMessage id="valuation_drawer.close" />
              ) : (
                <FormattedMessage id="valuation_drawer.valuation_inputs" />
              )}
            </span>
          </button>
        </div>
      </div>
    );
  };
}

EditPeValuationDrawer.propTypes = {
  user    : PropTypes.object.isRequired,
  opened  : PropTypes.bool,
  classes : PropTypes.object.isRequired,
};

EditPeValuationDrawer.defaultProps = {
  opened : false,
};

const mapStateToProps = state => ({
  user : state.user,
});

export default connect(mapStateToProps)(withWidth()(withStyles(styles)(EditPeValuationDrawer)));
