import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import numeral from 'numeral';
import CurrencyInput from '../FBCurrencyInput/FBCurrencyInput';
import TextField from '@material-ui/core/TextField';
import FBPercentInput from '../FBPercentInput/FBPercentInput';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';

const EditSellerNote = ({ classes, query, updateCfaQuery, showSellerNote }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      {Boolean(showSellerNote && query.purchasePrice && (query.hasSellerNote || query.sellerNoteAmount > 0) ) && (
        <React.Fragment>
          <><div className={classes.header}> Seller Note </div>

              
              <br />
              <Grid container>
                <Grid item>
                  <b className={classes.sub_heading}>
                    Seller Note Amount
                  </b>

                  <CurrencyInput
                    className={classes.currency}
                    style={{ width: 'inherit' }}
                    value={query.sellerNoteAmount}
                    onValueChange={(value) => {
                      updateCfaQuery({ ...query, sellerNoteAmount: parseFloat(value) });
                    }}
                  />

                </Grid>
                &nbsp;
                <Grid item>
                  <b className={classes.sub_heading}>% of Deal</b>

                  <TextField className={classes.percentage} value={numeral(query.sellerNoteAmount / query.purchasePrice).format('0.00%')} />

                </Grid>
                &nbsp;
                <Grid item>
                  <b className={classes.sub_heading}>
                    Seller Note Interest Rate
                  </b>

                  <FBPercentInput
                    className={classes.percentage}
                    value={query.sellerNoteInterestRate}
                    onChange={(val) => {
                      updateCfaQuery({ ...query, sellerNoteInterestRate: val });
                    }}
                  />

                </Grid>
                &nbsp;
                <Grid item>
                  <b className={classes.sub_heading}>
                    Seller Note Term
                  </b>

                  <select
                    value={query.sellerNoteTerm}
                    onChange={(e) => {
                      updateCfaQuery({ ...query, sellerNoteTerm: parseInt(e.target.value) });
                    }}
                  >
                    <option>Please Select a Year</option>
                    {Array.from({ length: query.cashFlowLength }, (_, i) => i + 1).map((year) => (
                      <option value={year} checked={query.sellerNoteTerm == year}>
                        {year}
                      </option>
                    ))}
                  </select>

                </Grid>
              </Grid>
          </>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditSellerNote;