import { createMuiTheme } from '@material-ui/core/styles';

export const demoTheme = {
    palette: {
        type: 'light',
        primary: {
            main: '#004b4b',
        },
        secondary: {
            main: '#2f2e2e',
        },
        accent: {
            main: '#666666',
        },
        highlight: {
            main: '#009192',
        },
        graph: ['#64A70B', '#f36621', '#1396e2', '#666666', '#FFC72C', '#ec008c', '#68217a', '#00bcf2', '#00b294', '#00188f', '#ff8c00'],
    },
    props: {
        MuiButton: {
            size: 'small',
            color: 'primary',
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
        MuiInputSlider: {
            color: 'secondary'
        },
        InputSlider: {
            color: 'secondary'
        },
    },
};

export default demoTheme;