import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import {MenuItem} from "@material-ui/core";

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
    display  : 'inline-flex',
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin   : theme.spacing.unit,
    fontSize : '24px',
  },
});

const validateEmailString = (email, allowEmpty = false) => {
  if (allowEmpty) {
    return !email || EmailValidator.validate(email);
  }
  return !EmailValidator.validate(email);
};

const updateField = (data, action, event) => {
  data[event.target.id] = event.target.value;
  action(data);
};

const MODELS = {
    inventory_user: [],
    user: ['number_of_buyers', 
      'number_of_sellers',
      'number_of_buyers_this_week',
      'number_of_sellers_this_week',
      'total_seller_aum',
      'avg_seller_aum',
      'total_buyer_aum',
      'avg_buyer_aum',
      'avg_seller_value',
      'avg_buyer_value',
    ],
    deal_interest: ['total_matches_presented',
    ],
  };

const Ticker = ({ object, action, deleteAction, updateAction, classes, intl }) => {
  const modelOptions = Object.keys(MODELS).map(v => ({value: v, label: v}));
  const resolverOptions = MODELS[object.model].map(v => ({value: v, label: v}));
  return (
    <TableRow>
      <TableCell>
        <ToggleButton
          id="enabled"
          label="Enabled"
          checked={object.enabled}
          onChange={() => {
            object.enabled = !object.enabled;
            action(object);
            object.modified = true;
          }}
        />
      </TableCell>
      <TableCell>
        <SimpleInputField
          id="name"
          required
          label={intl.formatMessage({ id: 'shared.name' })}
          data={object}
          action={action}
          required
        />
      </TableCell>
      <TableCell>
        <SimpleInputField
          id="label"
          required
          label="Label"
          data={object}
          action={action}
          required
        />
      </TableCell>
      <TableCell>
        <SimpleInputField
          id="french_label"
          required
          label="French Label"
          data={object}
          action={action}
          required
        />
      </TableCell>
      <TableCell>
        Color:<br/>
        <input type={'color'} value={object.color} onChange={e => {
          object.color = e.target.value;
          action(object);
          object.modified = true;
        }}
        />
      </TableCell>
      <TableCell style={{width: 150,}}>
        <Select value={{value: object.model, label: object.model}}
                options={modelOptions}
                onChange={e => {
                  console.log(object);
                  action({model: e.value});
                  object.model = e.value;
                  object.modified= true;
                }}
                 />
      </TableCell>
      <TableCell style={{width: 200,}}>
        <Select value={{value: object.resolver, label: object.resolver}}
                options={resolverOptions}
                onChange={e => {
                  action({resolver: e.value});
                  object.resolver = e.value;
                  object.modified= true;
                }} 
                />

      </ TableCell>
      <TableCell>
        <SimpleInputField
          id="format"
          required
          label="Format"
          data={object}
          action={action}
          required
        />

      </TableCell>
      <TableCell>
        <SimpleInputField
          id="french_format"
          required
          label="French Format"
          data={object}
          action={action}
          required
        />

      </TableCell>
      <TableCell>
        <ToggleButton
          id="arrow"
          label="Show Arrow"
          checked={object.arrow}
          onChange={() => {
            object.arrow = !object.arrow;
            action(object);
            object.modified = true;
          }}
        />
      </TableCell>

      <TableCell>
        <IconButton
          color="primary"
          className={classes.button}
          aria-label="Save"
          disabled={!object.name || !object.modified}
          onClick={() => {
            updateAction(object);
          }}>
          <Icon>save</Icon>
        </IconButton>
        <IconButton className={classes.button} aria-label="Delete">
          <DeleteIcon
            onClick={() => {
              deleteAction(object);
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

const TickerWithStyles = withStyles(styles)(Ticker);
export default injectIntl(TickerWithStyles);
