import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import UserApplication from './UserApplication';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

const styles = {
  paper  : {
    padding   : 12,
    marginTop : 12,
  },
  appRow : {
    cursor : 'pointer',
  },
};

const statusToString = status => {
  switch (status) {
    case 0:
      return 'OPEN';
    case 1:
      return 'PENDING';
    case 2:
      return 'FORWARDED';
    case 3:
    default:
      return 'REJECTED';
  }
};

class OpportunityApplications extends PureComponent {
  state = { application: {} };

  render () {
    const { classes, applications } = this.props;
    const { application } = this.state;

    return (
      <Paper className={classes.paper}>
        <h3>Applications</h3>
        <Table>
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell>Region</TableCell>
            <TableCell>Age</TableCell>
            <TableCell>Years In Business</TableCell>
            <TableCell>Status</TableCell>
          </TableHead>
          <TableBody>
            {applications.map(a => (
              <TableRow className={classes.appRow} onClick={() => this.setState({ application: a })}>
                <TableCell>{a.user.name}</TableCell>
                <TableCell>{a.user.province}</TableCell>
                <TableCell>{moment().diff(moment(a.user.birth_date), 'years')}</TableCell>
                <TableCell>{a.user.years_practicing || 0}</TableCell>
                <TableCell>
                  <Tooltip title={a.reason} placement="top" interactive>
                    <span>{statusToString(a.status)}</span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <UserApplication application={application} close={() => this.setState({ application: null })} />
      </Paper>
    );
  }
}

OpportunityApplications.propTypes = {
  classes      : PropTypes.object.isRequired,
  applications : PropTypes.array,
};

OpportunityApplications.defaultProps = {
  applications : [],
};

export default withStyles(styles)(OpportunityApplications);
