import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

export const AccessDates = ({ card, classes }) => {
  return (
    <div style={{ marginTop: 10 }}>
      <Divider />
      <List>
        <ListItemText
          primary="Created at:"
          secondary={moment(
            get(card, 'primary_agent.continuity_builder.userGoal.business_continuity_plan.createdAt', '')
          ).format('LLLL')}
        />
        <ListItemText
          primary="Last Updated:"
          secondary={moment(
            get(card, 'primary_agent.continuity_builder.userGoal.business_continuity_plan.updatedAt', '')
          ).format('LLLL')}
        />
        <ListItemText
          primary="Number of times accessed:"
          secondary={get(
            card,
            'primary_agent.continuity_builder.userGoal.business_continuity_plan.numberOfTimesAccessed',
            0
          )}
        />
      </List>
    </div>
  );
};

AccessDates.propTypes = {
  card                   : PropTypes.object.isRequired,
  classes                : PropTypes.object.isRequired,
  dealTrackingPipeLineId : PropTypes.number,
  enqueueSnackbar        : PropTypes.func.isRequired,
  updateCard             : PropTypes.func.isRequired,
  pipeline               : PropTypes.object.isRequired,
};

AccessDates.defaultProps = {
  dealTrackingPipeLineId : '',
};

const styles = {
  list : {
    margin : '10px 0px 0px 0px',
  },
};

export default withStyles(styles)(AccessDates);
