import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize } from 'lodash';

const SaveButton = ({ classes, dealInterest, agentWordForCompany }) => {
  return (
    <div className={classes.margin}>
      <a
        href={'https://findbob-production.s3-us-west-2.amazonaws.com/resources/Seller+Due+Diligence+Checklist.pdf'}
        target="_blank">
        <i class="fas fa-tasks" style={{ marginRight: 5 }} /><FormattedMessage id="profile_page.due_diligence_checklist"/>
      </a>
    </div>
  );
};

const styles = {
  margin : {
    margin : '0px 0px 15px 25px',
  },
};

export default withStyles(styles)(SaveButton);
