import React from 'react';
import TextField from '@material-ui/core/TextField';
import { get } from 'lodash';

export const ManagerTokenInfo = ({ user, approvalState }) => {
  if (approvalState !== 'pending') {
    return <div></div>
  }
  return (
    <div style={{ flexGrow: 1 }}>
      <h6>Approval Workflow URLs</h6>
      {Boolean(get(user, 'manager_token')) &&
        <TextField
          InputLabel={{ disabled: true }}
          label={`Manager Approval URL (${get(user, 'manager.name')}):`}
          fullWidth
          value={`https://${location.hostname}/manager_confirmation?token=${get(user, 'manager_token')}`}
          disabled />
      }

      {Boolean(get(user, 'divisional_director_token')) &&
        <TextField
          InputLabel={{ disabled: true }}
          label={`Manager Approval URL (${get(user, 'divisional_director_name')}):`}
          fullWidth
          value={`https://${location.hostname}/manager_confirmation?token=${get(user, 'divisional_director_token')}`}
          disabled />
      }

      {Boolean(get(user, 'asst_divisional_director1_token')) &&
        <TextField
          InputLabel={{ disabled: true }}
          label={`Manager Approval URL (${get(user, 'asst_divisional_director1_name')}):`}
          fullWidth
          value={`https://${location.hostname}/manager_confirmation?token=${get(user, 'asst_divisional_director1_token')}`}
          disabled />
      }
      {Boolean(get(user, 'asst_divisional_director2_token')) &&
        <TextField
          InputLabel={{ disabled: true }}
          label={`Manager Approval URL (${get(user, 'asst_divisional_director2_name')}):`}
          fullWidth
          value={`https://${location.hostname}/manager_confirmation?token=${get(user, 'asst_divisional_director2_token')}`}
          disabled />
      }

    </div>
  )
}

export default ManagerTokenInfo