import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, findIndex, isEmpty } from 'lodash';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import ContinuitySealButton from './ContinuitySealButton';
import AccessDates from './AccessDates';

const SharePlan = gql`
  mutation builderSharePlan($id: ID!) {
    builderSharePlan(id: $id)
  }
`;

const returnUserGoalData = (
  userGoal,
  agent_id,
  classes,
  enqueueSnackbar,
  card,
  updateCard,
  pipeline,
  lastSent,
  setLastSent
) => {
  if (userGoal && !userGoal.completed) {
    return (
      <div>
        <div>{14 - userGoal.remaining_step_count} out of 14 steps completed</div>
        <AccessDates card={card} pipeline={pipeline} />
      </div>
    );
  }
  if (userGoal && userGoal.completed) {
    const planSent = lastSent || get(card, 'continuity_builder.businessContinuityPlan.planSentAt');
    return (
      <Mutation mutation={SharePlan}>
        {sharePlan => (
          <div>
            <a
              href={`/continuity/download?agent_id=${agent_id})}`}
              style={{ cursor: 'pointer', color: 'rgb(0, 107, 219)' }}>
              Download Continuity Plan
            </a>
            <p>Press this button to share plan activation.</p>
            <Button
              onClick={() => {
                const { id } = card.continuity_builder;
                sharePlan({ variables: { id } }).then(() => {
                  enqueueSnackbar('Plan has been shared.', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                  });
                  setLastSent(moment().format('LLLL'));
                });
              }}
              variant="contained"
              color="primary">
              {planSent ? 'Re-Share Plan' : 'Share Plan'}
            </Button>
            {planSent && <div>Last Sent: {moment(planSent).format('LLLL')}</div>}
            <br />
            <AccessDates card={card} pipeline={pipeline} />
          </div>
        )}
      </Mutation>
    );
  }
  return '';
};

const showCompleted = userGoal => {
  if (!userGoal || userGoal.completed) {
    return true;
  }
  return false;
};

const clickViewAll = (dealId, id) => {
  window.open(`/admin/pipelines/${id}?deal_id=${dealId}`, '_self');
};

const getSteps = () => [
  {
    key: 'completed_plan',
    label: card =>
      showCompleted(get(card, 'primary_agent.continuity_builder.userGoal')) ? 'Completed Plan' : 'Plan In-Progress',
    completed: card => !isEmpty(get(card, 'primary_agent.continuity_builder.userGoal')),
    content: (card, id, classes, enqueueSnackbar, updateCard, pipeline, lastSent, setLastSent) =>
      returnUserGoalData(
        get(card, 'primary_agent.continuity_builder.userGoal'),
        get(card, 'primary_agent.id', ''),
        classes,
        enqueueSnackbar,
        card,
        updateCard,
        pipeline,
        lastSent,
        setLastSent
      ),
  },
  {
    key: 'find_partner',
    label: 'Find Partner',
    completed: card => get(card, 'primary_agent.continuity_builder.completedFindPartnerStep'),
    content: (card, dealTrackingPipeLineId) => {
      const successors = get(card, 'primary_agent.continuity_builder.continuityBuilderSuccessors', []);
      return (
        <div>
          {successors.map(s => (
            <div>
              {`Successor ${s.ordinal} Name: ${get(s, 'inventoryUser.name', '-')}`}
              <br />
              {`Successor ${s.ordinal} Email: ${get(s, 'inventoryUser.email', '-')}`}
              <br />
              <br />
            </div>
          ))}
          {isEmpty(successors) &&
            get(card, 'primary_agent.company.business_type') === 'wealth_rj' &&
            get(card, 'primary_agent.continuity_builder.searchedForPartner') && <div>Searched for a partner</div>}
          {get(card, 'primary_agent.continuity_builder.existingPartnerName', '') && (
            <div>
              {`Partner Name: ${get(card, 'primary_agent.continuity_builder.existingPartnerName', '')}`}
              <br />
              {`Partner Email: ${get(card, 'primary_agent.continuity_builder.existingPartnerEmail')}`}
              <br />
              {`Last Updated: ${moment(get(card, 'primary_agent.continuity_builder.lastUpdated')).format(
                'MM-DD-YYYY'
              )}`}
              <br />
              <br />
            </div>
          )}
          {get(card, 'primary_agent.continuity_builder.deal.number_of_deal_interests', 0) === 0 &&
            successors.length < 1 &&
            !get(card, 'primary_agent.company.disable_deal_room_from_bcb') &&
            get(card, 'primary_agent.company.business_type') !== 'wealth_rj' && <div>Started creating a deal room</div>}
          {get(card, 'primary_agent.continuity_builder.deal.number_of_deal_interests', 0) > 0 && (
            <button
              type="button"
              style={{
                height: 'auto',
                padding: 0,
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                color: 'rgb(0, 107, 219)',
              }}
              onClick={() =>
                clickViewAll(get(card, 'primary_agent.continuity_builder.deal.id', ''), dealTrackingPipeLineId)}>
              View Deal
            </button>
          )}
        </div>       
      );
    },
  },
  {
    key: 'filed_agreement',
    label: card => {
      const wealth_rj = get(card, 'primary_agent.company.business_type', '') === 'wealth_rj';
      return wealth_rj ? 'Submitted Agreement' : 'Filed Agreement'
    },
    completed: card => get(card, 'primary_agent.continuity_builder.completedFileAgreementStep'),
    content: (card, classes, enqueueSnackbar, updateCard, pipeline, lastSent, setLastSent) => (
      <div>
        {get(card, 'primary_agent.continuity_builder.filedAgreementTimestamp') && <div><b>Contract Date</b>: {moment(get(card, 'primary_agent.continuity_builder.filedAgreementTimestamp')).utc().format("MMM DD, YYYY")}<br/><br/></div>}
        {get(card, 'primary_agent.continuity_builder.documents', []).map(d => (
          <li>
            <a href={`${d.download_url}`}>{d.name}</a>
          </li>
        ))}
        <br />
        <br />
        {get(card, 'continuity_builder.canIssuedSeal', false) && (
          <ContinuitySealButton
            card={card}
            updateCard={updateCard}
            continuityBuilderId={card.continuity_builder.id}
            pipeline={pipeline}
          />
        )}
      </div>
    ),
  },
];

export const CompletedSteps = ({ card, classes, dealTrackingPipeLineId, enqueueSnackbar, updateCard, pipeline }) => {
  const [lastSent, setLastSent] = useState(0);
  const steps = getSteps(get(card, 'primary_agent.continuity_builder'));
  const stepIndex = findIndex(steps, { key: 'completed_plan' });
  return (
    <div>
      <Stepper activeStep={stepIndex} nonLinear orientation="vertical">
        {steps.map(({ key, label, completed, content }) => (
          <Step
            active={completed(card)}
            key={key}
            completed={completed(card)}
            classes={{
              root: classes.step,
              completed: classes.completed,
            }}>
            <StepLabel
              classes={{
                alternativeLabel: classes.alternativeLabel,
                labelContainer: classes.labelContainer,
              }}
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                },
              }}>
              {typeof (label) === 'function' ?
                <span>{label(card)}</span> :
                <span>{label}</span>}
            </StepLabel>
            <StepContent>
              <span>
                {content(
                  card,
                  dealTrackingPipeLineId,
                  classes,
                  enqueueSnackbar,
                  updateCard,
                  pipeline,
                  lastSent,
                  setLastSent
                )}
              </span>
              <div className={classes.actionsContainer} />
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

CompletedSteps.propTypes = {
  card: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dealTrackingPipeLineId: PropTypes.number,
  enqueueSnackbar: PropTypes.func.isRequired,
  updateCard: PropTypes.func.isRequired,
  pipeline: PropTypes.object.isRequired,
};

CompletedSteps.defaultProps = {
  dealTrackingPipeLineId: '',
};

const styles = {
  step: {
    '& $completed': {
      color: 'green',
    },
    '& $active': {
      color: 'blue',
    },
    '& $disabled': {
      color: 'red',
    },
  },
  alternativeLabel: {},
  active: {},
  disabled: {},
  completed: {
    color: '#000',
  },
  stepIcon: {
    color: 'green',
  },
  labelContainer: {
    '& $alternativeLabel': {
      marginTop: 0,
    },
  },
  completionCount: {
    marginTop: 10,
    marginLeft: 25,
    fontSize: 18,
  },
};

export default withStyles(styles)(withSnackbar(CompletedSteps));
