import React from 'react';
import { TextField, Box, Typography } from '@material-ui/core';

const Description = ({ formData, handleChange }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Title & Description
    </Typography>
    <Typography variant="body2">
      Please give this segment a name
    </Typography>
    <TextField
      label="Name"
      name="segmentDetails"
      value={formData.segmentDetails}
      onChange={handleChange}
      fullWidth
      multiline
      margin="dense"
      inputProps={{ maxLength: 100 }}
    />
    <br/>
    <br/>
    <Typography variant="body2">
      Describe the segement you are looking to sell in 250 characters or less.
    </Typography>
    <TextField
      label="Description"
      name="description"
      value={formData.description}
      onChange={handleChange}
      fullWidth
      multiline
      minRows={4}
      margin="dense"
      inputProps={{ maxLength: 250 }}
    />
  </Box>
);

export default Description;
