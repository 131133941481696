import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import moment from 'moment';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';

const DeleteNote = gql`
  mutation deleteNote($id: ID!, $userName: String!) {
    deleteNote(id: $id, userName: $userName) {
      id
      comment
      created_at
    }
  }
`;

const styles = {
  card         : {
    minWidth     : 275,
    margin       : '6px',
    marginBottom : 12,
  },
  pos          : {
    marginBottom : 12,
  },
  actions      : {
    borderTop     : '1px solid #e1e1e1',
    flexDirection : 'row-reverse',
  },
  date         : {},
  deleteButton : {
    marginRight : 'auto',
    border      : 0,
  },
  cardContent  : {
    padding : '16px',
  },
};

const deleteNote = (id, mutate, onDelete, createdAt, userName) => {
  onDelete(mutate, id, createdAt, userName);
};

const Note = ({ id, comment, createdAt, classes, mutate, onDelete, userName, intl }) => {
  moment.locale(intl.locale)
  return (
    <div>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div dangerouslySetInnerHTML={{ __html: comment }} />
        </CardContent>
        <CardActions className={classes.actions}>
          <p className={classes.date}>{moment(createdAt).calendar()}</p>
          <button
            onClick={() => {
              deleteNote(id, mutate, onDelete, createdAt, userName);
            }}
            className={classes.deleteButton}>
            <i className="fa fa-trash" />
          </button>
        </CardActions>
      </Card>
    </div>
  );
};

Note.propTypes = {
  classes   : PropTypes.object.isRequired,
  id        : PropTypes.integer,
  comment   : PropTypes.string,
  createdAt : PropTypes.string,
};

const NoteWithStyle = withStyles(styles)(Note);

export default injectIntl(graphql(DeleteNote)(NoteWithStyle));
