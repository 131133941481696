import React from 'react';
import PropTypes from "prop-types";
import numeral from 'numeral';
import {VictoryAxis, VictoryChart, VictoryLine, VictoryTheme} from "victory";
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from 'react-intl';

const CashFlowChart = ({cashFlow, personType, classes, intl}) => {
  const data = cashFlow.map((cf, k) => ({x: k + 1, y: parseFloat(cf.freeCashFlow)}));

  return (
    <div className={classes.graphContainer}>
    <center><h3><FormattedMessage id="calculators.cfa.free_cash_flow" /></h3></center>
      <div className={classes.chartHolder}>
        <VictoryChart height={180} width={400} theme={VictoryTheme.material} >
          <VictoryAxis
            tickFormat={(tick => `${intl.formatMessage({id: "calculators.cfa.year"})} ${numeral(tick).format('0')}`)}
            style={{
              tickLabels: {fontSize: 8,},
            }}
          />
          <VictoryAxis
            dependentAxis tickFormat={(tick) => numeral(tick).format(intl.locale == 'en' ? '$0a' : '0 a $').toUpperCase()}
            style={{
              tickLabels: {fontSize: 8,},
            }}
          />
          <VictoryLine
            style={{
              data: { stroke: "#7393B3" },
              parent: { border: "1px solid #ccc"}
            }}
            data={data}
          />
        </VictoryChart>
      </div>
      <div style={{clear: 'both'}}></div>
    </div>
      );
}

CashFlowChart.propTypes = {
  cashFlow: PropTypes.object,
  personType: PropTypes.string,
  classes: PropTypes.object,
};

CashFlowChart.defaultProps = {
 cashFlow: {},
 personType: '',
 classes: {},
};

const styles= {
  graphContainer: {
    height: 220,
    border: '1px solid #dadada',
    padding: 5,
    paddingBottom: 332,
  },
  chartHolder: {
    top: -112,
    position: 'relative',
  }
}

export default withStyles(styles)(injectIntl(CashFlowChart));