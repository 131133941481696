import gql from 'graphql-tag';

export const createTaskMutation = gql`
  mutation createTask(
    $name: String
    $pipeline_card_id: ID
    $activity_type_id: ID
    $inventory_user_id: ID
    $due_date: String
  ) {
    createTask(
      name: $name
      pipeline_card_id: $pipeline_card_id
      activity_type_id: $activity_type_id
      inventory_user_id: $inventory_user_id
      due_date: $due_date
    ) {
      id
    }
  }
`;

export const OpenTasksQuery = gql`
  query getOpenTasks($userID: ID) {
    getOpenTasks(userID: $userID) {
      id
      name
      status
      description
      dueDate
      pipelineCard {
        id
        name
        pipeline {
          id
          name
          __typename
        }
        __typename
      }
    }
  }
`;

export const TasksQuery = gql`
  query getTasks(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $name_or_inventory_user_name_or_pipeline_card_name_cont: String
    $activity_type_id_in: [ID]
    $userID: ID
    $pipelineCardID: ID
    $nameSearch: String
    $page: Int
    $rowsPerPage: Int
  ) {
    getActivityTypes {
      id
      name
      iconUrl
    }
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
      }
    }
    getManagers {
      id
      name
    }
    getTaskCount
    getTasksCount(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      nameSearch: $nameSearch
      page: $page
      rowsPerPage: $rowsPerPage
    )
    getTasks(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      name_or_inventory_user_name_or_pipeline_card_name_cont: $name_or_inventory_user_name_or_pipeline_card_name_cont
      activity_type_id_in: $activity_type_id_in
      userID: $userID
      pipelineCardID: $pipelineCardID
      nameSearch: $nameSearch
      page: $page
      rowsPerPage: $rowsPerPage
    ) {
      id
      name
      status
      description
      dueDate
      pipelineCard {
        id
        name
        __typename
      }
      activityType {
        id
        name
        iconUrl
      }
      inventoryUser {
        id
        name
        profile_picture
        has_user
      }
      comments {
        id
        title
        comment
        user {
          name
        }
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
      }
    }
  }
`;

export const updateTaskMutation = gql`
  mutation updateTask(
    $id: ID!
    $name: String
    $status: String
    $description: String
    $pipeline_card_id: ID
    $activity_type_id: ID
    $inventory_user_id: ID
    $due_date: String
  ) {
    updateTask(
      id: $id
      name: $name
      status: $status
      description: $description
      pipeline_card_id: $pipeline_card_id
      activity_type_id: $activity_type_id
      inventory_user_id: $inventory_user_id
      due_date: $due_date
    ) {
      id
      name
      status
      description
      dueDate
      pipelineCard {
        id
        name
        __typename
      }
      activityType {
        id
        name
        iconUrl
      }
      inventoryUser {
        id
        name
        profile_picture
        has_user
      }
      comments {
        id
        title
        comment
        user {
          name
        }
      }
    }
  }
`;

export const addTaskCommentMutation = gql`
  mutation addTaskComment($task_id: ID!, $comment: String) {
    addTaskComment(task_id: $task_id, comment: $comment)
  }
`;

export const getTaskQuery = gql`
  query getTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      status
      description
      dueDate
      pipelineCard {
        name
      }
      activityType {
        id
        name
        iconUrl
      }
      inventoryUser {
        id
        name
        profile_picture
        has_user
      }
      comments {
        id
        title
        comment
        user {
          name
        }
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
      }
    }
  }
`;

export const deleteTaskMutation = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;
