import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import { graphql } from 'react-apollo';
import { filter, find } from 'lodash';
import { resendActivationEmailQuery } from './InventoryUserQueries';

class InventoryUserResendActivationEmail extends React.Component {
  state = {
    open: false,
    resendActivationEmail: true,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  toggleResendActivationEmail = () => {
    const { resendActivationEmail } = this.state;
    this.setState({ resendActivationEmail: !resendActivationEmail });
  };

  render() {
    const { selected, changePage, data, mutate, loadBulkItemsList } = this.props;
    const { open, resendActivationEmail } = this.state;

    let validSelections = [];
    let invalidSelections = [];

    let message = `Cancel resending activation email to ${selected.length} users?`;
    let bodyText = 'Activation email(s) will not be re-sent to these users.';
    let resendActivationText = `Don't resend`;

    if (open) {
      const selections = selected.map(s => find(data, ['id', s]));
      validSelections = filter(selections, ['has_user', true]);
      invalidSelections = filter(selections, ['has_user', false]);

      if (resendActivationEmail) {
        message = `Are you sure you want to resend activation email to ${selected.length} users?`;
        bodyText = 'Activation email(s) will be sent to these users.';
        resendActivationText = 'resendActivation';
      }
    }

    return (
      <div style={{ float: 'left' }}>
        <Tooltip title="Resend Activation Email">
          <IconButton aria-label="Resend Activation Email">
            <i class="material-icons" onClick={this.handleClickOpen}>
              contact_mail
            </i>
          </IconButton>
        </Tooltip>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
          <DialogContent>
            <Checkbox checked={resendActivationEmail} onClick={this.toggleResendActivationEmail} /> Resend Activation
            Email?
            <DialogContentText>{bodyText}</DialogContentText>
            {validSelections.map(u => <div>{u.name}</div>)}
            <hr />
            {invalidSelections.length > 0 && (
              <div>
                <b>{`The following users have not signed up for an account and therefore cannot be ${resendActivationText} :`}</b>
                {invalidSelections.map(u => <div style={{ color: 'tomato' }}>{u.name}</div>)}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutate({
                  variables: {
                    usersToResendActivationEmail: validSelections.map(iu => iu.user.id),
                    resendActivationEmail: resendActivationEmail,
                  },
                }).then(() => {
                  changePage();
                });

                this.handleClose();
                loadBulkItemsList([]);
              }}
              color="primary"
              disabled={validSelections.length === 0}
              autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

InventoryUserResendActivationEmail.propTypes = {
  selected: PropTypes.array.isRequired,
  changePage: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  mutate: PropTypes.func.isRequired,
  loadBulkItemsList: PropTypes.func.isRequired,
};

export default graphql(resendActivationEmailQuery)(InventoryUserResendActivationEmail);
