import { connect } from 'react-redux';
import OnboardingBannerWidget from '../components/OnboardingBannerWidget/OnboardingBannerWidget';
import NewFormDialog from '../components/OnboardingBannerWidget/OnboardingBannerWidgetNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/onboardingBannerWidgetsActions';

const mapStateToProps = state => ({
  ...state,
  listData: state.onboarding_banner_widgets,
  title: 'Onboarding Banner Widgets',
  NewFormDialog,
  CrudRow: OnboardingBannerWidget,
});

export default connect(mapStateToProps, actions)(CrudTable);
