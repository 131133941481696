/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { get, isEmpty } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import { IntlProvider } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import ProfileCard from '../UserProfile/ProfileCard';
import PracticeInfo from '../UserProfile/PracticeInfo';
import MatchProfileSideBar from './MatchProfileSideBar';
import RecommendationSection from './RecommendationSection';
import ProposalLetter from './ProposalLetter';
import KnockoutQuestions from './KnockoutQuestions';
import ScheduledMeetings from './ScheduledMeetings';
import AumInfo from '../UserProfile/AumInfo';
import Strengths from '../UserProfile/Strengths';
import defaultLocale from '../../lib/i18n/default.json';
import translations from '../../lib/i18n/translations.json';
import { createIntl, createIntlCache, injectIntl } from 'react-intl';
import ChatWidget from '../ChatWidget/ChatWidget';
import FileTab from '../FileTab/FileTab';
import qs from 'query-string';
import { BobBanner } from '../BobBanner';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

class MatchProfile extends React.Component {
  constructor (props) {
    super(props);
    this.state = { selectedUser: false };
    this.recommendationRef = React.createRef();
    this.scheduleRef = React.createRef();
    this.proposalRef = React.createRef();
    this.chatRef = React.createRef();
  }

  componentWillReceiveProps (nextProps) {
    const nextUser = nextProps.user;
    const { user } = this.state;
    if (get(nextUser, 'id') !== get(user, 'id')) {
      this.setState({ selectedUser: true });
    }
    const params = qs.parse(location.search);
    const { anchor } = params;
    if (anchor) {
      setTimeout(() => {
        this.scrollTo(anchor);
      }, 1000);
      this.setState({ anchor });
    }
  }

  deSelectUser = () => {
    this.setState({ selectedUser: false });
  };

  renderSideBar = () => {
    const {
      dealInterest,
      agentWordForCompany,
      deadLinePassed,
      stylesheetName,
      dealInterestName,
      currentUser,
    } = this.props;
    return (
      <MatchProfileSideBar
        dealInterest={dealInterest}
        agentWordForCompany={agentWordForCompany}
        scrollTo={this.scrollTo}
        deadLinePassed={deadLinePassed}
        stylesheetName={stylesheetName}
        dealInterestName={dealInterestName}
        allowShortlistMessaging={get(currentUser, 'company.deal_room_allow_messaging_when_shortlisted', false)}
        dealRoomName={get(currentUser, 'company.deal_room_branded_name', 'Deal Room')}
        dealRoomSingleName={get(currentUser, 'company.deal_room_single_word', 'Room')}
        currentUser={currentUser}
        companyBusinessType={get(currentUser, 'company.business_type', '')}
      />
    );
  };

  componentDidMount = () => {};

  hasProductMix = user => {
    return !Object.values(get(user, 'product_mix') || { a: null }).every(
      x => x === null || x === 'ProductMix' || x === 0
    );
  };

  scrollTo = ref => {
    if (ref) {
      this[`${ref}Ref`].current.scrollIntoView({ behavior: 'smooth' });
      const currentLocation = window.location.href;
      window.history.replaceState({}, window.history.title, `${currentLocation.split('&')[0]}`);
    }
  };
  render () {
    const { selectedUser } = this.state;
    const {
      classes,
      user,
      dealInterest,
      availableStrengths,
      currentUser,
      currentUserInfoAuth,
      countryForCompany,
      companyHasCounty,
      availableProducts,
      companyBusinessType,
    } = this.props;
    const allowShortlistMessaging = get(currentUser, 'company.deal_room_allow_messaging_when_shortlisted', false);
    const dealInterestStage = get(dealInterest, 'workflow_state');
    const businessType = get(currentUser, 'company.business_type', '');
    setIntl();
    return (
      <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
        <Drawer
          open={selectedUser}
          variant="temporary"
          anchor="right"
          // onClose={this.handleDrawerToggle}
          classes={{
            paper : classes.drawerPaper,
          }}>
          <Button onClick={this.deSelectUser} style={{ width: 20 }}>
            <i className="material-icons" style={{ fontSize: 45, color: 'rgb(19, 150, 226)' }}>
              keyboard_arrow_left
            </i>
          </Button>
          {user && (
            <Grid container>
              <Grid xs={8}>
                <ProfileCard
                  user={user || {}}
                  availableStrengths={availableStrengths || []}
                  currentUser={currentUser || {}}
                  showRolodex={false}
                  userUpdate={() => {}}
                  followingUser={false}
                  currentUserInfAuth={currentUserInfoAuth}
                  notebook={false}
                  countryForCompany={countryForCompany || 'us'}
                  companyHasCounty={companyHasCounty}
                  obfuscateView={false}
                  authorized
                  enabledStudentProfiles={get(user, 'company.enabled_student_profiles')}
                  studentWord={get(user, 'company.student_word')}
                  studentProgramTitle={get(user, 'company.student_program_title')}
                  newUser={false}
                  notes={user.notes}
                  hideRating
                  companyBusinessType={companyBusinessType}
                />
                <BobBanner
                  user={user}
                  currentUser={currentUser}
                  obfuscate={currentUser.limited_access || user.obfuscated}
                />
                <PracticeInfo
                  user={user || {}}
                  authorized
                  availableProducts={availableProducts}
                  displayAds={false}
                  userIsAdmin={false}
                  currentUserHasLimitedAccess={false}
                  ownProfile={false}
                  currentUserHasSellingTransitionGoals={false}
                  obfuscateView={false}
                  companyBusinessType={companyBusinessType}
                />
                {this.hasProductMix(user) && (
                  <AumInfo
                    user={user}
                    useCanadianRetailWealthProductMix={get(user, 'company.use_canadian_retail_wealth_product_mix')}
                    usePAndCProductMix={get(user, 'company.use_p_and_c_product_mix')}
                  />
                )}
                {!isEmpty(get(user, 'tag_list')) && (
                  <Strengths
                    availableStrengths={availableStrengths || []}
                    strengths={get(user, 'tag_list')}
                    companyBusinessType={companyBusinessType}
                  />
                )}
                {get(dealInterest, 'reason_for_suggestion') && (
                  <div ref={this.recommendationRef}>
                    <RecommendationSection recommendation={get(dealInterest, 'reason_for_suggestion', '')} />
                  </div>
                )}
                <div ref={this.proposalRef}>
                  {get(dealInterest, 'proposal_letter') && (
                    <ProposalLetter
                      proposalLetter={get(dealInterest, 'proposal_letter', '')}
                      highlight={dealInterest.workflow_state === 'reviewing_proposal'}
                      dealInterest={dealInterest}
                      allowShortlistMessaging={allowShortlistMessaging}
                      businessType={businessType}
                    />
                  )}
                </div>
                <div ref={this.questionsRef}>
                  {get(dealInterest, 'a1') && (
                    <KnockoutQuestions dealInterest={dealInterest} businessType={businessType} />
                  )}
                </div>
                {!isEmpty(get(dealInterest, 'deal_scheduled_meetings')) &&
                get(currentUser, 'company.allow_scheduling_in_deal_room') && (
                  <div ref={this.scheduleRef}>
                    <ScheduledMeetings meetings={get(dealInterest, 'deal_scheduled_meetings')} />
                  </div>
                )}
                {dealInterestStage !== 'archived' && (
                  <div ref={this.chatRef}>
                    {dealInterest.conversation_id && (
                      <div>
                        {!user.deleted && (
                          <ChatWidget
                            room_id={dealInterest.conversation_id}
                            currentUser={currentUser}
                            dealInterestStage={dealInterestStage}
                            title={`Deal: ${get(user, 'allowed_name')} & Me`}
                            disableChat={get(dealInterest, 'deal.closed', false)}
                            disableChatMessage={`Chat has been disabled as the room has been closed.`}
                          />
                        )}
                        {user.deleted && (
                          <ChatWidget
                            room_id={dealInterest.conversation_id}
                            currentUser={currentUser}
                            dealInterestStage={dealInterestStage}
                            title="User is no longer available"
                            deleted
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
                {dealInterestStage == 'negotiating' && (
                  <div>
                      <FileTab
                        variables={{ deal_interest_id: dealInterest.id }}
                        documents={dealInterest.documents}
                        disableUpload={get(dealInterest, 'deal.closed', false)}
                      />
                  </div>
                )}
              </Grid>
              <Grid xs={4}>{this.renderSideBar()}</Grid>
            </Grid>
          )}
        </Drawer>
      </IntlProvider>
    );
  }
}

MatchProfile.propTypes = {
  classes              : PropTypes.object.isRequired,
  currentInventoryUser : PropTypes.object.isRequired,
  pipeline             : PropTypes.object,
  changePipelineState  : PropTypes.func.isRequired,
  dragging             : PropTypes.object.isRequired,
  updateCard           : PropTypes.func.isRequired,
  addPipelineCard      : PropTypes.func.isRequired,
  refetchPipelines     : PropTypes.func.isRequired,
  cardMutation         : PropTypes.func.isRequired,
  mutateOrder          : PropTypes.func.isRequired,
  pipelineList         : PropTypes.array,
  nameFilter           : PropTypes.string,
  statusFilter         : PropTypes.string,
  userFilter           : PropTypes.string.isRequired,
};

MatchProfile.defaultProps = {
  pipeline     : {},
  pipelineList : [],
  nameFilter   : '',
  statusFilter : '',
};

const styles = {
  MatchProfile    : {
    position   : 'relative',
    display    : 'flex',
    margin     : '0 auto',
    width      : '100%',
    fontFamily : 'Arial, "Helvetica Neue", sans-serif',
    overflow   : 'scroll',
    height     : 'calc(100vh - 150px)',
  },
  column          : {
    minWidth     : 200,
    width        : '23vw',
    height       : 'auto',
    minHeight    : '100%',
    margin       : '8px',
    borderRadius : '6px 6px 0 0',
    background   : '#fafafa',
  },
  columnHead      : {
    textAlign : 'left',
    padding   : '14px 14px 8px 18px',
    fontSize  : '1.4em',
    marginTop : 12,
    display   : 'flex',
    minHeight : 68,
  },
  columnTitle     : {
    width        : 252,
    textOverflow : 'ellipsis',
    whiteSpace   : 'nowrap',
    overflow     : 'hidden',
    display      : 'inline-block',
    flex         : 1,
  },
  buttonContainer : {
    flex : 1,
  },
  item            : {
    padding  : 10,
    margin   : 10,
    fontSize : '0.8em',
    cursor   : 'pointer',
  },
  spacer          : {
    height          : 200,
    width           : '100%',
    backgroundImage : 'repeating-linear-gradient(45deg, #fff, #fff 10px, #eee 10px, #eee 20px)',
  },
  cardHeader      : {
    marginTop : 0,
  },
  valueHeader     : {
    fontSize : 16,
  },
  status          : {
    float    : 'right',
    position : 'relative',
    top      : -28,
  },
  drawerPaper     : {
    width        : 'calc(100%)',
    maxWidth     : 1120,
    height       : 'calc(100%)',
    borderRadius : 4,
    overflowY    : 'auto',
    boxShadow    : '-2px 2px 2px -1px #aaa',
    borderTop    : '1px solid #aaa',
    padding      : 20,
    background   : '#F2F4F4',
  },
};

export default withStyles(styles)(MatchProfile);
