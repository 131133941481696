import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { injectIntl, FormattedMessage } from 'react-intl';

const DeleteUserNotes = gql`
  mutation deleteAllNotes($userName: String!) {
    deleteAllNotes(userName: $userName)
  }
`;

class RemoveFromRolodexButton extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showDialog : false,
    };
  }

  click = () => {
    const { mutate, user, onClick, intl, updateBobCards, loadBobCardList, removeFromList, sortedUsers } = this.props;

    mutate({ variables: { userName: user.fb_user_name } })
      .then(() => {
        this.setState({ showDialog: false });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: 0 });
          loadBobCardList({ ...user, note_count: 0 });
        }
        if (removeFromList) {
          const newList = sortedUsers.filter(u => u.fb_user_name !== user.fb_user_name);
          loadBobCardList({ data: newList });
        }
        onClick();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
    //onClick(mutate, userName);
  };

  renderDialog = () => (
    <div className="modal show" role="dialog" style={{ top: '350px' }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              <FormattedMessage id="shared.confirmation" />
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.setState({ showDialog: false });
              }}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>
              <FormattedMessage id="mailers.rolodex.are_you_sure" values={{ note_count: this.props.user.note_count }} />
            </p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" onClick={this.click}>
              <FormattedMessage id="mailers.rolodex.yes_delete_all_notes" />
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => {
                this.setState({ showDialog: false });
              }}>
              <FormattedMessage id="shared.no" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  renderButton = () => {
    const { fromSideBar, notes, agentWordForCompany } = this.props;

    return (
      <span>
        {fromSideBar && (
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              {
                this.setState({ showDialog: true });
              }
            }}>
            <i className="fas fa-heart" style={{ marginRight: 5, color: '#FF5087' }} /><FormattedMessage id="profile_page.remove_from_rolodex" />
          </a>
        )}
        {!fromSideBar && (
          <a
            onClick={() => {
              {
                this.setState({ showDialog: true });
              }
            }}>
            <i className="fas fa-trash" style={{ color: 'red', cursor: 'pointer' }} />
          </a>
        )}
      </span>
    );
  };

  render = () => {
    const { showDialog } = this.state;
    return (
      <span>
        {showDialog && this.renderDialog()}
        {this.renderButton()}
      </span>
    );
  };
}

RemoveFromRolodexButton.propTypes = {
  mutate         : PropTypes.func.isRequired,
  user           : PropTypes.object.isRequired,
  onClick        : PropTypes.func,
  fromSideBar    : PropTypes.bool,
  removeFromList : PropTypes.bool,
};

RemoveFromRolodexButton.defaultProps = {
  onClick        : () => {},
  fromSideBar    : false,
  removeFromList : false,
};

export default injectIntl(graphql(DeleteUserNotes)(RemoveFromRolodexButton));
