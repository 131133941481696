import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { FormattedMessage, injectIntl } from 'react-intl';
import { spotlightElement } from '../../lib/findBobUtils';
import Button from '@material-ui/core/Button';
import { generatedHeadlineForUser, generatedBioForUser, generatedRjHeadlineForUser } from '../../lib/userUtils';
import { STEP_NUMBERS } from '../SignUpWizard/SignUpWizard';
import LoadingButtonWrapper from '../LoadingButtonWrapper/LoadingButtonWrapper';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($headline: String, $bio: String, $ask_me_about: String) {
    updateCurrentUser(headline: $headline, bio: $bio, ask_me_about: $ask_me_about)
  }
`;

class TipViewUpdateProfileUserHeadlineBio extends React.Component {
  state = {};

  componentWillMount () {
    const {
      user,
      updateAction,
      intl,
      companyHasCounty,
      countryForCompany,
      agentWord,
      locale,
      companyBusinessType,
    } = this.props;
    let generateHeadline = '';
    let generatedBio = '';
    if(locale !== 'fr') {
      if (companyBusinessType === 'wealth_rj') {
        generateHeadline = generatedRjHeadlineForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
        generatedBio = generatedBioForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
      } else {
        generateHeadline = generatedHeadlineForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
        generatedBio = generatedBioForUser(user, intl, companyHasCounty, countryForCompany, agentWord);
      }
    }

    let newheadline = user.headline;
    let newbio = user.bio;

    if (user.headline == null) {
      newheadline = generateHeadline;
      updateAction({
        headline : newheadline,
      });
    }

    if (user.bio == null) {
      newbio = generatedBio;
      updateAction({
        bio : newbio,
      });
    }

    this.setState({
      headline : newheadline,
      bio      : newbio,
    });
  }

  updateUser = () => {
    const { mutate, onNext, updateAction } = this.props;
    const { headline, bio, ask_me_about } = this.state;
    updateAction({ loading: true });
    mutate({
      variables : {
        headline,
        bio,
        ask_me_about,
      },
    })
      .then(() => {
        onNext(STEP_NUMBERS.complete);
        updateAction({ loading: false });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
        updateAction({ loading: false });
      });
  };

  validate = () => {
    const { alterItems, askMeAboutMandatory } = this.props;
    const { headline, bio, ask_me_about } = this.state;
    if (alterItems && askMeAboutMandatory) {
      return headline && bio && ask_me_about;
    }
    return headline && bio;
  };

  render () {
    const {
      updateAction,
      onBack,
      classes,
      stylesheetName,
      alterItems,
      show_ask_about_me,
      askMeAboutMandatory,
      user,
    } = this.props;
    const { headline, bio } = this.state;
    console.log('headline', user);
    return (
      <div className="content">
        <b>
          <FormattedMessage id="sign_up_pages.headline" />
        </b>
        <br/><br/>
        <p>
          {user.is_student 
            ? <FormattedMessage id="sign_up_pages.student_headline_subtitle" />
            : <FormattedMessage id="sign_up_pages.headline_subtitle" />
          }
        </p>
        <div onMouseOver={e => spotlightElement('.headline')}>
          <TextareaAutosize
            placeholder={headline}
            value={headline}
            shouldFitContainer
            autoFocus
            minRows={4}
            isSpellCheckEnabled
            isLabelHidden
            multiline
            key="headline"
            style={{ width: '100%' }}
            onChange={e => {
              const newValue = e.target.value;
              if (!/(.)\1{10,}/.test(newValue)) {
                this.setState({ headline: newValue });
                updateAction({ headline: newValue });
              }
            }}
          />
        </div>
        <br/>
        <b>
          <FormattedMessage id="shared.description" />
        </b>
        <br/><br/>
        <p>
          <FormattedMessage id="profile_page.headline_goals.your_description_important_aspect" />
        </p>
        <p>
          <FormattedMessage id="profile_page.headline_goals.best_profiles_include_a_description" />
        </p>
        <div onMouseOver={e => spotlightElement('.bio')}>
          <TextareaAutosize
            placeholder={bio}
            value={bio}
            shouldFitContainer
            autoFocus
            minRows={4}
            isSpellCheckEnabled
            isLabelHidden
            multiline
            key="bio"
            style={{ width: '100%' }}
            onChange={e => {
              const newValue = e.target.value;
              if (!/(.)\1{10,}/.test(newValue)) {
                this.setState({ bio: newValue });
                updateAction({ bio: newValue });
              }
            }}
          />
        </div>
        <br/>
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
          <FormattedMessage id="motivation_onboarding.pages.update_profile_text.want_to_learn_more"/>
          <a href='https://intercom.help/findbob/en/articles/6143497-how-to-develop-a-great-profile'>
            <FormattedMessage id="motivation_onboarding.pages.update_profile_text.great_tips"/>
          </a>
          <FormattedMessage id="motivation_onboarding.pages.update_profile_text.to_get_started"/>
        </div>
        <div className={classes.buttonSection}>
        <div className={classes.buttonDiv}>
          <Button variant="contained" onClick={() => {window.location.href = `/dashboard/custom`}} className={classes.button}>
            <FormattedMessage id="motivation_onboarding.pages.back_to_dashboard_button"/>
          </Button>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={this.updateUser}
            disabled={!this.validate()}
            style={{ marginLeft: '10px' }}>
              <FormattedMessage id="motivation_onboarding.pages.update_profile_text.save_and_continue_editing_profile" />
          </Button>
        </div>
      </div>
      </div>
    );
  }
}

TipViewUpdateProfileUserHeadlineBio.propTypes = {
  headline          : PropTypes.string,
  bio               : PropTypes.string,
  countryForCompany : PropTypes.string,
  agentWord         : PropTypes.string,
  locale            : PropTypes.string,
  user              : PropTypes.object,
  updateAction      : PropTypes.func.isRequired,
  mutate            : PropTypes.func.isRequired,
  onNext            : PropTypes.func.isRequired,
  intl              : PropTypes.object.isRequired,
  companyHasCounty  : PropTypes.bool,
  onBack            : PropTypes.func,
};

TipViewUpdateProfileUserHeadlineBio.defaultProps = {
  headline          : null,
  bio               : null,
  user              : {},
  companyHasCounty  : false,
  countryForCompany : 'ca',
  agentWord         : '',
  locale            : 'en',
  onBack            : null,
};

const StyledTextField = styled(TextField)`
  && .MuiInput-underline:after {
    border-color: #002949 !important;
  }
`;

const styles = () => ({
  title         : {
    fontSize   : '24px',
    fontWeight : 100,
  },
  subTitle      : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  buttonSection : {
    width : '100%',
  },
  buttonDiv     : {
    marginLeft  : 'auto',
    marginRight : 'auto',
    width       : 'fit-content',
  },
  button        : {
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 25,
  },
  formGroup           : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle          : {
    paddingLeft : '6px',
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  ppiBackgroundColor  : {
    backgroundColor : '#7399c6',
    '&:hover'       : {
      background : '#949ca',
    },
  },
});

const TipViewUpdateProfileUserHeadlineBioWithStyles = injectIntl(withStyles(styles)(TipViewUpdateProfileUserHeadlineBio));
export default graphql(UpdateCurrentUser)(TipViewUpdateProfileUserHeadlineBioWithStyles);
