import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ApolloProvider } from 'react-apollo';
import { Provider } from 'react-intl-redux';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import { CircularProgress } from '@material-ui/core';
import { IntlProvider} from 'react-intl';
import themes from '../lib/themes';
import client from '../lib/apolloClient';
import defaultLocale from '../lib/i18n/default.json';
import translations from '../lib/i18n/translations.json';
import configureStore from '../store/findBobPublicStore';
import LoansRepaymentCalculator from '../components/LoansRepaymentCalculator/LoansRepaymentCalculator';



const LenderQuery = gql`
  query LenderQuery($id: ID, $loanAmount: Float, $loanAmortization: Float) {
    lender(id: $id) {
      id
			maximum_loan_amount
			minimum_loan_amount
			amortization_periods
      calculate_loan(loan_amount: $loanAmount, loan_amortization: $loanAmortization) {
				emi
				roi
				monthly_schedules {
					month
					total_interest
					total_repayments
					loan_outstanding
					loan_outstanding_percentage
				}
			}
    }
  }
`;

const LoansRepaymentCalculatorContainer = (props, railsContext) => {
	let store = {};
	if (document.store) {
	  store = document.store;
	} else {
	  store = configureStore(props);
	}
    const theme = themes[railsContext.theme_name];
		const { calculator_props } = props;
    return (<MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
					<ApolloProvider client={client}>
						<IntlProvider messages={translations[window.locale || 'en']} locale={window.locale} props>
							<Query query={LenderQuery} variables={{ id: calculator_props.lender_id, loanAmount: calculator_props.loan_amount, loanAmortization: calculator_props.loan_amortization }} >
								{({ loading, error, data, refetch }) => {
								
									if (error) return `Error!: ${error}`;
									return <React.Fragment>
										{loading && <div style={{position: 'absolute', top: 100, left: 200}}><CircularProgress /></div>}
										<LoansRepaymentCalculator {...props} data={data} refetch={refetch} />
									</React.Fragment>;
								}}
							</Query>
						</IntlProvider>
					</ApolloProvider>
    </MuiThemeProvider>);
};

export default LoansRepaymentCalculatorContainer;