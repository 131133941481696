import { combineReducers } from 'redux';
import { COMPANY_LIST } from '../constants/companyConstants';

const companiesReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPANY_LIST:
      return {...state, companies: action.companies};
    default:
      return state;
  }
};

export default companiesReducer;
