import React from 'react';
import Minicard from './Minicard';

const MinicardsComponent = ({
  users,
  countryForCompany,
  companyHasCounty,
  updateSelectedProfileDrawer,
  profile,
  client,
}) => {
  return users.map(user => (
    <Minicard
      user={user}
      countryForCompany={countryForCompany}
      companyHasCounty={companyHasCounty}
      updateSelectedProfileDrawer={updateSelectedProfileDrawer}
      profile={profile}
      client={client}
    />
  ));
};

export default MinicardsComponent;
