import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Mutation, Query } from 'react-apollo';
import { get } from 'lodash';
import moment from 'moment';
import { updateTaskMutation, getCategorizedTasks} from '../Tasks/TaskQuery';
import NewTaskDialog from './PipelineTaskCard/NewTaskDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PipelineTaskTab extends React.Component {
  state = { hover: false, expanded: false, openTask: false };

  handleClickOpen = () => {
    const { openTask } = this.state;
    this.setState({ openTask: !openTask });
  };

  returnIcon = task => {
    const { hover } = this.state;
    if (task.id === hover || task.status === 'completed') {
      return 'check_box';
    }
    switch (task.activityType.iconUrl) {
      case 'fa-edit':
        return 'edit';
      case 'fa-envelope':
        return 'email';
      case 'fa-phone':
        return 'phone';
      case 'fa-calendar':
        return 'calendar_today';
      case 'fa-sticky-note':
        return 'note';
      case 'fa-share':
        return 'share';
      default:
        return 'more_horiz';
    }
  };

  render () {
    const { refetch, pipelineCard, activityTypes, classes, currentInventoryUser, pipeline, data } = this.props;
    const { tasks } = pipelineCard;
    const { hover, expanded, openTask } = this.state;

    return (
          <div>
            <ExpansionPanel expanded={expanded} className={classes.panel}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon onClick={() => this.setState({ expanded: !expanded })} />}
                className={classes.header}>
                <div>
                  Tasks ({(tasks || []).length})
                  <Button
                    variant="text"
                    className={classes.taskButton}
                    size="small"
                    color="primary"
                    onClick={this.handleClickOpen}>
                    Add Task
                  </Button>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.details}>
                {(tasks || []).map(t => (
                  <div className={classes.taskCard}>
                    <Mutation mutation={updateTaskMutation}>
                      {updateTask => (
                        <Button
                          onClick={() => {
                            const variables = {
                              ...t,
                              status : get(t, 'status', 'open') === 'completed' ? 'open' : 'completed',
                            };
                            updateTask({
                              variables,
                            }).then(() => {
                              refetch();
                            });
                          }}>
                          <span
                            onMouseEnter={() => this.setState({ hover: t.id })}
                            onMouseLeave={() => this.setState({ hover: false })}
                            style={{
                              borderRadius : '50%',
                              background   : '#eee',
                              fontSize     : 20,
                              padding      : 20,
                              marginRight  : 12,
                              height       : 55,
                              color        : get(t, 'status', 'open') === 'completed' ? `green` : `black`,
                            }}>
                            <div style={{ marginTop: -7 }}>
                              {t.status === 'completed' && <FontAwesomeIcon icon={[ 'far', 'check-square' ]} />}
                              {t.status === 'open' && <FontAwesomeIcon icon={[ 'far', 'square' ]} />}
                            </div>
                          </span>
                        </Button>
                      )}
                    </Mutation>
                    <a href={`/admin/tasks?task_id=${t.id}&pipeline_card_id=${pipelineCard.id}`}>
                      <span style={{ textDecoration: t.status === 'completed' ? 'line-through' : 'none' }}>
                        {t.name} <br /> {decodeURI(get(t, 'pipelineCard.name'))} / {get(t, 'inventoryUser.name')}
                      </span>
                      <span style={{ marginLeft: 'auto' }}> {moment(t.dueDate).format('LLL')}</span>
                    </a>
                  </div>
                ))}
                <div className={classes.actions}>
                  <a href={`/admin/tasks?pipeline_card_id=${pipelineCard.id}`}>
                    <Button size="small" className={classes.fullListButton}>
                      <span className="fas fa-edit" /> &nbsp; View Full List
                    </Button>
                  </a>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <NewTaskDialog
              openTask={openTask}
              activityTypes={activityTypes}
              pipelineCard={pipelineCard}
              refetch={refetch}
              currentInventoryUser={currentInventoryUser}
              openNewTaskCard={() => this.handleClickOpen()}
            />
          </div>
    );
  }
}
const styles = {
  header         : {
    padding : '20px 0 0 0',
    display : 'flex',
  },
  taskCard       : {
    padding    : '10px',
    display    : 'flex',
    alignItems : 'center',
  },
  actions        : {
    padding : '10px 0 0 0',
  },
  fullListButton : {
    color       : 'blue',
    marginRight : 'auto',
  },
  taskButton     : {
    color      : 'blue',
    marginLeft : 'auto',
  },
  icon           : {
    fontSize     : 22,
    padding      : 10,
    margin       : '0 8px 0 0',
    borderRadius : '50%',
    background   : 'lightgrey',
  },
  details        : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : 0,
  },
  panel          : {
    boxShadow    : 'none',
    borderBottom : '1px solid rgba(0, 0, 0, 0.12)',
  },
};

PipelineTaskTab.propTypes = {
  refetch              : PropTypes.func.isRequired,
  pipelineCard         : PropTypes.object,
  currentInventoryUser : PropTypes.object,
  activityTypes        : PropTypes.array,
  classes              : PropTypes.object.isRequired,
  pipeline             : PropTypes.object,
  data                 : PropTypes.object,
};

PipelineTaskTab.defaultProps = {
  pipelineCard         : {},
  currentInventoryUser : {},
  activityTypes        : [],
  pipeline             : {},
  data                 : {},
};

export default withStyles(styles)(PipelineTaskTab);
