import React, { useState } from "react";
import { connect } from "react-redux";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { isEmpty } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";

import PipelineByOwnerTotalReport from "../../components/Reports/PipelineByOwnerTotalReport";

const PipelineReportQuery = gql`
  query pipeline($id: ID, $status: String, $agentName: String, $limit: String) {
    pipelines {
      id
      name
      pipeline_stages {
        id
      }
      pipeline_cards {
        id
      }
      card_count
    }
    pipeline(id: $id) {
      id
      name
      card_count
      pipeline_cards {
        id
        value
        close_date
        status
        created_at
        owner {
          id
          name
        }
        pipeline_stage {
          id
          name
        }
      }
      pipeline_stages {
        id
        name
        card_count
        total_value
        pipeline_cards(status: $status, agentName: $agentName, limit: $limit) {
          owner {
            id
            name
          }
          id
          value
          close_date
          status
          created_at
        }
      }
    }
  }
`;

export const PipelineByOwnerTotalReportContainer = (props) => {
  const [data, setData] = useState({});
  return (
    <div>
      <div>
        <Query
          query={PipelineReportQuery}
          onCompleted={(d) => {
            if (isEmpty(data)) {
              setData({ ...d });
            }
          }}
          fetchPolicy="network-only"
          variables={{
            agentName: "",
            limit: "none",
            status: "all",
          }}
        >
          {({ loading, error, refetch }) => {
            if (error) return `Error!: ${error}`;
            if (!loading && data.pipeline) {
              return (
                <PipelineByOwnerTotalReport
                  setData={setData}
                  data={data}
                  refetch={refetch}
                  {...props}
                />
              );
            }
            return <CircularProgress />;
          }}
        </Query>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  startDate: state.dateRangeFilter.sendStart.format("YYYY-MM-DD"),
  endDate: state.dateRangeFilter.sendEnd.format("YYYY-MM-DD"),
});

export default connect(mapStateToProps)(PipelineByOwnerTotalReportContainer);
