import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles";
import {get, range} from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import {FormattedMessage, injectIntl} from "react-intl";
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const ContinuingPayments = ({list, query, classes, updateCfaQuery, intl}) => {
    const validate = () => {
        let valid = true;

        if(query.buyerHasContinuingPayments == undefined)
            valid = false;
        else if(query.buyerHasContinuingPayments) {
            if(!query.buyerContinuingPaymentYears || parseFloat(query.buyerContinuingPaymentYears) < 1) {
              valid = false;
            }

            if((query.buyerContinuingPayments || []).length < 1) {
              valid = false;
            }
            (query.buyerContinuingPayments || []).map((payment, i) => {
              if(parseFloat(payment) <= 0 ) {
                valid = false;
              }
            });

        }

        if(query.valid != valid) {
            updateCfaQuery({...query, valid});
        }
    }

    validate();

    return <div>
        <h2><FormattedMessage id="calculators.cfa.what_are_term_of_deal"/></h2>
        <p>
            <FormattedMessage id="calculators.cfa.cont_payment_copy"/>
        </p>
        <h3><FormattedMessage id="calculators.cfa.cont_payments"/></h3>

            <FormLabel component="legend"><FormattedMessage id="calculators.cfa.will_cont_payments"/></FormLabel>
            <RadioGroup aria-label="numberOfBuyers" name="numberOfBuyers" value={`${query.buyerHasContinuingPayments}`} onChange={e => {
                const continuingPayments = Boolean("true" == e.target.value);
                let clear = {};
                if(!continuingPayments) {
                    clear = {buyerContinuingPaymentAmount: 0, buyerContinuingPaymentYears: 0, buyerContinuingPaymentStartYear: 0, buyerContinuingPayments: []}
                }

                updateCfaQuery({...query, ...clear, buyerHasContinuingPayments: continuingPayments, })
            }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.yes_label"})} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.no_label"})} />
            </RadioGroup>
            {query.buyerHasContinuingPayments &&
              <div>
                  <dl>
                      <dt><FormattedMessage id="calculators.cfa.how_many_years_cont"/></dt>
                      <dd>
                          <select onChange={e => {
                              let buyerContinuingPayments = query.buyerContinuingPayments || []
                              if (query.buyerContinuingPaymentYears > e.target.value) {
                                buyerContinuingPayments = buyerContinuingPayments.slice(0, e.target.value);
                              } else if (query.buyerContinuingPaymentYears < e.target.value) {
                                buyerContinuingPayments = [...buyerContinuingPayments, ...Array(e.target.value - query.buyerContinuingPaymentYears).fill(0)];
                              }
                              updateCfaQuery({...query, buyerContinuingPaymentYears: parseInt(e.target.value), buyerContinuingPayments,})
                          }
                          }>
                              <option>{intl.formatMessage({id: "calculators.cfa.please_select_a_year"})}</option>
                              { range(1, 6, 1).map(year =>
                                <option value={year} checked={query.buyerContinuingPaymentYears== year}>{year}</option>
                              )}
                          </select>
                      </dd>
                      <dt><FormattedMessage id="calculators.cfa.cont_start_year"/></dt>
                      <dd>
                          <select onChange={e => {
                              updateCfaQuery({...query, buyerContinuingPaymentStartYear: parseInt(e.target.value)})
                          }
                          }>
                              <option>{intl.formatMessage({id: "calculators.cfa.please_select_a_year"})}</option>
                              { range(1, 6, 1).map(year =>
                                <option value={year} checked={query.buyerContinuingPaymentStartYear == year}>{year}</option>
                              )}
                          </select>
                      </dd>
                      <dt><FormattedMessage id="calculators.cfa.cont_payment_per_year"/></dt>
                      </dl>
                      <br />
                      <br />
                      {(query.buyerContinuingPaymentStartYear && query.buyerContinuingPaymentYears) && Array(query.buyerContinuingPaymentYears).fill(0).map((_, i) => <React.Fragment>
                          <dl key={`cpYear${i}`}>
                              <dt className={classes.sub_heading}>Year {query.buyerContinuingPaymentStartYear + i} <FormattedMessage id="calculators.cfa.amount" /></dt>
                              <dd>
                                  <FBPercentInput
                                    upper={i > 0 ? query.buyerContinuingPayments[i - 1] * 100 : 100}
                                    style={{width: 'inherit'}}
                                    value={query.buyerContinuingPayments[i]}
                                    onChange={val => {
                                      let payments = [...query.buyerContinuingPayments];
                                      payments[i] = val;
                                      updateCfaQuery({...query, buyerContinuingPayments: payments} )
                                    }}
                                  />
                              </dd>
                          </dl>
                          <br/>
                        </React.Fragment>)}
              </div>
            }
    </div>
};

ContinuingPayments.propTypes = {
    query: PropTypes.array,
    classes: PropTypes.object.isRequired,
};

ContinuingPayments.defaultProps = {
    list: [],
}

const styles = () => ({

});

const mapStateToProps = (state, props) => {
    return {
        result: {...get(state, 'cfa.result', {})},
        query: {...get(state, 'cfa.query', {})},
    };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(ContinuingPayments)));