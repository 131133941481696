import PropTypes from 'prop-types';
import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';
import InviteDialog from '../InviteDialog/InviteDialog';
import { get } from 'lodash';

class InviteButton extends React.Component {
  state = {
    inviteDialogOpen : false,
    inviteeId        : '',
    inviteeName      : '',
    resend           : false,
  };

  sendInvite = (invitee_id, inviteeName, resend) => {
    this.setState({ inviteDialogOpen: true, inviteeId: invitee_id, inviteeName, resend });
  };

  closeSendInvite = () => {
    this.setState({ inviteDialogOpen: false });
  };

  buttonType = (type, inviteeId, inviteeName, intl, classes, company) => {
    switch (type) {
      case 'invite':
        return (
          <Tooltip title="Click to invite this user to the marketplace" placement="bottom-start" enterDelay={500}>
            <Button
              className={classes.invite}
              variant="outlined"
              size="small"
              onClick={() => this.sendInvite(inviteeId, inviteeName, false)}>
              {intl.formatMessage({ id: `admin_inventory_user.${type}` }) || ''}
            </Button>
          </Tooltip>
        );
      case 'activated':
        return (
          <div>
            {get(company, 'business_type', '') !== 'wealth_rj' && (
              <Tooltip title="This user has activated their profile" placement="bottom-start" enterDelay={500}>
                <Chip
                  label={intl.formatMessage({ id: `admin_inventory_user.${type}` }) || ''}
                  className={classes.activated}
                  variant="outlined"
                />
              </Tooltip>
            )}
            {get(company, 'business_type', '') === 'wealth_rj' && (
              <Chip label="Joined" className={classes.joined} variant="outlined" />
            )}
          </div>
        );
      case 'joined':
        return (
          <div>
            {get(company, 'business_type', '') !== 'wealth_rj' && (
              <Tooltip
                title="This user has joined the marketplace but has not yet activated their account"
                placement="bottom-start"
                enterDelay={500}>
                <Chip
                  label={intl.formatMessage({ id: `admin_inventory_user.${type}` }) || ''}
                  className={classes.joined}
                  variant="outlined"
                />
              </Tooltip>
            )}
            {get(company, 'business_type', '') === 'wealth_rj' && (
              <Chip
                label={intl.formatMessage({ id: `admin_inventory_user.${type}` }) || ''}
                className={classes.joined}
                variant="outlined"
              />
            )}
          </div>
        );
      case 'pending':
        return (
          <Tooltip
            title="An invite has already been sent to this user. Click to resend invite email"
            placement="bottom-start"
            enterDelay={500}>
            <Button
              className={classes.pending}
              variant="outlined"
              size="small"
              onClick={() => this.sendInvite(inviteeId, inviteeName, true)}>
              {intl.formatMessage({ id: `admin_inventory_user.${type}` }) || ''}
            </Button>
          </Tooltip>
        );
      default:
        return <div />;
    }
  };

  render () {
    const {
      type,
      inviteeId,
      inviteeName,
      intl,
      classes,
      inventory_users,
      updateInventoryUser,
      updateMarketplaceStatus,
      updateAction,
      company,
    } = this.props;
    const { inviteDialogOpen, resend } = this.state;
    return (
      <div>
        {this.buttonType(type, inviteeId, inviteeName, intl, classes, company)}
        <InviteDialog
          inviteDialogOpen={inviteDialogOpen}
          inviteeId={inviteeId}
          inviteeName={inviteeName}
          sendInvite={this.sendInvite}
          resend={resend}
          inventory_users={inventory_users}
          updateInventoryUser={updateInventoryUser}
          updateAction={updateAction}
          closeSendInvite={this.closeSendInvite}
          updateMarketplaceStatus={updateMarketplaceStatus}
        />
      </div>
    );
  }
}

const styles = theme => ({
  activated   : {
    color       : 'limeGreen',
    borderColor : 'limeGreen',
    width       : '100%',
  },
  joined      : {
    color       : 'blue',
    borderColor : 'blue',
    width       : '100%',
  },
  pending     : {
    color       : 'orange',
    borderColor : 'orange',
    width       : '100%',
  },
  invite      : {
    color       : 'grey',
    borderColor : 'grey',
    width       : '100%',
  },
  text        : {
    fontSize : '18px',
  },
  iconBox     : {
    border    : '1px solid #EAECEE',
    textAlign : 'center',
    height    : '250px',
    width     : '200px',
    margin    : '20px',
  },
  iconBoxText : {
    fontSize   : '18px',
    textAlign  : 'center',
    fontWeight : 'bold',
    color      : 'dodgerblue',
  },
  center      : {
    textAlign : 'center',
  },
});

export default injectIntl(withStyles(styles)(InviteButton));
