import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import AddDealDialog from '../components/SuggestedDealsPanel/AddDealDialog';

class SuggestedDealModalContainer extends React.Component {
  selectReduxPipeline = pipeline_id => {
    const { suggestedDeal, updateSuggestedDeal, updateSelectedSuggestedDeal } = this.props;
    updateSuggestedDeal({ ...suggestedDeal, selected_pipeline: pipeline_id });
    updateSelectedSuggestedDeal({ ...suggestedDeal, selected_pipeline: pipeline_id });
  };

  render () {
    const {
      suggestedDeal,
      pipelineList,
      owner,
      deleteSuggestedDeal,
      closeAddDealDialog,
      updateSuggestedDealsList,
      updateSuggestedDeal,
      updateSelectedSuggestedDeal,
    } = this.props;
    const manager = {
      ...owner,
      label : get(owner, 'name'),
      value : parseInt(get(owner, 'id', ''), 10),
      id    : parseInt(get(owner, 'id', ''), 10),
    };
    return (
      <AddDealDialog
        suggestedDeal={suggestedDeal}
        inventoryUser={suggestedDeal}
        pipelineList={pipelineList}
        owner={manager}
        deleteSuggestedDeal={deleteSuggestedDeal}
        closeAddDealDialog={closeAddDealDialog}
        updateSuggestedDealsList={updateSuggestedDealsList}
        updateSuggestedDeal={updateSuggestedDeal}
        updateSelectedSuggestedDeal={updateSelectedSuggestedDeal}
        selectedDealType={get(suggestedDeal, 'deal_type', null)}
        selectPipelineFunc={e => {
          this.selectReduxPipeline(e);
        }}
      />
    );
  }
}

SuggestedDealModalContainer.propTypes = {
  suggestedDeal               : PropTypes.object.isRequired,
  closeAddDealDialog          : PropTypes.func.isRequired,
  updateSelectedSuggestedDeal : PropTypes.func.isRequired,
  pipelineList                : PropTypes.object.isRequired,
  owner                       : PropTypes.object.isRequired,
  deleteSuggestedDeal         : PropTypes.func.isRequired,
  updateSuggestedDeal         : PropTypes.func.isRequired,
  updateSuggestedDealsList    : PropTypes.func.isRequired,
};

export default SuggestedDealModalContainer;
