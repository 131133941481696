import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import numeral from 'numeral';
import { withStyles } from '@material-ui/core/styles';
import { sanitizeNumber } from '../../lib/findBobUtils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListPriceSelector from './ListPriceSelector';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import styled from 'styled-components';
import { get, capitalize } from 'lodash';
import { interestsChip } from '../InventoryUserDrawer/OverviewUserData';
import { FormattedMessage, injectIntl } from 'react-intl';

const changeField = ({ deal, updateState }) => name => event => {
  updateState({ ...deal, [name]: event.target.value });
};

const listPriceChange = ({ deal, updateState, saveDeal }) => name => event => {
  updateState({ ...deal, [name]: event.target.value });
  saveDeal({ [name]: event.target.value });
};

const numFieldChange = ({ deal, updateState }) => name => event => {
  let num = null;
  if (event.target.value !== '') {
    num = parseInt(sanitizeNumber(event.target.value), 10) || 1;
  }

  updateState({ ...deal, [name]: num });
};

export const DealForm = ({
  updateState,
  deal,
  classes,
  hideDeadline,
  user,
  saveDeal,
  stylesheetName,
  fromAdmin,
  intl,
}) => {
  const fieldChange = changeField({ deal, updateState });
  const callListPriceChange = listPriceChange({ deal, updateState, saveDeal });
  const numericFieldChange = numFieldChange({ deal, updateState });
  const matches = useMediaQuery('(min-width:1000px)');
  const hideListPrice =
    (get(deal, 'deal_type') === 'continuity' && get(user, 'company.hide_list_price_in_deal_room_wizard_continuity')) ||
    (get(deal, 'deal_type') === 'succession' && get(user, 'company.hide_list_price_in_deal_room_wizard_succession')) ||
    (get(deal, 'deal_type') === 'sale' && get(user, 'company.hide_list_price_in_deal_room_wizard_sale'));
  const hideBusinessOverviewQuestion = get(user, 'company.teaser_hide_business_overview_question');
  const hideAssetsQuestion = get(user, 'company.teaser_hide_assets_question');
  const dealOwner = { ...get(deal, 'owner', {}) };

  return (
    <Grid container={matches} spacing={3}>
      <Grid item xs={12} className={classes.gridItem}>
        {!hideDeadline && (
          <div>
            <TextField
              id="number_of_invites"
              label={intl.formatMessage({ id: 'dr_wizard.number_of_invites' })}
              type="number"
              value={deal.number_of_invites}
              onChange={numericFieldChange('number_of_invites')}
              margin="normal"
              size="medium"
              multiline
              rows={1}
              variant="filled"
              classes={{ root: classes.label, label: classes.label }}
              InputProps={{
                className : classes.whiteFilled,
              }}
              className={classes.whiteFilled}
              style={{ float: 'left', marginRight: 8 }}
            />
            <div style={{ float: 'left', width: 300, marginTop: 12 }}>
            <b style={{ fontSize: 11, color: 'rgba(0, 0, 0, 0.54)' }}><FormattedMessage id="dr_wizard.invitation_deadline"/></b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <br />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={deal.invitation_deadline}
                  onChange={date => {
                    updateState({
                      deal : { ...deal, deal: { ...deal, invitation_deadline: date } },
                    });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        )}
        {!hideDeadline && (
          <div
            style={{
              marginLeft : 16,
              float      : 'left',
              width      : 300,
              paddingTop : 6,
            }}>
            <ManagerSearchField
              label="Owner"
              value={dealOwner}
              onChange={v => {
                updateState({
                  ...deal,
                  owner : {
                    value           : get(v, 'value'),
                    id              : get(v, 'id'),
                    name            : get(v, 'name'),
                    label           : get(v, 'name'),
                    has_user        : true,
                    profile_picture : get(v, 'profile_picture'),
                  },
                  deal  : {
                    ...get(deal, 'deal'),
                    owner : {
                      value           : get(v, 'value'),
                      id              : get(v, 'id'),
                      name            : get(v, 'name'),
                      label           : get(v, 'name'),
                      has_user        : true,
                      profile_picture : get(v, 'profile_picture'),
                    },
                  },
                });
              }}
            />
          </div>
        )}
        <div style={{ clear: 'both' }}>
          {!hideDeadline && (
            <div>
              <hr />
              <h2 className={classes.gridItem}><FormattedMessage id="dr_wizard.teaser"/></h2>
            </div>
          )}
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={`${intl.formatMessage({ id: 'dr_wizard.teaser_headline_max' })}`}
            helperText={
              <span>
                <b>
                  <FormattedMessage id="dr_wizard.here_are_some_good_examples" />{' '}
                </b>
                <FormattedMessage id="dr_wizard.successor_required" /> {get(deal, 'headline.length', '0')}/{100}
              </span>
            }
            value={deal.headline}
            onChange={fieldChange('headline')}
            margin="normal"
            rows="2"
            fullWidth
            multiline
            size="medium"
            variant="filled"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 100,
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>
        {!hideBusinessOverviewQuestion && (
          <div>
            <StyledTextField
              rjpe={stylesheetName === 'rjpe'}
              label={`${intl.formatMessage({ id: 'dr_wizard.business_owner' })}`}
              helperText={`${intl.formatMessage({ id: 'dr_wizard.keep_the_readers_attention' })}`}
              value={deal.sub_headline}
              onChange={fieldChange('sub_headline')}
              margin="normal"
              size="medium"
              multiline
              rows="4"
              fullWidth
              variant="filled"
              classes={{ root: classes.label, label: classes.label }}
              InputProps={{
                className : classes.whiteFilled,
              }}
              onBlur={() => saveDeal()}
              InputLabelProps={{
                classes : {
                  root    : classes.inputLabel,
                  focused : 'focused',
                  shrink  : 'shrink',
                },
              }}
              FormHelperTextProps={{
                className : classes.helperText,
              }}
            />
          </div>
        )}
        {!hideListPrice && (
          <div>
            <br />
            <ListPriceSelector
              deal={deal}
              user={user}
              numericFieldChange={numericFieldChange}
              fieldChange={callListPriceChange}
              saveDeal={saveDeal}
              updateState={updateState}
            />
          </div>
        )}
        <div>
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={`${intl.formatMessage({ id: 'dr_wizard.summary_of_business' })}`}
            value={deal.summary_of_business}
            onChange={fieldChange('summary_of_business')}
            helperText={`${intl.formatMessage({ id: 'dr_wizard.provide_a_detailed_description' })}`}
            margin="normal"
            multiline
            fullWidth
            size="medium"
            variant="filled"
            rows="5"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 500,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>

        <div>
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={`${intl.formatMessage({ id: 'dr_wizard.describe_your_ideal_candidate' })}`}
            value={deal.ideal_candidate}
            onChange={fieldChange('ideal_candidate')}
            helperText={`${intl.formatMessage({ id: 'dr_wizard.indicate_the_qualities' })}`}
            margin="normal"
            multiline
            fullWidth
            size="medium"
            variant="filled"
            rows="5"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 500,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>

        <div>
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={intl.formatMessage({ id: 'dr_wizard.growth_opportunities' })}
            helperText={
              <span>
                <FormattedMessage id="dr_wizard.describe_how_your_business"/>
              </span>
            }
            value={deal.growth_opportunities}
            onChange={fieldChange('growth_opportunities')}
            margin="normal"
            multiline
            size="medium"
            fullWidth
            variant="filled"
            rows="5"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 500,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>

        {!hideAssetsQuestion && (
          <div>
            <StyledTextField
              rjpe={stylesheetName === 'rjpe'}
              label={intl.formatMessage({ id: 'dr_wizard.assets_included' })}
              helperText={intl.formatMessage({ id: 'deal_page.are_there_any_tangibles_included_in_the_deal' })}
              value={deal.assets_included}
              onChange={fieldChange('assets_included')} 
              multiline
              fullWidth
              size="medium"
              rows="5"
              margin="normal"
              variant="filled"
              classes={{ root: classes.label, label: classes.label }}
              InputProps={{
                className : classes.whiteFilled,
              }}
              onBlur={() => saveDeal()}
              inputProps={{
                maxLength : 500,
              }}
              InputLabelProps={{
                classes : {
                  root    : classes.inputLabel,
                  focused : 'focused',
                  shrink  : 'shrink',
                },
              }}
              FormHelperTextProps={{
                className : classes.helperText,
              }}
            />
            <StyledTextField
              rjpe={stylesheetName === 'rjpe'}
              label={intl.formatMessage({ id: 'deal_page.intangible_assets_included' })}
              helperText={intl.formatMessage({ id: 'deal_page.goodwill_brand_recognition' })}
              value={deal.intangible_assets_included}
              onChange={fieldChange('intangible_assets_included')}
              fullWidth
              size="medium"
              multiline
              variant="filled"
              rows="5"
              margin="normal"
              classes={{ root: classes.label, label: classes.label }}
              InputProps={{
                className : classes.whiteFilled,
              }}
              onBlur={() => saveDeal()}
              inputProps={{
                maxLength : 500,
              }}
              InputLabelProps={{
                classes : {
                  root    : classes.inputLabel,
                  focused : 'focused',
                  shrink  : 'shrink',
                },
              }}
              FormHelperTextProps={{
                className : classes.helperText,
              }}
            />
          </div>
        )}

        <div>
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={
              get(user, 'company.business_type') === 'wealth_rj' ? (
                <FormattedMessage id="dr_wizard.client_demographics" />
              ) : (
                <FormattedMessage id="dr_wizard.customer_demographics" />
              )
            }
            helperText={`${intl.formatMessage({ id: 'dr_wizard.characteristics_of_your_client' })}`}
            value={deal.customer_demographics_comments}
            fullWidth
            onChange={fieldChange('customer_demographics_comments')}
            multiline
            size="medium"
            variant="filled"
            rows="4"
            margin="normal"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 500,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>

        <div>
          <StyledTextField
            rjpe={stylesheetName === 'rjpe'}
            label={intl.formatMessage({ id: 'dr_wizard.products_and_services' })}
            helperText={intl.formatMessage({ id: 'deal_page.describe_what_lines_of_business' })}
            value={deal.products_and_services_comments}
            onChange={fieldChange('products_and_services_comments')}
            multiline
            filled
            variant="filled"
            fullWidth
            size="medium"
            rows="4"
            margin="normal"
            classes={{ root: classes.label, label: classes.label }}
            InputProps={{
              className : classes.whiteFilled,
            }}
            onBlur={() => saveDeal()}
            inputProps={{
              maxLength : 500,
            }}
            InputLabelProps={{
              classes : {
                root    : classes.inputLabel,
                focused : 'focused',
                shrink  : 'shrink',
              },
            }}
            FormHelperTextProps={{
              className : classes.helperText,
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

const styles = {
  gridItem            : {
    padding : 12,
  },
  whiteFilled         : {
    background     : 'white',
    margin         : 8,
    '&$cssFocused' : {
      background : 'white',
    },
    fontSize       : 16,
  },
  adorn               : { paddingBottom: 14 },
  label               : {
    fontSize      : 12,
    paddingBottom : 8,
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  inputLabel          : {
    '&.focused' : {
      color : '#002949',
    },
    '&.shrink'  : {
      transform : 'translate(12px, 12px) scale(0.95)',
    },
  },
  helperText          : {
    color    : '#002949',
    fontSize : 15,
  },
};

const StyledTextField = styled(TextField)`

  ${props =>
    props.rjpe &&
    `&& .MuiFilledInput-underline:after {
      border-color: #002949 !important;
    }
    && .MuiInputLabel-animated {
      border-color: #002949 !important;
    }
    `};
`;


const DealFormWithStyles = withStyles(styles)(DealForm);
export default injectIntl(DealFormWithStyles);

