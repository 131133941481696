/* eslint-disable no-case-declarations */

import { findIndex } from 'lodash';
import {
  PIPELINE_UPDATE,
  PIPELINE_CARD_UPDATE,
  PIPELINE_CARD_ADD,
  CHANGE_PIPELINE_STATE,
} from '../constants/pipelineConstants';

const pipelineReducer = (
  state = { pipeline_stages: [], pipeline_cards: [], pipeline_state: { dragging: false } },
  action
) => {
  switch (action.type) {
    case PIPELINE_UPDATE:
      return { ...action.pipeline };
    case PIPELINE_CARD_UPDATE:
      const cardIndex = findIndex(state.pipeline_cards, [ 'id', action.pipelineCard.id ]);
      const cardList = state.pipeline_cards;
      if (cardIndex > -1) {
        cardList[cardIndex] = action.pipelineCard;
      }

      return { ...state, pipeline_cards: cardList };
    case PIPELINE_CARD_ADD:
      const cards = state.pipeline_cards;
      cards.push(action.pipelineCard);
      return { ...state, pipeline_cards: cards };
    case CHANGE_PIPELINE_STATE:
      return { ...state, pipeline_state: { ...state.pipeline_state, ...action.pipelineState } };
    default:
      return state;
  }
};

export default pipelineReducer;
