import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import PracticeInformation from './PracticeInformation';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $annual_revenue: Float
    $year_career_began: Int
    $years_practicing: Int
    $number_of_clients: Int
    $avg_client_age: Int
    $recurring_revenue: Float
    $percent_commission: Float
    $percent_fee_based: Float
    $planning_preference: String
    $fee_based_revenue: Float
    $trailer_revenue: Float
    $direct_revenue: Float
    $transactional_revenue: Float
    $transition_goals: [String!]
    $locale: String
  ) {
    updateCurrentUser(
      annual_revenue: $annual_revenue
      year_career_began: $year_career_began
      years_practicing: $years_practicing
      number_of_clients: $number_of_clients
      avg_client_age: $avg_client_age
      recurring_revenue: $recurring_revenue
      percent_commission: $percent_commission
      percent_fee_based: $percent_fee_based
      planning_preference: $planning_preference
      transition_goals: $transition_goals
      fee_based_revenue: $fee_based_revenue
      trailer_revenue: $trailer_revenue
      direct_revenue: $direct_revenue
      transactional_revenue: $transactional_revenue
      locale: $locale
    )
  }
`;

export default graphql(UpdateCurrentUser)(PracticeInformation);
