import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, replace } from 'lodash';
import CheckButton from '../../CheckButton/CheckButton';
import InputSlider from '../../InputSlider/InputSlider';
import { S3Image } from '../../../lib/s3';
import { spotlightElement } from '../../../lib/findBobUtils';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { uniq, toLower, capitalize, snakeCase } from 'lodash';

const styles = theme => ({
  formGroup         : {
    border       : '1px solid #dadada',
    padding      : '8px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle        : {
    paddingLeft : '6px',
  },
  avatarButton      : {
    margin       : '4px',
    padding      : 0,
    borderRadius : '2px',
  },
  selected          : {
    boxShadow : 'inset 0 0 0 4px #e1e1e1',
    border    : '6px solid #3f51b5',
    margin    : '0px',
  },
  avatarButtonImage : {
    width  : '70px',
    height : '50px',
  },
});

const PrimaryMarkets = ({
  userPrimaryMarkets,
  togglePrimaryMarkets,
  intl,
  availablePrimaryMarkets,
  locale,
  stylesheetName,
}) => {
  let managementCategories = [ ...availablePrimaryMarkets ];

  userPrimaryMarkets.forEach(primary_market => {
    managementCategories.push(primary_market);
  });

  const labels = {};
  availablePrimaryMarkets.map(s => {
    labels[s] = capitalize(intl.formatMessage({ id: `sign_up_pages.${s}` }));
  });

  managementCategories = uniq(managementCategories.map(c => snakeCase(c)));
  return (
    <div onMouseOver={e => spotlightElement('.the-tag-list')}>
      <div>
        <br />
        <br />
        {managementCategories.map(category => (
          <CheckButton
            key={category}
            stylesheetName={stylesheetName}
            label={labels[category] || category}
            checked={findIndex(userPrimaryMarkets, o => snakeCase(o) === category) > -1}
            value={snakeCase(category)}
            onClick={togglePrimaryMarkets}
          />
        ))}
      </div>
    </div>
  );
};

PrimaryMarkets.propTypes = {
  updateAction : PropTypes.func,
};

PrimaryMarkets.defaultProps = {
  number_of_clients : 500,
};

export default injectIntl(withStyles(styles)(PrimaryMarkets));
