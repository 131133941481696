import React from 'react';
import { connect } from 'react-redux';
import { updateIntl } from 'react-intl-redux';
import translations from '../../lib/i18n/translations.json';
import { upperCase, lowerCase, isEmpty } from 'lodash';

const mapStateToProps = state => ({
  currentLocale : state.intl.locale,
  store         : state,
});

const mapDispatchToProps = dispatch => ({
  changeLocale : e =>
    dispatch(
      updateIntl({
        locale   : lowerCase(e.target.value),
        messages : translations[lowerCase(e.target.value)],
      })
    ),
});

const SwitchLocale = ({ currentLocale, locales, store, changeLocale, updateAction }) => {
  if (isEmpty(locales) || locales.length < 2) return <span />;
  return (
    <select
      className="form-control"
      value={upperCase(currentLocale)}
      onChange={e => {
        changeLocale(e);
        updateAction({ locale: lowerCase(e.target.value) });
        if (lowerCase(e.target.value) === 'fr') {
          const logo = document.querySelector('.logo img');
          logo.src = logo.src.replace('.png', '_FR.png');
        } else {
          const logo = document.querySelector('.logo img');
          logo.src = logo.src.replace('_FR', '');
        }
        window.intercomSettings = {
          ...window.intercomSettings,
          language_override : lowerCase(e.target.value),
        };
        Intercom('boot', window.intercomSettings);
      }}>
      {locales.map(locale => <option key={locale}>{upperCase(locale)}</option>)}
    </select>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchLocale);
