/* eslint-disable import/prefer-default-export */

import axios from 'axios';

import { USER_UPDATE, PRODUCT_MIX_UPDATE } from '../constants/userConstants';

export const updateUser = user => ({
  type: USER_UPDATE,
  user,
});

export const updateProductMix = product_mix => ({
  type: PRODUCT_MIX_UPDATE,
  product_mix,
});
