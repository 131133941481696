import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';


const DefinedServiceModel = ({ classes, user, obfuscateView, intl }) => {
  if (user) {
    return (     
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.defined_service_model"/></strong>
        {!obfuscateView && <p>{get(user, 'defined_service_model') ? <FormattedMessage id="profile_page.yes_discretionary_investment_models"/> : <FormattedMessage id="profile_page.no_discretionary_investment_models"/>}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default injectIntl(withStyles(styles)(DefinedServiceModel));
