import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import FBTextArea from "../FBTextArea/FBTextArea";
import Button from "@material-ui/core/Button";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { debounce, get } from "lodash";

const UpdateCandidate = gql`
  mutation updateCandidate($description: String!) {
    updateCandidate(description: $description)
  }
`;

const styles = {
  container: {
    padding: 30,
  },
  nextBox: {
    margin: "30px auto 0px",
    display: "table",
  },
};

class CandidateDescription extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { description: "" };

    this.onClick = debounce(this.onClick, 300);
  }

  onClick = () => {
    const { mutate, onNext, opportunity } = this.props;
    const { description } = this.state;
    mutate({ variables: { description } });
    onNext();
  };

  render() {
    const { classes, company } = this.props;
    return (
      <div className={classes.container}>
        <p>
          Anything else you’d like us to know about you and why you’d be a good
          fit for our {get(company, "recruitment_organization_word")}.
          <br />
          <br />
        </p>
        <FBTextArea
          placeholder=""
          onChange={(e) => {
            this.setState({ description: e.target.value });
          }}
          shouldFitContainer
          rowsMin={15}
          rows={15}
          isLabelHidden
          compact
        />
        <div className={classes.nextBox}>
          <Button variant="contained" color="primary" onClick={this.onClick}>
            Next!
          </Button>
        </div>
      </div>
    );
  }
}
CandidateDescription.propTypes = {
  mutate: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  opportunity: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default graphql(UpdateCandidate)(
  withStyles(styles)(CandidateDescription)
);
