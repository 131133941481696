import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import TipViewUpdateProfile from '../components/TipViewUpdateProfile/TipViewUpdateProfile';
import DashboardQueries from '../components/Dashboard/Dashboard.graphql';
import * as actions from '../actions/companyActionCreators';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import * as userActions from '../actions/userActionCreators';
import { get, isEmpty } from 'lodash';
import ProfileDrawerWithListener from './ProfileDrawerWithListener';

const getUser = gql`
  query getCurrentUser {
    getCurrentUser {
      headline
      locale
      bio
      ask_me_about
      transition_goals
    }
    company {
      country
      has_county
      agent_word
      business_type
      ask_me_about_question
    }
  }
`;

const TipViewUpdateProfileContainer = ({
  updateUser,
  user,
  company,
  updateCompany,
}) => {
  const [ currentUserAllowed, setCurrentUserAllowed ] = useState(false);
  const [ data, setData ] = useState({});
  return (
    <Query
      query={getUser}
      onCompleted={returnData => {
        updateUser(get(returnData, 'getCurrentUser'));
        updateCompany(get(returnData, 'company'));
      }}>
      {({ refetch, loading, data }) => {
        if (loading) {
          return <div>Loading...</div>;
        }
        return (
          <div>
            <ProfileDrawerWithListener />
            <TipViewUpdateProfile
              refetch={refetch}
              user={data.getCurrentUser}
              updateAction={updateUser}
              company={company}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => {
  return ({
  user : state.user,
  company : state.company,
})
};

TipViewUpdateProfileContainer.propTypes = {
  user                        : PropTypes.object,
  updateUser                  : PropTypes.func.isRequired,
  company                     : PropTypes.object,
};

TipViewUpdateProfileContainer.defaultProps = {
  user : {},
  company : {},
};

export default connect(mapStateToProps, {
  ...actions,
  ...userActions,
})(TipViewUpdateProfileContainer);
