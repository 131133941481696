import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as actions from '../actions/userActionCreators';
import EditAdvisorLicenseComponent from '../components/SignUpWizard/EditAdvisorLicenseComponent';
import Designations from '../components/SignUpWizard/StrengthsAndAvatar/Designations';
import { get, findIndex } from 'lodash';
import { FormattedMessage } from 'react-intl';

const EducationQueries = gql`
  query AdvisorLicensesQueries {
    getCurrentUser {
      user_name
      advisor_licenses
      designations
      company {
        id
        stylesheet_name
        iiroc_licensing_options
      }
    }
    availableAdvisorLicenses
    availableDesignations {
      id
      name
    }
  }
`;

const ProfileAdvisorLicenseComponent = ({ user, updateUser }) => {
  let [ designations, setDesignations ] = useState([]);
  let [ gotDesignations, setGotDesignations ] = useState(false);
  return (
    <Query query={EducationQueries} onCompleted={data => updateUser(data.getCurrentUser)}>
      {({ loading, error, data, refetch }) => {
        if (!loading && get(data, 'getCurrentUser')) {
          if (
            designations.length == 0 &&
            get(data, 'getCurrentUser.designations', []).length > 0 &&
            gotDesignations === false
          ) {
            setDesignations(get(data, 'getCurrentUser.designations', []));
            setGotDesignations(true);
          }

          return (
            <div>
              <b><FormattedMessage id="profile_page.practice_info_section.designations" /></b>
              <input type="hidden" name="user[designations]" value={designations} />
              <br />
              <br />
              <Designations
                locale="en"
                availableDesignations={get(data, 'availableDesignations')}
                designations={designations}
                toggleDesignations={e => {
                  let d = [ ...designations ];

                  const index = findIndex(d, o => o === e);
                  if (index > -1) {
                    d.splice(index, 1);
                  } else {
                    d.push(e);
                  }
                  setDesignations(d);
                  updateUser({ designations: d });
                }}
                updateAction={updateUser}
                stylesheetName={get(user, 'company.stylesheet_name')}
              />
              <br />
              <br />
              <b><FormattedMessage id="profile_page.practice_info_section.advisor_licenses" /></b>
              <br />
              <br />
              <EditAdvisorLicenseComponent
                availableAdvisorLicenses={get(data, 'availableAdvisorLicenses')}
                updateAction={updateUser}
                userAdvisorLicenses={get(user, 'advisor_licenses')}
                userName={get(user, 'user_name')}
                user={{ ...get(user, 'user_name'), designations }}
                stylesheetName={get(user, 'company.stylesheet_name')}
                iirocLicenseOptions={get(user, 'company.iiroc_licensing_options')}
              />
            </div>
          );
        }
        return <div />;
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  user : state.user,
});

ProfileAdvisorLicenseComponent.propTypes = {
  user       : PropTypes.object.isRequired,
  updateUser : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(ProfileAdvisorLicenseComponent);
