import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { get, debounce, map, find, isEmpty } from 'lodash';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FBTable from '../FBTable/FBTable';
import TaskDrawer from '../Tasks/TaskDrawer';
import NewTaskDialog from '../Pipelines/PipelineTaskCard/NewTaskDialog';
import { updateTaskMutation, deleteTaskMutation } from './TaskQuery';

class Tasks extends React.Component {
  changePage = debounce(vars => {
    const { refetch, updateState, loadTasks, page, limit, userID } = this.props;

    const variables = { page, userID, ...vars };
    refetch({ ...variables })
      .then(response => {
        if (response.data) {
          loadTasks({ tasks: response.data.getTasks, ...variables });
          updateState({ ...variables });
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, 300);

  filterManagers = () => {
    const { managers, currentUser } = this.props;
    if (!isEmpty(managers) && currentUser) {
      return managers.filter(m => m.id !== currentUser);
    }
    return [];
  };

  render () {
    const {
      data,
      activityTypes,
      refetch,
      updateState,
      filteredColumns,
      page,
      columns,
      task,
      filterList,
      orderBy,
      order,
      userID,
      currentUser,
      currentInventoryUser,
      pipelineCardId,
      updateTaskCard,
      taskCardTask,
      updateTaskList,
      addTask,
      loadTasks,
      rowsPerPage,
      count,
    } = this.props;

    const filteredManagers = this.filterManagers();

    return (
      <div>
        <Mutation mutation={updateTaskMutation}>
          {updateTask => (
            <TaskDrawer
              data={data}
              mutation={updateTask}
              pipelineTask={task}
              activityTypes={activityTypes}
              refetch={refetch}
              updateState={updateState}
              updateTaskCard={updateTaskCard}
              taskCardTask={taskCardTask.task}
              updateTaskList={updateTaskList}
              onClose={() => updateTaskCard({ task: {} })}
            />
          )}
        </Mutation>
        <Mutation mutation={deleteTaskMutation}>
          {deleteTask => (
            <FBTable
              data={data}
              columns={filteredColumns}
              allColumns={columns}
              showFilterDialog={false}
              rowsPerPage={rowsPerPage}
              filterList={filterList}
              page={page}
              order={order}
              orderBy={orderBy}
              allowDelete
              hideColumnSelect
              onDelete={taskId => {
                deleteTask({ variables: taskId }).then(() => {
                  refetch().then(response => {
                    loadTasks({
                      tasks : get(response, 'data.getTasks'),
                    });
                  });
                });
              }}
              heading={() => (
                <span>
                  <Select
                    value={userID}
                    displayEmpty
                    onChange={e => {
                      this.changePage({ userID: e.target.value });
                    }}>
                    <MenuItem value="">All Tasks</MenuItem>
                    <MenuItem value={`${currentUser}`}>My Tasks</MenuItem>
                    {filteredManagers.map(m => <MenuItem value={m.id}>{m.name}&apos;s Tasks</MenuItem>)}
                  </Select>&nbsp;
                  {pipelineCardId && (
                    <Chip
                      label={`Pipeline: ${pipelineCardId}`}
                      onDelete={() => {
                        updateState({ pipelineCardId: null, cardDeleted: true });
                      }}
                    />
                  )}
                </span>
              )}
              rowClick={(r, t) => {
                if (r.target.type === 'checkbox') return;

                updateState({ task: t });
                updateTaskCard({
                  task : t,
                });
              }}
              count={count || 0}
              onSort={(e, colName) => {
                let newOrder = 'desc';

                if (orderBy === colName && order === 'desc') {
                  newOrder = 'asc';
                }

                this.changePage({ orderBy: colName, order: newOrder });
              }}
              changePage={(r, p) => {
                this.changePage({ page: p });
              }}
              changeRowsPerPage={rowsPerPage => {
                this.changePage({ rowsPerPage });
              }}
              onFilterChange={(r, m) => {
                const list = filterList || [];
                list[r.id] = m;
                const compactedList = {};
                map(list, (v, k) => {
                  const ransack_filter = get(find(columns, [ 'id', k ]), 'ransack_filter');
                  if (ransack_filter) {
                    return (compactedList[ransack_filter] = v);
                  }
                  return null;
                });

                this.changePage({ ...compactedList, filterList: list });
              }}
              onSearch={r => {
                this.changePage({ name_or_inventory_user_name_or_pipeline_card_name_cont: r });
                updateState({ name_cont: r });
              }}
              bulkEditDialog={r => {}}
              updateColumns={r => {
                updateState({ filteredColumns: columns.filter(c => r.includes(c.id)) });
              }}
              exportToCSVButton={() => {}}
            />
          )}
        </Mutation>
        <div style={{ position: 'absolute', bottom: 145, right: 32 }}>
          <NewTaskDialog
            buttonType="contained"
            refetch={() => {
              refetch().then(response => {
                addTask(response.data.getTasks[0]);
              });
            }}
            activityTypes={activityTypes}
            currentInventoryUser={currentInventoryUser}
          />
        </div>
      </div>
    );
  }
}

Tasks.propTypes = {
  data                 : PropTypes.object,
  activityTypes        : PropTypes.array,
  refetch              : PropTypes.func.isRequired,
  updateState          : PropTypes.func.isRequired,
  filteredColumns      : PropTypes.array,
  page                 : PropTypes.number,
  limit                : PropTypes.number,
  columns              : PropTypes.array,
  task                 : PropTypes.object,
  filterList           : PropTypes.array,
  orderBy              : PropTypes.string,
  order                : PropTypes.string,
  userID               : PropTypes.number,
  currentUser          : PropTypes.object,
  currentInventoryUser : PropTypes.object,
  managers             : PropTypes.array,
  pipelineCardId       : PropTypes.number.isRequired,
  updateTaskCard       : PropTypes.func.isRequired,
  taskCardTask         : PropTypes.object,
  updateTaskList       : PropTypes.func.isRequired,
  addTask              : PropTypes.func.isRequired,
  loadTasks            : PropTypes.func.isRequired,
  rowsPerPage          : PropTypes.number.isRequired,
  count                : PropTypes.number.isRequired,
};

Tasks.defaultProps = {
  data                 : {},
  activityTypes        : [],
  filteredColumns      : [],
  page                 : 0,
  limit                : 10,
  columns              : [],
  task                 : {},
  taskCardTask         : {},
  filterList           : [],
  orderBy              : 'id',
  order                : 'desc',
  userID               : 'all',
  currentUser          : {},
  currentInventoryUser : {},
  managers             : [],
};

export default Tasks;
