import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';

const SideSpecializedBusinesses = ({ classes, user, obfuscateView }) => {
  if (!isEmpty(get(user, 'specialized_businesses'))) {
    const specializedBusinesses = get(user, 'specialized_businesses', []).map(p => capitalize(p.replaceAll('_', ' ')));
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.specialized_businesses"/></strong>
        {!obfuscateView && (
          <p>
            {specializedBusinesses.map(s => (
              <span>
                {s}
                <br />
              </span>
            ))}
          </p>
        )}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(SideSpecializedBusinesses);
