import PropTypes from 'prop-types';
import React from 'react';
import SignupReport from '../../containers/SignupReportContainer';
import DateRangeFilterContainer from '../../containers/DateRangeFilterContainer';

const SignupReportFull = ({ classes, data }) => {
  return (
    <div>
      <div>
        <DateRangeFilterContainer />
        <SignupReport bigCard />
      </div>
    </div>
  );
};

SignupReportFull.propTypes = {
  classes : PropTypes.object,
};

export default SignupReportFull;
