import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Tooltip from "@material-ui/core/Tooltip";
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";
import { Card } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

const ConsultingAgreements = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');

  let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(2);
  if (isNaN(consultingPercentOfDeal)) {
    consultingPercentOfDeal = 0;
  }
  let consultingAnnualCompensation = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.consultingLength)).toFixed(2);
  if (isNaN(consultingAnnualCompensation)) {
    consultingAnnualCompensation = 0;
  }

  return (
    <React.Fragment>
      {(query.purchasePrice && showConsultingAgreements) && (query.hasConsultingAgreement || query.consultingTotalCompensation > 0) && <React.Fragment>
        <div>
          <>
            <div className={classes.header}>Consulting Agreement</div>
            <Grid container>
              <Grid item>

                <b className={classes.sub_heading}>Total Compensation</b>

                <CurrencyInput
                  className={classes.currency}
                  value={query.consultingTotalCompensation}
                  onValueChange={value => {
                    updateCfaQuery({ ...query, consultingTotalCompensation: parseFloat(value) })
                  }}
                />
              </Grid>
              <Grid item>
                <b className={classes.sub_heading}>% of Deal</b>

                <TextField className={classes.percentage} disabled value={`${consultingPercentOfDeal}%`} />
              </Grid>
              <br /><br />
              <Grid item>
                <b className={classes.sub_heading}>Length (Years)</b>
                <select value={query.consultingLength} onChange={e => {
                  updateCfaQuery({ ...query, consultingLength: parseInt(e.target.value) })
                }
                }>
                  <option>Please Select a Year</option>
                  {range(1, query.cashFlowLength, 1).map(year =>
                    <option value={year} checked={query.consultingLength == year}>{year}</option>
                  )}
                </select>
              </Grid>
              <br /><br />
              <Grid item>
                <b className={classes.sub_heading}>Annual Compensation</b>
                <CurrencyInput
                  className={classes.currency}
                  value={consultingAnnualCompensation}
                  disabled
                />
              </Grid>
              {/*
          <Grid item>
              <b className={classes.sub_heading}>Consulting included in List Price</b>
              <dd className='right'>
                  <RadioGroup
                  row
                  value={`${query.consultingIncludedInListPrice}`}
                  onChange={e => {
                      const consultingIncludedInListPrice = Boolean("true" == e.target.value);
                      updateCfaQuery({...query, consultingIncludedInListPrice, })
                  }}>
                      <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.yes_label"})} />
                      <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({id: "calculators.cfa.no_label"})} />
                  </RadioGroup>
              </dd>
          </Grid>   
          */}
            </Grid>
          </>
        </div>
      </React.Fragment>}
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(ConsultingAgreements));