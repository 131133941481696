import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isEmpty } from 'lodash';
import moment from 'moment';
import MessagesPage from '../components/MessagesPage/MessagesPage';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';

const ConversationsQuery = gql`
  {
    getPersonalConversations {
      id
      conversationType
      __typename
      deal {
        id
        closed
        __typename
      }
      dealInterest {
        id
        __typename
        deal {
          id
          closed
          __typename
        }
      }
      participant1 {
        id
        allowed_name
        can_view_real_name
        user_name
        profile_picture
        deleted
        hidden
        minimum_profile_completed
        __typename
        inventory_user {
          segmented_view
        }
      }
      participant2 {
        id
        allowed_name
        can_view_real_name
        user_name
        profile_picture
        deleted
        hidden
        minimum_profile_completed
        __typename
        inventory_user {
          segmented_view
        }
      }
      blockedByParticipant1
      blockedByParticipant2
      lastMessages(limit: 1) {
        id
        body
        createdAt
        messageType
        answerOptions
        __typename
        sender {
          id
          allowed_name
          user_name
          __typename
        }
      }
      partialSaleListingUser {
        id
        partial_sale_listing {
          id
          asking_price
          segment_details
          number_of_clients
          fee_based_revenue
          sale_status
          trails_revenue
          direct_revenue
          commission_revenue
          description
          assets_under_management
          friendly_name
          __typename
        }
      }
    }
    getCurrentUser {
      id
      name
      is_admin
      company {
        id
        __typename
        allow_blocking_in_messages
        segmented_views_enforced
        partial_sale_name
        feature_types
      }
      inventory_user {
        segmented_view
      }
      __typename
    }
    companyHasNetworking
    companyHasNewMessages
    companyHasFeature(featureName: "deal_tracking")
  }
`;

const sortConversations = convos =>
  convos
    .filter(c => (!isEmpty(c.lastMessages) || c.conversationType !== 'personal_sale_listing' ))
    .sort((a, b) => (moment(a.lastMessages.createdAt) > moment(b.lastMessages.createdAt) ? 1 : -1));

export const MessagesContainer = props => {
  const { updateSelectedProfileDrawer, profile } = props;
  return (
    <div>
      <Query query={ConversationsQuery}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div style={{ margin: '25% 0 25% 50%' }}>
                <CircularProgress />
              </div>
            );
          if (error) return 'We are having issues retrieving your conversations.';
          return (
            <MessagesPage
              conversations={sortConversations(data.getPersonalConversations)}
              currentUser={data.getCurrentUser}
              companyHasNetworking={data.companyHasNetworking}
              companyHasNewMessages={data.companyHasNewMessages}
              companyHasFeature={data.companyHasFeature}
              updateSelectedProfileDrawer={updateSelectedProfileDrawer}
              profile={profile}
              company={data.company}
            />
          );
        }}
      </Query>
    </div>
  );
};

const mapStateToProps = state => ({
  profile : state.profileDrawer,
});

export default connect(mapStateToProps, profileDrawerActions)(MessagesContainer);
