import { findIndex } from 'lodash';
import {
  SUGGESTED_USER_LIST_UPDATE,
  SUGGESTED_USER_UPDATE,
  UPDATE_SELECTED_SUGGESTED_USER,
} from '../constants/suggestedUserConstants';

const updateSuggestedUserFunc = (suggestedUsersList, suggestedUser, modified = true) => {
  const index = findIndex(suggestedUsersList, e => e.id === suggestedUser.id);
  const suggestedUsersListNew = suggestedUsersList;
  const suggestedUserNew = suggestedUser;
  if (index !== -1) {
    if (modified) suggestedUserNew.modified = true;
    else suggestedUserNew.modified = false;
    suggestedUsersListNew[index] = suggestedUserNew;
  }
  return suggestedUsersListNew;
};

const suggestedUserReducer = (
  state = {
    suggestedUsers : [],
    suggestedUser  : {},
    loaded         : false,
  },
  action
) => {
  switch (action.type) {
    case SUGGESTED_USER_LIST_UPDATE:
      return { ...state, ...action.suggestedUsers, loaded: true };
    case SUGGESTED_USER_UPDATE:
      return {
        ...state,
        ...updateSuggestedUserFunc(state.suggestedUsers, action.suggestedUser),
      };
    case UPDATE_SELECTED_SUGGESTED_USER:
      return { ...state, selected: action.selectedSuggestedUser };
    default:
      return state;
  }
};

export default suggestedUserReducer;
