import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/AddBox';
import { get, filter, lowerCase } from 'lodash';
import NewPipelineDialog from './NewPipelineDialog';

class PipelineSelector extends React.Component {
  state = { filterText: '', open: false, openPopup: false };

  handleClickOpen = () => {
    this.setState({ openPopup: true });
  };

  handleClose = () => {
    this.setState({ openPopup: false });
  };

  clickPipeline = p => {
    window.open(`/admin/pipelines/${p.id}`, '_self');
  };

  render () {
    const { match, pipelines, classes, pipeline, disablePipelineFunctions } = this.props;
    const { filterText, openPopup, open } = this.state;
    const currentPipeline = match.params.id || get(pipelines, '[0].id');
    let filteredPipelines = pipelines;

    if (filterText) {
      filteredPipelines = filter(pipelines, p => lowerCase(p.name).indexOf(lowerCase(filterText)) > -1);
    }
    const currentFilterCardCount = get(pipeline, 'pipeline_stages', [])
      .map(s => s.card_count)
      .reduce((a, b) => a + b, 0);

    const pipelineIndex = filteredPipelines.findIndex(p => p.id === currentPipeline);

    if (filteredPipelines[pipelineIndex]) {
      filteredPipelines[pipelineIndex].card_count = currentFilterCardCount;
    }
    filteredPipelines.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    return (
      <div>
        <Select
          value={currentPipeline}
          className={classes.title}
          open={open}
          onOpen={() => {
            this.setState({ open: true });
          }}
          onClose={e => {
            if (![ 'INPUT' ].includes(e.target.tagName)) {
              this.setState({ open: false });
            }
          }}>
          <MenuItem className={classes.menuItem}>
            <input
              value={filterText}
              placeholder="&#xF002; Search"
              onChange={e => this.setState({ filterText: e.target.value })}
              className={classes.searchBox}
            />
          </MenuItem>
          <Divider />
          {filteredPipelines.map(p => (
            <MenuItem key={`pipeline_${p.id}`} value={p.id} className={classes.menuItem}>
              <span
                onClick={open === true ? () => this.clickPipeline(p) : () => {}}
                className={classes.menuLink}>{`${decodeURI(p.name)} (${p.card_count})`}</span>
            </MenuItem>
          ))}
          <Divider />
          <MenuItem className={classes.menuItem} value="new_pipeline">
            <Button onClick={this.handleClickOpen}>
              <AddIcon /> Create new pipeline
            </Button>
          </MenuItem>
        </Select>
        <NewPipelineDialog open={openPopup} handleClose={this.handleClose} pipelines={pipelines} />
      </div>
    );
  }
}

PipelineSelector.propTypes = {
  match                    : PropTypes.object.isRequired,
  pipelines                : PropTypes.array.isRequired,
  pipeline                 : PropTypes.object.isRequired,
  classes                  : PropTypes.object.isRequired,
  disablePipelineFunctions : PropTypes.bool,
};

PipelineSelector.defaultProps = {
  disablePipelineFunctions : false,
};

const styles = {
  title     : {
    fontSize : 18,
    padding  : 0,
  },
  menuItem  : {
    fontSize : 18,
    padding  : '20px 40px',
  },
  menuLink  : {
    padding : 20,
    width   : '100%',
    height  : '100%',
    color   : '#337ab7',
  },
  searchBox : {
    fontFamily  : 'Roboto, FontAwesome, sans',
    paddingLeft : 12,
  },
};

export default withStyles(styles)(PipelineSelector);
