import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import ActivityTypes from './ActivityTypes';
import { groupBy, get, concat, isEmpty } from 'lodash';

const activityFilters = {
  note                : 'Notes',
  $click              : 'Clicks',
  'Map Search'        : 'Searches',
  'List Search'       : 'Searches',
  'Message Sent'      : 'Message Sent',
  'Profile View'      : 'Profile View',
  invite              : 'Invites',
  closed_conversation : 'Closed',
};

class ActivityTab extends React.Component {
  state = {
    name           : [],
    activityFilter : [ 'Notes', 'Clicks', 'Searches', 'Message Sent', 'Invites', 'Closed', 'Profile View' ],
    all            : true,
  };

  activities = [ 'Notes', 'Clicks', 'Searches', 'Message Sent', 'Invites', 'Closed', 'Profile View' ];

  componentWillReceiveProps (nextProps) {
    const { inventoryUser } = this.props;
    if (inventoryUser.id !== nextProps.inventoryUser.id) {
      this.setState({ ...nextProps.inventoryUser });
    }
  }

  renderActivities = events => {
    const { classes, inventoryUser, currentUserId, currentUserIsAdmin, updateAction } = this.props;
    const today = moment().format('YYYY-MM-DD');
    const groupDates = Object.keys(events).sort().reverse();

    return groupDates.map(date => (
      <div>
        <p className={classes.date}>{today === moment(date).format('YYYY-MM-DD') ? 'Today' : moment(date).fromNow()}</p>
        {events[date]
          .sort((a, b) => (a.time > b.time ? 1 : -1))
          .reverse()
          .map(e => (
            <ActivityTypes
              event={e}
              inventoryUser={inventoryUser}
              currentUserId={currentUserId}
              classes={classes}
              currentUserIsAdmin={currentUserIsAdmin}
              updateAction={updateAction}
            />
          ))}
      </div>
    ));
  };

  render () {
    const { classes, inventoryUser, notes, activityFilter } = this.props;
    const { user } = inventoryUser;
    let combinedEvents = [];
    let eventsArray = [];
    const lastTenEvents = get(user, 'recent_events', '');
    combinedEvents = lastTenEvents;
    if (notes && (activityFilter.includes('all') || activityFilter.includes('Notes'))) {
      combinedEvents = concat(notes, lastTenEvents);
    }

    if (!isEmpty(get(inventoryUser, 'invites'))) {
      combinedEvents = concat(combinedEvents, get(inventoryUser, 'invites'));
    }

    combinedEvents = (combinedEvents || []).map(e => ({ ...e, filter: activityFilters[e.name] }));

    eventsArray = groupBy(combinedEvents, e => moment(e.time).format('YYYY-MM-DD'));
    return (
      <div className={classes.container}>
        {isEmpty(combinedEvents) && <p>No Activities Available</p>}
        {this.renderActivities(eventsArray)}
      </div>
    );
  }
}

ActivityTab.propTypes = {
  classes        : PropTypes.object.isRequired,
  inventoryUser  : PropTypes.object.isRequired,
  notes          : PropTypes.object.isRequired,
  activityFilter : PropTypes.array.isRequired,
  updateAction   : PropTypes.func.isRequired,
  currentUserId  : PropTypes.string.isRequired,
};

const styles = {
  button     : {
    color      : '#0052CC',
    background : 'none',
    border     : 'none',
  },
  card       : {
    height       : 90,
    borderRadius : 0,
  },
  container  : {
    height          : '100%',
    backgroundColor : '#eeeeee',
    padding         : 10,
  },
  cardgroup  : {
    height       : 100,
    marginTop    : 30,
    marginBottom : 30,
  },
  title      : {
    padding : 4,
    float   : 'left',
  },
  close      : {
    float : 'right',
  },
  date       : {
    fontSize : 15,
    margin   : 8,
  },
  noUser     : {
    height : '150px',
    margin : 10,
  },
  noUserText : {
    margin   : 10,
    fontSize : 20,
  },
  filter     : {
    minWidth : 80,
    width    : 'auto',
  },
  filterForm : {
    float : 'right',
  },
};

export default withStyles(styles)(injectIntl(ActivityTab));
