import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import numeral from 'numeral';
import { get, range } from 'lodash';
import * as cfaActionCreators from '../../../actions/cfaActionCreators';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import TextField from "@material-ui/core/TextField";
import { FormattedMessage, injectIntl } from "react-intl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const Buyer = ({ list, query, classes, updateCfaQuery, intl, currentUser, company }) => {
  const validate = () => {
    let valid = true;

    for (let i = 0; i < query.numberOfBuyers; i++) {
      if (query[`buyer${i + 1}T12Revenue`] == undefined || query[`buyer${i + 1}T12Revenue`] < 1) {
        valid = false;
      }

      if (!company.host.toString().includes("rjf.com")) {
        if (query[`buyer${i + 1}ManualTaxRate`] == undefined || (query[`buyer${i + 1}ManualTaxRate`] && parseFloat(query[`buyer${i + 1}TaxRate`] || 0) < 0.01)) {
          valid = false;
        }
      }
    }

    if ((query[`buyer1PercentagePurchasing`] + query[`buyer2PercentagePurchasing`]) !== 1) {
      valid = false;
    }

    if (query.valid != valid) {
      updateCfaQuery({ ...query, valid });
    }
  }

  validate();

  return (
    <div>
      <h2><FormattedMessage id="calculators.cfa.tell_us_about" /></h2>
      <p>
        <FormattedMessage id="calculators.cfa.buyer_copy" />
      </p>
      {range(0, query.numberOfBuyers).map(index => (
        <div key={index}>
          <h3><FormattedMessage id="calculators.cfa.buyer" /> {query.numberOfBuyers > 1 && index + 1} </h3>
          <dl>
            <dt><FormattedMessage id="calculators.cfa.buyers_t12_revenue" values={{ index: index + 1 }} /></dt>
            <dd>
              <CurrencyInput
                decimalsLimit={2}
                style={{ width: 'inherit' }}
                value={query[`buyer${index + 1}T12Revenue`]}
                onValueChange={value => {
                  updateCfaQuery({ ...query, [`buyer${index + 1}T12Revenue`]: parseFloat(value) })
                }}
              />
            </dd>
            {!company.host.toString().includes("rjf.com") &&
              <React.Fragment>
                <dt><FormattedMessage id="calculators.cfa.buyers_growth_rate" values={{ index: index + 1 }} /></dt>
                <dd>
                  <FBPercentInput
                    style={{ width: 'inherit' }}
                    value={query[`buyer${index + 1}FiveYearGrowthRate`]}
                    upper={300}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${index + 1}FiveYearGrowthRate`]: val })
                    }}
                    lower={-100}
                  />
                </dd>
              </React.Fragment>
            }
            {query.numberOfBuyers > 1 &&
              <React.Fragment>
                <dt><FormattedMessage id="calculators.cfa.percent_purchased" /></dt>
                <dd>
                  <FBPercentInput
                    type="text"
                    style={{ width: 'inherit' }}
                    value={query[`buyer${index + 1}PercentagePurchasing`]}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${index + 1}PercentagePurchasing`]: val })
                    }}
                  />
                </dd>
              </React.Fragment>
            }
            {!company.host.toString().includes("rjf.com") && (
              <React.Fragment>
                <dt><FormattedMessage id="calculators.cfa.manually_override_tax_rate" /></dt>
                <dd>
                  <RadioGroup row className="right" aria-label={`overrideTaxRate${index}`} name={`overrideTaxRate${index}`} value={`${query[`buyer${index + 1}ManualTaxRate`]}`} onChange={e => {
                    const buyerManualTaxRate = Boolean("true" == e.target.value);
                    let taxRate = query[`buyer${index + 1}TaxRate`];

                    if (buyerManualTaxRate && taxRate === undefined) {
                      taxRate = 0.35;
                    }
                    updateCfaQuery({ ...query, [`buyer${index + 1}ManualTaxRate`]: buyerManualTaxRate, [`buyer${index + 1}TaxRate`]: taxRate })
                  }}>
                    <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.dynamic" })} />
                    <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.manual" })} />
                  </RadioGroup>
                </dd>
              </React.Fragment>
              )}
            {query[`buyer${index + 1}ManualTaxRate`] && (
              <React.Fragment>
                <dt><FormattedMessage id="calculators.cfa.tax_rate" /></dt>
                <dd>
                  <FBPercentInput
                    style={{ width: 'inherit' }}
                    value={query[`buyer${index + 1}TaxRate`]}
                    onChange={val => {
                      updateCfaQuery({ ...query, [`buyer${index + 1}TaxRate`]: val })
                    }}
                  />
                </dd>
              </React.Fragment>
            )}
          </dl>
        </div>
      ))}
    </div>
  );
};

Buyer.propTypes = {
  query: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

Buyer.defaultProps = {
  list: [],
};

const styles = () => ({
  question: {
    clear: 'both',
  },
});

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: { ...get(state, 'company', {}) },
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(Buyer)));