import React, {useState} from 'react';
import {get, take, isEmpty} from 'lodash';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const CfaChoice = ({classes, cfaType, updateCfaType, updateStep, step, companyName, intl, company, currentUser}) => {
  return (<div>
    <div className="wrapper">
      <div className="banner">
        <center>
        <span className="continuity-submit"><img className={`img-fluid continuity_button_image ${classes.image}`}
          src={`https://s3-us-west-2.amazonaws.com/findbob-production/images/${companyName}_cfa_wizard_start${intl.locale == 'fr' ? '_fr' : '' }.gif`}
          alt="deal_tracker_gif" /></span>
        </center>
        
        </div>
      </div>
      <center>
        <header>
          <br/>
          <b><FormattedMessage id="calculators.cfa_wizard.what_type_of_cash_flow" values={{cash_flow_analysis_word: get(company, 'cash_flow_analysis_word')}}/></b>
        </header>

        <div className={classes.box} style={styles.box}>
          <button
            onClick={() => {
              updateCfaType('pre-populated');
            }}
            className={classes.buttonB}
            style={{...styles.buttonB, border: cfaType === 'pre-populated' ? '2px solid #e1e1e1' : 'none' }}>
              <i className={`fas fa-handshake ${classes.icon}`} />
              <h3><FormattedMessage id="calculators.cfa_wizard.use_pre_populate" /></h3>
              <p>
                <FormattedMessage id="calculators.cfa_wizard.enter_a_purchase_price" />
              </p>
          </button>
          <button
            type="button"
            className={classes.buttonB}
            style={{...styles.buttonB, border: cfaType === 'blank' ? '2px solid #e1e1e1' : 'none' }}
            onClick={() => {
              updateCfaType('blank');
            }}>
            <i className={`fas fa-money-bill-wave-alt ${classes.icon}`} />
            <h3><FormattedMessage id="calculators.cfa_wizard.use_blank" /></h3>
            <p><FormattedMessage id="calculators.cfa_wizard.guide_you_through_the_process" /></p>
          </button>
        </div>
        <div>
        </div>
        <Button
          disabled={!cfaType}
          variant="contained"
          onClick={() => {
            if(cfaType === 'blank') {
              document.location.href = '/calculators/cfa/wizard';
            }else {
              updateStep(step + 1);
            }
          }}
          ><FormattedMessage id="calculators.cfa_wizard.continue" /></Button>
    </center>
 </div>);
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
  },
  buttonB: {
    margin: '18px 8px',
    padding: '48px 12px',
    width: 180,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    backgroundColor: '#fff',
    border: 'none',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : theme.palette.primary.main,
    width: 400,
    fontWeight: 'bold',
  },
  icon                   : {
    fontSize : 20,
  },
  image: {
    width: 300,
  },
});

const mapStateToProps = state => ({
  company: get(state, 'company.company.data'),
  currentUser: get(state, 'currentUser'),
});

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CfaChoice)));