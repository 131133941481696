import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isEmpty, get } from 'lodash';
import qs from 'query-string';
import Fab from '@material-ui/core/Fab';
import CurrentPipelineActivityCard from '../Staff/CurrentPipelineActivityCard';

//detects if user uses Internet Explorer
//returns version of IE or false, if browser is not IE
//Function to detect IE or not
const browserNotChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
  const isIOSChrome = winNav.userAgent.match('CriOS');
  const isSafari = winNav.userAgent.match('Safari');


  if (isIOSChrome || isSafari) {
    // is Google Chrome on IOS
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return false;
  } else {
    return true;
  }
};

const BrowserDetectionBanner = () => {
  const [stopCheck, setStopCheck] = useState(false);
  const url = window.location.href.split('/');
  const urlLocation = window.location.href.match(/unsupported_browser/);
  const params = qs.parse(location.search);
  const token = get(params, 'token');
  if (browserNotChrome() && !urlLocation) {
    window.open(`/dashboard/unsupported_browser?ref=${token || ''}`, '_self');
  }
  return null;
};

const styles = {
  root: {
    width: '100%',
    marginTop: 5,
    background: '#f8d7da',
    color: '#721c24',
    textAlign: 'center',
    padding: 50,
  },
  contentButton: {
    height: 'auto',
    padding: 0,
    border: 'none',
    background: 'none',
  },
  viewAll: {
    position: 'absolute',
    bottom: '25px',
    right: '25%',
    left: '50%',
    marginLeft: '-150px',
    color: '#0052CC',
  },
};

BrowserDetectionBanner.propTypes = {
  owned_cards: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

BrowserDetectionBanner.defaultProps = {
  owned_cards: [],
};

export default withStyles(styles)(BrowserDetectionBanner);
