import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';
import RemoveFromRolodexButton from '../../RemoveFromRolodexButton/RemoveFromRolodexButton';
import { FormattedMessage } from 'react-intl';

const AddNote = gql`
  mutation addNote($userName: String!) {
    addNote(userName: $userName, text: "Added to Rolodex") {
      id
      comment
      created_at
    }
  }
`;

class SaveButton extends Component {
  click = () => {
    const { mutate, userName, intl, enqueueSnackbar, updateNotes, user, updateBobCards, loadBobCardList } = this.props;

    mutate({
      variables : { userName },
    })
      .then(r => {
        enqueueSnackbar(
          `${intl.formatMessage({ id: 'mailers.rolodex.youve_added_a_new_contact' })}
           ${intl.formatMessage({ id: 'mailers.rolodex.you_can_keep_all_your_notes' })}`,
          {
            variant      : 'success',
            anchorOrigin : {
              vertical   : 'top',
              horizontal : 'center',
            },
          }
        );
        updateNotes({ notes: r.data.addNote, skip: true });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: 1 });
          loadBobCardList({ ...user, note_count: 1 });
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  clickUnsave = () => {
    const { user, updateNotes, updateBobCards, loadBobCardList } = this.props;
    updateNotes({ notes: [], skip: true });
    if (updateBobCards) {
      updateBobCards({ ...user, note_count: 0 });
      loadBobCardList({ ...user, note_count: 0 });
    }
  };

  renderButton = () => {
    const { display, userName, notes, user, agentWordForCompany, updateBobCards, loadBobCardList } = this.props;
    if (display && notes.length < 1) {
      return (
        <div>
          <a style={{ cursor: 'pointer' }} onClick={this.click} id={`${userName}-button`}>
            <i className="fas fa-heart" style={{ marginRight: 5 }} /> <FormattedMessage id="mailers.rolodex.add_to_rolodex" />
          </a>
        </div>
      );
    }
    return (
      <div>
        <RemoveFromRolodexButton
          onClick={() => this.clickUnsave()}
          user={user}
          notes={notes.length}
          fromSideBar
          agentWordForCompany={agentWordForCompany}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
        />
      </div>
    );
  };

  render = () => {
    const { display } = this.props;
    if (!display) {
      return <span />;
    }
    return <span>{this.renderButton()}</span>;
  };
}

SaveButton.propTypes = {
  mutate              : PropTypes.func.isRequired,
  enqueueSnackbar     : PropTypes.func.isRequired,
  updateNotes         : PropTypes.func.isRequired,
  updateBobCards      : PropTypes.func.isRequired,
  loadBobCardList     : PropTypes.func.isRequired,
  userName            : PropTypes.string.isRequired,
  display             : PropTypes.bool,
  intl                : PropTypes.object.isRequired,
  user                : PropTypes.object.isRequired,
  notes               : PropTypes.array,
  agentWordForCompany : PropTypes.string.isRequired,
};

SaveButton.defaultProps = {
  display : true,
  notes   : [],
};

const styles = {
  margin : {
    margin : '0px 0px 15px 10px',
  },
  button : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
    paddingRight  : 0,
  },
};

export default graphql(AddNote)(injectIntl(withStyles(styles)(withSnackbar(SaveButton))));
