import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize, isEmpty } from 'lodash';
import moment from 'moment';

const StatusSection = ({ classes, dealInterest, scrollTo, deadLinePassed, stylesheetName, dealRoomName, intl }) => {
  if (get(dealInterest, 'deal.closed', false)) {
    return <span></span>;
  }
  switch (get(dealInterest, 'workflow_state', '')) {
    case 'matching':
    case 'invited':
      if (!get(dealInterest, 'invited')) {
        return (
          <div className={classes.status}>
            <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
            {deadLinePassed && <p>{intl.formatMessage({ id: 'profile_page.match_profile.the_deadline_to_invite' })`${dealRoomName} has passed.`}</p>}
            {!deadLinePassed && (
              <p>
                #{get(dealInterest, 'inventory_user.user.user_name')}
                <FormattedMessage id="profile_page.match_profile.has_yet_to_be_invited"/><br/>
                {get(dealInterest, 'deal.headline')}
              </p>
            )}
          </div>
        );
      }
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            #{get(dealInterest, 'inventory_user.user.user_name')}
            <FormattedMessage id="profile_page.match_profile.has_yet_to_respond"/>
            {get(dealInterest, 'deal.headline')}
          </p>
        </div>
      );
    case 'reviewing_proposal':
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            #{get(dealInterest, 'inventory_user.user.user_name')} 
            <FormattedMessage id="profile_page.match_profile.expressed_their_interest_to_you"/>
          </p>
        </div>
      );
    case 'shortlisted':
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            <FormattedMessage id="profile_page.match_profile.you_have_shortlisted"/>
            #{get(dealInterest, 'inventory_user.user.user_name')}.
            <FormattedMessage id="profile_page.match_profile.keep_things_moving_and_have_them"/>
          </p>
        </div>
      );
    case 'archived':
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            <FormattedMessage id="profile_page.match_profile.you_have_archived"/> 
            #{get(dealInterest, 'inventory_user.user.user_name')}. 
            <FormattedMessage id="profile_page.match_profile.are_no_longer_interested"/> 
            {stylesheetName === 'rjpe' ? '' : <FormattedMessage id="profile_page.match_profile.unarchive_simply_click"/>}
          </p>
        </div>
      );
    case 'out_for_receiving_signature':
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            <FormattedMessage id="profile_page.match_profile.you_sent"/> 
            #{get(dealInterest, 'inventory_user.user.user_name')} 
            <FormattedMessage id="profile_page.match_profile.for_their_review_and_execution"/>
            {moment(get(dealInterest, 'receiving_party_nda_sent_timestamp', '')).format('MM/DD/YYYY')} at{' '}
            {moment.utc(get(dealInterest, 'receiving_party_nda_sent_timestamp', '')).format('h:mm a')}
          </p>
        </div>
      );
    case 'awaiting_disclosing_signature':
      return (
        <div className={classes.status}>
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            #{get(dealInterest, 'inventory_user.user.user_name')} 
            <FormattedMessage id="profile_page.match_profile.has_executed_the_nda"/>
          </p>
        </div>
      );
    case 'negotiating':
      return (
        <div className={classes.status}>
          {get(dealInterest, 'reason_for_suggestion', '') && (
            <Button
              className={`${classes.button} ${classes[`${stylesheetName}FontColor`]}`}
              onClick={() => scrollTo('recommendation')}>
              <span className="fas fa-user-check" style={{ marginRight: 10 }} /><FormattedMessage id="profile_page.match_profile.recommendation"/>
            </Button>
          )}
          {get(dealInterest, 'proposal_letter', '') && (
            <Button
              className={`${classes.button} ${classes[`${stylesheetName}FontColor`]}`}
              onClick={() => scrollTo('proposal')}>
              <span className="fas fa-envelope-open-text" style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.match_profile.read_proposal"/>
            </Button>
          )}
          <Button
            onClick={() => scrollTo('chat')}
            className={`${classes.button} ${classes[`${stylesheetName}FontColor`]}`}>
            <span className="fa fa-envelope" style={{ marginRight: 12 }} /> <FormattedMessage id="profile_page.match_profile.message"/>
          </Button>
          {!isEmpty(get(dealInterest, 'deal_scheduled_meetings')) && (
            <Button
              onClick={() => scrollTo('schedule')}
              className={`${classes.button} ${classes[`${stylesheetName}FontColor`]}`}>
              <span className="fas fa-calendar-alt" style={{ marginRight: 12 }} /> <FormattedMessage id="profile_page.match_profile.schedule_meeting"/>
            </Button>
          )}
          <br />
          <br />
          <strong><FormattedMessage id="profile_page.match_profile.status"/></strong>
          <p>
            {`Confidently continue safe negotiations with ${get(dealInterest, 'inventory_user.user.user_name')} now that
            you’ve executed a mutual NDA. Schedule a meeting or start messaging with them from your ${dealRoomName}.`}
          </p>
          {get(dealInterest, 'inventory_user.user.allowed_name') && (
            <div>
              <strong><FormattedMessage id="shared.name"/></strong>
              <p>{get(dealInterest, 'inventory_user.user.allowed_name')}</p>
              <strong><FormattedMessage id="shared.phone"/></strong>
              <p>{get(dealInterest, 'inventory_user.user.allowed_phone')}</p>
            </div>
          )}
        </div>
      );
    default:
      return <div><FormattedMessage id="profile_page.status_section.wrong_stage"/></div>;
  }
};

const styles = {
  status                 : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
  button                 : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
  },
  FontColor              : {
    color : '#1396e2',
  },
  rjpeFontColor          : {
    color : '#002949',
  },
  BackgroundColor        : {
    backgroundColor : '#1396e2',
  },
  rjpeBackgroundColor    : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
  rjpeBackgroundColorAlt : {
    backgroundColor : '#5B5E5A',
    '&:hover'       : {
      background : '#424949',
    },
  },
};

export default injectIntl(withStyles(styles)(StatusSection));
