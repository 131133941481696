import React from 'react';
import Confetti from 'react-confetti';

const ConfettiComponent = ({ numberOfPieces }) => {
  return (
    <Confetti
      numberOfPieces={numberOfPieces} // Number of confetti pieces
      recycle={false} // Whether to recycle confetti (optional, default: true)
      gravity={0.5} // Gravity strength (optional, default: 0.5)
      wind={0.1} // Wind strength (optional, default: 0.01)
      friction={0.99} // Air friction (optional, default: 0.99)
    />
  );
};

export default ConfettiComponent;
