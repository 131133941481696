/* eslint-disable import/prefer-default-export */

import { UPDATE_BOBCARDS, LOAD_BOBCARDS } from '../constants/bobCardConstants';

export const loadBobCardList = bobCardsSettings => ({
  type             : LOAD_BOBCARDS,
  bobCardsSettings,
});

export const updateBobCards = bobCard => ({
  type    : UPDATE_BOBCARDS,
  bobCard,
});

export const deleteBobCard = bobCard => ({
  type    : DELETE_BOBCARDS,
  bobCard,
});
