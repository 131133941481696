import React, { useState } from 'react';
import PropTypes, { number } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useMutation } from "react-apollo";
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import { withSnackbar } from 'notistack';
import { gql } from "graphql-tag";


export const UPDATE_PARTIAL_SALE = gql`
  mutation UpdatePartialSale($id: ID!, 
                              $description: String!, 
                              $number_of_clients: Int!,
                              $fee_based_revenue: Float!,
                              $commission_revenue: Float!,
                              $trailer_revenue: Float!,
                              $direct_revenue: Float!,
                              $asking_price: Float!) {
    updatePartialSale(  id: $id, 
                        description: $description,
                        number_of_clients: $number_of_clients,
                        fee_based_revenue: $fee_based_revenue,
                        commission_revenue: $commission_revenue,
                        trailer_revenue: $trailer_revenue,
                        direct_revenue: $direct_revenue,
                        asking_price: $asking_price ) {
      id
      description
      number_of_clients
      fee_based_revenue
      asking_price
      commission_revenue
      trails_revenue
      direct_revenue
    }

  }
`;

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const PartialSaleListing = (props) => {
  const [partialSale, updateLocalPartialSale] = React.useState(props.partialSale);
  const [open, setOpen] = React.useState(false);
  const [updatePartialSale, { data, loading, error }] = useMutation(UPDATE_PARTIAL_SALE);
  const { setPartialSale, enqueueSnackbar, company } = props;
  const classes = useRowStyles();
  const numberOfInterests = partialSale.partial_sale_listing_users.length;
  const [isNegotiable, setIsNegotiable] = useState(partialSale.asking_price === 0);
  const [isValid, setIsValid] = useState(true);

  const handleNumberOfClientsChange = (e) => {
    const value = e.target.value;
    updateLocalPartialSale({ ...partialSale, number_of_clients: value });
    setIsValid(value > 0);
  };


  const updateAskingPrice = (e) => {
    const newPrice = Number(e.target.value);
    updateLocalPartialSale({ ...partialSale, asking_price: newPrice });
    if (newPrice === 0) {
      setIsNegotiable(true);
    } else {
      setIsNegotiable(false);
    }
  };

  const handleNegotiableChange = (e) => {
    const checked = e.target.checked;
    setIsNegotiable(checked);
    if (checked) {
      updateLocalPartialSale({ ...partialSale, asking_price: 0 });
    }
  };

  const partialSalesPropertyCasualty = company?.feature_types?.includes('partial_sales_property_casualty') || false;

  return (
    <React.Fragment>
      <TextField
        id="description"
        label="Description"
        multiline
        numberOfLines={4}
        limit={250}
        value={partialSale.description}
        onChange={(e) => updateLocalPartialSale({ ...partialSale, description: e.target.value })}
        fullWidth
      />
      <br />
      <br />
      <TextField
        id="number_of_clients"
        label="Number Of Clients"
        value={partialSale.number_of_clients}
        onChange={handleNumberOfClientsChange}
        type="number"
      />
      <br />
      <br />
      <TextField
        id="assets_under_management"
        label={partialSalesPropertyCasualty ? "Total Premium" : "Assets Under Management"}
        value={partialSale.assets_under_management}
        onChange={(e) => updateLocalPartialSale({ ...partialSale, assets_under_management: e.target.value })}
        type="number"
      />
      <br />
      <br />
      <TextField
        id="fee_based_revenue"
        label={partialSalesPropertyCasualty ? "Recurring Commission Revenue" : "Fee Based Revenue"}
        value={partialSale.fee_based_revenue}
        onChange={(e) => updateLocalPartialSale({ ...partialSale, fee_based_revenue: e.target.value })}
        type="number"
      />
      <br />
      <br />
      <TextField
        id="commission_revenue"
        label={partialSalesPropertyCasualty ? "New Commission Revenue" : "Commission Revenue"}
        value={partialSale.commission_revenue}
        onChange={(e) => updateLocalPartialSale({ ...partialSale, commission_revenue: e.target.value })}
        type="number"
      />
      <br />
      <br />
      { !partialSalesPropertyCasualty && (
        <div>
          <TextField
          id="trailer_revenue"
          label="Trailer Revenue"
          value={partialSale.trails_revenue}
          onChange={(e) => updateLocalPartialSale({ ...partialSale, trailer_revenue: e.target.value })}
          type="number"
          />
          <br />
          <br />
          <TextField
            id="direct_revenue"
            label="Direct Revenue"
            value={partialSale.direct_revenue}
            onChange={(e) => updateLocalPartialSale({ ...partialSale, direct_revenue: e.target.value })}
            type="number"
          />
          <br />
          <br />
        </div>
      )}
      <TextField
        id="asking_price"
        label="Asking Price"
        value={partialSale.asking_price}
        onChange={updateAskingPrice}
        type="number"
      />
      <br />
      <FormControlLabel
      control={
        <Checkbox
          checked={isNegotiable}
          onChange={handleNegotiableChange}
          name="isNegotiable"
          color="primary"
        />
      }
      label="Price is negotiable"
    />
      <br />
      <br />
      <Button variant="contained" color="primary" onClick={() => {
        const { id, description, number_of_clients, fee_based_revenue, asking_price } = partialSale;
        updatePartialSale({ 
          variables: { 
            id, 
            description,
            number_of_clients: parseInt(number_of_clients),
            fee_based_revenue: parseFloat(fee_based_revenue),
            commission_revenue: parseFloat(partialSale.commission_revenue),
            trailer_revenue: parseFloat(partialSale.trails_revenue),
            direct_revenue: parseFloat(partialSale.direct_revenue),
            asking_price: parseFloat(asking_price) } }).then(data => {

          setPartialSale({ ...partialSale, ...data });
          // Write a success message to the user via snackbar
          enqueueSnackbar("Listing Updated", {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            }
          });

        })
      }}
      disabled={!isValid}> 
      Save </Button>
      <br />
      <br />
    </React.Fragment>
  );
}

PartialSaleListing.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
  company: PropTypes.shape({
    feature_types: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default withSnackbar(PartialSaleListing);