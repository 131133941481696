import React from 'react';
import PropTypes from 'prop-types';

const WarningText = ({ visible, message }) => {
  if (visible)
    return (
      <div id="flash" class="alert alert-warning alert-block fade in">
        <p>{message} </p>
      </div>
    );
  return <span />;
};

WarningText.propTypes = {
  visible : PropTypes.bool.isRequired,
  message : PropTypes.string.isRequired,
};

export default WarningText;
