import { createStore, combineReducers, applyMiddleware } from 'redux';
import companyReducer from '../reducers/companyReducer';
import companiesReducer from '../reducers/companiesReducer';
import inventoryUserReducer from '../reducers/inventoryUserReducer';
import designationsReducer from '../reducers/designationsReducer';
import advisorLicensesReducer from '../reducers/advisorLicensesReducer';
import insurersReducer from '../reducers/insurersReducer';
import linesOfBusinessesReducer from '../reducers/linesOfBusinessesReducer';
import plansReducer from '../reducers/plansReducer';
import conversationReducer from '../reducers/conversationReducer';
import todoListsReducer from '../reducers/todoListsReducer';
import todoItemsReducer from '../reducers/todoItemsReducer';
import todoItemQuestionsReducer from '../reducers/todoItemQuestionsReducer';
import reportsReducer from '../reducers/reportsReducer';
import pendingAuthorizedUserReducer from '../reducers/pendingAuthorizedUserReducer';
import dateRangeFilterReducer from '../reducers/dateRangeFilterReducer';
import opportunityReducer from '../reducers/opportunityReducer';
import dealReducer from '../reducers/dealReducer';
import marketplaceStatusReducer from '../reducers/marketplaceStatusReducer';
import pipelineReducer from '../reducers/pipelineReducer';
import taskReducer from '../reducers/taskReducer';
import bobCardReducer from '../reducers/bobCardReducer';
import bulkEditItemReducer from '../reducers/bulkEditItemReducer';
import suggestedUserReducer from '../reducers/suggestedUserReducer';
import suggestedDealReducer from '../reducers/suggestedDealReducer';
import educationalContentReducer from '../reducers/educationalContentReducer';
import motivationReportReducer from '../reducers/motivationReportReducer';
import userReducer from '../reducers/userReducer';
import tickersReducer from '../reducers/tickersReducer';
import onboardingBannerWidgetsReducer from '../reducers/onboardingBannerWidgetsReducer';
import affiliationsReducer from '../reducers/affiliationsReducer';

import thunk from 'redux-thunk';
import { intlReducer } from 'react-intl-redux';

const configureStore = railsProps =>
  createStore(
    combineReducers({
      company                  : companyReducer,
      companies                : companiesReducer,
      inventory_users          : inventoryUserReducer,
      designations             : designationsReducer,
      advisor_licenses         : advisorLicensesReducer,
      insurers                 : insurersReducer,
      pending_authorized_users : pendingAuthorizedUserReducer,
      lines_of_businesses      : linesOfBusinessesReducer,
      plans                    : plansReducer,
      conversations            : conversationReducer,
      todo_lists               : todoListsReducer,
      todo_items               : todoItemsReducer,
      todo_item_questions      : todoItemQuestionsReducer,
      reports                  : reportsReducer,
      intl                     : intlReducer,
      dateRangeFilter          : dateRangeFilterReducer,
      opportunities            : opportunityReducer,
      deals                    : dealReducer,
      marketplaceStatus        : marketplaceStatusReducer,
      pipeline                 : pipelineReducer,
      all_tasks                : taskReducer,
      bobCards                 : bobCardReducer,
      match_preference         : state => ({ ...state }),
      deal                     : dealReducer,
      bulkEditItems            : bulkEditItemReducer,
      suggestedUsers           : suggestedUserReducer,
      suggestedDeals           : suggestedDealReducer,
      educationalContents      : educationalContentReducer,
      motivationFilters        : motivationReportReducer,
      user                     : userReducer,
      tickers                  : tickersReducer,
      onboarding_banner_widgets : onboardingBannerWidgetsReducer,
      affiliations                 : affiliationsReducer,
    }),
    railsProps,
    applyMiddleware(thunk)
  );

export default configureStore;
