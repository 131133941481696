import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Switch from '@material-ui/core/Switch';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const CreateEducationalContentMutation = gql`
  mutation createEducationalContent(
    $title: String
    $description: String
    $visible: Boolean
    $notify_on_publish: Boolean
    $content_type: String
    $thumbnail_url: String
    $asset_url: String
    $embed_code: String
    $locale: String
  ) {
    createEducationalContent(
      title: $title
      description: $description
      visible: $visible
      notify_on_publish: $notify_on_publish
      thumbnail_url: $thumbnail_url
      content_type: $content_type
      asset_url: $asset_url
      embed_code: $embed_code
      locale: $locale
    ) {
      id
      title
      description
      visible
      notifyOnPublish
      contentType
      assetUrl
      embedCode
      locale
    }
  }
`;

class AddEducationalContentButton extends React.Component {
  initialState = {
    title: '',
    description: '',
    visible: false,
    notifyOnPublish: false,
    contentType: '',
    thumbnailUrl: '',
    assetUrl: '',
    embedCode: '',
    locale: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ ...this.initialState, open: false });
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  render() {
    const { mutate, educationalContents, updateEducationalContent } = this.props;
    const { open, title, description, visible, contentType, thumbnailUrl,
       assetUrl, embedCode, notifyOnPublish, locale } = this.state;
    return (
      <div>
        <Fab
          style={{ position: 'fixed', bottom: '10%', right: 40 }}
          onClick={() => this.handleClickOpen()}
          color="primary">
          <AddIcon />
        </Fab>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Add An Educational Content</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              fullWidth
              value={title}
              onChange={e => {
                this.handleChange('title', e.target.value);
              }}>
              {title}
            </TextField>
            <TextField
              label="Description"
              fullWidth
              value={description}
              onChange={e => {
                this.handleChange('description', e.target.value);
              }}>
              {description}
            </TextField>
            <br />
            <InputLabel id="demo-simple-select-label">Visible?</InputLabel>
            <Switch
              checked={visible}
              onChange={e => {
                this.handleChange('visible', e.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <InputLabel id="demo-simple-select-label">Notify On Publish?</InputLabel>
            <Switch
              checked={notifyOnPublish}
              onChange={e => {
                this.handleChange('notifyOnPublish', e.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
            <InputLabel id="demo-simple-select-label">Content Type</InputLabel>
            <Select
              fullWidth
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={contentType}
              onChange={e => {
                this.handleChange('contentType', e.target.value);
              }}>
              <MenuItem value="video">Video</MenuItem>
              <MenuItem value="document">Document</MenuItem>
              <MenuItem value="iframe">iFrame</MenuItem>
            </Select>
            <TextField
              label="Thumbnail URL"
              fullWidth
              value={thumbnailUrl}
              onChange={e => {
                this.handleChange('thumbnailUrl', e.target.value);
              }}>
              {thumbnailUrl}
            </TextField>
            {contentType !== 'iframe' && (
              <TextField
                label="Asset URL"
                fullWidth
                value={assetUrl}
                onChange={e => {
                  this.handleChange('assetUrl', e.target.value);
                }}>
                {assetUrl}
              </TextField>
            )}
            {contentType !== 'document' &&
              contentType !== 'video' && (
                <TextField
                  label="Embed Code"
                  fullWidth
                  value={embedCode}
                  onChange={e => {
                    this.handleChange('embedCode', e.target.value);
                  }}>
                  {embedCode}
                </TextField>
              )}
              <InputLabel id="demo-simple-select-label">Locale</InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={locale}
                onChange={e => {
                  this.handleChange('locale', e.target.value);
                }}>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">French</MenuItem>
              </Select>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <IconButton
              disabled={!(title && description && contentType && (thumbnailUrl || assetUrl || embedCode))}
              color="secondary"
              onClick={() => {
                mutate({
                  variables: {
                    title,
                    description,
                    visible,
                    notify_on_publish: notifyOnPublish,
                    content_type: contentType,
                    thumbnail_url: thumbnailUrl,
                    asset_url: assetUrl,
                    embed_code: embedCode,
                    locale,
                  },
                })
                  .then(response => {
                    if (response.data.createEducationalContent) {
                      updateEducationalContent(educationalContents.push(response.data.createEducationalContent));
                      this.setState(this.initialState);
                      this.handleClose();
                    }
                  })
                  .catch(e => {
                    // eslint-disable-next-line
                    console.log(e);
                  });
              }}>
              Save
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddEducationalContentButton.propTypes = {
  mutate: PropTypes.func.isRequired,
  educationalContents: PropTypes.array,
  updateEducationalContent: PropTypes.func.isRequired,
};

AddEducationalContentButton.defaultProps = {
  educationalContents: [],
};

export default graphql(CreateEducationalContentMutation)(AddEducationalContentButton);
