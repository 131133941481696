import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import gql from 'graphql-tag';
import client from '../../lib/apolloClient';
import { Send } from '@material-ui/icons';
import { S3Image } from '../../lib/s3';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import moment from 'moment';

const GET_SUPPORT_REQUEST_COMMENTS = gql`
  query SupportRequstComments($id: ID!) {
    supportRequest(id: $id) {
      id
      __typename
      comments {
        id
        comment
        title
        created_at
        user {
          id
          name
          profile_picture
        }
      }
    }
  }
`;

const ADD_SUPPORT_REQUEST_COMMENT = gql`
  mutation AddSupportRequestComment($id: ID!, $comment: String!) {
    addSupportRequestComment(id: $id, comment: $comment, title: "Support Note") {
      id
    }
  }
`;

const SupportRequestNotes = ({ supportRequest, classes }) => {
  const [comment, setComment] = useState('');
  const { data, loading, refetch } = useQuery(GET_SUPPORT_REQUEST_COMMENTS, {
    variables: { id: supportRequest.id },
    client,
  });
  const [addSupportRequestComment] = useMutation(ADD_SUPPORT_REQUEST_COMMENT, { client });

  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = () => {
    addSupportRequestComment({
      variables: { id: supportRequest.id, comment, title: 'Note' },
    }).then(() => {
      setComment('');
      refetch();
    });
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Notes" />
      <CardContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Box display="flex" alignItems="center" mb={2}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Add a note..."
                value={comment}
                onChange={handleChange}
                multiline
                rows={3}
              />
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                style={{ marginLeft: '8px', height: '80px' }}
              >
                <Send />
              </Button>
            </Box>
            <Divider />
            {get(data, 'supportRequest.comments', []).map((comment) => (
              <Box key={comment.id} display="flex" alignItems="flex-start" mt={2}>
                <Avatar src={S3Image(get(comment, 'user.profile_picture'))} className={classes.avatar} />
                <Box ml={2} flex={1}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle1">{get(comment, 'user.name', '')}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {moment(comment.created_at).fromNow()}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    {comment.title}
                  </Typography>
                  <Typography variant="body1">{comment.comment}</Typography>
                  <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

SupportRequestNotes.propTypes = {
  supportRequest: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    maxHeight: 'calc(100vh - 300px)',
    height: 'calc(100vh - 300px)',
    width: 340,
    overflow: 'auto',
    margin: '0 auto',
    padding: theme.spacing(2),
    marginLeft: -38,
  },
  avatar: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
});

export default withStyles(styles)(SupportRequestNotes);
