import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/SendRounded';
import { withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';

const SupportRequestInputForm = ({ classes, intl, createSupportRequest, refetch, changeConversation }) => {
  const [body, setBody] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const disableChat = false;
  const disableButton = body.length === 0 || email.length === 0 || name.length === 0;
  if (submitted) {
    return (
      <div>
      <h4>Thank you for submitting. One of our team members will get back to you as soon as possible</h4>
      </div>
    );
  }

  return (
    <div>
      <h1>{intl.formatMessage({ id: 'support_request.support_request_input_form' })}</h1>
      <div id="messageList" className={classes.messageList}>
        <p>
          {intl.formatMessage({ id: 'support_request.input_form_copy' })}
        </p>
        <TextField
          variant="outlined"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder={intl.formatMessage({ id: 'support_request.name_placeholder' })}
          fullWidth
          style={{ width: '95%' }}
          inputProps={{
            style: {
              overflow: 'auto',
            },
          }}
        />
        <TextField
          variant="outlined"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={intl.formatMessage({ id: 'support_request.email_placeholder' })}
          fullWidth
          style={{ width: '95%' }}
          inputProps={{
            style: {
              overflow: 'auto',
            },
          }}
        />
      </div>

      <TextField
        disabled={disableChat}
        variant="outlined"
        type="text"
        value={body}
        onChange={(e) => setBody(e.target.value)}
        multiline
        placeholder={disableChat ? intl.formatMessage({ id: 'messages_section.chat_has_been_disabled' }) : intl.formatMessage({ id: 'messages_section.type_message_here_placeholder' })}
        fullWidth
        inputProps={{
          style: {
            height: 100,
            overflow: 'auto',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!disableChat && (
                <IconButton 
                  disabled={disableButton}
                  onClick={() => {
                  createSupportRequest({ variables: { email, name, message: body } }).then(response => {
                    setSubmitted(true);                       
                  })
                }}>
                  <SendIcon color={disableButton ? 'default' : 'primary'} />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

const styles = theme => ({
  chatroomContainer: {
    padding: 12,
  },
  messageList: {
    overflowY: 'scroll',
    height: '400px',
  },
  standaloneList: {
    overflowY: 'scroll',
    height: 'calc(100vh - 416px)',
    width: '100%',
  },
  loadButton: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  listItem: {
    padding: 8,
  },
  blockButton: {
    float: 'right',
    top: '-12px',
    position: 'relative',
  },
});

export default withStyles(styles)(injectIntl(SupportRequestInputForm));