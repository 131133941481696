import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import WarningText from '../WarningText/WarningText';
import numeral from 'numeral';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import CurrencyInput from 'react-currency-input-field';
import {intlCurrencyConfig} from '../../lib/findBobUtils';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $year_1_gross: Int
    $year_2_gross: Int
    $year_3_gross: Int
    $year_4_gross: Int
    $year_5_gross: Int
    $revenue_growth_rate: Float
    $use_revenue_growth_rate: Boolean
  ) {
    updateCurrentUser(
      year_1_gross: $year_1_gross
      year_2_gross: $year_2_gross
      year_3_gross: $year_3_gross
      year_4_gross: $year_4_gross
      year_5_gross: $year_5_gross
      revenue_growth_rate: $revenue_growth_rate
      use_revenue_growth_rate: $use_revenue_growth_rate
    )
  }
`;

const ColorSwitch = withStyles({
  switchBase : {
    color                : '#002949',
    '&$checked'          : {
      color : '#002949',
    },
    '&$checked + $track' : {
      backgroundColor : '#002949',
    },
  },
  checked    : {},
  track      : {},
})(Switch);

class PeGrossProduction extends React.Component {
  updateUser = debounce(() => {
    const { mutate } = this.props;
    const {
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate,
    } = this.state;
    if (this.numbersAreValid()) {
      mutate({
        variables : {
          year_1_gross,
          year_2_gross,
          year_3_gross,
          year_4_gross,
          year_5_gross,
          revenue_growth_rate,
          use_revenue_growth_rate,
        },
      }).catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
    }
  }, 500);

  numbersAreValid = () => {
    const { year_1_gross, year_2_gross, year_3_gross, year_4_gross, year_5_gross, revenue_growth_rate } = this.state;
    if (
      year_1_gross <= 12000000 &&
      year_2_gross <= 12000000 &&
      year_3_gross <= 12000000 &&
      year_4_gross <= 12000000 &&
      year_5_gross <= 12000000 &&
      revenue_growth_rate <= 100
    ) {
      return true;
    }
    return false;
  };

  constructor (props) {
    super(props);
    this.state = {
      year_1_gross            : props.currentUser.year_1_gross || 0,
      year_2_gross            : props.currentUser.year_2_gross || 0,
      year_3_gross            : props.currentUser.year_3_gross || 0,
      year_4_gross            : props.currentUser.year_4_gross || 0,
      year_5_gross            : props.currentUser.year_5_gross || 0,
      revenue_growth_rate     : props.currentUser.revenue_growth_rate * 100 || 0,
      use_revenue_growth_rate : props.currentUser.use_revenue_growth_rate || false,
    };
  }

  runUpdate = () => {
    const { updateGrossProduction, saveToUser } = this.props;
    const {
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate,
    } = this.state;
    if (saveToUser) {
      this.updateUser();
    }
    updateGrossProduction(
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate
    );
  };

  handleChange = (val, s) => {
    const { saveToUser } = this.props;
    this.setState({ [val]: s }, () => this.runUpdate());
  };

  componentDidMount = () => {
    const { showRevGrowthRate } = this.props;
    if (!showRevGrowthRate) {
      this.setState({ use_revenue_growth_rate: false });
    }
  };

  handleCurrencyChange = (val, s) => {
    this.setState({ [val]: parseInt(s.replace('$', '').replace(',', '', 10) || 0) }, () => this.runUpdate());
  };

  validatePercentage = (name, val) => {
    var v = parseFloat(val);
    let value;
    value = numeral(v).format('0[.]00a', Math.floor);
    this.setState({ [name]: parseFloat(value) }, () => this.runUpdate());
  };

  toggleUserRevGrowth = () => {
    const { use_revenue_growth_rate } = this.state;
    this.setState({ use_revenue_growth_rate: !use_revenue_growth_rate }, () => this.runUpdate());
  };

  showIncompleteWarning = () => {
    const { showRevGrowthRate } = this.props;
    const { year_1_gross, year_2_gross, year_3_gross, year_4_gross, year_5_gross } = this.state;
    if (!showRevGrowthRate) {
      return false;
    }
    return year_1_gross === 0 || year_2_gross === 0 || year_3_gross === 0 || year_4_gross === 0 || year_5_gross === 0;
  };

  showEditOffIncompleteWarning = () => {
    const { showRevGrowthRate } = this.props;
    const { year_1_gross, year_2_gross, year_3_gross, year_4_gross, year_5_gross } = this.state;
    if (!showRevGrowthRate) {
      return year_1_gross === 0 || year_2_gross === 0 || year_3_gross === 0 || year_4_gross === 0 || year_5_gross === 0;
    }
  };

  render () {
    const { classes, profileView, disableRevenueSliders, showRevGrowthRate, currentUser, intl, theme } = this.props;
    const {
      year_1_gross,
      year_2_gross,
      year_3_gross,
      year_4_gross,
      year_5_gross,
      revenue_growth_rate,
      use_revenue_growth_rate,
    } = this.state;
    const sliderSize = profileView ? 9 : 7;
    const amountSize = profileView ? 3 : 5;

    return (
      <div style={{ marginTop: 25 }}>
        <br />
        <Grid container spacing={1}>
          {this.showIncompleteWarning() && (
            <Grid item xs={12}>
              <p style={{ color: 'red' }}>
                <FormattedMessage id="valuation_drawer.incomplete_historical_gross_production" />
              </p>
            </Grid>
          )}
            {this.showEditOffIncompleteWarning() && (
              (!disableRevenueSliders &&(
              <Grid item xs={12}>
                <p style={{ color: 'red' }}>
                  <FormattedMessage id="valuation_drawer.incomplete_5_year_gross_production" />
                </p>
              </Grid>
            ))
          )}
          {showRevGrowthRate && (
            <div>
              <Grid item xs={12}>
                <span>
                  <FormattedMessage id="valuation_drawer.revenue_growth_rate" />{' '}
                </span>
                <Tooltip
                  classes={{ tooltip: classes.tooltiptext }}
                  title={intl.formatMessage({ id: 'valuation_drawer.revenue_growth_rate_tool_tip' })}
                  placement="top">
                  <Button style={{ margin: -15 }}>
                    <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
                  </Button>
                </Tooltip>
                <span style={{ float: 'right', margin: -8, marginRight: profileView ? '40px' : '' }}>
                  <FormattedMessage id="valuation_drawer.specify_revenue_growth_revenue" />
                  <ColorSwitch
                    checked={use_revenue_growth_rate}
                    onChange={this.toggleUserRevGrowth}
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </span>
              </Grid>
              <Grid item xs={sliderSize}>
                {use_revenue_growth_rate && (
                  <RangeSliderContainer theme={theme}>
                    <InputSlider
                      maxValue={100}
                      minValue={0}
                      value={revenue_growth_rate || 0}
                      formatLabel={value => ``}
                      step={0.5}
                      onChange={e => {
                        this.handleChange('revenue_growth_rate', e);
                      }}
                    />
                  </RangeSliderContainer>
                )}
              </Grid>
              {!profileView && <Grid item xs={1} />}
              <Grid item xs={amountSize}>
                <Grid container>
                  <Grid item xs={8}>
                    <TextField
                      id="outlined-name"
                      disabled={!use_revenue_growth_rate}
                      inputProps={{ tabIndex: '1' }}
                      outlined-name
                      value={revenue_growth_rate || 0}
                      margin="normal"
                      variant="outlined"
                      type="number"
                      name="user[revenue_growth_rate]"
                      onChange={e => {
                        this.validatePercentage('revenue_growth_rate', e.target.value);
                      }}
                      error={revenue_growth_rate > 100}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <div style={{ marginTop: 28, marginLeft: profileView ? 6 : 10 }}>%</div>
                  </Grid>
                </Grid>
              </Grid>
              <WarningText
                visible={revenue_growth_rate > 100}
                message={intl.formatMessage({ id: 'valuation_drawer.practice_info_value_must_be_between_prct_zero' })}
              />
            </div>
          )}
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <span className={classes.headline}>
              <FormattedMessage id="valuation_page.gross_production" />
            </span>
            <Tooltip
              classes={{ tooltip: classes.tooltiptext }}
              title={intl.formatMessage({ id: 'valuation_drawer.gross_production_tool_tip' })}
              placement="top">
              <Button style={{ margin: -15 }}>
                <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
              </Button>
            </Tooltip>
          </Grid>
          {[1,2,3,4,5].map(year => <React.Fragment>
                    <Grid item xs={12}>
                      <span>{moment().year() - year}</span>
                    </Grid>
                    <Grid item xs={profileView ? 9 : 7}>
                      {!use_revenue_growth_rate &&
                        (!disableRevenueSliders && (
                          <RangeSliderContainer theme={theme}>
                            <InputSlider
                              maxValue={12000000}
                              minValue={0}
                              value={eval(`year_${year}_gross`) || 0}
                              formatLabel={value => ``}
                              step={50000}
                              onChange={e => {
                                this.handleChange(`year_${year}_gross`, e);
                              }}
                            />
                          </RangeSliderContainer>
                        ))}
                    </Grid>
                    <Grid item xs={profileView ? 3 : 5}>
                    <CurrencyInput
                      disabled={use_revenue_growth_rate || disableRevenueSliders}
                      id="outlined-name"
                      name={`user[year_${year}_gross]`}
                      value={eval(`year_${year}_gross`) || 0}
                      intlConfig={intlCurrencyConfig(intl)}
                      allowDecimals={false}
                      onValueChange={(value, name) => this.handleChange(`year_${year}_gross`, parseInt(value))}
                    />
                  </Grid>
                  <WarningText
                    visible={eval(`year_${year}_gross`) > 12000000}
                    message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
                  />
                  </React.Fragment>
          )
            
         }
        </Grid>
      </div>
    );
  }
}

PeGrossProduction.propTypes = {
  mutate                : PropTypes.func.isRequired,
  currentUser           : PropTypes.object.isRequired,
  updateGrossProduction : PropTypes.func,
  saveToUser            : PropTypes.bool,
};

PeGrossProduction.defaultProps = {
  disableRevenueSliders : false,
  updateGrossProduction : () => {},
  saveToUser            : false,
};

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${props.theme.palette.accent.main} !important;
        border: 1px solid ${props.theme.palette.accent.main} !important;
      }

      .input-range__track--active {
        background: ${props.theme.palette.accent.main};
      }
    `};
`;

const styles = theme => ({
  toolTip     : {
    color : '#1396e2',
  },
  tooltiptext : {
    fontSize : 16,
  },
  headline    : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

export default withTheme(injectIntl(withStyles(styles)(graphql(UpdateCurrentUser)(PeGrossProduction))));
