import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import IconSelector from '../ActivityTypes/IconSelector';
import { injectIntl } from 'react-intl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
    display  : 'inline-flex',
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin   : theme.spacing.unit,
    fontSize : '24px',
  },
});

const updateField = (data, action, event) => {
  data[event.target.id] = event.target.value;
  action(data);
};

const handleIconChange = (object, action, field, value) => {
  action({ ...object, [field]: value, edited: true });
};

const SimpleInputTextField = ({ id, label, required, data, action, select, children, classes }) => {
  const handleChange = event => {
    const { value } = event.target;
    action({ ...data, [id]: value });
  };

  if (select) {
    return (
      <TextField
        id={id}
        label={label}
        required={required}
        select
        value={data[id] || ''}
        onChange={handleChange}
        InputProps={{
          className: classes.textField,
          style: { whiteSpace: 'normal', width: '100%' },
          inputProps: { style: { width: '100%' } },
        }}
      >
        {children}
      </TextField>
    );
  }
  return (
    <TextField
      id={id}
      label={label}
      required={required}
      value={data[id] || ''}
      onChange={handleChange}
      InputProps={{
        className: classes.textField,
        style: { whiteSpace: 'normal' },
        inputProps: { style: { width: '100%' } },
      }}
    />
  );
};

const OnboardingBannerWidget = ({ object, action, deleteAction, updateAction, classes, intl, iconUrl }) => (
  <TableRow>
    <TableCell className={classes.root}>
      <TableCell>
        <SimpleInputTextField
          id="motivation"
          required
          label="motivation"
          data={object}
          action={action}
          select
          classes={classes}     
        >
          <MenuItem value="growth">Growth</MenuItem>
          <MenuItem value="succession">Succession</MenuItem>
          <MenuItem value="no_motivation">No Motivation</MenuItem>
        </SimpleInputTextField>
      </TableCell>
      <TableCell>
        <SimpleInputTextField
          id="order"
          required
          label="order"
          data={object}
          action={action}
          select
          classes={classes}
        >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
        </SimpleInputTextField>
      </TableCell>
        <SimpleInputField
          id="name"
          required
          label="name"
          data={object}
          action={action}
        />
      <TableCell>
      <InputLabel style={{ marginRight: 10 }}>Icon</InputLabel>
      <IconSelector
        iconName={object.icon}
        onIconChange={value => handleIconChange(object, action, 'icon', value)}
        iconOptions={[
          'fa-map-marked',
          'fa-clock',
          'fa-check',
          'fa-clipboard',
          'fa-chart-bar',
          'fa-edit',
          'fa-comment-dots',
          'fa-graduation-cap',
          'fa-calculator',
          'fa-bullseye',
          'fa-search',
          'fa-chart-line',
          'fa-handshake',
          'fa-pencil-alt',
          'fa-sticky-note',
          'fa-envelope',
          'fa-user-friends',
          'fa-user-check',
        ]} 
      />
      </TableCell>
      <SimpleInputField
        id="title"
        autoFocus
        style={{ width: '250px' }}
        label="title"
        data={object}
        action={action}
      />
        <SimpleInputField
          id="title_fr"
          autoFocus
          style={{ width: '250px' }}
          label="title_fr"
          data={object}
          action={action}
        />
      <TableCell>
      <InputLabel style={{ marginRight: 10 }}>Sub Icon</InputLabel>
      <IconSelector
        iconName={object.sub_icon}
        onIconChange={value => handleIconChange(object, action, 'sub_icon', value)}
        iconOptions={[
          'fa-map-marked',
          'fa-clock',
          'fa-check',
          'fa-clipboard',
          'fa-chart-bar',
          'fa-edit',
          'fa-comment-dots',
          'fa-graduation-cap',
          'fa-calculator',
          'fa-bullseye',
          'fa-search',
          'fa-chart-line',
          'fa-handshake',
          'fa-pencil-alt',
          'fa-sticky-note',
          'fa-envelope',
          'fa-user-friends',
          'fa-user-check',
        ]} 
      />
      </TableCell>
      <SimpleInputField
        id="sub_title"
        required
        label="sub_title"
        data={object}
        action={action}
      />
          <SimpleInputField
            id="sub_title_fr"
            required
            label="sub_title_fr"
            data={object}
            action={action}
          />
      <br />
      <TextField
        id="description"
        autoFocus
        label="description"
        onChange={e => {
          updateField(object, action, e);
        }}
        multiline
        value={object.description || ''}
        rows="2"
        className={classes.textField}
        margin="dense"
        style={{ width: '30%' }}
      />
          <TextField
            id="description_fr"
            autoFocus
            label="description_fr"
            onChange={e => {
              updateField(object, action, e);
            }}
            multiline
            value={object.description_fr || ''}
            rows="2"
            className={classes.textField}
            margin="dense"
            style={{ width: '30%' }}
          />
      <SimpleInputField
        id="url_link"
        required
        label="url_link"
        data={object}
        action={action}
      />
    <TableCell>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Save"
        disabled={!object.name || !object.modified}
        onClick={() => {
          updateAction(object);
        }}>
        <Icon>save</Icon>
      </IconButton>
      <IconButton className={classes.button} aria-label="Delete">
        <DeleteIcon
          onClick={() => {
            deleteAction(object);
          }}
        />
      </IconButton>
    </TableCell>
        </TableCell>
  </TableRow>
);

const OnboardingBannerWidgetWithStyles = withStyles(styles)(OnboardingBannerWidget);
export default injectIntl(OnboardingBannerWidgetWithStyles);
