/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import { NetworkStatus } from 'apollo-client';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import qs from 'query-string';
import { get } from 'lodash';
import * as actions from '../actions/taskActionCreators';
import Tasks from '../components/Tasks/Tasks';
import { TasksQuery, updateTaskMutation, getTaskQuery } from '../components/Tasks/TaskQuery';

const renderCheck = task => {
  if (task.status === 'open') {
    return 'check_box_outline_blank';
  }
  return 'check_box';
};
const columns = (activityTypes, refetch, updateState, updateTaskList, updateTaskCard) => [
  {
    id             : 'status',
    dataType       : 'string',
    disablePadding : false,
    label          : 'Status',
    filter         : false,

    renderMethod   : t => (
      <Mutation mutation={updateTaskMutation}>
        {updateTask => (
          <span style={{ borderRadius: '50%', background: 'rgb(238, 238, 238)', padding: '20px 0', border: 'none' }}>
            <Button
              onClick={() => {
                const old_task = t;
                const variables = {
                  ...t,
                  status : get(t, 'status', 'open') === 'completed' ? 'open' : 'completed',
                  force  : true,
                };
                updateTask({
                  variables,
                }).then(() => {
                  refetch()
                    .then(response => {
                      updateTaskList(get(response, 'data.getTasks').find(task => task.id === t.id));
                      updateTaskCard({ task: get(response, 'data.getTasks').find(task => task.id === t.id) });
                    })
                    .catch(e => {
                      updateTaskList(old_task);
                      updateTaskCard({ task: old_task });
                    });
                });
              }}>
              <span
                noClick
                type="noClick"
                style={{
                  marginTop : 7,
                  fontSize  : 20,
                  color     : get(t, 'status', 'open') === 'completed' ? `green` : `black`,
                }}>
                <i className="material-icons">{renderCheck(t)}</i>
              </span>
            </Button>
          </span>
        )}
      </Mutation>
    ),
  },
  {
    id             : 'name',
    dataType       : 'string',
    disablePadding : false,
    label          : 'Name',
    filter         : false,
    customStyle    : {
      position   : 'sticky',
      left       : 72,
      zIndex     : 10,
      background : 'white',
    },
    customWidth    : 150,
    renderMethod   : task => (
      <span style={{ textDecoration: get(task, 'status') === 'completed' ? 'line-through' : 'none' }}>
        {decodeURI(task.name)}
      </span>
    ),
  },

  {
    id             : 'activity_types',
    dataType       : 'string',
    disablePadding : false,
    label          : 'Activity Type',
    filter         : true,
    ransack_filter : 'activity_type_id_in',
    filterOptions  : (activityTypes || []).map(at => ({ value: at.id, name: at.name })),
    renderMethod   : task => get(task, 'activityType.name', '-'),
  },
  {
    id             : 'inventory_users',
    dataType       : 'string',
    disablePadding : false,
    label          : 'Owner',
    filter         : false,

    renderMethod   : task => get(task, 'inventoryUser.name', '-'),
  },
  {
    id             : 'pipeline_cards',
    dataType       : 'string',
    disablePadding : false,
    label          : 'Related To',
    filter         : false,
    renderMethod   : task => decodeURI(get(task, 'pipelineCard.name', '-')),
  },
  {
    id             : 'dueDate',
    dataType       : 'number',
    disablePadding : false,
    label          : 'Due Date',
    filter         : false,
    filterType     : 'range',
    renderMethod   : task => {
      if (get(task, 'dueDate')) {
        return moment(get(task, 'dueDate')).format('MM/DD/YYYY');
      }
      return '-';
    },
  },
];

class TaskContainer extends React.Component {
  state = {
    task                                                   : {},
    page                                                   : 0,
    loading                                                : true,
    activity_type_id_in                                    : null,
    name_or_inventory_user_name_or_pipeline_card_name_cont : null,
    userID                                                 : '',
    initialTaskLoad                                        : false,
    pipelineCardId                                         : null,
    cardDeleted                                            : false,
    offset                                                 : 0,
    orderBy                                                : 'created_at',
    order                                                  : 'desc',
    rowsPerPage                                            : 10,
    count                                                  : 0,
    nameSearch                                             : '',
  };

  render () {
    const {
      page,
      offset,
      task,
      activity_type_id_in,
      name_or_inventory_user_name_or_pipeline_card_name_cont,
      userID,
      initialTaskLoad,
      pipelineCardId,
      cardDeleted,
      order,
      orderBy,
      rowsPerPage,
      count,
      nameSearch,
    } = this.state;
    const { updateTaskCard, taskCardTask, all_tasks, loadTasks, updateTaskList, addTask } = this.props;
    const params = qs.parse(get(this.props, 'location.search'));
    const { pipeline_card_id, task_id } = params;
    if (pipeline_card_id && !pipelineCardId && !cardDeleted) {
      this.setState({ pipelineCardId: pipeline_card_id });
    }
    return (
      <div>
        {task_id && (
          <Query query={getTaskQuery} variables={{ id: task_id }}>
            {({ data, loading }) => {
              if (loading) return '';
              if (get(data, 'getTask') && !initialTaskLoad) {
                updateTaskCard({ task: data.getTask });
                this.setState({ task: data.getTask, initialTaskLoad: true });
              }
              return '';
            }}
          </Query>
        )}
        <Query
          query={TasksQuery}
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
          variables={{
            pipelineCardID : pipelineCardId,
            userID         : this.state.userID,
            taskId         : task_id,
            page           : this.state.page,
            order,
            orderBy,
            rowsPerPage,
            count,
            nameSearch,
          }}>
          {({ error, data, refetch, networkStatus }) => {
            const loading = networkStatus !== NetworkStatus.ready;
            if (loading === true && !this.state.loading) {
              this.setState({ loading });
            }

            if (error) return `Error!: ${error}`;
            if (!data) return <div />;
            if (!loading && all_tasks.loaded === false) {
              const c = columns(data.getActivityTypes, refetch, s => this.setState(s), updateTaskList, updateTaskCard);
              const fl = {};
              let inv_user = get(data, 'getCurrentUser.inventory_user', {});
              inv_user = { ...inv_user, label: inv_user.name, value: inv_user.id };
              c.forEach(col => (fl[col.id] = []));
              loadTasks({
                tasks                : data.getTasks,
                // loading              : false,
                filterList           : fl,
                filteredColumns      : c,
                currentInventoryUser : inv_user,
                activityTypes        : get(data, 'getActivityTypes'),
                managers             : get(data, 'getManagers'),
                currentUserId        : get(data, 'getCurrentUser.id'),
                page,
                order,
                orderBy,
                rowsPerPage,
                count                : data.getTasksCount,
                nameSearch,
              });
            }
            const { activityTypes } = this.state;

            return (
              <div>
                <Tasks
                  data={all_tasks.tasks}
                  activityTypes={all_tasks.activityTypes}
                  taskCount={get(data, 'getTaskCount')}
                  refetch={refetch}
                  updateState={s => this.setState(s)}
                  updateTaskCard={updateTaskCard}
                  updateTaskList={updateTaskList}
                  columns={columns(activityTypes, refetch)}
                  filteredColumns={all_tasks.filteredColumns}
                  filterList={all_tasks.filterList}
                  page={page}
                  name_or_inventory_user_name_or_pipeline_card_name_cont={
                    name_or_inventory_user_name_or_pipeline_card_name_cont
                  }
                  activity_type_id_in={activity_type_id_in}
                  offset={offset}
                  task={task}
                  order={order}
                  orderBy={orderBy}
                  userID={userID}
                  currentUser={all_tasks.currentUserId}
                  currentInventoryUser={all_tasks.currentInventoryUser}
                  managers={all_tasks.managers}
                  pipelineCardId={pipelineCardId}
                  taskCardTask={taskCardTask}
                  addTask={addTask}
                  loadTasks={loadTasks}
                  loading={loading}
                  rowsPerPage={rowsPerPage}
                  count={get(data, 'getTasksCount')}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  taskCardTask : get(state.all_tasks, 'taskCardTask', {}),
  all_tasks    : state.all_tasks,
});

TaskContainer.propTypes = {
  updateTaskCard : PropTypes.func.isRequired,
  taskCardTask   : PropTypes.object.isRequired,
  all_tasks      : PropTypes.object.isRequired,
  loadTasks      : PropTypes.func.isRequired,
  updateTaskList : PropTypes.func.isRequired,
  addTask        : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(TaskContainer);
