import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { classNames } from '../../lib/findBobUtils';

const styles = theme => ({
  root      : {
    fontSize     : '16px',
    border       : '1px solid #e1e1e1',
    padding      : '6px 12px',
    borderRadius : '2px',
    margin       : '4px',
    display      : 'inline-flex',
    background   : theme.palette.primary.main,
  },
  selected      : {
    background   : theme.palette.primary.main,
    color      : '#eee',
    "&$checked": {
      color: theme.palette.secondary.main,
    }
  },
  grey      : {
    background : '#e1e1e1',
    color      : '#333',
  },
  label     : {
    paddingLeft   : '6px',
    fontWeight    : 'normal',
    verticalAlign : 'text-top',
  },
  rjpe      : {
    background : '#002949',
    color      : '#fff',
  },
  grey_text : {
    color : '#aaa',
  },
});

const CheckButton = ({
  checked,
  label,
  value,
  classes,
  onClick,
  stylesheetName = '',
  disable = false,
  notAllowed = false,
}) => (
  <div
    className={classNames([ classes.root, checked ? classes.selected : classes.grey ])}
    style={{ cursor: notAllowed ? 'not-allowed' : 'pointer' }}>
    <input
      type="checkbox"
      id={label}
      name={value.toString()}
      value={value}
      checked={checked}
      onClick={onClick}
      disabled={disable}
      className={checked ? classes.selected : classes.grey}
      style={{ cursor: notAllowed ? 'not-allowed' : 'pointer' }}
      onChange={() => {}}
    />
    <label
      className={classNames([ classes.label, disable && classes.grey_text ])}
      label={label}
      disabled={disable}
      style={{ cursor: notAllowed ? 'not-allowed' : 'pointer' }}>
      {label}
    </label>
  </div>
);

export default withStyles(styles)(CheckButton);
