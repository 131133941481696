import React from 'react';
import PropTypes from 'prop-types';
import ViewAProfile from './ViewAProfile';

const renderSelected = (selectedView, dealRoomName) => {
  switch (selectedView) {
    case 'view_a_profile':
      return <ViewAProfile />;
    case 'enhance_profile':
      return <div>Enhance view component</div>;
    case 'review_valuation':
      return <div>Review Valuation component</div>;
    case 'build_a_plan':
      return <div>Build a plan component</div>;
    case 'build_a_deal_room':
      return <div>{`Build a ${dealRoomName} component`}</div>;
    case 'send_a_message':
      return <div>Send a message component</div>;
    case 'see_practice_metrics':
      return <div>See practice metrics component</div>;
    default:
      return <ViewAProfile />;
  }
};

const ViewAreaContainer = ({ selectedView, dealRoomName }) => {
  return <div>{renderSelected(selectedView, dealRoomName)}</div>;
};

ViewAreaContainer.propTypes = {
  selectedView : PropTypes.string.isRequired,
};

export default ViewAreaContainer;
