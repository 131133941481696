/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { find, get, startCase } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import qs from 'query-string';
import UserMenu from './UserMenu';
import Menu from './MenuItems';
import NotificationsMenuContainer from '../../containers/NotificationsMenuContainer';

const drawerWidth = 300;

// {label: '', url: '', controllerAction: '', menu: []},
class NavigationDrawer extends React.Component {
  constructor (props) {
    super(props);

    const params = qs.parse(location.search);
    const { open_nav } = params;
    let open_val = true;
    if (params.open_nav) {
      open_val = open_nav === 'true';
    }
    this.state = {
      open        : open_val,
      subMenuOpen : true,
      active      : null,
    };
  }

  toggleDrawerOpen = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  toggleSubMenu = label => {
    const { state } = this;
    state[label] = !state[label];

    this.setState({ ...state });
  };

  goToLink = url => (window.location.href = `${url}?open_nav=${this.state.open}}`);

  getMenu = () => {
    const { agent_word } = this.props;
    const { open } = this.state;
    return Menu(startCase(agent_word), open);
  };

  getTitle = controller => {
    const { active } = this.state;
    if (controller === 'suggestions') {
      return <span>Suggested Cases</span>;
    }
    return <span>{get(active, 'label', startCase(controller))}</span>;
  };

  renderListItem = (listItem, conAction, classes, nested = false, parent) => {
    const { label, icon, controllerAction, menu, url, admin, feature, manager_can_view } = listItem;
    const { active, open } = this.state;
    const { user, hidden_actions } = this.props;

    if ((admin && !user.admin) || hidden_actions.includes(feature)) return <span />;
    if (manager_can_view && !user.can_view_valuation_menu && !user.admin) return <span />;

    if (controllerAction === conAction && !active) {
      if (parent) {
        const { state } = this;
        state[parent] = true;
        this.setState({ ...state, active: listItem });
      } else {
        this.setState({ active: listItem });
      }
    }
    return (
      <a href={menu ? '#' : `${url}?open_nav=${open}`}>
        <ListItem
          button
          key={label}
          classes={{
            root : classNames(
              { [classes.nested]: nested },
              {
                [classes.active]: conAction === controllerAction,
              }
            ),
          }}
          onClick={menu ? () => this.toggleSubMenu(label) : () => this.goToLink(url)}>
          <ListItemIcon className={classes.list}>{icon}</ListItemIcon>
          <ListItemText classes={{ primary: classes.text }} primary={label} />
        </ListItem>
        {menu && (
          <Collapse in={this.state[label]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {menu.map(m => this.renderListItem(m, conAction, classes, true, label))}
            </List>
          </Collapse>
        )}
      </a>
    );
  };

  render () {
    const { classes, theme, pageTitle, user, controller, action, logo, hidden_actions, company } = this.props;
    const { open, redirect, active } = this.state;
    const conAction = [ controller, action ].join('/');

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}>
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.toggleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: open,
              })}>
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              <span className={classes.appBarTitle}>{this.getTitle(controller)}</span>
            </Typography>
            <span style={{ marginLeft: 'auto' }}>
              {hidden_actions.findIndex(n => n === 'notifications') < 0 && (
                <NotificationsMenuContainer color="default" />
              )}
            </span>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper : classNames({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
              [classes.paper]: true,
            }),
          }}
          open={open}>
          <div className={classes.toolbar}>
            {open && <UserMenu user={user} company={company} />}
            <IconButton className={classes.closeButton} onClick={this.toggleDrawerOpen}>
              {open ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </div>
          <Divider classes={{ light: classes.divider }} light />
          <List className={classes.list}>{this.getMenu().map(m => this.renderListItem(m, conAction, classes))}</List>
          {open && (
            <div className={classes.companyLogo}>
              <img src={logo} className={classes.logo} />
            </div>
          )}
        </Drawer>
        <main className={classes.content} />
      </div>
    );
  }
}

const styles = theme => ({
  root        : {
    display         : 'flex',
    backgroundColor : '#444',
    color           : '#eee',
    height          : 100,
    fontSize        : 16,
  },
  paper       : {
    backgroundColor : '#444',
    color           : '#eee',
  },
  list        : {
    color    : '#eee',
    overflow : 'auto',
  },
  companyLogo : {
    marginTop : 75,
  },
  closeButton : {
    color    : '#eee',
    position : 'absolute',
    top      : 0,
    right    : 6,
  },
  active      : {
    backgroundColor : '#999',
    color           : '#eee',
  },
  text        : {
    color    : '#eee',
    fontSize : 16,
  },
  nested      : {
    color       : '#eee',
    paddingLeft : theme.spacing.unit * 4,
  },
  divider     : {
    backgroundColor : '#667',
  },
  logo        : {
    margin   : '0 auto 16px 16px',
    height   : 50,
    position : 'absolute',
    bottom   : 0,
    left     : 0,
    filter   : 'grayscale(1)',
  },
  appBar      : {
    zIndex          : theme.zIndex.drawer + 1,
    backgroundColor : 'rgb(53 97 183)',
    transition      : theme.transitions.create([ 'width', 'margin' ], {
      easing   : theme.transitions.easing.sharp,
      duration : theme.transitions.duration.leavingScreen,
    }),
    fontSize        : 18,
    position        : 'fixed',
    left            : 0,
    top             : 0,
  },
  appBarTitle : {
    fontSize : 18,
  },
  appBarShift : {
    marginLeft : drawerWidth,
    width      : `calc(100% - ${drawerWidth}px)`,
    transition : theme.transitions.create([ 'width', 'margin' ], {
      easing   : theme.transitions.easing.sharp,
      duration : theme.transitions.duration.enteringScreen,
    }),
    height     : 62,
  },
  menuButton  : {
    marginLeft  : 12,
    marginRight : 36,
  },
  hide        : {
    display : 'none',
  },
  drawer      : {
    width           : drawerWidth,
    flexShrink      : 0,
    whiteSpace      : 'nowrap',
    backgroundColor : '#444',
    color           : '#eee',
  },
  drawerOpen  : {
    width      : drawerWidth,
    transition : theme.transitions.create('width', {
      easing   : theme.transitions.easing.sharp,
      duration : theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose : {
    transition                   : theme.transitions.create('width', {
      easing   : theme.transitions.easing.sharp,
      duration : theme.transitions.duration.leavingScreen,
    }),
    overflowX                    : 'hidden',
    width                        : theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width : theme.spacing.unit * 9 + 1,
    },
  },
  toolbar     : {
    background     : 'url(https://s3-us-west-2.amazonaws.com/findbob-production/images/header-bg-9.png)',
    filter         : 'grayscale(1)',
    backgroundSize : 'auto 177px',
    display        : 'flex',
    alignItems     : 'center',
    padding        : '0 8px',
    color          : '#eee',
    ...theme.mixins.toolbar,
  },
  content     : {
    flexGrow : 1,
  },
});

NavigationDrawer.propTypes = {
  classes        : PropTypes.object.isRequired,
  theme          : PropTypes.object,
  logo           : PropTypes.string.isRequired,
  controller     : PropTypes.string.isRequired,
  action         : PropTypes.string.isRequired,
  agent_word     : PropTypes.string,
  hidden_actions : PropTypes.array,
  user           : PropTypes.object.isRequired,
};

NavigationDrawer.defaultProps = {
  hidden_actions : [],
  agent_word     : 'Agent',
  theme          : {},
};

export default withStyles(styles, { name: 'NavDrawer', productionPrefix: 'fb101' })(NavigationDrawer);
