import React from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import { graphql } from 'react-apollo';
import { get } from 'lodash';
import moment from 'moment';
import WarningText from '../WarningText/WarningText';
import { createPipelineCardMutation } from './PipelineQueries';
import InventoryUserSearchField from '../../containers/InventoryUserSearchFieldContainer';
import FindbobDatePicker from '../FindbobDatePicker/FindbobDatePicker';
import CustomDollarFormat from '../CustomDollarFormat/CustomDollarFormat';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';

class NewPipelineCardDialog extends React.Component {
  originalState = {
    name          : '',
    value         : null,
    description   : null,
    visibility    : 'public_visibility',
    priority      : 'medium',
    owner         : null,
    close_date    : moment().add(1, 'M'),
    primary_agent : null,
    open          : false,
  };

  state = { ...this.originalState };

  onOpen () {
    const { currentInventoryUser } = this.props;

    this.setState({ open: true, owner: currentInventoryUser });
  }

  onClose () {
    this.setState({ ...this.originalState });
  }

  onSave () {
    const { mutate, addPipelineCard, pipeline, refetch } = this.props;
    const { name, value, description, visibility, priority, owner, close_date, primary_agent } = this.state;

    mutate({
      variables : {
        name              : encodeURI(name),
        pipeline_id       : pipeline.id,
        pipeline_stage_id : get(pipeline, 'pipeline_stages[0].id'),
        value             : parseFloat(value),
        description       : encodeURI(description),
        visibility,
        priority,
        close_date,
        owner_id          : owner.id,
        primary_agent_id  : primary_agent.id,
      },
    })
      .then(response => {
        addPipelineCard(response.data.createPipelineCard);
        refetch();
        this.onClose();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  }

  validateDate = date => {
    if (date === null) {
      return <div />;
    }
    return moment(date).isSameOrAfter(moment());
  };

  render () {
    const { pipeline, classes } = this.props;
    const { open, name, value, description, visibility, priority, owner, primary_agent, close_date } = this.state;

    return (
      <div className={classes.container}>
        <Fab
          disabled={(pipeline.pipeline_stages || []).length < 1}
          variant="extended"
          color="primary"
          aria-label="Add"
          onClick={() => this.onOpen()}
          className={classes.addButton}>
          Add Case <ArrowDownIcon />
        </Fab>
        {open && (
          <Dialog open={open} onClose={() => this.onClose()} aria-labelledby="form-dialog-new-pipeline">
            <DialogTitle id="form-dialog-title">Create A New Case</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                id="name"
                className={classes.dialogRoot}
                label="Name"
                type="text"
                value={name}
                placeholder="Add Name"
                onChange={e => {
                  this.setState({ name: e.target.value });
                }}
                fullWidth
              />
              <br />
              <TextField
                id="value"
                label="Value"
                value={value}
                placeholder="Add Value"
                InputProps={{
                  inputComponent : CustomDollarFormat,
                }}
                onChange={e => {
                  this.setState({ value: e.target.value });
                }}
                fullWidth
              />
              <br />
              <TextField
                id="description"
                label="Description"
                type="text"
                value={description}
                placeholder="Add Description"
                onChange={e => {
                  this.setState({ description: e.target.value });
                }}
                fullWidth
              />
              <br />
              <br />
              <div>Close Date: </div>
              <FindbobDatePicker
                getDate={date => {
                  this.setState({ close_date: date });
                }}
                date={close_date}
                maxYear={moment().year() + 20}
                minYear={moment().year()}
                ascYears
              />
              <br />
              <WarningText visible={!this.validateDate(close_date)} message="Close Date Must Be In The Future" />
              <br />
              <ManagerSearchField
                label="Owner"
                value={owner}
                onChange={v => {
                  this.setState({ owner: v });
                }}
              />
              <br />
              <InventoryUserSearchField
                label="Primary Agent"
                value={primary_agent}
                onChange={v => {
                  this.setState({ name: `${this.state.name} - ${v.name}`, primary_agent: v });
                }}
              />
              <br />
              <br />
              <TextField
                id="standard-priority"
                select
                fullWidth
                label="Priority"
                className={classes.inputField}
                value={priority}
                onChange={e => {
                  this.setState({ priority: e.target.value });
                }}
                InputLabelProps={{
                  shrink : true,
                }}
                margin="normal">
                {[
                  { value: 'no', label: 'None' },
                  { value: 'low', label: 'Low' },
                  { value: 'medium', label: 'Medium' },
                  { value: 'high', label: 'High' },
                ].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              <TextField
                id="standard-visibility"
                select
                fullWidth
                label="Visibility"
                className={classes.inputField}
                value={visibility}
                onChange={e => {
                  this.setState({ visibility: e.target.value });
                }}
                InputLabelProps={{
                  shrink : true,
                }}
                margin="normal">
                {[
                  { value: 'public_visibility', label: 'Public' },
                  { value: 'private_visibility', label: 'Private' },
                ].map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <Divider />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.onClose()} color="primary">
                Cancel
              </Button>
              <Button
                disabled={
                  !(
                    name &&
                    value &&
                    description &&
                    owner &&
                    primary_agent &&
                    priority &&
                    visibility &&
                    this.validateDate(close_date)
                  )
                }
                onClick={() => this.onSave()}
                color="primary">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

const styles = {
  addButton  : {
    height : 35,
    width  : 120,
  },
  container  : {
    display : 'inline-block',
    float   : 'right',
  },
  dialogRoot : {
    width : 380,
  },
};

NewPipelineCardDialog.propTypes = {
  mutate          : PropTypes.func.isRequired,
  addPipelineCard : PropTypes.func.isRequired,
  pipeline        : PropTypes.object,
  refetch         : PropTypes.func.isRequired,
  classes         : PropTypes.object.isRequired,
};

NewPipelineCardDialog.defaultProps = {
  pipeline : {},
};

export default graphql(createPipelineCardMutation)(withStyles(styles)(NewPipelineCardDialog));
