import React, {useState} from 'react';
import gql from 'graphql-tag';
import { ApolloProvider, Query } from 'react-apollo';
import { IntlProvider, createIntl, createIntlCache, injectIntl, FormattedMessage } from 'react-intl';
import translations from '../lib/i18n/translations.json';
import defaultLocale from '../lib/i18n/default.json';
import client from '../lib/apolloClient';
import Spotlight from '../components/Spotlight/Spotlight';

const SpotlightQuery = gql`
  query getSpotlights($spotlights: [String]) {
    getSpotlights(spotlights: $spotlights)
    getCurrentUser {
      id
      company {
        id
        catastrophic_planning_word
      }
    }
  }
`;

const templateStep = () => {
  let locale = { back: 'Back', close: 'Close', last: 'Done', next: 'Next', open: 'Open the dialog', skip: 'Skip' }
  if(window.locale == 'fr') {
    locale = { back: 'Retour', close: 'Fermer', last: 'Terminé', next: 'Suivant', open: 'Ouvert', skip: 'Passer' }
  }
  return ({
    title         : '',
    content       : '',
    target        : '',
    position      : 'bottom-left',
    type          : 'hover',
    isFixed       : true,
    disableBeacon : true,
    locale,
    styles        : {
      contentAlign : 'center',
      header       : {
        borderBottom : '1px solid #45A6FF',
      },
      main         : {
        padding : '20px',
      },
      footer       : {
        display : 'none',
      },
      skip         : {
        color : '#f04',
      },
      hole         : {
        backgroundColor : 'rgba(69,166,255, 0.2)',
      },
    },
})};

const SpotlightSteps = catastrophicWord => {
  return {
    managed_profile_strength      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_strength_title"/>,
      content : <FormattedMessage id="spotlight.profile_strength_content"/>,
      target  : '.managed_profile_strength',
      id      : 'managed_profile_strength',
    },
    deal_room_menu                : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_menu_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_menu_content"/>,
      target  : '.deal_room_nav',
      id      : 'deal_room_menu',
    },
    deal_room_eval_matches        : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_eval_matches_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_eval_matches_content"/>,
      target  : '.evaluate_matches',
      id      : 'deal_room_eval_matches',
    },
    deal_room_rolodex             : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_rolodex_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_rolodex_content"/>,
      target  : '.rolodex_spotlight',
      id      : 'deal_room_rolodex',
    },
    deal_room_invite_firms        : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_invite_firms_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_invite_firms_content"/>,
      target  : '.invited_firms',
      id      : 'deal_room_invite_firms',
    },
    deal_room_saved               : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_saved_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_saved_content"/>,
      target  : '.saved_firms',
      id      : 'deal_room_saved',
    },
    deal_room_invite_button       : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_invite_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_invite_button_content"/>,
      target  : '.invite_button',
      id      : 'deal_room_invite_button',
    },
    deal_room_message_button      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_message_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_message_button_content"/>,
      target  : '.message_button',
      id      : 'deal_room_message_button',
    },
    deal_room_schedule_button     : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_schedule_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_schedule_button_content"/>,
      target  : '.schedule_button',
      id      : 'deal_room_schedule_button',
    },
    deal_room_archive_button      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_archive_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_archive_button_content"/>,
      target  : '.archive_button',
      id      : 'deal_room_archive_button',
    },
    deal_room_shortlist_button    : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_shortlist_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_shortlist_button_content"/>,
      target  : '.shortlist_button',
      id      : 'deal_room_shortlist_button',
    },
    deal_room_send_nda_button     : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_send_nda_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_send_nda_button_content"/>,
      target  : '.nda_button',
      id      : 'deal_room_send_nda_button',
    },
    deal_room_sign_nda_button     : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_sign_nda_button_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_sign_nda_button_content"/>,
      target  : '.sign_nda_button',
      id      : 'deal_room_sign_nda_button',
    },
    managed_home_profile_strength : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.managed_home_profile_strength_title"/>,
      content : <FormattedMessage id="spotlight.managed_home_profile_strength_content"/>,
      target  : '.managed_update_profile',
      id      : 'managed_home_profile_strength',
    },
    managed_home_valuation        : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.managed_home_valuation_title"/>,
      content : <FormattedMessage id="spotlight.managed_home_valuation_content"/>,
      target  : '.managed_valuation',
      id      : 'managed_home_valuation',
    },
    managed_home_matches          : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.managed_home_matches_title"/>,
      content : <FormattedMessage id="spotlight.managed_home_matches_content"/>,
      target  : '.managed_matches',
      id      : 'managed_home_matches',
    },
    managed_home_deal_tracker     : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.managed_home_deal_tracker_title"/>,
      content : <FormattedMessage id="spotlight.managed_home_deal_tracker_content"/>,
      target  : '.managed_deal_tracker',
      id      : 'managed_home_deal_tracker',
    },
    teaser_home_buyer             : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.teaser_home_buyer_title"/>,
      content : <FormattedMessage id="spotlight.teaser_home_buyer_content"/>,
      target  : '.spotlight_teaser_buyer',
      id      : 'teaser_home_buyer',
    },
    teaser_home_buyer_banner      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.teaser_home_buyer_banner_title"/>,
      content : <FormattedMessage id="spotlight.teaser_home_buyer_banner_content"/>,
      target  : '.spotlight_teaser_buyer_banner',
      id      : 'teaser_home_buyer_banner',
    },
    teaser_home_buyer_teaser      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.teaser_home_buyer_teaser_title"/>,
      content : <FormattedMessage id="spotlight.teaser_home_buyer_teaser_content"/>,
      target  : '.spotlight_teaser_buyer_teaser',
      id      : 'teaser_home_buyer_teaser',
    },
    profile_welcome               : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_welcome_title"/>,
      content : <FormattedMessage id="spotlight.profile_welcome_content"/>,
      target  : '.spotlight_profile_welcome',
      id      : 'profile_welcome',
    },
    profile_valuation             : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_valuation_title"/>,
      content : <FormattedMessage id="spotlight.profile_valuation_content"/>,
      target  : '.spotlight_profile_valuation',
      id      : 'profile_valuation',
    },
    profile_metrics               : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_metrics_title"/>,
      content : <FormattedMessage id="spotlight.profile_metrics_content"/>,
      target  : '.spotlight_profile_metrics',
      id      : 'profile_metrics',
    },
    profile_benchmarks            : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_benchmarks_title"/>,
      content : <FormattedMessage id="spotlight.profile_benchmarks_content"/>,
      target  : '.spotlight_profile_benchmarks',
      id      : 'profile_benchmarks',
    },
    profile_edit                  : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_edit_title"/>,
      content : <FormattedMessage id="spotlight.profile_edit_content"/>,
      target  : '.spotlight_profile_edit',
      id      : 'profile_edit',
    },
    profile_show                  : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.profile_show_title"/>,
      content : <FormattedMessage id="spotlight.profile_show_content"/>,
      target  : '.spotlight_profile_show',
      id      : 'profile_show',
    },
    practice_metrics_menu         : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.practice_metrics_menu_title"/>,
      content : <FormattedMessage id="spotlight.practice_metrics_menu_content"/>,
      target  : '.spotlight_practice_metrics_menu',
      id      : 'practice_metrics_menu',
    },
    practice_metrics_container    : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.practice_metrics_container_title"/>,
      content : <FormattedMessage id="spotlight.practice_metrics_container_content"/>,
      target  : '.spotlight_practice_metrics_container',
      id      : 'practice_metrics_container',
    },
    practice_benchmarks_menu      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.practice_benchmarks_menu_title"/>,
      content : <FormattedMessage id="spotlight.practice_benchmarks_menu_content"/>,
      target  : '.spotlight_practice_benchmarks_menu',
      id      : 'practice_benchmarks_menu',
    },
    practice_benchmarks_container : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.practice_benchmarks_container_title"/>,
      content : <FormattedMessage id="spotlight.practice_benchmarks_container_content"/>,
      target  : '.spotlight_practice_benchmarks_container',
      id      : 'practice_benchmarks_container',
    },
    valuation_menu                : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.valuation_menu_title"/>,
      content : <FormattedMessage id="spotlight.valuation_menu_content"/>,
      target  : '.spotlight_valuation_menu',
      id      : 'valuation_menu',
    },
    valuation_reports             : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.valuation_menu_title"/>,
      content : <FormattedMessage id="spotlight.valuation_menu_content"/>,
      target  : '.spotlight_valuation_reports',
      id      : 'valuation_reports',
    },
    pe_valuation_practice_data    : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.pe_valuation_practice_data_title"/>,
      content : <FormattedMessage id="spotlight.pe_valuation_practice_data_content"/>,
      target  : '.spotlight_pe_indication_of_value',
      id      : 'pe_valuation_practice_data',
    },
    pe_valuation_report_toggle    : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.pe_valuation_report_toggle_title"/>,
      content : <FormattedMessage id="spotlight.pe_valuation_report_toggle_content"/>,
      target  : '.spotlight_pe_valuation_report_toggle',
      id      : 'pe_valuation_report_toggle',
    },
    custom_enhance_profile        : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_enhance_profile_title"/>,
      content : <FormattedMessage id="spotlight.custom_enhance_profile_content"/>,
      target  : '.profile',
      id      : 'custom_enhance_profile',
    },
    custom_obtain_valuation       : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_obtain_valuation_title"/>,
      content : <FormattedMessage id="spotlight.custom_obtain_valuation_content"/>,
      target  : '.valuation',
      id      : 'custom_obtain_valuation',
    },
    custom_plan                   : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_plan_title" values={{catastrophicWord: catastrophicWord}} />,
      content : <FormattedMessage id="spotlight.custom_plan_content" values={{catastrophicWord: catastrophicWord}} />,
      target  : '.custom_plan',
      id      : 'custom_plan',
    },
    custom_review_matches         : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_review_matches_title"/>,
      content : <FormattedMessage id="spotlight.custom_review_matches_content"/>,
      target  : '.matches',
      id      : 'custom_review_matches',
    },
    custom_financial_support      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_financial_support_title"/>,
      content : <FormattedMessage id="spotlight.custom_financial_support_content"/>,
      target  : '.managed_valuation',
      id      : 'custom_financial_support',
    },
    deal_room_review_shortlist    : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.deal_room_review_shortlist_title"/>,
      content : <FormattedMessage id="spotlight.deal_room_review_shortlist_content"/>,
      target  : '#deal_room_review_shortlist',
      id      : 'deal_room_review_shortlist',
    },
    custom_match_pool_status      : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.custom_match_pool_status_title"/>,
      content : <FormattedMessage id="spotlight.custom_match_pool_status_content"/>,
      target  : '.custom_match_pool_status',
      id      : 'custom_match_pool_status',
    },
    rolodex_page                  : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.rolodex_page_title"/>,
      content : <FormattedMessage id="spotlight.rolodex_page_content"/>,
      target  : '.managed_matches',
      id      : 'rolodex_page',
    },
    cfa_dashboard                  : {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.cfa_title"/>,
      content : <FormattedMessage id="spotlight.cfa_dashboard"/>,
      target  : '.cfa_dashboard',
      id      : 'cfa_dashboard',
    },
    cfa_buyer_seller_summaries: {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.cfa_seller_buyer_title"/>,
      content : <FormattedMessage id="spotlight.cfa_buyer_seller_summaries_content"/>,
      target  : '.cfa_buyer_seller_summaries',
      id      : 'cfa_buyer_seller_summaries',
    },
    cfa_graph: {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.cfa_graph_title"/>,
      content : <FormattedMessage id="spotlight.cfa_graph_content"/>,
      target  : '.cfa_graph',
      id      : 'cfa_graph',
    },
    cfa_table: {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.cfa_table_title"/>,
      content : <FormattedMessage id="spotlight.cfa_table_content"/>,
      target  : '.cfa_table',
      id      : 'cfa_table',
    },
    cfa_edit: {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.cfa_edit_title"/>,
      content : <FormattedMessage id="spotlight.cfa_edit_content"/>,
      target  : '.cfa_edit',
      id      : 'cfa_edit',
    },
    my_platform_goals: {
      ...templateStep(),
      title   : <FormattedMessage id="spotlight.my_platform_goals_titles"/>,
      content : <FormattedMessage id="spotlight.my_platform_goals_contents"/>,
      target  : '.my_platform_goals',
      id      : 'my_platform_goals',
    },
  };
};

export const generateSteps = (stepArray, catastrophicWord) => stepArray.map(s => SpotlightSteps(catastrophicWord)[s]);

class SpotlightContainer extends React.Component {
  state = {
    waiting: true, 
    timerStarted: false
  };

  render(){
    const {props, railsContext} = this.props;
    const {waiting, timerStarted} = this.state;
    
    window.locale = railsContext.i18nLocale || 'en';
    const cache = createIntlCache();

    const intl = createIntl(
      {
        locale   : window.locale,
        messages : translations[window.locale || 'en'],
      },
      cache
    );

    if(!timerStarted){
      setTimeout(()=> this.setState({waiting: false}), 1000);
    }
    if(waiting) {
      return <span />;
    }

    return (
      <IntlProvider messages={translations[window.locale]} locale={window.locale}>
        <ApolloProvider client={client}>
          <Query fetchPolicy="network-only" query={SpotlightQuery} variables={{ spotlights: props.spotlights }}>
            {({ loading, error, data }) => {
              if (loading) return <span />;
              if (error) return <span />;
              let steps = [];
              if (data.getSpotlights) {
                const catastrophicWord = data.getCurrentUser.company.catastrophic_planning_word;
                steps = generateSteps(data.getSpotlights, catastrophicWord);
              }

              return <Spotlight {...props} steps={steps} />;
            }}
          </Query>
        </ApolloProvider>
      </IntlProvider>
    );
  };
};

const SpotlightContainerWContext = (props, railsContext) => <SpotlightContainer props={props} railsContext={railsContext} />;

export default SpotlightContainerWContext;
