import { connect } from 'react-redux';
import Ticker from '../components/Ticker/Ticker';
import NewFormDialog from '../components/Ticker/TickerNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/tickersActionCreators';

const mapStateToProps = (state, props) => {
  return {
    ...state,
    listData: state.tickers,
    title: "Tickers",
    CrudRow: Ticker,
    NewFormDialog,
  }
};

export default connect(mapStateToProps, actions)(CrudTable);
