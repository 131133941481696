import React from 'react';
import { Card, CardContent, Typography, withStyles } from '@material-ui/core';
import { get } from 'lodash';

const CountCard = ({ title, count, classes, color }) => (
  <Card className={`${classes.countCard} ${classes[color]}`} >
    <CardContent>
      <Typography variant="h2">{count}</Typography>
      <Typography variant="h6">{title}</Typography>
    </CardContent>
  </Card>
);

const styles = theme => ({
  countCard: {
    margin: theme.spacing(2, 0),
    textAlign: 'center',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  secondary: {
    backgroundColor: "#999",
    color: theme.palette.primary.contrastText,
  },
});

export default withStyles(styles)(CountCard);
