import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import { get, debounce } from 'lodash';

class MyOpenTasksCard extends React.Component {
  toggleTask = debounce(task => {
    const { mutation } = this.props;
    mutation({ variables: { ...task } }).then(r => {
      this.setState({ tsk: r.data.updateTask });

      // refetch();
    });
  }, 300);

  constructor (props) {
    super(props);
    const { task } = this.props;
    this.state = {
      tsk : task,
    };
  }

  clickName = () => {
    const { pipeline } = this.props;
    window.open(`/admin/inventory_users?inv_user=${pipeline.agent_iu_id}`, '_self');
  };

  clicktask = task => {
    const taskUrl = `/admin/tasks?task_id=${task.id}`;
    let pipelineParams = '';
    if (get(task, 'pipelineCard.id')) {
      pipelineParams = `&pipeline_card_id=${task.pipelineCard.id}`;
    }
    window.open(`${taskUrl}${pipelineParams}`, '_self');
  };

  clickPipeline = () => {
    const { task } = this.props;
    window.open(`/admin/pipelines/${task.pipelineCard.pipeline.id}?card_id=${task.pipelineCard.id}`, '_self');
  };

  formatDate = date => {
    const today = moment().format('YYYYMMDD');
    if (today === moment(date).format('YYYYMMDD')) {
      return `Today at ${moment(date).format('LT')}`;
    }
    if (moment(date).isBefore(today)) {
      return (
        <div style={{ color: 'red' }}>
          {moment(date).format('MMM D YYYY')} at {moment(date).format('LT')}
        </div>
      );
    }
    return (
      <div>
        {moment(date).format('MMM D YYYY')} at {moment(date).format('LT')}
      </div>
    );
  };

  renderCheck = task => {
    if (task.status === 'open') {
      return 'check_box_outline_blank';
    }
    return 'check_box';
  };

  render () {
    const { classes, task } = this.props;
    const { tsk } = this.state;
    return (
      <div className={classes.box}>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>
              <Button
                onClick={() => {
                  const t = {
                    ...task,
                    status : get(task, 'status', 'open') === 'completed' ? 'open' : 'completed',
                  };
                  this.toggleTask(t);
                }}>
                <span
                  noClick
                  type="noClick"
                  style={{
                    marginTop : 10,
                    fontSize  : 20,
                    color     : get(task, 'status', 'open') === 'completed' ? `green` : `black`,
                  }}>
                  <i class="material-icons">{this.renderCheck(task)}</i>
                </span>
              </Button>
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <div className={classes.contentBox}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <button type="button" className={classes.button} onClick={() => this.clicktask(task)}>
                    <div className={classes.title}>{decodeURI(task.name)}</div>
                  </button>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <div style={{ textAlign: 'left' }}>
                      <button type="button" className={classes.contentButton} onClick={() => this.clickName()}>
                        <span className={classes.content}>{this.formatDate(task.dueDate)}</span>
                      </button>
                    </div>
                  </Grid>
                  {get(task, 'pipelineCard.name') && (
                    <Grid item xs={12}>
                      <div style={{ textAlign: 'left', marginTop: '-5px' }}>
                        <span className={classes.pipelineName}>
                          Related to{' '}
                          <button type="button" className={classes.pipelineBUtton} onClick={() => this.clickPipeline()}>
                            {decodeURI(get(task, 'pipelineCard.name'))}
                          </button>
                        </span>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

MyOpenTasksCard.propTypes = {
  classes  : PropTypes.object.isRequired,
  pipeline : PropTypes.object.isRequired,
  task     : PropTypes.object.isRequired,
  mutation : PropTypes.func.isRequired,
  refetch  : PropTypes.func.isRequired,
};

const styles = {
  box              : {
    marginTop : 0,
  },
  button           : {
    width      : '100%',
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  buttonBackground : {
    borderRadius : '50%',
    background   : '#eee',
    padding      : 20,
    marginRight  : 12,
  },
  contentButton    : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  title            : {
    textAlign  : 'left',
    fontWeight : 'bold',
  },
  event            : {
    textAlign : 'left',
    color     : '#0052CC',
  },
  contentBox       : {
    margin : '5px 0px 5px 5px',
  },
  content          : {
    textAlign : 'left',
    color     : '#808080',
    fontSize  : '1em',
  },
  contentName      : {
    textAlign  : 'left',
    color      : '#0052CC',
    fontSize   : '0.9em',
    fontWeight : 'bold',
  },
  note             : {
    textAlign    : 'left',
    fontSize     : 16,
    color        : '#000',
    marginTop    : 8,
    marginBottom : 10,
  },
  avatar           : {
    margin     : '10px 10px 0px 15px',
    background : '#eee',
  },
  avatarText       : {
    textTransform : 'uppercase',
  },
  pipelineName     : {
    fontSize : 12,
  },
  pipelineBUtton   : {
    fontSize       : 12,
    height         : 'auto',
    padding        : 0,
    border         : 'none',
    background     : 'none',
    color          : '#0052CC',
    textDecoration : 'underline',
  },
};

export default withStyles(styles)(MyOpenTasksCard);

// <button type="button" className={classes.box} onClick={() => this.clickName()}>
