import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { startCase } from 'lodash';
import * as actions from '../actions/dealActionCreators';
import { get } from 'lodash';

import Deals, {formatDealFilter} from '../components/Deals/Deals';
import InventoryUserAvatar from '../components/InventoryUserAvatar/InventoryUserAvatar';

const DealsQuery = gql`
  query DealsQuery(
    $limit: Int
    $order: String
    $orderBy: String
    $nameSearch: String
    $page: Int
    $dealsFilter: DealListViewFilterType
  ) {
    getDeals(
      limit: $limit
      order: $order
      orderBy: $orderBy
      nameSearch: $nameSearch
      page: $page
      dealsFilter: $dealsFilter
    ) {
      id
      headline
      invitation_deadline
      number_of_invites
      number_of_proposals
      number_of_ndas
      number_of_negotiations
      matches_count
      deal_state
      deal_name
      deal_type
      creation_date
      closed
      inventory_user {
        name
        transition_goals
      }
      owner {
        name
      }
    }
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
      }
      company {
        id
        default_deal_owner_customer
      }
    }
    getInventoryUsers(
      allCustomers: true
    ) {
      id
      name
      __typename
    }
    agentWordForCompany
    getDealCount(
      limit: $limit, 
      order: $order, 
      orderBy: $orderBy, 
      nameSearch: $nameSearch, 
      dealsFilter: $dealsFilter
    )
  }
`;

const DealsContainer = props => {
  const { deals, updateDeals } = props;
  const queryVars = {
    limit   : deals.rowsPerPage || 10,
    order   : deals.order || 'desc',
    orderBy : deals.orderBy || 'id',
    page    : deals.page || 0,
    dealsFilter: formatDealFilter(deals.dealsFilter) || {},
    nameSearch: deals.nameSearch
  };
  return (
    <Query
      query={DealsQuery}
      variables={queryVars}
      fetchPolicy="network-only"
      onCompleted={data => {
        console.log("updating deals")
        updateDeals({
          order       : queryVars.order,
          orderBy     : queryVars.orderBy,
          data        : data.getDeals,
          page        : deals.page || 0,
          rowsPerPage : queryVars.limit,
          count       : data.getDealCount,

        });
      }}
      >
      {({ loading, error, data, refetch }) => {
        return (
          <div>
            <Deals
              agentWordForCompany={
                get(data, 'agentWordForCompany') ? `${startCase(get(data, 'agentWordForCompany', 'agent'))}s` : ''
              }
              currentUser={get(data, 'getCurrentUser', {})}
              allCustomers={get(data, 'getInventoryUsers', {})}
              refetch={refetch}
              loading={loading}
            />
          </div>
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  state,
  deals : state.deals,
  intl  : state.intl,
});

DealsContainer.propTypes = {
  deals       : PropTypes.object.isRequired,
  updateDeals : PropTypes.func.isRequired,
  refetch     : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(DealsContainer);
