import React from 'react';
import PropTypes from 'prop-types';

const displayLocation = (city, province, county, countryForCompany, companyHasCounty, show_city) => {
  if (countryForCompany === 'us' && companyHasCounty && !show_city) {
    if (county) {
      return ` ${county} County, ${province}`;
    }
    if (city) {
      return `${province}`;
    }
  } else if (city) {
    return `${city}, ${province}`;
  }
};

const UserLocation = ({ user, countryForCompany, companyHasCounty }) => (
  <div>
    <span className="fa fa-map-marker " />{' '}
    {displayLocation(user.city, user.province, user.county, countryForCompany, companyHasCounty, user.show_city)}
  </div>
);

UserLocation.propTypes = {
  user              : PropTypes.object.isRequired,
  countryForCompany : PropTypes.string.isRequired,
  companyHasCounty  : PropTypes.bool.isRequired,
};

export default UserLocation;
