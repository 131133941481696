import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import Card from "@material-ui/core/Card";
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const LifetimeCapitalGains = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');
  const showLifeTimeCapitalGains = get(company, 'features', []).includes('cashflow_lifetime_capital_gains_exemption');

  if(!showLifeTimeCapitalGains) return null;

  return (
    <React.Fragment>
      <div>
        <>
          &nbsp;
          <b className={classes.sub_heading}>Seller - Long Term Capital Gains</b>
            <FBPercentInput
              className={classes.percentage}
              value={query.cashflowSellerLifetimeCapitalGainsTax.toFixed(2)}
              onChange={val => {
                updateCfaQuery({ ...query, cashflowSellerLifetimeCapitalGainsTax: parseFloat(val), })
              }}
              lower={-100}
            />
        </>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(LifetimeCapitalGains));