import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ActivityTypes from '../components/ActivityTypes/ActivityTypes';

const ActivityTypeQuery = gql`
  query getActivityTypes {
    getActivityTypes {
      id
      name
      iconUrl
      relationCount
      interaction
    }
  }
`;

const ActivityTypeContainer = () => (
  <Query query={ActivityTypeQuery}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <CircularProgress />;
      if (error) return <div>{error.message}</div>;
      if (data.getActivityTypes) {
        return (
          <Grid>
            <Paper>
              <ActivityTypes refetch={refetch} activityTypes={data.getActivityTypes} />
            </Paper>
          </Grid>
        );
      }
      return <div />;
    }}
  </Query>
);

export default ActivityTypeContainer;
