import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const ApproveUserDialog = ({ classes, open, handleClose, approveUser, closeApproveCard }) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <div className={classes.card}>
      <DialogTitle className={classes.nameSpace}>
        <div style={{ textAlign: 'center' }}>
          <div style={{ display: 'inline-block', fontSize: '22px' }}>
            <b>Approve User?</b>
          </div>
          <br />
        </div>
      </DialogTitle>
      <DialogContent>
        <b>You are sure you want to approve this user?</b>
      </DialogContent>
      <Button
        onClick={() => closeApproveCard()}
        color="primary"
        style={{ float: 'left', marginLeft: 15, marginTop: 25 }}>
        Cancel
      </Button>
      <Button
        onClick={() => {
          approveUser();
        }}
        variant="contained"
        color="primary"
        style={{ float: 'right', marginRight: 15, marginTop: 25 }}>
        Yes, APPROVE this user
      </Button>
    </div>
  </Dialog>
);

ApproveUserDialog.propTypes = {
  handleClose      : PropTypes.func.isRequired,
  approveUser      : PropTypes.func.isRequired,
  closeApproveCard : PropTypes.func.isRequired,
  classes          : PropTypes.object.isRequired,
  open             : PropTypes.bool.isRequired,
};

const styles = {
  card : {
    width  : 520,
    height : 200,
    margin : 20,
  },
};

export default withStyles(styles)(ApproveUserDialog);
