import React, { useState } from 'react';
import { IntlProvider, createIntl, createIntlCache, injectIntl, FormattedMessage } from 'react-intl';
import { Provider } from 'react-intl-redux';
import { updateIntl } from 'react-intl-redux';
import { findIndex, get } from 'lodash';
import translations from '../lib/i18n/translations.json';
import defaultLocale from '../lib/i18n/default.json';
import configureStore from '../store/findBobPublicStore';
import TransitionGoalSelector from '../components/SignUpWizard/PracticeInformation/TransitionGoalSelector';
import CatastrophicPlanningPreference from '../components/SignUpWizard/PracticeInformation/CatastrophicPlanningPreference';
import { getMotivation } from '../lib/findBobUtils';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

const cache = createIntlCache();

const setIntl = () => {
  updateIntl(
  {
    locale   : defaultLocale,
    messages : translations[window.locale || 'en'],
  },
  cache
);}

class TransitionGoalSelectorContainer extends React.Component {
  constructor (props) {
    super();
    if (document.store) {
      this.store = document.store;
    } else {
      this.store = configureStore(props);
      document.store = this.store;
    }
    this.state = {
      goals : props.user.transition_goals,
    };
  }

  render () {
    const props = this.props;
    const { user, company, disableSelection, theme_name, intl, hidePlanningPreference } = props;
    
    const theme = themes[theme_name];

    let t_goals = get(this.state, 'goals', []);

    setIntl();

    return (
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <div> 
            <Provider store={this.store} props={{ user: { ...user, transition_goals: t_goals } }}>
              <TransitionGoalSelector
                disableSelection={disableSelection}
                stylesheetName={get(user, 'company.stylesheet_name')}
                intl={intl}
                toggleGoal={e => {
                  const userMotivation = getMotivation(t_goals);
                  const goal = e.target.value;
                  if (userMotivation === getMotivation([ goal ])) {
                    const index = findIndex(t_goals, o => o === e.target.value);
                    t_goals = [ ...t_goals ];
                    if (index > -1) {
                      t_goals.splice(index, 1);
                    } else {
                      t_goals.push(e.target.value);
                    }
                    this.store.dispatch({ type: 'USER_UPDATE', user: { transition_goals: t_goals } });
                    this.setState({ goals: t_goals });
                  } else {
                    this.store.dispatch({ type: 'USER_UPDATE', user: { transition_goals: [ e.target.value ] } });
                    this.setState({ goals: [ e.target.value ] });
                  }
                }
              }
            />
            <br />
            {!hidePlanningPreference && <div>
              <h2>{intl.locale == 'en' ? get(user, 'company.catastrophic_planning_word') : 'Continuité' }</h2>
              <p>
                <FormattedMessage id="sign_up_pages.planning_question" values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word'), branded_name: get(company, 'branded_name', 'the system'), platform_name:  get(company, 'branded_name')}} />
              </p>
              <CatastrophicPlanningPreference /> 
            </div> }
          </Provider>
      </div>
    </MuiThemeProvider>
    );
  }
}

const TransitionGoalSelectorContainerWithIntl = injectIntl(TransitionGoalSelectorContainer);

const TransitionGoalSelectorContainerWithContext = (props, railsContext) => (
  <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale || 'en'}>
    <TransitionGoalSelectorContainerWithIntl {...props} theme_name={railsContext.theme_name} />
  </IntlProvider>);


export default TransitionGoalSelectorContainerWithContext;
