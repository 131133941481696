import { connect } from 'react-redux';
import Insurer from '../components/LinesOfBusiness/LinesOfBusiness';
import NewFormDialog from '../components/LinesOfBusiness/LinesOfBusinessNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/linesOfBusinessActionCreators';

const mapStateToProps = state => ({
  ...state,
  listData: state.lines_of_businesses,
  title: state.intl.messages['admin_lines_of_businesses.lines_of_business'],
  NewFormDialog,
  CrudRow: Insurer,
});

export default connect(mapStateToProps, actions)(CrudTable);
