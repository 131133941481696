import React from 'react';
import { debounce } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {injectIntl} from 'react-intl'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/SendRounded';

class MessageForm extends React.Component {
  constructor (props) {
    super(props);
    this.state = { body: '' };
    this.handleSubmit = debounce(this.handleSubmit.bind(this), 500);
  }

  update (field) {
    return e => this.setState({ [field]: e.currentTarget.value });
  }

  handleSubmit (e) {
    const { cable, room_id } = this.props;
    e.preventDefault();
    cable.subscriptions.subscriptions[0].speak({
      message : this.state.body,
      room_id : room_id,
    });
    this.setState({ body: '' });
  }

  render () {
    const { classes, disableChat, intl } = this.props;
    return (
      <div className={classes.formContainer}>
        <form onSubmit={e => { e.preventDefault(); this.handleSubmit(e);}}>
          <TextField
            disabled={disableChat}
            variant="outlined"
            type="text"
            value={this.state.body}
            onChange={this.update('body')}
            multiline
            placeholder={disableChat ? intl.formatMessage({ id: 'messages_section.chat_has_been_disabled' }) : intl.formatMessage({ id: 'messages_section.type_message_here_placeholder' })}
            fullWidth
            inputProps={{
              style : {
                height   : 100,
                overflow : 'auto',
              },
            }}
            InputProps={{
              endAdornment : (
                <InputAdornment position="end">
                  {!disableChat && (
                    <IconButton type="submit">
                      <SendIcon color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
    );
  }
}

const styles = {
  formContainer : {
    marginTop  : 8,
    background : 'white',
  },
};

export default injectIntl(withStyles(styles)(MessageForm));
