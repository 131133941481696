import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AgentsIcon from '@material-ui/icons/TableChart';
import PersonIcon from '@material-ui/icons/Person';
import PendingIcon from '@material-ui/icons/HourglassEmpty';
import OpportunityIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import ReportsIcon from '@material-ui/icons/InsertChartOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import CompaniesIcon from '@material-ui/icons/Domain';
import PlansIcon from '@material-ui/icons/LocalAtm';
import TodoListIcon from '@material-ui/icons/DoneAllOutlined';
import QuizIcon from '@material-ui/icons/AssignmentIndOutlined';
import GradeCEIcon from '@material-ui/icons/School';
import DesignationsIcon from '@material-ui/icons/DonutSmall';
import InsurersIcon from '@material-ui/icons/Gavel';
import LOBIcon from '@material-ui/icons/LibraryBooks';
import ActivityTypesIcon from '@material-ui/icons/Adjust';
import MatchIcon from '@material-ui/icons/MmsSharp';
import PipelinesIcon from '@material-ui/icons/TableChartOutlined';
import TasksIcon from '@material-ui/icons/CheckBox';
import CalibrationsIcon from '@material-ui/icons/CenterFocusStrong';
import PayoutGridIcon from '@material-ui/icons/MenuOpen';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { ChatBubble } from '@material-ui/icons';


const Menu = agent_word => [
  { 
    label            : 'Dashboard', 
    icon             : <DashboardIcon />, 
    url              : '/admin/dashboard', 
    controllerAction : 'dashboard/index' 
  },
  {
    label            : `${agent_word} Inventory`,
    icon             : <AgentsIcon />,
    url              : '/admin/inventory_users',
    controllerAction : 'inventory_users/index',
  },
  { 
    label            : 'Users', 
    icon             : <PersonIcon />, 
    url              : '/admin/user_admin', 
    controllerAction : 'user_admin/index' 
  },
  {
    label            : 'Calibration',
    icon             : <CalibrationsIcon />,
    url              : '/admin/calibration_sessions',
    controllerAction : 'calibration_sessions/index',
    feature          : 'calibrations',
  },
  {
    label            : 'Pipelines',
    icon             : <PipelinesIcon />,
    url              : '/admin/pipelines',
    controllerAction : 'pipelines/index',
    feature          : 'pipelines',
  },
  {
    label            : 'Deals',
    icon             : <i className="material-icons fas fa-briefcase" />,
    url              : '/admin/deals',
    controllerAction : 'deals/index',
    feature          : 'deal_tracking',
  },
  {
    label            : 'Recruitment',
    icon             : <OpportunityIcon />,
    url              : '/admin/opportunities',
    controllerAction : 'opportunities/index',
    feature          : 'opportunities',
  },
  { 
    label            : 'Tasks', 
    icon             : <TasksIcon />, 
    url              : '/admin/tasks', 
    controllerAction : 'tasks/index', 
    feature          : 'pipelines' 
  },
  { 
    label            : 'Reports', 
    icon             : <ReportsIcon />, 
    url              : '/admin/reports', 
    controllerAction : 'reports/index' 
  },
  { 
    label            : 'Support Requests', 
    icon             : <ChatBubble />, 
    url              : '/admin/support_requests', 
    controllerAction : 'support_requests/index',
    feature          : 'support_requests'
  },
  {
    label            : 'Settings',
    icon             : <SettingsIcon />,
    url              : '',
    controllerAction : '',
    menu             : [
      {
        label            : 'Pending Authorizations',
        icon             : <PendingIcon />,
        url              : '/admin/pending_authorized_users',
        controllerAction : 'pending_authorized_users/index',
        feature          : 'pending_authorized_users',
      },
      {
        label            : 'Companies',
        icon             : <CompaniesIcon />,
        url              : '/admin/companies',
        controllerAction : 'companies/index',
        admin            : true,
      },
      {
        label            : 'Conversations',
        icon             : <QuestionAnswerIcon />,
        url              : '/admin/conversations',
        controllerAction : 'conversations/index',
        feature          : 'view_conversations',
      },
      {
        label            : 'Affiliations',
        icon             : <i style={{ marginLeft: 3 }} className="fas fa-link" />,
        url              : '/admin/affiliations',
        controllerAction : 'affiliations/index',
        feature          : 'allow_user_affiliations',
      },
      {
        label            : 'Getting Started Widgets',
        icon             : <PlayCircleFilledWhiteIcon />,
        url              : '/admin/onboarding_banner_widgets',
        controllerAction : 'onboarding_banner_widgets/index',
        feature          : 'enable_motivation_onboarding_banner',
      },
      {
        label            : 'Valuation Menu',
        icon             : <i style={{ marginLeft: 3 }} className="fas fa-balance-scale" />,
        url              : '/admin/companies/valuation',
        controllerAction : 'companies/valuation',
        manager_can_view : true,
      },
      {
        label            : 'RC Planning Calculator',
        icon             : <i style={{ marginLeft: 3 }} className="fas fa-calculator" />,
        url              : '/admin/companies/loan_calculator',
        controllerAction : 'companies/loan_calculator',
        feature          : 'custom_loan_calculator',
      },
      {
        label            : 'Home Office CTA',
        icon             : <i style={{ marginLeft: 3 }} className="fas fa-home" />,
        url              : '/admin/companies/home_office_cta',
        controllerAction : 'companies/home_office_cta',
        feature          : 'home_office_cta',
        admin            : true,
      },
      {
        label            : 'E-Signature Config',
        icon             : <i style={{ marginLeft: 5 }} className="fas fa-file-signature" />,
        url              : '/admin/companies/e_sig_config',
        controllerAction : 'companies/e_sig_config',
        admin            : true,
      },
      { 
        label            : 'Plans', 
        icon             : <PlansIcon />, 
        url              : '/admin/plans', 
        controllerAction : 'plans/index', 
        admin            : true },
      {
        label            : 'Categorized Tasks',
        icon             : <TodoListIcon />,
        url              : '/admin/category_for_tasks',
        controllerAction : 'category_for_tasks/index',
        admin            : true,
      },
      {
        label            : 'Todo Lists',
        icon             : <TodoListIcon />,
        url              : '/admin/todo_lists',
        controllerAction : 'todo_lists/index',
        admin            : true,
      },
      { 
        label            : 'Quizzes', 
        icon             : <QuizIcon />, 
        url              : '/surveys', 
        controllerAction : 'surveys/index' 
      },
      { 
        label            : 'Grade CE', 
        icon             : <GradeCEIcon />, 
        url              : '/staff/grade_ce', 
        controllerAction : 'staff/grade_ce' 
      },
      {
        label            : 'Designations',
        icon             : <DesignationsIcon />,
        url              : '/admin/designations',
        controllerAction : 'designations/index',
      },
      { 
        label            : 'Insurers', 
        icon             : <InsurersIcon />,
        url              : '/admin/insurers', 
        controllerAction : 'insurers/index' 
      },
      {
        label            : 'Lines of Business',
        icon             : <LOBIcon />,
        url              : '/admin/lines_of_businesses',
        controllerAction : 'lines_of_businesses/index',
      },
      {
        label            : 'Activity Types',
        icon             : <ActivityTypesIcon />,
        url              : '/admin/activity_types',
        controllerAction : 'activity_types/index',
        feature          : 'pipelines',
      },
      {
        label            : 'Educational Content',
        icon             : <i style={{ marginLeft: 5 }} className="fas fa-graduation-cap" />,
        url              : '/admin/educational_content',
        controllerAction : 'educational_content/index',
      },
      {
        label            : 'Match Preferences',
        icon             : <MatchIcon />,
        url              : '/admin/match_preferences',
        controllerAction : 'match_preferences/index',
      },
      {
        label            : 'Advisor Licenses',
        icon             : <i className="material-icons">class</i>,
        url              : '/admin/advisor_licenses',
        controllerAction : 'advisor_licenses/index',
      },
      {
        label            : 'Payout Grid',
        icon             : <PayoutGridIcon />,
        url              : '/admin/payout_grids',
        controllerAction : 'payout_grids/index',
        feature          : 'cfa_calculator',
      },
      {
        label            : 'Dashboard Ticker',
        icon             : <i style={{ marginLeft: 3 }} className="fas fa-calculator" />,
        url              : '/admin/tickers',
        controllerAction : 'companies/tickers',
        admin            : true,
        feature          : 'dashboard_marketplace_ticker',
      },
    ],
  },
];

export default Menu;
