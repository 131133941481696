import React from 'react';
import { LoremIpsum } from 'lorem-ipsum';
import { random, sortBy, snakeCase, lowerCase } from 'lodash';
import { FormattedMessage } from 'react-intl';
import numeral from 'numeral';
import { get } from 'lodash';
import { empty } from 'apollo-link';

export const nameForUser = user => user.nick_name || user.user_name;

export const bioForUser = (user, authorized) => {
  const lorem = new LoremIpsum({
    sentencesPerParagraph : {
      max : 8,
      min : 4,
    },
    wordsPerSentence      : {
      max : 16,
      min : 4,
    },
  });
  if (authorized) {
    if (user.is_student) {
      return <FormattedMessage id="sign_up_pages.default_student_bio" />;
    } else {
      return user.bio || <FormattedMessage id="sign_up_pages.default_bio" />;
    }
  }
  return lorem.generateSentences(2);
};

export const locationForUser = user => {
  if (!user.city) return <span />;
  return (
    <span>
      <span className="fa fa-map-marker " />
      <strong>
        <small>
          {user.city}, {user.province}
        </small>
      </strong>
    </span>
  );
};

const majorityProductLine = user => {
  const lines = [
    { name: 'Insurance', val: user.mix_insurance },
    { name: 'Segmented Funds', val: user.mix_annuities },
    { name: 'Mutual Funds', val: user.mix_mutual_funds },
  ];
  return sortBy(lines, 'val');
};

export const generatedHeadlineForUser = (user, intl, companyHasCounty, countryForCompany, agentWord) => {
  const headlines = [];
  if (countryForCompany === 'us' && companyHasCounty && user.county) {
    if (user.years_practicing > 1) {
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_one' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.county + ' County',
            product_name     : get(majorityProductLine(user), '[0].name', ''),
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_two' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.county + ' County',
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
    }
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_three' },
        {
          product_name     : get(majorityProductLine(user), '[0].name', ''),
          user_city        : user.county + ' County',
          transition_goals : user.transition_goals
            .map(trans_goal =>
              intl.formatMessage({
                id : `sign_up_pages.${snakeCase(trans_goal)}`,
              })
            )
            .join(', '),
          agent_word       : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_four' },
        {
          product_name    : get(majorityProductLine(user), '[0].name', ''),
          user_city       : user.county + ' County',
          transition_goal : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word      : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_five' },
        {
          product_name      : get(majorityProductLine(user), '[0].name', ''),
          product_line_user : majorityProductLine(user)[1].name,
          user_city         : user.county + ' County',
          transition_goal   : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word        : agentWord,
        }
      )
    );
  } else if (countryForCompany === 'us' && companyHasCounty && !user.county) {
    if (user.years_practicing > 1) {
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_one' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.province,
            product_name     : get(majorityProductLine(user), '[0].name', ''),
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_two' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.province,
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
    }
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_three' },
        {
          product_name     : get(majorityProductLine(user), '[0].name', ''),
          user_city        : user.province,
          transition_goals : user.transition_goals
            .map(trans_goal =>
              intl.formatMessage({
                id : `sign_up_pages.${snakeCase(trans_goal)}`,
              })
            )
            .join(', '),
          agent_word       : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_four' },
        {
          product_name    : get(majorityProductLine(user), '[0].name', ''),
          user_city       : user.province,
          transition_goal : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word      : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_five' },
        {
          product_name      : get(majorityProductLine(user), '[0].name', ''),
          product_line_user : majorityProductLine(user)[1].name,
          user_city         : user.province,
          transition_goal   : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word        : agentWord,
        }
      )
    );
  } else if (user.city) {
    if (user.years_practicing > 1) {
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_one' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.city,
            product_name     : get(majorityProductLine(user), '[0].name', ''),
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
      headlines.push(user =>
        intl.formatMessage(
          { id: 'sign_up_pages.headline_two' },
          {
            years_practicing : user.years_practicing,
            user_city        : user.city,
            transition_goal  : intl.formatMessage({
              id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
            }),
            agent_word       : agentWord,
          }
        )
      );
    }
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_three' },
        {
          product_name     : get(majorityProductLine(user), '[0].name', ''),
          user_city        : user.city,
          transition_goals : user.transition_goals
            .map(trans_goal =>
              intl.formatMessage({
                id : `sign_up_pages.${snakeCase(trans_goal)}`,
              })
            )
            .join(', '),
          agent_word       : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_four' },
        {
          product_name    : get(majorityProductLine(user), '[0].name', ''),
          user_city       : user.city,
          transition_goal : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word      : agentWord,
        }
      )
    );
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_five' },
        {
          product_name      : get(majorityProductLine(user), '[0].name', ''),
          product_line_user : get(majorityProductLine(user)[1], 'name', ''),
          user_city         : user.city,
          transition_goal   : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
          agent_word        : agentWord,
        }
      )
    );
  } else {
    headlines.push(user =>
      intl.formatMessage(
        { id: 'sign_up_pages.headline_six' },
        {
          transition_goal : intl.formatMessage({
            id : `sign_up_pages.${snakeCase(user.transition_goals[0])}`,
          }),
        }
      )
    );
  }

  const randomIndex = random(headlines.length - 1);
  return headlines[randomIndex](user);
};

export const generatedRjHeadlineForUser = (user, intl, companyHasCounty, countryForCompany, agentWord) => {
  const headlines = [];
  if (get(user, 'motivation', '') === 'growth') {
    if (user.years_practicing > 1) {
      headlines.push(
        `Successful firm with ${get(user, 'years_practicing')} years experience, seeking opportunities for growth with likeminded advisors`
      );
      headlines.push(`Exceptional opportunity to collaborate with an up and coming producer in ${get(user, 'city')}`);
      headlines.push(`Let’s connect! Looking forward to connecting with succession minded advisors.`);
      headlines.push(
        `Experienced advisor with ${get(user, 'years_practicing')} years experience looking forward to growth in the ${get(user, 'city')} area.`
      );
    }
    else if (get(user, 'is_student') && get(user, 'company.enabled_student_profiles')) {
      headlines.push(
        `A student advisor looking to connect with experienced advisors.`
      );
    }
  } else if (get(user, 'motivation', '') === 'succession') {
    if (user.years_practicing >= 1) {
      headlines.push(
        `Established advisor ${user.years_practicing} year practice in ${get(
          user,
          'city'
        )} with successful book looking to connect with successor candidates.`
      );
      headlines.push(`Seasoned advisor with ${user.years_practicing} years of industry experience, seeking exit strategy.`);
      headlines.push(`Phenomenal growth opportunity for the right candidate in the ${get(user, 'city')} area.`);
      headlines.push(
        `A unique practice in ${get(user, 'city')} serving ${get(
          user,
          'number_of_clients'
        )} clients and approximately ${get(user, 'assets_under_management') < 1000000
          ? numeral(get(user, 'assets_under_management', 0)).format('$0a')
          : numeral(get(user, 'assets_under_management', 0)).format('$0.00a')} in AUM.`
      );
      headlines.push(
        `Fantastic opportunity to partner with a ${get(user, 'assets_under_management') < 1000000
          ? numeral(get(user, 'assets_under_management', 0)).format('$0a')
          : numeral(get(user, 'assets_under_management', 0)).format(
              '$0.00a'
            )} AUM practice with over ${user.years_practicing} years in the business.`
      );
    }
  } 
  else {
    headlines.push(`Successful firm in ${get(user, 'city')} seeking opportunities for growth with likeminded advisors`);
  }

  const randomIndex = random(headlines.length - 1);
  return headlines[randomIndex];
};

export const generatedBioForUser = user => '';

export const areUsersTheSame = (user1, user2) => user1.user_name === user2.user_name;

export const interest_targets = user => user.transition_goals.map(t => snakeCase(lowerCase(t)));

//inputs: currentUser, and user 
//outputs: true if currentUser is allowed to view user's profile

export function canViewProfile(currentUser, user, company) {
  const viewingProfilesMotivation = get(currentUser, 'viewing_profiles_motivation');
  const companyBusinessType = get(company, 'business_type');
  const hasCommonDealPastOutForSignature = get(currentUser, 'is_in_deal_past_out_for_receiving_signature_state', false);
  const partialSaleVisibilityOptIn = get(currentUser, 'partial_sale_visibility_opt_in', false);
  if (
    companyBusinessType === 'wealth_rj' &&
    viewingProfilesMotivation === 'succession' &&
    (!hasCommonDealPastOutForSignature || !partialSaleVisibilityOptIn)
  ) {
    return false;
  }
  return true;
}

//inputs: currentUser, and user 
//outputs: true if currentUser can message user
export const canMessageUser = (currentUser, user, company) => {
  const companyBusinessType = get(company, 'business_type', '');
  const companyHasNewMessages = get(company, 'feature_types', []).includes('new_messages');
  const companyHasNetworking = get(company, 'feature_types', []).includes('networking');
  const companyAllowAllUsersToMessageEachOther = get(currentUser, 'company.feature_types', []).includes('allow_all_users_to_message_each_other');
  const currentUserIsSubscribed = get(currentUser, 'subscribed_to_marketplace', []);
  const prohibitedProfileTypesPandC = [ 'implicitly_obfuscated_user', 'explicitly_obfuscated_user', 'limited_access_seller', 'limited_access_buyer' ];
  const prohibitedProfileTypes = [ 'implicitly_obfuscated_user', 'explicitly_obfuscated_user', 'limited_access_seller', 'limited_access_buyer' ];
  const currentUserMotivation = get(currentUser, 'motivation');
  const currentUserPlanningPreference = get(currentUser, 'planning_preference');
  const userMotivation = get(user, 'motivation');
  const userPlanningPreference = get(user, 'planning_preference');
  const currentUserHasPlanningPreference = currentUserPlanningPreference === 'find_partner' || currentUserPlanningPreference === 'become_partner';
  const userHasPlanningPreference = userPlanningPreference === 'find_partner' || userPlanningPreference === 'become_partner';
  const currentUserIsSeller = currentUser.profile_type === 'limited_access_seller' || currentUser.profile_type === 'regular_buyer';
  const userIsSeller = user.profile_type === 'regular_buyer';

  if (companyBusinessType === 'p_and_c') {
    if( (companyHasNewMessages &&
      companyHasNetworking &&
      !prohibitedProfileTypesPandC.includes( currentUser.profile_type ) )) {
      return true;
    }else {
      return false;
    }
  }

  if (
       ((companyHasNewMessages &&
       companyHasNetworking &&
       !prohibitedProfileTypes.includes( currentUser.profile_type ) ||
    ((currentUserIsSeller) && userIsSeller)) &&
    ((currentUserMotivation === 'growth' && userMotivation === 'growth') ||
    (currentUserMotivation === 'growth' && userMotivation === 'no_motivation' && userHasPlanningPreference) ||
    (currentUserMotivation === 'no_motivation' && currentUserHasPlanningPreference && userMotivation === 'growth' && userHasPlanningPreference) ||
    (currentUserMotivation === 'no_motivation' && currentUserHasPlanningPreference && userMotivation === 'no_motivation' && userHasPlanningPreference))) ||
    (companyAllowAllUsersToMessageEachOther && currentUserIsSubscribed)
  ) {
    return true;
  }
  return false;
};