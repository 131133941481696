import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import OpportunityCard from '../../OpportunityCard/OpportunityCard';

const styles = {
  dialog    : {
    maxWidth : 'inherit',
  },
  container : {
    justifyContent : 'center',
    display        : 'flex',
  },
};

class PreviewCard extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  disableButton = opportunity => {
    return !(opportunity.title && opportunity.description && opportunity.expiry_date && opportunity.requirements);
  };

  createOpportunity = () => {
    const { mutate, opportunity, updateStatus, history } = this.props;
    mutate({
      variables : {
        id                  : opportunity.id,
        title               : opportunity.title,
        description         : opportunity.description,
        expiry_date         : opportunity.expiry_date,
        requirements        : opportunity.requirements,
        user_id             : opportunity.user.id,
        opportunity_type_id : opportunity.opportunity_type.id,
      },
    })
      .then(response => {
        updateStatus(`Great! You've successfully published the ${opportunity.opportunity_type.name}`);
        history.push('/admin/opportunities');
      })
      .catch(error => {
        this.setState({ error });
        // eslint-disable-next-line
        console.log(error);
        alert('there was an error sending the query', error);
      });
  };

  render () {
    const { opportunity, classes } = this.props;
    const { open } = this.state;
    return (
      <div className={classes.container}>
        <Button
          variant="contained"
          size="medium"
          color="primary"
          disabled={this.disableButton(opportunity)}
          onClick={this.handleClickOpen}>
          Preview
        </Button>
        <Button component={props => <Link to="new" {...props} />}>or cancel</Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialog }}>
          <DialogTitle id="alert-dialog-title">Preview Opportunity</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Have a look at the opportunity you’ve created below. If you’re satisfied with your edits click Publish
              Now” otherwise you can continue editing your profile.
              <br />
              <br />
            </DialogContentText>
            <OpportunityCard opportunity={opportunity} />
          </DialogContent>
          <DialogActions className={classes.container}>
            <Button variant="contained" onClick={this.createOpportunity} color="primary" autoFocus>
              Publish Now!
            </Button>
            <Button onClick={this.handleClose}>or continue editing</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

PreviewCard.propTypes = {
  opportunity  : PropTypes.object.isRequired,
  classes      : PropTypes.object.isRequired,
  status       : PropTypes.string,
  mutate       : PropTypes.func.isRequired,
  updateStatus : PropTypes.func.isRequired,
  history      : PropTypes.array.isRequired,
};

PreviewCard.defaultProps = {
  status : null,
};

export default withRouter(withStyles(styles)(PreviewCard));
