import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import { useQuery } from '@apollo/client';
import { Grid, MenuItem, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { get, debounce } from 'lodash';
import SupportRequestCards from '../components/SupportRequestAdmin/SupportRequestCards';
import CountCard from '../components/SupportRequestAdmin/CountCard';
import Pagination from '../components/SupportRequestAdmin/Pagination';
import { CheckBox } from '@material-ui/icons';
import { getCurrentUser } from './NotificationsMenuContainer';
import SupportRequestModal from '../components/SupportRequestAdmin/SupportRequestModal';

export const GET_LATEST_SUPPORT_REQUESTS = gql`
  query LatestSupportRequests($page: Int, $perPage: Int, $orderBy: String, $order: String, $status: String, $userName: String, $getCurrentUser: Boolean) {
    supportRequests(page: $page, perPage: $perPage, orderBy: $orderBy, order: $order, status: $status, userName: $userName, getCurrentUser: $getCurrentUser) {
      id
      friendlyName
      leadName
      leadEmail
      leadMessage
      createdAt
      __typename
      user {
        id
        user_name
        name
        profile_picture
        minimum_profile_completed
        profile_percentage_complete
        messages_sent
        messages_received
        motivation
        can_match
        can_create_meeting_room
        inventory_user {
          id
          __typename
        }
        __typename
      }
      owner {
        id
        user_name
        name
        profile_picture
        __typename
      }
      status
      conversation {
        id
        conversationType
        __typename
        firstMessages(limit: 1) {
          id
          body
          createdAt
        }
        lastMessages(limit: 1) {
          id
          body
          createdAt
        }
      }
    }
    getManagers {
      id
      user_name
      name
      profile_picture
    }
    getCurrentUser {
      id
      user_name
      is_staff_or_customer
      __typename
    }
  }
`;


export const GET_SUPPORT_REQUEST_COUNTS = gql`
  query SupportRequestCounts($ownerId: ID, $getCurrentUser: Boolean) {
    supportRequestCounts(ownerId: $ownerId, getCurrentUser: $getCurrentUser)
  }
`;

const SupportRequestContainer = ({ classes }) => {
  const [latestPage, setLatestPage] = useState(0);
  const [latestFilter, setLatestFilter] = useState({ status: '', userName: '', getCurrentUser: false});
  const [currentConversation, setCurrentConversation] = useState(null);

  const { loading: loadingLatest, data: dataLatest, refetch: refetchSupportRequests } = useQuery(GET_LATEST_SUPPORT_REQUESTS, {
    client: client,
    variables: { page: latestPage, perPage: 10, orderBy: 'created_at', order: 'desc', ...latestFilter },
  });

  const { data: countData } = useQuery(GET_SUPPORT_REQUEST_COUNTS, {
    client: client,
    variables: { getCurrentUser: false },
  });

  const { data: myCountData } = useQuery(GET_SUPPORT_REQUEST_COUNTS, {
    client: client,
    variables: { getCurrentUser: true },
  });

  const handleFilterChange = (setFilter) => (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };
  
  const handleGetCurrentUserChange = (setFilter) => (e) => {
    const { name, checked } = e.target;
    setFilter((prev) => ({ ...prev, [name]: checked }));
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} md={3}>
        <CountCard title="Total Open Requests" count={countData?.supportRequestCounts[0] || 0} color="primary" classes={classes} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CountCard title="Total Requests" count={countData?.supportRequestCounts[3] || 0} color="secondary" classes={classes} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CountCard title="My Total Open Requests" count={myCountData?.supportRequestCounts[0] || 0} color="primary" classes={classes} />
      </Grid>
      <Grid item xs={12} md={3}>
        <CountCard title="My Total Requests" count={myCountData?.supportRequestCounts[3] || 0} color="secondary" classes={classes} />
      </Grid>
      <Grid item xs={12} md={12} style={{ margin: 0, padding: 0, }}>
      </Grid>

      <Grid item xs={12} md={12}>
        <SupportRequestCards
          title="Latest Support Requests"
          supportRequests={dataLatest?.supportRequests || []}
          loading={loadingLatest}
          classes={classes}
          managers={dataLatest?.getManagers || []}
          refetch={refetchSupportRequests}
          setCurrentConversation={setCurrentConversation}
        >
          <div className={classes.search}>
            <TextField
              label="Search by user's name"
              name="userName"
              value={latestFilter.userName}
              onChange={handleFilterChange(setLatestFilter)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
            &nbsp;
            &nbsp;
            <TextField
              select
              label="Status"
              name="status"
              value={latestFilter.status}
              onChange={handleFilterChange(setLatestFilter)}
              variant="outlined"
              margin="dense"
              className={classes.statusSelect}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="open">Open</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </TextField>
            &nbsp;
            &nbsp;
            <FormControlLabel
              control={<Switch checked={latestFilter.getCurrentUser} onChange={handleGetCurrentUserChange(setLatestFilter)} name="getCurrentUser" />}
              label="Assigned To Me"
            />
          </div>
        </SupportRequestCards>
        <Pagination
          page={latestPage}
          setPage={setLatestPage}
          totalPages={Math.ceil((dataLatest?.supportRequests?.length || 0) / 10)}
        />
      </Grid>
      <SupportRequestModal 
        supportRequest={currentConversation} 
        changeCurrentRequest={setCurrentConversation} 
        currentUser={dataLatest?.getCurrentUser}
        managers={dataLatest?.getManagers || []}
        refetch={refetchSupportRequests}
        />
    </Grid>
  );
};

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  search: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  statusSelect: {
    minWidth: 120,
  },
});

export default withStyles(styles)(SupportRequestContainer);