import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {FormattedMessage, injectIntl} from "react-intl";

const CfaViewExample = ({classes, cashFlows, companyName, intl}) => {
  return (
    <div className="wrapper">
      <div className="banner">
        <h2><FormattedMessage id="calculators.cfa_wizard.congratulations_below_are_your_3"/></h2>
        <center>
          <a href={`/calculators/cfa/${cashFlows[0].id}`}>
            <img src={`https://findbob-production.s3.us-west-2.amazonaws.com/images/${companyName}_cfa_lump_sum${intl.locale == 'fr' ? '_fr' : '' }.gif`} className={classes.image} />
          </a>
          <a href={`/calculators/cfa/${cashFlows[1].id}`}>
            <img src={`https://findbob-production.s3.us-west-2.amazonaws.com/images/${companyName}_cfa_fixed_earnout${intl.locale == 'fr' ? '_fr' : '' }.gif`} className={classes.image} />
          </a>
          <a href={`/calculators/cfa/${cashFlows[2].id}`}>
            <img src={`https://findbob-production.s3.us-west-2.amazonaws.com/images/${companyName}_cfa_contingent_earnout${intl.locale == 'fr' ? '_fr' : '' }.gif`} className={classes.image} />
          </a>
        </center>
      </div>
 </div>);
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
  },
  buttonB: {
    margin: '18px 8px',
    padding: '48px 12px',
    width: 180,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    backgroundColor: '#fff',
    border: 'none',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : '#1396e2',
    width: 400,
  },
  icon                   : {
    fontSize : 20,
  },
  image: {
    margin: 10,
    width: 200,
  },
};

export default withStyles(styles)(injectIntl(CfaViewExample));