import React, { Fragment, Component } from "react";
import PropTypes from 'prop-types';
import { ApolloConsumer, Mutation } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router";
import Button from "@material-ui/core/Button";
import { get, range, compact, values, capitalize } from "lodash";
import DealQuestion from "../DealWizard/DealQuestion";
import { withSnackbar } from "notistack";
import { UpdateDealMutation } from "../Deals/DealQueries";
import BackToContinuityButton from "./BackToContinuityButton";
import { FormattedMessage, injectIntl } from "react-intl";

class DealQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deal: props.deal,
      value: 0,
      publishState: props.deal_state,
      redirect: false,
      loaded: false,
      addQuestion: true,
      numberOfQuestions:
        compact(values(get(props.deal, "questions"))).length + 1,
      questions: get(props.deal, "questions"),
      fromProfile: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (this.props.deal.questions !== nextProps.deal.questions) {
      this.setState({ questions: nextProps.deal.questions });
    }
  };

  updateDeal = (mutate, togglePublished) => {
    const { enqueueSnackbar, deal, fromProfile, updateDealInRedux, intl, afterSaveRedirect } = this.props;
    const { questions } = this.state;
    let sanitizedQuestions = Object.keys(questions)
      .filter((k) => questions[k] !== "")
      .filter((k) => questions[k] !== null)
      .reduce((a, k) => ({ ...a, [k]: questions[k] }), {});
    sanitizedQuestions = this.reorderQuestions(sanitizedQuestions);
    updateDealInRedux({ questions: sanitizedQuestions });
    const variables = {
      ...deal,
      question_1: sanitizedQuestions.question_1,
      question_2: sanitizedQuestions.question_2,
      question_3: sanitizedQuestions.question_3,
      question_4: sanitizedQuestions.question_4,
      question_5: sanitizedQuestions.question_5,
    };

    mutate({
      variables,
    }).then((response) => {
      enqueueSnackbar(
        intl.formatMessage({ id: "dr_wizard.deal_successfully_saved" }),
        {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
        }
      );
      if (afterSaveRedirect) {
        this.setState({ redirect: afterSaveRedirect }, () => {
          window.location.reload();
        })
      } else {
        this.setState({ redirect: '/deal/survey' });
      }
    });
  };

  validate = () => {
    const { deal } = this.props;

    return (
      this.questionsAreFull() && compact(values(get(deal, "questions"))).length
    );
  };

  setButtonState = (addQuestion) => {
    this.setState({ addQuestion });
  };

  addNewQuestion = () => {
    const { numberOfQuestions } = this.state;
    this.setState({ numberOfQuestions: numberOfQuestions + 1 });
  };

  reorderQuestions = (questions) => {
    let num = 1;
    const newQuestions = {};
    for (const property in questions) {
      newQuestions[`question_${num}`] = `${questions[property]}`;
      num += 1;
    }
    return newQuestions;
  };

  questionsAreFull = () => {
    const { numberOfQuestions, questions } = this.state;
    for (let index = 1; index < numberOfQuestions; index++) {
      if (!get(questions, `question_${index}`, null)) {
        return false;
      }
    }
    return true;
  };

  deleteQuestion = (qIndex) => {
    const { deal } = this.props;
    const { questions, numberOfQuestions } = this.state;
    delete questions[qIndex];
    const newQuestions = this.reorderQuestions(questions);
    this.setState({
      questions: newQuestions,
      numberOfQuestions: numberOfQuestions - 1,
    });
  };

  render() {
    const {
      classes,
      updateDealInRedux,
      deal,
      stylesheetName,
      businessType,
      intl,
      companyName,
      showContinuityButtons,
    } = this.props;
    const { redirect, loaded, addQuestion, numberOfQuestions, questions } =
      this.state;
    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    if (window && !loaded) {
      this.setState({ loaded: true }, window.scrollTo(0, 0));
    }
    const questionWord = stylesheetName === "rjpe" ? "discovery" : "knockout";
    return (
      <center style={{ margin: "20px auto" }}>
        <Mutation mutation={UpdateDealMutation}>
          {(updateDealMutation) => (
            <div className={classes.root}>
              <h2 className={classes.title}>
                <FormattedMessage
                  id="dr_wizard.add_knockout_questions"
                  values={{ questionWord: capitalize(questionWord) }}
                />
              </h2>
              <header className={classes.subTitle}>
                <FormattedMessage
                  id="dr_wizard.add_up_to_five"
                  values={{ questionWord: questionWord }}
                />
                {stylesheetName === "rjpe" && (
                  <div>
                    <br />
                    <FormattedMessage
                      id="dr_wizard.want_to_lear_more"
                      values={{ questionWord: questionWord }}
                    />
                    <a
                      href="http://help-practiceexchange.rjf.com/en/articles/5152018-learn-about-discovery-questions-in-the-meeting-room"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      <FormattedMessage id="dr_wizard.article" />
                    </a>{" "}
                    <FormattedMessage id="dr_wizard.in_our_help_section" />
                  </div>
                )}
              </header>
              <br />
              {range(1, numberOfQuestions).map((i) => (
                <DealQuestion
                  updateQuestion={updateDealInRedux}
                  value={get(questions, `question_${i}`, "")}
                  questionId={`question_${i}`}
                  questions={questions}
                  stylesheetName={stylesheetName}
                  setButtonState={this.setButtonState}
                  addQuestionState={addQuestion}
                  deleteQuestion={this.deleteQuestion}
                />
              ))}
              <div className={classes.buttonBox}>
                <Button
                  color="primary"
                  variant="contained"
                  className={`${classes.button} ${stylesheetName === "rjpe"
                      ? classes[`${stylesheetName}BackgroundColor`]
                      : ""
                    }`}
                  disabled={numberOfQuestions >= 6 || !this.questionsAreFull()}
                  style={{ minWidth: 250 }}
                  onClick={() => this.addNewQuestion()}
                >
                  <FormattedMessage id="dr_wizard.add_a_question" />
                </Button>
              </div>
              <br />
              <div className={classes.buttonBox}>
                {showContinuityButtons && (
                  <center style={{ margin: "0" }}>
                    <BackToContinuityButton
                      deal={deal}
                      stylesheetName={stylesheetName}
                    />
                    <Button
                      variant="contained"
                      className={`${classes.button} ${stylesheetName
                          ? classes[`${stylesheetName}BackgroundColorAlt`]
                          : ""
                        }`}
                      onClick={() => {
                        this.setState({ redirect: "/deal/wizard" });
                      }}
                    >
                      <FormattedMessage id="dr_wizard.back" />
                    </Button>
                  </center>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  className={`${classes.button} ${stylesheetName === "rjpe"
                      ? classes[`${stylesheetName}BackgroundColor`]
                      : ""
                    }`}
                  disabled={!this.validate()}
                  onClick={() => {
                    this.updateDeal(updateDealMutation);
                  }}
                >
                  <FormattedMessage id={`dr_wizard.${showContinuityButtons ? 'next' : 'save'}`} />
                </Button>
              </div>
            </div>
          )}
        </Mutation>
      </center>
    );
  }
}

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 12px",
    margin: "auto",
  },
  button: {
    margin: "18px 8px",
    padding: 8,
    width: 100,
  },
  title: {
    textAlign: "left",
  },
  subTitle: {
    textAlign: "left",
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 18,
  },
  rjpeBackgroundColor: {
    backgroundColor: "#002949",
    "&:hover": {
      background: "#424949",
    },
  },
  rjpeBackgroundColorAlt: {
    backgroundColor: "#5B5E5A",
    "&:hover": {
      background: "#424949",
    },
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
  },
});

DealQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
  deal: PropTypes.object.isRequired,
  updateDealInRedux: PropTypes.func.isRequired,
  stylesheetName: PropTypes.string.isRequired,
  businessType: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  companyName: PropTypes.string.isRequired,
  showContinuityButtons: PropTypes.bool,
  afterSaveRedirect: PropTypes.string,
};

DealQuestions.defaultProps = {
  showContinuityButtons: true,
  afterSaveRedirect: null,
};


export default injectIntl(withStyles(styles)(withSnackbar(DealQuestions)));
