/* eslint-disable import/prefer-default-export */

import { DEAL_LIST_UPDATE, DEAL_UPDATE, FILTER_UPDATE, UPDATE_STATUS } from '../constants/dealConstants';

export const updateDeals = deals => ({
  type  : DEAL_LIST_UPDATE,
  deals,
});

export const updateDeal = deal => ({
  type : DEAL_UPDATE,
  deal,
});

export const updateFilter = filter => ({
  type   : FILTER_UPDATE,
  filter,
});

export const updateUser = user => ({
  type : DEAL_INVENTORY_USER_UPDATE,
  user,
});

export const updateStatus = status => ({
  type   : UPDATE_STATUS,
  status,
});
