import React from 'react';
import { Query, ApolloProvider } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import Revenue from '../components/Revenue/Revenue';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      marketing_expenses_percentage
      assistant_compensation_percentage
      management_cost_percentage
    }
  }
`;

const RevenueContainer = (props, railsContext) => {
  const { classes } = props;
  let theme = themes[railsContext.theme_name];
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <Query query={getCurrentUser}>
          {({ loading, data }) => {
            if (loading) return <CircularProgress />;
            if (data.getCurrentUser) {
              return <Revenue currentUser={data.getCurrentUser} classes={classes} />;
            }
          }}
        </Query>
      </MuiThemeProvider>
    </ApolloProvider>
  );
};

export default RevenueContainer;
