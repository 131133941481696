import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, capitalize, reject, lowerCase, uniqBy } from 'lodash';
import { Input } from '@material-ui/core';
import CheckButton from '../../CheckButton/CheckButton';

const Designations = ({ availableDesignations, locale, designations, stylesheetName, toggleDesignations, updateAction } ) => {

    const i18ndesignations = [];
    if (locale === 'fr') {
      availableDesignations.map(d => i18ndesignations.push({ value: d.name, displayName: d.fr_name }));
    } else {
      availableDesignations.map(d => i18ndesignations.push({ value: d.name, displayName: d.name }));
    }
    const availableValues = availableDesignations.map(d => d.value);
    let otherDesignations = reject(designations, d => (findIndex(availableValues, d.value) >= 0))
    otherDesignations = otherDesignations.map(o => ({value: o, displayName: o})); 

    console.log(i18ndesignations)

    return <React.Fragment>
      <Input
        fullWidth
        id="designation_input"
        onKeyDown={ e => {
          if(e.keyCode == 13 || e.keyCode == 32) {
            // updateAction({designations: [...designations, e.target.value]})

            toggleDesignations(e.target.value)
            e.target.value = '';
          }
        }}
      />
      {uniqBy(i18ndesignations.concat(otherDesignations), 'value')
          .sort(
            (a, b) =>
              capitalize(a.displayName) > capitalize(b.displayName)
                ? 1
                : capitalize(b.displayName) > capitalize(a.displayName) ? -1 : 0
          )
          .map(designation => (
            <CheckButton
              stylesheetName={stylesheetName}
              label={designation.displayName}
              checked={findIndex(designations, o => o === designation.value) > -1}
              value={designation.value}
              onClick={e => toggleDesignations(e.target.value)}
            />
          ))}
      </React.Fragment>
    };


Designations.propTypes = {
  availableDesignations: PropTypes.array,
  locale: PropTypes.string,
  designations: PropTypes.array,
  stylesheetName: PropTypes.string,
  toggleDesignations: PropTypes.func,  
  updateAction: PropTypes.func,  
};

Designations.defaultProps = {
  availableDesignations: [{}],
  locale: "",
  designations: [{}],
  stylesheetName: "",
  toggleDesignations: () => {},
  updateAction: () => {},
}

export default Designations;
