import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotebookEditor from '../NotebookEditor/NotebookEditor';
import { trackAnalyticsEvent } from '../../lib/analytics';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const AddNote = gql`
  mutation addNote($userName: String!, $text: String!) {
    addNote(userName: $userName, text: $text) {
      id
      comment
      created_at
    }
  }
`;

class NotebookDrawerContainer extends Component {
  render = () => {
    const { userName, nickName, onSave, mutate } = this.props;
    return <NotebookEditor userName={userName} nickName={nickName} onSave={onSave} mutate={mutate} />;
  };
}

NotebookDrawerContainer.propTypes = {
  user        : PropTypes.object.isRequired,
  currentUser : PropTypes.object,
  opened      : PropTypes.bool,
};

export default graphql(AddNote)(NotebookDrawerContainer);
