import React, { Component, useState } from "react";
import {isEmpty, find, get} from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';

const BootstrapInput = withStyles(theme => ({
    root: {
      'label + &': {
        marginTop: theme.spacing.unit * 3,
      },
    },
    input: {
      borderRadius: 2,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 14,
      width: '100%',
      padding: '10px 26px 10px 12px',
      color: '#5a5a5a',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: "Helvetica Neue,Helvetica,Arial,sans-serif",
      '&:focus': {
        borderRadius: 2,
        borderColor: '#80bdff',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);

class FBSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {value: props.value};
    
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (event) => {
        this.setState({value: event.target.value});
    };

    render = () => {
        const { label, name, options, multiple, classes } = this.props;
        const { value } = this.state;

        return <Select
                fullWidth
                select
                multiple={multiple}
                label={label}
                value={value}
                name={name}
                onChange={this.handleChange}
                variant="standard"
                displayEmpty
                renderValue={selected => {
                    if(Array.isArray(selected)) {
                        if (selected.length === 0) {
                            return <em>{label}</em>;
                        }
            
                        return selected.map(s => find(options, ['value', s]).label).join(', ');
                    }
                    return isEmpty(selected) ? label : get(find(options, ['value', selected]), 'label');
                  }}
                input={<BootstrapInput />}
                >
                <MenuItem value="" disabled>
                {label}
                </MenuItem>
                {(options || []).map((option) => (
                <MenuItem key={option.value} value={`${option.value}`}>
                    {option.label}
                </MenuItem>
                ))}
            </Select>

    }
};

export default FBSelect;