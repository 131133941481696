import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
  root        : {
    display : 'flex',
  },
  formControl : {
    margin : theme.spacing.unit * 3,
  },
  group       : {
    margin        : `${theme.spacing.unit}px 0`,
    flexDirection : 'row',
  },
});

const MatchSurvey = ({ selection, handleChange, options, question, classes }) => (
  <div className={classes.root}>
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{question}</FormLabel>
      <RadioGroup
        aria-label="Gender"
        name="gender1"
        className={classes.group}
        value={selection}
        onChange={handleChange}>
        {(options || [])
          .map(({ value, label }) => <FormControlLabel value={value} control={<Radio />} label={label} />)}
      </RadioGroup>
    </FormControl>
  </div>
);

MatchSurvey.propTypes = {
  classes : PropTypes.object.isRequired,
};

export default withStyles(styles)(MatchSurvey);
