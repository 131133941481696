import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { DragSource, DropTarget } from 'react-dnd';
import Card from '@material-ui/core/Card';
import { flowRight } from 'lodash';

const boxSource = {
  beginDrag (props) {
    props.changePipelineState({ dragging: true });
    return {
      name  : props.id,
      props,
    };
  },

  endDrag (props, monitor) {
    props.changePipelineState({ dragging: false });

    const dropResult = monitor.getDropResult();
    if (dropResult) {
      props.onDrop(monitor.getItem().name, dropResult.stage, dropResult.columnIndex);
    }
  },
};

class PipelineCard extends React.Component {
  render () {
    const {
      children,
      connectDragSource,
      connectDropTarget,
      classes,
      isOver,
      isDragging,
      spacer,
      disablePipelineFunctions,
    } = this.props;

    let className = classes.cardWrapper;
    if (isOver) {
      className = classes.cardWrapperTarget;
    }
    if (isDragging) {
      className = classes.cardWrapperDrag;
    }
    if (!disablePipelineFunctions) {
      return flowRight(connectDragSource, connectDropTarget)(
        <div>
          <div className={className}>{spacer ? <div>{children}</div> : <Card>{children}</Card>}</div>
        </div>
      );
    } else {
      return (
        <div>
          <div className={className}>{spacer ? <div>{children}</div> : <Card>{children}</Card>}</div>
        </div>
      );
    }
  }
}

const PipelineCardWithSource = flowRight([
  DropTarget(
    'pipelineCard',
    {
      drop (props) {
        return { ...props };
      },
    },
    (connect, monitor) => ({
      isOver            : monitor.isOver(),
      connectDropTarget : connect.dropTarget(),
    })
  ),
  DragSource('pipelineCard', boxSource, (connect, monitor) => ({
    connectDragSource : connect.dragSource(),
    isDragging        : monitor.isDragging(),
  })),
])(PipelineCard);

const styles = {
  cardWrapper       : {
    padding    : 10,
    background : 'transparent',
    height     : 'inherit',
  },
  cardWrapperDrag   : {
    padding    : 10,
    background : 'transparent',
    border     : '3px dashed rgba(0,0,0,0.5)',
    opacity    : '0.5',
  },
  cardWrapperTarget : {
    padding : '80px 10px 10px 10px',
  },
};

PipelineCard.propTypes = {
  children          : PropTypes.object.isRequired,
  connectDragSource : PropTypes.func.isRequired,
  connectDropTarget : PropTypes.func.isRequired,
  classes           : PropTypes.object.isRequired,
  isOver            : PropTypes.bool,
  isDragging        : PropTypes.bool,
  spacer            : PropTypes.bool,
};

PipelineCard.defaultProps = {
  isOver     : false,
  isDragging : false,
  spacer     : false,
};

export default withStyles(styles)(PipelineCardWithSource);
