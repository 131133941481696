import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import { FormattedMessage } from 'react-intl';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $marketing_expenses_percentage: Int
    $assistant_compensation_percentage: Int
    $management_cost_percentage: Int
  ) {
    updateCurrentUser(
      marketing_expenses_percentage: $marketing_expenses_percentage
      assistant_compensation_percentage: $assistant_compensation_percentage
      management_cost_percentage: $management_cost_percentage
    )
  }
`;

class Revenue extends React.Component {
  updateUser = debounce(() => {
    const { mutate } = this.props;
    const { marketing_expenses_percentage, assistant_compensation_percentage, management_cost_percentage } = this.state;

    mutate({
      variables : {
        marketing_expenses_percentage,
        assistant_compensation_percentage,
        management_cost_percentage,
      },
    }).catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
    });
  }, 2000);

  constructor (props) {
    super(props);
    this.state = {
      marketing_expenses_percentage     : props.currentUser.marketing_expenses_percentage || 0,
      assistant_compensation_percentage : props.currentUser.assistant_compensation_percentage || 0,
      management_cost_percentage        : props.currentUser.management_cost_percentage || 0,
      marketingMax                      : props.currentUser.marketing_expenses_percentage,
      assistantMax                      : props.currentUser.assistant_compensation_percentage,
      managementMax                     : props.currentUser.management_cost_percentage,
    };
  }

  percentValuesAreGood = () => {
    const { marketing_expenses_percentage, assistant_compensation_percentage, management_cost_percentage } = this.state;
    if (marketing_expenses_percentage + assistant_compensation_percentage + management_cost_percentage <= 100) {
      return true;
    }
  };

  calcValues = () => {
    const { marketing_expenses_percentage, assistant_compensation_percentage, management_cost_percentage } = this.state;
    if (marketing_expenses_percentage + assistant_compensation_percentage + management_cost_percentage <= 100) {
      this.setState({ marketingMax: 100 - (assistant_compensation_percentage + management_cost_percentage) });
      this.setState({ assistantMax: 100 - (marketing_expenses_percentage + management_cost_percentage) });
      this.setState({ managementMax: 100 - (marketing_expenses_percentage + assistant_compensation_percentage) });
    }
    if (this.percentValuesAreGood()) {
      this.updateUser();
    }
  };

  render () {
    const { classes } = this.props;
    const {
      marketing_expenses_percentage,
      assistant_compensation_percentage,
      management_cost_percentage,
      marketingMax,
      assistantMax,
      managementMax,
    } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <span className={classes.headline}>
            <FormattedMessage id="profile_page.revenue_info_section.expense_information" />
          </span>
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: 25 }} />
          <span>
            <FormattedMessage id="valuation_drawer.marketing_expenses" />{' '}
          </span>
          <Tooltip
            title=<FormattedMessage id="profile_page.revenue_info_section.percent_of_annual_revenue_used_for_marketing" />
          placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={marketing_expenses_percentage <= marketingMax ? marketing_expenses_percentage : marketingMax}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.setState({ marketing_expenses_percentage: v <= marketingMax ? v : marketingMax });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{marketing_expenses_percentage}%</div>
        </Grid>
        <Grid item xs={12}>
          <span>
            <FormattedMessage id="valuation_drawer.staff_assistant_expenses" />{' '}
          </span>
          <Tooltip
          title=<FormattedMessage id="profile_page.revenue_info_section.percent_of_annual_revenue_used_to_compensate_staff_and_assistants" />
        placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={
                assistant_compensation_percentage <= assistantMax ? assistant_compensation_percentage : assistantMax
              }
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.setState({
                  assistant_compensation_percentage : v <= assistantMax ? v : assistantMax,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{assistant_compensation_percentage}%</div>
        </Grid>
        <Grid item xs={12}>
          <span>
            <FormattedMessage id="valuation_drawer.management_costs" />{' '}
          </span>
          <Tooltip
            title=<FormattedMessage id="profile_page.revenue_info_section.percent_of_annual_revenue_used_to_compensate_management_for_advisor_time" />
            placement="right">
            <Button style={{ margin: -15 }}>
              <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item xs={7}>
          <RangeSliderContainer>
            <InputSlider
              maxValue={100}
              minValue={0}
              value={management_cost_percentage <= managementMax ? management_cost_percentage : managementMax}
              formatLabel={value => ``}
              step={1}
              onChange={v => {
                this.setState({
                  management_cost_percentage : v <= managementMax ? v : managementMax,
                });
                this.calcValues();
              }}
            />
          </RangeSliderContainer>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <div style={{ marginLeft: 10, marginTop: 25 }}>{management_cost_percentage}%</div>
        </Grid>
        <div style={{ marginBottom: 100 }} />
      </Grid>
    );
  }
}

Revenue.propTypes = {
  mutate      : PropTypes.func.isRequired,
  currentUser : PropTypes.object.isRequired,
};

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }
`;

export default graphql(UpdateCurrentUser)(Revenue);
