import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize } from 'lodash';

const Affiliation = ({ classes, dealInterest, agentWordForCompany }) => {
  if (get(dealInterest, 'inventory_user.affiliation')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.affiliation"/></strong>
        <p>{get(dealInterest, 'inventory_user.affiliation')}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(Affiliation);
