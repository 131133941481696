import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '../ToggleButton/ToggleButton';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import IconSelector from '../ActivityTypes/IconSelector';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const CreateActivityTypeMutation = gql`
  mutation createActivityType($name: String, $iconUrl: String) {
    createActivityType(name: $name, icon_url: $iconUrl) {
      id
    }
  }
`;

const styles = theme => ({
  addButton: {
    position: 'fixed',
    right: 30,
    bottom: 200,
  },
  text: {
    fontSize: '18px',
  },
  title: {
    fontSize: 24,
    margin: 12,
  },
});

class OnboardingBannerWidgetNewFormDialog extends React.Component {
  defaultState = {
    open: false,
    order: '',
    motivation: '',
    icon: '',
    sub_icon: '',

  };

  state = {...this.defaultState};

  handleClickOpen = () => {
    this.setState({ open: true });
  }; 

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ 
        ...this.defaultState
      });
    }
  };

  handleIconChange = (field, value) => {
    this.setState({ [field]: value });
  };

  addRow = action => {
    const { motivation, order, name, icon, title, title_fr, sub_icon, sub_title, sub_title_fr, description, description_fr, url_link } = this.state;

    action({
      motivation,
      order,
      name,
      icon,
      title,
      title_fr,
      sub_icon,
      sub_title,
      sub_title_fr,
      description,
      description_fr,
      url_link,
    });
  };

  render() {
    const { classes, error, close, intl } = this.props;
    const { mutate, refetch } = this.props;
    const { open, icon, sub_icon, order, motivation } = this.state;
    if (close) this.handleRequestClose();

    const motivationOptions = [
      { label: 'Growth', value: 'growth' },
      { label: 'Succession', value: 'succession' },
      { label: 'No Motivation', value: 'no_motivation' },
    ];

    return (
      <div>
        <Button
          variant="fab"
          color="primary"
          aria-label="add"
          className={classes.addButton}
          onClick={this.handleClickOpen}
        >
          <AddIcon />
        </Button>
        <Dialog open={open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>Add an Onboarding Banner Widget</DialogTitle>
          <DialogContent>
          <TextField
          autoFocus
          margin="dense"
          id="motivation"
          select
          label="motivation"
          className={classes.textField}
          value={motivation}
          onChange={e => {
            this.setState({ motivation: e.target.value });
          }}
          fullWidth
        >
          {motivationOptions.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          id="order"
          select
          label="order"
          className={classes.textField}
          value={order}
          onChange={e => {
            this.setState({ order: e.target.value });
          }}
          fullWidth
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
            <TextField
              margin="dense"
              required
              id="name"
              label={intl.formatMessage({ id: 'shared.name' })}
              className={classes.textField}
              onChange={e => {
                this.setState({ name: e.target.value });
              }}
              fullWidth
            />
            <div>
              Icon Type: &nbsp;
              <IconSelector
                iconName={icon}
                onIconChange={value => this.handleIconChange('icon', value)}
                iconOptions={[
                  'fa-map-marked',
                  'fa-clock',
                  'fa-check',
                  'fa-clipboard',
                  'fa-chart-bar',
                  'fa-edit',
                  'fa-comment-dots',
                  'fa-graduation-cap',
                  'fa-calculator',
                  'fa-bullseye',
                  'fa-search',
                  'fa-chart-line',
                  'fa-handshake',
                  'fa-pencil-alt',
                  'fa-sticky-note',
                  'fa-envelope',
                  'fa-user-friends',
                  'fa-user-check',
                ]} 
              />
            </div>
            <TextField
              margin="dense"
              required
              id="title"
              label="title"
              className={classes.textField}
              onChange={e => {
                this.setState({ title: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="title_fr"
              label="title_fr"
              className={classes.textField}
              onChange={e => {
                this.setState({ title_fr: e.target.value });
              }}
              fullWidth
            />
            <div>
            Sub Icon Type: &nbsp;
            <IconSelector
              iconName={sub_icon}
              onIconChange={value => this.handleIconChange('sub_icon', value)}
              iconOptions={[
                'fa-map-marked',
                'fa-clock',
                'fa-check',
                'fa-clipboard',
                'fa-chart-bar',
                'fa-edit',
                'fa-comment-dots',
                'fa-graduation-cap',
                'fa-calculator',
                'fa-bullseye',
                'fa-search',
                'fa-chart-line',
                'fa-handshake',
                'fa-pencil-alt',
                'fa-sticky-note',
                'fa-envelope',
                'fa-user-friends',
                'fa-user-check',
              ]} 
            />
          </div>
            <TextField
              margin="dense"
              required
              id="sub_title"
              label="sub title"
              className={classes.textField}
              onChange={e => {
                this.setState({ sub_title: e.target.value });
              }}
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="sub_title_fr"
              label="sub title_fr"
              className={classes.textField}
              onChange={e => {
                this.setState({ sub_title_fr: e.target.value });
              }}
              fullWidth
            />
            <TextField
              id="description"
              label={intl.formatMessage({ id: 'shared.description' })}
              onChange={e => {
                this.setState({ description: e.target.value });
              }}
              multiline
              rows="2"
              className={classes.textField}
              margin="dense"
              fullWidth
            />
            <TextField
              id="description_fr"
              label="description_fr"
              onChange={e => {
                this.setState({ description_fr: e.target.value });
              }}
              multiline
              rows="2"
              className={classes.textField}
              margin="dense"
              fullWidth
            />
            <TextField
              margin="dense"
              required
              id="url_link"
              label="url link"
              className={classes.textField}
              onChange={e => {
                this.setState({ url_link: e.target.value });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              <FormattedMessage id="shared.cancel" />
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}
              disabled={!this.state.name}>
              <FormattedMessage id="admin_plans.add" />
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

OnboardingBannerWidgetNewFormDialog.propTypes = {

};

const OnboardingBannerWidgetNewFormDialogWithStyles = withStyles(styles)(OnboardingBannerWidgetNewFormDialog);
export default injectIntl(graphql(CreateActivityTypeMutation)(OnboardingBannerWidgetNewFormDialogWithStyles));
