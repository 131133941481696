import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core';
import ActivityRow from './ActivityRow';
import AddActivityButton from './AddActivityButton';

export const ActivityTypes = ({ activityTypes, classes, refetch }) => (
  <div>
    <AddActivityButton refetch={refetch} />
    <Table className={classes.root}>
      <TableHead>
        <TableRow>
          <TableCell>Icon</TableCell>
          <TableCell>Activity Name</TableCell>
          <TableCell>Interaction</TableCell>
        </TableRow>
      </TableHead>
      {activityTypes.map(at => <ActivityRow id={`ar_${at.id}`} refetch={refetch} activityType={at} />)}
    </Table>
  </div>
);

ActivityTypes.propTypes = {
  activityTypes : PropTypes.array,
  classes       : PropTypes.object.isRequired,
  refetch       : PropTypes.func.isRequired,
};

ActivityTypes.defaultProps = {
  activityTypes : [],
};

const styles = theme => ({
  root       : {
    flexGrow : 1,
    fontSize : '18px',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing.unit * 2,
  },
  title      : {
    fontSize : 18,
    color    : '#fff',
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
  },
});

export default withStyles(styles)(ActivityTypes);
