import { LOAD_BULK_EDIT_ITEMS } from '../constants/bulkEditItemsConstants';

const bulkEditItemReducer = (state = [], action) => {
  switch (action.type) {
    case LOAD_BULK_EDIT_ITEMS:
      return { ...state, data: action.bulkEditItemsSettings };
    default:
      return state;
  }
};

export default bulkEditItemReducer;
