// Create a page that indicates that the calibration session is being created
import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: 200,
    left: 0,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles)(({ classes }) => (
  <div className={classes.root}>
    <Typography variant="h5" gutterBottom>
      Creating Calibration Session
    </Typography>
    <Typography variant="body1" gutterBottom>
      Please wait while we create your calibration session.
    </Typography>
    <Typography variant="body1" gutterBottom>
      <Link to="/admin/calibration_sessions" className={classes.link}>
        Click here to go back to the calibration session list.
      </Link>

    </Typography>
  </div>
));


