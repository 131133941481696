import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import GmvYoyPage from '../components/GmvYoyPage/GmvYoyPage'

const sacMonthlyMarketValue = gql`
  query {
	monthlyMarketValue {
	  company_id
    year
	  month
	  value
	}
  currentYearMv {
    company_id
    year
	  month
	  value
  }
}
`;

export default graphql(sacMonthlyMarketValue)(GmvYoyPage);
