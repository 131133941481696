import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import { Provider, updateIntl } from 'react-intl-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { CssBaseline, Fab, Drawer, Badge } from '@material-ui/core';
import { ApolloProvider } from 'react-apollo';
import translations from '../lib/i18n/translations.json';
import themes from '../lib/themes';
import configureStore from '../store/findBobPublicStore';
import ChatIcon from '@material-ui/icons/Chat';
import { Close } from '@material-ui/icons';
import client from '../lib/apolloClient';
import { withStyles } from '@material-ui/core/styles';
import SupportDrawer from '../components/SupportDrawer/SupportDrawer';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { get } from 'lodash';

// Get numbe of unread messages
const GET_UNREAD_MESSAGES = gql`
  query unreadMessageCount {
    unreadMessageCount
  }
`;

const cache = createIntlCache();

class SupportDrawerContainer extends Component {
  constructor(props) {
    super(props);

    let store = {};
    if (document.store) {
      store = document.store;
    } else {
      store = configureStore(props);
    }
    this.store = store;
    updateIntl({
      locale: window.locale || 'en',
      messages: translations[window.locale || 'en'],
    }, cache);
    this.state = {
      openDrawer: false,
      store: store,
    };
  }

  setOpenDrawer = (open) => {
    this.setState({ openDrawer: open });
  };

  render() {
    const theme = this.props.theme;
    const { classes } = this.props;
    const { openDrawer } = this.state;

    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <Provider store={this.state.store} props>
          <ApolloProvider client={client}>
            <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
              <Fab variant='circular' className={classes.fab} onClick={() => this.setOpenDrawer(!openDrawer)}>
                <Badge badgeContent={this.props.unreadMessages} color='secondary'>
                  { openDrawer ? <Close /> : <ChatIcon /> }
                </Badge>
              </Fab>
              { openDrawer ? <SupportDrawer {...this.props} client={client} /> : <span/>}

            </IntlProvider>
          </ApolloProvider>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

const styles = theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(6),
  },
});

const SupportDrawerWithQuery = (props) => {
  const { data, loading, error } = useQuery(GET_UNREAD_MESSAGES, {client});
 
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error! {error.message}</div>;

  return (
    <SupportDrawerContainer {...props} unreadMessages={get(data, 'unreadMessageCount')} />
  );
}


const SupportDrawerContainerWithStyles = withStyles(styles)(SupportDrawerWithQuery);

const SupportDrawerContainerWithContext = (props, railsContext) => {
  let theme = themes[railsContext.theme_name];
  window.locale = railsContext.i18nLocale || 'en';
  const { company } = railsContext;
  return (
    <SupportDrawerContainerWithStyles {...props} company={company} theme={theme} />
  );
}

export default SupportDrawerContainerWithContext;