import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import UserDetails from '../../UserDetails/UserDetails';

const renderUserInfo = (
  user,
  isUserAuthorized,
  userIsAdmin,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  marketplaceOnly,
  handleClick,
  companyBusinessType
) => (
  <div style={{ textAlign: `${marketplaceOnly ? 'right' : ''}` }}>
    <UserDetails
      user={user}
      isUserAuthorized={isUserAuthorized}
      userIsAdmin={userIsAdmin}
      currentUserHasLimitedAccess={currentUserHasLimitedAccess}
      currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
      marketplaceOnly={marketplaceOnly}
      companyBusinessType={companyBusinessType}
    />
    {isUserAuthorized && (
      <a
        onClick={() => {
          handleClick();
        }}
        className="link-arrow">
        <FormattedMessage id="shared.view_profile" />
      </a>
    )}
  </div>
);

const BobCardUserDetails = ({
  user,
  isUserAuthorized,
  userIsAdmin,
  currentUserHasLimitedAccess,
  currentUserHasSellingTransitionGoals,
  marketplaceOnly,
  handleClick,
  companyBusinessType,
}) => (
  <div>
    {renderUserInfo(
      user,
      isUserAuthorized,
      userIsAdmin,
      currentUserHasLimitedAccess,
      currentUserHasSellingTransitionGoals,
      marketplaceOnly,
      handleClick,
      companyBusinessType
    )}
  </div>
);

BobCardUserDetails.propTypes = {
  user                                 : PropTypes.object.isRequired,
  isUserAuthorized                     : PropTypes.bool.isRequired,
  userIsAdmin                          : PropTypes.bool.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  handleClick                          : PropTypes.func.isRequired,
  companyBusinessType                  : PropTypes.string.isRequired,
};

export default BobCardUserDetails;
