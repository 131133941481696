import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import * as actions from '../actions/userActionCreators';
import ProductMix from '../components/SignUpWizard/ProductMix';
import ProductMixChart from '../components/UserCharts/ProductMix';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';

const mapStateToProps = state => ({ user: state.user });
const ProductMixQueries = gql`
  query ProductMixQueries {
    availableProducts
    getCurrentUser {
      user_name
      fb_user_name
      assets_under_management
      product_mix {
        life_ifp
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        total_assets
        segregated_funds_aua
        mcpi_ifp
        private_products_ifp
        crop_hail_ifp
        crop_hail_with_wind_ifp
        farm_insurance_ifp
        price_products_ifp
        replant_supplement_ifp
        gic_fixed_income
        insurance
        equity
      }
      company {
        id
        use_canadian_retail_wealth_product_mix
        use_p_and_c_product_mix
        skip_product_mix_on_signup
      }
    }
  }
`;

class ProductMixContainer extends React.Component {
  componentWillReceiveProps = nextProps => {
    if (this.props.data.loading && !nextProps.data.loading) {
      nextProps.updateUser(nextProps.data.getCurrentUser);
    }
  };

  render = () => {
    const { user, updateProductMix, intl, updateUser } = this.props;
    const product_mix = user.product_mix || {};
    const useCanadianRetailWealth= get(user, 'company.use_canadian_retail_wealth_product_mix', false);
    const usePAndCProductMix= get(user, 'company.use_p_and_c_product_mix', false);

    let reqF = [
      'life_ifp',
      'home_ifp',
      'auto_ifp',
      'commercial_ifp',
      'group_ifp',
      'health_ifp',
      'mutual_funds_aum',
      'securities_aum',
      'annuities_aua',
      'segregated_funds_aua',
      'mcpi_ifp',
      'private_products_ifp',
      'crop_hail_ifp',
      'crop_hail_with_wind_ifp',
      'farm_insurance_ifp',
      'price_products_ifp',
      'replant_supplement_ifp',
      'gic_fixed_income',
      'equity',
    ];

    if (useCanadianRetailWealth) {
      reqF = [
        'gic_fixed_income',
        'equity',
        'mutual_funds_aum',
        'segregated_funds_aua',
        'insurance',
      ];
    }

    if (usePAndCProductMix) {
      reqF = [
        'home_ifp',
        'commercial_ifp',
        'private_products_ifp',
        'crop_hail_with_wind_ifp',
        'life_ifp',
        'auto_ifp',
        'mcpi_ifp',
        'crop_hail_ifp',
        'farm_insurance_ifp',
        'replant_supplement_ifp',
        'health_ifp',
      ];
    }
    return (
      <div className="row">
        <div className="col-md-7 col-sm-8">
          <ProductMix
            life_ifp={product_mix.life_ifp}
            home_ifp={product_mix.home_ifp}
            health_ifp={product_mix.health_ifp}
            auto_ifp={product_mix.auto_ifp}
            commercial_ifp={product_mix.commercial_ifp}
            group_ifp={product_mix.group_ifp}
            mutual_funds_aum={product_mix.mutual_funds_aum}
            securities_aum={product_mix.securities_aum}
            annuities_aua={product_mix.annuities_aua}
            segregated_funds_aua={product_mix.segregated_funds_aua}
            mcpi_ifp={product_mix.mcpi_ifp}
            private_products_ifp={product_mix.private_products_ifp}
            crop_hail_ifp={product_mix.crop_hail_ifp}
            crop_hail_with_wind_ifp={product_mix.crop_hail_with_wind_ifp}
            farm_insurance_ifp={product_mix.farm_insurance_ifp}
            price_products_ifp={product_mix.price_products_ifp}
            replant_supplement_ifp={product_mix.replant_supplement_ifp}
            gic_fixed_income={product_mix.gic_fixed_income}
            insurance={product_mix.insurance}
            equity={product_mix.equity}
            useCanadianRetailWealth={get(user, 'company.use_canadian_retail_wealth_product_mix', false)}
            usePAndCProductMix={get(user, 'company.use_p_and_c_product_mix', false)}
            disableProductMixStep={get(user, 'company.skip_product_mix_on_signup', false)}
            availableProducts={this.props.data.availableProducts}
            fullName={user.user_name}
            aua={get(user, 'assets_under_management', 0)}
            updateAction={updateProductMix}
            updateUser={updateUser}
            headerText={intl.formatMessage({ id: 'bobcard.user_details.product_mix' })}
            nextButtonText={intl.formatMessage({ id: 'profile_page.update_and_exit' })}
            onNext={() => {
              window.location.href = `/users/${user.user_name}`;
            }}
            requiredFields={reqF}
            fromEditMyProfile
            userName={user.fb_user_name}
          />
        </div>
        <div className="col-md-4 col-sm-5">
          <ProductMixChart
            life_ifp={product_mix.life_ifp}
            home_ifp={product_mix.home_ifp}
            health_ifp={product_mix.health_ifp}
            auto_ifp={product_mix.auto_ifp}
            commercial_ifp={product_mix.commercial_ifp}
            group_ifp={product_mix.group_ifp}
            mutual_funds_aum={product_mix.mutual_funds_aum}
            securities_aum={product_mix.securities_aum}
            annuities_aua={product_mix.annuities_aua}
            segregated_funds_aua={product_mix.segregated_funds_aua}
            mcpi_ifp={product_mix.mcpi_ifp}
            private_products_ifp={product_mix.private_products_ifp}
            crop_hail_ifp={product_mix.crop_hail_ifp}
            crop_hail_with_wind_ifp={product_mix.crop_hail_with_wind_ifp}
            farm_insurance_ifp={product_mix.farm_insurance_ifp}
            price_products_ifp={product_mix.price_products_ifp}
            replant_supplement_ifp={product_mix.replant_supplement_ifp}
            gic_fixed_income={product_mix.gic_fixed_income}
            insurance={product_mix.insurance}
            equity={product_mix.equity}
            availableProducts={this.props.data.availableProducts}
            itemsPerRow={2}
            width={400}
          />
        </div>
      </div>
    );
  };
}
export default injectIntl(graphql(ProductMixQueries)(connect(mapStateToProps, actions)(ProductMixContainer)));
