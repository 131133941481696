import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import * as actions from '../actions/userActionCreators';
import StrengthsComponent from '../components/SignUpWizard/StrengthsAndAvatar/StrengthsComponent';
import PrimaryMarketsComponent from '../components/SignUpWizard/StrengthsAndAvatar/PrimaryMarketsComponent';
import SpecializedBusinessComponent from '../components/SignUpWizard/StrengthsAndAvatar/SpecializedBusinessComponent';
import WhyValuesComponent from '../components/SignUpWizard/StrengthsAndAvatar/WhyValuesComponent';
import ToggleDiscretionaryInvestmentModels from '../components/SignUpWizard/StrengthsAndAvatar/ToggleDiscretionaryInvestmentModels';
import { get } from 'lodash';
import { graphql } from 'react-apollo';
import AffiliationsSelectorComponent from '../components/SignUpWizard/StrengthsAndAvatar/AffiliationsSelectorComponent';

const StrengthsQueries = gql`
  query StrengthsQueries {
    getCurrentUser {
      user_name
      tag_list
      primary_markets
      specialized_businesses
      discretionary_investment_models
      why_values
      fb_user_name
      company {
        id
        business_type
        stylesheet_name
        available_strengths
        available_primary_markets
        available_specialized_businesses
        available_why_values
        allow_user_affiliations
      }
      affiliations {
        id
        name
      }
    }
    availableAffiliations {
      id
      name
    }
  }
`;

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $primary_markets: [String!]
    $tag_list: [String!]
    $specialized_businesses: [String!]
    $why_values: [String!]
    $discretionary_investment_models: Boolean
  ) {
    updateCurrentUser(
      primary_markets: $primary_markets
      tag_list: $tag_list
      specialized_businesses: $specialized_businesses
      why_values: $why_values
      discretionary_investment_models: $discretionary_investment_models
    )
  }
`;

const saveUser = (user, mutate, redirect = false) => {
  mutate({
    variables : {
      why_values                      : get(user, 'why_values'),
      specialized_businesses          : get(user, 'specialized_businesses'),
      primary_markets                 : get(user, 'primary_markets'),
      tag_list                        : get(user, 'tag_list'),
      discretionary_investment_models : get(user, 'discretionary_investment_models'),
    },
  })
    .then(response => {
      if (redirect) {
        const currentPath = window.location.pathname.split('/').pop();
        const menuList = document.getElementById('edit_profile_path');
        const items = menuList.getElementsByTagName('li');
        let paths = [];
        for (var i = 0; i < items.length; ++i) {
          paths.push(items[i].querySelector('div a').href.split('/').pop());
        }
        window.location.href = `/profile/${paths[paths.indexOf(currentPath) + 1]}`;
      } else {
        window.location.href = `/users/${user.user_name}`;
      }
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
    });
};

const disableUpdateButton = (user, alterItems) => {
  return (
    get(user, 'tag_list', []).length > 5 ||
    get(user, 'specialized_businesses', []).length > 5 ||
    get(user, 'primary_markets', []).length > 5 ||
    get(user, 'why_values', []).length > 5
  );
};

const StrengthsAndPrimaryMarketsContainer = ({ user, updateUser, mutate, company }) => {
  return (
    <Query query={StrengthsQueries} onCompleted={data => updateUser(data.getCurrentUser)}>
      {({ loading, error, data, refetch }) => {
        if (!loading && get(data, 'getCurrentUser')) {
          const alterItems = get(data, 'getCurrentUser.company.business_type') === 'wealth_rj';
          const allowUserAffiliations = get(data, 'getCurrentUser.company.allow_user_affiliations');
          return (
            <div>
              <StrengthsComponent
                availableStrengths={get(data, 'getCurrentUser.company.available_strengths', [])}
                professionalStrengths={get(user, 'tag_list', [])}
                updateAction={updateUser}
                headerText="Product Mix"
                nextButtonText="Update Profile"
                businessType={get(user, 'company.business_type', '')}
                onNext={() => {
                  window.location.href = `/users/${user.fb_user_name}`;
                }}
                saveUser={() => saveUser(user, mutate)}
                stylesheetName={get(data, 'getCurrentUser.company.stylesheet_name', '')}
                fromProfile
              />
              {allowUserAffiliations && (
                <AffiliationsSelectorComponent
                  availableAffiliations={get(data, 'availableAffiliations', [])}
                  userAffiliations={get(user, 'affiliations', [])}
                  updateAction={updateUser}
                  user={user}
                />
              )}
              <PrimaryMarketsComponent
                userName={user.fb_user_name}
                availablePrimaryMarkets={get(data, 'getCurrentUser.company.available_primary_markets', [])}
                userPrimaryMarkets={get(user, 'primary_markets', [])}
                updateAction={updateUser}
                headerText="Product Mix"
                nextButtonText="Update Profile"
                businessType={get(user, 'company.business_type', '')}
                onNext={() => {
                  window.location.href = `/users/${user.fb_user_name}`;
                }}
                saveUser={() => saveUser(user, mutate)}
                saveUserAndNext={() => saveUser(user, mutate, true)}
                stylesheetName={get(data, 'getCurrentUser.company.stylesheet_name', '')}
                alterItems={alterItems}
                disableUpdateButton={disableUpdateButton(user, alterItems)}
              />
              {alterItems && (
                <div>
                  <SpecializedBusinessComponent
                    availableSpecializedBusinesses={get(
                      data,
                      'getCurrentUser.company.available_specialized_businesses',
                      []
                    )}
                    userSpecializedBusinesses={get(user, 'specialized_businesses', [])}
                    updateAction={updateUser}
                    headerText="Product Mix"
                    nextButtonText="Update Profile"
                    businessType={get(user, 'company.business_type', '')}
                    onNext={() => {
                      window.location.href = `/users/${user.fb_user_name}`;
                    }}
                    saveUser={() => saveUser(user, mutate)}
                    stylesheetName={get(data, 'getCurrentUser.company.stylesheet_name', '')}
                    disableUpdateButton={disableUpdateButton(user, alterItems)}
                  />
                  <ToggleDiscretionaryInvestmentModels
                    updateAction={updateUser}
                    stylesheetName={get(data, 'getCurrentUser.company.stylesheet_name', '')}
                    userDiscretionaryInvestmentModels={get(user, 'discretionary_investment_models')}
                  />
                  <br />
                  <br />
                  <WhyValuesComponent
                    userName={user.fb_user_name}
                    availableWhyValues={get(data, 'getCurrentUser.company.available_why_values', [])}
                    userWhyValues={get(user, 'why_values', [])}
                    updateAction={updateUser}
                    headerText="Product Mix"
                    nextButtonText="Update Profile"
                    businessType={get(user, 'company.business_type', '')}
                    onNext={() => {
                      window.location.href = `/users/${user.user_name}`;
                    }}
                    saveUser={() => saveUser(user, mutate)}
                    saveUserAndNext={() => saveUser(user, mutate, true)}
                    stylesheetName={get(data, 'getCurrentUser.company.stylesheet_name', '')}
                    disableUpdateButton={disableUpdateButton(user, alterItems)}
                  />
                </div>
              )}
            </div>
          );
        }
        return <div />;
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  user : state.user,
  company : state.company,
});

export default graphql(UpdateCurrentUser)(connect(mapStateToProps, actions)(StrengthsAndPrimaryMarketsContainer));
