import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { isEmpty, get, debounce } from 'lodash';
import TaskNotes from './TaskNotes';
import ManagerSearchField from '../../containers/ManagerSearchFieldContainer';
import FileTab from '../FileTab/FileTab';

class TaskDrawer extends Component {
  state = { task: {}, value: 0 };

  componentWillReceiveProps (nextProps) {
    const { pipelineTask } = nextProps;
    const { task } = this.state;

    if (get(pipelineTask, 'id') !== get(task, 'id') || get(pipelineTask, 'force')) {
      const activityTypeId = get(pipelineTask, 'activityType.id');
      const tsk = { ...pipelineTask };
      if (activityTypeId) {
        tsk.activityTypeId = activityTypeId;
      }
      this.setState({ task: tsk });
    }
  }

  onSave = debounce(task => {
    const { data, mutation, refetch, updateState, updateTaskCard, updateTaskList } = this.props;
    mutation({ variables: { ...task } }).then(r => {
      updateTaskCard({ task: r.data.updateTask });
      updateTaskList(r.data.updateTask);
    });
  }, 300);

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render () {
    const { onClose, classes, activityTypes, updateState, refetch, taskCardTask } = this.props;
    const { task, value } = this.state;
    return (
      <Drawer
        open={!isEmpty(taskCardTask)}
        variant="persistent"
        anchor="right"
        classes={{
          paper : classes.drawerPaper,
        }}>
        <Button onClick={onClose}>Close</Button>
        <h3 className={classes.container}>
          <Button
            style={{ borderRadius: '50%', background: '#eee', padding: 20, marginRight: 12 }}
            onClick={() => {
              const t = {
                ...taskCardTask,
                status : get(taskCardTask, 'status', 'open') === 'completed' ? 'open' : 'completed',
              };
              this.onSave(t);
            }}>
            <span
              className={
                get(taskCardTask, 'status', 'open') === 'completed' ? `fas fa-check-square green` : `fal fa-square`
              }
              style={{ fontSize: 20, color: get(taskCardTask, 'status', 'open') === 'completed' ? `green` : `black` }}
            />
          </Button>
          {decodeURI(task.name || '')}
        </h3>
        <Tabs
          value={value}
          onChange={this.handleChange}
          centered
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth">
          <Tab label="Details" />
          <Tab label="Notes" />
          <Tab label="Related" />
        </Tabs>
        <Divider />
        <SwipeableViews axis="x" index={value} onChangeIndex={this.handleChangeIndex}>
          <div className={classes.tabContainer}>
            <TextField
              autoFocus
              id="name"
              label="Name"
              type="text"
              className={classes.inputField}
              value={decodeURI(task.name)}
              placeholder="Add Name"
              onChange={e => {
                const tsk = { ...task, name: encodeURI(e.target.value) };
                this.setState({ task: tsk });
              }}
              onBlur={() => {
                this.onSave(task);
              }}
              fullWidth
            />
            <TextField
              id="standard-description"
              label="Description"
              fullWidth
              className={classes.textField}
              value={task.description ? task.description : ''}
              onChange={e => {
                const tsk = { ...task, description: e.target.value };
                this.setState({ task: tsk });
              }}
              onBlur={() => {
                this.onSave(task);
              }}
              margin="normal"
            />
            <br />
            <br />
            <ManagerSearchField
              label="Owner"
              value={
                task.inventoryUser ? (
                  {
                    ...task.inventoryUser,
                    label : get(task, 'inventoryUser.name'),
                    value : get(task, 'inventoryUser.id'),
                  }
                ) : null
              }
              onChange={v => {
                const tsk = { ...task, inventoryUser: v, inventory_user_id: v.id };
                this.setState({ task: tsk });
                this.onSave(tsk);
              }}
            />
            <div>
              <br />
              <b>Status</b> &nbsp;
              <Select
                value={task.status || 'open'}
                onClick={e => {
                  const tsk = { ...task, status: e.target.value };
                  this.setState({ task: tsk });
                  this.onSave(tsk);
                }}>
                {[ 'open', 'completed' ].map(a => (
                  <MenuItem key={a} value={a}>
                    {a}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <br />
              <b>Activity Type</b> &nbsp;
              <Select
                value={task.activityTypeId}
                onClick={e => {
                  const tsk = { ...task, activityTypeId: e.target.value };
                  this.setState({ task: tsk });
                  this.onSave(tsk);
                }}>
                {(activityTypes || []).map(at => (
                  <MenuItem key={at.id} value={at.id}>
                    {at.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <br />
              <b>Due Date</b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={new Date(task.dueDate)}
                  onChange={date => {
                    const tsk = { ...task, dueDate: date, due_date: date };
                    this.setState({ task: tsk });
                    this.onSave(tsk);
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>

          <div className={classes.tabContainer}>
            <TaskNotes
              task={task}
              notes={task.comments}
              refetch={refetch}
              updateTask={t => {
                this.setState({ task: t });
              }}
              updateState={updateState}
            />{' '}
          </div>
          <div className={classes.tabContainer}>
            {(task || {}).id && <FileTab variables={{ task_id: task.id }} documents={(task || {}).documents} />}
          </div>
        </SwipeableViews>
      </Drawer>
    );
  }
}

const styles = {
  drawerPaper  : {
    width        : 530,
    height       : 'calc(100% - 100px)',
    position     : 'absolute',
    top          : 68,
    borderRadius : 4,
    overflowY    : 'auto',
    boxShadow    : '-2px 2px 2px -1px #aaa',
    borderTop    : '1px solid #aaa',
  },
  container    : {
    padding : 8,
  },
  tabContainer : {
    minHeight : '80vh',
    padding   : 8,
  },
  dark         : {
    background : '#fafafa',
  },
  inputField   : {
    padding : 8,
  },
};

TaskDrawer.propTypes = {
  onClose       : PropTypes.func.isRequired,
  classes       : PropTypes.object.isRequired,
  activityTypes : PropTypes.array,
  updateState   : PropTypes.func.isRequired,
  refetch       : PropTypes.func.isRequired,
  mutation      : PropTypes.func.isRequired,
  pipelineTask  : PropTypes.object,
};

TaskDrawer.defaultProps = {
  activityTypes : [],
  pipelineTask  : {},
};

export default withStyles(styles)(TaskDrawer);
