import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import numeral from 'numeral';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import styled, { css } from 'styled-components';
import InputSlider from '../InputSlider/InputSlider';
import WarningText from '../WarningText/WarningText';
import { get, upperCase } from 'lodash';
import Switch from '@material-ui/core/Switch';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';

export const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $gross_expenses: Int
    $branch_net_payout: Float
    $expense_growth_rate: Float
    $use_benchmark_expense_percentage: Boolean
  ) {
    updateCurrentUser(
      gross_expenses: $gross_expenses
      branch_net_payout: $branch_net_payout
      expense_growth_rate: $expense_growth_rate
      use_benchmark_expense_percentage: $use_benchmark_expense_percentage
    )
  }
`;

const ColorSwitch = withStyles(theme => ({
  switchBase : {
    color: get(theme, 'palette.accent.main', 'blue'),
    '&$checked'          : {
      color: get(theme, 'palette.accent.main', 'blue'),
    },
    '&$checked + $track' : {
      backgroundColor: get(theme, 'palette.accent.main', 'blue'),
    },
  },
  checked    : {},
  track      : {},
}))(Switch);

class PracticeInfo extends React.Component {
  updateUser = debounce(() => {
    const { mutate } = this.props;
    const {
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses,
    } = this.state;
    if (this.numbersAreValid()) {
      mutate({
        variables : {
          gross_expenses,
          branch_net_payout,
          expense_growth_rate,
          use_benchmark_expense_percentage,
        },
      })
        .then(r => {
          if (r) {
            // window.location.reload(true);
          }
        })
        .catch(error => {
          // eslint-disable-next-line
          console.log('there was an error sending the query', error);
        });
    }
  }, 2000);

  constructor (props) {
    super(props);
    let use_benchmark_expense_percentage = false;
    if (props.employeeBasedAdvisors) {
      const isRjas = get(props, 'currentUser.is_rjas', false);
      const showRjasBenchmarkExpenses = get(props, 'company.features', []).find(f => f === 'rjas_benchmark_expenses'); 
      if (isRjas && showRjasBenchmarkExpenses)
        use_benchmark_expense_percentage = true;
      else {
        use_benchmark_expense_percentage = false;
      }
    } else {
      use_benchmark_expense_percentage = props.currentUser.use_benchmark_expense_percentage || false;
    }
    this.state = {
      gross_expenses                   : props.currentUser.gross_expenses || 0,
      branch_net_payout                : props.currentUser.branch_net_payout * 100 || 0,
      expense_growth_rate              : props.currentUser.expense_growth_rate * 100 || 0,
      use_benchmark_expense_percentage,
      benchmark_expenses               : props.currentUser.benchmark_expenses || 0,
    };
  }

  numbersAreValid = () => {
    const { gross_expenses, branch_net_payout, expense_growth_rate } = this.state;
    if (
      gross_expenses <= 5000000 &&
      branch_net_payout <= 100 &&
      branch_net_payout > 0.01 &&
      expense_growth_rate <= 100
    ) {
      return true;
    }
    return false;
  };

  runUpdate = () => {
    const { updatePePracticeInfo, saveToUser } = this.props;
    const {
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses,
    } = this.state;
    if (saveToUser) {
      this.updateUser();
    }
    updatePePracticeInfo(
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses
    );
  };

  handleChange = (val, s) => {
    this.setState({ [val]: s }, () => this.runUpdate());
  };

  handleCurrencyChange = (val, s) => {
    this.setState({ [val]: parseInt(s.replace('$', '').replace(',', '', 10) || 0) }, () => this.runUpdate());
  };

  validatePercentage = (name, val) => {
    var v = parseFloat(val);
    let value;
    value = numeral(v).format('0[.]00a', Math.floor);
    this.setState({ [name]: parseFloat(value) }, () => this.runUpdate());
  };

  toggleUserBenchmarkExpenses = () => {
    this.setState(prevState => ({
      use_benchmark_expense_percentage: !prevState.use_benchmark_expense_percentage,
      gross_expenses: !prevState.use_benchmark_expense_percentage ? 0 : prevState.gross_expenses
    }), this.runUpdate);
  };

  checkEmployeeBasedAdvisorsStatus = () => {
    const { employeeBasedAdvisors, currentUser } = this.props;
    const isRjas = currentUser.is_rjas ;

    if (employeeBasedAdvisors && !isRjas) {
      return true;
    }
    return false;
  };

  payoutLabel = () => {
    const { employeeBasedAdvisors, company } = this.props;

    if (employeeBasedAdvisors)
      return <FormattedMessage id="valuation_drawer.fa_payout" />;
    if (get(company, 'features', []).find(f => f === 'non_branch_valuation_copy'))
      return <FormattedMessage id="valuation_page.payout_rate" />
    return <FormattedMessage id="valuation_page.branch_net_payout" />
  }

  render () {
    const { classes, currentUser, employeeBasedAdvisors, intl, theme, company} = this.props;
    const {
      gross_expenses,
      branch_net_payout,
      expense_growth_rate,
      use_benchmark_expense_percentage,
      benchmark_expenses,
    } = this.state;
    const isRjas = currentUser.is_rjas ;

    const hideBranchNetPayout = get(currentUser.company, 'hide_branch_net_payout');
    const showRjasBenchmarkExpenses = get(company, 'features', []).find(f => f === 'rjas_benchmark_expenses');
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <span className={classes.headline}>
              <FormattedMessage id="practice_info.practice_info" />
            </span>
          </Grid>
          {!hideBranchNetPayout && (
            <Grid item xs={12}>
              <span>
                {this.payoutLabel()}
              </span>
              <Tooltip
                classes={{ tooltip: classes.tooltiptext }}
                title={`${intl.formatMessage({ id: 'valuation_drawer.practice_info_tool_tip' })}`}
                placement="top">
                <Button style={{ margin: -15 }}>
                  <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
                </Button>
              </Tooltip>
            </Grid>
          )}
          {!hideBranchNetPayout && (
            <Grid item xs={7}>
              {!employeeBasedAdvisors ||
                (employeeBasedAdvisors &&
                isRjas && (
                  <RangeSliderContainer theme={theme}>
                    <InputSlider
                      maxValue={100}
                      minValue={1}
                      value={branch_net_payout || 0.01}
                      formatLabel={value => ``}
                      step={0.5}
                      onChange={e => {
                        this.handleChange('branch_net_payout', e);
                      }}
                    />
                  </RangeSliderContainer>
                ))}
            </Grid>
          )}
            <Grid item xs={1} />
          {!hideBranchNetPayout && (
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={8}>
                  <TextField
                    id="outlined-name"
                    disabled={this.checkEmployeeBasedAdvisorsStatus()}
                    inputProps={{ tabIndex: '1' }}
                    outlined-name
                    value={branch_net_payout}
                    margin="normal"
                    variant="outlined"
                    type="number"
                    onChange={e => {
                      this.validatePercentage('branch_net_payout', e.target.value);
                    }}
                    error={branch_net_payout > 100 || branch_net_payout < 0.01}
                  />
                </Grid>
                <Grid item xs={2}>
                  <div style={{ marginTop: 28, marginLeft: 10 }}>%</div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!hideBranchNetPayout && (
            <WarningText
              visible={branch_net_payout > 100 || branch_net_payout < 0.01}
              message={intl.formatMessage({ id: 'valuation_drawer.practice_info_value_must_be_between_prct' })}
            />
          )}   
          <Grid item xs={12}>
            <div style={{ marginBottom: 25 }} />
            {upperCase(currentUser.company_name) !== 'RJA' && (
              <span>
                <FormattedMessage id="valuation_drawer.operating_expenses" />
              </span>
            )}
            {upperCase(currentUser.company_name) === 'RJA' && (
              <span>
                {employeeBasedAdvisors ? (
                  <FormattedMessage id="valuation_drawer.additional_practice_expenses" />
                ) : (
                  <FormattedMessage id="valuation_drawer.practice_expenses" />
                )}
              </span>
            )}
            <Tooltip
              classes={{ tooltip: classes.tooltiptext }}
              title={
                employeeBasedAdvisors ? (
                  intl.formatMessage({ id: 'valuation_drawer.practice_expense_tool_tip_1' })
                ) : (
                  intl.formatMessage({ id: 'valuation_drawer.practice_expense_tool_tip_2' })
                )
              }
              placement="top">
              <Button style={{ margin: -15 }}>
                <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
              </Button>
            </Tooltip>
            {(!employeeBasedAdvisors || ( showRjasBenchmarkExpenses && isRjas) ) && (
              <span style={{ float: 'right', margin: -8 }}>
                <FormattedMessage id="valuation_drawer.use_benchmark" />
                <ColorSwitch
                  checked={use_benchmark_expense_percentage}
                  onChange={this.toggleUserBenchmarkExpenses}
                  name="checkedB"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </span>
            )}
          </Grid>
          <Grid item xs={7}>
            {!use_benchmark_expense_percentage && (
              <RangeSliderContainer theme={theme}>
                <InputSlider
                  maxValue={5000000}
                  minValue={0}
                  value={gross_expenses || 0}
                  formatLabel={value => ``}
                  step={50000}
                  onChange={e => {
                    this.handleChange('gross_expenses', e);
                  }}
                />
              </RangeSliderContainer>
            )}
          </Grid>
          <Grid item xs={5}>
            <TextField
              id="outlined-name"
              disabled={use_benchmark_expense_percentage}
              inputProps={{ tabIndex: '1' }}
              outlined-name
              value={
                use_benchmark_expense_percentage ? (
                  numeral(benchmark_expenses).format('$0,0[.]')
                ) : (
                  numeral(gross_expenses).format('$0,0[.]') || 0
                )
              }
              margin="normal"
              variant="outlined"
              onChange={e => {
                this.handleCurrencyChange('gross_expenses', e.target.value);
              }}
              error={gross_expenses > 5000000}
            />
          </Grid>
          <WarningText
            visible={gross_expenses > 5000000}
            message={intl.formatMessage({ id: 'valuation_drawer.benchmark_warning' })}
          />
          <br />
          <Grid item xs={12}>
            <span>
              <FormattedMessage id="valuation_drawer.expense_growth_rate" />{' '}
            </span>
            <Tooltip
              classes={{ tooltip: classes.tooltiptext }}
              title={intl.formatMessage({ id: 'valuation_drawer.expense_growth_rate_tool_tip' })}
              placement="top">
              <Button style={{ margin: -15 }}>
                <i className="fas fa-info-circle custom-tool-tip" style={{ color: '#1396e2' }} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={7}>
            <RangeSliderContainer theme={theme}>
              <InputSlider
                maxValue={100}
                minValue={0}
                value={expense_growth_rate || 0}
                formatLabel={value => ``}
                step={0.5}
                onChange={e => {
                  this.handleChange('expense_growth_rate', e);
                }}
              />
            </RangeSliderContainer>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={8}>
                <TextField
                  id="outlined-name"
                  inputProps={{ tabIndex: '1' }}
                  outlined-name
                  value={expense_growth_rate || 0}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  onChange={e => {
                    this.validatePercentage('expense_growth_rate', e.target.value);
                  }}
                  error={expense_growth_rate > 100}
                />
              </Grid>
              <Grid item xs={2}>
                <div style={{ marginTop: 28, marginLeft: 10 }}>%</div>
              </Grid>
            </Grid>
          </Grid>
          <WarningText
            visible={expense_growth_rate > 100}
            message={intl.formatMessage({ id: 'valuation_drawer.practice_info_value_must_be_between_prct_zero' })}
          />
        </Grid>
      </div>
    );
  }
}

PracticeInfo.propTypes = {
  mutate               : PropTypes.func.isRequired,
  classes              : PropTypes.object.isRequired,
  updatePePracticeInfo : PropTypes.func,
  saveToUser           : PropTypes.bool,
};

PracticeInfo.defaultProps = {
  updatePePracticeInfo : () => {},
  saveToUser           : false,
};

const styles = theme => ({
  toolTip     : {
    color : '#1396e2',
  },
  tooltiptext : {
    fontSize : 16,
  },
  headline    : {
    fontSize     : 18,
    fontWeight   : 'lighter',
    marginBottom : 20,
    color        : '#333',
  },
});

const mapStateToProps = state => ({
  company: state.company,
});

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${get(props, 'theme.palette.accent.main')} !important;
        border: 1px solid ${get(props, 'theme.palette.accent.main')} !important;
      }

      .input-range__track--active {
        background: ${get(props, 'theme.palette.accent.main')};
      }
    `};
`;

export default connect(mapStateToProps)(withTheme(withStyles(styles)(injectIntl(graphql(UpdateCurrentUser)(PracticeInfo)))));
