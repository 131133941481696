import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import {loremIpsum} from 'lorem-ipsum';
import { FormattedMessage } from 'react-intl';
import { isEmpty, get } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import StarSigns from './StarSigns';

const renderBlurryInfo = (marketplaceOnly, companyBusinessType, classes) => (
  <div>
    {!marketplaceOnly && (
      <aside>
        <p className="blurry-text">{loremIpsum({ count: 2, units: 'sentences' })}</p>
        <dl>
          <dt>
            <FormattedMessage id="bobcard.user_details.annual_revenue" />
          </dt>
          <dd className="blurry-text">$0.00</dd>
          <dt>
            <FormattedMessage id="bobcard.user_details.recurring_revenue" />
          </dt>
          <dd className="blurry-text">$0.00</dd>
          <dt>
            <FormattedMessage id="bobcard.user_details.assets_under_management" />
          </dt>
          <dd className="blurry-text">$0.00</dd>
          <dt>
            <FormattedMessage id="bobcard.user_details.number_of_clients" />
          </dt>
          <dd className="blurry-text">$0.00</dd>
        </dl>
      </aside>
    )}
    {marketplaceOnly && (
      <aside>
        <div>
          <table className="table" style={{ border: 'none', backgroundColor: '#ffffff' }}>
            <tbody style={{ border: 0 }}>
              <tr style={{ border: 0 }}>
                <td style={{ padding: 15 }}>
                  <center>
                    <span className="blurry-text">
                      000
                      <br />
                    </span>
                    <span style={{ fonstSize: 14 }}>
                      {(companyBusinessType === 'life' || companyBusinessType === 'wealth' || companyBusinessType === 'wealth_rj') && (
                        <div className={classes.body}>Practicing</div>
                      )}
                      {companyBusinessType === 'p_and_c' && <div className={classes.body}>In Business</div>}
                      {!companyBusinessType && <div className={classes.body}>In Business</div>}
                    </span>
                  </center>
                </td>
                <td style={{ padding: 15 }}>
                  <center>
                    <span className="blurry-text">
                      $0.00
                      <br />
                    </span>
                    <span style={{ fonstSize: 14 }}>Revenue</span>
                  </center>
                </td>
                <td style={{ padding: 15 }}>
                  <center>
                    <span className="blurry-text">
                      00
                      <br />
                    </span>
                    <span style={{ fonstSize: 14 }}>Clients</span>
                  </center>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </aside>
    )}
  </div>
);

const UserDetails = ({
  user,
  isUserAuthorized,
  userIsAdmin,
  currentUserHasLimitedAccess,
  marketplaceOnly,
  classes,
  companyBusinessType,
}) => {
  if (!userIsAdmin) {
    if (
      !isUserAuthorized ||
      user.obfuscated ||
      (!user.is_pure_buyer && currentUserHasLimitedAccess) ||
      (currentUserHasLimitedAccess && isEmpty(user.transition_goals))
    ) {
      return renderBlurryInfo(marketplaceOnly, companyBusinessType, classes);
    }
  }
  return (
    <div>
      {!marketplaceOnly && (
        <aside>
          <p>{user.bio}</p>
          <dl>
            <dt>
              <FormattedMessage id="bobcard.user_details.annual_revenue" />
            </dt>
            <dd>{numeral(user.annual_revenue).format('$0,0')}</dd>
            <dt>
              <FormattedMessage id="bobcard.user_details.recurring_revenue" />
            </dt>
            <dd>{numeral(user.recurring_revenue).format('$0,0')}</dd>
            {['health_and_life_stars', 'aum_stars', 'p_c_stars', 'group_stars'].map(star_type => {
              if (user.product_mix && user.product_mix[star_type]) {
                return (
                  <div>
                    <dt>
                      <FormattedMessage id={`shared.${star_type}`} />
                    </dt>
                    <dd>
                      <StarSigns number={user.product_mix[star_type]} />
                    </dd>
                  </div>
                );
              }
            })}
            <dt>
              <FormattedMessage id="bobcard.user_details.number_of_clients" />
            </dt>
            <dd>{user.number_of_clients}</dd>
          </dl>
        </aside>
      )}
      {marketplaceOnly && (
        <aside>
          <div>
            <table className="table" style={{ border: 'none', backgroundColor: '#ffffff' }}>
              <tbody style={{ border: 0 }}>
                <tr style={{ border: 0 }}>
                  <td style={{ padding: 15 }}>
                    <center>
                      <span className={classes.header}>
                        {get(user, 'years_practicing') || 0} {get(user, 'years_practicing') === 1 ? 'yr' : 'yrs'}
                        <br />
                      </span>
                      <span style={{ fonstSize: 14 }}>
                        {(companyBusinessType === 'life' || companyBusinessType === 'wealth') && (
                          <div className={classes.body}>Practicing</div>
                        )}
                        {companyBusinessType === 'p_and_c' && <div className={classes.body}>In Business</div>}
                        {!companyBusinessType && <div className={classes.body}>In Business</div>}
                      </span>
                    </center>
                  </td>
                  <td style={{ padding: 15 }}>
                    <center>
                      <span className={classes.header}>
                        {numeral(get(user, 'recurring_revenue', 0)).format('$0a')}
                        <br />
                      </span>
                      <span style={{ fonstSize: 14 }}>Revenue</span>
                    </center>
                  </td>
                  <td style={{ padding: 15 }}>
                    <center>
                      <span className={classes.header}>
                        {get(user, 'number_of_clients') || 0}
                        <br />
                      </span>
                      <span style={{ fonstSize: 14 }}>Clients</span>
                    </center>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </aside>
      )}
    </div>
  );
};

const styles = {
  header: {
    fontFamily: 'Trebuchet MS, Arial, Helvetica, sans-serif',
    fontWeight: 'bold',
    fontSize: 20,
  },
  body: {
    fontFamily: 'Trebuchet MS, Arial, Helvetica, sans-serif',
    color: '#5a5a5a',
  },
};

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  isUserAuthorized: PropTypes.bool.isRequired,
  userIsAdmin: PropTypes.bool.isRequired,
  currentUserHasLimitedAccess: PropTypes.bool.isRequired,
  marketplaceOnly: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  companyBusinessType: PropTypes.string.isRequired,
};

export default withStyles(styles)(UserDetails);
