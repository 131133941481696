import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

export const LimitedAccessMessage = ({ classes, companyBrandedName, currentCompanySellerWord }) => {
  return (
    <div className={classes.snackText}>
      <strong>{`Welcome to ${companyBrandedName}!`}</strong>
      <br />
      <br />
      <p>
        {`
        As someone who is not currently a ${currentCompanySellerWord}, you are welcome to browse profiles across the country to see the opportunities
        that you could access if you choose to become a ${currentCompanySellerWord}.`}
      </p>
      <br />
      <p>
        While you will be able to see profiles, where they are located and what their goals are, you will not be able to
        send direct messages to them.
      </p>
      <br />
      <p>{`When you see a profile of a user with interests on the sell-side, such as selling or finding a successor, their data is blurred to protect their
        identity. If you become a ${currentCompanySellerWord}, you will not only be able to see their data, but also reach out to them directly to discuss their opportunity!`}</p>
      <br />
      <p>{`When you see the profile of a user with interests on the buy-side, such as buying or merging, their data is shown but you will not be able to
        reach out to them directly. Similarly, if you become a ${currentCompanySellerWord}, you can reach out to them directly!`}</p>
      <br />
      <p>
        {`If you're interested in selling your business, inquire with us to learn about how we can facilitate conversations between yourself and the sellers
        you see on ${companyBrandedName}. We make achieving your goal of selling easier by providing you with the pre-qualified
        leads and support you require to get there!`}
      </p>
    </div>
  );
};

const styles = theme => ({
  snackbar  : {
    maxWidth : 600,
    margin   : 4,
  },
  snackText : {
    fontSize : 15,
  },
});

LimitedAccessMessage.propTypes = {
  pipelineCard    : PropTypes.object,
  classes         : PropTypes.object.isRequired,
  activityTypes   : PropTypes.array.isRequired,
  refetch         : PropTypes.func.isRequired,
  inventoryUserId : PropTypes.string,
};

LimitedAccessMessage.defaultProps = {
  pipelineCard    : {},
  inventoryUserId : null,
};

export default withStyles(styles)(LimitedAccessMessage);
