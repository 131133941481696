import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { withSnackbar } from 'notistack';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import { get } from 'lodash';

export const DownloadCatPlan = gql`
  mutation downloadCatPlan(
    $id: ID!
  ) {
    downloadCatPlan(
      id: $id
    )
  }
`;

export const DownloadCatPlanButton = ({ inventoryUser, classes, enqueueSnackbar}) => {
  const [downloadBuilder] = useMutation(DownloadCatPlan);
  console.log(inventoryUser);
  const dealStructureForCatastrophicPlan = get(inventoryUser, 'continuity_builder.dealStructureForCatastrophicPlan');
  const continuityBuilderSuccessors = get(inventoryUser, 'continuity_builder.continuityBuilderSuccessors', []);
  return (
    <div>
      <h5>Catastrophic Planning Info</h5>
      {continuityBuilderSuccessors.map((successor, index) => (
        <div key={index}>
          <p>{`Name of Partner: ${successor.inventoryUser.name}`}</p>
          <p>{`Agreement Type: ${dealStructureForCatastrophicPlan}`}</p>
          <p>{`Execution Date: ${moment(inventoryUser.catastrophic_plan_contract_date).format('MMM/DD/YYYY')}`}</p>
          <br />
        </div>
      ))}
      <Button
        variant="outlined"
        onClick={() => {
          const variables = {
            id: inventoryUser.id,
          };
          downloadBuilder({
            variables,
          }).then(() => {
            enqueueSnackbar("The Plan is being retrieved and will be emailed to you.", {
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              }
            });
          })
        }}>Click here to download plan
      </Button>
    </div>
  );
}

DownloadCatPlanButton.propTypes = {
  inventoryUser: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = {
  container: {
    maxWidth: 1140,
    margin: '20px auto',
    height: 'calc(100vh - 160px)',
    display: 'flex',
  },
  drawerPaper: {
    width: '100vw',
    top: 114,
  },
};

export default withSnackbar(withStyles(styles)(DownloadCatPlanButton));