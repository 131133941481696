import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { get, startCase } from 'lodash';
import numeral from 'numeral';
import FileTab from '../FileTab/FileTab';

export const PipelineCardTab = ({ cards, classes, inventoryUserId, documents }) => {
  return (
    <div>
      <h5>Pipeline Activity</h5>
      {cards.map(card => (
        <a href={`/admin/pipelines/${card.pipeline.id}?card_id=${card.id}`}>
          <Card className={classes.card}>
            <div className={classes.item}>
              <Avatar className={classes.avatar}>$</Avatar>
              <div className={classes.body}>
                <b>{decodeURI(card.name)}</b>
                <br />

                {`${decodeURI(get(card.pipeline, 'name', ''))}: ${decodeURI(
                  get(card, 'pipeline_stage.name')
                )} / ${startCase(get(card, 'status'))}`}
              </div>
              <div className={classes.value}>{numeral(get(card, 'value', 0)).format('$0,')}</div>
            </div>
          </Card>
        </a>
      ))}
      <FileTab variables={{ inventory_user_id: inventoryUserId }} documents={documents} />
    </div>
  );
};

PipelineCardTab.propTypes = {
  classes         : PropTypes.object.isRequired,
  cards           : PropTypes.object.isRequired,
  inventoryUserId : PropTypes.number.isRequired,
  documents       : PropTypes.array.isRequired,
};

const styles = {
  item   : {
    padding       : 10,
    margin        : 10,
    fontSize      : '1em',
    display       : 'flex',
    flexDirection : 'row',
  },
  card   : {
    margin : 10,
  },
  avatar : {
    fontSize : 18,
  },
  body   : { padding: '0 10px', flex: 1 },
  value  : { width: 58, textAlign: 'right' },
};

export default withStyles(styles)(PipelineCardTab);
