import React from "react";
import { CircularProgress, LinearProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { withStyles } from '@material-ui/core/styles';
import { get, find } from "lodash";
import { connect } from 'react-redux';
import { yearsFromRetirementOptions } from "../SuccessionReadinessBanner/SuccessionReadinessBanner";
import * as successionReadinessActionCreators from '../../actions/successionReadinessActionCreators';
import { S3Image } from "../../lib/s3";
import Button from "@material-ui/core/Button";
import PropTypes from 'prop-types';

const SuccessionReadinessStatus = props => {
  const [refreshCount, setRefreshCount] = React.useState(0);

  const { classes, companySuccessionReadinessName, onClose } = props;

  if (Object.keys(props.successionReadiness || {}).length === 0) return <p>No data</p>;
  const completionPercentage = get(props, 'successionReadiness.completionPercentage', 0);
  const yearsFromRetirement = get(props, 'successionReadiness.yearsFromRetirement', 0);
  const score = get(props, 'successionReadiness.score', 0);
  const sr = props.successionReadiness;

  if (refreshCount !== props.refreshCount) {
    props.refetchFunc({}).then(result => {
    });

    setRefreshCount(props.refreshCount);
  }

  const currentYearOption = find(yearsFromRetirementOptions, ['value', yearsFromRetirement * 10]) || {};
  const currentStepImage = get(props, 'successionReadiness.currentStepImage', null);
  let barColor = classes.mid;
  if(sr.roundedScore/sr.maxScore > 0.6) {
    barColor = classes.high;
  }
  if(sr.roundedScore/sr.maxScore < 0.25) {
    barColor = classes.low;
  }

  const handleCloseAssessment = () => {
    if (onClose) {
      onClose();
    }
  } 

  return (
    <div>
      {completionPercentage > 99 ? (<div>
        <p>
          <b>Your Score</b><br />
          Congratulations on completing your assessment. We've gone ahead and added action items to your dashboard. Here is how you scored:
        </p>
        <center>
          <div style={{ position: 'relative', width: '250px', height: '100px', margin: '10px' }}>
            <div className={classes.scoreHolder}>
              <LinearProgress 
                variant="determinate" 
                value={sr.roundedScore/sr.maxScore*100} 
                valueBuffer={sr.maxScore}
                className={classes.linearProgrss}
                classes={{ barColorPrimary: barColor}} />
            </div>
              
            <Typography
              variant="h6"
              component="div"
              color="textSecondary"
              className={classes.percentage}
            >
              {sr.roundedScore} / {sr.maxScore}
            </Typography>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseAssessment}
          >
            Close Assessment
          </Button>
        </center>
      </div>)
        : (<div>
          <p>
            <b>Status</b><br />
            You're at the beginning of your {companySuccessionReadinessName} Assesement.
          </p>
          <center>
            <div style={{ position: 'relative', width: '100px', height: '100px', margin: '10px' }}>
              <CircularProgress
                className={classes.progress}
                variant="determinate"
                value={completionPercentage}
                size={100}
              />
              <Typography
                variant="h6"
                component="div"
                color="textSecondary"
                className={classes.percentage}
              >
                {`${Math.round(completionPercentage)}%`}
              </Typography>
            </div>
          </center>
        </div>)}
      <br />
      {companySuccessionReadinessName === 'Succession Readiness' && 
        <p>
          <b>Years Away from Retirement</b><br />
          {currentYearOption.label}
        </p>
      }
      {currentStepImage &&
        <p>
          <img
            src={S3Image(`readiness/${currentStepImage}`)}
            alt="Current Step"
            style={{ width: '100%', height: 'auto' }}
          />
        </p>}
    </div>
  );
}

const styles = theme => ({
  progress: {
    margin: theme.spacing(2),
    position: 'relative',
    width: '100px',
    height: '100px',
  },
  percentage: {
    position: 'absolute',
    top: '50%',
    left: '45%',
  },
  linearProgrss: {
    height: '20px',
    margin: '10px',
  },
  scoreHolder: {
    border: '1px solid #dadada',
    borderRadius: '6px',
    padding: 20,
    margin: 20,
  },
  low: {
    backgroundColor: '#ef5350',
  },
  mid: {
    backgroundColor: '#ffdd3c',
  },
  high: {
    backgroundColor: '#4caf50',
  },
});

SuccessionReadinessStatus.propTypes = {
  classes: PropTypes.object.isRequired,
  companySuccessionReadinessName: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    successionReadiness: get(state, 'successionReadiness.successionReadiness', {}),
    refetchFunc: get(state, 'successionReadiness.refetch', null),
  }
};

export default withStyles(styles)(connect(mapStateToProps, { ...successionReadinessActionCreators })(SuccessionReadinessStatus));