import React from 'react';
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

const AddPayoutMutation = gql`
  mutation createCashFlowCompensationLookup(
    $compMax: Float
    $result: Float
  ) {
    createCashFlowCompensationLookup(
      comp_max: $compMax
      result: $result
    ){
      id
      comp_max
      result 
    }
  }
`

const AddPayoutButton = ({payoutRows, setRows }) => {
  const [open, setOpen] = React.useState(false);
  const [compMax, setCompMax] = React.useState(0.0);
  const [result, setResult] = React.useState(0.0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return( <Mutation mutation={AddPayoutMutation}>
      {(addPayout) => (
        <React.Fragment>
          <Fab
            color="primary"
            variant="round"
            size="small"
            style={{position: 'fixed', bottom: 20, right: 20,}}
            onClick={handleClickOpen}
          >
            <AddIcon/>
          </Fab>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Subscribe</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="compMax"
                label="Comp Max"
                type="number"
                fullWidth
                value={compMax}
                onChange={e => setCompMax(parseFloat(e.target.value))}
              />
              <TextField
                margin="dense"
                id="result"
                label="Result"
                type="number"
                fullWidth
                value={result}
                onChange={e => setResult(parseFloat(e.target.value))}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => {
                addPayout({variables: {compMax, result}}).then(result => {
                  setRows([...payoutRows, result.data.createCashFlowCompensationLookup].sort(p => p.comp_max));
                  handleClose();
                })
              }} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>

      )}
    </Mutation>
  )}
;

export default AddPayoutButton;
