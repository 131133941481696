import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const clickArchive = (dealInterest, declined) => {
  window.open(
    `/deal/${get(dealInterest, 'deal.deal_name')}/archive_deal_interest/${get(
      dealInterest,
      'id'
    )}?declined=${declined}`,
    '_self'
  );
};

const DeclineFaModal = ({ dealInterest, openModal, setModalState }) => {
  return (
    <Dialog open={openModal} onClose={() => setModalState(false)}>
      <DialogTitle id="alert-dialog-title">{'Decline Interest'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage id="profile_page.match_profile.clicking_decline_we_will_notify"/> 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalState(false)} color="primary">
          <FormattedMessage id="shared.cancel"/> 
        </Button>
        <Button onClick={() => clickArchive(dealInterest, true)} color="primary" autoFocus>
         <FormattedMessage id="profile_page.match_profile.decline"/> 
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const styles = {
  highlight                : {
    animation  : '$changeColor 3s linear 2s 1 normal forwards',
    background : '#ffff99',
  },
  '@keyframes changeColor' : {
    from          : { background: '#ffff99' },
    to            : { background: '#fff' },
    bottomButtons : {
      position        : 'fixed',
      left            : 0,
      bottom          : 0,
      textAlign       : 'center',
      paddingBottom   : 10,
      borderTop       : '1px #D5D8DC solid',
      backgroundColor : '#fff',
      width           : '100%',
    },
    status        : {
      marginTop : 20,
    },
  },
};

export default withStyles(styles)(DeclineFaModal);
