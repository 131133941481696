import PropTypes from 'prop-types';
import React from 'react';
import GmvYoyPageContainer from '../../containers/GmvYoyPageContainer';

const GmvYoyPageFull = ({ classes, data }) => {
  return (
    <div>
      <GmvYoyPageContainer />
    </div>
  );
};

GmvYoyPageFull.propTypes = {
  classes : PropTypes.object,
};

export default GmvYoyPageFull;
