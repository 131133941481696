import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import { get, debounce } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  root       : {
    fontSize : '18px',
    width    : 'inherit',
    display  : 'block',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing.unit * 2,
  },
  title      : {
    fontSize : 24,
    color    : '#dadada',
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
    zIndex   : 1000,
  },
  success    : {
    display    : 'flex',
    alignItems : 'center',
  },
  icon       : {
    fontSize    : 20,
    marginRight : 8,
  },
  dashButton : {
    float : 'right',
  },
  fullWidth  : { width: 'inherit' },
  demo       : { display: 'block' },
});

class OpportunityList extends Component {
  columns = [
    { name: 'Title' },
    { name: 'Region' },
    { name: 'Type', options: { sort: false } },
    { name: 'Due Date' },
    { name: 'Number of Candidates' },
    {
      name    : 'Status',
      options : {
        customBodyRender : (value, tabeMeta, updateValue) => (
          <span className={`label ${this.statusClass[value]}`}>{value}</span>
        ),
      },
    },
    {
      name    : '',

      options : {
        sort             : false,
        filter           : false,
        download         : false,
        customBodyRender : (v, t, u) => <a href={`/admin/opportunities/${v}`}>Edit</a>,
      },
    },
    {
      name    : '',
      options : {
        sort             : false,
        filter           : false,
        download         : false,
        customBodyRender : (v, t, u) => <a href={`/admin/opportunities/view/${v}`}>View</a>,
      },
    },
  ];

  statusClass = {
    OPEN   : 'label-success',
    PAUSED : 'label-warning',
    CLOSED : 'label-info',
  };

  constructor (props, context) {
    super(props, context);
    this.state = {
      order       : 'asc',
      orderBy     : 'id',
      page        : 0,
      rowsPerPage : 10,
      nameSearch  : '',
    };

    this.changePage = debounce(this.changePage, 300);
  }

  options = () => {
    const { opportunities } = this.props;
    const { count } = opportunities;
    const { rowsPerPage, page } = this.state;
    return {
      serverSide          : true,
      selectableRows      : false,
      rowsPerPage,
      onRowClick          : (data, meta, e) => {
        if (e.target.localName !== 'a') {
          const { history } = this.props;
          history.push(data[7].props.href);
        }
      },
      onChangePage        : e => {
        this.setState({ page: e }, this.changePage);
      },
      onChangeRowsPerPage : e => {
        this.setState({ rowsPerPage: e }, this.changePage);
      },
      onColumnSortChange  : (e, d) => {
        this.setState({ orderBy: e, order: d }, this.changePage);
      },
      onSearchChange      : e => {
        this.setState({ nameSearch: e }, this.changePage);
      },
      page,
      count,
    };
  };

  changePage = () => {
    const { updateOpportunities, refetch } = this.props;
    const { page, rowsPerPage, orderBy, order, nameSearch } = this.state;
    refetch({
      page,
      rowsPerPage,
      orderBy,
      order,
      nameSearch,
    }).then(response => {
      if (response.data) {
        updateOpportunities({
          page,
          order,
          orderBy,
          data        : response.data.getOpportunities,
          rowsPerPage,
          count       : response.data.getOpportunitiesCount,
          nameSearch,
        });
      }
    });
  };

  componentWillReceiveProps = nextProps => {
    const { loading } = this.props;
    if (loading && !nextProps.loading) {
      nextProps.updateOpportunities(nextProps.getOpportunities);
    }
  };

  formatRow = opportunity => [
    opportunity.title,
    opportunity.province,
    get(opportunity, 'opportunity_type.name'),
    opportunity.expiry_date,
    opportunity.number_of_applications,
    opportunity.status,
    opportunity.id,
    opportunity.id,
  ];

  render () {
    const { classes, updateStatus, opportunities } = this.props;
    const { data, status } = opportunities;

    return (
      <section>
        <Snackbar
          anchorOrigin={{
            vertical   : 'top',
            horizontal : 'center',
          }}
          open={status}
          autoHideDuration={6000}
          aria-describedby="client-snackbar"
          className={classes.success}
          onClose={() => updateStatus(null)}
          message={
            <span id="client-snackbar" className={classes.success}>
              <CheckCircleIcon className={classes.icon} />
              {status}
            </span>
          }
        />
        <Paper className={classes.paper}>
          <Grid container className={classes.root}>
            <div>
              <Button
                onClick={() => {
                  window.location = '/opportunities';
                }}
                variant="contained"
                color="primary"
                className={classes.dashButton}>
                View Opportunity Dashboard
              </Button>
            </div>
            <Grid container className={classes.root}>
              <MUIDataTable
                title=""
                data={data.map(o => this.formatRow(o))}
                columns={this.columns}
                options={this.options()}
              />
            </Grid>
          </Grid>
        </Paper>
      </section>
    );
  }
}

OpportunityList.propTypes = {
  opportunities       : PropTypes.object,
  classes             : PropTypes.object,
  history             : PropTypes.object,
  getOpportunities    : PropTypes.object.isRequired,
  updateOpportunities : PropTypes.func.isRequired,
  refetch             : PropTypes.func.isRequired,
  updateStatus        : PropTypes.func.isRequired,
  loading             : PropTypes.bool.isRequired,
};

OpportunityList.defaultProps = {
  opportunities : [],
  classes       : {},
  history       : {},
};

export default injectIntl(withStyles(styles)(OpportunityList));
