/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class IconSelector extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render () {
    const { iconName, onIconChange, iconOptions } = this.props;
    const { open } = this.state;

    return (
      <div style={{ display: 'inline-block' }}>
        <a onClick={this.handleClickOpen}>
          <span
            onClick={this.handleClickOpen}
            style={{ border: '1px solid #ddd', width: 28, height: 28, fontSize: 28 }}
            id="icon">
            &nbsp;
            <FontAwesomeIcon icon={iconName === null || '' ? 'coffee' : iconName.replace('fa-', '')} />
          </span>
        </a>

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Choose an icon</DialogTitle>
          <DialogContent>
            {iconOptions.map(i => (
              <IconButton
                variant="outlined"
                onClick={() => {
                  onIconChange(i);
                  this.handleClose();
                }}>
                <span className={`fas ${i}`} style={{ fontSize: 32 }} />
              </IconButton>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

IconSelector.propTypes = {
  iconName     : PropTypes.string,
  onIconChange : PropTypes.func.isRequired,
  iconOptions  : PropTypes.arrayOf(PropTypes.string).isRequired,
};

IconSelector.defaultProps = {
  iconName : '',
  iconOptions : [
    'fa-sticky-note',
    'fa-phone',
    'fa-calendar',
    'fa-clipboard',
    'fa-chart-bar',
    'fa-edit',
    'fa-envelope',
    'fa-share',
    'fa-bell',
    'fa-microphone',
    'fa-user-friends',
    'fa-user-check',
  ], 
};

export default IconSelector;
