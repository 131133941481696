import React from 'react';
import ValuationUpdateContainer from './ValuationUpdateContainer';

const ValuationsContainer = () => (
  <div>
    <ValuationUpdateContainer />
  </div>
);

export default ValuationsContainer;
