import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { get, capitalize } from 'lodash';
import { FormattedMessage } from 'react-intl';

const ContinuityPlanInPlace = ({ classes, user, obfuscateView }) => {
  if (get(user, 'business_continuity_state')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.continuity_plan_in_place"/></strong>
        {!obfuscateView && <p>{get(user, 'business_continuity_state')}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default withStyles(styles)(ContinuityPlanInPlace);
