import PropTypes from 'prop-types';
import React from 'react';
import { Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '../ToggleButton/ToggleButton';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { startCase, groupBy } from 'lodash';

const UpdateFeatureMutation = gql`
  mutation updateFeatures($id: ID, $feature_name: String, $value: Boolean) {
    updateFeatures(id: $id, feature_name: $feature_name, value: $value)
  }
`;

const styles = theme => ({
  root     : {
    flexGrow : 0,
    width    : '100%',
    margin   : 12,
    display  : 'block'
  },
  paper    : {
    padding : 12,
  },
  control  : {
    padding : theme.spacing.unit * 2,
  },
  title    : {
    fontSize : 18,
    color    : '#fff',
    margin   : 12,
  },
  features : {
    display       : 'block',
    flexDirection : 'column',
  },
  control : {
    width: 400,
  },
});

const toggleFeature = (f, i, company, updateCompany, event) => {
  f.enabled = !f.enabled;

  // Find the index of the object with the target enum value
  const targetIndex = company.data['all_feature_types'].findIndex(item => item.enum_value === i);

  // If the target object was found, replace it with the new object
  if (targetIndex !== -1) {
    company.data['all_feature_types'][targetIndex] = f;
  }

  updateCompany(company);
};

const FeatureForm = ({ company, updateCompany, classes }) => {
  const [ open, setOpen ] = React.useState(false);
  const [ featureName, setName ] = React.useState('');
  const [ featureValue, setValue ] = React.useState(false);
  const handleClickOpen = (name, val) => {
    setName(name);
    setValue(val);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let groupedFeatures = groupBy(company.data.all_feature_types, 'group');
  // sort alphabetically
  Object.keys(groupedFeatures).forEach((key) => {
    groupedFeatures[key] = groupedFeatures[key].sort((a, b) => {
      if (a.feature_type < b.feature_type) {
        return -1;
      }
      if (a.feature_type > b.feature_type) {
        return 1;
      }
      return 0;
    });
  });
  // sort keys alphabetically
  groupedFeatures = Object.keys(groupedFeatures).sort().reduce((r, k) => (r[k] = groupedFeatures[k], r), {});
  return (
    <div style={{width: '100%'}}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Typography type="h1" className={classes.title}>
            Features
          </Typography>
        </Toolbar>
      </AppBar>
      <Paper className={classes.features}>
        <div style={{display: 'flex', flexFlow: 'row wrap'}}>
          <Mutation mutation={UpdateFeatureMutation}>
            {toggleCompanyFeatureMutation => {
              return groupedFeatures && Object.keys(groupedFeatures).map((group) => (
                <div style={{float: 'left'}}>
                  <h2>
                    {group}
                  </h2>
                  {groupedFeatures[group].map((feature, index) => (<div className={classes.control}>
                    <ToggleButton
                      disabled={feature.feature_type === 'show_county' && company.data.country != 'us'}
                      checked={feature.enabled || false}
                      label={feature.feature_type}
                      onChange={e => {
                        toggleCompanyFeatureMutation({
                          variables : {
                            id           : company.data.id,
                            feature_name : feature.feature_type,
                            value        : !feature.enabled,
                          },
                        }).then(r => {
                          if (r.data.updateFeatures) {
                            handleClickOpen(startCase(feature.feature_type), feature.enabled);
                          }
                        });
                        toggleFeature(feature, feature.index, company, updateCompany, e);
                      }}
                    />
                    <Tooltip title={feature.description}>
                        <span>
                            {' '}<i className="fas fa-info-circle custom-tool-tip" />
                        </span>
                    </Tooltip>
                </div>))}
                <hr/>
              </div>
              ));
            }}
          </Mutation>
        </div>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        message={`${featureName} ${featureValue === true ? 'Enabled' : 'Disabled'}`}
        autoHideDuration={2000}
      />
    </div>
  );
};

FeatureForm.propTypes = {
  company       : PropTypes.object,
  updateCompany : PropTypes.func.isRequired,
  classes       : PropTypes.object.isRequired,
};

FeatureForm.defaultProps = {
  company : {},
};

export default withStyles(styles)(FeatureForm);