import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Toolbar, AppBar } from '@material-ui/core';
import { colors } from '@material-ui/core';
import EngagementsPageContainer from '../../containers/EngagementsPageContainer';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  root       : {
    display : 'flex',
    width   : '100%',
  },
  paper      : {
    marginLeft : '190px',
    width      : '100%',
  },
  title      : {
    fontSize : '24px',
    color    : '#F5FFFA',
  },
  headline   : {
    width : '100%',
  },
  reportarea : {
    margin : 'auto',
    width  : '80%',
    height : '50%',
  },
});

const EngagementsComponentPageFull = ({ classes, data }) => (
  <div className={classes.root}>
    <Paper className={classes.paper}>
      <div className={classes.headline}>
        <div className={classes.reportarea}>
          <EngagementsPageContainer />
        </div>
      </div>
    </Paper>
  </div>
);

export default withStyles(styles)(EngagementsComponentPageFull);
