import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, withStyles, Chip, Select, MenuItem } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';
import { ChevronRight } from '@material-ui/icons';
import OwnershipSelect from './OwnershipSelect';
import StatusSelect from './StatusSelect';
import { tr } from 'date-fns/locale';

const colorsForStatus = {
  open: 'primary',
  pending: 'secondary',
  closed: 'default',
};

const SupportRequestCards = ({ title, supportRequests, loading, classes, children, setCurrentConversation, refetch, managers }) => {
  return (
    <Card className={classes.card}>
      <CardContent className={classes.header}>
        <Typography variant="h5">{title}</Typography>
      </CardContent>
      <CardContent>
        {children}
      </CardContent>
      <CardContent>
        <table className={classes.requests}>
          <tr>
            <th>Name</th>
            <th>Assigned To</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Last Message</th>
          </tr>
          <tbody>
          {supportRequests.map((request) => {
            const status = get(request, 'status', '');
            return (
              <tr
                key={request.id}
                className={classes.request}
                onClick={(e) => {
                    setCurrentConversation(request)
                  }
                }
              >
                <td className={classes.tableCell}>
                  <Typography variant="body2" className={classes.userName}>
                    {get(request, 'user.name', '')}{get(request, 'leadName', '')}
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography variant="body2">
                    <OwnershipSelect supportRequest={request} refetch={refetch} managers={managers} />
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <Typography variant="body2" className={classes.createdAt}>
                    {moment(get(request, 'createdAt', '')).format("MMM, DD, YYYY")}
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <StatusSelect supportRequest={request} refetch={refetch} />
                </td>
                <td className={classes.tableCell}>
                  <Typography variant="body2" className={classes.lastMessage}>
                    {get(request, 'conversation.lastMessages[0].body', '')}
                    {get(request, 'leadMessage', '')}
                  </Typography>
                </td>
                <td className={classes.tableCell}>
                  <ChevronRight className={classes.chevronIcon} />
                </td>

              </tr>
            );
          })}
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

const styles = theme => ({
  card: {
    margin: theme.spacing(2, 0),
    maxHeight: 'calc(100vh - 350px)',
    overflowY: 'scroll',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '4px 4px 0 0',
  },
  requests: {
    overflowY: 'auto',
    width: '100%',
    padding: 8,
  },
  request: {
    padding: '10px',
    borderBottom: '1px solid #dadada',
    cursor: 'pointer',

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  details: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  userName: {
    flex: 1,
  },
  createdAt: {
    flex: 1,
    textAlign: 'right',
  },
  chip: {
    marginLeft: theme.spacing(1),
  },
  lastMessage: {
    flex: 3,
    color: '#333',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(2),
  },
  chevronIcon: {
    marginLeft: theme.spacing(1),
  },
  tableCell: {
    padding: '4px',
  },
});

SupportRequestCards.propTypes = {
  title: PropTypes.string.isRequired,
  supportRequests: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  changeConversation: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default withStyles(styles)(SupportRequestCards);
