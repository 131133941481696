import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { get } from 'lodash';
import ViewCard from './ViewCard';
import EditCard from './EditCard';
import DeleteButton from './DeleteButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class PipelineActivityCard extends React.Component {
  state = { edit: false };

  render () {
    const { activity, pipelineCard, refetch, classes, inventoryUserId } = this.props;
    const { edit } = this.state;

    const typeName = get(activity, 'activityType.name');
    const typeURL =
      get(activity, 'activityType.iconUrl') === null || ''
        ? 'coffee'
        : get(activity, 'activityType.iconUrl').replace('fa-', '');
    let cardContent;
    let userName = get(activity, 'inventoryUser.name', 'A user');
    if (get(activity, 'inventoryUser.id') === inventoryUserId) {
      userName = 'You';
    }

    if (edit) {
      cardContent = (
        <EditCard
          activity={activity}
          pipelineCard={pipelineCard}
          refetch={refetch}
          onCancel={() => this.setState({ edit: false })}
        />
      );
    } else {
      cardContent = <ViewCard activity={activity} />;
    }
    return (
      <Card
        className={classes.root}
        onClick={() => {
          if (!edit) {
            this.setState({ edit: true });
          }
        }}>
        <CardHeader
          classes={{ title: classes.title }}
          className={classes.cardHeader}
          title={`${userName} logged a ${typeName}`}
          avatar={
            <span className={`${classes.avatar}`} style={{ fontSize: 18 }}>
              {' '}
              <FontAwesomeIcon icon={typeURL} />{' '}
            </span>
          }
          action={<span>{edit && <DeleteButton refetch={refetch} activityId={activity.id} />}</span>}
        />
        {cardContent}
      </Card>
    );
  }
}

const styles = {
  root       : {
    margin : '8px 0',
  },
  deleteIcon : {
    opacity : '0.4',
  },
  cardHeader : {
    paddingBottom : 0,
    fontSize      : 18,
  },
  title      : {
    fontSize : 18,
  },
  avatar     : {
    borderRadius    : '50%',
    backgroundColor : '#eee',
    padding         : 8,
  },
};

PipelineActivityCard.propTypes = {
  activity        : PropTypes.object,
  pipelineCard    : PropTypes.object,
  refetch         : PropTypes.func.isRequired,
  classes         : PropTypes.func.isRequired,
  inventoryUserId : PropTypes.string,
};

PipelineActivityCard.defaultProps = {
  activity        : {},
  pipelineCard    : {},
  inventoryUserId : null,
};

export default withStyles(styles)(PipelineActivityCard);
