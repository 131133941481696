import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import FileIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';
import { FormattedMessage  } from 'react-intl';

function FileUploadDropZone({ classes, createDoc, dropMessage, uploading }) {
  const maxSize = 10485760;
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files

    createDoc({ files: acceptedFiles });
  }, []);
  const { getRootProps, getInputProps, isDragActive, rejectedFiles } = useDropzone({ onDrop, maxSize });
  const isFileTooLarge = (rejectedFiles || []).length > 0 && rejectedFiles[0].size > maxSize;
  return (
    <div {...getRootProps()} className={`${classes.root} ${isDragActive ? classes.active : ''}`}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <p> <FileIcon /></p>}
      {uploading && (
        <div>
          <FormattedMessage id="file_tab.uploading" /> <LinearProgress />
        </div>
      )}
      {isFileTooLarge && <div className="text-danger mt-2">File is too large. Limit 10 MB</div>}
    </div>
  );
}

FileUploadDropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  createDoc: PropTypes.func.isRequired,
  dropMessage: PropTypes.string,
};

FileUploadDropZone.defaultProps = {
  dropMessage: "Drag 'n' drop some files here, or click to select files",
};

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#333',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'copy',
  },
  active: {
    backgroundColor: 'rgba(133, 192, 247, 0.4)',
    color: 'grey',
    borderColor: 'blue',
  },
};

export default withStyles(styles)(FileUploadDropZone);
