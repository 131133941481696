import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import ToggleButton from '../ToggleButton/ToggleButton';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
  addButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 200,
  },
  text      : {
    fontSize : '18px',
  },
  title     : {
    fontSize : 24,
    margin   : 12,
  },
});

class TodoItemNewFormDialog extends React.Component {
  state = {
    open : false,
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleRequestClose = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  addRow = action => {
    const { id, todo_item_id, title, description, link_type, link_url, link_title, index } = this.state;

    action({ id, todo_item_id, title, description, link_type, link_url, link_title, index });
  };

  render () {
    const { classes, error, close } = this.props;
    if (close) this.handleRequestClose();

    return (
      <div>
        <Button fab color="primary" aria-label="add" className={classes.addButton} onClick={this.handleClickOpen}>
          <AddIcon />
        </Button>
        <Dialog open={this.state.open} onRequestClose={this.handleRequestClose}>
          <DialogTitle className={classes.text}>Add Todo Item</DialogTitle>
          <DialogContent>
            {[ 'title', 'description', 'link_type', 'link_url', 'link_title', 'index' ].map(id => (
              <TextField
                autoFocus={id === 'title'}
                margin="dense"
                required={true}
                key={id}
                id={id}
                label={id}
                className={classes.textField}
                onChange={e => {
                  this.state[id] = e.target.value;
                }}
                fullWidth
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleRequestClose} className={classes.text} color="primary">
              Cancel
            </Button>
            <Button
              className={classes.text}
              onClick={e => {
                this.addRow(this.props.action);
              }}>
              Add
            </Button>
          </DialogActions>
          {error && <FormHelperText>{error}</FormHelperText>}
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(TodoItemNewFormDialog);
