import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage } from 'react-intl';
import CashFlowChart from './CashFlowChart';
import SellerSellerNote from './SellerSellerNote';
import { injectIntl } from 'react-intl';
import SellerOrdinaryTaxBreakdown from './SellerOrdinaryTaxBreakdown';
import SellerFullTaxBreakdown from './SellerFullTaxBreakdown';
import SellerFullBreakdown from './SellerFullBreakdown';
import SellerOrdinaryBreakdown from './SellerOrdinaryBreakdown';
import CFABanner from './CFABanner';


const SellerPerspective = ({result, classes, intl, lenderUrl, company, query}) => {
  const sellerCashFlow = get(result, 'cashFlow.illustration.sellerCashFlow', []);

  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  
  let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(0);
  if(isNaN(consultingPercentOfDeal)) {
      consultingPercentOfDeal = 0;
  }

  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return <div className="my-properties">
    <span className="cfa_graph">
      <CashFlowChart cashFlow={sellerCashFlow} personType={"Seller"} />
      <br/>
      <br/>
      <br/>
    </span>
    <CFABanner lenderUrl={lenderUrl} company={company} />
    <br/>
    <span className="table-responsive" >
        <table className="table_cfa_table">
            <thead>
              <tr>
                  <th colSpan={sellerCashFlow.length + 1}><FormattedMessage id="calculators.cfa.sellers_erspective"/></th>
              </tr>
            </thead>
            <tbody>
              {showOrdinaryTaxes ? <SellerOrdinaryBreakdown classes={classes} /> : <SellerFullBreakdown classes={classes} />} 

              {showOrdinaryTaxes ? <SellerOrdinaryTaxBreakdown classes={classes} /> : <SellerFullTaxBreakdown classes={classes} />} 
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.after_tax_net_income"/></strong></td>
                  {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.afterTaxNetIncome).format(dollarFormat)}</b></td>))}
              </tr>
              <tr>
                {sellerCashFlow.map((v) => (<td className={classes.gap}></td>))}
              </tr>
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.debt_service"/></strong></td>
                  <td></td>
              </tr>
              <tr>
                <td>Transition Assistance Loan Repayment</td>
                {sellerCashFlow.map((v) => (<td style={{textAlign: 'right', color: 'red',}}>{numeral(v.transitionAssistanceLoanRepayment).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              {showOrdinaryTaxes ? 
              <tr>
                <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.free_cash_flow"/></strong></td>
                {sellerCashFlow.map((v) => {
                  let freeCashFlow = v.afterTaxNetIncome - v.transitionAssistanceLoanRepayment;
                  return (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>
                          <b>
                            {numeral(freeCashFlow).format(dollarFormat)}
                          </b>
                        </td>);
                  })}
              </tr>
              :
              <tr>
                  <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.free_cash_flow"/></strong></td>
                  {sellerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}><b>{numeral(v.freeCashFlow).format(dollarFormat)}</b></td>))}
              </tr>}
              <tr>
                <td><strong>Cumulative Proceeds</strong></td>
                {sellerCashFlow.map((v) => (<td style={{textAlign: 'right', fontWeight: 'bold'}}>{numeral(v.cumulativeProceeds).format(dollarFormat)}</td>))}
              </tr> 
            </tbody>
          </table>
        </span>
      </div>
};

const styles = {
  highlightedRow: {
    borderTop: '3px solid black !important',
    borderBottom: '3px solid black !important',
  },
  lineTop: {
    borderTop: '3px solid black !important',
  },
  gap: {
    height: 1,
    display: 'table-row',
  },
  lineBottom: {
    borderBottom: '3px solid black !important',
  },
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    lenderUrl: get(state, 'notes.lender_url'),
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(SellerPerspective))
);