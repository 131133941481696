import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from 'react-intl';
import CashFlowChart from "./CashFlowChart";
import BuyerTaxBreakdown from './BuyerTaxOrdinaryBreakdown';
import CFABanner from './CFABanner';

const BuyerPerspective = ({result, query, index, classes, intl, lenderUrl, company}) => {
  const buyerCashFlow = get(result, `cashFlow.illustration.buyer${index}CashFlow`, []);
  let buyBackYear = -1;

  buyBackYear = get(result, `cashFlow.buyer${index}BreakEven`);
  
  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');
  const showConsultingAgreement = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  const showBranchExpenses = get(company, 'features', []).includes('cashflow_branch_expenses');

  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return <div className="my-properties">
    <div className="cfa_graph_buyer">
      <CashFlowChart cashFlow={buyerCashFlow} personType={`Buyer ${index}`} />
      <br/>
      <br/>
      <br/>
    </div>
    <CFABanner lenderUrl={lenderUrl} company={company} />

    {buyBackYear > 0 && <div>
      <div className={classes.buyBack} style={{display: 'inline-block', width: 10, height:10}}></div> * Break even year 
      </div>}
    <span className="table-responsive">
        <table className="table" >
            <thead>
              <tr>
                  <th colSpan={buyerCashFlow.length + 1}><FormattedMessage id="calculators.cfa.buyer_perspective" values={{index}}/></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.year"/></strong></td>
                  {buyerCashFlow.map((v, i) => (<td style={{textAlign: 'right'}} className={(buyBackYear === i + 1) ? classes.buyBack : ''}><strong>{i + 1}{(buyBackYear === i + 1) ? '*' : ''}</strong></td>))}
              </tr>
              <tr>
                  <td><i><FormattedMessage id="calculators.cfa.revenue_growth"/></i></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.revenueGrowth).format(percentFormat)}</td>))}
              </tr>
              <tr>
                  <td><FormattedMessage id="calculators.cfa.personal_production"/></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.personalProduction).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td><FormattedMessage id="calculators.cfa.acquired_production"/></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.aquiredProduction).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                 <td><strong><FormattedMessage id="calculators.cfa.total_production"/></strong></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.totalProduction).format(dollarFormat)}</b></td>))}
              </tr>
              <tr>
                  <td>{get(result, 'company.cashflow_payout_word')} Rate*</td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.payoutRate).format(intl.locale === 'en' ? '0.0%' : '0.0 %')}</td>))}
              </tr>
              <tr>
                  <td>{get(result, 'company.cashflow_payout_word')}</td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.payout).format(dollarFormat)}</td>))}
              </tr>
              { document.location.href.match('rja') && <tr>
                <td><strong><FormattedMessage id="calculators.cfa.retirement_choice"/></strong></td>
                <td></td>
              </tr>}
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.book_buy_payment_total"/></strong></td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red',textAlign: 'right'}}>{numeral(v.bookBuyPaymentTotal).divide(-1).format(dollarFormat)}</td>))}
              </tr>

              <tr>
                  <td style={{paddingLeft: 5}}><FormattedMessage id="calculators.cfa.fixed_payment"/></td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red',textAlign: 'right'}}>{numeral(v.fixedPayment).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="calculators.cfa.contingent_participation_payment" />
                </td>
                {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.contingentPayment).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              {showCheckSwap && <React.Fragment>
                <tr>
                <td><strong>Check Swap</strong></td>
                  <td></td>
                </tr>
                <tr>
                  <td><FormattedMessage id="calculators.cfa.transition_assistance_bonus"/></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.transitionAssistanceBonus).format(dollarFormat)}</td>))}
                </tr>
                { showBranchExpenses && <>
                  <tr>
                      <td><strong>Branch Expenses</strong></td>
                      {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.branchExpenses).format(dollarFormat)}</td>))}
                  </tr>
                </>}
              </React.Fragment>}
              {/* show consulting_agreement */}
              {showConsultingAgreement && <tr>
                  <td>Consulting Agreement</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.consultingAgreement).format(dollarFormat)}</td>))}
              </tr>}
              <tr>
                  <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.pretax_net_income"/></strong></td>
                  {buyerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>{numeral(v.pretaxNetIncome).format(dollarFormat)}</td>))}
              </tr>
              <BuyerTaxBreakdown buyerCashFlow={buyerCashFlow} classes={classes} />
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.after_tax_net_income"/></strong></td>
                  {buyerCashFlow.map((v) => (<td style={{textAlign: 'right'}}><b>{numeral(v.afterTaxNetIncome).format(dollarFormat)}</b></td>))}
              </tr>
              <tr>
                  <td><FormattedMessage id="calculators.cfa.lump_sum_payment_pocket"/></td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.lumpSumPaymentOutOfPocket).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td><strong><FormattedMessage id="calculators.cfa.debt_service"/></strong></td>
                  <td></td>
              </tr>
              <tr>
                  <td>Transition Assistance Loan Repayment</td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.transitionAssistanceBonus).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              <tr>
                  <td>
                    { document.location.href.match('icd') ?
                      <FormattedMessage id="calculators.cfa.book_buy"/> :
                      <FormattedMessage id="calculators.cfa.transition_assistance_loan_repayment"/>
                    }
                  </td>
                  {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.successionLoanRepayment).divide(-1).format(dollarFormat)}</td>))}
              </tr>
              { showSellerNote && <tr>
                <td>Seller Note</td>
                {buyerCashFlow.map((v) => (<td style={{color: 'red', textAlign: 'right'}}>{numeral(v.sellerNotePayment).format(dollarFormat)}</td>))}
              </tr>}
              <tr>
                {buyerCashFlow.map((v) => (<td className={classes.gap}></td>))}
              </tr>
              <tr>
                  <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.free_cash_flow"/></strong></td>
                  {buyerCashFlow.map((v) => {
                    let freeCashFlow = parseFloat(v.afterTaxNetIncome) - parseFloat(v.lumpSumPaymentOutOfPocket) - parseFloat(v.successionLoanRepayment) + parseFloat(v.sellerNotePayment) - parseFloat(v.transitionAssistanceBonus);
                    return (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}>
                            <b>
                              {numeral(freeCashFlow).format(dollarFormat)}
                            </b>
                          </td>)
                  })}
              </tr>
              <tr>
                  <td className={classes.highlightedRow}>&nbsp;&nbsp;&nbsp;<i><FormattedMessage id="calculators.cfa.debt_service_coverage_ratio"/></i></td>
                  {buyerCashFlow.map((v) => (<td className={classes.highlightedRow} style={{textAlign: 'right'}}>{numeral(v.debtServiceCoverageRatio).format('0.00')}</td>))}
              </tr>
              <br/>
            </tbody>
          </table>
        </span>
      </div>
};

const styles = {
  highlightedRow: {
    borderTop: '3px solid black !important',
    borderBottom: '2px solid black !important',
  },
  lineTop: {
    borderTop: '3px solid black !important',
  },
  gap: {
    height: 1,
    display: 'table-row',
  },
  lineBottom: {
    borderBottom: '3px solid black !important',
  },
  buyBack: {
    backgroundColor: '#98c1d9',
  }
}

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    lenderUrl: get(state, 'notes.lender_url'),
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(injectIntl(BuyerPerspective))
);