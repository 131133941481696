import * as api from '../lib/api';
import { AUTHORIZE_PENDING_USER, DELETE_PENDING_UNAUTHORIZED_USER } from '../constants/pendingAuthorizedUsersConstants';

export const authorizePendingAuthorizedUser = (user) => {
  const user_id = user.id;
  return (dispatch) => {
    dispatch({type: AUTHORIZE_PENDING_USER, pending_authorized_users: {}, loading: true});
    api.get(`/admin/pending_authorized_users/authorize_user.json?id=${user_id}`).then((response) => {
      dispatch({
        type: AUTHORIZE_PENDING_USER,
        pending_authorized_users: response.data,
        user_id: user_id,
        loading: false
      });
    });
  }
};

export const deletePendingUnauthorizedUser = (user) => {
  let deletedUser = user;
  const user_id = user.id;
  deletedUser["__destroy"] = true;
  return (dispatch) => {
    dispatch({type: DELETE_PENDING_UNAUTHORIZED_USER, user:null, error:null, close:false, loading: true});
    api.del(`/admin/pending_authorized_users/destroy.json?id=${user_id}`).then((response) => {
      dispatch({
        type: DELETE_PENDING_UNAUTHORIZED_USER,
        user_id: user_id,
        error: response.error,
        loading: false,
      });
      setInterval(() => {
        dispatch({
          type: DELETE_PENDING_UNAUTHORIZED_USER,
          error: null,
          loading: false,
        });
      }, 8000);
    });
  }
}
