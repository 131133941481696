import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NotebookEditor from '../NotebookEditor/NotebookEditor';
import { trackAnalyticsEvent } from '../../lib/analytics';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

const addNoteForIU = gql`
  mutation addNoteForIU($id: ID!, $text: String!) {
    addNoteForIU(id: $id, text: $text)
  }
`;

class AdminNotebookDrawerContainer extends Component {
  render = () => {
    const { id, onSave, mutate } = this.props;
    return <NotebookEditor userName={id} onSave={onSave} mutate={mutate} />;
  };
}

AdminNotebookDrawerContainer.propTypes = {
  user        : PropTypes.object.isRequired,
  currentUser : PropTypes.object,
  opened      : PropTypes.bool,
};

export default graphql(addNoteForIU)(AdminNotebookDrawerContainer);
