import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const DefinedServiceModel = ({ classes, dealInterest }) => {
  if (get(dealInterest, 'inventory_user.user')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.defined_service_model"/></strong>
        <p>{get(dealInterest, 'inventory_user.user.defined_service_model') ? <FormattedMessage id="profile_page.yes_discretionary_investment_models"/> : <FormattedMessage id="profile_page.no_discretionary_investment_models"/>}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default injectIntl(withStyles(styles)(DefinedServiceModel));
