import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Grid } from '@material-ui/core';
import client from '../../lib/apolloClient';
import SuggestedDealModalContainer from '../../containers/SuggestedDealModalContainer';
import SuggestedDealCard from './SuggestedDealCard';

const deleteSuggestedDealMutation = gql`
  mutation deleteSuggestedDeal($id: ID!) {
    deleteSuggestedDeal(id: $id)
  }
`;

class SuggestedDealsPage extends React.Component {
  render () {
    const {
      classes,
      suggestedDeals,
      suggestedDeal,
      updateSuggestedDealsList,
      updateSuggestedDeal,
      updateSelectedSuggestedDeal,
      agentWordForCompany,
      owner,
    } = this.props;
    return (
      <ApolloProvider client={client}>
        <Mutation mutation={deleteSuggestedDealMutation}>
          {deleteSuggestedDeal => (
            <div className={classes.page}>
              <SuggestedDealModalContainer
                suggestedDeal={suggestedDeal}
                deleteSuggestedDeal={deleteSuggestedDeal}
                closeAddDealDialog={() => this.closeAddDealDialog()}
                updateSuggestedDealsList={updateSuggestedDealsList}
                updateSuggestedDeal={updateSuggestedDeal}
                updateSelectedSuggestedDeal={updateSelectedSuggestedDeal}
                owner={owner}
              />
              <Grid xs={12}>
                <div className={classes.subTitle}>Add Suggested Deals</div>
              </Grid>
              <Grid container justify="left" spacing={3}>
                {suggestedDeals.map(deal => (
                  <Grid item xs={4}>
                    <div style={{ height: 220 }}>
                      <SuggestedDealCard
                        deal={deal}
                        suggestedDeals={suggestedDeals}
                        deleteSuggestedDeal={deleteSuggestedDeal}
                        addDeal={() => this.addDeal(deal)}
                        updateSuggestedDealsList={updateSuggestedDealsList}
                        updateSuggestedDeal={updateSuggestedDeal}
                        updateSelectedSuggestedDeal={updateSelectedSuggestedDeal}
                        agentWordForCompany={agentWordForCompany}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </Mutation>
      </ApolloProvider>
    );
  }
}

SuggestedDealsPage.propTypes = {
  suggestedDeals              : PropTypes.object,
  suggestedDeal               : PropTypes.object.isRequired,
  updateSelectedSuggestedDeal : PropTypes.func.isRequired,
  pipelineList                : PropTypes.object.isRequired,
  currentDeal                 : PropTypes.object.isRequired,
  updateSuggestedDealsList    : PropTypes.func.isRequired,
  updateSuggestedDeal         : PropTypes.func.isRequired,
};

SuggestedDealsPage.defaultProps = {
  suggestedDeals : [],
};

const styles = {
  page          : {
    width      : '100%',
    height     : 'calc(100vh - 100px)',
    marginTop  : 15,
    overflowY  : 'auto',
    overflowX  : 'hidden',
    background : '#F7F9F9',
  },
  name          : {
    width : '85%',
  },
  nameField     : {
    fontWeight : 500,
  },
  avatar        : {
    margin : '0px 10px 0px 15px',
  },
  text          : {
    textAlign : 'left',
  },
  row           : {
    marginTop    : 5,
    marginBottom : 5,
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  subTitle      : {
    fontSize     : 20,
    fontWeight   : 500,
    marginLeft   : 15,
    marginBottom : 15,
    color        : '#444',
  },
};

export default withStyles(styles)(SuggestedDealsPage);
