import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { injectIntl } from 'react-intl';
class FindbobDatePicker extends React.Component {
  // Leaving the monthsInWords array for future use
  // monthsInWords = {
  //   Jan  : 31,
  //   Feb  : 28,
  //   Mar  : 31,
  //   Apr  : 30,
  //   May  : 31,
  //   June : 30,
  //   July : 31,
  //   Aug  : 31,
  //   Sept : 30,
  //   Oct  : 31,
  //   Nov  : 30,
  //   Dec  : 31,
  // };

  months = {
    1  : 31,
    2  : 28,
    3  : 31,
    4  : 30,
    5  : 31,
    6  : 30,
    7  : 31,
    8  : 31,
    9  : 30,
    10 : 31,
    11 : 30,
    12 : 31,
  };

  nullState = {
    day   : null,
    month : null,
    year  : null,
  };

  state = { ...this.nullState };

  componentWillMount () {
    const { date } = this.props;

    this.setDefaultDate(date);
  }

  componentWillReceiveProps (nextProps) {
    const { date } = this.props;

    if (date !== nextProps.date) {
      this.setDefaultDate(nextProps.date);
    }
  }

  setDefaultDate = date => {
    const { yearOnly } = this.props;
    if (date) {
      const momentDate = moment(date, 'YYYY-MM-DD');

      this.setState({ day: momentDate.date(), month: parseInt(momentDate.month() + 1, 10), year: momentDate.year() });
    } else if (yearOnly) {
      const momentDate = moment('2000-01-01', 'YYYY-MM-DD');
      this.setState({ day: momentDate.date(), month: parseInt(momentDate.month() + 1, 10), year: null });
    } else {
      this.setState({ ...this.nullState });
    }
  };

  validateDate = date => {
    try {
      return date === moment(date, 'YYYY-M-D').format('YYYY-M-D');
    } catch (e) {
      return false;
    }
  };

  onDateChange = datePart => {
    const { getDate } = this.props;
    const dateObject = { ...this.state, ...datePart };
    const { day, month, year } = dateObject;
    const date = `${year}-${month}-${day}`;
    if (day && month && year) {
      if (this.validateDate(date)) {
        getDate(moment([ year, month, day ], 'YYYY-MM-DD').format('YYYY-MM-DD'));
      } else {
        return getDate('Invalid Date');
      }
    }
    return '';
  };

  getDay = currentMonth => {
    let numOfDays = 31;
    if (currentMonth) {
      numOfDays = this.months[currentMonth];
    }
    const days = [];
    for (let i = 1; i <= numOfDays; i += 1) {
      days.push(i);
    }
    return days;
  };

  getYears = () => {
    const { maxYear, minYear } = this.props;
    const year = [];
    for (let i = maxYear; i >= minYear; i -= 1) {
      year.push(i);
    }
    return year;
  };

  render () {
    const { intl, classes, ascYears, yearOnly } = this.props;
    const { day, month, year } = this.state;
    const monthArray = Object.keys(this.months);
    const dayArray = this.getDay(month);
    const years = this.getYears();

    return (
      <div>
        {!yearOnly && (
          <span>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="month-simple">{intl.formatMessage({ id: 'date_picker.month' })}</InputLabel>
              <Select
                inputProps={{
                  name : 'month',
                  id   : 'month-simple',
                }}
                value={`${month}`}
                onChange={e => {
                  this.setState({ month: e.target.value });
                  this.onDateChange({ month: e.target.value });
                }}>
                {monthArray.map(m => (
                  <MenuItem key={`month_${m}`} value={m}>
                    {m}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="day_input">{intl.formatMessage({ id: 'date_picker.day' })}</InputLabel>
              <Select
                inputProps={{
                  name : 'day',
                  id   : 'day_input',
                }}
                value={`${day}`}
                onChange={e => {
                  this.setState({ day: e.target.value });
                  this.onDateChange({ day: e.target.value });
                }}>
                {dayArray.map(d => (
                  <MenuItem key={`day_${d}`} value={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </span>
        )}
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="month-year_input">
            {yearOnly ? '' : intl.formatMessage({ id: 'date_picker.year' })}
          </InputLabel>
          <Select
            inputProps={{
              name : 'year',
              id   : 'year_input',
            }}
            value={`${year}`}
            onChange={e => {
              this.setState({ year: e.target.value });
              this.onDateChange({ year: e.target.value });
            }}>
            {(ascYears ? years.reverse() : years).map(y => (
              <MenuItem key={`year_${y}`} value={y}>
                {y}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

FindbobDatePicker.propTypes = {
  getDate  : PropTypes.func.isRequired,
  maxYear  : PropTypes.number.isRequired,
  minYear  : PropTypes.number.isRequired,
  intl     : PropTypes.object.isRequired,
  date     : PropTypes.string,
  classes  : PropTypes.object.isRequired,
  ascYears : PropTypes.bool,
  yearOnly : PropTypes.bool,
};

FindbobDatePicker.defaultProps = {
  date     : null,
  ascYears : false,
  yearOnly : false,
};

const styles = theme => ({
  formControl : {
    margin   : theme.spacing.unit,
    minWidth : 120,
  },
});

export default withStyles(styles)(injectIntl(FindbobDatePicker));
