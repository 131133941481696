import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const Availability = ({ classes, dealInterest, agentWordForCompany }) => {
  if (get(dealInterest, 'proposal_meeting_availability')) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.availability"/></strong>
        <p>{get(dealInterest, 'proposal_meeting_availability')}</p>
      </div>
    );
  }
  return <div />;
};

const styles = {
  status : {
    margin : '0px 0px 15px 25px',
    width  : 170,
  },
};

export default withStyles(styles)(Availability);
