import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router';
import { withSnackbar } from 'notistack';
import DealStepper from '../components/DealWizard/DealStepper';
import DealTeaser from '../components/DealTeaser';
import { UpdateDealMutation } from '../components/Deals/DealQueries';
import BackToContinuityButton from '../components/DealWizard/BackToContinuityButton';
import { get, capitalize, lowerCase } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, injectIntl } from 'react-intl';

const DealQuery = gql`
  query DealQuery($name: String!) {
    getDeal(name: $name) {
      id
      headline
      sub_headline
      list_price
      invitation_deadline
      number_of_invites
      number_of_proposals
      number_of_ndas
      number_of_negotiations
      matches_count
      deal_state
      deal_name
      summary_of_business
      ideal_candidate
      growth_opportunities
      assets_included
      intangible_assets_included
      continuity_builder_id
      customer_demographics_comments
      products_and_services_comments
      inventory_user {
        name
        branch
        user {
          name
          division
          city
          province
          assets_under_management
          number_of_clients
          years_practicing
          avg_client_age
          number_of_clients
          niche_market
          year_1_gross
          year_2_gross
          year_3_gross
          year_4_gross
          transition_period_months
          annual_revenue
          gross_rev_detail_total_revenue
          recurring_revenue
          percent_commission
          percent_fee_based
          gross_production
          team_size
          fee_based_revenue
          trailer_revenue
          direct_revenue
          transactional_revenue
          tag_list
          primary_markets
          specialized_businesses
          discretionary_investment_models
          why_values
          business_continuity_state_id
          company {
            id
            business_type
          }
          product_mix {
            life_ifp
            home_ifp
            auto_ifp
            commercial_ifp
            mutual_funds_aum
            securities_aum
            mcpi_ifp
            private_products_ifp
            crop_hail_ifp
            farm_insurance_ifp
            price_products_ifp
            replant_supplement_ifp
            gic_fixed_income
            insurance
            equity
          }
        }
      }
    }
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
      }
      company {
        id
        name
        stylesheet_name
        business_type
        teaser_hide_business_overview_question
        teaser_hide_assets_question
        deal_room_branded_name
      }
    }
  }
`;

class DealReviewContainer extends React.Component {
  state = { deal: {}, company: {}, redirect: null, loaded: false, openDialog: false };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  render () {
    const { match: { params }, enqueueSnackbar, classes, intl } = this.props;
    const { deal, company, redirect, loaded, openDialog } = this.state;
    const dealRoomNameFr = intl.formatMessage({ id: "deal_page.deal_room"});
    const updateWord =
      get(deal, 'deal_state', '') === 'published'
        ? intl.formatMessage({ id: 'dr_wizard.publish' })
        : intl.formatMessage({ id: 'dr_wizard.create' });
    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    if (window && !loaded) {
      this.setState({ loaded: true }, window.scrollTo(0, 0));
    }

    return (
      <Query query={DealQuery} variables={{ name: params.dealID }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return 'loading';
          if (error) return 'error';
          if (data && data.getDeal && !deal.id) {
            this.setState({ deal: data.getDeal, company: data.getCurrentUser.company });
            return <div />;
          }
          const stylesheetName = get(data, 'getCurrentUser.company.stylesheet_name');
          const dealRoomName = get(company, 'deal_room_branded_name', 'Deal Room');
          return (
            <Mutation mutation={UpdateDealMutation}>
              {updateDealMutation => (
                <div>
                  <Fragment>
                    <br />
                    <DealStepper
                      activeStep={4}
                      dealID={deal.deal_name}
                      stylesheetName={stylesheetName}
                      updateWord={updateWord}
                    />
                    <h2 style={{ textAlign: 'left' }}>
                      {intl.locale == 'fr' ? (<FormattedMessage id="dr_wizard.review_and_create_update" values={{updateWord: lowerCase(updateWord), dealRoomName: dealRoomNameFr }}/>) : (<FormattedMessage id="dr_wizard.review_and_create_update" values={{updateWord: updateWord, dealRoomName}}/>)}
                    </h2>
                    <p>
                      {intl.locale == 'fr' ? (<FormattedMessage id="dr_wizard.its_time_to_create_deal_room" values={{updateWord: lowerCase(updateWord), dealRoomName: dealRoomNameFr}}/>) : (<FormattedMessage id="dr_wizard.its_time_to_create_deal_room" values={{updateWord: updateWord, dealRoomName}}/>)}
                    </p>
                    <p>
                      <strong>
                        <FormattedMessage id="dr_wizard.note" />
                      </strong>
                      &nbsp;&nbsp; 
                      {intl.locale === 'fr' ? (<FormattedMessage id="dr_wizard.you_have_total_control" values={{dealRoomName}}/>) : (<FormattedMessage id="dr_wizard.you_have_total_control" values={{dealRoomName: dealRoomNameFr}}/>)}
                    </p>
                    <br />
                    <DealTeaser deal={deal} company={company} />
                    <center style={{ margin: '20px auto' }}>
                      <BackToContinuityButton deal={deal} stylesheetName={stylesheetName} />
                      <span>&nbsp;&nbsp;</span>
                      <Button
                        variant="contained"
                        onClick={() => {
                          window.location.href = '/deal/wizard';
                        }}>
                        <FormattedMessage id="dr_wizard.make_changes" />
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState({ openDialog: true });
                        }}>
                        {intl.locale === 'fr' ? (`${capitalize(updateWord)}`) : (`${capitalize(updateWord)} ${dealRoomName}`)}
                      </Button>
                    </center>
                  </Fragment>
                  <Dialog open={openDialog} onClose={() => this.handleClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle>
                      <b>{intl.locale === 'fr' ? (`${capitalize(updateWord)}`) : (`${capitalize(updateWord)} ${dealRoomName}`)}</b>
                    </DialogTitle>
                    <DialogContent>
                      {intl.locale === 'fr' ? (<FormattedMessage id="dr_wizard.once_you_approve_this" values={{dealRoomNameFr}}/>) : (<FormattedMessage id="dr_wizard.once_you_approve_this" values={{dealRoomName: dealRoomName}}/>)}
                    </DialogContent>
                    <div>
                      <center style={{ margin: '20px auto' }}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            this.setState({ openDialog: false });
                          }}>
                          <FormattedMessage id="shared.cancel" />
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            if (deal.deal_state === 'published') {
                              window.location.href = `/deal/${deal.deal_name}/evaluate_matches`;
                            } else {
                              updateDealMutation({
                                variables : {
                                  id              : deal.id,
                                  deal_state      : 'published',
                                  approval_status : true,
                                },
                              }).then(() => {
                                enqueueSnackbar(intl.locale === 'fr' ? (<FormattedMessage id="dr_wizard.once_you_approve_this" values={{dealRoomName}}/>) : (<FormattedMessage id="dr_wizard.once_you_approve_this" values={{dealRoomName: dealRoomNameFr}}/>), {
                                  variant      : 'success',
                                  anchorOrigin : {
                                    vertical   : 'top',
                                    horizontal : 'center',
                                  },
                                });
                                window.location.href = `/deal/${deal.deal_name}/evaluate_matches`;
                              });
                              this.setState({ openDialog: false });
                            }
                          }}>
                          {intl.locale === 'fr' ? (`${capitalize(updateWord)}`) : (`${capitalize(updateWord)} ${dealRoomName}`)}
                        </Button>
                      </center>
                    </div>
                  </Dialog>
                </div>
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    match_preference : state.match_preference,
  };
};

DealReviewContainer.propTypes = {
  enqueueSnackbar : PropTypes.func.isRequired,
  match           : PropTypes.object.isRequired,
};

const styles = theme => ({
  root      : {
    display       : 'flex',
    flexDirection : 'column',
    padding       : '24px 12px',
    margin        : 'auto',
  },
  button    : {
    margin  : '18px 8px',
    padding : 8,
    width   : 100,
  },
  title     : {
    textAlign : 'left',
  },
  subTitle  : {
    textAlign    : 'left',
    paddingLeft  : 20,
    paddingRight : 20,
  },
  subHeader : {
    fontSize : 18,
  },
});

export default injectIntl(withStyles(styles)(connect(mapStateToProps)(withSnackbar(DealReviewContainer))));
