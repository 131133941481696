import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { get, debounce, map, find } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import FBTable from '../FBTable/FBTable';
import { deleteCalibrationSessionMutation } from './CalibrationQuery';
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

class CalibrationSessions extends React.Component {
  changePage = debounce(vars => {
    const { refetch, updateState, page, userID } = this.props;

    const variables = { page, userID, ...vars };

    refetch({ ...variables })
      .then(response => {
        if (response.data) {
          updateState({ sessions: response.data.getCalibrationSessions, ...variables });
        }
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, 300);

  constructor (props) {
    super(props);
    this.state = { selectedCalibrations: [] };
  }

  loadBulkItemsList = list => {
    this.setState({ selectedCalibrations: list });
  };

  bulkEditItems = () => {
    const { selectedCalibrations } = this.state;
    return { data: selectedCalibrations };
  };

  render () {
    const {
      data,
      refetch,
      updateState,
      filteredColumns,
      page,
      columns,
      filterList,
      orderBy,
      order,
      history,
      agentWordForCompany,
      loading,
      rowsPerPage,
      count,
    } = this.props;
    return (
      <div>
        <div style={{ textAlign: 'center' }}>{loading && <CircularProgress />}</div>
        <Mutation mutation={deleteCalibrationSessionMutation}>
          {deleteSession => (
            <FBTable
              data={data}
              columns={filteredColumns}
              allColumns={columns}
              hideColumnSelect
              showFilterDialog={false}
              rowsPerPage={rowsPerPage}
              filterList={filterList}
              page={page}
              order={order}
              orderBy={orderBy}
              allowDelete
              loadBulkItemsList={list => this.loadBulkItemsList(list)}
              bulkEditItems={this.bulkEditItems()}
              onDelete={taskId => {
                deleteSession({ variables: taskId }).then(() => {
                  refetch().then();
                });
              }}
              rowClick={(r, t) => {
                if (r.target.type === 'checkbox') return;
                if (t.completedPopulating === false) return;
                
                history.push(`/admin/calibration_sessions/${t.id}`);
              }}
              count={count || 0}
              onSort={(e, colName) => {
                let newOrder = 'desc';

                if (orderBy === colName && order === 'desc') {
                  newOrder = 'asc';
                }

                this.changePage({ orderBy: colName, order: newOrder });
              }}
              changePage={(r, p) => {
                this.changePage({ page: p });
              }}
              changeRowsPerPage={rowsPerPage => {
                this.changePage({ rowsPerPage });
              }}
              onFilterChange={(r, m) => {
                const list = filterList || [];
                list[r.id] = m;
                const compactedList = {};
                map(list, (v, k) => {
                  const ransack_filter = get(find(columns, [ 'id', k ]), 'ransack_filter');
                  if (ransack_filter) {
                    return (compactedList[ransack_filter] = v);
                  }
                  return null;
                });

                this.changePage({ ...compactedList, filterList: list });
              }}
              onSearch={r => {
                this.changePage({ name_cont: r });
                updateState({ name_cont: r });
              }}
              bulkEditDialog={r => {}}
              updateColumns={r => {
                updateState({ filteredColumns: columns.filter(c => r.includes(c.id)) });
              }}
              exportToCSVButton={() => {}}
            />
          )}
        </Mutation>
        <div style={{ position: 'absolute', bottom: 145, right: 32 }}>
          <Button href={`/admin/calibration_sessions/new`} variant="contained" color="primary" aria-label="add">
            <AddIcon /> Add Calibration Session
          </Button>
        </div>
      </div>
    );
  }
}

CalibrationSessions.propTypes = {
  data                : PropTypes.object,
  refetch             : PropTypes.func.isRequired,
  updateState         : PropTypes.func.isRequired,
  filteredColumns     : PropTypes.array,
  page                : PropTypes.number,
  columns             : PropTypes.array,
  filterList          : PropTypes.array,
  orderBy             : PropTypes.string,
  order               : PropTypes.string,
  userID              : PropTypes.number,
  history             : PropTypes.object,
  agentWordForCompany : PropTypes.string,
  loading             : PropTypes.bool.isRequired,
  rowsPerPage         : PropTypes.number.isRequired,
  count               : PropTypes.number.isRequired,
};

CalibrationSessions.defaultProps = {
  data                : {},
  filteredColumns     : [],
  page                : 0,
  columns             : [],
  filterList          : [],
  orderBy             : 'id',
  order               : 'desc',
  userID              : 'all',
  history             : '{}',
  agentWordForCompany : 'agent',
};

export default withRouter(CalibrationSessions);
