import React from 'react';
import { Query, ApolloProvider } from 'react-apollo';
import CircularProgress from '@material-ui/core/CircularProgress';
import gql from 'graphql-tag';
import client from '../lib/apolloClient';
import MyOpenTasks from '../components/Admin/MyOpenTasks';

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      inventory_user {
        id
        name
        profile_picture
        has_user
        user {
          id
        }
      }
    }
  }
`;

const MyOpenTasksContainer = () => (
  <ApolloProvider client={client}>
    <Query query={getCurrentUser}>
      {({ loading, data }) => {
        if (loading) return <CircularProgress />;
        if (data.getCurrentUser) {
          return <MyOpenTasks currentUser={data.getCurrentUser} />;
        }
      }}
    </Query>
  </ApolloProvider>
);

export default MyOpenTasksContainer;
