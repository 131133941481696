import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import FBTableDistanceSearch from '../FBTable/FBTableDistanceSearch';
import { ExportToCSVQuery } from './InventoryUserQueries';
import { graphql } from 'react-apollo';

const handleClick = (e, inventory_users, mutate, filterList) => {
  const rows = inventory_users.rowsPerPage;
  const ord = { orderBy: inventory_users.orderBy, order: inventory_users.order };
  mutate({
    variables : {
      limit      : rows,
      offset     : 0,
      ...ord,
      filterList,
      nameSearch : inventory_users.nameSearch,
      onlyUsers  : inventory_users.onlyUsers,
      distanceSearch : inventory_users.distanceSearch,
      distanceWithin : inventory_users.distanceWithin,
    },
  })
    .then(alert('CSV will be emailed to you shortly'))
    .catch(error => console.log(error));
};

const ExportToCSVButton = ({ inventory_users, mutate, filterList}) => (
  <Tooltip title="Export To CSV">
    <IconButton onClick={e => handleClick(e, inventory_users, mutate, filterList)}>
      <CloudDownloadIcon />
    </IconButton>
  </Tooltip>
);

export default graphql(ExportToCSVQuery)(ExportToCSVButton);
