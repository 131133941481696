/* eslint-disable import/prefer-default-export */

import {
  SELECTED_PROFILE_DRAWER_UPDATE,
  PROFILE_DRAWER_LIST_UPDATE,
  PROFILE_DRAWER_UPDATE,
  FILTER_UPDATE,
  PROFILE_DRAWER_STATUS_UPDATE,
} from '../constants/profileDrawerConstants';

export const updateProfiles = profileDrawers => ({
  type           : PROFILE_DRAWER_LIST_UPDATE,
  profileDrawers,
});

export const updateProfile = profileDrawer => ({
  type          : PROFILE_DRAWER_UPDATE,
  profileDrawer,
});

export const updateSelectedProfileDrawer = selectedProfileDrawer => ({
  type                  : SELECTED_PROFILE_DRAWER_UPDATE,
  selectedProfileDrawer,
});

export const updateFilter = filter => ({
  type   : FILTER_UPDATE,
  filter,
});

export const updateStatus = status => ({
  type   : PROFILE_DRAWER_STATUS_UPDATE,
  status,
});
