import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ExportIcon from "@material-ui/icons/CloudDownload";
import CloseIcon from "@material-ui/icons/Close";
import { get, isEmpty, debounce, map, find } from "lodash";
import moment from "moment";
import numeral from "numeral";
import CircularProgress from "@material-ui/core/CircularProgress";
import ApproveSessionButton from "./ApproveSessionButton";
import FBTableToolbar from "../FBTable/FBTableToolbar";
import InventoryUserList from "./InventoryUserList";
import InventoryUserDrawer from "../InventoryUserDrawer/InventoryUserDrawer";
import CalibrationSessionUserColumns from "./CalibrationSessionUserColumns";
import CalibrationStaticGrid from "./CalibrationStaticGrid";
import ValueColumnSelector from "./ValueColumnSelector";
import {
  updateCalibrationSessionMutation,
  exportCalibrationSessionMutation,
} from "./CalibrationQuery";
import ManualCharts from "./ManualCharts";
import CalculatedCharts from "./CalculatedCharts";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {FormGroup} from "@material-ui/core";
import CalibrationSessionFilterDialog from "./CalibrationSessionFilterDialog";

class CalibrationSession extends PureComponent {
  debouncedCall = debounce((func) => {
    func();
  }, 250);

  constructor(props) {
    super(props);
    this.state = {
      tab: 0,
      showFilterDialog: false,
      selected: [],
      offset: 0,
      inventoryUser: {},
      showManual: true,
    };
  }

  changePage = (vars) => {
    const { refetch, offset, limit, orderBy, order, updateState } = this.props;

    const variables = { offset, limit, orderBy, order, ...vars };
    delete variables.filterList;
    delete variables.selected;
    delete variables.inventoryUser;
    variables.offset =
      variables.page > 0 ? variables.page * variables.limit : 0;

    updateState({ ...variables });
    this.setState({ ...variables });
    refetch({ ...variables }).then(result => {
      const { data } = result;
      if(get(data, "calibrationSession.inventoryUsers", null) != null) {
        updateState({inventoryUsers: get(data, "calibrationSession.inventoryUsers", [])});
      }
    });
  };

  changeTab = (event, tab) => {
    this.setState({ tab });
  };

  toggleFilterDialog = () => {
    const { showFilterDialog } = this.state;
    this.setState({ showFilterDialog: !showFilterDialog });
  };

  toggleManual = () => {
    const { showManual } = this.state;
    this.setState({ showManual: !showManual});
  }

  initializeFilterList = (session, managers, columns) => {
    let currentFilter = {}
    let cnt = 0;
    while (typeof currentFilter === "string" || cnt > 10) {
      currentFilter = JSON.parse(currentFilter);
      cnt += 1;
    }

    const fl = {};
    columns.forEach((col) => {
      const filt = currentFilter[`${col.ransack_filter}`];
      if (col.filterType === "date") {
        if (get(filt, "gte") && get(filt, "lte")) {
          fl[col.id] = [
            moment().diff(filt.lte, "years"),
            moment().diff(filt.gte, "years"),
          ];
        } else {
          fl[col.id] = [];
        }
      } else if (col.filterType === "range") {
        if (get(filt, "gte") && get(filt, "lte")) {
          fl[col.id] = [filt.gte, filt.lte];
        } else {
          fl[col.id] = [];
        }
      } else if (col.filterType === "manager") {
        if (filt) {
          fl[col.id] = filt.map((u) => {
            const man =
              find(managers, (m) => get(m, "inventory_user.id") === u) || {};
            const i_man = get(man, "inventory_user", {});
            return { ...i_man, label: i_man.name, value: i_man.id };
          });
        } else {
          fl[col.id] = [];
        }
      } else {
        fl[col.id] = filt || [];
      }
    });
    this.setState({ filterList: fl });
  };

  render() {
    const {
      session,
      refetch,
      managers,
      inventoryUsers,
      updateState,
      classes,
      tags,
      loading,
      order,
      orderBy,
      limit,
      offset,
      company,
    } = this.props;
    const {
      tab,
      showFilterDialog,
      selected,
      inventoryUser,
      filterList,
      showManual,
    } = this.state;
    const disabled = get(session, "status") === "approved";
    let columns = CalibrationSessionUserColumns({
      updateState: (s) => this.setState(s),
      refetch,
      disabled,
      session,
      company,
    });

    const pncColumnNames = ['health_ifp', 'life_ifp', 'group_ifp', 'mutual_funds_aum', 'securities_aum', 'annuities_aum',
      'segregated_funds_aua', 'mcpi_ifp', 'private_products_ifp', 'crop_hail_ifp', 'price_products_ifp', 'replant_supplement_ifp',
      'farm_insurance_ifp', 'crop_hail_with_wind_ifp', 'annuities_aua']
    // Remove columns specific columns if in columnNames

    if(!company.feature_types.includes('include_branch_division_region_in_calibration')){
      const branchDivisionRegionColumns = ['branch', 'division', 'region'];
     columns = columns.filter((column) => !branchDivisionRegionColumns.includes(column.id))
    }

    if(!company.feature_types.includes('display_p_and_c_values')) {
      columns = columns.filter((column) => !pncColumnNames.includes(column.id))
    }

    if (!filterList && Boolean(get(session, "filterJson"))) {
      this.initializeFilterList(session, managers, columns);
    }
    return (
      <div>
        {inventoryUser && (
          <InventoryUserDrawer
            inventoryUser={inventoryUser}
            showBench={false}
            open={Boolean(inventoryUser)}
            openSnack={() => {}}
            updateInventoryUser={() => {
              updateState({ loading: true });
              this.changePage();
            }}
            onClose={() => {
              this.setState({ inventoryUser: null });
            }}
            updateAction={({ user }) => {
              if (isEmpty(user)) {
                this.setState({ inventoryUser: null });
              }
            }}
            tags={tags}
            company={company}
          />
        )}
        <Mutation mutation={updateCalibrationSessionMutation}>
          {(updateCalibration) => (
            <FBTableToolbar
              heading={() => <span>{session.name}</span>}
              hideColumnSelect
              columns={columns}
              allColumns={columns}
              filterList={filterList}
              filteredColumns={columns}
              hideFilterDialog
              onDistanceSearch={false}
              disableDistanceSearch={true}
              showFilterDialog={showFilterDialog}
              toggleDialog={this.toggleFilterDialog}
              numSelected={selected.length}
              onSearch={(r) => {
                this.changePage({
                  name_cont: r,
                });
                this.setState({ name_cont: r });
              }}
              addedIcons={() => (
                <span>
                  <ValueColumnSelector
                    value={session.valueColumnName}
                    onChange={(v) => {
                      updateCalibration({
                        variables: {
                          id: session.id,
                          value_column_name: v.target.value,
                        },
                      }).then(() => {
                        this.setState({ calibratedOn: v.target.value });
                        refetch();
                      });
                    }}
                  />
                  <CalibrationSessionFilterDialog session={session} />
                  <ApproveSessionButton
                    session={session}
                    updateCalibration={updateCalibration}
                    refetch={refetch}
                    inv_users={inventoryUsers}
                    calibrationValue={get(session, "completedInvUserValues", 0)}
                    calibratedOn={session.valueColumnName}
                  />
                  <a
                    href="/admin/calibration_sessions"
                    style={{
                      verticalAlign: "sub",
                      top: 4,
                      position: "relative",
                    }}
                  >
                    <CloseIcon />
                  </a>
                </span>
              )}
              exportToCSVButton={() => (
                <Mutation mutation={exportCalibrationSessionMutation}>
                  {(exportCalibration) => (
                    <IconButton
                      onClick={() => {
                        exportCalibration({
                          variables: { id: session.id },
                        }).then(() => {
                          alert("Email sent");
                        });
                      }}
                    >
                      <ExportIcon />
                    </IconButton>
                  )}
                </Mutation>
              )}
            />
          )}
        </Mutation>
        <div>
          <span className={classes.completeCount}>
            {session.completedInvUserCount} of {session.inventoryUserCount}
          </span>
          <span className={classes.completePercent}>
            (
            {numeral(
              session.completedInvUserCount / session.inventoryUserCount
            ).format("0%")}{" "}
            completed)
          </span>
          <span className={classes.completeCount}>
            {numeral(get(session, "completedInvUserValues", 0)).format("$0a")}
          </span>
          <span className={classes.completePercent}>calibrated</span>
        </div>
        <Tabs
          value={tab}
          onChange={this.changeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Dashboard" />
          <Tab label="List" />
        </Tabs>
        {tab === 0 && (
          <div style={{padding: 40,}}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch checked={!showManual} onChange={this.toggleManual} name="showCalculatedValues" />
                }
                label="Show Calculated Values"
              />
            </FormGroup>
            {showManual ?
              <div>
                <h2>Manual</h2>
                <ManualCharts session={session}/>
              </div>
              :
              <div>
                <h2>Calculated (as of {moment(session.createdAt).format( "MMM d, Y" ) })</h2>
                <CalculatedCharts session={session}/>
              </div>
            }
          </div>
        )}
        {tab === 1 && (
          <InventoryUserList
            data={inventoryUsers || []}
            order={order}
            orderBy={orderBy}
            columns={columns}
            filterList={filterList}
            refetch={refetch}
            page={offset / limit}
            rowsPerPage={limit}
            count={get(session, "inventoryUserCount", 0)}
            changePage={(r, p) => {
              try {
                document
                  .querySelector(".invUserRow")
                  .firstChild.scrollIntoViewIfNeeded();
              } catch {
                // do nothing
              }
              this.changePage({ page: p });
            }}
            changeRowsPerPage={(rowsPerPage) => {
              this.changePage({ limit: rowsPerPage });
            }}
            bulkEditDialog={(r) => {}}
            updateColumns={(r) => {
              this.setState({
                filteredColumns: columns.filter((c) => r.includes(c.id)),
              });
            }}
            exportToCSVButton={() => {}}
            rowClick={(r, i) => {
              if (
                r._dispatchListeners.length > 1 ||
                r.target.type === "checkbox" ||
                (r.target.getAttribute &&
                  (r.target.getAttribute("role") === "button" ||
                    r.target.getAttribute("class") === "userToggle")) ||
                r.target.value
              )
                return;
              if (r.target.innerHTML === i.name) {
                this.setState({ inventoryUser: i });
              }
            }}
            onSort={(e, colName) => {
              let newOrder = "desc";

              if (orderBy === colName && order === "desc") {
                newOrder = "asc";
              }

              this.changePage({ orderBy: colName, order: newOrder });
            }}
            loading={loading}
          />
        )}
      </div>
    );
  }
}

CalibrationSession.propTypes = {
  session: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  managers: PropTypes.object,
  inventoryUsers: PropTypes.object,
  classes: PropTypes.object,
  tags: PropTypes.object,
  loading: PropTypes.bool,
};

CalibrationSession.defaultProps = {
  session: {},
  managers: {},
  inventoryUsers: {},
  classes: {},
  tags: {},
  loading: false,
};

const styles = {
  completeCount: {
    fontSize: 30,
    fontWeight: "400",
    paddingLeft: 24,
  },
  completePercent: {
    fontSize: 16,
    paddingLeft: 6,
  },
};

export default withStyles(styles)(CalibrationSession);
