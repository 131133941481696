/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import qs from 'query-string';
import moment from 'moment';
import { get, startCase } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import InProgressIcon from '@material-ui/icons/DonutSmall';
import CalibrationSessions from '../components/CalibrationSessions/CalibrationSessions';
import { CalibrationQuery } from '../components/CalibrationSessions/CalibrationQuery';

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#ddd',
  },
  barColorPrimary: {
    backgroundColor: '#43a047',
  },
})(LinearProgress);

const columns = () => [
  {
    id: 'name',
    dataType: 'string',
    disablePadding: false,
    label: 'Name',
    filter: false,
    filterType: null,
    customStyle: {
      position: 'sticky',
      left: 55,
      zIndex: 10,
      background: 'white',
    },
    customWidth: 150,
    renderMethod: session => (
      <span style={{ textDecoration: get(session, 'status') === 'completed' ? 'line-through' : 'none' }}>
        {decodeURI(session.name)} { get(session, 'completedPopulating') == false && <span><InProgressIcon/> (Populating)</span>}
      </span>
    ),
  },
  {
    id: 'startDate',
    dataType: 'number',
    disablePadding: false,
    label: 'Start Date',
    filter: false,
    filterType: 'range',
    renderMethod: session => {
      if (get(session, 'startDate')) {
        return moment(get(session, 'startDate')).format('MM/DD/YYYY');
      }
      return '-';
    },
  },
  {
    id: 'endDate',
    dataType: 'number',
    disablePadding: false,
    label: 'End Date',
    filter: false,
    filterType: 'range',
    renderMethod: session => {
      if (get(session, 'endDate')) {
        return moment(get(session, 'endDate')).format('MM/DD/YYYY');
      }
      return '-';
    },
  },
  {
    id: 'status',
    dataType: 'string',
    disablePadding: false,
    label: 'Status',
    filter: true,
    filterType: null,
    filterOptions: [{ name: 'In Progress', value: 'in_progress' }, { name: 'Approved', value: 'approved' }],
    ransack_filter: 'status_eq_any',
    renderMethod: session => startCase(get(session, 'status', '-')),
  },
  {
    id: 'advisors',
    dataType: 'number',
    disablePadding: false,
    label: 'No of Advisors',
    filter: false,
    filterType: 'range',
    renderMethod: session => get(session, 'numberOfAdvisors', '-'),
  },
  {
    id: 'participants',
    dataType: 'number',
    disablePadding: false,
    label: 'Paricipant Count',
    filter: false,
    filterType: 'range',
    renderMethod: session => get(session, 'numberOfParticipants', '-'),
  },
  {
    id: 'facilitators',
    dataType: 'disableSort',
    disablePadding: false,
    label: 'Facilitators',
    filter: false,
    filterType: 'range',
    renderMethod: session => get(session, 'facilitators', []).map(f => f.name).join(', '),
  },
  {
    id: 'completion',
    dataType: 'number',
    disablePadding: false,
    label: 'Completion',
    filter: false,
    filterType: 'range',
    renderMethod: session => (
      <ColorLinearProgress
        variant="determinate"
        value={get(session, 'completedInvUserCount', 0) / get(session, 'numberOfAdvisors', 0) * 100}
      />
    ),
  },
];

class CalibrationSessionsContainer extends React.Component {
  state = {
    filteredColumns: [],
    page: 0,
    sessions: [],
    loading: true,
    filterList: [],
    activity_type_id_in: null,
    name_or_inventory_user_name_or_pipeline_card_name_cont: null,
    userID: '',
    pipelineCardId: null,
    cardDeleted: false,
    order: 'desc',
    orderBy: 'created_at',
    rowsPerPage: 10,
    count: 0,
    nameSearch: '',
  };

  render() {
    const {
      filteredColumns,
      page,
      offset,
      sessions,
      filterList,
      activity_type_id_in,
      name_or_inventory_user_name_or_pipeline_card_name_cont,
      userID,
      currentInventoryUser,
      pipelineCardId,
      cardDeleted,
      rowsPerPage,
      nameSearch,
      count,
      order,
      orderBy,
    } = this.state;
    const params = qs.parse(get(this.props, 'location.search'));
    const { pipeline_card_id } = params;
    if (pipeline_card_id && !pipelineCardId && !cardDeleted) {
      this.setState({ pipelineCardId: pipeline_card_id });
    }

    return (
      <div>
        <Query
          query={CalibrationQuery}
          fetchPolicy="no-cache"
          notifyOnNetworkStatusChange
          variables={{ page, rowsPerPage, order, orderBy }}>
          {({ loading, error, data, refetch }) => {
            if (loading && !this.state.loading) {
              this.setState({ loading: true });
            }

            if (error) return `Error!: ${error}`;
            if (!data) return <div />;
            if (!loading && this.state.loading) {
              const c = columns(refetch, s => this.setState(s));
              const fl = {};
              let inv_user = get(data, 'getCurrentUser.inventory_user', {});
              inv_user = { ...inv_user, label: inv_user.name, value: inv_user.id };

              c.forEach(col => (fl[col.id] = []));
              this.setState({
                sessions: data.getCalibrationSessions,
                loading: false,
                filterList: fl,
                filteredColumns: c,
                currentInventoryUser: inv_user,
                activityTypes: get(data, 'getActivityTypes'),
                page,
                order,
                orderBy,
                rowsPerPage,
                count: data.getCalibrationSessionsCount,
                nameSearch,
              });
            }

            const { activityTypes } = this.state;
            return (
              <div>
                <CalibrationSessions
                  data={sessions}
                  activityTypes={activityTypes}
                  taskCount={get(data, 'getTaskCount')}
                  refetch={refetch}
                  updateState={s => this.setState(s)}
                  columns={columns(refetch)}
                  filteredColumns={filteredColumns}
                  filterList={filterList}
                  page={page}
                  name_or_inventory_user_name_or_pipeline_card_name_cont={
                    name_or_inventory_user_name_or_pipeline_card_name_cont
                  }
                  activity_type_id_in={activity_type_id_in}
                  offset={offset}
                  order={order}
                  orderBy={orderBy}
                  userID={userID}
                  currentUser={get(data, 'getCurrentUser.id')}
                  currentInventoryUser={currentInventoryUser}
                  managers={get(data, 'getManagers')}
                  pipelineCardId={pipelineCardId}
                  agentWordForCompany={get(data, 'agentWordForCompany')}
                  loading={loading}
                  rowsPerPage={rowsPerPage}
                  count={count}
                />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default CalibrationSessionsContainer;
