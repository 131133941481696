/* eslint-disable import/prefer-default-export */

import axios from 'axios';

import { COMPANY_UPDATE, LOAD_COMPANY, SUBMIT_COMPANY } from '../constants/companyConstants';

export const updateCompany = company => ({
  type    : COMPANY_UPDATE,
  company,
});

export const loadCompany = id => {
  return dispatch => {
    dispatch({ type: LOAD_COMPANY, company: {}, loading: true });
    axios
      .get(`/admin/companies/${id}.json`, {
        headers : { 'Content-Type': 'application/json', Accept: 'application/json' },
      })
      .then(response => {
        dispatch({
          type    : LOAD_COMPANY,
          company : response.data,
          loading : false,
        });
      });
  };
};

export const submitCompany = (payload, company, event, enqueueSnackbar) => {
  const csrfToken = ReactOnRails.authenticityToken();
  const config = {
    dataType : 'json',
    headers  : {
      'Content-Type' : 'application/json; charset=UTF-8',
      Accept         : 'application/json',
      'X-CSRF-Token' : csrfToken,
    },
  };

  const location = `/admin/companies/${payload.company.id}.json`;
  return dispatch => {
    dispatch({
      type    : SUBMIT_COMPANY,
      loading : true,
      success : false,
      error   : false,
    });

    axios
      .put(location, payload, config)
      .then(response => {
        updateCompany(company);
        dispatch({
          type    : SUBMIT_COMPANY,
          loading : false,
          success : response.status === 200,
          error   : response.error,
        });
        if (response.error) {
          enqueueSnackbar(`Company failed to send: ${response.error}`, {
            variant      : 'error',
            anchorOrigin : {
              vertical   : 'top',
              horizontal : 'center',
            },
          });
        } else {
          enqueueSnackbar('Saved company success', {
            variant      : 'success',
            anchorOrigin : {
              vertical   : 'top',
              horizontal : 'center',
            },
          });
        }
      })
      .catch(error => {
        dispatch({
          type    : SUBMIT_COMPANY,
          loading : false,
          success : false,
          error   : error.response,
        });
        enqueueSnackbar('Company failed to send', {
          variant      : 'error',
          anchorOrigin : {
            vertical   : 'top',
            horizontal : 'center',
          },
        });
      });
    setInterval(() => {
      dispatch({
        type    : SUBMIT_COMPANY,
        loading : false,
        success : false,
        error   : false,
      });
    }, 5000);
  };
};
