import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { get } from 'lodash';
import Dashboard from '../components/Dashboard/Dashboard';
import * as actions from '../actions/bobCardActionCreators';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import * as notesActions from '../actions/noteActionCreators';

export const DashboardQueries = gql`
  query DashboardQueries {
    getCurrentUser {
      name
      user_name
      roles
      has_selling_transition_goals
      limited_access
      profile_type
      welcome_hide_banner
      welcome_current_interest
      company {
        id
        name
        branded_name
        seller_only_label
        networking_allowed
        business_type
      }
      suggested_user_count
      suggested_users {
        id
        user_name
        annual_revenue
        assets_under_management
        bio
        city
        county
        show_city
        number_of_clients
        profile_picture
        province
        recurring_revenue
        created_at
        headline
        my_rating
        nick_name
        obfuscated
        is_pure_buyer
        transition_goals
        interested_in_selling
        expressed_interest
        profile_type
        years_practicing
        recognition_level
        product_mix {
          life_ifp
          home_ifp
          auto_ifp
          commercial_ifp
          health_ifp
          group_ifp
          mutual_funds_aum
          securities_aum
          annuities_aua
          total_assets
          health_and_life_stars
          aum_stars
          p_c_stars
          group_stars
          gic_fixed_income
          insurance
          equity
        }
        note_count
        conversation_with_me {
          id
        }
      }
    }
    currentUserInfoAuth
    companyHasFeature(featureName: "rolodex")
    companyHasCounty(featureName: "show_county")
    countryForCompany
    companyHasBilling
    companyHasAds
    currentAuctionCount
    currentUserASubscriber
    currentUserAllowed
    agentWordForCompany
    companyBusinessType
  }
`;

const DashboardContainer = props => {
  const {
    bobCards,
    loadBobCardList,
    updateBobCards,
    updateSelectedProfileDrawer,
    profile,
    updateNotes,
    updateProfile,
  } = props;
  return (
    <Query query={DashboardQueries}>
      {({ loading, data }) => {
        if (!loading && bobCards.loaded === false) {
          loadBobCardList({
            data : get(data, 'getCurrentUser.suggested_users'),
          });
        }
        return (
          <Dashboard
            data={data}
            updateBobCards={updateBobCards}
            loadBobCardList={loadBobCardList}
            updateNotes={updateNotes}
            updateSelectedProfileDrawer={updateSelectedProfileDrawer}
            profile={profile}
            agentWordForCompany={get(data, 'agentWordForCompany', 'agent')}
            updateProfile={updateProfile}
            companyBusinessType={get(data, 'companyBusinessType', '')}
          />
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  bobCards : state.bobCards,
  profile  : state.profileDrawer,
  notes    : state.notes,
});

DashboardContainer.propTypes = {
  bobCards                    : PropTypes.array.isRequired,
  profile                     : PropTypes.object.isRequired,
  updateBobCards              : PropTypes.func.isRequired,
  updateSelectedProfileDrawer : PropTypes.func.isRequired,
  loadBobCardList             : PropTypes.func.isRequired,
  updateNotes                 : PropTypes.func.isRequired,
  updateProfile               : PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...actions,
  ...profileDrawerActions,
  ...notesActions,
})(DashboardContainer);
