import {
  OPPORTUNITY_LIST_UPDATE,
  OPPORTUNITY_UPDATE,
  FILTER_UPDATE,
  UPDATE_STATUS,
} from '../constants/opportunityConstants';

const opportunityReducer = (
  state = {
    opportunities : [],
    opportunity   : {},
    filter        : {},
    user          : {},
    status        : null,
    order         : 'desc',
    orderBy       : 'id',
    selected      : [],
    data          : [],
    loaded        : false,
    page          : 0,
    rowsPerPage   : 10,
  },
  action
) => {
  switch (action.type) {
    case OPPORTUNITY_LIST_UPDATE:
      return { ...state, ...action.opportunities, loaded: true };
    case OPPORTUNITY_UPDATE:
      return { ...state, opportunity: { ...state.opportunity, ...action.opportunity } };
    case FILTER_UPDATE:
      return { ...state, filter: { ...state.filter, ...action.filter } };
    case UPDATE_STATUS:
      return { ...state, status: action.status };
    default:
      return state;
  }
};

export default opportunityReducer;
