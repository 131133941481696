import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { get } from 'lodash';

import './PlanningInformation.css';

const UserBusinessContinuity = ({ user, updateAction, company }) => {
  const businessContinuityStates = get(company, 'business_continuity_states', []);

  return (<>
    <span>Do you have a business continuity plan in the event of your death or disability?</span>
    <Select
      fullWidth
      className="PlanningInformation__radio"
      value={`${get(user, 'business_continuity_state_id')}`}
      onChange={e => {
        updateAction({ business_continuity_state_id: parseInt(e.target.value) })
      }}
    >
      {businessContinuityStates.map(bcs =>
        <MenuItem
          value={bcs.id}
          className="PlanningInformation__radio"
          label={bcs.name}>
          {bcs.name}
        </MenuItem>)
      }
    </Select>
  </>);
};

UserBusinessContinuity.propTypes = {
  user: PropTypes.object,
  updateAction: PropTypes.func.isRequired,
  company: PropTypes.object,
};

UserBusinessContinuity.defaultProps = {
  user: {},
  company: {},
}

export default UserBusinessContinuity;