import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BobCard from '../BobCard/BobCard';
import { isEmpty, take, sortBy, length } from 'lodash';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import { connect } from 'react-redux';

class Rolodex extends Component {
  constructor (props) {
    super(props);
    this.state = {
      page : 1,
    };
  }

  MAX_PAGES = 3;
  CARDS_PER_PAGE = 12;

  isAdmin = () => {
    const { getCurrentUser } = this.props;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles.includes('admin')) {
      return true;
    }
    return false;
  };

  disableMessageBtn = () => {
    const { getCurrentUser } = this.props;
    const checkRoles = [ 'customer', 'staff' ];

    return (
      !get(getCurrentUser, 'company.networking_allowed') ||
      checkRoles.some(role => get(getCurrentUser, 'roles', []).includes(role))
    );
  };

  handleShowMore = () => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  renderUserCards = () => {
    const {
      users,
      currentUserInfoAuth,
      companyHasFeature,
      loading,
      getCurrentUser,
      companyHasCounty,
      countryForCompany,
      updateBobCards,
      loadBobCardList,
      marketplaceOnly,
      updateSelectedProfileDrawer,
      updateNotes,
      refetch,
    } = this.props;
    const { page } = this.state;
    if (loading || isEmpty(users)) return <div />;
    const checkRoles = [ 'customer', 'staff' ];
    let sortedUsers = sortBy(users, [ 'nick_name', 'note_count' ]);
    let currentUserSegmentedViewEnabled = getCurrentUser.company.segmented_views_enforced;
    if (currentUserSegmentedViewEnabled === true) {
      const currentUserSegmentedView = getCurrentUser.inventory_user.segmented_view;
      sortedUsers = sortedUsers.filter(user => user.inventory_user.segmented_view === currentUserSegmentedView);
    }
    sortedUsers = take(sortedUsers, this.CARDS_PER_PAGE * page);
    this.MAX_PAGES = this.CARDS_PER_PAGE / users;
    return sortedUsers.map(user => (
      <BobCard
        key={`${user.user_name}`}
        userId={user.id}
        user={user}
        isUserAuthorized={currentUserInfoAuth}
        displaySocialButtons={companyHasFeature}
        refreshList={refetch}
        canDeleteNotes
        refetch={refetch}
        companyHasCounty={companyHasCounty}
        countryForCompany={countryForCompany}
        updateBobCards={updateBobCards}
        loadBobCardList={b => {
          refetch();
        }}
        userIsAdmin={this.isAdmin()}
        currentUserHasLimitedAccess={getCurrentUser.limited_access}
        currentCompanySellerWord={getCurrentUser.company.seller_only_label}
        currentUserHasSellingTransitionGoals={getCurrentUser.has_selling_transition_goals}
        getCurrentUser={getCurrentUser}
        disableMessageBtn={this.disableMessageBtn()}
        marketplaceOnly={marketplaceOnly}
        updateSelectedProfileDrawer={updateSelectedProfileDrawer}
        updateNotes={updateNotes}
        sortedUsers={sortedUsers}
        removeFromList
      />
    ));
  };

  render = () => {
    const { page } = this.state;
    const { users } = this.props;

    return (
      <div>
        <section className="results">
          <header>
            <h1>
              <FormattedMessage id="mailers.rolodex.organize_your_contacts" />
            </h1>
          </header>
        </section>
        <section id="properties" className="display-lines">
          {this.renderUserCards()}
        </section>
        <div>
          {users && page * this.CARDS_PER_PAGE < users.length && (
            <button
              onClick={this.handleShowMore}
              className="btn btn-default center-block">
              <FormattedMessage id="mailers.rolodex.load_additional_results" />
            </button>
          )}
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => ({
  state,
  bobCards : state.bobCards,
  users: state.bobCards.getRolodex,
  currentUserInfoAuth: state.bobCards.currentUserInfoAuth,
  companyHasFeature: state.bobCards.companyHasFeature,
  loading: state.bobCards.loading,
  getCurrentUser: state.bobCards.getCurrentUser,
  companyHasCounty: state.bobCards.companyHasCounty,
  countryForCompany: state.bobCards.countryForCompany,
  agentWordForCompany: state.bobCards.agentWordForCompany,
});

export default connect(mapStateToProps)(Rolodex);
