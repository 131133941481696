import { combineReducers } from 'redux';
import { CONVERSATION_UPDATE, } from '../constants/conversationConstants';

const defaultState = {

};

const conversationReducer = (state = {...defaultState }, action) => {
    switch (action.type) {
        case CONVERSATION_UPDATE:
            return {...state, ...action.conversation };
        default:
            return state;
    }
};

export default conversationReducer;