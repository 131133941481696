import gql from 'graphql-tag';

export const HomeOfficeQuery = gql `
  query {
    companyHasHomeOffice
    countryForCompany
    agentWordForCompany
    availableProducts
    allFeatures
    company {
      has_home_office
      country
    }
  }
`;

export const ExportMetrics = gql `
  mutation {
    exportMetrics
  }
`;

export const ActivitiesQuery = gql `
  query getInventoryUser($id: ID!, $activityFilter: [String!]) {
    getInventoryUser(id: $id) {
      id
      name
      invites {
        user_id
        name
        time
        properties {
          invitee_name
          invitee_iu_id
          invitee_email
          from_name
          from_iu_id
        }
      }
      user {
        id
        created_at
        number_of_sessions
        last_session
        number_of_views
        number_of_profiles_viewed
        following
        number_of_searches
        messages_sent
        messages_received
        recent_events(activityFilter: $activityFilter) {
          id
          name
          property_string
          properties {
            tag
            href
            page
            viewed_user_id
            viewed_user {
              id
              name
              inventory_user {
                id
              }
            }
            text
            user_name
            keyword
            interest
            province
            within
            aum
            revenue
            designations
            languages
            ethnicity
            faith
            interests
            sports
            avg_age_of_client
            invitee_name
            invitee_iu_id
            invitee_email
            from_name
            from_iu_id
            reasons
            subject
            participant
            sender {
              id
              __typename
              name
              inventory_user {
                id
                __typename
              }
            }
            receiver {
              id
              __typename
              name
              inventory_user {
                id
                __typename
              }
            }
            body
            convo_id
          }
          time
        }
      }
    }
    adminNotesForUser(id: $id) {
      id
      title
      comment
      created_at
      user {
        id
        name
        roles
      }
    }
    currentUserId
  }
`;

export const InventoryUserQuery = gql `
  query getInventoryUser($id: ID!) {
    getInventoryUser(id: $id) {
      id
      name
      birth_date
      phone_number
      province
      city
      rev_yield
      recurring_revenue
      clients
      total_premium
      total_assets
      key_asset
      risk_of_loss
      impact_of_loss
      risk_of_loss_num
      impact_of_loss_num
      reason_for_loss
      marketplace_status
      start_date
      has_user
      email
      manager_email
      transition_goals
      profile_picture
      life_ifp
      home_ifp
      auto_ifp
      commercial_ifp
      health_ifp
      group_ifp
      mutual_funds_aum
      securities_aum
      annuities_aua
      segregated_funds_aua
      total_assets
      mcpi_ifp
      private_products_ifp
      crop_hail_ifp
      crop_hail_with_wind_ifp
      farm_insurance_ifp
      price_products_ifp
      replant_supplement_ifp
      limited_access
      hidden
      is_customer
      is_exception
      in_good_standing
      is_obfuscated
      affiliation
      branch
      division
      minimum_production_threshold
      agency_name
      feature_types
      website
      series_7
      series_63
      series_65
      series_66
      insurance_business
      planning_preference
      outstanding_load_bonus_information
      alex_brown_flag
      region
      catestrophic_plan_on_file
      recognition_level
      industry_length_of_service
      amp_trainee_status
      office_city
      office_state
      office_postal_code
      discretion
      licensing
      pct_client_0to18
      pct_client_18to34
      pct_client_35to50
      pct_client_51to70
      pct_client_71plus
      cssid
      retirement_choice_calculator_code
      can_match
      can_create_meeting_room
      is_student
      segmented_view
      is_rjas
      market_value
      market_value_percentile
      transactional_revenue
      direct_revenue
      trailer_revenue
      fee_based_revenue
      manager_remote_id
      heightened_supervision
      team_size
      team_name
      team_enhanced_compensation
      state_registrations
      divisional_director_name
      asst_divisional_director1_name
      asst_divisional_director2_name
      manager_token
      divisional_director_token
      asst_divisional_director1_token
      asst_divisional_director2_token
      is_branch_exception
      is_invisible
      cat_plan_filename
      successor1_name
      agreement_type_for_for_catastrophic_plan
      catastrophic_plan_contract_date
      continuity_builder {
        id
        dealStructureForCatastrophicPlan
        continuityBuilderSuccessors {
          id
          ordinal
          inventoryUser {
            id
            name
            __typename
          }
        }
        __typename
      }
      iu_tags {
        id
        name
      }
      pvp_value
      calibration_sessions {
        id  
        name
      }
      invites {
        user_id
        name
        properties {
          invitee_name
          invitee_iu_id
          invitee_email
          from_name
          from_iu_id
        }
      }
      manager {
        id
        name
        email
        has_user
        profile_picture
        __typename
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
      }
      discounted_cash_flows {
        id
        friendlyName
        name
        createdAt
        user {
          id
          name
          __typename
        }
        author {
          id
          name
          __typename
        }
      }
      user {
        id
        headline
        bio
        name
        birth_date
        years_practicing
        annual_revenue
        recurring_revenue
        assets_under_management
        profile_percentage_complete
        number_of_clients
        transition_goals
        city
        province
        languages
        tag_list
        email
        locale
        created_at
        designations
        user_name
        fb_user_name
        last_session
        number_of_sessions
        number_of_views
        number_of_profiles_viewed
        number_of_searches
        messages_sent
        messages_received
        mix_insurance
        mix_annuities
        mix_mutual_funds
        mix_other
        agency_name
        deleted
        following
        transactional_revenue
        fee_based_revenue
        trailer_revenue
        direct_revenue
        approval_state
        cash_flows {
          id
          name
          createdAt
          friendlyName
        }
        company {
          id
          business_type
          affiliate_name
          branded_name
          stylesheet_name
          is_managed
          feature_types
        }
        inventory_user {
          id
          is_invisible
        }
        recent_events {
          user_id
          name
          property_string
          properties {
            tag
            href
            page
            viewed_user_id
            viewed_user {
              id
              name
              inventory_user {
                id
              }
            }
            text
            user_name
            keyword
            interest
            province
            within
            aum
            revenue
            designations
            languages
            ethnicity
            faith
            interests
            sports
            avg_age_of_client
            invitee_name
            invitee_iu_id
            invitee_email
            from_name
            from_iu_id
            reasons
            subject
            participant
            sender {
              id
              __typename
              name
              inventory_user {
                id
                __typename
              }
            }
            receiver {
              id
              __typename
              name
              inventory_user {
                id
                __typename
              }
            }
            body
          }
          time
        }
        product_mix {
          id
          aum_stars
          p_c_stars
          group_stars
          health_and_life_stars
          life_ifp
          home_ifp
          auto_ifp
          commercial_ifp
          health_ifp
          group_ifp
          mutual_funds_aum
          securities_aum
          annuities_aua
          segregated_funds_aua
          total_assets
          mcpi_ifp
          private_products_ifp
          crop_hail_ifp
          crop_hail_with_wind_ifp
          farm_insurance_ifp
          price_products_ifp
          replant_supplement_ifp
          gic_fixed_income
          insurance
          equity
        }
      }
    }
  }
`;

export const UpdateCurrentUserColumns = gql `
  mutation updateCurrentUser($default_inventory_user_columns: [String!], $default_user_columns: [String!]) {
    updateCurrentUser(default_inventory_user_columns: $default_inventory_user_columns, default_user_columns: $default_user_columns)
  }
`;


export const toggleHiddenForUsers = gql `
  mutation toggleHiddenForUsers($usersToHide: [ID!], $hidden: Boolean) {
    toggleHiddenForUsers(usersToHide: $usersToHide, hidden: $hidden)
  }
`;

export const toggleAsLimitedAccess = gql `
  mutation toggleAsLimitedAccess($usersToAssignAsLimitedAccess: [ID!], $assignedAsLimitedAccess: Boolean) {
    toggleAsLimitedAccess(
      usersToAssignAsLimitedAccess: $usersToAssignAsLimitedAccess
      assignedAsLimitedAccess: $assignedAsLimitedAccess
    )
  }
`;

export const toggleOmitFromMkt = gql `
  mutation toggleOmitFromMkt($usersToOmit: [ID!], $omitted: Boolean) {
    toggleOmitFromMkt(usersToOmit: $usersToOmit, omitted: $omitted)
  }
`;

export const resendActivationEmailQuery = gql `
  mutation resendActivationEmailQuery($usersToResendActivationEmail: [ID!], $resendActivationEmail: Boolean) {
    resendActivationEmailQuery(
      usersToResendActivationEmail: $usersToResendActivationEmail
      resendActivationEmail: $resendActivationEmail
    )
  }
`;

export const toggleObfuscateForUsers = gql `
  mutation toggleObfuscateForUsers($usersToObfuscate: [ID!], $obfuscated: Boolean) {
    toggleObfuscateForUsers(usersToObfuscate: $usersToObfuscate, obfuscated: $obfuscated)
  }
`;

export const toggleCustomerForUsers = gql `
  mutation toggleCustomerForUsers($usersToUpdate: [ID!], $customer: Boolean) {
    toggleCustomerForUsers(usersToUpdate: $usersToUpdate, customer: $customer)
  }
`;

export const adminNotesForUser = gql `
  query adminNotesForUser($id: ID!) {
    adminNotesForUser(id: $id) {
      id
      title
      comment
      created_at
      user {
        id
        name
        roles
      }
    }
    currentUserId
  }
`;

export const ExportToCSVQuery = gql `
  mutation exportInvUsersToCsv(
    $offset: Int
    $limit: Int
    $order: String
    $orderBy: String
    $filterList: InventoryUserFilterInput
    $nameSearch: String
    $onlyUsers: Boolean
    $distanceSearch: String
    $distanceWithin: Int
  ) {
    exportInvUsersToCsv(
      offset: $offset
      limit: $limit
      order: $order
      orderBy: $orderBy
      filterList: $filterList
      nameSearch: $nameSearch
      onlyUsers: $onlyUsers
      distanceSearch: $distanceSearch
      distanceWithin: $distanceWithin
    )
  }
`;

export const UpdateCurrentInventoryUser = gql `
  mutation editInventoryUser(
    $id: ID!
    $name: String
    $email: String
    $birth_date: String
    $phone_number: String
    $enterprise_id: String
    $organization: String
    $company_id: Int
    $start_date: String
    $rev_yield: Int
    $clients: Int
    $total_premium: Int
    $total_assets: Int
    $key_asset: Boolean
    $risk_of_loss: String
    $risk_of_loss_num: String
    $reason_for_loss: String
    $impact_of_loss: String
    $impact_of_loss_num: String
    $province: String
    $manager_email: String
    $pvp_value: String
  ) {
    editInventoryUser(
      id: $id
      email: $email
      name: $name
      birth_date: $birth_date
      phone_number: $phone_number
      enterprise_id: $enterprise_id
      organization: $organization
      company_id: $company_id
      start_date: $start_date
      rev_yield: $rev_yield
      clients: $clients
      total_premium: $total_premium
      total_assets: $total_assets
      key_asset: $key_asset
      risk_of_loss: $risk_of_loss
      risk_of_loss_num: $risk_of_loss_num
      reason_for_loss: $reason_for_loss
      impact_of_loss: $impact_of_loss
      impact_of_loss_num: $impact_of_loss_num
      province: $province
      manager_email: $manager_email
      pvp_value: $pvp_value
    )
  }
`;