import {
  LOAD_INVENTORY_USERS,
  INVENTORY_USERS,
  INVENTORY_USERS_UPDATE,
  INVENTORY_USERS_ADD,
  INVENTORY_USERS_DELETE,
  INVENTORY_USERS_PUT,
  SELECT_INVENTORY_USER_UPDATE,
  LOAD_BENCH_USERS,
} from '../constants/inventoryUsersConstants';
import { findIndex, pullAt } from 'lodash';

const updateUser = (inventoryUsersList, inventoryUser, modified = true) => {
  const index = findIndex(inventoryUsersList, e => e.id === inventoryUser.id);
  const inventoryUsersListNew = inventoryUsersList;
  const inventoryUserNew = inventoryUser;
  if (index !== -1) {
    if (modified) inventoryUserNew.modified = true;
    else inventoryUserNew.modified = false;
    inventoryUsersListNew[index] = inventoryUserNew;
  }
  return inventoryUsersListNew;
};

const removeInventoryUser = (inventoryUsersList, inventoryUser_id) => {
  const index = findIndex(inventoryUsersList, e => e.id === inventoryUser_id);
  pullAt(inventoryUsersList, index);
  return inventoryUsersList;
};

const InventoryUserReducer = (
  state = {
    order         : 'desc',
    orderBy       : 'id',
    selected      : [],
    data          : [],
    loaded        : false,
    page          : 0,
    rowsPerPage   : 10,
    inventoryUser : {},
    offset        : 0,
    limit         : 10,
  },
  action
) => {
  let list = [];

  switch (action.type) {
    case LOAD_INVENTORY_USERS:
      return { ...state, ...action.inventoryUserSettings, loaded: true };
    case INVENTORY_USERS:
      return { ...state, data: action.inventory_users };
    case INVENTORY_USERS_UPDATE:
      return {
        ...state,
        data : updateUser(state.data, action.inventoryUser),
      };
    case SELECT_INVENTORY_USER_UPDATE:
      return {
        ...state,
        inventoryUser : action.selectedInventoryUser,
      };
    case LOAD_BENCH_USERS:
      return {
        ...state,
        inventoryUser : {
          user : {
            ...state.inventoryUser.user,
            bench     : action.bench,
            benchLoad : state.inventoryUser.user.benchLoad || action.benchLoad,
          },
        },
      };
    case INVENTORY_USERS_ADD:
      list = state.data;
      if (action.newInventoryUser) {
        list.unshift(action.newInventoryUser);
      }

      return {
        ...state,
        data    : list,
        error   : action.error,
        close   : action.close,
        loading : action.loading,
      };
    case INVENTORY_USERS_DELETE:
      list = state.data;
      list = action.error || action.loading ? list : removeInventoryUser(list, action.inventoryUser_id);
      return {
        ...state,
        data    : list,
        error   : action.error,
        close   : action.close,
        loading : action.loading,
      };
    case INVENTORY_USERS_PUT:
      list = state.data;
      list = action.error || action.loading ? list : updateUser(state.data, action.inventoryUser, false);

      return {
        ...state,
        data    : list,
        error   : action.error,
        close   : action.close,
        loading : action.loading,
      };
    default:
      return state;
  }
};

export default InventoryUserReducer;
