import React from 'react';
import { ApolloProvider } from 'react-apollo';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import SignupReport from '../components/SignupReport/SignupReport';
import { connect } from 'react-redux';
import moment from 'moment';

const SignupStepCount = gql`
  query sacSignupStepCount($startDate: String!, $endDate: String!) {
    sacSignupStepCount(startDate: $startDate, endDate: $endDate) {
      signup_started
      signup_ended
      practiceInfo_ended
      productMix_ended
      strengths_ended
      bio_ended
      signup_started_uniq
      signup_ended_uniq
      practiceInfo_ended_uniq
      productMix_ended_uniq
      strengths_ended_uniq
      bio_ended_uniq
    }
  }
`;

const mapStateToProps = state => ({
  startDate : state.dateRangeFilter.sendStart.format('YYYY-MM-DD'),
  endDate   : state.dateRangeFilter.sendEnd.format('YYYY-MM-DD'),
});

const setGqlOptions = props => {
  return { variables: { startDate: props.startDate, endDate: props.endDate } };
};

const SignupReportContainer = connect(mapStateToProps)(
  graphql(SignupStepCount, { options: setGqlOptions })(SignupReport)
);

export default SignupReportContainer;
