import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { MenuItem } from '@material-ui/core/Menu';
import Select from '@material-ui/core/Select';
import EngagementsPage from '../EngagementsPage/EngagementsPage';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import 'react-dates/initialize';

import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';

const styles = theme => ({
  report                      : {
    margin : '25px',
    width  : '100%',
  },
  title                       : {
    fontSize : '24px',
  },
  container                   : {
    padding : '1em 2em',
  },
  button                      : {
    margin : '20px',
  },
  updateButton                : {
    margin  : '20px',
    display : 'inline',
  },
  intervalText                : {
    marginLeft : '20px',
  },
  messages                    : {
    fontSize : '24px',
  },
  textField                   : {
    margin   : '20px',
    padding  : '15px',
    fontSize : '15px',
  },
  PresetDateRangePicker_panel : {
    padding : '0 22px 11px 22px',
  },

  button__selected            : {
    color      : '#fff',
    background : '#00f',
  },
});

const intervalButtonClick = (event, updateDateFilter) => {
  updateDateFilter({ anchorEl: event.currentTarget });
};

const intervalButtonClose = (intervalType, updateDateFilter) => {
  updateDateFilter({
    anchorEl : null,
    interval : intervalType,
  });
};

const today = moment();
const presets = [
  {
    text  : 'Today',
    start : today,
    end   : today,
  },
  {
    text  : 'Last Week',
    end   : today,
    start : moment().add(-1, 'week'),
  },
  {
    text  : 'Last Month',
    end   : today,
    start : moment().add(-1, 'month'),
  },
  {
    text  : 'Last Year',
    end   : today,
    start : moment().add(-1, 'year'),
  },
];

class DateRangeFilter extends Component {
  constructor (props) {
    super(props);
    const { defaultStartDate, defaultEndDate } = this.props;

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }

    props.updateDateFilter({
      focusedInput,
      startDate    : defaultStartDate || today,
      endDate      : defaultEndDate || moment().add(+1, 'year'),
    });
    this.state = {
      focusedInput,
      startDate    : props.initialStartDate,
      endDate      : props.initialEndDate,
      error        : false,
    };

    this.onDatesChange = e => {
      console.log(e);
    };
    this.onFocusChange = e => {};
  }

  renderDatePresets (ps) {
    const { presets, classes, dateRangeFilter, updateDateFilter } = ps;
    const { startDate, endDate } = dateRangeFilter;

    return (
      <div className={classes.PresetDateRangePicker_panel}>
        {(presets || []).map(({ text, start, end }) => {
          const isSelected =
            moment(start).format('YYYY-MM-DD') === moment(startDate).format('YYYY-MM-DD') &&
            moment(end).format('YYYY-MM-DD') === moment(endDate).format('YYYY-MM-DD');
          return (
            <Button
              key={text}
              selected={isSelected}
              className={`${isSelected && classes.button__selected}`}
              type="button"
              onClick={() => updateDateFilter({ startDate: start, endDate: end })}>
              {text}
            </Button>
          );
        })}
      </div>
    );
  }

  sendUpdatedDates = (date1, date2) => {
    const { updateDateFilter } = this.props;
    updateDateFilter({
      sendStart : date1,
      sendEnd   : date2 || date1,
    });
  };

  validateDates = (date1, date2) => {
    if (moment(date2).isAfter(moment(date1))) {
      this.setState({ error: false });
      this.sendUpdatedDates(date1, date2);
    } else if (date2 === null) {
      this.setState({ error: true });
      this.sendUpdatedDates(date1, date2);
    }
  };

  render () {
    const {
      classes,
      updateDateFilter,
      message,
      vertical,
      horizontal,
      open,
      anchorEl,
      sendStart,
      sendEnd,
      sendInterval,
      intervalType,
      interval,
      dateRangeFilter,
      intl,
    } = this.props;

    const { error } = this.state;

    const { startDate, endDate, focusedInput } = dateRangeFilter;

    return (
      <form className={classes.container} noValidate>
        {error && (
          <div>
            Displaying results for {moment(startDate).format('MM/DD/YYYY')}. For ranged results, please select an end
            date that is later than the start date.
          </div>
        )}
        <DateRangePicker
          renderCalendarInfo={() => this.renderDatePresets(this.props)}
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => updateDateFilter({ startDate, endDate })} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => updateDateFilter({ focusedInput })} // PropTypes.func.isRequired,
          onOutsideClick={DayPickerRangeController.onOutsideClick}
          onPrevMonthClick={DayPickerRangeController.onPrevMonthClick}
          onNextMonthClick={DayPickerRangeController.onNextMonthClick}
          isOutsideRange={() => {
            false;
          }}
        />
        <Button variant="raised" className={classes.button} onClick={() => this.validateDates(startDate, endDate)}>
          <FormattedMessage id="admin_company_reports.update" />
        </Button>
      </form>
    );
  }
}

DateRangeFilter.defaultProps = {
  presets : presets,
};

export default injectIntl(withStyles(styles)(DateRangeFilter));
