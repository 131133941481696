import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const FixedPayments = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');

  return (
    <React.Fragment>
      <div>
        <>
          {query.buyerHasFixedPayments && <React.Fragment>
            <div className={classes.header}><FormattedMessage id="calculators.cfa.fixed_payments" /></div>
            <Grid container spacing={2}>
              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.no_of_years" /></b>

                <select value={query.buyerFixedPaymentYears}
                  onChange={e => {
                    updateCfaQuery({ ...query, buyerFixedPaymentYears: parseInt(e.target.value) })
                  }
                  }>
                  <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                  {range(1, 6, 1).map(year =>
                    <option value={year} checked={query.buyerFixedPaymentYears == year}>{year}</option>
                  )}
                </select>

              </Grid>

              <Grid item>
                <b className={classes.sub_heading}><FormattedMessage id="calculators.cfa.start_year" /></b>

                <select value={query.buyerFixedPaymentStartYear}
                  onChange={e => {
                    updateCfaQuery({ ...query, buyerFixedPaymentStartYear: parseInt(e.target.value) })
                  }
                  }>
                  <option>{intl.formatMessage({ id: "calculators.cfa.please_select_a_year" })}</option>
                  {range(1, 6, 1).map(year =>
                    <option value={year} checked={query.buyerFixedPaymentStartYear == year}>{year}</option>
                  )}
                </select>

              </Grid>

              <Grid item xs={4} style={{ display: 'inline-flex', flexWrap: 'nowrap', alignContent: 'center', justifyContent: 'space-evenly', alignItems: 'baseline', }}>
                <b className={classes.sub_heading} style={{ width: 300, }}>
                  <FormattedMessage id="calculators.cfa.payment_amount" />
                </b>
                <CurrencyInput
                  style={{ float: 'left', }}
                  className={classes.currency}
                  value={(query.buyerFixedPaymentAmount || 0)}
                  onValueChange={value => {
                    updateCfaQuery({ ...query, buyerFixedPaymentAmount: parseFloat(value) })
                  }}
                />
              </Grid>
            </Grid>
          </React.Fragment>}
        </>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(FixedPayments));