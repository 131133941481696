import PropTypes from 'prop-types';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  textField : {
    margin          : '12px',
    backgroundColor : '#fff',
    minWidth        : '300px',
  },
});

const updateField = (data, action, event) => {
  data[event.target.id] = event.target.value;
  action(data);
};

const SimpleTextField = ({
  id,
  label,
  data,
  action,
  required = false,
  type = 'text',
  validator,
  helperText,
  color = 'primary',
  classes,
  fullWidth = false,
  autoFocus = false,
  style,
  labelProps,
  placeholder,
}) => (
  <TextField
    autoFocus={autoFocus}
    required={required}
    id={id}
    label={label}
    type={type}
    color={color}
    error={validator}
    helperText={helperText}
    className={classes.textField}
    onChange={e => {
      updateField(data, action, e);
    }}
    value={data[id] || ''}
    margin="normal"
    fullWidth={fullWidth}
    InputLabelProps={labelProps}
    style={style}
    placeholder={placeholder}
  />
);

SimpleTextField.propTypes = {
  data       : PropTypes.string.isRequired,
  action     : PropTypes.func.isRequired,
  validator  : PropTypes.func.isRequired,
  required   : PropTypes.bool,
  fullWidth  : PropTypes.bool,
  autoFocus  : PropTypes.bool,
  type       : PropTypes.string.isRequired,
  helperText : PropTypes.string.isRequired,
  color      : PropTypes.string.isRequired,
  classes    : PropTypes.object.isRequired,
  style      : PropTypes.object.isRequired,
  labelProps : PropTypes.object.isRequired,
};

SimpleTextField.defaultProps = {
  required  : false,
  fullWidth : false,
  autoFocus : false,
};

export default withStyles(styles)(SimpleTextField);
