import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EngagementsPage from '../EngagementsPage/EngagementsPage';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const styles = theme => ({
  report       : {
    margin : '25px',
    width  : '100%',
  },
  title        : {
    fontSize : '24px',
  },
  container    : {
    backgroundColor : 'Gainsboro',
  },
  button       : {
    margin : '20px',
  },
  updateButton : {
    margin  : '20px',
    display : 'inline',
  },
  intervalText : {
    marginLeft : '20px',
  },
  messages     : {
    fontSize : '24px',
  },
  textField    : {
    margin   : '20px',
    padding  : '15px',
    fontSize : '15px',
  },
});

class EngagementsComponentPage extends React.Component {
  constructor (props) {
    super(props);
    const date1 = moment().subtract(6, 'days').format('YYYY-MM-DD');
    const date2 = moment().format('YYYY-MM-DD');
    this.state = {
      startDate    : date1,
      endDate      : date2,
      sendStart    : date1,
      sendEnd      : date2,
      anchorEl     : null,
      interval     : 'daily',
      sendInterval : 'daily',
      open         : false,
      vertical     : null,
      horizontal   : null,
    };
  }

  intervalButtonClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  intervalButtonClose = intervalType => {
    this.setState({
      anchorEl : null,
      interval : intervalType,
    });
  };

  flashHandleClick = state => {
    this.setState({ open: true, ...state });
  };

  flashHandleClose = () => {
    this.setState({ open: false });
  };

  clickUpdateButton = () => {
    const { intl, enqueueSnackbar } = this.props;
    const { startDate, endDate, interval } = this.state;
    const dateOne = moment(startDate);
    const dateTwo = moment(endDate);
    const numOfDays = dateTwo.diff(dateOne, 'days');
    const lastDayOfCurrentMonth = moment().endOf('month').format('YYYY-MM-DD');
    const startDateForWeekly = moment(dateOne).subtract(7, 'days').format('YYYY-MM-DD');

    if (startDate < endDate) {
      switch (interval) {
        case 'daily':
          if (numOfDays < 31) {
            this.setState({
              sendStart    : startDate,
              sendEnd      : endDate,
              sendInterval : interval,
            });
          } else if (numOfDays > 30) {
            enqueueSnackbar(intl.formatMessage({ id: 'admin_company_reports.please_choose_range' }));
            this.setState({
              message : intl.formatMessage({ id: 'admin_company_reports.please_choose_range' }),
            });
            this.flashHandleClick({ vertical: 'top', horizontal: 'center' });
          }
          break;
        case 'weekly':
          if (numOfDays % 7 === 0) {
            this.setState({
              sendStart    : startDateForWeekly,
              sendEnd      : endDate,
              sendInterval : interval,
            });
          } else if (numOfDays % 7 != 0) {
            enqueueSnackbar(intl.formatMessage({ id: 'admin_company_reports.start_end_date' }));
            this.setState({
              message : intl.formatMessage({ id: 'admin_company_reports.start_end_date' }),
            });
            this.flashHandleClick({ vertical: 'top', horizontal: 'center' });
          }
          break;
        case 'monthly':
          this.setState({
            sendStart    : `${moment().format('YYYY')}-01-01`,
            sendEnd      : lastDayOfCurrentMonth,
            sendInterval : interval,
          });
      }
    } else {
      enqueueSnackbar(intl.formatMessage({ id: 'admin_company_reports.range_is_invalid' }), {
        variant      : 'success',
        anchorOrigin : {
          vertical   : 'top',
          horizontal : 'center',
        },
      });
      this.setState({
        message : intl.formatMessage({ id: 'admin_company_reports.range_is_invalid' }),
      });
      this.flashHandleClick({ vertical: 'top', horizontal: 'center' });
    }
  };

  render = () => {
    const { classes } = this.props;
    const {
      message,
      vertical,
      horizontal,
      open,
      anchorEl,
      sendStart,
      sendEnd,
      sendInterval,
      intervalType,
      interval,
      startDate,
      endDate,
      intl,
    } = this.state;
    return (
      <section>
        <div className={classes.report}>
          <div className={classes.title}>
            <FormattedMessage id="admin_company_reports.user_engagement" />
          </div>
          <br />
          <form className={classes.container} noValidate>
            <Button
              className={classes.button}
              variant="raised"
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.intervalButtonClick}>
              {interval}
            </Button>
            <span className={classes.intervalText}>
              <FormattedMessage id="admin_company_reports.currently_selected" values={{ interval }} />
            </span>
            <span>
              {interval === 'monthly' ? <FormattedMessage id="admin_company_reports.this_mode_will_default" /> : ''}
            </span>
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)}>
              <MenuItem onClick={() => this.intervalButtonClose('daily')}>
                <FormattedMessage id="admin_company_reports.daily" />
              </MenuItem>
              <MenuItem onClick={() => this.intervalButtonClose('weekly')}>
                <FormattedMessage id="admin_company_reports.weekly" />
              </MenuItem>
              <MenuItem onClick={() => this.intervalButtonClose('monthly')}>
                <FormattedMessage id="admin_company_reports.monthly" />
              </MenuItem>
            </Menu>
            <div className={classes.datefield}>
              <TextField
                disabled={interval === 'monthly'}
                label={
                  interval === 'weekly' ? (
                    <FormattedMessage id="admin_company_reports.week_ending_on" />
                  ) : (
                    <FormattedMessage id="admin_company_reports.start_date" />
                  )
                }
                id="date"
                type="date"
                defaultValue={startDate}
                error={startDate >= endDate}
                onChange={e => {
                  this.setState({ startDate: e.target.value });
                }}
                className={classes.textField}
                InputLabelProps={{
                  shrink : true,
                }}
              />
              <span>
                <strong>
                  <FormattedMessage id="admin_company_reports.to" />:
                </strong>
              </span>
              <TextField
                disabled={interval === 'monthly'}
                label={
                  interval === 'weekly' ? (
                    <FormattedMessage id="admin_company_reports.week_ending_on" />
                  ) : (
                    <FormattedMessage id="admin_company_reports.end_date" />
                  )
                }
                id="date"
                type="date"
                defaultValue={endDate}
                error={endDate <= startDate}
                onChange={e => {
                  this.setState({ endDate: e.target.value });
                }}
                className={classes.textField}
                InputLabelProps={{
                  shrink : true,
                }}
              />
              <Button variant="raised" className={classes.button} onClick={this.clickUpdateButton}>
                <FormattedMessage id="admin_company_reports.update" />
              </Button>
            </div>
          </form>
          <EngagementsPage firstDate={sendStart} lastDate={sendEnd} interval={sendInterval} />
        </div>
      </section>
    );
  };
}

EngagementsComponentPage.propTypes = {
  classes : PropTypes.object.isRequired,
};

const EngagementsComponentPageStyled = withStyles(styles)(EngagementsComponentPage);
export default injectIntl(withSnackbar(EngagementsComponentPageStyled));
