import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { Tab, Tabs, Box } from '@material-ui/core';
import ChatWidgetContainer from '../../containers/ChatWidgetContainer';
import ActivityTabContainer from '../../containers/ActivityTabContainer';
import SupportRequestHeader from './SupportRequestHeader';
import SupportRequestsForUser from './SupportRequestsForUser';
import SupportRequestNotes from './SupportRequestNotes';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  activityBox: {
    height: 'calc(100vh - 280px)',
    overflow: 'scroll',
    width: '95%',
  },
  chatWidget: {
    height: 'calc(100vh - 280px)',
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SupportRequestModal = ({ supportRequest, changeCurrentRequest, currentUser, managers, refetch }) => {
  const classes = useStyles();
  const [currentTab, setCurentTab] = useState(0);

  if (!supportRequest) return <span />;
  let owner = get(supportRequest, 'owner');
  if (owner === null) owner = { id: 0, user_name: 'Not Assigned Yet', name: 'Not Assigned Yet', profile_picture: '' }

  const hasInventoryUser = Boolean(supportRequest?.user?.inventory_user?.id);

  return (
    <div>
      <Dialog fullScreen open={Boolean(supportRequest)} onClose={() => changeCurrentRequest(null)} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => changeCurrentRequest(null)} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Support Request Details - {`${supportRequest.friendlyName}`}
            </Typography>

          </Toolbar>
        </AppBar>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} >
            <SupportRequestHeader supportRequest={supportRequest} managers={managers} refetch={refetch} />
          </Grid>
          <Grid item xs={12} md={3}>
            {Boolean(supportRequest.user) && (<>
              <h4>Support Requests From This User:</h4>
              <SupportRequestsForUser userId={get(supportRequest, 'user.id')} changeSupportRequest={changeCurrentRequest} supportRequest={supportRequest} />
            </>)}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.chatWidget}>
              {Boolean(supportRequest.user) ? <ChatWidgetContainer
                standalone
                room_id={supportRequest.conversation?.id}
                currentUser={owner}
              /> : <div>
                  <h1>Lead Message</h1>
                  <p>
                    {supportRequest.leadMessage}
                  </p>
                </div>}
            </div>

          </Grid>
          <Grid xs={12} md={3}>
            <div className={classes.activityBox}>
              <Tabs
                value={currentTab}
                onChange={(e, newValue) => setCurentTab(newValue)}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="Notes" value={0} />
                {hasInventoryUser && <Tab label="Activity" index={1} />}
              </Tabs>
              <TabPanel index={0} value={currentTab}>
                <SupportRequestNotes supportRequest={supportRequest} currentUser={currentUser} />
              </TabPanel>
              {hasInventoryUser && <TabPanel index={1} value={currentTab}>
                <ActivityTabContainer
                  hideInfoBox
                  inventoryUser={supportRequest?.user?.inventory_user}
                  currentUserId={currentUser.id}
                  currentUserIsAdmin={currentUser.is_staff_or_customer}
                /> </TabPanel>}

            </div>

          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export default SupportRequestModal;