import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { get } from 'lodash';
import EducationalContent from '../components/EducationalContent/EducationalContent';
import * as actions from '../actions/educationalContentActionCreators';

const EducationalContentQuery = gql`
  query getEducationalContents($order: String, $orderBy: String, $page: Int) {
    getEducationalContents(order: $order, orderBy: $orderBy, page: $page) {
      id
      title
      description
      visible
      contentType
      thumbnailUrl
      assetUrl
      embedCode
      notifyOnPublish
      locale
    }
    getEducationalContentCount(order: $order, orderBy: $orderBy, page: $page)
  }
`;

class EducationalContentContainer extends React.Component {
  render() {
    const { educationalContents, updateEducationalContents, updateEducationalContent } = this.props;
    const { page, order, orderBy } = educationalContents;
    return (
      <Query
        fetchPolicy="network-only"
        query={EducationalContentQuery}
        variables={{
          page,
          order,
          orderBy,
        }}
        onCompleted={data => {
          updateEducationalContents({
            educationalContents: data.getEducationalContents,
            order: order,
            orderBy: orderBy,
            page: page,
            count: data.getEducationalContentCount,
          });
        }}>
        {({ loading, refetch }) => {
          return (
            <Grid>
              {loading && <CircularProgress />}
              <Paper>
                <EducationalContent
                  educationalContents={educationalContents}
                  updateEducationalContent={updateEducationalContent}
                  updateEducationalContents={updateEducationalContents}
                  count={get(educationalContents, 'count')}
                  refetch={refetch}
                />
              </Paper>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = state => ({
  educationalContents: state.educationalContents,
});

EducationalContentContainer.propTypes = {
  refetch: PropTypes.func.isRequired,
  educationalContent: PropTypes.object,
  educationalContents: PropTypes.array,
  updateEducationalContent: PropTypes.func.isRequired,
  updateEducationalContents: PropTypes.func.isRequired,
};

EducationalContentContainer.defaultProps = {
  educationalContent: {},
  educationalContents: [],
};

export default connect(mapStateToProps, actions)(EducationalContentContainer);
