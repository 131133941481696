import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import client from '../lib/apolloClient';
import ProfileConnectContainer from './ProfileConnectContainer';
import { UserProfileQueries } from './UserProfileContainer';
import { initX } from '../lib/findBobUtils';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import qs from 'query-string';
import { canViewProfile } from '../lib/userUtils';

class ProfileDrawerWithListener extends React.Component {
  state = {
    selectedUser        : {},
    data                : {},
    agentWordForCompany : '',
  };

  setUser = async user_name => {
    const { updateSelectedProfileDrawer } = this.props;
    const result = await client.query({
      query       : UserProfileQueries,
      variables   : { userName: user_name },
      fetchPolicy : 'network-only',
    });
    const user = get(result, 'data.getUser');
    this.resetUrl();
    if (!canViewProfile(user)) {
      return;
    }
    this.setState({
      selectedUser        : true,
      data                : get(result, 'data', {}),
      agentWordForCompany : get(result, 'data.agentWordForCompany', ''),
    });
    updateSelectedProfileDrawer({ ...get(result, 'data') });
  };

  resetUrl = () => {
    const currentUrl = window.location.href;
    window.history.pushState({}, document.title, `${currentUrl.substring(0, currentUrl.indexOf('?'))}`);
  };

  deselectUser = () => {
    this.setState({ selectedUser: false, data: {} });
  };

  render () {
    const params = qs.parse(location.search);
    if (params.user_name) {
      this.setUser(params.user_name);
      profileDrawerActions.updateSelectedProfileDrawer({ user_name: params.user_name });
    }

    initX();

    document.x.registerListener(val => {
      if (val) {
        this.setUser(val);
        profileDrawerActions.updateSelectedProfileDrawer({ user_name: val });
      }
    });
    const { selectedUser, data, agentWordForCompany } = this.state;
    return (
      <div>
        {selectedUser && (
          <ProfileConnectContainer
            updateSelectedProfileDrawer={() => this.deselectUser()}
            agentWordForCompany={agentWordForCompany}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile : state.profileDrawer,
});

export default connect(mapStateToProps, profileDrawerActions)(ProfileDrawerWithListener);
