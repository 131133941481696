import React, { useState, Fragment } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import numeral from "numeral";
import { withStyles } from "@material-ui/core/styles";
import { FormattedMessage, injectIntl } from "react-intl";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Card from "@material-ui/core/Card";
import Grid from '@material-ui/core/Grid';
import CurrencyInput from '../../FBCurrencyInput/FBCurrencyInput';
import FBPercentInput from "../../FBPercentInput/FBPercentInput";

const DealStructure = ({ classes, intl, query, result, company, updateCfaQuery }) => {
  const showPurchasePrice = get(company, 'features', []).includes('cashflow_purchase_price');
  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  const showCheckSwap = get(company, 'features', []).includes('check_swap');

  return (
    <React.Fragment>
      <div>
        <>
          <div className={classes.header}>Deal Structure</div>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12}>
          <item xs={12} sm={6} alignItems="left">
          <div className='left'>
              <RadioGroup
                className={classes.radioGroup}
                row
                value={`${query.buyerHasLumpSumPayment}`}
                onChange={e => {
                  const lumpSumPayments = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!lumpSumPayments) {
                    clear = { buyersFinancingAmount: 0, buyerLumpSumAmount: 0, };
                  }

                  updateCfaQuery({ ...query, ...clear, buyerHasLumpSumPayment: lumpSumPayments, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
              <b className={`${classes.sub_heading} right`}>Down Payment </b>
            </div>
            <br />
            </item>
            {showSellerNote && 
              <item xs={12} sm={6} alignItems="left">
              <div className='left'>
              <RadioGroup
                className={classes.radioGroup}
                row
                value={`${query.hasSellerNote || query.sellerNoteAmount > 0}`}
                onChange={e => {
                  const hasSellerNote = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!hasSellerNote) {
                    clear = { sellerNoteAmount: null, sellerNoteInterestRate: 0, };
                  }

                  updateCfaQuery({ ...query, ...clear, hasSellerNote, sellerNoteAmount: 0, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
              <b className={`${classes.sub_heading} right`}>Seller Note </b>
            </div>
            <br />
            </item>}
            <item xs={12} sm={6} alignItems="left">
            <div className='left'>
              <RadioGroup
                className={classes.radioGroup}
                row
                value={`${query.buyerHasContinuingPayments}`} onChange={e => {
                  const continuingPayments = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!continuingPayments) {
                    clear = { buyerContinuingPaymentAmount: 0, buyerContinuingPaymentYears: 0, buyerContinuingPaymentStartYear: 0, };
                  }

                  updateCfaQuery({ ...query, ...clear, buyerHasContinuingPayments: continuingPayments, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
              <b className={`${classes.sub_heading} right`}>{showOrdinaryTaxes ? "Continuing Payments" : "Earn Out"}</b>
            </div>
            <br />
            </item>
            {showOrdinaryTaxes &&
              <item xs={12} sm={6} alignItems="left">
              <div className='left'>
                <RadioGroup
                  className={classes.radioGroup}
                  row
                  value={`${query.buyerHasFixedPayments}`}
                  onChange={e => {
                    const fixedPayments = Boolean("true" == e.target.value);
                    let clear = {};
                    if (!fixedPayments) {
                      clear = { buyerFixedPaymentYears: 0, buyerFixedPaymentStartYear: 0, buyerFixedPaymentAmount: 0, };
                    }

                    updateCfaQuery({ ...query, ...clear, buyerHasFixedPayments: fixedPayments, })
                  }}>
                  <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                  <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
                </RadioGroup>
                <b className={`${classes.sub_heading} right`}>Fixed Payments</b>
              </div>
            <br />
            </item>}
            {showConsultingAgreements && 
              <item xs={12} sm={6} alignItems="left">
              <div className='left'>
              <RadioGroup
                className={classes.radioGroup}
                row
                value={`${query.hasConsultingAgreement || query.consultingTotalCompensation > 0}`}
                onChange={e => {
                  const hasConsultingAgreement = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!hasConsultingAgreement) {
                    clear = { hasConsultingAgreement: null, consultingTotalCompensation: 0, };
                  }

                  updateCfaQuery({ ...query, ...clear, hasConsultingAgreement, consultingTotalCompensation: 0, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
              <b className={`${classes.sub_heading} right`}>Consulting Agreement</b>
            </div>
            <br />
            </item>}
            {showCheckSwap && 
              <item xs={12} sm={6} alignItems="left">
              <div className='left'>
              <RadioGroup
                className={classes.radioGroup}
                row
                aria-label="hasCheckSwap"
                name="hasCheckSwap"
                value={`${query.hasCheckSwap ? "true" : "false"}`}
                onChange={e => {
                  const hasCheckSwap = Boolean("true" == e.target.value);
                  let clear = {};
                  if (!hasCheckSwap) {
                    // clear = {buyersFinancingAmount: 0};
                  }

                  updateCfaQuery({ ...query, ...clear, hasCheckSwap, })
                }}>
                <FormControlLabel value="true" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.yes_label" })} />
                <FormControlLabel value="false" control={<Radio />} label={intl.formatMessage({ id: "calculators.cfa.no_label" })} />
              </RadioGroup>
              <b className={`${classes.sub_heading} right`}>Check Swap</b>
            </div>
            <br />
            </item>}
            </Grid>
          </Grid>
        </>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps)(injectIntl(DealStructure));