import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ApolloProvider, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Grid } from '@material-ui/core';
import client from '../../lib/apolloClient';
import SuggestionsModalContainer from '../../containers/SuggestionsModalContainer';
import SuggestedUserCard from './SuggestedUserCard';

const deleteSuggestedCaseMutation = gql`
  mutation deleteSuggestedCase($id: ID!) {
    deleteSuggestedCase(id: $id)
  }
`;

class SuggestedUsersPage extends React.Component {
  render () {
    const {
      classes,
      suggested_users,
      suggestedUser,
      pipelineList,
      currentUser,
      updateSuggestedUsersList,
      updateSuggestedUser,
      updateSelectedSuggestedUser,
    } = this.props;
    return (
      <ApolloProvider client={client}>
        <Mutation mutation={deleteSuggestedCaseMutation}>
          {deleteSuggested => (
            <div className={classes.page}>
              <SuggestionsModalContainer
                suggestedUser={suggestedUser}
                pipelineList={pipelineList}
                owner={currentUser}
                deleteSuggested={deleteSuggested}
                updateSuggestedUsersList={updateSuggestedUsersList}
                updateSuggestedUser={updateSuggestedUser}
                updateSelectedSuggestedUser={updateSelectedSuggestedUser}
              />
              <Grid xs={12}>
                <div className={classes.subTitle}>Add these Suggested Cases to your Pipeline</div>
              </Grid>
              <Grid container justify="left" spacing={3}>
                {suggested_users.map(user => (
                  <Grid item xs={4}>
                    <div style={{ height: 325 }}>
                      <SuggestedUserCard
                        user={user}
                        suggested_users={suggested_users}
                        deleteSuggested={deleteSuggested}
                        addUser={() => this.addUser(user)}
                        pipelineList={pipelineList}
                        updateSuggestedUsersList={updateSuggestedUsersList}
                        updateSuggestedUser={updateSuggestedUser}
                        updateSelectedSuggestedUser={updateSelectedSuggestedUser}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          )}
        </Mutation>
      </ApolloProvider>
    );
  }
}

SuggestedUsersPage.propTypes = {
  suggested_users             : PropTypes.object,
  suggestedUser               : PropTypes.object.isRequired,
  updateSelectedSuggestedUser : PropTypes.func.isRequired,
  pipelineList                : PropTypes.object.isRequired,
  currentUser                 : PropTypes.object.isRequired,
  updateSuggestedUsersList    : PropTypes.func.isRequired,
  updateSuggestedUser         : PropTypes.func.isRequired,
};

SuggestedUsersPage.defaultProps = {
  suggested_users : [],
};

const styles = {
  page          : {
    width      : '100%',
    height     : 'calc(100vh - 100px)',
    marginTop  : 15,
    overflowY  : 'auto',
    overflowX  : 'hidden',
    background : '#F7F9F9',
  },
  name          : {
    width : '85%',
  },
  nameField     : {
    fontWeight : 500,
  },
  avatar        : {
    margin : '0px 10px 0px 15px',
  },
  text          : {
    textAlign : 'left',
  },
  row           : {
    marginTop    : 5,
    marginBottom : 5,
  },
  viewAll       : {
    position   : 'absolute',
    bottom     : '25px',
    right      : '25%',
    left       : '50%',
    marginLeft : '-150px',
    color      : '#0052CC',
  },
  contentButton : {
    height     : 'auto',
    padding    : 0,
    border     : 'none',
    background : 'none',
  },
  subTitle      : {
    fontSize     : 20,
    fontWeight   : 500,
    marginLeft   : 15,
    marginBottom : 15,
    color        : '#444',
  },
};

export default withStyles(styles)(SuggestedUsersPage);
