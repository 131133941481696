import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import moment from 'moment';

import CircularProgress from '@material-ui/core/CircularProgress';
import { BobBanner, StandardBanner, AuctionBanner } from '../BobBanner';
import { nameForUser, bioForUser, locationForUser, areUsersTheSame, interest_targets } from '../../lib/userUtils.jsx';

import Spotlight, { generateSteps } from '../Spotlight/Spotlight';
import PracticeInfo from './PracticeInfo';
import AumInfo from './AumInfo';
import Strengths from './Strengths';
import { Bling as GPT } from 'react-gpt';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import qs from 'query-string';
import { get, uniq, toLower, capitalize, isEmpty } from 'lodash';

import ProfileCard from './ProfileCard';

GPT.enableSingleRequest().then(value => {
  // console.log('value', value);
});

class UserProfile extends Component {
  constructor (props) {
    super(props);

    this.userUpdate = this.userUpdate.bind(this);
  }

  userUpdate = () => {
    this.props.data.refetch();
  };

  isAdmin = () => {
    const { getCurrentUser } = this.props.data;
    if (!isEmpty(getCurrentUser) && getCurrentUser.roles) {
      if (getCurrentUser.roles.includes('admin')) {
        return true;
      }
    }
    return false;
  };

  hasProductMix = user => {
    return !Object.values(get(user, 'product_mix') || { a: null }).every(
      x => x === null || x === 'ProductMix' || x === 0
    );
  };

  render () {
    const { loading } = this.props.data;
    let notebook = false;
    if (this.props.location) {
      const params = qs.parse(this.props.location.search);
      notebook = params.notebook;
    }

    if (loading) {
      return (
        <div style={{ minHeight: '1340px' }}>
          <CircularProgress size={32} />
        </div>
      );
    }
    const { data } = this.props;
    const {
      getUser,
      getCurrentUser,
      currentUserInfoAuth,
      companyHasFeature,
      getSpotlights,
      countryForCompany,
      followingUser,
      companyHasAds,
      availableStrengths,
      companyHasCounty,
      enabledStudentProfiles,
      studentWord,
      managedMarketplaceOnly,
      companyBusinessType,
    } = data;
    if (!getUser) {
      return <div />;
    }
    const user = getUser;
    const currentUser = getCurrentUser;
    const authorized = areUsersTheSame(user, currentUser) || currentUserInfoAuth;
    const ownProfile = areUsersTheSame(user, currentUser);
    let obfuscateView = false;
    if (!managedMarketplaceOnly) {
      if (!ownProfile || !managedMarketplaceOnly) {
        obfuscateView =
          user.obfuscated ||
          (!user.is_pure_buyer && currentUser.limited_access) ||
          (currentUser.limited_access && isEmpty(user.transition_goals));
      }
    }
    const { newUser, intl, classes } = this.props;
    const useCanadianRetailWealthProductMix = get(user, 'company.use_canadian_retail_wealth_product_mix', false);
    const usePAndCProductMix = get(user, 'company.use_p_and_c_product_mix', false);
    const hideProductMix = get(user, 'company.hide_product_mix_on_profile', false);
    const checkRoles = [ 'customer', 'staff' ];
    let disableMessageBtn = false;
    if (currentUser.roles) {
      disableMessageBtn = checkRoles.some(role => currentUser.roles.includes(role));
    }

    return (
      <div>
        <div className="center" style={{ margin: '20px' }}>
          {companyHasAds && (
            <GPT
              adUnitPath="/21717728079/personal_profile_page_leaderboard"
              slotSize={[ 728, 90 ]}
              targeting={{ platform: window.location.hostname, interests: interest_targets(user) }}
            />
          )}
        </div>
        {companyHasFeature && <Spotlight steps={generateSteps(getSpotlights, intl)} />}
        <ProfileCard
          user={user}
          availableStrengths={availableStrengths}
          currentUser={currentUser}
          showRolodex={companyHasFeature}
          userUpdate={this.userUpdate}
          followingUser={followingUser}
          currentUserInfoAuth={currentUserInfoAuth}
          notebook={notebook}
          countryForCompany={countryForCompany}
          companyHasCounty={companyHasCounty}
          obfuscateView={obfuscateView}
          authorized={authorized}
          enabledStudentProfiles={enabledStudentProfiles}
          studentWord={studentWord}
          newUser={newUser}
          intl={intl}
          marketplaceOnly={managedMarketplaceOnly}
          companyBusinessType={companyBusinessType}
        />
        <BobBanner user={user} currentUser={currentUser} override={newUser}>
          {user.in_auction ? (
            <AuctionBanner user={user} authorized={authorized} newUser={newUser} />
          ) : (
            <StandardBanner
              user={user}
              authorized={authorized}
              newUser={newUser}
              rolodexEnabled={!newUser && companyHasFeature}
              getInterestedUsers={user.expressed_interest}
              currentUserHasLimitedAccess={currentUser.limited_access}
              currentCompanySellerWord={get(currentUser, 'company.seller_only_label')}
              currentUserHasSellingTransitionGoals={currentUser.has_selling_transition_goals}
              getCurrentUser={getCurrentUser}
              disableMessageBtn={disableMessageBtn}
              marketplaceOnly={managedMarketplaceOnly}
            />
          )}
        </BobBanner>
        <PracticeInfo
          user={user}
          authorized={authorized}
          availableProducts={data.availableProducts}
          displayAds={companyHasAds}
          userIsAdmin={this.isAdmin()}
          currentUserHasLimitedAccess={currentUser.limited_access}
          ownProfile={ownProfile}
          currentUserHasSellingTransitionGoals={currentUser.has_selling_transition_goals}
          obfuscateView={obfuscateView}
          companyBusinessType={companyBusinessType}
        />
        {this.hasProductMix(user) && (
          <AumInfo
            user={user}
            authorized={authorized}
            obfuscateView={obfuscateView}
            useCanadianRetailWealthProductMix={useCanadianRetailWealthProductMix}
            usePAndCProductMix={usePAndCProductMix}
            hideProductMix={hideProductMix}
          />
        )}
        {!isEmpty(get(user, 'tag_list')) && (
          <Strengths intl={intl} availableStrengths={availableStrengths || []} strengths={get(user, 'tag_list')} />
        )}
      </div>
    );
  }
}

UserProfile.propTypes = {
  data    : PropTypes.object,
  newUser : PropTypes.bool,
};

UserProfile.defaultProps = {
  data    : {
    currentUserInfoAuth    : true,
    companyHasFeature      : true,
    companyHasBilling      : false,
    curreUserAuctionCount  : 0,
    currentUserASubscriber : true,
    getSpotlights          : [],
  },
  newUser : false,
};

export default injectIntl(UserProfile);
