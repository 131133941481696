import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import PracticeInformation from '../SignUpWizard/PracticeInformation/PracticeInformation';

const UpdateCandidate = gql`
  mutation updateCandidate(
    $annual_revenue: Float
    $year_career_began: Int
    $years_practicing: Int
    $number_of_clients: Int
    $avg_client_age: Int
    $recurring_revenue: Float
    $percent_commission: Float
    $percent_fee_based: Float
    $transition_goals: [String]
    $locale: String
  ) {
    updateCandidate(
      annual_revenue: $annual_revenue
      year_career_began: $year_career_began
      years_practicing: $years_practicing
      number_of_clients: $number_of_clients
      avg_client_age: $avg_client_age
      recurring_revenue: $recurring_revenue
      percent_commission: $percent_commission
      percent_fee_based: $percent_fee_based
      transition_goals: $transition_goals
      locale: $locale
    )
  }
`;

export default graphql(UpdateCandidate)(PracticeInformation);
