import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as actions from '../../../actions/userActionCreators';
import CheckButton from '../../CheckButton/CheckButton';

export const CatastrophicPlanningPreference = ({ user, company, updateUser, classes }) => {
  return (
    <div className={classes.formGroup}>
      <input type="hidden" name="user[planning_preference]" value={user.planning_preference} />
      <span><FormattedMessage id={'sign_up_pages.what_is_your_pref'} values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word')}}/></span>
      <br />
      <CheckButton
        stylesheetName="rjpe"
        label={<FormattedMessage id={'sign_up_pages.find_partner'} values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word')}}/>}
        checked={user.planning_preference === 'find_partner'}
        onClick={e => updateUser({ planning_preference: e.target.value })}
        value="find_partner"
      />
      <CheckButton
        stylesheetName="rjpe"
        label={<FormattedMessage id={'sign_up_pages.become_partner'} values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word')}}/>}
        checked={user.planning_preference === 'become_partner'}
        onClick={e => updateUser({ planning_preference: e.target.value })}
        value="become_partner"
      />
      {get(user, 'company.planning_preference_already_exists_question') && (
        <CheckButton
          stylesheetName="rjpe"
          label={<FormattedMessage id={'sign_up_pages.already_have'} values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word')}}/>}
          checked={user.planning_preference === 'already_have'}
          onClick={e => updateUser({ planning_preference: e.target.value })}
          value="already_have"
        />
      )}
      <CheckButton
        stylesheetName="rjpe"
        label={<FormattedMessage id={'sign_up_pages.dont_want'} values={{catastrophic_planning_word: get(user, 'company.catastrophic_planning_word')}}/>}
        checked={user.planning_preference === 'no_plan_preference'}
        onClick={e => updateUser({ planning_preference: e.target.value })}
        value="no_plan_preference"
      />
    </div>
  );
};

CatastrophicPlanningPreference.propTypes = {
  user       : PropTypes.object.isRequired,
  company    : PropTypes.object.isRequired,
  updateUser : PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user    : state.user,
  company : state.company,
});

const styles = theme => ({
  formGroup  : {
    border       : '1px solid #dadada',
    padding      : '12px',
    marginTop    : '6px',
    borderRadius : '2px',
  },
  groupTitle : {
    paddingLeft : '6px',
  },
});

export default withStyles(styles)(connect(mapStateToProps, actions)(injectIntl(CatastrophicPlanningPreference)));
