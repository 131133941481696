import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ViewAreaContainer from './ViewAreaContainer';

class GettingStartedBanner extends React.Component {
  constructor (props) {
    super(props);
    this.state = { selected: 'view_a_profile' };
  }

  renderLastButton = () => {
    const { currentUser } = this.props;
    const { selected } = this.state;
    switch (currentUser.welcome_current_interest) {
      case 'growing_my_business':
        return (
          <div
            role="button"
            className="universal-button"
            style={selected === 'see_practice_metrics' ? { border: '4px solid #1396e2' } : {}}
            onClick={() => this.handleSelect('see_practice_metrics')}>
            <span>
              <figure className="fa fa-comment-dots" style={{ color: '#1396e2' }} /> See Practice Metrics
            </span>
            <span className="arrow fa fa-angle-right" />
          </div>
        );
      case 'establish_business_continuity':
        return (
          <div
            role="button"
            className="universal-button"
            style={selected === 'build_a_plan' ? { border: '4px solid #1396e2' } : {}}
            onClick={() => this.handleSelect('build_a_plan')}>
            <span>
              <figure className="fa fa-comment-dots" style={{ color: '#1396e2' }} /> Build a Plan
            </span>
            <span className="arrow fa fa-angle-right" />
          </div>
        );
      case 'finding_a_successor':
      case 'selling_my_business':
        return (
          <div
            role="button"
            className="universal-button"
            style={selected === 'build_a_deal_room' ? { border: '4px solid #1396e2' } : {}}
            onClick={() => this.handleSelect('build_a_deal_room')}>
            <span>
              <figure className="fa fa-comment-dots" style={{ color: '#1396e2' }} />{' '}
              {`Build a ${currentUser.company.deal_room_branded_name}`}
            </span>
            <span className="arrow fa fa-angle-right" />
          </div>
        );
      case 'buying_a_business':
        return (
          <div
            role="button"
            className="universal-button"
            style={selected === 'send_a_message' ? { border: '4px solid #1396e2' } : {}}
            onClick={() => this.handleSelect('send_a_message')}>
            <span>
              <figure className="fa fa-comment-dots" style={{ color: '#1396e2' }} /> Send a Message
            </span>
            <span className="arrow fa fa-angle-right" />
          </div>
        );
      default:
        return (
          <div
            role="button"
            className="universal-button"
            style={selected === 'see_practice_metrics' ? { border: '4px solid #1396e2' } : {}}
            onClick={() => this.handleSelect('see_practice_metrics')}>
            <span>
              <figure className="fa fa-comment-dots" style={{ color: '#1396e2' }} /> See Practice Metrics
            </span>
            <span className="arrow fa fa-angle-right" />
          </div>
        );
    }
  };

  handleSelect = clicked => {
    this.setState({ selected: clicked });
  };

  render () {
    const { classes, currentUser } = this.props;
    const { selected } = this.state;
    return (
      <div className="content-card" style={{ padding: 30 }}>
        <div className={classes.title}>Get ready to succeed! Try these tips to get started.</div>
        <hr />
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <div
              role="button"
              className="universal-button"
              style={selected === 'view_a_profile' ? { border: '4px solid #1396e2' } : {}}
              onClick={() => this.handleSelect('view_a_profile')}>
              <span>
                <figure className="fa fa-user-circle" style={{ color: '#1396e2' }} /> View a Profile
              </span>
              <span className="arrow fa fa-angle-right" />
            </div>
            <div
              role="button"
              className="universal-button"
              style={selected === 'enhance_profile' ? { border: '4px solid #1396e2' } : {}}
              onClick={() => this.handleSelect('enhance_profile')}>
              <span>
                <figure className="fa fa-pencil-alt" style={{ color: '#1396e2' }} /> Enhance Profile
              </span>
              <span className="arrow fa fa-angle-right" />
            </div>
            <div
              role="button"
              className="universal-button"
              style={selected === 'review_valuation' ? { border: '4px solid #1396e2' } : {}}
              onClick={() => this.handleSelect('review_valuation')}>
              <span>
                <figure className="fa fa-calculator" style={{ color: '#1396e2' }} /> Review Valuation
              </span>
              <span className="arrow fa fa-angle-right" />
            </div>
            {this.renderLastButton()}
          </Grid>
          <Grid item xs={7}>
            <ViewAreaContainer selectedView={selected} dealRoomName={currentUser.company.deal_room_branded_name} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

GettingStartedBanner.propTypes = {
  currentUser : PropTypes.object,
  classes     : PropTypes.object.isRequired,
};

GettingStartedBanner.defaultProps = {
  currentUser : {},
};

const styles = () => ({
  title    : {
    fontSize   : '24px',
    fontWeight : 100,
    width      : '70%',
  },
  subTitle : {
    fontSize   : '18px',
    fontWeight : 100,
    width      : '70%',
  },
  button   : {
    float           : 'right',
    textTransform   : 'none',
    backgroundColor : '#1396e2',
  },
  avatar   : {
    height : 125,
    width  : 125,
  },
  selected : {
    backgroundColor : '#1396e2',
  },
});

export default withStyles(styles)(GettingStartedBanner);
