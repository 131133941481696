import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import './PlanningInformation.css';

const UserSuccessionPlanning = ({user, updateAction, company}) => {
  const successionPlanningStates = get(company, 'succession_planning_states', []); 

	return(<>
    <span>Do you have a business continuity plan in the event of your death or disability?</span> 
    <Select
      fullWidth
      className="PlanningInformation__radio" 
      value={`${get(user, 'succession_planning_state_id')}`}
      onChange={e => {
          updateAction({succession_planning_state_id: parseInt(e.target.value) })
        }} 
      >
      {successionPlanningStates.map(sps =>
        <MenuItem
          value={sps.id}
          className="PlanningInformation__radio"
          label={sps.name} >
          {sps.name}
        </MenuItem>)
      }
    </Select>
	</>);
};

UserSuccessionPlanning.propTypes = {
  user : PropTypes.object,
  updateAction: PropTypes.func.isRequired,
  company: PropTypes.object,
};

UserSuccessionPlanning.defaultProps = {
  user: {},
  company: {},
};

export default UserSuccessionPlanning;
