import React from 'react';
import DeleteIcon from "@material-ui/icons/Delete";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import IconButton from "@material-ui/core/Button";

const DeletePayoutMutation = gql`
  mutation deleteCashFlowCompensationLookup(
    $id: ID!
  ) {
     deleteCashFlowCompensationLookup(id: $id)
  }
`

const DeleteButton = ({id, setRows, payoutGrids}) => (
  <Mutation mutation={DeletePayoutMutation}>
    {(deletePayout) => (
      <IconButton onClick={() => {deletePayout({variables: {id}}).then(result => {
        const index = payoutGrids.findIndex(object => {
          return object.id === id;
        });
        let newRows = [...payoutGrids];
        newRows.splice(index, 1);
        setRows(newRows);
      })}}><DeleteIcon color="error" /></IconButton>
    )}
  </Mutation>
);

export default DeleteButton;