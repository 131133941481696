import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import * as actions from '../../actions/opportunityActionCreators';
import { get } from 'lodash';
import { Redirect } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import TalentSelection from '../../components/OpportunityAdmin/TalentSelection/TalentSelection';
import TalentFilter from '../../components/OpportunityAdmin/TalentFilter/TalentFilter';
import AdminWrapper from '../../components/AdminWrapper/AdminWrapper';

const TalentSelectionQueries = gql`
  query TalentSelectionQueries {
    getInventoryUsers(limit: 1000, offset: 0) {
      id
      name
      birth_date
      province
      rev_yield
      clients
      total_premium
      total_assets
      key_asset
      risk_of_loss
      impact_of_loss
      profile_picture
      transition_goals
      has_user
      user {
        headline
        bio
        annual_revenue
        recurring_revenue
        assets_under_management
        number_of_clients
      }
    }
  }
`;

export const TalentSelectionContainer = ({ data, updateOpportunity, updateFilter, filter, opportunity }) => {
  if (!opportunity.opportunity_type) {
    return <Redirect to="new" />;
  }

  return (
    <div>
      <TalentFilter updateAction={updateFilter} filter={filter} opportunityType={opportunity.opportunity_type} />
      <AdminWrapper title="Create Opportunity - Talent Pool">
        {data.loading ? (
          <center>
            <CircularProgress size={68} style={{ margin: 150 }} />
          </center>
        ) : (
          <TalentSelection
            inventoryUsers={data.getInventoryUsers}
            userFilter={filter}
            updateAction={updateOpportunity}
            opportunity={opportunity}
          />
        )}
      </AdminWrapper>
    </div>
  );
};

const mapStateToProps = state => ({
  opportunity : get(state.opportunities, 'opportunity', {}),
  filter      : get(state.opportunities, 'filter', {}),
  intl        : state.intl,
});

export default connect(mapStateToProps, actions)(graphql(TalentSelectionQueries)(TalentSelectionContainer));
