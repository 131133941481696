import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import initials from 'initials';
import { bgColorForName } from '../../lib/findBobUtils';
import { S3Image } from '../../lib/s3';

export const InventoryUserAvatar = ({ inventoryUser, classes }) => (
  <Avatar
    size="24"
    name={inventoryUser.name}
    src={inventoryUser.has_user ? S3Image(inventoryUser.profile_picture) : null}
    className={classes.avatar}
    style={{ backgroundColor: bgColorForName(inventoryUser.name) }}>
    <span className={classes.avatarText}>{initials(inventoryUser.name)}</span>
  </Avatar>
);

InventoryUserAvatar.propTypes = {
  inventoryUser : PropTypes.object,
  classes       : PropTypes.object.isRequired,
};

InventoryUserAvatar.defaultProps = {
  inventoryUser : {},
};

const styles = {
  avatar     : {
    height      : 30,
    width       : 30,
    display     : 'inline-flex',
    marginRight : 4,
    fontSize    : 10,
  },
  avatarText : {
    textTransform : 'uppercase',
  },
};

export default withStyles(styles)(InventoryUserAvatar);
