import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Mutation } from 'react-apollo';
import { get, remove, isEmpty } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl  } from 'react-intl';
import FileUploadDropZone from '../FileUploadDropZone/FileUploadDropZone';
import { createDocumentMutation, deleteDocumentMutation } from '../Document/DocumentQueries';
import FileCard from './FileCard';
import { Select, MenuItem, InputLabel, FormControl, Card } from '@material-ui/core';

class FileTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      documents: props.documents, 
      variables: props.variables, 
      uploading: false, 
      category: 'other' };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.variables) !== JSON.stringify(nextProps.variables)) {
      this.setState({ documents: nextProps.documents });
    }
  }

  createDoc = ({ files, createDocument }) => {
    const { variables } = this.props;
    const { category } = this.state;

    let fileName = '';

    this.setState({ uploading: true });

    files.map(file => {
      fileName = file.name;
      createDocument({ variables: { file: [file], name: fileName, category, ...variables } })
        .then(resp => {
          this.setState({ uploading: false });
        })
        .catch(error => console.log('Error: ', error));
    });
  };

  render() {
    const { classes, variables, dropMessage, callBack, disableUpload, company, disableFileSelection, intl } = this.props;
    const { documents, uploading, category,  } = this.state;

    return (
      <div>
        <div className={classes.header}><FormattedMessage id="file_tab.files" /> ({documents.length})</div>
        <Mutation
          mutation={createDocumentMutation}
          context={{ hasUpload: true }}
          onCompleted={response => {
            if (get(response, 'createDocument.id', -1) > 0) {
              let docs = documents;
              docs.unshift(response.createDocument);
              this.setState({
                documents: docs,
              },
              callBack(response));
            }
          }}>
          {createDocument => {
            if (disableUpload)
              return <span></span>
            return <Card variant="elevation" className={classes.uploadCard}>
              <h4><FormattedMessage id="file_tab.file_upload" /></h4> 
              {get(company, 'file_import_disclaimer') && <p>
                {intl.locale == 'fr' ? get(company, 'file_import_disclaimer_fr', '') : get(company, 'file_import_disclaimer', '')}
                </p>}
              {disableFileSelection == false && <FormControl variant="outlined" fullWidth>
                <InputLabel id="select-document-type"><FormattedMessage id="file_tab.select_document_type" /></InputLabel>
                <Select
                  labelId="select-document-type"
                  type="select"
                  fullWidth
                  value={isEmpty(category) ? null : category}
                  label="Select Document Type"
                  onClick={e => { this.setState({ category: e.target.value }) }}>
                    {['other', 'purchase_agreements', 'employment_agreements', 'contracts', 'organizational', 'letter_of_intent'].map(v => <MenuItem value={v}><FormattedMessage id={`file_tab.${v}`} /></MenuItem>)}
                </Select>
              </FormControl>}
              
              {category &&
                <FileUploadDropZone
                  createDoc={({ files }) => this.createDoc({ files, createDocument })}
                  dropMessage={dropMessage}
                  uploading={uploading}
                />
              }
            </Card>
          }
        }
        </Mutation>
        <br />
        <Mutation
          mutation={deleteDocumentMutation}
          onCompleted={response => {
            remove(documents, d => d.id === response.deleteDocument);
            this.setState({ documents });
            document.location.reload();
          }}>
          {deleteDoc => {
            return (documents || []).map(d => <FileCard document={d} deleteDoc={deleteDoc} />);
          }}
        </Mutation>
      </div>
    );
  }
}

FileTab.propTypes = {
  classes: PropTypes.object.isRequired,
  variables: PropTypes.object,
  documents: PropTypes.array,
  dropMessage: PropTypes.string,
  callBack: PropTypes.func,
  disableUpload: PropTypes.bool,
  disableFileSelection: PropTypes.bool,
};

FileTab.defaultProps = {
  variables: {},
  documents: [],
  dropMessage: "Drag 'n' drop some files here, or click to select files",
  callBack: () => {},
  disableUplaod: false,
  disableFileSelection: false,
};

const styles = {
  header: {
    padding: '20px 0 12px 0',
    display: 'flex',
  },
  uploadCard: {
    padding: 10,
  },
};

const mapStateToProps = state => ({
  company : state.company,
});

export default injectIntl(connect(mapStateToProps)(withStyles(styles)(FileTab)));
