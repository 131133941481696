import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import StrengthsAndAvatar from './StrengthsAndAvatar';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($designations: [String!], $tag_list: [String!], $profile_picture: String) {
    updateCurrentUser(designations: $designations, tag_list: $tag_list, profile_picture: $profile_picture)
  }
`;

export default graphql(UpdateCurrentUser)(StrengthsAndAvatar);
