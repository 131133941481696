import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Redirect } from 'react-router';
import gql from 'graphql-tag';
import { graphql, Query } from 'react-apollo';
import client from '../lib/apolloClient';
import AdminWrapper from '../components/AdminWrapper/AdminWrapper';
import NewOpportunityDetails from '../components/OpportunityAdmin/NewOpportunityDetails';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as actions from '../actions/opportunityActionCreators';

const OpportunityQuery = gql`
  query OpportunityQuery($id: ID) {
    getOpportunity(id: $id) {
      id
      title
      description
      views
      status
      city
      province
      inquiries
      requirements
      expiry_date
      user {
        id
        name
        birth_date
        province
        rev_yield
        clients
        total_premium
        total_assets
        key_asset
        risk_of_loss
        impact_of_loss
        profile_picture
        transition_goals
        has_user
        user {
          headline
          bio
          annual_revenue
          recurring_revenue
          assets_under_management
          number_of_clients
        }
      }
      opportunity_type {
        id
        name
        icon_url
        background_url
      }
    }
    getOpportunityTypes {
      id
      name
      icon_url
      background_url
    }
  }
`;

const EditOpportunity = gql`
  mutation editOpportunity(
    $id: ID!
    $title: String
    $description: String
    $expiry_date: String
    $requirements: String
    $user_id: ID
    $opportunity_type_id: ID
  ) {
    editOpportunity(
      id: $id
      title: $title
      description: $description
      expiry_date: $expiry_date
      requirements: $requirements
      user_id: $user_id
      opportunity_type_id: $opportunity_type_id
    )
  }
`;
/*
        */
const EditOpportunityContainer = props => (
  <AdminWrapper title="Opportunity - Edit">
    <Query query={OpportunityQuery} variables={{ id: props.match.params.id }}>
      {({ loading, error, data }) => {
        if (loading) return <CircularProgress />;
        if (error) return `Error!: ${error}`;
        if (!props.opportunity.id) {
          props.updateOpportunity(data.getOpportunity);
          return <div />;
        }

        return (
          <NewOpportunityDetails
            {...props}
            loading={loading}
            error={error}
            opportunityTypes={data.getOpportunityTypes}
          />
        );
      }}
    </Query>
  </AdminWrapper>
);

const mapStateToProps = state => ({
  opportunity : get(state.opportunities, 'opportunity', {}),
  intl        : state.intl,
});

export default connect(mapStateToProps, actions)(graphql(EditOpportunity)(EditOpportunityContainer));
