import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { S3Image } from '../../lib/s3';

const ProfilePicture = ({ pictureUrl, userName, userIsStudent, userCompanyEnabledStudentProfiles, disableClick, userCompanyStudentWord }) => (
  <div style={{ position: 'relative', display: 'inline-block' }}>
    <a>
      <img src={S3Image(pictureUrl)} alt={userName} className="avatar" />
    </a>
    {userIsStudent && userCompanyEnabledStudentProfiles && (
      <figure className="ribbon" style={{ backgroundColor: '#333333' }}>{userCompanyStudentWord}</figure>
    )}
  </div>
);

ProfilePicture.propTypes = {
  pictureUrl: PropTypes.string,
  userName: PropTypes.string,
};

export default ProfilePicture;
