import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/dealActionCreators';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Grid, Paper, TextField } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { get, debounce, filter, lowerCase, map } from 'lodash';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FormLabel from '@material-ui/core/FormLabel';
import { injectIntl } from 'react-intl';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import NewDealDialog from './NewDealDialog';
import { interestsChip } from '../InventoryUserDrawer/OverviewUserData';
import CircularProgress from '@material-ui/core/CircularProgress';


export const formatDealFilter = (dealsFilter = {}) => {
  let filter = {};
  Object.keys(dealsFilter).map(filterKey => {

    if ( ['invitation_deadline',
      'matches_count',
      'number_of_invites',
      'number_of_proposals',
      'number_of_ndas',
      'number_of_negotiations',
      'closed',
      'created_at',].includes(filterKey)) {
      filter[filterKey] = {gt: get(dealsFilter[filterKey], '[0]')};
    }
    else if(filterKey === 'owner_name') {
      filter[filterKey] = dealsFilter[filterKey].map(owner_name => lowerCase(owner_name));
    }else
      filter[filterKey] = dealsFilter[filterKey];
  });
 
  return filter;
};

const styles = theme => ({
  root       : {
    fontSize : '18px',
    width    : 'inherit',
    display  : 'block',
  },
  paper      : {
    padding : 12,
  },
  control    : {
    padding : theme.spacing.unit * 2,
  },
  title      : {
    fontSize : 24,
    color    : '#dadada',
  },
  saveButton : {
    position : 'fixed',
    right    : 30,
    bottom   : 130,
    zIndex   : 1000,
  },
  success    : {
    display    : 'flex',
    alignItems : 'center',
  },
  icon       : {
    fontSize    : 20,
    marginRight : 8,
  },
  dashButton : {
    float : 'right',
  },
  fullWidth  : { width: 'inherit' },
  demo       : { display: 'block' },
});

class Deals extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      order       : 'asc',
      orderBy     : 'id',
      page        : 0,
      rowsPerPage : 10,
      nameSearch  : '',
    };

    this.changePage = debounce(this.changePage, 400);
  }

  sortOrder = col => {
    const { orderBy, order } = this.state;
    if (orderBy === col) {
      return order;
    }
    return 'none';
  };

  options = () => {
    const { deals, updateDeals } = this.props;
    const { count, nameSearch, rowsPerPage, page, orderBy, order } = deals;

    return {
      serverSide          : true,
      selectableRows      : 'none',
      sortOrder           : {
        name      : orderBy,
        direction : order,
      },
      // Calling the applyNewFilters parameter applies the selected filters to the table 
      onColumnSortChange  : (name, direction) => {
        updateDeals({ orderBy: name, order: direction }, this.changePage);
      },
      rowsPerPage,
      onRowClick          : (data, meta, e) => {
        if (e.target.localName !== 'a') {
          window.open(`${data[data.length - 1].props.href}?new_task=true`, '_self');
        }
      },
      responsive          : 'vertical',
      onChangePage        : e => {
        updateDeals({ page: e }, this.changePage());
      },
      onChangeRowsPerPage : e => {
        updateDeals({ rowsPerPage: e }, this.changePage());
      },
      onColumnSortChange  : (e, d) => {
        updateDeals({ orderBy: e, order: d }, this.changePage());
      },
      customSearch: (searchQuery, currentRow, columns) => {
        this.changePage();
      
        return true;
      },
      onSearchChange      : e => {
        updateDeals({ nameSearch: e })
       

      },
      onFilterConfirm: (filterList) => {
        console.dir(filterList);
      },
      onFilterChange      : (e, f, g) => {
        const cols = this.columns().map(c => c.id);
        let filters = {};
        for (let i = 0; i < cols.length; i++) {
          if (f[i].length > 0) {
            filters[cols[i]] = f[i];
          }
        }
        updateDeals({ dealsFilter: filters });
        this.changePage();
      },
      onFilterChipClose: (index, removedFilter, filterList) => {
        const cols = this.columns().map(c => c.id);
        let filters = {};
    
        for (let i = 0; i < cols.length; i++) {
          if (filterList[i].length > 0 && cols[i] !== removedFilter) {
            filters[cols[i]] = filterList[i];
          }
        }
        updateDeals({ dealsFilter: filters });
        //this.changePage();
      },
      page,
      count,
      searchText          : nameSearch,
    };
  };

  changePage = () => {
    const { updateDeals, refetch, deals } = this.props;
    const { page, rowsPerPage, orderBy, order, nameSearch, dealsFilter } = deals
    
    refetch({
      page,
      limit       : rowsPerPage,
      orderBy,
      order,
      nameSearch,
      dealsFilter: formatDealFilter(dealsFilter),
    });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides : {
        MUIDataTableBodyCell : {
          root : {
            fontSize : 10,
          },
        },
        MUIDataTable         : {
          responsiveScroll : {
            height    : 'calc(100vh - 210px)',
            maxHeight : 'calc(100vh - 210px) !important',
          },
        },
      },
    });

  componentWillReceiveProps = nextProps => {
    const { loading } = this.props;
    if (loading && !nextProps.loading) {
      nextProps.updateDeals(nextProps.getDeals);
    }
  };

  printError = errors => {
    return <h2>{errors.request.responseText}</h2>;
  };

  columns = classes => {
    const { allCustomers } = this.props;
    const inventoryUsersAllCustomers = [...new Set(Object.values(allCustomers || {}).map(inventoryUser => inventoryUser.name))];

    return [
      { name: 'Name', id: 'name', options: { filter: false, sortOrder: this.sortOrder('Name') } },
      { name: 'ID', id: 'id', options: { filter: false, sortOrder: this.sortOrder('ID')  } },
      { name: 'Type', id: 'deal_type', 
        options: { 
          filter: true,
          sortOrder: this.sortOrder('Type'),
          filterOptions: {names: ['continuity', 'succession', 'sale'] },
        } 
      },
      { name: 'Creation Date', id: 'created_at', options: { 
        filter: true, 
        filterType: 'custom',
        sortOrder: this.sortOrder('Creation Date'),
        filterOptions: {
          names: [],
          logic: (date, filters) => {
            return date;
          },
          display: (filterList, onChange, index, column) => {
            return (
              <TextField
                label="Creation Date"
                type="date"
                fullWidth
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                value={filterList[index][0] || ''}
                onFocus={event => {
                  const date = moment(event.target.value).format('YYYY-MM-DD');
            
                  filterList[index][0] = date;
                  if(moment(event.target.value).isValid()) {
                    onChange(filterList[index], index, column);
                    this.changePage();
                  }
                }}
                onChange={event => {
                  const date = moment(event.target.value).format('YYYY-MM-DD');
            
                  filterList[index][0] = date;
                  if(moment(event.target.value).isValid()) {
                    onChange(filterList[index], index, column);
                    this.changePage();
                  }
                }}
                /> 
          )},
        },
      }},
      { name: 'Deadline', id: 'invitation_deadline', options: { filter: false, sortOrder: this.sortOrder('Deadline') } },
      {
        name    : 'Transition Goals',
        id      : 'transition_goals',
        options : {
          customBodyRender : (value, tabeMeta, updateValue) => value.map(v => interestsChip(v, classes)),
          filter           : false,
          sort             : false,
        },
      },
      {
        name    : 'Matching',

        id      : 'matches_count',
        options : { filter: false, sort: false },
      },
      {
        name    : 'Invites',

        id      : 'number_of_invites',
        options : { filter: false, sort: false },
      },
      {
        name    : 'Proposals',
        id      : 'number_of_proposals_geqt_any',
        options : { filter: false, sort: false },
      },
      {
        name    : 'No. of NDAs',
        id      : 'number_of_ndas',
        options : { filterType: 'textField', filter: false, sort: false },
      },
      {
        name    : 'No. Negotiations',
        id      : 'number_of_negotiations',
        options : { filter: false, sort: false },
      },
      {
        name: 'Owner',
        id  : 'owner_name',
        options: {
          filter: true,
          filterOptions: {
            names: inventoryUsersAllCustomers,
            renderValue: val => {
              if (val === "" || val === null || val === undefined) {
                return "(none)";
              }
              return val;
            },
          },
          filterType: "multiselect",
          responsive: "scroll",
          sortOrder: this.sortOrder('Owner'),
        },
      },
      {
        name: 'Closed',
        options: { filter: true, sortOrder: this.sortOrder('Closed') },
        id: 'closed',
      },
      {
        name    : 'Status',
        id      : 'deal_state',
        options : {
          sortOrder        : this.sortOrder('Status'),
          filter           : true,
          customBodyRender : (value, tabeMeta, updateValue) =>
            value === 'new_deal' ? (
              <Chip label="Unpublished" />
            ) : (
              <Chip label="Published" style={{ backgroundColor: '#28a745', color: 'white' }} />
            ),
          filterOptions: {
            names: ['new_deal', 'published'], renderValue: v => v === 'new_deal' ? 'Editing' : 'Published' 
          },
          customFilterListOptions: {
            render: v => v == 'new_deal' ? 'Editing' : 'Published',
          },
        },
      },
      {
        name     : 'Action',
        sort     : false,
        filter   : false,
        download : false,
        options  : {
          filter           : false,
          sort             : false,
          customBodyRender : (v, t, u) => <a href={`/admin/deals/${v}`}>View</a>,
        },
      },
    ];
  };

  formatRow = deal => [
    get(deal, 'inventory_user.name', ''),
    deal.deal_name,
    deal.deal_type,
    deal.creation_date,
    deal.invitation_deadline,
    deal.inventory_user.transition_goals,
    deal.matches_count,
    deal.number_of_invites,
    deal.number_of_proposals,
    deal.number_of_ndas,
    deal.number_of_negotiations,
    get(deal, 'owner.name', ''),
    deal.closed ? 'true' : 'false',
    deal.deal_state,
    deal.id,
  ];

  render () {
    // if (data.loading) return <div />;
    const { classes, updateStatus, updateDeals, agentWordForCompany, deals, currentUser, loading } = this.props;
    const { data, status } = deals;
    let inv_user = get(currentUser, 'inventory_user', {});
    inv_user = { ...inv_user, label: inv_user.name, value: inv_user.id };
    const defaultDealOwnerCustomer = get(currentUser, 'company.default_deal_owner_customer', false);

    return (
      <section>
        {loading && <div><CircularProgress style={{position: 'absolute', left: '47%', top: '47%',}}/></div>}
        <Snackbar
          anchorOrigin={{
            vertical   : 'top',
            horizontal : 'center',
          }}
          open={status}
          autoHideDuration={6000}
          aria-describedby="client-snackbar"
          className={classes.success}
          onClose={() => updateStatus(null)}
          message={
            <span id="client-snackbar" className={classes.success}>
              <CheckCircleIcon className={classes.icon} />
              {status}
            </span>
          }
        />
        <Paper className={classes.paper}>
          <Grid container className={classes.root}>
            <Grid container className={classes.root}>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  title=""
                  data={(data || []).map(d => this.formatRow(d))}
                  columns={this.columns(classes)}
                  options={this.options()}
                />
              </MuiThemeProvider>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ position: 'absolute', bottom: 134, right: 24 }}>
          <NewDealDialog
            buttonType="extendedFab"
            updateDeals={updateDeals}
            currentInventoryUser={inv_user}
            defaultDealOwnerCustomer={defaultDealOwnerCustomer}
            deals={data}
            agentWordForCompany={agentWordForCompany}
          />
        </div>
      </section>
    );
  }
}

Deals.propTypes = {
  deals               : PropTypes.object,
  classes             : PropTypes.object,
  data                : PropTypes.object.isRequired,
  getDeals            : PropTypes.object.isRequired,
  updateDeals         : PropTypes.func.isRequired,
  updateStatus        : PropTypes.func.isRequired,
  refetch             : PropTypes.func.isRequired,
  state               : PropTypes.object.isRequired,
  loading             : PropTypes.bool.isRequired,
  agentWordForCompany : PropTypes.string.isRequired,
  count               : PropTypes.number,
  allCustomers        : PropTypes.func.isRequired,
};

Deals.defaultProps = {
  deals   : [],
  classes : {},
  count   : 0,
};

const mapStateToProps = state => ({
  state,
  deals : state.deals,
  intl  : state.intl,
});

export default injectIntl(withStyles(styles)(connect(mapStateToProps, actions)(Deals)));
