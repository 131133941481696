import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import ProductMix, { aumList, insuranceList, ifpOptions, aumOptions } from './ProductMix';

export { canadianRetailWealthAumList, pAndCProductAumList, aumList, insuranceList, ifpOptions, aumOptions } from './ProductMix';

const UpdateCurrentUser = gql`
  mutation updateCurrentUser(
    $product_mix: ProductMixInput
    $advisor_licenses: [String!]
    $assets_under_management: Float
  ) {
    updateCurrentUser(
      product_mix: $product_mix
      advisor_licenses: $advisor_licenses
      assets_under_management: $assets_under_management
    )
  }
`;

export default graphql(UpdateCurrentUser)(ProductMix);
