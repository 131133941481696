import React from 'react';
import numeral from 'numeral';
import { RevenueMix, CompensationMix, ProductMix, OldProductMix } from '../UserCharts';
import { FormattedMessage } from 'react-intl';
import { get, find, keys, forEach, isEmpty } from 'lodash';
import { ifpOptions, aumOptions } from '../SignUpWizard/ProductMix';
import { Bling as GPT } from 'react-gpt';
import { interest_targets } from '../../lib/userUtils.jsx';
import { injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';

const renderBlurryInfo = (authorized, user, availableProducts, currentUserHasLimitedAccess, obfuscateView) => {
  if ((authorized && user.obfuscated) || (!user.is_pure_buyer && currentUserHasLimitedAccess) || obfuscateView) {
    return (
      <section id="agent-detail" className="content-card" style={{ height: 'inherit' }}>
        <div className="row">
          <div className="col-md-4">
            <h3>
              <FormattedMessage id="bobcard.user_details.revenue_mix" />
            </h3>
            <div style={{ filter: 'blur(8px)' }}>
              <RevenueMix
                recurring={user.percent_revenue_recurring}
                nonRecurring={user.percent_non_recurring_revenue}
                colorScale={[ '#002949', '#5489B6' ]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <h3>
              <FormattedMessage id="bobcard.user_details.compensation_mix" />
            </h3>
            <div style={{ filter: 'blur(8px)' }}>
              <CompensationMix
                feeBased={user.percent_fee_based}
                commissionBased={user.percent_commission}
                colorScale={[ '#002949', '#5489B6' ]}
              />
            </div>
          </div>
          <div className="col-md-4 product-mix">
            <h3>
              <FormattedMessage id="bobcard.user_details.product_mix" />
            </h3>
            <div style={{ filter: 'blur(8px)' }}>
              {user.product_mix ? (
                <ProductMix
                  life_ifp={user.product_mix.life_ifp}
                  home_ifp={user.product_mix.home_ifp}
                  auto_ifp={user.product_mix.auto_ifp}
                  commercial_ifp={user.product_mix.commercial_ifp}
                  health_ifp={user.product_mix.health_ifp}
                  group_ifp={user.product_mix.group_ifp}
                  mutual_funds_aum={user.product_mix.mutual_funds_aum}
                  securities_aum={user.product_mix.securities_aum}
                  annuities_aua={user.product_mix.annuities_aua}
                  segregated_funds_aua={user.product_mix.segregated_funds_aua}
                  mcpi_ifp={user.product_mix.mcpi_ifp}
                  private_products_ifp={user.product_mix.private_products_ifp}
                  crop_hail_ifp={user.product_mix.crop_hail_ifp}
                  crop_hail_with_wind_ifp={user.product_mix.crop_hail_with_wind_ifp}
                  farm_insurance_ifp={user.product_mix.farm_insurance_ifp}
                  price_products_ifp={user.product_mix.price_products_ifp}
                  replant_supplement_ifp={user.product_mix.replant_supplement_ifp}
                  availableProducts={availableProducts}
                  itemsPerRow={3}
                  width={600}
                />
              ) : (
                <OldProductMix
                  insurance={user.mix_insurance}
                  segregatedFunds={user.mix_annuities}
                  mutualFunds={user.mix_mutual_funds}
                  other={user.mix_other}
                />
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">&nbsp;</div>
        </div>
        <div className="col-md-8" />
      </section>
    );
  }
  return (
    <section id="agent-detail" className="content-card" style={{ height: 'inherit' }}>
      <div className="row">
        <div className="col-md-4 blurry-text">
          <section id="agent-testimonials">
            <h3>
              <FormattedMessage id="practice_info.practice_info" />
            </h3>
            <dl>
              <dt>
                <FormattedMessage id="bobcard.user_details.annual_revenue" />:
              </dt>
              <dd>$0.0</dd>
              <dt>
                <FormattedMessage id="bobcard.user_details.annual_revenue" />:
              </dt>
              <dd>$0.0</dd>
              <dt>
                <FormattedMessage id="bobcard.user_details.assets_under_management" />:
              </dt>
              <dd>$0.0</dd>
              <dt>
                <FormattedMessage id="bobcard.user_details.years_in_practice" />:
              </dt>
              <dd>0.0</dd>
              <dt>
                <FormattedMessage id="bobcard.user_details.number_of_clients" />:
              </dt>
              <dd>0.0</dd>
              <dt>
                <FormattedMessage id="bobcard.user_details.number_of_employees" />:
              </dt>
              <dd>0.0</dd>
            </dl>
          </section>
        </div>

        <div className="col-md-4">
          <h3>
            <FormattedMessage id="bobcard.user_details.revenue_mix" />
          </h3>
          <RevenueMix recurring={0} nonRecurring={0} colorScale={[ '#002949', '#5489B6' ]} />
        </div>
        <div className="col-md-4">
          <h3>
            <FormattedMessage id="bobcard.user_details.compensation_mix" />
          </h3>
          <CompensationMix feeBased={0} commissionBased={0} colorScale={[ '#002949', '#5489B6' ]} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">&nbsp;</div>
      </div>
    </section>
  );
};

const renderIfp = (user, s, intl) => {
  if (get(user, `product_mix.${s}`, false)) {
    return (
      <div>
        <dt>
          <FormattedMessage id={`bobcard.user_details.${s.replace(/_ifp|_aum|_aua/, '')}`} />
        </dt>
        <dd>
          {s.indexOf('_ifp') > -1 ? (
            get(find(ifpOptions(intl), { value: user.product_mix[s] }), 'label', null)
          ) : (
            get(find(aumOptions(intl), { value: user.product_mix[s] }), 'label', null)
          )}
        </dd>
      </div>
    );
  }
  return <div />;
};

const shouldRenderGroup = (user, valueNames) => {
  if (!user.product_mix) return false;
  let result = false;
  valueNames.some(name => {
    if (user.product_mix[name] > 0) {
      result = true;
      return true;
    }
  });
  return result;
};

const PracticeInfo = ({
  user,
  authorized,
  availableProducts,
  displayAds,
  intl,
  userIsAdmin,
  currentUserHasLimitedAccess,
  ownProfile,
  obfuscateView,
  companyBusinessType,
  theme,
  company,
}) => {
  const availableProductsObject = JSON.parse(availableProducts);
  let showProductMix = !get(user, 'company.skip_product_mix_on_signup');
  if (get(user, 'company.hide_product_mix_on_profile')) {
    showProductMix = false;
  }
  if (get(user, 'is_student') && get(user, 'company.enabled_student_profiles')) {
    showProductMix = false;
  }
  const useCanadianRetailWealth = get(user, 'company.use_canadian_retail_wealth_product_mix');
  const usePAndCProductMix = get(user, 'company.use_p_and_c_product_mix');
  const colorScale = [...theme.palette.graph];

  //const disableProductMixStep = get(this.props, 'data.company.skip_product_mix_on_signup');

  if (user.is_student && user.company.enabled_student_profiles) {
    return (
      <div id="agent-detail" className="content-card" style={{ height: 'inherit'}}>
        <div className="row" style={{ padding: 30 }}>
          <h3>
              <FormattedMessage id="profile_page.student_video" values={{ student_video: user.company.student_word }}  />
          </h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <video 
              width="560" 
              height="315" 
              controls
            >
              <source src={user.student_url_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    );
  }

  if (userIsAdmin || ownProfile || (authorized && !obfuscateView))
    return (
      <div id="agent-detail" className="content-card" style={{ height: 'inherit' }}>
        <div className="row">
          <div className={`${showProductMix || useCanadianRetailWealth ? 'col-md-4' : 'col-md-6'} revenue-mix`}>
            <h3>
              {companyBusinessType === 'wealth_rj' ? (
                <FormattedMessage id="profile_page.gross_production" />
              ) : (
                <FormattedMessage id="bobcard.user_details.revenue_mix" />
              )}
            </h3>
            <RevenueMix
              recurring={user.percent_revenue_recurring}
              nonRecurring={user.percent_non_recurring_revenue}
              colorScale={colorScale}
            />
          </div>
          <div className={`${showProductMix || useCanadianRetailWealth ? 'col-md-4' : 'col-md-6'} compensation-mix`}>
            <h3>
              <FormattedMessage id="bobcard.user_details.compensation_mix" />
            </h3>
            <CompensationMix
              feeBased={user.percent_fee_based}
              commissionBased={user.percent_commission}
              colorScale={colorScale}
            />
          </div>
          {(showProductMix || useCanadianRetailWealth) && (
            <div className="col-md-4 product-mix">
              <h3>
                <FormattedMessage id="bobcard.user_details.product_mix" />
              </h3>
              {user.product_mix ? (
                <ProductMix
                  life_ifp={user.product_mix.life_ifp}
                  home_ifp={user.product_mix.home_ifp}
                  auto_ifp={user.product_mix.auto_ifp}
                  commercial_ifp={user.product_mix.commercial_ifp}
                  health_ifp={user.product_mix.health_ifp}
                  group_ifp={user.product_mix.group_ifp}
                  mutual_funds_aum={user.product_mix.mutual_funds_aum}
                  securities_aum={user.product_mix.securities_aum}
                  annuities_aua={user.product_mix.annuities_aua}
                  segregated_funds_aua={user.product_mix.segregated_funds_aua}
                  mcpi_ifp={user.product_mix.mcpi_ifp}
                  private_products_ifp={user.product_mix.private_products_ifp}
                  crop_hail_ifp={user.product_mix.crop_hail_ifp}
                  crop_hail_with_wind_ifp={user.product_mix.crop_hail_with_wind_ifp}
                  farm_insurance_ifp={user.product_mix.farm_insurance_ifp}
                  price_products_ifp={user.product_mix.price_products_ifp}
                  replant_supplement_ifp={user.product_mix.replant_supplement_ifp}
                  gic_fixed_income={user.product_mix.gic_fixed_income}
                  insurance={user.product_mix.insurance}
                  equity={user.product_mix.equity}
                  availableProducts={availableProducts}
                  itemsPerRow={3}
                  width={600}
                  colorScale={colorScale}
                />
              ) : (
                <OldProductMix
                  insurance={user.mix_insurance}
                  segregatedFunds={user.mix_annuities}
                  mutualFunds={user.mix_mutual_funds}
                  other={user.mix_other}
                  colorScale={colorScale}
                />
              )}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">&nbsp;</div>
        </div>
        <div className="row">
          <div className="col-md-4">
            {displayAds && (
              <GPT
                adUnitPath="/21717728079/personal_profilepage_upper_bigbox"
                slotSize={[ 300, 250 ]}
                targeting={{ platform: window.location.hostname, interests: interest_targets(user) }}
              />
            )}
          </div>
          <div className="col-md-4" />
        </div>
      </div>
    );
  return renderBlurryInfo(authorized, user, availableProducts, currentUserHasLimitedAccess, obfuscateView);
};
export default withTheme(injectIntl(PracticeInfo));
