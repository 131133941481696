import { SUCCESSION_READINESS_UPDATE, SUCCESSION_READINESS_REFETCH } from '../constants/successionReadinessConstants';

export const updateSuccessionReadinesses = succesionReadiness => ({
  type  : SUCCESSION_READINESS_UPDATE,
  succesionReadiness,
});

export const refetchSuccessionReadiness = refetchFunc => ({
  type  : SUCCESSION_READINESS_REFETCH,
  refetchFunc: refetchFunc,
});
