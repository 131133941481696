import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ExpressInterestButton from '../../ExpressInterestButton/ExpressInterestButton';
import { FormattedMessage } from 'react-intl';

const renderMessageButton = (
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  sellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  scrollTo,
  classes,
  updateNotes,
  updateProfile
) => {
  if (marketplaceOnly) {
    return <div />;
  }
  if (disableMessageBtn) {
    return (
      <div>
        <Button variant="contained" disabled className={classes.button}>
          <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.message"/>
        </Button>
      </div>
    );
  }
  if ((user.obfuscated && hasInterestedUsers) || (currentUserHasLimitedAccess && hasInterestedUsers)) {
    return (
      <div>
        <Button variant="contained" className={classes.pressed}>
          <span style={{ marginRight: 12 }} /> <FormattedMessage id="profile_page.express_intrest_button.express_interest"/>
        </Button>
      </div>
    );
  }
  if (user.obfuscated && !hasInterestedUsers) {
    return (
      <div>
        <ExpressInterestButton
          user={user}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          fromBobCard
          updateNotes={updateNotes}
          updateProfile={updateProfile}
        />
      </div>
    );
  }
  if (currentUserHasLimitedAccess && currentUserHasSellingTransitionGoals && user.is_pure_buyer) {
    return (
      <div>
        <ExpressInterestButton
          user={user}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          fromBobCard
          currentUserHasLimitedAccess
          currentUserHasSellingTransitionGoals
          sellerWord={sellerWord}
          getCurrentUser={getCurrentUser}
          updateNotes={updateNotes}
          updateProfile={updateProfile}
        />
      </div>
    );
  }
  if (currentUserHasLimitedAccess) {
    return (
      <div>
        <ExpressInterestButton
          user={user}
          updateBobCards={updateBobCards}
          loadBobCardList={loadBobCardList}
          fromBobCard
          currentUserHasLimitedAccess
          sellerWord={sellerWord}
          updateNotes={updateNotes}
          updateProfile={updateProfile}
        />
      </div>
    );
  }
  return (
    <div>
      <Button variant="contained" onClick={() => scrollTo('chatRef')} className={classes.button}>
        <span style={{ marginRight: 12 }} /><FormattedMessage id="profile_page.message"/>
      </Button>
    </div>
  );
};

const ProfileCardMessageButton = ({
  user,
  currentUserHasLimitedAccess,
  hasInterestedUsers,
  updateBobCards,
  loadBobCardList,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  scrollTo,
  classes,
  updateNotes,
  updateProfile,
}) => (
  <div>
    {renderMessageButton(
      user,
      currentUserHasLimitedAccess,
      hasInterestedUsers,
      updateBobCards,
      loadBobCardList,
      currentCompanySellerWord,
      currentUserHasSellingTransitionGoals,
      getCurrentUser,
      disableMessageBtn,
      marketplaceOnly,
      scrollTo,
      classes,
      updateNotes,
      updateProfile
    )}
  </div>
);

ProfileCardMessageButton.propTypes = {
  classes                              : PropTypes.object.isRequired,
  user                                 : PropTypes.object.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  hasInterestedUsers                   : PropTypes.bool.isRequired,
  updateBobCards                       : PropTypes.func.isRequired,
  loadBobCardList                      : PropTypes.func.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  getCurrentUser                       : PropTypes.func.isRequired,
  updateProfile                        : PropTypes.func.isRequired,
  scrollTo                             : PropTypes.func.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  sellerWord                           : PropTypes.string.isRequired,
};

const styles = {
  button  : {
    width      : '100%',
    background : 'rgb(19, 150, 226)',
    color      : 'white',
    marginTop  : 5,
  },
  pressed : {
    width      : '100%',
    background : 'white',
    color      : 'rgb(19, 150, 226)',
    marginTop  : 5,
  },
};

export default withStyles(styles)(ProfileCardMessageButton);
