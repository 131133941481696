import { findIndex } from 'lodash';
import { UPDATE_BOBCARDS, LOAD_BOBCARDS } from '../constants/bobCardConstants';

const updateBobCardFunc = (bobCardsList, bobCard, modified = true) => {
  const index = findIndex(bobCardsList, e => e.id === bobCard.id);
  const bobCardsListNew = bobCardsList;
  const bobCardNew = bobCard;
  if (index !== -1) {
    if (modified) bobCardNew.modified = true;
    else bobCardNew.modified = false;
    bobCardsListNew[index] = bobCardNew;
  }
  return bobCardsListNew;
};
const bobCardReducer = (state = { data: [], loaded: false }, action) => {
  switch (action.type) {
    case LOAD_BOBCARDS:
      return { ...state, ...action.bobCardsSettings, loaded: true };
    case UPDATE_BOBCARDS:
      return {
        ...state,
        data : updateBobCardFunc(state.data, action.bobCard),
      };
    default:
      return state;
  }
};

export default bobCardReducer;
