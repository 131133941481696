/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Query } from "react-apollo";
import { get, startCase } from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import { NetworkStatus } from "apollo-client";
import { getCalibrationSessionQuery } from "../components/CalibrationSessions/CalibrationQuery";
import InventoryUserAvatar from "../components/InventoryUserAvatar/InventoryUserAvatar";
import CalibrationSession from "../components/CalibrationSessions/CalibrationSession";

const render_bool = (n, att) => {
  const val = get(n, att, null);
  if (val !== null) {
    return val === true ? "Yes" : "No";
  }
  return "-";
};

const columns = [
  {
    id: "name",
    dataType: "string",
    disablePadding: false,
    label: "Name",
    filter: false,
    customStyle: {
      position: "sticky",
      left: 72,
      zIndex: 10,
      background: "white",
    },
    customWidth: 150,
    renderMethod: (inventoryUser) => (
      <span>
        <span>
          <InventoryUserAvatar inventoryUser={inventoryUser} />
        </span>
        <span style={{ verticalAlign: "middle" }}>{inventoryUser.name}</span>
      </span>
    ),
  },
  {
    id: "key_asset",
    dataType: "string",
    disablePadding: false,
    label: "Key Asset?",
    filter: true,
    ransack_filter: "key_asset",
    filterOptions: [
      { name: "True", value: "true" },
      { name: "False", value: "false" },
    ],
    renderMethod: (n) => render_bool(n, "key_asset"),
  },
  {
    id: "risk_of_loss_num",
    dataType: "string",
    disablePadding: false,
    label: "Risk Of Loss",
    filter: true,
    ransack_filter: "risk_of_loss_num",
    filterOptions: [
      { name: "None", value: "0" },
      { name: "Low", value: "1" },
      { name: "Medium", value: "2" },
      { name: "High", value: "3" },
    ],
    renderMethod: (n) =>
      n.risk_of_loss_num
        ? startCase(n.risk_of_loss_num.replace("risk", ""))
        : "-",
  },
  {
    id: "impact_of_loss_num",
    dataType: "string",
    disablePadding: false,
    label: "Impact Of Loss",
    filter: true,
    ransack_filter: "impact_of_loss_num",
    filterOptions: [
      { name: "None", value: "0" },
      { name: "Low", value: "1" },
      { name: "Medium", value: "2" },
      { name: "High", value: "3" },
    ],
    renderMethod: (n) =>
      n.impact_of_loss_num
        ? startCase(n.impact_of_loss_num.replace("impact", ""))
        : "-",
  },
];

class CalibrationSessionsContainer extends React.Component {
  state = {
    filteredColumns: [],
    limit: 10,
    session: { },
    inventoryUsers: [],
    loading: true,
    filterList: {},
    orderBy: "id",
    order: "desc",
    offset: 0,
    company: {},
    name_cont: "",
  };

  render() {
    const {
      limit,
      offset,
      session,
      order,
      orderBy,
      inventoryUsers,
      tags,
      managers,
      company,
      name_cont,
    } = this.state;
    const {
      match: { params },
    } = this.props;

    return (
      <div>
        <Query
          query={getCalibrationSessionQuery}
          notifyOnNetworkStatusChange
          fetchPolicy="network-only"
          variables={{ id: params.session_id, orderBy, order, limit, offset, name_cont, }}
          onCompleted={(data) => {
            const c = columns;
            const fl = {};
            c.forEach((col) => (fl[col.id] = []));
            this.setState({
              session: data.getCalibrationSession,
              inventoryUsers: data.getCalibrationSession.inventoryUsers,
              loading: false,
              filterList: fl,
              filteredColumns: c,
              tags: get(data, "getInventoryUserTags", []),
              managers: get(data, "getManagers"),
              company: get(data, 'company',{})
            });
          }}
        >
          {({ loading, error, data, refetch, networkStatus }) => {
            if (!session || get(this.state, 'loading', true)) return <div style={{position: 'absolute', top: '48%', left: '48%'}}>
              <CircularProgress variant="query" />
            </div>;
            const { filterList, filteredColumns, company } = this.state;

            if(session.completedPopulating === false){
              return <div className="container">
                <p>
                  <h4>
                    Session is being populated. We will let you know via email when it's completed.
                  </h4>
                </p>
              </div>;
            }
            return (
              <div style={{ height: "100vh", overflow: "scroll" }}>
                <CalibrationSession
                  session={session}
                  inventoryUsers={inventoryUsers}
                  refetch={refetch}
                  updateState={(s) => this.setState({ ...s })}
                  allColumns={columns}
                  filterList={filterList}
                  filteredColumns={filteredColumns}
                  managers={managers}
                  tags={tags}
                  loading={loading}
                  order={order}
                  orderBy={orderBy}
                  offset={offset}
                  limit={limit}
                  company={company}
                />
                <div style={{ display: "block", height: 200 }} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default CalibrationSessionsContainer;
