import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Mutation } from "react-apollo";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {withApollo} from "react-apollo";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";
import { isEmpty, get, debounce } from "lodash";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ManagerSearchField from "../../containers/ManagerSearchFieldContainer";
import ValueColumnSelector from "./ValueColumnSelector";
import { createCalibrationSessionMutation, checkInventoryUserCountQuery } from "./CalibrationQuery";
import { regionForCountry } from "../../lib/findBobUtils";
import {connect} from "react-redux";

class NewCalibrationSessionDialog extends React.Component {
  originalState = {
    open: false,
    name: "",
    start_date: moment().format("YYYY-MM-DD"),
    value_column_name: "",
    participants: [],
    facilitators: [],
    advisor_filter: {},
    ageMin: null,
    ageMax: null,
    start_date_min: null,
    start_date_max: null,
    state: [],
    managerPicked: false,
    provincePicked: false,
    yearsWithCompanyPicked: false,
    agePicked: false,
    inventoryUserCount: null,
    industry_length_of_service_min: null,
    industry_length_of_service_max: null,
    managed_by_user_id: null,
  };

  state = { ...this.originalState };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    window.history.back();
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  prepareStateForMutation = (delta = {}) => {
    const deltaState = {...this.state, ...delta};
    const {
      state,
      start_date,
      participants,
      facilitators,
      advisor_filter,
      managed_by_user_id,
      start_date_min,
      start_date_max,
      age_min,
      age_max,
      name,
      division,
      region,
      branch,
      value_column_name,
      catastrophic_plan_on_file,
      industry_length_of_service_min,
      industry_length_of_service_max,
    } = deltaState;
    let managedUsers = [];

    if (managed_by_user_id) {
      managedUsers = managed_by_user_id.map((user) => {
        if (user.id) {
          return user.id;
        }
      });
    }

    let mutationVariables = {
      name,
      start_date_min: start_date_min ? parseInt(start_date_min, 10) : null,
      start_date_max: start_date_max ? parseInt(start_date_max, 10) : null,
      age_min: age_min ? parseInt(age_min, 10) : null,
      age_max: age_max ? parseInt(age_max, 10) : null,
      value_column_name,
      calibration_participants: participants.map((p) => p.id),
      calibration_facilitators: facilitators.map((f) => f.id),
      managed_by_user_id: managedUsers,
      state,
      branch,
      region,
      division,
      catastrophic_plan_on_file,
      start_date,
      industry_length_of_service_min: industry_length_of_service_min ? parseInt(industry_length_of_service_min, 10) : null,
      industry_length_of_service_max: industry_length_of_service_max ? parseInt(industry_length_of_service_max, 10) : null,
    };

    // compact the mutation variables to remove null values
    return Object.keys(mutationVariables).reduce((acc, key) => {
      // check if value not null and if it is an array then the array is not empty
      if (mutationVariables[key] && (Array.isArray(mutationVariables[key]) ? mutationVariables[key].length : true)) {
        acc[key] = mutationVariables[key];
      }else {
        if (mutationVariables[key] === false) {
          acc[key] = mutationVariables[key];
        }
      }
      return acc;
    }, {});
  }

  // Call graphql query that checks the amonut of inventory users will be it the calibration session
  checkInventoryUserCount = debounce((delta) => {
    const { country, client } = this.props;
    const region = regionForCountry(country);
    let newState = this.prepareStateForMutation(delta);

    client.query({
      query: checkInventoryUserCountQuery,
      variables: {
        ...newState,
      },
    })
    .then((response) => {
      const { data } = response;
      const { checkCalibrationPotentialInventoryUserCount  } = data;

      this.setState({ inventoryUserCount: checkCalibrationPotentialInventoryUserCount });
    })
    .catch((error) => {
      console.log(error);
    });
  }, 500);

  checkIfDisabled = () => {
    const { name, start_date, participants, facilitators, value_column_name, inventoryUserCount } = this.state;
    const parsedUserCount = parseInt(inventoryUserCount, 10);
    return (  !name ||
              isEmpty(participants) ||
              isEmpty(facilitators) ||
              parsedUserCount < 1 ||
              parsedUserCount > 1000
            );
  }

  render() {
    const { classes, refetch, buttonType, agentWordForCompany, company } = this.props;
    const {
      open,
      name,
      start_date,
      start_date_min,
      start_date_max,
      industry_length_of_service_min,
      industry_length_of_service_max,
      participants,
      facilitators,
      advisor_filter,
      value_column_name,
      managed_by_user_id,
      inventoryUserCount,
    } = this.state;

    const parsedUserCount = parseInt(inventoryUserCount, 10);

    return (
      <div style={{ position: 'relative', padding: 20,}}>
        <div>
          <h5 id="alert-dialog-title">
            Add a new calibration session
          </h5>
          <DialogContent className={classes.dialogBody}>
            <TextField
              id="standard-name"
              label="Name"
              fullWidth
              className={classes.textField}
              value={name}
              onChange={
                this.handleChange("name")
              }
              margin="normal"
            />
            <br />
            <br />
            <ManagerSearchField
              label="Participants"
              isMulti
              value={participants}
              onChange={(v) => {
                this.setState({ participants: v });
              }}
            />
            <ManagerSearchField
              label="Facilitators"
              isMulti
              value={facilitators}
              onChange={(v) => {
                this.setState({ facilitators: v });
              }}
            />
            <div>
              <br />
              <b>Start Date</b> &nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  value={start_date}
                  onChange={(date) => {
                    this.setState({ start_date: date });
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <br />
            <b>Calibrate value on</b> &nbsp;
            <ValueColumnSelector
              fullWidth
              value={value_column_name}
              onChange={(v) => {
                this.setState({
                  value_column_name: v.target.value,
                });
              }}
            />
            <br />
            <br />
            <br />
            <h5>{`Filter which ${
              agentWordForCompany || "advisor"
              }s will be calibrated (minimum one filter)`}</h5>
            <br />
            <div>
              {inventoryUserCount != null &&
                <div>
                  <b>Amount of advisors in this session: {inventoryUserCount}</b>
                  {parsedUserCount < 1 && <h5 style={{color: 'red'}}>
                    Too few users in this session. Please relax your filter criteria.
                  </h5>}
                </div>
              }
              {parsedUserCount > 1000 && <h5 style={{color: 'red'}}>
                Too many users in this session. Please refine your filter criteria.
              </h5>}
            </div>
            <br />
            <ManagerSearchField
              label="Managed By"
              isMulti
              value={managed_by_user_id}
              onChange={(v) => {
                this.setState({
                  managed_by_user_id: v,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            <br />
            <b>State/Province</b>
            <br />
            <Select
              label="Region"
              value={this.state.state || []}
              multiple
              fullWidth
              onChange={(v) => {
                const region = v.target.value;
                this.setState({
                  state: region,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            >
              {regionForCountry("getall", true).map((r) => (
                <MenuItem value={r}>{r}</MenuItem>
              ))}
            </Select>
            <br />
            <br />
            <b>Years with Company</b>
            <br />
            {"min: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  start_date_min: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            {"max: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  start_date_max: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            <br />
            <br />
            <b>Age</b>
            <br />
            {"min: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  age_min: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            {"max: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  age_max: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            <br />
            <br />
            <b>Industry Length Of Service</b>
            <br />
            {"min: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  industry_length_of_service_min: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            {"max: "}
            <TextField
              type="number"
              onChange={(e) => {
                this.setState({
                  industry_length_of_service_max: e.target.value,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }}
            />
            <br />
            <br />
            <b>Catastrophic Plan on File</b>
            <br />
            <Select
              label="Catastrophic Plan on File"
              value={this.state.catastrophic_plan_on_file}
              fullWidth
              onChange={(v) => {
                const catastrophic_plan_on_file = v.target.value;
                this.setState({
                  catastrophic_plan_on_file: catastrophic_plan_on_file,
                  catastrophic_plan_on_file_selected: true,
                }, () => {
                  this.checkInventoryUserCount();
                });
              }
            }>
              {[{label: "Yes", value: true}, {label: "No", value: false} ].map(({label, value}) => (
                <MenuItem value={value}>{label}</MenuItem>
              ))}
            </Select>
            {company.features.includes("include_branch_division_region_in_calibration") && <div>
              <br />
              <br />
              <b>Branch</b>
              <br />
              <TextField
                label="Branch"
                value={this.state.branch || []}
                fullWidth
                onChange={(v) => {
                  const branch = v.target.value;
                  this.setState({
                    branch,
                    branch_selected: Boolean(branch),
                  },() => {
                    this.checkInventoryUserCount();
                  });

               }}
              />
              <br />
              <br />
              <b>Division</b>
              <br />
              <TextField
                label="Division"
                value={this.state.division || []}
                fullWidth
                onChange={(v) => {
                  const division = v.target.value;
                  this.setState({
                    division,
                    division_selected: Boolean(division),
                  }, () => {
                    this.checkInventoryUserCount();
                  });
                }}
              />
              <br />
              <br />
              <b>Region</b>
              <br />
              <TextField
                label="Region"
                value={this.state.region || []}
                fullWidth
                onChange={(v) => {
                  const region = v.target.value;
                  this.setState({
                    region,
                    region_selected: Boolean(region),
                  }, () => {
                    this.checkInventoryUserCount();
                  });
                }}
              />
              <br />
            </div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Mutation mutation={createCalibrationSessionMutation}>
              {(createCalibration) => (
                <Button
                  onClick={() => {
                    createCalibration({
                      variables: {
                        ...this.prepareStateForMutation(),
                      },
                    }).then((response) => {
                      window.location.href = '/admin/calibration_sessions/coming_soon'; 
                    });
                  }}
                  color="primary"
                  disabled={this.checkIfDisabled()}
                >
                  Save
                </Button>
              )}
            </Mutation>
          </DialogActions>
        </div>
      </div>
    );
  }
}
const styles = {
  taskButton: {
    marginLeft: "auto",
  },
  dialogBody: { minWidth: 540, overflow: 'scroll', height: 'calc(100vh - 200px)', width: '100%' },
};

NewCalibrationSessionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  activityTypes: PropTypes.array.isRequired,
  pipelineCard: PropTypes.object,
  currentInventoryUser: PropTypes.object,
  refetch: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
};

NewCalibrationSessionDialog.defaultProps = {
  pipelineCard: {},
  buttonType: "text",
  currentInventoryUser: {},
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps)(withStyles(styles)(withApollo(NewCalibrationSessionDialog)));
