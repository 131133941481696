import React, { useState } from 'react';

const InlineEdit = ({ value, setValue, onConfirm }) => {
  const [editingValue, setEditingValue] = useState(value);
  
  const onChange = (event) => setEditingValue(event.target.value);
  
  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.target.blur();
    }
  }
  
  const onBlur = (event) => {
    if (event.target.value.trim() === "") {
      setEditingValue(value);
    } else {
      setValue(event.target.value)
    }
  }

  return (
    <span>
        <input
            type="text"
            aria-label="Field name"
            value={editingValue}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            style={{ width: '88%', }}
        />
        &nbsp;
        <button 
            onClick={() => {onConfirm(editingValue)}}
            style={{ width: '8%', height: '50%', }}
        >✓</button>
    </span>
  );
};


export default InlineEdit;
