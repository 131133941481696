import React from 'react';
import client from '../lib/apolloClient';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Query, ApolloProvider } from 'react-apollo';
import JssProvider from 'react-jss/lib/JssProvider';
import {
  createGenerateClassName,
  jssPreset,
  withStyles,
  MuiThemeProvider,
  createMuiTheme,
} from '@material-ui/core/styles';
import findBobMuiTheme from '../lib/findBobMuiTheme';
import { create } from 'jss';
import NavigationDrawer from '../components/Admin/NavigationDrawer';
import { get } from 'lodash';
import CssBaseline from '@material-ui/core/CssBaseline';
import themes from '../lib/themes';

const getCompany = gql`
  query company {
    company {
      id
      help_url
      toggled_help_url
      __typename
    }
  }
`;

const NavigationDrawerContainer = (props, railsContext) => {
  let theme = themes[railsContext.theme_name];
  const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS : true,
    productionPrefix        : 'fbNav',
  });
  const jss = create({
    ...jssPreset(),
  });
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbNav-">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <Query query={getCompany}>
            {({ loading, data }) => {
              if (loading) return <div />;
              if (data.company) {
                return <NavigationDrawer {...props} company={get(data, 'company', {})} />;
              }
            }}
          </Query>
        </ApolloProvider>
      </MuiThemeProvider>
    </JssProvider>
  );
};

export default NavigationDrawerContainer;
