import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import ManagerSearchFieldContainer from '../../containers/ManagerSearchFieldContainer';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import client from '../../lib/apolloClient';
import { assign, get } from 'lodash';

const ASSIGN_OWNERSHIP = gql`
  mutation AssignSupportRequestOwnership($id: ID!, $userId: ID!) {
    assignSupportRequestOwnership(id: $id, userId: $userId) {
      id
    }
  }
`;

const OwnershipSelect = ({ supportRequest, owner, refetch, managers }) => {
  const [assignOwnership] = useMutation(ASSIGN_OWNERSHIP, { client, });


  return (
    <select
      value={get(supportRequest, 'owner.id')}
      onChange={(e) => {
        e.stopPropagation();
        assignOwnership({
          variables: {
            id: supportRequest.id,
            userId: e.target.value
          }
        }).then(() => {
          refetch()
        });
      }
      }
    >
      <option value={''}>All</option>
      {managers.map(manager => (
        <option key={manager.id} value={manager.id}>{manager.name}</option>
      ))}
    </select>
  );
}

export default OwnershipSelect;