import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import numeral from 'numeral';
import {withStyles} from "@material-ui/core/styles";
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const SellerFullTaxBreakdown = ({result, classes, intl, lenderUrl, company, query}) => {
  const sellerCashFlow = get(result, 'cashFlow.illustration.sellerCashFlow', []);

  const dollarFormat = intl.locale === 'en' ? '$0,0.00' : '0,0.00 $';
  const percentFormat = intl.locale === 'en' ? '0%' : '0 %';

  if(intl.locale == 'fr') {
    numeral.localeData().delimiters.decimal = ','
    numeral.localeData().delimiters.thousands = ' '
  }

  const showCheckSwap = get(company, 'features', []).includes('check_swap');
  const showConsultingAgreements = get(company, 'features', []).includes('cash_flow_consulting_agreements');
  
  let consultingPercentOfDeal = (parseFloat(query.consultingTotalCompensation) / parseFloat(query.purchasePrice) * 100).toFixed(0);
  if(isNaN(consultingPercentOfDeal)) {
      consultingPercentOfDeal = 0;
  }

  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');
  const showOrdinaryTaxes = get(company, 'features', []).includes('cash_flow_ordinary_income_tax_rates');

  return (
    <React.Fragment>
      <tr>
        <td><strong><FormattedMessage id="calculators.cfa.year"/></strong></td>
            {sellerCashFlow.map((v, i) => (<td style={{textAlign: 'right'}}><strong>{i + 1}</strong></td>))}
        </tr>
        <tr>
            <td>Down Payment</td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.lumpSumPaymentReceived).format(dollarFormat)}</td>))}
        </tr>
        <tr>
          <td>
            Earn Out
          </td>
          {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.contingentPaymentReceived).format(dollarFormat)}</td>))}
        </tr>
        { showSellerNote && <tr>
          <td>Seller Carried Note Payments</td>
          {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.sellerNotePayment).format(dollarFormat)}</td>))}
        </tr> }
        {showConsultingAgreements && <React.Fragment>
          <tr>
            <td>Consulting Agreement</td>
            {sellerCashFlow.map((v) => (<td style={{textAlign: 'right'}}>{numeral(v.consultingAgreement).format(dollarFormat)}</td>))}
        </tr> 
          </React.Fragment>}
          
        <tr>
            <td style={{ borderTop: '3px solid black' }}><strong><FormattedMessage id="calculators.cfa.pretax_net_income"/></strong></td>
            {sellerCashFlow.map((v) => (<td style={{ borderTop: '3px solid black', textAlign: 'right'}}><strong>{numeral(v.pretaxNetIncome).format(dollarFormat)}</strong></td>))}
        </tr>
    </React.Fragment>
  );
};

const mapStateToProps = (state, props) => {
  return {
    result: {...state.cfa.result},
    query: {...state.cfa.query},
    lenderUrl: get(state, 'notes.lender_url'),
    company: { ...get(state, 'company', {}), ...get(state, 'notes.company', {}),},
  };
};

export default injectIntl(connect(mapStateToProps)(SellerFullTaxBreakdown));