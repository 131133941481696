import { combineReducers } from 'redux';
import { DATE_RANGE_UPDATE } from '../constants/filterConstants';
import moment from 'moment';

const date1 = moment();
const date2 = moment().add(+1, 'year');

const defaultState = {
  startDate    : date1,
  endDate      : date2,
  sendStart    : date1,
  sendEnd      : date2,
  focusedInput : null,
};

const dateRangeFilterReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case DATE_RANGE_UPDATE:
      return { ...state, ...action.filterOptions };
    default:
      return state;
  }
};

export default dateRangeFilterReducer;
