import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { snakeCase } from 'lodash';

const BobBanner = ({ children, user, intl, currentUser, override = false, obfuscate }) => {
  if (user.user_name === currentUser.user_name && !override) return <div />;
  return (
    <div id="advertising">
      <div className="banner">
        <div className="wrapper">
          <span className="title transition-goals" style={{ width: '50%', fontSize: '20px' }}>
            <FormattedMessage id="interested_in_ad.im_interested_in" />{' '}
            {obfuscate ? (
              <span className="blurry-text">????? ??? ????</span>
            ) : (
              <span>
                {user.transition_goals
                  .map(g =>
                    ` ${intl.formatMessage({
                      id : `sign_up_pages.${snakeCase(g)}`,
                    })}`.replace('Become', intl.formatMessage({ id: 'interested_in_ad.becoming' }))
                  )
                  .join(', ')}
              </span>
            )}
          </span>
          {children}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(BobBanner);
