import React from 'react';
import { Box, Button, Switch, Typography } from '@material-ui/core';

const Publish = ({ formData, handleCheckChange }) => {

  return (
    <Box>
      <Typography variant="body1">
        Get ready to work your magic! Click here to conjure up your partial sale.
      </Typography>
      <br />
      <Typography variant="body2">
        <Switch
          checked={formData.publishOnSave}
          onChange={handleCheckChange}
          name="publishOnSave"
        />
        Publish this partial sale as soon as it's saved.
      </Typography>
    </Box>
  );
}

export default Publish;

