import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Matching from './StatusComponents/Matching';
import ReviewingProposal from './StatusComponents/ReviewingProposal';
import ShortListed from './StatusComponents/ShortListed';
import Archived from './StatusComponents/Archived';
import OutForSig from './StatusComponents/OutForSig';
import AwaitingDisclosingSig from './StatusComponents/AwaitingDisclosingSig';
import Negotiating from './StatusComponents/Negotiating';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

const MatchProfileSideBar = ({
  classes,
  dealInterest,
  agentWordForCompany,
  scrollTo,
  deadLinePassed,
  stylesheetName,
  dealInterestName,
  allowShortlistMessaging,
  dealRoomName,
  dealRoomSingleName,
  currentUser,
  companyBusinessType,
  intl
}) => {
  switch (get(dealInterest, 'workflow_state', '')) {
    case 'matching':
    case 'invited':
      return (
        <div>
          <Matching
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            deadLinePassed={deadLinePassed}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'reviewing_proposal':
      return (
        <div>
          <ReviewingProposal
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'shortlisted':
      return (
        <div>
          <ShortListed
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            allowShortlistMessaging={allowShortlistMessaging}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'archived':
      return (
        <div>
          <Archived
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'out_for_receiving_signature':
      return (
        <div>
          <OutForSig
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'awaiting_disclosing_signature':
      return (
        <div>
          <AwaitingDisclosingSig
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    case 'negotiating':
      return (
        <div>
          <Negotiating
            dealInterest={dealInterest}
            agentWordForCompany={agentWordForCompany}
            scrollTo={scrollTo}
            stylesheetName={stylesheetName}
            dealInterestName={dealInterestName}
            dealRoomName={dealRoomName}
            dealRoomSingleName={dealRoomSingleName}
            currentUser={currentUser}
            companyBusinessType={companyBusinessType}
          />
        </div>
      );
    default:
      return <div>Invalid Stage</div>;
  }
};

const styles = {
  margin : {
    margin : 5,
  },
};

export default injectIntl(withStyles(styles)(MatchProfileSideBar));
