import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

const hideWelcomeBanner = gql`
  mutation updateCurrentUser {
    updateCurrentUser(welcome_hide_banner: true)
  }
`;

const WelcomeBanner = ({ user }) => {
  const [hide_banner, setHideBanner] = useState(null);
  const company = get(user, 'company', {});
  let welcome_hide_banner = hide_banner;
  if (hide_banner === null) {
    welcome_hide_banner = get(user, 'welcome_hide_banner', true);
  }

  if (welcome_hide_banner) {
    return <span />;
  }

  return (
    <div className="banner">
      <div className="wrapper">
        <p>
          <span className="title">
            Welcome to {company.branded_name}, {user.name}!
          </span>
          <span className="sub-title">Let&apos;s get you started.</span>
          <br />
          <br />
          <a href="/dashboard/getting_started">
            <button type="button" className="btn btn-default">
              Learn More
            </button>
          </a>
        </p>
        <span className="submit">
          <script
            type="text/javascript"
            async
            src="https://play.vidyard.com/embed/v4.js"
          />
          <img
            style={{ width: '100%', margin: 'auto', display: 'block' }}
            className="vidyard-player-embed"
            src="https://play.vidyard.com/Nwf6DX1bSZacvkkBoX842j.jpg"
            data-uuid="Nwf6DX1bSZacvkkBoX842j"
            data-v="4"
            data-type="inline"
            alt="Video"
          />
        </span>
        <Mutation mutation={hideWelcomeBanner}>
          {hideWelcomeBannerMutation => (
            <button
              type="button"
              className="top right"
              onClick={() => {
                hideWelcomeBannerMutation().then(() => {
                  setHideBanner(true);
                });
              }}>
              X
            </button>
          )}
        </Mutation>
      </div>
    </div>
  );
};

WelcomeBanner.propTypes = {
  user: PropTypes.object,
};

WelcomeBanner.defaultProps = {
  user: {},
};

export default WelcomeBanner;
