import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import qs from 'query-string';
import NotebookDrawerContainer from './NotebookDrawerContainer';
import Note from '../Note/Note';
import NickNameInput from '../NickNameInput/NickNameInput';
import { trackAnalyticsEvent } from '../../lib/analytics';
import { get } from 'lodash';

const styles = theme => ({
  drawerPaper           : {
    width      : '340px',
    background : '#f3f3f3',
    top        : '112px',
    boxShadow  : '-2px 3px 2px rgba(0, 0, 0, 0.2)',
  },
  content               : {
    flexGrow        : 1,
    backgroundColor : theme.palette.background.default,
    padding         : theme.spacing.unit * 3,
    minWidth        : 0, // So the Typography noWrap works
  },
  toolbar               : theme.mixins.toolbar,
  noteBookButtonWrapper : {
    position        : 'fixed',
    transform       : 'rotate(-90deg)',
    top             : 'calc(50% - 100px)',
    backgroundColor : theme.palette.secondary.main,
    borderColor     : theme.palette.secondary.main,
    zIndex          : '9999999',
    borderRadius    : '2px',
    boxShadow       : '-2px 2px 0px rgba(0, 0, 0, 0.33)',
  },
  noteBookButton        : {
    backgroundColor : theme.palette.secondary.main,
    borderColor     : theme.palette.secondary.main,
    color           : '#fff',
    padding         : '10px',
    fontSize        : '18px',
    borderRadius    : '2px',
    fontWeight      : 'lighter',
    width           : '160px',
  },
  userName              : {
    color        : '#e1e1e1',
    marginTop    : '-20px',
    marginBottom : 0,
    paddingLeft  : '6px',
  },
  bookTitle             : {
    padding : '6px',
    color   : '#e1e1e1',
  },
  cardList              : {
    overflowY     : 'scroll',
    background    : '#f3f3f3',
    paddingBottom : '50%',
  },
  header                : {
    boxShadow       : '0px 2px 1px 0px rgba(0,0,0,0.33)',
    backgroundColor : theme.palette.secondary.main,
    padding         : '6px',
    marginBottom    : '6px',
    border          : 0,
  },
});

class NotebookDrawer extends Component {
  constructor (props) {
    super(props);
    this.state = {
      opened : props.opened || false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentWillReceiveProps (nextProps) {
    const { user } = this.props;
    if (get(nextProps, 'user.id') !== get(user, 'id')) {
      this.setState({ opened: false });
    }
  }

  componentDidCatch = error => {
    // Display fallback UI
    console.log(error);
  };

  toggleOpen = () => {
    const { opened } = this.state;
    const { user } = this.props;
    this.setState({ opened: !opened });
    if (!opened) {
      trackAnalyticsEvent('notebookDrawerOpened', {
        subjectUser : user.id,
      });
    }
  };

  onSave = (mutation, userName, text, clear) => {
    const { updateNotes, refetch, notes, user, updateBobCards, loadBobCardList } = this.props;
    mutation({
      variables : { userName, text },
    })
      .then(r => {
        // let newNotes = [];
        // if (notes.length && r.data) {
        //   newNotes = [ ...notes ];
        //   newNotes.unshift(r.data.addNote);
        // }
        clear();
        updateNotes({ notes: r.data.addNote });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: r.data.addNote.length });
          loadBobCardList({ ...user, note_count: r.data.addNote.length });
        }
        refetch();
        trackAnalyticsEvent('notebook_note_made', {
          subjectUser : user.id,
        });
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  onDelete = (mutation, noteId) => {
    const { user, updateNotes, refetch, updateBobCards, loadBobCardList } = this.props;
    mutation({
      variables : { id: noteId },
    })
      .then(r => {
        updateNotes({ notes: r.data.deleteNote });
        if (updateBobCards) {
          updateBobCards({ ...user, note_count: r.data.deleteNote.length });
          loadBobCardList({ ...user, note_count: r.data.deleteNote.length });
        }
        refetch();
      })
      .catch(error => {
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  handleParams = params => {
    if (params.anchor === 'notebook') {
      setTimeout(() => {
        this.setState({ opened: true });
        window.history.replaceState({}, window.history.title, `/dashboard/index`);
      }, 1000);
    }
  };

  render = () => {
    const { user, classes, notes } = this.props;
    const { opened } = this.state;
    const params = qs.parse(location.search);
    if (params) {
      this.handleParams(params);
    }
    return (
      <div>
        {opened && (
          <Drawer
            variant="persistent"
            open={opened}
            anchor="right"
            onClose={this.toggleOpen}
            classes={{
              paper : classes.drawerPaper,
            }}>
            <header className={classes.header}>
              <h1 className={classes.bookTitle}>
                <i className="fas fa-book" />
                &nbsp;<FormattedMessage id="notebook.notebook" />
              </h1>
              <NickNameInput
                userName={user.user_name}
                userId={user.id}
                nickName={user.nick_name}
                styleTag={classes.userName}
              />
            </header>
            <NotebookDrawerContainer userName={user.fb_user_name} nickName={user.nick_name} onSave={this.onSave} />
            <div className={classes.cardList}>
              {notes.map(note => (
                <Note
                  key={note.id}
                  id={note.id}
                  comment={note.comment}
                  createdAt={note.created_at}
                  onDelete={this.onDelete}
                  userName={user.user_name}
                />
              ))}
            </div>
          </Drawer>
        )}
        <div
          className={classes.noteBookButtonWrapper}
          style={{ right: this.state.opened ? '285px' : '-56px' }}
          onClick={this.toggleOpen}>
          <button className={`${classes.noteBookButton} notebook-tip`}>
            <i className="fas fa-book" />
            <span>
              &nbsp; <FormattedMessage id="notebook.notebook" /> {notes.length === 0 ? '' : `(${notes.length - 1})`}
            </span>
          </button>
        </div>
      </div>
    );
  };
}

NotebookDrawer.propTypes = {
  user            : PropTypes.object.isRequired,
  opened          : PropTypes.bool,
  updateNotes     : PropTypes.func.isRequired,
  refetch         : PropTypes.func.isRequired,
  notes           : PropTypes.array,
  classes         : PropTypes.object.isRequired,
  updateBobCards  : PropTypes.func.isRequired,
  loadBobCardList : PropTypes.func.isRequired,
};

NotebookDrawer.defaultProps = {
  notes  : [],
  opened : false,
};

export default withStyles(styles)(NotebookDrawer);
