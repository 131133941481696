import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, replace } from 'lodash';
import CheckButton from '../CheckButton/CheckButton';
import { uniq, toLower, capitalize, snakeCase, get } from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import LoadingButtonWrapper from '../LoadingButtonWrapper/LoadingButtonWrapper';

const togglelicenses = (e, availableAdvisorLicenses, updateAction, userAdvisorLicenses, iirocLicenseOptions) => {
  let advisor_licenses = userAdvisorLicenses;
  const license = e.target.value;
  const index = findIndex(advisor_licenses, o => o === license);
  advisor_licenses = [ ...advisor_licenses ];
  if (index > -1) {
    advisor_licenses.splice(index, 1);
    if (iirocLicenseOptions && license === 'IIROC') {
      advisor_licenses = removeAmpOptions(advisor_licenses);
    }
  } else {
    advisor_licenses.push(license);
  }
  updateAction({ advisor_licenses });
};

const removeAmpOptions = advisor_licenses => {
  const ampIndex = findIndex(advisor_licenses, o => o === 'AMP');
  if (ampIndex > -1) {
    advisor_licenses.splice(ampIndex, 1);
  }
  const optiondIndex = findIndex(advisor_licenses, o => o === 'Options');
  if (optiondIndex > -1) {
    advisor_licenses.splice(optiondIndex, 1);
  }
  return advisor_licenses;
};

const UpdateCurrentUser = gql`
  mutation updateCurrentUser($advisor_licenses: [String!], $designations: [String!]) {
    updateCurrentUser(advisor_licenses: $advisor_licenses, designations: $designations)
  }
`;

const updateUser = ({ mutate, userAdvisorLicenses, updateAction, designations, userName }) => {
  updateAction({ loading: true });
  mutate({
    variables : {
      advisor_licenses : userAdvisorLicenses,
      designations     : designations,
    },
  })
    .then(response => {
      // onNext();
      document.location.href = `/profile/interests?user_id=${userName}`;
      updateAction({ loading: false });
    })
    .catch(error => {
      // eslint-disable-next-line
      console.log('there was an error sending the query', error);
      updateAction({ loading: false });
    });
};

const alphaSortLicenses = licenses => {
  const sorted = licenses.sort((a, b) => {
    return a.localeCompare(b, undefined, {
      numeric     : true,
      sensitivity : 'base',
    });
  });

  return sorted;
};

const checkIfIiroc = (iirocLicenseOptions, userAdvisorLicenses, license) => {
  if (iirocLicenseOptions) {
    if (!userAdvisorLicenses.includes('IIROC')) {
      return license === 'AMP' || license === 'Options';
    }
  }
  return false;
};

const renderAdvisorLicenses = (
  availableAdvisorLicenses,
  updateAction,
  userAdvisorLicenses,
  stylesheetName,
  iirocLicenseOptions,
  intl
) => {
  return alphaSortLicenses(availableAdvisorLicenses).map(license => (
    <CheckButton
      stylesheetName={stylesheetName}
      label={intl.formatMessage({id: `sign_up_pages.licenses.${snakeCase(license)}`})}
      checked={findIndex(userAdvisorLicenses, o => o === license) > -1}
      value={license}
      disable={checkIfIiroc(iirocLicenseOptions, userAdvisorLicenses, license)}
      onClick={e => togglelicenses(e, availableAdvisorLicenses, updateAction, userAdvisorLicenses, iirocLicenseOptions)}
    />
  ));
};

const EditAdvisorLicenseComponent = ({
  availableAdvisorLicenses,
  updateAction,
  userAdvisorLicenses,
  stylesheetName,
  mutate,
  userName,
  user,
  iirocLicenseOptions,
  intl
}) => {
  return (
    <div>
      <div>
        {renderAdvisorLicenses(
          availableAdvisorLicenses,
          updateAction,
          userAdvisorLicenses,
          stylesheetName,
          iirocLicenseOptions,
          intl
        )}
      </div>
      <div style={{ width: '100%', textAlign: 'center', marginTop: 20 }}>
        <LoadingButtonWrapper stateChild="user">
          <a
            onClick={() =>
              updateUser({
                mutate,
                userAdvisorLicenses,
                updateAction,
                designations        : get(user, 'designations'),
                userName,
              })}
            className="btn btn-default center text-center"
            style={{ padding: '12px 24px' }}>
            <FormattedMessage id="profile_page.practice_info_section.save_next" />
          </a>
        </LoadingButtonWrapper>
        <span><FormattedMessage id="profile_page.practice_info_section.or_go" /></span>
        <a href={`/users/${userName}`}><FormattedMessage id="profile_page.practice_info_section.back_your_profile" /></a>
      </div>
    </div>
  );
};

EditAdvisorLicenseComponent.propTypes = {
  mutate                   : PropTypes.func.isRequired,
  availableAdvisorLicenses : PropTypes.array,
  updateAction             : PropTypes.func.isRequired,
  userAdvisorLicenses      : PropTypes.array,
  stylesheetName           : PropTypes.string.isRequired,
  userName                 : PropTypes.string.isRequired,
};

EditAdvisorLicenseComponent.defaultProps = {
  availableAdvisorLicenses : [],
  userAdvisorLicenses      : [],
};

export default injectIntl(graphql(UpdateCurrentUser)(EditAdvisorLicenseComponent));
