import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { get, capitalize } from 'lodash';
import { Chip, Menu, MenuItem, Box } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import { support } from 'jquery';

const CHANGE_SUPPORT_REQUEST_STATUS = gql`
  mutation ChangeSupportRequestStatus($id: ID!, $status: String!) {
    changeSupportRequestStatus(id: $id, status: $status) {
      id
    }
  }
`;

const StatusSelect = ({ supportRequest, refetch, prefix }) => {
  const [changeSupportRequestStatus] = useMutation(CHANGE_SUPPORT_REQUEST_STATUS);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    const targetEl = document.getElementById(`${prefix}_${supportRequest.id}`);
    setAnchorEl(targetEl);
  };

  const handleClose = (event) => {
    if(event) {
      event.preventDefault();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);


  const handleChange = (option) => {
    changeSupportRequestStatus({
      variables: {
        id: supportRequest.id,
        status: option
      }
    }).then(() => {
      refetch().then(() => {
        supportRequest.status = option;
        handleClose();
      });
    });
  };

  const colorsForStatus = {
    open: '#7ec4cf',
    pending: '#ffee93',
    closed: '#e27396',
  };

  return (
    <Box>
      <Chip
        id={`${prefix}_${supportRequest.id}`}
        label={capitalize(supportRequest.status)}
        onClick={handleClick}
        style={{ cursor: 'pointer', color: '#fff', backgroundColor: colorsForStatus[supportRequest.status], marginLeft: 4,}}
        onDelete={handleClick}
        deleteIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}>{}</Chip>
        
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={
          e => {
            e.stopPropagation(),
            handleChange('open')
          }
          }>Open</MenuItem>
        <MenuItem onClick={e => {
            e.stopPropagation(),
            handleChange('closed')
          }}>Close</MenuItem>
        <MenuItem onClick={e => {
            e.stopPropagation(),
            handleChange('pending')
          }}>Pending</MenuItem>
      </Menu>
    </Box>
  );
}

StatusSelect.propTypes = {
  supportRequest: PropTypes.object,
  refetch: PropTypes.func,
  prefix: PropTypes.string,
};

StatusSelect.defaultProps = {
  prefix: 'status',
};

export default StatusSelect; 