import React, { Component, Fragment } from "react";
import gql from "graphql-tag";
import {injectIntl, FormattedMessage} from 'react-intl'
import { Mutation } from "react-apollo";
import { get } from 'lodash';
import { connect } from 'react-redux';
import * as actions from '../../actions/conversationActionCreators';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, Paper, Popper, Grow, MenuItem, MenuList, ClickAwayListener } from "@material-ui/core";

const ToggleBlockConversation = gql`
  mutation toggleBlockConversation($id: ID!) {
    toggleBlockConversation(id: $id)
  }
`;

class BlockButton extends Component {
	state = { open: false, anchorRef: null }

	handleToggle = event => {
		this.setState({ anchorRef: event.currentTarget, open: !this.state.open });
	};

	handleClose = (event) => {
		this.setState({ open: false });
	};

	handleListKeyDown(event) {

		if (event.key === 'Tab') {
			event.preventDefault();
			this.setState({ open: false });
		} else if (event.key === 'Escape') {
			this.setState({ open: false });
		}
	}

	render() {
		const { room_id, conversation, updateConversation, user } = this.props;
		const { open, anchorRef } = this.state;

		if (!get(user, 'company.allow_blocking_in_messages', false)) {
			return <span />;
		}
		return (<Fragment>
			<Button
				id="composition-button"
				aria-controls={open ? 'composition-menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={this.handleToggle}
			>
				<MenuIcon />
			</Button>
			<Popper
				anchorEl={anchorRef}
				open={open}
				role={undefined}
				placement="bottom-start"
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom-start' ? 'left top' : 'left bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={this.handleClose}>
								<MenuList
									autoFocusItem={open}
									id="composition-menu"
									aria-labelledby="composition-button"
									onKeyDown={this.handleListKeyDown}
								>
									<Mutation mutation={ToggleBlockConversation}>
										{(toggleBlockConversation) => (
											<MenuItem onClick={
												e => {
													updateConversation({ ...conversation, blockedByMe: !conversation.blockedByMe });
													toggleBlockConversation({ variables: { id: room_id } })

													this.handleClose();
												}
											}>{get(conversation, 'blockedByMe') ? <FormattedMessage id="messages_section.unblock"/> : <FormattedMessage id="messages_section.block_chat"/>}</MenuItem>
										)}
									</Mutation>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</Fragment>
		)
	}
};

const mapStateToProps = state => ({
	conversation: state.conversation,
});

export default injectIntl(connect(mapStateToProps, { ...actions })(BlockButton));