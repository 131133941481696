import { connect } from 'react-redux';
import AdvisorLicense from '../components/AdvisorLicense/AdvisorLicense';
import NewFormDialog from '../components/AdvisorLicense/AdvisorLicenseNewFormDialog';
import CrudTable from '../components/CrudTable/CrudTable';
import * as actions from '../actions/advisorLicensesActionCreators';

const mapStateToProps = state => ({
  ...state,
  listData      : state.advisor_licenses,
  title         : state.intl.messages['admin_advisor_licenses.advisor_licenses'],
  NewFormDialog,
  CrudRow       : AdvisorLicense,
});

export default connect(mapStateToProps, actions)(CrudTable);
