import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import InfoIcon from '@material-ui/icons/Info';
import numeral from 'numeral';
import { get, upperFirst } from 'lodash';
import PencilIcon from '@material-ui/icons/AddComment';
import NotesIcon from '@material-ui/icons/CommentSharp';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const styles = theme => ({
  root : {
    width           : '100%',
    backgroundColor : theme.palette.background.paper,
  },
});

class CheckboxList extends React.Component {
  state = { reasonSubject: false, reason: '' };

  handleToggle = value => () => {
    const { onToggle, checked } = this.props;
    const currentIndex = checked.indexOf(value);
    const newChecked = [ ...checked ];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    onToggle(newChecked);
  };

  moveItem = (array, from, to) => {
    const { onMove } = this.props;
    const f = array.splice(from, 1)[0];
    array.splice(to, 0, f);
    onMove(array);
  };

  moveUp = value => {
    const { items } = this.props;
    const currentIndex = items.indexOf(value);

    if (currentIndex > 0) {
      this.moveItem(items, currentIndex, currentIndex - 1);
    }
  };

  moveDown = value => {
    const { items } = this.props;
    const currentIndex = items.indexOf(value);

    if (currentIndex + 1 < items.length) {
      this.moveItem(items, currentIndex, currentIndex + 1);
    }
  };

  handleClose = () => {
    this.setState({ reasonSubject: false, reason: '' });
  };

  render () {
    const {
      classes,
      items,
      checked,
      movable,
      infoClick,
      showInfo,
      showState,
      showReasons,
      onReasonChange,
    } = this.props;
    const { reasonSubject, reason } = this.state;
    return (
      <div>
        <List className={classes.root}>
          {items.map((value, index) => (
            <ListItem key={value.id} role={undefined} dense button onClick={this.handleToggle(value.id)}>
              <Checkbox checked={checked.indexOf(value.id) !== -1} tabIndex={-1} disableRipple />
              <ListItemText
                primary={`${index + 1}. ${value.name}`}
                secondary={[
                  numeral(get(value, 'user.annual_revenue', 0)).format('$0,a'),
                  get(value, 'province', ''),
                ].join(' | ')}
              />
              <ListItemSecondaryAction>
                {showState &&
                value.status && <Chip color="primary" label={upperFirst(value.status).replace(/_/g, ' ')} />}
                {showInfo && (
                  <IconButton onClick={() => infoClick(value)}>
                    <InfoIcon />
                  </IconButton>
                )}
                {showReasons && (
                  <IconButton
                    onClick={() => {
                      this.setState({ reasonSubject: value, reason: value.reason_for_suggestion });
                    }}>
                    {Boolean(value.reason_for_suggestion) ? <NotesIcon /> : <PencilIcon />}
                  </IconButton>
                )}
                {movable && (
                  <span>
                    <IconButton onClick={() => this.moveUp(value)}>
                      <KeyboardArrowUp />
                    </IconButton>
                    <IconButton onClick={() => this.moveDown(value)}>
                      <KeyboardArrowDown />
                    </IconButton>
                  </span>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <Dialog open={Boolean(reasonSubject)} onClose={this.handleClose}>
          <DialogTitle>Reason For Suggestion</DialogTitle>
          <DialogContent>
            <DialogContentText>Describe why this person would be a great match.</DialogContentText>
            <TextField
              value={reason}
              autoFocus
              margin="dense"
              multiline
              rows={5}
              fullWidth
              onChange={e => this.setState({ reason: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={e => {
                const { reasonSubject, reason } = this.state;
                onReasonChange(reasonSubject, reason);
                this.handleClose();
              }}
              color="primary">
              Add Comment
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CheckboxList.propTypes = {
  classes        : PropTypes.object.isRequired,
  items          : PropTypes.array,
  onToggle       : PropTypes.func.isRequired,
  checked        : PropTypes.array.isRequired,
  movable        : PropTypes.bool,
  showReasons    : PropTypes.bool,
  showState      : PropTypes.bool,
  onReasonChange : PropTypes.func,
};

CheckboxList.defaultProps = {
  items          : [],
  movable        : false,
  showReasons    : false,
  showState      : false,
  onReasonChange : () => {},
};

export default withStyles(styles)(CheckboxList);
