import React from 'react';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types';
import { get } from 'lodash';

const PipelineUserSelect = ({ options, onChange, value }) => {
  return <TextField value={value} onChange={onChange} /> 
};

PipelineUserSelect.propTypes = {
  options  : PropTypes.array,
  onChange : PropTypes.func.isRequired,
  value    : PropTypes.string,
};

PipelineUserSelect.defaultProps = {
  options : [],
  value   : '',
};

export default PipelineUserSelect;
