/* eslint-disable no-case-declarations */

import { findIndex } from 'lodash';
import { TASK_UPDATE, LOAD_TASKS, TASK_LIST_UPDATE, ADD_TASK } from '../constants/taskConstants';

const updateTask = (tasksList, task, modified = true) => {
  const index = findIndex(tasksList, e => e.id === task.id);
  const tasksListNew = tasksList;
  const taskNew = task;
  if (index !== -1) {
    if (modified) taskNew.modified = true;
    else taskNew.modified = false;
    tasksListNew[index] = taskNew;
  }
  return tasksListNew;
};

const taskReducer = (state = { taskCardTask: {}, tasks: [], loaded: false }, action) => {
  let list = [];
  switch (action.type) {
    case TASK_UPDATE:
      return {
        ...state,
        taskCardTask : action.task,
      };
    case LOAD_TASKS:
      return { ...state, ...action.taskList, loaded: true };
    case TASK_LIST_UPDATE:
      return {
        ...state,
        tasks : updateTask(state.tasks, action.tasksList),
      };
    case ADD_TASK:
      list = state.tasks;
      if (action.newTask) {
        list.unshift(action.newTask);
      }
      return {
        ...state,
        tasks : list,
      };
    default:
      return state;
  }
};

export default taskReducer;
