import React from 'react';
import MUIDataTable from "mui-datatables";
import moment from "moment";
import numeral from 'numeral';
import { updateIntl } from 'react-intl-redux';
import { IntlProvider, createIntlCache} from 'react-intl';
import translations from '../../lib/i18n/translations.json';
import defaultLocale from '../../lib/i18n/default.json';
import { NavigateBeforeSharp } from '@material-ui/icons';
import {FormattedMessage} from "react-intl";

const DCFList = ({ discounted_cash_flows }) => {
  const columns = [
        { name: 'name',
          label: <FormattedMessage id="dcf_list.name" defaultMessage="Name" />,
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              const id = tableMeta.tableData[tableMeta.rowIndex].id;
              return <a href={`/valuations/dcf?dcf_id=${id}`}>{value}</a>;
            }
          },
        },
        { name: 'updated_at',
          label: <FormattedMessage id="dcf_list.last_updated" defaultMessage="Last Updated" />,
          options: {
            filter: false,
            sort: true,
            customBodyRender: d => moment.parseZone(d).fromNow()},
        },
        { name: 'value_of_practice',
          label: <FormattedMessage id="dcf_list.value_of_practice" defaultMessage="Value of practice" />,
          options: {
            filter: false,
            sort: true,
            customBodyRender: d => <span className="tag price">{numeral(d || 0).format('$0,0')}</span>,
          },},
        { name: 'year_1_gross',
          label: <FormattedMessage id="dcf_list.gross_production" defaultMessage="Gross Production" />,
          options: {
            filter: false,
            sort: true,
            customBodyRender: d => <span className="tag price">{numeral(d || 0).format('$0,0')}</span>,
          },},
        { name: 'revenue_growth_rate',
          label: <FormattedMessage id="dcf_list.growth_rate" defaultMessage="Growth Rate" />,
          options: {
            filter: false,
            sort: true,
            customBodyRender: d => <span className="tag price">{numeral(parseFloat(d)).format('%0,0')}</span>,
          },},
        { name: 'actions',
          label: <FormattedMessage id="dcf_list.actions" defaultMessage="Actions" />,
          options: {
            filter: false,
            sort: false,
          },}
      ];
    const options = {
      filter: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      selectableRows: 'none',
    };

  const data = discounted_cash_flows.map(c => (
    {...c,

      actions: [<a href={`/valuations/dcf?dcf_id=${c.id}`}><i className="fas fa-pencil-alt" /><FormattedMessage id="shared.edit" defaultMessage="Edit" /></a>,
                <span>&nbsp;</span>,
                <span>&nbsp;</span>,
                <span>&nbsp;</span>,
                <a download href={`/valuations/dcf.pdf?dcf_id=${c.id}`}><i className="fas fa-download" /> <FormattedMessage id="calculators.cfa.download" defaultMessage="Download" /></a>,
                <span>&nbsp;</span>,
                <form style={{display: 'inline'}} className="button_to" method="post" action={`/valuations/dcf?dcf_id=${c.id}&authenticity_token=${ReactOnRails.authenticityToken()}`}>
                  <input type="hidden" name="_method" value="delete" />
                  <button style={{border:'none', background: 'none', display:'inline', color: 'red',}} type="submit">
                    <i className="fas fa-trash-alt" /> <FormattedMessage id="shared.delete" defaultMessage="Delete" />
                  </button>
                </form>,
               ]
    }));

    const cache = createIntlCache();
    
    const setIntl = () => {
      updateIntl(
      {
        locale   : defaultLocale,
        messages : translations[window.locale || 'en'],
      },
      cache
    );}
    setIntl();
  return <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale}>
            <MUIDataTable columns={columns} options={options} data={data}/>
        </IntlProvider>;
}

export default DCFList;