import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import PipelineColumnViewContainer from '../containers/PipelineColumnViewContainer';

const PipelineContainer = ({ match }) => {
  return <PipelineColumnViewContainer match={match} matchId={get(match, 'params.id')} />;
};

PipelineContainer.propTypes = {
  match : PropTypes.object.isRequired,
};

export default PipelineContainer;
