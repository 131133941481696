import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import DealStepper from '../components/DealWizard/DealStepper';
import MatchSurvey from '../components/MatchSurvey/MatchSurvey';
import { get } from 'lodash';

const CompanyQuery = gql`
  query company {
    company {
      id
      stylesheet_name
      affiliation_type
    }
  }
`;

export const MatchPreferencesContainer = ({ deal, match_preference, user }) => (
  <Query query={CompanyQuery}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <div />;
      if (data.company) {
        const stylesheetName = get(data, 'company.stylesheet_name');
        const hasAffiliation = get(data, 'company.affiliation_type');
        return (
          <Fragment>
            <br />
            <MatchSurvey
              deal={{ deal: deal }}
              match_preference={match_preference}
              user={user}
              stylesheetName={stylesheetName}
              hasAffiliation={hasAffiliation}
              fromProfile
            />
          </Fragment>
        );
      }
      return <div />;
    }}
  </Query>
);

const mapStateToProps = state => ({
  deal             : state.deal,
  match_preference : state.match_preference,
  user             : state.user,
});

export default connect(mapStateToProps)(MatchPreferencesContainer);
