import React from 'react';
import client from '../lib/apolloClient';
import { connect } from 'react-redux';
import { ApolloProvider, Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Rolodex from '../components/Rolodex/Rolodex';
import * as actions from '../actions/bobCardActionCreators';
import * as profileDrawerActions from '../actions/profileDrawerActionCreators';
import { get } from 'lodash';

const RolodexQueries = gql`
  query RolodexQueries {
    getRolodex {
      id
      user_name
      fb_user_name
      annual_revenue
      assets_under_management
      bio
      city
      county
      show_city
      number_of_clients
      years_practicing
      profile_picture
      province
      recurring_revenue
      created_at
      headline
      my_rating
      nick_name
      obfuscated
      is_pure_buyer
      is_student
      transition_goals
      interested_in_selling
      expressed_interest
      profile_type
      inventory_user {
        segmented_view
      }
      company {
        id
        enabled_student_profiles
        student_word
        student_program_title
      }
      product_mix {
        id
        life_ifp
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        total_assets
        health_and_life_stars
        aum_stars
        p_c_stars
        group_stars
        gic_fixed_income 
        insurance
        equity
        __typename
      }
      note_count
      conversation_with_me {
        id
      }
      __typename
    }
    getCurrentUser {
      id
      user_name
      locale
      roles
      has_selling_transition_goals
      limited_access
      inventory_user {
        segmented_view
      }
      company {
        id
        __typename
        name
        branded_name
        seller_only_label
        segmented_views_enforced
      }
      __typename
    }
    currentUserInfoAuth
    companyHasFeature(featureName: "rolodex")
  }
`;

const RolodexContainer = ({ bobCards, loadBobCardList, updateBobCards, updateSelectedProfileDrawer }) => {
  return (
    <Query 
      query={RolodexQueries}
      fetchPolicy="network-only"
      notifyOnNetworkStatusChange={true}
      onCompleted={data => {
        loadBobCardList(data);
      }}
      onError={error => console.log(error)}
      >
      {({ loading, refetch }) => {
        if (loading) {
          <div>Loading...</div>
        }
        return (
          <Rolodex
            marketplaceOnly
            updateBobCards={updateBobCards}
            loadBobCardList={loadBobCardList}
            updateSelectedProfileDrawer={updateSelectedProfileDrawer}
            refetch={refetch}
          />
        );
      }}
    </Query>
  );
};

const mapStateToProps = state => ({
  bobCards : state.bobCards,
});

export default connect(mapStateToProps, {
  ...actions,
  ...profileDrawerActions,
})(RolodexContainer);
