import React from "react";
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';


const DesiredLocation = ({ classes, user, obfuscateView }) => {
  if (user) {
    return (
      <div className={classes.status}>
        <strong><FormattedMessage id="profile_page.desired_location"/></strong>
        {!obfuscateView && <p>{get(user, 'desired_location')}</p>}
        {obfuscateView && <p className="blurry-text">???</p>}
      </div>
    );
  }
  return <div />;
}

const styles = {
  status : {
    marginBottom : 15,
    width        : 170,
  },
};

export default withStyles(styles)(DesiredLocation);