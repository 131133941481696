import React from 'react';
import {
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Typography,
  FormGroup
} from '@material-ui/core';
import { regionForCountry } from '../../lib/findBobUtils';
import { get } from 'lodash';

const Details = ({ formData, handleCheckChange, handleMultiSelectChange, company }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Details
    </Typography>
    <FormGroup>
      {formData.questions.map((question, index) => (
        <FormControlLabel
          key={question.id}
          control={
            <Switch
              checked={get(formData, `[answers][${index}]`, false)}
              onChange={handleCheckChange}
              name={index}
            />
          }
          label={question}
        />
      ))
      }
    </FormGroup>
    <FormControl fullWidth margin="normal">
      <InputLabel>{company.country == 'ca' ? "Provinces" : "States"}</InputLabel>
      <Select
        multiple
        value={formData.states}
        onChange={handleMultiSelectChange}
        renderValue={(selected) => selected.join(', ')}
      >
        {regionForCountry(company.country).map((state) => (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

export default Details;
