import React from 'react';
import Grow from '@material-ui/core/Grow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { withStyles } from '@material-ui/core/styles';

class FBTableSearch extends React.Component {
  handleTextChange = event => {
    const { onSearch } = this.props;
    onSearch(event.target.value);
  };

  render () {
    const { classes, onHide } = this.props;

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main} ref={el => (this.rootRef = el)}>
          <SearchIcon className={classes.searchIcon} />
          <TextField
            className={classes.searchText}
            autoFocus={true}
            onChange={this.handleTextChange}
            fullWidth={true}
            inputRef={el => (this.searchField = el)}
          />
        </div>
      </Grow>
    );
  }
}

const styles = theme => ({
  main       : {
    display : 'flex',
    flex    : '1 0 auto',
    float   : 'left',
  },
  searchIcon : {
    color       : theme.palette.text.secondary,
    marginTop   : '10px',
    marginRight : '8px',
  },
  searchText : {
    flex : '0.8 0',
  },
  clearIcon  : {
    '&:hover' : {
      color : theme.palette.error.main,
    },
  },
});

export default withStyles(styles)(FBTableSearch);
