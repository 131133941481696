import React, {useState} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import CurrencyInput from "react-currency-input-field";
import {Mutation} from "react-apollo";
import gql from "graphql-tag";
import {get} from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

const userGenerateCashFlows = gql`
  mutation userGenerateCashFlows($seller_t12: Float) {
    userGenerateCashFlows(seller_t12: $seller_t12) {
      id
    }
  }
`;

const CfaPrice = ({classes, updateStep, step, updateCashFlows, companyName, intl, company, user}) => {
  const [sellerT12, updatePurchasePrice] = useState(0);
  const features = get(company, 'features', []);

  return (<div>
    <div className="wrapper">
      <div className="banner">
        <center>
          <span className="continuity-submit">
              <img className={`img-fluid continuity_button_image ${classes.image}`}
              src={`https://s3-us-west-2.amazonaws.com/findbob-production/images/${companyName}_cash_flow_carousel${intl.locale == 'fr' ? '_fr' : '' }.gif`}
              alt="deal_tracker_gif" />
          </span>
        </center>
        
        </div>
      </div>
      <center>
        <header>
          <br/>
          <b><FormattedMessage id="calculators.cfa_wizard.specify_the_purchase_price" /></b>
        </header>
        <br/>
        <div className={classes.box} style={styles.box}>
            <dt><FormattedMessage id="calculators.cfa_wizard.seller_t12" /> &nbsp;&nbsp;</dt>
            <dd><CurrencyInput onValueChange={value => {
              updatePurchasePrice(value)}
              } /></dd>
        </div>
        <div>
          <br/>
          <br/>
        </div>
        <Mutation
          mutation={userGenerateCashFlows}
          onCompleted={data => {
            updateCashFlows(get(data, 'userGenerateCashFlows', []));
            updateStep(step + 1);
          }}
        >
          {generateCashFlows => (
            <Button
              disabled={!sellerT12}
              variant="contained"
              onClick={() => {
                generateCashFlows({variables : { seller_t12: parseFloat(sellerT12),}}).then(r => {

                });
              }}><FormattedMessage id="calculators.cfa_wizard.continue" /></Button>)}
        </Mutation>
        <br/>
        <br/>
        
        <div>    
          <p>You’ll have to a chance to adjust these and other key drivers of the cash flow as needed later on.</p>
        </div>
      
    </center>
 </div>);
};

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 12px',
  },
  buttonB: {
    margin: '18px 8px',
    padding: '48px 12px',
    width: 180,
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'none',
    backgroundColor: '#fff',
    border: 'none',
  },
  box                    : {
    display       : 'flex',
    flexDirection : 'row',
    margin        : 'auto',
    color         : theme.palette.primary.main,
    width: 400,
    fontWeight: 'bold',
  },
  icon                   : {
    fontSize : 20,
  },
  image: {
    width: 300,
  },
});

const mapStateToProps = (state, props) => {
  return {
    company : state.company,
    user    : state.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(injectIntl(CfaPrice)));