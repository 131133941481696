import React from 'react';
import PropTypes from 'prop-types';
import PartialSaleListings from '../components/PartialSaleListings/PartialSaleListings';
import client from '../lib/apolloClient';
import { ApolloProvider } from 'react-apollo';
import { SnackbarProvider } from 'notistack';
import JssProvider from 'react-jss/lib/JssProvider';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import themes from '../lib/themes';


const PartialSalesContainer = ({partialSales, user, company, partialSaleQuestions}, railsContext) => {
  const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS : true,
    productionPrefix        : 'fbPSales',
  });
  const jss = create(jssPreset());
  let theme = themes[railsContext.theme_name];
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <JssProvider jss={jss} generateClassName={generateClassName} classNamePrefix="fbSnack-">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <SnackbarProvider maxSnack={3}>
          <ApolloProvider client={client}>
            <PartialSaleListings 
              partialSales={partialSales}
              user={user}
              company={company}
              partialSaleQuestions={partialSaleQuestions} />  
          </ApolloProvider>
        </SnackbarProvider>
      </MuiThemeProvider>    
    </JssProvider>
  );
}

PartialSalesContainer.propTypes = {
  // Add your proptypes here
};

export default PartialSalesContainer;