import React, {useState} from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import CfaStart from '../components/calculators/CfaStart';
import { updateCompany } from '../actions/companyActionCreators';
import { updateUser } from '../actions/userActionCreators';

const GetCompany = gql`
	query getCompany {
		company {
			id
			cash_flow_analysis_word
			cash_flow_analysis_word_fr
			feature_types
			rj_mor_recurring_avg
		}
		getCurrentUser {
			id
			inventory_user {
				id
				user {
					id
					dcf_valuation
					average_mor_estimate
				}
			}
		}
	}
`;

const CfaStartContainer = props => {
	return (<Query query={GetCompany}
		onCompleted={data => {
			props.updateCompany(data.company);
			props.updateUser(data.getCurrentUser);
		}}>{({loading}) => <CfaStart/>}
		</Query>);
};

export default connect(()=>({}), {updateCompany, updateUser})(CfaStartContainer);