import PropTypes from 'prop-types';
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import SimpleInputField from '../SimpleTextField/SimpleTextField';

const styles = theme => ({
  root    : {
    flexGrow : 1,
    width    : '100%',
    margin   : 12,
  },
  paper   : {
    padding : 12,
  },
  control : {
    padding : theme.spacing.unit * 2,
  },
  title   : {
    fontSize : 24,
    margin   : 12,
  },
  button  : {
    margin : theme.spacing.unit,
  },
});

const TodoItemQuestion = ({ object, action, deleteAction, updateAction, classes }) => (
  <TableRow>
    <TableCell className={classes.root}>
      <SimpleInputField id="title" required label="Title" data={object} action={action} />
      <SimpleInputField id="description" required label="Description" data={object} action={action} />
      <SimpleInputField id="link_type" label="Link Type" data={object} action={action} />
      <SimpleInputField id="link_url" label="Link Url" data={object} action={action} />
      <SimpleInputField id="link_title" label="Link Title" data={object} action={action} />
      <SimpleInputField id="index" label="Index" data={object} action={action} />
      <br />
    </TableCell>
    <TableCell>
      <IconButton
        color="primary"
        className={classes.button}
        aria-label="Save"
        disabled={!object.title || !object.modified}
        onClick={() => {
          updateAction(object);
        }}>
        <Icon>save</Icon>
      </IconButton>
      <IconButton className={classes.button} aria-label="Delete">
        <DeleteIcon
          onClick={() => {
            deleteAction(object);
          }}
        />
      </IconButton>
    </TableCell>
  </TableRow>
);

TodoItemQuestion.propTypes = {
  object       : PropTypes.object,
  action       : PropTypes.func.isRequired,
  deleteAction : PropTypes.func.isRequired,
  updateAction : PropTypes.func.isRequired,
  classes      : PropTypes.object.isRequired,
};

TodoItemQuestion.defaultProps = {
  object : {},
};

export default withStyles(styles)(TodoItemQuestion);
