import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

class OtherActivities extends React.Component {
  filterEvent = (firstName, event) => {
    if (event) {
      switch (event.name) {
        case 'user_rating':
          return `${firstName} rated a user`;
        case 'Viewed Auction':
          return `${firstName} ${event.name}`;
        case 'Viewed Education':
          return `${firstName} ${event.name}`;
        case 'notebook_drawer_opened' || 'notebookDrawerOpened':
          return `${firstName} opened their notebook`;
        case 'practiceInfo_started':
          return `${firstName} started filling our their Practice Info`;
        case 'practiceInfo_completed':
          return `${firstName} completed their Practice Info`;
        case 'Expressed Interest in continuity':
          return `${firstName} ${event.name}`;
        case 'signup_completed':
          return `${firstName} completed the signup process`;
        case 'signup_started':
          return `${firstName} started the signup process`;
        case 'productMix_completed':
          return `${firstName} completed their product mix`;
        case 'productMix_started':
          return `${firstName} started their product mix`;
        case 'strengths_completed':
          return `${firstName} completed their strengths`;
        case 'strengths_started':
          return `${firstName} started their strengths`;
        case 'bio_completed':
          return `${firstName} completed their bio`;
        case 'bio_started':
          return `${firstName} started their bio`;
        case 'notebook_note_made':
          return `${firstName} made a note in their notebook`;
        case 'Attempted CE Quiz':
          return `${firstName} attempted a CE Quiz`;
        case 'Viewed CE Course':
          return `${firstName} viewed a CE Quiz`;
        default:
          return event.name;
      }
    }
    return <div />;
  };

  render () {
    const { firstName, event } = this.props;
    return <span>{this.filterEvent(firstName, event)}</span>;
  }
}

OtherActivities.propTypes = {
  classes : PropTypes.object.isRequired,
  title   : PropTypes.string.isRequired,
};

const styles = {
  border  : {
    border       : '1px solid lightgrey',
    borderRadius : 8,
    margin       : 5,
    height       : 80,
    width        : 80,
  },
  title   : {
    fontWeight : 'bold',
    textAlign  : 'center',
    margin     : 7,
    fontSize   : 10,
  },
  content : {
    textAlign : 'center',
    margin    : 7,
    fontSize  : 16,
  },
};

export default withStyles(styles)(OtherActivities);
