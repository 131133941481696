import { combineReducers } from 'redux';
import { AUTHORIZE_PENDING_USER, DELETE_PENDING_UNAUTHORIZED_USER } from '../constants/pendingAuthorizedUsersConstants';
import { findIndex, pullAt } from 'lodash';

const removeUser = (userList, user_id) => {
  const index = findIndex(userList, (e) => e.id === user_id)
  pullAt(userList, index);
  return userList;
}

const PendingAuthorizedUserReducer = (state = {}, action) => {
  let list = [];
  switch (action.type) {
    case AUTHORIZE_PENDING_USER:
    case DELETE_PENDING_UNAUTHORIZED_USER:
      list = state.data;
      list = action.error || action.loading ? list : removeUser(list, action.user_id);
      return {
        ...state,
        data: list,
        error: action.error,
        close: action.close,
        loading: action.loading,
      };
  default:
      return state;
  }
};

export default PendingAuthorizedUserReducer;
